var createReactClass = require('create-react-class');
import React from 'react';

const FlagModal = createReactClass({
  getInitialState: function() {
    return {
      flagStatus: this.props.flag
    }
  },

  toggleOptions: function(e) {
    this.setState({flagStatus: e.target.value})
  },

  handleSetFlagWrapper: function () {
    this.props.handleSetFlag(this.state.flagStatus)
  },

   render: function() {
    return (
      <div className="reveal-modal middling" id="remind-modal" data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content middling">
            <h4>Flag Suggested Author Tier</h4>
            <p>Select a tier to flag the author for a suggest tier.</p>
            <select style={{width: '50%'}} onChange={this.toggleOptions} defaultValue={this.state.flagStatus}>
              <option value="">Select tier</option>
              <option value='Free'>Free</option>
              <option value="Cureus">Cureus Editing Suggested</option>
              <option value="3rd Party">3rd Party Suggested</option>
            </select>
          </div>

          <div className="modal-footer">
            <div className="row">
              <a className="button small success" style={{float: 'left'}} href={this.props.authorPath} target="_blank">Author Profile</a>
              <a className="button small" onClick={this.handleSetFlagWrapper}>Set Flag</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default FlagModal;