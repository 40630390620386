var createReactClass = require('create-react-class');
import React from 'react';
import ExportCsvModal from './ExportCsvModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const Row = createReactClass({
  triggerExportCsv: function (evt){
    $.ajax({
      type: "POST",
      dataType: "json",
      url: this.props.emailList.exportUrl,
      data: { id: this.props.emailList.id }
    })
      .done(function (data, textStatus, jqXHR) {
        Modal.open({
          children: <ExportCsvModal />
        });
      })
      .fail(function (jqXHR, textStatus, errorThrown){
        //TODO: Handle error case
      });
  },

  triggerRefresh: function(evt) {
    $.ajax({
      type: "POST",
      dataType: "json",
      url: this.props.emailList.refreshUrl
    })
      .done(function (data, textStatus, jqXHR) {
        window.scrollTo({top: 0});
        Cureus.Flash.success("Your email list is queued for refreshing!")
      })
      .fail(function (jqXHR, textStatus, errorThrown){
        //TODO: Handle error case
      });
  },

  renderActionButton: function (emailList){
    return (
      <a href={emailList.viewUrl} className="button secondary tiny split">
        <div>View list</div>
        <span data-dropdown={"email-list-actions-"+emailList.id} />
      </a>
    )
  },

  renderActionButtonItems: function (emailList){
    var settings_link;

    settings_link = null;
    if(emailList.isEditable) {
      settings_link = ( <li> <a href={emailList.editUrl}>Settings</a> </li> )
    }

    var refreshLink;
    if (emailList.hasRefreshes) {
      refreshLink = ( <li><a onClick={this.triggerRefresh}>Refresh List</a></li>)
    }

    return (
      <ul id={"email-list-actions-"+emailList.id} className="f-dropdown" data-dropdown-content>
        <li>
          <a href={emailList.duplicateUrl} data-method="post">Duplicate list</a>
        </li>
        { settings_link }
        <li>
          <a onClick={this.triggerExportCsv}>Export CSV</a>
        </li>
        { refreshLink }
      </ul>
    )
  },

  handleChecked: function(evt) {
    var isChecked = $(this.refs.checkbox).is(":checked");
    this.props.onCheck(this.props.emailList.id, isChecked);
  },

  lockIcon: function() {
    if (!this.props.emailList.isEditable) {
      return(
        <span><i className="fa fa-lock"></i>&nbsp;</span>
      )
    }
  },

  render: function (){
    return (
      <tr>
        <td>
          <input ref='checkbox' type='checkbox' checked={this.props.isChecked} onChange={this.handleChecked} />
        </td>
        <td className="drag-handle">
          <h4 className="reg">
            { this.lockIcon() }
            <a href={this.props.emailList.viewUrl}>
              { this.props.emailList.title }
            </a>
          </h4>
          <br />
          Created:
          &nbsp;
          { this.props.emailList.createdAtFormatted }
          <br />
          Updated:
          &nbsp;
          { this.props.emailList.updatedAtFormatted }
        </td>
        <td>
          { this.props.emailList.size }
        </td>
        <td>
          { this.renderActionButton(this.props.emailList) }
          { this.renderActionButtonItems(this.props.emailList) }
        </td>
      </tr>
    );
  }
});

export default Row;