import React, {useState, useEffect} from 'react';

const MultimediaCard = ({media, handleDelete, idx}) => {
  
  return (
    <div className="card drag-handle attachment-container">
      <img className={"action-buttons drag featured-multimedia"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
      <img className={"action-buttons featured-multimedia delete"}  src="https://public.cureus.com/channel_design/delete_widget.png" onClick={(e) => handleDelete(e, idx)}/>
      <div className='image-container'>
        <a href={media.url} target="_blank">
          {
            media.preview_image ? (
              <img className='image-size' src={media.preview_image}/>
            ) : (
              <img className='image-size' src={media.image_thumbnail}/>
            )
          }
        </a>
      </div>
      <h6>{media.name}</h6>
    </div>
  )
}

export default MultimediaCard;