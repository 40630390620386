import React, { memo, useState } from 'react';
import Collapse from '../../Shared/Collapse';
import Categories from './Categories';
import Buttons from './Buttons';

const CategorySidebar = ({ categories, handleSubmit, categorySelection, updateCategory }) => {
  const [showMore, setShowMore] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const openCollapse = () => {
    setIsOpen(!isOpen);
  }

  const handleShowMore = () => {
    setShowMore((prevState) => !prevState);
    setIsOpen(true);
  }

  const clearCategories = !categorySelection.allCategories && Object.keys(categorySelection).length > 0;

  return (
    <div className="small-2 columns show-for-large-up category-side-bar">
        <Collapse title="Categories" isOpen={isOpen} openCollapse={openCollapse}>
          <Categories
            categories={categories}
            categorySelection={categorySelection}
            onChange={updateCategory}
            showMore={showMore}
            isOpen={isOpen}
          />
        </Collapse>
        <Buttons
          clearCategory={clearCategories}
          handleClearCategories={updateCategory}
          handleShowMore={handleShowMore}
          handleSubmit={handleSubmit}
          showMore={showMore}
        />
    </div>
  )
}

export default memo(CategorySidebar)