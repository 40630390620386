var createReactClass = require('create-react-class');
import React from 'react';

const AddFromExistingModal = createReactClass({
  getInitialState: function() {
    return {
      selected: ""
    }
  },

  handleSelect: function(selected) {
    this.setState({
      selected: selected
    });
  },

  handleViewList: function() {
    window.location = this.state.selected;
  },

  render: function() {
    return (
      <div className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <div className='modal-form'>
              <h4>Select a list to add from</h4>
              <Select
                value={this.state.selected}
                options={this.props.options}
                onChange={this.handleSelect}
                clearable={false}
                searchable={false}
                className='select-existing-list'
                />
              <div className='modal-footer'>
                <a className='cancel-modal button secondary'>Cancel</a>
                <a className='button primary' onClick={this.handleViewList}>View List</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default AddFromExistingModal;