import React, { useState, useRef, useEffect } from 'react';
import HistoryModal from '../../History/HistoryModal';
import Modal from '../../Modal';
import {Cureus} from '../../../Cureus';

const Reviewing = ({reviewershipsPath, createPath, templatePath}) => {

  const [tableData, setTableData] = useState(false);

  useEffect(() => {
    fetchData();
  }, [])

  const populateTable = (fetchedTableData) => {
    var dt = $("#reviewing-reviewerships-table").DataTable({
      columns: [
        {width: '35px'},
        {width: '180px'},
        null,
        {width: '160px'},
        null,
        {width: '100px', class: 'centered'}
      ],
      columnDefs: [
        {targets: [1], orderData: [2]},
        {targets: [2], visible: false},
        {orderable: false, targets: [0, 5]}
      ],
      searching: false,
      dom: '<"row">tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "bAutoWidth": false,
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
      "iDisplayLength": 5,
      "rowCallback": function(row) {
        let element = row.children[4].children[0]
        element.addEventListener('click',function() {
          openHistoryModal(element.dataset.articleId, element.dataset.reviewershipId)
        })
      }
    })
    
    dt.api().clear();
    for (var i = 0; i < fetchedTableData.reviewerships.length; i++) {
      let row = fetchedTableData.reviewerships[i];
      dt.fnAddData( [
          row.checkbox,
          row.most_recent_activity,
          row.sort_column,
          row.reviewer,
          row.title,
          row.reminder_history
      ], false)
    }
    dt.fnSort([[2, 'desc']])
    dt.fnDraw()
    $(document).foundation('reflow');
    Cureus.Admin.MultipleReminders.init({
      btnSelector: ".bulk-email-button-reviewers-table",
      noNavSelector: true,
      tabPaneSelector: "#reviewing-reviewerships-datatable-container",
      templateName: "CompleteReview",
      templatePath: templatePath,
      async: true,
      createPath: createPath
    });
  }

  const fetchData = () => {
    if(tableData) {
      populateTable(tableData)
      return;
    }

    $.ajax({
      type: 'GET',
      url: reviewershipsPath,
      data: {state: 'reviewing'},
      success: function(data) {
        setTableData(data)
        populateTable(data)
      }
    });
  }

  const openHistoryModal = (articleId, authorshipReviewershipId) => {
    Modal.open({
      children: <HistoryModal
          articleId={articleId}
          authorshipReviewershipId={authorshipReviewershipId}
        />
    });
  }


  return (
    <div className="channel-articles-datatable-container" id="reviewing-reviewerships-datatable-container">
      <table id="reviewing-reviewerships-table">
        <thead>
          <tr>
            <th><input type="checkbox" /></th>
            <th>Review start date</th>
            <th></th>
            <th>Reviewer</th>
            <th>Title</th>
            <th>History</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  )
}

export default Reviewing;