import React, { useState, useEffect, useRef } from 'react';
import QuestionAnswerItem from './QuestionAnswerItem';
import Coi from './Coi';
import CredibleConclusions from './CredibleConclusions';

const Main = ({questions, completeReviewPath, isArticleInReview}) => {

  const [questionAnswersPairs, setQuestionAnswersPairs] = useState([]);

  const qapRef = useRef(questionAnswersPairs);

  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const [disclosureDeclared, setDisclosureDeclared] = useState(null);

  const [disclosure, setDisclosure] = useState(null);

  useEffect(() => {
    addEventListenerToSubmitButton();
  }, [])

  useEffect(() => {
    checkAllQuestionsAnswered(questionAnswersPairs);
    qapRef.current = questionAnswersPairs;
  }, [questionAnswersPairs])

  useEffect(() => {
    enableSubmit();
  }, [disableSaveButton])


  const addEventListenerToSubmitButton = () => {
    let button = document.getElementById("submit-questionnaire-button")
    button.addEventListener("click", handleSubmit)
  }

  const validAnswers = (questionAnswersPairs) => {
    let result = true;
    for(let i = 0; i < questionAnswersPairs.length; i++) {
      const validAnswerString = (typeof questionAnswersPairs[i].answer === "string" && questionAnswersPairs[i].answer.trim() == "");
      if (validAnswerString || questionAnswersPairs[i].answer === null) {
        result = false;
        break;
      }
    }
      
    return result;    
  }

  // valid conflict of interest responses
  const validCoi = () => {
    if(disclosureDeclared === false) {
      // remove disclosure text answer if disclosure declared radio button is false
      const disclosureObj = questionAnswersPairs.filter((pair) => pair.id === "disclosure");
      if(disclosureObj.length > 0) {
        setQuestionAnswersPairs(questionAnswersPairs.filter((pair) => pair.id !== "disclosure"));
      }      
      return true;
    }
    return disclosureDeclared && disclosure !== null;
  }
  
  const checkAllQuestionsAnswered = (questionAnswersPairs) => {
    if(isArticleInReview) {
      if(validAnswers(questionAnswersPairs) && validCoi() && questionAnswersPairs.length > questions.length) {
        setDisableSaveButton(false);
      } else {
        setDisableSaveButton(true);
      }
    } else {
      if(questionAnswersPairs.length < questions.length || !validAnswers(questionAnswersPairs)) {
        setDisableSaveButton(true);
      } else {
        setDisableSaveButton(false);
      }
    }
  }

  const enableSubmit = () => {
    let button = document.getElementById("submit-questionnaire-button")
    disableSaveButton ? button.classList.add("disabled") : button.classList.remove("disabled")
  }

  const handleUpdateAll = () => {
    $.ajax({
      url: completeReviewPath,
      type: 'PUT',
      dataType: 'json',
      data: {questions: qapRef.current},
      success: function(res) {
        window.location.replace(res.path)
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateAll();
  }

  const setQuestionType = (id) => {
    if(typeof id === 'number') {
      return "Question";
    }
    else if(id === "disclosure_declared") {
      return "Disclosure_Declared";
    }
    else {
      return "Disclosure";
    }
  }

  const handleQuestionChange = (id, value) => {
    const questionIndex = questionAnswersPairs.findIndex((answer) => answer.id === id);

    if (questionIndex === -1) {
      setQuestionAnswersPairs([...questionAnswersPairs, { id: id, answer: value , type: setQuestionType(id)}]);
    } else {
      const updatedAnswers = [...questionAnswersPairs];
      updatedAnswers[questionIndex] = { id: id, answer: value, type: setQuestionType(id) };
      setQuestionAnswersPairs(updatedAnswers);
    }
  };

  const renderQuestions = (questions) => {
    return (
      questions && questions.map((question, index) => {
        return <QuestionAnswerItem question={question.question} id={question.id} answer={question.response} subtitle={question.question_subtitle} handleQuestionChange={handleQuestionChange} type={question.type} key={index} setDisableSaveButton={setDisableSaveButton}/>
      })
    )
  }

  const renderDisclosureQuestion = () => {
    if (isArticleInReview) {
      return (
        <div className='row coi-container'>
          <div className='large-12 medium-12 small-12 columns'>
            <Coi handleQuestionChange={handleQuestionChange} setDisableSaveButton={setDisableSaveButton} setDisclosureDeclared={setDisclosureDeclared} setDisclosure={setDisclosure}/>
          </div>
          <p className='header-subtitle'>Ready to submit your review? Click the green Submit button at the top of your screen.</p>
        </div>
      )
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="questionnaire-body">
        <div className='row questions-container'>
          <div className='large-12 medium-12 small-12 columns'>
            {renderQuestions(questions)}
          </div>
        </div>
        <div className='row conclusion-container'>
          <div className='large-12 medium-12 small-12 columns'>
            <CredibleConclusions handleQuestionChange={handleQuestionChange} question={questions[questions.length-1]} setDisableSaveButton={setDisableSaveButton}/>
          </div>
        </div>
        {renderDisclosureQuestion()}
      </div>
    </form>
  )
}

export default Main;