import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import MainContent from './MainContent';
import Sidebar from './Sidebar';

const Main = ({lastCureusUpdate, cureusUpdatePath, featuredContent, featuredContentPath, featuredMultimedia, featuredMultimediaPath, heroPath, specialties, articleExistsPath, newsroomPostDetailsPath, usersPath, adminFeaturedUsersPath}) => {
  const [activeTab, setActiveTab] = useState('main_content')
  useEffect(() => {

  }, [])

  return (
    <Router hashType={"hashbang"}>
      <div className="row">
        <div className="large-12 columns">
          <div className="cureus-tabs secondary-tabs dashboard homepage">
            <ul className="tabs">
            <Link to="/main_content/hero">
                <li className={"tab-title " + (activeTab === 'main_content' ? 'active' : '')} onClick={() => setActiveTab('main_content')}>
                  <a className="dashboard">MAIN CONTENT</a>
                </li>
              </Link>
              <Link to="/sidebar/picture-prognosis">
                <li className={"tab-title " + (activeTab === 'sidebar' ? 'active' : '')} onClick={() => setActiveTab('sidebar')}>
                <a className="dashboard">SIDEBAR</a>
                </li>
              </Link>
            </ul>
          </div>
        </div>
          <Switch>
            <Route path='/main_content'>
              <MainContent
                featuredContentPath={featuredContentPath}
                featuredContent={featuredContent}
                featuredMultimedia={featuredMultimedia}
                featuredMultimediaPath={featuredMultimediaPath}
                heroPath={heroPath}
                articleExistsPath={articleExistsPath}
                newsroomPostDetailsPath={newsroomPostDetailsPath}
                specialties={specialties}
                usersPath={usersPath}
                adminFeaturedUsersPath={adminFeaturedUsersPath}
              />
            </Route>
            <Route path='/sidebar'>
              <Sidebar
                cureusUpdatePath={cureusUpdatePath}
                lastCureusUpdate={lastCureusUpdate}
                setActiveTab={setActiveTab}
              />
            </Route>
          </Switch>
      </div>
    </Router>
  )

}

export default Main;