var createReactClass = require('create-react-class');
import React from 'react';
import Card from './Card';

const Selection = createReactClass({
  handleBorder: function(name) {
    if(this.props.selected === name) {
      return {border: "2px solid #0096B7", boxShadow: "0 0 5px #CCCCCC"}
    }
  },

  renderCloseModal: function() {
    if(!this.props.paymentsPage) {
      return (
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
      )
    }
  },

  copyText: function() {
    var textArea = document.createElement("textarea")
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = this.props.copyText
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    textArea.setSelectionRange(0, 99999)
    document.execCommand("copy");
    textArea.style.display = 'none'
  },

  renderClipboardText: function() {
    return (
      <div className="text-center">
        <br></br>
        <p><strong>Is someone else going to complete the purchase?</strong></p>
        <div className="row copy-link-clipboard-container">
          <p>Please share this page with the payer by copying and pasting the page URL into an email.</p>
          <a className="button secondary" onClick={this.copyText}>Copy Link</a>
        </div>
      </div>
    )
  },

  renderStandardCard: function() {
    if(!this.props.subsequentBoost) {
      return (
        <Card
          header="Standard"
          price={60}
          blurb="Organic social media promotion: Facebook, Instagram, LinkedIn, and Twitter."
          footer="700-1,000+ additional article views"
          handleSelection={this.props.handleSelection}
          type="standard"
        />
      )
    }
  },

  render: function() {
   return (
    <div className="modal-body">
      {this.renderCloseModal()}
      <div className="modal-content">
       <h4>“{this.props.title}”</h4>
       <p>Build your reputation, increase your chances of being cited, and ensure that your work is widely read with a Cureus Article Promotion package.</p>
        <div className="row tile-container" data-equalizer="blurb" data-equalizer-mq="small-up">
          {this.renderStandardCard()}
          <Card
            header="Premium"
            price={99}
            blurb="Organic social media promotion plus a targeted paid social media ad campaign driving traffic to your article based on keywords, geo-targeting, and demographics."
            footer="1,500-2,000+ additional article views"
            handleSelection={this.props.handleSelection}
            type="premium"
            subsequentBoost={this.props.subsequentBoost}
          />
          <Card
            header="Premium Plus"
            price={199}
            blurb="Reach new heights: organic social media promotion plus a larger targeted paid social media ad campaign driving even more traffic to your article based on keywords, geo-targeting, and demographics."
            footer="2,600-3,000+ additional article views"
            handleSelection={this.props.handleSelection}
            type="premium-plus"
            subsequentBoost={this.props.subsequentBoost}
          />
          <Card
            header="Maximum"
            price={499}
            blurb="The ultimate article promotion: organic social media posting and the largest targeted paid social media ad campaign driving the most traffic to your article based on keywords, geo-targeting, and demographics."
            footer="6,000+ additional article views"
            handleSelection={this.props.handleSelection}
            type="maximum"
            subsequentBoost={this.props.subsequentBoost}
            lastCard={true}
          />
        </div>
       <div className='modal-footer text-center'>
         <p>Your article will appear on our social channels 4-5 days after purchase. The results of your boost will be provided within 45 days of purchase.</p>
         <p><small><strong>*Please note: articles containing images of genitalia are not eligible for Article Promotion due to social media image policies.</strong></small></p>
       </div>
       <div className="text-center">
          <br></br>
          <p>If you or your marketing department are interested in custom Article Promotion targeting to reach an even larger audience, please email our social media team at <a href="mailto:social@cureus.com">social@cureus.com</a> to discuss your goals and pricing options.</p>
          <p><small><strong>*A budget of $600+ is required for custom social media marketing campaigns.</strong></small></p>
        </div>
       {this.renderClipboardText()}
     </div>
    </div>
   )
 }
})

export default Selection;