import React from 'react';

const PublishingStepButtons = ({prevPath, saveFunction}) => {

  return (
    <div className='step-actions clearfix'>
      <div className='pull-left'>
        <a href={prevPath} className='button secondary'>Back</a>
      </div>
      <div className='pull-right'>
        <a onClick={saveFunction} className='button special' id="save-now">Save</a>
        <input className='button primary' style={{"margin-left": "4px"}} type='submit' value="Save and continue"/>
      </div>
    </div>
  )
}

export default PublishingStepButtons;