import React, {useState, useEffect} from 'react';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const RejectModal = ({applicationId, pendingProposals=null, setApplicationState, reasonsData}) => {

  const [reasons, setReasons] = useState(reasonsData);
  const [reasonsCheckedCount, setReasonsCheckedCount] = useState(0);

  useEffect(() => {
    new Cureus.CKEditor('reject-request-textarea',  { 
      editor_type: 'deferral',
      ckeditor: {height: 300},
      change_listener: "#reject-request-textarea"
    })
  }, [])

  useEffect(() => {
    renderDeferralReasonText();
  }, [reasons])

  const renderData = () => {
    const message = CKEDITOR.instances["reject-request-textarea"].getData();
    if (message) {
      return { message: message };
    } else {
      return null;
    }
  }

  const handleRejectModal = () => {
    $.ajax({
      type: 'PUT',
      url: `/admin/collection_applications/${applicationId}/reject`,
      data: renderData(),
      dataType: 'json',
      success: function(res) {
        Modal.close();
        if(pendingProposals) {
          var table = $('#collections-table').DataTable();
          let row = $(`#row-${applicationId}`);
          table.fnDeleteRow(row[0]);
        }
        if(setApplicationState) {
          setApplicationState(res.state)
        }
        Cureus.Flash.success("Collection inquiry has been rejected.");
      },
      error: function() {
        Modal.close();
        Cureus.Flash.alert("Collection inquiry could not be rejected.");
      }
    });
  }

  const renderDeferralReasonText = () => {
    if (reasonsCheckedCount == 0) { return; }
    let ckeditor = CKEDITOR.instances['reject-request-textarea'];
    let reasonDescriptions = ['<div id="deferral-reasons-list">'];

    for (let reasonId in reasons) {
      let currentReason = reasons[reasonId];
      if (currentReason.checked) {
        reasonDescriptions.push(
          "<p>" + currentReason.description + "</p>"
        )
      }
    }

    reasonDescriptions.push('</div>')
    var oldText = ckeditor.getData();
    var completeText = reasonDescriptions.join(" ") + oldText

    if (ckeditor.getData() == '') {
      ckeditor.setData(completeText + "<p></p>")
    } else {
      ckeditor.setData(completeText);
    }
  }

  const handleReasonRemoval = () => {
    let ck = CKEDITOR.instances["reject-request-textarea"];
    let deferralReasonsContent = ck.document.getById("deferral-reasons-list");
    if (deferralReasonsContent) {
      deferralReasonsContent.remove();
    }
  }

  const countCheckedReasons = (updatedReasons) => {
    return Object.values(updatedReasons).reduce((count, reason) => {
      return count + (reason.checked ? 1 : 0);
    }, 0);
  }

  const handleCheckboxToggle = (e) => {
    handleReasonRemoval();
    let reasonId = e.target.id;
    const updatedReasons = {
      ...reasons,
      [reasonId]: {
        ...reasons[reasonId],
        checked: !reasons[reasonId].checked
      }
    };
    setReasonsCheckedCount(countCheckedReasons(updatedReasons));
    setReasons(updatedReasons);
  }

  const renderCheckBoxes = () => {
    let checkBoxes = [];
    for (let key in reasons) {
      let currentReason = reasons[key];
      checkBoxes.push(
        <div className='columns large-4 end' key={key}>
          <div className='checkbox-container'>
            <label htmlFor={key}>
              <input name={key} id={key} type='checkbox' value={currentReason.checked} onChange={handleCheckboxToggle} checked={currentReason.checked} />
              {currentReason.label}
            </label>
          </div>
        </div>
      )
    }
    return checkBoxes.chunk(3).map(function(group) {
      return (
        <div className='row'>
          {group}
        </div>
      )
    })
  }

  return (
    <div id={"reject-collection-modal"} className="reveal-modal medium" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="large-12 columns">
              <p>Are you sure you want to reject this collection? Please include detailed notes for the guest editor.</p>
            </div>
          </div>
          {renderCheckBoxes()}
          <div className="row">
            <div className="small-12 columns">
              <textarea id='reject-request-textarea'></textarea>
            </div>
            <div className="large-12 columns buttons-container">
              <a className="button small success" onClick={handleRejectModal}>Reject</a>
              <a href="#" className="button small alert cancel-modal">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RejectModal;