var createReactClass = require('create-react-class');
import React from 'react';
import CategoryModal from './CategoryModal';
import Modal from '../Modal';

const CategorySelector = createReactClass({
  getInitialState: function() {
    return ({
      selectedCategoryIds: this.props.selectedCategoryIds
    })
  },

  renderSelectedCategories: function() {
    var self = this;
    return this.props.categoryIds.map(function(id) {
      if (self.state.selectedCategoryIds.includes(parseInt(id))) {
        return (
          <li key={'hidden-' + id}>
            <input type='hidden' value='true' name={'campaign[category_ids][' + id + ']'} id={'category_id_' + id}/>
            {self.props.categories[id]}
          </li>
        )
      }
    })
  },

  renderCheckboxes: function() {
    var self = this
    return this.props.categoryIds.map(function(id) {
      var isChecked = self.state.selectedCategoryIds.includes(parseInt(id))
      return (
        <li key={id} style={{display: 'block', float: 'left', width: '50%'}}>
          <input onClick={self.handleChecked} type='checkbox' name={'campaign[category_ids][' + id + ']'}id={id} defaultChecked={isChecked}/>
          <label htmlFor={id}>{self.props.categories[id]}</label>
        </li>
      )
    })
  },

  handleChecked: function(e) {
    var checked = this.state.selectedCategoryIds;
    let idx = checked.indexOf(parseInt(e.target.id))
    if (idx != -1) {
      checked.splice(idx, 1)
    } else {
      checked.push(parseInt(e.target.id))
    }
    this.setState({selectedCategoryIds: checked})
  },

  handleClear: function() {
    this.setState({selectedCategoryIds: []}, function() {
      $('input[type=checkbox]').prop('checked', false);
    })
  },

  openModal: function() {
    Modal.open({
      children: <CategoryModal
        renderCheckboxes={this.renderCheckboxes}
        handleClear={this.handleClear}/>
    })
  },

  render: function() {
    return (
      <div>
        <h3 className='reg'>Cureus U Categories</h3>
        <h4 className='reg'>Currently Selected Categories</h4>
        {this.renderSelectedCategories()}
        <br/>
        <br/>
        <button className="button small primary" data-reveal-id="category-modal" type="button" onClick={this.openModal}>Select Categories</button>
        <br/>
      </div>
    )
  }
})

export default CategorySelector;