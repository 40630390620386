var createReactClass = require('create-react-class');
import React from 'react';

const Twitter = createReactClass({
  componentWillMount: function() {
    blurb: this.props.widget.blurb
  },

  renderWidgetContent: function() {
    if (this.props.open) {
      return(
        <div>
          Embed Code <a href="#" data-reveal-id="twitter_widget_info"><i className="fa fa-question-circle"/></a>
          <textarea defaultValue={this.props.widget.blurb} name="widget[blurb]"/>
        </div>
      )
    }
  },

  render: function() {
    return (
      <div>
        { this.renderWidgetContent() }
      </div>
    )
  }
})

export default Twitter;