import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../../Cureus/ChannelApi';
import UpdateButton from '../../UpdateButton';
import DeleteButton from '../../DeleteButton';
import PollOption from './PollOption';
import { getCookie, setCookie } from '../../../../Cureus/Cookies';
import AnswerBarRow from '../../../PicturePrognosisHome/AnswerBarRow';

const PollWidget = ({slug, handleDeleteWidget, handleUpdateWidget, sidebarWidgets, widgetId}) => {
  const [content, setContent] = useState(null);
  const [pictureQuizId, setPictureQuizId] = useState(null);
  const [selectedId, setSelectedId] = useState(null)
  const [answers, setAnswers] = useState([]);
  const [answeredForTheDay, setAnsweredForTheDay] = useState(false)

  useEffect(() => {
    setAnsweredForTheDay(getCookie(`channel-poll-${slug}-${widgetId}`).length > 0)
    if(widgetId) {
      let onSuccess = (res) => {
        setContent(res);
        setAnswers(res.answers);
        setPictureQuizId(res.id)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [sidebarWidgets])

  useEffect(() => {
    if(pictureQuizId) {
      handleSubmit()  
    }
  }, [selectedId])

  const handleSubmit = () => {
    $.ajax({
      type: 'PUT',
      url: `/picture_quizzes/${pictureQuizId}`,
      data: {quiz_option_id: selectedId, answered_already: answeredForTheDay},
      success: function(data) {
        setAnsweredForTheDay(true)
        setCookie(`channel-poll-${slug}-${widgetId}`, data.userAnsweredId, 7);
      }
    });
  }

  const percent = (count, answersTotal) => {
    return Math.floor((count/answersTotal) * 100) + '%'
  }

  const renderActionButtons = () => {
    return (
    <>
      <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
      <UpdateButton
        handleUpdateWidget={handleUpdateWidget}
        widget={content}
        modalName={"Poll"}
        sidebarWidgets={sidebarWidgets}
        slug={slug}
      />
      <DeleteButton
        handleDeleteWidget={handleDeleteWidget}
        widgetId={widgetId}
      />
    </>
    )
  }

  const renderAnswers = () => {
    return answers.map((option, idx) => {
      if(answeredForTheDay) {
        let count = option.id === selectedId ? option.count + 1 : option.count
        let total = selectedId ? content.total + 1 : content.total
        return (
          <AnswerBarRow 
            key={idx} 
            answer={option} 
            percent={percent(count, total)} 
            percentNumber={percent(count, total)} 
            correctAnswerId={content.correctAnswerId} 
            userAnsweredId={getCookie(`channel-poll-${slug}-${widgetId}`) || selectedId} 
          />
        )
      } else {
      return (
        <PollOption 
          key={option.id}
          idx={idx}
          optionId={option.id}
          answer={option.answer}
          setSelectedId={setSelectedId}
          selected={false}
        />
        )
      }
    })
  }

  if(!content) {
    return <></>
  }

  return (
    <div className="row sidebar-row">
      {handleDeleteWidget && renderActionButtons()}
      <div className="small-12 columns widget poll-widget">
        <h3>{content.title}</h3>
        {content.image && <img className='img-container' src={content.image} alt="Poll Image" />}
        <p>{content.question}</p>
        {renderAnswers()}
      </div>
    </div>
  )
}

export default PollWidget;