import React, { useState } from 'react';
import FaqItem from './FaqItem';
import NewFaq from './NewFaq';
import { Cureus } from '../../Cureus';
import PublishingStepButtons from '../Shared/PublishingStepButtons';


const Index = ({basePath, faqs, faqLimit, nextPath, prevPath}) => {
  const [faqPairs, setFaqPairs] = useState(faqs);

  const handleUpdateAll = () => {
    $.ajax({
      url: basePath,
      type: 'PUT',
      dataType: 'json',
      data: {questions: faqPairs},
      success: function(res) {
        if(res.status == 304) {
          return
        }
        setFaqPairs(res.faqs);
        Cureus.Flash.success('Successfully updated FAQs.')
      },
      error: function(res) {
        Cureus.Flash.alert(`${res.responseJSON.message}`)
      }
    })
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateAll();
    window.location = nextPath
  }

  const handleDelete = (question_id) => {
    $.ajax({
      url: basePath,
      type: 'DELETE',
      dataType: 'json',
      data: {question_id: question_id},
      success: function(res) {
        setFaqPairs(res.faqs);
        Cureus.Flash.success('Successfully deleted question.')
      },
      error: function(res) {
        Cureus.Flash.alert(`${res.responseJSON.message}`)
      }
    })
  }

  const handleChange = (id, field, value) => {
    setFaqPairs((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  }

  return (
      <form onSubmit={handleSubmit}>
        <div className="publish-faq-container">
          <NewFaq maxQuestions={faqLimit} basePath={basePath} currentFaqPairs={faqPairs} setNewFaqPairs={setFaqPairs}/>
          <div className='row faq-container'>
            <div className='large-9 medium-9 small-9 columns faq-inner-container' id="cardHolder">
              {faqPairs && faqPairs.map((question, index) => {
                return <FaqItem question={question.question} id={question.id} answer={question.answer} handleChange={handleChange} key={index} handleDelete={handleDelete} />
              })}
            </div>
          </div>
          <PublishingStepButtons prevPath={prevPath} nextPath={nextPath} saveFunction={handleUpdateAll}/>
        </div>
      </form>
  )
}

export default Index;