import React, { useState } from 'react';

const NewTermModal = ({handleCreate}) => {
const [term, setTerm] = useState('')


  return (
    <div className="reveal-modal small unwanted-accounts-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Add single word/phrase</h5>
          </div>
          <hr/>

          <div className='modal-form'>
            <div className="row">
              <div className="small-12 columns">
                <p>Please enter the term you want to add to the blacklist. </p>
              </div>
            </div>

            <div className="row">
              <div className="small-10 columns">
                <div className="input-container">
                  <label>Term</label>
                  <input type="text" placeholder="Please enter the term" onChange={(e) => {setTerm(e.target.value)}}/>
                  <p className='id-error-message'></p>
                </div>
              </div>
              <div className="small-2 columns">
                <button className="button primary" onClick={() => handleCreate(term)}>Add</button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default NewTermModal