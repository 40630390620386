import React from 'react';
import ArticleContent from './ArticleContent';

const BrowseArticles = ({
  bannerAdTopProps,
  contents,
  loading,
  renderBannerAd,
  handleSubmit,
  title,
}) => {
  const perPageText = (loading || !contents.length)
    ? ''
    : `1-${contents.length}`
  return (
    <div className="large-8 medium-12 small-12 columns end">
      {renderBannerAd(bannerAdTopProps, "banner-ad-1")}
      <div className="browse-content-container">
        <h1 className="browse-contents-header">{title}</h1>
        <div className="most-popular-per-page">{perPageText}</div>
        <ArticleContent
          loading={loading}
          contents={contents}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default BrowseArticles;
