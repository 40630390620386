import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import UpdateButton from '../UpdateButton';
import DeleteButton from '../DeleteButton';


const PromotionWidget = ({slug, handleDeleteWidget, handleUpdateWidget, sidebarWidgets, widgetId}) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if(widgetId) {
      let onSuccess = (res) => {
        setContent(res)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [sidebarWidgets])

  const renderActionButtons = () => {
    return (
    <>
      <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
      <UpdateButton
        handleUpdateWidget={handleUpdateWidget}
        widget={content}
        modalName={"Promotion"}
        sidebarWidgets={sidebarWidgets}
        slug={slug}
      />
      <DeleteButton
        handleDeleteWidget={handleDeleteWidget}
        widgetId={widgetId}
      />
    </>
    )
  }

  if(!content) {
    return <></>
  }

  return (
    <div className="row sidebar-row">
      {handleDeleteWidget && renderActionButtons()}
      <div className="small-12 columns widget channel-promotion">
        <h3>{content.title}</h3>
        <div className="img-container">
          <a href={content.displayUrl} target="_blank" rel="nofollow">
            <img src={content.image}/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default PromotionWidget;