import React, {useState, useEffect} from 'react';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import CollectionTable from './CollectionTable';
import GuestEditor from './GuestEditor';
import CureusCurated from './CureusCurated';
import GuestEditorTable from './GuestEditorTable';
import GuestEditorDraftTable from './GuestEditorDraftTable';

const Main = ({createCollectionPath, draftCollectionsCount, publishedCollectionsCount, categoriesData, guestEditorDraftCount, guestEditorPublishedCount, guestEditorApprovalCount, guestEditorRejectedCount}) => {
  const [activeTab, setActiveTab] = useState('cureus_curated')
  const [collections, setCollections] = useState([]);
  const [categories, setCategories] = useState(categoriesData || []);

  const fetchCollections = (state, type) => {
    if(state == 'rejected') {
      state = 'blocked'
    }
    $.ajax({
      type: "GET",
      url: "/admin/collections",
      dataType: "json",
      data: {state: state, type: type},
      success: function(res) {
        setCollections(res.collections);
      }
    })
  }

  // need to pass type to determine guest editor or cureus curated

  const fetchCollectionsForSelectedCategory = (category, state, type) => {
    if(state == 'rejected') {
      state = 'blocked'
    }
    $.ajax({
      type: "GET",
      url: '/admin/collections/get_collections_by_category',
      dataType: "json",
      data: {state: state, category: category, type: type},
      success: function(res) {
        setCollections(res.serializedCollections);
      }
    })
  }

  const renderCollectionTable = () => {
    return (
      <CollectionTable 
        collections={collections}
        fetchCollectionsForSelectedCategory={fetchCollectionsForSelectedCategory}
        categories={categories}
        activeTab={activeTab}
        type={activeTab === 'cureus_curated' ? null : 'GuestEditorCollection'}
      />
    )
  }

  const renderGuestEditorTable = () => {
    return (
      <GuestEditorTable 
        collections={collections}
        fetchCollectionsForSelectedCategory={fetchCollectionsForSelectedCategory}
        categories={categories}
        activeTab={activeTab}
        type={activeTab === 'cureus_curated' ? null : 'GuestEditorCollection'}
      />
    )
  }

  const renderGuestEditorDraftTable = () => {
    return (
      <GuestEditorDraftTable 
        collections={collections}
        fetchCollectionsForSelectedCategory={fetchCollectionsForSelectedCategory}
        categories={categories}
        activeTab={activeTab}
        type={activeTab === 'cureus_curated' ? null : 'GuestEditorCollection'}
      />
    )
  }

  return (
    <Router hashType={"hashbang"}>
      <div className="row">
        <div className="large-12 columns">
          <div className="cureus-tabs secondary-tabs dashboard homepage">
            <ul className="tabs admin-collections">
            <Link to="/cureus_curated/draft">
                <li className={"tab-title " + (activeTab === 'cureus_curated' ? 'active' : '')} onClick={() => setActiveTab('cureus_curated')}>
                  <a className="dashboard">CUREUS</a>
                </li>
              </Link>
              <Link to="/guest_editor/draft">
                <li className={"tab-title " + (activeTab === 'guest_editor' ? 'active' : '')} onClick={() => setActiveTab('guest_editor')}>
                <a className="dashboard">GUEST EDITOR</a>
                </li>
              </Link>
            </ul>
          </div>
        </div>
          <Switch>
            <Route path='/cureus_curated'>
              <CureusCurated
                createCollectionPath={createCollectionPath}
                draftCollectionsCount={draftCollectionsCount} 
                publishedCollectionsCount={publishedCollectionsCount}
                renderCollectionTable={renderCollectionTable}
                fetchCollections={fetchCollections}
              />
            </Route>
            <Route path='/guest_editor'>
              <GuestEditor 
                guestEditorPublishedCount={guestEditorPublishedCount}
                guestEditorApprovalCount={guestEditorApprovalCount}
                guestEditorDraftCount={guestEditorDraftCount}
                guestEditorRejectedCount={guestEditorRejectedCount}
                renderCollectionTable={renderCollectionTable}
                fetchCollections={fetchCollections}
                renderGuestEditorTable={renderGuestEditorTable}
                renderGuestEditorDraftTable={renderGuestEditorDraftTable}
              />
            </Route>
          </Switch>
      </div>
    </Router>
  )
};

export default Main;