import React from 'react';
import { Cureus } from '../../../Cureus';

export function normalizeArrayOfObjectsToAllArrays(arrayOfObjects) {
  if (arrayOfObjects.length === 0) { return [] }
  let data = []
  let headers = Object.keys(arrayOfObjects[0])
  data.push(headers)
  arrayOfObjects.forEach((item) => {
    let values = Object.values(item)
    data.push(values)
  })
  return data
}

const ExportCSVButton = ({ userEmail, csvContent, tableTitle, left, top }) => {
  const renderCSVButton = () => {
    const disabled = csvContent.length === 0;

    const handleClick = (e) => {
      if (disabled) return;
      $.ajax({
        type: 'POST',
        dataType: 'json',
        data: {contents: csvContent, userEmail: userEmail, tableTitle: `${tableTitle}`},
        url: '/admin/ae_dashboards/export_csv',
        success: function(data) {
          Cureus.Flash.success("CSV file has been emailed to you!")
        }
      });
    }

    return (
      <a
        style={{left: left, top: top }}
        className={`csv-button-container ${disabled ? 'disabled' : ''}`}
        onClick={handleClick}>
        <span>CSV</span>
        <img src="https://public.cureus.com/csv-icon.png" alt="" />
      </a>
    )
  }

  return (
    <div className="list-tab">
     {renderCSVButton()}
    </div>
  );
};

export default ExportCSVButton;