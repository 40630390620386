var createReactClass = require('create-react-class');
import React from 'react';

const Row = createReactClass({
  getInitialState: function() {
    return {
      reviewerships: null,
      currentUser: null,
      checked: [],
      disabled: 'disabled'
    }
  },

  renderCheckbox: function() {
    if( this.props.articleStateHash()[this.props.articleState]) {
      return <td><input className="th reviewer-checks" onClick={(e) => this.props.updateCheckedState(e, this.props.reviewer.id)} type="checkbox"/></td>
    }
  },

  renderVolunteer: function() {
    if(!this.props.isCompetition) {
      return <td>{this.props.reviewer.volunteer}</td>
    }
  },

  render: function () {
    if (!this.props.reviewer) {
      return null;
    }
    let reviewer = this.props.reviewer
    return (
      <tbody>
        <tr>
          {this.renderCheckbox()}
          {this.renderVolunteer()}
          <td> <a href={reviewer.reviewer_admin_user_path} target="_blank">{reviewer.email}</a> </td>
          <td>{reviewer.first_name}</td>
          <td>{reviewer.last_name}</td>
          <td>{reviewer.specialty}</td>
          <td>{reviewer.invitations_accepted}</td>
          <td>{reviewer.reviews_completed}</td>
          <td>{reviewer.articles_published}</td>
        </tr>
    </tbody>
    )
  }
});

export default Row;