var createReactClass = require('create-react-class');
import React from 'react';
import Siq from './Siq';

const Main = createReactClass({
  getInitialState: function() {
    return {
      minimized: false,
      view: null
    }
  },

  componentDidMount: function() {
    window.addEventListener('scroll', this.handleScroll);
  },

  handleScroll: function() {
    var element;
    if(this.props.articleType == 'OriginalArticle') {
      element = document.getElementById("discussion")
    } else {
      element = document.getElementById("references")
    }
    if (!element) {return;}
    if (window.scrollY > (element.offsetTop + element.offsetHeight) && (this.state.view !== 'siq')) {
      this.setState({view: 'siq'}, function() {
        var ratingModal = document.querySelector('.article-rating-modal-container');
        if (ratingModal) {
          ratingModal.style.display = 'none';
        }
        document.querySelector('.siq-footer-modal-container').style.display = 'block';
      })
    }
  },


  renderMinimizeButton: function() {
    if (this.state.minimized) {
      return (
        <div className="minimize-button minimized" onClick={this.handleMinimize}><span>^</span></div>
      )
    } else {
      return (
        <div className="minimize-button" onClick={this.handleMinimize}>-</div>
      )
    }
  },

  handleBorder: function() {
    if(this.state.minimized) {
      return {}
    } else {
      return {border: "1px solid #0096B7"}
    }
  },

  handleMinimize: function() {
    var min = !this.state.minimized
    this.setState({minimized: min})
  },

  handleClose: function() {
    document.querySelector(".article-rating-modal-container").style.display = 'none'
  },

  renderContent: function() {
    if (!this.state.minimized) {
      if (this.state.view === "siq") {
        return <Siq 
          siq_widget={this.props.siqWidget.siq_widget}
          />
      }
    }
  },

  render: function (){
    return (
      <div className={"siq-footer-modal"} style={this.handleBorder()}>
        <div className="blue-bar">
          {this.renderMinimizeButton()}
        </div>
        {this.renderContent()}
      </div>
    )
  }
});

export default Main;
