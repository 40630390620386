var createReactClass = require('create-react-class');
import React from 'react';

const MinMax = createReactClass({
  getInitialState: function() {
    return {
      button_src: "https://public.cureus.com/minimize-square-minus.png"
    }
  },

  toggle: function(){
    var $dom = $("#" + this.props.dom_id);
    var $content = $("#" + this.props.content_id);
    var $nav = $("#" + this.props.nav_id);
    if($content.css('display') == 'none'){
      $content.css('display','block');
      $nav.css('display','block');
      $dom.css('top', 0);
      $dom.css('left', 0);
      this.setState({button_src: "https://public.cureus.com/minimize-square-minus.png" })
    }else{
      $content.css('display','none');
      $nav.css('display','none');
      $dom.css('top', '90%');
      $dom.css('left', 0);
      this.setState({button_src: "https://public.cureus.com/maximize-square-plus.png"})
    }
  },

  render: function(){
    return (
      <div className="pull-right">
        <a onClick={ this.toggle }>
          <img src={ this.state.button_src }/>
        </a>
      </div>
    );
  }
});

export default MinMax;