import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from './Home';
import Articles from './Articles';
import Abstracts from './Abstracts';
import Posters from './Posters';
import Internal from './Internal';
import Meetings from './Meetings';
import About from './About/About';

const NavItems = ({admin, submitButtonColor, fetchInfoPath}) => {
  const [activeTab, setActiveTab] = useState('/')
  const [channelData, setChannelData] = useState(null);

  useEffect(() => {
    $.ajax({
      type: 'GET',
      url: fetchInfoPath,
      dataType: 'json',
      success: function(data) {
        setChannelData(data)
      }
    });
  }, [activeTab])

  if(!channelData) {
    return <div/>
  }

  const renderButtonColor = () => {
    return submitButtonColor ? submitButtonColor : '';
  }
  return (
    <div className="row">
      <div className="large-12 columns channel-main-nav">
        <div className="channel-main-nav-bar">
          <ul className="channel-main-nav-links">
          <Link to="/">
              <li className={"border " + (activeTab === '/' ? 'active' : '')} onClick={() => setActiveTab('/')}>
                HOME
              </li>
            </Link>
            {channelData.hasArticles &&
              <Link to="/articles" className={admin ? 'disabled' : ''}>
                <li className={"border " + (activeTab === 'articles' ? 'active' : '')} onClick={() => setActiveTab('articles')}>
                  ARTICLES
                </li>
              </Link>
            }
            {channelData.hasAbstracts &&
              <Link to="/abstracts" className={admin ? 'disabled' : ''}>
                <li className={"border " + (activeTab === 'abstracts' ? 'active' : '')} onClick={() => setActiveTab('abstracts')}>
                  ABSTRACTS
                </li>
              </Link>
            }
            {channelData.hasPosters &&
              <Link to="/posters" className={admin ? 'disabled' : ''}>
                <li className={"border " + (activeTab === 'posters' ? 'active' : '')} onClick={() => setActiveTab('posters')}>
                  POSTERS
                </li>
              </Link>
            }
            <Link to="/internal">
              <li className={"border " + (activeTab === 'internal' ? 'active' : '')} onClick={() => setActiveTab('internal')}>
                OTHER CONTENT
              </li>
            </Link>
            <Link to="/meetings">
              <li className={"border " + (activeTab === 'meetings' ? 'active' : '')} onClick={() => setActiveTab('meetings')}>
                MEETINGS
              </li>
            </Link>
            <Link to="/about">
              <li className={"border " + (activeTab === 'about' ? 'active' : '')} onClick={() => setActiveTab('about')}>
                ABOUT
              </li>
            </Link>
          </ul>
          <a id="channel-submitButton" className="button primary small submit-button" href={`/channels/${channelData.slug}`} style={{ background: renderButtonColor() }}>Submit your research</a>

        </div>
      </div>
        <Switch>
          <Route exact path='/'>
            <Home
              {...channelData}
            />
          </Route>
          <Route path='/articles'>
            <Articles
            />
          </Route>
          <Route path='/abstracts'>
            <Abstracts
            />
          </Route>
          <Route path='/posters'>
            <Posters
            />
          </Route>
          <Route path='/internal'>
            <Internal
              {...channelData}
            />
          </Route>
          <Route path='/meetings'>
            <Meetings
              {...channelData}
            />
          </Route>
          <Route path='/about'>
            <About
              {...channelData}
            />
          </Route>
        </Switch>
    </div>
  )

}

export default NavItems;