import {Cureus} from '../Cureus';

Cureus.ShowMoreTable = function(tableId, initialRows) {
  this.table = document.querySelector(tableId)
  this.triggerContainer = this.table.querySelector("#expand-trigger-wrap");
  
  if (!this.table) { return }
  
  this.tbody = this.table.querySelector("tbody")
  if (!this.tbody) { return }

  this.initialRows = initialRows;
  this._hideToggleIfTooFewRows();
  this._initiallySetVisibleTableRows()
  this._toggleContent();
}

Cureus.ShowMoreTable.prototype._hideToggleIfTooFewRows = function() {
  if (this.tbody.children.length <= this.initialRows) {
    this.table.querySelector("caption").style.display = "none";
  }
}

Cureus.ShowMoreTable.prototype._initiallySetVisibleTableRows = function() {
  var rows = this.tbody.children
  for (var i=this.initialRows; i < rows.length; i++) {
    rows[i].style.display = "none"
  }
}

Cureus.ShowMoreTable.prototype._toggleContent = function() {
  var self = this;
  self.triggerContainer.addEventListener("click", function() {
    self.toggleExpandText();
    self.openOrCloseTableContent();
  })
}

Cureus.ShowMoreTable.prototype.toggleExpandText = function() {
  var seeMoreLessText = this.triggerContainer.children
  for (var i=0; i< seeMoreLessText.length; i++){
    seeMoreLessText[i].toggle()
  }
}

Cureus.ShowMoreTable.prototype.openOrCloseTableContent = function() {
  var rows = this.tbody.children
  for (var i=this.initialRows; i < rows.length; i++) {
    var display = rows[i].style.display
    display = display == "" ? "none" : ""
    rows[i].style.display = display
  }
}