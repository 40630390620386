var createReactClass = require('create-react-class');
import React from 'react';
import ArticleStatsRow from './ArticleStatsRow';
import Modal from '../Modal';

const Row = createReactClass({
  getInitialState: function() {
    return {
      showArticleStats: false,
      articles: null,
      darkenRow: false,
      hover: false,
      sortCol: null
    }
  },

  closeModal: function() {
    Modal.close();
  },

  handleCursor: function() {
    if (this.props.currentUser.canEdit) {
      return (
        {cursor: 'pointer'}
      )
    } else {
      return (
        {cursor: 'default'}
      )
    }
  },

  handleRowClick: function(e, state) {
    var self = this;
    var url = "/admin/users/" + self.props.author.id + "/contents"
    if(state) {
      url = url + '?state=' + state
    }
    if (e.target.type === "checkbox") {return;}
    $.ajax({
      type: 'GET',
      url: url,
      data: {articleId: self.props.author.articleId},
      dataType: 'json',
      success: function(res) {
        self.setState({
          showArticleStats: !self.state.showArticleStats,
          articles: res.articles,
          darkenRow: !self.state.darkenRow,
        })
      },
      error: function(res) {
      }
    });
  },

  handleSort: function(col) {
    var sortedArticles;
    if (col === this.state.sortCol) {
      sortedArticles = this.state.articles.reverse()
    } else {
      sortedArticles = this.state.articles.sort(function(a, b) {
        var aVal = a[col];
        var bVal = b[col];
        if (aVal < bVal) { return -1; }
        if (aVal > bVal) { return 1; }
        return 0;
      });
    }
    this.setState({articles: sortedArticles, sortCol: col})
  },

  renderArticleHeaders: function() {
    if (this.state.showArticleStats && this.state.articles.length > 0) {
      return (
        <tr className="no-border-radius">
          <td className="article-stats-details" colSpan={10}>
            <div className="article-stats-container">
              <table>
                <tr className="article-stats-details-header">
                  <td className="headers small" onClick={() => this.handleSort('articleStatus')}>Current State</td>
                  <td className="headers small" onClick={() => this.handleSort('tier_sort')}>Tier</td>
                  <td className="headers small" onClick={() => this.handleSort('submitted_sort')}>Submitted</td>
                  <td className="headers small" onClick={() => this.handleSort('role')}>Role</td>
                  <td className="headers small" onClick={() => this.handleSort('type')}>Type</td>
                  <td className="headers title" onClick={() => this.handleSort('title')}>Article Title</td>
                </tr>
                {this.renderArticleStats()}
              </table>
            </div>
          </td>
        </tr>
      )
    } else {
      return (
          <tr className="no-users">
            <td className="article-stats-details centered" colSpan={9}>
              User has no articles.
            </td>
          </tr>
      )
    }
  },

  renderArticleStats: function() {
    var self = this;
    return self.state.articles.map(function(article) {
      return <ArticleStatsRow
        article={article}
        key={article.id} />
    })
  },

  renderApprovalEditor: function() {
    if(this.props.author.isApproval) {
      return(
        <i class="fas fa-id-badge"/>
      )
    }
  },

  renderMedStudent: function() {
    if(this.props.author.isMedStudent) {
      return (
        <i class="fas fa-user-graduate"/>
      )
    }
  },


  renderAngleAndAuthor: function() {
    var author = this.props.author
    if (!this.props.confirmed || this.props.flagged) {
      return (
        <td className={author.similar ? 'similar' : ''} onClick={(e) => this.handleRowClick(e)}>
          <i style={{cursor: 'pointer'}} className={"fa fa-lg " + `${this.renderAngleUpOrDownArrow()}`} aria-hidden="true"></i> &nbsp; &nbsp;
          <a href={author.userPath} target="_blank">{ author.full_name } {this.renderApprovalEditor()}{this.renderMedStudent()}</a> &nbsp; &nbsp;
          {!this.props.confirmed && <img style={{height: "10px"}} src={"https://public.cureus.com/unconfirmed-user-dot.png"} />}
          {this.props.flagged && <i className="fa-solid fa-flag"/>}
        </td>
      )
    } else {
      return(
        <td className={author.similar ? 'similar' : ''} onClick={(e) => this.handleRowClick(e)}>
          <i style={{cursor: 'pointer'}} className={"fa fa-lg " + `${this.renderAngleUpOrDownArrow()}` } aria-hidden="true"></i> &nbsp; &nbsp;
          <a href={author.userPath} target="_blank">{ author.full_name } {this.renderApprovalEditor()}{this.renderMedStudent()}</a>
        </td>
      )
    }
  },

  renderAngleUpOrDownArrow: function() {
    if(this.state.showArticleStats) {
      return "fa-angle-up";
    } else {
      return "fa-angle-down";
    }
  },

  handleRowStyle: function() {
    if (this.state.darkenRow) {
      return ({
        background: '#DDDDDD',
      })
    } else if (this.state.hover) {
      return ({ background: '#DDDDDD' })
    } else {
      return ({background: '#ECECEC' })
    }
  },

  handleHover: function() {
    this.setState({hover: !this.state.hover})
  },

  renderCheckbox: function() {
    if (this.props.checkedAllActive && this.props.confirmed) {
      return <></>
    } else if (!this.props.author.banned) {
      return <input className="td author-checks" onClick={(e) => this.props.updateCheckedState(e, this.props.author.id)} type="checkbox"/>
    }
  },

  render: function (){
    var self = this;
    var author = this.props.author
    return (
      <tbody>
        <tr className={"no-border-radius " + (author.banned ? 'strikeout' : '')} style={self.handleRowStyle()} onMouseEnter={self.handleHover} onMouseLeave={self.handleHover}>
          <td className="text-center" style={{paddingLeft: '10px'}} onClick={(e) => this.handleRowClick(e)}>
            {self.renderCheckbox()}
          </td>
          {self.renderAngleAndAuthor()}
          <td onClick={(e) => this.handleRowClick(e)}>
            <a href={"mailto:" + author.email}>{ author.email }</a>
          </td>
          <td className="centered" onClick={(e) => this.handleRowClick(e, 'in_progress_articles')}>
            <div className="nowrap">{ author.article_stats.in_progress }</div>
          </td>
          <td className="centered" onClick={(e) => this.handleRowClick(e, 'deferred_articles')}>
            <div className="nowrap">{ author.article_stats.deferred }</div>
          </td>
          <td className="centered" onClick={(e) => this.handleRowClick(e, 'desk_rejected_articles')}>
            <div className="nowrap">{ author.article_stats.desk_rejected }</div>
          </td>
          <td className="centered" onClick={(e) => this.handleRowClick(e, 'blocked_articles')}>
            <div className="nowrap">{ author.article_stats.blocked }</div>
          </td>
          <td className="centered" onClick={(e) => this.handleRowClick(e, 'published_articles')}>
            <div className="nowrap">{ author.article_stats.published }</div>
          </td>
        </tr>
        {self.state.showArticleStats ? self.renderArticleHeaders() : null}
      </tbody>
    )
  }
});

export default Row;
