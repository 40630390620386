import React, { useState } from 'react';

const UploadTermsModal = ({handleUploadCSV}) => {
  const [fileData, setFileData] = useState({})

  return (
    <div className="reveal-modal small unwanted-accounts-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Upload a List</h5>
          </div>
          <hr/>

          <div className="modal-content">
            <div className='modal-form'>
              <p>Important! Only CSV files are accepted. Please include word/phrase.</p>
              <input type="file" onChange={(e) => {setFileData(e.target.files[0])}  }/>

              <div className="form-actions text-center">
                <a className='cancel-modal button special'>CANCEL</a>
                <a className='cancel-modal button primary' onClick={() => {handleUploadCSV(fileData)}}>Add</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadTermsModal
