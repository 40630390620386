import React from 'react';
import { useState } from 'react';
import { Cureus } from '../../Cureus'

const AddToRefresh = ( { updateUrl, filters, } ) => {

  const [disabled, setDisabled] = useState(false);

  function handleAddToList(evt) {
    evt.preventDefault();
    setDisabled(true);
    $.ajax({
        type: "PUT",
        dataType: "json",
        url: updateUrl,
        data: { filters: filters, refresh: true }
      })
      .done(function (data, textStatus, jqXHR){
        window.scrollTo({top: 0});
        setDisabled(false);
        Cureus.Flash.success("Successfully added filter parameters for auto-refreshing.");
      })
      .error(function (jqXHR, textStatus, errorThrown){
        window.scrollTo({top: 0});
        setDisabled(false);
        Cureus.Flash.alert("There was an issue, please try later.");
      });
  }
 
  var disabledClass = disabled ? "disabled" : ""
  return (
    <React.Fragment>
      &nbsp;
      <a className={ `button primary ${disabledClass}` } onClick={handleAddToList}>Set Refresh</a>
    </React.Fragment>
  )

}

export default AddToRefresh;