var createReactClass = require('create-react-class');
import React from 'react';

const OAChecklistItem = createReactClass({
  getInitialState: function (){
    return {
      pass: false,
      fail: false
    }
  },

  componentDidUpdate: function(prevProps) {
    if(this.props.statFail === 'none') {
      return
    }
    if(prevProps.statFail !== this.props.statFail || (this.state.fail === false && this.state.pass === false)) {
      if(this.props.statFail) {
        this.setState({fail: this.props.statFail})
      } else {
        this.setState({pass: true})
      }
    }
  },

  handleSelection: function(e) {
    if(e.target.className.includes('pass') || e.target.className.includes('fail')) {
      return;
    }

    var name = e.target.getAttribute('name')
    
    if(!this.state.pass && !this.state.fail) {
      if(e.target.className.includes('times')) {
        this.setState({fail: true}, function() {
          this.props.handleCheck(name)
          this.props.buttonDisable()
        })
      } else {
        this.setState({pass: true}, function() {this.props.buttonDisable()})
      }
    } else {
      this.setState({pass: !this.state.pass, fail: !this.state.fail}, function() {
        this.props.handleCheck(name)
        this.props.buttonDisable()
      })
    }
  },

  renderCount: function() {
    if(this.props.count) {
      return <span className="count">({this.props.count})</span>
    }
  },

  render: function() {
    return (
      <div className="checklist-item row">
        <div className="large-8 columns title-container">
          <span>{this.props.title}</span>
        </div>
        <div className="large-3 columns">
          <i className={"fa fa-times-circle fa-1x " + (this.state.fail ? 'fail' : '')} name={this.props.score} onClick={this.handleSelection}/>
          <i className={"fa fa-check-circle fa-1x " + (this.state.pass ? 'pass' : '')} name={this.props.score * -1} onClick={this.handleSelection}/>
          {this.renderCount()}
        </div>
      </div>
    )
  }
})

export default OAChecklistItem;