var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../../Cureus';

const RemindSpecificModal = createReactClass({
  sendReminder: function(e) {
    var self = this;
    $.ajax(self.props.article.remindReviewersPath, {
      dataType: "JSON",
      data: {article_id: self.props.article.id, reviewershipTargetGroup: e.target.id},
      type: "PUT"
    }).done(function() {
      Cureus.Flash.success('Reminder emails were successfully sent to the reviewers who have not accepted or declined the review invitation.')
    }).always(function(res) {
      self.props.disableSendReminders(res.reviewershipTargetGroup)
      $('#remind-modal').foundation('reveal', 'close')
      $("html, body").animate({scrollTop: 0}, 1000);
    })
  },

  renderAuthorInvited: function() { 
    if (this.props.canSendReminders['author_invited']) {
      return (
        <div className="small-5 columns remind-box">
          <p className="invite-text">Remind {this.props.reviews.author_invited} author-invited reviewers</p>
          <input type="button" id="author_invited" className="button primary"  name="commit" value="Send" onClick={ this.sendReminder } />
        </div>
      )
    } else {
      if (this.props.article.authorReviewEnded) {
        return (
          <div className="small-5 columns remind-box">
            <p className="cd-text">Author-invited reviews have ended.</p>
            <input type="button" id="author_invited" className="button primary disabled" value="Send" />
          </div>
        )
      } else {
        return (
          <div className="small-5 columns remind-box">
            <p className="cd-text">Please wait {this.props.canSendReminders['author_cooldown'] || 48} hours before sending a new reminder.</p>
            <input type="button" id="author_invited" className="button primary disabled" value="Send" />
          </div>
        )
      }
    }
  },

  renderCureusInvited: function() {
    if (this.props.canSendReminders['cureus_invited']) {
      return (
        <div className="small-5 columns remind-box">
          <p className="invite-text">Remind {this.props.reviews.cureus_invited} Cureus-invited reviewers</p>
          <input type="button" id="cureus_invited" className="button primary"  name="commit" value="Send" onClick={ this.sendReminder } />
        </div>
      )
    } else {
      return (
        <div className="small-5 columns remind-box">
          <p className="cd-text">Please wait {this.props.canSendReminders['cureus_cooldown'] || 48} hours before sending a new reminder.</p>
          <input type="button" id="cureus_invited" className="button primary disabled" value="Send" />
        </div>
      )
    }
  },

  renderRemindBoxes: function() {
    return (
      <div className="row remind-box-container">
        {this.renderAuthorInvited()}
        {this.renderCureusInvited()}
    </div>
    )
  },


  render: function() {
    return (
      <div className="reveal-modal middling" id="remind-modal" data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Remind Invited Reviewers</h4>
            <div className="row">
              <p>{"You have " + this.props.reviews.author_invited +  " author-invited reviewers and " + this.props.reviews.cureus_invited + " Cureus-invited reviewers who have yet to accept or decline your review invitation. You can send up to three email reminders to each group. (Cureus sends additional reminder emails after three, seven and 14 days.)"}</p>
            </div>
            {this.renderRemindBoxes()}
          </div>
        </div>
      </div>
    )
  }
})

export default RemindSpecificModal;