var createReactClass = require('create-react-class');
import React from 'react';
import Transloadit from '../Transloadit';
import AttachmentMixin from '../AttachmentMixin';

const EditFile = createReactClass({
  mixins: [AttachmentMixin],

  handleDelete: function() {
    var self = this;

    if(window.confirm("Are you sure you want to delete this file?")) {
      $(self.refs.spin).spin();
      $.ajax({
        type: "DELETE",
        url: self.props.updateObjectFilePath,
        success: function(collection) {
          self.props.onUpdate(collection, true);

        }
      })
    }
  },

  renderProgressBarText: function() {
    if (this.state.finishedUploading) {
      return <p>Please wait, we are processing the figure...</p>
    }
  },

  render: function() {
    var preview_url = this.props.collection.preview_url; 
    if(this.state.errorMessage) {
      var errorMessage =
        <div className='alert-box alert'>
          { this.state.errorMessage }
        </div>
    }

    if(this.state.isUploading) {
      return (
        <div key='progressbar' className='cureus-progress progress-striped active collection-progress'>
          {this.renderProgressBarText()}
        <div ref='progressBar' className='bar'></div>
        </div>
      )
    } else {
      return (
        <div>
          { errorMessage }
          <div className='collection-upload-widget'>
            <div className='collection-upload-has-file'>
              <div ref='spin' className='collection-upload-preview-container'>
                <div className='row'>
                  <div className='large-3 columns'>
                    <div className='collection-upload-preview-window'>
                      <img src={ preview_url } />
                    </div>
                  </div>
                </div> 
              </div>
              <br/>
              <Transloadit
                transloaditTemplate={ this.props.collection.transloadit_template }
                handleFileSelect={ this.handleFileSelect }
                handleProgress={ this.handleProgress }
                handleSuccess={ this.handleSuccess }
                handleError={ this.handleError }
                fileName={ 'banner_image_file' }>
              </Transloadit>
              &nbsp;
              <a onClick={ this.handleDelete } className='button small'>
                Replace
              </a>
            </div>
          </div>
        </div>
      )
    }
  }
});

export default EditFile;