const AttachmentUploadMixin = {
  getInitialState: function() {
    return {
      isUploading: false,
      isAnimating: false,
      errorMessage: null
    }
  },

  handleFileSelect: function() {
    this.setState({errorMessage: null});
    this.setState({isUploading: true});
  },


  handleProgress: function(bytesReceived, bytesExpected) {
    var self = this;
    var $progressBar = $(".progressBar");
    var percentComplete = (bytesReceived * 100) / bytesExpected;

    self.setState({isAnimating: true});
    $progressBar.animate({width: percentComplete + "%"}, 3000, function() {
      self.setState({isAnimating: false, finishedUploading: true});
    });
  },

  handleSuccess: function(assembly) {
    var self = this;

    setTimeout(function() {
      if(!self.state.isAnimating) {
        self.updateAttachment(assembly);
      } else {
        self.handleSuccess(assembly);
      }
    }, 500);
  },

  handleError: function(error) {
    if(error.error == "AUTH_EXPIRED") {
      this.setErrorMessage("Please refresh the page, and try uploading again.");
    }
    else if (error.message == 'Wrong file type - must be PNG, JPG or TIFF file.' || error.message == 'Wrong size - must be at least 900 pixels wide. (1600 px recommended)' || error.error == 'MAX_SIZE_EXCEEDED') {
      this.setErrorMessage(error.message);
    } else {
      this.setErrorMessage("An error has occurred. Please try again.");
    }
    this.setState({isUploading: false});
  },

  setErrorMessage: function(message) {
    this.setState({errorMessage: message});
  },

  handleCloseErrorBox: function() {
    this.setState({errorMessage: null});
  },

  updateAttachment: function(assembly) {
    var self = this;
    var uploadedFile = assembly.uploads[0];
    // Parse the filename from the response - this is what actually got uploaded to s3
    var fileName = assembly.results.original[0].url.split('/').pop()
    var width = uploadedFile.meta.width;
    var height = uploadedFile.meta.height;

    var newAttachment = Object.assign({}, self.state.attachment)
    newAttachment["url"] = assembly['results']['lightbox'][0]['url']
    newAttachment["fileName"] = fileName;
    newAttachment["width"] = width;
    newAttachment["height"] = height;

    self.handleUploadComplete(newAttachment);
  }
}

export default AttachmentUploadMixin;