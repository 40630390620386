import React, { useState, useEffect } from 'react';

const Articles = ({}) => {

  // const [data, setData] = useState(null);

  useEffect(() => {
  }, [])

  return (
    <div className="admin-channel-design">
      holds articles
    </div>
  )
}

export default Articles;