import React from 'react';
import CompletedRow from './CompletedRow';
const DualCompleted = ({data, type, editorView}) => {

  const renderCopyActions = () => {
    if(data.showCopy || type === 'dual') {
      return (
        <React.Fragment>
          <CompletedRow 
            label="Approved"
            value={data.approved}
            progress={(data.approved/data.total) * 100}
            editorView={editorView}
          />
          <CompletedRow 
            label="Deferred"
            value={data.deferred}
            progress={(data.deferred/data.total) * 100}
            editorView={editorView}
          />
        </React.Fragment>
        
      )
    }
  }

  const renderSubmissionsActions = () => {
    if(data.showSubmissions || type === 'dual') {
      return (
        <React.Fragment>
          <CompletedRow 
            label="Free"
            value={data.free}
            progress={(data.free/data.total) * 100}
            editorView={editorView}
          />
          <CompletedRow 
            label="Deferred for PE"
            value={data.preferred}
            progress={(data.preferred/data.total) * 100}
            editorView={editorView}
          />
          <CompletedRow 
            label="Deferred for 3rd party"
            value={data.professional}
            progress={(data.professional/data.total) * 100}
            editorView={editorView}
          />
          <CompletedRow 
            label="Deferred for content"
            value={data.content}
            progress={(data.content/data.total) * 100}
            editorView={editorView}
          />
          <CompletedRow 
            label="Desk rejected"
            value={data.desk}
            progress={(data.desk/data.total) * 100}
            editorView={editorView}
          />
          <CompletedRow 
            label="Blocked"
            value={data.blocked}
            progress={(data.blocked/data.total) * 100}
            editorView={editorView}
          />
        </React.Fragment>
      )
    }
  }

  return (
    <div className="completed-container">
      <div className="row">
        <div className="small-12 columns">
          <div className="small-1 columns icon-container">
            <span>
              <i className="fas fa-tasks background"/>
            </span>
          </div>
          <div className="small-11 columns header">
            <h5>Actions performed on {data.total - data.deferred} articles</h5>
            <p>This total may be higher than the total displayed in the goals section if a second completion event occurred for an article after the selected timeframe. For example, if you defer an article for third party editing (initial completion event) on July 29th and the article is resubmitted and you approve it for free (or defer it for PE) on August 2nd, the second completion event will be displayed here, but not in the goal section, as it already was counted for July.</p>
          </div>
        </div>
      </div>
      {renderSubmissionsActions()}
      {renderCopyActions()}
    </div>
  )
}

export default DualCompleted;