import React from 'react';
import Modal from '../Modal';

const GuestEditorModal = ({notifications}) => {

  const closeModal = () => {
    Modal.close();
  }

  const renderPluralization = () => {
    if (notifications.length === 1) {
      return `You have ${notifications.length} article to review.`;
    } else {
      return `You have ${notifications.length} articles to review.`;
    }
  }

  return (
    <div data-reveal className='reveal-modal' id='guest-editor-alert-modal'>
      <div className='modal-body'>
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
      </div>
      <div className='modal-content'>
        <div className='image'>
          <img src="https://public.cureus.com/bell-alerts/guest-editor-alert-modal.png" />
        </div>
        <h4 className='title'>{notifications[0].title}</h4>
        <div className='message'>
          <p>{renderPluralization()}</p>
        </div>
        <a href='/dashboard/my_collections#!/pending_articles' className='button primary' onClick={closeModal}>{notifications[0].btn_text}</a>
      </div>
    </div>
  )
}

export default GuestEditorModal;