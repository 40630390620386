import {Cureus} from '../../Cureus';

Cureus.SplitButton = (function ($) {
  "use strict";
  var SplitButton, DROPDOWN_OFFSET,
    init;

  DROPDOWN_OFFSET = 4;

  init = function (){
    $("a[data-cureus-split-dropdown]").each(function (index, element){
      new SplitButton($(element));
    });
  };

  SplitButton = function ($button){
    var dropdown_id, $dropdown, $pulldown_tag;

    dropdown_id = $button.data("cureus-split-dropdown");
    $dropdown = $("#"+dropdown_id);
    $pulldown_tag = $button.find("span");

    function closeAllDropdowns(){
      $(".cureus-split-dropdown.active").removeClass("active");
    }

    function handleSplitButtonClickAway(evt){
      $("body").off("click", handleSplitButtonClickAway); // unbind self
      $dropdown.removeClass("active"); // close the dropdown
    }

    function handleSplitButtonClicked(evt){
      evt.preventDefault();
      evt.stopPropagation();
      setPosition();

      if($dropdown.hasClass("active")) {
        handleSplitButtonClickAway();
      } else {
        closeAllDropdowns();
        $dropdown.addClass("active");
        $("body").on("click", handleSplitButtonClickAway);
      }
    }

    function setPosition() {
      $dropdown.css("position", "absolute");
      $dropdown.css("left", $pulldown_tag.parent().position().left + $pulldown_tag.parent().outerWidth() - $dropdown.outerWidth() - 1);
      $dropdown.css("top", ($button.outerHeight()+DROPDOWN_OFFSET) + 'px');
    }

    function unbindAll(){
      $dropdown.off();
      $pulldown_tag.off();
    }

    return (function (){
      unbindAll();
      $pulldown_tag.on("click", handleSplitButtonClicked);
    })();
  };

  $(function (){
    init();
  });

  return {init: init};
})(jQuery);
