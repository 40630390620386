import React, {useEffect} from 'react';

const ArticleCard = ({cardDetails, index, visibleIndex, fireAmplitude, setVisibleCardType}) => {

  useEffect(() => {
    if (index == visibleIndex) {
      setVisibleCardType("article")
    }
  })

  const renderImage = () => {
    return <img src={cardDetails.preview_image ||cardDetails.large_card_image || cardDetails.article_image_large} />
  }

  return (
    <div className={`row featured-content-card ${index == visibleIndex ? '' : 'hide-card'}`}>
      <div className="columns hide-for-small-only medium-6 col-6-image">
        {renderImage()}
      </div>
      <div className="hide-for-medium-up">
        <img className="small-screen-image" src={cardDetails.preview_image || cardDetails.large_card_image  || cardDetails.article_image} />
      </div>
      <div className="columns small-12 medium-6 card-info">

        <div className="article-header">
          <span className="left">{cardDetails.published_at}</span>
          <span className="right">{cardDetails.article_type}</span>
        </div>

        <p className="article-title">{cardDetails.article_title}</p>
        <p className="article-authors">{cardDetails.article_authors}</p>
        <p className="ck-editor-content" dangerouslySetInnerHTML={{__html: cardDetails.description}}></p>
        <a className="read-more" href={"/articles/" + cardDetails.article_id} onClick={() => fireAmplitude('article', ("/articles/" + cardDetails.article_id))}>
          Read More
          <i className="fas fa-arrow-right"></i>
        </a>
      </div>
    </div>
  )
}

export default ArticleCard;