var createReactClass = require('create-react-class');
import React from 'react';
import Main from '../CampaignSections/Main';
import Inside from './Inside';

const NewsroomMain = createReactClass({
  getInitialState: function() {
    return {
      title: this.props.title,
      contentType: this.props.contentType,
      state: this.props.state,
      thumbnailImage: this.props.thumbnailImage,
      heroImage: this.props.heroImage,
      publishedAt: this.props.publishedAt,
      campaignLink: this.props.campaignLink,
      introMessage: this.props.introMessage,
      imageFile: null,
      heroFile: null,
      insides: this.props.insides,
      disabled: "",
      carousel: this.props.carousel,
      cta_button_text: this.props.cta_button_text,
      cta_button_url: this.props.cta_button_url
    }
  },

  handleChange: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  updateNewsroomPost: function() {
    var self = this;
    var formFile = self.createFormData(self.state.imageFile, self.state.heroFile)
    formFile = this.formatPayload(formFile)
    fetch(self.props.updatePath, {
      method: "PUT",
      body: formFile,
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $("[name='csrf-token']").attr("content")
      }
    }).then(function(response) {return response.json()}).then(function(res) {
      if(self.state.preview) {
        var win = window.open(self.props.previewPath, '_blank');
        win.focus();
      }
      var newState = {...res.newsroomPost, ...{disabled: ''}, ...{preview: false}, ...{imageFile: null}, ...{heroFile: null}}
      self.setState(newState)
    })
  },

  handleSave: function() {
    var self = this;
    self.setState({disabled: 'disabled'})
    if(self.state.contentType === 'inside') {
      var ckeditor = CKEDITOR.instances['campaign_inside_intro_message']
      var data = ckeditor.getData()
      this.setState({introMessage: data}, function() {
        self.updateNewsroomPost()
      })
    } else {
      self.updateNewsroomPost()
    }
  },

  handlePreview: function() {
    this.setState({preview: true}, function() {
      this.handleSave()
    })
  },

  handleFileUpload: function(e) {
    this.setState({imageFile: e.target.files[0]})
  },

  handleHeroUpload: function(e) {
    this.setState({heroFile: e.target.files[0]})
  },

  createFormData: function(file, hero) {
    var formFile = new FormData()
    if (file) {
      formFile.append("newsroom_post[thumbnail_image]", file)
    }
    if (hero) {
      formFile.append("newsroom_post[hero_image]", hero)
    }
    return formFile
  },

  formatPayload: function(formFile) {
    var self = this;
    Object.keys(self.state).forEach(function(key) {
      if(key === 'insides') {
        formFile.append(`newsroom_post[${key}]`, JSON.stringify(self.state[key]))
      } else {
        formFile.append(`newsroom_post[${key}]`, self.state[key])
      }
    })
    formFile.delete("newsroom_post[sectionData]")
    formFile.delete("newsroom_post[imageFile]")
    formFile.delete("newsroom_post[heroFile]")
    formFile.delete("newsroom_post[disabled]")
    formFile.delete("newsroom_post[addInsidePath]")
    formFile.delete("newsroom_post[newsroomPostId]")
    formFile.delete("newsroom_post[cancelPath]")
    formFile.delete("newsroom_post[previewPath]")
    return formFile;
  },

  addInside: function() {
    var self = this;
    $.ajax({
      type: "POST",
      url: self.props.addInsidePath,
      data: {insides: self.state.insides.length},
      success: function(res) {
        var newInsides = self.state.insides
        newInsides.forEach((x, idx) => {
          x.position = idx+2
        })
        newInsides.unshift(res.inside)
        self.setState({insides: newInsides})
      }
    })
  },

  destroyInside: function(e) {
    var self = this;
    var insideId = e.target.name;
    var path = '/admin/newsroom_posts/'  +  self.props.newsroomPostId  + '/insides/' + insideId
    $.ajax({
      url: path,
      type: "DELETE",
      success: function() {
        var deletedPosition;
        var newInsides = self.state.insides.filter(function(inside) {
          if (inside.id === parseInt(insideId)) {
            deletedPosition = inside.position
          }
          else return inside.id !== parseInt(insideId)
        })
        newInsides.forEach(function(inside) {
          if (inside.position > deletedPosition) {
            inside.position -= 1;
          }
        })
        self.setState({insides: newInsides})
      }
    })
  },

  handleInsidesChange: function(e) {
    var newInsides = this.state.insides
    var position = parseInt(e.target.name) - 1
    newInsides[position][e.target.getAttribute('field')] = e.target.value
    this.setState({insides: newInsides})
  },

  handleCancel: function() {
    window.location = this.props.cancelPath
  },

  renderImage: function() {
    if(this.state.thumbnailImage) {
      return (
      <div className="row">
        <label>Current image:</label>
        <div dangerouslySetInnerHTML= {{ __html: this.state.thumbnailImage }} />
        <br/>
      </div>
      )
    }
  },

  renderContents: function() {
    if(this.state.contentType === 'news') {
      return (
        <Main 
          newsroom={true}
          sections={this.props.sectionData.sections}
          campaignId={this.props.sectionData.newsroomId}
          addSectionPath={this.props.sectionData.addSectionPath}
        />
      )
    } else if(this.state.contentType === 'newsletter') {
      return (
        <div className="row">
          <label>
            Newsletter Campaign Link
            <input type="text" name="campaignLink" onChange={(e) => this.handleChange(e)} value={this.state.campaignLink}/>
          </label>
        </div>
      )
    } else {
      return (
        <Inside 
          heroImage={this.state.heroImage}
          handleHeroUpload={this.handleHeroUpload}
          introMessage={this.state.introMessage}
          insides={this.state.insides}
          addInside={this.addInside}
          destroyInside={this.destroyInside}
          handleInsidesChange={this.handleInsidesChange}
        />
      )
    }
  },

  renderCta: function() {
    if (this.state.contentType == 'news') {
      return (
        <React.Fragment>
          <div className="row">
            <label style={{marginTop: "10px"}}>Call to Action Button Text</label>
            <input type="text" name="cta_button_text" onChange={(e) => this.handleChange(e)} value={this.state.cta_button_text}/>
          </div>
          <div className="row">
            <label>Call to Action Button URL</label>
            <input type="text" name="cta_button_url" onChange={(e) => this.handleChange(e)} value={this.state.cta_button_url}/>
          </div>
        </React.Fragment>
      )
    }
  },

  render: function() {
    return (
      <div className="row">
        <div className="large-6 columns left-column">
          <h3 className="reg">Setup</h3>
          <div className="row">
            <label>
              Title
              <input type="text" name="title" onChange={(e) => this.handleChange(e)} value={this.state.title}/>
            </label>
          </div>
          <div className="row">
            <label>
              Content Type
              <select name="contentType" onChange={(e) => this.handleChange(e)} value={this.state.contentType}>
                <option value="news">News</option>
                <option value="newsletter">Newsletter</option>
                <option value="inside">Cureus U</option>
              </select>
            </label>
          </div>
          <div className="row">
            <label>
              Thumbnail Image
              <input type="file" onChange={(e) => this.handleFileUpload(e)}
          />
            </label>
          </div>
          {this.renderImage()}
          <div className="row">
            <label>State</label>
            <label className="position-radio">
              <input type="radio" name="state" value="draft" checked={this.state.state === 'draft'} onChange={(e) => this.handleChange(e)}/>
              Draft
            </label>
            <label className="position-radio">
              <input type="radio" name="state" value="published" checked={this.state.state === 'published'} onChange={(e) => this.handleChange(e)}/>
              Published
            </label>
          </div>
          <div className="row">
           <label>
              Carousel Location (Blank if not included):
              <input className="carousel" type="text" name="carousel" value={this.state.carousel} onChange={(e) => this.handleChange(e)}/>
            </label>
          </div>
          {this.renderCta()}
        </div>
        <div className="large-6 columns">
          <h3 className="reg">Content</h3>
          {this.renderContents()}
        </div>
        <div className="right">
          <a className="button secondary cancel-button" onClick={this.handleCancel}>Cancel</a>
          <a className={"button special cancel-button " + this.state.disabled} onClick={this.handlePreview}>Preview</a>
          <a className={"button primary " + this.state.disabled} onClick={this.handleSave}>Save</a>
        </div>
      </div>
    )
  }
});

export default NewsroomMain;