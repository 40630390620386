import React, {useEffect} from 'react';
import Categories from '../../../Shared/Categories';
import DeleteButton from '../../../ChannelDesign/DeleteButton';
import UpdateButton from '../../../ChannelDesign/UpdateButton';

const Channel100Card = ({content, handleDeleteWidget, handleUpdateWidget, mainContentWidgets, slug}) => {
  return (
    <div className="hundred-percent-section featured-content-card">
      <div className="large-12 columns hundred-percent-container">

        {handleDeleteWidget && <><img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
        <UpdateButton
          handleUpdateWidget={handleUpdateWidget}
          widget={content}
          modalName={"CustomContentModal"}
          mainContentWidgets={mainContentWidgets}
          slug={slug}
        />
        <DeleteButton
          handleDeleteWidget={handleDeleteWidget}
          widgetId={content.widgetId}
          className=""
        /></>}
        <div className="columns hide-for-small-only medium-6 col-6-image no-pad">
          <img src={content.content_image_large} />
        </div>
        <div className="hide-for-medium-up no-pad">
          <img className="small-screen-image" src={content.content_image} />
        </div>
        <div className="columns small-12 medium-6 card-info">

          <div className="row">
            <div className="content-header">
              <span className="left widget-font header-color">{content.published_at}</span>
              <span className="right widget-font header-color">{content.content_type}</span>
            </div>
          </div>

          <div className="row content-body title">
            <a href={content.path} target="_blank">
              <h4 className="content-title">{content.content_title}</h4>
            </a>
            <p className="content-authors widget-font">{content.content_authors}</p>
            <Categories categories={content.categories} resourceId={content.id}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Channel100Card;