import React, { useState, useEffect } from 'react';
import HighlightPop from './HighlightPop';
import { Cureus } from '../../Cureus';

const ArticleTab = ({html, preview, page, articleId, fireAmplitude, currentUserId}) => {

  useEffect(() => {
    document.querySelectorAll('.attachment-anchor').forEach(function(element) {
      element.addEventListener('click', function() {
        const y = document.getElementById(element.dataset.anchor).getBoundingClientRect().top + window.pageYOffset - 70;
        window.scrollTo({top: y, left: 0, behavior: 'smooth'});
      })
    })
    let refs = document.getElementById('references')
    document.querySelectorAll('.refs-anchor').forEach(function(element) {
      element.addEventListener('click', function() {
        const y = refs.getBoundingClientRect().top + window.pageYOffset - 70;
        window.scrollTo({top: y, left: 0, behavior: 'smooth'});
      })
    })

  new Cureus.LazyLoader.init({selector:'img[data-lazy]'});
    Cureus.MediaLightbox.init({
      items: $(".gallery-wrap .gallery-item")
    });
  }, []);

  const handleHighlightComment = (text) => {
    if(currentUserId) {
      let commentTextarea = document.getElementById('comment_body')
      commentTextarea.focus()
      let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value").set;
      nativeInputValueSetter.call(commentTextarea, `"${text}"`);
      let ev2 = new Event('input', { bubbles: true});
      commentTextarea.dispatchEvent(ev2);
      document.getElementById('comments_list').scrollIntoView()
      fireAmplitude('articleHighlighted', {article_id: articleId, type: 'comment'})
    } else {
      $('#sign-in-modal').foundation('reveal', 'open');
    }
  }
  
  if(!preview && page === 'article') {
    return (
      <div className="row">
        <div className="small-12 columns" style={{position: 'inherit'}}>
          <div className="tab-content-container article">
            <HighlightPop
              popoverItems={(itemClass, text) => (
                <span className={itemClass}>
                  <div className="popover-icon-container left-icon">
                    <a onClick={() => handleHighlightComment(text)}><i className="far fa-comment-dots"/></a>
                  </div>
                  <div className="popover-icon-container">
                    <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(text + " @cureusinc")}`}target="_blank" onClick={() => fireAmplitude('articleHighlighted', {article_id: articleId, type: 'twitter'})}><i className="fa-brands fa-x-twitter"/></a>
                  </div>
                </span>
              )}
            >
              <div dangerouslySetInnerHTML={{__html: html}}/>
            </HighlightPop>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="row">
        <div className="small-12 columns" style={{position: 'inherit', marginBottom: '70px'}}>
          <div className="tab-content-container">
            <div dangerouslySetInnerHTML={{__html: html}}/>
          </div>
        </div>
      </div>
    )
  }
}

export default ArticleTab;