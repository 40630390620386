import React, { useState, useEffect } from 'react';

const NoArticlesDisplay = () => {
  return (
    <div className="row widget" style={{display: 'flex'}}>
      <div className="small-6 columns">
        <img src='https://public.cureus.com/channel_design/no-articles.png' style={{width: '400px'}}/>
      </div>
      <div className="small-6 columns text-center" style={{display: "flex", alignItems: "center"}}>
        <h1>New articles will be published soon!</h1>
      </div>
    </div>
  )
}

export default NoArticlesDisplay