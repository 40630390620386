import React, { useState, useEffect } from 'react';
import Card from './Card';
import Tab from './Tab';
import SearchBar from '../Shared/SearchBar/SearchBar';

const Index = () => {

  const [channels, setChannels] = useState([])
  const [type, setType] = useState('')
  const [term, setTerm] = useState('')

  useEffect(() => {
    fetchChannels();
  }, [type, term])

  const fetchChannels = () => {
    $.ajax({
      type: 'GET',
      url: '/channels/all',
      dataType: 'json',
      data: {
        type: type,
        searchTerm: term,
      },
      success: function(res) {
        setChannels(res.cards);
      }
    });
  }

  return (
    <div className='row all-channels-container'>
      <div className="top-section-container large-11 medium-11 small-12 columns small-centered">
        <h4 className='all-channels-heading'>CUREUS CHANNELS</h4>
        <h1 className='all-channels-title'>Shining a spotlight on dozens of leading societies, universities, and hospitals</h1>
        <div className='all-channels-tab-holder'>
          <Tab
            src="https://public.cureus.com/icons/elements.png"
            srcActive="https://public.cureus.com/icons/elements-blue.png"
            type=""
            title="All"
            activeTab={type}
            setType={setType}
          />
          <Tab
            src="https://public.cureus.com/channels_page/academic-council.png"
            srcActive="https://public.cureus.com/channels_page/academic-council-blue.png"
            type="Channel::AcademicChannel"
            title="Academic"
            activeTab={type}
            setType={setType}
          />
          <Tab
            src="https://public.cureus.com/channels_page/editorial-board.png"
            srcActive="https://public.cureus.com/channels_page/editorial-board-blue.png"
            type="Channel::ProfessionalSocietyChannel"
            title="Professional Societies"
            activeTab={type}
            setType={setType}
          />
        </div>
      </div>
      <div className="row small-collapse">
        <div className='small-12 columns'>
          <SearchBar url={'/channels/all'} placeHolder = {'Search for a specific channel here.'} isFilterMenuOpen={false} setIsFilterMenuOpen={{}} setCards={setChannels} page={"channels"} isFilterRequired={false} />
        </div>
      </div>
      <div className="row small-collapse">
        <div className="small-12 columns">
          <div className='all-channels-grid'>
            {channels && channels.map((channel)=> {
            return (
                    <Card channel={channel} key={channel.id}/>
            )})}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index;