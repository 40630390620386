var createReactClass = require('create-react-class');
import React from 'react';
import PropTypes from 'prop-types';

const UpdateStatsButton = createReactClass({
  propTypes: {
    updateStatsPath: PropTypes.string.isRequired,
    ids: PropTypes.array.isRequired
  },
  
  getInitialState: function() {
    return {
      disabled: ""
    }
  },

  unCheck(id){
    var el = `check-box-${id}`
    var checkbox = document.getElementById(el)
    checkbox.checked = false
 },

  handleUpdateStats: function() {
    var self = this;
    self.setState({disabled: "disabled"})
    $.ajax(self.props.updateStatsPath, {
      data: {statable_ids: self.props.ids},
      type: "GET",
      success: function(){
        self.props.onUpdate();
        self.props.ids.forEach(function(id) {
          self.unCheck(id);
        })
        self.setState({disabled: ""})
      }
    });
  },
  
  render: function() {
    if (this.props.ids.length > 0 ) {
      return (
        <a onClick={this.handleUpdateStats} className="button special" disabled={this.state.disabled}>UPDATE STATS</a>
      )
    }  else {
      return <div></div>;
    }
  }
})

export default UpdateStatsButton;