var createReactClass = require('create-react-class');
import React from 'react';
import AddDisclosureModal from './AddDisclosureModal';
import RelationshipRow from './RelationshipRow';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const RelationshipRows = createReactClass({
  getInitialState: function() {
    return {
      financialRelationships: [],
      rowsAvailable: false,
      tableErrors: []
    }
  },

  componentDidMount: function() {
    this.getData();
    new Cureus.SimpleRadioToggler(this.props.declared, true)
  },

  getData: function() {
    var self = this;
    $.ajax({
      type: 'get',
      url: '/publish/articles/' + self.props.articleId + '/financial_relationships/fetch',
      data: {disclosure_id: self.props.disclosureId},
      success: function(data) {
        self.setState(data)
      },
      error: self.addTableErrors
    })
  },

  handleNextButton: function() {
    if (this.state.financialRelationships.length) {
      $("[data-button-type=next]").attr('disabled', false);
    } else if ($(".financial-relationship-radio-true").is(':checked')) {
      $("[data-button-type=next]").attr('disabled', true);
    }
  },

  mapRows: function() {
    var self = this;
    return self.state.financialRelationships.map(function(fr) {
      return <RelationshipRow
        coiTypes={fr.coi_types}
        id={fr.id}
        key={fr.id}
        relationInfo={fr}
        articleId={self.props.articleId}
        getData={self.getData}
        clearErrors={self.clearErrors}
      />
    })
  },

  addTableErrors: function() {
    this.setState({
      tableErrors: ["We're sorry something went wrong. The engineers have been notified. Please try your request later."]
    })
  },

  clearErrors: function() {
    this.setState({
      tableErrors: []
    })
  },

  addDisclosure: function(e) {
    e.preventDefault();
    var self = this;
    self.clearErrors();
    Modal.open({
      children: <AddDisclosureModal
                    articleId={self.props.articleId}
                    disclosureId={self.props.disclosureId}
                    action='create'
                    getData={self.getData}
                  />
    });
  },

  renderErrors:function() {
    if (this.state.tableErrors.length) {
      return(
        <div className='table-errors errors'>{this.state.tableErrors.join(' ')}</div>
      )
    }
  },

  render: function() {
    this.handleNextButton();
    return(
      <div data-toggle-field='true'>
        <div id='financial_relationships'>
          <small>Tip: Use the Add Disclosure button for each activity to disclose.</small>
          <div id='financial_relationships_manager'>
            { this.renderErrors() }
            <table>
              <thead>
                <tr>
                  <th className='financial-relationship-institution'>Institution/Company</th>
                  <th className='financial-relationship-authors'>Author/s</th>
                  <th className='financial-relationship-COI'>COI Type</th>
                  <th className='financial-relationship-comments'>Comments</th>
                  <th className='financial-relationship-action'></th>
                </tr>
              </thead>
              <tbody>
                <tr className='financial-relationship-default-row' style={{display: (this.state.rowsAvailable ? 'none' : 'table-row')}}>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
                { this.mapRows() }
              </tbody>
            </table>
          </div>
        </div>
        <div className='pull-left'>
          <a className='add-button small button' onClick={this.addDisclosure} data-alert-exception='true'>Add Disclosure</a>
        </div>
        <div className='clearfix'></div>
      </div>
    )
  }
})

export default RelationshipRows;