var createReactClass = require('create-react-class');
import React from 'react';
import SocialBoost from './SocialBoost';
import PreferredEditingModal from './PreferredEditingModal';
import ExpeditedPmcModal from './ExpeditedPmcModal';
import Payment from './Payment';
import PreferredLandingPage from './PreferredLandingPage';
import SocialBoostLandingPage from './SocialBoostLandingPage';
import { Cureus } from '../../Cureus';

const Main = createReactClass({
  getInitialState: function() {
    return({
      loading: true,
      social_boost: null,
      twitter: null,
      instagram: null,
      aff_twitter: null,
      aff_instagram: null,
      custom_receipt: null,
      laurOrSum: false,
      bundle: false,
      emails: [],
      email: "",
      payer_email: "",
      payer_name: "",
      errors: {}
    })
  },

  componentDidMount: function() {
    // Get cost data here
    if(this.props.product === 'social_boost') {
      return this.setState({loading: false, social_boost: {price_cents: this.props.price, price_paid_cents: this.props.price}})
    }
    var self = this
    $.ajax({
      type: 'post',
      url: '/price',
      dataType: 'JSON',
      data: {
        product: this.props.product,
        articleId: this.props.articleId,
        optional: this.props.optional
      },
      success: function(res) {
        self.setState({loading: false, pricingDetails: res, laurOrSum: res.laurOrSum })
      },
      error: function(res) {
        self.setState({loading: false}, () => {
          $('#payment-modal').foundation('reveal', 'close')
          var response = JSON.parse(res)
          Cureus.Flash.alert(response.message)
        })
      }
    })
  },

  handleChange: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  handleExpeditedPurchase: function(type) {
    var bundle = type === 'bundle'
    this.setState({bundle: bundle}, function() {
      this.redirectToCheckout()
    })
  },

  handleBoostPurchase: function(e) {
    let price = parseInt(e.target.dataset.price) * 100
    this.setState({social_boost: {price_cents: price, price_paid_cents: price}}, function() {
      this.redirectToCheckout()
    })
  },


  redirectToCheckout: function() {
    if(this.props.paymentsPage && (this.state.payer_name.length === 0 || this.state.payer_email.length === 0)) {
      this.setState({errors: {payer_name: this.state.payer_name.length === 0 , payer_email: this.state.payer_email.length === 0 }})
      return;
    }
    var contributionLevel = this.state.pricingDetails ? this.state.pricingDetails.level : null;
    $.ajax({
      type: 'post',
      url: '/stripe/create_session',
      dataType: 'JSON',
      data: {
        product: this.props.product,
        articleId: this.props.articleId,
        optional: this.props.optional,
        source: this.props.source,
        social_boost: this.state.social_boost,
        twitter: this.state.twitter,
        instagram: this.state.instagram,
        aff_twitter: this.state.aff_twitter,
        aff_instagram: this.state.aff_instagram,
        custom_receipt: this.state.custom_receipt,
        ampSource: this.props.ampSource,
        bundle: this.state.bundle,
        emails: JSON.stringify(this.state.emails),
        payer_email: this.state.payer_email,
        payer_name: this.state.payer_name,
        contribution_level: contributionLevel
      },
      success: function(res) {
        var stripe = Stripe(res.stripeKey)
        stripe.redirectToCheckout({
          sessionId: res.sessionId
        })
      },
      error: function(res) {
      }
    })
  },

  renderEmailsList: function() {
    var self = this
    var emails = self.state.emails.map(function(email) {
      return (
        <li>
          {email}
        </li>
      )
    })
    if (emails.length > 0) {
      return (
        <ul>
          {emails}
        </ul>
      )
    }
  },

  handleFormUpdate: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  renderEmailsForm: function() {
    return (
      <div>
        <h5>Who should receive the receipt? (optional)</h5>
        <p>A receipt will be sent to you and the article’s submitting author via email. Please enter any additional email recipients below:</p>
        {this.renderEmailsList()}
        <div className="row payment-form-section">
          <div className="small-6 columns no-padding-left">
            <input type='email' placeholder="Email address" name="email" onChange={this.handleFormUpdate} value={this.state.email}/>
          </div>
          <div className="small-2 columns end">
            <a style={{marginBottom: 0}} className="button secondary" onClick={this.handleEmailAdd}>Add</a>
          </div>
        </div>
      </div>
    )
  },

  renderPayerForm: function() {
    var payerNameErrors;
    var payerEmailErrors;
    if(this.state.errors.payer_name) {
      payerNameErrors = <div className="errors">This field is required.</div>
    }
    if(this.state.errors.payer_email) {
      payerEmailErrors = <div className="errors">This field is required.</div>
    }
    return (
      <div className="row payment-form-section">
        <h5>Please enter your name and email address to ensure the information is displayed correctly on the receipt.</h5>
        <div className="small-3 columns no-padding-left">
          <input className={this.state.errors.payer_name ? 'error' : ''}type='text' name="payer_name" placeholder="Full name" onChange={this.handleFormUpdate}/>
          {payerNameErrors}
        </div>
        <div className="small-3 columns end">
          <input className={this.state.errors.payer_email ? 'error' : ''} type='email' name="payer_email" placeholder="Email address" onChange={this.handleFormUpdate}/>
          {payerEmailErrors}
        </div>
      </div>
    )
  },

  renderCustomReceiptForm: function() {
    return (
      <div className="row payment-form-section">
        <h5>Do you need to customize your receipt? (optional)</h5>
        <p>Your emailed receipt will contain the following information: service purchased, article title, order number, order date, payment type, total paid. Please enter any additional information needed in order to receive reimbursement from your institution (if provided). <strong><u>This information will appear in your receipt email exactly as entered below.</u></strong></p>
        <textarea name="custom_receipt" onChange={this.handleChange}/>
      </div>
    )
  },

  handleEmailAdd: function() {
    var emails = this.state.emails
    emails.push(this.state.email)
    this.setState({emails: emails, email: ""})
  },

  renderModalContent: function() {
    if (this.state.loading) {
      return this.renderSpinner();
    }
    if (this.props.product === 'preferred_editing') {
      if(this.props.paymentsPage) {
        return (
          <PreferredLandingPage
            renderPricingInfo={this.renderPricingInfo}
            articleId={this.props.articleId}
            handleChange={this.handleChange}
            title={this.props.title}
            renderLandingPageCopy={this.renderLandingPageCopy}
          />
        )
      } else {
        return <PreferredEditingModal
        renderPricingInfo={this.renderPricingInfo}
        optional={this.props.optional}
        source={this.props.source}
        articleId={this.props.articleId}
        handleChange={this.handleChange}
        title={this.props.title}
        />
      }
    }
    if (this.props.product === 'expedited_pmc') {
      return <ExpeditedPmcModal
        renderPricingInfo={this.renderPricingInfo}
        source={this.props.source}
        articleId={this.props.articleId}
        laurOrSum={this.state.laurOrSum}
        singlePrice={this.state.pricingDetails.price_cents/100}
        bundlePrice={this.state.pricingDetails.bundle_price_cents/100}
        handleExpeditedPurchase={this.handleExpeditedPurchase}
        title={this.props.title}
        renderLandingPageCopy={this.renderLandingPageCopy}
        paymentsPage={this.props.paymentsPage}
      />
    }
    if (this.props.product === 'social_boost') {
      if(this.props.paymentsPage) {
        return (
          <SocialBoostLandingPage
            articleId={this.props.articleId}
            handleChange={this.handleChange}
            title={this.props.title}
            handleBoostPurchase={this.handleBoostPurchase}
            renderLandingPageCopy={this.renderLandingPageCopy}
            subsequentBoost={this.props.subsequentBoost}
          />
        )
      } else {
        return (
          <SocialBoost
            articleId={this.props.articleId}
            handleBack={this.props.handleBack}
            handleChange={this.handleChange}
            ampSource={this.props.ampSource}
            title={this.props.title}
          />
        )
      }
    }
  },

  renderPricingInfo: function() {
    let pricingDetails = this.state.pricingDetails
    if (pricingDetails.discount_cents > 0) {
      var subtotal = (
        <tr>
          <td><h6 className='reg'>Subtotal</h6></td>
          <td><h6 className='reg'>&nbsp;${pricingDetails.price_cents/100}</h6></td>
        </tr>
      )
      var level = '';
      if (pricingDetails.level != 'High_discount' && pricingDetails.level != 'Low_discount') {
        level = pricingDetails.level
      }
      var discount = (
        <tr>
          <td><h6 className='reg'>{level} Discount ({parseInt(pricingDetails.discount_rate)}% Off)</h6></td>
          <td><h6 className='reg'>-${pricingDetails.discount_cents/100}</h6></td>
        </tr>
      )
    }
    return (
      <table className='pricing-details-table'>
        <tbody>
          {subtotal}
          {discount}
          <tr>
            <td><h5 className='reg'>Price</h5></td>
            <td><h5 className='reg'>&nbsp;${pricingDetails.price_paid_cents/100}</h5></td>
          </tr>
        </tbody>
      </table>
    )
  },

  renderLandingPageCopy: function() {
    if(this.props.paymentsPage) {
      return (
        <div className="payment-landing-container">
          {this.renderPayerForm()}
          {this.renderEmailsForm()}
          {this.renderCustomReceiptForm()}
        </div>
      )
    }
  },

  renderFooter: function() {
    if(this.props.paymentsPage && this.props.product === 'social_boost') {
      return;
    }
    if(this.props.product !== "expedited_pmc" || this.state.laurOrSum) {
      return (
        <div className="modal-footer">
          <Payment
          redirectToCheckout={this.redirectToCheckout}
          loading={this.state.loading}/>
        </div>
      )
    }
  },

  renderSpinner: function() {
    return (
      <div className='modal-content'>
        <div style={{'textAlign': 'center', 'zIndex': 5000}}>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      </div>
    )
  },

  renderModalClose: function() {
    if(!this.props.paymentsPage) {
      return (
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
      )
    }
  },

  copyText: function() {
    var textArea = document.createElement("textarea")
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = this.props.copyText
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    textArea.setSelectionRange(0, 99999)
    document.execCommand("copy");
    textArea.style.display = 'none'
  },

  renderClipboardText: function() {
    var br;
    if(this.props.product === 'preferred_editing') {
      br = <br/>
    }
    if(!this.props.paymentsPage) {
      return (
        <div className={(this.props.product === 'expedited_pmc' && !this.props.paymentsPage) ? 'text-center' : ''}>
          <br></br>
          <p><strong>Is someone else going to complete the purchase?</strong></p>
          <div className="row copy-link-clipboard-container">
            <p>Please share this page with the payer by copying and pasting the page URL into an email.</p>
            {br}
            <a className={"button secondary " + (br ? 'no-margin-left' : '')} onClick={this.copyText}>Copy Link</a>
          </div>
        </div>
      )
    }
  },

  render: function() {
    return(
      <div>
        <div className='modal-body'>
          {this.renderModalClose()}
          {this.renderModalContent()}
          {this.renderClipboardText()}
          {this.renderFooter()}
        </div>
      </div>
    )
  }
})

export default Main;