var createReactClass = require('create-react-class');
import React from 'react';
import Main from '../SocialChannels/Main';

const PublicProfile = createReactClass({
  getInitialState: function() {
    var userProfile = this.props.userProfile
    return {
      public_email: userProfile.public_email,
      public_phone: userProfile.public_phone,
      web_address: userProfile.web_address,
      social_channels: userProfile.social_channels,
      instagram: userProfile.instagram,
      twitter: userProfile.twitter
    }
  },
  
  componentDidMount: function() {
    var self = this;
    if (self.props.currentStep === 6) {
      $.ajax({
        url: self.props.getComponentDataPath,
        type: "GET",
        data: {class: 'PublicProfile', user_id: self.props.user_id},
        dataType: "json",
        success: function(data) {
          self.setState({
            public_email: data.userProfile.public_email,
            public_phone: data.userProfile.public_phone,
            web_address: data.userProfile.web_address,
            social_channels: data.userProfile.social_channels,
            instagram: data.userProfile.instagram,
            twitter: data.userProfile.twitter
          })
        }
      })
    } else {
      var userProfile = this.props.userProfile
      self.setState({
        public_email: userProfile.public_email,
        public_phone: userProfile.public_phone,
        web_address: userProfile.web_address,
        social_channels: userProfile.social_channels,
        instagram: userProfile.instagram,
        twitter: userProfile.twitter
      })
    }
  },

  handleChange: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  renderSocial: function() {
    if(!this.state.social_channels) {return;}
    return (
      <Main
      update_profile_path={this.props.updateProfilePath}
      social_channels={this.state.social_channels}
      user_data={this.state}
      section="PublicProfileForm"
      />
    )
  },

  formatPayload: function() {
    delete this.state['social_channels']
    return this.state
  },

  renderContents: function() {
    if(!this.state) {return;}
    return (
      <div className="modal-content">
      <h4>Public Contact Info</h4>
      <h6 className="reg">Make yourself accessible to Cureus readers by adding the following contact information.</h6>
      <br/>
      <div className="row">
        <div className="lage-6 medium-6 columns">
          <label>Email</label>
          <input type="text" name="public_email" value={this.state.public_email || ""} 
          onChange={(e) => this.handleChange(e)}></input>
        </div>
        <div className="large-6 medium-6 columns">
          <label>Phone</label>
          <input type="text" className="phone" name="public_phone" value={this.state.public_phone || ""} onChange={(e) => this.handleChange(e)}></input>
        </div>
      </div>
      <div className="row">
        <div className="large-6 medium-6 columns">  
          <label>Website/URL</label>
          <input type="text" name="web_address" value={this.state.web_address || ""}
          onChange={(e) => this.handleChange(e)}></input>
        </div>
        <div className="large-6 medium-6 columns">  
          <label>Twitter</label>
          <input type="text" name="twitter" value={this.state.twitter || ""}
          onChange={(e) => this.handleChange(e)} placeholder="@username"></input>
        </div>
      </div>
      <div className="row">
        <div className="large-6 medium-6 columns">  
          <label>Instagram</label>
          <input type="text" name="instagram" value={this.state.instagram || ""}
          onChange={(e) => this.handleChange(e)} placeholder="username"></input>
        </div>
      </div>
      <div className="row">
        <div className="large-12 medium-12 columns public-profile">
          {/* {this.renderSocial()} */}
        </div>
      </div>
      {this.props.renderErrors()}
      <div className="modal-footer">
        {this.props.renderSkipButton()}
        {this.props.renderButton({user: this.formatPayload(), section: "PublicProfileForm"}, this.props.updateProfilePath, () => this.forceUpdate())}
      </div>
    </div>
    )
  },

  render: function() {
    return (
      <div className="reveal-modal medium" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a href="#" className="cancel-modal">
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          {this.renderContents()}
        </div>
      </div>
    )
  }
})

export default PublicProfile;