import React from 'react';

const PersonRowCard = ({person}) => {

  const specialtyName = () => {
    let name = person.specialty.toLowerCase()
    if (!person.qualifications.toLowerCase().includes(name)  ) {
      return <p><a className={classForSpecialty()} target="_blank" href={person.category_url}>{person.specialty}</a></p>
    }
  }

  const classForSpecialty = () => {
    if (!person.category_url) {
      return "no-link"
    }
  }

  return (
    <div className="row person-row-card">
      <div className="column small-1">
        <img src={person.avatar_image} alt="person icon" height="50px" width="50px"/>
      </div>
      <div className="column small-3">
        <div className="row">
          <div className="column small-12 name">
            <p><a arget="_blank" href={`/users/${person.id}`}><strong>{person.first_name} {person.last_name}</strong> {person.qualifications}</a></p>
          </div>
        </div>
        <div className="row">
          <div className="column small-12">
            {specialtyName()}
          </div>
        </div>
      </div>
      <div className="column small-3 affiliations">
        {person.affiliation_name}
      </div>
      <div className="column small-2 text-right counts">
        {person.publication_count}
      </div>
      <div className="column small-1 text-center counts">
        {person.reviews_count}
      </div>
      <div className="column small-1 text-center counts">
        {person.siq_rated_count}
      </div>
      <div className="column small-1 text-center">
        {person.honors_badge && <img src={person.honors_badge} alt="badge icon" width="75px"/>}
      </div>
    </div>
  );
};

export default PersonRowCard;
