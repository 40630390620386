import React from 'react';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, subMonths, format, subDays } from 'date-fns';
var createReactClass = require('create-react-class');

export function currentWeek() {
  return {
    label: 'Current week',
    from: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
    to: format(endOfWeek(new Date()), 'yyyy-MM-dd')
  }
}

export function currentMonth() {
  return {
    label: 'Current month',
    from: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    to: format(endOfMonth(new Date()), 'yyyy-MM-dd')
  }
}

export function lastMonth() {
  return {
    label: 'Last month',
    from: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
    to: format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
  }
}

export function lastSixMonths() {
  return {
    label: 'Last 6 months',
    from: format(subMonths(new Date(), 6), 'yyyy-MM-dd'),
    to: format(endOfWeek(new Date()), 'yyyy-MM-dd')
  }
}

export function lastThirtyDays() {
  return {
    label: 'Last 30 days',
    from: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  }
}

export function lastSevenDays() {
  return {
    label: 'Last 7 days',
    from: format(subDays(new Date(), 7), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd')
  }
}

const DateSelector = createReactClass({
  getInitialState: function() {
    return({
      open: false,
      selectedRange: this.props.selectedRange,
      from: this.props.from,
      to: this.props.to
    })
  },

  definedRanges: function() {
    var self = this;
    return this.props.dateRanges.map(function(range, idx) {
      return(
        <a key={range.label + idx} onClick={self.updateRange(range.from, range.to, range.label)} href="javascript:void(0)">{range.label}</a>
      )
    });
  },

  updateRange: function(fromDate, toDate, label) {
    var self = this;
    return function() {
      self.setState({
        from: fromDate, to: toDate, selectedRange: label, open: false
      }, function(){
        self.props.getStats(fromDate, toDate, label)
      });
    }
  },

  updateFrom: function(e) {
    this.setState({from: e.target.value})
  },

  updateTo: function(e) {
    this.setState({to: e.target.value})
  },

  applyButton: function() {
    this.setState({open: false, selectedRange: this.state.from + ' - ' + this.state.to}, function() {
      this.props.getStats(this.state.from, this.state.to, this.state.selectedRange)
    }.bind(this))
  },

  toggleOpen: function() {
    this.setState({open: !this.state.open})
  },

  rangeSelector: function() {
    if (this.state.open) {
      var marginLeft = this.props.page === 'email' ? '-50px' : '-150px'
      return(
        <div className="f-dropdown fancy-date-selector-content open"style={{position: "absolute", left: "-366px !important", width: '500px', maxWidth:'500px', top: "32px", marginLeft: marginLeft}} aria-hidden="false">
          <div className="date-inputs-wrap">
            <div className="date-input"style={{width: '165px'}}>
              <label htmlFor="from-3">From</label>
              <input onChange={this.updateFrom} id="from-3" name="from" type="date" value={this.state.from} className="hasDatepicker"/>
            </div>
            <div className="date-input" style={{width: '165px'}}>
              <label htmlFor="to-3">To</label>
              <input onChange={this.updateTo} id="to-3" name="to" type="date" value={this.state.to} className="hasDatepicker"/>
            </div>
          </div>
          <div className="date-filters-wrap">
            <div className="date-filters">
              {this.definedRanges()}
            </div>
            <input className="button secondary tiny"  type="button" value="Apply" onClick={this.applyButton}/>
            <a  onClick={function() {this.setState({open: false})}.bind(this)}>Cancel</a>
          </div>
        </div>
      )
    }
  },

  selectedRange: function() {
    if (this.props.page === 'email' && !this.state.selectedRange) {
      return "Compare to past"
    }
    return this.state.selectedRange;
  },

  render: function() {
    var float = this.props.noFloat ? "no-float" : "right"
    return(
      <div className={float}>
        <div className='fancy-date-selector'>
          <div className='fake-dropdown' >
            {/* { this.props.page === 'email' && <span style={{paddingTop: "8px", paddingLeft: "10px", color: "#9b9b9b"}}>Compare to past</span>} */}
            <a role='button' onClick={this.toggleOpen} className='current'>{this.selectedRange()}</a>
            <a role='button'className='selector' onClick={this.toggleOpen}></a>
            {this.rangeSelector()}
          </div>
        </div>
      </div>
    )
  },
})

export default DateSelector;