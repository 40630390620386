import React, { useState, useEffect } from 'react';
import SettingsButton from './SettingsButton';

const HomepageSettings = ({handleAddWidget, hasArticles, hasLessThan4Multimedia, widgetMainRiverId, mainContentWidgets, slug}) => {
  const sharedProps = {handleAddWidget, widgetMainRiverId, mainContentWidgets, hasArticles, slug}
  useEffect(() => {
  }, [])

  return (
    <div className="homepage-settings-container">
      <div className="settings-box">
        <h3>Homepage Settings</h3>
        <p>Click below to add a widget to your channel homepage. Widget order cannot be changed, but content within each widget can be reordered by clicking and dragging to the desired location (green reorder icon must be present).</p>
        <hr/>
        <SettingsButton
          {...sharedProps}
          img={"https://public.cureus.com/channel_design/custom_content_4x.png"}
          title={"Highlighted Content"}
          modalName={'CustomContentModal'}
          imgClass={"custom-content"}
        />

        <SettingsButton
          {...sharedProps}
          img={"https://public.cureus.com/channel_design/recently_pub_4x.png"}
          title={"Recently Published"}
          modalName={'RecentlyPublished'}
          imgClass={"rec-pub"}
        />

        <SettingsButton
          {...sharedProps}
          img={"https://public.cureus.com/channel_design/channel_team.png"}
          title={"Channel Team"}
          modalName={'ChannelTeam'}
          imgClass={"channel-team"}
          nextRowClass={'next-row'}
        />

        <SettingsButton
          {...sharedProps}
          img={"https://public.cureus.com/channel_design/featured_multimedia_4x.png"}
          title={"Article Media"}
          modalName={'FeaturedMultimediaModal'}
          imgClass={"multimedia"}
          nextRowClass={'next-row'}
          hasLessThan4Multimedia={hasLessThan4Multimedia}
        />

        <SettingsButton
          {...sharedProps}
          img={"https://public.cureus.com/channel_design/internal_content.png"}
          title={"Other Content"}
          modalName={'InternalContent'}
          imgClass={"internal-content"}
          nextRowClass={'next-row'}
        />
      </div>
    </div>
  )
}

export default HomepageSettings;