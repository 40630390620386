import {Cureus} from '../Cureus';

Cureus.MultiSlideModal = (function($, _) {
  var init, MultiSlideModal;

  init = function(options) {
    return new MultiSlideModal(options);
  };

  MultiSlideModal = function(options) {
    var self = this;

    self.$modal = $(options.modalSelector);
    self.$container = self.$modal.find(".modal-multislide-wrap-container");
    self.$slideWrap = self.$modal.find(".modal-multislide-wrap");
    self.$slides = self.$slideWrap.find(".modal-multislide-slide");
    self.$currentSlide = $(self.$slides[0]);

    self.validationCallbacks = options.validationCallbacks || [];

    self.setupSlider();
    self.bindEvents();
  };

  _.extend(MultiSlideModal.prototype, {
    setupSlider: function() {
      this.$slides.hide();
      this.$currentSlide.show();
    },

    slideValid: function($slide, successCallback) {
      var slideIndex = $slide.index();
      var callback = this.validationCallbacks[slideIndex];

      if(callback) {
        callback($slide, successCallback);
      } else {
        successCallback();
      }
    },

    firstSlide: function (){
      if(this.getIndex() == 0) { return; }
      this.$currentSlide.hide();
      this.$currentSlide = $(this.$slides[0]);
      this.$currentSlide.show();
    },

    nextSlide: function() {
      var self = this;

      if(self.getIndex() != self.$slides.length - 1) {
        self.slideValid(self.$currentSlide, function() {
          self.animateToNextSlide();
          self.$currentSlide = self.$nextSlide();
        });
      }
    },

    prevSlide: function() {
      if(this.getIndex() != 0) {
        //Skip validations when going back slides
        this.animateToPrevSlide();
        this.$currentSlide = this.$prevSlide();
      }
    },

    animateToNextSlide: function() {
      this.$slides.hide();
      this.$nextSlide().show();
    },

    animateToPrevSlide: function() {
      this.$slides.hide();
      this.$prevSlide().show();
    },

    $nextSlide: function() {
      return $(this.$slides[this.getIndex() + 1]);
    },

    $prevSlide: function() {
      return $(this.$slides[this.getIndex() - 1]);
    },

    getIndex: function() {
      return this.$slides.index(this.$currentSlide);
    },

    bindEvents: function() {
      var self = this;

      self.$slides.on("click", "[data-multislide-continue]", function(e) {
        self.nextSlide();
        e.preventDefault();
      });

      self.$slides.on("click", "[data-multislide-back]", function(e) {
        self.prevSlide();
        e.preventDefault();
      });

      self.$slides.on("click", "[data-multislide-cancel]", function(e) {
        self.firstSlide();
        e.preventDefault();
      });
    }
  });

  return { init: init };
})(jQuery, _);
