import React, { useState, useEffect, useCallback } from 'react';
import CheckListDesk from './CheckListDesk';
import CheckListBlock from './CheckListBlock';

const BlockOrDesk = ({ blockOrDeskItems, currStep, isOriginalArticle, blockOrDeskItemsAdd, renderIthenticates, handleNext }) => {
  const [blockDisabled, setBlockDisabled] = useState('disabled');
  const [deskDisabled, setDeskDisabled] = useState('disabled');
  const [block, setBlock] = useState(0);
  const [desk, setDesk] = useState(0);

  useEffect(() => {
    var block = 0;
    var desk = 0;
    var pass = 0;
    for(var index in blockOrDeskItems) {
      if(!blockOrDeskItems[index] && blockOrDeskItems[index] !== undefined) {
        if(index >= 0 && index < 4) {
          desk++;
        } else {
          block++;
        }
      } else {
        pass++;
      }
    }
    if (currStep === "block") {
      const disabled = (pass === 5 || pass === 9) ? '' : ' disabled';
      setBlockDisabled(disabled);
    } else {
      const disabled = pass === 9 ? '' : ' disabled'
      setDeskDisabled(disabled);
    }
    setBlock(block);
    setDesk(desk);
  }, [currStep]);

  const buttonDisable = useCallback((index, status) => {
    let disabled;
    if (currStep === "block") {
      disabled = document.querySelectorAll('.pass').length === 5 ? '' : ' disabled';
      setBlockDisabled(disabled);
    } else {
      disabled = document.querySelectorAll('.pass').length === 4 ? '' : ' disabled';
      setDeskDisabled(disabled);
    }
    blockOrDeskItemsAdd(index, status);
  }, [currStep]);

  const handleCheck = useCallback((score, type) => {
    if (type === 'block') {
      setBlock(prev => prev + parseInt(score));
    } else {
      setDesk(prev => prev + parseInt(score));
    }
  }, []);

  const renderRecommendedAction = () => {
    if (block > 0 && desk > 0) {
      return <h4 className="recommend">Recommended action: Block or Desk reject</h4>;
    } else if (block > 0) {
      return <h4 className="recommend">Recommended action: Block</h4>;
    } else if (desk > 0) {
      return <h4 className="recommend">Recommended action: Desk reject</h4>;
    } else {
      return <h4 className="recommend">Recommended action: Proceed</h4>;
    }
  };

  const handleSelectAll = useCallback((deferType) => {
    document.querySelectorAll('.fa-check-circle').forEach((node) => {
      if (node.getAttribute('name') === deferType) {
        if (!node.classList.contains('pass')) {
          setTimeout(() => {
            node.click();
          }, 1);
        }
      }
    });
  }, []);

  const renderSelectAll = (deferType) => {
    return (
      <div className="checklist-item row">
        <div className="large-3 large-offset-8 columns end">
          <div className="select-all">
            <a className="button tiny success" onClick={() => handleSelectAll(deferType)}>Select All</a> 
          </div>
        </div>
      </div>
    );  
  };

  const renderBlockChecklist = () => {
    if (currStep === 'block') {
      return (
        <CheckListBlock 
          buttonDisable={buttonDisable}
          handleCheck={handleCheck}
          blockOrDeskItems={blockOrDeskItems}
          handleSelectAll={handleSelectAll}
          renderSelectAll={renderSelectAll}
        />
      );
    } else {
      return (
        <CheckListDesk
          buttonDisable={buttonDisable}
          handleCheck={handleCheck}
          blockOrDeskItems={blockOrDeskItems}
          handleSelectAll={handleSelectAll}
          renderSelectAll={renderSelectAll}
        />
      );
    }
  };

  const renderGoBack = () => {
    if (currStep === "desk" || isOriginalArticle) {
      return (
        <a className={"button secondary " + disableGoBack()} name="back" onClick={handleNext}>Go back</a>
      );
    }
  };
  
  const disableGoBack = () => {
    return (block > 0 || desk > 0) ? "disabled" : "";
  }

  const renderBlockOrDeskButton = () => {
    if(currStep === "desk") {
      return desk > 0 ? '' : 'disabled';
    } else {
      return block > 0 ? '' : 'disabled';
    }
  }
  
  const renderNext = () => {
    return currStep === "desk" ? deskDisabled : blockDisabled;
  };

  const renderBlockOrDeskButtonText = () => {
    return currStep === "desk" ? "Desk Reject" : "Block";
  };

  const renderHeaderText = () => {
    return currStep === "desk" ? "Should this be desk rejected?" : "Should this be blocked?";
  };

  const renderSubHeaderText = () => {
    return currStep === "desk" ? "Desk reject if:" : "Block if:";
  };

  const renderRecommendation = () => {
    return currStep === "desk" ? "*Refer to Submissions Editing guide for details." : "*Check with Soujanya before blocking if uncertain."
  };

  const renderName = () => {
    return currStep === "desk" ? "desk" : "block"
  };

  return (
    <div className="modal-body">
      <div className='modal-close'>
        <a href='#' className='cancel-modal'>
          <img src="https://public.cureus.com/close_x.png" alt="close"/>
        </a>
      </div>
      <div className="modal-content">
        <h3 className="reg">{renderHeaderText()}</h3>
        {renderIthenticates()}
        <h4>{renderSubHeaderText()}</h4>
        {renderBlockChecklist()}
        <p><em>{renderRecommendation()}</em></p>
      </div>
      {renderRecommendedAction()}
      <div className="modal-footer">
        {renderGoBack()}
        <a className={"button alert " + renderBlockOrDeskButton()} name={renderName()} onClick={handleNext}>{renderBlockOrDeskButtonText()}</a>
        <a className={"button primary " + renderNext()} name="next" onClick={handleNext}>Next</a>
      </div>
    </div>
  );
};

export default BlockOrDesk;