import React from 'react';

const TopAuthors = ({
  seeMoreUsersPath,
  authors,
  viewMoreButton= true,
  viewMoreArrow = true,
  title = 'Top Authors',
  description = 'Top Popular Authors',
}) => {
  // Created a fallback incase the users image is ever broken or does not load
  const defaultImage = (currentTarget) => {
    currentTarget.onerror = "null"
    currentTarget.src = "https://public.cureus.com/new-default-user-image.png"
  }

  const renderAllAuthorInfo = () => {
    let elements = authors.map((author, idx) => {
      const { user_id, avatar_profile_url, first_name, last_name } = author;
      return (
        <div key={idx} className="card-container">
          <a href={`/users/${user_id}`} target="_blank" className="card-media">
            <div className="card">
              <div className="img-container">
                <img
                  src={avatar_profile_url}
                  onError={({currentTarget}) => defaultImage(currentTarget)}
                  alt={`${first_name} ${last_name} profile picture`}
                />
              </div>
              {renderInfo(author)}
            </div>
          </a>
        </div>
      )
    })

    return (
      <div className="author-infos">
        {elements}
      </div>
    )
  }

  const renderInfo = ({primary_affiliation, first_name, last_name}) => {
    return (
      <div className="info">
        <h2>{`${first_name} ${last_name}`}</h2>
        {primary_affiliation && <p className="primary_affiliation">{primary_affiliation}</p>}
      </div>
    )
  }

  const renderSuggestionButton = () => {
    return (
      viewMoreButton &&
      <a href={seeMoreUsersPath} target="_blank">
        <div className="button stencil-blue small text-center see-more-suggestions">
          VIEW MORE
        </div>
      </a>
    )
  }

  return (
    <div className='authors'>
      <div className="header">
        <div className="text">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        {(viewMoreArrow && seeMoreUsersPath) &&
          <a href={seeMoreUsersPath} target="_blank">
            <i className="fas fa-arrow-right"/>
          </a>
        }
      </div>
      {renderAllAuthorInfo()}
      {renderSuggestionButton()}
    </div>
  )
}

export default TopAuthors