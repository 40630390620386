import React from "react";

const Carat = ({showNav, setShowNav }) => {
  const addMargin = () => {
    if (showNav) {return "24px"}
  }

  let caretDirection = showNav ? "up" : "down"
  return <i style={{marginBottom: addMargin()}} className={`fa fa-angle-${caretDirection} carat`} onClick={() => {setShowNav(!showNav)}}/>
}

export default Carat