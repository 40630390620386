import {Cureus} from '../../Cureus';

Cureus.Admin.PageWidgetToggler = (function($, _) {
  var init, WidgetToggler, RssFeedColorSelector;
  var instances = [];

  init = function() {
    var widgets = $(".edit-page-widget");
    _.each(widgets, function(widget){
      if($(widget).data('editable') != undefined) {
        initializeOnce(widget);
      }
    });
  };

  function initializeOnce(widget) {
    if(!_.contains(instances, widget)) {
      new WidgetToggler(widget);
      new RssFeedColorSelector(widget);
      instances.push(widget);
    }
  }

  WidgetToggler = function(widget) {
    this.$widget = $(widget);
    this.$widgetControls = this.$widget.find("> .widget-controls-wrap .pull-right");
    this.$lock = this.$widgetControls.find(".fa-lock");
    this.$form = this.$widget.find(".edit-widget-form").first();
    this.render();
  };

  _.extend(WidgetToggler.prototype, {
    render: function(){
      this.renderTriggers();
      this.bindAll();
    },

    renderTriggers: function(){
      this.$hideTrigger = $("<a class='button tiny secondary' style='display:none'>Close</a>");
      this.$showTrigger = $("<a class='button tiny secondary'>Edit</a>");
      this.$widgetControls.append(this.$showTrigger, this.$hideTrigger);
    },

    bindAll: function(){
      var self = this;

      self.$hideTrigger.on("click", function(e){
        e.preventDefault();
        self.hide();
      });

      self.$showTrigger.on("click", function(e){
        e.preventDefault();
        self.show();
        new RssFeedColorSelector(self.$widget[0]);
      });

      self.$widget.on('ajax:success', '.edit-widget-form', function(e, response){
        var ecw = $(this).closest('.edit-page-widget');
        ecw.find("#widget_url").val(response.url)
        ecw.effect('highlight', {}, 2000);
        if(typeof(ecw.data('can-add-widgets')) == "undefined") {
          self.hide();
        }
        e.stopPropagation();
      });

      self.$widget.on('dblclick', function(){
        self.show();
      });
    },

    hide: function(){
      this.$form.hide();
      this.$hideTrigger.hide();
      this.$showTrigger.show();
      this.$lock.show();
    },

    show: function(){
      this.$form.show();
      this.$showTrigger.hide();
      this.$hideTrigger.show();
      this.$lock.hide();
    }
  });

  RssFeedColorSelector = function(widget) {
    if (widget.getAttribute('data-type') === "Widget::RssFeed") {
      var id = widget.getAttribute("data-id");
      $(`#configuration_border_${id}`).minicolors({
        letterCase: "uppercase"
      });
      $(`#configuration_header_background_${id}`).minicolors({
        letterCase: "uppercase"
      });
      $(`#configuration_header_text_${id}`).minicolors({
        letterCase: "uppercase"
      });
      $(`#configuration_item_title_background_${id}`).minicolors({
        letterCase: "uppercase"
      });
      $(`#configuration_item_title_text_${id}`).minicolors({
        letterCase: "uppercase"
      });
      $(`#configuration_item_background_${id}`).minicolors({
        letterCase: "uppercase"
      });
      $(`#configuration_item_text_${id}`).minicolors({
        letterCase: "uppercase"
      });
    }
  }

  return { init: init };
})(jQuery, _);
