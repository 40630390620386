import React, { useState, useEffect } from 'react';
import OverviewModalInput from '../SidebarWidgets/Overview/OverviewModalInput';

const OverviewSidebarModal = ({handleAddWidget, widgetSidebarId, widget, handleUpdateWidget, slug}) => {
  const [description, setDescription] = useState(widget ? widget.description : '');
  const [location, setLocation] = useState(widget ? widget.location : '');
  const [category, setCategory] = useState(widget ? widget.category : '');

  const shouldDisable = () => {
    return (description || location || category) ? '' : 'disabled';
  }

  const handleAddWidgetWrapper = () => {
    let params;
    params = {
      widget: {
        type: "Widget::OverviewSidebar",
        parent_id: widgetSidebarId,
        configuration: { description, location, category },
      }
    }
    if(handleAddWidget) {
      handleAddWidget(params, false)
    } else {
      handleUpdateWidget(widget.widgetId, params, false)
    }
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Overview</h5>
            <hr/>
          </div>

          <div className="row">
            <div className="small-12 columns">
              <p>Highlight key information about your organization and channel.</p>
            </div>
          </div>

          <OverviewModalInput
            label={"Description"}
            placeholder={"Share some information about your organization."}
            value={description}
            setState={setDescription}
          />

          <OverviewModalInput
            label={"Location"}
            placeholder={"Where is your organization located or headquartered?"}
            value={location}
            setState={setLocation}
          />

          <OverviewModalInput
            label={"Category"}
            placeholder={"Enter the primary area of research or clinical focus."}
            value={category}
            setState={setCategory}
          />

          <div className='modal-footer text-center'>
            <a className={"button primary " + shouldDisable()} onClick={handleAddWidgetWrapper}>Add</a>
          </div>
        </div>
      </div>
    </div>
  )
}


export default OverviewSidebarModal;