import React, { useEffect } from 'react';
import Chart from 'chart.js-v3/auto';

const BarChart = ( { ctx, datasets, title, labels, yLabel, xLabel} ) => {

  useEffect(() => {
    $(`#${ctx}`).remove();
    $(`#${ctx}-container`).append(`<canvas id='${ctx}'></canvas>`);
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: title
          }
        },
        scales: {
          x: [{
            ticks: {
              beginAtZero: true
            }
          }],
          y: {
            title: {
              display: true,
              text: yLabel
            }
          }
        }
      }
    })
  })

  return (
    <div id={`${ctx}-container`}>
      <canvas id={ctx}></canvas>
    </div>
  )

}

export default BarChart;