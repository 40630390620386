var createReactClass = require('create-react-class');
import React from 'react';
import PreferredChoiceModal from './PreferredChoiceModal';
import DeferralModal from './DeferralModal';

const MainModal = createReactClass({
  getInitialState: function() {
    return {
      view: null
    }
  },

  componentDidMount: function() {
    var preferredCheck;
    var view;
    if (this.props.tier === 'preferred' && this.props.inEditorPreview && !this.props.seniorCopyOwnership) {
      view = 'select'
      preferredCheck = false
    } else {
      view = 'defer'
      preferredCheck = true
    }
    this.setState({view: view, preferredCheck: preferredCheck})
  },

  renderContents: function() {
    if (this.state.view === null) {return;}
    if (this.state.view === 'select') {
      return <PreferredChoiceModal
                handleSelection={this.handleSelection}
              />
    } else {
      return <DeferralModal 
        path={this.props.path}
        articleState={this.props.articleState}
        userRole={this.props.userRole}
        inEditorPreview={this.props.inEditorPreview}
        actionMethod={this.props.actionMethod}
        reasons={this.props.reasons}
        tier={this.props.tier}
        view={this.state.view}
        preferredCheck={this.state.preferredCheck}
        sendToSeniorCopyPath={this.props.sendToSeniorCopyPath}
        seniorCopyOwnership={this.props.seniorCopyOwnership}
        affiliationsPath={this.props.affiliationsPath}
      />
    }
  },

  handleSelection: function(e) {
    this.setState({view: e.target.name})
  },

   render: function() {
    return (
      <div className="reveal-modal" id="preferred-choice-modal" data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
            {this.renderContents()}
        </div>
      </div>
    )
  }
})

export default MainModal;