import React, { useEffect, useState, useRef } from 'react';
import Table from './Table';
import Modal from '../../Modal';
import CollectionLockSubsModal from './CollectionLockSubsModal';

const CollectionRow = ({collection, fetchCollections}) => {

  const [expanded, setExpanded] = useState(false);
  const [articleCount, setArticleCount] = useState(collection.article_count);
  const [tableData, setTableData] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    $(document).foundation('tooltip', 'reflow');
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    fetchArticles();
  }, [expanded])

  const fetchArticles = () => {
    if(tableData){
      setTableData(tableData);
      return;
    }
    $.ajax({
      type: 'GET',
      url: `/collections/${collection.id}/articles`,
      dataType: 'json',
      success: function(res) {
        setTableData(res.data);
      }
    })
  }

  const toggleShowArticles = () => {
    if(expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  }

  const renderChevron = () => {
    if(expanded) {
      return (
        <div className='article-count-chevron-up'>
          <img src="https://public.cureus.com/my_dashboard/my_collections/chevron-up.png" />
        </div>
      )
    } else {
      return (
        <div className='article-count-chevron-down'>
          <img src="https://public.cureus.com/my_dashboard/my_collections/chevron-down-article.png" />
        </div>
      )
    }
  }

  const renderLockSubmissionsModal = () => {
    Modal.open({
      children:
        <CollectionLockSubsModal collectionId={collection.id} fetchCollections={fetchCollections} />
    })
  }

  const renderLocked = () => {
    if(collection.is_submission_open) {
      return (
        <div className='collection-lock collection-unlocked button secondary tip-top' data-tooltip="" title={"Close submission window?"} onClick={renderLockSubmissionsModal}>
          <img src="https://public.cureus.com/my_dashboard/my_collections/unlock.png" />
        </div>
      )
    } else {
      return (
        <div className='collection-lock collection-locked button secondary disabled'>
          <img src="https://public.cureus.com/my_dashboard/my_collections/lock.png" />
      </div>
      )
    }
  }

  return (
    <div className='collection-row' id={collection.id}>
      <div className= {"collection-row-header " + (expanded ? 'clicked' : '')}>
        <div className='header-left'>
          <div className='collection-thumbnail'>
            <img src={collection.banner_image.thumbnail.url}/>
          </div>
          <div className='collection-title'>
            <h4>{collection.title}</h4>
          </div>
        </div>
        <div className='header-right'>
          {renderLocked()}
          <div className={"article-toggle button " + (expanded ? 'primary' : 'secondary')} onClick={toggleShowArticles}>
            <div className='article-count-text'>{articleCount} Articles</div>
            <div className='article-count-chevron'>
              {renderChevron()}
            </div>
          </div>
        </div>
      </div>
      {expanded && tableData && <Table collectionId={collection.id} tableData={tableData}/>}
    </div>
  )
}

export default CollectionRow;