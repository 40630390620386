import React, {useState, useEffect, useRef} from 'react';

const SmallCard = ({collection, index, tooltip}) => {
  const [hiddenCategories, setHiddenCategories] = useState(0);
  const [like, setLike] = useState(false);
  let categoryContainer = useRef();
  let categoryItems = useRef();
  const maxCategoriesToShow = 3;

  useEffect(() => {
    window.addEventListener('resize', adjustCategories);
    adjustCategories();
    return () => {
      window.removeEventListener('resize', adjustCategories);
    };
  },[]);
  
  const adjustCategories = () => {
    categoryContainer = document.getElementById(`categoryContainer${index}`);
    categoryItems = categoryContainer.querySelectorAll('.category');
    let availableSpace = categoryContainer.offsetWidth - 80;
      let visibleCategories = 0;
      for (let i = 0; i < categoryItems.length; i++) {
        categoryItems[i].style.display = 'inline-block';
        const categorySpan = categoryItems[i].querySelector('span');
        categorySpan.style.display = 'inline';

        const categoryWidth = categoryItems[i].offsetWidth; 
        if (categoryWidth > availableSpace) {
          categorySpan.style.display = 'none';
          categoryItems[i].style.display = 'none';
        } else {
          availableSpace -= categoryWidth;
          visibleCategories++;
        }
        if (visibleCategories === maxCategoriesToShow) {
          break;
        }
      }

      const hiddenCats = categoryItems.length - visibleCategories;
      setHiddenCategories(hiddenCats);
    };

    const renderSubmissionStatus = (open) => {
      if (open) {
        return (
          <div className='submission-window open'>
            Accepting Submissions
          </div>
        )
      } else {
        return (
          <div className='submission-window closed'>
            Closed
          </div>
        )
      }
    }

  return (
      <a className="small-12 medium-6 large-4 columns card small" href={collection.collection_path}>
        {collection.type != 'CureusUCollection' && renderSubmissionStatus(collection.is_submission_open)} 
        <div className='image-holder'>
          <img src={collection.card_url}/>
        </div>
        <div className='small-card-text' id={`categoryContainer${index}`}>
          <div className='category-container'>
            <div className='category-list'>
              {collection.categories && collection.categories.map((category)=>{
              return (
                <div className='category' key={category.id}>
                  <span>{category.name}</span>
                </div>
              )
              })}
              {hiddenCategories > 0 && <div title={tooltip} data-tooltip="" className="category-count tip-top">+{hiddenCategories}</div>}
              </div>
          </div>
          <h5 className='collection-type'>{collection.pretty_type}</h5>
          <h4 className="collection-title">{collection.title}</h4>
          <div className='bottom-row'>
            <div className='article-number tip-top' data-tooltip="" title={"The number of collection articles"}>
                <img src="https://public.cureus.com/collections/articles.png"/>
                <div className='article-count'>{collection.article_count}</div>
            </div>
            <div className='collection-views tip-top' data-tooltip="" title={"The number of views across the collection and its articles"}>
                <img src="https://public.cureus.com/collections/eye.png"/>
                <div className='views-count'>{collection.pretty_article_views_count}</div>
            </div>
          </div>
        </div>
      </a>
  )
}

export default SmallCard;