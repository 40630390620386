var createReactClass = require('create-react-class');
import React from 'react';
import EditModal from './EditModal';
import DeleteModal from './DeleteModal';
import Modal from '../Modal';

const Row = createReactClass({
  openEditModal: function() {
    Modal.open({
      children: <EditModal
        reason={this.props.reason}
        editPath={"/admin/deferral_reasons/" + this.props.reason.id} 
        type={this.props.type}
      />
    });
  },

  openDeleteModal: function() {
    Modal.open({
      children: <DeleteModal
        reason={this.props.reason}
        deletePath={"/admin/deferral_reasons/" + this.props.reason.id} 
      />
    });
  },

  renderDragHandle: function () {
    return (
      <td className='drag-handle'>
      <table className="dots">
        <tbody>
          <tr>
            <td className="dot"></td>
            <td></td>
            <td className="dot"></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="dot"></td>
            <td></td>
            <td className="dot"></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="dot"></td>
            <td></td>
            <td className="dot"></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="dot"></td>
            <td></td>
            <td className="dot"></td>
          </tr>
          {this.renderAdditionalRowForEditorApproval()}
        </tbody>
      </table>
    </td>
    )
  },

  renderAdditionalRowForEditorApproval: function() {
    let editorApprovalRejectionTable = this.props.type === "EditorApprovalRejectionReason";
    return (
      editorApprovalRejectionTable ? (
        <>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="dot"></td>
            <td></td>
            <td className="dot"></td>
          </tr>
        </>
      ) : (
        <></>
      )
    );
  },
  
  render: function() {
    let editorApprovalRejectionTable = this.props.type === "EditorApprovalRejectionReason";
    const { number, label, tooltip, description } = this.props.reason;
    return (
      <tr>
        {this.renderDragHandle()}
        <td style={{textAlign: 'center'}}>{number}</td>
        <td>{label}</td>
        {editorApprovalRejectionTable && <td>{tooltip}</td>}
        <td>{description}</td>
        <td><a onClick={this.openEditModal}><i className="fa fa-edit"></i></a></td>
        <td><i className="far fa-trash-alt" onClick={this.openDeleteModal}></i></td>
      </tr>
    )
  }
});

export default Row;
