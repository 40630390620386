import React, { useState, useEffect } from 'react';

const Promotion = ({id, promoImage, urlLink, selectedCategories, categories, cssStyling, fetchPromotions, setPromotions}) => {

  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState('');
  const [enableButtonClass, setEnableButtonClass] = useState('disabled');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState(selectedCategories || []);
  const [inputString, setInputString] = useState("");

  useEffect(() => {
    if (selectedCategoryIds.length > 0) {
      setInputString(handleStringForPlaceholder());
    } else {
      setInputString("");
    }
  }, []);

  useEffect(() => {
    checkValidForm()
  }, [image, url, selectedCategoryIds])

  const checkValidForm = () => {
    if (isValidForm()) {
      setEnableButtonClass('')
    } else {
      setEnableButtonClass('disabled')
    }
  }

  const isValidForm = () => {
    return image && url && selectedCategoryIds.length > 0
  }

  const handleRemove = () => {
    $.ajax({
      url: `/admin/browse_specialty_design/${id}`,
      type: 'DELETE',
      success: function(res) {
        fetchPromotions();
      }
    });
  }

  const handleSave = () => {
    let formData = new FormData()
    formData.append("widget[image]", image)
    formData.append("widget[url]", url)
    formData.append("widget[selected_category_ids]", selectedCategoryIds)

    setIsLoading(true)

    fetch('/admin/browse_specialty_design', {
      method: "POST",
      body: formData,
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
      },
    })
    .then((res) => {
      setIsLoading(false)
      if (res.ok) {
        setEnableButtonClass('disabled')
        return res.json()
      } else {
        return Promise.reject(res.json())
      }
    })
    .then((json) => {
      let emptyPromotionAdded = [...json.promotions, {id: null}]
      setPromotions(emptyPromotionAdded);
    })
    .catch((failedPromise) => {
    })
  }

  const renderImage = () => {
    if (promoImage) {
      return (
        <div className="image-container">
          <img src={promoImage}/>
        </div>
      )
    }
  }

  const renderErrorMessage = () => {
    if (!isValidForm()) {
      return (
        <div className="small-4 columns end">
          <p className="error-message">You cannot post widget without a content.</p>
        </div>
      )
    }
  }

  const handleCheckboxChange = (event, category) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedCategoryIds((prevSelected) => [...prevSelected, category.id]);
    } else {
      setSelectedCategoryIds((prevSelected) =>
        prevSelected.filter((id) => id !== category.id)
      );
    }
  };

  const handleStringForPlaceholder = () => {
    const arrOfCategories = getSelectedCategories();
    const categoryNames = arrOfCategories.map((category) => {
      return category.name;
    })
  
    let selectedCategoryNames = "";
    let totalStringCounter = 0;
  
    for (let i = 0; i < categoryNames.length; i++) {
      const string = categoryNames[i];
  
      if (totalStringCounter + string.length <= 55) {
        if (selectedCategoryNames !== "") {
          selectedCategoryNames += ', ';
          totalStringCounter += 2; // Including 2 for the comma and space
        }
        selectedCategoryNames += string;
        totalStringCounter += string.length;
      } else {
        const remainingCategoriesThatCantFitOnPlaceholder = categoryNames.length - i;
        selectedCategoryNames += ` (+${remainingCategoriesThatCantFitOnPlaceholder})`;
        break;
      }
    }
    return selectedCategoryNames;
  }

  const getSelectedCategories = () => {
    const filteredCategoriesObj = categories.filter((category) => {
      return selectedCategoryIds.find((selectedId) => category.id === selectedId)
    })
    return filteredCategoriesObj;
  }

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleApply = () => {
    setOpen(!open);
    setInputString(handleStringForPlaceholder());
  };

  const handleContainerClick = (e) => {
    e.stopPropagation();
  };

  const handleUrlPlaceholder = () => {
    return urlLink ? urlLink : "Enter the link.";
  }

  const renderCategoriesListDropdown = () => {
    return (
      <div className='list-container' onClick={handleContainerClick} onMouseLeave={toggleOpen}>
        <div className='list-inner-container'>
          {categories.map((category) => {
            return (
              <div key={category.id} className='checkbox-container'>
                <input
                  id={category.id}
                  type="checkbox"
                  checked={selectedCategoryIds.includes(category.id)}
                  onChange={(e) => handleCheckboxChange(e, category)}
                  disabled={id ? true : false}
                />
                <label htmlFor={category.id}>{category.name}</label>
              </div>
            )
          })}
        </div>
        <a className={'button medium ' + (id ? "disabled" : "")} onClick={handleApply}>Apply</a>
      </div>
    )
  }

  const renderAddOrRemoveButton = () => {
    if (id) {
      return (
        <div className="row button-row">
          <div className="small-2 columns">
            <div className={`button alert`} onClick={handleRemove}>Remove</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row button-row">
          <div className="small-2 columns">
            <div className={`button secondary ${enableButtonClass}`} onClick={handleSave}>Add</div>
          </div>
          {renderErrorMessage()}
        </div>
      )
    }
  }

  return (
    <>
      {cssStyling && <hr className="divider" />}
      <div className={"small-12 columns promotion " + `${cssStyling}` }>

        <div className="row">
          <div className="small-5 columns specialty-section" onClick={toggleOpen}>
            <div className="input-container specialty-input">
              <label>Specialty</label>
              <input type="text" value={inputString} placeholder="Please, select a specialty page" />
              <img src="https://public.cureus.com/browse-article/arrow_drop_down_1x.png" />
            </div>
            {open && renderCategoriesListDropdown()}
          </div>
        </div>

        {renderImage()}

        {!id &&
          <div className="row">
            <div className="small-4 columns image-text">
              <span >Image</span><input type="file" onChange={(e) => {setImage(e.target.files[0])}} />
            </div>
          </div>
        }

        <div className="row">
          <div className="small-5 columns">
            <div className="input-container">
              <label>URL</label>
              <input type="text" value={url} placeholder={handleUrlPlaceholder()} onChange={(e) => {setUrl(e.target.value)}} disabled={id ? "disabled" : ""} />
            </div>
          </div>
        </div>

        {renderAddOrRemoveButton()}

        {isLoading &&
          <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
          </div>
        }
      </div>
    </>
  )
}

export default Promotion;