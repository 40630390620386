import React, { useState, useEffect } from 'react';
import CreateList from './CreateList';
import { HashRouter as Router } from "react-router-dom";
import Modal from '../Modal'
import CreateListModal from './CreateListModal';

const ChannelMain = ({createListUrl, title, listType, listTypeBlurb, fetchEmailListsPath, viewAllInviteesPath, removeEmailListsPath}) => {

  const [checked, setChecked] = useState(false);
  const [spinning, setSpinning] = useState(true);
  const [emailLists, setEmailLists] = useState([]);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setSpinning(true)
    $.ajax({
      type: 'GET',
      url: fetchEmailListsPath,
      success: function(data) {
        setSpinning(false)
        setEmailLists(data)
        populateTable(data)
      }
    });
  }

  const removeEmailLists = () => {
    if (confirm("Are you sure you want to delete the selected email lists?")) {
      setSpinning(true)
      var emailListIds = []
      $("input:checked").each(function() {
        emailListIds.push($(this).data('resource-id'))
      })
      $.ajax({
        type: 'Delete',
        url: removeEmailListsPath,
        data: {email_list_ids: emailListIds},
        success: function(data) {
          fetchData()
          const checkedInputs = $("input:checked");
          setChecked(checkedInputs.length > 0)
        }
      });
    }
  }

  const checkboxClicked = (event) => {
    const checkedInputs = $("input:checked");
    setChecked(checkedInputs.length > 0)
  }

  const selectAllCheckboxes = (event) => {
    $('input:checkbox').prop('checked', event.target.checked);
    setChecked(event.target.checked)
  }

  const setColumns = () => {
    return [
      {width: '35px'},
      {width: '250px'},
      {width: '100px'},
      {width: '100px'},
      {width: '35px'},
    ]
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.checkbox,
        row.title,
        row.created_at,
        row.updated_at,
        row.num_subscribers,
      ], false)
    )
  }

  const populateTable = (emailLists) => {
    if (emailLists.length > 0) {
      var dt = $("#channel-email-lists-table").DataTable({
        columns: setColumns(),
        language: {
          "searchPlaceholder": "Search...",
          "search": ""
        },
        "bAutoWidth": false,
        columnDefs: [
          {orderable: false, targets: [0]}
        ],
        dom: '<"row"<"small-4 small-offset-8 columns"f>>tr<"row pagination-row"<"small-8 columns pagination-details" <"pull-left"p>><"small-4 columns pagination-details table-length-container"<"text-right"l>>>',
        "fnDrawCallback": function(oSettings) {
          if (oSettings.fnRecordsDisplay() == 0) {
            $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
            $(oSettings.nTableWrapper).find('.dataTables_length').hide();
            $(oSettings.nTableWrapper).find('.dataTables_info').hide();
          } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
            $(oSettings.nTableWrapper).find('.dataTables_info').show();
          } else {
            $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
            $(oSettings.nTableWrapper).find('.dataTables_length').show();
            $(oSettings.nTableWrapper).find('.dataTables_info').show();
          }
        },
        "rowCallback": function(row) {
          row.children[0].children[0].addEventListener('click',function(e) {
            checkboxClicked(e)
          })
        }
      })
      
      dt.api().clear();
      for (var i = 0; i < emailLists.length; i++) {
        let row = emailLists[i];
        mapColumns(dt, row)
      }
      dt.fnSort([[1, 'desc']])
      dt.fnDraw()
    }
  }

  const renderContent = () => {
    if (spinning) {
      return (
        <React.Fragment>
          <div className='spinner-container'>
            <img src='https://public.cureus.com/ajax-loader.gif'/>
          </div>
        </React.Fragment>
      )
    } else if (emailLists && emailLists.length > 0) {
      return (
        <div>
          <CreateList listType={listType} createListUrl={createListUrl} />
          <div>
            <a className={`button email-lists-delete-button secondary ${checked ? '' : 'hidden'}`} onClick={(e) => removeEmailLists(e)}><i className='fa fa-trash-alt'></i> Delete</a>
          </div>
          <div className="channel-email-lists-datatable-container">
            <table id="channel-email-lists-table">
              <thead>
                <tr>
                  <th><input onClick={(e) => selectAllCheckboxes(e)} type="checkbox" /></th>
                  <th>Title</th>
                  <th>Created</th>
                  <th>Updated</th>
                  <th>Recipients</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      )
    } else {
      return (
        <div className="empty-container text-center">
          <div>
            <i className='fa fa-user-friends'></i>
            <h3>No recipients listed</h3>
            <span>
              <a onClick={(e) => createListClick(e)}>Create new list</a>
            </span> 
          </div>
        </div> 
      )
    }
  }

  const createListClick = () => {
    Modal.open({
      children: <CreateListModal listType={listType} createListUrl={createListUrl}/>
    });
  }

  return (
    <Router hashType={"hashbang"}>
      <div className="small-12 columns channel-email-list-container">
        <div className="email-list-header">
          <div className="row">
            <div className="columns small-4">
                <h1>
                  {title || "Cureus Email Lists"} 
                </h1>
              </div>
              <div className="columns small-3">
                {emailLists.length > 0 &&
                  <div className="email-list-view-invitees-button">
                    <a href={viewAllInviteesPath} className="button primary">
                      <i class="fa fa-search-plus"></i>
                      VIEW ALL RECIPIENTS
                    </a>
                  </div>
                }
              </div>
          </div>
          <p className="email-list-description">Use the {listTypeBlurb} to generate awareness of your Cureus academic channel within your organization. Create recipient lists and send campaigns featuring calls for articles and invitations to join your internal reviewer list.  Recipients can be added individually or via bulk CSV upload. All added recipients are private and will not be shared with Cureus nor receive any emails from Cureus outside of those you send via the {listTypeBlurb}.</p>
        </div>
        {renderContent()}
      </div>
    </Router>
  )

}

export default ChannelMain;