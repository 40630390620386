import React from 'react';

const CopyrightAndLicensing = () => {
  return (
    <React.Fragment>
      <h1>Copyright, Licensing and Archiving</h1>

      <h2>Copyright and Licensing</h2>
      <p>
        Cureus applies the Creative Commons Attribution (CC-BY) license to all articles we publish. Under this license, authors retain copyright and agree to make articles legally available for reuse, without permission or fees, for virtually any purpose. Anyone may copy, distribute, or reuse these articles, as long as the author and original source are properly cited.
      </p>
      <p>
        It is the author’s responsibility to obtain the appropriate permissions from the original publisher to republish any previously published text, figures, tables, supplementary information, etc., in an open access journal under a CC-BY license.
      </p>
      <p>
        Cureus <em>does not accept</em> articles that have previously appeared in a recognized peer-reviewed journal (as this represents dual publication).
      </p>
      <p>
        Cureus <em>does accept</em> submissions which have previously been presented at conferences or appeared in other ‘non-journal’ venues (for example: blogs or posters).
      </p>

      <h2>Archiving</h2>
      <p>
        Articles first published in Cureus may be posted to institutional websites, personal websites, or third party repositories provided that the author and original source are properly cited. Permission from the author and journal are not required.
      </p>
      <p>
        In order to digitally preserve all peer-reviewed content published in Cureus, all articles are submitted to PubMed Central for long term digital preservation. This information can be found here: <a href='https://www.ncbi.nlm.nih.gov/pmc/journals/2757/'>https://www.ncbi.nlm.nih.gov/pmc/journals/2757/</a>
      </p>
    </React.Fragment>
  )
}

export default CopyrightAndLicensing;