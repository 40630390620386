import React from 'react';

const PerPageViewSelector = ({perPage, setPerPage}) => {
  return (
    <div className='count-selector-container'>
      <label htmlFor="per-page-count">Show</label>
      <select id="per-page-count" value={perPage} onChange={(e) => setPerPage(e.target.value)}>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  );
};

export default PerPageViewSelector;
