import React from 'react';
var createReactClass = require('create-react-class');
const DropDown = createReactClass({
  renderOptions: function() {
    var self = this;
    return self.props.options.map(function(option, idx) {
      return (
        <option key={option} value={self.props.range ? idx : option}>{option}</option>
      )
    })
  },

  render: function() {
    return(
      <label>
        {this.props.label}
        <select id={this.props.id} onChange={(e) => this.props.getPrice(this.props.stateKey, e.target.value)}>
          {this.renderOptions()}
        </select>
      </label>
    )
  }
})

export default DropDown;