var createReactClass = require('create-react-class');
import React from 'react';
import StateHistoryRow from './StateHistoryRow';

const StateHistoryModal = createReactClass({
  getInitialState: function (){
    return {
      events: [],
      title: ""
    }
  },

  componentDidMount: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      data: {article_id: this.props.article.id, poster: this.props.article.isPoster, abstract: this.props.article.isAbstract},
      url: "/dashboard/fetch_state_data",
      success: function(data) {
        self.setState({events: data.events, title: data.title})
      }
    });
  },

  renderRows: function() {
    var self = this;
    return self.state.events.map(function(event, idx) {
      return (
        <StateHistoryRow 
          key={event.id}
          date={event.date}
          state={event.state}
          message={event.message}
          even={idx % 2 === 0 ? true : false}
        />
      )
    })
  },

  renderTable: function() {
    return (
      <div className="row">
      <div className="small-12 columns">
        <table id="versions-table" className="state-history-modal">
          <thead>
            <tr>
              <th></th>
              <th>Date</th>
              <th>Editorial State</th>
            </tr>
          </thead>
          <tbody>
            {this.renderRows()}
          </tbody>
        </table>
      </div>
    </div>
    )
  },

  renderBlurb: function() {
    if(this.props.article.isPoster) {
      return "The complete poster history is displayed below."
    } else if(this.props.article.isAbstract) {
      return "The complete abstract history is displayed below."
    } else {
      return "The complete article history is displayed below."
    }
  },

  render: function() {
    return (
      <div className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4 className='reg'>{this.state.title}</h4>
            <p>{this.renderBlurb()}</p>
            {this.renderTable()}
          </div>

          <div className="modal-footer">
            <a href="#" className="button secondary cancel-modal"> Done</a>
          </div>
        </div>
      </div>
    )
  }
})

export default StateHistoryModal;