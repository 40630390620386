import React from 'react';
const MessagesCommentsToAuthor = ({data, label, iconClass, type, editorView}) => {
  return (
    <div className="row-container">
      <div className="row">
        <div className="small-12 columns">
          <div className="small-1 columns icon-container">
            <span>
              <i className={iconClass + ' background'}/>
            </span>
          </div>
          <div className={(editorView ? "small-4" : "small-3") + " small-offset-1 columns"}>
            <h5>{label}</h5>
          </div>
          <div className={(editorView ? "small-7" : "small-8") + " columns"}>
            <h6 className="reg">{data.count} messages, {data.articles} articles</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessagesCommentsToAuthor;