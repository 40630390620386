var createReactClass = require('create-react-class');
import React from 'react';
import FileDrop from './FileDrop';
import { Cureus } from '../Cureus';

const TransloaditFileDrop = createReactClass({
  getInitialState:  function() {
    return {
      dragHover: false
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  handleFileSelect: function() {
    this.props.handleFileSelect();
  },

  handleProgress: function(bytesReceived, bytesExpected) {
    this.props.handleProgress(bytesReceived, bytesExpected);
  },

  handleSuccess: function(assembly) {
    this.props.handleSuccess(assembly);
  },

  handleError: function(error) {
    this.props.handleError(error);
  },

  componentDidMount: function() {
    var $form = $(this.refs.form);

    var options = {
      wait: true,
      modal: false,
      autoSubmit: false,
      triggerUploadOnFileSelection: true,
      onFileSelect: this.handleFileSelect,
      onProgress: this.handleProgress,
      onSuccess: this.handleSuccess,
      onError: this.handleError
    }

    Cureus.TransloaditLoader(function() {
      $form.unbind('submit.transloadit');
      $form.transloadit(options);
    });
  },

  handleDroppedFile: function(file) {
    var $form = $(this.refs.form);
    var formData = new FormData();

    formData.append(this.props.fileName, file);
    formData.append("signature", $form.find("input[name='signature']").val())

    var options = {
      wait: true,
      modal: false,
      autoSubmit: false,
      triggerUploadOnFileSelection: true,
      onProgress: this.handleProgress,
      onSuccess: this.handleSuccess,
      onError: this.handleError,
      formData: formData
    }

    this.handleFileSelect();
    Cureus.TransloaditLoader(function() {
      $form.unbind('submit.transloadit');
      $form.transloadit(options);
      $form.submit();
    });
  },

  handleDragEnter: function(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({dragHover: true});
  },

  handleDragOver: function(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({dragHover: true});
  },

  handleDragLeave: function(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({dragHover: false});
  },

  render: function() {
    if (this.state.dragHover) {
      var uploadButton = (
        <div className="media-uploader-content drop centered">
          <h1 className="reg">DROP FIGURE</h1>
        </div>
      )
    }
    else if(this.props.isIE) {
      var uploadButton = <input id="hidden-file-drop-input" key={ this.props.fileKey + "-file"} ref='file' name={ this.props.fileName } type="file" style={{display: 'block'}}/>
    } else {
      var uploadButton = [
        <input id="hidden-file-drop-input" key={ this.props.fileKey + "-file"} ref='file' name={ this.props.fileName } type="file" style={{display: 'none'}}/>,
        this.props.children
      ]
    }

    return (
      <form ref='form' acceptCharset='UTF-8' encType="multipart/form-data" method="post" className={ this.props.className }>
        <input name="utf8" type="hidden" value="✓" />
        <input name="_method" type="hidden" value="put "/>
        <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
        <div dangerouslySetInnerHTML={{__html: this.props.transloaditTemplate}}/>
        <FileDrop
          onDroppedFile={ this.handleDroppedFile }
          plain={ this.props.plain }
          inline={ this.props.inline }
          handleDragEnter={this.handleDragEnter}
          handleDragOver={this.handleDragOver}
          handleDragLeave={this.handleDragLeave}
          dragHover={this.state.dragHover}>
          { uploadButton }
        </FileDrop>
      </form>
    )
  }
});

export default TransloaditFileDrop;
