import React from 'react';
import { Cureus } from '../../Cureus';

const SubscribeButton = ({cureusUEmailListIds, isSignedIn}) => {
  const subscribeToEmailLists = () => {
    if (isSignedIn) {
      $.ajax({
        type: 'POST',
        url: '/users/subscribe_to_cureus_u_emails',
        dataType: 'json',
        data: {email_list_ids: cureusUEmailListIds},
        success: function() {
          Cureus.Flash.success('You are now subscribed to Cureus U digest.')
        },
        error: function() {
          Cureus.Flash.notice('You are already subscribed to Cureus U digest.')
        }
      });
    } else {
      $('#sign-in-modal').foundation('reveal', 'open');
    }
  }
  return <button class="button small stencil-special" onClick={subscribeToEmailLists}>Subscribe</button>
}

export default SubscribeButton