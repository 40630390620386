var createReactClass = require('create-react-class');
import React from 'react';

const Abstract = createReactClass({
  render: function(){
    return (
      <div>
        <h4 className="reg">How should I format my abstract subheaders? </h4>
        <div className="row">
          <div className="columns large-6">
            <h5>
              <i className="far fa-check-circle good"/>
              &nbsp;Correct
            </h5>
            <img src='https://public.cureus.com/abstract-good.png'/>
          </div>
          <div className="columns large-6">
            <h5>
              <i className="far fa-check-circle good"/>
              &nbsp;Correct
            </h5>
            <img src='https://public.cureus.com/abstract-bad.png'/>
          </div>
        </div>
      </div>
    );
  }
});

export default Abstract;