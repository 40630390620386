var createReactClass = require('create-react-class');
import React from 'react';

const Range = createReactClass({
  render: function(){
    return (
      <div className="range">
        <span>{ this.props.pageIndex } - { this.props.totalPages }</span>
      </div>
    );
  }
})

export default Range