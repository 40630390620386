var createReactClass = require('create-react-class');
import React from 'react';

const Biography = createReactClass({
  getInitialState: function() {
    return ({
      bio: this.props.bio,
      truncatedBio: this.truncateBio(this.props.bio),
      activeBioText: this.truncateBio(this.props.bio),
      count: this.props.bio.length,
      expanded: this.props.bio.length > 453 ? false : true
    })
  },

  renderBioText: function() {
    return (
      <div className ={ this.state.expanded ? "" : "fadeable" }>
        <div dangerouslySetInnerHTML={{ __html: this.state.activeBioText }} />
        <div className="fader"/>
      </div>
    )
  },

  renderShowMoreButton: function() {
    if (this.state.count > 453) {
      return this.renderTogglerMessage()
    }
  },

  renderTogglerMessage: function() {
    var text = this.state.expanded ? "Show less" : "Show more"
    var caratClass = this.state.expanded ? "fa fa-angle-up" : "fa fa-angle-down"

    return (
      <div className="content-toggler" id="expand-trigger-wrap">
        <a onClick={ this.toggleTextExpand }> { text } <i className={ caratClass }></i>
        </a>
      </div>
    )
  },

  toggleTextExpand: function() {
    this.setState({
      expanded: !this.state.expanded,
      activeBioText: this.state.expanded ? this.state.truncatedBio : this.state.bio
    })
  },

  truncateBio: function(text) {
    return text.split("").splice(0, 453).join("")
  },

  render: function() {
    return (
      <div className="row">
        <div className="large-12 medium-12 columns">
          <h6> Bio </h6>
          <br/>
          <div className="user-contact-show" id="biography">
            <div className="row">
              <div className="small-12 columns ">
                { this.renderBioText() }
                { this.renderShowMoreButton() }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Biography;

