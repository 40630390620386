import React, { useState, useRef } from 'react';
import _ from "lodash";

const ChannelTeamMember = ({email, title, idx, channelMemberEmails, handleUpdate, setHasAllValidEmails, slug, removeMember}) => {
  const [validEmail, setValidEmail] = useState(email.length > 0 ? true : null)

  const handleEmailUpdate = (e) => {
    let value = e.target.value
    handleUpdate(e, idx)
    debouncedSearch(value)
  }

  const isValid = async(email) => {
    $.ajax({
      type: 'GET',
      url: `/admin/custom_content/check_user`,
      data: { content_type: "User", content_field: "email", content_value: email, channel_id: slug},
      dataType: "JSON",
      success: function(res) {
        setValidEmail(true)
        setHasAllValidEmails(true)
      },
      error: function(res) {
        setValidEmail(false);
        setHasAllValidEmails(false)
      }
    })
  }

  const debouncedSearch = useRef(
    _.debounce(async (email) => {
      await isValid(email)
    }, 500, {'leading': false, 'trailing': true})
  ).current;

  return (
    <>
      <img className={"action-buttons channel-team-member delete"}  src="https://public.cureus.com/channel_design/delete_widget.png" onClick={()=>removeMember(idx)}/>
      <div className="row">
        <div className="small-12 columns">
          <strong>Team Member</strong>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns">
          <div className="input-container">
            <label>Email address</label>
            <input className="datalist-input" type="email" name="email" autoComplete="off" list="memberEmail" placeholder="Please add the email address associated with their Cureus account." onChange={(e) => handleEmailUpdate(e)} value={email}/>
            <datalist id="memberEmail">
              {channelMemberEmails.map((email) => {
                return <option key={email} value={email}>{email}</option>
              })}
            </datalist>
          </div>
        </div>
      </div>
      {
        validEmail == false &&
        <div className="row">
          <div className="small-12 columns">
            <p className="error-message text-left">There is no Cureus account associated with this email address.</p>
          </div>
        </div>
      }

      <div className="row">
        <div className="small-12 columns">
          <div className="input-container">
            <label>Title</label>
            <input type="text" name="title" value={title} placeholder="E.g. Channel Administrator, Deputy Editor, etc." onChange={(e) =>  handleUpdate(e, idx)}/>
            </div>
          </div>
      </div>
      <br/>
    </>
  )
}


export default ChannelTeamMember;