var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../../Cureus';

const RemindModal = createReactClass({
  sendReminder: function() {
    var self = this;
    $.ajax(self.props.article.remindReviewersPath, {
      dataType: "JSON",
      data: {article_id: self.props.article.id, reviewershipTargetGroup: self.props.reviewershipTargetGroup},
      type: "PUT"
    }).done(function() {
      Cureus.Flash.success('Reminder emails were successfully sent to the reviewers who have not accepted or declined the review invitation.')
    }).always(function() {
      self.props.disableSendReminders(self.props.reviewershipTargetGroup)
      $('#remind-modal').foundation('reveal', 'close')
      $("html, body").animate({scrollTop: 0}, 1000);
    })
  },

  renderModalHeader: function() {
    if (this.props.invited) {
      return "Remind Invited " + this.titlelize()
    } else {
      return "Remind " + this.titlelize() + " In Progress"
    }
  },

  pluralize: function(){
    return this.reviewsCount() > 1 ? "reviewers" : "reviewer";
  },

  titlelize: function() {
    return this.pluralize().charAt(0).toUpperCase() + this.pluralize().slice(1)
  },

  reviewsCount: function() {
    return this.props.invited ? this.props.reviews.invited : this.props.reviews.reviewing
  },

  renderModalText: function() {
    var verb = this.reviewsCount() > 1 ? " have" : " has";
    var pluralize = this.reviewsCount() > 1 ? " reviewers" : " reviewer";

    if (this.props.canSendReminders[this.props.reviewershipTargetGroup]) {
      if (this.props.invited) {
        return "You have " + this.reviewsCount() + " " + this.pluralize() + " who" + verb + " yet to accept or decline your review invitation. You can send up to three reminders to encourage them to accept the invitation. (Cureus sends additional reminder emails after three, seven and 14 days.)"
      } else {
        return "You have " + this.reviewsCount() + " " + this.pluralize() + " who" + verb + " yet to submit their review. You can send up to three reminders to encourage them to finish up. (Cureus sends additional reminder emails after three, seven and 14 days.)"
      }
    } else {
      return "You’ve already sent a reminder within the last 48 hours. We don’t want to annoy the reviewers!"
    }
  },

  renderModalButton: function() {
    if (this.props.canSendReminders[this.props.reviewershipTargetGroup]) {
      return <input type="button" className="button primary" name="commit" value="Send Reminder" onClick={ this.sendReminder } />
    } else {
      return (
        <div className="text-center">
           <a className="button secondary cancel-modal"> Close </a>
        </div>
      )
    }
  },

  render: function() {
    return (
      <div className="reveal-modal middling" id="remind-modal" data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>{this.renderModalHeader()}</h4>
            <p>
             {this.renderModalText()}
            </p>
          </div>

          <div className="modal-footer">
            {this.renderModalButton()}
          </div>
        </div>
      </div>
    )
  }
})

export default RemindModal;