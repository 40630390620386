var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../Cureus';

const Transloadit = createReactClass({
  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  handleFileSelect: function() {
    this.props.handleFileSelect();
  },

  handleProgress: function(bytesReceived, bytesExpected) {
    this.props.handleProgress(bytesReceived, bytesExpected);
  },

  handleSuccess: function(assembly) {
    this.props.handleSuccess(assembly);
  },

  handleError: function(error) {
    this.props.handleError(error);
  },

  componentDidMount: function() {
    var $form = $(this.refs.form);

    var options = {
      wait: true,
      modal: false,
      autoSubmit: false,
      triggerUploadOnFileSelection: true,
      onFileSelect: this.handleFileSelect,
      onProgress: this.handleProgress,
      onSuccess: this.handleSuccess,
      onError: this.handleError
    }

    Cureus.TransloaditLoader(function() {
      $form.transloadit(options);
    });
  },

  handleButtonClick: function(e) {
    $(this.refs.file).click();
  },

  render: function() {
    if(this.props.isIE) {
      var uploadButton = <input key={ this.props.fileKey + "-file"} ref='file' name={ this.props.fileName } type="file" style={{display: 'block'}}/>
    } else {
      var uploadButton = [
        <input key={ this.props.fileKey + "-file"} ref='file' name={ this.props.fileName } type="file" style={{display: 'none'}}/>,
        <div onClick={ this.handleButtonClick }>
          { this.props.children }
        </div>
      ]
    }
    return (
      <form ref='form' acceptCharset='UTF-8' encType="multipart/form-data" method="post" className={ this.props.className + ' transloadit-figure-upload-form'}>
        <input name="utf8" type="hidden" value="✓" />
        <input name="_method" type="hidden" value="put "/>
        <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
        <div dangerouslySetInnerHTML={{__html: this.props.transloaditTemplate}}/>
        { uploadButton }
      </form>
    )
  }
});

export default Transloadit;