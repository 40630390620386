var createReactClass = require('create-react-class');
import React from 'react';

const PersonCard = createReactClass({
  renderThumbnail: function() {
    let resource = this.props.resource
    return (
      <div className="medium-1 small-centered medium-uncentered columns person-card-avatar">
        <img alt={resource.full_name} src={resource.avatar_profile_url} title={resource.full_name}/>
      </div>
    )
  },

  renderSpecialty: function() {
    let resource = this.props.resource;
    if (resource.sub_specialty_name) {
      return (
        <span>
          {resource.specialty_name}&nbsp;&middot;&nbsp;{resource.sub_specialty_name}
        </span>
      )
    } else {
      return (
        <span>
          {resource.specialty_name}
        </span>
      )
    }
  },

  renderDetails: function() {
    let resource = this.props.resource
    let qualifications = resource.qualification_titles
    let name_delim = qualifications.length > 0 ? ", " : ""
    return (
      <div className="medium-5 large-6 columns person-details">
        <div className="row person-title">
          <a target="_blank" href={resource.path}>
            <strong>{resource.full_name}{name_delim}</strong>{qualifications}
          </a>
        </div>
        <div className="row person-specialty">
          {this.renderSpecialty()}
        </div>
        <div className="row person-affiliation">
          {resource.affiliation_name_full}
        </div>
      </div>
    )
  },

  renderStats: function() {
    let resource = this.props.resource
    let width = resource.active_level == 'laureate' ? '70px' : '55px';
    return (
      <div className="medium-4 large-4 columns person-stats">
        <table className="person-stats">
          <tbody>
            <tr>
              <td>
                <span className="stat-label">Published</span>
                <br/><br/>
                <span className="stat-value">{resource.published_count}</span>
              </td>
              <td>
                <span className="stat-label">Reviewed</span>
                <br/><br/>
                <span className="stat-value">{resource.reviewed_count}</span>
              </td>
              <td>
                <span className="stat-label">SIQ Rated</span>
                <br/><br/>
                <span className="stat-value">{resource.rated_count}</span>
              </td>
            </tr>
            <tr>
              <td className="contribution-level-badge"></td>
              <td className="contribution-level-badge">
                {resource.active_level != null && resource.active_level != 'scholar' && <img style={{'width': width}} class="has-tip tip-top" data-tooltip src={"https://public.cureus.com/" + resource.active_level + ".png"} title={resource.badge_tooltip}></img>}
              </td>
              <td className="contribution-level-badge">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  },

  renderBadge: function() {
    let resource = this.props.resource;
    let width = resource.active_level == 'laureate' ? '70px' : '55px';
    if (resource.active_level != null && resource.active_level != 'scholar') {
      return (
        <div className='row'>
          <div className='medium-offset-2 columns contribution-level-badge-large'>
            <img style={{'width': width}} class="has-tip tip-top" data-tooltip src={"https://public.cureus.com/" + resource.active_level + ".png"} title={resource.badge_tooltip}></img>
          </div>
        </div>
      )
    }
  },

  render:function() {
    return (
      <div className="row">
        <div className="medium-12 columns small-centered">
          <div className="medium-10 columns small-centered person-content-card">
            <div className="row">
              {this.renderThumbnail()}
              {this.renderDetails()}
              {this.renderStats()}
              {this.renderBadge()}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default PersonCard;
