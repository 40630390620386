var createReactClass = require('create-react-class');
import React from 'react';

const AddArticleInput = createReactClass({
  getInitialState: function() {
    return ({
      id: ''
    });
  },

  updateIdState: function(e) {
    this.setState({id: e.target.value.trim() })
  },

  addArticle: function() {
    this.props.fetchArticle(this.state.id);
    this.setState({id: ''});
  },

  handleEnter: function(event) {
    if (event.key === "Enter") {
      this.addArticle();
      event.preventDefault();
    }
  },

  render: function() {
    return (
      <div className='add-article-id-input row'>
        <div className="small-8 columns">
          <label>
            Article ID
            <input className="add-campaign-article" type='text' placeholder='Add Article ID' value={this.state.id} onKeyPress={this.handleEnter} onChange={this.updateIdState} />
          </label>
        </div>
        <div className='small-4 columns text-center'>
          <input value='ADD' type='button' className='button primary' style={{marginTop: '20px'}} onClick={this.addArticle} />
        </div>
      </div>
    )
  }
})

export default AddArticleInput;