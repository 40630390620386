var createReactClass = require('create-react-class');
import React from 'react';
import Main from './Main';

const SuperMain = createReactClass({
  getInitialState: function (){
    return {
      articles: this.props.articles,
      currentUser: this.props.currentUser
    }
  },

  componentDidMount: function() {
    // IE compatible js
    var elem = document.getElementsByClassName("spinner")[0]
    elem.parentNode.removeChild(elem)
  },

  render: function() {
    var self = this;
    return (
      <div>
        {
          this.state.articles.map(function(article){
            return (
              <Main
                article={ article.articleData.data.attributes }
                coauthorConfirmedEmphasisBox={ article.coauthorConfirmedEmphasisBox }
                authorships={ article.authorships }
                authorshipEndpoint={ self.props.authorshipEndpoint }
                editingServiceInvoiceId={ article.editingServiceInvoiceId }
                emailReceiptPath={ self.props.emailReceiptEndPoint }
                email={ self.props.email }
                key={"article_row_" + article.articleData.data.id}
              />
            )
          })
        }
      </div>
    )
  }
})

export default SuperMain;