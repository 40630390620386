var createReactClass = require('create-react-class');
import React from 'react';

const SwitchList = createReactClass({
  handleChange: function(val) {
    window.location = val;
  },

  render: function() {
    return (
      <Select 
        value={ this.props.selected } 
        options={ this.props.options } 
        onChange={ this.handleChange } 
        clearable={ false } 
        searchable={ false } />
    )
  }
});

export default SwitchList;