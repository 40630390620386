var createReactClass = require('create-react-class');
import React from 'react';
import ScoringModal from './ScoringModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus'

const Main = createReactClass({
  componentDidMount: function(){
    var self = this;
    $("#scoring-trigger").on('open', function(){
      if(self.props.siq_widget.user_can_score && !self.props.siq_widget.miniAccount){
        self.openScoringModal();
      }
    });
  },

  openScoringModal: function(){
    if (this.props.siq_widget.daily_limit) {
      window.scrollTo(0, 0);
      Cureus.Flash.warning("You've read and rated 20 articles today - this is our daily limit.")
    } else if (this.props.siq_widget.rate_limit) {
      window.scrollTo(0, 0);
      Cureus.Flash.warning("There is a five-minute cooldown period before you can rate your next article. Have you read the entire article?")
    } else {
      Modal.open({
        children: <ScoringModal
          siq_widget={ this.props.siq_widget }
      />})
    }
  },

  render: function(){
    return (
      <div id="scoring-trigger"/>
    )
  }
})

export default Main;