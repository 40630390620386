import {Cureus} from '../Cureus';

Cureus.SidebarWithTabs = function(options) {
  var tabContainer = options.tabContainerSelector.split("")
  tabContainer.shift()
  this.tabContainer = document.getElementsByClassName(tabContainer.join(""))

  this.sidebarTabLinks = document.querySelectorAll(".sidebar-wrap a[data-tab-id], #article-see-history")
  this.scrollspyTarget = document.getElementsByClassName("scrollspy-target")[0]

  this.headerOffset = options.headerOffset || -65;
  this.scrollspyOffset = options.scrollspyOffset || 75;

  this.setupScrollSpy();
  this.bindEvents();
}

Cureus.SidebarWithTabs.prototype.setupScrollSpy = function() {
  $(document.querySelector("body")).scrollspy({
    target: ".scrollspy-target",
    offset: this.scrollspyOffset
  })
}

Cureus.SidebarWithTabs.prototype.bindEvents = function() {
  var self = this;

  var tabs = self.tabContainer[0].querySelectorAll("ul.tabs li.tab-title a");

  for (var i = 0; i < tabs.length ; i ++) {
    tabs[i].addEventListener("click", function(){
      self.removeAllBolded(self.sidebarTabLinks)
      self.removeScrollspy();
    })
  }

  for (var i=0; i < self.sidebarTabLinks.length; i++) {
    self.sidebarTabLinks[i].addEventListener("click", function(e) {
      var tabId = e.target.getAttribute("data-tab-id")
      var tab = document.querySelector(tabId)
      var scrollSpyBoolean = e.target.getAttribute("data-scrollspy")
      var href = e.target.getAttribute("href")
      var target = document.getElementById(href)

      tab.click()
      self.removeAllBolded(self.sidebarTabLinks)
      e.target.addClass("active")
      self.toggleScrollspy(scrollSpyBoolean)
      e.preventDefault

      //tab -> link
      tab.addEventListener("click", function(e) {
        self.toggleScrollspy(scrollSpyBoolean)
        e.target.addClass("active")
      })
    })
  }
}

Cureus.SidebarWithTabs.prototype.toggleScrollspy = function(scrollspyOn) {
if (scrollspyOn) {
    this.scrollspyTarget.addClass("scrollspy-target");
  } else {
    this.removeScrollspy();
  }
}

Cureus.SidebarWithTabs.prototype.removeScrollspy = function() {
  if (this.scrollspyTarget) {
    this.scrollspyTarget.removeClass("scrollspy-target")
    var target = this.scrollspyTarget.querySelector("li.active")
    if (target) { target.removeClass("active") }
  }
}

Cureus.SidebarWithTabs.prototype.removeAllBolded = function(sideBarTabLinks) {
  sideBarTabLinks.forEach( function(e) { e.removeClass("active")} )
}