import {Cureus} from '../Cureus';

Cureus.TransloaditLoader = function(callback, version) {
  var loaded = false;

  var transloaditVersion = version || "v2-latest";
  var script = 'https://assets.transloadit.com/js/jquery.transloadit2-' + transloaditVersion + '.js';

  if(loaded) {
    callback();
  } else {
    Cureus.getScript(script, function() {
      callback();
      loaded = true;
    });
  }
}
