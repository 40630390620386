import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import UpdateButton from '../UpdateButton';
import DeleteButton from '../DeleteButton';
import { Timeline } from 'react-twitter-widgets'

const TwitterWidget = ({slug, handleDeleteWidget, handleUpdateWidget, sidebarWidgets, widgetId}) => {
  const [widget, setWidget] = useState('');
  const [profileName, setProfileName] = useState('');

  useEffect(() => {
    if(widgetId) {
      let onSuccess = (res) => {
        setWidget(res);
        setProfileName(res.blurb);
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [sidebarWidgets])

  const renderActionButtons = () => {
    return (
    <>
      <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
      <UpdateButton
        handleUpdateWidget={handleUpdateWidget}
        widget={widget}
        modalName={"TwitterSidebarModal"}
        slug={slug}
      />
      <DeleteButton
        handleDeleteWidget={handleDeleteWidget}
        widgetId={widgetId}
      />
    </>
    )
  }

  return (
    <div className="row sidebar-row" key={widgetId}>
      {handleDeleteWidget && renderActionButtons()}
      <div className="small-12 columns widget">
        <div className="channels-twitter-container">
          {/* https://github.com/andrewsuzuki/react-twitter-widgets/issues/65 */}
          <Timeline
              dataSource={{ sourceType: "profile", screenName: profileName }}
              options={{ height: '600' }}
          />
        </div>
      </div>
    </div>
  )
}

export default TwitterWidget;