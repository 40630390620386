import React from "react";

const SiqSignInModal = () => {
  return (
    <div className="reveal-modal mobile-middling middling" data-reveal="" id="siq-join">
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal" href="#"><img src="https://public.cureus.com/close_x.png"/></a>
        </div>
        <div className="modal-content">
          <h4>Scholarly Impact Quotient™ (SIQ™)</h4>
          <p>
            Scholarly Impact Quotient™ (SIQ™) is our unique post-publication peer review rating process. SIQ™ assesses article importance and quality by embracing the collective intelligence of the Cureus community-at-large. All registered users are invited to contribute to the SIQ™ of any published article. (Authors cannot rate their own articles.)
          </p>
          <div className="text-center">
            <p>
              Already have an account?&nbsp;
              <a href={`/users/sign_in?return_to=${window.location.pathname}?score_article=true`}>Sign in.</a>
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <a className="button secondary cancel-modal" href="#">Cancel</a>
          <a className="button" href="/registrations/sign_up">Join Now</a>
        </div>
      </div>
    </div>
  )
}

export default SiqSignInModal