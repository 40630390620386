import React from 'react';
const CompletedRow = ({label, value, progress, editorView}) => {

  const renderProgress = () => {
    if(progress !== false) {
      return (
        <React.Fragment>
          <div className={(editorView ? 'small-2 small-offset-2' : 'small-1 small-offset-3') + " columns completed-progress-bar"}>
            <div className="cureus-progress thin active">
              <div className="bar" style={{width: progress ? Math.floor(progress) + '%' : '0%'}}></div>
            </div>
          </div>
          <div className="small-1 columns end">
            <h6 className="reg">{progress ? Math.floor(progress) + '%' : '0%'}</h6>
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <div className={(editorView ? 'small-2 small-offset-2' : 'small-1 small-offset-3') + " columns completed-progress-bar"}>
          </div>
          <div className="small-1 columns end">
          </div>
        </React.Fragment>
      )
    }
  }
  
  return (
    <div className="row">
      <div className="small-12 columns">
        <div className={(editorView ? "small-3" : "small-2") + " small-offset-1 columns line-item"}>
          <h6 className="reg">{label}</h6>
        </div>
        <div className="small-2 small-offset-1 columns">
          <h6 className="reg">{value} articles</h6>
        </div>
        {renderProgress()}
      </div>
    </div>
  )
}

export default CompletedRow;