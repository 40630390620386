var createReactClass = require('create-react-class');
import React from 'react';
import ChecklistItem from './ChecklistItem';


const Deferral = createReactClass({
  getInitialState: function (){
    return {
      disabled: ' disabled',
      defer: 0,
      deferType: 'defer'
    }
  },

  componentDidMount: function() {
    var defer = 0
    var pass = 0
    var items = this.props.deferralItems
    for(var index in items) {
      if(!items[index] && items[index] !== undefined) {
        defer++
      } else {
        pass++
      }
    }
    var disabled = (pass > 0 || defer > 0) ? '' : 'disabled'
    this.setState({disabled: disabled, defer: defer, deferType: items[1] === false ? 'professional' : 'content'})
  },

  buttonDisable: function(index, status) {
    var disabled = this.disableButton()
    this.setState({disabled: disabled}, function() {
      this.props.deferralItemsAdd(index, status)
    });
  },

  disableButton: function() {
    let failSelected = document.querySelectorAll('.fail').length > 0
    return failSelected ? 'disabled' : ''
  },

  handleCheck: function(score, type) {
    var newScore = this.state[type] + parseInt(score)
    this.setState({[type]: newScore, deferType: this.setDeferType()})
  },

  renderRecommendedAction: function() {
    if(this.state.defer > 0) {
      return (
        <h4 className="recommend">Recommended action: Defer</h4>
      )
    } else {
      return (
        <h4 className="recommend">Recommended action: Proceed</h4>
      )
    }
  },

  handleSelectAll: function(e) {
    e.target.classList.add('pass')
    document.querySelectorAll('.fa-check-circle').forEach(function(node) {
      if(!node.classList.contains('pass')) {
        setTimeout(function() {
          node.click()
        }, 1)
      }
    })
  },

  renderSelectAll: function() {
    return(
      <div className="checklist-item row">
        <div className="large-3 large-offset-8 columns end">
          <div className="select-all">
            <a className="button tiny success" onClick={this.handleSelectAll}>Select All</a>
          </div>
        </div>
      </div>
    )
  },

  renderChecklist: function() {
    return (
      <div className="checklist">
        {this.renderSelectAll()}
        <ChecklistItem
          title={"Text duplication: too much cited text duplication (iThenticate)"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={0}
          deferralItems={this.props.deferralItems}
          reason={`All articles submitted to Cureus undergo an automated check for plagiarism and text duplication. Your article contains ${this.props.ithenticatePercent}% direct text duplication. (Duplicated text must always be cited.) You can view the similarity report <a href=${this.props.ithenticateUrl} target_='_blank'>here</a>.`}
          drawerReason={"Text duplication (iThenticate)"}
        />
        <ChecklistItem
          title={"Writing quality: needs professional third party editing"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          professional={true}
          handleCheck={this.handleCheck}
          index={1}
          deferralItems={this.props.deferralItems}
        />
        <ChecklistItem
          title={"Scientific content: revisions needed"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={2}
          deferralItems={this.props.deferralItems}
          drawerReason={"Scientific issues"}
        />
        <ChecklistItem
          title={"Media: mentioned in article but not included"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={3}
          deferralItems={this.props.deferralItems}
          reason={"Media items (figures, tables or videos) should be included in the body of your article to help illustrate your article. All relevant images need to be included in the article."}
          drawerReason={"Media not included"}
        />
        <ChecklistItem
          title={"Figures: blurry, stretched, skewed, too dark, need cropping"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={4}
          deferralItems={this.props.deferralItems}
          reason={"One or more of your figures have the following issue(s): too blurry (please upload higher resolution), the dimensions are stretched or skewed (please upload with proper dimensions), the image is too dark to see (please lighten) or the extra white/black space around the image needs to be cropped."}
          drawerReason={"Bad image quality"}
        />
        <ChecklistItem
          title={"Figures: patient ID information visible"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={5}
          deferralItems={this.props.deferralItems}
          reason={"Patient-identifying information is visible in one or more figures. Please remove or obscure this information and resubmit."}
          drawerReason={"Patient ID visible"}
        />
        <ChecklistItem
          title={"Tables: multiple values in the same cell"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={6}
          deferralItems={this.props.deferralItems}
          reason={"Your tables include multiple rows or columns of data within the same cell. Please rebuild the table so that each row of data is contained within its own cell. (No soft line returns within a cell.)"}
          drawerReason={"Multiple values in table cell(s)"}
        />
        <ChecklistItem
          title={"Tables: submitted as figures"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={7}
          deferralItems={this.props.deferralItems}
          reason={"One or more tables have been submitted in picture form as a figure. Please remove the figure(s) and add these tables after building them in Microsoft Word or Excel."}
          drawerReason={"Tables submitted as figures"}
        />
        <ChecklistItem
          title={"Videos: won’t play"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={8}
          deferralItems={this.props.deferralItems}
          reason={"Your embedded videos will not play. Please check the video source pages (on YouTube or Vimeo)."}
          drawerReason={"Videos won’t play"}
        />
        <ChecklistItem
          title={"IRB: details included in Ethics Statement? (Required for prospective)"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={9}
          deferralItems={this.props.deferralItems}
          reason={"Please include the IRB name and approval number on the Human Subjects step of the submission process. This is required for all prospective studies."}
          drawerReason={"IRB name and approval number on the Human Subjects"}
        />
        <ChecklistItem
          title={"Peer reviewers: medical students listed"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={10}
          deferralItems={this.props.deferralItems}
          reason={"Medical students are not appropriate peer reviewers. Please remove the medical student(s) listed below and replace them before resubmitting."}
          drawerReason={"Peer reviewers: medical students listed"}
        />
        <ChecklistItem
          title={"Previously presented as abstract or poster"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={11}
          deferralItems={this.props.deferralItems}
          reason={'As your article was previously presented as an abstract or poster, this must be mentioned in the introduction section. Please include the meeting or conference title and date. For example, "This article was previously presented as a meeting abstract at the 2020 AANS Annual Scientific Meeting on April 27, 2020."'}
          drawerReason={"Previously presented as abstract or poster"}
        />
        <ChecklistItem
          title={"Previously posted to preprint server"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={12}
          reason={'As your article was previously posted to a preprint server, this must be mentioned in the body of the article. For example, “This article was previously posted to the medRxiv preprint server on April 27, 2020.”'}
          deferralItems={this.props.deferralItems}
          drawerReason={"Previously posted to preprint server"}
        />
        <ChecklistItem
          title={"Other issues"}
          buttonDisable={this.buttonDisable}
          type={'defer'}
          handleCheck={this.handleCheck}
          index={13}
          deferralItems={this.props.deferralItems}
          drawerReason={"Other issues"}
        />
      </div>
    )
  },

  setDeferType: function() {
    return document.querySelectorAll('.fa-times-circle')[1].classList.contains('fail') ? 'professional' : 'content'
  },

  setTier: function() {
    return this.props.tier === 'select' ? 'select' : 'free'
  },

  render: function() {
    return (
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className="modal-content">
            <h3 className="reg">Should this be deferred back to the author?</h3>
            {this.props.renderIthenticates()}
            {this.renderChecklist()}
          </div>
          {this.renderRecommendedAction()}
          <div className="modal-footer">
            <a className={"button secondary"} name="back" onClick={this.props.handleNext}>Go back</a>
            <a className={"button alert " + (this.state.defer > 0 ? '' : 'disabled')} name={this.state.deferType} onClick={this.props.handleNext}>Defer</a>
            <a className={"button primary " + this.state.disabled} name={this.setTier()} onClick={this.props.handleNext}>Next</a>
          </div>
        </div>
    )
  }
})

export default Deferral;