import React, {useState, useEffect} from 'react';
import { HashRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

const CureusCurated = ({createCollectionPath, draftCollectionsCount, publishedCollectionsCount, renderCollectionTable, fetchCollections}) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname)

  useEffect(() => {
    fetchCollections(location.pathname.split('/')[2]);
  }, [activeTab])

  return (
    <div className='small-12 columns admin-collection'>
      <div className="row">
        <div className="large-12 columns">
          <div className="cureus-tabs secondary-tabs dashboard homepage">
            <ul className="tabs">
              <Link to="/cureus_curated/draft">
                <li className={"tab-title " + (activeTab === '/cureus_curated/draft' ? 'active' : '')} onClick={() => setActiveTab('/cureus_curated/draft')}>
                  <a className="dashboard">DRAFT ({draftCollectionsCount})</a>
                </li>
              </Link>
              <Link to="/cureus_curated/published">
                <li className={"tab-title " + (activeTab === '/cureus_curated/published' ? 'active' : '')} onClick={() => setActiveTab('/cureus_curated/published')}>
                  <a className="dashboard">PUBLISHED ({publishedCollectionsCount})</a>
                </li>
              </Link>
              <a className='button create-new-collection-btn' href={createCollectionPath}>CREATE NEW</a>
            </ul>
          </div>
        </div>
        <Switch>
          <Route path={['/cureus_curated/draft', '/cureus_curated/published']} render={renderCollectionTable} />
        </Switch>
      </div>
    </div>
  )
};

export default CureusCurated;