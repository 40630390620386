import React, {useState, useEffect, useRef} from 'react';

const ErrorMessage = ({label}) => {
  return (
    <div className="row">
      <div className="small-12 columns">
        <div className="error-container">
          <i className="fa fa-exclamation-circle"/>
          <span>{label}</span>
        </div>
      </div>
    </div>
  )
}

export default ErrorMessage;