import React, { useEffect } from 'react';
import Questions from './Questions';
import RecentArticles from './RecentArticles';
import CollectionComments from './CollectionComments';
import TopAuthor from './TopAuthor';
import Ad from '../../../components/Shared/Ad.jsx'
import GuestEditor from './GuestEditor';

const Overview = ({collection, topics, keywords, setActiveTab, questions, recentArticles, comments, sidebarAd, preview, collectionType, featuredPeople}) => {

  useEffect(() => {
    setActiveTab("Overview");
    if(typeof(sidebarAd) !== 'object') {
      $('#square-ad').append(sidebarAd)
    }
  }, []);

  const renderAd = () => {
    if(typeof(sidebarAd) === 'object') {
      return (
        <div id="square-ad">
          <div className='cureus-ad-wrapper page-square-ad show-for-large-up' >
            <Ad
              props={ sidebarAd }
              size={ "square" }
            />
          </div>
        </div>
      )
    } else {
      return (
        <div id="square-ad"/>
      )
    }
  }
  const renderFeaturedAuthors = () => {
    const AuthorsComponent = collectionType === "GuestEditorCollection" ? GuestEditor : TopAuthor;
  
    return (
      <div className={`featured-authors${collectionType === "GuestEditorCollection" ? ' guest' : ''}`}>
        <h2>{collectionType === "GuestEditorCollection" ? 'Collection Editors' : 'Featured Authors'}</h2>
        <div className='featured-authors-container'>
          {featuredPeople.map((author) => (
            <AuthorsComponent key={author.id} {...{ [collectionType === "GuestEditorCollection" ? 'editor' : 'author']: author }} />
          ))}
        </div>
      </div>
    );
  };
  
  return (
    <div className='overview-body-container large-12 medium-12 small-12'>
      <div className='overview-info-container columns large-12 medium-12 small-12'>
          <div className='left columns large-9 medium-9 small-12'>
            <div className='scope'>
              <h2>Scope</h2>
              <p>{collection.summary}</p>
              {collection.background && (
                <div>
                <br/>
                <p>{collection.background}</p>
                </div>
              )}
              
            </div>
            <div className='keywords'>
              <h2>Keywords</h2>
              <div className='keyword-holder'>
                {keywords.map((kw)=> {
                  return <div className='keyword'>{kw.capitalized_name}</div>
                })}
              </div>
            </div>
            <div className='topics'>
              <h2>Topics</h2>
              <div className='topics-container'>
                <ul className='topics-ul'>
                  {topics.map((topic) => {
                    return <li>{topic.capitalized_name}</li>
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className='right columns large-3 medium-3'>
            {renderFeaturedAuthors()}
            <div className='ad-banner-collections'>
              {renderAd()}
            </div>
          </div>
      </div>
      <div className='overview-lower-container'>
        {questions.length > 0 && <Questions questions={questions} /> }
        
        <RecentArticles recentArticles={recentArticles}/>
        
        {!preview && (
          <CollectionComments comments={comments}/>
        )}
      </div>
    </div>
  )
}


export default Overview;