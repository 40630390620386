import React from 'react';

const TopNavMenu = ( { sections, location } ) => {

  function renderSections() {
    return sections.map(function(section) {
      var className = section.section == location[0] ? "active" : ""
      return <a key={ "top-nav-section-" + section.section } className={ className } href={ `#!/${section.section}/${section.page}` }>{ section.title }</a>
    })
  }

  function renderPages() {
    var section = sections.find(section => section.section == location[0])
    if (section) {
      return section.pages.map(function(page) {
        var className = page.page == location[1] ? "active" : ""
        return <a key={"top-nav-page-" + page.page} className={ className } href={ `#!/${page.section}/${page.page}` }>{ page.title }</a>
      })
    }
  }

  return (
    <React.Fragment>
      <div className='section-nav'>
        { renderSections() }
      </div>
      <div className='page-nav'>
        { renderPages() }
      </div>
    </React.Fragment>
  )
}

export default TopNavMenu;