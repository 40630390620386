import React, { useState } from 'react';
import { Cureus } from '../../Cureus';
const QuestionnaireQuestionComment = ({reviewerResponse, submitter, adminOrEditor, enablePublishButton, articleId, printMode, formatTextForHtml}) => {


  const [responding, setResponding] = useState(false);
  const [response, setResponse] = useState(reviewerResponse.author_response)
  const [persistedResponse, setPersistedResponse] = useState(reviewerResponse.author_response)

  const handleStrikeThrough = (state) => {
    if(state === 'rejected') {
      return {textDecoration: 'line-through'}
    }
  }

  const renderResponse = (response) => {
    return (
      <div key={response.questionId} className='reviewer-response'>
        <span style={{'fontWeight': 'bold'}}>Reviewer {response.reviewer}: </span><span style={handleStrikeThrough(response.state)}>{response.response}</span>
      </div>
    )
  }

  const handleResponse = () => {
    let url = `/reviewer/articles/${articleId}/questionnaire`
    $.ajax({
      type: 'PUT',
      url: url,
      dataType: 'json',
      data: {question: reviewerResponse, author_response: response},
      success: function(res) {
        setPersistedResponse(res.author_response)
        setResponding(false)
        enablePublishButton(res.has_all_submitter_responses)
      },
      error: function(err) {
        Cureus.Flash.notice(err.responseJSON.error)
        window.scrollTo(0, 0)
      }
    })
  }

  const renderAuthorResponse = () => {
    if(persistedResponse && (adminOrEditor || submitter)) {
      return (
        <div className="submitter-response">
          <span className="response-bold">
            Author response:
          </span>
          <span dangerouslySetInnerHTML={{
            __html: formatTextForHtml(persistedResponse)
          }}/>
        </div>
      )
    }
  }

  const renderButtons = () => {
    if (!adminOrEditor && !submitter || printMode) {
      return;
    }

    if(responding) {
      return (
        <div>
          <textarea className="submitter-response" onChange={(e) => setResponse(e.target.value.trim())} defaultValue={response}/>
          <a className={"button home-orange tiny " + (response ? "" : "disabled")} onClick={handleResponse}>Respond</a>
          <a className="button alert tiny" onClick={() => setResponding(false)}>Cancel</a>
        </div>
      )
    } else if(persistedResponse) {
      return (
        <div>
          {renderAuthorResponse()}
          {submitter && <a className="button secondary tiny" onClick={() => setResponding(true)}>Edit</a>}
        </div>
      )
    } else {
      return (
        <div>
          {submitter && <a className="button home-orange tiny" onClick={() => setResponding(true)}>
            Respond
          </a>}
        </div>
      )
    }
  }
  
  return (
    <div className='bottom-questionnaire-comments-container'>
      <div className='questionnaire-question-comment'>
        {renderResponse(reviewerResponse)}
        {renderButtons()}
      </div>
    </div>
  )
}

export default QuestionnaireQuestionComment;