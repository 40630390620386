import React, { useState } from 'react';

const Goals = ({completedPrevMonth, completedCurrMonth, goals, isSubmissionsEditor }) => {

  const [activeGoals, setActiveGoals] = useState(completedCurrMonth);
  const [month, setMonth] = useState('current');

  function handleChange(e) { 
    setMonth(e.target.value);
    if (e.target.value == 'current') {
      setActiveGoals(completedCurrMonth);
    } else {
      setActiveGoals(completedPrevMonth);
    }
  }

  function renderGoals() {
    return goals.map(function(goal, idx) {
      var width = (activeGoals[goal.goal_type] || 0.0) * 1.0 / goal.amount * 100 + '%';
      return (
        <React.Fragment key={idx} >
          <div className='row'>
            <div className='small-4 columns goal-container'>
              <h6>
                <i className='far fa-star goal-star'/>
                {goal.goal_type.capitalize()} Editing articles completed:
              </h6>
            </div>
            <div className='small-7 columns text-center end'>
              <h6>Articles Completed: {activeGoals[goal.goal_type] || 0} of {goal.amount}</h6>
              <div className='cureus-progress thin active'>
                <div className='bar' style={{width: width}}></div>
              </div>
            </div>
          </div>
          <br/>
        </React.Fragment>
      )
    })
  }

  function renderCopyForSubmissionsEditors() {
    if (isSubmissionsEditor) {
      return (
        <div className='small-6 columns end'>
          <p>Only the initial completion event is recorded here. For example, if you defer an article for third party editing (initial completion event) on July 29th and the article is resubmitted and you approve it for free (or defer it for PE) on August 2nd, it will only count for your July goal.</p>
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      <div className='row'>
        <div className='small-10 columns'>
          <h4>Monthly Editing Goals</h4>
        </div>
        <div className='small-2 columns'>
          <select className='thin-dropdown' value={month} onChange={(e) => { handleChange(e) }}>
            <option value="current">Current month</option>
            <option value="previous">Previous month</option>
          </select>
        </div>
        {renderCopyForSubmissionsEditors()}
      </div>
      { renderGoals() }
    </React.Fragment>
  )

}

export default Goals;