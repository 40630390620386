var createReactClass = require('create-react-class');
import React from 'react';
import LengthControl from './LengthControl';
import PropTypes from 'prop-types';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';
import { Cureus } from '../../Cureus';

const Table = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("Table")
  ],

  getDefaultProps: function (){
    return {
      filters: [],
      columns: ['Date', 'First Name', 'Last Name', 'Email', 'Specialty']
    }
  },

  propTypes: {
    dataSource: PropTypes.string.isRequired
  },

  getStateFromFlux: function() {
    return this.getFlux().store("Table").getState();
  },

  componentWillUpdate: function(nextProps, nextState) {
    if(nextState.query != this.state.query) {
      this.$table().DataTable().api().search(nextState.query).draw();
    }
  },

  componentDidUpdate: function(prevProps, prevState) {
    if(this.props.dataSource != prevProps.dataSource ||
       this.props.filters != prevProps.filters ||
       this.props.subscribed != prevProps.subscribed) {
      var dataTable = $(this.refs.table).DataTable();
      dataTable.api().ajax.url(this.getDataSource()).load();
    }
  },

  getDataSource: function() {
    if(this.props.subscribed) {
      return this.props.dataSource + "?subscribed=true";
    } else {
      return this.props.dataSource + "?subscribed=false";
    }
  },

  componentDidMount: function() {
    var self = this;

    $(document).foundation();

    var $table = $(self.refs.table);

    $table.on("preXhr.dt", function() {
      $table.spin();
      self.getFlux().actions.beginAjaxFetch();
    });

    $table.on("xhr.dt", function() {
      $table.spin(false);
      var total = self.dataTableAPI().ajax.json().recordsFiltered;
      self.getFlux().actions.endAjaxFetch(total);
    });

    $table.on("change", "input[type='checkbox']", function(e) {
      var $checkbox = $(this);

      var data = $table.DataTable().api().row($checkbox.closest("tr")).data();
      var parsed = self.parseRowData(data);

      if($checkbox.is(":checked")) {
        self.getFlux().actions.check(parsed.id);
      } else {
        self.getFlux().actions.uncheck(parsed.id);
      }
    });

    $table.dataTable({
      dom: "t<'email-tool-pagination'p>",
      language: {
        paginate: {
          first: "«",
          previous: "‹",
          next: "›",
          last: "»"
        }
      },
      autoWidth: false,
      serverSide: true,
      ajax: {
        url: self.getDataSource(),
        data: function(d) {
          return _.extend(d, {filters: self.props.filters});
        }
      },
      columns: [
        {
          orderable: false,
          render: function(data, type, row) {
            var parsed = self.parseRowData(row);

            if(self.isStaged(parsed.id)) {
              var checkbox = "<input name='recipient' type='checkbox' checked='checked'>";
            } else {
              var checkbox = "<input name='recipient' type='checkbox'>";
            }
            return checkbox;
          }
        }, null, null, null, null, null
      ].concat(this.props.className == 'pmc-list' ? [null, null] : [])
    });
     Cureus.DataTable.searchOnCompletion(".email-list-datatable", ".search")
  },

  componentWillUnmount: function() {
    var $table = $(this.refs.table);
    $table.off();
    $table.DataTable().api().destroy();
  },

  parseRowData: function(row) {
    //The indices correspond to the EmailToolDatable
    return { id: row[0] }
  },

  isStaged: function(id) {
    if(this.state.selectAll) {
      return !this.state.staged[id];
    } else {
      return !!this.state.staged[id];
    }
  },

  selectAll: function() {
    this.getFlux().actions.selectAll();
    this.redraw();
  },

  deselectAll: function() {
    this.getFlux().actions.deselectAll();
    this.redraw();
  },

  selectVisible: function() {
    this.getFlux().actions.selectVisible(this.mappedTableRows());
    this.redraw();
  },

  deselectVisible: function() {
    this.getFlux().actions.deselectVisible(this.mappedTableRows());
    this.redraw();
  },

  mappedTableRows: function() {
    var self = this;
    return $(self.refs.table).DataTable().api().rows().data().map(function(data, i) {
      return self.parseRowData(data).id;
    });
  },

  //TODO: Make this not do a full table fetch - should just redraw w/ current data
  redraw: function() {
    $(this.refs.table).DataTable().api().rows().invalidate().draw();
  },

  $table: function() {
    return $(this.refs.table);
  },

  dataTableAPI: function() {
    return this.$table().dataTable().api();
  },

  updateTableLength: function(length) {
    this.$table().DataTable().api().page.len(length).draw();
  },

  render: function() {
    return (
      <div className="email-list-datatable-wrap">
        <table ref='table' className={"table-wrapper email-list-datatable " + this.props.className} style={{width: "100%"}}>
          <thead>
            <tr>
              <th>
                <a className="button dropdown large secondary selection-dropdown" data-dropdown={"filter-" + this.props.identifier}>&nbsp;</a>
                <ul id={"filter-" + this.props.identifier} className="f-dropdown" data-dropdown-content>
                  <li><a onClick={ this.selectAll }>Select All</a></li>
                  <li><a onClick={ this.selectVisible }>Select Visible</a></li>
                  <li><a onClick={ this.deselectAll }>Deselect All</a></li>
                  <li><a onClick={ this.deselectVisible }>Deselect Visible</a></li>
                </ul>
              </th>
              { this.props.columns.map(function(col, idx){return <th key={col + '_th_' + idx}>{ col }</th>}) }
            </tr>

          </thead>
        </table>

        <LengthControl totalUserCount={ this.state.totalRecordCount } onChange={this.updateTableLength} />
      </div>
    )
  }
});

export default Table;
