var createReactClass = require('create-react-class');
import React from 'react';
import DeleteHeroModal from './DeleteHeroModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const Hero = createReactClass({
  getInitialState: function() {
    return {
      heroImage: this.props.hero.heroImage,
      fontHex: this.props.hero.fontHex,
      active: this.props.hero.active,
      logo_color: this.props.hero.logo_color || 'blue',
      heroFile: null,
      disabled: this.props.hero.id ? '' : 'disabled',
      id: this.props.hero.id,
      updatePath: this.props.hero.updatePath,
      previewPath: this.props.hero.previewPath
    }
  },

  handleSave: function() {
    Cureus.Flash.notice('Saving...this may take a minute')
    var self = this;
    var path = self.state.id ?  {path: self.state.updatePath, method: "PUT"} :  {path: 'homepage_heros', method: "POST"}
    var formFile = self.createFormData(self.state.heroFile)
    formFile = this.formatPayload(formFile)
    fetch(path["path"], {
      method: path["method"],
      body: formFile,
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $("[name='csrf-token']").attr("content")
      }
    }).then(function(response) {return response.json()}).then(function(res) {
      Cureus.Flash.success('The hero image has been saved.')
      var newState = {...res.hero,...{disabled: '', heroFile: null}}
      self.setState(newState)
    })
  },

  createFormData: function(file) {
    var formFile = new FormData()
    formFile.append("hero_image", file)
    return formFile
  },

  formatPayload: function(formFile) {
    formFile.append('active', JSON.stringify(this.state.active))
    formFile.append('font_hex', JSON.stringify(this.state.fontHex))
    formFile.append('logo_color', JSON.stringify(this.state.logo_color))
    return formFile;
  },

  handleChange: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  handleHeroUpload: function(e) {
    this.setState({heroFile: e.target.files[0]})
  },

  handleEnter: function(e) {
    if(e.keyCode == 13) {
      this.handleSave()
    }
  },

  openDeleteModal: function() {
    Modal.open({
      children: <DeleteHeroModal
        id={this.state.id}
        destroyPath={this.state.updatePath}
        onDelete={this.props.onDelete}
      />
    })
  },

  renderActionButton: function() {
    return(
      <div className="cureus-split-dropdown-wrap">
      <a href={this.state.previewPath} target="_blank" className={"button secondary split " + this.state.disabled} data-cureus-split-dropdown={"hero" + this.state.id} >
        <div>Preview</div>
        <span />
      </a>

      <ul id={"hero" + this.state.id} className="cureus-split-dropdown">
        <li>
          <a href={this.state.previewPath} target="_blank">Preview</a>
        </li>

        <li>
          <a onClick={this.openDeleteModal}>Delete</a>
        </li>
      </ul>
    </div>
    )
  },

  renderImage: function() {
    if(this.state.heroImage) {
      return (
      <div className="row">
        <div className="small-4 columns" dangerouslySetInnerHTML= {{ __html: this.state.heroImage }} />
      </div>
      )
    }
  },

  render: function(){
    return (
      <React.Fragment>
        <div className="row">
          <div className="small-4 columns">
            <div className="field-container">
            <input type="file" onChange={(e) => this.handleHeroUpload(e)}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-4 columns">
            <label>
              <input style={{marginRight: '5px'}}type="radio" defaultChecked={this.state.active} name="active" onClick={(e) => this.handleChange(e)}/>
              Active
            </label>
          </div>
        </div>
        {this.renderImage()}
        <br/>
        <div className="row">
          <div className="small-2 columns">
            <div className="input-container">
              <label>Logo color</label>
              <select name="logo_color" onChange={(e) => this.handleChange(e)} defaultValue={this.state.logo_color}>
                <option value="blue">Blue</option>
                <option value="black">Black</option>
                <option value="white">White</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-2 columns">
            <div className="input-container">
              <label>Logo color hex code</label>
              <input type="text" name="fontHex" onChange={(e) => this.handleChange(e)} value={this.state.fontHex} placeholder="Please, enter hex code." onKeyDown={(e) => this.handleEnter(e)}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-3 columns">
            <div className="field-container">
              {this.renderActionButton()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-2 columns">
            <a className="button save" onClick={this.handleSave}>Save</a>
          </div>
        </div>
        <hr/>
        <br/>
      </React.Fragment>
    )
  }
})

export default Hero;