var createReactClass = require('create-react-class');
import React from 'react';
import Modal from './Modal';
import ContactCoAuthorsModal from './ContactCoAuthorsModal';

const ContactCoAuthors = createReactClass({
  handleEmailCoAuthorsClicked: function() {
		Modal.open({
			children: <ContactCoAuthorsModal
									author_emails={ this.props.author_emails }
									submitter_email={ this.props.submitter_email}
									event_klass={ this.props.event_klass }
									shareEmailUrl={ this.props.shareEmailUrl} 
									submitter_name={ this.props.submitter_name }
									/>
		});
	},

  render: function() {
    return (
    	<div className = "disclosure_email_link">
	  		<p><a href="#"  rel="nofollow" onClick={ this.handleEmailCoAuthorsClicked }>Help me contact my co-authors to obtain any potential conflicts of interest.</a></p>
	  	</div>
	  )
  }
})

export default ContactCoAuthors;