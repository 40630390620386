var createReactClass = require('create-react-class');
import React from 'react';

const DeleteAuthorModal = createReactClass({
  renderText: function() {
    if(this.props.pastEditorCheck) {
      return (
        <p>Authors cannot be added back to your article after removal. No exceptions!</p>
      )
    } else if(this.props.isCollection) {
      return (
        <p>Editors cannot be added after your collection is published.</p>
      )
    } else {
      return (
        <p>Authors can be added to the article before your initial submission. No additional authors may be added once submitted for editor check.</p>
      )
    }
  },

  render: function() {
    return (
      <div className="reveal-modal middling" id="delete-modal" data-reveal>
        <div className="modal-body modal-red">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Are you sure you want to remove this {this.props.isCollection ? "editor?" : "author?"}</h4>
            {this.renderText()}
            <div className='modal-footer'>
                <a className='cancel-modal button secondary'>Cancel</a>
                <a className='button alert' onClick={ this.props.removeAuthorRequest }>Remove {this.props.isCollection ? "Editor" : "Author"}</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default DeleteAuthorModal;