var createReactClass = require('create-react-class');
import React from 'react';

const CommentForm = createReactClass({
  getInitialState: function() {
    return ({
      isDisabled: true,
      userStatus: "signed_out",
      commentText: '',
      showSpinner: false,
      commenting: (this.props.signedIn ? true : false),
      replyForm: false
    })
  },

  componentDidMount: function() {
    this.setState({
      isDisabled: (this.props.signedIn && this.props.commentablePublished) ? false : "disabled",
      userStatus: this.props.signedIn ? "signed_in" : "signed_out",
      replyForm: this.props.replyForm
    })
  },

  renderTextArea: function() {
    if (this.props.signedIn) {
      return (
        <textarea id="comment_body" name="comment[body]" required disabled={this.state.isDisabled} onChange={this.handleKeyPress} value={this.state.commentText} placeholder="What are your thoughts?" style={{height: "100px"}}/>
      )
    } else {
      return (
        <div className="not-logged-in">
          Please <a onClick={this.openSignInModal}>sign in</a> or <a onClick={this.openSignUpModal}>sign up</a> to comment.
        </div>
      )
    }
  },

  openSignInModal: function() {
    $('#sign-in-modal').foundation('reveal', 'open');
  },

  openSignUpModal: function() {
    $('#sign-up-modal').foundation('reveal', 'open');
  },

  handleSubmit: function() {
    this.setState({showSpinner: true, isDisabled: true});
    var params = {
                    comment: {
                      commentable_type: this.props.commentableType,
                      commentable_id: this.props.commentableId,
                      parent_id: this.props.parentId,
                      body: this.state.commentText,
                      },
                    comment_ids: this.props.commentIds
                  }
    this.props.createComment(params, this.callBackAfterSubmit)
  },

  callBackAfterSubmit: function() {
    if (this.props.callback) { this.props.callback() }
    this.setState({showSpinner: false, isDisabled: false, commenting: true});
    this.clearTextField();
  },

  clearTextField: function() {
    this.setState({ commentText: ''})
  },

  handleKeyPress: function(e) {
    this.setState({
      commentText: e.target.value
    })
  },

  renderCommentForm: function() {
    if (!this.props.readonly) {
      return (
        <div className="row">
          {this.props.signedIn &&
          <div className="row avatar-profession-container">
            <div className="avatar">
              <div dangerouslySetInnerHTML={{__html: this.props.currentUserAvatar}}/>
            </div>
            <div>
              <span className="comment_user"><a target="_blank">{this.props.currentUserName}</a>{this.props.currentUserName && this.props.currentUserQualifications ? ', ' : ''}  </span>
              <span style={{ color: "#83878E" }}> {this.props.currentUserQualifications}</span>
            </div>
          </div>
          }
        <div className="small-12 medium-12 large-12 columns" style={{ padding: '0 0'}}>
          <div className={"comment_form " + this.state.userStatus} id="comment_form">

              <div className="large-12 columns comment_form_inner" style={{width: '100%', padding: '0 0'}}>

                <form ref='form' acceptCharset="UTF-8">
                  
                  {this.renderTextArea()}
                  {this.renderButtons()}
                </form>
              </div>
            </div>
          </div>
        </div>
      )
    }
  },

  renderButtons() {
    if(this.state.commenting) {
      return (
      <div className="left">
        <input className="button" disabled={this.state.isDisabled} type="button" value="POST A COMMENT" onClick={this.handleSubmit} />
      </div>
      )
    }
  },

  render: function() {
    return (
      <div className="comment-form-container">
        {this.props.renderSpinner()}
        {this.renderCommentForm()}
      </div>
    )
  }
})

export default CommentForm;