import {Cureus} from '../../Cureus';

Cureus.Admin.JobPoller = (function (){
  var init, pollUrl, timerId;

  init = function (options){
    pollUrl = options.pollUrl || null;
    _beginPolling();
  };

  function _beginPolling(){
    timerId = window.setInterval(poll, 2000);
  };

  function _stopPolling(){
    window.clearInterval(timerId);
  };

  function poll(){
    $.ajax({
      type: "GET",
      dataType: "json",
      url: pollUrl
    })
      .done(function (data){
        if(data.batch.status === 'processing'){
          Cureus.Flash.info(data.batch.title);
        } else {
          _stopPolling();
          window.location.reload();
        };
      });
  };

  return {init: init}
})();
