var createReactClass = require('create-react-class');
import React from 'react';

const HistoryModal = createReactClass({
  renderTableRows: function() {
      var self = this;
      return self.props.events.map(function(event) {
        return (
          <tr key={event.id}>
            <td>{event.history_name}</td>
            <td>{event.created_at}</td>
          </tr>
        )
      })
  },
  
  render: function() {
    return (
      <div id="raw-history-log-modal" className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
              <table className="full-width table-wrapper">
                <tbody>
                 {this.renderTableRows()}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    )
  }
});

export default HistoryModal;