import {Cureus} from '../../Cureus';

Cureus.Admin.PageWidgetSorter = (function($, _) {
  var init, Sorter;

  init = function() {
    $("[data-sortable]").each(function(i, el) {
      new Sorter($(el));
    });
  };

  Sorter = function($el) {
    this.$el = $el;
    this.initializeSortable();
  };

  _.extend(Sorter.prototype, {
    initializeSortable: function() {
      var self = this;

      self.$el.sortable({
        axis: "y",

        handle: ".edit-widget-title",

        start: function (e, ui){
          var cke_id, cke_options;
          cke_id = $(ui.item).find(".widget-cktext").attr("id");


          if(cke_id){ 
            cke_options = $("#"+cke_id).data("ckeditor-options");
            self._storeCkeOptions(cke_options);
            self.unloadEditor(cke_id)
          }
        },

        stop: function (e, ui){
          var $item, index, $form, cke_id;

          $item = ui.item;
          cke_id = $item.find(".widget-cktext").attr('id');
          if(cke_id) { self.loadEditor(cke_id) };
          index = $item.index() + 1;
          // NOTE: in case this breaks: we may be running into a problem with
          //   the order in which elements are returned from a jquery selector.
          //   We're assuming first() will grab the element nearest in the DOM
          //   tree.
          $form = $item.find("form").first();
          $form.find("input[name='widget[position]']").val(index);
          var path = $form[0].action
          $.ajax(path, {
            dataType: "json",
            type: "POST",
            data: $form.serialize()
          })
        }
      });
    },

    loadEditor: function (id){
      new Cureus.CKEditor(id, this.ckeOptions);
    },

    unloadEditor: function (id){
      var instance = CKEDITOR.instances[id];
      if(instance) { instance.destroy() };
    },

    _storeCkeOptions: function (options){
      this.ckeOptions = options;
    }
  });

  return { init: init };
})(jQuery, _);

