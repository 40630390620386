
var createReactClass = require('create-react-class');
import React from 'react';
import Sort from './Sort';
import Specialties from './Specialties';

const Filters = createReactClass({
  getInitialState: function() {
    return({
      specialties: this.props.specialties,
      sort: this.props.sorts,
      selectedSort: this.props.selectedSort,
      search: this.props.search,
      page: this.props.page
    })
  },

  updateSort: function(val) {
    var self = this
    this.setState({selectedSort: val}, function() {self.props.updateSort(val)})
  },

  render: function() {
    return (
      <section>
        <div className="row">
          <div className="small-12 columns">
            <br/>
          </div>
          <div className="content-filters-wrap">
            <div className="row">
              <div className="large-8 large-offset-2 medium-8 medium-offset-2 small-12 columns">
                <Specialties
                  specialtyIds={this.props.specialtyIds}
                  specialties={this.state.specialties}
                  submitUpdate={this.props.submitUpdate}
                  resetPage={this.props.resetPage}
                  handleChange={this.props.updateSpecialties}
                />
                <Sort
                  sorts={this.props.sorts}
                  updateSort={this.updateSort}
                  selectedSort={this.state.selectedSort}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
})

export default Filters;