var createReactClass = require('create-react-class');
import React from 'react';
import Pages from '../Shared/Pagination/Pages';
import BulkActions from './BulkActions';
import CheckboxToggler from './CheckboxToggler';
import Sorter from './Sorter';
import CreateList from './CreateList';
import Search from './Search';
import Table from './Table';

const Main = createReactClass({
  componentDidMount: function() {
    $(document).foundation('dropdown', 'reflow');
  },
  
  componentWillMount: function (){
    this.setState({
      emailLists: this.props.emailLists,
      lockedLists: this.props.lockedLists,
      paginationData: this.props.paginationData
    })
  },

  getInitialState: function (){
    return {
      sort: "title",
      sortDir: "normal",
      checked: [],
      isOrderable: false,
      searchTerm: '',
      emailLists: this.props.emailLists,
      lockedLists: this.props.lockedLists,
      paginationData: this.props.paginationData
    }
  },

  reload: function (page){
    var self = this;
    $.ajax({
      type: "GET",
      dataType: "json",
      data: {
        page: page,
        sort: this.state.sort,
        direction: this.state.sortDir,
        search_term: this.state.searchTerm
      },
      url: 'email_lists/search'
    })
      .done(function (data, textStatus, jqXHR){
        self.setState({
          emailLists: data.emailLists,
          lockedLists: data.lockedLists,
          paginationData: data.paginationData
        });
      });
  },

 sortTypeChanged: function (evt){
    var self = this;

    self.setState({
      sort: evt.target.value,
      sortDir: evt.target.value == "position" ? "normal" : this.state.sortDir,
      isOrderable: evt.target.value == "position" ? true : false
    }, function() {
      self.reload(1)
    })
  },

  sortDirChanged: function (evt){
    var self = this;

    self.setState({
      sortDir: (self.state.sortDir == "normal" ? "reverse" : "normal")
    }, function() {
      self.reload(self.state.paginationData.currentPage)
    });
  },

  handleCheck: function(checked) {
    this.setState({checked: checked});
  },

  handleSelectAll: function() {
    var self = this;
    $.get(self.props.checkboxEmailListUrl, {
    }, function(data) {
      self.setState({checked: data})
    }, "json");
  },

  handleDeselectAll: function() {
    this.setState({checked: []});
  },

  isChecked: function() {
    return this.state.checked.length == this.state.paginationData.totalItems;
  },

  renderBulkActions: function() {
    if(this.state.checked.length > 0) {
      var lockedListIds = _.map(this.state.lockedLists, function(emailList) {
        return emailList.id;
      });

      return <BulkActions emailListIds={this.state.checked} bulkActionPaths={this.props.bulkActionPaths} lockedListIds={lockedListIds} isChannel={this.props.isChannel}/>
    } else {
      return null;
    }
  },

  fetchResults: function() {
    var self = this;
    $.ajax({
      method: 'GET',
      url: 'email_lists/search',
      data: {search_term: self.state.searchTerm},
      success: self.searchSuccess,
      error: function() {alert('Error fetching search results')}
    })
  },

  searchSuccess: function(res) {
    if(this.state.searchTerm.length) {
      this.setState({
        emailLists: res.emailLists,
        paginationData: res.paginationData,
        lockedLists: res.lockedLists
      })
    }
  },

  updateSearchTermState: function(term) {
    var self = this;
    self.setState({searchTerm: term}, function() {
      if (self.state.searchTerm.length) {
        self.fetchResults();
      } else {
        self.reload(1)
      }
    });
  },

  render: function (){
    return (
      <div className="email-list-tool"> 
        <div className="email-list-tool-header">
          <h2 className="reg email-list-tool-header-title"> 
            {this.props.title || "Cureus Email Lists"}
          </h2>
          <h4 className="email-list-tool-header-count"> 
            {this.state.paginationData.totalItems}
          </h4>
          <p>
            Checking the checkbox below will select all email lists across all pages.
          </p>
        </div>

        <CheckboxToggler 
          isChecked={this.isChecked}
          onSelectAll={this.handleSelectAll}
          onDeselectAll={this.handleDeselectAll} />

        <Sorter 
          onSortDirChange={this.sortDirChanged}
          onSortTypeChange={this.sortTypeChanged} 
          sortDir={this.state.sortDir}
          sortType={this.state.sort} />
        <Search updateSearchTermState={this.updateSearchTermState} />
        <CreateList createListUrl={this.props.createListUrl} />

        {this.renderBulkActions()}

        <Table 
          emailLists={this.state.emailLists} 
          checked={this.state.checked}  
          onCheck={this.handleCheck}
          isOrderable={this.state.isOrderable} 
          afterReorder={this.reload} />

        <Pages
          paginationData={this.state.paginationData}
          handlePageSelect={this.reload} />
      </div>
    );
  }
});

export default Main;
