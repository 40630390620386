import React from 'react';
import Modal from '../../Modal';
import ColleagueInvitationModal from '../ColleagueInvitationModal';
import { useHistory } from 'react-router-dom';

const InfoWidgetInviteAndMetrics = ({infoWidget, setChangeTab}) => {

  const history = useHistory();

  const openColleagueInvitationModal = () => {
    Modal.open({
      children:
        <ColleagueInvitationModal 
          fromEmail={infoWidget.userEmail}
        />
    })
  }

  const goToPendingArticles = () => {
    history.push('/pending_articles');
  }

  const addCommasToNums = (stateNum) => {
    if (!stateNum) {
      return 0;
    }
    return stateNum.toLocaleString();
  }

  return (
    <div className='invitation-and-metric-section'>
      <div className='large-12 medium-6 small-12 medium-push-6 columns invitation-container'>
        <a className='invitation-banner'>
          <img className='invitation-img' src="https://public.cureus.com/my_dashboard/my_collections/invitation-icon.png" />
          <div className='invitation-text-container' onClick={openColleagueInvitationModal}>
            Invite authors
            <img className='right-arrow-img' src="https://public.cureus.com/my_dashboard/my_collections/orange-right-arrow.png" />
          </div>
        </a>
        <div className='header-container'>
          <h6 className='text-left'>Cureus Collections</h6>
          <p className='text-left'>Manage your draft and published Cureus collections here. New article submissions can be reviewed below to determine collection eligibility.</p>
        </div> 
      </div>
      <hr className='hide-for-medium-only'/>
      <div className='large-12 medium-6 small-12 medium-pull-6 columns metrics-container'>
        <div className='metric orange'>
          <h6 className='text-left orange-text'>Articles to review</h6>
          <h2 className='text-left'>
            <a id='pending-review-count' className='orange-text' onClick={goToPendingArticles}>{addCommasToNums(infoWidget.pendingReviewCount)}</a>
          </h2>
          <small><span className='orange-text'>{addCommasToNums(infoWidget.daysWaiting)}</span> days in queue</small>
        </div>
        <div className='metric black'>
          <h6 className='text-left'>Total article views</h6>
          <h2 classname='text-left'>{addCommasToNums(infoWidget.totalArticleViews)}</h2>
          <small><span className='green-text'>{addCommasToNums(infoWidget.totalArticleViewsThisWeek)}</span> this week</small>
        </div>
        <div className='metric black'>
          <h6 className='text-left'>Total articles included</h6>
          <h2 classname='text-left'>{addCommasToNums(infoWidget.totalArticles)}</h2>
          <small><span className='green-text'>{addCommasToNums(infoWidget.totalArticlesThisWeek)}</span> this week</small>
        </div>
      </div>
    </div>
  )
}

export default InfoWidgetInviteAndMetrics;