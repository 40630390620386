import React, {useState, useEffect} from 'react';
import Table from './Table';

const Main = ({collectionArticlesPath}) => {

  const renderChevronContainer = (expanded, setExpanded, className='') => {
    return (
      <div className="chevron-arrow-container">
        <img 
          src="https://public.cureus.com/channel-icons/chevron-thin-down.png" 
          onClick={() => setExpanded(!expanded)} 
          onMouseOver={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-hovered-down.png")}
          onMouseOut={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-thin-down.png")}
          onMouseDown={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-clicked-down.png")}
          onMouseUp={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-hovered-down.png")}
          className={`chevron-toggle ${className} ${expanded ? "rotate" : ""}`}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <h5 className="articles-header">Guest Editor Actions</h5>
      <div className="articles-container">
        <Table 
          renderChevronContainer={renderChevronContainer} 
          path={collectionArticlesPath} 
          label={"Included in Collection"}
          isParent={false} 
          state={'approved'}
        />
        <Table 
          renderChevronContainer={renderChevronContainer} 
          path={collectionArticlesPath} 
          label={"Excluded from Collection"}
          isParent={false} 
          state={'rejected'}
        />
      </div>
    </React.Fragment>

  )
}

export default Main;