import React, {useState} from "react";
import EditorPreview from "../Index/ArticlesTable/EditorPreview";
import InReview from "../Index/ArticlesTable/InReview";
import EditorApproval from "./ArticlesTable/EditorApproval";
import ChannelRemoved from "./ArticlesTable/ChannelRemoved";
import ClaimExpired from "./ArticlesTable/ClaimExpired";

const ArticlesTableSection = ({channelTableData, defaultState, headerText, states}) => {
  const [activeTab, setActiveTab] = useState(defaultState)

  const renderTabs = () => {
    return filterTableData().map( (tableData) => {
      return (
        <li key={tableData.state} className={"tab-title inline " + (activeTab === tableData.state ? 'active' : '')} onClick={() => setActiveTab(tableData.state)}>
          <a className="dashboard">{tableData.text} {`(${tableData.count})`}</a>
        </li>
      )
    })
  }

  const filterTableData = () => {
    return channelTableData.filter( (tableData) => {
      return states.includes(tableData.state)
    })
  }

  const getArticleTable = () => {
    switch (activeTab) {
      case "editor_preview":
        return <EditorPreview/>
      case "in_review":
        return <InReview/>
      case "editor_approval":
        return <EditorApproval/>
      case "channel_removed":
        return <ChannelRemoved/>
      case "claim_expired":
        return <ClaimExpired/>
    }
  }

  const renderEmailButton = () => {
    if (["editor_preview", "editor_approval"].includes(activeTab)) {
      return (
        <div className={`button primary small ${activeTab} bulk-email-button disabled has-tip tip-left`} id={"mail-tooltip-reviewing"} data-tooltip="" title="Select one or more checkboxes and click this button to send email reminders.">
          <i className="fa fa-envelope"></i>
        </div>
      )
    }
  }

  const renderSpinner = () => {
    return (
      <div className='modal-spinner' style={{ display: 'none', zIndex: '999'}}>
        <div class='large-12 centered'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      </div>
    )
  }

  return (
    <div className="articles-container">
      <h5 className="articles-header">{headerText}</h5>
      <div className="new-channel-container">
        <div className="small-12 columns reviewers-table-container table-container ">
          {renderSpinner()}
          {renderEmailButton()}
          <div className="row">
            <div className="large-12 columns secondary-tabs-channel">
              <div className="secondary-tabs">
                <div className="tabs-container">
                  <div className="tab-items">
                    <ul id="reviewer-actions-nav">
                      {renderTabs()}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getArticleTable()}
        </div>
      </div>
    </div>
  )
}
export default ArticlesTableSection

