import React, { useState, useEffect, useRef } from 'react';
import CustomContentModalInputButtons from './InputElements/CustomContentModalInputButtons';
import _ from "lodash";

const CustomContentModal = ({widget, handleAddWidget, handleUpdateWidget, widgetMainRiverId, mainContentWidgets, slug}) => {

  const [contentType, setContentType] = useState(widget ? widget.type : '');
  const [contentTypeId, setContentTypeId] = useState(widget ? widget.id : '');
  const [contentTypeIdFound, setContentTypeIdFound] = useState(null);
  const [contentTypeSize, setContentTypeSize] = useState('');
  const [errorMessage, setErrorMessage] = useState("You cannot add a widget without selecting the content.");

  useEffect(() => {
    if(widget) {
      handleSize(widget);
    }
  }, [])

  const handleSize = (widget) => {
    if(widget.size === '50') {
      setContentTypeSize('FiftyPercent');
    } else if(widget.size === '100') {
      setContentTypeSize('OneHundredPercent');
    }
  }

  const shouldDisable = () => {
    return !(contentType && contentTypeSize && contentTypeIdFound);
  }

  const handleAddWidgetWrapper = () => {
    let params;
    // if you have the parent, create the child
    if (mainContentWidgets["Widget::CustomContent"]) {
      params = {
        widget: {
          type: widgetSizeClass(),
          parent_id: mainContentWidgets["Widget::CustomContent"],
          resource_type: contentType,
          resource_id: contentTypeId
        }
      }
    } else {
      // creating parent and the child at the same time
      params = {
        widget: {
          type: "Widget::CustomContent",
          parent_id: widgetMainRiverId,
          widgets_attributes: [{
            type: widgetSizeClass(),
            resource_type: contentType,
            resource_id: contentTypeId
          }]
        }
      }
    }

    if(handleAddWidget) {
      handleAddWidget(params)
    } else {
      handleUpdateWidget(widget.widgetId, params)
    }
  }

  const widgetSizeClass = () => {
    return `Widget::CustomContent::${contentTypeSize}`
  }

  const isIdValid = async(contentType, contentTypeId) => {
    $.ajax({
      type: 'GET',
      url: `/admin/custom_content/check_resource`,
      data: { content_type: contentType, content_id: contentTypeId, channel_id: slug },
      dataType: "JSON",
      success: function(res) {
        if (res.success) {
          setContentTypeIdFound(true);
        }
      },
      error: function(res) {
        setContentTypeIdFound(false);
      }
    })
  }

  const debouncedSearch = useRef(
    _.debounce(async (contentType, contentTypeId) => {
      await isIdValid(contentType, contentTypeId)
    }, 500, {'leading': false, 'trailing': true})
  ).current;

  const handleTextFieldChange = (contentTypeId) => {
    setContentTypeId(contentTypeId);
    debouncedSearch(contentType, contentTypeId);
  }

  const props = ({
    setContentType,
    contentType,
    setContentTypeId,
    contentTypeIdFound,
    setContentTypeIdFound,
    setContentTypeSize,
    contentTypeSize,
    handleTextFieldChange
  })

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Highlighted Content</h5>
          </div>
          <hr/>

          <div className='modal-form'>
            <p>Highlight published articles, posters and abstracts by adding the desired number of content cards (no limit). Paste the ID number from the corresponding URL and decide if the content card should be half-width (50%) or full-width (100%).</p>

            <CustomContentModalInputButtons
              {...props}
              inputValue={contentType}
              textFieldValue={contentTypeId}
              uniqueName="Article"
            />

            <CustomContentModalInputButtons
              {...props}
              inputValue={contentType}
              textFieldValue={contentTypeId}
              uniqueName="Poster"
            />

            <CustomContentModalInputButtons
              {...props}
              inputValue={contentType}
              textFieldValue={contentTypeId}
              uniqueName="Abstract"
            />

          </div>
          <div className='modal-footer text-center'>
            <a className={"button primary " + (shouldDisable() ? 'disabled' : '')} onClick={handleAddWidgetWrapper}>Add</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomContentModal;