import React from 'react';
import { Helmet } from 'react-helmet';

const Introduction = () => {

  return (
    <React.Fragment>
      <Helmet>
        <title>Call for Medical Editors | Open Access Research | Cureus</title>
        <meta name='description' content='Become a curator of medical knowledge as a Cureus medical editor, including as a volunteer guest editor, and join the cutting edge of scientific research.' />
      </Helmet>
      <h1>An Introduction to Cureus Collections</h1>

      <h2>What is a Cureus Collection?</h2>
      <p>
        Cureus Collections are curated collections of peer-reviewed articles focused on a specific topic. Think of them as our version of special issues! There are two types of Cureus Collections: collections created and managed by the journal’s editorial staff, and collections that are proposed, created and managed by one or more guest editors.
      </p>

      <h2>What are the Responsibilities of a Collection Guest Editor?</h2>
      <p>
        Guest editors are tasked with reviewing article submissions that the authors have submitted for inclusion in a specific collection. When creating and submitting an article draft to Cureus, the submitting author has the opportunity to select a specific collection that they think would be a good fit for their article. If the article is accepted for peer review and eventually approved for publication, it will be queued for review and approval or rejection by the designated collection’s guest editor. Approved articles will appear within the collection, while rejected articles will still be published, just not in the designated collection.
      </p>

      <p>
        We want the guest editor’s job to be as easy as possible. As a result, the guest editor is limited to making a binary choice: to include or exclude articles submitted to their collection. Cureus is known for our fast and efficient publication process and we don’t want to add additional steps and delays for articles submitted to collections. While we may expand the scope of guest editor’s responsibilities in the future, for now we are keeping it simple.
      </p>

      <h2>What are the Benefits of Volunteering as a Guest Editor?</h2>
      <p>
        Volunteering as a guest editor of a Cureus Collection is a great opportunity to position yourself as a thought leader in your field and gain invaluable academic experience for your CV. Share and promote your collection with colleagues near and far to drive engagement and raise awareness of your collaboration with the Cureus Journal of Medical Science!
      </p>

      <p>Cureus handles everything related to article processing and publishing including preliminary quality checks, peer review management, and copy editing so you can focus on two vital ingredients for a successful Cureus Collection:</p>
      <ol className='decimals indented-for-medium-up'>
        <li>Selecting the highest quality and most relevant articles for inclusion in your collection</li>
        <li>Promoting your collection with colleagues across your institution and any relevant professional organizations or societies</li>
      </ol>
    </React.Fragment>
  )
  
}

export default Introduction