import {Cureus} from '../Cureus';

Cureus.ReviewerRatingStars = (function($, _) {
  var init, ReviewerRatingStars, StarWidget;
  var SLIDE_WIDTH = 564;

  init = function(modalSelector) {
    new ReviewerRatingStars(modalSelector);
  };

  ReviewerRatingStars = function(modalSelector) {
    this.modalSelector = modalSelector;
    this.$modal = $(modalSelector);
    this.$submitButton = this.$modal.find("#submit");
    this.currentSlide = 0;

    this.bindButtonEvents();
    this.toggleDisabledButtons();
    this.toggleButtonDisplay();
    this.initializeStarWidget();
  };

  _.extend(ReviewerRatingStars.prototype, {
    setupSlider: function() {
      var width = this.totalSlides * SLIDE_WIDTH;
      this.$modal.find("ul").css('width', width);
    },
    bindButtonEvents: function() {
      var self = this;
      this.$submitButton.click(function(e) {
        e.target.addClass("disabled")
        if(e.target.innerText !== 'Next') {
          e.target.innerHTML = "Submitting..."
        }
        e.preventDefault();
      });
    },

    getCurrentSlide: function() {
      return $(this.$modal.find("li")[this.currentSlide]);
    },

    updateButtons: function() {
      this.toggleButtonDisplay();
      this.toggleDisabledButtons();
    },

    toggleDisabledButtons: function() {
      if(this.currentSlideIsRated()) {
        if(this.onLastSlide()){
          this.$submitButton.removeAttr("disabled");
        }
      }
    },

    toggleButtonDisplay: function (){
      if(this.onLastSlide()){
        this.showSubmitButton();
      }
    },

    showSubmitButton: function (){
      this.$submitButton.show();
    },

    currentSlideIsRated: function() {
      let res = []
      document.querySelectorAll(".rating-field").forEach(function(node){
        if(node.value == "") {
          res.push(false)
        }
      })
      return res.length === 0
    },

    onLastSlide: function() {
      return true
    },

    initializeStarWidget: function() {
      var self = this;

      _.each(this.$modal.find(".reviewer-rating"), function(reviewerRating, index) {
        var widget = new StarWidget(reviewerRating);
        $(widget).on("starwidget:value-set", function() {
          self.toggleDisabledButtons();
        });
      });
    }
  });

  StarWidget = function(starWrapperSelector) {
    this.$starWrapper = $(starWrapperSelector);
    this.stars = this.$starWrapper.find("i.far"); //maybe find %i, and initalize them with star empties
    this.$ratingField = this.$starWrapper.find(".rating-field");
    this.bindStarEvents();
  }

  _.extend(StarWidget.prototype, {
    bindStarEvents: function() {
      var self = this;

      _.each(self.stars, function(star, index) {
        $(star).on("mouseover", function() {
          self.renderStars(index);
        });
        
        self.$starWrapper.on('mouseleave', function (){
          self.renderStars(self.getRatingField() - 1);
        });

        $(star).on("touchstart", function (e){
          self.renderStars(index);
        });

        $(star).on("click", function(e) {
          if(self.$ratingField.val() == '') {
            self.$ratingField.trigger("first-rating-chosen");
          }
          self.setRatingField(index + 1);
        });
      });
    },

    renderStars: function(index) {
      //highlight things up until index
      for(var i = 0; i <= index; i++) {
        var $star = $(this.stars[i]);
        $star.removeClass("far");
        $star.addClass("fas");
      }

      //unhighlight things after index
      for(var i = index + 1; i < this.stars.length; i++) {
        var $star = $(this.stars[i]);
        $star.removeClass("fas");
        $star.addClass("far");
      }
    },

    setRatingField: function(value) {
      this.$ratingField.val(value);
      $(this).trigger("starwidget:value-set");
    },

    getRatingField: function() {
      return this.$ratingField.val() || "";
    }
  }); 
  return { init: init };
})(jQuery, _);
