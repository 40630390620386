var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const DeletePosterAbstractModal = createReactClass({
  getInitialState: function() {
    return {
      type: this.props.resource.isAbstract ? 'abstract' : 'poster'
    }
  },

  componentDidMount: function() {
    Cureus.DisableWithATag.enable();
  },

  render: function() {
    return(
      <div className="reveal-modal middling" id={"abandon-modal-" + this.props.resource.id} data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Are you sure you want to delete this {this.state.type}?</h4>
          </div>

          <div className="modal-footer">
            <a className="button secondary cancel-modal"> Keep {this.state.type} </a>
            <a href={`/publish/${this.state.type}s/${this.props.resource.id}/abandon`} className="button alert" data-method="delete" data-disable-with="Deleting..."><i className="far fa-trash-alt"/> Delete {this.state.type} </a>
          </div>
        </div>
      </div>
    )
  }
})

export default DeletePosterAbstractModal;


