import React from 'react';

const GuestEditor = ({ editor }) => {
  const notMedicalProfessional = editor.specialty === "I'm not a medical professional.";

  const renderCard = () => {
    if (editor.is_removed_from_collections) {
      return;
    } else {
      return (
        <div className='author-card'>
          <div className='circle-image'>
            <img className='avatar-image' src={editor.avatar} onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src="https://public.cureus.com/new-default-user-image.png"
            }}/>
          </div>
          <div className='title'>
            <a href={`/users/${editor.user_id}`} target='_blank'>{editor.full_name} {editor.qualifications}</a>
            {!notMedicalProfessional &&
              <p className='specialty'>{editor.specialty}</p>
            }
            <a
              className='email'
              href={`mailto:${editor.email}`}>{editor.email}</a>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      {renderCard()}
    </>
  )
}

export default GuestEditor;