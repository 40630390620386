var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';
import ReferenceRow from './ReferenceRow';
import Sortable from '../../components/Sortable';


//handles checkboxes + deletion
const ReferencesList = createReactClass({
  getDefaultProps: function() {
      var param = $("[name='csrf-param']").attr("content");
      var token = $("[name='csrf-token']").attr("content");
  
      return {
        csrfParam: param,
        csrfToken: token
      };
    },

  getInitialState: function() {
    return {
      checkedReferences: {},
      removeSelected: false
    }
  },

  handleSelectAll: function() {
    var $checkbox = $(this.refs.selectAll);

    if($checkbox.is(":checked")) {
      var checkedReferences = {};

      this.props.references.map(function(reference) {
        checkedReferences[reference.id] = reference;
      });

      this.setState({ checkedReferences: checkedReferences });
    } else {
      this.setState({ checkedReferences: {} });
    }
  },

  handleCheck: function(reference, isChecked) {
    if(isChecked) {
      this.uncheckReference(reference);
    } else {
      this.checkReference(reference);
    }
  },

  uncheckReference: function(reference) {
    var checkedReferences = _.clone(this.state.checkedReferences);
    delete checkedReferences[reference.id];
    this.setState({ checkedReferences: checkedReferences });
  },

  checkReference: function(reference) {
    var checkedReferences = _.clone(this.state.checkedReferences);
    checkedReferences[reference.id] = reference;
    this.setState({ checkedReferences: checkedReferences });
  },

  isReferenceChecked: function(reference) {
    var ref = this.state.checkedReferences[reference.id];

    if(ref) {
      return true;
    } else {
      return false;
    }
  },

  renderSpinner: function() {
    if (this.props.editingList) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  },

  batchReferences() {
    if(window.confirm("This will delete all selected references. Are you sure you want to do this? This action is irreversible!")) {
      this.props.disableEditing();
      var reference_ids = Object.keys(this.state.checkedReferences);
      this.handleRemoveSelected(reference_ids)
    }
  },

  handleRemoveSelected: function(reference_ids) {
    var self = this;
    if (reference_ids.length === 0) {
      self.props.onDelete();
      return;
    }
    $.ajax(self.props.bulkDestroyPath, {
      data: {
        reference_ids: reference_ids.splice(0, 5),
        article_id: self.props.articleId,
        authenticity_token: self.props.csrfToken
      },
      type: "DELETE",
      success: function() {
        self.handleRemoveSelected(reference_ids)
      }
    });
  },

  onUpdate: function() {
    this.props.onUpdate();
  },

  handleSort: function(e, sortable, movedComponent) {
    var newPosition = e.newIndex + 1;
    this.handleUpdatePosition(movedComponent, newPosition);
  },

  handleUpdatePosition: function(movedComponent, newPosition){
    var self = this;
    var updatePositionUrl = movedComponent.props.reference.update_position_path;
    var oldPosition = movedComponent.props.reference.position;

    var $flash = $('.wizard-nav-wrap').parent();
    if($flash.length == 0) { $flash = undefined } //Hacky but necessary for now

    if(this.isValidPosition(newPosition)) {
      $.ajax({
        type: "PUT",
        url: updatePositionUrl,
        data: { position: newPosition },
        success: function() {
          Cureus.Flash.success("You have successfully moved line " + oldPosition + " to line " + newPosition + ".", $flash);
          self.onUpdate();
        }
      });
    } else {
      Cureus.Flash.alert("That is not a valid reference number.", $flash);
    }
  },

  isValidPosition: function(position) {
    var min = 1;
    var max = this.props.references.length;
    return position >= min && position <= max;
  },

  render: function() {
    var self = this;
    return (
      <div className='article-content-references-table-wrap'>
        {self.renderSpinner()}
        <a className='remove-selected-references' onClick={ this.batchReferences }>
          Remove Selected
        </a>
        <table id='article-content-references-table' style={{width: "100%"}}>
          <thead>
            <tr>
              <th className='table-draggable'></th>
              <th className='table-header-select-all' style={{width: "40px"}}>
                <input ref='selectAll' type='checkbox' onChange={ this.handleSelectAll } />
              </th>
              <th className='table-header-number' style={{width: "50px"}}>#</th>
              <th className='table-header-details'>Reference details</th>
              <th className='table-header-actions' style={{width: "88px"}}></th>
            </tr>
          </thead>
          <Sortable tagName='tbody' handleSort={ this.handleSort }>
            {
              self.props.references.map(function(reference, i) {
                return (
                  <ReferenceRow
                    key={ reference.id }
                    reference={ reference }
                    onDelete={ self.props.onDelete }
                    checked={ self.isReferenceChecked(reference) }
                    onCheck={ self.handleCheck }
                    onUpdate={ self.onUpdate }
                    onUpdatePosition={ self.handleUpdatePosition }
                    fieldData={ self.props.fieldData }
                    optionsForReferenceSelect={ self.props.optionsForReferenceSelect }
                    disableEditing={self.props.disableEditing}
                    isEditor={self.props.isEditor}
                    fetchParsedReferencePath={self.props.fetchParsedReferencePath}
                    revertReferencePath={self.props.revertReferencePath}
                    parsedReferences={self.props.parsedReferences}
                    />
                )
              })
            }
          </Sortable>
        </table>
      </div>
    )
  }
});

export default ReferencesList;