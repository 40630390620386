var createReactClass = require('create-react-class');
import React from 'react';
import AttachmentUploadMixin from './AttachmentUploadMixin';
import TransloaditFileDrop from '../TransloaditFileDrop';
import Edit from './Edit';
import CheckList from './CheckList';

const Figure = createReactClass({
  mixins: [AttachmentUploadMixin],
  getInitialState: function(){
    return {
      attachment: this.props.attachment,
      disableButton: true,
      characterCount: this.props.attachment.legend ? this.props.attachment.legend.split('').length : 0,
      view: 'edit',
      reupload: false
    }
  },

  updateKey: function(e) {
    var newAttachment = Object.assign({}, this.state.attachment)
    newAttachment[e.target.dataset.title] = e.target.value
    this.setState({attachment: newAttachment})
  },

  setValidState: function(isValid){
    this.setState({disableButton: !isValid})
  },

  handleCharacterCount: function(count, legend) {
    var newAttachment = Object.assign({}, this.state.attachment)
    newAttachment["legend"] = legend
    this.setState({characterCount: count, attachment: newAttachment})
    this.isValidNextButton()
  },

  isValidNextButton: function() {
    var validTitle = ($("#attachment_title").val() !== "") && ($("#attachment_title").val() !== undefined);
    var validImage = this.state.attachment.has_file || this.state.attachment.url;
    var validCharacterCount = this.state.characterCount <= 2000
    this.setValidState((validTitle && validImage && validCharacterCount));
  },

  handleUploadComplete: function(data) {
    var self = this;
    self.setState({
      attachment: data,
      isReplacing: false,
      isUploading: false,
      hasUploaded: true,
      reupload: false
    }, function() {
      self.isValidNextButton()
    });
  },

  handleUpdate: function(e, data){
    var self = this;
    self.props.handleUpdate(e, data, function(serializedAttachmentData) {
      self.setState({
        disableButton: true,
        attachment: serializedAttachmentData
      }, function() {$("#add-figure .primary").trigger("insertItem")})
    });
  },

  handleBack: function() {
    this.setState({view: 'edit'})
  },

  handleNext: function() {
    this.setState({view: 'checklist', disableButton: true})
  },

  renderUploaderContent: function(){
    return (
      <div className="media-uploader-content">
        <p className='reg'>Cureus supports <strong>PNG</strong>, <strong>JPEG</strong>, and <strong>TIFF</strong> file types. <br/> Our recommended figure size is at least 1600 pixels wide. <br/> View <a href="/media_guide#figure-req" target="_blank">figure formatting tools</a></p>
        <img src="https://public.cureus.com/Dragndrop.png" />
        <h3>- or -</h3>
        <a className="button small special" onClick={this.handleFileButtonClick}>Select From <br/> Computer</a>
      </div>
    )
  },

  handleFileButtonClick: function() {
    document.getElementById('hidden-file-drop-input').click()
  },

  handleReupload: function() {
    this.setState({reupload: true, disableButton: true})
  },

  transloadit: function() {
    return (
      <TransloaditFileDrop
        handleFileButtonClick={ this.handleFileButtonClick }
        transloaditTemplate={ this.props.attachment.transloadit_template }
        handleFileSelect={ this.handleFileSelect }
        handleProgress={ this.handleProgress }
        handleSuccess={ this.handleSuccess }
        handleError={ this.handleError }
        fileName={ 'figure_file' }>
        {this.renderUploaderContent()}
      </TransloaditFileDrop>
    )
  },

  renderView: function() {
    if (this.state.view === 'edit') {
      return <Edit
        errorMessage = {this.state.errorMessage}
        isUploading = {this.state.isUploading}
        handleCharacterCount = {this.handleCharacterCount}
        attachment={this.state.attachment}
        characterCount={this.state.characterCount}
        transloadit={this.transloadit}
        disableButton={this.state.disableButton}
        setValidState={this.setValidState}
        isValidNextButton={this.isValidNextButton}
        handleNext={this.handleNext}
        updateKey={this.updateKey}
        hasUploaded={this.state.hasUploaded}
        handleReupload={this.handleReupload}
        reupload={this.state.reupload}
        finishedUploading={this.state.finishedUploading}
        mediaGuidePath={this.props.mediaGuidePath}
      />
    } else {
      return (
        <CheckList
          attachment={this.state.attachment}
          handleInsertButton={this.handleInsertButton}
          handleBack={this.handleBack}
          disableButton={this.state.disableButton}
          setValidState={this.setValidState}
          handleUpdate={this.handleUpdate}
          checkAllCheckboxes={this.props.checkAllCheckboxes}
        />
      )
    }
  },

  render: function() {
    return (
      <div className="reveal-modal custom-full attachment-modal" data-reveal>
        {this.renderView()}
      </div>
    ) }
  })

  export default Figure;