var createReactClass = require('create-react-class');
import React from 'react';

export const Link = createReactClass({
  getInitialState: function() {
    return({
      popperDisplay: 'none',
      timeout: null
    })
  },

  mailIcon: function() {
    if (this.props.author.correspondent || (this.props.author.id === this.props.correspondentId)) {
      return(
        <sup>
          <a href={"mailto:" + this.props.author.email}><i className='far fa-envelope'/></a>
        </sup>
      )
    }
  },

  hideSiblings: function() {
    var self = this;
    document.querySelectorAll('.popper-window').forEach(function(el) {
      if (el.dataset.idx != self.props.idx){
        el.style.display = 'none';
      }
    })
  },

  handleMouseEnter: function() {
    if (this.props.plain) {return;}
    this.hideSiblings()
    window.clearTimeout(this.state.timeout)
    this.setState({timeout: null, popperDisplay: 'block'})
  },

  handleMouseLeave: function() {
    if (this.props.plain) {return;}
    var self = this;
    self.setState({timeout: setTimeout(function(){
        self.setState({popperDisplay: 'none'})
      }, 200)
    })
  },

  renderName: function() {
    var content = this.props.author.author_type == 'Person' ? <span>{this.props.author.full_name}&#65279;{this.mailIcon()}</span> : <a href={'/users/' + this.props.author.user_id} target='_blank' rel='noreferrer'>{this.props.author.full_name}&#65279;{this.mailIcon()}</a>
    return (
      <span className='popper-name' onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        {this.renderPopper()}
        {content}
        {this.props.isLast ? '' : <span>,&nbsp;</span>}
      </span>
    )
  },

  renderPopper: function() {
    if (this.props.plain) {return;}
    var name = this.props.author.full_name;
    var correspond;
    if (this.props.author.qualification_titles.length) {
      name += ', '
    }
    if (this.props.author.id === this.props.correspondentId){
      correspond = (
        <p>
          For correspondence:
          <br/>
          <a href={'mailto:' + this.props.author.public_email} className='author-popper-mailto'>{this.props.author.public_email}</a>
        </p>
      )
    }
    return(
      <span className='popper-window' data-idx={this.props.idx} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} style={{display: this.state.popperDisplay}}>
        <span>
          <strong>{name}</strong>
          <span>
            {this.props.author.qualification_titles.join(', ')}
          </span>
          <br/>
        </span>
        <p>{this.props.author.primary_affiliation}</p>
        {correspond}
      </span>
    )
  },

  render: function() {
    return(
      <span>
        {this.renderName()}
      </span>
    )
  }
})

export default Link;