var createReactClass = require('create-react-class');
import React from 'react';

const ContentCard = createReactClass({

  renderThumbnail: function() {
    if (this.props.resource.has_thumbnail) {
      return(
        <div className="content-card-large-thumbnail">
          <a href={ this.props.resource.path }><div className="content-card-thumbnail-mask"></div>
          <div className='content-card-thumbnail-mask'/>
          <div key={ this.props.resource.id + "thumb" } dangerouslySetInnerHTML={ {__html: this.props.resource.thumbnail} } />
          </a>
        </div>
      )
    }
  },

  renderCategories: function() {
    var self = this;
    if (!this.props.resource.categories) { return; }
    return this.props.resource.categories.map(function(category) {
      return <a target="_blank" key={ category.id + "_" + self.props.resource.id} href={category.path} className="tag">{ category.name }</a>
    });
  },

  render: function() {
    return(
      <div className="content-card">
        <div className="content-card-header">
          <div className="content-card-header-info">
            <span>{ this.props.resource.published_date }</span>
            <br/>
            <span className="content-card-type">{ this.props.resource.humanized_type }</span>
          </div>
          <div className="clearfix"/>
        </div>
        { this.renderThumbnail() }

        <div className="content-card-details">
          <a target="_blank" href={ this.props.resource.path }>
            <h4>
              { this.props.resource.title }
            </h4>
          </a>
          <div className="content-card-authors">
            { this.props.resource.author_list }
          </div>
          <div className="content-card-categories">
            { this.renderCategories() }
          </div>
        </div>
      </div>
    )
  }
});

export default ContentCard;