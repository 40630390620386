import React, {useState, useEffect} from 'react';
import CollectionTile from './CollectionTile';
import { Cureus } from '../../Cureus';

const CollectionSelector = ({collectionPath, backLink, preSelectedCollections, collectionDeclared}) => {

  const [radioButtonState, setRadioButtonState] = useState(null);
  const [collections, setCollections] = useState([]);
  const [selectedCards, setSelectedCards] = useState(preSelectedCollections || []);
  const [searchTerm, setSearchTerm] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState('disabled');

  useEffect(() => {
    fetchCollections();
    setInitialRadioButtonIfPreSelectedCollections();
    handleInitialSubmitButton();
    new Cureus.InstaSaver("#save-now");
  }, []);

  useEffect(() => {
    Cureus.ChangeDetectorAlert.init("form");
  }, [collections]);


  const fetchCollections = () => {
    const initial = searchTerm.length === 0 ? true : false;
    const term = searchTerm.length > 0 ? searchTerm : "";

    $.ajax({
      type: "GET",
      url: collectionPath,
      data: { initialFetch: initial, searchTerm: term },
      success: function(res) {
        setCollections(res.cards);
      }
    })
  }

  const setInitialRadioButtonIfPreSelectedCollections = () => {
    if(preSelectedCollections.length > 0) {
      setRadioButtonState(true);
    } else if (collectionDeclared == false) {
      setRadioButtonState(false);
    }
  }

  const handleInitialSubmitButton = () => {
    if (preSelectedCollections.length > 0) {
      setSubmitDisabled('');
    } else if (collectionDeclared == false) {
      setSubmitDisabled('');

    } else {
      setSubmitDisabled('disabled');
    }
  }

  const handleRadioChange = (e) => {
    if (e.target.value == 'true') {
      setRadioButtonState(true);
    } else {
      setRadioButtonState(false);
      setSubmitDisabled('');
      setSelectedCards([]);
    }
  }

  const handleChooseCollection = (collection) => {
    selectYesRadioButtonUponClickingACollection();

    if (isActive(collection)) {      
      setSelectedCards(selectedCards.filter((selected) => {
        return selected.id !== collection.id;
      }))
    } else {
      setSelectedCards([...selectedCards, collection]);
      setSubmitDisabled('');
    }
  }
  
  const selectYesRadioButtonUponClickingACollection = () => {
    if (!radioButtonState) {
      setRadioButtonState(true);
      setSubmitDisabled('');
    }
  }

  const isActive = (isActiveCollection) => {
    return selectedCards.some((collection) => collection.id === isActiveCollection.id);
  }

  const collectionsToAdd = () => {
    return selectedCards.map((collection) => collection.id)
  }

  return (
    <div className="collection-section">
        <input type="hidden" value={collectionsToAdd()} name="article[collections]"/>
        <input type="hidden" value={radioButtonState} name="article[collection_declared]"/>


      <div className="row">
        <div className="large-12 columns">
          <input id="collection_entry_false" type="radio" value="false" checked={radioButtonState === false} onChange={handleRadioChange}/>
          <label htmlFor="collection_entry_false">No, this is not a collection article.</label>
        </div>
      </div>
      <div className="row">
        <div className="large-12 columns">
          <input id="collection_entry_true" type="radio" value="true" checked={radioButtonState === true} onChange={handleRadioChange}/>
          <label htmlFor="collection_entry_true">Yes, this is a collection article.</label>
        </div>
      </div>

      <div>Select a collection below or search for a collection by name or topic area.</div>
      <div className="collection-search-container">
        <input className="search-input" type="text" placeholder="Search for a collection here." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <a className="button small collection-step-search-btn" onClick={fetchCollections}>Search</a>
      </div>

      <div className="collections-container">
        {collections.map((collection, idx) => {
          return (
            <CollectionTile
              key={idx}
              active={isActive(collection)}
              handleChooseCollection={handleChooseCollection}
              collection={collection}
            />
          )
        })}
      </div>

      <div className="step-actions clearfix">
        <div className="row">
          <div className="pull-right">
            <div className="save-success-message" id="save-success-message">
              You have successfully saved your progress.
            </div>
          </div>
        </div>
        <div className="pull-left">
          <a href={backLink} className="button secondary">Back</a>
        </div>
        <div className="pull-right">
          <a id="save-now" className="button special" data-disable-with="Save" disabled={ submitDisabled } data-alert-exception>Save</a>
          &nbsp;
          <input className="button primary" data-disable-with="Save and continue" disabled={ submitDisabled } name="commit" type="submit" value="Save and continue" data-alert-exception/>
        </div>
      </div>

    </div>
  )
}

export default CollectionSelector;