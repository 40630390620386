import React, { useState, useEffect } from 'react';
import AboutMainSettings from './AboutMainSettings';
import AboutSidebarSettings from './AboutSidebarSettings';
import ChannelApi from '../../../Cureus/ChannelApi';
import AboutWidget from './AboutWidget';
import Modal from '../../Modal';
import AboutSidebarContent from './AboutSidebarContent';

const About = ({slug, widgetAboutMainId, widgetAboutSidebarId, initialAboutMainContentWidgets, initialAboutSidebarWidgets}) => {

  const [mainContentWidgets, setMainContentWidgets] = useState({});
  const [sidebarWidgets, setSidebarWidgets] = useState([]);

  useEffect(() => {
    setMainContentWidgets(initialAboutMainContentWidgets)
    setSidebarWidgets(initialAboutSidebarWidgets)
  }, [])

  const handleAddWidget = (params, hasImage=false) => {
    ChannelApi.createWidget(params, slug, function(res) {
      setWidgets(res)
    }, hasImage)
  }

  const handleDeleteWidget = (widgetId) => {
    ChannelApi.destroyWidget(widgetId, slug, function(res) {
      setWidgets(res)
    })
  }

  const handleUpdateWidget = (widgetId, params, hasImage=false) => {
    ChannelApi.updateWidget(widgetId, params, slug, function(res) {
      setWidgets(res)
    }, hasImage)
  }

  const setWidgets = (res) => {
    if(res.ordered_side_rail) {
      setSidebarWidgets(res.ordered_side_rail)
    } else {
      let mainContentWidgetsObj = {...mainContentWidgets}
      mainContentWidgetsObj[res.type] = res.id
      setMainContentWidgets(mainContentWidgetsObj)
    }
    Modal.close()
  }

  return (
    <div className="admin-channel-home-container">
      <div className="row small-collapse">
        <div className="small-8 columns">
        <div className="channel-main-content-container channel-widget-design">
          {mainContentWidgets["Widget::AboutSection"] &&
              <AboutWidget
                slug={slug}
                handleDeleteWidget={handleDeleteWidget}
                handleUpdateWidget={handleUpdateWidget}
                mainContentWidgets={mainContentWidgets}
              />
            }
        </div>
        </div>
        <div className="small-4 columns sidebar-content">
          <AboutSidebarContent
              sidebarWidgets={sidebarWidgets}
              slug={slug}
              handleDeleteWidget={handleDeleteWidget}
              handleUpdateWidget={handleUpdateWidget}
          />
        </div>
      </div>
      <hr className="orange-bar"/>
      <div className="row small-collapse">
        <div className="small-8 columns">
          <AboutMainSettings
            handleAddWidget={handleAddWidget}
            handleUpdateWidget={handleUpdateWidget}
            widgetAboutMainId={widgetAboutMainId}
            slug={slug}
            mainContentWidgets={mainContentWidgets}
          />
        </div>
        <div className="small-4 columns">
          <AboutSidebarSettings
            handleAddWidget={handleAddWidget}
            widgetAboutSidebarId={widgetAboutSidebarId}
            slug={slug}
            sidebarWidgets={sidebarWidgets}
          />
        </div>
      </div>
    </div>
  )
}

export default About;