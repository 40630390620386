
var createReactClass = require('create-react-class');
import React from 'react';
import References from './References';
import Authorships from './Authorships';
import Modal from '../Modal';

const PublishingConfirmationModal = createReactClass({
  getInitialState: function() {
    return {
      checked: false
    }
  },

  handleChecked: function(e) {
    this.setState({
      checked: e.target.checked
    });
  },

  handleSubmit: function() {
    this.props.target.dataset.clickthroughAllowed =  true;
    Modal.close();
    this.props.target.click();
  },

  renderBody: function() {
    if (this.props.type === 'references') {
      return (
        <References  handleChecked={this.handleChecked}/>
      )
    } else {
      return (
        <Authorships  handleChecked={this.handleChecked}/>
      )
    }
  },

  render: function() {
    return (
      <div ref='modal' className='reveal-modal fixed-small' data-reveal>
        <div className='modal-body'>
          <div className='row'>
            {this.renderBody()}
          </div>
          <div className='modal-footer'>
            <a className='button secondary cancel-modal'>Cancel and edit</a>
            <a onClick={ this.handleSubmit } className={"button primary " + (this.state.checked ? "" : "disabled")}>Proceed</a>
          </div>
        </div>
      </div>
    )
  }
});

export default PublishingConfirmationModal;
