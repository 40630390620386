import React from 'react';

const SortButton = ({ text, setSortDirection, sortDirection, sortButtonName,sortBy, setSortBy=null, showCarat=true, clickable=true }) => {

  const selected = () => {
    return sortButtonName == sortBy
  }

  const handleClick = () => {

    if (!setSortBy) return

    setSortBy(sortButtonName)

    if (selected()) {
      let dir = selectSortDirection
      setSortDirection(dir)
    } else {
      setSortDirection("desc")
    }
  }

  const selectSortDirection = () => {
    if (sortDirection == "desc") {
      return "asc"
    } else {
      return "desc"
    }
  }

  const selectedClass = () => {
    return selected() ?  "selected" : ""
  }

  const cursorClass = () => {
    return clickable ? "cursor" : ""
  }

  const handleCarat = () => {
    if (!selected()) { return <i className="fa fa-angle-down carat"/>}

    if (sortDirection == "desc") {
      return <i className="fa fa-angle-down carat"/>
    } else {
      return <i className="fa fa-angle-up carat"/>
    }
  }

  return (
    <p className={`sort-buttons ${selectedClass()} ${cursorClass()}`} onClick={handleClick}>
      {text} {showCarat && handleCarat()}
    </p>
  )
};

export default SortButton;
