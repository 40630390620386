var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../../../Modal';

const QuickviewModal = createReactClass({
  closeModal: function() {
    Modal.close()
  },

  articleComments: function(comments) {
    var self = this;
    if (comments === null || comments.length === 0) {
      return (
        <div className='row'>
          <div className="large-6 large-offset-3 columns">
            <p className="no-comments">Your reviewer has not added any comments yet. Comments will appear here when added.</p>
          </div>
        </div>
      )
    }
    return (
      <ol>
        {comments.map(function(comment) {
          return <li style={self.props.handleStrikethrough()}>{comment}</li>
          })
        }
      </ol>
    )
  },

  generalComments: function(comment) {
    if (comment === null || comment.length == 0) {
      return (
        <div className='row'>
          <div className="large-6 large-offset-3 columns">
            <p className="no-comments">Once your reviewer completes the review, the general comments will appear here.</p>
          </div>
        </div>
      )
    } else {
      return(
        <p style={this.props.handleStrikethrough()}>
          {comment}
        </p>
      )
    }
  },

  questionComments: function(questionHash) {
    return(
      <div>
        <p className="reviewer-question">
         Provide a brief summary of the main research question, claims, and conclusions of the study. How does this research fit within the existing literature?
        </p>
        <p style={this.props.handleStrikethrough()}>
          {questionHash.summary}
        </p>
        <p className="reviewer-question">
          Provide a brief assessment of the research process and presentation while noting any obvious flaws or areas of weakness. Are the conclusions consistent with the evidence presented?
        </p>
        <p style={this.props.handleStrikethrough()}>
          {questionHash.assessment}
        </p>
      </div>
    )
  },

  renderEmphasisBox: function() {
    if(this.props.reviewership.status === 'rejected') {
      return (
        <div className="emphasis-box">
          <p>This review has been rejected. Cureus editors have determined that it does not meet our standards. Editor comments:</p>
          <p>{this.props.reviewership.rejectMessage}</p>
        </div>
      )
    } else if(this.props.reviewership.status === 'expired') {
      return (
        <div className="emphasis-box">
          <p>{this.props.reviewership.greek_symbol} did not submit their review in time, but any comments they did leave should be addressed.</p>
        </div>
      )
    } else if(this.props.reviewership.status === 'reviewing' && this.props.reviewerTimeLeft) {
      var hours = this.props.reviewerTimeLeft === 1 ? 'hour' : 'hours'
      return (
        <div className="emphasis-box">
        <p>{this.props.reviewership.greek_symbol} has up to {this.props.reviewerTimeLeft} {hours} left to complete the review.</p>
        </div>
      )
    }
  },

  reviewLabel: function(currentIdx) {
    return currentIdx === this.props.reviewership.reviewHistory.cycles-1 ? "Peer Review Comments:" : "Re-Review Comments:"
  },

  renderResponses: function(responses) {
    // remove last question about decision
    responses.pop()
    return responses.map(function(response) {
      return (
        <div>
          <p><strong>{response.question}</strong></p>
          <p>{response.response}</p>
        </div>
      )
    })
  },

  renderQuestionnaireResponses: function(responses) {
    return (
      <div>
        <strong>Questionnaire:</strong>
        <br/>
        <br/>
        {this.renderResponses(responses)}
      </div>
    )
  },

  renderNewReviews: function() {
    var self = this;
    return this.props.reviewership.reviewHistory.data.map(function(review, index) {
      if (review.questions) {
        return (
          <div>
            {index > 0 && <br/>}
            <p className="reviewLabel">{self.reviewLabel(index)}</p>
            <strong>Comments in article:</strong>
            {self.articleComments(review.annotations)}
            <strong>Reviewer assessment:</strong>
            {self.questionComments(review.questions)}
            <p></p>
          </div>
        )
      } else {
        return (
          <div>
            {index > 0 && <br/>}
            <p className="reviewLabel">{self.reviewLabel(index)}</p>
            <strong>Comments in article:</strong>
            {self.articleComments(review.annotations)}
            {self.renderGeneralCommentorQuestionnaire(review)}
          </div>
        )
      }
    });
  },

  renderGeneralCommentorQuestionnaire: function(review) {
    if(review.general) {
      return (
        <div>
          <strong>General Comments:</strong>
          {this.generalComments(review.general)}
        </div>
      )
    } else if(review.questionnaire_questions) {
      return (
        <div>
          {this.renderQuestionnaireResponses(review.questionnaire_questions)}
        </div>
      )
    } else {
      return (
        <div>
          <strong>General Comments:</strong>
          {this.generalComments(review.general)}
        </div>
      )
    }
  },

  renderReviews: function() {
    if(this.props.reviewership.reviewHistory.question) {
      return this.renderNewReviews()
    }
    if (this.props.reviewership.reviewHistory.data.length === 0) {
      return (
        <div>
          <strong>Comments in article:</strong>
          {this.articleComments(null)}
          <strong>General Comments:</strong> 
          {this.generalComments(null)}
        </div>
      )
    }
    var self = this;
    return this.props.reviewership.reviewHistory.data.map(function(review, index) {
      return (
        <div>
          {index > 0 && <br/>}
          <p className="reviewLabel">{self.reviewLabel(index)}</p>
          <strong>Comments in article:</strong>
          {self.articleComments(review.annotations)}
          {self.renderGeneralCommentorQuestionnaire(review)}
        </div>
      )
    });
  },

  render: function() {
    return (
      <div className="reveal-modal medium reviewer-quickview-modal" data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className="modal-content">
            <h4>Reviewer {this.props.reviewership.greek_symbol + '\'s'} Activity</h4>
            <div className="quickview-modal-content">
              <table className="dashboard-table rev-activity">
                <thead>
                  <tr>
                    <th className="large-2">Status</th>
                    <th className="large-3">Reviewer</th>
                    <th className="large-4">Highlight</th>
                    <th className="large-3">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="no-hover-row">
                    {this.props.renderCircle("large-2")}
                    <td className="large-3" style={this.props.handleStrikethrough()}> {this.props.reviewership.greek_symbol} </td>
                    <td className="large-4">
                       <div className={"colored-box " + this.props.reviewership.greek_symbol.toLowerCase() }/>
                    </td>
                    <td className="large-3" style={this.props.handleStrikethrough()}> {this.props.reviewership.reviewHistory.count} </td>
                  </tr>
                </tbody>
              </table>
              {this.renderEmphasisBox()}
              {this.renderReviews()}
            </div>
          </div>
          <div className="modal-footer" id="quickview-modal-footer">
            <input type="button" className="button secondary" value="Close" onClick={ this.closeModal }/>
            <a href={this.props.articlePath} target="_blank" className="button primary">View Article</a>
          </div>
        </div>
      </div>
    )
  }
})

export default QuickviewModal;