var createReactClass = require('create-react-class');
import React from 'react';

const EllipsePage = createReactClass({

	handlePageSelect: function(e) {
    e.preventDefault();
    if(this.props.pageNumber == 0 || this.props.pageNumber > this.props.totalPages){
      return;
    }
		this.props.handlePageSelect(this.props.pageNumber);
	},

  handleCustomCharacterDisplay: function() {
		return this.props.character ? this.props.character : this.props.pageNumber
	},

	checkCurrentPage: function(){
		return this.props.pageNumber == this.props.currentPage ? "current" : "";
	},

	render: function() {

    if(this.props.type == "ellipses") {
      return (
        <li className= {this.checkCurrentPage()} >
          <a onClick= {this.handlePageSelect}> <img src={this.checkCurrentPage() ? "https://public.cureus.com/collections/ellipse-black.png" : "https://public.cureus.com/collections/ellipse-white.png"} /> </a>
        </li>
      )
    }

    return (
      
			<li className= {this.checkCurrentPage()} >
				<a onClick= {this.handlePageSelect}> {this.handleCustomCharacterDisplay()} </a>
			</li>
		)
	}
})

export default EllipsePage;