import { useState, useEffect } from 'react';
import React from 'react';
import EditorMessagesTable from './EditorMessagesTable';

const Main = ({indexPath, updateTablePath}) => {

  useEffect(() => {
  }, []);

  
  return (
    <div className='main'>
      <h1>Article Message Board Queue</h1>
      <EditorMessagesTable indexPath = {indexPath} updateTablePath = {updateTablePath}/>
    </div>
  )


}


export default Main;