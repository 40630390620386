import React from 'react';

const TopAuthor = ({author}) => {
  return (
    <div className='author-container'>
      <div className='avatar'>
      <img src={author.avatar} onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src="https://public.cureus.com/new-default-user-image.png"
        }}/>
      </div>
      <div className='description'>
        <a className='name' href={`/users/${author.user_id}`} target='_blank'>{author.full_name}</a>
        <div className='info'>
          <div className='qualification'>{author.qualifications}</div>
          <div className='specialty'>{author.specialty}</div>
        </div>
      </div>
    </div>
  )
}

export default TopAuthor;