import {Cureus} from '../Cureus';

Cureus.ChangeDetectorAlert = (function($,_){
	var init, ChangeDetectorAlert;

	function shouldDisable() {
		var pages = ["/publish/articles/new"]
		if (pages.includes(window.location.pathname)) { return true;}
	}

	init = function(selector) {
		if (shouldDisable()) { return; }
		new ChangeDetectorAlert(selector);
	},

	ChangeDetectorAlert = function(selector) {
		this.$form = $(selector);
		this.changed = false;
		this.excludedElems = ["a[target='_blank']:not(#preview-button)", "[data-alert-exception=true]", "a.close", ".reveal-modal a", ".reveal-modal input.button", ".submit-review-trigger-button", ".collection-step-search-btn"];
    this.$confirmBoxTarget = $("a").not(this.excludedElems.join(','));
    this.$saveButton = $("#save-now");
		this._bindEvents();
	},

	_.extend(ChangeDetectorAlert.prototype, {
		_bindEvents: function() {
			var self = this;
			self.detectChange();
      self.handleNextButton();
			self.handleNavOrBack();
      self.handleSave();
		},

		handleNavOrBack: function() {
			var self = this;
			self.$confirmBoxTarget.click(function(a) {
    		if (self.changed == true) {
					self.unsetDefaultAlertMessage();
					if (!confirm("Click 'Cancel' and select the 'Save' button to save your work before navigating away from this page. Click 'OK' to proceed without saving.")) {
						a.preventDefault();
            return false;
					}
				}
			})
		},

    handleNextButton: function() {
      var self = this;
      $("[data-alert-exception=true]").click( function() {
        self.unsetDefaultAlertMessage()
      })
    },

    handleSave: function() {
      var self = this;
      this.$saveButton.click( function() {
        self.changed = false
      })
    },

		detectChange: function() {
			var self = this;
			self.$form.on("input change", function() {
				self.changed = true;
				self.setDefaultAlertMessage();
			});
		},

    setDefaultAlertMessage: function() {
      $(window).on('beforeunload', function() {
        return 'Changes you made may not be saved.';
      });
    },

		unsetDefaultAlertMessage: function() {
			$(window).off('beforeunload');
		}
	})

  return { init: init };
})(jQuery, _);



