import React, { Component } from 'react';
import Article from './Article'

class ArticleTabs extends Component {
  componentDidMount() {
    document.querySelector('.on-demand-tab').classList.add('active')
    document.querySelector('.on-demand-content').classList.add('active')
    $(document).foundation('reflow');
  }

  renderTabs = () => {
    return this.props.articles.map((article, idx) => {
      return (
          <li className={"tab-title on-demand-tab"}>
            <a href={"#article-" + article.id} onClick={this.props.handleSelection} name={idx + 1}>ARTICLE {idx + 1}</a>
          </li>
      )
    })
  }

  renderContent = () => {
    return this.props.articles.map(function(article) {
      return (
        <div className="content on-demand-content" id={"article-" + article.id}>
          <Article article={article}/>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <ul className="tabs on-demand-tabs-container" data-tab>
          {this.renderTabs()}
        </ul>
        <div className="tabs-content">
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

export default ArticleTabs;