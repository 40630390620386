var createReactClass = require('create-react-class');
import React from 'react';
import Card from './Card';
import BigArticleCard from '../Shared/ContentCards/BigArticleCard';
import SmallArticleCard from '../Shared/ContentCards/SmallArticleCard';

const Content = createReactClass({
  
  getArticlesPath: function() {
    if (this.props.selectedId != 0) {
      return this.props.categoryUrl
    } else {
      return '/articles'
    }
  },

  fireAmplitude: function(event, data) {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: event, 
              user_id: this.props.currentUserId,
              specialty: this.props.selected,
              ...data
            }
    })
  },

  renderLeft: function() {
    var self = this;
    return self.props.resources.slice(0, 3).map(function(resource, idx) {
      if(idx === 0) {
        return (
          <div className="small-12 columns no-pad" onClick={()=>{
            self.fireAmplitude("topArticleClicked", {article_id: resource.id})
          }}>
            <BigArticleCard resource={resource.attributes}/>
          </div>
        )
      } else {
        return (
          <div className="small-12 columns no-pad" onClick={()=>{
            self.fireAmplitude("topArticleClicked", {article_id: resource.id})
          }}>
            <SmallArticleCard resource={resource.attributes}/>
          </div>
        )
      }
    })
  },

  renderRight: function() {
    var self = this;
    return self.props.resources.slice(3, 8).map(function(resource) {
      return (
        <div className="small-12 columns no-pad" onClick={()=> {
          self.fireAmplitude("topArticleClicked", {article_id: resource.id})
        }}>
          <SmallArticleCard resource={resource.attributes}/>
        </div>
      )
    })
  },

  renderViewButton: function () {
    if (!this.props.landing) {
      return (
        <div className="view-all-articles">
          <a className="button alternate" href={this.getArticlesPath()}>View All Articles</a>
        </div>
      )
    }
  },


  render: function() {
    return (
      <div className="large-12 columns featured-articles">
        <div className="small-12 medium-12 large-6 columns left-container no-pad">
          {this.renderLeft()}
        </div>
        <div className="small-12 medium-12 large-6 columns right-container no-pad">
          {this.renderRight()}
          <div className="right view-more-top-articles small-12 columns">
            <a href={this.getArticlesPath()} onClick={()=>{this.fireAmplitude('viewTopArticlesClicked')}}>View More <i class="fa fa-arrow-right"/></a>
          </div>
        </div>
      </div>
    )
  }
})

export default Content;