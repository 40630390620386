var createReactClass = require('create-react-class');
import React from 'react';

const Card = createReactClass({
  renderDetails: function() {
    let resource = this.props.resource.attributes;
    let className = resource.has_thumbnail ? 'medium-9 medium-pull-3 columns' : 'medium 9 columns';
    return (
      <div className={className}>
        <div className="card-header">
          <span className="date">
            {resource.published_date}
          </span>
          <span className="type">
            {resource.humanized_type}
          </span>
        </div>
        <div className="card-title">
        <a href={resource.path}>{resource.title}</a>
        </div>
        <div className="authors">
          {resource.author_list}
        </div>
      </div>
    )
  },

  renderThumbnail: function() {
    let resource = this.props.resource.attributes;
    if (resource.has_thumbnail) {
      return (
        <div className="medium-3 medium-push-9 columns thumbnail-wrap">
          <a href={resource.path} target="_blank">
            <div className="thumbnail-mask"/>
            <div key={resource.id + "thumb"} dangerouslySetInnerHTML={{__html: resource.thumbnail}}/>
          </a>
        </div>
      )
    }
  },

  render: function() {
    return (
      <div className="row card">
        {this.renderThumbnail()}
        {this.renderDetails()}
        {!this.props.last && <hr/>}
      </div>
    )
  }
})

export default Card;