import React, { useState, useEffect } from 'react';

const Plummary = ({article}) => {

  useEffect(() => {
    setTimeout(function() {
      window.__plumX.widgets.init();
    }, 1000)
  }, [])

  return (
    <div className="row small-collapse">
      <div className="large-12 columns">
        <div className="metrics-section">
          <h3 className="reg">Article Research Metrics</h3>
          <p>
            We’ve partnered with <a href="https://plumanalytics.com/">Plum Analytics</a> to track and display research metrics for each article. (All data provided by PlumX Metrics.) 
          </p>
          <a className="plumx-details" href={`https://plu.mx/plum/a/?doi=${article.doi}`}></a>
        </div>
      </div>
    </div>
  )
}

export default Plummary;