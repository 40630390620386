var createReactClass = require('create-react-class');
import React from 'react';
import ChannelTeamMember from './ChannelTeamMember';
import Twitter from './Twitter';
import Promotion from './Promotion';

const Widget = createReactClass({
  componentWillMount: function(){
    this.setState({widget: this.props.widget})
  },

  getInitialState: function() {
    return({
      open: false
    })
  },

  closeWidget:function() {
    this.setState({open: false})
  },

  destroyWidget: function() {
    var self = this;
    if (confirm("Are you sure?")) {
      $.ajax(self.state.widget.deleteWidgetPath, {
        dataType: "json",
        type: "DELETE",
        success: function(data) {
          self.props.updateAllWidgets(data)
        }
      })
    }
  },

  toggleWidgetContent: function() {
    if (this.props.canManage) {
      var toggleState = (this.state.open === false ? true : false)
      this.setState({open: toggleState})
    }
  },

  updateWidget: function(widget) {
    this.setState({widget: widget})
  },

  handleSubmitForm: function(e) {
    if (["channel_promotion", "promotion"].includes(this.props.widget.name)) {
      this.submitWidgetFormWithImage(e)
    } else {
      this.submitWidgetWithoutImage(e)
    }
  },

  submitWidgetFormWithImage: function(e) {
    var self = this;
    let formFile = self.createFormData(e)
    e.preventDefault();
    fetch(self.state.widget.updateWidgetPath, {
      method: "PUT",
      body: formFile,
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $("[name='csrf-token']").attr("content")
      }
    })
    .then(function(response) {return response.json()})
    .then(function(data) {
      self.updateWidget(data);
      self.closeWidget();
    })
  },

  createFormData: function(e) {
    let formFile = new FormData()
    for (let i=0; i < e.target.elements.length; i++) {
      let ele = e.target.elements[i]
      if (ele.id == "image") {
        formFile.append(ele.name, ele.files[0])
      } else if (ele.name) {
        formFile.append(ele.name, ele.value)
      }
    }
    return formFile
  },

  submitWidgetWithoutImage: function(e) {
    var self = this;
    e.preventDefault();
    let form = e.target
    $.ajax(self.state.widget.updateWidgetPath, {
      dataType: "json",
      type: "PUT",
      data: $(form).serialize(),
      success: function(data) {
        self.updateWidget(data);
        self.closeWidget();
      }
    })
  },

  renderLockIcon: function() {
    if (this.props.widget.name == "promotion") {
      return (
        <i className="fa fa-lock" />
      )
    }
  },

  renderCloseAndSaveButtons: function() {
    if (this.props.canManage) {
      if (this.state.open) {
        return (
          <div className="pull-right">
            <a onClick={this.toggleWidgetContent} className="button tiny secondary">Close</a>
            <input type="submit" value="Save" className="button tiny primary"/>
          </div>
        )
      } else if (!this.state.open) {
        return (
        <div className="pull-right">
          <a onClick={this.toggleWidgetContent} className="button tiny secondary">Edit</a>
        </div>
        )
      }
    }
  },

  renderDeleteButton: function() {
    if (this.props.canManage) {
      return (
        <a href="javascript:void(0)" onClick={this.destroyWidget} className="button alert tiny"><i className="far fa-trash-alt"/></a>
      )
    } else {
      return (
        <i className="fa fa-lock" />
      )
    }

  },

  renderWidgetContent: function() {
    let widgets = {
      "channel_promotion": <Promotion
                            open={this.state.open}
                            widget={this.state.widget}
                            handleSubmitForm={this.handleSubmitForm}/>,
      "twitter": <Twitter
                            open={this.state.open}
                            handleFormFieldInput={this.handleFormFieldInput}
                            widget={this.state.widget}/>,
      "promotion": <Promotion
                            open={this.state.open}
                            handleSubmitForm={this.handleSubmitForm}
                            widget={this.state.widget}/>,
      "channel_team_small": <ChannelTeamMember
                            open={this.state.open}
                            widget={this.state.widget}/>
    }
    return widgets[this.props.widget.name]
  },

  render: function() {
    return (
      <div onDoubleClick={this.toggleWidgetContent} id={this.props.widget.id} className={"edit-page-widget " + this.state.widget.cssClass} >
        <form encType="multipart/form-data" className="edit_widget" id="edit_widget" onSubmit={this.handleSubmitForm}>
          <div className="edit-widget-title">
            <h5 className="reg">{this.renderLockIcon()} {this.state.widget.friendlyName} </h5>
          </div>
          {this.renderWidgetContent()}
          <div className="widget-controls-wrap">
            <div className="pull-left">
              {this.renderDeleteButton()}
            </div>
            {this.renderCloseAndSaveButtons()}
          </div>
          <div className="clearfix"/>
        </form>
      </div>
    )
  }
})

export default Widget;