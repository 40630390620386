var createReactClass = require('create-react-class');
import React from 'react';
import JudgeRows from './JudgeRows';
import JudgeInputField from './JudgeInputField';

const Main = createReactClass({
	getInitialState: function() {

		return ({
			judgeships: [],
			judgeView: 'show',
			envelopeColor: "black",
			emailDisable: true
		})
	},

	componentWillMount: function(){
		this.getJudgeships( function() {
			this.handleEmptyJudgeships()
		})
	},

	getJudgeships: function(callback) {
    var self = this;
    $.get(self.props.indexJudgePath, function(data) {
      self.setState({
        judgeships: data,
        judgeView: 'show'
      }, callback )
    }, "json")

	},

	deleteJudgeship: function(judgeId) {
		var self = this;
		$.ajax({
      type: "DELETE",
      url: "/admin/competitions/" + this.props.competitionId + "/judges/" + judgeId,
      success: function() {
      	self.getJudgeships(function() {self.handleEmptyJudgeships()	});
      	self.handleCheckboxSelect()

      }
    })
	},

	sendOrientationEmails: function(element) {
		var self = this;
		var $form = $(this.refs.form);
		$.post(self.props.emailJudgesPath, $form.serialize(), function() {
			self.getJudgeships();
			self.clearCheckboxes();
			self.showSuccessMessage();
		});

		element.preventDefault();

	},

	showSuccessMessage: function() {
		$("#flash_container").append( "<div id='flash'><div class='alert-box success' title='success'><div class='row'><div class='large-12 columns'><a class='close' href='#'><div class='flash-close-btn'></div></a><span class='flash-content'>Invitations were sent to reviewers.</span></div></div></div></div>");

		$('.close').click(function() {
			$('#flash').remove()
		})
	},

	showJudgeInputField: function() {
		this.setState({
			judgeView: "new"
		})
	},

	handleCheckboxSelect: function() {
		this.setState({
			envelopeColor: this.checkCheckboxes() ? "#0096b7" : "black",
			emailDisable: this.checkCheckboxes() ? false : true
		})
	},

	handleEmptyJudgeships: function() {
		if (this.state.judgeships.length == 0) {
			this.showJudgeInputField();
		}
	},

	showEnvelopeIcon: function() {
		if ( this.state.judgeships.length > 0) {
			return <i style={{ color: this.state.envelopeColor }} className="far fa-envelope"></i>
		}
	},

	checkCheckboxes: function() {
		return $("[data-checkbox]:checked").length > 0 ? true : false
	},

	clearCheckboxes: function() {
		$("[data-checkbox]:checked").prop({checked:false})
		this.handleCheckboxSelect();
	},

	render: function() {
		return (
			<form ref='form' className="judgeship" acceptCharset="UTF-8" method="post">
				<input name="utf8" type="hidden" value="✓" />

				<div className="row">
					<div className="small-12 columns">
						<h5>{ this.showEnvelopeIcon() }<span> User </span></h5>
					</div>
				</div>

				<div>
					<div>
						<JudgeRows
							judgeships = { this.state.judgeships }
							deleteJudgeship = { this.deleteJudgeship }
							handleCheckboxSelect = { this.handleCheckboxSelect }
						/>
					</div>
				</div>

				<JudgeInputField
					judgeships = { this.state.judgeships }
					judgeView = { this.state.judgeView }
					getJudgeships = { this.getJudgeships }
					indexJudgePath = { this.props.indexJudgePath }
					createJudgePath = { this.props.createJudgePath }
					competitionId = { this.props.competitionId }
					handleCheckboxSelect = { this.handleCheckboxSelect }
					handleEmptyJudgeships = { this.handleEmptyJudgeships}
				/>

				<hr></hr>

				<div className="row">
					<div className="small-12 columns center">
						<a src="#" onClick={ this.showJudgeInputField } disabled={this.state.judgeView == "new" ? true : false} className="button secondary small">Add another reviewer</a>
					</div>
				</div>

				<hr></hr>

				<div className="row">
					<div className="small-12 columns center">
						<input disabled={ this.state.emailDisable } onClick={ this.sendOrientationEmails } className="button primary" name="commit" type="submit" value="Email Invitation to Selected Reviewers" />
					</div>
				</div>

			</form>
		)
	}
});

export default Main;