
var createReactClass = require('create-react-class');
import React from 'react';
import Post from './Forms/Post';

const GettingStarted = createReactClass({
  render: function() {
    return (
      <Post action={ this.props.path }>
        <div className='row'>
          <div className='large-4 large-offset-4 columns'>
            <select name="article[type]" required>
              <option value="">Select article type</option>
              { 
                this.props.all_types.map(function(data) {
                  return <option value={ data[1] }>{ data[0] }</option>
                })
              }
            </select>
          </div>
        </div>

        <hr/>

        <div className='pull-right'>
          <a href="/dashboard/my_documents" className="button secondary">Cancel</a>
          &nbsp;
          <input className="button primary" name="commit" type="submit" value="Let's Begin"/>
        </div>
      </Post>
    )
  }
});

export default GettingStarted;
