var createReactClass = require('create-react-class');
import React from 'react';

const Filter = createReactClass({
  handleTypeChange: function(type) {
    var filter = _.extend({}, this.props.filter, {type: type, condition: this.filterFor(type).conditions[0].value});
    this.props.onUpdate(filter);
  },

  handleConditionChange: function(condition) {
    var filter = _.extend({}, this.props.filter, {condition: condition});
    this.props.onUpdate(filter);
  },
  
  handleInputValueChange: function(e) {
    var value = e.target.value;
    var filter = _.extend({}, this.props.filter, {value: value});
    this.props.onUpdate(filter);
  },

  handleSelectValueChange: function(value) {
    var filter = _.extend({}, this.props.filter, {value: value});
    this.props.onUpdate(filter);
  },

  buildType: function() {
    var options = _.map(this.props.availableFilters, function(filter) {
      return filter.type;
    });

    return (
      <Select 
        options={ options } 
        searchable={ false }
        value={ this.props.filter.type }
        clearable={ false }
        onChange={ this.handleTypeChange }
        className={ "plain-select" }
      />
    )
  },

  buildCondition: function() {
    if(this.props.filter.type != null) {
      var options = this.filterFor(this.props.filter.type).conditions;

      return (
        <Select 
          options={ options } 
          searchable={ false }
          value={ this.props.filter.condition }
          clearable={ false }
          onChange={ this.handleConditionChange }
          className={ "plain-select" }
        />
      )
    }
  },

  buildRemoveBtn: function (){
    if(this.props.isRemovable){
      return (
        <div className="add-remove-filter remove-filter">
          <a href="#" onClick={this.handleRemove}>
            <i className="fa fa-minus-circle"></i>
          </a>
        </div>
      )
    }else{
      return null;
    }
  },

  buildValue: function() {
    if(this.props.filter.type != null) {
      switch(this.getFilter().value.type) {
        case "text":
          return <input type='text' defaultValue={ this.props.filter.value } onChange={ this.handleInputValueChange }/>
          break;
        case "multiselect":
          return (
            <Select 
              options={ this.getFilter().value.options } 
              value={ this.props.filter.value }
              searchable={ false }
              multi={ true }
              clearable={ false }
              onChange={ this.handleSelectValueChange }
              className={ "plain-select" }
            />
          )
          break;
        case "number":
          return (
            <div className='row'>
              <div className='large-3 columns'>
                <input type='number' defaultValue={ this.props.filter.value } onChange={ this.handleInputValueChange }/>
              </div>
            </div>
          )
        case "select":
          return (
            <Select 
              options={ this.getFilter().value.options } 
              value={ this.props.filter.value }
              searchable={ false }
              clearable={ false }
              onChange={ this.handleSelectValueChange }
              className={ "plain-select" }
            />
          )
      }
    }
  },

  getFilter: function() {
    return this.filterFor(this.props.filter.type);
  },

  filterFor: function(type) {
    var self = this;
    return _.find(self.props.availableFilters, function(filter) {
      return filter.type.value == type;
    });
  },

  handleRemove: function (e){
    e.preventDefault();
    this.props.onRemove(e, this.props.filter);
  },

  handleSubmit: function(e) {
    e.preventDefault();
  },

  render: function() {
    return (
      <div className='row'>
        <form onSubmit={this.handleSubmit}>
          <div className='large-1 columns'>
            { this.buildRemoveBtn() }
          </div>
          <div className='large-3 columns'>
            { this.buildType() }
          </div>
          <div className='large-3 columns'>
            { this.buildCondition() }
          </div>
          <div className='large-5 columns'>
            { this.buildValue() }
          </div>
        </form>
      </div>
    )
  }
});

export default Filter;