var createReactClass = require('create-react-class');
import React from 'react';
import Filter from './Filter';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const FilterTool = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("FilterTool")
  ],

  getStateFromFlux: function() {
    var filters = this.getFlux().store("FilterTool").getState().filters;

    return {
      filters: filters,
      key: filters.length
    }
  },

  handlePreview: function(e) {
    this.getFlux().actions.addFilters(this.state.filters);
    this.props.onSave();
    e.preventDefault();
  },

  handleCancel: function(e) {
    this.setState({filters: []});
    this.props.onCancel();
    e.preventDefault();
  },

  addFilter: function(e) {
    var filters = this.state.filters;
    var nextKey = this.state.key + 1;
    var blankFilter = {
      type: null,
      condition: null,
      value: null,
      key: nextKey,
      uniqueKey: _.uniqueId()
    }

    filters.push(blankFilter);

    this.setState({
      filters: filters,
      key: nextKey
    });

    e.preventDefault();
  },

  handleFilterUpdate: function(updatedFilter) {
    var filters = this.state.filters;
    var filter = _.find(filters, function(oldFilter) {
      return oldFilter.key == updatedFilter.key;
    });

    _.extend(filter, updatedFilter);

    this.setState({filters: filters});
  },

  handleRemoveFilter: function (e, filter){
    var filters;
    filters = this.state.filters;
    filters = _.without(filters, filter);
    _.each(filters, function (filter, index, list){ // Renumber filters after removing one
      filter.key = index;
    });
    this.setState({filters: filters});
  },

  render: function() {
    var self = this;
    var availableFilters = this.getFlux().store("FilterTool").getAvailableFilters();
    var filters = _.map(self.state.filters, function(filter) {
      return (
        <Filter 
          key={filter.uniqueKey} 
          filter={filter} 
          availableFilters={availableFilters}
          onUpdate={self.handleFilterUpdate} 
          onRemove={self.handleRemoveFilter}
          isRemovable={self.state.filters.length > 1}
        />
      )
    });

    return (
      <div>
        <div className='row'>
          <div className='large-12 columns'>
            { filters }
          </div>
        </div>

        <div className='row'>
          <div className='large-12 columns'>
            <div className='add-remove-filter add-filter'>
              <a onClick={self.addFilter}>
                <i className='fa fa-plus-circle'></i>
                Add condition
              </a>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='large-12 columns'>
            <a className='button primary' onClick={self.handlePreview}>
              Preview Results
            </a>
            &nbsp;
            <a className='button secondary' onClick={self.handleCancel}>
              Cancel
            </a>
          </div>
        </div>
      </div>
    )
  },
});

export default FilterTool;