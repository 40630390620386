import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import Modal from '../../Modal';
import ApproveOrRejectModal from '../ApproveOrRejectModal';

const Table = ({label, card_url, collectionId, pendingArticlesData, fetchData}) => {

  useEffect(() => {
    populateTable(pendingArticlesData);
  }, [pendingArticlesData])

  const setColumns = () => {
    return [
      {class: 'show-for-large-up'},
      {class: 'show-for-large-up'},
      {class: 'title'},
      {class: 'show-for-medium-up'},
      {class: 'centered buttons-container'}
    ]
  }

  const mapColumns = (dt, row) => {
    let viewUrl = row.state === 'approved' ? `/publish/articles/${row.id}/preview/?collection=${row.collection_id}` : `/articles/${row.id}`
    const editButtons = `
      <div class='desktop-buttons-container'>
        <a class="text-center button show-for-large-up" href=${viewUrl} target="_blank">View</a>
        <a class="text-center button success show-for-large-up" data="${row.cureus_collection_article_id}">Approve</a>
        <a class="text-center button edit alert show-for-large-up" data="${row.cureus_collection_article_id}">Reject</a>
      </div>

      <div class='show-for-medium-down mobile-buttons-container'>
        <a class="mobile-approve" data="${row.cureus_collection_article_id}">
          <img class='approve-reject-icons' src="https://public.cureus.com/my_dashboard/my_collections/approve-icon.png" />
        </a>
        <a class="mobile-reject" data="${row.cureus_collection_article_id}">
          <img class='approve-reject-icons' src="https://public.cureus.com/my_dashboard/my_collections/reject-icon.png" />
        </a>
      </div>
    `;
    return (
      dt.fnAddData( [
        row.most_recent_article_history_event_date,
        `<a class='email' target='_blank' href='/users/${row.submitter_id}'>${row.submitter}</a>`,
        `<a href=${viewUrl}>${row.title}</a>`,
        row.categories,
        editButtons
      ], false)
    )
  }

  const populateTable = (fetchedTableData) => {
    var dt = $(`#${collectionId}-table`).DataTable({
      destroy: true,
      columns: setColumns(),
      columnDefs: [
        { width: '15%', targets: 0 },
        { width: '10%', targets: 1 },
        { width: '35%', targets: 2 },
        { width: '10%', targets: 3 },
        { width: '30%', targets: 4 },
        // putting searchable true option first will the targed column searchable
        {searchable: true, targets: [1]},
        {searchable: false, targets: [0,1,2,3,4]},
        { className: 'all', targets: -1 },
        {
          // when displaying, show date in MM-DD-YYYY format, when sorting it will sort by ISO date
          targets: [1],
          render: (date, type, row) => {
            return (type === 'display') ? date : moment(date, "MM-DD-YYYY").toISOString();
          }
        }
      ],
      scrollY: '70vh',
      scrollCollapse: true,
      paging: false,
      searching: false,
      "rowCallback": function(row) {
        let approveBtn = row.children[4].children[0].children[1];
        let ccaId = row.children[4].children[0].children[1].getAttribute("data");
        approveBtn.addEventListener('click',function() {
          openApproveOrRejectModal(ccaId, "approve");
        })
        let rejectBtn = row.children[4].children[0].children[2];
        rejectBtn.addEventListener('click',function() {
          openApproveOrRejectModal(ccaId, "reject");
        })
        let tabletMobileApproveBtn = row.children[4].children[1].children[0];
        tabletMobileApproveBtn.addEventListener('click',function() {
          openApproveOrRejectModal(ccaId, "approve");
        })
        let tabletMobileRejectBtn = row.children[4].children[1].children[1];
        tabletMobileRejectBtn.addEventListener('click',function() {
          openApproveOrRejectModal(ccaId, "reject");
        })
      },
      dom: '<"row">tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "bAutoWidth": false,
      "fnDrawCallback": function(oSettings) {
        $(oSettings.nTableWrapper).find('.dataTables_info').hide();
      },
    })

    dt.fnClearTable();
    for (var i = 0; i < fetchedTableData.length; i++) {
      let row = fetchedTableData[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']]);
    dt.fnDraw();
  }

  const renderHeaderRow = () => {
    return (
      <div className="row article-state-row">
        <img className="thumbnail" src={card_url}/>
        <div className="article-state-label text-left">
          <h4>{label}</h4>  
        </div>
      </div>
    )
  }

  const openApproveOrRejectModal = (ccaId, action) => {
    Modal.open({
      children:
        <ApproveOrRejectModal 
          collectionId={collectionId}
          action={action}
          fetchData={fetchData}
          ccaId={ccaId}
          onActionComplete={fetchData}
        />
    })
  }

  return (
    <React.Fragment>
      <div className="article-state-row-container">
        {renderHeaderRow()}
          <div className="channel-articles-datatable-container">
            <table id={`${collectionId}-table`}>
              <thead>
                <tr>
                  <th>Entered Queue</th>
                  <th>Author</th>
                  <th>Title</th>
                  <th>Categories</th>
                  <th/>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
      </div>

    </React.Fragment>
  )
}

export default Table;