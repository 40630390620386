var createReactClass = require('create-react-class');
import React from 'react';
import NewSubmitModal from './NewSubmitModal';
import Modal from '../Modal';

const Main = createReactClass({
  getInitialState: function() {
    return {
      summary: '',
      assessment: '',
      quality: 1,
      credibility: null,
      comment: '',
      disclosure: '',
      disclosure_declared: null,
      submitting: false
    }
  },

  handleMainStateChange: function(key, value) {
    this.setState({[key]: value})
  },

	componentDidMount: function(){
    var self = this;
    $('#action-dropdown-submit-tooltip-' + self.props.reviewershipId).on('click', function(){
      self.openSubmitReviewModal();
    })
  },

  openSubmitReviewModal: function(){
    Modal.open({
      children: <NewSubmitModal
                  annotationsUrl={ this.props.annotationsUrl }
                  reviewershipId={ this.props.reviewershipId }
                  completeReviewPath={ this.props.completeReviewPath }
                  articleId={this.props.articleId}
                  handleMainStateChange={this.handleMainStateChange}
                  step={1}
                  summary={this.state.summary}
                  assessment={this.state.assessment}
                  quality={this.state.quality}
                  credibility={this.state.credibility}
                  comment={this.state.comment}
                  disclosure={this.state.disclosure}
                  disclosure_declared={this.state.disclosure_declared}
                  submitting={this.state.submitting}
                />
    });
  },

  render: function(){
    return (
      <div/>
    )
  }
})

export default Main;