var createReactClass = require('create-react-class');
import React from 'react';

const NpiRegistered = createReactClass({
  renderMessage: function() {
    if(this.props.npi === 'none') {
      return (
        <div className="message-wrapper">
          <div>There is no Identification Number associated with your account.</div>
          <div>Please click the edit button to add one.</div>
        </div>
      )
    } else {
      return (
        <div>
          <p>Identification Type: &nbsp; {this.props.details ? "Other" : "National Provider Identifier (NPI)"}</p>
          <p>Identification Number: &nbsp; {this.props.npi}</p> 
        </div>
      )
    }
  },

  render: function(){
    return (
      <div className="modal-content">
        <h4>Identification Number</h4>
        <p>Click the Edit button to update your identification number.</p>
        <div className="row">
          <div className="npi-box">
            {this.renderMessage()}
            <a className="button right" onClick={this.props.handleEdit}>Edit</a>
          </div>
        </div>
      </div>
    )
  }
})

export default NpiRegistered;