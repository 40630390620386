import React, {useState} from 'react';
import { useEffect } from 'react';

const MultimediaCarousel = ({homepageFeaturedMultimedia, currentUserId}) => {

  const [media, setMedia] = useState(homepageFeaturedMultimedia);
  const [displayingCards, setDisplayingCards] = useState([]);
  const [offset, setOffset] = useState(0);
  // offsetCount set to 5 instead of 4 to decide whether or not to disable the right arrow button if less than 5
  const [offsetCount, setOffsetCount] = useState(5);

  useEffect(() => {
    let params = { offset };
    $.ajax({
      type: 'GET',
      url: '/featured_multimedia',
      data: params,
      dataType: 'json',
      success: function(res) {
        let showCurrentPage = res.slice(0, 4);
        setDisplayingCards(showCurrentPage);
        setMedia(res);
      }
    })
  }, [offset])

  const fireAmplitude = (eventType, data) => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: eventType,
              user_id: currentUserId,
              ...data
            }
    })
  }

  const offSetRight = () => {
    fireAmplitude('multimediaClicked', {type: 'right-arrow'})
    setOffset(offset + (offsetCount - 1));
  }
  
  const offSetLeft = () => {
    fireAmplitude('multimediaClicked', {type: 'left-arrow'})
    if(offset === 0) {
      return
    }
    setOffset(offset - (offsetCount - 1));
  }
  
  const disableLeftArrow = () => {
    if(offset === 0) {
      return 'disable-arrow';
    }
  }
  
  const disableRightArrow = () => {
    if (media.length < 5) {
      return 'disable-arrow';
    }
  }
  
  const renderButtons = () => {
    return (
      <>
        <i class={"far fa-arrow-alt-circle-left arrows " + disableLeftArrow()} onClick={offSetLeft}></i>
        <i class={"far fa-arrow-alt-circle-right arrows " + disableRightArrow()} onClick={offSetRight}></i>
      </>
    )
  }

  const renderMedias = () => {
    return displayingCards.map((medium) => {
        return (
          <div className="small-6 medium-3 large-3 columns attachment-container" onClick={()=>{fireAmplitude('multimediaClicked', {type: 'video', url: medium.url})

          }}>
            <a href={medium.url} target="_blank">
              <div className='image-container'>
                  <img className='image-size' src={medium.image.url}/>
              </div>
              <h6>{medium.name}</h6>
            </a>
          </div>
        )
      })
  }
  
  return (
    <>
      <div className="widget featured-multimedia-section">
        <div className='row'>
          <h3 className='header-title'>Featured Multimedia</h3>
        </div>
        <div className='row' style={{ display: (displayingCards.length < 4 ? 'flex' : 'block'), minHeight: '280px'}}>
          {renderMedias()}
        </div>
        <div className='row text-center'>
          {renderButtons()}
        </div>
      </div>
    </>
  )
};

export default MultimediaCarousel;