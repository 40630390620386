import React from 'react';
import { Cureus } from '../../Cureus';

const EmailListRefreshes = ({emailListRefreshes, spinner, renderSpinner, refreshPath, updateState}) => {

  const triggerEmailListRefresh = (triggerPath) => {
    updateState({ spinner: true });
    $.ajax({
      type: "POST",
      dataType: "json",
      url: triggerPath,
      success: function(data) {
        window.scrollTo({top: 0});
        Cureus.Flash.success("Email list queued for refreshing.");
        updateState({ spinner: false, emailListRefreshes: data.emailListRefreshes });
      }
    })
  }

  const deleteEmailListRefresh = (destroyPath) => {
    if (window.confirm('Delete refresh source? This action is irreversible.')) {
      updateState({ spinner: true });
      $.ajax({
        type: "DELETE",
        dataType: "json",
        url: destroyPath,
        success: function(data) {
          window.scrollTo({top: 0});
          Cureus.Flash.success("Refresh source removed from email list. Existing emails from this source will not be removed.");
          updateState({ spinner: false, emailListRefreshes: data.emailListRefreshes });
        }
      })
    }
  }

  const setAutoRefresh = function(id, bool) {
    $.ajax({
      type: "PUT",
      dataType: "json",
      url: "/admin/email_list_refreshes/" + id,
      data: { auto_refresh: bool},
      success: function(data) {
        window.scrollTo({top: 0});
        if (bool == true) {
          Cureus.Flash.success("Auto refresh set. Will run ever 2 days");
        } else {
          Cureus.Flash.warning("Auto refresh removed. Will not run every 2 days.");
        }
        updateState({ emailListRefreshes: data.emailListRefreshes });
      }
    })
  }

  const handleRefresh = () => {
    updateState({ spinner: true })
    $.post(refreshPath, function(data) {
      window.location.href = window.location.href
    })
  }

  if (emailListRefreshes.length > 0) {
    let button = <a className='button' onClick={ handleRefresh } disabled= { spinner }>Refresh all</a>
    let header = <h3>Refresh Sources:</h3>
    let elements =  emailListRefreshes.map(function(item, idx) {
      return (
        <tr key={idx}>
          <td>{item.source}</td>
          <td dangerouslySetInnerHTML={{__html: item.params}}></td>
          <td>{item.state}</td>
          <td>{item.updated}</td>
          <td className='centered'>
            <div className="actions">
              <i className='fas fa-sync' style={{cursor: 'pointer'}} onClick={() => {triggerEmailListRefresh(item.triggerPath)}}></i>
                &nbsp;&nbsp;&nbsp;&nbsp;
              <i className='fas fa-trash' style={{cursor: 'pointer'}} onClick={() => {deleteEmailListRefresh(item.destroyPath)}}></i>
              <input type="checkbox" onChange={() => setAutoRefresh(item.id, !item.autoRefresh)} checked={item.autoRefresh}/>
            </div>
          </td>
        </tr>
      )
    })
    return (
      <>
        { header }
        { button }
        { renderSpinner() }
        <table>
          <thead>
            <th>Source</th>
            <th>Info</th>
            <th>State</th>
            <th>Updated</th>
            <th>Actions</th>
          </thead>
          <tbody>
            {elements}
          </tbody>
        </table>
        <br/>
      </>
    )
  } else {
    return <div/>
  }
};

export default EmailListRefreshes;