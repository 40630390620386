import React, { useState, useEffect } from 'react';

const InternalContent = ({handleAddWidget, widgetMainRiverId, mainContentWidgets={}, widget, handleUpdateWidget, internalTab, widgetInternalContentTabId}) => {
  const [header, setHeader] = useState(widget ? widget.title : '')
  const [description, setDescription] = useState(widget ? widget.blurb : '')
  const [link, setLink] = useState(widget ? widget.url : '')
  const [contentType, setContentType] = useState(widget ? widget.contentType : '' )
  const [image, setImage] = useState(null)
  const [contentFile, setContentFile] = useState(null)
  const [imageFilename, setImageFilename] = useState(widget ? widget.imageFilename : '')
  const [contentFilename, setContentFilename] = useState(widget ? widget.contentFilename : '')
  const [disabled, setDisabled] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [whitelistExtension] = useState(["pdf", "ppt", "pptx", "doc", "docx", "xls", "xlsm", "xlsx", "jpeg", "png", "tiff", "mov", "mp4", "txt", "odt", "ods", "aif", "mp3", "mpa", "wav", "mid", "zip", "7z", "csv", "xml", "ai", "bmp", "pps", "mpg", "mpeg"])

  useEffect(() => {
    let disableCheck = false
    if(contentType == 'link') {
      disableCheck = image || imageFilename
    } else {
      disableCheck = (image || imageFilename) && (contentFile || contentFilename)
    }
    setDisabled(contentType && isCorrectLink() && isCorrectFileExtension() && disableCheck ? '' : 'disabled')
  }, [contentType, link, image, imageFilename, contentFile, contentFilename])

  useEffect( () => {
    setLinkErrorMessage()
  }, [link])

  useEffect( () => {
    setContentFileErrorMessage()
  }, [contentFile])

  useEffect(() => {
    triggerToolTipStyling()
  }, [])

  const triggerToolTipStyling = () => {
    $(document).foundation("reflow")
  }

  const setLinkErrorMessage = () => {
    let msg = isCorrectLink() ? "" : 'Please do not include "https://" or "http://"'
    setErrorMessage(msg)
  }

  const setContentFileErrorMessage = () => {
    let msg = isCorrectFileExtension() ? "" : 'This is not an accepted file type.'
    setErrorMessage(msg)
  }

  const isCorrectLink = () => {
    return /https?:\/\//.test(link) ? false : true
  }
  const isCorrectFileExtension = () => {
    if (contentFile === null) {return true}
    let ext = contentFile.name.split(".").pop()
    return whitelistExtension.includes(ext.toLowerCase()) ? true : false
  }

  const handleAddWidgetWrapper = () => {
    setDisabled('disabled')
    setErrorMessage('Upload in progress!')
    let params = createFormData()
    if(handleAddWidget) {
      handleAddWidget(params, true)
    } else {
      handleUpdateWidget(widget.id, params, true)
    }
  }

  const internalContentIdentifier = () => {
    return internalTab ? "Widget::InternalContentTab" :  "Widget::InternalContent";
  }

  const parentIdIdentifier = () => {
    return internalTab ? widgetInternalContentTabId : (mainContentWidgets["Widget::InternalContent"] || widgetMainRiverId);
  }

  const createFormData = () => {
    let formData = new FormData();

    let type = contentType.charAt(0).toUpperCase() + contentType.slice(1);

    if(widgetInternalContentTabId || mainContentWidgets["Widget::InternalContent"]) {
      formData.append("widget[parent_id]", parentIdIdentifier());
      formData.append("widget[type]", `Widget::InternalContent${type}`);
      formData.append("widget[blurb]", description);
      formData.append("widget[title]", header);
      if(image) {
        formData.append("widget[image]", image);
      }
      if(contentFile) {
        formData.append("widget[content_file]", contentFile);
      }
      formData.append("widget[url]", link);
    } else {
      formData.append("widget[parent_id]", parentIdIdentifier());
      formData.append("widget[type]", internalContentIdentifier());
      formData.append("widget[widgets_attributes][type]", `Widget::InternalContent${type}`);
      formData.append("widget[widgets_attributes][title]", header);
      formData.append("widget[widgets_attributes][blurb]", description);
      formData.append("widget[widgets_attributes][url]", link);
      if(image) {
        formData.append("widget[widgets_attributes][image]", image);
      }
      if(contentFile) {
        formData.append("widget[widgets_attributes][content_file]", contentFile);
      }
    }
    return formData;
  }

  const renderImage = () => {
    if(imageFilename) {
      return <span>Uploaded: {imageFilename}</span>
    }
  }

  const renderFilename = () => {
    if(contentFilename) {
      return <span>Uploaded: {contentFilename}</span>
    } else {
      return <></>
    }
  }

  const renderFileInput = (name, setFunction, label) => {
    return (
    <div className="row file-container flex">
      <div className="small-8 columns file-uploads">
        <label htmlFor={name}>{label}</label>
        <input
          id={name}
          type="file"
          onChange={(e) => setFunction(e.target.files[0])}
        />
      </div>
      {label == 'File' && renderFilename() }
      {label == 'Preview Image (required)' && renderImage()}
    </div>
    )
  }

  return (
    <div className="reveal-modal small channel-design-modals internal-content-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Other Content</h5>
            <hr/>
          </div>
          <p>Feature content from outside Cureus such as powerpoint presentations, PDFs, or articles published in other journals. If uploading a file, be sure to click the tooltip to see the extensive list of acceptable file types!</p>
          <div className="row">
            <div className="small-12 columns">
              <div className="input-container">
                <label>Header</label>
                <input
                  type="text"
                  name="header"
                  value={header}
                  onChange={(e) => setHeader(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <div className="input-container">
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <strong>Choose Content Type</strong>
              <label className="checkbox-label">
                <input type="radio" name="content-type" data-type="link" onChange={(e) => setContentType(e.target.dataset.type)} checked={contentType === 'link'}/>
                Add Link
              </label>
              {contentType === 'link' &&
              <div className="row">
                <div className="small-12 columns">
                  <div className="input-container">
                    <label>Add link to journal article, webpage, etc.</label>
                    <input
                      type="text"
                      name="link"
                      placeholder="Enter the URL here."
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              }
              {contentType === 'link' && renderFileInput('image', setImage, 'Preview Image (required)')}
              <label className="checkbox-label">
                <input type="radio" name="content-type" data-type="file" onChange={(e) => setContentType(e.target.dataset.type)} checked={contentType === 'file'}/>
                <div className="tool-tip">
                  Upload file
                  <img src="https://public.cureus.com/channel_design/tool-tip-internal.png" className="has-tip tip-top" data-tooltip='' title="Accepted file types: PDF, PPT, PPTX, DOC, DOCX, XLS, XLSM, XLSX, JPEG, PNG, TIFF, MOV, MP4, TXT, ODT, ODS, AIF, MP3, MPA, WAV, MID, ZIP, 7z, CSV, XML, AI, BMP, PPS, MPG, MPEG"/>
                </div>

              </label>
              {contentType === 'file' && renderFileInput('contentFile', setContentFile, 'File')}
              {contentType === 'file' && renderFileInput('image', setImage, 'Preview Image (required)')}
            </div>
          </div>
          <div className='modal-footer text-center'>
            <a className={"button primary " + disabled} onClick={handleAddWidgetWrapper}>Add</a>
            {errorMessage && <p className="error-message text-left">{errorMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}


export default InternalContent;