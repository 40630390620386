var createReactClass = require('create-react-class');
import React from 'react';

const Sidebar = createReactClass({
  getInitialState: function() {
    return({
      specialties: this.props.specialties || [],
      filteredSpecialties: [],
      selectedSpecialties: {},
      search: ''
    })
  },

  updateSelected: function(specialtyId) {
    var self = this;
    return function() {
      var update = Object.assign(self.state.selectedSpecialties, {});
      update[specialtyId] = !self.state.selectedSpecialties[specialtyId]
      self.setState({selectedSpecialties: update}, self.getEditors)
    }
  },

  getEditors: function() {
    var self = this;
    var ids = Object.keys(this.state.selectedSpecialties).filter(function(spec) {
      return self.state.selectedSpecialties[spec];
    });
    if (ids.length) {
      var params = {'filters': {'0': {'type': 'specialty', 'value': ids}}}
    } else {
      var params = {}
    }
    self.props.updateLoading(true)
    $.ajax({
      type: 'get',
      url: '/editorial_board',
      data: params,
      success: function(res) {
        self.props.updateEditors(res)
        self.props.updateLoading(false);
      },
      error: function(res) {
      }
    })
  },

  clearSelected: function() {
    this.setState({selectedSpecialties: {}}, this.getEditors)
  },

  updateSearch: function(e) {
    this.setState({search: e.target.value}, this.props.updateSearch(e.target.value));
  },

  renderSpecialties: function() {
    var self = this;
    return self.state.specialties.map(function(cat) {
      var isChecked = self.state.selectedSpecialties[cat.id] ? true : false
      return(
        <div key={cat.id + '_' + 'spec_key'}>
          <label>
              <input checked={isChecked} type='checkbox' id={cat.id} onClick={self.updateSelected(cat.id)}/>
              {cat.name}
            </label>
        </div>
      )
    });
  },

  renderClear: function() {
    if(Object.keys(this.state.selectedSpecialties).length > 0) {
      return (
        <span className='article-index-nav-clear span-link' onClick={this.clearSelected}>Clear All</span>
      )
    }
  },

  render: function() {
    return(
      <div className='eb-nav'>
        <section className='article-index-nav-section'>
          <div className='article-index-nav-section-inner-box'>
            <div className='article-filter-box'>
              <h4 className="cureus-blue">Filter</h4>
                <div className='search-box'>
                  <input type='text' placeholder='Name, affiliation...' onChange={this.updateSearch}/>
                  <i className='fa fa-search'/>
                </div>
              <div className='article-index-categories-wrap'>
                <h4 className="text-left">Specialties</h4>
                <section>
                  <div className='article-index-categories-form'>
                    <div>
                      {this.renderSpecialties()}
                    </div>
                    <div className='fader'/>
                  </div>
                </section>
              </div>
            </div>
            {this.renderClear()}
          </div>
        </section>
      </div>
    )
  }
})

export default Sidebar;