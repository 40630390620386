var createReactClass = require('create-react-class');
import React from 'react';

const Tile = createReactClass({
  getInitialState: function() {
    return({
      truncated: true
    })
  },

  toggleTruncated: function() {
    this.setState({truncated: !this.state.truncated})
  },

  renderBio: function() {
    if (this.state.truncated) {
      return (
        <div className='advisor-tile-bio'>
          {this.props.tile.truncBio}
        </div>
      )
    } else {
      return (
        <div className='advisor-tile-bio' dangerouslySetInnerHTML={{__html: this.props.tile.bio}}>
        </div>
      )
    }
  },

  render: function() {
    var classStyle = this.state.truncated ? 'advisor-tile' : 'advisor-tile open';
    return(
      <div className={classStyle}>
        <div className='advisor-tile-name'>
          {this.props.tile.name}
        </div>
        {this.renderBio()}
        <a className='show-bio' onClick={this.toggleTruncated}>{this.state.truncated ? 'Read more' : 'Close'}</a>
      </div>
    )
  }
});

export default Tile;