import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';

const Table = ({collectionId, tableData}) => {

  useEffect(() => {
    populateTable(tableData)
  }, [])

  const setColumns = () => {
    return [
      {width: '20%'},
      {width: '20%'},
      {width: '60%'}
    ]
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData([
        row.published_at,
        userLink(row.submitter_id, row.submitter_name),
        articleLink(row.id, row.title)
      ])
    )
  }

  const articleLink = (articleId, articleTitle) => {
    return `<a target='_blank' href='/articles/${articleId}'>${articleTitle}</a>`;
  }

  const userLink = (userId, userName) => {
    return `<a target='_blank' href='/users/${userId}'>${userName}</a>`
  }

  const populateTable = (data) => {   
    let dt = $(`#${collectionId}-published-articles-table`).DataTable({
      blengthChange: false,
      bPaginate: false,
      info: false,
      columns: setColumns(),
      columnDefs: [
        {
          targets: 0,
          render: function (data, type, row) {
            if (type === 'display') {
              return moment(data).format('MM/DD/YYYY');
            }
            return data;
          }
        }
      ],
      searching: false,
    }
    );
    dt.api().clear()
    data.forEach(row => {
      mapColumns(dt, row)
    })
    dt.fnDraw()
  }

  return (
    <React.Fragment>
      <div className="collection-article-row-container">
        <div className="collection-articles-datatable-container">
          <table id={`${collectionId}-published-articles-table`}>
            <thead className='table-head-collection-pub'>
              <tr>
                <th>Published</th>
                <th>Author</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Table;