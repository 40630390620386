var createReactClass = require('create-react-class');
import React from 'react';
import TransloaditFileDrop from '../TransloaditFileDrop';
import AttachmentMixin from '../AttachmentMixin';

const NewFile = createReactClass({
  mixins: [AttachmentMixin],

  getInitialState: function() {
    return {
      file: null
    }
  },

  renderUploaderContent: function(){
    return (
      <div className="media-uploader-content">
        <img src="https://public.cureus.com/Dragndrop.png" />
        <h3>- or -</h3>
        <a className="button small special" onClick={this.handleFileButtonClick}>Select From <br/> Computer</a>
      </div>
    )
  },

  handleFileButtonClick: function() {
    document.getElementById('hidden-file-drop-input').click()
  },

  renderProgressBarText: function() {
    if (this.state.finishedUploading) {
      return <p>Please wait, we are processing the figure...</p>
    }
  },

  render: function() {
    if(this.state.errorMessage) {
      var errorMessage =
        <div className='alert-box alert'>
          { this.state.errorMessage }
        </div>
    }

    if(this.state.isUploading) {
      return (
        <div key='progressbar' className='cureus-progress progress-striped active poster-progress'>
          {this.renderProgressBarText()}
          <div ref='progressBar' className='bar'></div>
        </div>
      )
    } else {
      return (
        <div className='poster-upload-widget-wrap'>
          { errorMessage }
          <TransloaditFileDrop
            transloaditTemplate={ this.props.collection.transloadit_template }
            handleFileSelect={ this.handleFileSelect }
            handleProgress={ this.handleProgress }
            handleSuccess={ this.handleSuccess }
            handleError={ this.handleError }
            fileName={ 'banner_image_file' }>

            <div className='poster-upload-widget media-uploader'>
              <div className='poster-upload-no-file'>
                <h5 className='subhead'>
                  {this.renderUploaderContent()}
                </h5>
              </div>
            </div>
          </TransloaditFileDrop>
        </div>
      )
    }
  }
});

export default NewFile;