import React, { useState } from 'react';
import Carat from "./Carat"

const Categories = ({categories, fireAmplitude}) => {
  const [showCategories, setShowCategories] = useState(true)

  const renderCategories = () => {
    return categories.map( (category, idx) => {
      return <li key={idx}>
              <a onClick={() => fireAmplitude('articleCategoryClicked', {source: "article page", category: category.name})} target="_blank" href={`/articles?categories[${category.id}]=${category.name}`}>{categoryName(category.name)}</a>
            </li>
    })
  }

  const categoryName = (name) => {
    let words = name.split("/")
    return words.join(" / ")
  }

  return (
    <div className="left-sidebar-row">
      <div className="collapsible" onClick={() => {setShowCategories(!showCategories)}}>
        <h1>Categories</h1>
        {<Carat showNav={showCategories} setShowNav={setShowCategories}/>}
      </div>

      <ul className='categories'>
        {showCategories && renderCategories()}
      </ul>
    </div>
  )
}

export default Categories