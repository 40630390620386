import React, { useEffect, useState } from 'react';
import MetricsCard from './MetricsCard'
import ArticleCard from './ArticleCard'
import NewsCard from './NewsCard'
import CureusUCard from './CureusUCard'
import { Cureus } from '../../Cureus';

const FeaturedContentCarousel = ({featuredContentPath, firstCard, currentUserId}) => {

  const [visibleIndex, setVisibleIndex] = useState(0);
  const [featuredContent, setFeaturedContent] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [visibleCardType, setVisibleCardType] = useState("metrics");
  
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false)
      fetchFeaturedContent()
    }
    if (!Cureus.MobileCheck()) {
      const intervalId = setInterval(() => {
        moveRight()
      }, 8000)
      return () => clearInterval(intervalId); 
    }
  })

  const fetchFeaturedContent = () => {
    $.ajax({
      type: 'GET',
      url: featuredContentPath,
      success: function(data) {
        setFeaturedContent(data)
      }
    });
  }

  const moveLeft = (e) => {
    let newIndex = visibleIndex == 0 ? featuredContent.length-1 : visibleIndex-1
    setVisibleIndex(newIndex)
  }

  const moveRight = (e) => {
    let newIndex = visibleIndex == featuredContent.length-1 ? 0 : visibleIndex+1
    setVisibleIndex(newIndex)
  }

  const dotClicked = (newIndex) => {
    setVisibleIndex(newIndex)
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: 'featuredContentDotClicked', 
              user_id: currentUserId,
            }
    })
  }

  const renderDots = () => {
    return featuredContent.map((cardDetails, idx) => {
      return <i className={`fas fa-circle dot ${idx == visibleIndex ? 'active' : ''}`} onClick={() => dotClicked(idx)} key={idx} id={`dot-${idx}`} />
    })
  }

  const renderCards = () => {
    if (featuredContent.length == 0) {
      let initialCard = firstCard.length > 0 ? firstCard[0] : {}
      if (initialCard.content_type == 'metrics')
        return (<MetricsCard setVisibleCardType={setVisibleCardType} cardDetails={initialCard} index={0} visibleIndex={visibleIndex} key={initialCard.id} />)
      else if (initialCard.content_type == 'article')
        return (<ArticleCard setVisibleCardType={setVisibleCardType} cardDetails={initialCard} index={0} visibleIndex={visibleIndex} key={initialCard.id} />)
      else if (initialCard.content_type == 'news')
        return (<NewsCard setVisibleCardType={setVisibleCardType} cardDetails={initialCard} index={0} visibleIndex={visibleIndex} key={initialCard.id} />)
      else if (initialCard.content_type == 'cureus-u')
        return (<CureusUCard setVisibleCardType={setVisibleCardType} cardDetails={initialCard} index={0} visibleIndex={visibleIndex} key={initialCard.id} />)
      else
        return (<div className="featured-content-card" key={99} id="card-hold"></div>)
    } else {
      return featuredContent.map((cardDetails, idx) => {
        if (cardDetails.content_type == 'metrics')
          return <MetricsCard setVisibleCardType={setVisibleCardType} cardDetails={cardDetails} index={idx} visibleIndex={visibleIndex} key={cardDetails.id} />
        else if (cardDetails.content_type == 'article')
          return <ArticleCard setVisibleCardType={setVisibleCardType} cardDetails={cardDetails} index={idx} fireAmplitude={fireAmplitude} visibleIndex={visibleIndex} key={cardDetails.id} />
        else if (cardDetails.content_type == 'news')
          return <NewsCard setVisibleCardType={setVisibleCardType} cardDetails={cardDetails} index={idx} fireAmplitude={fireAmplitude} visibleIndex={visibleIndex} key={cardDetails.id} />
        else if (cardDetails.content_type == 'cureus-u')
          return <CureusUCard setVisibleCardType={setVisibleCardType} cardDetails={cardDetails} index={idx} fireAmplitude={fireAmplitude} visibleIndex={visibleIndex} key={cardDetails.id} />
      })
    }
  }

  const fireAmplitude = (type, page) => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: 'featuredContentClicked', 
              user_id: currentUserId,
              type: type,
              page: page
            }
    })
  }

  return (
    <div className="small-12 columns featured-content-container">
      {renderCards()}
      <div className="dot-container">
        {renderDots()}
      </div>
    </div>
  )
}

export default FeaturedContentCarousel;