import {Cureus} from '../Cureus';

Cureus.LazyLoader = (function($, _) {
  var init, LazyLoader;

  init = function(options) {
    return new LazyLoader(options);
  };

  LazyLoader = function(options){
    this.selector = options['selector'];
    this.keepClass = options['selector'] || false;
    this.bindEvents();
  };

  LazyLoader.prototype.loadImages = function() {
    var self = this;
    var els = document.querySelectorAll(this.selector);

    for (var i=0; i < els.length; i++) {
      var bounding = els[i].getBoundingClientRect();
      if (self.inView(bounding)) {
        els[i].src = els[i].dataset.lazy;
        delete els[i].dataset.lazy;
        if(!self.keepClass){
          els[i].classList.remove('spinner')
        }
      }
    }
  },

  LazyLoader.prototype.inView = function (bounding) {
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  },

  LazyLoader.prototype.bindEvents = function(){
    var self = this;
    window.addEventListener('scroll', function () {
      self.loadImages();
    })
  }
  return { init: init };
})(jQuery, _);