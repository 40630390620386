var createReactClass = require('create-react-class');
import React from 'react';
import CategoriesModal from './CategoriesModal';
import Modal from '../Modal';

const Categories = createReactClass({

  getInitialState: function() {
    return({
      categories: this.props.categories
    })
  },

  innerLabel: function() {
    var checked = []
    Object.values(this.state.categories).forEach(function(cat) {
      if (cat.checked){ checked.push(cat.name) }
    });
    var inner;
    if (checked.length) {
      return (
        <div>
          <div className='cureus-multi-select-placeholder'>
            {checked.join(', ')}
          </div>
          <div className='cureus-multi-select-count'>{checked.length}</div>
        </div>
      )
    } else {
      return (
        <div className='cureus-multi-select-placeholder'>
          <i className='fa fa-filter'/>
          <span>ALL CATEGORIES</span>
        </div>
      )
    }
  },

  classType: function() {
    if (this.props.hasSubchannels) {
      return 'large-3 large-offset-0 medium-4 medium-offset-0 small-12 columns'
    }
    if (this.props.compOrChannel) {
      return this.props.typesRequired ? 'large-3 large-offset-0 medium-8 medium-offset-2 small-12 columns': 'large-4 large-offset-0 medium-8 medium-offset-2 small-12 columns'
    } else if (this.props.posters) {
      return "large-4 large-offset-2 small-6 medium-6 columns"
    }
    return 'large-4 large-offset-0 medium-4 medium-offset-0 small-12 columns'
  },

  openCategoryModal: function() {
    Modal.open({
      children: <CategoriesModal  categoryIds={this.props.categoryIds} categories={this.state.categories} handleChange={this.props.handleChange} submitUpdate={this.props.submitUpdate} resetPage={this.props.resetPage}/>
    })
  },

  render: function() {
    return(
      <div className={this.classType()}>
        <div id='categories-multiselect-wrap'>
          <div className='cureus-custom-multi-select-wrap' >
            <div className='cureus-multi-select' onClick={this.openCategoryModal}>
              {this.innerLabel()}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Categories;