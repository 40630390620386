import React, { useState, useEffect } from 'react';

const RadioInput = ({contentType, setContentType, uniqueName}) => {

  const isSelected = () => {
    return (contentType == uniqueName)
  }

  return (
    <div>
      <input type="radio" id={`${uniqueName}-radio`} onChange={() => setContentType(uniqueName)} checked={isSelected()} />
      <label htmlFor={`${uniqueName}-radio`}>{uniqueName}</label>
    </div>
  )
}

export default RadioInput;