import React, { useState, useEffect } from 'react';
import AboutContact from './SidebarWidgets/AboutContact';
import SocialAccountsWidget from './SidebarWidgets/SocialAccountsWidget';
import Sortable from '../../../components/Sortable';

const AboutSidebarContent = ({sidebarWidgets, slug, handleDeleteWidget, handleUpdateWidget}) => {
  const sharedProps = {sidebarWidgets, slug, handleDeleteWidget, handleUpdateWidget}
  const componentMapping = (widget) => {
    return {
      'Widget::AboutContact': <AboutContact
                                    {...sharedProps}
                                    widgetId={widget.id}
                                />,
      'Widget::SocialAccounts': <SocialAccountsWidget
                                    {...sharedProps}
                                    widgetId={widget.id}
                                />
    }
  }

  const renderOrderedContents = () => {
    return sidebarWidgets.map((widget, idx) => {
      let type = widget.type
      return (
          <div className="card drag-handle" key={idx}>
            {componentMapping(widget)[type]}
          </div>
        )
    })
  }

  const handleSort = (e, sortable, movedComponent) => {
    let widgetId = movedComponent.props.children.props.widgetId
    let params = {widget: {position: e.newIndex + 1}}
    handleUpdateWidget(widgetId, params, false, true)
  }

  return (
    <div className="channel-main-content-container sidebar-content">
      <Sortable tagName='div' handleSort={ handleSort }>
        {renderOrderedContents()}
      </Sortable>
    </div>
  )
}

export default AboutSidebarContent;