import React, { Component } from 'react';

class Row extends Component {
  state = {
    editing: false
  }

  componentDidUpdate(prevProps) {
    if(prevProps.edited != this.props.edited) {
      this.setState({editing: this.props.edited[this.props.editor.id] || false})
    }
  }

  handleClick = () => {
    this.setState({editing: true})
  }

  renderGoal() {
    let editor = this.props.editor
    if(this.state.editing) {
      return <td style={{cursor: 'pointer'}}><input name={this.props.type} defaultValue={editor.goals[this.props.type] || "N/A"} onChange={(e) => this.props.handleEdit(e, editor.id)}/></td>
    } else {
      return <td style={{cursor: 'pointer'}} onClick={this.handleClick}>{editor.goals[this.props.type] || "N/A"}</td>
    }
  }

  render() {
    let editor = this.props.editor
    return (
      <tr>
        <td dangerouslySetInnerHTML={{__html: editor.email}}/>
        <td>{editor.first_name}</td>
        <td>{editor.last_name}</td>
        <td>{editor.title}</td>
        {this.renderGoal()}
      </tr>
    )
  }
}

export default Row;