var createReactClass = require('create-react-class');
import React from 'react';

const Post = createReactClass({
  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token,
      className: null,
      action: null
    };
  },

  render: function() {
    return (
      <form acceptCharset='UTF-8' className={ this.props.className } method='post' action={ this.props.action }>
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />

        { this.props.children }
      </form>
    )
  }
});

export default Post;
