import React, { useEffect, useState } from 'react';

const PerformanceTable = ({setData, data, tableNumber}) => {

  const [sortName, setSortName] = useState('count');
  const [sortDirection, setSortDirection] = useState('desc');
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect( () => {
    sortData()
  }, [sortDirection, sortName])

  useEffect( () => {
    if (!isAllCheckboxesChecked()) {
      setSelectAllChecked(false);
    }
  }, [data])

  const tableData = data[tableNumber]

  const sortData = () => {
    let newSortedData = [...tableData];
    if (sortName == "count") {
      newSortedData = sortByCount(newSortedData, sortDirection)
    } else if (sortName == "name") {
      newSortedData = sortByName(newSortedData, sortDirection)
    }
    updateTableData(newSortedData)
  }

  const sortByName = (list, direction) => {
    if (direction == "desc") {
      return list.sort((a, b) => b.full_name.localeCompare(a.full_name));
    } else {
      return list.sort((a, b) => a.full_name.localeCompare(b.full_name));
    }
  }

  const sortByCount = (list, direction) => {
    if (direction == "desc") {
      return list.sort((a, b) => b.count - a.count);
    } else {
      return list.sort((a, b) => a.count - b.count);
    }
  }

  const handleSort = (newSortName) => {
    handleSortDirection(newSortName)
    setSortName(newSortName)
  };

  const handleSortDirection = (newSortName) => {
    let dir;
    if (newSortName != sortName) {
      dir =  "asc"
    } else {
      dir =  sortDirection === 'asc' ? 'desc' : 'asc'
    }
    setSortDirection(dir);
  }

  const updateTableData = (newData) => {
    let updateWithThisData = newData
    let cloneData = [...data];
    cloneData[tableNumber] = updateWithThisData;
    setData(cloneData);
  }

  const renderDetails = () => {
    switch (tableNumber) {
      case 0:
        return {text: 'Lowest 25%' }
      case 1:
        return  {text: 'Lower 25%' }
      case 2:
        return  {text: 'Higher 25%' }
      case 3:
        return  {text: 'Top 25%' }
    }
  }

  const handleSelectAllCheckbox = () => {
    if (selectAllChecked == false) {
      flipAllCheckboxes(true)
    } else {
      if (isAllCheckboxesChecked()) {
        flipAllCheckboxes(false)
      }
    }
    setSelectAllChecked(!selectAllChecked);
  }

  const isAllCheckboxesChecked = () => {
    return tableData.every(item => item.checked === true)
  }

  const flipAllCheckboxes = (trueOrFalse) => {
    let newTableData = [...tableData]
    newTableData.forEach(item => {
      item.checked = trueOrFalse
    });
    updateTableData(newTableData)
  }

  const handleSelectRowCheckBox = (id) => {
    let newTableData = [...tableData]
    let foundItem = newTableData.find(item => item.id === id)
    foundItem.checked = !foundItem.checked
    updateTableData(newTableData)
  }

  return (
    <div className={`performance-tables performance-table-${tableNumber}`}>
      <h4 className="text-center">{renderDetails().text}</h4>
      <table>
        <thead>
          <tr>
            <th><input type='checkbox' onChange={handleSelectAllCheckbox} checked={selectAllChecked}/></th>
            <th onClick={() => {handleSort("name")}}>Editor</th>
            <th onClick={() => {handleSort("count")}}>Contribution</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={index}>
              <td><input onClick={() => handleSelectRowCheckBox(item.id)} type='checkbox' checked={item.checked}/></td>
              <td><a target="_blank" href={`/admin/users/${item.id}`}>{item.full_name}</a></td>
              <td className="text-center">{item.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PerformanceTable;