import React from 'react';

const List = ({ isOpen = true, items, onSelect }) => {
  const handleSelect = (onItemClick) => {
    if (onSelect) {
      onItemClick();
      onSelect();
    }
    onItemClick();
  }

  const renderList = () => {
    return (
      items.map(({ onItemClick, content }) => {
        return (
          <li
            role="option"
            onClick={() => handleSelect(onItemClick)}
            key={content}
          >
            {content}
          </li>
        )
      })
    )
  }

  return (
    <div className="drop-down-menu">
      <ul>
        {isOpen && renderList()}
      </ul>
    </div>
  )
}

export default List;