var createReactClass = require('create-react-class');
import React from 'react';
import AttachmentInsertMixin from './AttachmentInsertMixin';
import Modal from '../Modal';

const CheckList = createReactClass({
  mixins: [AttachmentInsertMixin],
  getInitialState: function(){
    return {
      attachment: this.props.attachment
    }
  },

  componentDidMount: function(){
    var self = this;
    this.props.setValidState(this.props.checkAllCheckboxes)
    $('#add-figure').on("insertItem", ".primary", function(e){
      // insert figure
      var elem = CKEDITOR.dom.element.createFromHtml(self.props.attachment.html + "\n")
      // edit figure and reinserting figure needs to remove old figure in article content
      try{
        self.setCursorPosition();
        self.removeAttachmentForReinsertion(self.state.attachment.type);
        self.ckeditor().insertElement(elem);
      } catch(e) {
        self.moveCursorToStart();
        self.ckeditor().insertElement(elem);
        self.resetCursorPosition();
      }
      self.ckeditor().updateElement();
      self.disableAttachments();
      Modal.close()
    });
  },

  handleCheck: function() {

    if ($('.checkboxes input:checked').length === $('.checkboxes input').length) {
      this.props.setValidState(true)
    } else {
      this.props.setValidState(false)
    }
  },

  handleSubmit: function(e){
    var self = this;
    var payload = {
      attachment: {
        title: self.props.attachment.title,
        legend: self.props.attachment.legend,
        file: self.props.attachment.fileName,
        height: self.props.attachment.height,
        width: self.props.attachment.width
      }
     }
     self.props.handleUpdate(e, payload)
  },

  render: function() {
    return (
      <div className='modal-content' id='add-figure'>
        <div className='modal-header figure-checklist-header'>
          <h4 className="reg">Insert Figure - Requirements Checklist</h4>
            <p className="title"> Please review our <a target="_blank" href="/media_guide#figure-req">Figure Guidelines</a> for detailed instruction and examples.</p>
          <div className='modal-close'>
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
        </div>
        <div className='modal-body figure-checklist'>
          <div className="checkboxes">
            <p> To prevent deferrals, please check each box to confirm your figure meets the following requirements:</p>
            <label htmlFor="1"><input id="1" type="checkbox" defaultChecked={this.props.checkAllCheckboxes} onChange={this.handleCheck}/> I have removed all patient identifying information.</label>
            <label htmlFor="2"><input id="2" type="checkbox" defaultChecked={this.props.checkAllCheckboxes} onChange={this.handleCheck}/> I have not stretched, skewed or distorted the figure.</label>
            <label htmlFor="3"><input id="3" type="checkbox" defaultChecked={this.props.checkAllCheckboxes} onChange={this.handleCheck}/> I have removed any unnecessary white space around the figure.</label>
            <label htmlFor="4"><input id="4" type="checkbox" defaultChecked={this.props.checkAllCheckboxes} onChange={this.handleCheck}/> I have used arrows (and labels when needed) to provide focus to the relevant aspects.</label>
            <label htmlFor="5"><input id="5" type="checkbox" defaultChecked={this.props.checkAllCheckboxes} onChange={this.handleCheck}/> If I have multi-pane figures, I've labeled each pane.</label>
            <label htmlFor="6"><input id="6" type="checkbox" defaultChecked={this.props.checkAllCheckboxes} onChange={this.handleCheck}/> I have properly mentioned the figure in the preceding paragraph.</label>
          </div>
        </div>
        <div className='modal-footer'>
        <div className="pull-left">
          <a className='button special' onClick={this.props.handleBack}>Back</a>
        </div>
        <a className='cancel-modal button secondary'>Cancel</a>
        <a className={'button primary ' + this.disableButtonClass(this.props.disableButton)} onClick={this.handleSubmit}>Insert</a>
      </div>
    </div>
    )
  }
})

export default CheckList;
