var createReactClass = require('create-react-class');
import React from 'react';
import Table from './Table';
import PeerReviewers from '../Channels/PeerReviewers';

const Main = createReactClass({
  getInitialState: function() {
    return {
      reviewerships: null,
      currentUser: null,
      checked: [],
      expanded: false,
      disabled: 'disabled',
      numPeerReviewers: 0
    }
  },

  handleClick: function() {
    this.setState({expanded: true})
  },

  setReviewersCount: function(num) {
    this.setState({numPeerReviewers: num})
  },

  renderSearchPubmedButton: function() {
    if (this.props.isCompetition) {
      return;
    }
    if (this.props.canEdit) {
      return (
         <a className="button secondary tiny" href= {this.props.adminArticleReviewerFinder} target="_blank"> <i className="fa fa-search"> </i> Search pubmed for reviewers</a>
      )
    } else {
      return <a className="button secondary tiny" disabled="disabled" value="This action is currently not available"><i className="fa fa-search"></i>Search pubmed for reviewers</a>
    }
  },

  sendInvite: function() {
    var self = this;
    self.setState({disabled: "disabled"})
    $.ajax({
      dataType: "JSON",
      url: self.props.ReviewersPoolInvitePath,
      data: {reviewerIds: self.state.checked},
      type: "PUT"
    }).done(function(res) {
      window.scrollTo(0, 0);
      window.location.reload()
    })
  },

  updateCheckedState: function(e, reviwershipId) {
    var newChecked = this.state.checked
    if (e.target.checked) {
      newChecked.push(reviwershipId)
      this.setState({checked: newChecked, disabled: ""})
    } else {
      var idx = newChecked.indexOf(reviwershipId)
      newChecked.splice(idx, 1)
      this.setState({checked: newChecked})
      if (newChecked.length === 0) {
        this.setState({disabled: "disabled"})
      }
    }
  },

  updateChannelArticleCheckedState: function(checkboxUserIds) {
    const disabled = checkboxUserIds.length > 0 ? "" : "disabled";
    this.setState({checked: checkboxUserIds, disabled: disabled})
  },

  renderInviteButton: function() {
    if (this.state.disabled === "disabled") {
      return (
        <a className="button primary tiny" disabled='disabled'>Invite Selected Reviewers</a>
        );
      } else {
        return (
          <a className="button primary tiny" onClick={this.sendInvite}>Invite Selected Reviewers</a>
      )
    };
  },

  renderReviewerText: function() {
    if(this.props.isCompetition) {
      return (
        <p>
          All listed reviewers are members of the competition peer review panel.
        </p>
      )
    } else if(this.props.isChannelArticle && this.props.isChannelPerson) {
      return (
        <p>
          Invite additional reviewers from your channel’s peer reviewer list. <i>(Optional)</i>
        </p>
      )
    } else {
      return (
        <p>
          The table includes peer reviewer volunteers and authors of 1 or more papers who have not reached their maximum allowed invitations. 
          However, any user can be invited on the <a href={this.props.editPublishArticle} target="_blank">reviewers page </a>in edit mode.
      </p>
      )
    }
  },

  renderBody: function() {
    if (this.state.expanded) {
      if (this.props.isChannelArticle && this.props.isChannelPerson) {
        return (
          <div>
            {this.renderReviewerText()}
            <div className="invite-pubmed-search-btn-container-abs">
              {this.renderInviteButton()}
            </div>
            <div className="invite-pubmed-spacer-abs"></div>
            <div className="channel-peer-reviewers-container">
              <PeerReviewers setReviewersCount={this.setReviewersCount} adminArticlePage={true} updateCheckedState={this.updateChannelArticleCheckedState} removeReviewersPath={this.props.removeReviewersPath} setLastUpdatedTimestamp={null} reviewersPath={this.props.reviewersPath} fetchReviewersPath={this.props.fetchReviewersPath} reviewerPollingPath={this.props.reviewerPollingPath} uploadReviewersPath={this.props.uploadReviewersPath} userToolProps={this.props.userToolProps}/>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            {this.renderReviewerText()}
            <div className="invite-pubmed-search-btn-container">
              {this.renderInviteButton()}
              &nbsp;
              &nbsp;
              {this.renderSearchPubmedButton()}
            </div>
            <div className="invite-pubmed-spacer"></div>
              <Table 
                availableReviewersPath={this.props.availableReviewersPath}
                updateCheckedState={this.updateCheckedState}
                isCompetition={this.props.isCompetition}
                searchPath={this.props.searchPath}
                articleState={this.props.articleState}
              />
          </div>
        )
      }

    } else {
      return (
        <div>
          {this.renderReviewerText()}
          <a className="button primary tiny" onClick={this.handleClick}>Show Table</a>
        </div>
      )
    }
  },

  render: function() {
    return (
      <div>
        {this.renderBody()}
      </div>
    )
  }
})

export default Main;