import React from 'react';

const RadioContainer = ({ label, value, selectedValue, handleChange, name }) => {
  const handleRadioClick = (name, value) => {
    if (handleChange === 'disable') {
      return;
    }
    if (selectedValue !== value) {
      handleChange({ target: { name, value } });
    } else {
      handleChange({ target: { name, value: null } });
    }
  };

  return (
    <div className="large-6 medium-6 small-12 columns" onClick={() => handleRadioClick(name, value)}>
      <div className={`form-radio-container ${selectedValue === value ? 'selected' : ''}`}>
        <div className="cureus-blue-checkbox">
          <input className="checkbox" name={name} type="radio" value={value} checked={selectedValue === value}/>
          <label>{label}</label>
        </div>
      </div>
    </div>
  );
};

export default RadioContainer;
