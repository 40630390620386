import React from 'react';

const FigureRequriements = () => {

  return (
    <React.Fragment>
      <h1>Figure Requirements</h1>
      <div className='figure-requirements row centered'>
        <div className='small-6 medium-3 columns figure-requirement-item'>
          <div className='small-font blue'><strong>Accepted<br/>File Types:</strong></div>
          <div className='medium-small-font space-top-m'>
            <strong>.PNG<br/>.JPEG<br/>.TIFF</strong>
          </div>
        </div>
        <div className='small-6 medium-3 columns figure-requirement-item'>
          <div className='small-font blue'><strong>Minimum<br/>Width:</strong></div>
          <div className='large-font space-top-m'><strong>900</strong></div>
          <div className='small-font space-top-xxs'><strong>Pixels</strong></div>
        </div>
        <div className='small-6 medium-3 columns figure-requirement-item'>
          <div className='small-font blue'><strong>Recommended<br/>Minimum Width:</strong></div>
          <div className='large-font space-top-m'><strong>1600</strong></div>
          <div className='small-font space-top-xxs'><strong>Pixels</strong></div>
        </div>
        <div className='small-6 medium-3 columns figure-requirement-item last'>
          <div className='small-font blue'><strong>Maximum File<br/>Size:</strong></div>
          <div className='large-font space-top-m'><strong>10</strong></div>
          <div className='small-font space-top-xxs'><strong>MB</strong></div>
        </div>
      </div>
      <ul className='disc'>
        <li>Figures featuring stretched, skewed, blurry or otherwise images are not permitted.</li>
        <li>Republished figures must include proper attribution and include a note that permission was obtained from the original publisher. (Both attribution and permission should be included in the figure legend.)</li>
        <li>Original DICOM images must be uploaded.</li>
        <li>Extraneous whitespace should be cropped.</li>
        <li>Arrows should be used to identify points of interest.</li>
        <li>If the figure contains multiple panes, each pane should be labeled with a letter.</li>
        <li>If you need to resize your figure(s), please do not stretch the image using MS Paint or a similar application. Many options exist to revise figures without diminishing quality. In addition to applications such as Adobe Photoshop and Adobe Illustrator, we recommend <a href='https://retoucher.online/image-resizer' target='_blank' rel='noreferrer'>https://retoucher.online/image-resizer</a>.</li>
      </ul>
    </React.Fragment>
  )
}

export default FigureRequriements;