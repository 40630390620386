var createReactClass = require('create-react-class');
import React from 'react';
import NewAdvancedSearchRow from './NewAdvancedSearchRow';

const NewAdvancedSearch = createReactClass({
  getInitialState: function() {
    return ({
      filters: this.props.filters || [{boolean: '', field: 'all', query: ''}]
    })
  },

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters) {
      this.setState({ filters: this.props.filters });
    }
  },

  renderAdvancedSearchText: function() {
    if (this.props.advanced) {
      return(
        <div className="row">
          <div className="small-12 columns end advanced-search-text">
            <a style={{float: "right"}} href="javascript: void(0)" onClick={this.toggleAdvanced}>Simple Search</a>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="">
          <a href="javascript: void(0)" onClick={this.toggleAdvanced}>Advanced</a> 
        </div>
      )
    }
  },

  toggleAdvanced: function() {
    var self = this;
    this.setState({filters: [{boolean: '', field: 'all', query: ''}]}, self.props.toggleAdvanced())
  },

  getFilterText: function(filter) {
    // boolean, field, query
    let field = ''
    if (!filter.query) {
      return field
    }
    if (filter.field != 'all') {
      field = '[' + filter.field.toUpperCase() + ']'
    }
    return filter.query + field
  },

  getAdvancedSearchText: function() {
    let filters = this.state.filters
    if (filters.length == 0) {
      return ''
    }
    let results = this.getFilterText(filters[0]);
    for (var i = 1; i < filters.length; i++) {
      let current = filters[i]
      results = '(' + results + ') ' + current.boolean.toUpperCase() + ' ' + this.getFilterText(current)
    }
    return results;
  },

  addAdvancedSearchRow: function() {
    var filters = this.state.filters;
    let boolean = filters.length == 0 ? '' : 'and'
    filters.push({boolean: 'and', field: 'all', query: ''})
    this.setState({filters: filters});
  },

  removeAdvancedSearchRow: function(idx) {
    var self = this;
    var filters = this.state.filters
    filters.splice(idx, 1)
    filters[0].boolean = ''
    this.setState({filters: filters}, () => self.props.updateSearchBar(null, self.getAdvancedSearchText()))
  },

  handleChange: function(idx, e) {
    var self = this;
    var filters = this.state.filters
    filters[idx][e.target.name] = e.target.value;
    this.setState({filters: filters}, () => self.props.updateSearchBar(null, self.getAdvancedSearchText()))
  },

  handleAdvancedSearchEnter: function(e) {
    var self = this
    this.props.handleAdvancedSearchEnter(e, self.state.filters)
  },

  renderAdvancedSearchRows: function() {
    var self = this;
    var numFilters = self.state.filters.length;
    if (this.props.advanced) {
      return(
        <div className="small-12 columns advanced-search-row-wrap new-row-wrap">
          {self.state.filters.map(function(filter, idx) {
            return (
              <NewAdvancedSearchRow
                key={idx}
                id={idx}
                idx={idx}
                filter={filter}
                numFilters={numFilters}
                addRow={self.addAdvancedSearchRow}
                removeRow={self.removeAdvancedSearchRow}
                handleChange={self.handleChange}
                handleAdvancedSearchEnter={self.handleAdvancedSearchEnter}
              />
            )
          })}
        </div>
      )
    }
  },

  render: function() {
    return(
      <div>
          {this.renderAdvancedSearchRows()}
      </div>
    )
  }
})

export default NewAdvancedSearch;