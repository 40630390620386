import React, {useState, useEffect, useRef} from 'react';
import Modal from '../../Modal';
import CollectionButton from '../../Shared/Buttons/CollectionButton';
import CollectionDeleteModal from './CollectionDeleteModal';
import DeferralModal from './DeferralModal';

const CollectionCard = ({collection, index, fetchCollections}) => {

  const [hiddenCategories, setHiddenCategories] = useState(0);
  const [dropdownActive, setDropdownActive] = useState(false);
  let categoryContainer = useRef();
  let categoryItems = useRef();
  const maxCategoriesToShow = 3;

  useEffect(() => {
    window.addEventListener('resize', adjustCategories);
    adjustCategories();
    $(document).foundation('tooltip', 'reflow');
    return () => {
      window.removeEventListener('resize', adjustCategories);
    };
  },[]);

  useEffect(() => {
    $(document).foundation('tooltip', 'reflow');
  },[hiddenCategories]);

  const adjustCategories = () => {
    categoryContainer = document.getElementById(`categoryContainer${index}`);
    categoryItems = categoryContainer.querySelectorAll('.category');
    let availableSpace = categoryContainer.offsetWidth - 80;
    let visibleCategories = 0;
    for (let i = 0; i < categoryItems.length; i++) {
      categoryItems[i].style.display = 'inline-block';
      const categorySpan = categoryItems[i].querySelector('span');
      categorySpan.style.display = 'inline';

      const categoryWidth = categoryItems[i].offsetWidth; 
      if (categoryWidth > availableSpace) {
        categorySpan.style.display = 'none';
        categoryItems[i].style.display = 'none';
      } else {
        availableSpace -= categoryWidth;
        visibleCategories++;
      }
      if (visibleCategories === maxCategoriesToShow) {
        break;
      }
    }

    const hiddenCats = categoryItems.length - visibleCategories;
    setHiddenCategories(hiddenCats);
  };

  const formatToolTip = (collection) => {
    return collection.categories.map(cat => cat.name).join(', ');
  }
 
  const openMenuDropdown = () => {
    setDropdownActive(!dropdownActive);
  }

  const renderDeleteModal = () => {
    Modal.open({
      children:
        <CollectionDeleteModal collection={collection} fetchCollections={fetchCollections}/>
    })
  }

  const renderDeferralModal = () => {
    Modal.open({
      children:
        <DeferralModal deferralReasons={collection.deferral.reasons}/>
    })
  }

  const renderDropDownButtons = () => {
    return (
      <ul className='cureus-split-dropdown new-dash'>
        <li>
          <a href={`/publish/collections/${collection.id}/preview`} target="_blank">Preview</a>
        </li>
        {collection.deferral.was_deferred &&
          <li onClick={renderDeferralModal}>
            <a>View deferral notes</a>
          </li>
        }
        <li onClick={renderDeleteModal}>
          <a>Delete</a>
        </li>
      </ul>
    )
  }

  const renderButtons = () => {
    if (collection.state === 'Draft' && collection.submitter_of_current_collection) {
      return (
        <>
          <CollectionButton classProp='edit-collection button' text={'Edit collection'} hrefLink={collection.collection_path}/>
          <div className={'dropdown-container ' + (dropdownActive ? 'active' : '')} onClick={openMenuDropdown}>
            <a className='button alternate show-for-large-up menu-collection'>Menu
              <img src="https://public.cureus.com/my_dashboard/my_collections/chevron-down.png" />
            </a>
            {dropdownActive &&
             renderDropDownButtons()
            }
          </div >
        </>
      )
    } else {
      return (
        <>
          <CollectionButton classProp={'preview-button button'} text={'Preview'} preview={true} hrefLink={collection.preview_path}/>
        </>
      )
    }
  };

  const renderCoEditorIcon = () => {
    if (!collection.submitter_of_current_collection) {
      return (
        <span className="has-tip tip-bottom" data-tooltip="" title="You have been added as a co-editor of this collection. The submitting editor is responsible for submitting and revising the collection.">
          <i className="fas fa-user-friends" />
        </span>
      )
    }
  }

  return (
    <div className='large-3 medium-4 small-6 columns unpublished-collection-card'>
      <div className='collection-card-image-container'>
        <div className='status-badge'>{collection.state}</div>
        <img src={collection.banner_image.hero.url} alt="banner-image" />
      </div>
      <div className='collection-card-text-container'>
        <div className='small-card-text' id={`categoryContainer${index}`}>
          <div className='category-container'>
            <div className='category-list'>
              {collection.categories && collection.categories.map((category)=>{
                return (
                  <div className='category' key={category.id}>
                    <span>{category.name}</span>
                  </div>
                )
              })}
              {hiddenCategories > 0 && <div title={formatToolTip(collection)} data-tooltip="" className="category-count tip-top">+{hiddenCategories}</div>}
            </div>
          </div>
        </div>
        <div className='collection-type-container'>
          <h6 className='text-left collection-type'>{collection.type}</h6>
          {renderCoEditorIcon()}
        </div>
        <h5 className='text-left collection-title'>{collection.title}</h5>
      </div>
      <div className='content-footer'>
        {collection.deferral.was_deferred &&
          <div className='defer-container'>
            <i class="fa fa-exclamation-triangle" />
            <p>Collection <a onClick={renderDeferralModal}>deferred for revisions.</a></p>
          </div>
        }
        <div className='buttons-container'>
          {renderButtons()}
        </div>
      </div>
    </div>
  )
}

export default CollectionCard;