var createReactClass = require('create-react-class');
import React from 'react';
import ScoringModal from '../Scoring/ScoringModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const Siq = createReactClass({
  openScoringModal: function(){
    Modal.open({
      children: <ScoringModal
        siq_widget={ this.props.siq_widget }
      />
    });
    $.get(this.props.siq_widget.amplitudePath);
    this.handleClose();
  },

  handleClose: function() {
    document.querySelector(".siq-footer-modal-container").style.display = 'none'
  },

  displayFlash: function() {
    if (this.props.siq_widget.daily_limit) {
      window.scrollTo(0, 0);
      Cureus.Flash.warning("You've read and rated 20 articles today - this is our daily limit.")
    } else {
      window.scrollTo(0, 0);
      Cureus.Flash.warning("There is a five-minute cooldown period before you can rate your next article. Have you read the entire article?")
    }
  },

  renderButton: function() {
    if (this.props.siq_widget.daily_limit || this.props.siq_widget.rate_limit) {
      return <a data-data-highlight="true" className="siq-widget-trigger button special small siq-click" href="#a" onClick={this.displayFlash}>Rate article</a>
    } else if(this.props.siq_widget.user_can_score) {
      return <a data-data-highlight="true" className="siq-widget-trigger button special small siq-click" href="#a" onClick={this.openScoringModal}>Rate article</a>
    } else {
      return <a data-data-highlight="true" className="siq-widget-trigger button special small siq-click" data-reveal-id="siq-join" href="#" onClick={this.handleClose}>Rate article</a>
    }
  },

  render: function (){
    return (
     <div className="rating-container">
      <p>Please provide feedback to the authors by contributing your SIQ rating.</p>
      {this.renderButton()}
      <br/>
      <a data-reveal-id="siq-faq" href="#" className="whats-this">What's this?</a>
     </div>
    )
  }
})

export default Siq;