import React, { useState, useEffect } from 'react';

const NewUserModal = ({ newUserPath, checkUserExistsPath, fetchUsers }) => {

  const [addButtonEnabled, setAddButtonEnabled] = useState(false);
  const [userExists, setUserExists] = useState(true);

  const addNewUser = (e) => {
    if (!addButtonEnabled) {
      return
    }
    $.ajax({
      type: 'POST',
      url: newUserPath,
      data: {
        'user_id': $('#user-id').val(),
        'reason': $('#reason').val()
      },
      success: function(data) {
        fetchUsers()
      }
    });
  }

  const checkAddButtonEnabled = (skipUserExists) => {
    const isEnabled = (skipUserExists || userExists) && $('#user-id').val() !== '' && $('#reason').val() !== ''
    setAddButtonEnabled(isEnabled)
  }

  const checkUserExists = (e) => {
    const userId = e.target.value
    if (userId == '') {
      setUserExists(true)
      setAddButtonEnabled(false)
      return
    }
    $('#user-id').prop('disabled', true)
    $.ajax({
      type: 'POST',
      url: checkUserExistsPath,
      data: {user_id: userId},
      success: function(data) {
        $('#user-id').prop('disabled', false)
        $('#user-id').focus()
        if (data.user_exists) {
          setUserExists(true)
          checkAddButtonEnabled(true)
          // setAddButtonEnabled(true)
        } else {
          setUserExists(false)
          setAddButtonEnabled(false)
        }
      }
    });
  }

  return (
    <div className="wall-of-shame-modal reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-header">
          <h5>
            Add user
          </h5>
        </div>

        <div className="modal-content">
          <div className='modal-form'>
            <div className="input-container">
              <label>User ID</label>
              <input id="user-id" type="text" placeholder="Please enter a valid user ID" onChange={(e) => checkUserExists(e)} />
              <span className={`user-error ${userExists ? 'hide' : ''}`}>No user found with given ID</span>
            </div>

            <div className="input-container">
              <label>Reason</label>
              <textarea id="reason" type="text" placeholder="Please enter the reason this user is being added to the list." onChange={(e) => checkAddButtonEnabled(false)} />
            </div>

            <div className="row">
              <button className='close-modal cancel-modal'>CANCEL</button>
              <button className={`add-button ${addButtonEnabled ? 'cancel-modal' : 'disabled'}`} onClick={() => addNewUser()}>ADD</button>
              { !addButtonEnabled && 
                <div className="columns small-12">
                  <span className="add-warning">You must add all fields before submitting</span>
                </div>              
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default NewUserModal;