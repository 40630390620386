import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import DateSelector from '../../../StatsWidget/DateSelector'
import { isNotAllZeroData } from './shared.js';
import ExportCSVButton, {normalizeArrayOfObjectsToAllArrays} from '../../../Shared/Utility/ExportCSVButton';

const ChannelEditorPreviewActivity = ({dateRanges, currentUserEmail}) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [tableData, setTableData] = useState([]);
  const [dateFrom, setDateFrom] = useState(dateRanges[0].from)
  const [dateTo, setDateTo] = useState(dateRanges[0].to)
  const [selectedRangeName, setSelectedRangeName] = useState("Current week")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
   if (tableData) {
    initializeTable()
   }
  }, [tableData])

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [dateFrom, dateTo, selectedRangeName])

  const fetchData = () => {
    $.ajax({
      type: 'GET',
      url: '/admin/channels/articles_table_data',
      data: {
        dataType: "performance_channel_editor_preview_activity",
        date_from: dateFrom,
        date_to: dateTo
      },
      success: function(data) {
        setTableData(data)
      }
    });
  }

  const initializeTable = () => {
    let dt;
    if (isTableInitialized()) {
      dt = $("#performance-cep").DataTable()
    } else {
      dt = $("#performance-cep").DataTable({
        language: {
          search: "Search by channel name:"
        },
        destroy: true,
        columns: setColumns(),
        dom: '<"row header-bar"<"large-3 columns ed-prev-title"><"large-2 columns ed-prev-date">><"large-5 columns"f><"table"t>r<"row bottom"<"large-2 columns"l><"large-4 columns pagination end"p>>',
        "drawCallback": () => { setLoading(false) }
      });
    }

    dt.api().clear()
    mapColumns(dt)
    dt.api().draw()
    renderTitleAndDateSelector()
  }

  const isTableInitialized = () => {
    return $.fn.dataTable.isDataTable($("#performance-cep"))
  }
  const renderTitleAndDateSelector = () => {
    if (firstLoad) {
      document.querySelector(".ed-prev-title").innerHTML = "<h5>Channel Editor Preview Activity</h5>"
      ReactDom.render(dateSelector(), document.querySelector(".ed-prev-date"))
      setFirstLoad(false)
    }
  }

  const setColumns = () => {
    return [
      {width: '100px'},
      {width: '100px'},
      {width: '100px'},
      {width: '100px'},
      {width: '100px'},
      {width: '100px'},
    ]
  }

  const mapColumns = (dt) => {
    tableData.forEach((data) => {
      if (isNotAllZeroData(data)) {
        dt.fnAddData([
          `<a target="_blank" href="/admin/channels/${data.id}">${data.title}</a>`,
          data.initial_submit_editor_preview,
          data.deferred_1x,
          data.deferred_2x,
          data.deferred_3x,
          data.approved_in_editor_preview
        ])
      }
    })
  }

  const dateSelector = () => {
    return <DateSelector
      from={dateRanges[0].from}
      to={dateRanges[0].to}
      getStats={filterData}
      selectedRange={"Current week"}
      noFloat={true}
      dateRanges={dateRanges}
    />
  }

  const filterData = (from, to, rangeName) => {
    setDateFrom(from)
    setDateTo(to)
    setSelectedRangeName(rangeName)
  }

  const renderSpinner = () => {
    if (loading) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  }

  return (
    <div className='datatables'>
      {renderSpinner()}
      <div className="performance">
        <ExportCSVButton userEmail={currentUserEmail} csvContent={normalizeArrayOfObjectsToAllArrays(tableData)} tableTitle={'Channel_Editor_Preview_Activity'} top={-2} left={"44%"}/>
        <table className="table-wrapper" id="performance-cep">
          <thead>
            <tr>
              <th>Channel</th>
              <th>Initial Submissions to Ed Preview</th>
              <th>Deferred In Editor Preview 1x</th>
              <th>Deferred In Editor Preview 2x</th>
              <th>Deferred In Editor Preview 3x or More</th>
              <th>Approved in Editor Preview</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  )
}
export default ChannelEditorPreviewActivity;

