import {Cureus} from '../Cureus';

Cureus.Ads = function(options) {
  if (this.skipSquareAd(options)) { return; }
  this.options = this.updateOptions(options);

  this.inited = false;
  this.plt = this.options.mdv == 'null' ? 'desktop' : 'mobileweb';
  this.MP_U = this.getCookie("MP_U") || [];
  this.MP_prof = this.getCookie("MP_prof") || [];
  this.MPT = this.getCookie("MP_tval") || [];
  this.specCookie = this.getCookie('MP_spec') || this.options.spec;

  if (!window.cureusAds) { window.cureusAds = []; }
  window.cureusAds.push(this)

  if (this.options.lazyLoad) {
    this.initLazyLoad();
  } else {
    this.inited = true;
    this.runScripts();
  }
}

Cureus.Ads.prototype.skipSquareAd = function(options) {
  if (options.size === 'square') {
    if (document.documentElement.clientWidth < 1024 && options.page !== 'registration') {
      return true;
    } else if (document.documentElement.clientWidth < 640 && options.page === 'registration') {
      return true;
    }
  }
  return false;
}

Cureus.Ads.prototype.initLazyLoad = function() {
  var self = this;
  if (this.inView()) {
    this.inited = true;
    this.runScripts();
  } else {
    document.addEventListener("touchmove", function(event) { 
      self.checkScroll();
    });
    window.addEventListener('scroll', function(event) {
      self.checkScroll();
    })
  }
}

Cureus.Ads.prototype.checkScroll = function() {
  if (this.inited) { return; }
  if (this.inView()) {
    this.inited = true;
    this.runScripts();
  }
}

Cureus.Ads.prototype.updateOptions = function(options) {
  if (document.documentElement.clientWidth < 728 && options.size === 'banner') {
    var newId = 'edh-square-' + options.altDiv;
    this.updateDiv(newId, 'banner', options.divNum, true);
    options.size = 'square';
    options.slot = options.altSlot;
    options.divNum = options.altDiv;
    options.dims = options.altDims;
  }
  return options;
}

Cureus.Ads.prototype.getCookie = function(cookiename) {
  var cookiestring=RegExp(""+cookiename+"[^;]+").exec(document.cookie);
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
}

Cureus.Ads.prototype.runScripts = function() {
  this.scriptOne();
  this.scriptTwo();
}

Cureus.Ads.prototype.inView = function() {
  var elId = 'edh-' + this.options.size + '-' + this.options.divNum;
  var el = document.getElementById(elId);
  var bounding = el.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

Cureus.Ads.prototype.scriptOne = function() {
  var self = this;
  googletag.cmd.push(function() {
    googletag.defineSlot('/4213/edh.cureus/' + self.options.adType, self.options.dims, "edh-"+ self.options.size + "-" + self.options.divNum).setTargeting('pos', [self.options.position]).setTargeting('slot', [self.options.slot]).addService(googletag.pubads());

    googletag.pubads().setTargeting('ugc', [self.options.ugc]).setTargeting('cat', self.options.categories).setTargeting('page', self.options.page).setTargeting('u', self.MP_U).setTargeting('prof', self.MP_prof).setTargeting('spec', self.specCookie).setTargeting('t', self.MPT).setTargeting('mdv', self.options.mdv).setTargeting('plt', self.plt);

    googletag.enableServices();
  })
}

Cureus.Ads.prototype.scriptTwo = function() {
  var self = this;
  googletag.cmd.push(function() {
    googletag.display("edh-" + self.options.size + "-" + self.options.divNum);
  });
}

Cureus.Ads.UpdateUgc = function() {
  window.cureusAds.forEach(function(ad) {
    var divNums = window.cureusAds.map(function(el) { return el.options['divNum']})
    var newDivNum = Math.max.apply(null, divNums) + 1
    var newId = 'edh-' + ad.options.size + '-' + newDivNum.toString();
    ad.updateDiv(newId, ad.options.size, ad.options.divNum);
    ad.options['ugc'] = '1';
    ad.options['divNum'] = newDivNum.toString();
    ad.scriptOne()
    ad.scriptTwo()
  });
}

Cureus.Ads.prototype.updateDiv = function(newId, size, divNum, updateClass) {
  var oldId = 'edh-' + size + '-' + divNum;
  var adEl = document.getElementById(oldId);
  if (updateClass) {
    adEl.parentElement.removeClass('banner')
    adEl.parentElement.addClass('square')
  }
  adEl.innerHTML = '';
  delete adEl.dataset.googleQueryId;
  adEl.id = newId;
}