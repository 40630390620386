import React, {useState, useEffect} from 'react';
import { HashRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

const GuestEditorDraftTable = ({collections, categories, fetchCollectionsForSelectedCategory, activeTab, type}) => {

  const [selectedDropdownCategory, setSelectedDropdownCategory] = useState("");
  const location = useLocation();

  useEffect(() => {
    populateTable(collections);
    initializeOrSetCategoriesDropdown();
  }, [collections])
  
  useEffect(() => {
    document.querySelector('#category-selector').value = "all";
    setSelectedDropdownCategory('');
  }, [activeTab])

  const initializeOrSetCategoriesDropdown = () => {
    createCategoriesDropdown(categories);
    if (!selectedDropdownCategory) {
      return;
    } else {
      document.querySelector('#category-selector').value = selectedDropdownCategory;
    }
  }

  const createCategoriesDropdown = (categories) => {  
    const selectElement = document.createElement('select');
    selectElement.id = 'category-selector';
    selectElement.className = 'large-3 columns';

    const defaultOption = document.createElement('option');
    defaultOption.value = "all";
    defaultOption.text = "Category";
    selectElement.appendChild(defaultOption);

    categories.forEach((category) => {
      const option = document.createElement('option');
      option.value = category.name;
      option.text = category.name;
      selectElement.appendChild(option);
    })

    const searchFiltersElement = document.querySelector(".search-filters");
    if (searchFiltersElement) {
      searchFiltersElement.appendChild(selectElement);
    }

    $('#category-selector').on('change', function() {
      const selectedCategory = $(this).val();
      if (selectedCategory) {
        setSelectedDropdownCategory(selectedCategory);
        fetchCollectionsForSelectedCategory(selectedCategory, location.pathname.split('/')[2], type);
      }
    })
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData([
        row.created_and_updated,
        `<a class='column-hoverable' href='/admin/collections/${row.id}'>${row.id}</a>`,
        `<a class='column-hoverable' id=${row.id} href='/admin/users/${row.created_by_id}'>${row.created_by}</a>`,
        `<a class='column-hoverable' href='/admin/collections/${row.id}'>${row.title}</a>`,
        row.category,
        row.deferred
      ], false)
    )
  }

  const setColumns = () => {
    return [
      {width: 'centered'},
      {class: 'centered'},
      {class: 'centered'},
      {class: 'centered'},
      {class: 'centered'},
      {class: 'centered'}
    ]
  }

  const populateTable = (collectionsData) => {
    var dt = $("#collections-table").DataTable({
      destroy: true,
      pageLength: 20,
      lengthMenu: [20, 50, 100],
      language: {
        searchPlaceholder: "Search..."
      },
      columns: setColumns(),
      columnDefs: [
        {targets: [0], orderData: [1]}
      ],
      dom: '<"row"<"large-12 columns search-filters"f>>tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
    })
    
    dt.api().clear();
    for (var i = 0; i < collectionsData.length; i++) {
      let row = collectionsData[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
    $('#collections-table_filter label').contents().filter(function() {
      return this.nodeType === 3;
    }).remove();
    $('#collections-table_filter').addClass('large-5 columns');
  }

  return (
    <div className='row'>
      <div className='small-12 columns collection-table-section'>
        <table id="collections-table">
          <thead>
            <tr>
              <th>Date Created / Updated</th>
              <th>ID</th>
              <th>Created by</th>
              <th>Collection Title</th>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  )
}

export default GuestEditorDraftTable;