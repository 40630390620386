import React, {useState, useEffect} from 'react';
import SpecialtyCard from './SpecialtyCard';
import Filter from '../Shared/FilterTab/Filter';
import SearchBar from '../Shared/SearchBar/SearchBar';

const Main = () => {

  const [specialtiesForCards, setSpecialtiesForCards] = useState([]);
  const [specialtiesForFilterTab, setSpecialtiesForFilterTab] = useState([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  useEffect(() => {
    fetchSpecialties();
  }, [])

  const fetchSpecialties = () => {
    $.ajax({
      type: "GET",
      url: `/specialties`,
      dataType: "json",
      success: function(data) {
        setSpecialtiesForCards(data.cards);
        setSpecialtiesForFilterTab(data.cards);
      }
    })
  }

  const renderSpecialtiesCards = () => {
    return (
      <div className={"large-12 medium-12 small-12 columns specialty-cards-section "}>
        {specialtiesForCards.map((specialty) => {
          return <SpecialtyCard specialty={specialty} />
        })}
      </div>
    )
  };

  return (
    <div className='row specialties-page'>
      <div className='large-11 medium-11 small-12 columns small-centered specialties-section'>
        <div className='header-container'>
          <p className='text-center mini-header'>MEDICAL SPECIALTIES</p>
          <h1 className='text-center'>Browse by Specialty</h1>
        </div>
        <SearchBar
          url={"/specialties"}
          placeHolder={"Search for a specific specialty here."}
          specialtiesPage={true}
          setCards={setSpecialtiesForCards}
          isFilterMenuOpen={isFilterMenuOpen}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          page={"specialties"}
          isFilterRequired={true}
        />
        {renderSpecialtiesCards()}
        <Filter 
          isMenuOpen={isFilterMenuOpen}
          setFilterMenuOpen={setIsFilterMenuOpen}
          contents={specialtiesForFilterTab}
          pills={[]}
        />
      </div>
    </div>
  )
}

export default Main;