import React from 'react';

const Introduction = () => {
  return (
    <React.Fragment>
      <h1>Introduction</h1>
      <h2>What to Include in Your Review</h2>
      <p>Article credibility is determined by favorable commentary from trusted peer reviewers that includes <strong>substantial critical feedback</strong> confirming the absence of fatal scientific and methodological flaws. <strong>Do not include comments on spelling, grammar, punctuation, or formatting - this is not part of scientific peer review!</strong> Positive reviews lacking in substantial constructive feedback will be closely scrutinized by our editors and may be rejected</p>
      <h2>The Cureus Peer Review System</h2>
      <p>All submitted articles must undergo a single-blind pre-publication peer review process in which reviewers know the identity of the authors, but authors do not know the identity of the reviewers.</p>
      <p>Reviewers are made anonymous through Cureus' automated system. Once a reviewer accepts the review invitation, they are assigned a Greek letter to be used for identification purposes by the authors (e.g. Reviewer Alpha).</p>
      <p>Authors are required to suggest a minimum of five reviewers when submitting their draft. In addition to these author-suggested reviewers, a minimum of six peer reviewers are automatically invited from the Cureus internal peer review panel, consisting of relevant specialists who have either volunteered for the panel or previously published as an author in Cureus. This ensures that a minimum of 11 peer reviewers are invited to review each article.</p>
      <p>Please note that medical students may not be invited to peer review. Prior to the forwarding of an invitation, each author-submitted reviewer is carefully vetted by our editorial staff with a specific focus on relevant scientific expertise. Once the article has entered peer review, three additional reviewers are automatically invited by Cureus every two days to ensure that the article completes peer review in a timely fashion.</p>
      <p>Two completed reviews from Cureus-invited reviewers are required to satisfy peer review.</p>
      <h2>Ending the Peer Review Period</h2>
      <p>Authors have the option to end the peer review period for all reviewers once their article has satisfied our peer review requirements. If chosen, this option will expire all outstanding and in-progress reviews, with the exception of any reviews that were started within the prior 48 hours. These reviewers are given a maximum of 24 hours to submit their review, however, the review period may end sooner if the author revises and submits for publication approval.</p>
      <p>Authors who are waiting on a Cureus-invited review (and have received at least one review from an author-invited reviewer) can also choose to end the review period for the remaining author-invited reviewers. Any reviewers who started their review in the prior 48 hours will be notified that they have a maximum of 24 hours remaining to submit their review.</p>
      <h2>Reviewer Keywords</h2>
      <p>Users who have published an article in Cureus or volunteered for our peer review panel will be prompted to select up to 20 keywords to ensure they are matched with articles in their areas of interest and expertise. When you start typing in the keyword field, a list of suggested keywords will be displayed so you can make your selection. (New keywords cannot be added at this time, but don’t worry - our list is quite extensive.) Peer reviewers will then be matched with relevant articles based on their keywords.</p>
    </React.Fragment>
  )
}

export default Introduction;