var createReactClass = require('create-react-class');
import React from 'react';
import EditorialActivity from './EditorialActivity';
import InvoiceTool from './InvoiceTool';
import UpdateMiscItemModal from './UpdateMiscItemModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const Main = createReactClass({
  getInitialState: function() {
    return({
      open: false,
      from: this.props.dateRanges[2].from,
      to: this.props.dateRanges[2].to,
      selectedRange: this.props.dateRanges[2].label,
      articlesCompleted: this.props.articlesCompleted,
      miscItemCount: this.props.miscItemCount,
      tableData: this.props.tableData
    })
  },

  componentDidMount: function() {
    this.props.isAdmin ? this.addEventListenersToMisc() : null
  },

  updateRange: function(fromDate, toDate, label) {
    var self = this;
    return function() {
      self.setState({
        from: fromDate, to: toDate, selectedRange: label, open: false
      }, function(){
        self.getData()
      });
    }
  },

  payload: function() {
    var payload = this.state
    payload.tableData = null
    return payload
  },

  getData: function() {
    var self = this
    $.ajax({
      type: "get",
      dataType: "json",
      data: self.payload(),
      success: function(data) {
        self.setState(data)
        let dt = $("#editor-activity-table").DataTable();
        dt.fnClearTable();
        if(data.tableData.length != 0) {
          dt.fnAddData(data.tableData)
        }
      },
      error: function() {
        alert("Please select a valid date range.")
      }
    })
  },

  addEventListenersToMisc: function() {
    var self = this;
    $('#editor-activity-table').on('click', 'tr.misc-row',  function(e) {
      var miscRow = e.target.parentElement.className.match("misc-row") ? e.target.parentElement : e.target.parentElement.parentElement
      var miscId = miscRow.children[1].children[0].dataset["miscId"]
      var url = "/admin/misc_events/" + miscId
      $.get(url, {}, function(data){
        self.setState({miscEventData: data['miscEvent']}, function() {self.openModal()})
      }, 'json')
    })
  },

  populateTable: function() {
    var self = this;
    $(function() {
      $('#editor-activity-table').DataTable({
        dom: '<"row"<"large-2 columns"l><"large-5 columns"f>>t<"row"<"large-6 columns"i><"pull-right"p>>',
        pagingType: 'full_numbers',
        data: self.props.tableData,
        autoWidth: false,
        search: {
          search: Cureus.Utils.QueryString.urlParams().q
        },
        columns: [
          {width: '120px', class: "centered"},  // hidden date column for sorting
          {width: '120px', class: "centered"},  // Completed Date
          {width: '100px', class: "centered"},  // ID
          {width: '120px', class: "centered"},  // Tier
          {width: '100px', class: "centered"},  // Type
          {width: '100px', class: "centered"},  // Task
          {width: '120px', class: "centered"},  // Location
          {width: '120px', class: "centered"},  // Article Price
          {width: '120px', class: "centered"},  // Wordcount
          {width: '120px', class: "centered"},  // Title
          {width: '100px', class: "centered"},  // Rate
          {width: '100px', class: "centered"},  // Amount

        ],
        order: [[0, 'asc']],
        columnDefs: [
          // column index number 1 needs to be sorted using data from hidden column index 0
            {
              targets: [1],
              orderData: [0]
            },
            {
              targets: [0],
              visible: false
            }
          ],

          "createdRow": function (row, data, dataIndex) {
            if ($(data[2]).html() === 'Misc' && self.props.isAdmin) {
              $(row).addClass('misc-row')
            }
          },

        "fnRowCallback": function (nRow, aData, iDisplayIndex) {

          var approved = $(nRow).find("td:nth-child(3) i.completed");
          var copy_edited = $(nRow).find("td:nth-child(4) i.completed");
          if((approved.length >= 1) && (copy_edited.length >= 1)){ $(nRow).addClass('approved_and_copy_edited'); }
        },
        "initComplete": function (settings, json) {
          $(document).foundation('reflow');
        },
        "drawCallback": function( settings ) {
          $(document).foundation('reflow');
        }
      });
      Cureus.DataTable.searchOnCompletion("editor-activity-table");
    })
  },

  openModal: function() {
    Modal.open({
      children: <UpdateMiscItemModal
                  miscEventData={this.state.miscEventData}
                  populateTable={this.populateTable}
                  getData={this.getData}
                  />
    })
  },

  updateFrom: function(e) {
    this.setState({from: e.target.value})
  },

  updateTo: function(e) {
    this.setState({to: e.target.value})
  },

  closeDatePicker: function() {
    this.setState({open: false})
  },

  applyButton: function() {
    var self = this;
    this.setState({
      open: false,
      selectedRange: this.state.from + ' - ' + this.state.to
    }, function() {
      self.getData()
    })
  },

  toggleOpen: function() {
    this.setState({open: !this.state.open})
  },

  render: function() {
    return(
      <div>
        <InvoiceTool
          dateRanges={this.props.dateRanges}
          updateFrom={this.updateFrom}
          updateTo={this.updateTo}
          toggleOpen={this.toggleOpen}
          updateRange={this.updateRange}
          applyButton={this.applyButton}
          closeDatePicker={this.closeDatePicker}
          isCurrentUser={this.props.isCurrentUser}
          earningsHash={this.props.earningsHash}
          {...this.state}
          />
          <h4>Editorial Activity</h4>
        <EditorialActivity
          tableData={this.state.tableData}
          path={this.props.path}
          populateTable={this.populateTable}
        />
      </div>
    )
  }
})

export default Main;
