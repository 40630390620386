import React, { Component } from 'react';
import Intro from './Intro';
import Selection from './Selection';
class OnDemandModal extends Component {
  state = {
    step: 1,
    articles: [],
    loading: true
  }

  componentDidMount() {
    this.fetchArticles()
  }

  fireAmplitude = (eventName, eventParams) => {
    eventParams['userId'] = this.props.currentUserId;
    eventParams['event_type'] = eventName
    $.ajax("/amplitudes", {
      dataType: "JSON",
      type: "POST",
      data: eventParams
    })
  }

  fetchArticles = () => {
    $.ajax({
      type: 'GET',
      url: this.props.articlesPath,
      dataType: 'json',
      success: (res) => {
        this.setState({
          loading: false,
          articles: res.articles
        }, function() {
          this.fireAmplitude('onDemandClick', {coolDown: this.props.cooldown ? 1 : 0, availableArticles: res.articles.length})
        })
      }
    });
  }

  handleNext = () => {
    this.setState({step: 2})
  }

  handleBack = () => {
    this.setState({step: 1})
  }

  renderContent() {
    if(this.state.loading) {
      return;
    }
    if(this.state.step === 1) {
      return <Intro 
                handleNext={this.handleNext}
                noArticles={this.state.articles.length === 0}
                cooldown={this.props.cooldown}
              />
    } else {
      return <Selection
                articles={this.state.articles}
                handleBack={this.handleBack}
                acceptancePath={this.props.acceptancePath}
                fireAmplitude={this.fireAmplitude}
              />
    }
  }

  render() {
    return (
        <div className="reveal-modal medium on-demand-modal" data-reveal>
          <div className="modal-body">
            <div className='modal-close'>
              <a href='#' className='cancel-modal'>
                <img src="https://public.cureus.com/close_x.png"/>
              </a>
            </div>
            {this.renderContent()}
          </div>
      </div>
    )
  }
}

export default OnDemandModal;