import React from "react";

const TopContent = ({
  contents,
  seeMoreUrl,
  viewMoreArrow = true,
  contentTypeTitle,
  contentType,
}) => {
  const renderAllContentInfo = () => {
    return contents.map((content, idx) => {
      const { id, title, author_list } = content;

      let authorListSplit = author_list.split(',');
      let authorList = authorListSplit.slice(0, 2).join(', ');
      let overFlow = authorListSplit.slice(2).length;

      return (
        <div className='content-container' key={`content-${idx}`}>
          <a
            href={`/${contentType}/${id}`}
            target="_blank"
            className="content"
          >
            {title}
          </a>
          <span className="author-info">
            <p>{authorList}</p>
            {overFlow > 1 &&
              <a
                className="more-authors"
                href={`/${contentType}/${id}`}
                target="_blank"
              >
                &nbsp;+{overFlow} more
              </a>
            }
          </span>
        </div>
      );
    });
  };

  return (
    <div className="top-contents">
      <div className="contents">
        <div className="header">
          <div className="text">
            <h2>Top {contentTypeTitle}</h2>
            <p>Top Popular {contentTypeTitle}</p>
          </div>
          {viewMoreArrow && seeMoreUrl && (
            <a href={seeMoreUrl} target="_blank">
              <i className="fas fa-arrow-right" />
            </a>
          )}
        </div>
        {renderAllContentInfo()}
      </div>
    </div>
  );
};

export default TopContent;
