var createReactClass = require('create-react-class');
import React from 'react';
import Sidebar from './Sidebar';
import Editor from './Editor';
import { Cureus } from '../../Cureus';

const Main = createReactClass({
  getInitialState: function() {
    return({
      specialties: this.props.specialties,
      editors: this.props.editors,
      specialtyCheck: {},
      search: '',
      loading: false
    })
  },

  componentDidMount: function() {
    new Cureus.LazyLoader.init({selector:'img[data-lazy]'});
  },

  filteredEditors: function() {
    var self = this;
    return self.state.editors.filter(function(editor) {
      return (String(editor.full_name + ' ' + editor.affiliation_name + ' ' + editor.specialty_name).toLowerCase()).indexOf(self.state.search.toLowerCase()) !== -1
    })
  },

  renderEditors: function(firstRow, remaining) {
    var self = this;
    var editors = self.state.search.length ? self.filteredEditors() : self.state.editors;
    if(firstRow) {
      editors = editors.slice(0, 2)
    } else if(remaining) {
      editors = editors.slice(2)
    }
    return editors.map(function(editor, idx) {
      return <Editor editor={editor} spinnerURL={self.props.spinnerURL} idx={idx} key={idx + '_' + editor.id} firstRowCol={firstRow} minHeight={(!self.props.filter && [0,1,2,3].includes(idx))} filter={self.props.filter}/>
    });
  },

  renderContent: function() {
    if (this.state.loading) {
      return(
        <div className='large-12 centered'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    } else {
        return this.renderEditors()  
    }
  },

  renderLarge: function() {
    return (
      <React.Fragment>
        <div className="row show-for-large-up">
          <div className="large-6 columns no-padding">
            <Sidebar updateSearch={this.updateSearch} updateLoading={this.updateLoading} specialties={this.state.specialties} updateEditors={this.updateEditors} />
          </div>
          <div className="large-6 columns no-padding edit-first-row">
            {this.renderEditors(true)}
          </div>
        </div>
        <div className="row show-for-large-up">
          <div className="large-12 columns no-padding">
            {this.renderEditors(false, true)}
          </div>
        </div>
      </React.Fragment>
    )
  },

  updateLoading: function(bool) {
    this.setState({loading: bool})
  },


  updateEditors: function(editors) {
    this.setState({editors: JSON.parse(editors)})
  },

  updateSearch: function(val) {
    return function() {
      this.setState({search: val})
    }.bind(this)
  },

  renderBody: function() {
    if (this.props.filter) {
      return (
        <React.Fragment>
          {this.renderLarge()}
          <div className='row show-for-medium-down'>
            <div className='small-12 columns'>
              <Sidebar updateSearch={this.updateSearch} updateLoading={this.updateLoading} specialties={this.state.specialties} updateEditors={this.updateEditors} />
            </div>
            <div className='small-12 columns'>
              {this.renderContent()}
            </div>
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <div className='row'>
          <div className='small-12 large-12 columns no-padding'>
            {this.renderContent()}
          </div>
        </div>
      )
    }
  },

  render: function() {
    return(
      <div>
        { this.renderBody() }
      </div>
    )
  }
});

export default Main;