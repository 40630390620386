var createReactClass = require('create-react-class');
import React from 'react';

const Filter = createReactClass({
  getDefaultProps: function() {
    return {
      options: [
        {
          label: "All",
          value: "all"
        },
        {
          label: "Draft",
          value: "draft"
        },
        {
          label: "Sent",
          value: "sent"
        }
      ]
    }
  },

  handleChange: function(val) {
    this.props.onChange(val);
  },

  render: function() {
    return (
      <Select
        value={ this.props.value }
        onChange={ this.handleChange }
        options={ this.props.options }
        clearable={ false }
        searchable={ false }
      />
    )
  }
});

export default Filter;