var createReactClass = require('create-react-class');
import React from 'react';
import MainModal from './MainModal';
import Modal from '../Modal';

const Main = createReactClass({
  componentDidMount: function(){
    var self = this;
    $(".deferral-modal-button").on('click', function(){
      if ( !self.isDisabled($(this)) ) {
        self.openDeferralModal();
        window.scrollTo(0, 0)
      }
    });
  },

  isDisabled: function(element) {
    return element.attr("class").includes("disabled")
  },

  openDeferralModal: function(){
    Modal.open({
      children: <MainModal
        path={this.props.path}
        articleState={this.props.articleState}
        userRole={this.props.userRole}
        inEditorPreview={this.props.inEditorPreview}
        actionMethod={this.props.actionMethod}
        reasons={this.props.reasons}
        tier={this.props.tier}
        sendToSeniorCopyPath={this.props.sendToSeniorCopyPath}
        seniorCopyOwnership={this.props.seniorCopyOwnership}  
        affiliationsPath={this.props.affiliationsPath}
      />
    })
  },

  render: function(){
    return (
      <div></div>
    );
  }
})

export default Main;