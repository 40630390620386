import React, { useEffect, useState } from 'react';
import Carat from './Carat';

const Categories = ({categories, categoryIds, updateCategories, checkedLength, id}) => {
  const [collapse, setCollapse] = useState(false);
  const [total, setTotal] = useState(checkedLength(Object.values(categories)))
  const [showMore, setShowMore] = useState(false)

  const handleChange = (categoryId) => {
    let updatedCategories = categories
    updatedCategories[categoryId].checked = !updatedCategories[categoryId].checked
    if(categoryId === 0) {
      clearCategories()
    } else {
      updatedCategories[0].checked = false
      setTotal(updatedCategories[categoryId].checked ? total + 1 : total - 1)
    }
    updateCategories(updatedCategories)
  }

  const clearCategories = () => {
    let updatedCategories = categories
    categoryIds.forEach((id) => {
      updatedCategories[id].checked = false
    })
    updatedCategories[0].checked = true
    setTotal(0)
    updateCategories(updatedCategories)
  }

  const renderCategories = () => {
    return categoryIds.map((catId) =>  {
      var el = categories[catId]
      return (
        <div className={`checkbox-row ${(el.checked ? 'checked' : '')}`} key={el.id + id}>
          <input id={el.id + id} type="checkbox" className="css-checkbox" checked={el.checked} onChange={() => handleChange(el.id)} />
          <label htmlFor={el.id + id}><span>{el.name}</span></label>
        </div>
      )
    })
  }

  const collapsedClass = () => {
    return collapse ? 'collapsed' : ''
  }

  return (
    <div className="row small-collapse">
      <div className="small-12 columns">
        <div className={`filter-header ${collapsedClass()}`} onClick={() => setCollapse(!collapse)}>
            <span>Categories {total > 0 && <span>({total})</span>}</span>
            {<Carat collapse={collapse} setCollapse={setCollapse}/>}
        </div>
        {!collapse && <div className="checkbox-container" style={showMore ? {} : {height: '305px'}}>
          {renderCategories()}
        </div>}
        {!showMore && <a className="button stencil-blue small show-more" onClick={() => setShowMore(!showMore)}>Show more</a>}
        {showMore && <a className="button stencil-blue small show-more" onClick={() => {setShowMore(!showMore); window.scrollTo(0,0);}}>Show less</a>}
        {total > 0 && <a className="button home-orange small show-more" onClick={clearCategories}>Clear Categories</a>}
      </div>
    </div>
  )
}

export default Categories