import React from 'react';

const IcmjeRequirements = () => {
  return (
    <React.Fragment>
      <h1>ICMJE Authorship Requirements</h1>
      <p><strong>Cureus follows the <a href='https://www.icmje.org/' target='_blank'>Recommendations for the Conduct, Reporting, Editing, and Publication of Scholarly Work in Medical Journals</a> issued by the International Committee of Medical Journal Editors (ICMJE). In addition, Cureus adheres to the ICMJE uniform requirements of authorship. All authors of an article must meet the following requirements:</strong></p>
      <div className='indented-for-medium-up'>
        <p className='left-gray-bar'>
          <em>&ldquo;Authorship credit should be based on 1) substantial contributions to conception and design, acquisition of data, or analysis and interpretation of data; 2) drafting the article or reviewing it critically for important intellectual content; 3) final approval of the version to be published; AND 4) agreement to be accountable for all aspects of the work in ensuring that questions related to the accuracy or integrity of any part of the work are appropriately investigated and resolved. All those designated as authors should meet all four criteria.</em>
        </p>
        <p className='left-gray-bar'>
          <em>Acquisition of funding, collection of data, or general supervision of the research group alone does not constitute authorship.</em>
        </p>
        <p className='left-gray-bar'>
          <em>All persons designated as authors should qualify for authorship, and all those who qualify should be listed.</em>
        </p>
        <p className='left-gray-bar'>
          <em>Each author should have participated sufficiently in the work to take public responsibility for appropriate portions of the content.&rdquo;</em>
        </p>
      </div>
      <p>The submitting author will be prompted during the submission process to select contributions for each listed author. Any contributor who does not meet the criteria for authorship may be listed in the Acknowledgements section with their consent.</p>
      <p>Examples include a person who provided technical help or writing assistance or a department chair that provided general support. Disputes regarding authorship and contributorship must be resolved by the authors as it is not the journal’s responsibility to adjudicate such disputes.</p>
      <p>The submitting author and corresponding author are two distinct designations that may be assigned to one or separate authors.</p>
      <p><strong>The submitting author's role and responsibilities are to:</strong></p>
      <ul className='indented-for-medium-up'>
        <li className='check'>
          <strong>Inform all co-authors when an article bearing their name has been submitted to Cureus. (Upon submission, each co-author will also receive an email requesting confirmation of authorship).</strong>
        </li>
        <li className='check'>
          <strong>Manage correspondence between Cureus and all co-authors, keeping the full co-author group apprised of the article progress. (Each co-author will receive automated email notifications confirming authorship, article publication, and, if necessary, article retraction.)</strong>
        </li>
        <li className='check'>
          <strong>Declare all relevant conflicts of interest on behalf of all co-authors.</strong>
        </li>
        <li className='check'>
          <strong>Ensure, to the best of their abilities, that the article fully adheres to all Cureus policies (including such items as publication ethics, data deposition, materials deposition, etc).</strong>
        </li>
      </ul>
      <p><strong>The corresponding author’s role and responsibilities are to:</strong></p>
      <ul className='indented-for-medium-up'>
        <li className='check'>
          <strong>Respond to all queries pertaining to the published article, while also providing relevant data and materials as requested.</strong>
        </li>
      </ul>
    </React.Fragment>
  )
}

export default IcmjeRequirements;