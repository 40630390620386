import {Cureus} from '../Cureus';

Cureus.Drags = function(elem, handle) {
  this.elem = elem;
  this.handle = handle;
  this.xPos = 0;
  this.yPos = 0;
  var self = this;
  this.handle.addEventListener('mousedown', self.onDown.bind(self), false)
  window.addEventListener('mouseup', self.onUp.bind(self), false)
}

Cureus.Drags.prototype.move = function(e) {
  this.elem.style.top = (e.clientY - this.yPos) + "px";
  this.elem.style.left= (e.clientX - this.xPos) + "px";
}

Cureus.Drags.prototype.onDown = function(e) {
  var self= this;
  self.xPos = e.clientX - self.elem.offsetLeft;
  self.yPos = e.clientY - self.elem.offsetTop;
  window.onmousemove = self.move.bind(self)
}

Cureus.Drags.prototype.onUp = function(e) {
  var self= this;
  window.onmousemove = null;
}