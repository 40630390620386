import React from 'react';

const ResultsHeader = () => {
  return (
    <div className="row">
      <div className="large-11 medium-12 small-12 columns show-for-medium-up small-centered end">
        <div className="row results-header ">
        `  <div className="small-4 columns">
            <p>Name</p>
          </div>
          <div className="small-3 columns text-left">
            <p>Affiliation</p>
          </div>
          <div className="small-2 text-right columns">
            <p>Publications</p>
          </div>
          <div className="small-1 text-center columns">
            <p>Peer Reviews</p>
          </div>
          <div className="small-1 text-center  columns">
            <p>SIQ Ratings</p>
          </div>
          <div className="small-1 text-center columns">
            <p>Honors Tier</p>
          </div>
        </div>`
      </div>
    </div>
  );
};

export default ResultsHeader;
