import React, { useState, useEffect } from 'react';
import { Switch, Route, Link } from "react-router-dom";
import Promotion from "./Promotion"

const Sidebar = ({categoriesList}) => {

  const [activeSubTab, setActiveSubTab] = useState(location.pathname)
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    setActiveSubTab('/sidebar/promotion');
    fetchPromotions();
  }, [])

  const fetchPromotions = () => {
    $.ajax({
      url: "/admin/browse_specialty_design",
      type: 'GET',
      dataType: 'json',
      success: function(res) {
        let emptyPromotionAdded = [...res.promotions, {id: null}]
        setPromotions(emptyPromotionAdded);
      }
    });
  }

  const renderPromotionComponents = () => {
    return (
      <>
        {promotions.map((promotion, idx) => {
          const cssStylingAfterFirstElement = idx > 0 ? "styling-top" : "";
          if (promotion.id) {
            const { id, image, url, selected_categories } = promotion;
            return (
              <Promotion 
                key={id}
                id={id}
                promoImage={image}
                urlLink={url}
                selectedCategories={selected_categories}
                cssStyling={cssStylingAfterFirstElement}
                categories={categoriesList}
                fetchPromotions={fetchPromotions}
                setPromotions={setPromotions}
              />
            )
          } else {
            return (
              <Promotion 
                key={idx}
                categories={categoriesList}
                cssStyling={cssStylingAfterFirstElement}
                fetchPromotions={fetchPromotions}
                setPromotions={setPromotions}
              />
            )
          }
        })}
      </>
    )
  }

  return (
    <>
      <div className="small-12 columns nav-container bordered">
        <div className="row">
          <div className="large-12 columns">
            <div className="cureus-tabs secondary-tabs">
              <div className="tabs-container dashboard">
                <div className="tab-items">
                  <ul>
                  <Link to="/sidebar/promotion">
                    <li className={"tab-title inline " + (activeSubTab === '/sidebar/promotion' ? 'active' : '')} onClick={() => setActiveSubTab('/sidebar/promotion')}>
                      <a className="dashboard">PROMOTION</a>
                    </li>
                  </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch>
        <Route path='/sidebar/promotion'>
          <div className="small-12 columns">
            <h3>Promotion</h3>
          </div>
          {renderPromotionComponents()}
        </Route>
      </Switch>
    </>
  )

}

export default Sidebar;