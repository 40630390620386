
var createReactClass = require('create-react-class');
import React from 'react';
import Form from '../../UserPicker/Form';
import * as UserToolUtils from '../../Publishing/UserTool/ApiClient';

const AuthorDetailsModal = createReactClass({
  getInitialState: function() {
    return {
      spinner: false,
      errors: null
    }
  },

  handleSubmit: function(params) {
    var self = this;
    if (self.props.resource.user.change_email) {
      params.user.change_email = true
    }
    UserToolUtils.putUpdate({
      id: self.props.resource.authorship.id,
      params: Object.assign(params, self.props.defaultParams),
      endpointUrl: self.props.endpointUrl,
      success: function(res) {
        self.props.updateUsership(res)
        $(self.refs.container).foundation("reveal", "close");
      },
      error: function(jqXHR) {
        self.setState({errors: JSON.parse(jqXHR.responseText)});
      }
    });
  },

  render: function() {
    return (
      <div className="reveal-modal middling user-picker-modal" data-reveal ref="container">

        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className='modal-content'>
            <Form
              key="edit_coauthor_form"
              resource={ this.props.resource }
              title={"Author Details"}
              submitText={"Update Author"}
              specialtyOptions={this.props.specialtyOptions}
              countryOptions={this.props.countryOptions}
              affiliationNameQueryUrl={this.props.affiliationNameQueryUrl}
              deptAutocompletePath={ this.props.deptAutocompletePath }
              enableSearch={true}
              onSubmit={this.handleSubmit}
              showSpinner={this.state.spinner}
              errors={this.state.errors}
              hideInstitutionFields={false}
              added={true}
              searchEmail={this.props.searchEmail}
              showCheckingForUser={this.state.showCheckingForUser}
              dashboardEmail={true}
              isAuthorship={true}
              />
          </div>
        </div>
      </div>
    )
  }
})

export default AuthorDetailsModal;