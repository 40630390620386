import React, { useState, useEffect } from 'react';
import ResultCard from './ResultCard';

const SearchResults = ({ query, guide }) => {

  const pageChoices = [10, 25, 50, 100];
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(pageChoices[0]);
  const [searchData, setSearchData] = useState({})
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if (query) {
      setSpinner(true);
      $.ajax({
        type: "POST",
        url: '/guides/search',
        data: { query: query, guide: guide, page: page, perPage: perPage},
        success: function(data) {
          setSearchData(data);
          setSpinner(false);
          window.scroll({top: 0, behavior: 'smooth'})
        }
      })
    }
  }, [query, page, perPage])

  function renderResultsHeader() {
    var start = (page - 1) * perPage + 1;
    var end = Math.min(page * perPage, searchData.totalCount);
    return (
      <div>
        Results <strong>{ start }-{ end } of { searchData.totalCount }</strong> for <strong>{"\u00ab"}{ searchData.query }{"\u00bb"}</strong>
      </div>
    )
  }

  function renderResults() {
    return searchData.searchResults.map(function(res) {
      var data = res.data
      var attributes = res.data.attributes;
      var href = guide == attributes.guide ? "" : attributes.guide
      href = href + "#!/" + attributes.section + "/" + attributes.page + "/##" + attributes.position;
      return <ResultCard key={ data.id } title={ attributes.title } header={ attributes.header } content={ attributes.content } href={ href }/>
    })
  }

  function renderPageSelection() {
    var pageNumbers = Array.from(Array(searchData.totalPages).keys());
    var tds = pageNumbers.map(function(x) {
      var number = x + 1;
      var active = number == page ? "active" : ""
      if (number == 1 || number == searchData.totalPages || (number >= page - 2 && number <= page + 2)) {
        return <td key={ "page-" + number} className= { active } onClick={ () => setPage(number) }> { number }</td>
      }
    })
    return (
      <table className='pagination'>
        <tbody>
          <tr>
            { tds }
          </tr>
        </tbody>
      </table>
    )
  }

  function renderPerPageFooter() {
    var tds = pageChoices.map(function(x) {
      var active = perPage == x ? "active" : ""
      return (
        <td key={ `per-page-${x}`} onClick={ () => { setPerPage(x); setPage(1); } } className={ active }>{ x }</td>
      )
    });
    return (
      <table className='footer'>
        <tbody>
          <tr>
            <td>Results per page</td>
            { tds }
          </tr>
        </tbody>
      </table>
    )
  }

  if (spinner) {
    return (
      <div className="spinner-container">
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>
    )
  }
  else if (searchData.query) {
    return (
      <div className='guide-search-results'>
        { renderResultsHeader() }
        <hr className='top'/>
        { renderResults() }
        { renderPageSelection() }
        <hr className='bottom'/>
        { renderPerPageFooter() }
      </div>
    )
  } else {
    return (<div></div>)
  }

}

export default SearchResults;