import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import NavItems from './NavItems';
import Hero from './Hero';


const AdminMain = ({fetchInfoPath, published, publishPath, heroData, slug}) => {

  const handlePublish = () => {
    $.ajax({
      type: 'PUT',
      url: publishPath,
      success: function() {
        window.location.href = `/channels/${slug}`
      }
    });
  }
  return (
    <div className="admin-channel-design">
      <div className="row">
        <div className="small-6 columns">
          <h2>Preview</h2>
        </div>
        <div className="small-6 columns text-right">
          <a className="button secondary" onClick={handlePublish}>{published ? "Unpublish" : "Publish"}</a>
        </div>
      </div>
      <Hero heroData={heroData} />
      <Router hashType={"hashbang"}>
        <NavItems
          admin={true}
          submitButtonColor={heroData.submitButtonColor}
          fetchInfoPath={fetchInfoPath}
          slug={slug}
        />
      </Router>
    </div>
  )
}

export default AdminMain;