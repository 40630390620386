var createReactClass = require('create-react-class');
import React from 'react';
import StateHistoryModal from './StateHistoryModal';
import Modal from '../Modal';

const ArticleStateDetails = createReactClass({
  openModal: function() {
    Modal.open({
			children: <StateHistoryModal
                  article={this.props.article}
                />
		});
  },
  render: function() {
    return (
      <div className="dashboard-status-wrap">
        <div dangerouslySetInnerHTML={{__html: this.props.publishingProgressBarMarkup}} onClick={this.openModal}/>
      </div>
    )
  }
})

export default ArticleStateDetails;