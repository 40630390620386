var createReactClass = require('create-react-class');
import React from 'react';
import AbandonModal from './AbandonModal';
import PaidServiceModal from './PaidServiceModal';
import Modal from '../../Modal';

const Draft = createReactClass({
  getInitialState: function() {
    return {
      paymentRequired: false,
      paymentOptional: false
    }
  },

  componentWillMount: function() {
    if (this.props.article.articleTier === "select" && this.props.article.articleNotPaid) {
      this.setState({paymentRequired: true})
    }
    if (this.props.article.post_deferral_pe_optional && this.props.article.articleNotPaid) {
      this.setState({paymentOptional: true})
    }
  },

  handleDelete: function() {
    Modal.open({
    children: <AbandonModal
                article={this.props.article}/>
    });
  },

  handleClickPurchase: function() {
    Modal.open({
      children: <PaidServiceModal
                  article={this.props.article}
                  payments={this.state}
                  editArticlePath={this.props.article.editArticlePath}/>
    });
  },

    renderActionButton: function() {
      var href, clickHandler, title, text, target;
      if (this.state.paymentRequired) {
        if (this.props.article.isValid) {
          href = "javascript:void(0)";
          clickHandler = this.handleClickPurchase;
          title = "Your article requires Preferred Editing. Click to view details.";
          text = "Purchase";
          target = '';
        } else {
          href = this.props.article.correctArticlePath
          clickHandler = '';
          title = "Your article requires Preferred Editing. Click to view details.";
          text = "Purchase";
          target = "_blank";
        }
      } else if (this.state.paymentOptional) {
        if (this.props.article.isValid) {
          href = "javascript:void(0)";
          clickHandler = this.handleClickPurchase;
          title = "Your article requires Preferred Editing. Click to view details.";
          text = "Deferral Options";
          target = '';
        }
      }
      else {
        href = this.props.article.editArticlePath
        clickHandler = '';
        title = "Returns you to the Publishing mode where you can continue editing your article.";
        text = "EDIT ARTICLE";
        target = "_blank";
      }

    return (
      <a href={href} onClick={clickHandler} className="button secondary split has-tip tip-top" data-cureus-split-dropdown={"action-buttons-" + this.props.article.id} data-tooltip="" title={title} target={target} id={"action-dropdown-view-tooltip-" + this.props.article.id}>
          {text}
        <span/>
      </a>
    )
  },

  renderDropDownEditButton: function() {
    if (this.state.paymentRequired || this.state.paymentOptional) {
      return (
        <li>
          <a href={this.props.article.editArticlePath} className="has-tip tip-top" data-cureus-split-dropdown={"action-buttons-" + this.props.article.id} data-tooltip="" title="Returns you to the Publishing mode where you can continue editing your article." target="_blank" id={"edit" + this.props.article.id}>
            Edit Article
          </a>
        </li>
      )
    }
  },

  renderPreview: function() {
    if(this.props.article.title) {
      return (
        <li>
          <a target="_blank" href={this.props.article.previewPath}>Preview</a>
        </li>
      )
    }
  },

  renderDropDownOptions: function() {
    return (
      <div>
        {this.renderDropDownEditButton()}
        {this.renderPreview()}
        {this.props.renderEmailEditingReceipt()}
        <li>
          <a onClick={this.handleDelete} className='has-tip tip-top' id={"abandon-modal-" + this.props.article.id}>
          <i  className="far fa-trash-alt"/>&nbsp;
            Delete
          </a>
        </li>
      </div>
    )
  },

  render: function() {
    return(
      <div className="cureus-split-dropdown-wrap" >
        {this.renderActionButton()}
        <ul className="cureus-split-dropdown" id={"action-buttons-" + this.props.article.id}>
        {this.renderDropDownOptions()}
        </ul>
      </div>
    )
  }
})

export default Draft;