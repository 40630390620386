import React, { useState, useEffect } from 'react';

const Promotion = () => {
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState('')
  const [url, setUrl] = useState('')
  const [enableButtonClass, setEnableButtonClass] = useState('disabled')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchWidget()
  }, [])

  useEffect(() => {
    checkValidForm()
  }, [image, url])

  const fetchWidget = () => {
    fetch('/admin/articles/design', {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
        'Accept': 'application/json',
      }
    })
    .then((res) => {
      return res.json()
    })
    .then((json) => {
      setImageUrl(json.imageUrl)
      setUrl(json.url)
    })
  }

  const checkValidForm = () => {
    if (isValidForm()) {
      setEnableButtonClass('')
    } else {
      setEnableButtonClass('disabled')
    }
  }

  const isValidForm = () => {
    return image && url
  }

  const handleSave = () => {
    let formData = new FormData()
    formData.append("widget[image]", image)
    formData.append("widget[url]", url)
    setIsLoading(true)

    fetch('/admin/articles/design', {
      method: putOrPost(),
      body: formData,
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
      },
    })
    .then((res) => {
      setIsLoading(false)
      if (res.ok) {
        setEnableButtonClass('disabled')
        return res.json()
      } else {
        return Promise.reject(res.json())
      }
    })
    .then((json) => {
      setImageUrl(json.imageUrl)
    })
    .catch((failedPromise) => {
    })
  }

  const putOrPost = () => {
    return imageUrl ? "PUT" : "POST"
  }

  const renderImage = () => {
    return imageUrl ? <img src={imageUrl}/> : ''
  }

  const renderErrorMessage = () => {
    if (!isValidForm()) {
      return (
        <div className="small-4 columns end">
          <p className="error-message">You cannot post widget without a content.</p>
        </div>
      )
    }
  }

  return (
    <div className="small-12 columns promotion">
      <div className="row">
        <div className="small-12 columns">
        <h3>Promotion</h3>
        </div>
      </div>
      {renderImage()}
      <div className="row">
        <div className="small-4 columns image-text">
          <span >Image</span><input type="file" onChange={(e) => {setImage(e.target.files[0])}} />
        </div>
      </div>
      <div className="row">
        <div className="small-4 columns">
          <div className="input-container">
            <label>URL</label>
            <input type="text" value={url} placeholder="Enter the link." onChange={(e) => {setUrl(e.target.value)}}/>
          </div>
        </div>
      </div>
      <div className="row button-row">
        <div className="small-2 columns">
          <div className={`button secondary ${enableButtonClass}`} onClick={handleSave}>Add</div>
        </div>
        {renderErrorMessage()}
      </div>
      {isLoading &&
        <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>}
    </div>
  )
}

export default Promotion;