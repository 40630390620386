import React, { useState, useEffect } from 'react';
import SettingsButton from '../SettingsButton';

const AboutMainSettings = ({handleAddWidget, widgetAboutMainId, slug, mainContentWidgets, handleUpdateWidget}) => {
  const sharedProps = {handleAddWidget, widgetAboutMainId, slug, mainContentWidgets, handleUpdateWidget}

  return (
    <div className="homepage-settings-container">
      <div className="settings-box">
        <h3>About Page Settings</h3>
        <p>Click below to add or edit your channel’s About page.</p>
        <hr/>
        <div className="text-center">
          <SettingsButton
            {...sharedProps}
            img={"https://public.cureus.com/channel_design/custom_content_4x.png"}
            title={"ADD/EDIT"}
            modalName={'AboutModal'}
            imgClass={"custom-content"}
          />
        </div>
      </div>
    </div>
  )
}

export default AboutMainSettings;