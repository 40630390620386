import React from 'react';
import Modal from '../../Modal';
import { Cureus } from '../../../Cureus';

const CollectionLockSubsModal = ({collectionId, fetchCollections}) => {

  const lockSubmissions = () => {
    $.ajax({
      type: 'put',
      url: `/publish/collections/${collectionId}/lock_submission_window`,
      dataType: 'json',
      success: function(res) {
        fetchCollections("published");
        Modal.close();
        Cureus.Flash.success("You have successfully locked the collection.");
      },
      error: function(res) {
        Modal.close();
        Cureus.Flash.alert("Something went wrong. Please try again.");
      }
    })
  }

  return (
    <div id={"lock-collection-reveal-modal"} className="reveal-modal new-modal lock-collection-modal middling large-12 columns" data-reveal>
      <div className="modal-body lock-section">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <h6>Are you sure you want to close the submission window for this collection?</h6>
          </div>
          <div>
            <p>Closing the submission window will prevent any further article submissions to your collection. A closed submission window cannot be reopened.</p>
          </div>
          <div className="modal-footer">
            <a href="#" className="button cancel-modal">Cancel</a>
            <button className="button alert" onClick={lockSubmissions}>Close Window</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionLockSubsModal;