var createReactClass = require('create-react-class');
import React from 'react';

const InsideListItem = createReactClass({
  render: function() {
    return (
      <div className="row">
        <label>
          Cureus U Section Title
          <input type="text" 
            onChange={(e) => this.props.handleInsidesChange(e)} 
            value={this.props.inside.title}
            name={this.props.inside.position}
            field={"title"}
          />
        </label>
        <label>
          Cureus U Campaign Link
          <input type="text" 
            onChange={(e) => this.props.handleInsidesChange(e)} 
            value={this.props.inside.campaignLink}
            name={this.props.inside.position}
            field={"campaignLink"}
          />
        </label>
        <div className="pull-right">
            <a className="button tiny secondary"
             name={this.props.inside.id}
             onClick={(e) => this.props.destroyInside(e)}>Remove</a>
        </div>
        <hr/>
      </div>
    )
  }
});

export default InsideListItem;