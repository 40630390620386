var createReactClass = require('create-react-class');
import React from 'react';
import FieldSet from './FieldSet';

const NewReferenceFields = createReactClass({
  render: function() {
    return (
      <div>
        <div className='reference-fields'>
          <FieldSet referenceFields={ this.props.referenceFields }/>
        </div>
        <div className='pull-right'>
          <a className="button small secondary cancel" onClick={ this.props.handleClose }>Cancel</a>
          <input className='button small pull-right' name='commit' type='submit' value='Save' />
        </div>
        <div className="clearfix"/>
      </div>
    )
  }
});

export default NewReferenceFields;
