import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-labels';

const Stats = ({ getStat, getCsv }) => {

  const [stats, setStats] = useState({})

  useEffect(() => {
    getStat('#stats-container', {statType: "all_stats"}, handleSuccess)
  }, [])

  function handleSuccess(data) {
    setStats(data.stats)
    if ($('#pie-chart')) {
      $('#pie-chart').remove();
    }
    $('#pie-chart-container').append("<canvas id='pie-chart'></canvas>")
    new Chart('pie-chart', {
      type: 'pie',
      data: data.chart,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false
        },
        plugins: {
          labels: {
            render: function(args) {
              return '';
            }
          }
        }
      }
    })
  }

  return (
    <div id='stats-container' className='stats-container'>
      <div className='medium-7 columns'>
        <div id='pie-chart-container' className='small-6 columns'>
          <canvas id='pie-chart'></canvas>
        </div>
        <div className='small-6 columns legend'>
          <div className='row' style={{marginBottom: "13px"}}>
            <i className='dot opens'/>
            <span className='stat'>{stats.uniqueOpens}</span>
            <a href="#" onClick={() => getCsv("unique_opens")}>Unique opens</a>
            <br/>
            <span className='small-grey'>{stats.totalOpens} total opens to date</span>
          </div>
          <div className='row' style={{marginBottom: "30px"}}>
            <i className='dot not-opened'/>
            <span className='stat'>{stats.notOpened}</span>
            <a href="#" onClick={() => getCsv("unopened")}>Not opened</a>
          </div>
          <div className='row'>
            <i className='dot bounced'/>
            <span className='stat'>{stats.bounced}</span>
            <a href="#" onClick={() => getCsv("bounced")}>Bounced</a>
            <br/>
            <span className='small-grey'>{stats.undeliveredRate}% couldn't be delivered</span>
          </div>
        </div>
      </div>
      <div className='medium-5 columns right-stats'>
        <div className='left'>
          <div className='row'>
            <span className='stat'>{stats.openRate}%</span>
            <span className='description'>Of all recipients <a href="#" onClick={() => getCsv("unique_opens")}>opened so far</a></span>
          </div>
          <div className='row'>
            <span className='stat'>{stats.clickRate}%</span>
            <span className='description'><a href="#" onClick={() => getCsv("unique_clicks")}>Clicked a link</a> ({stats.uniqueClicks} people)</span>
          </div>
          <div className='row'>
            <span className='stat'>{stats.unsubscribeRate}%</span>
            <span className='description'>  <a href="#" onClick={() => getCsv("unsubscribes")}>Unsubscribed</a> ({stats.unsubscribes } people)</span>
          </div>
          <div className='row'>
            <span className='stat'>{stats.spam}</span>
            <span className='description'>People <a href="#" onClick={() => {getCsv("spam_reports")}}>marked it as spam</a> ({stats.spamRate}%)</span>
          </div>
          <div className='row'>
            <span className='stat'>{stats.adUniqueClicks}/{stats.adClicks}</span>
            <span className='description'>Ads clicked uniques/total ({stats.adClickRate}%)</span>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Stats;