import React, { useState, useEffect } from 'react';
import Main from '../OnDemand/Main';
import EditorMessageModal from './EditorMessageModal';
import Modal from '../Modal';
import PaidServiceModal from '../Dashboard/ActionButton/PaidServiceModal';

const InfoWidget = ({infoWidget, handleSort, setArticleStates, newEditorMessages, fetchArticles, articles, editorMessagePath, view}) => {

  const renderPublisherHealthScript = () => {
    if (view === 'desktop') {
      const script = document.createElement("script");
      script.setAttribute("src", "https://phx.publisherhealth.com/assets/widget.js?account_id=cureus-widget");
      let ele = document.querySelector(".publisher-health-ad")
      ele.appendChild(script);
    }
  }

  useEffect(() => {
    renderPublisherHealthScript()
  }, []);

  const renderOnDemand = () => {
    if(infoWidget.canOnDemand) {
      return (
        <div className="large-12 medium-6 medium-push-6 small-12 columns peer-review-button-container large-reset-order">
          <Main
            articlesPath={infoWidget.articlesPath}
            acceptancePath={infoWidget.acceptancePath}
            cooldown={infoWidget.cooldown}
            currentUserId={infoWidget.currentUserId}
          />
        </div>
      )
    } else if (infoWidget.noRevPool) {
      return (
        <div className="large-12 medium-6 medium-push-6 small-12 columns peer-review-button-container large-reset-order">
          <a className="button special reviewer-pool" data-reveal-id={'reviewer-pool-modal'}><i className="fas fa-user-md"/>Join our Peer Review Panel</a>
        </div>
      )
    }
  }

  const fireAmplitude = () => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: {
              event_type: 'editorMessageClicked',
              user_id: infoWidget.currentUserId,
              source: "info-widget",
              color: 'red'
            }
    })
  }

  const renderNewEditorMessages = () => {
    if(newEditorMessages) {
      return (
        <React.Fragment>
          <div className="large-12 large-offset-0 medium-10 medium-offset-1 small-10 small-offset-1 columns editor-messages-container hide-for-medium" onClick={openEditorMessagesModal}>
            <div className="large-11 medium-7 small-9 columns">
              <p>
                You’ve received a <strong>message from the editor.</strong>
              </p>
            </div>
            <div className="large-1 medium-1 small-1 columns end flex-container">
              <span class="lil-red-circle"/>
            </div>
          </div>
          <div className="large-12 large-offset-0 medium-10 medium-offset-1 small-10 small-offset-1 columns editor-messages-container show-for-medium-only" onClick={openEditorMessagesModal}>
          <div className="small-12 columns">
            <p>
            You’ve received a <strong>message from the editor.</strong>
            </p>
            <span class="lil-red-circle"/>
          </div>
        </div>
      </React.Fragment>
      )
    }
  }

  const openEditorMessagesModal = () => {
    if(newEditorMessages) {
      let article = articles.filter(function(art) {
        return parseInt(art.articleData.data.id) === newEditorMessages
      })
      if(!article[0]) {
        return;
      }
      article = article[0].articleData.data.attributes
      fireAmplitude()
      Modal.open({
        children: <EditorMessageModal
          editorMessages={article.editorMessages}
          title={article.title}
          id={article.id}
          fetchArticles={fetchArticles}
          editorMessagePath={editorMessagePath}
          currentUserId={infoWidget.currentUserId}
        />
      })
    }
  }

  const handleClickPurchase = (article) => {
    Modal.open({
      children: <PaidServiceModal
                  article={article}
                  editArticlePath={article.editArticlePath}/>
    });
  }

  const renderDiscountButton = () => {
    let article = articles.filter((art) => {
      return art.articleData.data.attributes.canDiscount
    })
    if(article[0]) {
      return (
        <img src="https://public.cureus.com/pe_sale.png" className="pe-discount" onClick={() => handleClickPurchase(article[0].articleData.data.attributes)} />
      )
    }
  }

  const renderBundle = () => {
    let bundle = infoWidget.bundle
    if(bundle) {
      return (
        <div className="large-12 medium-6 medium-pull-6 small-12 columns bundles-container large-reset-order">
          <div className="small-8 columns small-offset-2">
            <div className="bundle-box has-tip tip-top" data-tooltip="" title={infoWidget.canExpedited ? "You have a recently published article that is eligible - use your credit!" : "You don't have any eligible articles right now!"} id="expedited-pmc-credits">
              <h3>{bundle.remaining_credits}</h3>
              <h6>Expedited PMC Credits</h6>
            </div>
          </div>
        </div>
      )
    }
  }

  const handleInfoClick = (e) => {
    setArticleStates('published')
    handleSort(e.target.getAttribute('name'))
  }

  const renderBoost = () => {
    if(infoWidget.canBoost) {
      return (
        <div className="large-12 large-offset-0 medium-10 medium-offset-1 small-10 small-offset-1 columns bundles-container">
          <div className="bundle-box row notification">
            <div className="small-2 columns bell-container round-icon info-widget-bell">
              <i className="fas fa-bell"/>
            </div>
            <div className="small-10 columns">
              <span onClick={(e) => handleInfoClick(e)} name="boost">You qualify for article promotion!</span>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderExpedited = () => {
    if(infoWidget.canExpedited) {
      return (
        <div className="large-12 large-offset-0 medium-10 medium-offset-1 small-10 small-offset-1 columns bundles-container">
          <div className="bundle-box row notification">
            <div className="small-2 columns bell-container round-icon info-widget-bell">
              <i className="fas fa-bell"/>
            </div>
            <div className="small-10 columns">
              <span onClick={(e) => handleInfoClick(e)} name="expedited">You qualify for expedited PMC indexing!</span>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderHonors = () => {
    let contribution = infoWidget.contribution
    return (
      <div className="small-10 small-offset-1 columns contributions end large-12 large-offset-0">
        <h6>Cureus Honors Dashboard</h6>
        <div className="bars">
          <div className="contribution-label-left">
            Scholar
          </div>
          <div className="bar-wrap">
            <div className="contribution-bar dashboard" style={{width: contribution.scholar + '%'}}/>
          </div>
          <div className="contribution-label-right">
            {contribution.scholar === 100 ? 'Complete' : String.fromCharCode(160)}
          </div>
          <div className="contribution-label-left">
            Magna
          </div>
          <div className="bar-wrap">
            <div className="contribution-bar dashboard" style={{width: contribution.magna + '%'}}/>
          </div>
          <div className="contribution-label-right">
            {contribution.magna === 100 ? 'Complete' : String.fromCharCode(160)}
          </div>
          <div className="contribution-label-left">
            Summa
          </div>
          <div className="bar-wrap">
            <div className="contribution-bar dashboard" style={{width: contribution.summa + '%'}}/>
          </div>
          <div className="contribution-label-right">
            {contribution.summa === 100 ? 'Complete' : String.fromCharCode(160)}
          </div>
        </div>
        <div className="learn-more-container">
          <a href={infoWidget.learnMoreContributionsPath} target='_blank'>Learn more</a>
        </div>
      </div>
    )
  }

  const renderPublisherHealthTag = () => {
    // doing this if statement since it was rendering double ads
    if (view === 'mobile') {
      return null;      
    } else {
      return (
        <div className="row">
          <div className=" small-12 columns">
            <div className='cureus-ad-wrapper'>
              <div className='cureus-ad publisher-health-ad square'>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderViews = () => {
    if (view === 'desktop') {
      return 'show-for-large-up';
    } else {
      return 'show-for-small-only';
    }
  }

  return (
    <div className={"large-3 small-12 medium-12 columns bordered info-widget-container " + renderViews()}>
      {renderDiscountButton()}
      {renderOnDemand()}
      {renderNewEditorMessages()}
      {renderBundle()}
      {renderBoost()}
      {renderExpedited()}
      {renderHonors()}
      {renderPublisherHealthTag()}
    </div>
  )
}

export default InfoWidget;

