import {Cureus} from '../Cureus';

Cureus.EditorialActions = function(currentUser) {

	var shownButtons = document.querySelector('.shown-action-buttons');
  var hiddenButtons = document.querySelector('.hidden-action-buttons');
  var adminActionsBar = document.querySelector('.admin-actions .actions');

  if (currentUser === 'ChannelAdmin' || currentUser === 'DeputyEditor') {
    shownButtons.classList.remove('invisible');
    hiddenButtons.classList.add('invisible');
    adminActionsBar.classList.add('open-actions');
  }

  hiddenButtons.addEventListener('click', function() {
    shownButtons.toggleClass('invisible')
    hiddenButtons.toggleClass('invisible')
    adminActionsBar.toggleClass('open-actions')
  });

  shownButtons.addEventListener('click', function() {
    hiddenButtons.toggleClass('invisible')
    shownButtons.toggleClass('invisible')
    adminActionsBar.toggleClass('open-actions')
  });
}