import React from 'react';
const RejectedReviews = ({data, type}) => {
  return (
    <div className="row-container">
      <div className="row">
        <div className="small-12 columns">
          <div className="small-1 columns icon-container">
            <i className="fas fa-user-times background"/>
          </div>
          <div className="small-3 small-offset-1 columns">
            <h5>Peer reviews rejected</h5>
          </div>
          <div className="small-8 columns">
            <h6 className="reg">{data.count}</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RejectedReviews;