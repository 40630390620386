var createReactClass = require('create-react-class');
import React from 'react';

const ArticleMedia = createReactClass({
  render: function(){
    return (
      <div>
        <h4 className="reg">How do I add figures, tables, videos or interactive models?</h4>
        <p>
          1. To add media to your article first place your cursor in the desired location in the media article section text field and click one of the Insert Figure, Insert Table, Insert Video or Insert Interactive Model buttons on the article section toolbar.
        </p>

        <p>
          2. You will then be prompted to upload the figure, paste the table or video/interactive model URL, and add a title and optional legend.
        </p>
        <p>
          3. For more detailed instructions, see our <a href={this.props.mediaGuidePath} target="_blank">Media Instructions page</a>.
        </p>
        <div className='row'>
          <div className='columns large-12 centered'>
            <img src='https://public.cureus.com/insert_media_buttons_2.png'/>  
          </div>
        </div>
      </div>
    );
  }
});

export default ArticleMedia;