import React, { useState, useEffect } from 'react';
import MapButton from './MapButton';
import MapTable from './MapTable';

const WorldMap = ({metricsPaths}) => {
  const [data, setData] = useState([])
  const [view, setView] = useState('map')

  useEffect(() => {
    if(view === 'map') {
      renderMap()
    }
  }, [view])

  const renderMap = () => {
    $("#map-container").spin();
    // The tooltip
    let tip = d3.tip()
      .attr('class', 'd3-tip')
      .html(function(d) {
        let views = map.get(d.id) || 0;
        return "<strong>" + d.id + "</strong>" + "<br/>Views : " + "<strong>" + views + "<strong/>"
      })
      // The svg
      let svg = d3.select("svg.viewer-map");
      // width is ~1,000 pixels, a good height to have for this is 680
      let width = $('#map-container').width();
      let ratio = 0.72; // aspect ratio
      let scale = width / 1004 // responsiveness
      let height = width * ratio
      svg.attr("viewBox", "0 0 " + width.toString() + " " + height.toString())
      svg.call(tip)
       // Map and projection
       const path = d3.geoPath();
       let projection = d3.geoMercator()
           .scale(150 * scale)
           .center([0,60 * ratio])
           .translate([width / 2, height / 2 - 50 * scale]);
 
       // Data and color scale
       let map = d3.map();
       let data = [];
       let seen = new Set();
       // Load external data and boot
       d3.queue()
         .defer(d3.json, "https://public.cureus.com/worldmap.geo.json")
         .defer(d3.csv, metricsPaths.world_map, function(res) {
           // Temp hack. I dont know why its doubling up on data.
           if (seen.has(res.rank)) {
             return
           }
           seen.add(res.rank)
           if (res.code != 'N/A') {
             map.set(res.code, parseInt(res.views))
           }
           data.push(res)
         })
         .await(ready);

         function ready(error, topo) {
          $("#map-container").spin(false);
          setData(data)
          // populateTable(data);
          // new Cureus.ShowMoreTable("#article-map-table", 3)
          let maxVal = d3.max(map.values())
          let zeroColor = "#F8F8F8";
          let lowColor = "#f3cfd3";
          let highColor = "#CA3145";
          let colorScale = d3.scaleLinear()
            .domain([1, maxVal])
            .range([lowColor, highColor])
  
          if (error) throw error;
  
          // Draw the map
          svg.append("g")
            .selectAll("path")
            .data(topo.features)
            .enter()
            .append("path")
              // draw each country
              .attr("d", d3.geoPath()
                .projection(projection)
              )
              .on('mouseover', tip.show)
              .on('mousemove', function(d) {
                tip.show(d)
                  .style("left", (event.pageX + 15) + "px")
                  .style("top", (event.pageY - 30) + "px");
              })
              .on('mouseout', tip.hide)
              // set the color of each country
              .attr("fill", function (d) {
                d.total = map.get(d.id) || 0;
                return d.total == 0 ? zeroColor : colorScale(d.total);
              })
              .style("stroke", "#777777")
              .attr("class", function(d){ return "Country" } )  
              .style("opacity", .8)
              
  
            if (data.length > 0) {
              // Horizontal legend
              svg.append("g")
                .attr("class", "legendLinear")
                .attr("transform", "translate(20," + (height - 40).toString() + ")")
              var legendLinear = d3.legendColor()
                .labelFormat(d3.format(",.2r"))
                .shapeWidth(60)
                .cells(10 * scale)
                .orient('horizontal')
                .scale(colorScale);
                
  
              svg.select(".legendLinear")
                .call(legendLinear);
            }
        }
  }

  const renderContents = () => {
    if(view === 'list') {
      return <MapTable 
                selector="table.article-map-table>tbody"
                data={data}
                cssClass="article-map-table"
              />
    } else {
      return (
        <>
          <div className="article-viewer-map-container" id="map-container">
            <svg class="viewer-map" id="article-views-map" preserveAspectRatio="xMidYMid meet"></svg>
          </div>
        </>
      )
    }
  }

  return (
    <div className="row small-collapse">
      <div className="large-12 columns">
        <div className="metrics-section">
          <h3 className="reg">Article Reads by Country</h3>
            <p className="no-margin-bottom">
              Hover your cursor over a country to see the number of online article views and PDF downloads from Cureus only.
            </p>
            <p className="italic">Note: PMC does not share location data and not all Cureus reads are included below as location data is not available for all IP addresses.</p>
          <div className="interval-buttons-container">
            <MapButton 
              setView={setView}
              title="Map"
              activeView={view}
              viewName="map"
            />
            <MapButton
              setView={setView}
              title="List"
              activeView={view}
              viewName="list"
            />
          </div>
          {renderContents()}
        </div>
      </div>
    </div>
  )
}

export default WorldMap;