var createReactClass = require('create-react-class');
import React from 'react';

const ChecklistItem = createReactClass({
  getInitialState: function (){
    return {
      pass: false,
      fail: false
    }
  },

  componentDidMount: function() {
    if((this.props.type === 'desk' || this.props.type === 'block') && Object.keys(this.props.blockOrDeskItems).length !== 0) {
      this.setState({
        pass: this.props.blockOrDeskItems[this.props.index] ? true : false,
        fail: !this.props.blockOrDeskItems[this.props.index] && this.props.blockOrDeskItems[this.props.index] !== undefined ? true : false
      })
    } else if(this.props.type === 'defer' && Object.keys(this.props.deferralItems).length !== 0) {
      this.setState({
        pass: this.props.deferralItems[this.props.index] ? true : false,
        fail: !this.props.deferralItems[this.props.index] && this.props.deferralItems[this.props.index] !== undefined ? true : false
      })
    }
  },

  handleSelection: function(e) {
    if(e.target.className.includes('pass') || e.target.className.includes('fail')) {
      return;
    }

    var name = e.target.getAttribute('name')
    
    if(!this.state.pass && !this.state.fail) {
      if(e.target.className.includes('times')) {
        this.setState({fail: true}, function() {
          this.props.handleCheck(1, name)
          this.props.buttonDisable(this.props.index, false)
        })
      } else {
        this.setState({pass: true}, function() {this.props.buttonDisable(this.props.index, true)})
      }
    } else {
      this.setState({pass: !this.state.pass, fail: !this.state.fail}, function() {
        if(e.target.className.includes('times')) {
          this.props.handleCheck(1, name)
          this.props.buttonDisable(this.props.index, false)
        } else {
          this.props.handleCheck(-1, name)
          this.props.buttonDisable(this.props.index, true)
        }
      })
    }
  },

  renderCount: function() {
    if(this.props.count) {
      return <span className="count">({this.props.count})</span>
    }
  },

  render: function() {
    return (
      <div className="checklist-item row">
        <div className="large-8 columns title-container">
          <span>{this.props.title}</span>
        </div>
        <div className="large-3 columns">
          <i className={"fa fa-times-circle fa-1x " + (this.state.fail ? 'fail' : '')} name={this.props.type} onClick={this.handleSelection} data-reason={this.props.reason} data-drawerReason={this.props.drawerReason}/>
          <i className={"fa fa-check-circle fa-1x " + (this.state.pass ? 'pass' : '')} name={this.props.type} onClick={this.handleSelection}/>
          {this.renderCount()}
        </div>
      </div>
    )
  }
})

export default ChecklistItem;