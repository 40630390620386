import React from 'react';

const Introduction = () => {
  return (
    <React.Fragment>
      <h1>Introduction</h1>
      <p>
        Academic channels are a branded section of the Cureus Journal of Medical Science devoted to publishing, highlighting and promoting the scholarly output of channel organizations and their members. The Cureus channel platform enables channel members to publish peer-reviewed articles, as well as post meeting abstracts, posters, announcements and other content produced by channel members, staff, residents, and/or students.
      </p>
      <p>
        An academic channel represents a contractual relationship between each channel organization and the journal, enabling their authors to benefit from discounted editing fees. Channel administrators (“Channel Admins”) and editors (“Deputy Editors”) take an active role in attracting and reviewing initial submissions and ensuring that journal guidelines are met and later review article revisions. Cureus journal editors will still review all submissions and requests for publication, appoint peer-reviewers, determine whether authors have applied necessary changes, and make the final decision regarding whether to publish or reject all channel articles. The journal’s editorial decision-making process does not include, nor is it influenced by, the channel editors.
      </p>
      <p>
        The following flowchart provides a high-level overview of the publication process for articles submitted to Cureus academic channels:
      </p>
      <img src='https://public.cureus.com/channels_flow_chart.png' />
    </React.Fragment>
  )
}

export default Introduction;

