var createReactClass = require('create-react-class');
import React from 'react';
import Invite from './Invite';
import NewModal from './NewModal';
import RemindSpecificModal from './RemindSpecificModal';
import RemindModal from './RemindModal';
import Draft from '../ActionButton/Draft';
import InReview from '../ActionButton/InReview';
import ReviewsComplete from '../ActionButton/ReviewsComplete';
import RereviewsComplete from '../ActionButton/RereviewsComplete';
import Approved from '../ActionButton/Approved';
import CoauthorMain from '../CoauthorTool/CoauthorMain';
import ArticleStateDetails from '../ArticleStateDetails';
import CoauthorNotification from './CoauthorNotification';
import ArticleNotifications from './ArticleNotifications';
import PeerReviewProgress from './PeerReviewProgress';
import ReviewerActivityMain from './ReviewerActivity/ReviewerActivityMain';
import EmailEditingServiceReceiptModal from '../EmailEditingServiceReceiptModal';
import Modal from '../../Modal';
import { Cureus } from '../../../Cureus';

const Main = createReactClass({
  getInitialState: function (){
    return {
      reviews: this.props.article.reviews,
      canSendReminders: this.props.article.canSendReminders,
      article: this.props.article,
      authorships: this.props.authorships
    }
  },

  componentDidMount: function() {
    Cureus.SplitButton.init()
    this.addModalOpenListeners()
  },

  addModalOpenListeners: function() {
    this.handleOnClickModalOpen('#action-dropdown-abandon-tooltip-' + this.state.article.id)
    if (this.state.article.articleState === "reviews_complete" || this.state.article.rereviews) {
      this.handleOnClickModalOpen('#action-dropdown-request-re-review-tooltip-' + this.state.article.id)
      this.handleOnClickModalOpen('#action-dropdown-request-publication-tooltip-' + this.state.article.id)
      this.handleOnClickModalOpen('#action-dropdown-end-review-tooltip-' + this.state.article.id)

      if (this.state.article.articleState === "in_rereview") {
        this.handleOnClickModalOpen('#action-dropdown-continue-editing-tooltip-' + this.state.article.id)
      }
    } else if (this.state.article.articleState === "approved") {
      // Cureus.ReviewerRating.init('#publish-article-' + this.state.article.id);
    } else if (this.state.article.articleState === 'blocked') {
      this.handleOnClickModalOpen('#action-dropdown-unblock-tooltip-' + this.state.article.id)
    } else if(this.state.article.articleState === 'in_review') {
      this.handleOnClickModalOpen('#action-dropdown-end-review-tooltip-' + this.state.article.id)
    }
  },

  handleOnClickModalOpen: function(toolTipQuerySelector) {
    var self = this;
    $(toolTipQuerySelector).on("click", function(e) {
      $(self.formattedQuerySelector(toolTipQuerySelector)).foundation('reveal', 'open');
      e.preventDefault();
      e.stopPropagation();
   })
  },

  formattedQuerySelector: function(toolTipQuerySelector) {
    var modalSelector = toolTipQuerySelector.split("-")
    modalSelector = modalSelector.filter(function(el) {
      if (el !== "#action" && el !== "dropdown" && el.indexOf("tooltip") === -1) {
        return el
      }
    })
    modalSelector.splice(modalSelector.length-1, 0, "modal")
    modalSelector = ("#" + modalSelector.join("-"))
    return modalSelector
  },

  handleReviewerAdd: function() {
    var newReviews = Object.assign({}, this.state.reviews)
    newReviews.total = newReviews.total + 1
    newReviews.invited = newReviews.invited + 1
    this.setState({
      reviews: newReviews
    })
  },

  handleInvite: function() {
    Modal.open({
      children: <NewModal
      article={ this.state.article }
      defaultParams={{article_id: this.state.article.id}}
      endpointUrl={this.state.article.endpointUrl}
      inReview={this.state.article.articleState === "in_review"}
      handleReviewerAdd={this.handleReviewerAdd}
      specialtyOptions={ this.state.article.specialtyOptions }
      countryOptions={ this.state.article.countryOptions }
      affiliationNameQueryUrl={ this.state.article.affiliationNameQueryUrl }
      isAuthorships={this.state.article.isAuthorships}
      />
    })
  },

  openRemindModal: function(e) {
    if(this.props.article.remindSpecficModal && e.target.id === 'invited') {
      Modal.open({
        children: <RemindSpecificModal
                    article={this.state.article}
                    reviews={this.state.reviews}
                    reviewershipTargetGroup={e.target.id}
                    canSendReminders={this.state.canSendReminders}
                    disableSendReminders={this.disableSendReminders}
                    invited={(e.target.id === 'invited' || e.target.id === 'reinvited') ? true : false}
                    />
      })

    } else {
      Modal.open({
        children: <RemindModal
                    article={this.state.article}
                    reviews={this.state.reviews}
                    reviewershipTargetGroup={e.target.id}
                    canSendReminders={this.state.canSendReminders}
                    disableSendReminders={this.disableSendReminders}
                    invited={(e.target.id === 'invited' || e.target.id === 'reinvited') ? true : false}
                    />
      })
    }
  },

  disableSendReminders: function(reviewer_state) {
    var newCanSendRemindersState = Object.assign({}, this.state.canSendReminders)
    newCanSendRemindersState[reviewer_state] = false
    this.setState({canSendReminders: newCanSendRemindersState})
  },

  handleEmailEditingReceipt: function() {
    Modal.open({
      children: <EmailEditingServiceReceiptModal
                  id={"email-editing-service-receipt-modal-" + this.props.editingServiceInvoiceId}
                  editingServiceInvoiceId={this.props.editingServiceInvoiceId}
                  emailReceiptPath={this.props.emailReceiptPath}
                  email={this.props.email}/>
    })
  },

  renderEmailEditingReceipt: function() {
    if(this.props.editingServiceInvoiceId) {
      return (
        <li>
          <a href="javascript:void(0)" id={"email-editing-receipt-modal-" + this.props.editingServiceInvoiceId} onClick={this.handleEmailEditingReceipt}>Resend PE Receipt</a>
        </li>
      )
    }
  },

  renderActionButton: function() {
    if (this.state.article.articleState === 'draft') {
      return (
        <Draft
          article={this.state.article}
          isValid={this.props.article.isValid}
          post_deferral_pe={this.props.article.post_deferral_pe_optional}
          renderEmailEditingReceipt={this.renderEmailEditingReceipt}
          />
      )
    } else if (this.state.article.articleState === 'in_review') {
      return (
        <InReview
          article={this.state.article}
          handleInvite={this.handleInvite}
          renderEmailEditingReceipt={this.renderEmailEditingReceipt}
          />
      )
    } else if (this.state.article.articleState === 'reviews_complete'){
      return (
        <ReviewsComplete
          article={this.state.article}
          renderRemindReviewers={this.renderRemindReviewers}
          renderEmailEditingReceipt={this.renderEmailEditingReceipt}
          />
      )
    } else if (this.state.article.articleState === 'rereviews_complete') {
      return (
        <RereviewsComplete
        article={this.state.article}
        renderRemindReviewers={this.renderRemindReviewers}
        renderEmailEditingReceipt={this.renderEmailEditingReceipt}
        />
      )
    } else if(this.state.article.articleState === 'approved') {
      return (
        <Approved 
        article={this.state.article}
        renderEmailEditingReceipt={this.renderEmailEditingReceipt}
      />
      )
    }
    else {
      return (
        <div dangerouslySetInnerHTML={{__html: this.state.article.generateActionControlMarkup}}/>
      )
    }
  },

  renderTitleText: function() {
    var reviewsCount = this.state.article.reviews.reviewing
    var verb = reviewsCount > 1 ? " have" : " has";
    var pluralize = reviewsCount > 1 ? " reviewers" : " reviewer";
    return "You have " + reviewsCount + pluralize + " who " + verb + " not submitted their review. Send a reminder to encourage them to complete their peer review."
  },

  renderRemindReviewers: function() {
    if (this.state.article.reviews.reviewing === 0) {
      return (
        <span style={{cursor: 'default'}} className='has-tip tip-top'>
          Remind reviewers
        </span>
      )
    } else {
      return (
        <a className='has-tip tip-top' data-tooltip="" title={this.renderTitleText()} id={"action-dropdown-remind-tooptip-" + this.state.article.id} onClick={this.openRemindModal}>
          Remind reviewers
        </a>
      )
    }
  },

  renderCoauthors: function() {
    if (this.state.article.articleState != 'published') {
      return (
        <CoauthorMain
        key={ "article_authors_row_" + this.state.article.id }
        articleId={ this.state.article.id }
        articleState={ this.state.article.articleState }
        pastEditorApproval={ this.state.article.pastEditorApproval }
        deferredForPreferred={ this.state.article.deferredForPreferred }
        authorships={ this.state.authorships }
        authorshipEndpoint={ this.props.authorshipEndpoint }
        editAuthorsPath={this.props.article.editAuthorsPath}
        />
      )
    }
  },

  articleTitle: function() {
    if (this.state.article.title) {
      return this.state.article.title
    }
    return 'Untitled Article'
  },

  render: function() {
    return(
      <div className="dashboard-list-item-wrap">
        <div dangerouslySetInnerHTML={{__html: this.state.article.flashMessage}}/>
        <div className="row">
          <div className="large-3 small-12 columns">
            <div className="row">
              <div className="large-10 columns">
                <ArticleStateDetails
                  publishingProgressBarMarkup={this.state.article.publishingProgressBarMarkup}
                  article={ this.state.article }
                />
              </div>
            </div>
          </div>
          <div className="large-6 small-12 columns">
            <div className="article-title-wrap">
              {this.articleTitle()}
            </div>
          </div>

          <div className="large-3 small-12 columns dashboard-action-wrap">
            <div className="row">
              <div className="large-10 columns large-offset-2">
                { this.renderActionButton() }
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="large-3 columns">
            <div className="row">
              <div className="large-10 columns">
                <div className="dashboard-status-wrap">
                  <CoauthorNotification
                    emphasisBox={this.props.coauthorConfirmedEmphasisBox}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="large-6 columns">
            { this.renderCoauthors() }
          </div>
          <div className="large-3 columns"></div>
        </div>

        <div className="row">
          <div className="large-3 columns" style={{minHeight: '1px'}}>
            <div className="row">
              <div className="large-10 columns">
                <div className="dashboard-status-wrap">
                  <ArticleNotifications
                    article={this.state.article}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="large-6 columns">
            <PeerReviewProgress
              article={this.state.article}
              reviews={this.state.reviews}
              handleInvite={this.handleInvite}
              openRemindModal={this.openRemindModal}/>
            <ReviewerActivityMain
              article={this.state.article}
              handleInvite={this.handleInvite}
              openRemindModal={this.openRemindModal}
              />
          </div>

          <div className="large-3 columns dashboard-action-wrap">
            <div className="row">
              <div className="large-10 columns large-offset-2">
                <Invite
                  toolTip={true}
                  specialtyOptions={ this.state.article.specialtyOptions }
                  countryOptions={ this.state.article.countryOptions }
                  affiliationNameQueryUrl={ this.state.article.affiliationNameQueryUrl }
                  endpointUrl={this.state.article.endpointUrl}
                  isAuthorships={this.state.article.isAuthorships}
                  article={this.state.article}
                  generateActionControlMarkup={this.state.article.generateActionControlMarkup}
                  handleReviewerAdd={this.handleReviewerAdd}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Main;