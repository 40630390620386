var createReactClass = require('create-react-class');
import React from 'react';
import ViewableTable from './ViewableTable';
import AddFromExistingModal from './AddFromExistingModal';
import AddFromPmcModal from './AddFromPmcModal';
import UploadFileModal from './UploadFileModal';
import LockedTable from './LockedTable';
import Modal from '../Modal';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';
import EmailTool from '../EmailTool';
import EmailListRefreshes from './EmailListRefreshes';

const ViewList = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React)
  ],

  getInitialState: function() {
    return {
      emailListRefreshes: this.props.emailListRefreshes,
      spinner: false
    }
  },

  handleAddFromExisting: function() {
    Modal.open({
      children: <AddFromExistingModal
                  options={this.props.addFromExistingOptions} />
    });
  },

  handleFileUploadClicked: function (evt){
    Modal.open({
      children: <UploadFileModal
                  updateListPath={this.props.updateListPath} />
    });
  },

  handleAddFromPmc: function() {
    Modal.open({
      children: <AddFromPmcModal createPmcListPath={this.props.createPmcListPath}/>
    })
  },

  search: function() {
    $.get(this.props.searchPath, function(data) {
      self.setState({
        judgeships: data,
      }, callback )
    }, "json")
  },

  renderSpinner: function() {
    if (this.state.spinner) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  },

  updateState: function(newState) {
    // Passing setState as a prop to the child component has function name problem, so we have to use a dif name for the prop to set state in the child
    this.setState(newState)
  },

  render: function() {
    if(this.props.disabled) {
      var content =
        <div className='no-subscribers-wrap'>
          <i className='far fa-envelope'></i>
          <h3>This list is currently being processed. The page will refresh when finished.</h3>
        </div>
    }else if(this.props.isLocked) {
      var content = <LockedTable dataSource={this.props.dataSource} title={this.props.title} columns={ this.props.columns} />
    }else if(this.props.listEmailsCount == 0) {
      var content =
        <div className='no-subscribers-wrap'>
          <i className='far fa-envelope'></i>
          <h3>This list has no subscribers.</h3>
          <p>
            <a className="email-list-upload-link" onClick={this.handleFileUploadClicked}>Upload a file</a>, <a onClick={this.handleAddFromExisting}>add from an existing list</a> or <a href={this.props.addFromCureusPath}>add from Cureus Users</a>
          </p>
        </div>
    } else {
      var content = <ViewableTable
                      dataSource={this.props.dataSource}
                      title={this.props.title}
                      deleteListEmailsPath={this.props.deleteListEmailsPath}
                      handleFileUpload={this.handleFileUploadClicked}
                      handleAddFromExisting={this.handleAddFromExisting}
                      addFromCureusPath={this.props.addFromCureusPath}
                      handleAddFromPmc={this.handleAddFromPmc}
                      className={this.props.className}
                      columns={ this.props.columns}
                    />
    }

    return (
      <div>
        <EmailListRefreshes
          updateState={this.updateState}
          emailListRefreshes={this.state.emailListRefreshes}
          spinner={this.spinner}
          renderSpinner={this.renderSpinner}
          setAutoRefresh={this.setAutoRefresh}
          refreshPath={this.props.refreshPath}
         />
        <h3>View list</h3>
        {content}
      </div>
    )
  }
});

ViewList.defaultProps = {
  flux: new Fluxxor.Flux({
    Table: new EmailTool.fluxxor.stores.Table()
  }, EmailTool.fluxxor.actions)
}

export default ViewList;