var createReactClass = require('create-react-class');
import React from 'react';
import CreateListModal from './CreateListModal';
import Modal from '../Modal';

const CreateList = createReactClass({
  handleClick: function() {
    Modal.open({
      children: <CreateListModal listType={this.props.listType} createListUrl={this.props.createListUrl}/>
    });
  },

  render: function() {
    return (
      <div className="email-list-tool-new-button">
        <a onClick={this.handleClick} className="button primary">
          <i class="fa fa-plus-circle"></i>
          Create New List
        </a>
      </div>
    )
  }
});

export default CreateList;