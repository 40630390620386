import React from 'react';
const RejectedModal = ({ rejectedReviewEvent }) => {
  return (
    <div className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <h4>Editor Comments</h4>
          <div dangerouslySetInnerHTML={{__html: rejectedReviewEvent.message}}/>
        </div>
        <div className="modal-footer">
          <a href="#" className="button secondary cancel-modal">Close</a>
        </div>
      </div>
    </div>
  )
}

export default RejectedModal;