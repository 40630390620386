import React from 'react';
import BaseTable from './BaseTable';

const ClaimExpired = () => {
  const tableHeaders = [
    "Assignment Expiration Date",
    "Channel",
    "Author",
    "Title",
    "Expiration State",
    "History"
  ]

  const rowNames = [
    "most_recent_activity",
    "channel_name",
    "admin_user_link",
    "title",
    "expired_state",
    "reminder_history"
  ]

  const columnAmountAndWidths = [
    {width: '180px'},
    null,
    {width: '160px'},
    null,
    {width: '100px'},
    {width: '100px', class: 'centered'}
  ]

  return <BaseTable
          tableHeaders={tableHeaders}
          state={"claim_expired"}
          rowNames={rowNames}
          historyElementColumnIndex={5}
          removeOrderingColumn={[5]}
          columnAmountAndWidths={columnAmountAndWidths}
          searchableColumnIndex={1}
          dateOrderColumn={0}
          />
}

export default ClaimExpired;