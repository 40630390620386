var createReactClass = require('create-react-class');
import React from 'react';

const Button = createReactClass({
  getInitialState: function() {
    return(
      {
        added: this.props.added,
        update_profile_path: this.props.update_profile_path,
        user_data: this.props.user_data,
        section: this.props.section
      })
  },

  handleClick: function(e) {
    e.preventDefault();
    this.state.added ? this.handleRemove() : this.handleAdd()
  },

  handleAdd: function() {
    var actionUrl = this.state.update_profile_path;
    var successUrl = this.props.addLink;

    $.ajax({
      url: actionUrl,
      type: 'put',
      data: {'user': this.state.user_data, 'section': this.state.section},
      dataType: 'json',
      success: function(res){
        location.href = successUrl
      }
    });
  },

  handleRemove: function() {
    var self = this;
    $.ajax({
      url: self.props.removeLink,
      type: 'delete',
      success: function() {
        self.setState({added: false})
      }
    });
  },

  sliderClass: function() {
    return this.state.added ? 'on' : 'off'
  },

  sliderText: function() {
    return this.state.added ? 'ON' : 'OFF'
  },

  connectedClass: function() {
    return this.state.added ? 'channel-connected' : 'channel-not-connected'
  },

  connectedText: function() {
    return this.state.added ? 'Connected' : 'Connect'
  },

  render: function() {
    return(
      <div>
        <div className='row'>

          <div className='large-1 medium-1 small-2 columns'>
            <div className={`icon-border ${this.props.name}`}>
              <i className={this.props.icon}/>
            </div>
          </div>

          <div className='large-1 medium-1 small-2 columns social-channel-title'>
            <p>{this.props.name}</p>
          </div>
          <div className='large-2 medium-2 small-4 columns'>
            <label className='switch' onClick={this.handleClick} >
              <input type='checkbox' checked={this.state.added} />
              <div className='round slider'>
                <p className={this.sliderClass()}>{this.sliderText()}</p>
              </div>
            </label>
          </div>
          <div className={`large-4 medium-4  small-6 columns end ${this.connectedClass()}`}>
            <label className={`switch ${this.connectedClass()}`} onClick={this.handleClick} >
              <input type='checkbox' checked={this.state.added} />
                <p className={`inner-connect-${this.connectedClass()}`}>{this.connectedText()} </p>
            </label>
          </div>
        </div>
      </div>
    );
  }
});

export default Button;