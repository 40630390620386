import React, {useState, useEffect} from 'react';
import Modal from '../Modal';

const ApproveOrRejectModal = ({collectionId, action, fetchData, ccaId}) => {

  const renderHeaderText = () => {
    if (action === 'approve') {
      return "Are you sure you want to include this article in your collection?";
    } else {
      return "Are you sure you want to exclude this article from your collection?";
    }
  }

  const updateAction = () => {
    $.ajax({
      type: 'put',
      url: `/collections/${collectionId}/cureus_collection_articles/${ccaId}/${action}`,
      success: function(res) {
        fetchData();
        pendingCounter();
        Modal.close();
      }
    })
  }

  const pendingCounter = () => {
    const infoWidgetReviewCount = document.querySelector('#pending-review-count');
    const pendingTabReviewCount = document.querySelector('#pending-tab-count');

    let newPendingCount = parseInt(infoWidgetReviewCount.innerText) - 1;
    infoWidgetReviewCount.innerText = newPendingCount;
    pendingTabReviewCount.innerText = `PENDING ARTICLES (${newPendingCount})`;
  }

  return (
    <div id={"approve-or-reject-article-reveal-modal"} className="reveal-modal new-modal approve-or-reject-article-modal middling large-12 columns" data-reveal>
      <div className="modal-body approve-or-reject-section">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="large-12 columns img-container">
              <img className='approve-or-reject-img text-center' src="https://public.cureus.com/my_dashboard/my_collections/approve-or-reject-img.png" />
            </div>
            <div className="large-12 columns header-container text-center">
              <h4>{renderHeaderText()}</h4>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#" className="no-button cancel-modal text-center">No</a>
            <button className="button yes-button" onClick={updateAction}>Yes</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApproveOrRejectModal;