var createReactClass = require('create-react-class');
import React from 'react';
import Page from './Page';
import {uniqueId} from 'lodash';

const Pages = createReactClass({
	// Props need to have Current Page, Total Page, and ajax get index method that is bound to pagination page number on click.
	// Index controller action should return kaminari page data.
	// See Cureus.EmailCampaignIndex.Main for great example.
	generatePagination: function () {
		var pages = [];
    for(var i=1; i <= this.props.paginationData.totalPages; i++) {
      pages.push(<Page
                  pageNumber={i}
                  currentPage={this.props.paginationData.currentPage}
                  handlePageSelect={this.props.handlePageSelect}
                  key={uniqueId()}
                  />
                  )
    }

    // don't show pagination if there is only one page
    if(pages.length == 1) {
      return
    }

		return this.paginate(pages);
	},

  paginate: function(pages) {

		var showPages = [];
		var firstPages = this.pagesLeftOfCurrentPage(pages);
		var lastPages = this.pagesRightOfCurrentPage(pages);

		showPages.push(firstPages);
		showPages.push(lastPages);
		return this.handleNavGuillemets(showPages);
  },

  pagesLeftOfCurrentPage: function (pages) {
		var currentPageIndex = this.props.paginationData.currentPage - 1;

		if (this.props.paginationData.currentPage - 1 > 5) {
			var leftOfCurrentPage = pages.slice(currentPageIndex - 3, currentPageIndex);
			var firstPages = pages.slice(0, 2);

			firstPages.push(<li key={uniqueId()} className= "unavailable" ><a>...</a></li>);
			firstPages.push(leftOfCurrentPage);
			return firstPages
		}
		else {
			return pages.slice(0, currentPageIndex)
		}
	},

	pagesRightOfCurrentPage: function(pages) {
		var currentPageIndex = this.props.paginationData.currentPage - 1;

		if (this.props.paginationData.totalPages - this.props.paginationData.currentPage > 5) {
			var rightOfCurrentPage = pages.slice(currentPageIndex, currentPageIndex + 4 );
			var lastPages = pages.slice(-2);

			rightOfCurrentPage.push(<li key={uniqueId()} className= "unavailable" ><a>...</a></li>);
			rightOfCurrentPage.push(lastPages);
			return rightOfCurrentPage
		}
		else {
			return pages.slice(currentPageIndex)
		}
	},

	handleNavGuillemets: function(paginationPages) {
		var pagination = paginationPages;
		if (!this.props.paginationData.firstPage) {
			pagination.unshift(this.guillemetComponent(this.props.paginationData.currentPage-1, (this.props.pagesType === 'article' ? "Prev" : "‹")));
		};

		if (!this.props.paginationData.lastPage) {
			pagination.push(this.guillemetComponent(this.props.paginationData.currentPage+1, (this.props.pagesType === 'article' ? "Next" : "›")));
		};
		return pagination
	},

	guillemetComponent: function(pageNumber, character) {
		return <Page
            pageNumber={pageNumber}
            character={character}
            currentPage={this.props.paginationData.currentPage}
            handlePageSelect={this.props.handlePageSelect}
            key={uniqueId()}
            totalPages={this.props.paginationData.totalPages}
            />
	},

	checkReviewer: function() {
		if (this.props.classNameForReviewer) {
			return "pagination default-pagination right"
		}
    else {
			return "pagination default-pagination"
		}
	},

	render: function() {

		return (
			<ul className= {this.checkReviewer()}> {this.generatePagination()} </ul>
		)
	}
})

export default Pages;