import React from 'react';

const AddingFigures = () => {

  return (
    <React.Fragment>
      <h1>Adding Figures</h1>
      <p>Follow these steps to upload and insert a figure directly into your article:</p>
      <ol className='decimals indented-for-medium-up'>
        <li>Place your cursor where you wish to insert the figure and click the Insert Figure button in the toolbar.</li>
        <li>Upload your image file.</li>
        <li>Enter a title in the title field.</li>
        <li>If desired, enter a legend in the legend field.</li>
        <li>Click the Next button and confirm that you have formatted your figure to meet all listed requirements.</li>
        <li>Click the Insert button to insert the figure into your article.</li>
      </ol>
    </React.Fragment>
  )

}

export default AddingFigures;