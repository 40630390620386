var createReactClass = require('create-react-class');
import React from 'react';

const NpiOther = createReactClass({

  render: function(){
    return (
      <div className="row">
        <div className="large-5 columns">
          <label>Describe identification type</label>
          <input name="details" onChange={(e) => this.props.handleChange(e)} type="text" placeholder="e.g. Medical Council of India" value={this.props.details}></input>
          <label>Identification number</label>
          <input name="npi" onChange={(e) => this.props.handleChange(e)} type="text" placeholder="e.g. 1234567" value={this.props.npi}></input>
        </div>
      </div>
    )
  }
})

export default NpiOther;