import React, {useState, useEffect} from 'react';
import Sortable from '../Sortable';
import { Cureus } from '../../Cureus';
import MultimediaCard from './MultimediaCard';

const Main = ({featuredMultimediaPath}) => {
  const [mediaList, setMediaList] = useState([]);
  const [url, setUrl] = useState('');
  const [name, setName] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [urlErrorMessage, setUrlErrorMessage] = useState(false);
  const [typingUrl, setTypingUrl] = useState(false);
  const [saving, setSaving] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(false);


  useEffect(() => {
    if(typingUrl) {
      handleUrlMessage();
    }
  }, [url])

  useEffect(() => {
    $.ajax({
      type: 'GET',
      url: "/admin/featured_multimedia",
      success: function(data) {
        setMediaList(data.media)
      }
    });
  }, [])

  const handleDelete = (e, idx) => {
    if(window.confirm("Are you sure you want to delete this card?")) {
      let mediaListCopy = [...mediaList];
      mediaListCopy.splice(idx, 1);
      setMediaList(mediaListCopy);
      setEnableSaveButton(true);
      setSaving(false);
    }
  }

  const handleSave = () => {
    setSaving(true);
    let formData = new FormData();
    let card = null;

    for (var i = 0; i < mediaList.length; i++) {
      card = mediaList[i];
      for(let dataKey in card) {
        formData.append(`multimedia[${i}][${dataKey}]`, outputCorrectPosition(card, dataKey, i));
      }
    }

    fetch(featuredMultimediaPath, {  
      method: "POST",
      body: formData,
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $("[name='csrf-token']").attr("content")
      }
    }).then(function(res) {
      Cureus.Flash.success('Content Successfully Saved.')
      setSaving(false);
      setEnableSaveButton(false);
    })
  }

  const outputCorrectPosition = (card, dataKey, index) => {
    if (dataKey == "position") {
      return index + 1
    } else {
      return card[dataKey]
    }
  }

  const renderCards = () => { 
    return mediaList.map((media, idx) => {
      return (
        <MultimediaCard
          key={media.position}
          media={media} 
          idx={idx}
          handleDelete={handleDelete}
        />
      )
    })
  }

  const addButtonDisabled = () => {
    return !urlErrorMessage && url && name && imageFile && !saving ? '' : 'disabled';
  }

  const saveButtonDisabled = () => {
    return enableSaveButton && !url && !name && !imageFile && !saving ? '' : 'disabled';
  }

  const handleUrl = (value) => {
    setTypingUrl(true);
    setUrl(value);
  }

  const handleUrlMessage = () => {
    if(url.includes('http://') || url.includes('https://')) {
      setEnableSaveButton(true);
      setUrlErrorMessage(false);
    } else if(!url) {
      setUrlErrorMessage(false);
    } else {
      setEnableSaveButton(false);
      setUrlErrorMessage(true);
    }
  }

  const handleAddMedia = () => {
    const newMedia = {
      name,
      url,
      image: imageFile,
      new_image: true,
      position: mediaList.length + 1,
      preview_image: URL.createObjectURL(imageFile),
    }
    setMediaList([...mediaList, newMedia]);
    setImageFile(null);
    setName('');
    setUrl('');
    setSaving(false);
    setEnableSaveButton(true);
    setTypingUrl(false);
    setUrlErrorMessage(false);
  }

  const handleSort = (e, sortable, movedComponent) => {
    let newPosition = e.newIndex + 1;
    handleUpdatePosition(movedComponent, newPosition);
  }
  
  const handleUpdatePosition = (movedComponent, newPosition) => {
    let oldPositionIndex = movedComponent.props.media.position - 1;
    
    let movedCard = mediaList[oldPositionIndex];
    let newMediaListState = mediaList.slice();
    
    newMediaListState.splice(oldPositionIndex, 1);
    newMediaListState.splice(newPosition - 1, 0, movedCard);
    let updatedPositions = updatePositionAfterMoving(newMediaListState);

    setMediaList(updatedPositions);
    setSaving(false);
    setEnableSaveButton(true);
  }

  const updatePositionAfterMoving = (newMediaListState) => {
    return newMediaListState.map((media, idx) => {
      let updatedMedia = {};
      for(let key in media) {
        if(key === 'position') {
          updatedMedia[key] = idx + 1;
        } else {
          updatedMedia[key] = media[key];
        }
      }
      return updatedMedia;
    })
  }

  return (
    <div className='featured-multimedia-container small-12 columns'>
      <div>
        <h3>
            Featured Multimedia Content
        </h3>
      </div>
      {mediaList.length > 0 &&
        <div className='row'>
          <Sortable className='small-12 columns carousel-container featured-multimedia-section' tagName='div' handleSort={handleSort}>
            {renderCards()}
          </Sortable>
        </div>
      }
      <div className='row'>
        <div className='small-12 columns'>
          <div className="input-container input-width">
            <label>URL</label>
            <input type="text" name="title" value={url} placeholder="Enter the URL here." onChange={(e) => handleUrl(e.target.value)}/>
          </div>
          {urlErrorMessage && <p className='warning-message'>Please include http:// or https:// in your URL</p>}
        </div>
      </div>
      <div className='row'>
        <div className='small-12 columns'>
          <div className="input-container input-width">
            <label>Title</label>
            <input type="text" name="title" value={name} placeholder="Enter the title of this multimedia item." onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='small-12 columns'>
          Preview Image (required)
          <input
            className="choose-file-btn"
            type="file"
            onChange={(e) => setImageFile(e.target.files[0])}
            style={{width: '300px'}}
          />
        </div>
      </div>

      <div className='row'>
        <div className='small-12 columns margin-top'>
          <button
            className={'button-width button medium special ' + `${addButtonDisabled()}`}
            onClick={handleAddMedia}
          >
            + ADD MULTIMEDIA
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='small-12 columns margin-top'>
          <button 
            className={'button-width button medium ' + `${saveButtonDisabled()}`}
            onClick={handleSave}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  )
}

export default Main;