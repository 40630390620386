import React, { useState, useEffect } from 'react';
import PeerReviewers from './PeerReviewers';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";

const PeerReviewersMain = ({reviewersPath, lastUpdated, fetchReviewersPath, removeReviewersPath, reviewerPollingPath, uploadReviewersPath, userToolProps}) => {
  
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(lastUpdated);
  const [reviewersCount, setReviewersCount] = useState(null);

  return (
    <Router hashType={"hashbang"}>
      <div className="small-12 columns channel-peer-reviewers-container">
        <div className="row peer-review-header">
          <h1>Internal Peer Reviewer List
            {reviewersCount > 0 && <span className="reviewer-h1-blurb reviewers-count">{reviewersCount}</span>}
          </h1>
          {lastUpdatedTimestamp && <span className="header-light-text">Updated on {lastUpdatedTimestamp}</span>}
          <p className="peer-review-description">
            {reviewersCount > 0 && "Channel admins and editors can invite peer reviewers from this list on the corresponding Admin Article page. Reviewers can be added while an article is in editor preview, editor check and peer review. Use this page to manage your list and add additional reviewers individually or via CSV upload. Select the checkbox and click the Delete button to remove reviewers from the list."}
            {reviewersCount == 0 && "Use this page to add peer reviewers individually or via CSV upload. Channel admins and editors can invite peer reviewers from this list on the corresponding Admin Article page. Reviewers can be added while an article is in editor preview, editor check and peer review."}
          </p>
        </div>
          <Switch>
            <Route path='/' exact>
              <PeerReviewers removeReviewersPath={removeReviewersPath} setLastUpdatedTimestamp={setLastUpdatedTimestamp} setReviewersCount={setReviewersCount} reviewersPath={reviewersPath} fetchReviewersPath={fetchReviewersPath} reviewerPollingPath={reviewerPollingPath} uploadReviewersPath={uploadReviewersPath} userToolProps={userToolProps}/>
            </Route>
          </Switch>
      </div>
    </Router>
  )

}

export default PeerReviewersMain;