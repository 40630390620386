import React, { Component } from 'react';

class Card extends Component {
  componentDidMount() {
    $(document).foundation({
      equalizer : {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true,
        // Allow equalizer to resize hidden elements
        act_on_hidden_el: false,
      }
    });
  }

  render() {
    return (
      <div className="small-6 columns">
        <div className="tile" data-equalizer-watch="expedited" style={{minHeight: '450px'}}>
          <div className="height-container">
            <h3>{this.props.header}</h3>
          </div>
          <h2>${this.props.price}</h2>
          <a className="button purchase" name={this.props.type} onClick={(e) => this.props.handleExpeditedPurchase(e)}>Purchase</a>
          <p>{this.props.firstParagraph}</p>
          <p>{this.props.secondParagraph}</p>
        </div>
      </div>
    )
  }
}

export default Card;