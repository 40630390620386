import React from 'react';
import Selector from './Selector';

const Authorship = ({contributions, fullName, id, position}) => {

  return (
    <div className="row contribution">
      <div className="large-5 columns">
        <div className="large-1 columns">
          {position}
        </div> 
        <div className="large-11 columns">
          {fullName}
        </div>  
      </div>
      <div className="large-7 columns">
          <Selector
            id={id}
            initialContributions={contributions}
          />
      </div>
    </div>
  )
}

export default Authorship;