import React from 'react';

const CureusPromotionModal = ({handleAddWidget, widgetSidebarId}) => {

  const handleAddWidgetWrapper = () => {
    let params = {
      widget: {
        type: "Widget::CureusPromotionSidebar",
        parent_id: widgetSidebarId
      }
    }
    handleAddWidget(params)
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>SIQ Promotion</h5>
            <hr/>
          </div>
          <p>Insert a promotional ad for <a target="_blank" href="https://www.cureus.com/siq">SIQ</a> to encourage readers to rate your channel articles!</p>
          <div className='modal-footer text-center'>
            <a className={"button primary"} onClick={handleAddWidgetWrapper}>Add</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CureusPromotionModal;