import React from 'react';

const DataCitationAndTransparency = () => {
  return (
    <React.Fragment>
      <h1>Data Citation and Transparency</h1>

      <h2>Data Citation</h2>
      <p>
        All data, program code and other methods should be appropriately cited. Such materials are recognized as original intellectual contributions and afforded recognition through citation. We recommend citing all data sets and program code used in a publication in the text and listing them in the reference section.
      </p>
      <p>
        References for data sets and program code should include a persistent identifier, such as a Digital Object Identifier (DOI). Persistent identifiers ensure future access to unique published digital objects, such as a text or data set. Persistent identifiers are assigned to data sets by digital archives, such as institutional repositories and partners in the Data Preservation Alliance for the Social Sciences (DataPASS).
      </p>

      <h2>Data, Analytic Methods (Code), and Research Materials Transparency</h2>
      <p>
        Authors are encouraged to indicate whether the data, methods used in the analysis, and materials used to conduct the research will be made available to any researcher for purposes of reproducing the results or replicating the procedure.
      </p>
      <p>
        Authors are encouraged to indicate in the acknowledgments section if they will or will not make their data, analytic methods, and study materials available to other researchers. If an author agrees to make materials available, the author must specify where that material will be available. 
      </p>

      <h2>Design and Analysis Transparency</h2>
      <p>
        Authors should follow standards for disclosing key aspects of the research design and data analysis. Authors are encouraged to review the standards available for many research applications from <a href='http://www.equator-network.org' target='_blank' rel='noreferrer'>http://www.equator-network.org/</a> ​and use those that are relevant for the reported research applications.
      </p>

    </React.Fragment>
  )
}

export default DataCitationAndTransparency;