var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';
import PmcCard from '../Shared/ContentCards/PmcCard';
import CorrectionRetractionCard from '../Shared/ContentCards/CorrectionRetractionCard';
import PersonCard from '../Shared/ContentCards/PersonCard';
import ArticleCard from '../Shared/ContentCards/ArticleCard';
import PosterCard from '../Shared/ContentCards/PosterCard';
import ContentCard from '../UserProfile/ContentCard';
import AbstractCard from '../Shared/ContentCards/AbstractCard';
import Filters from './Filters';
import Pages from '../Shared/Pagination/Pages';
import PMCFilters from '../SearchTool/PMCFilters';
import Header  from './Header';
import Search from './Search';

const Main = createReactClass({
  getInitialState: function() {
    var selectedSortIdx;
    selectedSortIdx = this.props.searched ? 0 : this.props.entriesPage ? 3 : 1
    return ({
      advanced: this.props.advanced ? true : false,
      contents: this.props.contents.map(function(obj) {
        if(obj.type) {
          return obj
        } else {
          return obj.data.attributes
        }
      }),
      filters: this.props.filters,
      page: 1,
      pageCountOverview: this.props.pageCountOverview,
      spinning: false,
      categories: this.props.categories,
      types: this.props.contentTypes,
      selectedSort: this.props.sorts.selectOptions[selectedSortIdx].value,
      search: this.props.q || '' ,
      paginationData: this.props.paginationData,
      shuffler: null,
      subchannels: this.props.subchannels,
      hasSubchannels: this.props.subchannels !== null && Object.keys(this.props.subchannels).length > 0,
      pmc: {
        perPage: 20,
        page: 1,
        order: 'published_at_desc',
        paginationData: {currentPage: 1, totalItems: 20, firstPage: true, lastPage: false},
        spinning: false,
        pageCountOverview: '',
        shuffler: null,
        contents: []
      },
      siqTrackingSourcePage: this.props.siqTrackingSourcePage,
      searched: this.props.searched,
      obeySort: false,
      channelVersion: this.props.channelVersion
    })
  },

  componentDidMount: function() {
    var self = this;
    if (this.props.articlesPage){
      this.submitUpdate(true, true)
      this.setState({
        shuffler: new Cureus.ContentCardShuffler({containerSelector: '#content-results-wrap', maxColumns: 3}),
        pmc: Object.assign(self.state.pmc, {shuffler: new Cureus.ContentCardShuffler({containerSelector: '#pmc-cards', maxColumns: 3})})
      } );
    } else {
      this.setState({shuffler: new Cureus.ContentCardShuffler({containerSelector: '#content-results-wrap', maxColumns: 3})});
    }
  },

  shuffleOnLoad: function() {
    this.setState({shuffler: new Cureus.ContentCardShuffler({containerSelector: '#content-results-wrap', maxColumns: 3})});
  },

  updateCategories: function(newCatState) {
    this.setState({categories: newCatState})
  },

  updateSubchannels: function(newSubchannelsState) {
    this.setState({subchannels: newSubchannelsState})
  },

  updateTypes: function(newTypeState) {
    this.setState({types: newTypeState})
  },

  updateSort: function(sort) {
    this.setState({selectedSort: sort, page: 1, obeySort: true}, this.submitUpdate)
  },

  updateSearch: function(e) {
    this.setState({search: e.target.value})
  },

  resetPage: function() {
    this.setState({page: 1}, this.submitUpdate);
  },

  handleSearchEnter: function(e) {
    var self = this;
    if (e.key === 'Enter') {
      self.setState({page: 1, advanced: false, selectedSort: this.props.sorts.selectOptions[0].value, searched: true}, self.submitUpdate);
      self.setState({pmc: Object.assign(self.state.pmc, {page: 1})}, function() { self.submitUpdate(true) });
    }
  },

  handleSearchClick: function() {
    var self = this;
    self.setState({page: 1, advanced: false, selectedSort: this.props.sorts.selectOptions[0].value, searched: true}, self.submitUpdate);
    self.setState({pmc: Object.assign(self.state.pmc, {page: 1})}, function() { self.submitUpdate(true) });
  },

  handleAdvancedSearchEnter: function(e, filters) {
    var self = this;
    if (e.key === 'Enter') {
      this.setState({page: 1, filters: this.cleanAdvancedSearchFilters(filters), advanced: true, selectedSort: this.props.sorts.selectOptions[0].value, searched: true}, self.submitUpdate);
    }
  },

  handleAdvancedSearchClick: function(filters) {
    var self = this;
    this.setState({page: 1, filters: this.cleanAdvancedSearchFilters(filters), advanced: true, selectedSort: this.props.sorts.selectOptions[0].value, searched: true}, self.submitUpdate);
  },

  cleanAdvancedSearchFilters: function(filters) {
    return filters.reduce(function(res, filter) {
      if (filter.query !== null) {
        filter.query = filter.query.trim()
        if (filter.query.length > 0) {
          res.push(filter);
        }
      }
      return res;
    }, [])
  },

  updatePage: function(page, pmc) {
    this.setState({page: page}, this.submitUpdate)
  },

  updatePmcPage: function(page) {
    var self = this;
    this.setState({pmc: Object.assign(self.state.pmc, {page: page})}, function() { this.submitUpdate(true) })
  },

  updatePmcPerPage: function(perPage) {
    var self = this;
    this.setState({pmc: Object.assign(self.state.pmc, {perPage: perPage})}, function() { this.submitUpdate(true) })
  },

  updatePmcSort: function(sort) {
    var self = this;
    this.setState({pmc: Object.assign(self.state.pmc, {order: sort, page: 1})}, function() {self.submitUpdate(true)})
  },

  formatParams: function() {
    var params = {categories: {}, subchannels: {}, types: {}, page: this.state.page, q: this.state.search, order: this.state.selectedSort, advanced: this.state.advanced, filters: this.state.filters, obeySort: this.state.obeySort };

    if(!this.state.categories[0].checked) {
      Object.values(this.state.categories).forEach(function(cat) {
        if(cat.checked) { params.categories[cat.id] = cat.name }
      });
    }

    Object.values(this.state.types).forEach(function(type){
      if (type.checked) { params.types[type.underscore] = type.classType }
    });

    if (this.state.hasSubchannels) {
      Object.values(this.state.subchannels).forEach(function(ch) {
        if (ch.checked) { params.subchannels[ch.id] = ch.title}
      })
    }
    return params;
  },

  submitUpdate: function(pmcSearch, skipScroll=false) {
    var self = this;
    if(self.props.articlesPage && !this.props.landing && !skipScroll) {
      $("html, body").animate({scrollTop: 175}, 0);
    }
    var path, params;
    if (pmcSearch) {
      path = self.props.pmcFilterPath;
      params = {q: self.state.search, per_page: self.state.pmc.perPage, order: this.state.pmc.order, page: self.state.pmc.page}
    } else {
      path = self.props.filterPath;
      params = self.formatParams();
    }
    self.spin(pmcSearch);
    $.ajax({
      method: 'get',
      url: path,
      data: params,
      dataType: 'json',
      success: function(res) {
        self.setState(self.updateContents(res, pmcSearch));

      },
      error: function(res) {
      }
    }, 'json');
  },

  mapCards: function(pmcCards) {
    var contents = pmcCards ? this.state.pmc.contents : this.state.contents;
    var self = this;
    return contents.map(function(resource, idx) {
      if (pmcCards) {
        return <PmcCard key={resource.struct.table.dbid + '_pmc_content'} resource={resource}/>
      } else {
        var isLast = false;
        if (idx == contents.length - 1) { isLast = true}
        return self.createCard({resource, isLast});
      }
    });
  },

  createCard: function({resource, isLast=false}) {
    var siqTrackingSourcePage = this.props.siqTrackingSourcePage
    switch(resource.type) {
      case 'User':
        return <PersonCard  key={resource.id + '_' + resource.type} resource={resource}/>
      case 'Correction':
        return <CorrectionRetractionCard  key={resource.id + '_' + resource.type} resource={resource}/>
      case 'Retraction':
        return <CorrectionRetractionCard  key={resource.id + '_' + resource.type} resource={resource}/>
      default:
        if (this.state.channelVersion == 2 && this.props.abstracts ) {
          return (
            <AbstractCard
              resource={resource}
              showCategories={true}
              isLast={isLast}
            />
          )
        }

        if (this.state.channelVersion == 2 && this.props.posters) {
          return (
            <PosterCard
              resource={resource}
              showCategories={true}
              isLast={isLast}
            />
          )
        }
        return (this.props.posters || this.props.abstracts) ?
        <ContentCard  key={resource.id + '_' + resource.type} resource={resource} articlesPage={this.props.articlesPage}  page={siqTrackingSourcePage? siqTrackingSourcePage : "ArticleIndex" } shuffleOnLoad={this.shuffleOnLoad}/>
        :
        <ArticleCard  key={resource.id + '_' + resource.type} resource={resource} articlesPage={this.props.articlesPage} page={siqTrackingSourcePage? siqTrackingSourcePage : "ArticleIndex" } compOrChannel={this.props.compOrChannel}/>
    }
  },

  shuffle: function(pmc){
    var self = this;
    window.setTimeout(function(){
      pmc ? self.state.pmc.shuffler.reshuffle() : self.state.shuffler.reshuffle()
    }, 750);
  },

  updateContents: function(data, pmc) {
    var self = this;
    var contents = data.contents.data === undefined ? data.contents : data.contents.data
    if (pmc) {
      self.setState({pmc: Object.assign(self.state.pmc, {contents: data.contents, page: data.page, pageCountOverview:   data.pageCountOverview, spinning: false, paginationData: data.paginationData})}, self.shuffle(true));
    } else {
      self.setState({contents: contents.map(function(obj) {
        if(obj.type && obj.type !== 'object') {
          return obj
        } else {
          if (obj.attributes !== undefined) {
            return obj.attributes
          } else {
            return obj.data.attributes
          }
        }
      }), page: data.page, pageCountOverview:   data.pageCountOverview, spinning: false, paginationData: data.paginationData}, self.shuffle);
    }
  },

  spin: function(pmc) {
    var self = this;
    if (pmc){
      this.setState({pmc: Object.assign(self.state.pmc, {spinning: true})})
    } else {
      this.setState({spinning: true })
    }
  },

  renderSpinner: function() {
    return (
      <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>
    )
  },

  searchPageCureusFilter: function() {
    if (this.props.articles) {
      return(
        <div>
          <br/>
          <br/>
        <h3 className='reg'>Results found in Cureus</h3>
        <br/>
        {this.renderCureusFilters()}
        </div>
      )
    }
  },

  renderPageCountOverview: function() {
    if (this.state.channelVersion != 2) {
      return (
        <div className='content-index-results-count'>
          {this.state.pageCountOverview}
        </div>
      )
    }
  },

  renderCureusContents: function() {
    return(
      <div className='row'>
        <div className='large-12 small-12 columns'>
            {this.props.searchPage && this.searchPageCureusFilter()}
            {this.renderPageCountOverview()}
          <div className='content-index-results-columns' id='content-results-wrap'>
            <div>
              {this.mapCards(false)}
            </div>
          </div>
        </div>
      </div>
    )
  },

  renderPmcContents: function() {
    return(
      <div className='row'>
        <div className='large-12 small-12 columns'>
          <div className='content-index-results-count'>
            {this.state.pmc.pageCountOverview}
          </div>
          <div className='content-index-results-columns' id='pmc-cards'>
            {this.mapCards(true)}
          </div>
        </div>
      </div>
    )
  },

  renderCureusBody: function() {
    if (this.state.spinning) {
      return this.renderSpinner();
    } else {
      return this.renderCureusContents();
    }
  },

  renderCureusFilters: function() {
    return (
      <Filters
        selectedSort={this.state.selectedSort}
        categories={this.props.categories}
        contentTypes={this.props.contentTypes}
        sorts={this.props.sorts}
        typesRequired={this.props.typesRequired}
        filterPath={this.props.filterPath}
        searchPage={this.props.searchPage}
        categoryIds={this.props.categoryIds}
        resetPage={this.resetPage}
        submitUpdate={this.submitUpdate}
        updateSearch={this.updateSearch}
        updateSort={this.updateSort}
        updateTypes={this.updateTypes}
        updateCategories={this.updateCategories}
        updateSubchannels={this.updateSubchannels}
        handleSearchEnter={this.handleSearchEnter}
        handleSearchClick={this.handleSearchClick}
        handleAdvancedSearchEnter={this.handleAdvancedSearchEnter}
        handleAdvancedSearchClick={this.handleAdvancedSearchClick}
        articlesPage={this.props.articlesPage}
        compOrChannel={this.props.compOrChannel}
        subchannels={this.props.subchannels}
        hasSubchannels={this.state.hasSubchannels}
        advanced={this.state.advanced}
        filters={this.state.filters}
        search={this.state.search}
        posters={this.props.posters}
        searched={this.state.searched}
        channelVersion={this.state.channelVersion}
      />
    )
  },

  renderPmcBody: function() {
    if (this.state.pmc.spinning) {
      return this.renderSpinner();
    } else {
      return this.renderPmcContents();
    }
  },

  handlePageUpdate: function(page) {
    this.setState({page: page}, function(){this.child.updatePage(page)});
  },

  renderCureusIndex: function() {
    return(
      <div>
        <div className='row collapse'>
          <div className='large-12 columns'>
            {(!this.props.searchPage || this.props.posters) && this.renderCureusFilters()}
          </div>
        </div>
        <div className='content-index-wrap' id='filter-results'>
          {this.renderCureusBody()}

          <div className='content-index-pagination'>
            <Pages
              paginationData={this.state.paginationData}
              handlePageSelect={this.updatePage} />
          </div>
        </div>
      </div>
    )
  },

  pmcContent: function() {
    if (this.props.articlesPage && !this.props.bot && !this.props.landing) {
      return(
        <div className='pmc-results-wrap'>
          <h3 className='reg'> Results found in PubMedCentral </h3>
          <div className='pmc-filters-component'>
            <PMCFilters
              handleOrderChange={this.updatePmcSort}
              handlePerPageChange={this.updatePmcPerPage}
            />
          </div>
          <div className='content-index-wrap' id='pmc-results'>
            {this.renderPmcBody()}

            <div className='content-index-pagination'>
              <Pages
              paginationData={this.state.pmc.paginationData}
              handlePageSelect={this.updatePmcPage} />
            </div>
          </div>
        </div>
      )
    }
  },

  renderHeader: function() {
    if (!this.props.compOrChannel) {
      return (
        <div className="row">
          <Header
            people={this.props.people}
            peoplePath={this.props.peoplePath}
            posters={this.props.posters}
            postersPath={this.props.postersPath}
            articlesPage={this.props.articlesPage}
            articlesPath={this.props.articlesPath}
          />
        </div>
      )
    }
  },

  render: function() {
    return (
      <div className={`channels-v${this.state.channelVersion}`}>
        {this.renderHeader()}
        <div className='row'>
          <div className='large-12 small-12 columns'>
          {this.props.searchPage &&
            <Search
              typesRequired={this.typesRequired}
              searchPage={this.props.searchPage}
              updateSearch={this.updateSearch}
              handleSearchEnter={this.handleSearchEnter}
              people={this.props.people}
              handleSearchClick={this.handleSearchClick}
              posters={this.props.posters}
            />
          }
          </div>
        </div>
        <div className='search-results-wrap'>
          {this.renderCureusIndex()}
          {this.pmcContent()}
        </div>
      </div>
    )
  }
});

export default Main;