import React from "react"
import SubscribeButton from "./SubscribeButton"

const Header = ({pageTitle, categoryIdPreSelected, cureusUEmailListIds, isSignedIn}) => {
  return (
    <div className="row">
      <div className='header large-11 medium-12 small-12 columns small-centered end'>
        <h1>{pageTitle}</h1>
        {categoryIdPreSelected && cureusUEmailListIds.length > 0 && <SubscribeButton cureusUEmailListIds={cureusUEmailListIds} isSignedIn={isSignedIn}/>}
      </div>
    </div>
  )
}

export default Header