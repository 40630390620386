import React, { useEffect } from 'react';
import { Cureus } from '../../Cureus';

const PointToPointModal = () => {

  useEffect(() => {
    Cureus.DismissableModal.init($(".point-to-point-modal"))
  }, [])

  return (
    <div className="reveal-modal medium point-to-point-modal dismissable-modal" id="point-to-point-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <h4>Please respond to each peer reviewer comment at the bottom of the page.</h4>
          <p>This is your opportunity to explain to the editor your choice to revise (or not). Please respond to each comment (including the reviewer questionnaire comments) to assist the editor in reviewing your revised draft. Your response is required for all comments. If two or more reviewers have provided similar comments, please copy and paste your response. If you disagree with a comment, don’t be afraid to say so!</p>
          <p>Click on the reviewer comment to jump to the response field at the bottom of the page. Click the extracted comment text to jump back to the highlighted comment within the article.</p>
          <div className='modal-img'>
            <label>
              <input type="checkbox" data-dismiss-checkbox="true"></input>
              Do not show this window again.
            </label>
            <img className="point-img" src="https://public.cureus.com/point_to_point_modal.png"/>
          </div>
        </div>
        <div className="modal-footer text-center">
          <a href="#" className="button cancel-modal" id="point-to-point-modal-submit">Get Started</a>
        </div>
      </div>
    </div>
  )
}

export default PointToPointModal;