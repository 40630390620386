var createReactClass = require('create-react-class');
import React from 'react';

const Picker = createReactClass({
  handleChange: function (val){
    this.props.onChosen(val)
  },

  getOptions: function (input, callback){
    $.ajax({
      type: "GET",
      dataType: "json",
      url: this.props.searchUrl,
      data: {query: input}
    }).done( function (data, textStatus, jqXHR) {
      callback(undefined, {options: data});
    });
  },

  UNSAFE_componentWillMount: function() {
    this.getOptions = _.debounce(this.getOptions, 250);
  },

  render: function (){
    var select = (
      <Select 
        key={_.uniqueId("user_mergetool_picker_")}
        asyncOptions={ this.getOptions } 
        name="foo" 
        onChange={this.handleChange} 
        value={this._displayValue()}
        searchable={true}/>
    );

    return (
      <div>
        { select }
      </div>
    )
  },


  _displayValue: function() {
    let selected_user = this.props.selectedUser;
    if (selected_user) {
      return selected_user.details.name + " (" + selected_user.details.email + " - " + selected_user.details.id + ")";
    }
    return "Enter email, name, or id"
  }
});

export default Picker;
