function confirmInstitution(id, validated) {
  var name = $("#inst_" + id + " td div.editName").html()
  $.ajax({
    type: "PUT",
    url: "/admin/clean_institutions/" + id,
    data: {validated: validated, name: name},
    dataType: 'json',
    success: function(data) {
      $("#clean-institutions").dataTable().fnUpdate(data, $('tr#inst_' + data.id), undefined, false)
    }
  });
}

export { confirmInstitution }