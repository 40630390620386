import React, { useState, useEffect } from 'react';
import DateSelector from '../StatsWidget/DateSelector';
import moment from 'moment';
import { Cureus } from '../../Cureus';

const Index = ({initialDateRanges, userEmail}) => {
  const [dateRanges, setDateRanges] = useState(initialDateRanges);
  const [selectedRange, setSelectedRange] = useState(initialDateRanges[0].label);
  const [from, setFrom] = useState(initialDateRanges[0].from)
  const [to, setTo] = useState(initialDateRanges[0].to)

  useEffect(() => {
    fetchData(from, to, selectedRange);
  }, [])

  const fetchData = (fromDate, toDate, label) => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      data: {start: fromDate, finish: toDate},
      url: '/admin/discounts',
      success: function(data) {
        setSelectedRange(label)
        setTo(toDate)
        setFrom(fromDate)
        populateTable(data.discounts)
      }
    });
  }

  const setColumns = () => {
    return [
      {width: '160px'},
      {width: '160px'},
      {width: '160px'},
      {width: '160px'},
      {width: '160px'},
      {width: '160px'},
      {width: '160px'},
      {width: '160px'},
      {width: '160px'}
    ]
  }
  
  const populateTable = (data) => {
    var dt = $("#discounts-table").DataTable({
        destroy: true,
        columns: setColumns(),
        columnDefs: [
          {
            // when displaying, show date in MM-DD-YYYY format, when sorting it will sort by ISO date
            targets: [1],
            render: (date, type, row) => {
              return (type === 'display') ? date : moment(date, "MM-DD-YYYY").toISOString();
            }
          }
        ],
        searching: true,
        pageLength: 20,
        dom: '<"row"<"large-5 columns"f>><"table"t>r<"row bottom"<"large-10 columns"p><"large-2 columns"l>>'
    });

    dt.api().clear();
    for (var i = 0; i < data.length; i++) {
        let row = data[i];
        dt.fnAddData([
            row.type,
            row.offer_date,
            `<a target='_blank' href='/admin/users/${row.user_id}'>${row.user_id}</a>`,
            row.country,
            `<a target='_blank' href='/admin/articles/${row.article_id}'>${row.article_id}</a>`,
            row.price,
            `<span class=${row.status_class}>${row.status}</span>`,
            row.offered,
            row.completed
        ], false)
    }

    dt.fnSort([[1, 'desc']])
    dt.fnDraw();

    initializeFilters(dt)
}

const initializeFilters = (dt) => {
  let headerRow = $(dt.api().table().header()).find('tr');
  let filterRow = $('<tr>').insertBefore(headerRow);

  dt.api().columns([0, 3, 6, 7, 8]).every(function () {
    let column = this;
    let header = $(column.header());

    header.find('select').remove();

    let select = $('<select><option value=""></option></select>')
      .appendTo(header)
      .on('change', function () {
        let val = $(this).val();

        if (val) {
          if (!isNaN(val)) {
            column
              .search('^' + val + '$', true, false)
              .draw();
          } else {
            column
              .search('^' + $.fn.dataTable.util.escapeRegex(val) + '$', true, false)
              .draw();
          }
        } else {
          column
            .search('')
            .draw();
        }
      });

    column.data().unique().sort(function(a, b) {
      return a - b;
    }).each(function (d, j) {
      if(column.index() === 6) {
        d = $(d).text()
      }
      select.append('<option value="' + d + '">' + d + '</option>');
    });
  });
}

  const renderDateSelector = () => {
    return (
      <div className='small-2 columns' style={{float: 'right'}}>
        <DateSelector
          from={from}
          to={to}
          getStats={fetchData}
          selectedRange={selectedRange}
          dateRanges={dateRanges}
        />
      </div>
    )
  }

  const exportCsv = () => {
    let dt = $('#discounts-table').DataTable()
    let rows = dt.api().rows({search: 'applied'}).data()
    let headers = dt.api().columns().header()

    let tableData = [headers.toArray().map(header => header.innerText.split('\n')[0]), ...rows.toArray()]
    
    $.ajax({
      type: 'POST',
      dataType: 'json',
      data: {contents: tableData, userEmail: userEmail},
      url: '/admin/discounts/export_csv',
      success: function() {
        Cureus.Flash.success("CSV file has been emailed to you!")
      }
    });
  }

  return (
    <div className='list-tab discounts-table-container'>
      <a className="button csv" onClick={exportCsv}>CSV</a>
      <div className="campaigns-index">
        {renderDateSelector()}
        <table id="discounts-table">
          <thead>
            <tr>
              <th class="bottom-align"><div>Discount type</div></th>
              <th class="bottom-align"><div>Offer date</div></th>
              <th class="bottom-align"><div>Author ID</div></th>
              <th class="bottom-align"><div>Country</div></th>
              <th class="bottom-align"><div>Article ID</div></th>
              <th class="bottom-align"><div>PE price before discount</div></th>
              <th class="bottom-align"><div>Discount status</div></th>
              <th class="bottom-align"><div># offered (in date range)</div></th>
              <th class="bottom-align"><div># paid (in date range)</div></th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  )
}

export default Index;




