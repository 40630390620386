var createReactClass = require('create-react-class');
import React from 'react';
import CreateCampaignModal from './CreateCampaignModal';
import UpdateStatsButton  from './UpdateStatsButton';
import Filter from './Filter';
import TemplateFilter from './TemplateFilter';
import DeleteButton from './DeleteButton';
import CampaignRow from './CampaignRow';
import Modal from '../Modal';
import Pages from '../Shared/Pagination/Pages';
import { Cureus } from '../../Cureus';

const Main = createReactClass({
  getInitialState: function() {
    return {
      filter: "All",
      emailCampaigns: [],
      checked: {},
      paginationData: [],
      searchQuery: null,
      sortColumn: "c.id",
      sortDirection: "desc"
    }
  },

  componentWillMount: function() {
    this.setState({
      emailCampaigns: this.props.emailCampaigns,
      paginationData: this.props.paginationData
    });
  },

  componentDidMount: function (){
    Cureus.SplitButton.init();
  },

  componentDidUpdate: function() {
    Cureus.SplitButton.init();
  },

  handleCampaignRename: function (updatedCampaign){
    this.setState({});
  },


  handleFilter: function(val) {
    var self = this;

    self.setState({
      filter: val
    }, function() {
      self.updateTable(1);
    });
  },

  handleRowChecked: function(id, isChecked) {
    var checked = this.state.checked;
    if(isChecked) {
      checked[id] = true;
    } else {
      delete checked[id];
    }

    this.setState({checked: checked});
  },

  handleSelectAllChanged: function(e) {
    var checked = this.state.checked;

    if(e.target.checked) {
      _.each(this.state.emailCampaigns, function(emailCampaign) {
        checked[emailCampaign.id] = true;
      });
    } else {
      checked = {};
    }

    this.setState({checked: checked});
  }, 
  checkedIds: function() {
    return Object.keys(this.state.checked);
  },

  updateTable: function(page) {
    var self = this;
    var $table = $(self.refs.table);
    var $button = $('#search')
    $button.attr("disabled", "disabled");
    $table.spin();
    $.get(self.props.indexPath, {
      filter: self.state.filter,
      page: page,
      searchQuery: self.state.searchQuery,
      templates: self.getTemplates(),
      sortColumn: self.state.sortColumn,
      sortDirection: self.state.sortDirection
    }, function(data) {
      $table.spin(false);
      self.setState({
        emailCampaigns: data.emailCampaigns,
        checked: {},
        paginationData: data.paginationData
      });
      $button.removeAttr("disabled");
    }, "json");
  },

  getTemplates: function() {
    var templates = []
    $("input.template:checked").each(function() {
      templates.push($(this).val())
    })
    return templates;
  },

  selectAllChecked: function() {
    var count = this.checkedIds().length;
    return count > 0 && count == this.state.emailCampaigns.length;
  },

  checkedForUpdate: function() {
    var self = this;
    var toUpdate = []
    self.state.emailCampaigns.map(function(emailCampaign) {
      if (emailCampaign.sent && self.state.checked[emailCampaign.id]) {
        toUpdate.push(emailCampaign.id)
      } else {
        return;
      }
    });
    return toUpdate;
  },

  handleSearch: function(e) {
    this.setState({searchQuery: e.target.value})
  },

  checkSubmit: function(e) {
    if (e.key === 'Enter') {
      this.updateTable();
    }
  },

  openCreateModal: function() {
    Modal.open({
      children: <CreateCampaignModal
        createPath={this.props.createPath}
        templates={this.props.templates.filter(function(x) { return x != 'most_read' })}
      />
    });
  },

  renderCreateButton: function() {
    if(this.props.channelDigests) {
      return (
        <a href={this.props.createPath} className='button primary'>{this.props.createLabel}</a>
      )
    } else {
      return (
        <a onClick={this.openCreateModal} className='button primary'>{this.props.createLabel}</a>   
      )
    }
  },

  handleSort: function(column) {
    var direction = this.state.sortDirection
    if (column != this.state.sortColumn) {
      direction = column == "c.title" ? "asc" : "desc";
    } else {
      direction = direction == "desc" ? "asc" : "desc"
    }
    var self = this;
    this.setState({sortDirection: direction, sortColumn: column}, () => { self.updateTable(1) })
  },

  render: function() {
    
    var self = this;
    return (
      <div className='campaigns-index'>
        <h2 className='reg'>
          {self.props.pageTitle}
          &nbsp;
          <small>{self.state.paginationData.totalItems}</small>
        </h2>
        
        { self.props.templateFilters && 
          <div className='row'>
            <div className='large-12 columns'>
              <TemplateFilter options={self.props.templateFilters}/>
            </div>
          </div>        
        }

        <div className='pull-right'>
          {self.renderCreateButton()}
        </div>

        <div className='row'>
          <div className='large-1 columns'>
            <Filter value={ self.state.filter } onChange={ self.handleFilter } />
          </div>

          { self.props.bulkUpdatePath && 
            <div className='large-2 columns'>
              <UpdateStatsButton 
              updateStatsPath= { self.props.bulkUpdatePath }
              ids = { self.checkedForUpdate() }
              onUpdate= { self.updateTable }
              />
            </div>
          }

          <div className='large-3 columns'>
            <input onChange={this.handleSearch} onKeyPress={this.checkSubmit} type="text" placeholder="Search by title" />
          </div>

          <div className="large-1 columns">
            <a onClick={() => this.updateTable(1)} id='search' className='button primary'>Search</a>
          </div>

          <div className='large-2 columns'>  
            <DeleteButton destroyPath={ self.props.bulkDestroyPath } ids={ self.checkedIds() } onDelete={ self.updateTable }/>
          </div>
        </div>

        <table ref='table' className='table-wrapper'>
          <thead>
            <tr>
              <th><input type="checkbox" onChange={self.handleSelectAllChanged} checked={self.selectAllChecked()}/></th>
              <th onClick={() => this.handleSort("status")}>Status</th>
              <th onClick={() => this.handleSort("c.title")}>Campaign</th>
              <th onClick={() => this.handleSort("c.created_at")}>Created</th>
              <th onClick={() => this.handleSort("edited/sent")}>Edited/Sent</th>
              <th onClick={() => this.handleSort("cs.requests")}>Requests</th>
              <th onClick={() => this.handleSort("cs.delivered")}>Delivered</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              self.state.emailCampaigns.map(function(emailCampaign) {
                return <CampaignRow
                         key={emailCampaign.id}
                         emailCampaign={emailCampaign}
                         isChecked={self.state.checked[emailCampaign.id]}
                         onCheck={self.handleRowChecked}
                         onRename={self.handleCampaignRename}
                          />
              })
            }
          </tbody>
        </table>
        <Pages
          paginationData={self.state.paginationData}
          handlePageSelect={self.updateTable} />
      </div>
    )
  }
});

export default Main;