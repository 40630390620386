import React, {useState, useEffect} from 'react';
import Filter from './Filter';

const FilterWrapper = ({ contents, page, classToOpenFilter}) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [pills, setPills] = useState([]);

  const filterCategories = contents.map((category, index) => {
    return {...category,
    id: index + 1}
  })

  useEffect(() => {
    const categoryButton = document.querySelector(classToOpenFilter);
    categoryButton.addEventListener('click', () => {
      setIsFilterMenuOpen(!isFilterMenuOpen);
    })
  }, [])

  useEffect(() => {
    document.querySelector("#category_ids").value = pills.map((pill) => pill.category_id).join(',');
    submitForm();
  }, [pills])

  const submitForm = () => {
    let colSearchAjaxForm = document.querySelector("#collection-search-ajax-form")
    if (colSearchAjaxForm) {
      colSearchAjaxForm.requestSubmit()
    }
  }

  return (
    <Filter
      isMenuOpen={isFilterMenuOpen}
      setFilterMenuOpen={setIsFilterMenuOpen}
      contents={filterCategories}
      page={page}
      enableFetchContents={false}
      setPills={setPills}
      pills={pills}
      closeFilterAndSearchOnClearClick={true}
    />
  )
}

export default FilterWrapper;