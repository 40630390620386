var createReactClass = require('create-react-class');
import React from 'react';
import BulkAddModal from './BulkAddModal';
import LockedListSelectedModal from './LockedListSelectedModal';
import BulkDeleteModal from './BulkDeleteModal';
import BulkExportModal from './BulkExportModal';
import Modal from '../Modal';
import PropTypes from 'prop-types';
import { Cureus } from '../../Cureus';

const BulkActions = createReactClass({
  propTypes: {
    emailListIds: PropTypes.array.isRequired,
    lockedListIds: PropTypes.array.isRequired
  },

  bulkAdd: function() {
    Modal.open({
      children: <BulkAddModal 
                  emailListIds={this.props.emailListIds}
                  bulkAddPath={this.props.bulkActionPaths.add} />
    });
  },

  bulkAddWithRefresh: function() {
    Modal.open({
      children: <BulkAddModal 
                  emailListIds={this.props.emailListIds}
                  bulkAddPath={this.props.bulkActionPaths.add}
                  refresh={true} />
    });
  },

  bulkDelete: function() {
    //Can't delete any list when a locked list is selected
    if(this.lockedListSelected()) {
      var modal =
        <LockedListSelectedModal />
    } else {
      var modal = 
        <BulkDeleteModal 
          emailListIds={this.props.emailListIds} 
          bulkDeletePath={this.props.bulkActionPaths.delete} />
    }

    Modal.open({children: modal});
  },

  bulkExport: function() {
    Modal.open({
      children: <BulkExportModal
                  emailListIds={this.props.emailListIds}
                  bulkExportPath={this.props.bulkActionPaths.export}
                  />
    })
  },

  lockedListSelected: function() {
    var intersect = _.intersection(this.props.lockedListIds, this.props.emailListIds);
    return intersect.length > 0;
  },

  renderBulkExport: function() {
    if (this.props.bulkActionPaths.export) {
      return (
        <li><a onClick={ this.bulkExport }>Download selected lists</a></li>
      )
    }
  },

  renderBulkAddWithRefresh: function() {
    if (!this.props.isChannel) {
      return ( <li><a onClick={this.bulkAddWithRefresh}>New list from selected (r)</a></li> )
    }
  },

  bulkRefresh: function() {
    if (confirm("Are you sure you want to refresh the selected lists?")) {
      $.post(this.props.bulkActionPaths.refresh, { email_list_ids: this.props.emailListIds }, function(data) {
        Cureus.Flash.success("Email lists queued for refreshing!")
        window.scrollTo({top: 0});
      })
    }
  },

  renderBulkRefresh: function() {
    if (!this.props.isChannel && this.props.bulkActionPaths.refresh) {
      return (
        <li><a onClick={ this.bulkRefresh }>Refresh selected lists</a></li>
      )
    }
  },

  render: function() {
    return (
      <div className='email-list-tool-bulk-actions'>
        <a className="button secondary tiny split">
          <div onClick={this.bulkAdd}>New list from selected</div>
          <span data-dropdown="email-list-bulk-actions" />
        </a>
        <ul id="email-list-bulk-actions" className='f-dropdown' data-dropdown-content>
          { this.renderBulkAddWithRefresh() }
          { this.renderBulkRefresh() }
          { this.renderBulkExport() }
          <li>
            <a onClick={this.bulkDelete}>Delete selected lists</a>
          </li>
        </ul>
      </div>
    )
  }
});

export default BulkActions;