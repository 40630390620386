import React, { useState, useEffect } from 'react';
import Draft from './AbstractStates/Draft';
import ChannelEditorApprovalDeferred from './AbstractStates/ChannelEditorApprovalDeferred';
import ChannelEditorApproval from './AbstractStates/ChannelEditorApproval';
import Published from './AbstractStates/Published';
import DeskRejected from './AbstractStates/DeskRejected';
import Abandoned from './AbstractStates/Abandoned';
import All from './AbstractStates/All';

const Abstracts = ({abstractsPath, setSpinning, isParent}) => {

  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setSpinning(true)
    $.ajax({
      type: 'GET',
      url: abstractsPath,
      success: function(data) {
        setData(data)
        setSpinning(false)
        $(document).foundation('reflow');
      }
    });
  }

  const renderChevronContainer = (expanded, setExpanded, className='') => {
    return (
        <img 
          src="https://public.cureus.com/channel-icons/chevron-thin-down.png" 
          onClick={() => setExpanded(!expanded)} 
          onMouseOver={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-hovered-down.png")}
          onMouseOut={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-thin-down.png")}
          onMouseDown={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-clicked-down.png")}
          onMouseUp={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-hovered-down.png")}
          className={`chevron-toggle ${className} ${expanded ? "rotate" : ""}`}
        />
    )
  }

  const tooltip = (color) => {
    if(!color || color === 'grey') {
      return "Number of the articles in this state."
    } else if(color === 'orange') {
      return "Please, pay attention."
    } else {
      return "Your action is required to proceed further."
    }
  }

  if (!data) {
    return <div/>
  } else {
    return (
      <React.Fragment>
        <h5 className="articles-header">Abstracts <span>({data.total})</span></h5>
        <div className="articles-container">
          <Draft renderChevronContainer={renderChevronContainer} abstractsPath={abstractsPath} initialData={data.initial_data.draft} isParent={isParent} />
          <ChannelEditorApproval renderChevronContainer={renderChevronContainer} abstractsPath={abstractsPath} initialData={data.initial_data.channel_editor_approval} isParent={isParent} />
          <ChannelEditorApprovalDeferred renderChevronContainer={renderChevronContainer} abstractsPath={abstractsPath} initialData={data.initial_data.channel_editor_approval_deferred} isParent={isParent} />
          <Published renderChevronContainer={renderChevronContainer} abstractsPath={abstractsPath} initialData={data.initial_data.published} isParent={isParent} />
          <DeskRejected renderChevronContainer={renderChevronContainer} abstractsPath={abstractsPath} initialData={data.initial_data.desk_rejected} isParent={isParent} />
          <Abandoned renderChevronContainer={renderChevronContainer} abstractsPath={abstractsPath} initialData={data.initial_data.abandoned} isParent={isParent} />
          <All renderChevronContainer={renderChevronContainer} abstractsPath={abstractsPath} initialData={data.initial_data.all} isParent={isParent} />
        </div>
      </React.Fragment>

    )
  }
}

export default Abstracts;