var createReactClass = require('create-react-class');
import React from 'react';

const KeywordTool = createReactClass({
  getInitialState: function() {
    return {
      keyword: "",
      keywords: []
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  keywordsRemaining: function() {
    return this.props.keywordLimit - this.state.keywords.length;
  },

  componentWillMount: function() {
    this.setState({
      keywords: this.props.keywords
    });
  },

  componentDidMount: function() {
    var self = this;
    var $input = $(self.refs.input);

    $input.autocomplete({
      source: self.keywordSuggestions,
      select: function(e, ui) {
        self.setState({
          keyword: ui.item.name
        });
        self.handleAdd();
      }
    });

    this.checkForKeywordPresence();
  },

  keywordSuggestions: function(request, response) {
    var term = request.term;
    var searchUrl = this.props.basePath + "/search";

    $.ajax({
      type: 'GET',
      url: searchUrl,
      data: _.extend(this.props.params, {keyword: term}),
      success: function(data) {
        var keywordSuggestions = [];
        _.each(data, function(el) {
          keywordSuggestions.push({
            label: el.name,
            value: el.name,
            name: el.name,
            id: el.id
          });
        });
        response(keywordSuggestions);
      }
    });
  },

  handleChange: function(e) {
    this.setState({
      keyword: e.target.value
    });
  },

  handleKeyPress: function(e) {
    if(e.key === 'Enter') {
      this.handleAdd();
      e.preventDefault();
    }
  },

  handleAdd: function() {
    var self = this;

    if(self.state.keywords.length < self.props.keywordLimit) {
      $.ajax({
        type: 'POST',
        url: self.props.basePath,
        data: _.extend(self.props.params, {
          keyword: self.state.keyword
        }),
        success: function(data) {
          self.setState({
            keywords: self.state.keywords.concat(data),
            keyword: ""
          });
          self.checkForKeywordPresence();
        }
      });
    };
  },

  handleRemove: function(keyword) {
    var self = this;

    $.ajax({
      type: 'DELETE',
      url: self.props.basePath + "/" + keyword.id,
      data: self.props.params,
      success: function() {
        self.removeKeyword(keyword);
        self.checkForKeywordPresence();
      }
    });
  },

  removeKeyword: function(keyword) {
    var keywords = _.reject(this.state.keywords, function(kw) {
      return keyword.id == kw.id;
    });

    this.setState({
      keywords: keywords
    });
  },

  checkForKeywordPresence: function() {
    this.state.keywords.length < this.props.keywordsRequired ? this.disableButton() : this.enableButton()
  },

  enableButton: function() {
    [].forEach.call(this.$buttons(), function(el) {
      el.removeAttribute("disabled")
    })
  },

  disableButton: function() {
    [].forEach.call(this.$buttons(), function(el) {
      el.setAttribute("disabled", true)
    })
  },

  $buttons: function() {
    return $(this.props.enableDisableTarget)
  },

  renderRemaining: function() {
    if(this.state.keywords.length < this.props.keywordsRequired) {
      var remaining = this.props.keywordsRequired - this.state.keywords.length;
      return (
        <p className="beta-keyword-info small-description">
          Please add {remaining} more {remaining === 1 ? "keyword" : "keywords"} in order to proceed.
        </p>
      )
    } else if(this.keywordsRemaining() <= this.props.keywordLimit) {
      return (
        <p className="beta-keyword-info small-description">
          You can add { this.keywordsRemaining() } more keywords.
        </p>
      )
    }
  },

  render: function() {
    var self = this;

    if(self.keywordsRemaining() < self.props.keywordLimit){
      var actionBox =
          <div>
            <div className='beta-keyword-list clearfix'>
              <ul id="beta-keywords">
                {
                  self.state.keywords.map(function(keyword) {
                    return (
                      <li key={ keyword.name }>
                        <div className='keyword_left_triangle'/>
                        <div className='dot-wrap'/>
                        <div className='dot'/>
                        { keyword.name }
                        <a onClick={ self.handleRemove.bind(self, keyword) }>
                          <i className='fa fa-times'>
                          </i>
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div>

            </div>
          </div>

    } else { var actionBox = <div></div> }


    return (
      <div className='beta-keyword-panel'>
        <div className='keyword-input-wrap'>
          <div className='row'>
            <div className='large-12 columns'>
              <small>Enter keywords separated by a comma.</small>
            </div>
          </div>

          <div className='row'>
            <div className='large-6 medium-6 small-9 columns'>
              <input type='text'  autoComplete="off" id="ui-autocomplete-input" ref='input' disabled={self.keywordsRemaining() === 0} onChange={ self.handleChange } onKeyPress={ self.handleKeyPress } value={ self.state.keyword }/>
            </div>
            <div className='large-1 medium-1 small-1 columns'>
              <a className='button' disabled={self.keywordsRemaining() === 0} onClick={ self.handleAdd }>Add</a>
            </div>
            <div className='large-5 columns'></div>
          </div>
        </div>
        <div>
            { actionBox }
        </div>
        {this.renderRemaining()}
      </div>
    )
  }
});

export default KeywordTool;
