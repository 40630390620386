import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import ChannelApi from '../../Cureus/ChannelApi';
import InternalContent from './Modals/InternalContent'
import InternalContentCard from '../../components/Shared/ContentCards/InternalContentCard';
import Sortable from '../Sortable';

const Internal = ({slug, widgetInternalContentTabId, userInternalContents}) => {

  const [contents, setContents] = useState([]);
  const [parentId, setParentId] = useState(widgetInternalContentTabId);
  const [stateUpdated, setStateUpdated] = useState(false);

  useEffect(() => {
    if(parentId) {
      let widgetId = parentId;
      let onSuccess = (res) => {
        setContents(res.contents);
      }
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    } else {
      setContents([]);
    }
  }, [stateUpdated])

  const handleAddWidget = (params) => {
    ChannelApi.createWidget(params, slug, function(res) {
      let widgetId;
      if(!parentId) {
        setParentId(res.id);
        widgetId = res.id;
      } else {
        widgetId = res.parent_id;
      }
      let onSuccess = (res) => {
          setContents(res.contents);
          Modal.close();
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }, true)
  }

  const handleUpdateWidget = (widgetId, params) => {
    ChannelApi.updateWidget(widgetId, params, slug, function(res) {
      setStateUpdated(!stateUpdated);
      Modal.close();
    }, true)
  }

  const handleLastWidgetDelete = () => {
    if (contents.length === 1) {
      setParentId('');
    }
  }

  const handleDeleteWidget = (widgetId) => {
    handleLastWidgetDelete();
    ChannelApi.destroyWidget(widgetId, slug, function(res) {
      setStateUpdated(!stateUpdated);
      Modal.close();
    }, true);
  }

  const openModal = () => {
    Modal.open({
      children: <InternalContent
                  handleAddWidget={handleAddWidget}
                  handleUpdateWidget={handleUpdateWidget}
                  widgetInternalContentTabId={parentId}
                  internalTab={true}
                />
    });
  }

  const renderWidgets = () => {
    return contents.map(function(content, idx) {
      return (
        <div className={userInternalContents ? "" : "card drag-handle"} key={idx}>
          <div className="large-4 medium-12 small-12 columns no-pad end">
            <InternalContentCard
              content={content}
              showActionButtons={true}
              handleDeleteWidget={handleDeleteWidget}
              handleUpdateWidget={handleUpdateWidget}
              widgetInternalContentTabId={parentId}
              internalTab={true}
              internalTabUser={userInternalContents}
            />
          </div>
        </div>
      )
    })
  }

  const handleSort = (e, sortable, movedComponent) => {
    let widgetId = movedComponent.props.children.props.children.props.content.id
    let params = {widget: {position: e.newIndex + 1}}
    ChannelApi.updateWidget(widgetId, params, slug)
  }

  const renderSortableForAdmin = () => {
    if(userInternalContents) {
      return (
        <>
          {renderWidgets()}
        </>
      )
    } else {
      return (
        <Sortable tagName='div' handleSort={handleSort}>
          {renderWidgets()}
        </Sortable>
      )
    }
  }

  return (
    <div className="channel-main-content-container">
      <div className='row'>
          <div className="small-12 columns widget internal-content internal-content-tab">
            {renderSortableForAdmin()}
          </div>
      </div>
      {!userInternalContents && <hr className="orange-bar internal"/>}
      {!userInternalContents &&
        <div className='row'>
          <div className='small-12 columns internal-tab-container'>
            <div className="settings-box">
              <h3>Other Content Settings</h3>
              <p>Feature content from outside Cureus such as powerpoint presentations, PDFs, or articles published in other journals. Click below to upload files or add links to your channel’s Other Content page. Click and drag to reorder widgets.</p>
              <hr/>
              <div className='text-center settings-button-container' onClick={openModal}>
                <img className='internal-content-tab-img' src="https://public.cureus.com/channel_design/internal_upload_icon_4x.png" alt="" />
                <span>ADD OTHER CONTENT</span>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Internal;