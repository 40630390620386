var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal';
import EmailListModal from './EmailListModal';

const EmailListSelector = createReactClass({
  getInitialState: function() {
    return ({
      emailLists: [],
      checkedEmailLists: this.props.currentEmailLists,
      allCureusUsers: this.props.allCureusUsersSelected,
      campaignType: this.props.campaignType || 'campaign'
    })
  },

  renderCheckBoxes: function() {
    var self = this;
    return self.props.emailLists.map(function(emailList, index) {
      // emailLists is a duple of a list of email_lists that may contain nulls
      var formattedEl = self.compact(emailList)
      return (
        <div key={"emailList-" + index} className="columns large-6">
          {self.mapCheckBoxMarkup(formattedEl)}
        </div>
      )
    })
  },

  mapCheckBoxMarkup: function(formattedEl) {
    var self = this;
    return formattedEl.map(function(el) {
      var isChecked = self.presentInCheckedEmailList(el)
      return (
        <div key={el.id}>
          <input onClick={self.handleChecked} type="checkbox"  name={"campaign[email_list_ids][" + el.id + "]"} id={JSON.stringify(el)} defaultChecked={isChecked}/>
          <label htmlFor={JSON.stringify(el)}>{el.title.slice(0, 50)} ({el.list_emails_count}) </label>
        </div>
      )
    })
  },

  presentInCheckedEmailList: function(el) {
    var checked;
    this.state.checkedEmailLists.forEach(function(element) {
      if (el.id === element.id) {
        checked = true
      }
    })
    return checked
  },

  renderAllCureusUsersCheckbox: function() {
    var isChecked = this.state.allCureusUsers
    return (
      <div>
        <input defaultChecked={isChecked} onClick={this.setAllCureusUsersState} value="true" type="checkbox"  name="campaign[all_cureus_users]" id="all_cureus_users"/>
        <label htmlFor="all_cureus_users"><strong>All Cureus Users</strong></label>
      </div>
    )
  },

  setAllCureusUsersState: function() {
    this.setState({allCureusUsers: !this.state.allCureusUsers})
  },

  compact: function(emailList) {
    return emailList.filter(function(e) {
      if (e !== null) {return e}
    })
  },

  renderHiddenInputFields: function() {
    var emailListMarkup = this.state.checkedEmailLists.map(function(el) {
      return (
        <li key={"hidden-" + el.id}>
          <input type="hidden" value="true" name={"campaign[email_list_ids][" + el.id + "]"} id={"campaign_email_list_ids_" + el.id}/>
          {el.title} ({el.list_emails_count})
        </li>
      )
    })
    emailListMarkup.push(this.allCureusUsersHiddenField())
    return emailListMarkup
  },


  allCureusUsersHiddenField: function() {
    if (this.state.allCureusUsers) {
      return (
        <li key="all-cureus-users-true">
          <input value="true" type="hidden" name={this.state.campaignType + "[all_cureus_users]"} id={this.state.campaignType + "[all_cureus_users]"} />
          All Cureus Users
        </li>
      )
    } else {
      return(
        <input key="all-cureus-users-true" value="false" type="hidden" name={this.state.campaignType + "[all_cureus_users]"}  id={this.state.campaignType + "[all_cureus_users]"} />
      )
    }
  },

  handleChecked: function(e) {
    var checkedEmails = this.state.checkedEmailLists;
    var el = JSON.parse(e.target.id)
    if (checkedEmails.length === 0) {
      checkedEmails.push(el)
    } else {
      checkedEmails = this.updateCheckedEmailLists(checkedEmails, el)
    }
    this.setState({checkedEmailLists: checkedEmails})
  },

  updateCheckedEmailLists: function(checkedEmails, el) {
    var newCheckedEmails = checkedEmails.slice()

    for (var i = 0; i < checkedEmails.length; i++) {
      if (checkedEmails[i].id === el.id) {
        newCheckedEmails = this.removeElementInArray(checkedEmails, el)
        break;
      } else if (i === checkedEmails.length - 1) {
        newCheckedEmails.push(el)
      }
    }
    return newCheckedEmails
  },

  removeElementInArray: function(array, element) {
    return array.filter(function(el) {
      if (el.id != element.id) {return el}
    })
  },

  handleClear: function() {
    this.setState({checkedEmailLists: []}, function() {
      $('input[type=checkbox]').prop('checked', false); 
    })
  },

  openModal: function() {
    var self = this;
    Modal.open({
      children: <EmailListModal
        renderAllCureusUsersCheckbox={self.renderAllCureusUsersCheckbox}
        renderCheckBoxes={self.renderCheckBoxes}
        handleClear={self.handleClear}
      />
    })
  },

  render: function() {
    return (
      <div>
        <h5 className="reg">Currently Selected Email Lists</h5 >
        <ul>
          {this.renderHiddenInputFields()}
        </ul>
        <button className="button small primary" data-reveal-id="email-list-modal" type="button" onClick={this.openModal}>Select Mailing List</button>
      </div>
    )
  }
})

export default EmailListSelector;