import React, { useEffect } from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-labels';

const PieChart = ( { ctx, datasets, title, labels} ) => {

  useEffect(() => {
    $(`#${ctx}`).remove();
    $(`#${ctx}-container`).append(`<canvas id='${ctx}'></canvas>`);
    new Chart(ctx, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          labels: {
            render: function(args) {
              return args.value + ' (' + args.percentage + '%)';
            },
            fontSize: 14,
            fontStyle: 'bold',
            fontColor: '#000'
          }
        }
      }
    })
  })

  function renderEditorApprovalQueueFooter() {
    if (ctx == "EditorApprovalQueue" && datasets[0]) {
      var data = datasets[0].data
      var approval = data[0] + data[1]; 
      var copy = data[2] + data[3];
      return (
        <div style={{display: "inline-block"}}>
          Total queue: {(approval + copy).toLocaleString("en-US")}, Approval: {approval.toLocaleString("en-US")}, Copy: {copy.toLocaleString("en-US")}
        </div>
      )
    }
  }


  return (
    <React.Fragment>
      <div id={`${ctx}-container`} className="large-12 columns"><canvas id={ctx}></canvas></div>
      { renderEditorApprovalQueueFooter() }
    </React.Fragment>
  )

}

export default PieChart;