import React, { useState, useEffect } from 'react';

const AnswerBarRow = ({answer, percent, percentNumber, userAnsweredId, handleAnswer}) => {

  const renderIcons = () => {
    if (answer.correct) {
      return <i className='fas fa-check-circle' />;
    } else if ((userAnsweredId == answer.id) && !answer.correct) {
      return <i className='fas fa-times-circle' />;
    } else {
      return <div className='circle-dot'/>;
    }
  }

  return (
    <div className={'individual-bar-container ' + (userAnsweredId ? '' : 'unanswered')} onClick={!userAnsweredId ? () => handleAnswer(answer.id) : undefined }>
      {userAnsweredId ? renderIcons() : <div className='circle-dot' onClick={() => handleAnswer(answer.id)}/>}
      <p className='text-in-percent-bar'>{answer.answer}</p>
      {userAnsweredId && <div className="answer" style={{width: percent}} />}
      {userAnsweredId && <p className='percent-text'>{percentNumber}</p>}
    </div>
  )
}

export default AnswerBarRow;