import React, { useState, useEffect } from 'react';
import Table from './Table';
import Selected from './Selected';
import { Cureus } from '../../Cureus';

const Index = ({}) => {
  return (
    <React.Fragment>
      <Selected />
      <hr/>
      <Table/>
    </React.Fragment>
  )

}

export default Index;