import React, { useState, useEffect, useRef } from 'react';
import DateSelector from '../StatsWidget/DateSelector';
import { Cureus } from '../../Cureus';
import useClickOutside from '../useClickOutside';

const MonthlyTab = ({approvalEditors, initialDateRanges, isAdmin, userEmail}) => {

  const editorDropdown = useRef("menu");
  useClickOutside(editorDropdown, () => {
    setExpandDropDown(false);
  });

  const [expandDropDown, setExpandDropDown] = useState(false);
  const [selectedApprovalEditors, setSelectedApprovalEditors] = useState([])
  const [toDate, setToDate] = useState(initialDateRanges[0].to)
  const [fromDate, setFromDate] = useState(initialDateRanges[0].from)
  const [selectedRange, setSelectedRange] = useState(initialDateRanges[0].label)
  const [tableData, setTableData] = useState(null);
  const [csvContents, setCsvContents] = useState(null);
  const [tooltip, setTooltip] = useState("No approval editor selected");

  const handleDropDown = () => {
    setExpandDropDown(!expandDropDown);
  }

  useEffect(() => {
    $('.approval-editor-menu').foundation('reflow');
  }, [tooltip]);

  const fetchData = (fromDate, toDate, label) => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      data: {start: fromDate, finish: toDate, approvalEditorIds: selectedApprovalEditors},
      url: '/admin/approval_editor_completions/monthly_breakdown',
      success: function(data) {
        setSelectedRange(label)
        setToDate(toDate)
        setFromDate(fromDate)
        setTableData(data.data)
        setTooltip(renderToolTip(data.data));
        generateCSVcontent(data.data)
      }
    });
  }

  const renderToolTip = (data) => {
    if(data) {
      let userNames = data.map((user) => {
        return `${user.full_name}`
      }).join(', ')
      return userNames
    }
  }

  const renderApprovalEditorDropdown = () => {
    return (
        <>
        <div className='approval-editor-menu has-tip tip-top' data-tooltip={tooltip} title={tooltip} onClick={handleDropDown} >
          <div className='fake-dropdown'>
            <a role='button' className='current'>
              Select approval editor from dropdown..
            </a>
            <a role='button' className='selector'>
            </a>
          </div>
        </div>
        {
          expandDropDown && (
            <div className='checkbox-section'>
              <div className='checkbox-content'>
                {renderSelectAllSelection()}
                {approvalEditors.map((ae) => {
                  return renderApprovalEditorSelection(ae)
                })}
              </div>
            </div>
          )
        }
      </>
    )
  }

  const handleCheckboxChange = (event,approvalEditor) => {
    const { checked } = event.target;

    setSelectedApprovalEditors((prevSelected) => {
      if (approvalEditor === 'all') {
        return checked ? approvalEditors.map((ae) => ae.id) : [];
      } else {
        return checked
          ? [...prevSelected, approvalEditor.id]
          : prevSelected.filter((id) => id !== approvalEditor.id);
      }
    });
  }

  const renderSelectAllSelection = () => {
    return (
      <div className='single-checkbox'>
        <input 
          id='select-all-checkbox'
          className='checkbox'
          type="checkbox"
          checked={selectedApprovalEditors.length == approvalEditors.length}
          onChange={(e) => handleCheckboxChange(e, 'all')}
        />
        <label for='select-all-checkbox'>Select/Deselect all</label>
      </div>
    )
  }
  

  const renderApprovalEditorSelection = (approvalEditor) => {
    return (
      <div className='single-checkbox' key={approvalEditor.id}>
        <input 
          id={approvalEditor.id}
          className='checkbox'
          type="checkbox"
          checked={selectedApprovalEditors.includes(approvalEditor.id)}
          onChange={(e) => handleCheckboxChange(e, approvalEditor)}
        />
        <label for={approvalEditor.id}>{approvalEditor.first_name} {approvalEditor.last_name}</label>
      </div>
    )
  }

  const renderDateSelector = () => {
    return (
      <DateSelector 
        to={toDate}
        from={fromDate}
        selectedRange={selectedRange} dateRanges={initialDateRanges} getStats={fetchData}
      />
    )
  }

  const generateCSVcontent = (data) => {
    const headers = ['Name', ...JSON.parse(data[0].monthly_data).map((month) => month.month)];
  
    const results = data.map((user) => {
      const userInfo = [user.full_name, ...JSON.parse(user.monthly_data).map((month) => month.count)];
      return userInfo;
    });
    setCsvContents([headers, ...results]);
  }

  const exportCSV = () => {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      data: {contents: csvContents, userEmail: userEmail},
      url: '/admin/approval_editor_completions/export_csv',
      success: function(data) {
        Cureus.Flash.success("CSV file has been emailed to you!")
      }
    });
  }

  const renderResultsTable = (data) => {

    return (
      <table id="ae-completions-table">
        <thead>
          <tr>
            <th>Name</th>
            {data && JSON.parse(data[0].monthly_data).map((month) => {
                return (
                  <th>{month.month}</th>
                )
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((user) => {
            return (
              <tr>
                <td>{user.full_name}</td>
                {JSON.parse(user.monthly_data).map((month) => {
                  return (
                    <td>{month.count}</td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <div className='monthly-breakdown'>
      <div className='top-section'>
        <div className='left'>
          <div className='select-names' ref={editorDropdown}>
            {renderApprovalEditorDropdown()}
          </div>
          <div className='select-time'>
            {renderDateSelector()}
          </div>
        </div>
        <div className='right'>
          {csvContents && isAdmin && (
            <div className='export-csv'>
              <div className='btn'>
                <div className='button-text button secondary' onClick={()=>exportCSV()}>
                  <span>Export CSV</span>
                  <img src='' alt='' />
                </div>
              </div>
            </div>
          )}
        </div> 
      </div>
      <div className='results-table'>
        {tableData ? renderResultsTable(tableData) : "No results"}
      </div>
    </div>
  )
}

export default MonthlyTab;