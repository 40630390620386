import React, {useState, useEffect} from 'react';
import { Cureus } from '../../Cureus';
import Modal from '../Modal';

const DeskRejectModal = ({articleId, populateText, reasonsData, adminArticlePath}) => {

  const [reasons, setReasons] = useState(reasonsData);
  const [reasonsCheckedCount, setReasonsCheckedCount] = useState(0);

  useEffect(() => {
    new Cureus.CKEditor('desk-reject-textarea',  { 
      editor_type: 'deferral',
      ckeditor: {height: 300},
      change_listener: "#desk-reject-textarea"
    })

    $(document).foundation("reflow");

    const timeout = setTimeout(function() {
      renderPreferredEditing();
    }, 1000)
    return () => {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    renderDeferralReasonText();
  }, [reasons]);

  
  const renderPreferredEditing = () => {
    let ckeditorInstance = CKEDITOR.instances['desk-reject-textarea'];
    if (ckeditorInstance) {
      let preText = populateText ? "<p>You will receive a refund for your Preferred Editing purchase less a $50 processing fee.</p>" : ""
      ckeditorInstance.setData(preText);
    }
  }

  const renderData = () => {
    const comments = CKEDITOR.instances["desk-reject-textarea"].getData();
    return { id: articleId, comments, block_type: "DeskRejected", commit: "Desk reject article", action: "block" }
  }

  const handleDeskRejection = () => {
    $.ajax({
      type: 'PUT',
      url: `/admin/articles/${articleId}/block`,
      data: renderData(),
      dataType: 'json',
      success: function(res) {
        Modal.close();
        window.location.pathname = adminArticlePath;
      },
      error: function() {
        Modal.close();
        Cureus.Flash.alert("Article could not be desk rejected.");
      }
    });
  }

  const renderDeferralReasonText = () => {
    if (reasonsCheckedCount == 0) { return; }
    let ckeditor = CKEDITOR.instances['desk-reject-textarea'];
    let reasonDescriptions = ['<div id="deferral-reasons-list">'];

    for (let reasonId in reasons) {
      let currentReason = reasons[reasonId];
      if (currentReason.checked) {
        reasonDescriptions.push(
          "<p>" + currentReason.description + "</p>"
        )
      }
    }

    reasonDescriptions.push('</div>')
    var oldText = ckeditor.getData();
    var completeText = reasonDescriptions.join(" ") + oldText

    if (ckeditor.getData() == '') {
      ckeditor.setData(completeText + "<p></p>")
    } else {
      ckeditor.setData(completeText);
    }
  }

  const handleReasonRemoval = () => {
    let ck = CKEDITOR.instances["desk-reject-textarea"];
    let deferralReasonsContent = ck.document.getById("deferral-reasons-list");
    if (deferralReasonsContent) {
      deferralReasonsContent.remove();
    }
  }

  const countCheckedReasons = (updatedReasons) => {
    return Object.values(updatedReasons).reduce((count, reason) => {
      return count + (reason.checked ? 1 : 0);
    }, 0);
  }

  const handleCheckboxToggle = (e) => {
    handleReasonRemoval();
    let reasonId = e.target.id;
    const updatedReasons = {
      ...reasons,
      [reasonId]: {
        ...reasons[reasonId],
        checked: !reasons[reasonId].checked
      }
    };
    setReasonsCheckedCount(countCheckedReasons(updatedReasons));
    setReasons(updatedReasons);
  }

  const renderCheckBoxes = () => {
    let checkBoxes = Object.entries(reasons).map(([key, currentReason]) => {
      let tooltipAttributes = currentReason.tooltipTitle ? { 'data-tooltip': '', title: currentReason.tooltipTitle } : {};
      return (
        <div className='columns large-4 end' key={key}>
          <div className='checkbox-container'>
            <label htmlFor={key} className="tip-top" {...tooltipAttributes} style={{fontSize: ".9em"}}>
              <input name={key} id={key} type='checkbox' value={currentReason.checked} onChange={handleCheckboxToggle} checked={currentReason.checked} />
              {currentReason.label}
            </label>
          </div>
        </div>
      )
    });
    
    return checkBoxes.chunk(3).map(function(group) {
      return (
        <div className='row'>
          {group}
        </div>
      )
    })
  }

  return (
    <div id="desk-rejection-article-modal" className="reveal-modal medium" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="small-12 columns">
              <h4>Desk Rejection</h4>
              <p>Please add comments indicating why you elected to desk reject this article. These comments will be delivered to the corresponding author via an automated email notification.</p>
            </div>
          </div>
          {renderCheckBoxes()}
          <div className="row">
            <div className="small-12 columns">
              <textarea id='desk-reject-textarea'></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <a className="button yes-button" onClick={handleDeskRejection}>Desk reject article</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeskRejectModal;