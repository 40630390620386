import React from 'react';

const RecentlyPublished = ({handleAddWidget, hasArticles, widgetMainRiverId}) => {
  const shouldDisable = () => {
    return hasArticles ? "" : "disabled";
  }

  const handleAddWidgetWrapper = () => {
    let params = {
      widget: {
        type: "Widget::RecentlyPublished",
        parent_id: widgetMainRiverId
      }
    }
    handleAddWidget(params)
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Recently Published</h5>
            <hr/>
          </div>
          <p>Display the four most recently published abstracts, articles and posters.</p>
          <div className='modal-footer text-center'>
            <a className={"button primary " + shouldDisable()} onClick={handleAddWidgetWrapper}>Add</a>
            {!hasArticles && <p className="error-message text-left">Your channel doesn’t have enough published content yet!</p>}
          </div>
        </div>
      </div>
    </div>
  )
}


export default RecentlyPublished;