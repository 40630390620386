import React, {useState, useEffect} from 'react';

const CollectionTile = ({active, collection, handleChooseCollection}) => {


  const renderClass = () => {
    if (active) {
      return "collection-card active"
    } else {
      return "collection-card"
    }
  }

  const renderImage = () => {
    if (collection.preview_url) {
      return collection.preview_url;
    } else {
      return "https://public.cureus.com/new_default_article_image.png";
    }
  }

  return (
    <div className={renderClass()} onClick={() => handleChooseCollection(collection)}>
      <div className="card-image-container">
        <img src={renderImage()}/>
      </div>
      <div className="text-container">
        <p>{collection.title}</p>
      </div>
    </div>
  )
}

export default CollectionTile;