var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const RenameCampaignModal = createReactClass({
  handleRename: function(evt) {
    var self, new_value;

    self = this;
    new_value = this.refs.renameCampaignInput.value

    $.ajax({
      type: "PUT",
      dataType: "json",
      url: this.props.renameCampaignPath,
      data: { emailCampaign: { title: new_value } }
    }).
      done( function (data, textStatus, jqXHR){
        self.props.emailCampaign.title = new_value;
        self.props.onRename(self.props.emailCampaign);
        Cureus.Flash.success(new_value + " has been successfully renamed.");
      }).
      fail( function (jqXHR, textStatus, errorThrown){
        var error_messages;

        error_messages = "Error: " + JSON.parse(jqXHR.responseText).errors;
        Cureus.Flash.alert(error_messages);
      }).
      always(function (){
        $(self.refs.modal).foundation("reveal", "close");
      });

    evt.stopPropagation();
    evt.preventDefault();
  },

  render: function() {
    return (
      <div ref="modal" className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4> Rename </h4>
            <div className="modal-form">
              <label>
                Title
                <input ref="renameCampaignInput" type="text" defaultValue={this.props.emailCampaign.title} />
              </label>

              <div className='modal-footer'>
                <a className='cancel-modal button secondary'>Cancel</a>
                <a className='button primary' onClick={this.handleRename}>Save</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default RenameCampaignModal;