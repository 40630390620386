var createReactClass = require('create-react-class');
import React from 'react';

const PreferredChoiceModal = createReactClass({
   render: function() {
    return (
        <div className="modal-content">
          <h4>Defer Preferred Editing article peer review</h4>
          <br/>
          <div className="row">
            <div className="large-5 columns selection">
              <h4>Defer to author for scientific content revisions</h4>
              <p>Only defer to the author if scientific content issues are present that cannot be addressed by the copy editor. For example, incorrect/incomplete data, missing article sections, etc. All other issues will be fixed during copy editing.</p>
              <a className="button" name="science" onClick={(e) => this.props.handleSelection(e)}>SELECT</a>
            </div>
            <div className="large-5  large-offset-1 columns end selection">
              <h4>Send to Preferred Editor queue for copy editing</h4>
              <p>Will the peer reviewers be able to provide a review of the article in its current form? Are there formatting or language issues that will prevent them from reviewing the article? Only send the article to the Preferred Editor queue if these issues are present.</p>
              <a className="button" name="seniorCopy" onClick={(e) => this.props.handleSelection(e)}>SELECT</a>
            </div>
        </div>
      </div>
    )
  }
})

export default PreferredChoiceModal;