import React from 'react';
var createReactClass = require('create-react-class');
const EditorialWidgetBody = createReactClass({

  renderStats: function() {
    var self = this
    var stats =  self.props.stats.map(function(e) {
      if (self.props.isCompetition || self.props.isChannel) {
        return (
          <tr>
            <td><h5 className="reg">{e.label}</h5></td>
            <td><strong>{e.total}</strong></td>
          </tr>
        )
      } else {
        return (
          <tr>
            <td><h5 className="reg">{e.label}</h5></td>
            <td><strong>{e.total}</strong></td>
            <td><strong>{e.free}</strong></td>
            <td><strong>{e.select}</strong></td>
            <td><strong>{e.preferred}</strong></td>
            <td><strong>{e.professional}</strong></td>
          </tr>
        )
      }
    })

    return (
      <tbody>
        {stats}
      </tbody>
    )
  },

  renderTableHeaders: function() {
    var headers;
    if (this.props.isCompetition || this.props.isChannel) {
      return (
        <tr>
          <th>
            <h5>Transition</h5>
          </th>
          <th><h5>Average</h5></th>
        </tr>
      )
    } else {
      return (
        <tr>
          <th>
            <h5>Transition</h5>
          </th>
          <th><h5>Total</h5></th>
          <th><h5>Free articles</h5></th>
          <th><h5>Select</h5></th>
          <th><h5>Preferred</h5></th>
          <th><h5>3rd party</h5></th>
        </tr>
      )
    }
  },

  getStyle: function() {
    if (this.props.isChannel) {
      return {tableLayout: 'fixed'}
    }
  },

  render: function() {
    return (
      <table className="editorial-widget" style={this.getStyle()}>
        <thead>
          {this.renderTableHeaders()}
        </thead>
          {this.renderStats()}
      </table>
    )
  }
})

export default EditorialWidgetBody;