var createReactClass = require('create-react-class');
import React from 'react';
import AttachmentInsertMixin from './AttachmentInsertMixin';
import Modal from '../Modal';
import Errors from './Errors';
import Details from './Details';

const InteractiveModel = createReactClass({
  mixins: [AttachmentInsertMixin],
  getInitialState: function(){
    return {
      disableButton: true,
      interactiveModelReupload: false,
      videoReupload: false,
      attachment: this.props.attachment
    }
  },

  componentDidMount: function(){
    var self = this;
    self.setState({attachment: self.props.attachment}, function() {
      self.generateIframe()
      self.generateThumbnail()
    })
    $('#add-figure').on("insertItem", ".primary", function(e){ 
      var elem = CKEDITOR.dom.element.createFromHtml(self.state.attachment.html + "\n")
      try{
        self.setCursorPosition();
        self.removeAttachmentForReinsertion(self.state.attachment.type);
        self.ckeditor().insertElement(elem);
      } catch(e) {
        self.moveCursorToStart();
        self.ckeditor().insertElement(elem);
        self.resetCursorPosition();
      }
      self.ckeditor().updateElement();
      self.disableAttachments();
      Modal.close()
    });
  },

  handleReupload: function(e) {
    var attachmentType = e.target.name === 'videoReupload' ? 'video' : 'interactive_model'
    this.setState({[e.target.name]: true, disableButton: true, [attachmentType]: null, interactiveModelError: null, videoError: null})
  },

  generateIframe: function() {
    var self = this;
    var url = $("#attachment_url").val() || self.state.attachment.sketchfab_link
    var src;
    var error;
    if (url) {
      if (url.includes('sketchfab')) {
        src = "//www.sketchfab.com/models/" + self.grabSketchfabId(url) + '/embed'
      } else {
        error = "Please enter a valid Sketchfab URL."
        src = null
      }
      var newAttachment = Object.assign({}, self.state.attachment)
      newAttachment["sketchfab_link"] = url
      self.setState({attachment: newAttachment, interactiveModel: src, interactiveModelReupload: false, interactiveModelError: error}, function() {
        self.isValidNextButton()
      })
    }
  },

  grabSketchfabId: function(url) {
    if (url.includes('3d-models')) {
      var url_array = url.split('-');
      return url_array[url_array.length - 1]
    }
    var url_array = url.split('/');
    return url_array[url_array.length - 1]
  },

  handleUpdate: function(e, data){
    var self = this;
    self.props.handleUpdate(e, data, function(serializedAttachmentData) {
      self.setState({
        disableButton: true,
        attachment: serializedAttachmentData
      }, function() {$("#add-figure .primary").trigger("insertItem")})
    });
  },

  handleSubmit: function(e) {
    e.preventDefault()
    var self = this;
    self.setState({disableButton: true})
    var payload = {
      attachment: {
        title: self.state.attachment.title,
        legend: self.state.attachment.legend,
        url: self.state.attachment.url,
        sketchfab_link: self.state.attachment.sketchfab_link
      }
    }
    self.handleUpdate(e, payload)
  },

  isValidNextButton: function() {
    var validTitle = ($("#attachment_title").val() !== "") && ($("#attachment_title").val() !== undefined);
    var validInteractiveModel = this.state.interactiveModel
    var validVideo = this.state.video
    var validCharacterCount = this.state.characterCount <= 2000
    this.setValidState((validTitle && validInteractiveModel && validCharacterCount && !this.state.interactiveModelError && validVideo));
  },

  renderErrors: function() {
    if (this.props.errorMessage) {
      return (
        <Errors message={this.props.errorMessage}/>
      )
    }
  },

  renderSketchfabUploader: function() {
    if ((this.state.interactiveModel || this.state.attachment.sketchfab_link) && this.state.interactiveModelReupload === false && !this.state.interactiveModelError) {
      return (
        <div className="media-uploader video">
          <div className="media-preview video" style={{textAlign: 'center'}}>
            <iframe width="400" height="200" src={"https:" + (this.state.interactiveModel || this.state.attachment.sketchfab_link)} frameBorder="0" allowFullScreen></iframe>
            <a className={'button small alert replace-button'} name="interactiveModelReupload" onClick={(e) => this.handleReupload(e)}>Replace</a>
          </div>
        </div>
      )
    } else {
      return (
          <div className="media-uploader video">
            <div className="media-preview video">
            <div className="media-preview-content-frame">
              {this.renderErrors()}
              <p>Insert a Sketchfab. To add the Interactive Model, you'll need to copy and paste its URL.</p>
              <p>Sketchfab URL: <span>&nbsp;{this.state.interactiveModelError}</span></p>
              <input autoComplete="off" id="attachment_url" name="attachment[url]" type="text" defaultValue={ this.state.attachment.interactive_model_link || this.state.attachment.sketchfab_link } className="left" placeholder="Paste the Sketchfab link here, e.g. https://sketchfab.com/3d-models/pterional-model-1-b917bd..." />
              <a className="button primary pull-right" onClick={ this.generateIframe }>Search</a>
            </div>
          </div>
        </div>
      )
    }
  },

  grabYoutubeVideoId: function(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    }
  },

  grabVimeoVideoId: function(url) {
    var match = /vimeo.*\/(\d+)/i.exec(url);
    if ( match ) {
      return match[1];
    }
  },

  grabVideoEmbedLink: function(url) {
    var self = this;
    if (url) {
      if (url.includes('youtu')) {
        return {src: "//www.youtube.com/embed/" + self.grabYoutubeVideoId(url)}
      } else if (url.includes('vimeo')) {
        return {src: "https://player.vimeo.com/video/" + self.grabVimeoVideoId(url)}
      } else {
        return { 
          error: "Please enter a valid YouTube or Vimeo URL.",
          src: null
        }
      }
    }
  },

  generateThumbnail: function() {
    var self = this;
    var url = $("#video_link").val() || self.state.attachment.url
    if (url) {
      var embedObject = self.grabVideoEmbedLink(url)
      } else {
        embedObject = {error: null, src: null}
      }
      var newAttachment = Object.assign({}, self.state.attachment)
      newAttachment["url"] = url
      self.setState({attachment: newAttachment, video: embedObject.src, videoReupload: false, videoError: embedObject.error}, function() {
        self.isValidNextButton()
    })
  },

  renderVideoUploader: function() {
    var url = this.state.attachment.url
    if ((url || this.state.video) && this.state.videoReupload === false && !this.state.videoError) {
      return (
        <div className="media-uploader video">
          <div className="media-preview video" style={{textAlign: 'center'}}>
            <iframe width="400" height="200" src={ this.state.video || this.grabVideoEmbedLink(url).src  } frameBorder="0" allowFullScreen></iframe>
            <a className={'button small alert replace-button'} name="videoReupload" onClick={(e) => this.handleReupload(e)}>Replace</a>
          </div>
        </div>
      )
    } else {
      return (
          <div className="media-uploader video">
            <div className="media-preview video">
            <div className="media-preview-content-frame">
              {this.renderErrors()}
              <p>Insert a video from YouTube or Vimeo. To add the video, you'll need to copy and paste its URL.</p>
              <p>Video URL: <span>&nbsp;{this.state.videoError}</span></p>
              <input autoComplete="off" id="video_link" name="video[url]" type="text" defaultValue={ this.state.attachment.url } className="left" placeholder="Paste the YouTube or Vimeo link here, e.g. https://www.youtube.com/watch?..." />
              <a className="button primary pull-right" onClick={ this.generateThumbnail }>Search</a>
            </div>
          </div>
        </div>
      )
    }
  },


  renderInsertButton: function() {
    var emptyTitle = ($("#attachment_title").val() === "") || ($("#attachment_title").val() === undefined)
    if (this.state.disableButton && emptyTitle) {
      return (
        <span className="tip-top" data-tooltip={this.state.disableButton} id="next-button" title="Please enter a title to proceed. (Scroll down).">
          <a className={'button primary ' + this.disableButtonClass()} data-tooltip={this.state.disableButton} onClick= {this.handleSubmit}>Insert</a>
        </span>
      )
    } else {
      return (
        <a className={'button primary ' + this.disableButtonClass()} onClick={this.handleSubmit}>Insert</a>
      )
    }
  },

  render: function() {
    return (
      <div className="reveal-modal custom-full attachment-modal" data-reveal>
        <div className='modal-content' id='add-figure'>
          <div className='modal-header'>
            <h4 className="reg">Insert Interactive Model</h4>
            <div className='modal-close'>
              <a className="cancel-modal">
                <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
              </a>
            </div>
            <p className="title"> Please review our <a target="_blank" href={this.props.mediaGuidePath + '#3d-models'}>Interactive Model Guidelines</a> for detailed instructions for inserting Interactive Models.</p>
          </div>
          <div className='modal-body video'>
            {this.renderSketchfabUploader()}
            {this.renderVideoUploader()}
            <Details
              isValidNextButton={this.isValidNextButton}
              attachment={this.state.attachment}
              handleCharacterCount={this.handleCharacterCount}
              setValidState={this.setValidState}
              updateKey={this.updateKey}
            />
          </div>
          <div className='modal-footer'>
            <a className='cancel-modal button secondary'>Cancel</a>
            {this.renderInsertButton()}
          </div>
        </div>
      </div>
    ) }
  })

  export default InteractiveModel;