var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';
import LeftSidebar from './LeftSidebar/Sidebar';
import RightSidebar from './RightSidebar/Sidebar';
import NewArticleCard from '../Shared/ContentCards/NewArticleCard';
import Pages from '../Shared/Pagination/Pages';
import FiltersModal from './FiltersModal';
import Modal from '../Modal';
import NewSearch from './NewSearch';
import { isEqual } from 'lodash';
import SortButton from './SortButton';
import ArticleDateSelector from './ArticleDateSelector';
import Ad from '../Shared/Ad';
import Header from './Header';

const Main = createReactClass({
  getInitialState: function() {
    return ({
      advanced: this.props.advanced ? true : false,
      contents: this.props.contents,
      filters: this.props.filters,
      page: 1,
      pageCountOverview: this.props.pageCountOverview,
      spinning: false,
      categories: this.props.categories,
      types: this.props.contentTypes,
      selectedSort: this.props.initialDefaultSortOptions.selectOptions[0],
      selectedArticlesPerPage: this.props.perPage,
      search: this.props.q || '' ,
      paginationData: this.props.paginationData,
      shuffler: null,
      searched: this.props.searched,
      triggerRightSideBar: [0],
      orderDirection: JSON.parse(this.props.initialDefaultSortOptions.selectOptions[0].value).dir,
      sortLabel: this.props.initialDefaultSortOptions.selectOptions[0].label,
      dateRanges: this.props.dateRanges,
      selectedRange: this.props.dateRanges.text,
      fromDate: this.props.dateRanges.from,
      toDate: this.props.dateRanges.to,
      datePickerActive: false,
      categoryIdPreSelected: this.props.categoryIdPreSelected,
      cureusUEmailListIds: this.props.cureusUEmailListIds,
      isSignedIn: this.props.isSignedIn
    })
  },

  componentDidMount: function() {
    this.submitUpdate(true, true)
    this.setState({shuffler: new Cureus.ContentCardShuffler({containerSelector: '#content-results-wrap', maxColumns: 3})});
    if(typeof(this.props.bannerAdTop) !== 'object') {
      $('#banner-ad-1').append(this.props.bannerAdTop)
    }
  },

  shuffleOnLoad: function() {
    this.setState({shuffler: new Cureus.ContentCardShuffler({containerSelector: '#content-results-wrap', maxColumns: 3})});
  },

  updateCategories: function(newCatState) {
    this.setState({categories: newCatState})
  },

  updateTypes: function(newTypeState) {
    this.setState({types: newTypeState})
  },

  updateSort: function(sort) {
    this.setState({selectedSort: sort, page: 1}, this.submitUpdate)
  },

  handleSetState: function(state) {
    this.setState(state)
  },

  updateSearch: function(e, keyword="") {
    if (!keyword) {
      this.setState({search: e.target.value});
    } else {
      this.setState({search: keyword}, this.submitUpdate);
    }
  },

  resetPage: function() {
    this.setState({page: 1}, this.submitUpdate);
  },

  handleSearchEnter: function(e) {
    var self = this;
    if (e.key === 'Enter') {
      self.setState({page: 1, advanced: false, selectedSort: this.defaultSort(), sortLabel: "Relevance", orderDirection: "desc"}, self.submitUpdate);
    }
  },

  defaultSort: function() {
    return {attr: "_score", dir: "desc", text: "Relevance"}
  },


  handleSearchClick: function() {
    var self = this;
    self.setState({
      page: 1,
      advanced: false,
      selectedSort: this.defaultSort(),
      sortLabel: "Relevance",
      orderDirection: "desc",
    }, self.submitUpdate);
  },

  handleAdvancedSearchEnter: function(e, filters) {
    var self = this;
    if (e.key === 'Enter') {
      this.setState({page: 1, filters: this.cleanAdvancedSearchFilters(filters), advanced: true, selectedSort: this.defaultSort(), sortLabel: "Relevance", orderDirection: "desc"}, self.submitUpdate);
    }
  },

  handleAdvancedSearchClick: function(filters) {
    var self = this;
    this.setState({page: 1, filters: this.cleanAdvancedSearchFilters(filters), advanced: true, selectedSort: this.defaultSort(), sortLabel: "Relevance", orderDirection: "desc"}, self.submitUpdate);
  },

  clearAdvancedSearch: function() {
    this.setState({filters: [{boolean: '', field: 'all', query: ''}]})
  },

  cleanAdvancedSearchFilters: function(filters) {
    return filters.reduce(function(res, filter) {
      if (filter.query !== null) {
        filter.query = filter.query.trim()
        if (filter.query.length > 0) {
          res.push(filter);
        }
      }
      return res;
    }, [])
  },

  updatePage: function(page) {
    this.setState({page: page}, this.submitUpdate)
  },

  formatParams: function() {
    var params = {categories: {}, types: {}, page: this.state.page, q: this.state.search, order: this.formatSort(), advanced: this.state.advanced,
    filters: this.state.filters, articles_per_page: this.state.selectedArticlesPerPage, article_index: true};
    if (this.state.datePickerActive) {
      params.from_date = this.state.fromDate;
      params.to_date = this.state.toDate;
      params.date_picker_active = this.state.datePickerActive;
    }
    if(!this.state.categories[0].checked) {
      Object.values(this.state.categories).forEach(function(cat) {
        if(cat.checked) { params.categories[cat.id] = cat.name }
      });
    }
    Object.values(this.state.types).forEach(function(type){
      if (type.checked) { params.types[type.underscore] = type.classType }
    });

    return params;
  },

  formatSort: function() {
    if (this.state.selectedSort.value) {
      let sortHash = JSON.parse(this.state.selectedSort.value)
      return { [sortHash.attr]: sortHash.dir}
    } else {
      let sortHash = this.state.selectedSort
      return { [sortHash.attr]: sortHash.dir}
    }
  },

  submitUpdate: function(skipScroll=false) {
    var self = this;
    if(self.props.articlesPage && !this.props.landing && !skipScroll) {
        setTimeout(function() {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }, 500);
    }
    var path, params;
    path = self.props.filterPath;
    params = self.formatParams();
    self.spin();
    $.ajax({
      method: 'get',
      url: path,
      data: params,
      dataType: 'json',
      success: function(res) {
        self.setState(self.updateContents(res));
      },
      error: function(res) {
      }
    }, 'json');
  },

  mapCards: function() {
    var self = this;
    return self.state.contents.map(function(resource, idx) {
      return self.createCard({resource});
    });
  },

  createCard: function({resource}) {
    return <NewArticleCard  key={resource.id + '_' + resource.type} resource={resource} articlesPage={this.props.articlesPage} page={''} compOrChannel={this.props.compOrChannel}/>
  },

  shuffle: function(){
    var self = this;
    window.setTimeout(function(){
      self.state.shuffler.reshuffle()
      $("html, body").animate({scrollTop: 0});
    }, 750);
  },

  updateContents: function(data) {
    var self = this;
    var contents = data.contents.data === undefined ? data.contents : data.contents.data
    self.setState({contents: contents.map(function(obj) {
      if(obj.type && obj.type !== 'object') {
        return obj
      } else {
        if (obj.attributes !== undefined) {
          return obj.attributes
        } else {
          return obj.data.attributes
        }
      }
    }), page: data.page, pageCountOverview:   data.pageCountOverview, spinning: false, paginationData: data.paginationData, triggerRightSideBar: self.updateTriggerRightSideBar()}, self.shuffle);
  },

  selectedCategoryIds: function() {
    if (this.props.categoryIdPreSelected) { return [this.props.categoryIdPreSelected] }
    let categoryValues = Object.values(this.state.categories)
    let selectedCategories = categoryValues.filter( (cat) => { return cat.checked === true})
    return selectedCategories.map( (cat) => {return cat.id}).sort()
  },

  updateTriggerRightSideBar: function() {
    if (this.props.categoryIdPreSelected) { return this.state.triggerRightSideBar}

    if (isEqual(this.selectedCategoryIds(), this.state.triggerRightSideBar)) {
      return this.state.triggerRightSideBar
     } else {
      return this.selectedCategoryIds()
     }
  },

  spin: function() {
    this.setState({spinning: true })
  },

  renderSpinner: function() {
    return (
      <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>
    )
  },

  renderPageCountOverview: function() {
    return (
      <div className='large-11 medium-12 columns small-centered content-index-results-count new-articles-results'>
        {this.state.pageCountOverview}
        <div className='article-count-selector-container'>
          <label htmlFor="article-select">Show</label>
          <select id="article-select" value={this.state.selectedArticlesPerPage} onChange={this.handleCountChange}>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
    )
  },

  handleCountChange: function(e) {
    this.setState({selectedArticlesPerPage: e.target.value}, this.submitUpdate);
  },

  renderCureusContents: function() {
    return(
      <div className='row'>
        <div className='large-12 small-12 columns'>
          <div className='content-index-results-columns' id='content-results-wrap'>
            <div>
              {this.mapCards(false)}
            </div>
          </div>
        </div>
      </div>
    )
  },

  renderCureusBody: function() {
    if (this.state.spinning) {
      return this.renderSpinner();
    } else {
      return this.renderCureusContents();
    }
  },

  handlePageUpdate: function(page) {
    this.setState({page: page}, function(){this.child.updatePage(page)});
  },

  renderFiltersModal: function() {
    Modal.open({
			children: <FiltersModal
        updateTypes={this.updateTypes}
        updateCategories={this.updateCategories}
        categories={this.props.categories}
        contentTypes={this.props.contentTypes}
        submitUpdate={this.submitUpdate}
        categoryIds={this.props.categoryIds}
        categoryIdPreSelected={this.props.categoryIdPreSelected}
      />
		});
  },

  renderSorts: function() {
    return this.props.sorts.selectOptions.map((option, idx) => {
      return (
        <SortButton
          key={idx}
          sortObj={option}
          updateSort={this.updateSort}
          handleSetState={this.handleSetState}
          selectedSortState={this.state.sortLabel}
          orderDirection={this.state.orderDirection}
        />
      )
    })
  },

  handleDateRangeSubmit: function(fromDate, toDate, selectedRange, isActive) {
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
      selectedRange: selectedRange,
      datePickerActive: isActive
    }, this.submitUpdate)
  },

  renderTimePeriodSelector: function() {
    return (
      <ArticleDateSelector
        fromDate={this.state.fromDate}
        toDate={this.state.toDate}
        handleDateRangeSubmit={this.handleDateRangeSubmit}
        selectedRange={this.state.selectedRange}
        datePickerActive={this.state.datePickerActive}
      />
    )
  },

  renderCureusIndex: function() {
    return(
      <div className='row collapse'>
        <div className='large-11 medium-12 columns small-centered'>
          <div className="large-6 medium-12 small-12 columns small-centered end sort-options-container">
            {this.renderSorts()}
            {this.renderTimePeriodSelector()}
            {this.renderPageCountOverview()}
          </div>
        </div>
        <div className='new-content-index-wrap' id='filter-results'>
          {this.renderCureusBody()}

          <div className='content-index-pagination'>
            <Pages
              paginationData={this.state.paginationData}
              handlePageSelect={this.updatePage}
              pagesType={"article"}
            />
          </div>
        </div>
      </div>
    )
  },

  renderFilterAndSearch: function() {
    return (
      <div className="show-for-medium-down">
        <div className="button white-bg mobile-filter-button" onClick={this.renderFiltersModal}>
          <img src="https://public.cureus.com/browse-article/filter-icon.png" />
          <span>Filter & Search</span>
        </div>
      </div>
    )
  },
  renderBannerAd: function(props, id) {
    if(typeof(props) === 'object') {
      return (
        <div id={id}>
          <div className='cureus-ad-wrapper page-banner-ad' >
            <Ad
              props={ props }
              size={ "banner" }
            />
          </div>
        </div>
      )
    } else {
      return (
        <div id={id}/>
      )
    }
  },

  render: function() {
    return (
      <div className="new-articles-index">
        <div className='row small-collapse'>
          <LeftSidebar
            updateTypes={this.updateTypes}
            updateCategories={this.updateCategories}
            categories={this.props.categories}
            contentTypes={this.props.contentTypes}
            submitUpdate={this.submitUpdate}
            categoryIds={this.props.categoryIds}
            categoryIdPreSelected={this.props.categoryIdPreSelected}
            id={"large-view"}
          />
          <div className='large-8 medium-12 small-12 columns end'>
            {this.renderBannerAd(this.props.bannerAdTop, 'banner-ad-1')}
            <div className='large-11 medium-12 columns small-centered search-container browse-article-header-container'>
              <Header
                pageTitle={this.props.pageTitle}
                categoryIdPreSelected={this.props.categoryIdPreSelected}
                cureusUEmailListIds={this.state.cureusUEmailListIds}
                isSignedIn={this.state.isSignedIn}
              />
              {this.renderFilterAndSearch()}

            </div>
            <NewSearch
              typesRequired={this.typesRequired}
              updateSearch={this.updateSearch}
              handleSearchEnter={this.handleSearchEnter}
              handleAdvancedSearchEnter={this.handleAdvancedSearchEnter}
              handleSearchClick={this.handleSearchClick}
              handleAdvancedSearchClick={this.handleAdvancedSearchClick}
              articlesPage={this.props.articlesPage}
              advanced={this.state.advanced}
              search={this.state.search}
              clearAdvancedSearch={this.clearAdvancedSearch}
              selectedCategoryIds={this.selectedCategoryIds}
            />
            <div className='search-results-wrap'>
              {this.renderCureusIndex()}
            </div>
          </div>
          <RightSidebar
            selectedCategoryIds={this.selectedCategoryIds()}
            triggerRightSideBar={this.state.triggerRightSideBar}
            articleCount={this.state.paginationData.totalItems}
            contents={this.state.contents}
            updateSearch={this.updateSearch}
            categoryIdPreSelected={this.props.categoryIdPreSelected}
          />
        </div>
      </div>
    )
  }
});

export default Main;