var createReactClass = require('create-react-class');
import React from 'react';

const EmailListModal = createReactClass({
  render: function() {
    return(
      <div className="reveal-modal" data-reveal="true" id="email-list-modal">
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal" href="#"><img src="/assets/close_x-228574b19e98d3294d2c9a3d8c5c41f9c794e554f6f5aa0d16d719d18316e8d7.png" alt="Close x"/>
            </a>
          </div>
          <div className="modal-content">
            <h5>Select Email Lists Below</h5>
            <div className="row">
              <div className="columns large-6">
                {this.props.renderAllCureusUsersCheckbox()}
              </div>
            </div>
            <div className="row">
              {this.props.renderCheckBoxes()}
            </div>
            <div className="modal-footer">
              <a className="button secondary" onClick={this.props.handleClear} href="#">Clear All</a>
              <a className="button primary cancel-modal" href="#">Submit</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default EmailListModal;