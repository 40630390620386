var createReactClass = require('create-react-class');
import React from 'react';

const DeleteModal = createReactClass({
  getInitialState: function() {
    return {
      reason: this.props.reason,
      label: this.props.reason.label,
    }
  },

  deleteReason: function() {
    $.ajax({
      type: 'delete',
      url: this.props.deletePath,
      dataType: 'json',
      success: function(res){
        window.location.reload()
      },
      error: function(res) {
        Cureus.Flash.alert('There was an issue, please try later.')
      }
    })
  },
  
  render: function() {
    return (
      <div className="reveal-modal middling" data-reveal id="edit-deferral-reason">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className="modal-content middling">
            <h4>Are you sure you want to delete this?</h4>
           <p>Deleting this deferral reason cannot be undone. How do you want to proceed?</p>
          </div>
          <div className='modal-footer'>
            <a className='cancel-modal button small secondary'>Cancel</a>
            <a className="button small primary alert" onClick={this.deleteReason}>Delete Deferral Reason</a>
          </div>
        </div>
      </div>
    )
  }
});

export default DeleteModal;