var createReactClass = require('create-react-class');
import React from 'react';
import DeleteListEmailsModal from './DeleteListEmailsModal';
import SubscribedFilter from './SubscribedFilter';
import AddDropdown from './AddDropdown';
import Search from './Search';
import Table from './Table';
import Modal from '../Modal';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const ViewableTable = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("Table")
  ],

  getInitialState: function() {
    return {
      subscribed: true
    }
  },

  getStateFromFlux: function() {
    return this.getFlux().store("Table").getState();
  },

  handleDelete: function() {
    Modal.open({
      children: <DeleteListEmailsModal
        title={this.props.title}
        subscribed={this.state.subscribed}
        deleteListEmailsPath={this.props.deleteListEmailsPath}
        flux={this.getFlux()}
      />
    });
  },

  handleSubscribeFilterChange: function(subscribed) {
    this.setState({subscribed: subscribed});
    this.getFlux().actions.deselectAll();
  },

  render: function() {
    var deleteButton;
    deleteButton = <div>&nbsp;</div>;
    if(this.state.selectedCount > 0) {
      deleteButton = <a onClick={this.handleDelete} className='button secondary'>Delete</a>
    }

    return (
      <div>
        <div className='email-list-datatable-header'>
          <div className='row'>
            <div className='large-1 columns'>
              {deleteButton}
            </div>

            <div className='large-2 columns'>
              <SubscribedFilter onChange={this.handleSubscribeFilterChange} subscribed={this.state.subscribed} />
            </div>

            <div className="large-4 columns">
              <Search
                search={this.props.dataSource}
               />
            </div>

            <div className="large-3 large-offset-2 columns">
              <AddDropdown
                handleFileUpload={this.props.handleFileUpload}
                handleAddFromExisting={this.props.handleAddFromExisting}
                addFromCureusPath={this.props.addFromCureusPath}
                handleAddFromPmc={this.props.handleAddFromPmc}
              />
            </div>
          </div>
        </div>

        <Table
          dataSource={this.props.dataSource}
          subscribed={this.state.subscribed}
          className={this.props.className}
          columns={ this.props.columns}
        />
      </div>
    )
  }
});

export default ViewableTable;