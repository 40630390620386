import React, { useState, useRef, useEffect } from 'react';
import HistoryModal from '../../History/HistoryModal';
import Modal from '../../Modal';
import {Cureus} from '../../../Cureus';

const ReviewsComplete = ({renderChevronContainer, path, initialData, createPath, templatePath, isParent, renderMailIcon}) => {

  const [expanded, setExpanded] = useState(false);
  const [tableData, setTableData] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    fetchData();
  }, [expanded])

  const setColumns = () => {
    if(isParent) {
      return [
        {width: '35px'},
        {width: '160px'},
        null,
        {width: '160px'},
        {width: '160px'},
        null,
        {width: '160px', class: 'centered'}
      ]
    } else {
      return [
        {width: '35px'},
        {width: '160px'},
        null,
        {width: '160px'},
        null,
        {width: '160px', class: 'centered'}
      ]
    }
  }

  const mapColumns = (dt, row) => {
    if(isParent) {
      return (
        dt.fnAddData( [
          row.checkbox,
          row.satisfied_peer_review_on,
          row.sort_column,
          row.channel_name,
          row.submitting_author,
          row.title,
          row.reminder_history
      ], false)
      )
    } else {
      return (
        dt.fnAddData( [
          row.checkbox,
          row.satisfied_peer_review_on,
          row.sort_column,
          row.submitting_author,
          row.title,
          row.reminder_history
      ], false)
      )
    }
  }

  const setSorting = () => {
    if(isParent) {
      return {orderable: false, targets: [0, 6]}
    } else {
      return {orderable: false, targets: [0, 5]}
    }
  }

  const initEmailReminderFeature = () => {
    Cureus.Admin.MultipleReminders.init({
      btnSelector: "#reviews-complete-row .bulk-email-button",
      noNavSelector: true,
      tabPaneSelector: "#reviews-complete-datatable-container",
      templateName: "ReviewsComplete",
      templatePath: templatePath,
      createPath: createPath,
      async: true
    });
  }

  const removeClickHandlerFromEmailButton = () => {
    // Need this ownerwise Cureus.Admin.MultipleReminders will cause duplicate modals to open at the same time.
    $("#reviews-complete-row .bulk-email-button").off('click')
  }

  const populateTable = (fetchedTableData) => {
    var dt = $("#reviews-complete-table").DataTable({
      columns: setColumns(),
      columnDefs: [
        {targets: [1], orderData: [2]},
        {targets: [2], visible: false},
        setSorting()
      ],
      searching: false,
      dom: '<"row">tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "bAutoWidth": false,
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
        removeClickHandlerFromEmailButton()
        initEmailReminderFeature()
      },
      "rowCallback": function(row) {
        let element = isParent ? row.children[5].children[0] : row.children[4].children[0]
        element.addEventListener('click',function() {
          openHistoryModal(element.dataset.articleId, element.dataset.authorshipId)
        })
      }
    })
    
    dt.api().clear();
    for (var i = 0; i < fetchedTableData.articles.length; i++) {
      let row = fetchedTableData.articles[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[2, 'desc']])
    dt.fnDraw()
    $(document).foundation('reflow');
  }

  const fetchData = () => {
    if(tableData) {
      populateTable(tableData)
      return;
    }

    $.ajax({
      type: 'GET',
      url: path,
      data: {state: 'ReviewsComplete'},
      success: function(data) {
        setTableData(data)
        populateTable(data)
      }
    });
  }

  const openHistoryModal = (articleId, authorshipReviewershipId) => {
    Modal.open({
      children: <HistoryModal
          isAuthorship={true}
          articleId={articleId}
          authorshipReviewershipId={authorshipReviewershipId}
        />
    });
  }

  const renderHeaderRow = () => {
    return (
      <div className="row article-state-row" id="reviews-complete-row">
        <div className="small-1 columns bubble-container has-tip tip-top" data-tooltip="" id="bubble-tooltip-editor-preview" title={initialData.tooltip}>
          <img src={`https://public.cureus.com/channel-icons/${initialData.color}-bubble.png`} />
          <span className="bubble-text">{initialData.total}</span>
        </div>
        <div className="small-9 columns article-state-label">
          <h4>{initialData.label}</h4>  
          <span>{initialData.blurb}</span>
        </div>
        <div className="small-2 columns chevron-container">
          {renderChevronContainer(expanded, setExpanded, 'chevron-toggle-with-mail-icon')}
          {expanded && renderMailIcon("reviews-complete")}
        </div>
      </div>
    )
  }

  const renderChannel = () => {
    if(isParent) {
      return <th>Channel</th>
    }
  }

  return (
    <React.Fragment>
      <div className="article-state-row-container">
        {renderHeaderRow()}
        {expanded && 
          <div className="channel-articles-datatable-container" id="reviews-complete-datatable-container">
            <table id="reviews-complete-table">
              <thead>
                <tr>
                  <th><input type="checkbox" /></th>
                  <th>Reviews complete date</th>
                  <th></th>
                  {renderChannel()}
                  <th>Submitting author</th>
                  <th>Title</th>
                  <th>Reminder history</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        }        
      </div>

    </React.Fragment>
  )
}

export default ReviewsComplete;