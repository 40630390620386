import React, {useState} from 'react';
import Modal from '../Modal';

const AddFromPmcModal = ({ emailListId, createPmcListPath }) => {

  var csrfParam = $("[name='csrf-param']").attr("content");
  var csrfToken = $("[name='csrf-token']").attr("content");

  const [title, setTitle] = useState("");
  const [words, setWords] = useState("");
  const [fromDate, setFromDate] = useState("2012-01-01");
  const [email, setEmail] = useState("");

  function submit(e) {
    e.preventDefault();
    $.ajax({
      type: "POST",
      url: createPmcListPath,
      data: {
        list: listParams()
      },
      success: function() {
        window.location.reload(); 
      }
    })
  }

  function listParams() {
    return {
      title: title,
      notify_email: email,
      from_date: fromDate,
      list_words_attributes: words.split(","),
      email_list_id: emailListId,
      refresh: true
    }
  }

  return (
    <div className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <div className='modal-form'>
            <form acceptCharset='UTF-8' className='custom' method='post'>
              <input name="utf8" type="hidden" value="✓" />
              <input type="hidden" name={ csrfParam } value={ csrfToken } />

              <label htmlFor='title'>List Title:</label>
              <input type='text' id='title' onChange={(e) => setTitle(e.target.value)} className='input-group text' value={title} placeholder='Title' />

              <label htmlFor='words'>
                Search Criteria:
                <br/>
                <small>Separate search terms by commas, any whitespace will be included with a comma separated phrase. This is case insensitive.</small>
              </label>
              <input type='text' id='words' onChange={(e) => setWords(e.target.value)} className='input-group text' value={words} placeholder='heart,Lungs, ENT,skin' />

              <label htmlFor='from_date'>From Date:</label>
              <input type='date' id='from_date' onChange={(e) => setFromDate(e.target.value)} className='input-group date' value={fromDate}/>
          
              <label htmlFor='notify_email'>
                Notify Email:
                <br/>
                <small>Optional notification via email when list is finished. Alternatively you can periodically check the lists index. </small>
              </label>
              <input type='email' id='notify_email' onChange={(e) => setEmail(e.target.value)} className='input-group email' value={email} placeholder='email@email.com'/>

              <div className='modal-footer'>
                <a className='cancel-modal button secondary'>Cancel</a>
                <input type='submit' value="Extract from PMC" onClick={ submit } className='button primary'/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )

}

export default AddFromPmcModal;