var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../../Cureus';

const AbandonModal = createReactClass({
  componentDidMount: function() {
    Cureus.DisableWithATag.enable();
  },

  render: function() {
    return(
      <div className="reveal-modal middling" id={"abandon-modal-" + this.props.article.id} data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Are you sure you want to delete this article?</h4>
            <p>
              <strong>Deleted articles cannot be resubmitted as a new draft.</strong> If this article was deferred for any reason (revisions, Preferred Editing, or third party editing required), you must resubmit this draft in order to proceed. A new draft will not be accepted.
            </p>
          </div>

          <div className="modal-footer">
            <a className="button secondary cancel-modal" id={"close-abandon-article-reveal-modal-button-" + this.props.article.id}> Keep Article </a>
            <a href={"/publish/articles/" + this.props.article.id + "/abandon"} className="button alert" data-method="put" data-disable-with="Deleting..."><i className="far fa-trash-alt"/> Delete article </a>
          </div>
        </div>
      </div>
    )
  }
})

export default AbandonModal;


