var createReactClass = require('create-react-class');
import React from 'react';
import ConfirmationModal from '../ConfirmationModal';
import Modal from '../Modal';

const ArticleListItem = createReactClass({
  getInitialState: function() {
    return ({
      summary: this.props.article.summary
    })
  },

  removeArticle: function(articleId) {
    var self = this;
    return function() {
      self.props.removeArticle(articleId);
    }
  },

   removeArticleConfirmationModal: function() {
    Modal.open({
      children: <ConfirmationModal
                  handleSubmit= {this.removeArticle(this.props.article.id)} />
    })
  },

  onChange: function(e) {
    this.setState({summary: e.target.value})
  },

  render: function() {
    return (
      <li className='article-list-item row drag-handle' >
        <div className="row">
          <div className='small-5 columns' >
            <div dangerouslySetInnerHTML= {{ __html: this.props.article.thumbnail }} />
          </div>
          <div className='small-7 columns'>
            <p>Article ID: {this.props.article.id}</p>
            <p>{this.props.article.title}</p>
          </div>
        </div>
        <br></br>
        <input id="campaign_campaign_articles_attributes_summary" name="campaign[campaign_articles_attributes][][summary]" value={this.state.summary} placeholder="Optional Summary" type="text" onChange={(e) => this.onChange(e)}/>
        <div className='small-7 columns end right'>
          <input type='button' onClick={this.removeArticleConfirmationModal} className="button small secondary pull-right" value='remove'/> 
        </div>
        <input type='hidden' name={"campaign_articles[" + this.props.article.id +"]"} value={this.props.article.id} />
        <input id="campaign_campaign_articles_attributes_id" name="campaign[campaign_articles_attributes][][id]"
          value={this.props.article.campaignArticleId ? this.props.article.campaignArticleId : ''} type="hidden"/>
        <input id="campaign_campaign_articles_attributes_article_id" name="campaign[campaign_articles_attributes][][article_id]"
          value={this.props.article.id} type="hidden"/>
        <input id="campaign_campaign_articles_attributes_position" name="campaign[campaign_articles_attributes][][position]"
          value={this.props.position} type="hidden"/>
          <hr/>
      </li>
    )
  }
});

export default ArticleListItem;