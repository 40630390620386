import React, { useState } from 'react';
import Carat from "./Carat"

const Keywords = ({keywords, fireAmplitude}) => {
  const [showKeywords, setShowKeywords] = useState(false)

  const renderKeywords = () => {
    return keywords.map( (word, idx) => {
      let query = word.split(" ").join("+")
      return (
        <React.Fragment key={idx}>
          <a onClick={() => fireAmplitude('articleKeywordClicked', {source: 'article sidebar', keyword: word})} className="keyword" target="_blank" href={`/articles?page=1&q=${query}&order=%7B%22attr%22%3A%22_score%22%2C%22dir%22%3A%22desc%22%2C%22text%22%3A%22Relevance%22%7D&advanced=true&filters%5B0%5D%5Bboolean%5D=&filters%5B0%5D%5Bfield%5D=keywords&filters%5B0%5D%5Bquery%5D=${query}`}>#{word}</a>
          {idx < keywords.length - 1 && <span key={`${idx}-span`}>, </span>}
        </React.Fragment>
      )

    })
  }

  return (
    <div className="left-sidebar-row">
      <div className="collapsible" onClick={() => {setShowKeywords(!showKeywords)}}>
        <h1>Keywords</h1>
        {<Carat showNav={showKeywords} setShowNav={setShowKeywords}/>}
      </div>

      <ul className='keywords no-margin'>
        {showKeywords && renderKeywords()}
      </ul>
    </div>
  )
}

export default Keywords