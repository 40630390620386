var createReactClass = require('create-react-class');
import React from 'react';
import Table from './Table';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const FilterableTable = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("FilterTool")
  ],

  getStateFromFlux: function() {
    return this.getFlux().store("FilterTool").getState();
  },

  render: function (){
    return (
      <Table 
        filters={this.state.filters} 
        dataSource={this.props.dataSource} 
        subscribed={this.props.subscribed} />
    )
  }
});

export default FilterableTable;