import React, { useEffect } from 'react';
import { Cureus } from '../../Cureus';

const Reviewership = ({ reviewership }) => {

  useEffect(() => {
    $(document).foundation('reflow');
    Cureus.SplitButton.init();
    $('body').on('mousedown touchstart', function(e) {
      let ele = document.querySelector('.has-tip.open')
      let href = e.target.href
      if(href) {
        setTimeout(function() {
          closeTooltip(ele)
        }, 100)
      } else {
        closeTooltip(ele)
      }
    });
  }, [])

  const closeTooltip = (ele) => {
    if(!ele) {
      return;
    }
    ele.classList.remove('open')
    document.getElementById(ele.dataset.selector).style.display = 'none'
  }

  const renderPrimaryActionButton = () => {
    if(reviewership.state === 'invited') {
      return (
        <a className="button preview-button" href={reviewership.acceptPath}><i class="fas fa-file-medical"/>Accept review</a>
      )
    } else if(reviewership.state === 'reinvited') {
      return (
        <a className="button preview-button" href={reviewership.acceptRereviewPath}><i class="fas fa-file-medical"/>Accept re-review</a>
      )
    } else if(reviewership.state === 'reviewing') {
      return (
        <a className="button preview-button" href={reviewership.continuePath}><i class="fas fa-user-edit"/>Continue my review</a>
      )
    } else if(reviewership.state === 'rereviewing') {
      return (
        <a className="button preview-button" href={reviewership.continuePath}><i class="fas fa-user-edit"/>Continue my re-review</a>
      )
    } else if(reviewership.state === 'completed' || reviewership.state === 'expired') {
      if(reviewership.articlePublished) {
        return <a className="button preview-button" href={reviewership.articlePath} target="_blank"><i class="fa fa-eye"/>View in journal</a>
      } else {
        return (
          <a className="button preview-button" href={reviewership.continuePath} target="_blank"><i class="fa fa-eye"/>View my comments</a>
        )
      }
    }
  }

  const renderHamburgerMenu = (small) => {
    var  listItems;
    if(reviewership.state === 'invited') {
      listItems = (
        <li>
          <a className="dropdown-link" href={reviewership.declinePath}>Decline review</a>
        </li>
      )
    } else if(reviewership.state === 'reviewing' || reviewership.state === 'rereviewing') {
      listItems = (
        <li>
          <a className="dropdown-link has-tip tip-left" data-tooltip="" id={"flag-dropdown-" + reviewership.id} title="Articles should only be flagged if you suspect academic fraud or dangerous or misleading data." data-reveal-id={"flag-article-" + reviewership.articleId}>Flag this article</a>
        </li>
      )
    } else if(reviewership.state === 'reinvited') {
      listItems = <a className="dropdown-link" href={reviewership.declineRereviewPath}>Decline re-review</a>
    } else if(reviewership.state === 'completed' || reviewership.state === 'expired') {
      if (!reviewership.articlePublished) {
        return;
      }
      listItems = (
        <li>
          <a className="dropdown-link has-tip tip-left" data-tooltip="" id={"version-dropdown-" + reviewership.id} title="View the comments you've made on the article in our reviewing tool." href={reviewership.continuePath}>View versions</a>
        </li>
      )
    }
    return (
      <div className="cureus-split-dropdown-wrap" style={{display: 'inline'}}>
        <a className={"secondary-button menu"} data-cureus-split-dropdown={"rev-menu-" + reviewership.id + small}>
          <span><img src="https://public.cureus.com/dashboard-menu.png"/></span>
        </a>
      <ul id={"rev-menu-" + reviewership.id + small} className="cureus-split-dropdown new-dash">
        {listItems}
      </ul>
    </div>
    )
  }


  const renderBanner = () => {
    if(reviewership.flashMessage) {
      return (
        <div className="row">
          <div className="small-12 columns dashboard-list-item-wrap article-banner-container">
            <div dangerouslySetInnerHTML={{__html: reviewership.flashMessage}}/>
          </div>
        </div>
      )
    }
  }

  const renderAuthors = () => {
    return (
      <div className="row" style={{marginTop: '24px'}}>
        <div className="small-2 medium-1 large-1 columns" style={{paddingTop: '2px'}}>
          <h6 className="authors-header">AUTHORS</h6>
        </div>
        <div className="small-10 medium-11 large-11 columns author-links-container reviews" style={{paddingLeft: '30px'}}>
          <div dangerouslySetInnerHTML={{__html: reviewership.authorListLinksMarkup}}/>
        </div>
      </div>
    )
  }

  const renderSubmittingAuthor = () => {
    return (
      <React.Fragment>
        <div className="row submitting-author-container">
          <div className="small-2 medium-1 large-1 columns">
            <div className="avatar">
              <img src={reviewership.submitterAvatar}/>
            </div>
          </div>
          <div className="small-10 medium-11 large-11 columns" style={{paddingLeft: '30px'}}>
              <div>
                <h6>{reviewership.submitterName}</h6>
                <a href={reviewership.submittingAuthorPath} target="_blank" className="submitting-author">Submitting author</a>
              </div>
              <span>{reviewership.submitterAff}</span>
          </div>
        </div>
      </React.Fragment>
    )
  }

  const renderType = () => {
    return (
      <div className="type-container">
        <span className="type">{reviewership.articleType}</span>
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <div className="title">
        <h5 className="title reg"><a target="_blank" href={reviewership.titlePath}>{reviewership.title}</a></h5>
      </div>
    )
  }

  const renderBadge = () => {
    return (
      <div className="row" style={(!reviewership.articlePublished && (reviewership.state === 'completed' || reviewership.state === 'expired')) ? {marginTop: '18px'} : {}}>
        <div className="small-12 columns">
          <div className="state-with-badge" dangerouslySetInnerHTML={{__html: reviewership.stateWithBadge}}/>
          <span className="snapshot-version">{reviewership.version}</span>
        </div>
    </div>
    )
  }

  const smallView = () => {
    return (
      <div className="small-12 columns bordered article-box show-for-small-only">
        <div className="row title-container">
          <div className="small-12 columns">
            {renderTitle()}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            {renderType()}
          </div>
        </div>
        <div className="small-view-container">
          {renderBadge()}
        </div>
        <div className="row">
          <div className="small-view-container">
            <div className="small-12 columns text-left">
              <div className="small-12 columns no-padding">
                {renderPrimaryActionButton()}
              </div>
              <div className="small-12 columns no-padding" style={{marginTop: '11px'}}>
                {renderHamburgerMenu('-small')}
              </div>
            </div>
          </div>
        </div>
        {renderBanner()}
        {renderAuthors()}
        {renderSubmittingAuthor()}
      </div>
    )
  }

  return (
    <React.Fragment>
      {smallView()}
      <div className="small-12 columns bordered article-box hide-for-small">
        <div className="row title-container">
          <div className={"large-8 medium-8 small-8 columns"}>
            {renderTitle()}
          </div>
          <div className={"large-4 medium-4 small-4 columns text-right"}>
            {renderPrimaryActionButton()}
          </div>
        </div>
        <div className="row">
          <div className="large-9 medium-8 small-6 columns">
            {renderType()}
          </div>
          <div className="large-3 medium-4 small-6 columns text-right no-padding">
            {renderHamburgerMenu('-large')}
          </div>
        </div>
        {renderBadge()}
        {renderBanner()}
        {renderAuthors()}
        {renderSubmittingAuthor()}
      </div>
    </React.Fragment>
  )
}

export default Reviewership;