var createReactClass = require('create-react-class');
import React from 'react';

const AddDropdown = createReactClass({
  handleChange: function (val){
    var self;
    
    self = this;

    switch(val){
      case 'ADD_VIA_UPLOAD': 
        this.props.handleFileUpload();
        break;
      case 'ADD_FROM_EXISTING': 
        this.props.handleAddFromExisting();
        break;
      case 'ADD_FROM_CUREUS': 
        window.location.replace(this.props.addFromCureusPath);
        break;
      case 'ADD_FROM_PMC_EXTRACTOR':
        this.props.handleAddFromPmc();
        break
      default:
        throw new Error("Invalid option selected from Add From ____ droplist");
    }
  },

  optionsForSelectList: function (){
    return [
      { label: "Add via upload", value: 'ADD_VIA_UPLOAD'},
      { label: "Add from existing list", value: 'ADD_FROM_EXISTING'},
      { label: "Add from Cureus users", value: 'ADD_FROM_CUREUS'},
      { label: "Add from PMC Extractor", value: 'ADD_FROM_PMC_EXTRACTOR'}
    ]
  },

  render: function (){
    return (
      <Select
        placeholder={"Add subscribers"}
        className='add-recipients-select'
        options={this.optionsForSelectList()}
        onChange={this.handleChange}
        clearable={ false }
        searchable={ false } />
    )
  }
});

export default AddDropdown;
