import React from 'react';

const FaqItem = ({id, question, answer, handleDelete, handleChange}) => {

  const handleQuestionChange = (e) => {
    handleChange(id, 'question', e.target.value);
  };

  const handleAnswerChange = (e) => {
    handleChange(id, 'answer', e.target.value);
  };

  return (
    <div className="faq">
      <div className="question-holder">
        <div>
          <textarea className='faq-question' value={question} onChange={handleQuestionChange
          }></textarea>
        </div>
        <div>
          <textarea className='faq-answer' value={answer} onChange={handleAnswerChange
          }></textarea>
        </div>
      </div>
      <div className='faq-delete-container'>
        <a className='alert small button delete-button'
        data-confirm='Are you sure you want to delete this question?'
        onClick={() => handleDelete(id)}>DELETE</a>
      </div>
    </div>
  )
}

export default FaqItem;