import React from 'react';

const FrequentlyAskedQuestions = () => {

  return (
    <React.Fragment>
      <h1>Frequently Asked Questions</h1>
  
      <h2>Can I submit my own article to my collection?</h2>
      <p>Yes, the guest editor and co-editors are welcome to submit their own articles to their collection, provided the articles fit the collection theme. However, your collection should not be limited to articles authored by the guest editors and co-editors. </p>

      <h2>Can I add my articles already published in Cureus to my collection?</h2>
      <p>Yes, but they must fit the theme of your collection. You will also be able to add articles that your co-editors previously published in Cureus.</p>
      
      <h2>Can I add someone else’s article to my collections?</h2>
      <p>No, you can only add articles authored by the guest editor or co-editor to your collection. Authors will determine whether to submit to your collection during the article submission process. These articles will then be queued for your review prior to publication.</p>

      <h2>An article submitted to my collection was published before I could review it for inclusion. Can it still be included in my collection?</h2>
      <p>Of course! We understand that you may not have time to review all article submissions before they are published. If an article submitted to your collection is published before guest editor or co-editor review, it will be added to the collection upon approval.</p>

      <h2>Can I edit my collection after it’s published?</h2>
      <p>Published collections cannot be edited or revised. However, the guest editor can close the submission window when desired.</p>

      <h2>Can I be a guest editor for multiple collections?</h2>
      <p>Yes, but please make sure that you can commit to providing timely oversight and article review for your collections.</p>
    </React.Fragment>
  )
}

export default FrequentlyAskedQuestions;