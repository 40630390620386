import React, { useEffect, useState } from 'react';
import DateSelector from '../StatsWidget/DateSelector';
import Summary from './Summary';
import DetailedPerformance from './DetailedPerformance';
import TemplateFilter from '../EmailCampaignIndex/TemplateFilter';
import { Cureus } from '../../Cureus';

const Main = ({pageTitle, dataPath, dateRanges, templateFilters}) => {

  const [startDate, setStartDate] = useState(dateRanges[0].from);
  const [endDate, setEndDate] = useState(dateRanges[0].to);
  const [selectedRange] = useState(dateRanges[0].label);
  const [compareStartDate, setCompareStartDate] = useState(null);
  const [compareEndDate, setCompareEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [sortColumn, setSortColumn] = useState("c.sent_at");
  const [sortDirection, setSortDirection] = useState('desc');
  const [data, setData] = useState({
    data: [],
    paginationData: {},
    summary: {},
    deltas: {}
  });


  useEffect(() => { loadData() }, [page, compareStartDate, compareEndDate, sortColumn, sortDirection]);

  function loadData() {
    var spinner = $("#spinner");
    spinner.spin();
    $.ajax({
      type: "GET",
      dataType: 'json',
      url: dataPath,
      data: { startDate: startDate, endDate: endDate, query: query, page: page, compareStartDate: compareStartDate, compareEndDate: compareEndDate, sortColumn: sortColumn, sortDirection: sortDirection, templates: getTemplates() },
      success: function(data) {
        setData(data);
        spinner.spin(false);
      }
    })
  }


  function getTemplates() {
    var templates = []
    $("input.template:checked").each(function() {
      templates.push($(this).val())
    })
    return templates;
  }

  function handleDateRangeSelect(fromDate, toDate, selectedRange) {
    setStartDate(fromDate);
    setEndDate(toDate);
  }

  function handleQueryChange(evt) {
    setQuery(evt.target.value);
  }

  function handleSearch() {
    setPage(1);
    loadData();
  }

  function handleSort(column) {
    var direction = sortDirection
    if (column != sortColumn) {
      direction = "desc"
    } else {
      direction = direction == "desc" ? "asc" : "desc"
    }
    setSortColumn(column);
    setSortDirection(direction);
  }

  return (
    <div className='email-performance'>
      <div id="spinner">
        <div className='header'>
          <span className='title'>{pageTitle}</span>
          <span className='count-label'>{data.paginationData.totalItems}</span>
          <DateSelector
            from={startDate}
            to={endDate}
            getStats={handleDateRangeSelect}
            selectedRange={selectedRange}
            dateRanges={dateRanges}
            noFloat={true}
          />
          <input className='search' placeholder="Search by title" type="text" value={query} onChange={ handleQueryChange }></input>
          <a className='button small apply' onClick={ handleSearch }>Search</a>
          <div className='row'>
            <div className='small-12 columns'>
              <TemplateFilter 
                options={templateFilters}
              />
            </div>
          </div>
        </div>
        <Summary
          summary={data.summary}
          deltas={data.deltas}
          compareStartDate={compareStartDate}
          compareEndDate={setCompareEndDate} 
          setCompareStartDate={setCompareStartDate} 
          setCompareEndDate={setCompareEndDate}
        />
        <DetailedPerformance 
          data={data.data}
          paginationData={data.paginationData}
          setPage={setPage}
          handleSort={handleSort}
        />
      </div>
    </div>
  )

}

export default Main;