
var createReactClass = require('create-react-class');
import React from 'react';

const Sort = createReactClass({
  valueRenderer: function(val) {
    return (
      <div>
        <i className='fa fa-sort'></i>
        &nbsp;
        { val.label }
      </div>
    )
  },

  handleChange: function(val) {
    this.props.updateSort(val)
  },

  render: function() {
    return (
      <div className="medium-6 small-12 columns">
        <div id="sort-filter-component">
          <Select
            name={"order"}
            options={this.props.sorts.selectOptions}
            searchable={false}
            value={this.props.selectedSort}
            clearable={false}
            onChange={this.handleChange}
            valueRenderer={this.valueRenderer}
            className={"plain-select"}
          />
        </div>
      </div>
    )
  }
})

export default Sort;