import React, {useState, useEffect, useRef} from 'react';
import Label from './Label'
import RadioContainer from '../Shared/RadioContainer'
import ErrorMessage from './ErrorMessage'
import ReCAPTCHA from "react-google-recaptcha";
import { Cureus } from '../../Cureus';

const ApplicationForm = ({user_id, first_name, last_name, email, affiliation, submitPath, recaptchaKey, privacyPath, articlesDropdownOptions, specialtyDropdownOptions}) => {
  const [validCaptcha, setValidCaptcha] = useState(false)
  const [canSubmit, setCanSubmit] = useState('disabled')
  const [recaptchaFailed, setRecapchaFailed] = useState(false)
  const [formData, setFormData] = useState({
    user_id: user_id,
    first_name: first_name,
    last_name: last_name,
    email: email,
    affiliation: affiliation,
    position: '',
    articles_count: '',
    medical_degree: null,
    title: '',
    description: '',
    specialty: '',
    previous_collection: null,
    external_links: '',
    previous_collection_link: ''
  })

  const param = $("[name='csrf-param']").attr("content");
  const token = $("[name='csrf-token']").attr("content");

  const handleSubmit = (e) => {
    e.preventDefault()
    if(validCaptcha) {
      setCanSubmit('disabled');
      $.ajax({
        type: 'POST',
        url: submitPath,
        data: {collection_application: formData},
        success: function(res) {
          window.location.href = "/collections"
        },
        error: function(res) {
          Cureus.Flash.alert(res.responseJSON.errors);
          window.scrollTo(0, 0);
        }
      });
    } else {
      setRecapchaFailed(true)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value }
    setFormData(newFormData);
    const allFieldsFilled = Object.keys(newFormData).every(key => {
      if (key === 'previous_collection_link') {
        return true;
      }
      const field = newFormData[key];
      return field !== '' && field !== null;
    })
    
    setCanSubmit(allFieldsFilled ? '' : 'disabled');
  }

  const handleCaptchaChange = (value) => {
    setValidCaptcha(value != null)
  }

  const renderSelectDropdown = (options, defaultOption, name) => {
    return (
      <select name={name} onChange={handleChange}>
        <option>{defaultOption}</option>
        {options.map((opt) => {
          return <option key={opt} value={opt}>{opt}</option>
        })}
    </select>
    )
  }

  return (
    <div className="large-10 large-offset-1 medium-12 small-12 columns application-form-container">
      <div className="row">
        <div className="large-12 columns header-container">
          <img src="https://public.cureus.com/collections/application-form/header-img.png"/>
          <h1>Submit your Cureus Collection proposal</h1>
          <p>Would you like to serve as a guest editor for a collection of peer-reviewed articles focused on a specific topic? Submit your detailed proposal for consideration. Our editorial department will review your eligibility and proposed topic and reply via email.</p>
        </div>
      </div>
      <div className="row small-collapse">
        <div className="large-12 columns">
          <form acceptCharset='UTF-8' className='custom' method='post' onSubmit={handleSubmit}>
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name={param} value={token} />
            <div className="row">
              <div className="large-6 medium-6 small-12 columns">
                <input type="text" placeholder="First name" name='first_name' value={formData.first_name} onChange={handleChange} required/>
              </div>
              <div className="large-6 medium-6 small-12 columns">
              <input type="text" placeholder="Last name" name='last_name' value={formData.last_name} onChange={handleChange} required/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <input type="email" placeholder="Email" name='email' value={formData.email} onChange={handleChange} required/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <input type="text" placeholder="Affiliation (Department, Institution)" name='affiliation' value={formData.affiliation} onChange={handleChange} required/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <input type="text" placeholder="Position" name='position' value={formData.position} onChange={handleChange} required/>
              </div>
            </div>
            <div className="row">
              <Label label={"Do you have a medical degree?"} />
              {formData.medical_degree === false && <ErrorMessage label={"Eligible guest editors must possess a medical degree and be finished with training (e.g. residency)."}/>}
              <RadioContainer label={"Yes"} value={true} selectedValue={formData.medical_degree} handleChange={handleChange} name={"medical_degree"}/>
              <RadioContainer label={"No"} value={false} selectedValue={formData.medical_degree} handleChange={handleChange} name={"medical_degree"}/>
            </div>
            <div className="row">
              <div className="small-12 columns">
                  <Label label={"How many articles have you published in PubMed-Central indexed journals?"} />
                  {renderSelectDropdown(articlesDropdownOptions, "Select Articles", "articles_count")}
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <Label label={"Please provide links to any relevant external profile pages describing your experience and publishing history."} />
                <textarea placeholder="For example, your ResearchGate profile, institution/university profile page, etc." name='external_links' value={formData.external_links} onChange={handleChange} required/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <Label label={"Collection title"} />
                <input type="text" placeholder="Enter your proposed title." name='title' value={formData.title} onChange={handleChange} required/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <Label label={"Collection description"} />
                <textarea placeholder="Enter a brief description of the collection aims and scope (minimum 50 words)." name='description' value={formData.description} onChange={handleChange} required/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <Label label={"Specialty"} />
                {renderSelectDropdown(specialtyDropdownOptions, "Select the specialty most relevant to your collection topic.", "specialty")}
              </div>
            </div>
            <div className="row">
              <Label label={"Have you previously guest edited a collection or special issue for Cureus or another journal?"} />
              <RadioContainer label={"Yes"} value={true} selectedValue={formData.previous_collection} handleChange={handleChange} name={"previous_collection"}/>
              <RadioContainer label={"No"} value={false} selectedValue={formData.previous_collection} handleChange={handleChange} name={"previous_collection"}/>
              {formData.previous_collection === true &&
                <div className="row">
                  <div className="small-12 columns">
                  <input type="text" placeholder="Please provide a link to the collection/special issue." name="previous_collection_link" value={formData.previous_collection_link} onChange={handleChange}/>
                  </div>
                </div>
              }
            </div>
            <div className="row bottom-section">
              <div className="large-4 medium-12 small-12 columns">
                {recaptchaFailed && <ErrorMessage label={"Complete the reCAPTCHA to continue."}/>}
                <ReCAPTCHA
                  sitekey={recaptchaKey}
                  onChange={handleCaptchaChange}
                />
              </div>
              <div className="large-4 medium-12 small-12 columns">
                <p>All fields are required. By submitting this form you consent to the use of this data as described in our <a href={privacyPath} target="_blank">privacy policy</a>.</p>
              </div>
              <div className="large-1 medium-12 small-12 columns">
                <input type='submit' value="Submit" className={`button primary submit ${canSubmit}`}/>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
  )
}

export default ApplicationForm;