var createReactClass = require('create-react-class');
import React from 'react';

const LengthControl = createReactClass({
  onLengthChange: function(e) {
    this.props.onChange(e.target.value);
  },

  // TODO: Move this into a utility if it ends up being needed somewhere else
  numberWithCommas: function (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  render: function (){
    return (
      <div className="email-list-length-control">
        <select onChange={this.onLengthChange}>
          <option value="10"> Show 10 </option>
          <option value="25"> Show 25 </option>
          <option value="50"> Show 50 </option>
          <option value="100"> Show 100 </option>
        </select>

        <div className="email-list-length-control-total">
          of {this.numberWithCommas(this.props.totalUserCount)}
        </div>
      </div>
    )
  }
});

export default LengthControl;