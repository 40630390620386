import React, { useState, useEffect } from "react";
import { Cureus } from "../../Cureus";
import QuestionInput from "./QuestionInput";
import Modal from '../Modal';
import QueueModal from './QueueModal';
import CompletedAnswer from "./CompletedAnswer";

const New = ({picture_quiz_id}) => {
  const [loadedCKEditor, setLoadedCKEditor] = useState(false);
  const [answers, setAnswers] = useState(
    Array(3).fill({ answer: "", correct: false })
  );
  const [articleId, setArticleId] = useState("");
  const [question, setQuestion] = useState("");
  const [clarification, setClarification] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [publishDate, setPublishDate] = useState("");
  const [updateDate, setUpdateDate] = useState('');
  const [isPictureQuizGettingUpdated, setIsPictureQuizGettingUpdated] = useState(false);
  const [pictureQuizId, setPictureQuizId] = useState('');
  const [imageURL, setImageURL] = useState(''); 
  const [posted, setPosted] = useState(false)
  const [answersTotal, setAnswersTotal] = useState(0)
  const [saving, setSaving] = useState("")

  useEffect(() => {
    if (!loadedCKEditor) {
      new Cureus.CKEditor("picture-prognosis-clarification", {
        handle_change: updateClarification,
        editor_type: "static_text_with_color",
        ckeditor: { height: 100 },
        placeholder: "Provide an explanation as to why the correct answer is correct. This is displayed for the user after selecting a correct or incorrect answer."
      });
      setLoadedCKEditor(true);
    }
    if (location.hash && location.hash.includes('?id=')) {
      const quizUpdateId = location.hash.split('?id=')[1]
      getQuizUpdate(quizUpdateId)
    }
    if(picture_quiz_id) {
      getQuizUpdate(picture_quiz_id)
    }
  }, [posted]);

  const updateClarification = () => {
    const newClarification =
      CKEDITOR.instances["picture-prognosis-clarification"].getData();
    setClarification(newClarification);
  };

  const addNewAnswer = () => {
    setAnswers([...answers, { answer: "", correct: false }]);
  };

  const onChangeAnswer = (e, index) => {
    const { name, value } = e.target;
    setAnswers(
      answers.map((answer, idx) => {
        if (idx + 1 === index) {
          return {
            ...answer,
            [name]: value,
            number: idx + 1
          };
        }
        return answer;
      })
    );
  };

  const onChangeCorrect = (e, index) => {
    setAnswers(
      answers.map((answer, idx) => {
        if (index === idx + 1 && e.target.checked) {
          return {
            ...answer,
            correct: true,
          };
        } else {
          return {
            ...answer,
            correct: false,
          };
        }
      })
    );
  };

  const handleSave = () => {
    setSaving('disabled')
    const method = isPictureQuizGettingUpdated ? 'PUT' : 'POST';
    const query = method === 'PUT' ? `/${pictureQuizId}` : '';
    var formFile = createFormData();
    fetch(`/admin/picture_quizzes${query}`, {
      method: method,
      body: formFile,
      credentials: "same-origin",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
      },
    }).then((response) => response.json())
    .then((data) => {
      setSaving('')
      window.location.replace(`/admin/picture_quizzes/${data.picture_quiz_id}`)
    })
  };

  const getQuizUpdate = (quizUpdateId) => {
    $.ajax({
      type: 'GET',
      url: `/admin/picture_quizzes/${quizUpdateId}`,
      success: function(data) {
        setIsPictureQuizGettingUpdated(true);
        setPictureQuizId(data.picture_quiz.id);
        setImageURL(data.picture_quiz.image.url);
        setQuestion(data.picture_quiz.question)
        setAnswers(data.answers);
        setUpdateDate(data.picture_quiz.updated_at);
        setClarification(data.picture_quiz.clarification);
        setArticleId(data.picture_quiz.article_id);
        setPosted(data.picture_quiz.posted)
        setAnswersTotal(data.total)
        if(data.picture_quiz.published_at !== 'N/A') {
          setPublishDate(new Date(data.picture_quiz.published_at).toISOString().substring(0, 10))
        }
        CKEDITOR.instances["picture-prognosis-clarification"].setData(data.picture_quiz.clarification)
      }
    });
  }

  const renderAnswers = () => {
    if(posted) {
      return answers.map((answer, idx) => {
        const { answer: text, correct, count } = answer;
        return (
          <CompletedAnswer 
            key={answer.id} 
            correct={correct}
            text={text}
            percent={Math.floor((count/answersTotal) * 100) + '%'}
          />
        )
      })
    } else {
      return answers.map((answer, idx) => {
        return (
          <QuestionInput
            key={idx}
            quizOption={answer}
            idx={idx + 1}
            onChangeAnswer={onChangeAnswer}
            onChangeCorrect={onChangeCorrect}
          />
        );
      })
    }
  }

  const createFormData = () => {
    var formFile = new FormData();
    formFile.append("image", imageFile);
    let pictureQuizObj = {
      question: question,
      article_id: articleId,
      options: answers,
      clarification: clarification,
      published_at: publishDate
    };

    formFile.append("picture_quiz", JSON.stringify(pictureQuizObj));

    return formFile;
  };

  const shouldDisable = () => {
    let atLeastOneAnswer;
    let check = answers.filter((answerObj) => {
      if(answerObj.correct) atLeastOneAnswer = true;
      return answerObj.answer && answerObj.answer.length > 0
    })
    return (imageFile.length === 0 || !imageURL) && question.length === 0 || articleId.length === 0 || clarification.length === 0 || check.length !== answers.length || !atLeastOneAnswer;
  }

  const openQueueModal = () => {
    Modal.open({
      children: <QueueModal 
                  publishDate={publishDate}
                  setPublishDate={setPublishDate}
                  pictureQuizId={pictureQuizId}
                />
    })
  }

  return (
    <div className="small-12 columns picture-prognosis-container">
      <div className="row">
        <div className="small-4 columns">
          {posted ? <h3 className='no-margin-bottom'>Picture prognosis history</h3> : <h3>Create/Edit Picture Prognosis</h3>}
          {posted && <p className='gray-text'>Upated on {updateDate}</p>}
        </div>
        <div className="small-8 view-history columns">
          <a href={'/admin/picture_quizzes'}>View queue</a>
        </div>
      </div>

      <div className="file-container">
        <span>Image </span>
        {posted ?  (
          imageURL && <img className='posted-image-container' src={imageURL} alt="image" />
          ) : (
          <>
            <input
              className="choose-file-btn"
              type="file"
              onChange={(e) => setImageFile(e.target.files[0])}
              style={{width: '200px'}}
            />
            <br/>
            {imageURL && <img className='posted-image-container' src={imageURL} alt="image" />}
          </>
        )}
      </div>

      <div className="row">
        <div className="columns small-10">
          <div className="input-container">
            <label>Question</label>
              <textarea
                placeholder="Enter the question text here."
                onChange={(e) => setQuestion(e.target.value)}
                value={question}
                style={{height: '200px'}}
              />
          </div>
        </div>
      </div>

      {renderAnswers()}

      <div className="row margin-below margin-left">
        {!posted && (
          <a className="button secondary columns small-5" onClick={addNewAnswer}>
            ADD ANSWER
          </a>
        )}
      </div>

      <div className="row margin-below margin-left">
        <div className="form-container">
          {!posted && (
            <div className="margin-below">
              <div>Answer explanation:</div>
            </div>
          )}
            <textarea
              className="columns small-8"
              id="picture-prognosis-clarification"
              name="picture-prognosis-clarification"
              style={{ height: "300px" }}
            />
        </div>
      </div>

      <div className="row">
        <div className="columns small-5 margin-below">
          <div className="input-container">
            <label>Case report ID</label>
              <input
                type="text"
                placeholder="Enter the article ID of the related case report."
                onChange={(e) => setArticleId(e.target.value)}
                value={articleId}
              />
          </div>
        </div>
      </div>

      {!posted && <div className="row">
        <div className="columns small-3">
          <a className={"button special tiny"} onClick={openQueueModal}>Add to queue</a>
        </div>
      </div>}

      <div className="row">
        <div className="columns small-3">
          <a className={"button " + (shouldDisable() ? "disabled" : "") + saving} onClick={handleSave}>SAVE</a>
        </div>
        <div className="columns small-9 add-warning">
          {shouldDisable() && <span>You cannot post widget without content.</span>}
        </div>
      </div>
    </div>
  );
};

export default New;
