import React, { useEffect } from 'react';
import Tile from './Tile';

const MoreAbout = ({keywords, fireAmplitude}) => {

  useEffect(() => {
    $(document).foundation({
      equalizer : {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true,
        // Allow equalizer to resize hidden elements
        act_on_hidden_el: false,
      }
    });
  }, []);

  const renderKeywords = () => {
    return keywords.map((keyword, idx) => {
      return <Tile
                keyword={keyword}
                key={idx}
                fireAmplitude={fireAmplitude}
              />
    })
  }

  return (
    <div className="row small-collapse">
      <div className="small-12 columns">
        <div className="more-about-container tab-content-container" data-equalizer='more-about-tiles'>
          <h3 className="section-header">Related Topics</h3>
          <hr/>
          {renderKeywords()}
        </div>
      </div>
    </div>
  )
}

export default MoreAbout;