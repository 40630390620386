import React, { useEffect } from 'react';
import { Cureus } from '../../Cureus';
import Modal from '../Modal';
import StateHistoryModal from '../Dashboard/StateHistoryModal';
import DeletePosterAbstractModal from './DeletePosterAbstractModal';

const Abstract = ({ abstract }) => {

  useEffect(() => {
    $(document).foundation('reflow');
    Cureus.SplitButton.init();
  }, [])

  const renderPrimaryActionButton = () => {
    if(abstract.state === 'draft') {
      return <a className="button preview-button" href={abstract.editPath}><i className='fa fa-pencil-alt'/>Edit</a>
    } else if(abstract.state === 'editor_approval') {
      return <a className="button preview-button" href={abstract.titlePath} target="_blank"><i className='fa fa-pencil-alt'/>View Abstract</a>
    } else if(abstract.state === 'published') {
      return <a className="button preview-button" href={abstract.titlePath} target="_blank"><i className='fa fa-pencil-alt'/>View in Journal</a>
    }
  }

  const renderButtons = (small) => {
    if(abstract.state === 'draft') {
      return (
        <div className="cureus-split-dropdown-wrap" style={{display: 'inline'}}>
          <a className="secondary-button menu" data-cureus-split-dropdown={"abstract-menu-" + abstract.id + small}>
            <span><img src="https://public.cureus.com/dashboard-menu.png"/></span>
          </a>
          <ul id={"abstract-menu-" + abstract.id + small} className="cureus-split-dropdown new-dash">
            <li>
              <a onClick={() => openDeleteModal()}>Delete</a>
            </li>
          </ul>
        </div>
      )
    } 
  }

  const openDeleteModal = () => {
    Modal.open({
      children: <DeletePosterAbstractModal resource={abstract}/>
    })
  }

  const openStateHistoryModal = () => {
    Modal.open({
      children: <StateHistoryModal article={ abstract }/>
    })
  }

  const renderStateSteps = () => {
    if(abstract.state === 'published') {
      return (
        <div className="row" style={{marginTop: '18px'}}>
          <div className="small-12 columns">
            <div className="state-with-badge" dangerouslySetInnerHTML={{__html: abstract.stateWithBadge}}/>
            <div className="article-steps">
              <span className="step has-tip tip-top" onClick={openStateHistoryModal} data-tooltip="" id={"abstract-step-notif " + abstract.id} title="Click to view abstract status and history." style={{fontSize: "13px"}}>View Progress</span>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row" style={(abstract.state !== 'draft') ? {marginTop: '18px'} : {}}>
          <div className="small-12 columns">
            <div className="state-with-badge" dangerouslySetInnerHTML={{__html: abstract.stateWithBadge}}/>
            <div className="article-steps">
              <a onClick={openStateHistoryModal} className="has-tip tip-top" data-tooltip="" id={"abstract-step-notif " + abstract.id} title="Click to view abstract status and history.">
                <span className="word">Step</span><span className="step">&nbsp;{abstract.abstractStep}&nbsp;</span><span className="word">of&nbsp;</span><span className="step">3</span>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }

  const smallView = () => {
    return (
      <div className="small-12 columns bordered article-box show-for-small-only">
        <div className="row title-container">
          <div className="small-12 columns">
            <div className="title">
              <h5 className="title reg"><span><i class="fas fa-file-alt"/></span><a target="_blank" href={abstract.titlePath}>{abstract.title}</a></h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <div className="type-container">
              <span className="type">Abstract</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns text-left small-view-container">
            {renderPrimaryActionButton()}
          </div>
          <div className="small-12 columns text-left" style={{marginTop: '11px'}}>
            {renderButtons('small')}
          </div>
        </div>
        <div className="small-view-container">
          {renderStateSteps()}
        </div>
        <div className="row">
          <div className="small-12 columns author-links-container">
            <div dangerouslySetInnerHTML={{__html: abstract.authorListLinksMarkup}}/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {smallView()}
      <div className="small-12 columns bordered article-box hide-for-small">
        <div className="row title-container">
          <div className={"large-9 medium-8 small-8 columns"}>
            <div className="title">
              <h5 className="title reg"><span><i class="fas fa-file-alt"/></span><a target="_blank" href={abstract.titlePath}>{abstract.title}</a></h5>
            </div>
          </div>
          <div className="large-3 medium-4 small-4 columns text-right">
            {renderPrimaryActionButton()}
          </div>
        </div>
        <div className="row">
          <div className="large-9 medium-8 small-6 columns">
            <div className="type-container">
              <span className="type">Abstract</span>
            </div>
          </div>
          <div className="large-3 medium-4 small-6 columns text-right no-padding">
            {renderButtons()}
          </div>
        </div>
        {renderStateSteps()}
        <div className="row">
          <div className="small-12 columns author-links-container">
            <div dangerouslySetInnerHTML={{__html: abstract.authorListLinksMarkup}}/>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Abstract;