import React, { useState, useEffect } from 'react';
import Categories from '../Categories';

const SmallArticleCard = ({resource, showCategories=false}) => {
  const [authors, setAuthors] = useState('')
  const [authorsOverflow, setauthorsOverflow] = useState(null)

  useEffect(() => {
    let authorList = resource.author_list.split(',')
    setAuthors(authorList.slice(0, 2).join(', '))
    let overflow = authorList.slice(2).length
    if(overflow > 1) {
      setauthorsOverflow(overflow)
    }
  })

  const renderAuthorList = () => {
    if(authorsOverflow) {
      return (
        <div className="authors small">
          {authors}
          <a href={resource.path + '?authors-tab=true'}><span>+{authorsOverflow} more</span></a>
        </div>    
      )
    } else {
      return (
        <div className="authors small">
          {authors}
        </div>    
      )
    }
  }

  const renderTitle = () => {
    if(resource.thumbnail) {
      return (
        <div className="row flex-container">
          <div className="small-8 columns">
            <div className="title small">
              <h3><a href={resource.path}>{resource.title}</a></h3>
            </div>    
          </div>
          <div className="small-4 columns">
            <div className="thumbnail small" style={{position: 'relative'}}>
              <a href={resource.path} target="_blank">
                <div key={resource.id + "thumb"} dangerouslySetInnerHTML={{__html: resource.thumbnail}}/>
              </a>
            </div>  
          </div>
        </div>
      )
    } else {
      return (
        <div className="row">
          <div className="small-12 columns">
            <div className="title small">
              <h3><a href={resource.path}>{resource.title}</a></h3>
            </div>    
          </div>
        </div>
      )
    }
  }

  return (
    <div className={"new-article-card small " + (showCategories ? 'category-show' : '')}>
      <div className="row">
        <div className="small-12 columns header-container small">
          <div className="header">
            <span className="left">{resource.published_date}</span>
            <span className="right">{resource.humanized_type}</span>
          </div>    
        </div>
      </div>
      {renderTitle()}
      <div className="row">
        <div className="small-12 columns">
          {renderAuthorList()}  
          <Categories categories={resource.categories} resourceId={resource.id} showCategories={showCategories}/>
        </div>
      </div>
    </div>
  )
}

export default SmallArticleCard;