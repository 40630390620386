import React, { Component } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from 'react-tooltip';

class Heatmap extends Component {
  state = {
    data: []
  }

  componentDidMount() {
    $.ajax({
      type: 'GET',
      url: this.props.path,
      success: (res) => {
        this.setState({data: res.data})
      }
    })
  }

  setColor(count) {
    if(count == 0) { 
      return 0
    } else if(count == 1) {
      return 1
    }
    if(this.props.editorType.includes('Submissions') || this.props.editorType === 'DeputyAdmin') {
      if(count >= 10) {
        return 4
      } else if(count >= 5) {
        return 3
      } else if(count >= 3) {
        return 2
      } else {
        return 1
      }
    } else if(this.props.editorType === 'ApprovalEditor') {
      if(count >= 3) {
        return 3
      } else if(count >=2) {
        return 2
      }
    } else {
      if(count >= 3) {
        return 2
      } else {
        return 1
      }
    }
  }

  render() {
    return (
      <div className="heatmap">
        <CalendarHeatmap
          startDate={new Date(this.props.startDate)}
          endDate={new Date(this.props.endDate)}
          values={this.state.data}
          gutterSize={2}
          classForValue={value => {
            if (!value) {
              return 'color-0';
            }
            return `color-${this.setColor(value.count)}`;
          }}
          tooltipDataAttrs={value => {
            if(value) {
              return {
                'data-tip': `${value.date}, Actions: ${
                  value.count
                }`,
              };
            }
          }}
          showWeekdayLabels={true}
        />
        <div className="row">
          <div className={"legend-container " + (this.props.editorView ? 'editor' : '')}>
            <div className="small-3 small-offset-9 columns">
              <svg width="275" height="30">
                <text x="0" y="20" font-size="16">Less</text>
                <rect width="22" height="22" x="40" y="1" className="color-0"><title></title></rect>
                <rect width="22" height="22" x="70" y="1" className="color-1"><title></title></rect>
                <rect width="22" height="22" x="100" y="1" className="color-2"><title></title></rect>
                <rect width="22" height="22" x="130" y="1" className="color-3"><title></title></rect>
                <rect width="22" height="22" x="165" y="1" className="color-4"><title></title></rect>
                <text x="195" y="20" font-size="16">More</text>
              </svg>
            </div>
          </div>
        </div>
        <ReactTooltip />
      </div>
    )
  }
}

export default Heatmap;