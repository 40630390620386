import React from 'react';

const Preregistration = () => {
  return (
    <React.Fragment>
        <h1>Preregistration of Studies and Analysis Plans</h1>
        <p>
            Authors should indicate whether or not the conducted research was preregistered with an analysis plan in an independent, institutional registry (e.g., <a href='http://www.clinicaltrials.gov/' target='_blank' rel='noreferrer'>http://clinicaltrials.gov/</a>, <a href='http://www.socialscienceregistry.org' target='_blank' rel='noreferrer'>http://socialscienceregistry.org/</a>, <a href='http://openscienceframework.org/' target='_blank' rel='noreferrer'>http://openscienceframework.org/</a>, <a href='https://www.egap.org/registry-0/' target='_blank' rel='noreferrer'>https://egap.org/registry-0/</a>, <a href='http://ridie.3ieimpact.org/' target='_blank' rel='noreferrer'>http://ridie.3ieimpact.org/</a>).
        </p>
        <p>
            Preregistration of studies involves registering the study design, variables, and treatment conditions. Including an analysis plan involves specification of sequence of analyses or the statistical model that will be reported.
        </p>
        <p>
            Authors are encouraged to indicate in the acknowledgments section if they did or did not preregister the research with or without an analysis plan in an independent, institutional registry.
        </p>
        <p>
            If an author did preregister the research with an analysis plan, the author should:
            <ol style={{listStyle: 'auto'}}>
                <li>Confirm in the text that the study was registered prior to conducting the research with links to the timestamped preregistration(s) at the institutional registry, and that the preregistration adheres to the disclosure requirements of the institutional registry or those required for the preregistered badge with analysis plans maintained by the Center for Open Science.</li>
                <li>Report all preregistered analyses in the text, or, if there were changes in the analysis plan following preregistration, those changes must be disclosed with explanation for the changes.</li>
                <li>Clearly distinguish in text analyses that were preregistered from those that were not, such as having separate sections in the results for confirmatory and exploratory analyses.</li>
            </ol>
        </p>
    </React.Fragment>
  )
}

export default Preregistration;