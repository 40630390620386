import React, {useEffect} from 'react';

const VideoModal = ({videoUrl}) => {

  return (
    <div className="reveal-modal middling" data-reveal id="home-videos-modal">
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" frameborder="0" height="300" src={videoUrl} title="YouTube video player" width="100%"></iframe>
        </div>
      </div>
    </div>
  )
}

export default VideoModal;