import React, { useEffect, useState } from 'react';

const FurtherReading = ({articleId, fireAmplitude}) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    $.ajax({
      type: "GET",
      url: `/articles/${articleId}/suggested_articles`,
      dataType: "JSON",
      success: function(data) {
        setArticles(data.articles)
      }
    })
  }, []);

  const renderArticles = () => {
    return (
      <>
        <ul>
        {articles.map((article) => {
          return <a key={article.id} onClick={() => fireAmplitude('furtherReadingClicked', {article_id: article.id})}href={`/articles/${article.id}`}><li>{article.title}</li></a>
          })
        }
        </ul>
      </>
    )
  }

  return (
    <div className="row small-collapse">
      <div className="small-12 columns">
        <div className="further-reading-container tab-content-container">
          <h3 className="section-header">Further Reading</h3>
          <hr/>
          {renderArticles()}
        </div>
      </div>
    </div>
  )
}

export default FurtherReading;