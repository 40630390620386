import React, { useState, useEffect } from 'react';
import AnswerOutcome from './AnswerOutcome';
import Clarification from './Clarification';

const Landing = ({pictureQuizId, currentUserId}) => {

  const [answers, setAnswers] = useState([]);
  const [userAnsweredId, setuserAnsweredId] = useState(null);
  const [pictureQuiz, setPictureQuiz] = useState(null);
  const [answersTotal, setAnswersTotal] = useState(0);
  const [correctAnswerId, setCorrectAnswerId] = useState(null)

  useEffect(() => {
    fetchPictureQuiz();
    const cookie = getCookie(`picture-quiz-${pictureQuizId}`)
    setuserAnsweredId(cookie)
  }, [])

  const fetchPictureQuiz = () => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `/picture_quizzes/${pictureQuizId}`,
      success: function(data) {
        const { answers, picture_quiz: pictureQuiz, total: totalAnswersCount } = data;
        setPictureQuiz(pictureQuiz);
        setAnswers(answers);
        setAnswersTotal(totalAnswersCount);
        setCorrectAnswerId(pictureQuiz.correctAnswerId)
      }
    });
  }

  const handleAnswer = (answerId) => {
    $.ajax({
      type: 'PUT',
      url: `/picture_quizzes/${pictureQuizId}`,
      data: {quiz_option_id: answerId, answered_already: false},
      success: function(data) {
        setCookie(`picture-quiz-${pictureQuizId}`, answerId, 7);
        setuserAnsweredId(answerId)
        fireAmplitude(data.answer)
        fetchPictureQuiz()
      }
    });
  }

  const fireAmplitude = (answer) => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: 'picturePrognosisSubmit', 
              user_id: currentUserId,
              answer: answer
            }
    })
  }

  if(!pictureQuiz) {
    return <div/>
  }
  
  return (
    <div className='small-12 columns picture-prognosis-answer-outcome-container'>
      <hr/>
      <AnswerOutcome 
        correctAnswerId={correctAnswerId}
        userAnsweredId={userAnsweredId} 
        answers={answers} 
        answersTotal={answersTotal}
        handleAnswer={handleAnswer}
      />
      <hr id='second-hr'/>
      {userAnsweredId && <Clarification clarification={pictureQuiz.clarification} articleId={pictureQuiz.article_id}/>}
      <hr/>
    </div>
  )
}

export default Landing;