var createReactClass = require('create-react-class');
import React from 'react';
import UserDetails from './UserDetails';
import Picker from './Picker';

const Main = createReactClass({
  getInitialState: function (){
    return {
      winner: null,
      loser: null,
      processing: false
    }
  },

  findUser: function (id){
    return $.ajax({
      type: "GET",
      dataType: "json",
      url: this.props.findUrl,
      data: {id: id}
    });
  },

  handleMerge: function (){
    var self = this;

    this.setState({processing: true});
    $.ajax({
      type: "PUT",
      dataType: "json",
      url: this.props.mergeUrl,
      data: { 
        merge_winner: this.state.winner.details.id,
        merge_loser: this.state.loser.details.id
      }
    }).done( function (data, textStatus, jqXHR) {
      Cureus.Flash.success("User " + self.state.loser.details.id + " was successfully merged into user " + self.state.winner.details.id);
      self.setState({loser: null, processing: false});
      self.updateWinner(self.state.winner.details.id);
    }).fail( function (jqXHR, textStatus, errorThrown) {
      self.setState({processing: false});
      Cureus.Flash.alert("Unable to merge user " + self.state.loser.details.id + " into user " + self.state.winner.details.id)
    });
  },

  isMergeReady: function (){
    if(this.state.loser && this.state.winner) { return true; }
    return false;
  },

  updateLoser: function (id){
    var self = this;

    if(!id) { return this.setState({loser: null}); }

    this.findUser(id)
      .done( function (data, textStatus, jqXHR) {
        if(Object.keys(data).length == 0) {
          self.setState({loser: null})
        }else{
          self.setState({loser: data})
        }
      }).fail( function (jqXHR, textStatus, errorThrown) {
        self.setState({loser: null})
      });
  },

  updateWinner: function (id){
    var self = this;

    if(!id) { return this.setState({winner: null}); }

    this.findUser(id)
      .done( function (data, textStatus, jqXHR) {
        if(Object.keys(data).length == 0) {
          self.setState({winner: null})
        }else{
          self.setState({winner: data})
        }
      }).fail( function (jqXHR, textStatus, errorThrown) {
        self.setState({loser: null})
      });
  },

  renderMergingAnim: function (){
    return (
      <div className="user-mergetool-merging-wrap" style={{visibility: (this.state.processing ? "visible" : "hidden")}}>
        <div className="user-mergetool-merging"></div>
        <h5>Merging...</h5>
      </div>
    )
  },

  renderMergeButton: function (){
    if(this.state.processing){
      return null;
    }
    if(this.isMergeReady()){
      return (
        <a className={"button large primary expand"} onClick={this.handleMerge}>
          Merge
        </a>
      )
    }else{
      return (
        <a className={"button large primary expand disabled"}> Merge </a>
      )
    }
  },

  render: function (){
    return (
      <div className='user-mergetool'>
        <div className='row'>
          <div className='large-6 columns'>
            <h3 className='text-center reg'>
              Merge this user..
            </h3>
            <Picker 
              searchUrl={this.props.searchUrl} 
              findUrl={this.props.findUrl} 
              onChosen={this.updateLoser}
              selectedUser={this.state.loser} />

            <UserDetails user={this.state.loser} />
          </div>

          <div className='large-6 columns'>
            <h3 className='text-center reg'>
              Into this user..
            </h3>
            <Picker 
              searchUrl={this.props.searchUrl} 
              findUrl={this.props.findUrl} 
              onChosen={this.updateWinner}
              selectedUser={this.state.winner} />

            <UserDetails user={this.state.winner} />
          </div>

          <div className="large-12 columns">
            { this.renderMergingAnim() }
            { this.renderMergeButton() }
          </div>
        </div>
      </div>
    )
  }
});

export default Main;
