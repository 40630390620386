var createReactClass = require('create-react-class');
import React from 'react';

const ReviewsComplete = createReactClass({
  renderRequestPublication: function() {
    if(this.props.article.isValid) {
      return(
        <li>
          <a href='#' className='has-tip tip-top' data-tooltip="" title="Provide a brief explanation of your revisions and request editor approval to publish your article." id={"action-dropdown-request-publication-tooltip-" + this.props.article.id}>
            Request publication
          </a>
      </li>
      )
    } else {
      return (
        <li>
          <a href={this.props.article.correctArticlePath} className='has-tip tip-top' data-tooltip="" title="Provide a brief explanation of your revisions and request editor approval to publish your article." id={"action-dropdown-request-publication-correction-" + this.props.article.id}>
            Request publication
        </a>
      </li>
      )
    }
  },

  renderRequestRereview: function() {
    if(!this.props.article.wasDeferred) {
      return (
        <li>
          <a href='#' className='has-tip tip-top' data-tooltip="" title="Edit the article and resubmit for additional reviews." id={"action-dropdown-request-re-review-tooltip-" + this.props.article.id}>
            Request re-review
          </a>
        </li>
      )
    }
  },

  renderEndPeerReview: function() {
    if(!this.props.article.endedPeerReview) {
      return (
        <li>
          <a href='#' id={"action-dropdown-end-review-tooltip-" + this.props.article.id}>
            End peer review
          </a>
        </li>
      )
    }
  },

  render: function() {
    return(
      <div className="cureus-split-dropdown-wrap" >
        <a href={this.props.article.articlePath} className="button secondary split has-tip tip-top view-comments" data-cureus-split-dropdown={"action-buttons-" + this.props.article.id} data-tooltip="" title="View the article in our reviewing tool. Opens in a new tab." target="_blank" id={"action-dropdown-view-tooltip-" + this.props.article.id}>
          View comments
          <span/>
        </a>
        <ul className="cureus-split-dropdown" id={"action-buttons-" + this.props.article.id}>
          <li>
            <a target="_blank" href={this.props.article.previewPath}>Preview</a>
          </li>
          {this.renderEndPeerReview()}
          {this.renderRequestPublication()}
          <li>
            <a href={this.props.article.editArticlePath} className='has-tip tip-top' data-tooltip="" title="Make additional edits (based on reviewer feedback) before submitting for publication." id={"action-dropdown-edit-tooltip-" + this.props.article.id}>
              Edit article
            </a>
          </li>
          {this.renderRequestRereview()}
          {this.props.renderEmailEditingReceipt()}
          <li>
            <a href='#' className='has-tip tip-top' data-confirm='Are you sure you want to delete this article?' title="Edit the article and resubmit for additional reviews." id={"action-dropdown-abandon-tooltip-" + this.props.article.id}>
              <i className="far fa-trash-alt"></i> Delete
            </a>
          </li>
        </ul>
      </div>
    )
  }
})

export default ReviewsComplete;