import React from 'react';

const SocialAccountIcon = ({href, img, alt}) => {

  return (
    <>
      <a href={href} target='_blank'>
        <img src={img} alt={alt} />
      </a>
    </>
  )
}

export default SocialAccountIcon;