import Fluxxor from '../../../../../../../vendor/assets/javascripts/fluxxor';
import constants from '../constants';

const FilterTool = Fluxxor.createStore({
  initialize: function(opts) {
    this.filters = this.defaultFilters();

    this.availableFilters = opts.availableFilters;

    this.bindActions(
      constants.ADD_FILTERS, this.onAddFilters,
      constants.CLEAR_FILTERS, this.onClearFilters
    )
  },

  defaultFilters: function (){
    return [
      {
        type: null,
        condition: null,
        value: null,
        key: 0,
        uniqueKey: _.uniqueId()
      }
    ];
  },

  getState: function() {
    return {
      filters: this.clone(this.filters),
      filterCount: this.filters.length
    }
  },

  getAvailableFilters: function() {
    return this.availableFilters;
  },

  hasValidFilters: function (){
    if(this.filters.length > 0){
      return !!this.filters[0].type
    }
    return true;
  },

  onAddFilters: function(filters) {
    //Reject filters that don't have a type
    this.filters = _.reject(filters, function(filter) {
      return filter.type == null;
    });
    this.emit("change");
  },

  onClearFilters: function() {
    this.filters = this.defaultFilters();
    this.emit("change");
  },

  //Needed so the store can't be modified externally
  clone: function(array) {
    return _.map(array, _.clone);
  }
});

export default FilterTool;