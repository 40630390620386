var createReactClass = require('create-react-class');
import React from 'react';
import ArticleListItem from './ArticleListItem';
import CureusUArticleListItem from './CureusUArticleListItem';
import AddArticleInput from './AddArticleInput';
import Sortable from '../Sortable';
import Pages from '../Shared/Pagination/Pages';
import UpdateKlaviyoTemplateButton from './UpdateKlaviyoTemplateButton';

const Main = createReactClass({
  getInitialState: function() {
    return ({
      articles: [],
      alternativeArticles: [],
      alternativesLoaded: false,
      showAlternatives: false,
      fetching: false,
      paginationData: {},
      page: 1,
      optionalChecked: false
    })
  },

  componentDidMount: function() {
    this.setState({
      articles: this.props.articles,
      campaignId: this.props.campaignId,
      parsleyErrorField: $(".add-campaign-article").parsley()
    })
  },

  mapItems: function() {
    var self = this;
    return self.state.articles.map(function(article, idx) {
      return (
          <ArticleListItem
            article={article}
            removeArticle={self.removeArticle}
            key={article.id}
            position={idx + 1}
          />
      )
    })
  },

  mapCureusUItems: function() {
    var self = this;
    return this.state.articles.map(function(article, idx) {
      return (
          <CureusUArticleListItem
            article={article}
            removeArticle={self.removeArticle}
            key={article.id}
            position={idx + 1}
          />
      )
    })
  },

  fetchArticle: function(articleId) {
    $.ajax({
      url: '/admin/campaigns/articles/' + articleId,
      type: 'GET',
      success: this.handleAddSuccess,
      error:  this.handleFailAjax
    })
  },

  removeArticle: function(articleId) {
    if (this.articlePersisted(articleId)) {
      this.deleteArticleFromDB(articleId)
    }
    var removedArticle;
    var newArticleState = this.state.articles.filter(function(article) {
      if (articleId == article.id) {
        removedArticle = article;
      }
      return articleId !== article.id;
    });
    var newAlternativeArticles = [removedArticle].concat(this.state.alternativeArticles);
    this.setState({articles: newArticleState, alternativeArticles: newAlternativeArticles})
  },

  deleteArticleFromDB: function(articleId) {
    var self = this;
    $.ajax({
      url: '/admin/campaigns/'  +  self.state.campaignId  + '/articles/' + articleId,
      data: {alternativeArticles: self.state.alternativeArticles.map(function(article) { return article.id })},
      type: "DELETE",
      error: self.handleFailAjax,
      success: function(res) {
        self.setState({paginationData: res.paginationData})
      }
    })
  },

  handleAddSuccess: function(response) {
    var articleId = response.article.id
    this.clearErrorsFromField()
    if (!this.checkDuplicates(articleId)) {
      var newArticleState = this.state.articles;
      newArticleState.push(response.article);
      this.setState({articles: newArticleState})
    } else {
      var errorText = 'Article ' + articleId + ' already added'
      this.addErrorToField(errorText)
    }
  },

  handleFailAjax: function(response) {
    var errorText = JSON.parse(response.responseText).error;
    this.addErrorToField(errorText)
  },

  addErrorToField: function(errorMessage) {
    this.clearErrorsFromField()
    window.ParsleyUI.addError( this.state.parsleyErrorField, "campaign-article-errors", errorMessage)
  },

  clearErrorsFromField: function() {
    $(".parsley-campaign-article-errors").remove()
    window.ParsleyUI.removeError( this.state.parsleyErrorField )
  },

  checkDuplicates: function(articleId) {
    var dupes = false;
    this.state.articles.forEach(function(article) {
      if (article.id === articleId) {
        dupes = true;
      }
    });
    return dupes;
  },

  articlePersisted: function(articleId) {
    var persisted = false;
    this.state.articles.forEach(function(article) {
      if (article.id === articleId && article.persisted) {
        persisted = true;
      }
    });
    return persisted;
  },

  handleSort: function(e, sortable, movedComponent) {
    var newPosition = e.newIndex + 1;
    this.handleUpdatePosition(movedComponent, newPosition);
  },

  handleUpdatePosition: function(movedComponent, newPosition){
    var self = this;
    var oldPositionIndex = movedComponent.props.position - 1;

    var movedArticle = this.state.articles[oldPositionIndex];
    var newArticleState = this.state.articles.slice()

    newArticleState.splice(oldPositionIndex, 1);
    newArticleState.splice(newPosition - 1, 0, movedArticle);
    this.setState({articles: newArticleState}, function() {
      var self = this;
      $.ajax({
        type: "PATCH",
        url: "/admin/campaigns/" + self.state.campaignId,
        dataType: 'json',
        data: { cureus_u_articles: newArticleState },
        success: function(data) {
        }
      });
    })
  },

  isValidPosition: function(position) {
    var min = 1;
    var max = this.props.attachments.length;
    return position >= min && position <= max;
  },

  renderCampaignArticles: function() {
    return (
      <div className="campaign-articles">
        <p> Enter an article ID to display in the email </p>
        <ul style={{listStyle:"none"}}>
          <Sortable tagName='div' handleSort={this.handleSort} >
          {this.mapItems()}
          </Sortable >
        </ul>
        <AddArticleInput
          fetchArticle={this.fetchArticle}
        />
      </div>
    )
  },

  handlePageSelect: function(page) {
    var newPaginationData = this.state.paginationData;
    newPaginationData.currentPage = page
    newPaginationData.lastPage = page == newPaginationData.totalPages;
    newPaginationData.firstPage = page == 1;
    this.setState({page: page, paginationData: newPaginationData})
  },

  renderAlternativeArticles: function() {
    var self = this;
    if (this.state.showAlternatives) {
      if (this.state.alternativeArticles.length == 0) {
        return (
          <React.Fragment>
            <p>No alternatives match the criteria.</p>
            <div className="campaign-articles" id="alt-articles" style={{borderTop: '3px #13BCA4 solid', paddingTop: '50px'}}></div>
          </React.Fragment>
        )
      }
      var articles = this.state.alternativeArticles.chunk(5)[this.state.page-1].map(function(article, idx) {
        return (
          <CureusUArticleListItem
            article={article}
            key={article.id}
            position={idx + 1}
            addArticle={ self.handleAddAlternativeArticle }
            canAdd={ self.canAddArticle() }
          />
        )
      })
      if (this.props.template == 'inside_cureus') {
        return (
          <React.Fragment>
            {!this.canAddArticle() && <p>You have five articles selected. Please, remove an article to be able to add an alternative.</p>}
            <div className="campaign-articles" id="alt-articles" style={{borderTop: '3px #13BCA4 solid', paddingTop: '50px'}}>
              <ul style={{listStyle:"none", marginLeft: '0'}}>
                { articles }
              </ul>
              <Pages
                paginationData={this.state.paginationData}
                handlePageSelect={this.handlePageSelect}
              />
            </div>
          </React.Fragment>
        )
      }
    }
  },

  handleAddAlternativeArticle: function(id) {
    var self = this;
    $.ajax({
      url: this.props.admin_campaign_cureus_u_articles_path,
      type: "POST",
      data: {articleId: id, alternativeArticles: this.state.alternativeArticles.map(function(article) { return article.id })},
      success: function(res) {
        var addedArticle;
        var newAlternativeArticles = self.state.alternativeArticles.filter(function(article) {
          if (article.id == id) {
            addedArticle = article;
            return false;
          } return true;
        })
        var newArticles = self.state.articles.concat([addedArticle])
        var newPaginationData = res.paginationData;
        var newPage = self.state.page;
        if (newPage > newPaginationData.totalPages) {
          newPage = newPaginationData.totalPages;
        }
        newPaginationData.lastPage = newPage == newPaginationData.totalPages;
        newPaginationData.firstPage = newPage == 1;
        self.setState({page: newPage, paginationData: newPaginationData, articles: newArticles, alternativeArticles: newAlternativeArticles})
      }
    })
  },

  toggleAlternatives: function() {
    var self = this;
    if (this.state.alternativesLoaded) {
      this.setState({showAlternatives: !this.state.showAlternatives});
    } else {
      this.setState({fetching: true})
      $.ajax({
        type: "GET",
        dataType: 'json',
        url: this.props.admin_campaign_cureus_u_articles_path,
        success: function(res) {
          self.setState({fetching: false, alternativesLoaded: true, showAlternatives: true, paginationData: res.paginationData, alternativeArticles: res.data.map(function(x) { return x.attributes })})
        }
      })
    }
  },

  toggleOptional: function(ev) {
    this.setState({optionalChecked: ev.target.checked && this.canAddArticle()})
  },

  addArticle: function(id) {
    if (this.state.articles.length < 5) {
      var self = this;
      $.ajax({
        url: this.props.admin_campaign_cureus_u_articles_path,
        type: "POST",
        data: {articleId: id},
        success: function(res) {
          var newArticles = self.state.articles.concat([res.data.attributes])
          self.setState({articles: newArticles })
        }
      })
    }
  },

  renderManualArticle: function() {
    if(this.state.optionalChecked && this.canAddArticle()) {
      return (
        <div className="campaign-articles">
        <p> Enter an article ID to display in the email </p>
        <AddArticleInput
          fetchArticle={this.addArticle}
        />
      </div>
      )
    }
  },

  renderCureusUArticles: function() {
    var toggleText = this.canAddArticle() ? "Option to select manually" : "You have five articles selected. Remove one to be able to add one manually."
    return (
      <React.Fragment>
        <div className="campaign-articles" id="cureus-u-articles">
          <div className='toggle'>
            <span className='toggle-label'>{toggleText}</span>
            <label class="switch">
              <input type="checkbox" checked={this.state.optionalChecked && this.canAddArticle()} onChange={this.toggleOptional}/>
              <span class="slider round"></span>
            </label>
            { this.renderManualArticle() }
          </div>
          <ul style={{listStyle:"none", marginLeft: '0'}}>
            <Sortable tagName='div' handleSort={this.handleSort} >
            {this.mapCureusUItems()}
            </Sortable >
          </ul>
        </div>
        { this.renderAlternativeArticles() }
        <div className='row button-container'>
          <div className='left'>
            <a className='button primary' onClick={this.toggleAlternatives} disabled={this.state.fetching}>{this.state.showAlternatives ? "HIDE ALTERNATIVES" : "SHOW ALTERNATIVES"}</a>
          </div>

        { this.renderActionButtons() }

        </div>
      </React.Fragment>
    )
  },

  renderActionButtons: function() {
    if (this.props.template == 'inside_cureus') {
      return (
        <div className='right'>
          <a className='button secondary' href={this.props.admin_campaigns_path}>BACK</a>
          <UpdateKlaviyoTemplateButton path={this.props.update_klaviyo_template_path}/>
      </div>
      )
    } else {
      return (
        <div className='right'>
        <a className='button secondary' href={this.props.admin_campaigns_path}>CANCEL</a>
        <a className='button secondary' href={this.props.edit_admin_campaign_path}>BACK</a>
        <a className='button special' href={this.props.preview_admin_campaign_path}>PREVIEW</a>
        <a className='button primary' href={this.props.admin_campaign_launch_path}>NEXT</a>
      </div>
      )
    }
  },


  canAddArticle: function() {
    return this.state.articles.length < 5;
  },

  render: function() {
    if (this.props.template == 'inside_cureus') {
      return this.renderCureusUArticles();
    } else {
      return this.renderCampaignArticles();
    }
  }
})

export default Main;