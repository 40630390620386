var createReactClass = require('create-react-class');
import React from 'react';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const DeleteListEmailsModal = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("Table")
  ],

  getInitialState: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  getStateFromFlux: function() {
    return this.getFlux().store("Table").getState();
  },

  handleDelete: function() {
    var params = {
      csrfParam: this.state.csrfParam,
      csrfToken: this.state.csrfToken,
      search: {value: this.state.query},
      staged: this.state.staged,
      selectAll: this.state.selectAll,
      subscribed: this.props.subscribed
    }

    $.post(this.props.deleteListEmailsPath, params, function() {
      window.location.reload();
    });
  },

  render: function() {
    return (
      <div className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <div className='modal-form'>
              <p>
                You are about to delete {this.state.selectedCount} subscribers from the {this.props.title} list.
              </p>
              <p>
                <strong>Are you sure you want to do that?</strong>
              </p>

              <div className='modal-footer'>
                <a className='cancel-modal button secondary'>Cancel</a>
                <a className='button primary' onClick={this.handleDelete}>Confirm</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default DeleteListEmailsModal;