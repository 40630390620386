var createReactClass = require('create-react-class');
import React from 'react';
import AffiliationsRow from './AffiliationsRow';

const AffiliationsMain = createReactClass({
  getInitialState: function() {
    return({
      hasNoAffiliation: this.props.hasNoAffiliation,
      affiliations: [],
      countryOptions: [],
      autocompleteUrl: "",
      totalAffiliations: 0
    })
  },

  componentWillMount: function() {
    this.setState({
      affiliations: this.state.affiliations,
      totalAffiliations: this.state.affiliations.length
    })
  },

  componentDidMount: function() {
    var self = this;
    if (self.props.confirmation) {
      $.ajax({
        url: self.props.getComponentDataPath,
        type: "GET",
        data: {class: 'AffiliationsModal', user_id: self.props.user_id},
        dataType: "json",
        success: function(data) {
          self.setState({
            affiliations: data.affiliations,
            countryOptions: data.countryOptions,
            hasNoAffiliation: data.hasNoAffiliation,
            autocompleteUrl: data.autocompleteUrl,
            totalAffiliations: data.affiliations.length,
            deptAutocompletePath: data.deptAutocompletePath
          })
        }
      })
    } else {
      self.setState({            
        affiliations: self.props.affiliations,
        countryOptions: self.props.countryOptions,
        hasNoAffiliation: self.props.hasNoAffiliation,
        autocompleteUrl: self.props.autocompleteUrl,
        totalAffiliations: self.props.affiliations.length,
        deptAutocompletePath: self.props.deptAutocompletePath})
    }
    $('[data-autocomplete-url]').associationAutocomplete();
  },

  addAffiliationRow: function() {
    var newAffiliationsArray = this.state.affiliations
    // add random unique number for key
    var newAffiliation = { affiliation: { id: new Date().getTime()} }

    newAffiliationsArray.push(newAffiliation)
    this.setState({affiliations: newAffiliationsArray, totalAffiliations: newAffiliationsArray.length})
  },

  removeAffiliationRow: function(affiliation) {
    if (this.state.affiliations.length > 1) {
      var newAffiliationsArray = this.state.affiliations
      newAffiliationsArray = newAffiliationsArray.filter(function(e) {
        return e.id != affiliation.id
      });
    if (affiliation.isPrimary) {
      newAffiliationsArray[0].isPrimary = true;
    }
    this.setState({affiliations: newAffiliationsArray, totalAffiliations: newAffiliationsArray.length})
    }
  },

  renderAffiliationsForm: function() {
    var self = this;
    var totalAffiliations = self.state.totalAffiliations
     return self.state.affiliations.map(function(affiliationObject, index) {
      return (
        <AffiliationsRow
          deptAutocompletePath={self.state.deptAutocompletePath}
          affiliationObject={affiliationObject}
          countryOptions={self.state.countryOptions}
          index={index}
          key={affiliationObject.id}
          removeAffiliation={self.removeAffiliationRow}
          autocompleteUrl={self.state.autocompleteUrl}
          hasNoAffiliation={self.state.hasNoAffiliation}
          handleChange={self.handleChange}
          handleOptionChange={self.handleOptionChange}
          acceptRecommendedInstitution={self.acceptRecommendedInstitution}
          totalAffiliations={totalAffiliations}
        />
      )
    })
  },

  handleChange: function(e) {
    var newAffiliations = this.state.affiliations
    var index = e.target.dataset.index
    newAffiliations[index][e.target.name] = e.target.value
    this.setState({affiliations: newAffiliations})
  },

  handleOptionChange: function(e) {
    var currentAffiliations = this.state.affiliations
    var primaryAffiliationIndex = parseInt(e.target.id);
    currentAffiliations.forEach(function(affiliationObject, idx) {
      affiliationObject.isPrimary = idx == primaryAffiliationIndex
    });
    this.setState({affiliations: currentAffiliations});
  },

  acceptRecommendedInstitution: function(idx) {
    var newAffiliations = this.state.affiliations
    newAffiliations[idx]['institutionName'] = this.state.affiliations[idx]['recommendedInstitutionName']
    newAffiliations[idx]['recommendedInstitutionName'] = null
    this.setState({affiliations: newAffiliations})
  },

  hasValidAffiliationData: function() {
    if (this.state.affiliations == null) {
      return false;
    }
    for (let element of this.state.affiliations) {
      if (!element['institutionName'] || !element['departmentName'] || !element['city'] || !element['country'] || element['country'] == 'Select Country') {
        return false;
      }
    }
    return true;
  },

  render: function() {
    return (
      <div className="reveal-modal medium" data-reveal data-options={this.props.setUncloseable()}>
        <div className="modal-body">
          {this.props.renderFlash()}
          {this.props.renderModalCloseX()}
          <div className="modal-content">
          <h4>Affiliation Name and Location</h4>
          <p>All Cureus users are required to list their affiliation information. This is especially important for authors as this information cannot be changed after articles are indexed in PubMed Central. Medical students should list the department relevant to the work.</p>
          { this.renderAffiliationsForm() }
            <a className="button tiny secondary" data-add-fields="true" href="javascript:void(0)" onClick={this.addAffiliationRow}>
            <i className="fa fa-plus-circle"></i>
              &nbsp;Add another
            </a>
          </div>
          <div className='modal-footer'>
              {this.props.renderButton({user: {affiliations: Object.values(this.state.affiliations)}, section: 'AffiliationForm'}, this.props.updateProfilePath, () => this.forceUpdate(), this.hasValidAffiliationData())}
          </div>
        </div>
      </div>
    ) 
  }
})

export default AffiliationsMain;