import {Cureus} from '../Cureus';
Cureus.ArticleTourJoyride = (function($, _) {
  var init, ArticleTourJoyride;

  init = function(options) {
    new ArticleTourJoyride(options);
  }

  ArticleTourJoyride = function(options) {
    var self = this;
    self.options = options;
    self.cookieJoyrideOptions = {
      cookie_monster: true,
      cookie_name: self.cookieName(),
      cookie_domain: false,
      cookie_expires: 365,
    }


    self.defaultOptions = {
      pre_ride_callback: function(){
        self.initialTourElementRemoval();
      },
      template: { // HTML segments for tip layout
        link : '<a href="#close" class="joyride-close-tip close-button">&times;</a>'
      },
      next_button: false,
      prev_button: false
    }

    self.initialize();
    self.afterInitialize();
  }

  _.extend(ArticleTourJoyride.prototype, {
    initialize: function() {
      this.toggleJoyride();
      this.$tourSelectors = $("#tour-end1, #tour-end2, #tour-end3, #tour-end4");
    },

    cookieName: function() {
      if (this.options.cookieName) {
        return "new-article-tour" + "-" + this.options.articleId + "-" + this.options.cookieName
      } else {
        return "new-article-tour" + "-" + this.options.articleId
      }
    },

    afterInitialize: function() {
      this.handleSkip();
      this.handleSkipCheckBoxSelection();
    },

    initialTourElementRemoval: function() {
      $("#beta-tour-wrapper").remove();
    },

    checkForCookie: function() {
      return $.cookie(this.cookieName()) ? true : false
    },

    addNeverShowAgainCookie: function() {
      document.cookie = this.cookieName() + "=ridden; expires=Sun, 1 May 3000 13:37:00 UTC; path=/"
    },

    removeCookie: function() {
      document.cookie = this.cookieName() + "=ridden; expires=Sun, 1 May 2000 13:37:00 UTC; path=/"
    },

    toggleJoyride: function() {
      var options = this.checkForCookie() ? $.extend(this.defaultOptions, this.cookieJoyrideOptions) : this.defaultOptions;

      $(document).foundation({
        joyride: options
      });
      $(document).foundation('joyride', 'start');
    },

    handleSkip: function() {
      var self = this;

      $(".close-button").click(function() {
        self.addNeverShowAgainCookie()
      })

      // checkboxes
      $("#tour-end1, #tour-end2, #tour-end3, #tour-end4").click(function(e) {
        if ($(e.target).is(":checked")) {
          self.addNeverShowAgainCookie()
        }
        else {
          self.removeCookie()
        }
      })
    },

    handleSkipCheckBoxSelection: function() {
      // check box selected now persists across all rides
      var self = this;
      this.$tourSelectors.click( function(e) {
        self.$tourSelectors.prop("checked", $(e.target).is(":checked"));
      });
    },
  })
  return { init: init };
})(jQuery, _)
