var createReactClass = require('create-react-class');
import React from 'react';
import Card from './Card';

const ExpeditedPmcModal = createReactClass({
  handleExpeditedPurchase: function(e) {
    var type = e.target.name
    this.props.handleExpeditedPurchase(type)
  },

  renderLandingHeader: function() {
    if(this.props.paymentsPage) {
      return (
        <React.Fragment>
          <h2 className="landing-header">Purchase Expedited PMC Indexing</h2>
          <h4 className="reg" style={{marginBottom: '1.25rem'}}>“{this.props.title}”</h4>
        </React.Fragment>
      )
    } else if(this.props.laurOrSum) {
      return (
        <h4 className='reg'>
          Purchase Expedited Indexing Service
        </h4>
      )
    } else {
      return <h4 className="reg">“{this.props.title}”</h4>
    }
  },

  renderLandingCopy: function() {
    if(this.props.paymentsPage) {
      return this.props.renderLandingPageCopy()
    }
  },

  renderContents: function() {
    if(this.props.laurOrSum) {
      return (
        <div className='modal-content'>
          {this.renderLandingHeader()}
          {this.props.renderPricingInfo()}
          <p>
            Purchase our expedited indexing service and we'll submit your article for PMC indexing in 1-3 business days.
          </p>
          <p>
            Your article will appear in PMC approximately 2 to 14 days after submission.
          </p>
          {this.renderLandingCopy()}
        </div>
      )
    } else {
      return (
        <div className='modal-content'>
          {this.renderLandingHeader()}
          {this.renderLandingCopy()}
          <div className="row tile-container" data-equalizer="expedited" data-equalizer-mq="small-up">
            <Card 
              header="Expedited PMC Indexing"
              price={this.props.singlePrice}
              type="single"
              handleExpeditedPurchase={this.handleExpeditedPurchase}
              firstParagraph={"Purchase our expedited indexing service and we'll submit your article for PMC indexing in 1-3 business days."}
              secondParagraph={"Your article will appear in PMC approximately 2 to 14 days after submission."}
            />
            <Card 
              header="Expedited PMC Indexing Bundle (3 Credits)"
              price={this.props.bundlePrice}
              type="bundle"
              handleExpeditedPurchase={this.handleExpeditedPurchase}
              firstParagraph={"We'll submit this article for PMC indexing in 1-3 business days and you will have two remaining credits to use for other articles."}
              secondParagraph={"Credits expire 18 months after purchase."}
            />
          </div>
        </div>
      )
    }
  },
  
  render: function() {
    return (
      this.renderContents()
    )
  }
})

export default ExpeditedPmcModal;