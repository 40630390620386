import React from 'react';
var createReactClass = require('create-react-class');

const PublishedContentWidget = createReactClass({
  renderStats: function(section) {
    return (
      <table>
        <thead>
          <tr><td colSpan={"3"}><h6>{ section }</h6></td></tr>
        </thead>
        <tbody>
          { this.renderStatRows(this.props.stats[section]) }
        </tbody>
      </table>
    )
  },

  renderStatRows: function(stats){
    var self = this;
    var formattedRows = [];
    while(stats.length > 0){ formattedRows.push(stats.splice(0,3)); }
    if(formattedRows[formattedRows.length - 1]){
      while(formattedRows[formattedRows.length - 1].length < 3){ formattedRows[formattedRows.length - 1].push(undefined) }
    }
    return (
      formattedRows.map(function(group){
        return (
          <tr>
            {
              group.map(function(stat){
                return ( self.renderStatCel(stat) )
              })
            }
          </tr>
        )
      })
    )
  },

  renderStatCel: function(stat){
    if (stat != undefined){
      return (
        <td className="thirds">
           <h3 className='reg'>{stat.count}&nbsp;</h3>
           <h5 className='reg'>{stat.label}</h5>
        </td>
      )
    } else {
      return (
        <td></td>
      )
    }
  },

  render: function() {
    return (
      <table className="published-content-widget">
        <tbody>
          { this.renderStats("Submission") }
          { this.renderStats("First Time Submissions") }
          { this.renderStats("iThenticate/Media Deferrals") }
          { this.renderStats("Cureus Editing Deferrals") }
          { this.renderStats("Total Submissions Metrics") }
          { this.renderStats("Approval Editing") }
          { this.renderStats("Publication") }
        </tbody>
      </table>
    )
  }
})

export default PublishedContentWidget;