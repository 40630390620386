import React, { useEffect } from 'react';

const SideNavMenu = ( { title, sections, location } ) => {

  useEffect(() => {
    var navId = "#nav-section-title-" + location[0];
    var navEle = $(navId);
    if (navEle[0] && navEle[0].getAttribute('aria-expanded') !== 'true') {
      navEle.trigger('click');
    }
  })

  function renderItems() {
    return sections.map(function(section, idx) {
      var currentSection = section.section == location[0] ? "current" : ""
      return (
        <dd className={ "accordion-navigation " + currentSection} key={ "nav-section-" + idx}>
          <a id={ "nav-section-title-" + section.section } href={ "#nav-section-" + section.section } className={ "section-title" }>
            { section.title }
            <i className='accordion-caret'/>
          </a>
          <div id={ "nav-section-" + section.section} className={ "panel content" }>
            <ul className="section-pages">
              { renderPages(section.pages) }
            </ul>
          </div>
        </dd>
      )
    })
  }

  function renderPages(pages) {
    return pages.map(function(page) {
      var current = page.page == location[1] ? "current" : ""
      return (
        <li key={ "page-" + page.page }>
          <a className={ current } href={ `#!/${page.section}/${page.page}` }>{ page.title }</a>
        </li>
      )
    })
  }

  return (
    <React.Fragment>
      <div className='menu-title'>{ title }</div>
      <dl className={ "accordion" } data-accordion>
      { renderItems() }
      </dl>
    </React.Fragment>
  )

}

export default SideNavMenu;