import React, {useState, useEffect, useRef} from 'react';
import Tab from './Tab';

const TabComponent = ({url, tabList, setCards}) => {

  const [category, setCategory] = useState(['All']);
  // needs useRef
  const scrollContainer = useRef();
  const prevButton = useRef();
  const nextButton = useRef();
  const tabs = useRef();
  const leftArrow = useRef();
  const rightArrow = useRef();

  useEffect(() => {
    scrollContainer.current = document.querySelector('.scroll-container');
    prevButton.current = document.querySelector('.scroll-button.prev');
    nextButton.current = document.querySelector('.scroll-button.next');
    tabs.current = document.querySelectorAll('.tab');
    leftArrow.current = document.querySelectorAll('.left-arrow-image');
    rightArrow.current = document.querySelectorAll('.right-arrow-image');
  
    prevButton.current.addEventListener('click', scrollTabs.bind(null, 'left'));
    nextButton.current.addEventListener('click', scrollTabs.bind(null, 'right'));
    tabs.current.forEach(tab => tab.addEventListener('click', scrollToTab));
    scrollContainer.current.addEventListener('scroll', handleScroll);

    leftArrow.current[0].style.display = 'none';
  }, [])

  useEffect(() => {
    fetchCompetitions();
  }, [category])

  // offset width is width of container view
  const scrollTabs = (direction) => {
    const scrollDistance = direction === 'left' ? -scrollContainer.current.offsetWidth : scrollContainer.current.offsetWidth;
    scrollContainer.current.scrollBy({
      left: scrollDistance,
      behavior: 'smooth'
    });
  }
  
  // moves selected tab to left of scroll container
  const scrollToTab = (event) => {
    const selectedTab = event.currentTarget;
    const tabRect = selectedTab.getBoundingClientRect();
    const containerRect = scrollContainer.current.getBoundingClientRect();
    const scrollOffset = tabRect.left - containerRect.left;
    scrollContainer.current.scrollBy({
      left: scrollOffset,
      behavior: 'smooth'
    });
  }
  
  // 
  const handleScroll = () => {
    const maxScrollLeft = scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth;
    leftArrow.current[0].style.display = scrollContainer.current.scrollLeft > 0 ? 'block' : 'none';
    rightArrow.current[0].style.display = scrollContainer.current.scrollLeft < maxScrollLeft ? 'block' : 'none';
  }

  const fetchCompetitions = () => {
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      data: {
        type: category.map((item) => item.toLowerCase())
      },
      success: function(res) {
        setCards(res.cards);
      }
    });
  }

  return (
    <div className="tab-bar">
      <div className='scroll-button prev'>
        <img className='left-arrow-image' src="https://public.cureus.com/competitions_past_page/tab-arrow-left.png" />
      </div>
      <div className="scroll-container">
        <ul className='tab-list'>
          {tabList && tabList.map((tab) => {
            return <Tab title={tab} type={tab} setCategory={setCategory} activeTab={category[0]} src={`https://public.cureus.com/competitions_past_page/${tab.toLowerCase()}.png`} srcActive={`https://public.cureus.com/competitions_past_page/${tab.toLowerCase()}-blue.png`}/>
          })}
        </ul>
      </div>
      <div className='scroll-button next'>
        <img className='right-arrow-image' src="https://public.cureus.com/competitions_past_page/tab-arrow-right.png" />
      </div>
    </div>
  )
}

export default TabComponent;