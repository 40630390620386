import React from 'react';

var createReactClass = require('create-react-class');

const Quote = createReactClass({
  renderQuote: function(){
    return {
      3600: "This journal has revolutionized the process of publishing in medical literature. I can't ask for a better journal to publish in.",
      101424: "I love how easy the submissions process is and how easy it is to follow the instructions and edit the paper. I have submitted to Cureus many times. This is my favorite journal for publishing case reports.",
      13796: "The process of publishing in Cureus is easy, fair and, importantly, low cost. In addition, several of my published articles have been noted in the literature.",
      147961: "Smooth and intuitive submission to publication process. Transparency at every step."
    }
  },

  renderName: function() {
    let user = this.props.user
    if(user.id === 3600) {
      return (
        <React.Fragment>
          <h4>Prof. M Rajab, Ph.D., M.P.H.</h4>
          <h6>{user.affiliation_name_full}</h6>
        </React.Fragment>
      )
    } else if(user.id === 147961) {
      return (
        <React.Fragment>
          <h4>Ronald Delanois, M.D.</h4>
          <h6>{user.affiliation_name_full}</h6>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <h4>{user.full_name + (user.qualification_titles ? ',' : '')} {user.qualification_titles}</h4>
          <h6>{user.affiliation_name_full}</h6>
        </React.Fragment>
      )
    }
  },

  render: function(){
    let user = this.props.user
    return (
      <div className="row flex-container">
        <div className="large-2 columns show-for-large-up arrow-flex">
          <div className="channel-carousel-arrow-container">
            <i className="far fa-arrow-alt-circle-left"  onClick={() => this.props.handleNext('left')}></i>
          </div>
        </div>
        <div className="large-8 medium-12 small-12 columns">
          <div className="user-testimonial-container">
            <div className="quote-container">
              <i class="fas fa-quote-left"/>
              <p>{this.renderQuote()[user.id]}</p>
              <i class="fas fa-quote-right"/>
            </div>
            <div className="row">
              <div className="small-3 columns">
                <div className="avatar">
                  <img alt={user.full_name} src={user.avatar_profile_url} title={user.full_name}/>
                </div>
              </div>
              <div className="small-9 columns">
                {this.renderName()}
              </div>
            </div>
          </div>
        </div>
        <div className="large-2 columns show-for-large-up arrow-flex">
          <div className="channel-carousel-arrow-container">
            <i className="far fa-arrow-alt-circle-right" onClick={() => this.props.handleNext('right')}></i>
          </div>
        </div>
      </div>
    );
  }
})

export default Quote;