import React from 'react';

const UnacceptableFigureExamples = () => {
  return (
    <React.Fragment>
      <h1>Bad Figure Examples</h1>
      <p>The following figures were previously rejected - if your figures look like these figures, please address the issues prior to submitting your articles. Additional examples highlighting other common figure errors will be added soon.</p>
      <div className='small-font space-top-m'>
        <i className='fas fa-exclamation-triangle red space-right-xs'></i>
        <strong>Blurry</strong>
        <img className='space-top-xs' src='https://public.cureus.com/blurry_figure.png'/>
      </div>
      <div className='small-font space-top-m'>
        <i className='fas fa-exclamation-triangle red space-right-xs'></i>
        <strong>Photo of computer/tv screen</strong>
        <img className='space-top-xs' src='https://public.cureus.com/photo_of_screen.jpg'/>
      </div>
    </React.Fragment>
  )
}

export default UnacceptableFigureExamples;