import { set } from 'date-fns';
import React, {useEffect, useState} from 'react';

const Activities = ({ratingStars, contributedArticlesSize, completedReviewershipsSize, scoringsSize, activitiesPath}) => {

  const [activities, setActivities] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetchActivities()
  }, [])


  const fetchActivities = () => {
    $.ajax({
      type: 'GET',
      url: activitiesPath,
      dataType: 'JSON',
      success: function(res) {
        setActivities(res.activities)
        setLoading(false)
      }
    })
  }

  const renderSpinner = () => {
    if (loading) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  }

  const toggleExpanded = () => {
    if(activities.length <= 4) {
      return
    }

    var caratClass = expanded ? "fa fa-angle-up" : "fa fa-angle-down"
    var message = expanded ? "See less" : "See more"

    return (
      <div className="centered">
        <a onClick={ () => setExpanded(!expanded) }>
          {message} <i className={ caratClass }></i>
        </a>
      </div>
    )
  }

  return (
    <div>
      <ul class="tabs" data-tab="true">
        <li class="tab-title active">
          <a href="#stats" class="noscroll">Stats</a>
        </li>
        <li class="tab-title">
          {renderSpinner()}
          <a href="#achievements" class="noscroll">Achievements ({activities.length})</a>
        </li>
      </ul>
      <div class="tabs-content">
        <div class="content active" id="stats">
          <div class="row">
            <div class="medium-12 columns centered">
              <div class="stat-container">
                <div class="stat reviewer-stat-rating">
                  <div class="reviewer-rating" dangerouslySetInnerHTML={{__html: ratingStars}}></div>
                  <img class="question-mark-icon reviewer-question-icon tip-top" data-tooltip="" src="https://public.cureus.com/homepage_new/question_mark.png" title="Your reviewer rating is based on scores assigned by submitting authors prior to publication." />
                </div>
                <div class="small-description">Reviewer Rating</div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="large-4 small-4 columns">
              <div class="stat">
                <div class="reg">
                  {contributedArticlesSize}
                </div>
              </div>
              <div class="small-description">Published</div>
            </div>
            <div class="large-4 small-4 columns">
              <div class="stat">
                <div class="reg">
                  {completedReviewershipsSize}
                </div>
              </div>
              <div class="small-description">Reviews</div>
            </div>
            <div class="large-4 small-4 columns">
              <div class="stat">{scoringsSize}</div>
              <div class="small-description">Rated</div>
            </div>
          </div>
        </div>
        <div class="content" id="achievements">
          <div id="achievements-container" class={expanded ? 'expand' : 'collapse'}>
            <div class="row">
              {activities.map((act, index) => (
                <div class={`large-3 medium-4 columns ${index == activities.size -1 ? 'end' : ''}`}>
                  <div class="achievement">
                    <div class="achievement-badge">
                      <div class={`sprite ${act.sprite_class}`}></div>
                    </div>
                    <small>{act.achievement_name}</small>
                    {act.target_title && (
                      <div>
                        <br/>
                        <small><a href={act.title_url} target="_blank">{act.truncated_title}</a></small>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div class="row">
            <div class="large-12 columns">
              {toggleExpanded()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Activities;