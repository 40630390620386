import React, { useState, useEffect } from 'react';
import TOCLink from "./TOCLink"
import Carat from "./Carat"

const TableOfContents = ({tableOfContents}) => {
  const [activeSection, setActiveSection] = useState('')
  const [showNav, setShowNav] = useState(false)

  const renderNav = () => {
    return (
      <ul className="nav no-margin">
        {renderSectionTitle()}
      </ul>
    )
  }

  const renderSectionTitle = () => {
    return tableOfContents.map ( (title, idx) => {
      return <TOCLink
        title={title}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        tableOfContents={tableOfContents}
        key={idx}/>
    })
  }

  return (
      <div className="row small-collapse">
        <div className="column small-12 toc">
            <div className="collapsible" onClick={() => {setShowNav(!showNav)}}>
              <h1>Table of Contents</h1>
              {<Carat showNav={showNav} setShowNav={setShowNav}/>}
            </div>
              {showNav && renderNav()}
        </div>
      </div>
  )
}

export default TableOfContents