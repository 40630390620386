import React, { useState, useEffect } from 'react';

const TextField = ({uniqueName, handleTextFieldChange, textFieldValue, contentTypeIdFound}) => {

  const capitalize = () => {
    return uniqueName.charAt(0).toUpperCase() + uniqueName.slice(1);
  }

  const onChangeText = (textValue) => {
    handleTextFieldChange(textValue);
  }

  return (
    <div>
      <div className="input-container columns">
        <label>{`${capitalize()} ID`}</label>
        <input id={`${uniqueName}-id`} type="text" onChange={(e) => onChangeText(e.target.value)} value={textFieldValue} />
        {contentTypeIdFound == false && <p className='id-error-message'>Content ID is incorrect or not yet published.</p>}
      </div>
    </div>
  )
}

export default TextField;