import {Cureus} from '../Cureus';
// takes in id as selector and event name
Cureus.HamlAmplitude = function(selector, eventName, userId) {
  document.getElementById(selector).addEventListener('click', function(e) {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: {
        event_type: eventName,
        user_id: userId
      }
    })
  });
}
