import React, { useState, useEffect } from 'react';
import AnswerBarRow from './AnswerBarRow';

const AnswerOutcome = ({handleAnswer, answers, answersTotal, correctAnswerId, userAnsweredId}) => {

  const percent = (count, answersTotal) => {
    return Math.floor((count/answersTotal) * 100) + '%'
  }

  const renderImageText = () => {
    if(correctAnswerId == userAnsweredId) {
      return (
        <div className="large-6 medium-12 small-12 columns">
          <div className='text-emoji-container'>
            <h3 className='green-h3-text'>Correct</h3>
            <h3 className='h3-margin-bottom'>You nailed it. Great job!</h3>
            <img src='https://public.cureus.com/homepage_new/picture_prognosis_correct.png'/>
          </div>
        </div>
      )
    } else if(!userAnsweredId) {
      return (
        <div className="large-6 medium-12 small-12 columns">
          <div className='text-emoji-container'>
            <h3 className='black-h3-text'>Do you know the answer?</h3>
            <h3 className='h3-margin-bottom'>Make your choice and let's find out!</h3>
            <img src='https://public.cureus.com/homepage_new/picture_prognosis_unanswered.png'/>
          </div>
        </div>
      )
    } else {
      return (
        <div className="large-6 medium-12 small-12 columns">
          <div className='text-emoji-container'>
            <h3 className='red-h3-text'>Incorrect</h3>
            <h3 className='h3-margin-bottom'>Unfortunately, you did not</h3>
            <h3 className='h3-margin-bottom'>select the right answer.</h3>
            <img src='https://public.cureus.com/homepage_new/picture_prognosis_incorrect.png'/>
          </div>
        </div>
      )
    }
  }


  return (
    <div className='results-container small-12 columns'>
      {renderImageText()}
      <div className="large-6 medium-12 small-12 columns">
        <div className='percent-bars-container'>
          {answers.map((answer, idx) => {
            return <AnswerBarRow key={idx} answer={answer} percent={percent(answer.count, answersTotal)} percentNumber={percent(answer.count, answersTotal)} correctAnswerId={correctAnswerId} userAnsweredId={userAnsweredId} handleAnswer={handleAnswer}/>
          })}
        </div>
      </div>
      <span className="pp-note">Note: Only first answer attempts are recorded and calculated above.</span>
    </div>
  );
}

export default AnswerOutcome;