import React, { useState, useEffect, useRef } from 'react';
import _ from "lodash";

const ChannelTeamSidebar = ({handleAddWidget, widgetSidebarId, widget, handleUpdateWidget, slug}) => {
  const [channelMemberEmails, setChannelMemberEmails] = useState([])
  const [email, setEmail] = useState(widget ? widget.email : null)
  const [title, setTitle] = useState(widget ? widget.title : "")
  const [errorMessage, setErrorMessage] = useState("")
  const [disabled, setDisabled] = useState(null)
  const [hasValidEmail, setHasValidEmail] = useState(widget ? widget.email : null)

  useEffect(() => {
    fetchEmails()
  }, [])

  useEffect(() => {
    handleDisableButton()
  }, [title, email])

  const fetchEmails = () => {
    $.ajax({
      type: 'GET',
      url: `/admin/channels/${slug}/fetch_emails`,
      dataType: "JSON",
      success: function(res) {
        setChannelMemberEmails(res.emails)
      }
    })
  }

  const handleDisableButton = () => {
    setDisabled((hasValidEmail && title) ? '' : 'disabled')
  }

  const handleSetEmail = (value) => {
    setEmail(value)
    debouncedSearch(value)
  }

  const checkEmailIsValid = async(email) => {
    $.ajax({
      type: 'GET',
      url: `/admin/custom_content/check_user`,
      data: { content_type: "User", content_field: "email", content_value: email, channel_id: slug},
      dataType: "JSON",
      success: function() {
        setHasValidEmail(true)
        setErrorMessage('')
      },
      error: function() {
        setHasValidEmail(false)
        setErrorMessage("There is no Cureus account associated with this email address.")
      }
    })
  }

  const debouncedSearch = useRef(
    _.debounce(async (email) => {
      await checkEmailIsValid(email)
    }, 500, {'leading': false, 'trailing': true})
  ).current;

  const handleAddWidgetWrapper = () => {
    let params;
    params = {
    widget: {
        type: "Widget::ChannelTeamSidebar",
        parent_id: widgetSidebarId,
        title: title,
        email: email
      }
    }
    if(handleAddWidget) {
      handleAddWidget(params, false)
    } else {
      handleUpdateWidget(widget.widgetId, params, false)
    }
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Channel Member Showcase</h5>
            <hr/>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <p>Showcase a single channel admin, editor or author. A Cureus account is required in order to be added.</p>
            </div>
          </div>
          <div className="row">
          <div className="small-12 columns">
            <div className="input-container">
              <label>Email address</label>
              <input className="datalist-input" type="email" name="email" autoComplete="off" list="memberEmail" placeholder="Please add the email address associated with their Cureus account." onChange={(e) => handleSetEmail(e.target.value)} value={email}/>
              <datalist id="memberEmail">
                {channelMemberEmails.map((email) => {
                  return <option key={email} value={email}>{email}</option>
                })}
              </datalist>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <div className="input-container">
              <label>Title</label>
              <input type="text" name="title" value={title} placeholder="E.g. Author of the Month, Department Chair, etc." onChange={(e) => setTitle(e.target.value)}/>
              </div>
            </div>
        </div>
          <div className='modal-footer text-center'>
            <a className={"button primary " + disabled} onClick={handleAddWidgetWrapper}>Add</a>
            {disabled && <p className="error-message text-left">{errorMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}


export default ChannelTeamSidebar;