import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFirstMountState } from "../../hooks/useFirstMountState";
import CategorySidebar from '././Sidebars/CategorySidebar';
import RightSideBar from '././Sidebars/RightSideBar';
import Ad from '../Shared/Ad';
import BrowseContents from '././BrowseContents/BrowseContents';
import { isEqual } from 'date-fns';
import { useCategoryParams, useSearchParamsState } from '../../hooks/useSearchParamsState';

const Index = ({
  categories,
  dateRanges,
  bannerAdTop,
  contentTypeTitle,
  contentType,
  title
}) => {
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState([]);
  const [searchTerm, setSearchTerm] = useSearchParamsState()
  const [submitCallback, setSubmitCallback] = useState(false);
  const [categorySelection, setCategorySelection] = useCategoryParams(categories);
  const [selectedSort, setSelectedSort] = useState({
    attr: "published_at",
    dir: "desc",
    text: "Newest",
  });
  const [refreshKeyWords, setRefreshKeyWords] = useState([0]);
  const [paginationData, setPaginationData] = useState({});
  const [perPage, setPerPage] = useState(20);
  const [pageCountOverview, setPageCountOverview] = useState("");
  const [page, setPage] = useState(1);
  const [dateData, setDateData] = useState({
    fromDate: dateRanges?.from || "",
    toDate: dateRanges?.to || "",
    dateRanges: dateRanges?.text || "",
    isActive: false,
  });

  const isFirstMount = useFirstMountState();

  useEffect(function onInitialLoad() {
    if (isFirstMount) {
      handleSubmit();
      if (typeof bannerAdTop !== "object") {
        $("#banner-ad-1").append(bannerAdTop);
      }
    }
  }, []);

  // used to fetchData on the following prop changes
  useEffect(function fetchData() {
    if (!isFirstMount) {
      handleSubmit();
    }
  }, [perPage, dateData, page, selectedSort]);

  // used to fetchData only after state has finished setting
  // (i.e. clicking abstract-category should setCategoryState and handleSubmit)
  useEffect(function fetchDataCallback() {
    if (!isFirstMount && submitCallback) {
      handleSubmit();
    }
  }, [submitCallback]);

  const selectCategory = (event, name, id = null) => {
    event.preventDefault();
    if (name === "allCategories") {
      return setCategorySelection({ allCategories: true });
    }

    const newCategorySelection = { ...categorySelection };
    if (!Object.keys(categorySelection).includes(`${id}`)) {
      delete newCategorySelection.allCategories;
      setCategorySelection({ ...newCategorySelection, [id]: name });
    } else {
      delete newCategorySelection[id];
      setCategorySelection(newCategorySelection);
    }
  };

  const formatSort = () => {
    if (selectedSort.value) {
      let sortHash = JSON.parse(selectedSort.value);
      return { [sortHash.attr]: sortHash.dir };
    } else {
      let sortHash = selectedSort;
      return { [sortHash.attr]: sortHash.dir };
    }
  };

  const buildParams = () => {
    let newCategories = !categorySelection["allCategories"]
      ? { ...categorySelection }
      : {};
    let params = {
      categories: newCategories,
      filters: {},
      order: formatSort(),
      page: page,
      per_page: perPage,
      q: searchTerm,
      types: {},
    };
    if (dateData.isActive) {
      const newDateParams = {
        from_date: dateData.fromDate,
        to_date: dateData.toDate,
        date_picker_active: dateData.isActive,
      };
      params = { ...params, ...newDateParams };
    }
    return params;
  };

  const updateSearch = (event, keyword = "") => {
    if (keyword) {
      setSearchTerm(keyword);
      setSubmitCallback(true);
    } else {
      event.preventDefault();
      setSearchTerm(event.target.value);
    }
  };

  const selectCategoryAndSubmit = (name, id) => {
    setCategorySelection({ [id]: name });
    setSubmitCallback(true);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!isFirstMount) {
      setRefreshKeyWords(updateTriggerRightSideBar());
    }
    $.ajax(
      {
        method: "GET",
        url: `/${contentType}`,
        data: buildParams(),
        dataType: "json",
        success: function (res) {
          setContents(res.contents);
          setPageCountOverview(res.pageCountOverview);
          setPaginationData(res.paginationData);
          setLoading(false);
          setSubmitCallback(false);
        },
        error: function (res) {
          console.log("error: ", res);
        },
      },
      "json",
    );
  };

  const handleDateRangeSubmit = (fromDate, toDate, selectedRange, isActive) => {
    setDateData({
      fromDate,
      toDate,
      dateRanges: selectedRange,
      isActive,
    });
  };

  const renderBannerAd = (props, id) => {
    if (typeof props === "object") {
      return (
        <div id={id}>
          <div className="cureus-ad-wrapper page-banner-ad">
            <Ad props={props} size="banner" />
          </div>
        </div>
      );
    } else {
      return <div id={id} />;
    }
  };

  const updateSortDirection = (dir) => {
    const newDirectionState = dir === "desc" ? "asc" : "desc";
    setSelectedSort({
      attr: "published_at",
      dir: newDirectionState,
      text: "Newest",
    });
  };

  const updatePage = (page) => {
    setPage(page);
  };

  const updatePerPage = (value) => {
    setPerPage(value);
    // Need to reset user back to first page in-case entries do not exist on the current page;
    setPage(1);
  }

  const updateTriggerRightSideBar = () => {
    if (categorySelection.allCategories) {
      return [0];
    }

    const categorySelectionIds = Object.keys(categorySelection).map((key) =>
      parseInt(key),
    );
    if (isEqual(categorySelectionIds, refreshKeyWords)) {
      return refreshKeyWords;
    } else {
      return categorySelectionIds;
    }
  };

  return (
    <div className="browse-index">
      <div className="row small-collapse">
        <CategorySidebar
          categories={categories}
          categorySelection={categorySelection}
          handleSubmit={handleSubmit}
          updateCategory={selectCategory}
        />
        <BrowseContents
          contents={contents}
          contentType={contentType}
          title={title}
          bannerAdTopProps={bannerAdTop}
          dateData={dateData}
          handleDateRangeSubmit={handleDateRangeSubmit}
          loading={loading}
          onSubmit={handleSubmit}
          pageCountOverview={pageCountOverview}
          paginationData={paginationData}
          renderBannerAd={renderBannerAd}
          searchTerm={searchTerm}
          selectCategoryAndSubmit={selectCategoryAndSubmit}
          perPage={perPage}
          selectedSort={selectedSort}
          setPerPage={updatePerPage}
          updatePage={updatePage}
          updateSearch={updateSearch}
          updateSortDirection={updateSortDirection}
        />
        <RightSideBar
          handleKeyWordClick={updateSearch}
          categorySelection={categorySelection}
          refreshKeyWords={refreshKeyWords}
          contentTypeTitle={contentTypeTitle}
          contentType={contentType}
        />
      </div>
    </div>
  );
};

Index.propTypes = {
  categories: PropTypes.array,
  dateRanges: PropTypes.object,
  bannerAdTop: PropTypes.any,
  contentTypeTitle: PropTypes.string,
  contentType: PropTypes.string,
  title: PropTypes.string,
}

export default Index;
