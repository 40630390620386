import React, {useState, useEffect} from 'react';
import { Cureus } from '../../Cureus';
import Modal from '../Modal';
import DeskRejectModal from './DeskRejectModal';

const Main = ({articleId, populateText, reasonsData, tooltip, disabledClass, fromDeskRejectActionBtn, adminArticlePath}) => {

  const openDeskRejectModal = () => {
    Modal.open({
      children: <DeskRejectModal 
        articleId={articleId}
        populateText={populateText}
        reasonsData={reasonsData}
        adminArticlePath={adminArticlePath}
      />
    })
  }

  const renderButtonOrText = () => {
    return fromDeskRejectActionBtn ? disabledClass : "button reject-btn " + disabledClass;
  }

  return (
    <a
      className={"desk-rejection-button " + renderButtonOrText()}
      onClick={openDeskRejectModal}
      title={tooltip ? tooltip : ""}
      disabled={disabledClass ? true : false}
    >
      Desk reject
    </a>
  )
}

export default Main;