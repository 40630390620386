import React, { useState, useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import ActivityLog from './ActivityLog';
import Snapshot from './Snapshot';
import Concern from './Concern';
import Other from './Other';
import Tabs from '../Shared/Tabs';

const Main = ({userEmail}) => {
  // useEffect(() => {
  //   console.log(location.pathname);
  // }, []);

  const checkboxObj = {
    title: '',
    data: null,
    class: 'centered',
    orderable: false,
    render: (row) => `<input type="checkbox" data-resource-id="${row.editor_id}" class="row-checkbox" />`,
  }

  const tabs = [
    { path: '/activity_log', label: 'ACTIVITY LOG', component: <ActivityLog userEmail={userEmail} checkboxObj={checkboxObj}  /> },
    { path: '/snapshot', label: 'CURRENT SNAPSHOT', component: <Snapshot userEmail={userEmail} checkboxObj={checkboxObj}/> },
    { path: '/concern', label: 'POINTS OF CONCERN', component: <Concern userEmail={userEmail} checkboxObj={checkboxObj} /> },
    { path: '/other', label: 'OTHER STATS', component: <Other /> },
  ];

  return (
    <Router hashType="hashbang">
      <Tabs tabs={tabs} />
    </Router>
  );
};

export default Main;