var createReactClass = require('create-react-class');
import React from 'react';
import EditReference from './EditReference';
import DisplayReference from './DisplayReference';

const ReferenceRow = createReactClass({
  getInitialState: function() {
    return {
      isEditing: false,
    }
  },

  handleDelete: function() {
    var self = this;

    if(window.confirm("Are you sure you want to do this? This action is irreversible!")) {
      self.props.disableEditing()
      $.ajax({
        type: "DELETE",
        url: self.props.reference.delete_path,
        success: function() {
          self.props.onDelete();
        }
      })
    }
  },

  renderDoiStatus: function() {
    var text;
    if (this.doiStatus()) {
      text = "Missing DOI or URL"
    } else if (this.props.isEditor && this.props.parsedReferences && !this.props.reference.updated_after_parse || this.props.reference.duplicate_reference || this.props.reference.incorrect_doi_format) {
      if(this.props.reference.duplicate_reference) {
        text = "Duplicate reference"
      } else if(!this.props.parsedReferences && this.props.reference.incorrect_doi_format) {
        text = this.props.reference.incorrect_doi_format
      } else if (this.props.reference.no_doi) {
        text = "No ID present to fetch metadata"
      } else if (this.props.reference.invald_parsed_reference) {
        text = "Failed to parse citation metadata"
      } else if (this.props.reference.fields_changed && this.props.reference.fields_changed.all) {
        text = "All fields changed, except DOI"
      } else if(this.props.reference.fields_changed && this.props.reference.fields_changed.num_changed > 0) {
        text = this.props.reference.fields_changed.num_changed + " fields changed"
      }
    }
    if (text) {
      return <span className='missing-doi'>{text}</span>
    }
  },

  doiStatus: function() {
    var fields = this.props.reference.fields.filter(function(field) {
      return field.name === 'doi' || field.name === 'url';
    });
    if (fields.length && !this.props.reference.omit_doi && fields.every(function(el) { return (!el.value || !el.value.length) })) {
      return true;
    } else {
      return false;
    }
  },

  handleCheck: function() {
    this.props.onCheck(this.props.reference, this.props.checked);
  },

  handleEdit: function() {
    this.setState({isEditing: true});
  },

  scrollOnFinishEdit: function() {
    var id = 'ref-' + this.props.reference.position 
    var elmnt = document.getElementById(id)
    elmnt.scrollIntoView();
  },

  handleUpdate: function() {
    this.setState({isEditing: false}, function() {
      this.scrollOnFinishEdit();
    });
    this.props.onUpdate();
  },

  handleCancel: function() {
    this.setState({isEditing: false}, function() {
      this.scrollOnFinishEdit();
    });
  },

  render: function() {
    if(this.state.isEditing) {
      return <EditReference
        reference={ this.props.reference }
        handleUpdate={ this.handleUpdate }
        handleCancel={ this.handleCancel }
        handleCheck={ this.handleCheck }
        checked={ this.props.checked }
        handleDelete={ this.handleDelete }
        fieldData={ this.props.fieldData }
        optionsForReferenceSelect={ this.props.optionsForReferenceSelect }
        handleUpdatePosition={ this.props.onUpdatePosition }
        isEditor={ this.props.isEditor }
        fetchParsedReferencePath={ this.props.fetchParsedReferencePath }
        revertReferencePath={ this.props.revertReferencePath }
        parsedReferences={ this.props.parsedReferences } 
        scrollOnFinishEdit={ this.scrollOnFinishEdit }/>
    } else {
      return <DisplayReference
        reference={ this.props.reference }
        handleEdit={ this.handleEdit }
        handleCheck={ this.handleCheck }
        checked={ this.props.checked }
        doiStatus={ this.renderDoiStatus() }
        handleDelete={ this.handleDelete }/>
    }
  }
});

export default ReferenceRow;