import React, { useState, useEffect } from 'react';
import DeleteButton from '../../DeleteButton';
import UpdateButton from '../../UpdateButton';
import TeamMemberCard from './TeamMemberCard';

const TeamMemberRow = ({content, handleDeleteWidget, handleUpdateWidget, mainContentWidgets, showActionButtons=false, slug}) => {

  const renderActionButtons = () => {
    if (showActionButtons) {
     return (
      <>
        <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
        <UpdateButton
          handleUpdateWidget={handleUpdateWidget}
          widget={content}
          modalName={"ChannelTeam"}
          mainContentWidgets={mainContentWidgets}
          slug={slug}
        />
        <DeleteButton
          handleDeleteWidget={handleDeleteWidget}
          widgetId={content[0].widgetId}
        />
      </>
     )
    }
  }

  const renderMembers = () => {
    return content.map((member) => {
      return <TeamMemberCard key={member.id} member={member} />
    })
  }

  return (
    <div className="row">
      <div className="team-member-row">
        {renderActionButtons()}
        {renderMembers()}
       </div>
    </div>
  )
}

export default TeamMemberRow;