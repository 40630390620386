import React, { useState, useEffect } from 'react';
import Invited from './ReviewerTableStates/Invited'
import Reviewing from './ReviewerTableStates/Reviewing'

const ReviewerTableMain = ({reviewershipsPath, createPath, templatePath, reviewershipsTotal, reviewingTotal, invitedTotal}) => {

  const [activeTab, setActiveTab] = useState('invited')
 
  useEffect(() => {
    $('input[type=checkbox]').prop('checked', false)
    $('.bulk-email-button-reviewers-table').off('click')
    $('.bulk-email-button-reviewers-table').addClass('disabled')
  }, [activeTab])

  const renderContents = () => {
    if(activeTab === 'invited') {
      return <Invited reviewershipsPath={reviewershipsPath} createPath={createPath} templatePath={templatePath}/>
    }
    if(activeTab === 'reviewing') {
      return <Reviewing reviewershipsPath={reviewershipsPath} createPath={createPath} templatePath={templatePath}/>
    }
  }

  const renderEmailButton = () => {
    return (
      <div className="button primary small bulk-email-button bulk-email-button-reviewers-table disabled has-tip tip-left" id={"mail-tooltip-reviewing"} data-tooltip="" title="Select one or more checkboxes and click this button to send email reminders.">
        <i className="fa fa-envelope"></i>
      </div>
    )
  }


  return (
    <React.Fragment>
      <h5 className="articles-header">Reviewer action needed <span>({reviewershipsTotal})</span></h5>
      <div className="small-12 columns reviewers-table-container">
        {renderEmailButton()}
        <div className="row">
          <div className="large-12 columns secondary-tabs-channel">
            <div className="cureus-tabs secondary-tabs">
              <div className="tabs-container">
                <div className="tab-items">
                  <ul id="reviewer-actions-nav">
                    <li className={"tab-title inline " + (activeTab === 'invited' ? 'active' : '')} onClick={() => setActiveTab('invited')}>
                      <a className="dashboard">Invited ({invitedTotal})</a>
                    </li>
                    <li className={"tab-title inline " + (activeTab === 'reviewing' ? 'active' : '')} onClick={() => setActiveTab('reviewing')}>
                      <a className="dashboard">Reviewing ({reviewingTotal})</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderContents()}
      </div>
    </React.Fragment>
  )

}

export default ReviewerTableMain;