import React, { useState, useEffect } from 'react';

const OverviewModalInput = ({label, placeholder, value, setState}) => {

  const renderInputOrTextArea = () => {
    if(label === 'Description') {
      return <textarea value={value} placeholder={placeholder} onChange={(e) => setState(e.target.value)} />
    } else {
      return <input type='text' value={value} placeholder={placeholder} onChange={(e) => setState(e.target.value)}/>
    }
  }

  return (
    <div className="row">
      <div className="small-12 columns">
        <div className="input-container">
          <label>{label}</label>
          {renderInputOrTextArea()}
        </div>
      </div>
    </div>
  )
}

export default OverviewModalInput;