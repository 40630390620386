import React from 'react';
import Card from '../Boost/Card';
const SocialBoostLandingPage = props => {

  const renderStandardCard = () => {
    if(!props.subsequentBoost) {
      return (
        <Card
          header="Standard"
          price={60}
          blurb="Organic social media promotion: Facebook, Instagram, LinkedIn, and Twitter."
          footer="700-1,000+ additional article views"
          handleSelection={props.handleBoostPurchase}
          type="standard"
        />
      )
    }
  }

  return (
    <div>
      <h2 className="landing-header">Purchase Article Promotion Service</h2>
      <h4 className="reg">“{props.title}”</h4>
      <p>Build your reputation, increase your chances of being cited, and ensure that your work is widely read with a Cureus Article Promotion package.</p>
      {props.renderLandingPageCopy()}
      <h5>Enter relevant social handles (optional)</h5>
      <p>Please provide the Twitter and Instagram handles that you would like tagged in our posts. Multiple handles should be separated by commas.</p>
      <div className="row payment-form-section">
        <div className='small-12 columns no-padding-left'>
          <label>
            Your and/or your co-authors’ Twitter(s)
          </label>
          <input type="text" name="twitter" onChange={props.handleChange}/>
          <label>
            Your and/or your co-authors’ Instagram(s)
          </label>
          <input type="text" name="instagram" onChange={props.handleChange}/>
          <label>
            Academic Institution/ Hospital Twitter
            <input type="text" name="aff_twitter" onChange={props.handleChange}/>
          </label>
          <label>
            Academic Institution/ Hospital Instagram
            <input type="text" name="aff_instagram" onChange={props.handleChange}/>
          </label>

        </div>
      </div>
      <div className="row tile-container" data-equalizer="blurb" data-equalizer-mq="small-up">
        {renderStandardCard()}

        <Card
          header="Premium"
          price={99}
          blurb="Organic social media promotion plus a targeted paid social media ad campaign driving traffic to your article based on keywords, geo-targeting, and demographics."
          footer="1,500-2,000+ additional article views"
          handleSelection={props.handleBoostPurchase}
          type="premium"
          subsequentBoost={props.subsequentBoost}
        />
        <Card
          header="Premium Plus"
          price={199}
          blurb="Reach new heights: organic social media promotion plus a larger targeted paid social media ad campaign driving even more traffic to your article based on keywords, geo-targeting, and demographics."
          footer="2,600-3,000+ additional article views"
          handleSelection={props.handleBoostPurchase}
          type="premium-plus"
          subsequentBoost={props.subsequentBoost}
        />
        <Card
          header="Maximum"
          price={499}
          blurb="The ultimate article promotion: organic social media posting and the largest targeted paid social media ad campaign driving the most traffic to your article based on keywords, geo-targeting, and demographics."
          footer="6,000+ additional article views"
          handleSelection={props.handleBoostPurchase}
          type="maximum"
          subsequentBoost={props.subsequentBoost}
          lastCard={true}
        />
      </div>
      <div className='text-center'>
        <p>Your article will appear on our social channels 4-5 days after purchase. The results of your boost will be provided within 45 days of purchase.</p>
        <p><small><strong>*Please note: articles containing images of genitalia are not eligible for Article Promotion due to social media image policies.</strong></small></p>
      </div>
    </div>
  )
}

export default SocialBoostLandingPage;