import React from 'react';

const Card = ({ type, label, title, body }) => {
  const labelElement = label ? <span className="featured">{ label }</span> : null

  function renderParagraph() {
    if(body.textTwo) {
      return (
        <React.Fragment>
            <p style={{marginBottom: '8px'}}>{ body.text }</p>
            <p>{ body.textTwo }</p>
        </React.Fragment>
      )
    }
    return (
      <p>{ body.text }</p>
    )
  }

  function renderList() {
    return (
      <ul>
        {body.items.map(function(item, idx) {
          return (
            <React.Fragment key={ idx }>
              <li className='space-top-20'><a href={ `${title.href}#!/${item.page}`}>{ item.text }</a></li>
            </React.Fragment>
          )
        })}
      </ul>
    )
  }

  function renderCard() {
    switch(type) {
      case 'paragraph':
        return renderParagraph();
      case 'list':
        return renderList();
    }
  }

  return (
    <div className={`card ${type} large-12 columns`}>
      <div className='small-11 columns'>
        { labelElement }
        <a href={ title.href }><span className='title'>{ title.text }</span></a>
        { renderCard() }
      </div>
      <a href={ title.href }><i className="fas fa-arrow-right fa-lg"></i></a>
    </div>
  )
};

export default Card;