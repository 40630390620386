import {Cureus} from '../Cureus';

Cureus.AdsNew = function(options) {
  if (this.skipSquareAd(options)) { return; }
  this.options = this.updateOptions(options);

  this.inited = false;
  this.plt = this.options.mdv == 'null' ? 'desktop' : 'mobileweb';
  this.MP_U = this.getCookie("MP_U") || [];
  this.MP_prof = this.getCookie("MP_prof") || [];
  this.MPT = this.getCookie("MP_tval") || [];
  this.specCookie = this.getCookie('MP_spec') || this.options.spec;

  if (!window.cureusAds) { window.cureusAds = []; }
  window.cureusAds.push(this)
}

Cureus.AdsNew.prototype.skipSquareAd = function(options) {
  if (options.size === 'square') {
    if (document.documentElement.clientWidth < 1024 && options.page !== 'registration') {
      return true;
    } else if (document.documentElement.clientWidth < 640 && options.page === 'registration') {
      return true;
    }
  }
  return false;
}

Cureus.AdsNew.prototype.initLazyLoad = function() {
  var self = this;
  if (this.inView()) {
    this.inited = true;
  } else {
    document.addEventListener("touchmove", function(event) { 
      self.checkScroll();
    });
    window.addEventListener('scroll', function(event) {
      self.checkScroll();
    })
  }
}

Cureus.AdsNew.prototype.checkScroll = function() {
  if (this.inited) { return; }
  if (this.inView()) {
    this.inited = true;
  }
}

Cureus.AdsNew.prototype.updateOptions = function(options) {
  if (document.documentElement.clientWidth < 728 && options.size === 'banner') {
    var newId = 'edh-square-' + options.altDiv;
    options.size = 'square';
    options.slot = options.altSlot;
    options.divNum = options.altDiv;
    options.dims = options.altDims;
  }
  return options;
}

Cureus.AdsNew.prototype.getCookie = function(cookiename) {
  var cookiestring=RegExp(""+cookiename+"[^;]+").exec(document.cookie);
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
}

Cureus.AdsNew.prototype.inView = function() {
  var elId = 'edh-' + this.options.size + '-' + this.options.divNum;
  var el = document.getElementById(elId);
  var bounding = el.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}