var createReactClass = require('create-react-class');
import React from 'react';
import EditorDropdown from './EditorDropdown';
import Table from './Table';
import { Cureus } from '../../Cureus';

const Main = createReactClass({
  getInitialState: function() {
    return({
      currentCopy: [],
      currentApproval: [],
      currentChannelPreview: [],
      currentChannelApproval: [],
      currentSubmissions: [],
      availableCopy: [],
      availableChannelPreview: [],
      availableChannelApproval: [],
      availableApproval: [],
      availableSubmissions: [],
      availableSeniorCopy: [],
      userInfo: {}
    })
  },

  componentDidMount: function() {
    var self = this;
    $.ajax({
      type: "get",
      url: `/admin/articles/${self.props.articleId}/article_editors`,
      dataType: "json",
      success: function(data) {
        self.setState({...data})
      }
    })
  },

  addEditor: function(data) {
    var self = this;
    $.ajax({
      type: 'post',
      url: self.props.ownershipPath,
      data: Object.assign(data, {id: self.props.articleId, type: data.type}),
      success: self.addEditorToState,
      error: function(res) {
        window.location.reload()
      }
    })
  },

  addEditorToState: function(res) {
    var self = this;
    var userInfo = {...this.state.userInfo}
    userInfo.ownershipIds = userInfo.ownershipIds.concat(res.editor.ownership_id)
    if (res.type === 'Copy') {
      self.setState({
        currentCopy: self.state.currentCopy.concat(res.editor),
        availableCopy: self.state.availableCopy.filter(function(el) { return el.id !== res.editor.user_id}
        ),
        userInfo: userInfo
      })
    } else if (res.type === 'Approval') {
      self.enableApprovalEditorEditorialButtons();
      self.setState({
        currentApproval: self.state.currentApproval.concat(res.editor),
        availableApproval: self.state.availableApproval.filter(function(el) { return el.id !== res.editor.user_id}),
        userInfo: userInfo
      })
    } else if (res.type === 'Submissions') {
      self.setState({
        currentSubmissions: [res.editor],
        availableSubmissions: self.state.availableSubmissions.filter(function(el) { return el.id !== res.editor.user_id}),
        userInfo: userInfo
      })
    } else if (res.type === 'Channel Preview') {
      self.setState({
        currentChannelPreview: [res.editor],
        availableChannelPreview: self.state.availableChannelPreview.filter(function(el) { return el.id !== res.editor.user_id}),
        userInfo: userInfo
      })
    } else if (res.type === 'Channel Approval') {
      self.setState({
        currentChannelApproval: [res.editor],
        availableChannelApproval: self.state.availableChannelPreview.filter(function(el) { return el.id !== res.editor.user_id}),
        userInfo: userInfo
      })
    }
  },

  destroy: function(data) {
    var self = this;
    $.ajax({
      type: 'delete',
      url: self.props.ownershipPath + "/" + data.ownership_id,
      success: function(res) {
        self.destroyOwnershipInState(res)
      },
      error: function(res) {
        Cureus.Flash.alert('There was an issue deleting the editor state, please try later.')
      }
    })
  },

  destroyOwnershipInState: function(res) {
    var self = this;
    var newState;
    switch(res.type) {
      case "Copy":
        newState = this.removeStateHelper(this.state.currentCopy, res);
        this.setState({currentCopy: newState, availableCopy: self.state.availableCopy.concat(res.editor)}, function() { self.enableDisableApproveReviewOrPublicationButton("currentCopy")} )
        break;
      case "Approval":
        newState = this.removeStateHelper(this.state.currentApproval, res);
        this.setState({currentApproval: newState, availableApproval: self.state.availableApproval.concat(res.editor)})
        break;
      case "Submissions":
        newState = this.state.currentSubmissions.includes(res.editor) ? this.removeStateHelper(this.state.currentSubmissions, res) : []
        let availableSubmissions = res.editor.user_id === 421 ? self.state.availableSubmissions : self.state.availableSubmissions.concat(res.editor)
        this.setState({currentSubmissions: newState, availableSubmissions: availableSubmissions})
        break;
      case "Channel Preview":
        newState = this.state.currentChannelPreview.includes(res.editor) ? this.removeStateHelper(this.state.currentChannelPreview, res) : []
        let availableChannelPreview = res.editor.user_id === 421 ? self.state.availableChannelPreview : self.state.availableChannelPreview.concat(res.editor)
        this.setState({currentChannelPreview: newState, availableChannelPreview: availableChannelPreview})
        break;
      case "Channel Approval":
        newState = this.state.currentChannelApproval.includes(res.editor) ? this.removeStateHelper(this.state.currentChannelApproval, res) : []
        let availableChannelApproval = res.editor.user_id === 421 ? self.state.availableChannelApproval : self.state.availableChannelApproval.concat(res.editor)
        this.setState({currentChannelApproval: newState, availableChannelApproval: availableChannelApproval})
        break;
    }
  },

  removeStateHelper: function (state, res) {
    return state.filter(function(el) { return el.user_id !== res.editor.user_id})
  },

  updateState: function(data) {
    var self = this;
    if(data.editorType === 'ApprovalEditor' && data.rating === null) {
      Cureus.Flash.alert('You must select an approval rating before clicking finish.')
    } else {
      $.ajax({
        type: 'put',
        url: self.props.ownershipPath + "/" + data.ownershipId,
        data: {id: self.props.articleId, state: data.newState, manually_changed: data.manuallyChanged, rating: data.rating, verify: data.verify},
        success: self.updateOwnershipInState,
        error: function(res) {
          Cureus.Flash.alert('There was an issue editing the editor state, please try later.')
        }
      })
    }
  },

  enableDisableApproveReviewOrPublicationButton: function(contentType) {
    if (contentType == "currentCopy" && this.props.articleState === "editor_approval") {
      this.isEditorOwnershipsCompleted(contentType) ? this.enableApproveReviewOrPublicationButton(contentType) : this.disableApproveReviewOrPublicationButton(contentType)
    }
  },

  isEditorOwnershipsCompleted: function(contentType) {
    if (this.state[contentType].length < 1 ) { return false}

    var ownershipStates = this.state[contentType].map(function(el) { return el.state })
    for (let i=0; i < ownershipStates.length; i++) {
      if (contentType === "currentChannelPreview" || contentType === "currentChannelApproval") {
        if(["started"].includes(ownershipStates[i])) {
          return true;
        }
      } else if (["unstarted", "started"].includes(ownershipStates[i])) {
        return false
      }
    }
    return true
  },

  disableApproveReviewOrPublicationButton: function(contentType) {
    document.getElementsByClassName("approve-for-publication")[0].addClass("disabled")
    document.getElementsByClassName("approve-for-publication")[0].setAttribute("disabled", "disabled")
  },

  enableApproveReviewOrPublicationButton: function(contentType) {
    document.getElementsByClassName("approve-for-publication")[0].className = "approve-for-publication"
    document.getElementsByClassName("approve-for-publication")[0].removeAttribute("disabled")
  },

  enableApprovalEditorEditorialButtons: function() {
    [".desk-rejection-button", ".request-re-review-modal-button", ".deferral-modal-button", ".email-author-modal"].forEach( (className) => {
      $(className).removeClass("disabled");
      $(className).removeAttr("disabled");
    })
  },

  disableEditorialButtons: function(bool) {
    [".desk-rejection-article-modal", ".deferral-modal-button, .request-re-review-modal-button"].forEach( (className) => {
      $(className).attr("disabled", bool);
      bool ? $(className).addClass("disabled") : $(className).removeClass("disabled");
    })
  },

  articleIsInPeerReview: function() {
    return ['in_review', 'in_rereview', 'reviews_complete', 'rereviews_complete'].includes(this.props.articleState)
  },

  handleDisablingDeferButtons: function(res) {
    res.editor.state === "completed" ? this.disableEditorialButtons(true) : this.disableEditorialButtons(false)
  },

  updateOwnershipInState: function(res) {
    var self = this;
    var newState;
    switch(res.type) {
      case "Copy":
        newState = this.updateStateHelper(this.state.currentCopy, res);
        this.setState({currentCopy: newState}, function() { self.enableDisableApproveReviewOrPublicationButton("currentCopy")} )
        break;
      case "Approval":
        if (!this.articleIsInPeerReview()) { this.handleDisablingDeferButtons(res)}

        newState = this.updateStateHelper(this.state.currentApproval, res);
        this.setState({currentApproval: newState, userInfo: res.userInfo})
        break;
      case "Submissions":
        newState = this.updateStateHelper(this.state.currentSubmissions, res);
        this.setState({currentSubmissions: newState, userInfo: res.userInfo})
        break;
      case "Channel Preview":
        newState = this.updateStateHelper(this.state.currentChannelPreview, res);
        this.setState({currentChannelPreview: newState, userInfo: res.userInfo}, function() {
          let approveButton = document.querySelector('.approve-for-review')
          let deferButton = document.querySelector('.deferral-modal-button');
          if(!approveButton && !deferButton) {return}
          if(this.isEditorOwnershipsCompleted('currentChannelPreview')) {
            approveButton.classList.remove('disabled')
            approveButton.removeAttribute('disabled')
            deferButton.classList.remove('disabled')
            deferButton.removeAttribute('disabled')
          } else {
            approveButton.classList.add('disabled')
            approveButton.setAttribute("disabled", "disabled")
            deferButton.classList.add('disabled')
            deferButton.setAttribute("disabled", "disabled")
          }
        })
        break;
      case "Channel Approval":
        newState = this.updateStateHelper(this.state.currentChannelApproval, res);
        this.setState({currentChannelApproval: newState, userInfo: res.userInfo}, function() {
          let button = document.querySelector('.approve-for-publication-channel')
          if(!button) {return}
          if(this.isEditorOwnershipsCompleted('currentChannelApproval')) {
            button.classList.remove('disabled')
            button.removeAttribute('disabled')
          } else {
            button.classList.add('disabled')
            button.setAttribute("disabled", "disabled")
          }
        })
        break;
    }
  },

  updateStateHelper: function(state, res) {
    return state.map(function(editor){
      return editor.ownership_id === res.editor.ownership_id ? Object.assign(editor, {state: res.editor.state, time_to_completion: res.editor.time_to_completion, since: res.editor.since, article_rating: res.editor.article_rating, verified: res.editor.verified}) : editor
    })
  },

  renderChannelPreviewClaim: function() {
    if(!this.state.editorAssignments && this.state.channelPerson && this.state.currentChannelPreview.length === 0  && !this.state.isArticleAuthor && !this.state.channelEditorPreviewCannotClaim) {
      return <a className="button success tiny" onClick={() => this.addEditor({user_id: this.state.userInfo.id, claimed: true, type: 'channel_preview' })}>Claim Article</a>
    }
  },

  renderChannelApprovalClaim: function() {
    if(!this.state.editorAssignments && this.state.channelPerson && this.state.currentChannelApproval.length === 0  && !this.state.isArticleAuthor && !this.state.channelEditorApprovalCannotClaim) {
      return <a className="button success tiny" onClick={() => this.addEditor({user_id: this.state.userInfo.id, claimed: true, type: 'channel_approval' })}>Claim Article</a>
    }
  },

  renderCopyClaim: function() {
    if(this.state.userInfo.isCopyEditor && this.state.currentCopy.length === 0) {
      return <a className="button success tiny" onClick={() => this.addEditor({user_id: this.state.userInfo.id, type: 'copy' })}>Claim Copy Editing</a>
    }
  },

  renderApprovalClaim: function() {
    if(this.state.userInfo.userType === 'ApprovalEditor' && this.state.currentApproval.length === 0 && !this.state.isArticleAuthor) {
      return <a className="button success tiny" onClick={() => this.addEditor({user_id: this.state.userInfo.id, type: 'approval' })}>Claim Approval Editing</a>
    }
  },

  renderCopyDropdown: function() {
    if (this.isAdmin() || this.state.currentCopy.some((copy) => copy.user_id == this.state.userInfo.id)) {
      return (
        <EditorDropdown
          type={'Copy'}
          editors={this.state.availableCopy}
          addEditor={this.addEditor}
          preferredArticle={this.state.preferredArticle}
        />
      )
    }
  },

  isAdmin: function() {
    return ['Developer', 'Admin', 'DeputyAdmin', 'ChannelAdmin'].includes(this.state.userInfo.userType)
  },

  renderChannelPreviewTable: function() {
    if(this.props.channelArticle) {
      return (
        <div className='row'>
          <div className='large-8 columns'>
            <h5 className='reg'>Channel Editor Preview</h5>
            <Table
                userInfo={this.state.userInfo}
                editors={this.state.currentChannelPreview}
                updateState={this.updateState}
                destroy={this.destroy}
                channel={true}
                currentUser={this.props.currentUser}
                isAdmin={this.isAdmin}
               />
              {this.renderChannelPreviewClaim()}
            {this.renderChannelPreviewDropdown()}
          </div>
        </div>
      )
    }
  },

  renderChannelPreviewDropdown: function() {
    if (this.isAdmin()) {
      return (
        <EditorDropdown
          type={'channel_preview'}
          editors={this.state.availableChannelPreview}
          addEditor={this.addEditor}
        />
      )
    }
  },

  renderChannelApprovalTable: function() {
    if(this.state.channelArticle && this.props.articleState != 'editor_preview' && this.state.pastEditorApproval) {
      return (
        <div className='row'>
          <div className='large-8 columns'>
            <h5 className='reg'>Channel Editor Approval</h5>
            <Table
                userInfo={this.state.userInfo}
                editors={this.state.currentChannelApproval}
                updateState={this.updateState}
                destroy={this.destroy}
                channel={true}
                currentUser={this.props.currentUser}
                isAdmin={this.isAdmin}
               />
              {this.renderChannelApprovalClaim()}
            {this.renderChannelApprovalDropdown()}
          </div>
        </div>
      )
    }
  },

  renderChannelApprovalDropdown: function() {
    if (this.isAdmin()) {
      return (
        <EditorDropdown
          type={'channel_approval'}
          editors={this.state.availableChannelApproval}
          addEditor={this.addEditor}
        />
      )
    }
  },

  renderSubmissionsDropdown: function() {
    if (this.isAdmin()) {
      return (
        <EditorDropdown
          type={'Submissions'}
          editors={this.state.availableSubmissions}
          addEditor={this.addEditor}
        />
      )
    }
  },


  renderSubmissionsTable: function() {
    return (
      <div className='row'>
        <div className='large-8 columns'>
          <h5 className='reg'>Submissions Editor</h5>
          <Table
              userInfo={this.state.userInfo}
              editors={this.state.currentSubmissions}
              updateState={this.updateState}
              destroy={this.destroy}
              submissions={true}
              currentUser={this.props.currentUser}
              isAdmin={this.isAdmin}
             />
          {this.renderSubmissionsDropdown()}
        </div>
      </div>
    )
  },

  renderCopyTable: function() {
    if (!this.state.showTable) {
      return
    }
    let header = this.state.preferredArticle ? "Senior Copy Editors" : "Copy Editors"
    return (
      <div className='row'>
        <div className='large-8 columns'>
          <h5 className='reg'>{ header }</h5>
          <Table
            userInfo={this.state.userInfo}
            editors={this.state.currentCopy}
            updateState={this.updateState}
            destroy={this.destroy}
            affiliations={this.state.affiliations}
            affiliationsPath={this.state.affiliationsPath}
            copy={true}
            isAdmin={this.isAdmin}
          />
          {this.renderCopyClaim()}
          {this.renderCopyDropdown()}
        </div>
      </div>
    )
  },

  renderApprovalTable: function() {
    if(this.props.articleState != 'editor_check') {
      return (
        <div className='row'>
          <div className='large-8 columns'>
            <h5 className='reg'>Approval Editors</h5>
            <Table
              userInfo={this.state.userInfo}
              editors={this.state.currentApproval}
              updateState={this.updateState}
              destroy={this.destroy}
              approval={true}
              currentUser={this.props.currentUser}
              isAdmin={this.isAdmin}
              articleState={this.props.articleState}
            />
            {this.renderApprovalClaim()}
          {this.isAdmin() && <EditorDropdown type={'Approval'} editors={this.state.availableApproval} addEditor={this.addEditor}/>}
          </div>
        </div>
      )
    }
  },

  render: function() {
    if(this.state.channelPerson) {
      return (
        <div>
          {this.renderChannelPreviewTable()}
          {this.renderChannelApprovalTable()}
        </div>
      )
    }
    return (
      <div>
        {this.renderChannelPreviewTable()}
        {this.renderChannelApprovalTable()}
        {this.renderSubmissionsTable()}
        {this.renderApprovalTable()}
        {this.renderCopyTable()}
      </div>
    )
  }
});

export default Main;