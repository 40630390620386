import React, { useState } from 'react';
import Card from './Card';
import SearchBar from '../Shared/SearchBar/SearchBar';
import Filter from '../Shared/FilterTab/Filter';
import TabComponent from './TabComponent';

const Index = ({tabArray}) => {

  const [competitions, setCompetitions] = useState([]);
  const [tabs, setTabs] = useState(tabArray);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  return (
    <div className='small-12 columns all-competitions-container'>
      <div className="top-section-container large-10 medium-11 small-12 columns small-centered specialties-section">
        <h4 className='all-competitions-heading'>CUREUS COMPETITIONS</h4>
        <h1 className='all-competitions-title'>Explore past and present sponsored publishing competitions</h1>
      </div>
      <div className="small-12 columns nav-container bordered competition-container">
        <div className="row hide-for-large-up"></div>
          <div className="row">
            <div className='large-10 large-offset-1 columns'>
                <TabComponent url={'/competitions/past_competitions'} tabList={tabs} setCards={setCompetitions}/>
          </div>
        </div>
      </div>
      <div className="row small-collapse">
        <div className='small-12 columns'>
          <SearchBar url={'/competitions/past_competitions'} placeHolder={'Search for a specific competition here.'} isFilterMenuOpen={false} setIsFilterMenuOpen={setIsFilterMenuOpen} setCards={setCompetitions} page={"competitions"} isFilterRequired={true} />
        </div>
      </div>
      <div className="row small-collapse">
        <div className="small-12 columns">
          <div className='all-competitions-grid'>
            {competitions && competitions.map((competition)=> {
            return (
              <Card competition={competition} key={competition.id}/>
            )})}
          </div>
        </div>
      </div>
      <Filter
        isMenuOpen={isFilterMenuOpen}
        setFilterMenuOpen={setIsFilterMenuOpen}
        contents={tabArray.slice(1).map((tab,i)=>{
          return {id: i+1, name: tab}
        })}
        setCards={setCompetitions}
        page={"pastCompetitions"}
        url={'/competitions/past_competitions'}
        pills={[]}
      />
    </div>
  )
}

export default Index;