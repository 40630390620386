var createReactClass = require('create-react-class');
import React from 'react';
import Table from './Table';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const LockedTable = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("Table")
  ],

  getStateFromFlux: function() {
    return this.getFlux().store("Table").getState();
  },


  render: function() {
    return (
      <div>
        <Table dataSource={this.props.dataSource} subscribed={true} columns={ this.props.columns}/>
      </div>
    )
  }
});

export default LockedTable;

