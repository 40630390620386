import React from 'react';

const CureusUSelector = ({cureusUSpecialties, currentSpecialty}) => {

  return (
    <React.Fragment>
      <label>Cureus U Specialty</label>
      <select name="campaign[specialty_id]" defaultValue={currentSpecialty}>
        <option>Select Specialty</option>
        {cureusUSpecialties.map(function(list) {
          return <option key={list.specialty_id} value={list.specialty_id}>{list.description + " (" + list.subscribed + ")"}</option>
        })}
      </select>
    </React.Fragment>
  )

}

export default CureusUSelector;