var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal';
import HistoryModal from './HistoryModal'

const Main = createReactClass({
  getInitialState: function() {
    return({
      events: null
    })
  },

  fetchHistory: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      url: self.props.resourcePath,
      dataType: 'json',
      success: function(res) {
        self.setState({
          events: res.events
        }, function() {
          self.openModal()
        })
      }
    });
  },
  
  openModal: function() {
      Modal.open({
        children: <HistoryModal
          resourcePath={this.props.resourcePath}
          events={this.state.events}
        />
      });
  },

  render: function (){
    return (
      <a className="resource-history-link" onClick={this.fetchHistory}>Full History</a>
    )
  }
});

export default Main;
