import React, { useState, useEffect } from 'react';

const UploadPeerReviewers = ({ uploadReviewersPath }) => {

  const getAuthenticityToken = () => {
    return $('meta[name="csrf-token"]').attr("content");
  }

  return (
    <div className="email-list-tool-modal reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>


        <div className="modal-content">
          <div className='modal-form'>
            <h5>
              Upload Reviewer List
            </h5>
            <p><b>Important!</b> Only CSV files are accepted. Please include the following information in columns ordered from left to right: email address, first name, last name, specialty, department, institution, city, country. </p>
            <form acceptCharset="UTF-8" method="post" action={uploadReviewersPath} id="email-list-upload-form" encType="multipart/form-data">
              <input name="_method" type="hidden" value="put" />
              <input name="authenticity_token" type="hidden" value={getAuthenticityToken()} />
              <input type="file" name="file" />
              <span>Acceptable file types: CSV</span>

              <div className="form-actions">
                <a className='cancel-modal button secondary'>Cancel</a>
                <input type='submit' value="Import" className='button primary'/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}


export default UploadPeerReviewers;