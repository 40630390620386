var createReactClass = require('create-react-class');
import React from 'react';

const Info = createReactClass({
   render: function() {
    return (
      <div className="modal-body">
      <div className='modal-close'>
        <a href='#' className='cancel-modal'>
          <img src="https://public.cureus.com/close_x.png"/>
        </a>
      </div>
      <div className="modal-content middling">
        <h4>Purchase Optional Article Promotion</h4>
        <p>Your article is eligible for promotion. Leveraging the power of social media will help your article reach a wider audience with our standard or premium Article Promotion options:</p>
        <p><strong>Standard:</strong> Organic social media promotion - we share your article on our official Cureus channels (Facebook, Instagram, LinkedIn, and Twitter) and other appropriate social channels.</p>
        <p><strong>Premium:</strong> Standard Option plus a targeted social media ad campaign. A social media ad is a paid online marketing campaign in which traffic is driven to your article based on keywords, geo-targetting, and demographics.</p>
        <ul style={{listStyleType:'none', marginLeft: 0}}>
          <li>Standard Estimated Reach: 300 - 400+ more article views</li>
          <li>Premium Estimated Reach: 500-700+ more article views</li>
        </ul>
        <p>Articles will be promoted within 3-5 days of purchase.</p>
        <p style={{fontSize: '.875rem'}}><i>*Articles that include improper content according to Facebook advertising guidelines may be subject to rejection. You will receive a full refund if your ad is declined by Facebook. If you're unsure about the content of your article, please contact <a href="mailto:support@cureus.com">&nbsp;support@cureus.com</a></i></p>
        <div className='modal-footer'>
          <a className='cancel-modal button secondary'>Cancel</a>
          <a className="button primary" onClick={this.props.handleNext}>Next</a>
        </div>
      </div>
    </div>
    )
  }
})

export default Info;