var createReactClass = require('create-react-class');
import React from 'react';

const Title = createReactClass({
  render: function(){
    return (
      <div>
        <h4 className="reg">How should I format my article title?</h4>
        <p>
          <i className="far fa-check-circle good"/>
          &nbsp;Correct: Endoscopic Endonasal Approach for Transclival Resection of a Petroclival Meningioma
        </p>
        <p>
          <i className="fa fa-times-circle bad"/>
          &nbsp;Incorrect: ENDOSCOPIC ENDONASAL APPROACH FOR TRANSCLIVAL RESECTION OF A PETROCLIVAL MENINGIOMA
        </p>
        <p>
          <i className="fa fa-times-circle bad"/>
          &nbsp;Incorrect: Endoscopic endonasal approach for transclival resection of a petroclival meningioma.
        </p>
      </div>
    );
  }
});

export default Title;