import React, {useState, useEffect, useRef} from 'react';

const Label = ({label, sublabel}) => {
  return (
    <div className="row">
      <div className="small-12 columns">
        <div className="label-container">
          <img src="https://public.cureus.com/collections/application-form/form-label-1.png"/>
          <div className="label-title">
            <h4>{label}</h4>
            <h6>{sublabel}</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Label;