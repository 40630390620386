import React, { useState, useEffect } from 'react';
import Promotion from './Sidebar/Promotion'
import Articles from './Sidebar/MostPopularArticles/Articles'

const RightSidebar = ({article, fireAmplitude}) => {

  return (
    <div className="small-2 columns show-for-large-up right-sidebar">
      <Promotion
        fireAmplitude={fireAmplitude}
      />
      <Articles
        article={article}
        fireAmplitude={fireAmplitude}
      />
    </div>
  )
}

export default RightSidebar;