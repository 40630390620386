import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import ListTab from './ListTab';
import MonthlyTab from './MonthlyTab';
import ScoresDistribution from './ScoresDistribution'

const Index = ({initialDateRanges, isAdmin, approvalEditors, userEmail}) => {

  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    setActiveTab(window.location.hash == '#/' ? 'list' : 'monthly');
  }, [])
  

  return (
    <Router>
      <div className="large-12 medium-12 small-12 columns new-dashboard-section">
        <div className="small-12 columns nav-container">
          <div className='row'>
            <div className='large-12 columns'>
              <h2>Approval Editor Leaderboard</h2>
            </div>
          </div>
          <div className="row">
            <div className="tab-items">
              <ul className='tabs'>
                <Link to='/'>
                  <li className={"tab-title " + (activeTab === 'list' ? 'active' : '')} onClick={() => setActiveTab('list')}>
                    <a className="dashboard">LIST</a>
                  </li>
                </Link>
                {
                  isAdmin && (
                    <Link to='/monthly_breakdown'>
                      <li className={"tab-title " + (activeTab === 'monthly' ? 'active' : '')} onClick={() => setActiveTab('monthly')}>
                        <a className="dashboard">MONTHLY BREAKDOWN</a>
                      </li>
                    </Link>
                  )
                }
              </ul>
            </div>
            <Switch>
              <Route exact path="/">
                <ListTab initialDateRanges={initialDateRanges} isAdmin={isAdmin}
                  />
                <ScoresDistribution />
              </Route>
              <Route path="/monthly_breakdown">
                <MonthlyTab approvalEditors={approvalEditors} initialDateRanges={initialDateRanges}
                isAdmin={isAdmin} userEmail={userEmail}
                  />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  )
}

export default Index;




