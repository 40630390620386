import React, { useState, useRef, useEffect } from 'react';
import Modal from '../Modal';
import NewOrganizationModal from './NewOrganizationModal';

const Main = ({fetchOrganizationsPath, newOrganizationPath, removeEntriesPath}) => {

  const [spinning, setSpinning] = useState(true);
  const [organizationsSelected, setOrganizationsSelected] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    populateTable();
    fetchOrganizations();
  }, [])

  const setColumns = () => {
    return [
      {width: '160px'},
      {width: '160px'},
      {width: '160px'},
      {width: '110px'},
      {width: '200px'},
      {width: '30px'},
    ]
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.email,
        row.organization,
        row.location,
        row.date_added,
        row.reason,
        row.checkbox
      ], false)
    )
  }

  const drawData = (organizationData) => {
    var dt = $("#organizations-table").DataTable();
    dt.api().clear();
    for (var i = 0; i < organizationData.length; i++) {
      let row = organizationData[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
  }

  const populateTable = (organizationData) => {
    var dt = $("#organizations-table").DataTable({
      destroy: true,
      columns: setColumns(),
      searching: false,
      dom: '<"row">tr<"pagination-container"<"large-8 columns pagination-details" <"pull-left"p>>>',
      "bAutoWidth": false,
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
      "rowCallback": function(row) {
        row.children[5].children[0].addEventListener('click',function(e) {
          checkboxClicked(e)
        })
      }
    })
    
  }

  const deleteSelected = (e) => {
    setSpinning(true)
    var wallOfShameIds = []
    $("input:checked").each(function() {
      wallOfShameIds.push($(this).data('resource-id'))
    })
    $.ajax({
      type: 'Delete',
      url: removeEntriesPath,
      data: {ids: wallOfShameIds},
      success: function() {
        setSpinning(false)
        fetchOrganizations()
        setChecked(false)
      }
    });
  }

  const checkboxClicked = () => {
    const checkedInputs = $("input:checked");
    setOrganizationsSelected(checkedInputs.length > 0)
  }

  const fetchOrganizations = () => {
    setSpinning(true)
    $.ajax({
      type: 'GET',
      url: fetchOrganizationsPath,
      success: function(data) {
        setSpinning(false)
        drawData(data)
      }
    });
  }

  const addNewOrganization = (e) => {
    e.preventDefault()
    Modal.open({
      children: <NewOrganizationModal fetchOrganizations={fetchOrganizations} newOrganizationPath={newOrganizationPath} />
    });
  }

  return (
    <React.Fragment>
      <div className="wall-of-shame-container organizations-container">
        <h3>Organizations</h3>
        <table id="organizations-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Organization</th>
              <th>Location</th>
              <th>Date added</th>
              <th>Reason</th>
              <th><i className="fas fa-pencil-alt"></i></th>
            </tr>
          </thead>
          { spinning &&
              <div className='spinner-container'>
                <img src='https://public.cureus.com/ajax-loader.gif'/>
              </div>
          }
          { !spinning &&
            <tbody></tbody>
          }
        </table>

        <a className="button special add-new-button" onClick={(e) => addNewOrganization(e)}>
          <i className="fa fa-plus-circle"></i>ADD NEW
        </a>

        <a className={`button delete-button ${organizationsSelected ? '' : 'hidden'}`} onClick={(e) => deleteSelected(e)}>
          DELETE
        </a>

      </div>
    </React.Fragment>
  )

}

export default Main;
