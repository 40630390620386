var createReactClass = require('create-react-class');
import React from 'react';
import ActionSplitButtons from './ActionSplitButtons';
import RenameCampaignModal from './RenameCampaignModal';
import Modal from '../Modal';

const CampaignRow = createReactClass({

  handleRenameClick: function (e){
    Modal.open({
      children: <RenameCampaignModal
        renameCampaignPath={this.props.emailCampaign.updatePath}
        emailCampaign={this.props.emailCampaign}
        onRename={this.props.onRename} />
    });
  },

  render: function() {
    var status;
    var path;
    if (this.props.emailCampaign.sent) {
      status = "fa fa-rocket";
      path = this.props.emailCampaign.showPath
    } else {
      status = "fa fa-pencil-alt";
      path = this.props.emailCampaign.editPath
    }
    return (
      <tr>
        <td className='checkbox-cell'>
          <input type='checkbox' onChange={() => {this.props.onCheck(this.props.emailCampaign.id, !this.props.isChecked)}} checked={this.props.isChecked} id ={`check-box-${this.props.emailCampaign.id}`}/>
        </td> 

        <td className='status-cell'>
          <i className={status}></i>
        </td>

        <td>
          { <a href={path} target="_blank" rel="noreferrer">{ this.props.emailCampaign.title }</a> }
        </td>

        <td>
          { this.props.emailCampaign.created }
        </td>

        <td>
          { this.props.emailCampaign.sentOrEdited }
        </td>

        <td>
          { this.props.emailCampaign.requests }
        </td>

        <td>
          { this.props.emailCampaign.delivered }
        </td>

        <td className='actions-cell'>
          <ActionSplitButtons
            id={ this.props.emailCampaign.id }
            showPath={ this.props.emailCampaign.showPath }
            editPath={ this.props.emailCampaign.editPath }
            copyPath={ this.props.emailCampaign.copyPath }
            emailPath={ this.props.emailCampaign.emailPath }
            sent={ this.props.emailCampaign.sent }
            onRenameClick={ this.handleRenameClick }
            />
        </td>
      </tr>
    )
  }
});

export default CampaignRow;
