import React, { useState } from 'react';

const NewOrganizationModal = ({ newOrganizationPath, fetchOrganizations }) => {

  const [addButtonEnabled, setAddButtonEnabled] = useState(false);

  const addNewOrganization = (e) => {
    if (!addButtonEnabled) {
      return
    }
    $.ajax({
      type: 'POST',
      url: newOrganizationPath,
      data: {
        'email': $('#email').val(),
        'name': $('#name').val(),
        'location': $('#location').val(),
        'reason': $('#reason').val(),
      },
      success: function(data) {
        fetchOrganizations()
      }
    });
  }

  const checkAddButtonEnabled = () => {
    const isEnabled = $('#email').val() !== '' && $('#name').val() !== '' && $('#location').val() !== '' && $('#reason').val() !== ''
    setAddButtonEnabled(isEnabled)
  }

  return (
    <div className="wall-of-shame-modal reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-header">
          <h5>
            Add organization
          </h5>
        </div>

        <div className="modal-content">
          <div className='modal-form'>
            <div className="input-container">
              <label>Email address</label>
              <input id="email" type="text" placeholder="Please enter the corresponding email address." onChange={(e) => checkAddButtonEnabled()} />
            </div>

            <div className="input-container">
              <label>Organization's name</label>
              <input id="name" type="text" placeholder="Please enter the name of the organization." onChange={(e) => checkAddButtonEnabled()} />
            </div>

            <div className="input-container">
              <label>City &#38; country</label>
              <input id="location" type="text" placeholder="Enter the location." onChange={(e) => checkAddButtonEnabled()} />
            </div>

            <div className="input-container">
              <label>Reason</label>
              <textarea id="reason" type="text" placeholder="Please enter the reason this organization is being added to the list." onChange={(e) => checkAddButtonEnabled(false)} />
            </div>

            <div className="row">
              <button className='close-modal cancel-modal'>CANCEL</button>
              <button className={`add-button ${addButtonEnabled ? 'cancel-modal' : 'disabled'}`} onClick={() => addNewOrganization()}>ADD</button>
              { !addButtonEnabled && 
                <div className="columns small-12">
                  <span className="add-warning">You must add all fields before submitting</span>
                </div>              
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default NewOrganizationModal;