export var Cureus = {
  Utils: {},
  Admin: {},
  initDatePicker: function (){
    $.datepicker.setDefaults({dateFormat: "yy-mm-dd"});
    $('input.date').each( function (){
      $(this).datepicker();
    });
  }
};

$(document).ready(function() {
  Cureus.Expander.doExpander();
  Cureus.Flash.init();
  new Cureus.CustomDropdown();
});

//Adding a cancel-modal class will close the modal when clicked
$(document).on("click", ".reveal-modal .cancel-modal", function(e) {
  $(e.currentTarget).closest(".reveal-modal").foundation("reveal", "close");
  e.preventDefault();
});

// wtf is this for
// $('a[href*="#"]:not([href="#"]):not(.noscroll)').on("click", function() {
//   if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
//     var target = $(this.hash);
//     var navBarHeight = 65;
//     target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
//     if (target.length) {
//       var offset = target.data("smooth-scroll-offset") || 0;
//       $('html,body').animate({
//         scrollTop: (target.offset().top - navBarHeight - offset)
//       }, 600, 'easeInOutQuint');
//       return false;
//     }
//   }
// });

export function decodeHtml(input) {
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function showSaveNotification(target){
  var $saved = $('#saved-notification');
  var $savedMessage = $('#save-success-message');
  $saved.fadeIn(100);
  $savedMessage.fadeIn(100);
  window.setTimeout(function(){
    if(target != undefined) {
      target.removeClass("disabled")
    }
  }, 900);
  window.setTimeout(function(){ $saved.fadeOut(500); }, 2000);
  window.setTimeout(function(){ $savedMessage.fadeOut(500); }, 2000);
}

export function saveFailedBanner(){
  if($('#flash')){$('#flash').remove()}
  window.scrollTo(0, 0);
  $("#flash_container").append( "<div id='flash'><div class='alert-box warning'><div class='row'><div class='large-12 columns'><a class='close' href='#'><div class='flash-close-btn'></div></a><span class='flash-content'>Something went wrong and your article has not been saved. Please try again in a moment.</span></div></div></div></div>");

  $('.close').click(function() {
    $('#flash').remove()
  })
  window.setTimeout(function(){ $('#save-now').removeClass('disabled') }, 2000);
}

//Links with a data-disabled = 'true' will have preventDefault
$(document).on("click", "a[data-disabled]", function(e) {
  e.preventDefault();
});

Object.defineProperty(Array.prototype, 'chunk', {
  value: function(chunkSize) {
    var R = [];
    for (var i = 0; i < this.length; i += chunkSize)
      R.push(this.slice(i, i + chunkSize));
    return R;
  }
});

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

