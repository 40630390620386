var createReactClass = require('create-react-class');
import React from 'react';

const UserCard = createReactClass({
  affiliationNames: function() {
    if (this.props.resource.affiliation_names && this.props.resource.affiliation_names.length) {
      return(
        <div className="user-data"><i className="far fa-hospital"></i><span>{this.props.resource.affiliation_names}</span>
        </div>
      )
    }
  },


  email: function() {
    if (this.props.resource.public_email && this.props.resource.public_email.length) {
      return(
        <div className="user-data"><i className="far fa-envelope"></i><span><a href={"mailto:" + this.props.resource.public_email}>{this.props.resource.public_email}</a></span>
        </div>
      )
    }
  },

  webAddress: function() {
    if (this.props.resource.web_address && this.props.resource.web_address) {
      return(
        <div target="_blank" className="user-data"><i className="fa fa-link"></i><span><a href={this.props.resource.web_address} rel="nofollow" target="_blank">{this.props.resource.web_address}</a></span>
        </div>
      )
    }
  },

  render: function() {
    var resource = this.props.resource;
    return (
      <div className="user-card">
        <div className="user-card-avatar">
          <img alt={resource.full_name} src={resource.avatar_profile_url} title={resource.full_name}/>
        </div>
        <div className="user-card-stats">
          <div className="user-card-stat">Published: {resource.published_articles_count}</div>
          <div className="user-card-stat">Reviewed: {resource.completed_reviews_count}</div>
          <div className="user-card-stat">
            Articles rated: {resource.scorings_count}
            <i className="fa fa-question-circle" data-tooltip="" title="All registered users are invited to rate published articles via SIQ&amp;&trade;, our unique post-publication review system."></i>
          </div>
        </div>
        <div className="user-card-rating">
          Reviewer Rating
          <div className="user-card-stars">
            <div dangerouslySetInnerHTML={{__html: resource.rating_stars_helper}} />
          </div>
        </div>
        <div className="user-card-details">
          <a target="_blank" href={resource.path}>
            <h4>{resource.full_name}</h4>
          </a><span className="subhead">{resource.card_subhead}</span>
          <div className="user-data-wrap">
            {this.affiliationNames()}
            {this.email()}
            {this.webAddress()}
          </div>
        </div>
      </div>
    )
  }
})

export default UserCard;