import React, { memo } from 'react';
import Checkbox from '../../Shared/Utility/Checkbox';

const Categories = ({ categories, categorySelection, onChange, showMore }) => {
  const renderCategories = () => {
    return categories.map((category) => {
      const { name, id } = category;
      return (
        <Checkbox
          label={name}
          id={id}
          checked={categorySelection[id] || false}
          key={`category-${id}`}
          onChange={(event) => onChange(event, name, id)}
        />
      )
    })
  }

  return (
    <div className="category-container" style={{maxHeight: showMore ? 'unset' : '600px'}}>
      <Checkbox label="All Categories" id="all-categories" checked={categorySelection['allCategories']} key="all-categories" onChange={(event) => onChange(event, 'allCategories')}/>
      {renderCategories()}
    </div>
  )
}

export default memo(Categories);