import React, { useState } from 'react';
import AllInviteesTable from './AllInviteesTable';
import { HashRouter as Router, Switch, Route } from "react-router-dom";

const ViewAllChannelInvitees = ({channelId, createEmailButtonTitle, breadcrumbPath, breadcrumbName, fetchAllInviteesPath, removeListEmailsPath}) => {
  
  const [listEmailsCount, setListEmailsCount] = useState(null);

  return (
    <Router hashType={"hashbang"}>
      <div className="small-12 columns channel-email-list-container with-breadcrumbs">
        <div className="row email-list-header-with-breadcrumbs">
          <div className="channel-breadcrumbs">
            <a href={breadcrumbPath} className="underline header-light-text">{breadcrumbName}</a>
            <span className="header-light-text"> {'>'} </span>
            <span className="header-light-text">All Recipients</span>
          </div>
          <h1>All Recipients
            {listEmailsCount > 0 && <span className="channels-h1-blurb reviewers-count">{listEmailsCount}</span>}
          </h1>
        </div>
          <Switch>
            <Route path='/' exact>
              <AllInviteesTable 
                channelId={channelId}
                createEmailButtonTitle={createEmailButtonTitle}
                removeListEmailsPath={removeListEmailsPath}
                setListEmailsCount={setListEmailsCount}
                fetchAllInviteesPath={fetchAllInviteesPath}
              />
            </Route>
          </Switch>
      </div>
    </Router>
  )

}

export default ViewAllChannelInvitees;