import React, { useState, useRef, useEffect } from 'react';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const ConfirmationModal = ({fetchData, recipients, channelName, campaignId}) => {
  const sendCampaign = () => {
    $.ajax({
      type: 'GET', 
      dataType: "JSON",
      url: `/admin/campaigns/${campaignId}/send_mail`,
      success: function() {
        fetchData()
        Modal.close();
        Cureus.Flash.success('You have successfully sent out e-mails for the campaign.')
      }
    });
  }

  return(
    <div className="reveal-modal small" data-reveal id="confirmation-modal">
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <h4>You are about to send this campaign to {recipients} {channelName} subscribers. Are you sure?</h4>
          <p>
           This action cannot be undone.
          </p>
        </div>

        <div className="modal-footer">
          <a href="#" className="button secondary cancel-modal"> Cancel </a>
          <a type="button" className="button primary" onClick={sendCampaign}>Send</a>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal;