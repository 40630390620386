import React, { useState, useEffect } from 'react';
import RecentlyPublishedWidget from './MainWidgets/RecentlyPublishedWidget';
import InternalContentWidget from './MainWidgets/InternalContentWidget'
import CustomContentWidget from './MainWidgets/CustomContentWidget';
import ChannelTeamWidget from './MainWidgets/ChannelTeamWidget';
import FeaturedMultimediaWidget from './MainWidgets/FeaturedMultimediaWidget';
import NoArticlesDisplay from './NoArticlesDisplay';


const MainContent = ({mainContentWidgets, slug, handleDeleteWidget, handleUpdateWidget, showNoArticles}) => {
  return (
    <div className="channel-main-content-container channel-widget-design">
        {showNoArticles && <NoArticlesDisplay />}
        {mainContentWidgets["Widget::CustomContent"] &&
        <CustomContentWidget
          slug={slug}
          handleDeleteWidget={handleDeleteWidget}
          handleUpdateWidget={handleUpdateWidget}
          mainContentWidgets={mainContentWidgets}
        />}
        {mainContentWidgets["Widget::RecentlyPublished"] &&
        <RecentlyPublishedWidget
          widgetId={mainContentWidgets["Widget::RecentlyPublished"]}
          slug={slug}
          handleDeleteWidget={handleDeleteWidget}
          mainContentWidgets={mainContentWidgets}
        />}
        {mainContentWidgets["Widget::ChannelTeam"] &&
          <ChannelTeamWidget
            slug={slug}
            handleDeleteWidget={handleDeleteWidget}
            mainContentWidgets={mainContentWidgets}
            handleUpdateWidget={handleUpdateWidget}
          />
        }
        {mainContentWidgets["Widget::InternalContent"] &&
        <InternalContentWidget
          slug={slug}
          handleDeleteWidget={handleDeleteWidget}
          mainContentWidgets={mainContentWidgets}
          handleUpdateWidget={handleUpdateWidget}
        />}
        {mainContentWidgets["Widget::FeaturedMultimedia"] &&
        <FeaturedMultimediaWidget
          widgetId={mainContentWidgets["Widget::FeaturedMultimedia"]}
          slug={slug}
          handleDeleteWidget={handleDeleteWidget}
        />}
    </div>
  )
}

export default MainContent;