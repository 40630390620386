import {Cureus} from '../Cureus';

Cureus.NestedFormLimiter = function() {
  var self = this;
  self.toggle();
  document.querySelector("a.add_nested_fields[data-limit]"). addEventListener('click', self.toggle);
  document.querySelector('.requirement-nested-fields-wrapper').addEventListener('click', function(event){
    if(event.target.className === "button alert tiny remove_nested_fields") {
      event.target.parentElement.parentElement.parentElement.remove();
      self.toggle();
    }
  });
}

Cureus.NestedFormLimiter.prototype.toggle = function() {
  window.setTimeout(function() {
    var addRequirementButton = document.querySelector("a.add_nested_fields[data-limit]");
    var requirementFields = document.querySelectorAll('.fields.requirement')
    addRequirementButton.dataset.limit > requirementFields.length ? addRequirementButton.style.display = 'inline-block' : addRequirementButton.style.display = 'none';
  }, 400)
}