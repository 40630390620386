import React, { useState, useEffect } from 'react';
import { Cureus } from '../../Cureus';

const CureusUpdates = ({lastCureusUpdate, cureusUpdatePath}) => {
  const [loadedCKEditor, setLoadedCKEditor] = useState(false)
  const [description, setDescription] = useState("")
  const [button, setButton] = useState(false)
  const [ctaText, setCtaText] = useState("")
  const [ctaUrl, setCtaUrl] = useState("")
  const [existingCureusUpdate, setExistingCureusUpdate] = useState(false)
  const [spinning, setSpinning] = useState(false)

  useEffect(() => {
    if (!loadedCKEditor){
      new Cureus.CKEditor('cureus-updates-description', {handle_change: updateDescription, editor_type: 'static_text_with_color', ckeditor: {height: 200}})
      setLoadedCKEditor(true)
    }
    if (location.hash && location.hash.includes('?id=') && !existingCureusUpdate) {
      setExistingCureusUpdate(true)
      const cureusUpdateId = location.hash.split('?id=')[1]
      setSpinning(true)
      getCureusUpdate(cureusUpdateId)
    }
  })

  const canSave = () => {
    return description !== "" && (!button || (ctaText && ctaUrl))
  }

  const getCureusUpdate = (cureusUpdateId) => {
    $.ajax({
      type: 'GET',
      url: `${cureusUpdatePath}/${cureusUpdateId}`,
      success: function(data) {
        setSpinning(false)
        setButton(false)
        setCtaText(data.cta_text)
        setCtaUrl(data.cta_url)
        setDescription(data.description)
        setTimeout(() => {CKEDITOR.instances["cureus-updates-description"].setData(data.description)}, 1000)
      }
    });
  }

  const addButton = () => {
    if (description) {
      setButton(true)
    }
  }

  const removeButton = () => {
    setButton(false)
    setCtaText("")
    setCtaUrl("")
  }

  const saveCureusUpdate = () => {
    if (description === '') {
      return
    }
    $.ajax({
      type: 'POST',
      url: cureusUpdatePath,
      data: {description: description, cta_text: ctaText, cta_url: ctaUrl},
      success: function(data) {
        location.reload();
      },
      error: function(data) {
        location.reload();
      }
    });
  }

  const updateDescription = () => {
    const newDescription = CKEDITOR.instances["cureus-updates-description"].getData()
    setDescription(newDescription)
    if (newDescription === "") {
      setButton(false)
      setCtaText("")
      setCtaUrl("")
    }
  }


  return (
    <div className="small-12 columns cureus-updates-container">
      {spinning &&
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      }
      <div className="row">
        <div className="small-3 columns">
          <h3>Cureus Updates</h3>
        </div>
        <div className="small-9 view-history columns">
          <a href={cureusUpdatePath} >View history</a>
        </div>
      </div>

      {!existingCureusUpdate &&
        <p className="last-updated">
          Last updated: {lastCureusUpdate}
        </p>
      }

      <div className="form-container">
        <textarea id="cureus-updates-description" name="cureus-updates-description" style={{height: '300px'}} />

        <div className="row add-button-container">
          {!existingCureusUpdate &&
            <div>
              <div className="columns small-3">
                <button className={`add-button-button ${button ? 'hide' : ''} ${description !== "" ? '' : 'disabled'}`} onClick={() => addButton()}>+ ADD BUTTON</button>
                <button className={`remove-button ${button ? '' : 'hide'}`} onClick={() => removeButton()}>REMOVE BUTTON</button>
              </div>
              <div className={`columns small-9 add-warning ${description !== "" ? 'hide' : ''}`}>
                <span>You need to add content in order to add a button.</span>
              </div>
            </div>
          }
        </div>

        <div className="row">
          <div className="columns small-8">
            <div className="input-container">
              <label>Name</label>
              <input type="text" disabled={!button} placeholder="Please, provide a name for the button" onChange={(e) => setCtaText(e.target.value)} value={ctaText} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="columns small-8">
            <div className="input-container">
              <label>URL</label>
              <input type="text" disabled={!button}  placeholder="Enter the link." onChange={(e) => setCtaUrl(e.target.value)} value={ctaUrl} />
            </div>
          </div>
        </div>

        {!existingCureusUpdate &&
          <div className="row">
            <div className="columns small-3">
              <button className={`save-button ${canSave() ? '' : 'disabled'}`} onClick={() => saveCureusUpdate()}>ADD</button>
            </div>
            <div className={`columns small-9 add-warning ${canSave() ? 'hide' : ''}`}>
              <span>You cannot post widget without content.</span>
            </div>
          </div>
        }

      </div>
    </div>
  )
}

export default CureusUpdates;