import React, { useState, useEffect } from 'react';
import Categories from '../Categories';
import DeleteButton from '../../ChannelDesign/DeleteButton';
import UpdateButton from '../../ChannelDesign/UpdateButton';

const BigArticleCard = ({resource, handleDeleteWidget, handleUpdateWidget, showThumbnail=true, showCategories=false, showActionButtons=false, customStyleClass, mainContentWidgets, slug}) => {
  const [authors, setAuthors] = useState('')
  const [authorsOverflow, setauthorsOverflow] = useState(null)


  useEffect(() => {
    let authorList = resource.author_list.split(',')
    setAuthors(authorList.slice(0, 2).join(', '))
    let overflow = authorList.slice(2).length
    if(overflow > 1) {
      setauthorsOverflow(overflow)
    }
  })

  const renderAuthorList = () => {
    let href = ''
    if (authorsOverflow) {
      href = <a href={resource.path + '?authors-tab=true'}><span>+{authorsOverflow} more</span></a>
    }

    return (
      <div className="authors">
        {authors}
        {href}
      </div>
    )
  }

  const renderActionButtons = () => {
    if (showActionButtons) {
      return (
        <>
          <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
          <UpdateButton
            handleUpdateWidget={handleUpdateWidget}
            widget={resource}
            modalName={"CustomContentModal"}
            mainContentWidgets={mainContentWidgets}
            slug={slug}
          />
          <DeleteButton
            handleDeleteWidget={handleDeleteWidget}
            widgetId={resource.widgetId}
          />
        </>
      )
    }
  }

  const renderThumbnail = () => {
    if (showThumbnail) {
      return (
        <div className="row">
          <div className="small-12 columns">
            <div className="thumbnail">
              <a href={resource.path} target="_blank">
                <div key={resource.id + "thumb"} dangerouslySetInnerHTML={{__html: resource.thumbnail}}/>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={`new-article-card big ` + (customStyleClass ? "custom-fifty-height" : "")}>
      {renderActionButtons()}
      <div className="row">
        <div className="small-12 columns header-container">
          <div className="header">
            <span className="left">{resource.published_date}</span>
            <span className="right">{resource.humanized_type}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns">
          <div className="title title-mobile">
            <h3><a href={resource.path}>{resource.title}</a></h3>
          </div>
        </div>
      </div>
      {renderThumbnail()}
      <div className="row">
        <div className="small-12 columns">
          {renderAuthorList()}
          <Categories categories={resource.categories} resourceId={resource.id} showCategories={showCategories}/>
        </div>
      </div>
    </div>
  )
}

export default BigArticleCard;