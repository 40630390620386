var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal'
import Odometer from 'odometer';
import SiqBadgeImage from '../Shared/SiqBadgeImage'

const ThankYouModal = createReactClass({
  handleCancel: function(){
    Modal.close();
  },

  componentWillMount: function() {
    $('.reveal-modal-bg').remove()
  },

  componentDidMount: function() {
    if (this.props.score) {
      var self = this;
      var siqScore =  this.parseScore(this.props.score);
      document.querySelectorAll('.siq-badge-score').forEach(function(node) {
        node.innerHTML = self.props.score.toFixed(1)
      })

      document.querySelectorAll('.category-value').forEach(function(node, idx) {
        node.innerHTML = self.props.categoryValues[idx]
      })
      let ratedNum = document.querySelector(".rated-number")
      if(ratedNum) {
        ratedNum.innerHTML = `RATED BY ${self.props.totalScores} READERS`
      }
      document.querySelectorAll("#disclaimer").forEach(function(node) {
        node.innerHTML = ""
      })
      document.querySelectorAll("#bar-container").forEach(function(node){
        node.removeClass('progress-striped')
        node.addClass('cureus-progress active')
      })
      document.querySelectorAll("#new-bar").forEach(function(node, idx) {
        node.style.width = `${self.props.categoryProgress[idx]}%`
      })
      document.querySelectorAll(".striped-bars").forEach(function(node){
        node.style['margin-left'] = '10px'
      })
      setTimeout(function() {
        self.initializeOdometer(siqScore)
      }, 1000);
    }
  },

  parseScore: function(score) {

    var siqScore = parseFloat(score.toPrecision(2));
    //Parses score to fit Odoometer libary 'format' requirements, allows spinning of decimal numbers,
    //by generating nested numbers and having css psudeo selectors hide surrounding numbers
    if (siqScore !== 10) {
      siqScore = siqScore * 1000 + 10000;
    } else {
      siqScore = 110000;
    }
    return siqScore;
  },

  initializeOdometer: function(siqScore) {
    var el = $("#siq-score-thank-you")[0]

    var od = new Odometer({
      el: el,
      value: 10001,
      selector: '#siq-score-thank-you',
      format: 'd.d',
      duration: 1000,
      theme: 'default',
      animation: 'count'

    });

    $('#siq-score-thank-you').text(siqScore)
    // align score center for non-decimal #
    if (siqScore === 110000) {
      $('.odometer').css({left: 102});
    }
  },

  handleCommentAdd: function() {
    Modal.close({onCloseInstant: function() {
      const element = document.getElementById("article-comments-section");
      const y = element.getBoundingClientRect().top + window.pageYOffset - 70;
      window.scrollTo({top: y, left: 0, behavior: 'smooth'});
    }});
  },

  renderShield: function(score) {
    return (
      <div>
        <p>This article's SIQ is now:</p>
        <div className="siq-shield-wrapper">
          <h4 className="odometer" id="siq-score-thank-you"> 0.0 </h4>
            <SiqBadgeImage
              siq={this.props.score}
              width="250px"
            />
        </div>
      </div>
    );
  },

  renderFirstScoreText: function() {
    return (
      <p>
        <span> You're the first to rate this article! </span>
        <span> The SIQ will be revealed after one more rating. </span>
      </p>
    )
  },

  renderBody: function() {
    if (this.props.score) {
      return this.renderShield();
    } else {
      return this.renderFirstScoreText();
    }
  },

  render: function(){
    if(this.props.article_base_class == "PmcArticle"){
      var cta_button = <a onClick={this.handleCancel} className="button large special">Close</a>
    }else{
      var cta_button = <a href="javascript:void(0)" onClick={this.handleCommentAdd} className="button large special"> Add Comment</a>
    }

    return (
      <div ref='modal' data-reveal className='reveal-modal text-center mobile-middling' id='score_thank_you'>
        <div className="modal-content">
          <div className='modal-body'>
            <div className='modal-close'>
              <a className="cancel" onClick={ this.handleCancel }>
                <img alt="Close_x" src="https://public.cureus.com/close_x_white.png"/>
              </a>
            </div>

            <img className='siq-modal-thank-you-img' src='https://public.cureus.com/siq-modal-thank-you.png'></img>

            { this.renderBody() }

            { this.props.children }

            { cta_button }
          </div>
        </div>
      </div>
    );
  }
});

export default ThankYouModal;