var createReactClass = require('create-react-class');
import React from 'react';
import ReviewerRating from './ReviewerRating';
import OwnershipRating from './OwnershipRating';
import { Cureus } from '../../../Cureus';

const RatingModal = createReactClass({
  getInitialState: function() {
    return {
      reviewershipRatings: {},
      ownershipRatings: {},
      rateables: this.props.rateables,
      comments: {}
    }
  },

  componentDidMount: function() {
    Cureus.ReviewerRatingStars.init('#publish-article-' + this.props.articleId)
  },

  submitRating: function() {
    var self = this;
    $.ajax({
      type: 'put',
      url: self.props.ratingsPath,
      data: {ownershipRatings: self.state.ownershipRatings, reviewershipRatings: self.state.reviewershipRatings, comments: self.state.comments},
      dataType: 'json',
      success: function(res){
        if (res.path) {
          window.location.replace(res.path);
        }
      },
      error: function(res) {
        Cureus.Flash.alert('There was an issue, please try later.')
      }
    })
  },

  handleOwnershipRating: function(ownId, rating) {
    var ratingsHash = this.state.ownershipRatings
    ratingsHash[ownId] = rating
    this.setState({ownershipRatings: ratingsHash})
  },

  handleReviewershipRating: function(revId, rating) {
    var ratingsHash = this.state.reviewershipRatings
    ratingsHash[revId] = rating
    this.setState({reviewershipRatings: ratingsHash})
  },

  handleCommentChange: function(e) {
    var commentsHash = this.state.comments
    commentsHash[e.target.name] = e.target.value
    this.setState({comments: commentsHash})
  },

  renderContents: function() {
    return (
      <div className="modal-content">
        <ReviewerRating 
          handleRating={this.handleReviewershipRating}
          submitRating={this.submitRating}
          ratings={this.state.reviewershipRatings}
          reviewerships={this.state.rateables.reviewerships}
          articleId={this.props.articleId}
        />
        <div className="img-container hide-for-small">
          <img src="https://public.cureus.com/reviewer-rating.jpg"/>
        </div>
        <div className="small-12 columns hr-container">
          <hr/>
        </div>
        <OwnershipRating 
          handleRating={this.handleOwnershipRating}
          submitRating={this.submitRating}
          ratings={this.state.ownershipRatings}
          ownerships={this.state.rateables.ownerships}
          articleId={this.props.articleId}
          handleCommentChange={this.handleCommentChange}
        />
        <div className="modal-footer text-center">
          <a className="button" id="submit" onClick={this.submitRating} disabled="true">Submit</a>
        </div>
      </div>
    )
  },

  render: function() {
    return(
      <div className="reveal-modal middling publish-article-modal" id={"publish-article-" + this.props.articleId} data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          {this.renderContents()}
        </div>
      </div>
    )
  }
})

export default RatingModal;


