import React, { useState, useEffect } from 'react';
import DeleteButton from '../../ChannelDesign/DeleteButton';
import UpdateButton from '../../ChannelDesign/UpdateButton';

const InternalContentCard = ({content, showActionButtons=false, handleDeleteWidget, handleUpdateWidget, mainContentWidgets, widgetInternalContentTabId, internalTab, internalTabUser}) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl((content.type === "LINK" ? content.displayUrl : content.contentFileLink))
  }, [content])

  const renderActionButtons = () => {
    if (showActionButtons) {
     return (
      <>
        <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
        <UpdateButton
          handleUpdateWidget={handleUpdateWidget}
          widget={content}
          modalName={"InternalContent"}
          mainContentWidgets={mainContentWidgets}
          widgetInternalContentTabId={widgetInternalContentTabId}
          internalTab={internalTab}
        />
        <DeleteButton
          handleDeleteWidget={handleDeleteWidget}
          widgetId={content.id}
          className=""
        />
      </>
     )
    }
  }

  return (
      <div className="internal-content-card internal-cards-styles">
        {!internalTabUser &&
          renderActionButtons()
        }
        <div className="file-type">
          <span>{content.type}</span>
        </div>
        <div className="title small">
          <a href={url} rel="nofollow" target="_blank">{content.title}</a>
        </div>
        <div className="img-container">
          <a href={url} rel="nofollow" target="_blank">
            <img src={content.image}/>
          </a>
        </div>
        <div className="description">
          <span>{content.blurb}</span>
        </div>
      </div>
  )
}

export default InternalContentCard;