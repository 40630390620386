import React from 'react';
import Quote from './Quote';

var createReactClass = require('create-react-class');

const Main = createReactClass({
  getInitialState: function() {
    return {
      users: this.props.users,
      start: 0,
      max: 1
    }
  },

  renderUser: function() {
    return <Quote user={this.props.users[this.state.start]} handleNext={this.handleNext}/>
  },

  handleNext: function(dir) {
    var start = this.state.start
    var length = this.state.users.length
    if (dir === 'left') {
      start = start - 1
      if(start < 0) {
        start = length + start
      }
    } else if (dir === 'right'){
      start = start + 1
      if(start >= length) {
        start = start - length
      }
    }
    this.setState({start: start})
  },

  render: function(){
    return (
      <React.Fragment>
          <div className="row">
            <div className="large-10 large-offset-1 medium-12 small-12 columns flex-container">
              <div className="large-1 medium-1 columns show-for-medium-only arrow-flex">
                <div className="channel-carousel-arrow-container">
                  <i className="far fa-arrow-alt-circle-left"  onClick={() => this.handleNext('left')}></i>
                </div>
              </div>
              <div className="medium-10 large-10 small-12 columns">
                <div className="quote-carousel-container">
                  {this.renderUser()}
                </div>
              </div>
              <div className="large-1 medium-1 columns show-for-medium-only arrow-flex">
                <div className="channel-carousel-arrow-container">
                  <i className="far fa-arrow-alt-circle-right" onClick={() => this.handleNext('right')}></i>
                </div>
              </div>
            </div>
          </div>
          <div className="row show-for-small-only">
            <div className="small-12 columns">
              <div className="channel-carousel-arrow-container">
                <i className="far fa-arrow-alt-circle-left"  onClick={() => this.handleNext('left')}></i>
                <i className="far fa-arrow-alt-circle-right" onClick={() => this.handleNext('right')}></i>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
})

export default Main