import React, { useEffect, useState } from 'react';
import { Cureus } from '../../Cureus';

const HistoryTable = ({path}) => {
  const [pictureQuizzes, setPictureQuizzes] = useState([]);
  useEffect(() => {
    fetchPictureQuizzes()
  }, [])

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.posted ? '<span><i class="fa fa-check"></i></span>' : '<span><i class="fa fa-pencil-alt"></i></span>',
        `<a href=${'/admin/picture_quizzes/' + row.id}>${row.question}</a>`,
        row.created_at,
        row.published_at,
        renderSplitButton(row.id)
      ], false)
    )
  }

  const setColumns = () => {
    return [
      {width: '70px'},
      {width: '325px'},
      { width: '135px', render: (data, type, row) => { return orderByUnixTime(data, type) } },
      { width: '135px', render: (data, type, row) => { return orderByUnixTime(data, type) } },
      { width: '135px'},
    ]
  }

  const orderByUnixTime = (data, type) => {
    if ( type === 'display' || type === 'filter' ) {
      return data
    } else {
      return Math.floor(new Date(data).getTime() / 1000)
    }
  }

  const fetchPictureQuizzes = () => {
    $.ajax({
      type: 'GET',
      url: path,
      success: function(data) {
        setPictureQuizzes(data.picture_quizzes)
        populateTable(data.picture_quizzes)
      }
    });
  }

  const populateTable = (data) => {
    var dt = $("#picture-quiz-history-table").DataTable({
      columns: setColumns(),
      "bAutoWidth": false,
      searching: false,
      dom: '<"row "<f>>tr<"row pagination-row"<"small-12 columns pagination-details" <"pull-left page-nums"p><"pull-left page-dropdown"l>>>',
    })
    if (!data) {
      data = []
    }
    dt.api().clear();
    for (var i = 0; i < data.length; i++) {
      let row = data[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[0, 'desc']])
    dt.fnDraw()
    Cureus.SplitButton.init();
  }

  const renderSplitButton = (id) => {
    return (
      `<div class='cureus-split-dropdown-wrap'>
        <a href=${'/picture_quizzes/' + id} class='button secondary tiny split' data-cureus-split-dropdown=${'quiz-actions' + id} >
          <div>View</div>
          <span />
        </a>

        <ul id=${'quiz-actions' + id} class='cureus-split-dropdown'>
          <li>
            <a href=${'/admin/picture_quizzes/' + id}>Edit</a>
          </li>

          <li>
            <a href=${'/admin/picture_quizzes/' + id} data-method='delete'>Delete</a>
          </li>
        </ul>
      </div>`
    )
  }

  return (
    <React.Fragment>
      <div className="">
        <table id="picture-quiz-history-table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Name</th>
              <th>Created</th>
              <th>Scheduled for</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

export default HistoryTable;