var createReactClass = require('create-react-class');
import React from 'react';

const ReviewsComplete = createReactClass({
  
  renderRequestRereview: function() {
    if(!this.props.article.wasDeferred && this.props.article.articleState !== 'in_rereview') {
      return (
        <li>
          <a href='#' className='has-tip tip-left' data-tooltip="" title="Edit the article and resubmit for additional reviews." id={"action-dropdown-request-re-review-tooltip-" + this.props.article.id} data-reveal-id={"request-re-review-modal-" + this.props.article.id}>
            Request re-review
          </a>
        </li>
      )
    }
  },

  renderEndPeerReview: function() {
    if(!this.props.article.endedPeerReview && this.props.article.articleState !== 'in_rereview') {
      return (
        <li>
          <a href='#' className='has-tip tip-left' data-tooltip="" title="You can end the review period to expire all outstanding and in-progress reviews. Any review invitations accepted in the prior 48 hours will not expire for an additional 24 hours. You do not need to wait for these reviews." id={"action-dropdown-end-review-tooltip-" + this.props.article.id} data-reveal-id={"end-review-modal-" + this.props.article.id}>
            End peer review
          </a>
        </li>
      )
    }
  },

  render: function() {
    return(
      <React.Fragment>
          <li>
            <a href={this.props.article.editArticlePath} className='has-tip tip-left' data-tooltip="" title="Make additional edits (based on reviewer feedback) before submitting for publication." id={"action-dropdown-edit-tooltip-" + this.props.article.id}>
              Edit article
            </a>
          </li>
          <li>
            <a target="_blank" href={this.props.article.previewPath} id={"preview-" + this.props.article.id}>Preview</a>
          </li>
          {this.renderEndPeerReview()}
          {this.renderRequestRereview()}
          {this.props.renderEmailEditingReceipt()}
          <li>
            <a className="dropdown-link" onClick={this.props.handleDelete}>Delete</a>
          </li>
      </React.Fragment>
    )
  }
})

export default ReviewsComplete;