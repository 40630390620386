import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../../Cureus/ChannelApi';
import DeleteButton from '../../DeleteButton';
import UpdateButton from '../../UpdateButton';
import SocialAccountIcon from './SocialAccountIcon';

const SocialAccountsWidget = ({slug, handleDeleteWidget, handleUpdateWidget, sidebarWidgets, widgetId, showActionButtons=true}) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if(widgetId) {
      let onSuccess = (res) => {
        setContent(res)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [sidebarWidgets])

  if(!content) {return <></>}

  const renderActionButtons = () => {
    if (showActionButtons) {
      return (
      <>
        <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
        <UpdateButton
          handleUpdateWidget={handleUpdateWidget}
          widget={content}
          modalName={"SocialAccountsModal"}
          slug={slug}
        />
        <DeleteButton
          handleDeleteWidget={handleDeleteWidget}
          widgetId={widgetId}
        />
      </>
      )
    }
  }
  
  const renderEmail = () => {
    return (
      <a href={"mailto:" + content.email}>
        <img src="https://public.cureus.com/homepage_new/email.png" alt="email" />
      </a>
    )
  }

  return (
    <div className="row">
      <div className="small-12 columns widget about-section follow-us-container">
        {renderActionButtons()}


        <h3>Follow us</h3>
        <div className='icon-container channels-about-widget'>
          {content.linkedin &&
            <SocialAccountIcon href={content.linkedin} img={"https://public.cureus.com/homepage_new/linkedin.png"} alt="linkedin" />
          }
          {content.facebook &&
            <SocialAccountIcon href={content.facebook} img={"https://public.cureus.com/homepage_new/facebook.png"} alt="facebook" />
          }
          {content.twitter &&
            <SocialAccountIcon href={content.twitter} img={"https://public.cureus.com/homepage_new/twitter.png"} alt="twitter" />
          }
          {content.instagram &&
            <SocialAccountIcon href={content.instagram} img={"https://public.cureus.com/homepage_new/instagram.png"} alt="instagram" />
          }
          {content.youtube &&
            <SocialAccountIcon href={content.youtube} img={"https://public.cureus.com/homepage_new/youtube.png"} alt="youtube" />
          }
          {content.email && renderEmail()}
        </div>
      </div>
    </div>
  )
}

export default SocialAccountsWidget;