import React, { useState, useEffect } from 'react';
import PersonRowCard from './PersonRowCard';
import MobilePersonRowCard from './MobilePersonRowCard';

const SearchResults = ({ people, loading }) => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderPeople = () => {
    return people.map((person, index) => {
      if (isMobile) {
        return <MobilePersonRowCard
          key={index}
          person={person}
        />
      } else {
        return <PersonRowCard
          key={index}
          person={person}
        />
      }
    })
  }

  const renderSpinner = () => {
    if (loading) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  }

  return (
    <div className='row'>
      <div className='large-11 medium-12 small-12 columns small-centered person-results end'>
        {renderSpinner()}
        {renderPeople()}
      </div>
    </div>
  );
};

export default SearchResults;