import React, { useEffect, useState } from 'react';
import Categories from './Categories';
import Types from './Types';
import Modal from '../../Modal';

const Filters = ({categories, contentTypes, updateTypes, updateCategories, categoryIds, submitUpdate, modal, id, categoryIdPreSelected}) => {
  const checkedLength = (elements) => {
    return Object.values(elements).filter((obj) => { return obj.checked && obj.id != 0}).length
  }

  const submitUpdateWrapper = () => {
    if (modal) {
      Modal.close()
    }
    submitUpdate()
  }

  return (
    <div id={id} className={(modal ? "new-articles-index" : "small-12 columns show-for-large-up") + " left-sidebar"}>
      <Types
        contentTypes={contentTypes}
        updateTypes={updateTypes}
        checkedLength={checkedLength}
        id={id}
      />
      {!categoryIdPreSelected && <Categories
        categories={categories}
        categoryIds={categoryIds}
        updateCategories={updateCategories}
        checkedLength={checkedLength}
        id={id}
      />}
      <a className="button small apply" onClick={submitUpdateWrapper}>Apply</a>
    </div>
  )
}

export default Filters