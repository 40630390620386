import React from 'react';
import Pages from '../Shared/Pagination/Pages';

const DetailedPerformance = ({ data, paginationData, setPage, handleSort, getCsv }) => {

  function renderRow(row) {
    var attributes = row.attributes;
    return (
      <tr>
        <td>
          <a href={attributes.campaignHref} target='_blank' rel='noreferrer'>{attributes.title}</a>
        </td>
        <td>
          <a href={attributes.href} target='_blank' rel='noreferrer'>{attributes.href}</a>
        </td>
        <td>
          {attributes.sentDate}
          <br/>
          <span>{attributes.sentDay}</span>
        </td>
        <td>
          {attributes.delivered}
        </td>
        <td>
          {attributes.clickRate}%
          <br/>
          <span>{attributes.clicks} clicked ({<a href="#" onClick={() => getCsv(attributes.csvPath, attributes.href)}>who</a>})</span>
        </td>
        <td>
          {attributes.ctor}
          <br/>
          <span>{attributes.opens} opened</span>
        </td>
      </tr>
    )
  }

  return (
    <div className='section'>
      <div className='row'>
        <h6>Detailed Performance</h6>
      </div>
      <table className="detailed-performance" id="detailed-performance-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("c.title")}>
              Campaign
            </th>
            <th style={{width: '400px'}} onClick={() => handleSort("link")}>
              Link
            </th>
            <th onClick={() => handleSort("c.sent_at")}>
              Sent
            </th>
            <th onClick={() => handleSort("cs.delivered")}>
              Delivered
            </th>
            <th onClick={() => handleSort("click_rate")}>
              Click rate
            </th>
            <th onClick={() => handleSort("ctor")}>
              CTOR
            </th>
          </tr>
        </thead>
        <tbody>
          { data.map(function(row) { return renderRow(row) }) }
        </tbody>
      </table>
      { paginationData.totalItems > 0 && <Pages
        paginationData={paginationData}
        handlePageSelect={setPage}
      /> }
    </div>
  )
}

export default DetailedPerformance;