var createReactClass = require('create-react-class');
import React from 'react';

const Index = createReactClass({
  getInitialState: function() {
    return({
      sortBy: 'name',
      categories: this.props.categories
    })
  },

  sortedCategories: function() {
    var newCategories = this.sort();
    this.setState({categories: newCategories})
  },

  sort: function() {
    var sortBy = this.state.sortBy;
    return this.state.categories.sort(function(cat1, cat2) {
      if (cat1[sortBy] > cat2[sortBy]) { return 1; }
      if (cat1[sortBy] < cat2[sortBy]) { return -1; }
      return 0;
    });
  },

  mapRows: function() {
    return this.props.categories.map(function(category) {
      return (
        <tr key={category.name}>
          <td>{category.name}</td>
          <td>{category.count}</td>
        </tr>
      )
    });
  },

  handleSort: function(sortType) {
    var self = this;
    if (sortType === self.state.sortBy) {
      self.setState({categories: self.state.categories.reverse()})
    } else {
      self.setState({sortBy: sortType}, function() {
        self.sortedCategories()
      });
    }
  },

  render: function() {
    var self = this;
    return(
      <table className='table-wrapper categories-table'>
        <thead>
          <tr>
            <th onClick={function(){ self.handleSort('name') }}>Category</th>
            <th onClick={function() { self.handleSort('count')}}>Used</th>
          </tr>
        </thead>
        <tbody>
          {this.mapRows()}
        </tbody>
      </table>
    )
  }
})

export default Index;