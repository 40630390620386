import React from 'react';

const Authors = ({seeMoreUsersPath, authors, viewMoreButton=true}) => {
  const renderAllAuthorInfo = () => {
    let elements = authors.map( (author, idx) => {
      return (
        <a key={idx} href={`/users/${author.user_id}`} target="_blank">
          <div className="card">
            <div className="img-container">
              <img src={author.avatar_profile_url}/>
            </div>
            {renderInfo(author)}
          </div>
        </a>
      )
    })

    return (
      <div className="author-infos">
        {elements}
      </div>
    )
  }

  const renderInfo = (author) => {
    return (
      <div className="info">
        <h2>{author.first_name}&nbsp;{author.last_name}</h2>
        {author.primary_affiliation && <p className="bio">{author.primary_affiliation}</p>}
      </div>
    )
  }

  const renderSuggestionButton = () => {
    return (
      viewMoreButton &&
      <a href={seeMoreUsersPath} target="_blank">
        <div className="button stencil-blue small text-center see-more-suggestions">
          VIEW MORE
        </div>
      </a>
    )
  }

  return (
    <div className='authors authors'>
      <div className="header">
        <div className="text">
          <h2>Top Authors</h2>
          <p>Based on recent article SIQ ratings</p>
          <img className='authors-question-mark-icon tip-top' src="https://public.cureus.com/homepage_new/question_mark.png" data-tooltip="" title="Authors of most popular articles are displayed if selected specialties lack articles with a sufficient number of SIQ scores." />
        </div>
        {viewMoreButton && <a href={seeMoreUsersPath} target="_blank">
          <i className="fas fa-arrow-right"/>
        </a>}
      </div>
      {renderAllAuthorInfo()}
      {renderSuggestionButton()}
    </div>
  )
}

export default Authors