import React, { useState} from "react";
import { Cureus } from '../../../../Cureus';

const CopyEmails = ({channelAdminEditors}) => {
  const [selected, setSelected] = useState('All')

  const copyEmailsToClipboard = (e) => {
    if (!channelAdminEditors) { return  }
    let emails = getEmails()
    navigator.clipboard.writeText(emails)
    Cureus.Flash.success(`${emails} copied to clipboard`)
  }

  const getEmails = () => {
    switch (selected) {
      case "All":
        return getEmailsByType()
      case "Admin":
        return getEmailsByType("ChannelAdmin")
      case "Editors":
        return getEmailsByType("DeputyEditor")
    }
  }

  const getEmailsByType = (type) => {
    let admins = ( type ? filterByEditorType(type) : channelAdminEditors )

    return admins.map((admin) => {
      return admin.email
    }).join(", ")
  }

  const filterByEditorType = (type) => {
    return channelAdminEditors.filter( (admin) => {
      return admin.user_type == type
    })
  }

  return (
    <div className="actions row">
      <span>Email</span>
      <select value={selected} onChange={(e) => setSelected(e.target.value)}>
        <option >All</option>
        <option>Admin</option>
        <option>Editors</option>
      </select>
      <button onClick={copyEmailsToClipboard} className="button primary small">Copy</button>
    </div>
  )
}

export default CopyEmails