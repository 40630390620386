import React, { useState, useRef, useEffect } from 'react';
import UploadPeerReviewers from './UploadPeerReviewers';
import AddPersonModal from './AddPersonModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus'

const PeerReviewers = ({adminArticlePage, updateCheckedState, reviewersPath, setReviewersCount, setLastUpdatedTimestamp, fetchReviewersPath, removeReviewersPath, reviewerPollingPath, uploadReviewersPath, userToolProps}) => {

  const [reviewers, setReviewers] = useState(null);
  const [isPolling, setPolling] = useState(false);
  const [spinning, setSpinning] = useState(true);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!isPolling) {
      const params = new URLSearchParams(window.location.search)
      if (params.get('batch_id') !== null) {
        setInterval(()=> checkBatchProgress(params.get('batch_id'), params.get('existing_reviewers')), 3000);
        setPolling(true)
      }
    }
  }, [isPolling])

  useEffect(() => {
    fetchData()
  }, [])

  const checkBatchProgress = (batchID, existingReviewers) => {
    $.ajax({
      type: 'GET',
      url: reviewerPollingPath,
      data: {batch_id: batchID, existing_reviewers: existingReviewers},
      success: function(data) {
        if (data.completed) {
          window.location.href = reviewersPath
        }
      }
    });
  }

  const fetchData = () => {
    setSpinning(true)
    $.ajax({
      type: 'GET',
      url: fetchReviewersPath,
      success: function(data) {
        setSpinning(false)
        if (setLastUpdatedTimestamp) {
          setLastUpdatedTimestamp(data.lastUpdated)
        }
        if (setReviewersCount) {
          setReviewersCount(data.reviewers.length)
        }
        setReviewers(data.reviewers)
        populateTable(data.reviewers)
      }
    });
  }

  const removeReviewers = () => {
    setSpinning(true)
    var channelReviewerIds = []
    $("input:checked").each(function() {
      channelReviewerIds.push($(this).data('resource-id'))
    })
    $.ajax({
      type: 'Delete',
      url: removeReviewersPath,
      data: {channel_reviewer_ids: channelReviewerIds},
      success: function(data) {
        fetchData()
        const checkedInputs = $("input:checked");
        setChecked(checkedInputs.length > 0)
      }
    });
  }

  const handleFileUploadClicked = (e) => {
    e.preventDefault()
    Modal.open({
      children: <UploadPeerReviewers uploadReviewersPath={uploadReviewersPath} />
    });
  }

  const addPersonModal = (e) => {
    e.preventDefault()
    Modal.open({
      children: <AddPersonModal fetchChannelReviewerData={fetchData} userToolProps={userToolProps} />
    });
  }

  const setColumns = () => {
    return [
      {width: '35px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      null
    ]
  }
  
  const checkboxClicked = (event) => {
    const checkedInputs = $("input:checked");
    setChecked(checkedInputs.length > 0)
    if (updateCheckedState){
      let channelReviewerIds = []
      $("input:checked").each(function() {
        channelReviewerIds.push($(this).data('resource-id'))
      })
      updateCheckedState(channelReviewerIds)
    }
  }

  const selectAllCheckboxes = (event) => {
    $('input:checkbox').prop('checked', event.target.checked);
    setChecked(event.target.checked)
    if (updateCheckedState){
      let channelReviewerIds = []
      $("input:checked").each(function() {
        channelReviewerIds.push($(this).data('resource-id'))
      })
      updateCheckedState(channelReviewerIds)
    }
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.checkbox,
        row.first_name,
        row.last_name,
        row.email,
        row.specialty_name,
        row.date_added,
        row.cureus_member ? 'Yes <i class="fa fa-check"></i>' : 'No',
        row.invites_accepted,
        row.reviews_completed,
        row.articles_published,
        row.sort_column
      ], false)
    )
  }

  const populateTable = (reviewers) => {
    var dt = $("#peer-reviewers-table").DataTable({
      columns: setColumns(),
      language: {
        "searchPlaceholder": "Search...",
        "search": ""
      },
      columnDefs: [
        {orderable: false, targets: [0]},
        {targets: [5], orderData: [10]},
        {targets: [10], visible: false}
      ],
      "bAutoWidth": false,
      dom: '<"row peer-review-search-row"<f>>tr<"row"<"small-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"small-4 columns table-length-container"<"text-right"l>>>',
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
      "rowCallback": function(row) {
        row.children[0].children[0].addEventListener('click',function(e) {
          checkboxClicked(e)
        })
      }
    })
    
    if (!reviewers) {
      reviewers = []
    }
    dt.api().clear();
    for (var i = 0; i < reviewers.length; i++) {
      let row = reviewers[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
  }

  if (spinning) {
    return (
      <React.Fragment>
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      </React.Fragment>
    )
  } else if (reviewers && reviewers.length > 0) {
    return (
      <React.Fragment>
        {!adminArticlePage &&
          <div>
            <a className="button add-single-btn primary" onClick={(e) => addPersonModal(e)}>+<i className='fa fa-user-friends'></i> ADD SINGLE REVIEWER</a>
            <a className="button upload-btn secondary" onClick={(e) => handleFileUploadClicked(e)}><i className='fa fa-upload'></i> UPLOAD CSV FILE</a>
            <a className={`button delete secondary ${checked ? '' : 'hidden'}`} onClick={(e) => removeReviewers(e)}><i className='fa fa-trash-alt'></i> Delete</a>
          </div> 
        }
        <div className="channel-reviewers-datatable-container">
          <table id="peer-reviewers-table">
            <thead>
              <tr>
                <th><input onClick={(e) => selectAllCheckboxes(e)} type="checkbox" /></th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email Address</th>
                <th>Specialty</th>
                <th>Added</th>
                <th>Confirmed Account</th>
                <th>Acceptance %</th>
                <th>Completed Reviews</th>
                <th>Published Articles</th>
                <th/>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <div className="empty-container text-center">
          <div>
            <i className='fa fa-user-friends'></i>
            <h3>No peer reviewers listed</h3>
            {!adminArticlePage &&
              <span>
                <a onClick={(e) => handleFileUploadClicked(e)}>Upload CSV file</a> or <a onClick={(e) => addPersonModal(e)}>add individually</a>
              </span>
            }
            {adminArticlePage &&
              <span>You can add peer reviewers <a href={reviewersPath}>here.</a></span>
            }
          </div>
        </div>        
      </React.Fragment>
    )
  }

}

export default PeerReviewers;