import React from 'react';
var createReactClass = require('create-react-class');

import EditorialWidgetBody from './EditorialWidgetBody';
import InvoicesWidget from './InvoicesWidget';
import PublishedContentWidget from './PublishedContentWidget';
import DateSelector from './DateSelector';

const Widget = createReactClass({
  
  getInitialState: function() {
    return({
      isCompetitionDashboard: this.props.isCompetition,
      dateRanges: this.props.dateRanges,
      selectedRange: this.props.isChannelDashboard ? this.props.dateRanges[4].label : this.props.dateRanges[0].label,
      from: this.props.isChannelDashboard ? this.props.dateRanges[4].from : this.props.dateRanges[0].from,
      to: this.props.isChannelDashboard ? this.props.dateRanges[4].to : this.props.dateRanges[0].to
    })
  },

  componentDidMount: function() {
    this.getStats(this.state.from, this.state.to, this.state.selectedRange)
  },

  getStats: function(fromDate, toDate, selectedRange) {
    var self = this;
    this.setState({
      from: fromDate,
      to: toDate,
      selectedRange: selectedRange
    }, function() {
      $.ajax({
        type: 'get',
        url: self.props.path,
        data: {from: self.state.from, to: self.state.to, default_dashboard: this.props.defaultDashboard},
        success: function(res) {
          self.setState({head: res.head, body: res.body})
        },
        error: function(e) {
        }
      })
    })
  },

  header: function() {
    if(!this.state.head) {
      return;
    }
    var self = this;
    var head = self.state.head;
    var isExpedited = head.title === "Expedited PMC"
    var stats = head.stats.map(function(stat, idx) {
      var content;
      if (stat.path) {
        content = (
          <a href={stat.path ? stat.path : '#'} target='_blank'>
            <h3 className='reg'>{stat.count}&nbsp;</h3>
            <h5 className='reg'>{stat.label}</h5>
          </a>
        )
      } else {
        content = (
          <div>
            <h3 className='reg'>{stat.count}&nbsp;</h3>
            <h5 className='reg'>{stat.label}</h5>
          </div>
        )
      }
      if(isExpedited && idx === 3) {
          return (
            <React.Fragment>
              <br></br>
              <br></br>
              <div style={{width: '161px', display: 'inline-block'}}>&nbsp;</div>
              <div className='published-content-stat' key={stat.label + '_header'}>
                {content}
              </div>
            </React.Fragment>
          )
      } else {
        return(
          <div className='published-content-stat' key={stat.label + '_header'}>
            {content}
          </div>
       )
      }
    });
    return(
      <div style={this.props.displayFlex ? {display: 'flex'} : {}}>
        <h6><i className={head.icon}/>{head.title} </h6>
        {stats}
      </div>
    )
  },

  body: function() {
    if(!this.state.body){return}
    var colType = this.state.head.title === 'Editorial' ? 'large-12 columns' : 'large-4 columns';
    var stats;
    if (this.props.widgetType === "editorialActivity") {
      stats = <EditorialWidgetBody
                  stats={this.state.body.stats}
                  isCompetition={this.props.isCompetition}
                  isChannel={this.props.isChannel}/>
    } else if (this.props.widgetType === 'invoicesWidget') {
        stats = <InvoicesWidget
                    stats={this.state.body.stats} />
    } else if (this.props.widgetType === 'publishedContent') {
        stats = <PublishedContentWidget
                    stats={this.state.body.stats} />
    } else if (this.props.widgetType === 'editorialQueue') {
      stats = this.state.body.stats.map(function(stat) {
        return(
          <div className={colType} key={stat.label +'_body'}>
            <div className='published-content-stat'>
              <h5 className='reg'>{stat.label} ({stat.count})</h5>
              <br/>
              <h5 className='reg'>{stat.average}</h5>
            </div>
          </div>
        )
      })
    } else {
      stats = this.state.body.stats.map(function(stat) {
       return(
         <div className={colType} key={stat.label +'_body'}>
           <div className='published-content-stat'>
             <h3 className='reg'>{stat.count}&nbsp;</h3>
             <h5 className='reg'>{stat.label}</h5>
           </div>
         </div>
       )
     })
    }

    return(
      <div className='published-contents-stats-wrap'>
        {this.state.body.title && <h6>{this.state.body.title}</h6>}
        <div className='recent-events-wrap clearfix'>
          {stats}
          <div className='large-8 columns'></div>
        </div>
      </div>
    )
  },

  render: function() {
    return(
      <div className='row'>
        <div className='large-12 columns'>
          <div className='dashboard-published-content-wrap' style={{backgroundColor: this.props.color}}>
            <div className='row'>
              <div className='large-10 columns'>
                <div className='published-content-stats-wrap'>
                  {this.header()}
                </div>
              </div>
              <div className='large-2 columns'>
                <DateSelector
                  from={this.state.from}
                  to={this.state.to}
                  getStats={this.getStats}
                  selectedRange={this.state.selectedRange}
                  dateRanges={this.state.dateRanges}
                />
              </div>
            </div>
            {this.state.body &&
              <div>
                <hr/>
                <div className='row'>
                  <div className='large-12 columns'>
                    {this.body()}
                  </div>
                </div>
                <hr/>
              </div>
            }
            <div>

            </div>
          </div>
        </div>
      </div>
    )
  },
});

export default Widget;