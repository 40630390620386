var createReactClass = require('create-react-class');
import React from 'react';
import Search from './Search';
import Channels from './Channels';
import Categories from './Categories';
import Sort from './Sort';
import Types from './Types';

const Filters = createReactClass({
  getInitialState: function() {
    return({
      categories: this.props.categories,
      subchannels: this.props.subchannels,
      types: this.props.contentTypes,
      sort: this.props.sorts,
      selectedSort: this.props.selectedSort,
      search: this.props.search || '',
      page: this.props.page
    })
  },

  searchProps: function() {
    return {
      typesRequired: this.props.typesRequired,
      updateSearch: this.props.updateSearch,
      handleSearchEnter: this.props.handleSearchEnter,
      handleSearchClick: this.props.handleSearchClick,
      handleAdvancedSearchEnter: this.props.handleAdvancedSearchEnter,
      handleAdvancedSearchClick: this.props.handleAdvancedSearchClick,
      articlesPage: this.props.articlesPage,
      hasSubchannels: this.props.hasSubchannels,
      channelVersion: this.props.channelVersion
    }
},

  renderCategories: function() {
    return (
      (!this.props.searchPage || this.props.posters) &&
      <Categories
        submitUpdate={this.props.submitUpdate}
        resetPage={this.props.resetPage}
        categories={this.state.categories}
        categoryIds={this.props.categoryIds}
        typesRequired={this.props.typesRequired}
        handleChange={this.props.updateCategories}
        compOrChannel={this.props.compOrChannel}
        posters={this.props.posters}
        hasSubchannels={this.props.hasSubchannels}
      />
    )
  },

  renderTypes: function() {
    return (
      this.props.typesRequired &&
      <Types
        submitUpdate={this.props.submitUpdate}
        resetPage={this.props.resetPage}
        types={this.state.types}
        handleChange={this.props.updateTypes}
        searchPage={this.props.searchPage}
        compOrChannel={this.props.compOrChannel}
        hasSubchannels={this.props.hasSubchannels}
      />
    )
  },

  renderSort: function() {
    return (
      <Sort
      sorts={this.props.sorts}
      updateSort={this.props.updateSort}
      typesRequired={this.props.typesRequired}
      selectedSort={this.props.selectedSort}
      searchPage={this.props.searchPage}
      compOrChannel={this.props.compOrChannel}
      posters={this.props.posters}
      hasSubchannels={this.props.hasSubchannels}
      searched={this.props.searched}
    />
    )
  },

  renderFilters: function() {
    if (this.props.channelVersion == 2) {
      return (
        <>
          {this.props.compOrChannel && !this.props.hasSubchannels && <Search
            {...this.searchProps()}
            compOrChannel={this.props.compOrChannel}
          />}
          {this.renderCategories()}
          {this.renderTypes()}
          {this.renderSort()}
        </>
      )
    } else {
      return (
        <>
          {this.renderCategories()}
          {this.renderTypes()}
          {this.renderSort()}
          {this.props.compOrChannel && !this.props.hasSubchannels && <Search
            {...this.searchProps()}
            compOrChannel={this.props.compOrChannel}
          />}
        </>
      )
    }
  },

  render: function() {
    return(
      <section>
        <div className="row">
          <div className="small-12 columns">
            {(!this.props.compOrChannel && !this.props.posters) && <Search
            {...this.searchProps()}
            compOrChannel={this.props.compOrChannel}
            advanced={this.props.advanced}
            filters={this.props.filters}
            search={this.state.search}
            />}
          </div>
        </div>
        <div className='content-filters-wrap'>
            {this.props.hasSubchannels && (
            <div className='row'>
              <Search {...this.searchProps()} />
            </div>)}
          <div className='row'>
          {this.props.hasSubchannels &&
              <Channels
                submitUpdate={this.props.submitUpdate}
                resetPage={this.props.resetPage}
                subchannels={this.state.subchannels}
                handleChange={this.props.updateSubchannels}
              />
            }
            {this.renderFilters()}
          </div>
        </div>
      </section>
    )
  }
});

export default Filters;