var createReactClass = require('create-react-class');
import React from 'react';

const UploadFileModal = createReactClass({
  getAuthenticityToken: function (){
    return $('meta[name="csrf-token"]').attr("content");
  },

  render: function (){
    return (
      <div className="email-list-tool-modal reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <div className='modal-form'>
              <h5>
                Upload Recipients to List
              </h5>
              <p><b>Important!</b> Only CSV files are accepted. Please include the following information in columns ordered from left to right: email address, first name, and last name.</p>
              <form ref="emailListUploadForm" acceptCharset="UTF-8" action={this.props.updateListPath} method="post" id="email-list-upload-form" encType="multipart/form-data">
                <input name="_method" type="hidden" value="put" />
                <input name="authenticity_token" type="hidden" value={this.getAuthenticityToken()} />
                <input ref="emailListFileUploadField" type="file" name="email_list[file]" />
                <span>Acceptable file types: CSV</span>

                <div className="form-actions">
                  <a className='cancel-modal button secondary'>CANCEL</a>
                  <input type='submit' value="IMPORT" className='button primary'/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default UploadFileModal;