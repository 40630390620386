
var createReactClass = require('create-react-class');
import React from 'react';
import SpecialtiesModal from './SpecialtiesModal';
import Modal from '../Modal';

const Specialties = createReactClass({
  getInitialState: function() {
    return({
      specialties: this.props.specialties
    })
  },

  innerLabel: function() {
    var checked = []
    Object.values(this.state.specialties).forEach(function(sp) {
      if (sp.checked) checked.push(sp.name)
    })
    if (checked.length) {
      return (
        <div>
          <div className='cureus-multi-select-placeholder'>
            {checked.join(', ')}
          </div>
          <div className='cureus-multi-select-count'>{checked.length}</div>
        </div>
      )
    } else {
      return (
        <div className='cureus-multi-select-placeholder'>
          <i className='fa fa-filter'/>
          &nbsp;
          ALL SPECIALTIES
        </div>
      )
    }
  },

  openSpecialtyModal: function() {
    Modal.open({
      children: <SpecialtiesModal specialtyIds={this.props.specialtyIds} specialties={this.state.specialties} handleChange={this.props.handleChange} submitUpdate={this.props.submitUpdate} resetPage={this.props.resetPage}/>
    })
  },

  render: function() {
    return(
      <div className="medium-6 small-12 columns">
        <div id='categories-multiselect-wrap'>
          <div className='cureus-custom-multi-select-wrap' >
            <div className='cureus-multi-select' onClick={this.openSpecialtyModal}>
              {this.innerLabel()}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Specialties;