import {Cureus} from '../Cureus';

Cureus.CustomDropdown = function() {
  var init, updateAllDropdownPositions, updateDropdownPosition, closeAllDropdowns, toggleDropdown, closeDropdown, openDropdown, dropdownIsOpen;
  var self = this;

  if (document.querySelector("a[data-custom-dropdown]")) {
    document.querySelector("a[data-custom-dropdown]").addEventListener("click", function(e) {
      var elm = e.target
      var dropdownSelector = e.target.dataset.customDropdown
      var dropdown = document.querySelector(dropdownSelector)

      self.toggleDropdown(dropdown, elm);

      e.preventDefault();
      e.stopPropagation();
    });

    $(window).resize(function() {
      self.updateAllDropdownPositions();
    });

    $('body').click(function() {
      self.closeAllDropdowns();
    });
    }
}

Cureus.CustomDropdown.prototype.toggleDropdown = function(dropdown, elm) {
  if(this.dropdownIsOpen(dropdown)){
    this.closeAllDropdowns();
  } else {
    this.openDropdown(dropdown, elm);
  }
}

Cureus.CustomDropdown.prototype.dropdownIsOpen = function(dropdown) {
  return dropdown.className.includes("open")
}

Cureus.CustomDropdown.prototype.openDropdown = function(dropdown, elm) {
    dropdown.addClass("right");
    this.closeAllDropdowns();
    this.updateDropdownPosition(dropdown, elm);
}

Cureus.CustomDropdown.prototype.closeAllDropdowns = function() {
  $("a[data-custom-dropdown]").each(function(index, elm) {
    var dropdown = document.querySelector(elm.dataset.customDropdown)
    dropdown.style.left = '-9999px'
    dropdown.removeClass("open");
  });

}

Cureus.CustomDropdown.prototype.updateDropdownPosition = function(dropdown, target) {
  var top, left;
  top = target.offsetTop + target.offsetHeight + 8;
  left = target.offsetLeft - dropdown.offsetWidth + target.offsetWidth

  dropdown.style.top = top + "px"
  dropdown.style.left = left + "px";
  dropdown.addClass("open");
}

Cureus.CustomDropdown.prototype.updateAllDropdownPositions = function() {
  var self = this;

  var dropdown = document.querySelector("a[data-custom-dropdown]").dataset.customDropdown
  if(dropdown.className) {
    if (dropdown.className.includes("open")) { self.updateDropdownPosition(dropdown, elm) }
  }
}