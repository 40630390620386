var createReactClass = require('create-react-class');
import React from 'react';

const Row = createReactClass({
  tooltipsHash: function() {
    return  {
      "Editor Check": "Preliminary review to determine if the article qualifies for free publication.",
      "Editor Preview": "Content review prior to approval for peer review.",
      "In Review": "Formal peer review by invited reviewers.",
      "In Re-review": "A second round of peer review initiated by the author or editor.",
      "Editor Approval": "Final editorial review and copy editing prior to publication.",
      "Published": "The article has been published and is viewable online."
    }
  },

  renderStateTd: function() {
    var version = this.props.version
    if(version.state === '-') {
      return (
        <td>{version.state}</td>
      )
    } else {
      return (
        <td id={this.props.index} className="has-tip tip-top" data-tooltip="" title={this.tooltipsHash()[version.state]}>{version.state}</td>
      )
    }
  },

  fetchSnapshot: function(e) {
    this.props.fetchSnapshot(e.target.dataset.url)
  },

  renderRadioButton: function() {
    var version = this.props.version
    if(this.props.snapshotId === version.id) {
      return (
        <td><input className="version-list-item" type="radio" data-url={version.value} name="version" defaultChecked={this.props.index === 0}/></td>
      )
    } else {
      return (
        <td><input className="version-list-item" type="radio" onClick={this.fetchSnapshot} data-url={version.value} name="version" defaultChecked={this.props.index === 0}/></td>
      )
    }
  },

  render: function() {
    var version = this.props.version
    return(
      <tr>
        {this.renderRadioButton()}
        <td>{version.versionNum}</td>
        <td>{version.date}</td>
        {this.renderStateTd()}
        <td>{version.commentsCount}</td>
      </tr>
    )
  }
})

export default Row;