import React from 'react';

const MapButton = ({setView, title, viewName, activeView}) => {

  return (
    <a className={"button stencil-blue " + (activeView === viewName ? 'active' : '')} onClick={() => setView(viewName)}>
      {title}
    </a>
  )
}

export default MapButton;