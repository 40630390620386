import {Cureus} from '../Cureus';

Cureus.ContentCard = function(container) {
  this.card = container;
  this.posX = null;
  this.posY = null;
  this.width = null;
  this.height = null;
  this.initializePosition();
}

Cureus.ContentCard.prototype.addClass = function(className) {
  this.card.addClass(className);
}

Cureus.ContentCard.prototype.getWidth = function() {
  return this.width;
}
Cureus.ContentCard.prototype.getHeight = function() {
  return this.height;
}
Cureus.ContentCard.prototype.getPos = function() {
  return {x: this.posX, y: this.posY}
}

Cureus.ContentCard.prototype.initializePosition = function() {
  this.calculateDims();
  this.setPosAbsolute();
}
Cureus.ContentCard.prototype.setPos = function(x, y) {
  this.posX = x;
  this.posY = y;
  this.card.style.top = this.posY + 'px';
  this.card.style.left = this.posX + 'px';
}

Cureus.ContentCard.prototype.calculateDims = function() {
  this.width = this.calculateWidth();
  this.height = this.calculateHeight();
}

Cureus.ContentCard.prototype.calculateHeight = function() {
  return this.card.offsetHeight;
}

Cureus.ContentCard.prototype.calculateWidth = function() {
  return this.card.offsetWidth;
}

Cureus.ContentCard.prototype.setPosAbsolute = function() {
  this.card.style.position = 'absolute';
}

Cureus.ContentCard.prototype.getDims = function() {
  return {w: this.getWidth(), h: this.getHeight()}
}


Cureus.Shuffler = function(options) {
  if(!options || (typeof(options) === "undefined")){ options = {} };
  this.config = {};
  this.allCards = [];
  this.setConfig(options);
  this.setNumColumns(this.determineColumnCount());
  this.initializeCards();
  this.outputArray = this.buildOutputArray(this.allCards, this.config.numColumns)
  this.bindReshuffleOnResize();
  this.shuffle(this.outputArray)
  imagesLoaded(this.config.containerSelector, this.reshuffle.bind(this))
  window.setTimeout(this.reshuffle.bind(this), 350);
}

Cureus.Shuffler.prototype.reshuffle = function() {
  this.flushCards();
  this.initializeCards();
  this.setNumColumns(this.determineColumnCount());
  this.recalculateCardDims();
  this.outputArray = this.buildOutputArray(this.allCards, this.config.numColumns);
  this.shuffle(this.outputArray);
}

Cureus.Shuffler.prototype.reshuffleHard = function() {
  this.reshuffle();
  window.setTimeout(this.reshuffle.bind(this), 400);
}

Cureus.Shuffler.prototype.recursiveShuffle = function() {
  var el = this.config.containerSelector;
  var height = el.outerHeight;
  this.reshuffle();
  window.setTimeout(function() {
    if (el.outerHeight !== height) { this.recursiveShuffle() }
  }.bind(this), 400);
}

Cureus.Shuffler.prototype.bindReshuffleOnResize = function() {
  window.addEventListener('resize', this.reshuffleHard.bind(this))
}

Cureus.Shuffler.prototype.buildOutputArray = function(cards, columnCount) {
  var colItor = 0;
  var output = [];
  var currInnerAry = [];

  for (var cardItor = 0; cardItor < cards.length; cardItor++) {
    if (colItor === columnCount - 1) {
      currInnerAry.push(cards[cardItor]);
      output.push(currInnerAry);
      currInnerAry = [];
      colItor = 0;
    } else {
      currInnerAry.push(cards[cardItor]);
      colItor++;
    }
  }
  if (currInnerAry.length > 0) { output.push(currInnerAry) }
  return output;
}

Cureus.Shuffler.prototype.determineColumnCount = function() {
  var small = "only screen and (max-width: 40rem)";
  var medium = "only screen and (min-width: 40.063rem) and (max-width: 63.9375rem)";
  var large = "only screen and (min-width: 64rem)";

  if(window.matchMedia(small).matches) { return 1; }
  if(window.matchMedia(medium).matches) { return 2; }
  if(window.matchMedia(large).matches) {
    return this.config.maxColumns;
  }
}

Cureus.Shuffler.prototype.flushCards = function() {
  this.allCards = null;
}

Cureus.Shuffler.prototype.getPaddingLeft = function() {
  switch(this.getNumColumns()){
    case 1:
      return this.config.paddingLeft + (this.config.paddingLeft * 0.95);
    case 2:
      return this.config.paddingLeft;
    case 3:
      return this.config.paddingLeft + (this.config.paddingLeft * 1.5);
    default:
      return this.config.paddingLeft;
  };
}

Cureus.Shuffler.prototype.getNumColumns = function() {
  return this.config.numColumns;
}

Cureus.Shuffler.prototype.initializeCards = function() {
  var self = this;
  var cardSelector = self.config.containerSelector + ' .content-card ,' +
    self.config.containerSelector + ' .user-card ,' +
    self.config.containerSelector + ' .user-card-completion';

  self.allCards = Array.prototype.map.call(document.querySelectorAll(cardSelector), function(curr, idx, list){
    var newC = new Cureus.ContentCard(curr);
    if (self.config.numColumns > 3) { newC.addClass('four-col')}
    return newC;
  });
}

Cureus.Shuffler.prototype.recalculateCardDims = function() {
  this.allCards.forEach(function(curr) {
    curr.calculateDims();
  });
}

Cureus.Shuffler.prototype.setConfig = function(options) {
  this.config.containerSelector = options.containerSelector || '.content-index-results-columns';
  this.config.paddingLeft = options.padding || 16;
  this.config.paddingTop = options.padding || 16;
  this.config.containerPaddingBottom = options.containerPaddingBottom || 32;
  this.config.maxColumns = options.maxColumns || 3;
}

Cureus.Shuffler.prototype.setNumColumns = function(numCol) {
  this.config.numColumns = numCol
}

Cureus.Shuffler.prototype.setContainerHeight = function(columnsArray) {
  var height = 0;
  columnsArray.forEach(function(row) {
    row.forEach(function(card) {
      var cardBottomY = card.getPos().y + card.getHeight();
      if (cardBottomY > height) { height = cardBottomY }
    });
  });

  document.querySelector(this.config.containerSelector).style.minHeight = (height + this.config.containerPaddingBottom) + 'px';
}

Cureus.Shuffler.prototype.shuffle = function(columnsArray) {
  var self = this;
  columnsArray.forEach(function(innerAry, itor, list) {
    innerAry.forEach(function(currCard, innerItor, innerList) {
      var prevCardInRow = (innerItor === 0 ? null : innerList[innerItor - 1]);
      var prevCardInCol = (itor === 0 ? null : list[itor - 1][innerItor]);
      if (prevCardInRow) {
        var newX = prevCardInRow.getPos().x + prevCardInRow.getWidth() + self.config.paddingLeft;
      } else {
        var newX = 0;
      }
      if (prevCardInCol) {
        var newY = prevCardInCol.getPos().y + prevCardInCol.getHeight() + self.config.paddingTop;
      } else {
        var newY = 0;
      }
      currCard.setPos(newX, newY);
    });
  });
  if (columnsArray.length > 0) { self.setContainerHeight(columnsArray) }
}

Cureus.ContentCardShuffler = function(options) {
  this.instances = [];
  var newShuffler = new Cureus.Shuffler(options);
  this.instances.push(newShuffler);
  return newShuffler;
}

Cureus.ContentCardShuffler.prototype.recursiveShuffle = function() {
  this.instances.forEach(function(inst) {
    inst.recursiveShuffle();
  });
}