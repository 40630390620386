import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import Modal from '../../../components/Modal';

const HeroEditModal = ({
  themeColor,
  fontColor,
  submitBtnColor,
  heroImageFile,
  logoFile,
  heroUrl,
  logoUrl,
  handleSave,
}) => {

  const [newThemeColor, setNewThemeColor] = useState(themeColor || '');
  const [newFontColor, setNewFontColor] = useState(fontColor || '');
  const [newSubmitBtnColor, setNewSubmitBtnColor] = useState(submitBtnColor || '');
  const [newHeroImageFile, setNewHeroImageFile] = useState(heroImageFile || '');
  const [newLogoFile, setNewLogoFile] = useState(logoFile || '');
  const [newdisableBtnForUpdate, setNewDisableBtnForUpdate] = useState('');
  const [disableBtnForUpdate, setDisableBtnForUpdate] = useState('');

  const renderHeroFileOrHeroUrl = () => {
    if (newHeroImageFile) {
      return <span>{newHeroImageFile.name}</span>
    } else if(heroUrl) {
      return <img className='pic-thumbnail' src={heroUrl} alt='hero-image' />
    } else {
      return <></>
    }
  }

  const renderLogoFileOrLogoUrl = () => {
    if (newLogoFile) {
      return <span>{newLogoFile.name}</span>
    } else if(logoUrl) {
      return <img className='pic-thumbnail' src={logoUrl} alt='logo-image' />
    } else {
      return <></>
    }
  }

  const handleSaveUpdater = (newThemeColor, newFontColor, newSubmitBtnColor, newHeroImageFile, newLogoFile) => {
    setDisableBtnForUpdate(true);
    handleSave(newThemeColor, newFontColor, newSubmitBtnColor, newHeroImageFile, newLogoFile);
  }

  const shouldDisable = () => {
    return (submitBtnColor === newSubmitBtnColor) && (fontColor === newFontColor) && (themeColor === newThemeColor) && (heroImageFile === newHeroImageFile) && (logoFile === newLogoFile)
  }


  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Channel Hero Settings</h5>
          </div>
          <hr/>
          <p className="text-left">Upload your channel logo and homepage banner (hero) image and select your theme color. Click on the color preview to select a color or enter the hex code manually.</p>


          <div className="row">
            <div className="small-9 columns input-row no-pad">
              <div className="input-container">
                <label htmlFor="theme-color">Theme color hex code</label>
                <input
                  type="text"
                  name="theme-color"
                  placeholder="Please, enter color hex code."
                  value={newThemeColor}
                  onChange={(e) => setNewThemeColor(e.target.value)}
                />
              </div>
              <input className='color-picker' type="color" value={newThemeColor} onChange={(e) => setNewThemeColor(e.target.value)}/>
            </div>
          </div>

          <div className="row">
            <div className="small-9 columns input-row no-pad">
              <div className="input-container">
                <label htmlFor="font-color">Font color hex code</label>
                <input type="text" name="font-color" placeholder="Please, enter hex code." value={newFontColor} onChange={(e) => setNewFontColor(e.target.value)}/>
              </div>
              <input className='color-picker' type="color" placeholder='#000000' value={newFontColor} onChange={(e) => setNewFontColor(e.target.value)}/>
            </div>
          </div>

          <div className="row">
            <div className="small-9 columns input-row no-pad">
              <div className="input-container">
                <label htmlFor="font-color">“Submit” button color hex code</label>
                <input type="text" name="font-color" placeholder="Please, enter hex code." value={`${newSubmitBtnColor}`} onChange={(e) => setNewSubmitBtnColor(e.target.value)}/>
              </div>
              <input className='color-picker' type="color" value={`${newSubmitBtnColor}`} onChange={(e) => setNewSubmitBtnColor(e.target.value)}/>
            </div>
          </div>

          <div className="row file-container">
            <div className="small-8 columns file-uploads no-pad">
              <p>Hero</p>
              <label htmlFor="hero-file" className="button small secondary">Choose File</label>
              <input
                id="hero-file"
                type="file"
                className="show-for-sr"
                onChange={(e) => setNewHeroImageFile(e.target.files[0])}
                style={{width: '200px'}}
              />
              {renderHeroFileOrHeroUrl()}
            </div>
          </div>

          <div className="row file-container">
            <div className="small-8 columns file-uploads no-pad">
              <p>Logo</p>
              <label htmlFor="logo-file" className="button small secondary">Choose File</label>
              <input
                id="logo-file"
                className="show-for-sr"
                type="file"
                onChange={(e) => setNewLogoFile(e.target.files[0])}
                style={{width: '200px'}}
              />
              {renderLogoFileOrLogoUrl()}
            </div>
          </div>

          <div className='modal-footer text-center'>
            <a className={"button " + (shouldDisable() ? 'disabled' : '')} disabled={disableBtnForUpdate} onClick={() => handleSaveUpdater(newThemeColor, newFontColor, newSubmitBtnColor, newHeroImageFile, newLogoFile)}>Apply</a>
          </div>
        </div>
      </div>
    </div>
  )
}


export default HeroEditModal;