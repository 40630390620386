import React from 'react';

const MentioningMedia = () => {

  return (
    <React.Fragment>
      <h1>Mentioning Media in Your Article</h1>
      <p>Figures, tables, videos and interactive models must be mentioned in the preceding paragraph.</p>
      <ul className='disc indented-for-medium-up'>
        <li>(Figure 1)</li>
        <li>(Tables 1, 2)</li>
        <li>(Videos 1-3)</li>
        <li>(Interactive Model 4)</li>
      </ul>
      <p>If the figure contains multiple panes, each pane should be labeled with a letter and this should be included when mentioning the figure in the article.</p>
      <ul className='disc indented-for-medium-up'>
        <li>(Figure 1A)</li>
      </ul>
      <p>If you wish to refer to figures, tables, videos or interactive models within a sentence and not within parentheses (e.g. “This was a particularly complicated procedure, as can be seen in Figure 1”), please adhere to the following format. (The same applies for tables, videos and interactive models as well.):</p>
      <ul className='disc indented-for-medium-up'>
        <li>Figure 1</li>
        <li>Interactive Model 1</li>
        <li>Tables 1, 2</li>
        <li>Videos 1-4</li>
        <li>Figure 1A</li>
        <li>Figures 1A, 1B</li>
        <li>Figures 1A-1D</li>
      </ul>
    </React.Fragment>
  )

}

export default MentioningMedia;