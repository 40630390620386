var createReactClass = require('create-react-class');
import React from 'react';

const ArticleDetails = createReactClass({
  renderAdditionalDetails: function() {
    if (this.props.article.state === "reviews_complete" && this.props.wasDeferred ) {
      return "Please revise your article according to the editorial feedback and resubmit when ready."
    }
  },

  render: function() {
    return (
      <div className="dashboard-title-wrap">
        <h6 className="subhead">{this.props.article.title || "No title provided"}</h6>
        <div className="deemphasis" dangerouslySetInnerHTML={{__html: this.props.authorListLinksMarkup}} />
        <p className="dashboard-article-description deemphasis">
          {this.props.articleType}
          <br/>
          {this.renderAdditionalDetails()}
        </p>
      </div>
    )
  }
})

export default ArticleDetails;