import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// Introduction
import WhyReview from './ReviewerPages/WhyReviewWithCureus';
// How it Works
import StepByStepOverview from './ReviewerPages/StepByStepOverview'
import RereviewProcess from './ReviewerPages/RereviewProcess';
// Plagiarism
import SuspectPlagiarism from './ReviewerPages/SuspectPlagiarism';
// Search
import SearchResults from './SearchResults';
import Introduction from './ReviewerPages/Introduction';


const ReviewerGuide = ( { query, urls, setFooterText }) => {
  return (
    <Switch>
      <Route path="/overview/why-review-with-cureus">
        <WhyReview urls={ urls } setFooterText={setFooterText}/>
      </Route>
      <Route path="/overview">
        <Redirect to="/overview/why-review-with-cureus"/>
      </Route>

      <Route path="/how-it-works/introduction">
        <Introduction/>
      </Route>
      <Route path="/how-it-works/step-by-step-overview">
        <StepByStepOverview/>
      </Route>
      <Route path="/how-it-works/rereview-process">
        <RereviewProcess/>
      </Route>
      <Route path="/how-it-works">
        <Redirect to="/how-it-works/introduction"/>
      </Route>

      <Route path="/plagiarism/suspect-plagiarism">
        <SuspectPlagiarism/>
      </Route>
      <Route path="/plagiarism">
        <Redirect to="/plagiarism/suspect-plagiarism"/>
      </Route>

      <Route path="/search">
        <SearchResults query={ query } guide={ 'reviewer_guide' }/>
      </Route>
      <Redirect to="/overview/why-review-with-cureus"/>
    </Switch>
  )
}

export default ReviewerGuide;