import React from 'react';

const PollOption = ({quizOption, idx, onChangeAnswer, onChangeCorrect}) => {
  return (
    <div className="row">
      <div className="columns small-10">
        <div className="input-container">
          <label>{`Answer ${idx + 1}`}</label>
          <div style={{ display: 'flex'}}>
            <input 
              type="text" 
              placeholder={`Enter the answer choice here.`} 
              name='answer' 
              value={quizOption.answer} 
              onChange={(e) => onChangeAnswer(e, idx)}
            />
            <input className='selection-btn' type='radio' value={`answer-${idx}`} checked={quizOption.correct} onChange={(e) => onChangeCorrect(e, idx)} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PollOption;