var createReactClass = require('create-react-class');
import React from 'react';

const FlagRow = createReactClass({
  getInitialState: function() {
    return({
      flag: this.props.flag
    })
  },

  handleReject: function(e) {
    var self = this;
    if (confirm("Are you sure you wish to reject this flagging?")) {
      $.ajax({
        type: 'put',
        url: self.state.flag.rejectFlag,
        dataType: 'json',
        success: function(res){
          self.props.updateFlaggings();
        },
        error: function(res) {
          Cureus.Flash.alert('There was an issue, please try later.')
        }
      })
    }
  },

  render: function() {
    return(
      <tr>
        <td colSpan='6'>
          <div className='row'>
            <div className='large-3 columns'>
              <h7 className='reg'>Reason: {this.state.flag.reason}</h7>
            </div>
            <div className='large-3 columns'>
              <h7 className='reg'>Flagger: {this.state.flag.flagger}</h7>
            </div>
            <div className='large-3 columns'>
              <h7 className='reg'>State: <strong>{this.state.flag.state}</strong></h7>
            </div>
            <div className='large-2 columns'>
              <div className='row'>
                <div className='large-4 columns'><a onClick={this.props.handleDelete}><i className='far fa-trash-alt'/></a></div>
                <div className='large-4 columns end'><a onClick={this.handleReject}><i className='fa fa-thumbs-up'/></a></div>
              </div>
            </div>
            <div className='large-1 columns'>
              <h7 className='reg '>
                <a onClick={this.props.closeFlag}><i className='fa fa-times pull-right'/></a>
              </h7>
            </div>
          </div>
        </td>
      </tr>
    )
  }
})

export default FlagRow;