import React, { useEffect, useState } from 'react';
import ProgressCard from './ProgressCard';
import MetricsApi from '../../Cureus/Admin/MetricsApi';

const ProgressStats = ({ name, progressPath }) => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    MetricsApi.makeRequest(progressPath, 'POST', {name: name, progress: true}, function(res) { setStats(res) })
  }, [])

  if(!stats) {
    return <div/>
  } else {
    return (
      <div className="large-6 columns">
        <ProgressCard 
          current={stats.currStats.submissions}
          total={stats.currStats.subTotal}
          title={"Submissions Editors vs. Previous Day"}
          prev={stats.prevStats.submissions}
        />
        <hr/>
        <ProgressCard 
          current={stats.currStats.approval}
          total={stats.currStats.appTotal}
          title={"Approval Editors vs. Previous Day"}
          prev={stats.prevStats.approval}
        />
        <hr/>
        <ProgressCard 
          current={stats.currStats.copy}
          total={stats.currStats.copyTotal}
          title={"Copy Editors vs. Previous Day"}
          prev={stats.prevStats.copy}
        />
      </div>
    )
  }
}

export default ProgressStats;