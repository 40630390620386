import React, {useState} from 'react';
import ChecklistItem from './ChecklistItem';

const CheckListDesk = ({buttonDisable, handleCheck, blockOrDeskItems, handleSelectAll}) => {

  const renderSelectAll = (deferType) => {
    return(
      <div className="checklist-item row">
        <div className="large-3 large-offset-8 columns end">
          <div className="select-all">
            <a className="button tiny success" onClick={() => handleSelectAll(deferType)}>Select All</a> 
          </div>
        </div>
      </div>
    )  
  }

  return (
    <div className="checklist">
      {renderSelectAll('block')}
      <ChecklistItem 
        title={"New submission after deferral"}
        buttonDisable={buttonDisable}
        type={'block'}
        handleCheck={handleCheck}
        index={4}
        blockOrDeskItems={blockOrDeskItems}
      />
      <ChecklistItem 
        title={"New submission after desk rejection or block"}
        buttonDisable={buttonDisable}
        type={'block'}
        handleCheck={handleCheck}
        index={5}
        blockOrDeskItems={blockOrDeskItems}
      />
      <ChecklistItem 
        title={"Fraudulent peer reviewers (fake or same as authors) **"}
        buttonDisable={buttonDisable}
        type={'block'}
        handleCheck={handleCheck}
        index={6}
        blockOrDeskItems={blockOrDeskItems}
      />
      <ChecklistItem 
        title={"Plagiarism (as seen in iThenticate report) **"}
        buttonDisable={buttonDisable}
        type={'block'}
        handleCheck={handleCheck}
        index={7}
        blockOrDeskItems={blockOrDeskItems}
      />
      <ChecklistItem 
        title={"Other issues"}
        buttonDisable={buttonDisable}
        type={'block'}
        handleCheck={handleCheck}
        index={8}
        blockOrDeskItems={blockOrDeskItems}
      />        
    </div>
  )
}

export default CheckListDesk;