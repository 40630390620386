import React, { useEffect, useState } from 'react';
import Authors from './Authors';
import Contributors from './Contributors';
import TrendingTopics from './TrendingTopics';
import PopularArticles from './PopularArticles';
import Reviewers from './Reviewers';

const Sidebar = ({triggerRightSideBar, articleCount, articlePath, contents, updateSearch, selectedCategoryIds, categoryIdPreSelected}) => {
  const [authors, setAuthors] = useState([])
  const [reviewers, setReviewers] = useState([])
  const [authorsCount, setAuthorsCount] = useState(0)
  const [avatars, setAvatars] = useState([])
  const [reviewersCount, setReviewersCount] = useState(0)
  const [seeMoreUsersPath, setSeeMoreUsersPath] = useState('/people')
  const [keywords, setKeywords] = useState([]);

  useEffect( () => {
    getHighestSiqUsers();
    fetchKeyWords();
  }, [triggerRightSideBar])

  const getHighestSiqUsers = () => {
    fetch("/users/contributors_of_high_siq_articles" + "?" + new URLSearchParams({
      categories: categoryIds(),
      get_reviewers: categoryIdPreSelected ? true : ''
    }))
    .then((res) => res.json())
    .then((json) => {
      setAuthors(json.authors);
      setReviewers(json.reviewers);
      setAuthorsCount(json.authorsCount)
      setAvatars(json.avatars);
      setReviewersCount(json.reviewersCount)
      setSeeMoreUsersPath(json.seeMoreUsersPath)
    })
  }

  const fetchKeyWords = () => {
    $.ajax({
      method: "GET",
      url: "/articles/most_popular_keywords",
      dataType: 'json',
      data: { category_ids: categoryIds() },
      success: function(res) {
        setKeywords(res.keyWords);
      },
      error: function(res) {
      }
    }, 'json');
  }

  const categoryIds = () => {
    if (selectedCategoryIds.length == 1 && selectedCategoryIds[0] == 0) {
      return ''
    } else {
      return getSelectedCategoryIds()
    }
  }

  const getSelectedCategoryIds = () => {
    return selectedCategoryIds.join(",")
  }
  
  return (
    <div className="small-2 columns show-for-large-up right-sidebar">
      <Contributors
        authorsCount={authorsCount}
        reviewersCount={reviewersCount}
        avatars={avatars}
        articleCount={articleCount}
      />
      <TrendingTopics
        articlePath={articlePath}
        updateSearch={updateSearch}
        keywords={keywords}
      />
      <Authors
        seeMoreUsersPath={seeMoreUsersPath}
        authors={authors}
      />
      {categoryIdPreSelected && reviewers.length > 1 &&
        <Reviewers 
          seeMoreUsersPath={seeMoreUsersPath}
          reviewers={reviewers}
        />
      }
      <PopularArticles
        contents={contents}
        selectedCategoryIds={selectedCategoryIds}
      />
    </div>
  )
}

export default Sidebar