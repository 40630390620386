var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal';

const DeleteModal = createReactClass({
  getInitialState: function() {
    return {editor: CKEDITOR.instances[this.props.ckEditorId]}
  },

  handleDelete: function() {
    var attachment = this.state.editor.document.getById(this.props.attachmentType + '-anchor-' + this.props.attachmentId)
    attachment.getParents()[2].remove()
    this.syncAttachmentNumbers();
    this.closeModal()
  },

  capitalizeAttachmentType: function(attachmentType){
    if (attachmentType === 'interactive_model') {
      return attachmentType.split('_').map(word => { return word.charAt(0).toUpperCase() + word.slice(1, word.length)}).join(' ')
    } else {
      return attachmentType.charAt(0).toUpperCase() + attachmentType.slice(1);
    }
  },

  updateHiddenTextarea: function(){
    $('#' + this.props.ckEditorId).html(this.state.editor.getData());
    $('#' + this.props.ckEditorId).val(this.state.editor.getData());
  },

  closeModal: function() {
    var self = this;
    Modal.close({onCloseInstant: function() {self.updateHiddenTextarea()}});
  },

  syncAttachmentNumbers: function(){
    var self = this;
    var html = $.parseHTML(this.state.editor.getData())

    // append to a temp element in order to traverse the dom tree
    var tempDom = $('<div></div>').append(html)

    // store arrays of the attachment markup with wrapper
    var attachments = {
      figure: [],
      table: [],
      video: [],
      interactive_model: []
    }

    // store the min range of each attachment type
    var minNumbers = {
      figure: null,
      table: null,
      video: null,
      interactive_model: null
    };

    // push attachment into appropriate array
    $('.attachment-wrapper', tempDom).each(function(index, elem){
      // Expected anchorId: figure-anchor-1234
      var anchorId = elem.dataset.anchorId.split('-');
      attachments[anchorId[0]].push(elem);
    })

    // set the min range for each attachment type
    for(var attachmentType in attachments){
      var minNumber = self.findMinNumber(attachments[attachmentType], attachmentType)
      if(minNumber){
        minNumbers[attachmentType] = minNumber;
      }
    }

    // sets the number for all the attachments in respect to the min value of each range
    for(var attachmentType in attachments){
      for(var i=0; i < attachments[attachmentType].length; i++){
        self.syncNumber(attachments[attachmentType][i], i + minNumbers[attachmentType])
      }
    }
  },

  syncNumber: function(elem, number){
    elem.dataset.number = number; // replace number in wrapper or add if doesn't exist
    $(elem).find('.article-attachment-wrap')[0].dataset.number = number // replace number in main wrap or add if doesn't exist
    var title = $(elem).find('.article-attachment-wrap .attachment-title').html().match(/^(.*?):(.*)/);
    $(elem).find('.article-attachment-wrap .attachment-title').html([title[1].replace(/(\d+)(?!.*\d)/, number), title[2]].join(':'));

    this.state.editor.document.findOne('[data-anchor-id="' + elem.dataset.anchorId + '"]').$.replaceWith(elem)
  },

  findMinNumber: function(attachments, attachmentType){
    var numbers = attachments.map(function(a){
      return parseInt($(a).find('.article-attachment-wrap .attachment-title').text().split(':')[0].split(' ').pop())
    })
    var minNumber;
    this.checkPreviousAttachment(attachmentType).success(function(previousContentNumber){
      if(numbers.length > 0){ // elements found on the page
        var pageMin = Math.min.apply(null, numbers) // minimum element on the page
        minNumber  = (previousContentNumber.number + 1 < pageMin ? previousContentNumber.number + 1 : pageMin)
      }
    });
    return minNumber
  },

  checkPreviousAttachment: function(type) {
    var self = this;
    var path = "/publish/articles/" + self.props.articleId +"/attachments/previous";
    return ($.ajax(
      {
        url: path,
        type: "GET",
        async: false,
        data: {
          attachment_type: type,
          article_content_type: self.props.articleContentType
        }
      }
    ))
  },

  render: function(){
    return (
      <div className="reveal-modal tiny" data-reveal>
       <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <h4>Delete {this.capitalizeAttachmentType(this.props.attachmentType)}?</h4>
          <p>Are you sure? This action cannot be undone!</p>
          <div className='modal-footer'>
            <a className='cancel-modal button secondary'>Cancel</a>
            <a className='button alert' onClick={this.handleDelete}>Delete</a>
          </div>
        </div>
      </div>
    </div>
    );
  }
})

export default DeleteModal;