import React from 'react';
var createReactClass = require('create-react-class');

const Button = createReactClass({
  
  render: function() {
    // style thing not working
    let style=this.props.handleButtonBorder(this.props.label);
    return(
      <div className="calculator-button" style={style} onClick={() => this.props.getPrice(this.props.stateKey, this.props.label)}>
        {this.props.label}
      </div>
    )
  }
})

export default Button;