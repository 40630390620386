import React, { useState, useEffect } from 'react';

const AnnotationComment = ({foundAnnotation, rejectedStyle, annotationId, submitterResponse, submitter, adminOrEditor, enablePublishButton, setSelectedComment, printMode, formatTextForHtml}) => {

  const [responding, setResponding] = useState(false);
  const [response, setResponse] = useState(submitterResponse ? submitterResponse.text : "")
  const [persistedResponse, setPersistedResponse] = useState(submitterResponse)

  useEffect(() => {
  }, [])

  const handleResponse = () => {
    let url = "/publish/submitter_responses"
    let type = 'POST'
    if(persistedResponse) {
      url = `/publish/submitter_responses/${persistedResponse.id}`
      type = 'PUT'
    }
    $.ajax({
      type: type,
      data: {annotation_id: annotationId, text: response},
      url: url,
      success: function(data) {
        setPersistedResponse(data.submitter_response)
        setResponding(false)
        enablePublishButton(data.has_all_submitter_responses)
      }
    });
  }

  const scrollAnnotationIntoView = () => {
    let ele = document.querySelector(`[data-annotation-id='${annotationId}']`)
    if(ele) {
      setSelectedComment(annotationId)
      let y = ele.getBoundingClientRect().top + window.pageYOffset - 10;
      ele.scrollIntoView({behavior: 'smooth', top: y})
    }
  }

  const renderButtons = () => {
    if (!adminOrEditor && !submitter || printMode) {
      return;
    }
    if(responding) {
      return (
        <div>
          <textarea className="submitter-response" onChange={(e) => setResponse(e.target.value.trim())} defaultValue={response}/>
          <a className={"button home-orange tiny " + (response ? "" : "disabled")} onClick={handleResponse}>Respond</a>
          <a className="button alert tiny" onClick={() => setResponding(false)}>Cancel</a>
        </div>
      )
    } else if(persistedResponse) {
      return (
        <div>
          {renderResponse()}
          {submitter && <a className="button secondary tiny" onClick={() => setResponding(true)}>Edit</a>}
        </div>
      )
    } else {
      return (
        <div>
          {submitter && <a className="button home-orange tiny" onClick={() => setResponding(true)}>
            Respond
          </a>}
        </div>
      )
    }
  }

  const renderResponse = () => {
    if(persistedResponse && (adminOrEditor || submitter)) {
      return (
        <div>
          <div className="submitter-response">
            <span className="response-bold">
              Author response:
            </span>
            <span dangerouslySetInnerHTML={{
              __html: formatTextForHtml(persistedResponse.text)
            }}/>
          </div>
        </div>
      )
    }
  }

  const renderAnnotation = () => {
    if(printMode) {
      return (
        <span className="annotation" id={`annotation-comment-${annotationId}`} style={rejectedStyle} onClick={scrollAnnotationIntoView}>{foundAnnotation.text}</span>
      )
    } else {
      return (
        <a className="annotation" id={`annotation-comment-${annotationId}`} style={rejectedStyle} onClick={scrollAnnotationIntoView}>{foundAnnotation.text}</a>
      )
    }
  }


  return (
    <div className="article-comment">
      <li key={annotationId}><span style={{'fontWeight': 'bold'}}>Reviewer {foundAnnotation.highlight}: </span>{renderAnnotation()}</li>
      {printMode && renderResponse()}
      {renderButtons()}
      <hr/>
    </div>
  )
}

export default AnnotationComment;