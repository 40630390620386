var createReactClass = require('create-react-class');
import React from 'react';

const BulkDeleteModal = createReactClass({
  getInitialState: function() {
    return {
      inputText: ""
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  handleChange: function(e) {
    this.setState({inputText: e.target.value});
  },

  render: function() {
    if(this.state.inputText == "DELETE") {
      var submitButton = <input type='submit' value='Delete' className='button primary'/>
    } else {
      var submitButton = <input type='submit' value='Delete' className='button primary disabled' disabled='disabled'/>
    }

    return (
      <div className="cureus-custom-multi-select-modal reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <div className='modal-form'>
              <h5>
                Delete {this.props.emailListIds.length} email lists
              </h5>

              <p>
                This is an irreversible action! Are you sure you want to do this? Please type 'DELETE' below to continue.
              </p>

              <form ref='form' acceptCharset='UTF-8' className='custom' method='post' action={this.props.bulkDeletePath}>
                <input name="utf8" type="hidden" value="✓" />
                <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
                <input type='hidden' name='_method' value='delete' />

                <input type='text' name='confirm_delete' onChange={this.handleChange} />
                <input type='hidden' name='email_list_ids' value={this.props.emailListIds} />

                <div className='modal-footer'>
                  <a className='cancel-modal button secondary'>Cancel</a>
                  {submitButton}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default BulkDeleteModal;