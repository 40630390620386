import {Cureus} from '../Cureus';
import '../../../../vendor/assets/javascripts/jquery.ui.labeledslider';

var COLORS =  {
                1:  "#2f249a ",
                2:  "#363dae ",
                3:  "#3e58c4 ",
                4:  "#4776dc ",
                5:  "#4f91f2 ",
                6:  "#53a4e8 ",
                7:  "#53acbf ",
                8:  "#53b492 ",
                9:  "#53bc66 ",
                10: "#53c33e "
              }

Cureus.Slider = (function($, _) {
  var init, Slider;

  init = function (callback, options) {
    var $sliders = $('.cuslider');
    $sliders.each(function(i) {
      var self = this;
      new Slider($(this), callback, options);

      var $slider = $(self).slider();
      $(self).slider({animate: 'fast', step: 1, max: 1000});

      // if ($slider[0].classList.contains('bad-refs')) {
      //   $slider.prev()[0].classList.add('red')
      // }
      
      var $ticks = $slider.siblings(".ui-slider-labels").find(".ui-slider-label-ticks");
      var min = parseInt(self.dataset.min);
      // makes slider align to correct position when clicked on sliderNumber
      var maxInterval = 1000 / (parseInt(self.dataset.max) - min)

      // set slider to initial sliderNumber, if there is an initial sliderNumber value
      if (self.dataset.initialValue) {
        var initialSliderNumber = parseInt(self.dataset.initialValue)
        $(self).slider("value", initialSliderNumber * maxInterval)
        handleSliderBoldAndBorder($slider, initialSliderNumber, $ticks);
      }

      $slider.siblings().find(".ui-slider-label-ticks span")
        .click(function(e) {
        var sliderNumber = parseInt(e.target.parentElement.dataset.index);
        var hiddenField    = $('#' + $slider.attr('data-input_id'));

        $(self).slider("value", sliderNumber * maxInterval)
        handleSliderBoldAndBorder($slider, sliderNumber, $ticks);
        //  callback for react component, or updates hidden field on slider value selection for a form submission
        callback ? callback({target:{name: hiddenField[0].id, value: sliderNumber}}) : updateHiddenField(hiddenField, sliderNumber + 1)
      });
    });
  };

  function handleSliderBoldAndBorder($slider, sliderNumber, $ticks) {
    $slider.children().css("border", "1px solid " + COLORS[sliderNumber + 1]);
    $ticks.removeClass("active");
    $($ticks[sliderNumber]).addClass("active");
  }

  function updateHiddenField(hiddenField, sliderNumber) {
    hiddenField.val(sliderNumber);
    hiddenField.trigger("change");
  }

  Slider = function($elm, callback, options) {
    this.$elm = $elm;
    this.initializeSlider($elm, callback, options);
    this.$ticks = this.$elm.siblings(".ui-slider-labels").find(".ui-slider-label-ticks");
    this._bindEvents();
  };

  _.extend(Slider.prototype, {
    initializeSlider: function($elm, callback, options) {
      var self = this;

      var min             = parseInt($elm.attr('data-min'));
      var max             = parseInt($elm.attr('data-max'));
      var hiddenField    = $('#' + $elm.attr('data-input_id'));
      var currentValue      = hiddenField.attr('value');
      var changeCallback = callback ?
                            function(event, ui) { callback({target:{name: hiddenField[0].id, value: ui.value}})} : function(event, ui) { updateHiddenField(hiddenField, ui.value) };
      var sliderOptions = {
        min: min,
        max: max,
        value: currentValue,
        animate: 'fast',
        slide: function(event, ui) {
          handleSliderBoldAndBorder($elm, ui.value - min, self.$ticks)
        },
        change: changeCallback
      }
      
      if(options && options.tickLabels){
        sliderOptions["tickLabels"] = options.tickLabels[$elm.attr('data-input_id')];
      }
      
      $elm.labeledslider(sliderOptions)
    },

    _bindEvents: function() {
      $(this.$ticks[0]).addClass("active");
    }

  });

  return { init: init };
})(jQuery, _);