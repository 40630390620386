import React from 'react';
import Modal from '../Modal';

const ApprovalEditorResubmittedModal = ({notifications}) => {

  const closeModal = () => {
    Modal.close();
  }

  return (
    <div data-reveal className='reveal-modal' id='ae-resubmitted-modal'>
      <div className='modal-body'>
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
      </div>
      <div className='modal-content'>
        <h4 className='title'>{notifications[0].title}</h4>
        <h4 className='message'>{notifications[0].message}</h4>
        <div className='notifications-container'>
          {notifications.map((notification, index) => {
            return (
              <div key={index} className='notification'>
                <div className='article-title'>{notification.target_title}</div>
                <div className='article-link btn'>
                  <a className='button special small' href={`/admin/articles/${notification.target_id}`} onClick={closeModal}>{notification.btn_text}</a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ApprovalEditorResubmittedModal;