import React, {useState, useEffect} from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';

const columnsConfig = [
  {
    title: 'Editor',
    data: 'full_name',
    render: (row) => {
      return `<a class='email' target='_blank' href='/admin/users/${row.user_id}'>${row.full_name}</a>`;
    }
  },
  { title: 'Approved Total', data: 'completed' },
  { title: 'Top 20%', data: 'rating_1' },
  { title: 'Next 20%', data: 'rating_2' },
  { title: 'Middle 20%', data: 'rating_3' },
  { title: 'Lower 20%', data: 'rating_4' },
  { title: 'Bottom 20%', data: 'rating_5' }
];

const ScoresDistribution = () => {
  return (
    <DynamicJqueryTable
      columnsConfig={columnsConfig}
      path="/admin/approval_editor_completions/scores_distribution"
      tableId="scores-distribution-table"
      tableTitle="Scores Distribution"
      tableCss="leader-board"
      initialDateIndex={2}
    />
  )
};

export default ScoresDistribution;