import React from 'react';

const RereviewProcess = () => {
  return (
    <React.Fragment>
      <h1>Rereview Process</h1>
      <p>After the author has incorporated your comments, you may receive an additional email requesting further review of a subsequent version of the article. (Accepting a rereview invitation is optional. Please refer to the <a href='#!/how-it-works/step-by-step-overview'>step-by-step overview</a> when revising additional manuscript versions.) Please feel free to provide feedback to our editorial staff during the review process.</p>
    </React.Fragment>
  )
}

export default RereviewProcess;