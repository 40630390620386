import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import InternalContentCard from '../../Shared/ContentCards/InternalContentCard';
import Sortable from '../../../components/Sortable';


const InternalContentWidget = ({slug, handleDeleteWidget, handleUpdateWidget, mainContentWidgets, showActionButtons=true, widget_id}) => {

  const [contents, setContents] = useState([]);

  useEffect(() => {
    let widgetId = widget_id || mainContentWidgets["Widget::InternalContent"]
    if(widgetId) {
      let onSuccess = (res) => {
        setContents(res.contents)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [mainContentWidgets])

  const renderContent = () => {
    return contents.map(function(content, idx) {
      if(widget_id) {
        return (
          <div className="large-6 medium-12 small-12 columns no-pad end">
            <InternalContentCard
              content={content}
              showActionButtons={showActionButtons}
              handleDeleteWidget={handleDeleteWidget}
              handleUpdateWidget={handleUpdateWidget}
              mainContentWidgets={mainContentWidgets}
            />
          </div>
        )
      } else {
        return (
          <div className="card drag-handle" key={idx}>
            <div className="large-6 medium-12 small-12 columns no-pad end">
              <InternalContentCard
                content={content}
                showActionButtons={showActionButtons}
                handleDeleteWidget={handleDeleteWidget}
                handleUpdateWidget={handleUpdateWidget}
                mainContentWidgets={mainContentWidgets}
              />
            </div>
          </div>
        )
      }
    })
  }

  const handleSort = (e, sortable, movedComponent) => {
    let widgetId = movedComponent.props.children.props.content.id
    let params = {widget: {position: e.newIndex + 1}}
    ChannelApi.updateWidget(widgetId, params, slug)
  }

  return (
    <div className="row">
      <div className={"small-12 columns widget internal-content " + (widget_id ? 'public' : '')}>
      <Sortable tagName='div' handleSort={ handleSort }>
        {renderContent()}
      </Sortable>
      </div>
    </div>
  )
}

export default InternalContentWidget;