var createReactClass = require('create-react-class');
import React from 'react';
import Tile from './Tile';

const Main = createReactClass({
  advisorInfo: function() {
    return([
      {
        name:'Ted Driscoll, Ph.D.',
        truncBio: "Dr. Driscoll is a Technology Partner at Claremont Creek Ventures. He is also an active angel investor in the Life Science Angels and a founding director of the Sand Hill Angels. ...",
        bio:"Dr. Driscoll is a Technology Partner at Claremont Creek Ventures. He is also an active angel investor in the Life Science Angels and a founding director of the Sand Hill Angels. Prior to his venture capital experience, he helped found five successful companies in imaging-related markets. Dr. Driscoll holds a Ph.D. from Stanford University in Digital Imaging, Master’s degrees from Harvard University in Computer Graphics and Remote Sensing and a Bachelor's degree from the University of Pennsylvania. Dr. Driscoll holds 40+ granted U.S. and foreign patents in image-related fields."
      },
      {
        name: "Tina Seelig, Ph.D.",
        truncBio: "Dr. Seelig earned her Ph.D. in neuroscience from Stanford University Medical School. She is the Executive Director of the Stanford Technology Ventures Program, ...",
        bio: "Dr. Seelig earned her Ph.D. in neuroscience from Stanford University Medical School. She is the Executive Director of the Stanford Technology Ventures Program, the Director of the National Center for Engineering Pathways to Innovation, and is the author of the international bestseller <a target='_blank' href='http://www.amazon.com/dp/B0028MVGZQ' rel='nofollow'>What I Wish I Knew When I Was 20</a>. In 2009, Dr. Seelig was awarded the prestigious Gordon Prize from the National Academy of Engineering for her pioneering work in engineering education."
      },
      {
        name: "Trip Adler",
        truncBio: 'John R. "Trip" Adler III is the founder and CEO of the social publishing company, Scribd. Scribd is the largest social publishing site in the world and one of the ...',
        bio: 'John R. "Trip" Adler III is the founder and CEO of the social publishing company, Scribd. Scribd is the largest social publishing site in the world and one of the most well-trafficked sites on the Internet. Trip grew up in Palo Alto, California and attended Harvard University, where he studied biophysics and graduated in June 2006.'
      }
    ])
  },

  renderTiles: function() {
    return this.advisorInfo().map(function(tile, idx) {
      return <Tile tile={tile}  key={idx}/>
    });
  },

  render: function() {
    return(
      <div className='row'>
        <div className='large-12 columns'>
          <div className='advisor-flex-wrap'>
            {this.renderTiles()}
          </div>
        </div>
      </div>
    )
  }
})

export default Main;