import React, { useEffect, useRef, useState } from "react";
import DropDown from './DropDown';
import DropDownInput from './DropDownInput';
import InstitutionInput from './InstitutionInput';
import useClickOutside from '../useClickOutside';

const SearchFields = ({
    submitSearch,
    showSpecialtyDropDown,
    setShowSpecialtyDropDown,
    showCountriesDropDown,
    setShowCountriesDropDown,
    specialties,
    setSpecialties,
    setName,
    institution,
    setInstitution,
    countries,
    setCountries,
  }) => {

  const specialtyDropDownRef = useRef(null);
  const countryDropDownRef = useRef(null);

  useClickOutside(specialtyDropDownRef, () => {
    setShowSpecialtyDropDown(false);
  });

  useClickOutside(countryDropDownRef, () => {
    setShowCountriesDropDown(false);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    submitSearch(e)
  }


  return (
    <div className="row">
      <div className='large-11 medium-12 small-12 columns small-centered end'>
        <form className="search-form" onSubmit={handleSubmit}>
          <div className="row collapse">
            <div className="medium-6 small-12 columns">
              <input type="text" onChange={(e) => {setName(e.target.value)}} placeholder="Name"/>
            </div>
              <div className="medium-6 small-12 columns">
                <InstitutionInput setInstitution={setInstitution} institution={institution}/>
              </div>
          </div>

          <div className="row collapse">
            <div className="medium-5 small-12 columns">
              <DropDownInput
                setShowFunction={setShowSpecialtyDropDown}
                show={showSpecialtyDropDown}
                list={specialties}
                text={"Specialty"}
              />
            </div>
            <div className="medium-5 small-12 columns">
              <DropDownInput
                setShowFunction={setShowCountriesDropDown}
                show={showCountriesDropDown}
                list={countries}
                text={"Country"}
              />
            </div>
            <div className="medium-2 small-12 columns">
              <button className="button submit-button primary" type="submit" onSubmit={handleSubmit}>Search</button>
            </div>
            <div className="small-12 columns">
              {showSpecialtyDropDown && <DropDown
                list={specialties}
                updateList={setSpecialties}
                dropdownRef={specialtyDropDownRef}
                title={"Specialty"}
              />}
            </div>
            <div className="small-12 columns">
              {showCountriesDropDown && <DropDown
                list={countries}
                updateList={setCountries}
                dropdownRef={countryDropDownRef}
                title={"Countries"}
              />}
            </div>
          </div>

          <div className="row">
            <div className="medium-12 small-12 columns">
              <p className='note'> Note: Only users with confirmed accounts are displayed below.</p>
            </div>
          </div>

        </form>
      </div>
    </div>
  )
}

export default SearchFields;