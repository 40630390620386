var createReactClass = require('create-react-class');
import React from 'react';

const HistoryModal = createReactClass({

  getInitialState: function() {
    return {
      events: []
    }
  },

  componentDidMount: function() {
    var self = this;
    $.ajax({
      type: "get",
      url: `/admin/articles/${self.props.articleId}/reviewerships/${self.props.reviewershipId}/get_events`,
      dataType: "json",
      data: {table: true},
      success: function(data) {
        self.setState({events: data.events})
      }
    })
  },

  renderTableRows: function() {
      var self = this;
      return self.state.events.map(function(event) {
        return (
          <tr key={event.id}>
            <td dangerouslySetInnerHTML={{__html: event.history_name}}/>
            <td>{event.created_at}</td>
          </tr>
        )
      })
  },
  
  render: function() {
    return (
      <div id="raw-history-log-modal" className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h6>Reviewer History</h6>
              <table className="full-width table-wrapper">
                <tbody>
                 {this.renderTableRows()}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    )
  }
})

export default HistoryModal;