import React, { useState, useEffect } from 'react';
import HeroEditModal from './Modals/HeroEditModal';
import Modal from '../../components/Modal';

const Hero = ({heroData}) => {
  const [themeColor, setThemeColor] = useState(heroData.heroColor || '#006671');
  const [fontColor, setFontColor] = useState(heroData.fontColor || '#FFFFFF');
  const [submitBtnColor, setSubmitBtnColor] = useState(heroData.submitButtonColor || '#006c84');
  const [heroImageFile, setHeroImageFile] = useState('');
  const [logoFile, setLogoFile] = useState('');
  const [heroUrl, setHeroUrl] = useState(heroData.heroImage.channel_hero_webp.url || '');
  const [logoUrl, setLogoUrl] = useState(heroData.logo.url || '');
  const [channelName, setChannelName] = useState(heroData.channelName);
  const [channelId, setChannelId] = useState(heroData.channelId);
  const [disable, setDisable] = useState('disabled')
  const [disableBtnForUpdate, setDisableBtnForUpdate] = useState(true);

  const createFormData = (newThemeColor, newFontColor, newSubmitBtnColor, newHeroImageFile, newLogoFile) => {
  var formData = new FormData();
  if(newLogoFile) {
    formData.append("channel[logo]", newLogoFile);
  }
  if(newHeroImageFile) {
    formData.append("channel[hero]", newHeroImageFile);
  }
  formData.append("channel[hero_color]", newThemeColor);
  formData.append("channel[font_color]", newFontColor);
  formData.append("channel[submit_button_color]", newSubmitBtnColor);

  return formData;
  }

  const handleSave = (newThemeColor, newFontColor, newSubmitBtnColor, newHeroImageFile, newLogoFile) => {
    let formFile = createFormData(newThemeColor, newFontColor, newSubmitBtnColor, newHeroImageFile, newLogoFile);
    fetch(`/admin/channels/${channelId}`, {
      method: 'PUT',
      body: formFile,
      credentials: "same-origin",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
        'Accept': 'application/json',
      },
    }).then(function(response) {return response.json()})
    .then(function(res) {
      Modal.close();
      setThemeColor(res.hero_color);
      setFontColor(res.font_color);
      setLogoUrl(res.logo);
      setHeroUrl(res.hero_webp);
      setSubmitBtnColor(res.submit_button_color);
      // Changing color of an element in another component
      let submitButton = document.querySelector("#channel-submitButton");
      submitButton.style.backgroundColor = res.submit_button_color;
    })
  }

  const openModal = () => {
    Modal.open({
      children: <HeroEditModal
        themeColor={themeColor}
        fontColor={fontColor}
        submitBtnColor={submitBtnColor}
        heroImageFile={heroImageFile}
        logoFile={logoFile}
        heroUrl={heroUrl}
        logoUrl={logoUrl}
        handleSave={handleSave}
      />
    })
  }

  const renderHeroImage = () => {
    return heroUrl ? `${heroUrl}` : "https://public.cureus.com/channel_hero_default.png";
  }

  const renderLogo = () => {
    if(logoUrl) {
      return <img className='' src={logoUrl} alt="logo-image" />
    } else {
      return <div style={{ background: '#FFFFFF', width: '100%', height: '100%', borderRadius: '10px' }} />
    }
  }

  return (
    <div className='row hero-section border-top-left border-top-right'>
      <div className='small-12 columns hero-container border-top-right border-top-left' style={{ backgroundColor: themeColor }}>
        <div className='small-6 columns border-top-left hero-left-container'>

          <div className='row'>
            <div className='small-6 columns'>
              <div className='logo-container'>
                {renderLogo()}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='small-12 columns'>
              <div className='channel-name-container'>
                <h1 style={{ color: fontColor }}>
                  {channelName}
                </h1>
              </div>
            </div>
          </div>

        </div>
        <div className='large-6 medium-12 small-12 columns hero-right-container border-top-right'>
          <div className='row hero-row small-collapse'>
            <img className='hero-img border-top-right' src={renderHeroImage()} alt="hero-image" />
            <a className='primary button edit-button' href='#' onClick={openModal}>
              <i className="fas fa-pencil-alt"></i>
              EDIT
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero;
