var createReactClass = require('create-react-class');
import React from 'react';

const StepThree = createReactClass({
  handleDisable: function() {
    if(this.props.disclosure_declared) {
      if(this.props.submitting) {
        return 'disabled'
      } else {
        return ''
      }
    } else {
      return 'disabled'
    }
  },

  renderTextarea: function() {
    if(this.props.disclosure_declared !== 'false') {
      return (
        <textarea name="disclosure" disabled={this.props.disclosure_declared === 'true' ? false : true} value={this.props.disclosure} onChange={this.props.handleChange}/>
      )
    }
  },

  renderError: function() {
    if(this.props.error) {
      return (
        <div className="row">
          <div className="small-4 small-offset-8 columns" style={{color: 'red'}}>There appears to be an issue with your internet connection. Please try again.</div>
        </div>
      )
    }
  },

  render: function() {
    return (
      <div className='modal-content'>
        <p><strong>Step 3: Please disclose any conflicts of interest</strong></p>
        <p>
          Cureus adheres to
          <a href="http://www.icmje.org/recommendations/browse/roles-and-responsibilities/author-responsibilities--conflicts-of-interest.html"  rel="nofollow" target="_blank"> ICMJE guidelines </a>
          concerning conflicts of interest. Conflicts of interest may involve 3rd party payment or service for any aspect of the submitted work, relevant financial activities outside the submitted work, patents that could be considered broadly relevant to the work and any relationships not covered previously.
        </p>
        <p>
          Reviewers must disclose to editors any conflicts of interest that could bias their opinions of the manuscripts, and must not use knowledge of the reviewed work to further their own interests before publication. In extreme cases, reviewers may wish to contact the
          <a href="mailto:alexander.muacevic@cureus.com" > Cureus Editor-in-Chief </a>
          in order to provide a more detailed explanation of the potential conflict and determine the best course of action.
        </p>
        <p>Based on the above guidelines, do you have any conflicts of interest to disclose?</p>
        <div>
          <label className="position-radio inline">
            <input type="radio" name="disclosure_declared" value="true" checked={this.props.disclosure_declared === "true"} onChange={(e) => this.props.handleChange(e)}/>
            Yes
          </label>
          <label className="position-radio inline">
            <input type="radio" name="disclosure_declared" value="false"  checked={this.props.disclosure_declared === "false"}  onChange={(e) => this.props.handleChange(e)}/>
            No
          </label>
        </div>
        {this.renderTextarea()}
        {this.renderError()}
        <div className='modal-footer'>
          <a href='#' className='button secondary left' onClick={this.props.handleBack}>Back</a>
          <a href='#' className='button secondary cancel-modal'>Cancel</a>
          <a href='#' className={'button primary ' + this.handleDisable()} onClick={this.props.handleSubmit}>Submit My Review</a>
        </div>
      </div>
    )
  }
})

export default StepThree;