import React, { useState, useEffect } from 'react';
import MainMetrics from './MainMetrics';
import InProgressOverview from './InProgressOverview';

const InProgress = ({channels, dateRanges}) => {

  return (
    <div className="row">
      <div className="large-12 columns">
        <InProgressOverview channels={channels} />
        <MainMetrics dateRanges={dateRanges} />
      </div>
    </div>
  )
}

export default InProgress;