import React, { useState, useEffect } from 'react';
import BigArticleCard from '../../Shared/ContentCards/BigArticleCard';

const AbstractCard = ({resource, isLast=false}) => {

  const endClass = () => {
    return isLast ? "end" : ""
  }

  return (
    <div className={`large-4 columns abstract-card ${endClass()}`}>
      <BigArticleCard
        resource={resource}
        showCategories={true}
        showThumbnail={false}
      />
    </div>
  )
}

export default AbstractCard;