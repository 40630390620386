
var createReactClass = require('create-react-class');
import React from 'react';

const Search = createReactClass({
  render: function() {
    return (
      <div className="search-container">
        <div className="row">
          <div className="large-8 large-offset-2 medium-8 medium-offset-2 small-12 columns">
            <div className="search-box">
              <div className="small-9 medium-10 large-9 large-offset-1 columns end">
                <input autoComplete="off" id="q" name="q" placeholder="Search People" type="text" onChange={this.props.updateSearch} onKeyPress={this.props.handleSearchEnter}/>
                <i className="fa fa-search"/>
              </div>
              <div className="small-3 medium-2 columns end">
                <a className="button primary" onClick={this.props.handleSearchClick}>Search</a>
            </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Search;