var createReactClass = require('create-react-class');
import React from 'react';

const CoauthorNotification = createReactClass({
  renderUnconfirmedCoauthors: function() {
    if (this.props.emphasisBox) {
      return <div dangerouslySetInnerHTML={{__html: this.props.emphasisBox}}/>
    } else {
      return <div><br></br></div>
    }
  },

  render: function() {
    return (
      <div>
        {this.renderUnconfirmedCoauthors()}
      </div>
    )
  }
})

export default CoauthorNotification;