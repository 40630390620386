import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../../Cureus/ChannelApi';
import UpdateButton from '../../UpdateButton';
import DeleteButton from '../../DeleteButton';

const AboutContact = ({slug, handleDeleteWidget, handleUpdateWidget, sidebarWidgets, widgetId, showActionButtons=true}) => {
  const [widget, setWidget] = useState({});

  useEffect(() => {
    if(widgetId) {
      let onSuccess = (res) => {
        setWidget(res);
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [sidebarWidgets])

  const renderActionButtons = () => {
    if (showActionButtons) {
      return (
      <>
        <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
        <UpdateButton
          handleUpdateWidget={handleUpdateWidget}
          widget={widget}
          modalName={"AboutContactModal"}
          slug={slug}
        />
        <DeleteButton
          handleDeleteWidget={handleDeleteWidget}
          widgetId={widgetId}
        />
      </>
      )
    }
  }

  return (
    <div className="row sidebar-row">
      {renderActionButtons()}
      <div className="small-12 columns widget about-contact">
        <h3>Contact</h3>
        <div dangerouslySetInnerHTML={{__html: widget.blurb}}/>
      </div>
    </div>
  )
}

export default AboutContact;