var createReactClass = require('create-react-class');
import React from 'react';
import InsideListItem from './InsideListItem';
import { Cureus } from '../../Cureus';

const Inside = createReactClass({
  componentDidMount: function() {
    new Cureus.CKEditor('campaign_inside_intro_message', {editor_type: 'marketing_mailer', ckeditor: {height: 200}});
  },

  renderInsides: function() {
    var self = this;
    return self.props.insides.map(function(inside) {
      return (
        <InsideListItem
          key={inside.id}
          inside={inside}
          destroyInside={self.props.destroyInside}
          handleInsidesChange={self.props.handleInsidesChange}
          title={inside.title}
        />
      )
    })
  },

  renderHeroImage: function() {
    if(this.props.heroImage) {
      return (
      <div className="row">
        <label>Current image:</label>
        <div dangerouslySetInnerHTML= {{ __html: this.props.heroImage }} />
        <br/>
      </div>
      )
    }
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <label>
            Hero Image
            <input type="file" onChange={(e) => this.props.handleHeroUpload(e)}/>
          </label>
        </div>
        {this.renderHeroImage()}
        <label>Intro Message</label>
        <textarea 
          id='campaign_inside_intro_message'
          value={this.props.introMessage || ""}
          name="introMessage"
        />
        <div>
          <a className="button primary" onClick={this.props.addInside}>Add Cureus U</a>
        </div>
        {this.renderInsides()}
      </div>
    )
  }
});

export default Inside;