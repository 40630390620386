var createReactClass = require('create-react-class');
import React from 'react';

const EditModal = createReactClass({
  getInitialState: function() {
    const { label, description, tooltip } = this.props.reason;
    return {
      reason: this.props.reason,
      label,
      description,
      tooltip,
    }
  },

  editReason: function(e) {
    $.ajax({
      type: 'put',
      url: this.props.editPath,
      data: {deferral_reason: {label: this.state.label, description: this.state.description}, type: this.props.type},
      dataType: 'json',
      success: function(res){
        window.location.reload()
      },
      error: function(res) {
        Cureus.Flash.alert('There was an issue, please try later.')
      }
    })
  },

  handleInputChange: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  titleButtonText: function(e) {
    let rejectionTables = ["EditorCheckDeferralReason", "CollectionRejectionReason", "EditorApprovalRejectionReason"];
    return rejectionTables.includes(this.props.type) ? "Edit Rejection Message" : "Edit Deferral Reason";
  },
  
  render: function() {
    const { description, tooltip, label } = this.state;
    let editorApprovalTable = this.props.type === "EditorApprovalRejectionReason";
    return (
      <div className="reveal-modal middling" data-reveal id="edit-deferral-reason">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className="modal-content middling">
            <h4>Edit Deferral Reason</h4>
            <label htmlFor='label' id="label">Label</label>
            <input name="label" value={label} type="text" onChange={this.handleInputChange}  required='required'/>

            {editorApprovalTable &&
              <>
                <label htmlFor='tooltip'>Tooltip</label>
                <textarea name="tooltip" value={tooltip} style={{height: '100px'}} onChange={this.handleInputChange} id='tooltip'/>
              </>
            }

            <label htmlFor='description'>Description</label>
            <textarea name="description" value={description} style={{height: '300px'}} onChange={this.handleInputChange} id='description'/>
          </div>
          <div className='modal-footer'>
            <a className='cancel-modal button small secondary'>Cancel</a>
            <a className="button small primary success" onClick={this.editReason}>{this.titleButtonText()}</a>
          </div>
        </div>
      </div>
    )
  }
});

export default EditModal;