var createReactClass = require('create-react-class');
import React from 'react';
import ArticleCard from '../Shared/ContentCards/ArticleCard';

const Main = createReactClass({
  getInitialState: function (){
    return {
      content: [],
      selectTypeValue: this.props.no_articles ? null : this.props.select_type_options.find((option) => !option.disabled).value,
      selectSortValue: this.selectSortOptions()[0].value,
      toggledOn: false
    }
  },

  componentDidMount: function(){
    this.fetchResources();
  },

  fetchResources: function(){
    var self = this;
    $.get(self.state.selectTypeValue, {}, function(data){
      self.setState({content: data.data.map(function(obj) {return obj.attributes}), selectSortValue: 'published_at_desc'});
    }, 'json')
  },

  sortContent: function(){
    var sort_comps = this.state.selectSortValue.split('_')
    var sort_direction = sort_comps.pop()
    var sort_by = sort_comps.join('_')
    var sortedContent = this.state.content.sort(function(a,b){
      if (a[sort_by] < b[sort_by]) {
        return 1;
      } else if (a[sort_by] > b[sort_by]) {
        return -1;
      } else {
        return 0;
      }
    });
    if (sort_direction === 'asc') { sortedContent = sortedContent.reverse() }
    this.setState({content: sortedContent})
  },

  handleFilterChange: function(e){
    var self = this;
    if (e !== self.state.selectTypeValue) {
      self.setState({ selectTypeValue: e}, self.fetchResources)
    }
  },

  handleSortChange: function(e){
    var self = this;
    if (e !== self.state.selectSortValue) {
      self.setState({ selectSortValue: e}, self.sortContent)
    }
  },

  selectSortOptions: function(){
    return(
      [
        {
          label: "Newest",
          value: "published_at_desc"
        },
        {
          label: "Oldest",
          value: "published_at_asc"
        },
        {
          label: "Highest SIQ",
          value: "siq_desc",
          disabled: this.hasSiq()
        },
        {
          label: "Lowest SIQ",
          value: "siq_asc",
          disabled: this.hasSiq()
        },
      ]
    )
  },

  hasSiq: function(){
    var abstracts = this.props.select_type_options[6].value;
    var posters = this.props.select_type_options[7].value;
    return (this.state === null ? true : [posters, abstracts].includes(this.state.selectTypeValue))
  },

  renderShowButton: function(){
    var seeText = this.state.toggledOn ? 'Show less ' : 'Show all ';
    var seeChevron = this.state.toggledOn ? <i className="fa fa-angle-up"/> : <i className="fa fa-angle-down"/>
    return (
      this.state.content.length === 0 ? "No published content" :
      <a onClick={ this.toggleContent } className="content-toggler">{ seeText } {seeChevron}</a>
    )
  },

  toggleContent: function(){
    this.setState({toggledOn: !this.state.toggledOn});
  },

  renderContent: function() {
    var self = this;
    var content = self.state.toggledOn ? self.state.content : self.state.content.slice(0, 2);
    return(
      content.map(function(resource){
        return (
          <ArticleCard  key={resource.id + '_' + resource.type} resource={resource} articlesPage={false} page="UserProfile"/>
         )
      })
    )
  },

  render: function (){
    return (
      <div>
        <div className="row">
          <div className="large-6 columns">
            <Select
              value={this.state.selectTypeValue}
              options={this.props.select_type_options}
              onChange={this.handleFilterChange}
              clearable={ false }
              searchable={ false } />
          </div>
          <div className="large-6 columns">
            <Select
              value={this.state.selectSortValue}
              options={this.selectSortOptions()}
              onChange={this.handleSortChange}
              clearable={ false }
              searchable={ false } />
          </div>
        </div>
        <br/>
        <div id="content-results-wrap">

          { this.renderContent() }

        </div>
        <div className="centered">
          { this.renderShowButton() }
        </div>
      </div>
    )
  }
});

export default Main;