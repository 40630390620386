import React, { memo } from "react";
import SiqBadgeImage from '../SiqBadgeImage';

const ArticleContentCard = ({
  content,
  handleSubmit,
}) => {
  const {
    humanized_type,
    published_date,
    title,
    author_list,
    path,
    categories,
    retraction_present,
    siq,
    competition_winner,
    trophy_image,
    id,
    has_thumbnail,
    thumbnail
  } = content;
  const renderSiq = () => {
    if (retraction_present || ['Poster', 'Abstract'].includes(humanized_type)) {
      return
    }
    return(
      <a target="_blank" href={`${path}?score_article=true`} className="siq-link">
        <div className={"siq-badge siq-badge-wrap-medium " + `${renderWidthClass()}`}>
          <div className={renderBadgeClass()}>
            <SiqBadgeImage
              siq={siq}
            />
            <div className="siq-badge-score react-content-card">
              {siq}
            </div>
          </div>
          {renderWinner()}
        </div>
      </a>
    )
  }

  const renderWidthClass = () => {
    if (siq <= 5 || siq == "-" || siq == null) {
      return 'badge';
    } else {
      return 'ribbon-badge';
    }
  }

  const renderBadgeClass = () => {
    if (siq <= 5 || siq == "-" || siq == null) {
      return 'browse-articles-badge ';
    }
  }

  const renderWinner = () => {
    if (competition_winner) {
      return(
        <div className='content-card-trophy'>
          <div dangerouslySetInnerHTML={ {__html: trophy_image} } key={ id + "winner"} />
        </div>
      )
    }
  }

  const renderCategoryCards = () => {
    return categories.map((category) => {
      const { id, name } = category;
      return (
        <button
          className="article-category"
          key={`category-${id}`}
          onClick={() => handleSubmit(name, id)}
        >
          {name}
        </button>
      );
    });
  };

  const renderThumbnail = () => {
    if (has_thumbnail) {
      return (
        <a href={path} target="_blank" rel="noreferrer" className="thumbnail-link">
          <div className="thumbnail-mask"/>
          <div className="thumbnail-container" key={id + "thumb"} dangerouslySetInnerHTML={{__html: thumbnail}}/>
        </a>
      )
    }
  }

  return (
    <div className="article-card-container">
      <div className="card-header">
        {renderSiq()}

        <div className="date-article-type">
          <div className='date-published'>{published_date}</div>
          <div className='article-type'>{humanized_type}</div>
        </div>
      </div>

      <div className='article-card-content'>
        {renderSiq()}
        <div className='article-content'>
          <a
            className="article-title"
            href={path}
            target="_blank">
            {title}
          </a>

          <div className="article-authors">{author_list}</div>

          <div className="article-categories-container">
            {renderCategoryCards()}
          </div>
        </div>
        {renderThumbnail()}
      </div>
    </div>
  );
};

export default memo(ArticleContentCard);