import React, { useState, useEffect } from 'react';
import EmailListTable from './EmailListTable';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";

const ViewChannelList = ({listId, channelId, listTitle, createEmailButtonTitle, emailListPollingPath, emailListPath, breadcrumbPath, breadcrumbName, updateListPath, lastUpdated, addEmailPath, fetchListEmailsPath, removeListEmailsPath}) => {
  
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(lastUpdated);
  const [listEmailsCount, setListEmailsCount] = useState(null);

  return (
    <Router hashType={"hashbang"}>
      <div className="small-12 columns channel-email-list-container with-breadcrumbs">
        <div className="row email-list-header-with-breadcrumbs">
          <div className="channel-breadcrumbs">
            <a href={breadcrumbPath} className="underline header-light-text">{breadcrumbName}</a>
            <span className="header-light-text"> {'>'} </span>
            <span className="header-light-text">{`${listTitle}`}</span>
          </div>
          <h1>{`${listTitle}`}
            {listEmailsCount > 0 && <span className="channels-h1-blurb reviewers-count">{listEmailsCount}</span>}
          </h1>
          {lastUpdatedTimestamp && <span className="header-light-text">Updated on {lastUpdatedTimestamp} UTC</span>}
        </div>
          <Switch>
            <Route path='/' exact>
              <EmailListTable 
                listId={listId}
                channelId={channelId}
                createEmailButtonTitle={createEmailButtonTitle}
                emailListPath={emailListPath}
                emailListPollingPath={emailListPollingPath}
                updateListPath={updateListPath}
                addEmailPath={addEmailPath}
                removeListEmailsPath={removeListEmailsPath}
                setLastUpdatedTimestamp={setLastUpdatedTimestamp}
                setListEmailsCount={setListEmailsCount}
                fetchListEmailsPath={fetchListEmailsPath} 
              />
            </Route>
          </Switch>
      </div>
    </Router>
  )

}

export default ViewChannelList;