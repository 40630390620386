import React from 'react';

const GenerativeAiImages = () => {
  return (
    <React.Fragment>
      <h1>Generative AI Images</h1>
      <p>
        The fast moving area of generative AI image creation has resulted in novel legal copyright and research integrity issues. As publishers, we strictly follow existing copyright law and best practices regarding publication ethics. While legal issues relating to AI-generated images and videos remain broadly unresolved, Springer Nature journals are unable to permit its use for publication. 
      </p>
      <p>Exceptions*:</p>
      <ul className='disc indented-for-medium-up'>
        <li>Images/art obtained from agencies that we have contractual relationships with that have created images in a legally acceptable manner.</li>
        <li>Images and video that are directly referenced in a piece that is specifically about  AI and such cases will be reviewed on a case-by-case basis.</li>
        <li>The use of generative AI tools developed with specific sets of underlying scientific data that can be attributed, checked and verified for accuracy, provided that ethics, copyright and terms of use restrictions are adhered to.</li>
      </ul>
      <p>
        *All exceptions must be labeled clearly as generated by AI within the image field.
      </p>
      <p>
        As we expect things to develop rapidly in this field in the near future, we will review this policy regularly and adapt it if necessary.
      </p>
      <p>
        NOTE: Examples of image types covered by this policy include: video and animation, including video stills; photography; illustration such as scientific diagrams, photo-illustrations and other collages, and editorial illustrations such as drawings, cartoons or other 2D or 3D visual representations. Not included in this policy are text-based and numerical display items, such as: tables, flow charts and other simple graphs that do not contain images. Please note that not all AI tools are generative. The use of non-generative machine learning tools to manipulate, combine or enhance existing images or figures should be disclosed in the relevant caption upon submission to allow a case-by-case review.
      </p>
    </React.Fragment>
  )
}

export default GenerativeAiImages;