import React from 'react';
import OpenClickChart from './OpenClickChart';
import Stats from './Stats';
import StatsTable from './StatsTable';
import {Cureus} from '../../Cureus';

const Main = ({adminCampaignsPath, adminCampaignsStatPath, adminCampaignsPopularLinksPath, adminCampaignsTopCountriesPath, viewEmailPath, title, sentDate, requests, delivered, lists, subject}) => {

  

  function getStat(containerId, params, handleSuccess) {
    $(containerId).spin();
    $.ajax({
      type: "POST",
      url: adminCampaignsStatPath,
      data: params,
      success: function(data) {
        handleSuccess(data)
        $(containerId).spin(false)
      }
    })
  }

  function getCsv(stat) {
    if (window.confirm("An email with the data will be sent you.")) {
      $.ajax({
        type: "POST",
        url: adminCampaignsStatPath,
        data: {statType: "csv", stat: stat},
        success: function() {
          window.scrollTo({top: 0, behavior: 'smooth'});
          Cureus.Flash.success('You will receive an email momentarily. There will be a delay if there is an active email send.')
        }
      })
    }
  }

  return (
    <div className='campaign-show'>
      <span className='small-grey'> 
      <a href={adminCampaignsPath}>Campaigns</a>
      {" > "}
      {title}
      </span>
      <div className='row'>
        <div className='large-6 columns'>
          <h1>{title}</h1>
          <span className='small-grey'>{sentDate}</span>
        </div>
        <div className='large-4 columns' style={{marginTop: "12px"}}>
          <span className='small-grey'>{requests} Requests / {delivered} Delivered</span>
        </div>
        <div className='large-2 columns' style={{marginTop: "12px"}}>
          <a href={viewEmailPath} target="_blank" rel="noreferrer">View email</a>
        </div>
      </div>
      <hr/>
      <OpenClickChart getStat={getStat}/>
      <Stats getStat={getStat} getCsv={getCsv}/>
      <hr/>
      <div className='row'>
        <div className='large-6 columns'>
          <StatsTable
            id="most-popular-links"
            title="Most popular links"
            fullReportHref={adminCampaignsPopularLinksPath}
          />
        </div>
        <div className='large-6 columns'>
          <StatsTable
            id="top-countries"
            title="Top countries"
            fullReportHref={adminCampaignsTopCountriesPath}
          />
        </div>
      </div>
      <hr/>
      <h6>Lists</h6>
      <div>
        {lists}
      </div>
      <hr/>
      <h6>Subject</h6>
      <div>
        {subject}
      </div>
    </div>
  )
}

export default Main;