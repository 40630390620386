
var createReactClass = require('create-react-class');
import React from 'react';

const Main = createReactClass({
  getInitialState: function() {
    return({
      processing: true,
      success: false,
      message: ''
    })
  },

  componentDidMount: function() {
    this.poll();
  },

  poll: function() {
    var self = this;
    var data = {user_id: self.props.userId, article_id: self.props.articleId, session_id: self.props.sessionId}

    var pollInterval = setInterval(function() {
      $.ajax({
        type: 'get',
        url: self.props.pollUrl,
        data: data,
        success: function(res) {
          if (Boolean(res.invoice)) {
            self.setState({processing: false, success: Boolean(res.success), invoiceType: res.type})
            clearInterval(pollInterval)
          }
        }
      })
    }, 2000);
  },

  renderPaymentConfirmationButton: function() {
    if (!this.state.processing) {
      return <a href="/dashboard" className='button large primary'>Go To My Dashboard</a>
    }
  },

  processingText: function() {
    return(
      <div className='spinner-container'>
        <h3 className='reg'>Your payment is processing.</h3>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
        <br/>
        <br/>
        <br/>
        <img src='https://public.cureus.com/success-doc.png'/>
      </div>
    )
  },

  preferredEditingSuccessText: function() {
    return (
      <div className='row'>
        <div className='large-8 columns large-offset-2'>
          <h2>Thank you for purchasing Preferred Editing.</h2>
          <br/>
          <h5 className='reg'>Your article has now entered peer review. When your article satisfies peer review you may revise and submit for publication. We will only contact you if we have questions concerning the scientific content of your article or if any media or reference issues designated as exceptions to this policy are present. All other formatting and language issues will be handled by our team, ensuring that your path to publication is fast, efficient and trouble-free.</h5>
        </div>
      </div>
    )
  },

  expeditedPmcSuccessText: function() {
    return (
      <div className='row'>
        <div className='large-8 columns large-offset-2'>
          <h2>Thank you for purchasing Expedited Indexing.</h2>
          <br/>
          <h5 className='reg'>Your payment has been confirmed and we'll submit your article for PMC indexing in 1-3 business days.</h5>
          <h5 className='reg'>Your article will appear in PMC approximately 2 to 14 days after submission.</h5>
        </div>
      </div>
    )
  },

  socialBoostSuccessText: function() {
    return (
      <div className='row'>
        <div className='large-8 columns large-offset-2'>
          <h2>Thank you for purchasing the Article Promotion.</h2>
          <br/>
          <h5 className='reg'>Your payment has been confirmed and your article will appear on our social media channels in 3-5 days. You will receive the results of your Article Promotion within 45 days.</h5>
        </div>
      </div>
    )
  },

  failureText: function() {
    return (
      <div>
        <h5 className='reg'>There was an issue processing your payment. Please return to your dashboard and try again.</h5>
      </div>
    )
  },

  renderBody: function() {
    let text = this.failureText()
    if (this.state.processing) {
      text = this.processingText()
    }
    else if (this.state.success) {
      if (this.state.invoiceType === 'EditingServiceInvoice') {
        text = this.preferredEditingSuccessText()
      }
      if (this.state.invoiceType === 'ExpeditedInvoice') {
        text = this.expeditedPmcSuccessText()
      }
      if (this.state.invoiceType === 'BoostedInvoice') {
        text = this.socialBoostSuccessText()
      }
    }
    return(
      <div>
        <br/>
        <br/>
          {text}
        <br/>
        {this.renderPaymentConfirmationButton()}
      </div>
    )
  },

  render: function() {
    return(
      <div className='step-content'>
        <div className='row'>
          <div className='large-12 columns centered'>
            {this.renderBody()}
          </div>
        </div>
      </div>
    )
  }
})

export default Main;