var createReactClass = require('create-react-class');
import React from 'react';
import Post from './Post';

const Main = createReactClass({
  getInitialState: function() {
    return {
      posts: this.props.posts,
      start: 0,
      max: null
    }
  },

  componentDidMount: function() {
    this.props.posts.forEach((post) => {
      const img = new Image();
      img.src = post.thumbnailImagePath;
    });
    this.triggerFoundation()
    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)
  },

  updateViewport: function() {
    var width = window.innerWidth
    var max;
    if(width > 1024) {
      max = 3
    } else if(width < 640) {
      max = 1
    } else {
      max = 2
    }
    this.setState({max: max})
  },

  triggerFoundation: function() {
    $(document).foundation({
      equalizer : {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true,
        // Allow equalizer to resize hidden elements
        act_on_hidden_el: false,
      }
    });
  },

  renderPosts: function() {
    var self = this;
    var index = 0;
    var end = self.state.max + self.state.start;
    var numPosts = this.props.posts.length;
    return self.state.posts.slice(self.state.start % numPosts, end).map(function(post) {
      index = index + 1
      return <Post post={post} key={post.carousel} index={"number-" + index}/>
    })
  },

  handleNext: function(dir) {
    var numPosts = this.state.posts.length;
    var start = this.state.start
    if (dir === 'left' && this.state.start !== 0) {
      start = start - this.state.max
      if (start < 0) {
        start = 0;
      }
    } else if (dir === 'right'){
      start = start + this.state.max
      if (start == numPosts) {
        start = 0
      } else if (start + this.state.max > numPosts) {
        start = numPosts - this.state.max
      }
    }
    this.fireAmplitude(dir)
    this.setState({start: start}, function() {this.triggerFoundation()})
  },

  fireAmplitude: function(direction) {
    var self = this;
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: 'featuredContentArrowClicked', 
              user_id: self.props.currentUserId,
              direction: direction
            }
    })
  },

  render: function(){
    return (
      <div className="carousel-container">
        <div className="header-container">
          <div className="row">
            <div className="small-8 small-offset-2 medium-10 medium-offset-1 large-9 columns">
              <h6>
                <strong>FEATURED CONTENT</strong>
              </h6>
            </div>
          </div>
        </div>
        <div className="height-container" data-equalizer="newsroom" data-equalizer-mq="medium-up">
          <div className="medium-1 small-2 columns arrow-container">
            <i className={"fas fa-arrow-circle-left fa-3x " + (this.state.start === 0 ? 'disabled' : "")}  onClick={() => this.handleNext('left')}></i>
          </div>
          {this.renderPosts()}
          <div className="medium-1 small-2 columns arrow-container">
            <i className={"fas fa-arrow-circle-right fa-3x "} onClick={() => this.handleNext('right')}></i>
          </div>
        </div>
      </div>
    );
  }
})

export default Main;