import {Cureus} from '../../Cureus';

Cureus.Admin.CleanTable = (function($, _) {
  var init;

  var MIN_ROWS = 5;
  var COL_DEFS = [
                    {orderable: false, targets: 0},
                    {type: "cureus_date", targets: 1}
                  ]
  init = function(opts) {
    var $tables = $(opts.selector);
    var minRows = opts.minRows || MIN_ROWS;
    var colDefs = opts.colDefs || COL_DEFS;
    var order   = opts.order || [1, 'desc'];

    $tables.each(function() {
      var self = this;

      $(self).dataTable({
        autoWidth: false,
        paging: false,
        info: false,
        searching: false,
        order: order,
        columnDefs: colDefs,
        drawCallback: function(e) {
          var $blankRow = $("<tr class='blank-row'></tr>");
          var $blankCol = $("<td></td>");
          var numColumns = e.aoColumns.length;

          for(var x = 0; x < numColumns; x++) {
            $blankRow.append($blankCol.clone());
          }

          var numBlankRows = minRows - e.fnRecordsTotal();

          if(e.fnRecordsTotal() == 0) {
            numBlankRows--;
          }

          for(var x = 0; x < numBlankRows; x++) {
            $(self).children("tbody").append($blankRow.clone());
          }
        }
      });
    });
  };

  return { init: init };
})(jQuery, _);
