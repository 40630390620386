import React, { useState, useRef, useEffect } from 'react';

const LikeUser = ({user}) => {
  return (
    <div className="row user-row">
      <div className="small-2 large-2 columns avatar">
      {/* <div className="avatar"> */}
        <div>
          <a href={user.userPath} target="_blank">
            <img src={user.avatar} alt="avatar" />
          </a>
        </div>
      </div>
      <div className="small-4 large-5 columns name-qualification-container">
        <div>
          <div>
            <a href={user.userPath}>{user.fullName}{user.qualification.length > 0 && ', '}</a>
            <p style={{ display: 'inline' }}>{user.qualification.length > 0 && user.qualification}</p>
          </div>
          <p className='specialty'>{user.specialty}</p>
        </div>
      </div>
      <div className='small-5 large-5 columns affiliation-container'>
        <i className='far fa-hospital' />
        <p>{user.affiliation}</p>
      </div>
    </div>
  )
}

export default LikeUser;