import React, { useState, useEffect } from 'react';
import SettingsButton from '../SettingsButton';

const AboutSidebarSettings = ({handleAddWidget, widgetAboutSidebarId, sidebarWidgets, slug}) => {
  const sharedProps = {handleAddWidget, widgetAboutSidebarId, sidebarWidgets, slug}
  return (
    <div className="sidebar-settings-container">
      <div className="settings-box">
        <h3>Sidebar Settings</h3>
        <p>Click below to add contact info and social media widgets to the About page sidebar. Click and drag to reorder widgets.</p>
        <hr/>
        <div className="sidebar-button-container">
          <SettingsButton
            {...sharedProps}
            img={"https://public.cureus.com/channel_design/overview_4x.png"}
            title={"Contact"}
            modalName={'AboutContactModal'}
            imgClass={"overview"}
          />
        </div>
        <div className="sidebar-button-container">
          <SettingsButton
            {...sharedProps}
            img={"https://public.cureus.com/channel_design/social_media.png"}
            title={"Social Accounts"}
            modalName={'SocialAccountsModal'}
            imgClass={"channel-team"}
          />
        </div>
      </div>
    </div>
  )
}

export default AboutSidebarSettings;