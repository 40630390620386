import React from 'react';
import PendingArticleRow from './PendingArticleRow';
import Pages from '../../Shared/Pagination/Pages'

const PendingArticlesTab = ({collections, paginationData, handlePageSelect, pagesType}) => {

  return (
    <div className='small-12 columns pending-section'>
      {collections.map((collection) => {
        return (
          <PendingArticleRow 
            key={collection.id} 
            collection={collection}
          />
        )
      })}
      <div className='pagination-container'>
        <Pages 
          paginationData={paginationData} 
          handlePageSelect={handlePageSelect} 
          pagesType={pagesType}/>
      </div>
    </div>
  )
}

export default PendingArticlesTab;