import React, { useState, useEffect } from 'react';

const MeetingCard = ({ meeting }) => {
  const [poster, setPoster] = useState(meeting.posters_count || 0);
  const [abstract, setAbstract] = useState(meeting.abstracts_count || 0);

  return (
    <div className="large-4 medium-12 small-12 columns no-pad end">
      <div className='meeting-content-card meeting-card-styles' style={{ background: `${meeting.color}`}}>
        <div className='img-container meeting-container'>
          <img src={meeting.image.url} alt="meeting-image" />
        </div>
        <span className='gray-text'><b>{meeting.meeting_date}</b></span>
        <h4>{meeting.title}</h4>
        <a href={meeting.posters_path}>
          <span className={!poster ? 'gray-text position-left-bottom' : 'position-left-bottom'}><b>{poster} Poster</b></span>
        </a>
        <a href={meeting.abstracts_path}>
          <span className={'right position-right-bottom ' + (!abstract ? 'gray-text' : '')}><b>{abstract} Abstract</b></span>
        </a>
      </div>
    </div>
  )
}

export default MeetingCard;