var createReactClass = require('create-react-class');
import React from 'react';

const InReview = ({article, renderEmailEditingReceipt}) => {
  const renderEndAuthorReviews = () => {
    if(article.canEndAuthorReviews) {
      return (
        <li>
          <a href='#' id={"action-dropdown-end-review-tooltip-" + article.id} data-reveal-id={"end-review-modal-" + article.id}>
            End author-invited review
          </a>
        </li>
      )
    }
  }

  return(
    <React.Fragment>
        {renderEndAuthorReviews()}
        {renderEmailEditingReceipt()}
    </React.Fragment>
  )
}

export default InReview;