var createReactClass = require('create-react-class');
import React from 'react';
import SiqBadgeImage from '../SiqBadgeImage'

const ArticleCard = createReactClass({
  renderSiq: function() {
    if (this.props.resource.retraction_present || ['Poster', 'Abstract'].includes(this.props.resource.humanized_type)) {
      return
    }
    var resource = this.props.resource;
     return(
      <a target="_blank" href={`${this.props.resource.path}?score_article=true`}>
        <div className="siq-badge siq-badge-wrap-medium">
          <div className="siq-hover" id={`siq-hover-appear-tracking-${resource.id}`}>
            <SiqBadgeImage
              siq={resource.siq}
            />
              <div className="siq-badge-score react-content-card">
                {resource.siq}
              </div>
          </div>
          {this.renderWinner()}
        </div>
      </a>
     )
  },
  renderSiqHeader: function() {
    return (
      <div className="header-siq">
        <span className="date">
          {this.props.resource.published_date}
        </span>
        <br/>
        <span className="type">
          {this.props.resource.humanized_type}
        </span>
        {this.renderWinnerDetails()}
      </div>
    )
  },

  renderCategories: function() {
    if (!this.props.resource.categories) {
      return
    }
    var self = this
    return this.props.resource.categories.map(function( category) {
    return <a key={category.id + '_' + self.props.resource.id} href={`/articles?categories%5B${category.id}%5D=${category.name}&page=1&q=&order=%7B%22attr%22%3A%22published_at%22%2C%22dir%22%3A%22desc%22%2C%22text%22%3A%22Newest%22%7D&advanced=false&filters=&obeySort=false`} className="tag">{category.name}</a>
    })
  },

  renderTitle: function() {
      if(this.props.page == "UserProfile" && this.props.resource.retraction_present) {
        return (
          <div className="row title">
            <a href={this.props.resource.path} target="_blank">
              <h4>
                <span style={{color: '#CA3145', marginRight: '7px'}}>RETRACTED:</span>{this.props.resource.title}
              </h4>
            </a>
          </div>
        )
      } else {
        return (
          <div className="row title">
            <a href={this.props.resource.path} target="_blank">
              <h4>
                {this.props.resource.title}
              </h4>
            </a>
          </div>
        )
      }
  },

  renderDetails: function() {
    var resource = this.props.resource;
    return(
      <div className="details">
        <div className="row header">
          <div>
            <div>
              <span className="date">
                {resource.published_date}
              </span>
            </div>
            <div>
              <span className="type">
                {resource.humanized_type}
              </span>
            </div>
          </div>
        </div>
        {this.renderTitle()}
        <div className="row authors">
          {this.props.resource.author_list}
        </div>
        <div className="row categories">
          {this.renderCategories()}
        </div>
      </div>
    )
  },

  renderChannelLogo: function() {
    if(this.props.compOrChannel && this.props.resource.channel_logo) {
      return (
        <div className="channel-logo-container">
          <img src={this.props.resource.channel_logo}/>
        </div>
      )
    }
  },

  renderWinner: function() {
    if (this.props.resource.competition_winner) {
      return(
        <div className='content-card-trophy'>
          <div dangerouslySetInnerHTML={ {__html: this.props.resource.trophy_image} } key={ this.props.resource.id + "winner"} />
        </div>
      )
    }
  },

  renderWinnerDetails: function() {
    if (this.props.resource.competition_winner) {
      return(
        <div className='content-card-trophy-details'>
          <div dangerouslySetInnerHTML={ {__html: this.props.resource.trophy_image} } key={ this.props.resource.id + "winner"} />
        </div>
      )
    }
  },

  renderThumbnail: function() {
    if (this.props.resource.has_thumbnail) {
      return (
        <div className="medium-3 medium-push-8 columns thumbnail-wrap">
          <a href={this.props.resource.path} target="_blank">
            <div className="thumbnail-mask"/>
            <div key={this.props.resource.id + "thumb"} dangerouslySetInnerHTML={{__html: this.props.resource.thumbnail}}/>
          </a>
        </div>
      )
    }
  },

  getDetailsClass: function() {
    if (this.props.resource.has_thumbnail) {
      return "medium-8 medium-pull-3 columns"
    }
    return "medium-11 columns"
  },

  render: function() {
    return (
      <div className="row">
        <div className="medium-12 columns small-centered">
          <div className="medium-10 columns small-centered article-content-card">
            <div className="medium-1 flex columns">
              {this.renderSiq()}
              {this.renderSiqHeader()}
              {this.renderChannelLogo()}
            </div>
            {this.renderThumbnail()}
            <div className={this.getDetailsClass()}>
              {this.renderDetails()}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default ArticleCard;