import React from 'react';
import SiqHover from './SiqHover';

const SiqHoverWrapper = ({article, articleRatingsAndSiq, handleOpenModal, hasScored, size, preview}) => {
  const iconUrl = () => {
    if (size == "medium") {
      return "https://public.cureus.com/article-show-redesign/siq-new-icon.png"
    } else {
      return "https://public.cureus.com/article-show-redesign/siq-b-new.png"
    }
  }

  const handleMouseEnter = () => {
    let el = document.querySelector(".left-sidebar-content")
    if (el) {el.classList.remove("overflow")}
  }

  const handleMouseLeave = () => {
    let el = document.querySelector(".left-sidebar-content")
    if (el) {el.classList.add("overflow")}
  }

  return (
    <div className="siq-badge siq-badge-wrap-medium">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="siq-hover" id={`siq-hover-appear-tracking-${article.id}`}>
      <img className="hide-for-small" alt="SIQ" data-svg-fallback="https://public.cureus.com/siq_shield.png" src={iconUrl()}/>
      <img className="hide-for-medium-up" alt="SIQ" data-svg-fallback="https://public.cureus.com/siq_shield.png" src={"https://public.cureus.com/article-show-redesign/small-siq.png"}/>
      <div className="content-card-siq">
        {!preview && <div className="siq-hover-partial">
          <SiqHover
            article={articleRatingsAndSiq}
            siq={article.siq}
            articleId={article.id}
            handleOpenModal={handleOpenModal}
            hasScored={hasScored}
            preview={preview}
            size={size}
          />
        </div>}
      </div>
      <div className={`siq-badge-score ${size} react-content-card`}>
        {article.siq}
      </div>
    </div>
  </div>
  )
}

export default SiqHoverWrapper