import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";

const NavBarItem = ({setActiveTab, activeTab, name, path, fireAmplitude, articleId, cssClass}) => {
  const handleOnClick = () => {
    setActiveTab(path)
    fireAmplitude("articleTabClicked", {article_id: articleId, tab: name})
    if(cssClass === "top-nav-links") {
      window.scrollTo({top: 500, left: 0, behavior: 'smooth'});
    }
  }

  return (
    <Link to={path} className={(activeTab === path ? 'active' : '')} onClick={handleOnClick}>
      <li>
          {name}
      </li>
    </Link>
  )
}

export default NavBarItem;