var createReactClass = require('create-react-class');
import React from 'react';
import EditModal from './EditModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const CleanAffiliations = createReactClass({
  componentDidMount: function() {
    this.populateTable()
  },

  populateTable: function() {
    var self = this
    var table;
    $(function() {
      table = $('#admin-affiliations').DataTable({
        dom: "<'row'<'large-2 columns'l>>t<'row'<'large-5 columns'i><'pull-right'p>>",
        pagingType: "full_numbers",
        serverSide: true,
        ajax: {
          url: self.props.tableDataSource
        },
        rowId: 14,
        autoWidth: true,
        "rowCallback": function(row, data) {
          var status = data[10];
          if (status === null) {
            return
          }
          var color = status == true ? 'lightgreen' : 'lightpink'
          $(row).css('background-color', color);
        },
        search: {
          search: Cureus.Utils.QueryString.urlParams().q
        },
        order: [[1, "asc"]]
      });
      $('#admin-affiliations tbody').on('click', 'tr', function() {
        var data = table.api().row(this).data();
        Modal.open({
          children: <EditModal
                      affiliation={data}/>
        })
      })
    });
    $(function() {
      var searchWait = 0;
      var searchWaitInterval;
      $('#admin-affiliations thead tr').clone(true).prependTo('#admin-affiliations thead')
      $('#admin-affiliations thead tr:eq(0) th').each(function(i){
        if (i < 9) {
          $(this).html('<input type="text"/>');
          $(this).unbind();
          $('input', this).on('keyup', function() {
            var item = $(this);
            if(item.val().length > 0 && !searchWaitInterval) searchWaitInterval = setInterval(function() {
              if (table.api().column(i).search() !== item.val() && searchWait >= 3) {
                searchWait = 0
                clearInterval(searchWaitInterval);
                searchWaitInterval = '';
                table.api().column(i).search(item.val()).draw();
              }
              searchWait ++;
            }, 200)
          });
        }
        else {
          $(this).html('<select><option>null</option><option>true</option><option>false</option></select>');
          $(this).unbind();
          $('select', this).on('change', function() {
            if (table.api().column(i).search() !== this.value) {
              table.api().column(i).search(this.value).draw();
            }
          })
        }
      });
    });
  },

  renderDataTable: function() {
    return (
      <table className="table-wrapper data-table" id="admin-affiliations">
        <thead>
          <tr>
            <th className="centered">UName</th>
            <th className="centered">PName</th>
            <th className="centered">UCountry</th>
            <th className="centered">PCountry</th>
            <th className="centered">UCity</th>
            <th className="centered">PCity</th>
            <th className="centered">Partial</th>
            <th className="centered">Token Sort</th>
            <th className="centered">Token Set</th>
            <th className="centered">Supported</th>
            <th className="centered">Status</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    )
  },

  openModal: function() {
    Modal.open({
      children: <EditModal/>
    })
  },

  render: function() {
    return(
      <div>
        {this.renderDataTable()}
      </div>
    )
  }
})

export default CleanAffiliations;