import React from 'react';

const TemplateFilter = ({ options }) => {

  return (
    <div>
      <span>Templates: </span> 
      {options.map(function(option, idx) {
        return (
          <React.Fragment key={idx}>
            <input className='template' type="checkbox" id={option.name} name={option.name} value={option.value} defaultChecked={true}/>
            <label htmlFor={option.name}>{option.name}</label>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default TemplateFilter