import React, { useState, useEffect } from 'react';
import { Cureus } from '../../Cureus';
import DiscussionCard from './DiscussionCard';
import Sortable from '../../components/Sortable';
import Modal from '../Modal';
import AddModal from './AddModal';

const Index = ({}) => {
  const [discussions, setDiscussions] = useState([])
  const [selected, setSelected] = useState([])
  const [limit, setLimit] = useState(4)

  useEffect(() => {
    fetchDiscussions()
  }, [limit])

  const fetchDiscussions = () => {
    $.ajax({
      type: 'GET',
      data: {limit: limit},
      url: "/admin/comments/fetch_discussions",
      success: function(data) {
        setDiscussions(data.discussions)
        setSelected(data.selected)
      }
    });
  }

  const handleLoadMore = () => {
    setLimit(limit + 4)
  }

  const handleSave = () => {
    let orderedCommentIds = [];
    document.querySelectorAll("input[type='checkbox']:checked").forEach(function(node) {
      orderedCommentIds.push(node.name)
    })
    $.ajax({
      type: 'POST',
      data: {comment_ids: JSON.stringify(orderedCommentIds)} ,
      url: "/admin/comments/set_selected_comments",
      success: function() {
        Cureus.Flash.success('Comments successfully saved.')
      }
    });
  }

  const handleAdd = () => {
    Modal.open({
			children: <AddModal 
                  selected={selected}
                  setSelected={setSelected}
                  discussions={discussions}
                  setDiscussions={setDiscussions}
                  handleRechecks={handleRechecks}
                />
		});
  }

  const handleSort = (e, sortable, movedComponent) => {
    let newPosition = e.newIndex + 1;
    handleUpdatePosition(movedComponent, newPosition);
  }

  const handleUpdatePosition = (movedComponent, newPosition) => {
    let oldPositionIndex = movedComponent.props.position - 1;
    
    let movedDiscussion = discussions[oldPositionIndex];
    let newDiscussionsState = discussions.slice()
    newDiscussionsState.splice(oldPositionIndex, 1);
    newDiscussionsState.splice(newPosition - 1, 0, movedDiscussion);
    handleRechecks()
    setDiscussions(newDiscussionsState)
  }

  const handleRechecks = () => {
    $('input[type=checkbox]').prop('checked', false);
    let currSelected = [...selected]
    setSelected([])
    setTimeout(function() {
      currSelected.forEach(function(id) {
        document.querySelector(`[name="${id}"]`).click()
      })
    }, 200)
  }

  const renderDiscussions = () => {
    return discussions.map(function(discussion, idx) {
      return <DiscussionCard 
        discussion={discussion}
        selected={selected}
        setSelected={setSelected}
        position={idx + 1}
      />
    })
  }
  
  return (
    <React.Fragment>
      <div className="large-12 columns">
        <h3>Discussions Settings</h3>
      <Sortable tagName='div' handleSort={ handleSort }>
        {renderDiscussions()}
      </Sortable>
      </div>
      <div className="large-12 columns">
        <a className="button special" onClick={handleLoadMore}>Load More</a>
        <br/>
        <a className="button special" onClick={handleAdd}>Add New</a>
        <br/>
        <a className="button" disabled={selected.length === 0 ? 'disabled' : ''} onClick={handleSave}>Save</a>
      </div>
    </React.Fragment>
  )

}

export default Index;