var createReactClass = require('create-react-class');
import React from 'react';
import ContactCoAuthors from '../ContactCoAuthors';

const Validator = createReactClass({
	getInitialState: function() {
		return {
			disclosure: {},
			disclosureFields: {}
		}
	},

	componentWillMount: function() {
		this.setState({
			disclosure: this.props.disclosure,
			disclosureFields: this.props.disclosure_fields,
		})
	},

	componentDidMount: function() {
		this.getInitialToggle();
	},

  getDefaultProps: function() {							
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");
    return {
      csrfParam: param,
      csrfToken: token
    };
  },


  getInitialToggle: function() {
  	this.handleRadioButtonSelection();
  },


  handleRadioButtonSelection: function() {
		if ($("[data-radio-button=true]").is(":checked")) {
			this.show();
			this.validateInput();
		}
		else if ($("[data-radio-button=false]").is(":checked")) {
			this.hide();
			this.enableNext();
			}
  },

 	validateInput: function() {
 		if (this.checkValid() == true) {
 			this.enableNext();
 		}
 		else if (this.checkValid() == false) {
 			this.disableNext();
 		}
 	},

  checkValid: function() {
  	if ( ($("[data-input-required1=true]").val().length > 0) && ( $("[data-input-required2=true]").val().length > 0 ) ) {
  		return true;
  	}
  	else {
  		return false
  	}
  },

  $hideElm: function(){
  	return $("[data-radio-button=false]");
  }, 

  $showElm: function() {
  	return $("[data-radio-button=true]");
  },

  $nextButton: function() {
  	return $(this.props.nextButton);
  },

 	$toggleableElm: function() {
 		return $("[data-toggleable-elm=true]");
 	},

 	enableNext: function() {
 		this.$nextButton().attr("disabled", false);
 	},

 	disableNext: function() {
 		this.$nextButton().attr("disabled", true);
 	},

  hide: function () {
    this.$toggleableElm().hide(); 
  },

  show: function () {
    this.$toggleableElm().show();
  },

	render: function() {
		return (
			<div>
	      <div className="row">
		      <div className="small-12 columns">
		      <input id={this.props.article_type + "_disclosures_attributes_0_id" } name={this.props.article_type + "[disclosures_attributes][0][id]"} type="hidden" defaultValue={ this.state.disclosure.id }/> 

		        
					  <div className="stacked-radio-inputs">
		        <input id={this.props.article_type + "_disclosures_attributes_1_id" } name={this.props.article_type + "[disclosures_attributes][1][id]"} type="hidden" defaultValue={ this.state.disclosure.id } />
		          <h4>Animal Subjects</h4>
		          <p>Did you study animal subjects or tissue?</p>

		          <input data-radio-button="false" id={ this.props.article_type + "_disclosures_attributes_1_declared_false"} name={this.props.article_type + "[disclosures_attributes][1][declared]"} type="radio" defaultValue = "false" defaultChecked= {this.state.disclosure.declared == false ? "true" : "" } onChange={ this.handleRadioButtonSelection }/>
		          <label htmlFor={ this.props.article_type + "_disclosures_attributes_1_declared_false" }>
		         		No, this study does not involve animal subjects or tissue.
		          </label>
		          <br></br>
							<input data-radio-button="true" defaultChecked={ this.state.disclosure.declared ? "checked" : ""} className="hs-radio-true" id={ this.props.article_type + "_disclosures_attributes_1_declared_true"} name={this.props.article_type + "[disclosures_attributes][1][declared]"} type="radio" defaultValue = "true" defaultChecked={ this.state.disclosure.declared == true ? "true" : "" } onChange={ this.handleRadioButtonSelection} />
		          <label htmlFor={ this.props.article_type + "_disclosures_attributes_1_declared_true" }>
		          	Yes, this study involves animal subjects or tissue.
		          </label>
		          
		          <ContactCoAuthors
		        		author_emails={ this.props.author_emails }
								submitter_email={ this.props.submitter_email}
								event_klass={ this.props.event_klass }
								shareEmailUrl={ this.props.shareEmailUrl}/>

         		 </div>
      		</div>
	      </div>



	    <div data-toggleable-elm="true" style={{display: "none"}}>
		    <input id={this.props.article_type + "_disclosures_attributes_0_id" } name={this.props.article_type + "[disclosures_attributes][2][id]"} type="hidden" defaultValue={ this.state.disclosure.id }/>

		      <div className= "row">
		        <div className= "small-12 columns">
		         	<label htmlFor="irb-name-field">
		          	Name of Local Animal Ethics Committee or NIH guidelines statement
	          	</label>
		          <textarea onChange= { this.validateInput } data-input-required1="true" className="larger-text-pizzas" cols="40" id="text-area" name={this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][0][data]"} defaultValue= { this.state.disclosureFields.AnimalSubjectsEthicsCommittee.text_data }></textarea>
		          <input id={this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_0_id" } name={this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][0][id]"} type="hidden" defaultValue={ this.state.disclosureFields.AnimalSubjectsEthicsCommittee.id }/>
		         	
		        </div>

		        <div className= "small-12 columns">
		        	<label htmlFor="approval-number-field">
		          	Protocol Number
	          	</label>
		          <input onChange={ this.validateInput } data-input-required2="true" id="approval-number-field" name={this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][1][data]"} size="30" type="text" defaultValue={ this.state.disclosureFields.AnimalSubjectsProtocolNumber.text_data }/>
		          <input id={this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_1_id" } name={this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][1][id]"} type="hidden" defaultValue={ this.state.disclosureFields.AnimalSubjectsProtocolNumber.id }/>
		          
		          
		        </div>
		      </div>
	    	</div>
    </div>
		)
	}
})

export default Validator;