import React, { useEffect } from 'react';
import Filters from './LeftSidebar/Filters';

const FiltersModal = ({categories, contentTypes, updateTypes, updateCategories, submitUpdate, categoryIds, categoryIdPreSelected}) => {

  return (
    <div className="reveal-modal large" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <h5>Filter Browse Articles</h5>
          <Filters
            categories={categories}
            categoryIds={categoryIds}
            contentTypes={contentTypes}
            updateTypes={updateTypes}
            updateCategories={updateCategories}
            submitUpdate={submitUpdate}
            modal={true}
            id={"modal-view"}
            categoryIdPreSelected={categoryIdPreSelected}
          />
        </div>
      </div>
    </div>
  )
}

export default FiltersModal