import React, { useEffect, useState } from 'react';
import FeaturedContentCard from './FeaturedContentCard'
import { Cureus } from '../../Cureus';
import Sortable from '../../components/Sortable';

const FeaturedContent = ({featuredContent, featuredContentPath, specialties, articleExistsPath, newsroomPostDetailsPath}) => {

  const [cards, setCards] = useState(featuredContent);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (cards.length == 0) {
      setCards([{},{},{},{}])
    }
    document.querySelectorAll('#num-cards-select option').forEach(opt => {
      if (opt.value < cards.length) {
        opt.disabled = true;
      } else {
        opt.disabled = false;
      }
    });
    $('#num-cards-select').val(cards.length);
  }, [cards])

  const renderCards = () => {
    return cards.map((card, idx) =>
      <FeaturedContentCard
        newsroomPostDetailsPath={newsroomPostDetailsPath}
        setSaveButtonEnabled={setSaveButtonEnabled}
        articleExistsPath={articleExistsPath}
        specialties={specialties}
        cards={cards}
        cardIndex={idx}
        setCards={setCards}
        originalCard={card}
        key={card.position}
        position={idx + 1}
      />
    )
  }
  
  const saveFeaturedContent = (e) => {
    if (!saving && saveButtonEnabled) {
      setSaving(true)
      let formData = new FormData();
      let card = null

      for (var i = 0; i < cards.length; i++) {
        card = cards[i]
        for(let dataKey in card) {
          formData.append(`cards[${i}][${dataKey}]`, outputCorrectPosition(card, dataKey, i));
        }
      }

      fetch(featuredContentPath, {
        method: "PUT",
        body: formData,
        credentials: 'same-origin',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': $("[name='csrf-token']").attr("content")
        }
      }).then(function(res) {
        Cureus.Flash.success('Content Successfully Saved.')
        setSaving(false)
        setSaveButtonEnabled(false)
      })
    }
  }

  const outputCorrectPosition = (card, dataKey, index) => {
    if (dataKey == "position") {
      return index + 1
    } else {
      return card[dataKey]
    }
  }

  const updateNumCards = (e) => {
    let newNum = e.target.value
    let numCards = cards.length
    if (newNum > numCards) {
      let diff = newNum - numCards
      let tempCards = [...cards]
      for (let i = 0; i < diff; i++){
        tempCards.push({})
      }
      setCards(tempCards)
    } else if (newNum < numCards) {
      alert('Please delete cards if required')
      e.preventDefault()
    }
  }

  const createSelectItems = () => {
    let items = [];
    for (let i = 1; i <= 10; i++) {
         items.push(<option key={i} value={i} >{i}</option>);
    }
    return items;
  }

  const handleSort = (e, sortable, movedComponent) => {
    let newPosition = e.newIndex + 1;
    handleUpdatePosition(movedComponent, newPosition)

  }

  const handleUpdatePosition = (movedComponent, newPosition) => {
    let oldPositionIndex = movedComponent.props.position - 1;

    let movedCard = cards[oldPositionIndex];
    let newCardsState = cards.slice()

    newCardsState.splice(oldPositionIndex, 1);
    newCardsState.splice(newPosition - 1, 0, movedCard);

    setCards(newCardsState)
    setSaveButtonEnabled(true)
  }

  return (
    <div className="small-12 columns featured-content-container">
      <h3>Featured Content Carousel</h3>
      <div className="row">
        <div className="columns small-4 num-cards-container">
          <div className="input-container">
            <label htmlFor="num-cards-select">Number of screens</label>
            <select defaultValue={cards.length} onChange={(e) => {updateNumCards(e)}} id="num-cards-select">
              {createSelectItems()}
            </select>
          </div>
        </div>
      </div>
      <div className="row" style={{paddingLeft: '.5em'}}>
        <div className="columns small-12 card-container">
          <Sortable tagName='div' handleSort={ handleSort }>
            {renderCards()}
          </Sortable>
        </div>

      </div>
      <div className="row">
        <div>
          <button className={`save-content ${saveButtonEnabled && !saving  ? '' : 'disabled'}`} onClick={() => saveFeaturedContent()}>SAVE</button>
          <img className={`saving-cards-spinner ${saving  ? '' : 'hide'}`} src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      </div>
    </div>

  )
}

export default FeaturedContent;