import {Cureus} from '../Cureus';

Cureus.Dashboard = {
  ReviewsDashboard: {
    ReviewerActivity: {}
  },
  CoauthorTool: {},
  ActionButton: {},
  Metrics: {}
};
//= require_tree ./Dashboard