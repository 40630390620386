import React, { useState } from 'react';
import { Cureus } from '../../Cureus';

const NewFaq = ({basePath, currentFaqPairs, maxQuestions, setNewFaqPairs}) => {
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");

  const handleAdd = () => {
    $.ajax({
      url: basePath,
      type: 'POST',
      dataType: 'json',
      data: {question: newQuestion, answer: newAnswer},
      success: function(res) {
        setNewFaqPairs(res.faqs);
        setNewQuestion("");
        setNewAnswer("");
        Cureus.Flash.success('Successfully added question.')
      },
      error: function(res) {
        res.responseJSON ? Cureus.Flash.alert(`${res.responseJSON.message}`) : Cureus.Flash.alert(`Question could not be created, make sure to enter a question and an answer.`)
      }
    })
  }

  const handleNewQuestionChange = (e) => {
    setNewQuestion(e.target.value)
  }

  const handleNewAnswerChange = (e) => {
    setNewAnswer(e.target.value)
  }

  return (
    <>
      <div className="row">
        <div className='large-9 medium-9 small-9 columns'>
          <textarea type='text' placeholder="Add question" onChange={handleNewQuestionChange} value={newQuestion}></textarea>
        </div>
      </div>
      <div className='row'>
        <div className='large-9 medium-9 small-9 columns'>
          <textarea type='text' placeholder="Add answer" onChange={handleNewAnswerChange} value={newAnswer}></textarea>
        </div>
      </div>
      <div className='row'>
        <div className='large-1 medium-1 small-1 columns'>
          <a className='button' disabled={currentFaqPairs.length >= maxQuestions} onClick={handleAdd}>Add</a>
        </div>
      </div>
    </>
  )
}

export default NewFaq;