import React from 'react'
var createReactClass = require('create-react-class');
import EmailReminderModal from './EmailReminderModal';
import Modal from '../Modal';


const Main = createReactClass({
  handleClick: function() {
    var self = this;

    $.get(self.props.templatePath, {templateData: self.props.templateData, templateName: self.props.templateName}, function(data) {
      Modal.open({
        children:
          <EmailReminderModal
            templateName={ self.props.templateName }
            templateData={ self.props.templateData }
            createPath={ self.props.createPath }
            data={ data }
          />
      });
    });
  },

  render: function() {
    return (
      <a onClick={ this.handleClick }>
        <i className='fa fa-envelope'></i>
      </a>
    )
  }
});

export default Main;