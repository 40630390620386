var createReactClass = require('create-react-class');
import React from 'react';

const PMCOrder = createReactClass({
  getDefaultProps: function() {
    return {
      selectOptions: [
        {
          label: "Newest",
          value: "published_at_desc"
        },
        {
          label: "Oldest",
          value: "published_at_asc"
        },
        {
          label: "Highest SIQ",
          value: "siq_desc"
        },
        {
          label: "Lowest SIQ",
          value: "siq_asc"
        },
        {
          label: "Journal Title A-Z",
          value: "journal_title_asc"
        },
        {
          label: "Journal Title Z-A",
          value: "journal_title_desc"
        }
      ],
      value: "Newest"
    }
  },

  handleChange: function(val) {
    this.props.onChange(val);
  },

  valueRenderer: function(val) {
    return (
      <div>
        <i className='fa fa-sort'></i>
        &nbsp;
        { val.label }
      </div>
    )
  },

  render: function() {
    return (
      <Select
        options={ this.props.selectOptions }
        searchable={ false }
        value={ this.props.value }
        clearable={ false }
        onChange={ this.handleChange }
        valueRenderer={ this.valueRenderer }
        className="plain-select"
      />
    )
  }
});

export default PMCOrder;
