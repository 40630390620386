var createReactClass = require('create-react-class');
import React from 'react';
import Card from './Card';

const Content = createReactClass({
  renderCards: function() {
    let numResources = this.props.resources.length
    return this.props.resources.map(function(res, idx) {
      return (
        <Card
          resource={res}
          last={idx==numResources-1}
        />
      )
    })
  },

  getArticlesPath: function() {
    if (this.props.selectedId != 0) {
      return this.props.categoryUrl
    } else {
      return '/articles'
    }
  },

  setClass: function() {
    if(this.props.landing) {
      return "small-10 small-offset-1 columns"
    } else {
      return "small-12 medium-9 large-10 columns"
    }
  },

  renderViewButton: function () {
    if (!this.props.landing) {
      return (
        <div className="view-all-articles">
          <a className="button alternate" href={this.getArticlesPath()}>View All Articles</a>
        </div>
      )
    }
  },

  renderTitle: function() {
    if(!this.props.landing) {
      return (
        <div className="title">
          {this.props.selected.split('/').join('/\n').toUpperCase()}
        </div>
      )
    }
  },

  render: function() {
    return (
      <div className={this.setClass()}>
        <div className={"featured-content " + (this.props.landing ? 'landing' : '')}>
          {this.renderTitle()}
          {this.renderCards()}
          {this.renderViewButton()}
        </div>
      </div>
    )
  }
})

export default Content;