import React from 'react';

const Confidentiality = () => {
  return (
    <React.Fragment>
      <h1>Confidentiality</h1>
      <p>
        All information regarding submitted articles is confidential, and Cureus will never publicly comment on submitted or rejected articles. Additionally, reviewer identities will remain confidential unless permission is given to reveal those identities. Authors, reviewers and editors must also treat all correspondence as confidential unless explicitly stated otherwise.
      </p>
    </React.Fragment>
  )
}

export default Confidentiality;