import React from 'react';

const Card = ({competition}) => {

  return (
      <div className="small-12 medium-6 large-4 competition-card">
        <div className="competition-card-top-section large-6">
          <img src={competition.thumbnail_url}/>
          <span className="competition-card-date">{competition.end_date}</span>
          <span>{competition.category}</span>
        </div>
        <p>{competition.title}</p>
        <div className='hovered-content'>
          <a class="button large learn-more-button" href={competition.competition_path}>LEARN MORE</a>
        </div>
      </div>
  )
}

export default Card;