var createReactClass = require('create-react-class');
import React from 'react';

const RereviewsComplete = createReactClass({
  render: function() {
    return(
      <React.Fragment>
          <li>
            <a href={this.props.article.editArticlePath} className='has-tip tip-top' data-tooltip="" title="Make additional edits (based on reviewer feedback) before submitting for publication." id={"action-dropdown-edit-tooltip-" + this.props.article.id}>
              Edit article
            </a>
          </li>
          <li>
            <a target="_blank" href={this.props.article.previewPath}>Preview</a>
          </li>
          <li>
            <a href='#' className='has-tip tip-top' data-tooltip="" title="Edit the article and resubmit for additional reviews." id={"action-dropdown-request-re-review-tooltip-" + this.props.article.id} data-reveal-id={"request-re-review-modal-" + this.props.article.id}>
              Request re-review
            </a>
          </li>
          {this.props.renderEmailEditingReceipt()}
          <li>
            <a className="dropdown-link" onClick={this.props.handleDelete}>Delete</a>
          </li>
        </React.Fragment>
    )
  }
})

export default RereviewsComplete;