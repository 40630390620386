var createReactClass = require('create-react-class');
import React from 'react';

const Close = createReactClass({
  render: function(){
    return (
      <div className="pull-right">
        <a className="howto-close" data-alert-exception="true" onClick={ this.props.handleClose }>
          <img src="https://public.cureus.com/close_x.png"/>
        </a>
      </div>
    );
  }
});

export default Close;