var createReactClass = require('create-react-class');
import React from 'react';

const ArticleCitations = createReactClass({
  render: function(){
    return (
      <div>
        <h4 className="reg">How should I format my citations?</h4>
        <h5>
          <i className="far fa-check-circle good"/>
          &nbsp;Correct
        </h5>
        <p>Like the Brown study [1], Reynolds, et al. also found a statistically significant decrease in procedure and fluoroscopy time with subsequent trials [2]. The most recent studies involved the evaluation of a 120-minute simulator-based training course for 37 participants [3-5].</p>
        <h5>
          <i className="far fa-check-circle good"/>
          &nbsp;Correct
        </h5>
        <p>Like the Brown study, Reynolds, et al. also found a statistically significant decrease in procedure and fluoroscopy time with subsequent trials [1,2]. The most recent studies involved the evaluation of a 120-minute simulator-based training course for 37 participants [3-5].</p>
        <h5>
          <i className="fa fa-times-circle bad"/>
          &nbsp;Incorrect
        </h5>
        <p>Like the Brown study, Reynolds, et al. also found a statistically significant decrease in procedure and fluoroscopy time with subsequent trials. [1, 2, 3] The most recent study involved the evaluation of a 120-minute simulator-based training course for 37 participants[4, 5].</p>
        <p><strong>Note: </strong> Citations are not permitted in figure, table or video titles, however, they may be included in the legend. </p>
      </div>
    );
  }
});

export default ArticleCitations;