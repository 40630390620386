var createReactClass = require('create-react-class');
import React from 'react';

const Slider = createReactClass({
  render: function(){
    return (
      <div>
        <p className="siq-slider-title">{ this.props.criterium.title }</p>
        <input id={"scoring_" + this.props.criterium.underscored_title}  name={"scoring[" + this.props.criterium.underscored_title + "]"} className="hidden-scoring-value" type="hidden" value={this.props.criterium.val}/>
        <div className="cuslider" data-input_id={"scoring_" + this.props.criterium.underscored_title} data-min="1" data-max="10"></div>
      </div>
    );
  }
});

export default Slider;