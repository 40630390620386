import React, { useState, useEffect } from 'react';

const IntervalButton = ({buttonInterval, setButtonInterval, title, intervalName}) => {

  return (
      <a className={"button stencil-blue " + (buttonInterval === intervalName ? 'active' : '')} onClick={() => setButtonInterval(intervalName)}>
        {title}
      </a>
  )
}

export default IntervalButton;