var createReactClass = require('create-react-class');
import React from 'react';
import AddFromCureus from './AddFromCureus';
import EmailTool from '../EmailTool';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const AddFromCureusMain = createReactClass({

  render: function() {
    let flux = new Fluxxor.Flux({
      Table: new EmailTool.fluxxor.stores.Table(),
      FilterTool: new EmailTool.fluxxor.stores.FilterTool({availableFilters: JSON.parse(this.props.availableFilters)})
    }, EmailTool.fluxxor.actions)

    return (
      <AddFromCureus
        flux={ flux }
        listEmailsCount={ this.props.listEmailsCount }
        emailListTitle={ this.props.emailListTitle }
        dataSource={ this.props.dataSource }
        updateUrl={ this.props.updateUrl }
        addFromCureusPath={ this.props.addFromCureusPath }
        emailListPath={ this.props.emailListPath }
        totalUserCount={ this.props.totalUserCount }
      />
    )
  }
})

export default AddFromCureusMain;
