import React, { useState } from 'react';
import Modal from '../Modal';

const AddPersonModal = ({ addEmailToList }) => {

  const [emailError, setEmailError] = useState(false);

  const addEmail = () => {
    const firstName = $('#first-name-input').val()
    const lastName = $('#last-name-input').val()
    const specialty = $('#specialty-input').val()
    const email = $('#add-email-to-list-input').val()
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if ( re.test(email) ) {
      setEmailError(false)
      addEmailToList(email, firstName, lastName)
      Modal.close();
    }
    else {
      setEmailError(true)
    }
  }

  return (
    <div className="email-list-tool-modal reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        
        <h5>Add Individual Recipient</h5>
        
        <div className="modal-content">
          <input id="first-name-input" type='text' style={{width: 320, display: "inline-block", marginRight: 8}} placeholder='First Name' />
          <input id="last-name-input" type='text' style={{width: 320, display: "inline-block", marginRight: 8}} placeholder='Last Name' />
          <input id="add-email-to-list-input" type='email' style={{width: 320, display: "inline-block", marginRight: 8}} placeholder='example@email.com' />
          <a className={'button secondary '} onClick={() => addEmail() }>add</a>
          <p className={`error ${emailError ? '' : 'hidden'}`}>This email is improperly formatted</p>
        </div>
      </div>
    </div>
  )
}

export default AddPersonModal;