import React from 'react';

const Tile = ({keyword, fireAmplitude}) => {
  let query = keyword.split(" ").join("+") 
  return (
    <div className="small-12 medium-4 large-4 columns no-padding end">
      <a onClick={() => fireAmplitude('articleKeywordClicked', {source: 'more about', keyword: keyword})} href={`/articles?page=1&q=&order=%7B%22attr%22%3A%22_score%22%2C%22dir%22%3A%22desc%22%2C%22text%22%3A%22Relevance%22%7D&advanced=true&filters%5B0%5D%5Bboolean%5D=&filters%5B0%5D%5Bfield%5D=keywords&filters%5B0%5D%5Bquery%5D=${query}&obeySort=false`}>
        <div className="more-about-tile" data-equalizer-watch="more-about-tiles">
          {keyword}
        </div>
      </a>
    </div>
  )
}

export default Tile;