import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import TeamMemberCard from '../MainWidgets/ChannelTeam/TeamMemberCard';
import UpdateButton from '../UpdateButton';
import DeleteButton from '../DeleteButton';
import OverviewInfoRow from './Overview/OverviewInfoRow';

const OverviewWidget = ({slug, handleDeleteWidget, handleUpdateWidget, sidebarWidgets, widgetId}) => {
  const [widget, setWidget] = useState({});
  const [mission, setMission] = useState("");
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    if(widgetId) {
      let onSuccess = (res) => {
        setWidget(res);
        setMission(res.description);
        setLocation(res.location);
        setCategory(res.category);
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [sidebarWidgets])

  const renderActionButtons = () => {
    return (
    <>
      <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
      <UpdateButton
        handleUpdateWidget={handleUpdateWidget}
        widget={widget}
        modalName={"OverviewSidebarModal"}
        slug={slug}
      />
      <DeleteButton
        handleDeleteWidget={handleDeleteWidget}
        widgetId={widgetId}
      />
    </>
    )
  }

  const renderFirstHr = () => {
    if (mission && location) {
      return <hr/>
    } else {
      return <></>
    }
  }

  const renderSecondHr = () => {
    if ((mission || location) && category) {
      return <hr/>
    } else {
      return <></>
    }
  }

  return (
    <div className="row sidebar-row">
      {handleDeleteWidget && renderActionButtons()}
      <div className="small-12 columns widget overview">
        <div>
          <h3 className='no-margin'>Overview</h3>

          {mission && <>
            <OverviewInfoRow
              label={"About the channel"}
              imgUrl={"https://public.cureus.com/channel_design/overview_about_icon_4x.png"}
              details={mission}
            />
          </>}
          {renderFirstHr()}

          {location && <>
            <OverviewInfoRow
              label={"Address"}
              imgUrl={"https://public.cureus.com/channel_design/location_and_category_icon_4x.png"}
              details={location}
            />
          </>}
          {renderSecondHr()}

          {category && <>
            <OverviewInfoRow
              label={"Category"}
              imgUrl={"https://public.cureus.com/channel_design/category_test_hat.png"}
              details={category}
            />
          </>}

        </div>
      </div>
    </div>
  )
}

export default OverviewWidget;