import React from 'react';

const ArtificialIntelligence = () => {
  return (
    <React.Fragment>
      <h1>Artificial Intelligence</h1>

      <h2>Author Use of Large Language Models (LLM) Including Artificial Intelligence (AI) Chatbots</h2>
      <p>
        Large Language Models (LLMs), such as <a href='https://openai.com/blog/chatgpt/' target='_blank'>ChatGPT</a>, do not currently satisfy our <a href='/author_guide#!/policies-and-procedures/icmje-requirements'>authorship criteria</a>. Notably an attribution of authorship carries with it accountability for the work, which cannot be effectively applied to LLMs. Use of an LLM should be properly documented in the Methods section (and if a Methods section is not available, in the Acknowledgements section) of the manuscript.
      </p>
      <p>
        The use of an LLM (or other AI-tool) for “AI assisted copy editing” purposes does not need to be declared. In this context, we define the term "AI assisted copy editing" as AI-assisted improvements to human-generated texts for readability and style, and to ensure that the texts are free of errors in grammar, spelling, punctuation and tone. These AI-assisted improvements may include wording and formatting changes to the texts, but do not include generative editorial work and autonomous content creation. In all cases, there must be human accountability for the final version of the text and agreement from the authors that the edits reflect their original work.
      </p>

      <h2>Generative AI Images</h2>
      <p>
        The fast moving area of generative AI image creation has resulted in novel legal copyright and research integrity issues. As publishers, we strictly follow existing copyright law and best practices regarding publication ethics. While legal issues relating to AI-generated images and videos remain broadly unresolved, Springer Nature journals are unable to permit its use for publication.
      </p>
      <p>Exceptions*:</p>
      <ul className='disc indented-for-medium-up'>
        <li>Images/art obtained from agencies that we have contractual relationships with that have created images in a legally acceptable manner.</li>
        <li>Images and video that are directly referenced in a piece that is specifically about  AI and such cases will be reviewed on a case-by-case basis.</li>
        <li>The use of generative AI tools developed with specific sets of underlying scientific data that can be attributed, checked and verified for accuracy, provided that ethics, copyright and terms of use restrictions are adhered to.</li>
      </ul>
      <p>
        *All exceptions must be labeled clearly as generated by AI within the image field.
      </p>
      <p>
        As we expect things to develop rapidly in this field in the near future, we will review this policy regularly and adapt it if necessary.
      </p>
      <p>
        NOTE: Examples of image types covered by this policy include: video and animation, including video stills; photography; illustration such as scientific diagrams, photo-illustrations and other collages, and editorial illustrations such as drawings, cartoons or other 2D or 3D visual representations. Not included in this policy are text-based and numerical display items, such as: tables, flow charts and other simple graphs that do not contain images. Please note that not all AI tools are generative. The use of non-generative machine learning tools to manipulate, combine or enhance existing images or figures should be disclosed in the relevant caption upon submission to allow a case-by-case review.
      </p>

      <h2>Peer Reviewer Use of Large Language Models (LLM) Including Artificial Intelligence (AI) Chatbots</h2>
      <p>
        Peer reviewers play a vital role in scientific publishing. Their expert evaluations and recommendations guide editors in their decisions and ensure that published research is valid, rigorous, and credible. Editors select peer reviewers primarily because of their in-depth knowledge of the subject matter or methods of the work they are asked to evaluate. This expertise is invaluable and irreplaceable. Peer reviewers are accountable for the accuracy and views expressed in their reports, and the peer review process operates on a principle of mutual trust between authors, reviewers and editors. Despite rapid progress, generative AI tools have considerable limitations: they can lack up-to-date knowledge and may produce nonsensical, biased or false information. Manuscripts may also include sensitive or proprietary information that should not be shared outside the peer review process. For these reasons we ask that, while Cureus explores providing our peer reviewers with access to safe AI tools, peer reviewers do not upload manuscripts into generative AI tools.
      </p>
      <p>
        If any part of the evaluation of the claims made in the manuscript was in any way supported by an AI tool, we ask peer reviewers to declare the use of such tools transparently in the peer review report.
      </p>
    </React.Fragment>
  )
}

export default ArtificialIntelligence;