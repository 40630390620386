import React, { useState, useEffect } from 'react';

const MobileNavBar = ({hasArticles, hasMeetings, hasAbstracts, hasPosters, activeTab}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const renderDropdown = () => {
    if(showDropdown) {
      return (
        <div className="nav-dropdown">
          <a href='google.com'>ARTICLES</a>
        </div>
      )
    }
  }

  return (
    <div className="row hide-for-large-up">
      <div className="small-12 columns channel-main-nav mobile">
        <div className="dropdown-container" onFocus={() => setShowDropdown(true)} onBlur={() => setShowDropdown(false)}>
          <span>{activeTab}</span> <i className="fa fa-angle-down"/>
        </div>
        {renderDropdown()}
      </div>
    </div>
  )
}

export default MobileNavBar;