
var createReactClass = require('create-react-class');
import React from 'react';
import * as UserToolUtils from '../../Publishing/UserTool/ApiClient';
import Form from '../../UserPicker/Form';

const EditModal = createReactClass({
  getInitialState: function() {
    return({
      showSpinner: false,
      showCheckingForUser: false,
      didSearch: false,
      errors: null, 
      usership: this.props.usership
    })
  },

  componentDidMount: function (){
    var self = this;
    $(self.refs.container).on("opened", function (evt){
      self.setState({focusOn:"firstName"});
    });
  },

  componentWillMount: function() {
    if(this.props.dashboardEmail) {
      this.handleSearch(this.props.searchEmail);
    }
  },

  handleSearch: function(email) {
    var self = this;

    if(email.length == 0) { return; }

    self.setState({showSpinner: true, showCheckingForUser: true, didSearch: false});

    UserToolUtils.getSearch({
      email: email,
      endpointUrl: self.props.endpointUrl,
      params: self.props.defaultParams,
      success: function(data) {
        var usership
        if (data["id"] && data.is_confirmed && data.first_name !== null) {
          usership = {...self.props.usership, ...{ editable: data.editable, user: data }}
        } else {
          usership = self.props.usership
          if (data["id"]) {
            usership.user.id = data["id"]
          }
        }
        self.setState({
          usership: usership,
          showSpinner: false,
          showCheckingForUser: false,
          errors: null,
          focusOn: (data.id ? "submit" : "firstName"),
          didSearch: true
        });
      },
      error: function(jqXHR) {
        self.setState({
          showSpinner: false,
          showCheckingForUser: false,
          errors: JSON.parse(jqXHR.responseText),
          didSearch: true
        });
      }
    });
  },

  handleSubmit: function(params) {
    var self = this;
    UserToolUtils.putUpdate({
      id: self.state.usership.id,
      params: Object.assign(params, self.props.defaultParams),
      endpointUrl: self.props.endpointUrl,
      success: function(res) {
        UserToolUtils.getIndex({
          endpointUrl: self.props.endpointUrl,
          params: self.props.defaultParams,
          success: self.props.updateUserships
        })
        $(self.refs.container).foundation("reveal", "close");
      },
      error: function(jqXHR) {
        self.setState({errors: JSON.parse(jqXHR.responseText)});
      }
    });
  },

  title: function(){
    if (this.props.isAuthorships) {
      return this.state.usership.user.is_confirmed ? "Author Details" : "Edit Author Details"
    }
    return "Edit details"
  },

  submitText: function() {
    if (this.props.isAuthorships) {
      return this.state.usership.user.is_confirmed ? "Add Author" : "Update Author"
    }
    return "Update reviewer"
  },

  render: function() {
    return (
      <div className="reveal-modal middling user-picker-modal" data-reveal ref="container">

        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className='modal-content'>
            <Form
              key="edit_reviewer_form"
              resource={this.state.usership}
              title={this.title()}
              submitText={this.submitText()}
              specialtyOptions={this.props.specialtyOptions}
              countryOptions={this.props.countryOptions}
              affiliationNameQueryUrl={this.props.affiliationNameQueryUrl}
              deptAutocompletePath={ this.props.deptAutocompletePath }
              enableSearch={this.props.dashboardEmail ? true : false}
              onSubmit={this.handleSubmit}
              showSpinner={this.state.showSpinner}
              errors={this.state.errors}
              hideInstitutionFields={false}
              doNotShowUserExistsLabel={true}
              focusOn={this.state.focusOn}
              added={this.props.added} 
              searchEmail={this.props.searchEmail}
              showCheckingForUser={this.state.showCheckingForUser}
              didSearch={this.state.didSearch}
              dashboardEmail={this.props.dashboardEmail}
              />
          </div>
        </div>
      </div>
    )
  }
});

export default EditModal;