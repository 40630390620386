
var createReactClass = require('create-react-class');
import React from 'react';
import Department from './Department';
import Institution from './Institution';

const Affiliation = createReactClass({
  affiliationHeaderText: function() {
    if (this.props.idx == 0) {
      return "Primary Affiliation"
    }
    return this.stringifyNumber(this.props.idx + 1) + " Affiliation"
  },

  stringifyNumber: function(n) {
    var special = ['Zeroth','First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth'];
    var deca = ['Twent', 'Thirt', 'Fort', 'Fift', 'Sixt', 'Sevent', 'Eight', 'Ninet'];
    if (n < 20) return special[n];
    if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
    return deca[Math.floor(n/10)-2] + 'y ' + special[n%10];
  },

  handleCountryChange: function(country) {
    this.props.handleCountryChange(country, this.props.idx)
  },

  renderCountry: function() {
    var class_name, error_field, text;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.country && !this.props.affiliation.country){
      text = this.props.errors.country
      class_name = "error";
      error_field = ( <div key={this.props.id + "_country_errors"} className="errors"> { text } </div> )
    }

    if (this.props.editable) {
      return ([<Select key={this.props.id + "_country_field"} name="country" id={this.props.id + "_country"} ref="affiliationCountry" data-idx={this.props.idx} options={this.props.countryOptions} className={class_name} onChange={this.handleCountryChange} value={this.props.affiliation.country} autoComplete='new-password' />, error_field])
    }
    
    return (<input type="text" name="country" id={this.props.id + "_country"} value={this.props.affiliation.country} disabled="disabled" autoComplete='new-password' />)
  },

  renderCity: function() {
    var class_name, error_field, text;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.city && !this.props.affiliation.city){
      text = this.props.errors.city
      class_name = "error";
      error_field = ( <div key={this.props.id + "_city_errors"} className="errors"> { text } </div> )
    }

    if(this.props.editable) {
      return ([<input key={this.props.id + "_city_field"} type="text" name="city" id={this.props.id + "_city"} ref="affiliationCity" data-idx={this.props.idx} className={class_name} onChange={this.props.handleChange} value={this.props.affiliation.city || ''} autoComplete='new-password' />, error_field])
    }
    return (<input type="text" name="city" id={this.props.id + "_city"} value={this.props.affiliation.city} disabled="disabled" autoComplete='new-password' />)
  },

  renderAddButton: function() {
    if (this.props.idx == this.props.count - 1) {
      return (
        <a className="button secondary add-affiliation" onClick={this.props.handleAffiliationAdd}>
          <i className="fa fa-plus-circle"></i>
          &nbsp;Add Affiliation
        </a>
      )
    }
  },

  renderRemoveButton: function() {
    if (this.props.count > 1) {
      return (
        <a className="button secondary remove-affiliation" data-idx={this.props.idx} data-id={this.props.affiliation.id} onClick={this.props.handleAffiliationRemove}>
          <i className="fa fa-minus-circle"></i>
          &nbsp;Remove Affiliation
        </a>
      )
    }
  },

  renderAddRemoveButtons: function() {
    return (
      <div className="row">
        { this.renderAddButton() }
        { this.renderRemoveButton() }
      </div>
    )
  },

  render: function() {
    var institutionName = this.props.affiliation.institutionName
    if (institutionName === 'Not Selected') {
      institutionName = ''
    }
    var departmentName = this.props.affiliation.departmentName
    if (departmentName === 'Not Selected') {
      departmentName = ''
    }
    return (
      <div className="row">
        <div className="small-12 columns">
          <h4>{ this.affiliationHeaderText() }</h4>
          <Institution
            id={ this.props.affiliation.id }
            idx={ this.props.idx }
            institution_name={ institutionName }
            editable={ this.props.editable }
            handleChange={ this.props.handleChange }
            affiliationNameQueryUrl={ this.props.affiliationNameQueryUrl }
            validInstitution={ this.props.affiliation.validInstitution }
            invalidInstitutionName={ this.props.affiliation.invalidInstitutionName }
            confirmInstitution={ this.props.affiliation.confirmInstitution }
            errors={ this.props.errors }
            toggleSpinner={ this.props.toggleSpinner }
          />

          <Department
            id={ this.props.affiliation.id}
            idx={ this.props.idx }
            institution_name={ institutionName }
            department_name={ departmentName }
            invalidDepartmentName= { this.props.affiliation.invalidDepartmentName }
            confirmDepartment={ this.props.affiliation.confirmDepartment }
            editable={ this.props.editable }
            handleChange={ this.props.handleChange }
            deptAutocompletePath={ this.props.deptAutocompletePath }
            errors={ this.props.errors }
          />
          <div className="row">
            <div className="small-6 columns">
              <label>Country</label>
              { this.renderCountry() }
            </div>
            <div className="small-6 columns">
              <label>City</label>
              { this.renderCity() }
            </div>
          </div>
          { this.renderAddRemoveButtons() }
        </div>
      </div>
    )
  }
})

export default Affiliation;