import React from 'react';
import ChannelEditorPreviewActivity from './ChannelEditorPreviewActivity';
import ChannelEditorApprovalActivity from './ChannelEditorApprovalActivity';
import EditorCheckActivity from './EditorCheckActivity';

const Performance = ({dateRanges, currentUserEmail}) => {
  return (
    <>
      <div className="row">
        <div className="large-6 columns">
          <p>Articles are counted once per category. For example, an article that is submitted to Editor Preview, deferred for content, and then resubmitted will be counted once in the Initial Submissions to Editor Preview column and once in the Deferred in Editor Preview 1x column. If a second content deferral is issued, it will be removed from the Deferred in Editor Preview 1x column and counted in the Deferred in Editor Preview 2x instead.</p>
        </div>
      </div>
      <br/>
      <EditorCheckActivity dateRanges={dateRanges} currentUserEmail={currentUserEmail}/>
      <ChannelEditorPreviewActivity dateRanges={dateRanges} currentUserEmail={currentUserEmail}/>
      <ChannelEditorApprovalActivity dateRanges={dateRanges} currentUserEmail={currentUserEmail}/>
    </>
  )
}
export default Performance;

