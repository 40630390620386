import React from "react";
import SortButton from "./SortButton";
import PerPageViewSelector from "./PerPageViewSelector";

const SortButtons = ({totalCount, pageCountText, page, perPage, setPerPage, setSortDirection, sortDirection, sortBy, setSortBy}) => {

  return (
    <div className="row">
      <div className='large-11 medium-12 small-12 columns small-centered sort-row end'>
        <div className="sort-section">
          <div className="section sort-options">
            <SortButton
              setSortDirection={setSortDirection}
              sortDirection={sortDirection}
              setSortBy={setSortBy}
              sortBy={sortBy}
              sortButtonName={"most_recent_contribution"}
              text={"Recent Contributions"}/>
            <SortButton
              setSortDirection={setSortDirection}
              sortDirection={sortDirection}
              setSortBy={setSortBy}
              sortBy={sortBy}
              sortButtonName={"publication_count"}
              text={"Publications"}/>
            <SortButton
              setSortDirection={setSortDirection}
              sortDirection={sortDirection}
              setSortBy={setSortBy}
              sortBy={sortBy}
              sortButtonName={"reviews_count"}
              text={"Peer Reviews"}/>
            <SortButton
              setSortDirection={setSortDirection}
              sortDirection={sortDirection}
              setSortBy={setSortBy}
              sortBy={sortBy}
              sortButtonName={"siq_rated_count"}
              text={"SIQ Ratings"}/>
          </div>
          <div className="section content-index-results-count">
            <SortButton text={pageCountText} showCarat={false} clickable={false}/>
            <PerPageViewSelector perPage={perPage} setPerPage={setPerPage}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SortButtons;