var createReactClass = require('create-react-class');
import React from 'react';

const Next = createReactClass({
  render: function(){
    return (
      <a disabled={ this.props.disabled } className={ this.props.disabled } onClick={ this.props.handleNext }><i className="fa fa-caret-right"></i></a>
    );
  }
})

export default Next;