import React from 'react';
const PreferredLandingPage = props => {
  return (
    <div>
      <h2 className="landing-header">Purchase Preferred Editing Service</h2>
      <h4 className="reg">“{props.title}”</h4>
      {props.renderPricingInfo()}
      <p>Our Preferred Editing service is required in order to proceed with your manuscript as detailed in your deferral email. Preferred Editing helps to defray the considerable expenses associated with editorial review before and after peer review and copy editing prior to publication. Learn more about our Preferred Editing service and the cost calculations <a href="/cureus_editing_services" target="_blank"><strong>here</strong></a>.</p>
      <p>Language and formatting errors will be fixed by our editorial team with the following exceptions requiring your revision:</p>
      <ul>
        <li>Scientific content issues that can only be addressed by the authors</li>
        <li>Blurry, stretched, or otherwise illegible figures</li>
        <li>Tables with multiple values in the same cell</li>
        <li>References that are missing required information</li>
      </ul>
      <br/>
      {props.renderLandingPageCopy()}
    </div>
  )
}

export default PreferredLandingPage;