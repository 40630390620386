import {Cureus} from '../Cureus';

Cureus.DataTable = {
  persistFilterParam: function(data) {
    var searchString = data.search.value;

    if ("replaceState" in history) {
      if (searchString == undefined || searchString == '') {
          history.replaceState(null, null, location.pathname);
      } else if (searchString != '') {
          history.replaceState(null, null, '?q=' + searchString);
      }
    }
  },

  searchOnCompletion: function(dataTableSelector, searchFieldSelector) {
    var table = $(dataTableSelector).DataTable()
    var input = ( searchFieldSelector ? $(searchFieldSelector) : $(dataTableSelector + "_wrapper input") )
    var timer;

    input.unbind()

    input.bind("input", function() {
      var self = this;
      clearTimeout(timer)
      timer = setTimeout(function() {
        table.api().search(self.value).draw()
      },800)
    })
  }
}