import React, { useState, useEffect } from 'react';
import AdminEditorCheckboxes from './AdminEditorCheckboxes';
import { Cureus } from '../../Cureus';


const EditorPreviewApprovalReminderModal = (props) => {
  const [csrfParam] = useState($("[name='csrf-param']").attr("content"));
  const [csrfToken] = useState($("[name='csrf-token']").attr("content"));

  const [recipients, setRecipients] = useState([]);

  useEffect(() => {
    autoCheckAssignedAdmins()
  }, []);

  const autoCheckAssignedAdmins = () => {
    let assignedAdmins = props.data.channelAdminsData.map( (admin) => {
      if (admin.isAssignedToArticle == true) {
        return admin.email
      }
    })
    let removeUndefined = assignedAdmins.filter(item => item);
    setRecipients(removeUndefined);
  }

  const handleSubmit = () => {
    const { createPath, async } = props;
    const $form = $("#reminder-form");

    $("#form-submit-button").addClass('disabled');

    $.ajax({
      type: "POST",
      url: createPath,
      data: $form.serialize(),
      success: () => {
        $('#email-reminder-modal').foundation('reveal', 'close');
        Cureus.Flash.success("Success! You've sent a reminder to the user.");
      },
      error: () => {
        $('#email-reminder-modal').foundation('reveal', 'close');
        Cureus.Flash.alert("Oops! There was an error with your request.");
      },
    });
  };

  const renderSubmitButton = () => {
    if (props.async) {
      return (
        <a className='button primary' id="form-submit-button" onClick={handleSubmit}>Send</a>
      );
    } else {
      return (
        <input type='submit' value='Send' className='button primary'/>
      );
    }
  };

  const handleSelectAdmin = (e) => {
    let email = e.target.value
    let recipientsClone = recipients.slice();

    if (isAlreadyAdded(recipientsClone, email)) {
      recipientsClone = recipientsClone.filter( (recipientEmail) => {
        return recipientEmail !== email
      })
      setRecipients(recipientsClone);
    } else {
      recipientsClone.push(email);
      setRecipients(recipientsClone);
    }
  }

  const handleSelectAll = (e) => {
    e.preventDefault()
    let recipients = props.data.channelAdminsData.map( (admin) => {
      return admin.email
    })
    setRecipients(recipients);
  }

  const isAlreadyAdded = (recipients, email) => {
    let filtered = recipients.filter( (recipient) => {
      return recipient === email
    })
    return filtered.length > 0
  }

  const recipientEmails = () => {
    return recipients.map( (recipient) => {
      return recipient
    }).join(', ')
  }

  return (
    <div className="reveal-modal small" data-reveal id="email-reminder-modal">
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <h4>Send an Email Reminder to the Channel Admins and Editors</h4>
          <form acceptCharset="UTF-8" method="post" action={props.createPath} id="reminder-form">
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name={csrfParam} value={csrfToken} />
            <input type='hidden' name='templateName' value={props.templateName}/>
            <input type='hidden' name='templateData[resource_ids]' value={props.templateData.resource_ids}/>

            <AdminEditorCheckboxes
              admins={props.data.channelAdminsData}
              handleSelectAdmin={handleSelectAdmin}
              recipients={recipients}
            />

            <button onClick={handleSelectAll} className="button primary small select-all">Select All</button>

            <label>Recipients</label>
            <input type='text' name='templateData[recipients]' value={recipientEmails()} readOnly/>

            <label>From</label>
            <input type='text' value={props.data.from} readOnly/>

            <label>Subject</label>
            <input type='text' defaultValue={props.data.subject} name='templateData[subject]'/>

            <label>Message</label>
            <textarea defaultValue={props.data.message} rows='8' className='large-text-pizzas' name='templateData[message]'/>

            <input type='hidden' name='templateData[article_id]' value={ props.data.articleId }/>

            <div className='modal-footer'>
              <a className='cancel-modal button secondary'>Cancel</a>
              {renderSubmitButton()}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditorPreviewApprovalReminderModal;
