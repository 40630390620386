import React, {useState, useEffect} from 'react';
import Label from '../Collections/Label';
import RadioContainer from '../Shared/RadioContainer';
import RejectModal from './RejectModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';
import ApproveModal from './ApproveModal';

const Show = ({application, reasons}) => {

  const [applicationState, setApplicationState] = useState(application.state)

  const openRejectModal = () => {
    Modal.open({
      children: 
        <RejectModal
          applicationId={application.id}
          setApplicationState={setApplicationState}
          reasonsData={reasons}
        />
    })
  }

  const openApproveModal = () => {
    Modal.open({
      children:
        <ApproveModal 
          applicationId={application.id}
          setApplicationState={setApplicationState}
        />
    })
  }

  return (
    <div className="large-10 large-offset-1 medium-12 small-12 columns application-form-container">
      <div className="row">
        <div className="large-12 columns header-container">
          <img src="https://public.cureus.com/collections/application-form/header-img.png"/>
          <h1>Submit your Cureus Collection proposal</h1>
          <p>Would you like to serve as a guest editor for a collection of peer-reviewed articles focused on a specific topic? Submit your detailed proposal for consideration. Our editorial department will review your eligibility and proposed topic and reply via email.</p>
        </div>
      </div>
      <div className="row small-collapse">
        <div className="large-12 columns">
          <form acceptCharset='UTF-8' className='custom' method='post'>
            <div className="row">
              <div className="large-6 medium-6 small-12 columns">
                <input type="text" placeholder="First name" name='first_name' value={application.first_name} disabled/>
              </div>
              <div className="large-6 medium-6 small-12 columns">
              <input type="text" placeholder="Last name" name='last_name' value={application.last_name} disabled/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <input type="email" placeholder="Email" name='email' value={application.email} disabled/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <input type="text" placeholder="Affiliation (Department, Institution)" name='affiliation' value={application.affiliation} disabled/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <input type="text" placeholder="Position" name='position' value={application.position} disabled/>
              </div>
            </div>
            <div className="row">
              <Label label={"Do you have a medical degree?"} />
              <RadioContainer label={"Yes"} value={true} selectedValue={application.medical_degree} handleChange={'disable'} name={"medical_degree"}/>
              <RadioContainer label={"No"} value={false} selectedValue={application.medical_degree} handleChange={'disable'} name={"medical_degree"}/>
            </div>
            <div className="row">
              <div className="small-12 columns">
                  <Label label={"How many articles have you published in PubMed-Central indexed journals?"} />
                  <input type="text" value={application.articles_count} disabled/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <Label label={"Please provide links to any relevant external profile pages describing your experience and publishing history."} />
                <textarea placeholder="For example, your ResearchGate profile, institution/university profile page, etc." name='external_links' value={application.external_links} disabled/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <Label label={"Collection title"} />
                <input type="text" placeholder="Enter your proposed title." name='title' value={application.title} disabled/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <Label label={"Collection description"} />
                <textarea placeholder="Enter a brief description of the collection aims and scope (minimum 50 words)." name='description' value={application.description} disabled/>
              </div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <Label label={"Specialty"} />
                <input type="text" value={application.specialty} disabled/>
              </div>
            </div>
            <div className="row">
              <Label label={"Have you previously guest edited a collection or special issue for Cureus or another journal?"} />
              <RadioContainer label={"Yes"} value={true} selectedValue={application.previous_collection} handleChange={'disable'} name={"previous_collection"}/>
              <RadioContainer label={"No"} value={false} selectedValue={application.previous_collection} handleChange={'disable'} name={"previous_collection"}/>
              {application.previous_collection === true &&
                <div className="row">
                  <div className="small-12 columns">
                  <input type="text" placeholder="Please provide a link to the collection/special issue." name="previous_collection_link" value={application.previous_collection_link} disabled/>
                  </div>
                </div>
              }
            </div>
            {applicationState == 'new' && <div className="row bottom-section admin-right">
              <a className="button secondary" onClick={openRejectModal}>Reject</a>
              <a className="button" onClick={openApproveModal}>Accept</a>
            </div>}
          </form>
        </div>
    </div>
  </div>
  )
}

export default Show;