var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';
import { Cureus } from '../../Cureus';
import Modal from '../Modal';
import AuthorsAndReviewersBanModal from '../Shared/AuthorsAndReviewersBanModal';

const Main = createReactClass({
  getInitialState: function() {
    return {
      checked: [],
      disabled: 'disabled',
      author_list: [],
      checkedAllActive: false,
    }
  },

  componentDidMount: function (){
    this.fetchAuthors()
    if(this.props.spinnerSelector) {
      $(this.props.spinnerSelector).remove();
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (prevState.checked.length !== this.state.checked.length) {
      if (this.state.checked.length) {
        this.setState({disabled: ''})
      } else {
        this.setState({disabled: 'disabled'})
      }
    }
  },

  fetchAuthors: function() {
    var self = this;
    $.ajax({
      type: "get",
      url: `/admin/${self.props.resourceType}/${self.props.resource_id}/author_stats_table`,
      dataType: "json",
      success: function(data) {
        self.setState({author_list: data.author_list})
      }
    })
  },

  banAuthors: function(banReason) {
    var self = this;
    $.ajax({
      type: "PUT",
      url: self.props.authorshipsBanPath,
      dataType: "json",
      data: { author_ids: this.state.checked, message: banReason },
      success: function(data) {
        self.setState({
          author_list: data.author_list,
          checked: [],
        });
        Modal.close();
      }
    })
  },

  openAdminBanModal: function() {
    Modal.open({
      children:
        <AuthorsAndReviewersBanModal
          banHandler={this.banAuthors}
        />
    })
  },

  renderAuthorInfo: function() {
    var self = this;
    return self.state.author_list.map(function(author) {
      return <Row
        author={author}
        key={"adminAuthorRow" + author.id}
        currentUser={self.props.current_user}
        checked={self.state.checked}
        updateCheckedState={self.updateCheckedState}
        checkedAllActive={self.state.checkedAllActive}
        confirmed={author.confirmed}
        flagged={author.flagged}
        />
    })
  },

  sendReminder: function() {
    var self = this;
    self.setState({disabled: "disabled"})
    $.ajax("/admin/" + self.props.resourceType + "/" + self.props.resource_id + "/authorships/remind_authors", {
      dataType: "JSON",
      data: {authorIds: self.state.checked},
      type: "PUT"
    }).done(function(res) {
      self.setState({disabled: "disabled", checked: []})
      Cureus.Flash.success('You have resent coauthor confirmation instructions to: ' + res.names)
    }).always(function() {
      $(".author-checks").prop({checked:false})
      $("html, body").animate({scrollTop: 0}, 1000);
    })
  },

  updateCheckedState: function(e, authorId) {
    var newChecked = this.state.checked
    if (e.target.checked) {
      newChecked.push(authorId)
      this.setState({checked: newChecked, disabled: ""})
    } else {
      var idx = newChecked.indexOf(authorId)
      newChecked.splice(idx, 1)
      this.setState({checked: newChecked})
      if (newChecked.length === 0) {
        $(".author-checks").prop({checked:false})
        this.setState({disabled: "disabled"})
      }
    }
  },

  allUnconfirmedAuthors: function() {
    return this.state.author_list.filter(function(author) {
      return !author.confirmed && !author.banned
    }).map(function(author){
      return author.id
    })
  },

  checkAll: function(e) {
    if (e.target.checked) {
      $(".author-checks").prop({checked: true})
      this.setState({checked: this.allUnconfirmedAuthors(), checkedAllActive: true});
    } else {
      $(".author-checks").prop({checked: false})
      this.setState({disabled: 'disabled', checked: [], checkedAllActive: false})
    }
  },

  renderBanAuthorsButton: function() {
    if (this.props.canBan && !this.state.checkedAllActive && this.state.checked.length > 0) {
      return <a onClick={this.openAdminBanModal} className={"button alert tiny ban-button"}>BAN SELECTED AUTHORS</a>
    }
  },

  renderResendConfirmationButton: function() {
    let checkedConfirmation = this.checkConfirmation();
    if (checkedConfirmation) {
      return <a className={"button primary tiny " + this.state.disabled} onClick={this.sendReminder}>Resend Confirmation</a>
    } else {
      return <a className={"button primary tiny disabled"} onClick={this.sendReminder}>Resend Confirmation</a>
    }
  },

  checkConfirmation: function() {
    let authors = this.state.author_list;
    let checkedIds = this.state.checked;
    for (const id of checkedIds) {
      const hasMatchingAuthor = authors.some(author => id === author.id && !author.confirmed);
      if (!hasMatchingAuthor) {
        return false;
      }
    }
    return true;
  },

  render: function (){
    return (
      <div>
        <div className="right-button-container">
          <h5>{this.props.resourceType == 'collections' ? "Collection Guest Editors" : "Authors"}</h5>
          {this.renderBanAuthorsButton()}
          {this.renderResendConfirmationButton()}
          <a style={{'marginLeft': '15px'}} href={`/publish/${this.props.resourceType}/${this.props.resource_id}/authors/edit`} target="_blank" className={"button primary tiny"}>{this.props.resourceType == 'collections' ? "Edit Editors" : "Edit Authors"}</a>
        </div>
      <div className="table-wrapper">
        <table className="author-stats-board">
          <thead>
            <tr>
              <th width="5%" className="text-center"><input className="th author-checks" type="checkbox" onClick={this.checkAll}/></th>
              <th width="20%">Editor Name</th>
              <th width="20%">Email Address</th>
              <th className="text-center" width="11%">In Progress</th>
              <th className="text-center" width="11%">Deferred</th>
              <th className="text-center" width="11%">Desk Rejected</th>
              <th className="text-center" width="11%">Blocked</th>
              <th className="text-center" width="11%">Published</th>
            </tr>
          </thead>
          { this.renderAuthorInfo() }
        </table>
      </div>
    </div>
    )
  }
});

export default Main;
