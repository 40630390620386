import React from 'react';
import Authorship from './Authorship';

const Index = ({authorships}) => {

  return (
    <div className="author-contributions-publishing">
      {authorships.map((authorship) => {
        return (
          <Authorship 
            contributions={authorship.contributions}
            fullName={authorship.name}
            id={authorship.id}
            position={authorship.position}
          />
        )
      })}
    </div>
  )
}

export default Index;