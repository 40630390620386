import React from 'react';
import Modal from '../../Modal';
import RatingModal from '../../Dashboard/ReviewsDashboard/RatingModal';

const PreviewPublishButton = ({articleId, rateables, ratingsPath}) => {
  const openPublishModal = () => {
    Modal.open({
      children: <RatingModal
        rateables={ rateables }
        articleId={ articleId }
        ratingsPath={ ratingsPath }
      />
    });
  }

  return(
      <a className="button success small" onClick={openPublishModal}>Publish</a>
  )
}

export default PreviewPublishButton;