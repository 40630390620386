import React from 'react';

const CitationModal = ({article, fireAmplitude}) => {
  const renderDownloadLink = (text) => {
    return (
      <div className='row download' onClick={() => fireAmplitude("citationDownloaded", {article_id: article.id, tool: text})}>
          <strong>{text}</strong>
          <a href={`/articles/${article.id}/download_ris_file`} className="button primary">Download</a>
      </div>
    )
  }

  return (
    <div id="citation-modal" className="reveal-modal middling row" data-reveal style={{ minHeight: 'auto' }}>
      <div className="modal-body">
        <div className='row modal-header'>
          <div className='citation-tool-header'>
            <img className='icon' src="https://public.cureus.com/article-show-redesign/citation-sharebar-icon.png" />
            <b>Download citation</b>
          </div>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className='row citation-body-bold'>
          <b>Download to Citation Manager</b>
        </div>

        <div className='row citation-body'>
          {article.long_citation}
        </div>

        {renderDownloadLink("Endnote")}
        {renderDownloadLink("Zotero")}
        {renderDownloadLink("Mendeley")}

        <div className='row citation-body-bold'>
          <b>Help</b>
        </div>

        <div className='row citation-body'>
          If you are unable to import the citation, please click below to contact technical support. (Links go to external sites.)
        </div>

        <div className='row buttons'>
          <a target="_blank" href="https://support.clarivate.com/Endnote/s/?language=en_US" className="button stencil-blue">EndNote</a>
          <a target="_blank" href="https://www.zotero.org/support/" className="button stencil-blue">Zotero</a>
          <a target="_blank" href="https://www.mendeley.com/guides/mendeley-reference-manager/" className="button stencil-blue">Mendeley</a>
        </div>

      </div>
    </div>
  )
}

export default CitationModal;