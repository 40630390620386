import React from 'react';

const TeamMemberCard = ({ member }) => {
  let specialtyName = member.specialty_name;
  if (specialtyName.includes("I'm not a medical professional.")) {
    specialtyName = specialtyName.split("|").filter((specialty) => !specialty.includes("I'm not a medical professional.")).join("|");
  }

  return(
    <div className={"board-container " + member.foundation_class}>
      <div className={"board-member"}>
        <div className="avatar">
          <a href={member.path}>
            <img src={member.avatar_profile_url} alt={member.full_name} title={member.full_name}/>
          </a>
        </div>
        <h4>{member.full_name}</h4>
        <span className="editor-in-chief">{member.title}</span>
        <div className="specialty truncate">
          {specialtyName}
        </div>
        <div className="affiliation truncate">
          {member.affiliation_name}
        </div>
        <a href={member.path} className={"button view-profile"}>View profile</a>
      </div>
    </div>
  )
}

export default TeamMemberCard;