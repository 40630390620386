var createReactClass = require('create-react-class');
import React from 'react';

const ArticleNotifications = createReactClass({
  renderArticleDetails: function() {
    if (this.props.article.reviewsState && !this.props.article.wasDeferred ) {
      return <div dangerouslySetInnerHTML={{__html: this.props.article.reviewerStatusEmphasisBox}}/>
    } else {
      return <div/>
    }
  },

  render: function() {
    return (
      <div>
        {this.renderArticleDetails()}
      </div>
    )
  }
})

export default ArticleNotifications;