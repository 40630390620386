import React, { useState, useEffect, useRef } from 'react';
import {Cureus} from '../../Cureus';

const Hero = ({collection, isSubmissionOpen, isCureusAdminCreated, collectionUrl, collectionType, subscribePath, currentUser, emailListId, subscribed, collectionPath, startDate, endDate, collectionDisplayName}) => {

  const [isSubscribed, setIsSubscribed] = useState(subscribed);
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsRef = useRef(urlParams);
  const [imgSrc, setImgSrc] = useState("https://public.cureus.com/collections/show/notification.png");

  useEffect(() => {
    collectionLinkCopy();
    handleWasReturnedTo();
  }, [])

  const collectionLinkCopy = () => {
    document.getElementById('collection-copy').addEventListener('click', function(e) {
      navigator.clipboard.writeText(collectionUrl);
      Cureus.Flash.success('Copied to Clipboard!');
    })
  }

  const renderCreatedby = () => {
    if(isCureusAdminCreated) {
      return <div className='cureus-collection'>Cureus Curated Collection</div>
    }
  }

  const renderCallForSubmissions = () => {

    let toolTip = false;
    if(isSubmissionOpen){
      toolTip = "Submission window: Open";
    }
    else {
      toolTip = "Submission window: Closed";
    }
    
    if(collection.start_date && collection.end_date) {
      toolTip = `Submission window: ${startDate} - ${endDate}`
    }

    const imageSrc = isSubmissionOpen 
      ? "https://public.cureus.com/collections/show/unlock.png"
      : "https://public.cureus.com/collections/show/lock.png";


    if(collectionType !== 'CureusUCollection' && collectionType !== 'CustomCollection') {
      return (
        <div className='call-holder'>
          <div className='call-for-submissions tip-top' data-tooltip="" title={toolTip}>
            <img src={imageSrc}/>
            <div>{collectionDisplayName}</div>
          </div>
        </div>
      )
    }
  }

  const renderParticipationButton = () => {

    const participationButton = (
      <a className='participate primary button' href={`/publish/articles/new?collection_id=${collection.id}`}>
        <div>Submit to Collection </div>
        <img src="https://public.cureus.com/collections/show/edit.png"/>
      </a>
    )

    if(collectionType === 'CureusUCollection') {
      return
    }

    if(isSubmissionOpen) {
      return participationButton;
    }
    else {
      return (
        <a className='participate primary button disabled' >
          <div>Submissions Closed</div>
        </a>
      )
    }
  }

  const isUserLogginIn = () => {
    if(!currentUser) {
      window.location.href = `/users/sign_in?return_to=${collectionPath}?redirected=true`;
      Cureus.Flash.alert('Please log in to subscribe to a collection');
    }
    return currentUser;
  }

  const wasReturnedTo = () => {
    return urlParamsRef.current.get("redirected");
  }

  const handleWasReturnedTo = () => {
    if(wasReturnedTo()) {
      subscribeToCollection();
    }
  } 

  const fireAmplitude = () => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: 'collectionSubscribeClicked', 
              user_id: currentUser ? currentUser.id : null,
              collection_id: collection.id
            }
    })
  }

  const handleSubscribe = () => {
    fireAmplitude()
    const currentUser = isUserLogginIn();
    if (currentUser) {
      subscribeToCollection();
    }
  }

  const subscribeToCollection = () => {

    $.ajax({
      url: subscribePath,
      method: 'POST',
      dataType: 'json',
      data: {emailListId: [emailListId], isSubscribed: isSubscribed, collectionId: collection.id},
      success: function(res) {
        setIsSubscribed(res.subscribed);
        if(res.subscribed) {
          setSubscribeTooltip('Unsubscribe from this collection.')
          Cureus.Flash.success('You have subscribed to this collection');
          changeUrl();
        } else {
          setSubscribeTooltip('Subscribe to receive a weekly email update on this collection.')
          Cureus.Flash.alert('You have unsubscribed from this collection');
        }
      }
    })
  }

  const changeUrl = () => {
    window.history.replaceState(null, '', window.location.pathname);
  }

  const setSubscribeTooltip = (text) => {
    Foundation.libs.tooltip.getTip($('#bell-tooltip')).contents().first().replaceWith(text);
  }

  const handleMouseEnter = () => {
    setImgSrc("https://public.cureus.com/collections/show/notification-hover.png");
  };

  const handleMouseLeave = () => {
    setImgSrc("https://public.cureus.com/collections/show/notification.png");
  };

  const renderNotificationBell = () => {
    let toolTipTitle;

    if(subscribed) {
      toolTipTitle = "Unsubscribe from this collection."
    } else { 
      toolTipTitle = "Subscribe to receive a weekly email update on this collection."
    }
    return (
      <a onClick={() => {handleSubscribe()}}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="notification-bell tip-top" id="bell-tooltip" data-tooltip="" title={toolTipTitle}>
            <img src={imgSrc} />
        </div>
      </a>
    )
  }

  const renderCollectionType = (collectionType) => {
    if(collectionType === 'CustomCollection') {
      return (
        <div className='type-holder'>
          <div className='collection-type customcollection '>
            <img src="https://public.cureus.com/collections/show/star.png"/>
            <div>Custom</div>
          </div>
        </div>
      ) 
    }
    else if(collectionType === 'CureusUCollection') {
      return (
        <div className='type-holder'>
          <div className='collection-type cureusucollection'>
            <img src="https://public.cureus.com/collections/show/cureusu.png"/>
            <div>Cureus U</div>
          </div>
        </div>
      )
  }
}

  return (
    <div className='hero-container'>
      <img className="backdrop" src={collection.banner_image.hero.url}/>
      <div className='hero-overlay'></div>
      <div className='word-container'>
        <div className='top-links'>
          {renderCreatedby()}
          {renderCallForSubmissions()}
          {renderCollectionType(collectionType)}
        </div>
        <div className='title-holder'>
          <h1 className='title'>{collection.title}</h1>
        </div>
        <div className='bottom-links'>
          <div className='left'>
            {renderParticipationButton()}
            {renderNotificationBell()}
          </div>
          <div className='right'>
            <div className='share-holder'>
              <div className='share-text'>Share</div>
              <a className='fb icon st-custom-button icon facebook-sharebar has-tip tip-top' data-tooltip="" title="Share on Facebook" data-network="facebook" data-url={collectionUrl}>
                <img src="https://public.cureus.com/collections/show/socialfb.png"  />
              </a>
              <a className='twitter icon logo st-custom-button tip-top' data-network="twitter" data-tooltip="" title="Share on Twitter" data-url={collectionUrl}>
                <img src='https://public.cureus.com/collections/show/socialtwit.png'/>
              </a>
              <a className='linkedin icon logo st-custom-button tip-top' data-network="linkedin" data-url={collectionUrl} data-tooltip="" title="Share on LinkedIn">
                <img src='https://public.cureus.com/collections/show/sociallinked.png'/>
              </a>
              <a className='copy icon tip-top' id='collection-copy' data-tooltip="" title="Copy URL">
                <img src='https://public.cureus.com/collections/show/link.png'/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default Hero;