var createReactClass = require('create-react-class');
import React from 'react';
import ReferencesList from './ReferencesList';
import Importer from './Importer';
import NewReference from './NewReference';

const Main = createReactClass({
  getInitialState: function() {
    return {
      references: null,
      referenceFields: [],
      selected: '',
      numberOfBadDois: 0,
      editingList: false
    }
  },

  componentDidMount: function() {
    this.updateState();

  },

  updateState: function() {
    var self = this;
    $.get(self.props.indexReferencesPath, function(data) {
      self.setState({
        references: data,
        numberOfBadDois: self.updateDoiState(data),
        editingList: false
      }, function() {self.buttonValidator();});
    }, "json").done(function(){
      self.buttonValidator();
    })
  },

  disableEditing: function() {
    this.setState({editingList: true})
  },

  updateDoiState: function(data) {
    var numberOfBadDois = 0;
    var self = this;
    data.forEach(function(ref) {
      if (self.doiMissing(ref)) { numberOfBadDois++ }
    });
    return numberOfBadDois;
  },

   doiMissing: function(reference) {
    var fields = reference.fields.filter(function(field) {
      return field.name === 'doi' || field.name === 'url';
    });
    if (fields.length && !reference.omit_doi && fields.every(function(el) { return (!el.value || !el.value.length) })) {
      return true;
    } else {
      return false;
    }
  },

  showConvertPanel: function(e){
    $('.add-reference-wrap').hide();
    $('.reference-import-wrap').fadeIn();
    this.setState({selected: ''});
    e.preventDefault();
  },

  showAddPanel: function(){
    $('.reference-import-wrap').hide();
    $('.add-reference-wrap').fadeIn();
  },

  hidePanels: function(e){
    $('.reference-import-wrap').hide();
    $('.add-reference-wrap').hide();
    this.setState({selected: ''});
  },

  handleChange: function(val) {
    this.showAddPanel();
    var referenceFields = this.props.fieldData[val];
    this.setState({referenceFields: referenceFields, selected: val});
  },

  buttonValidator: function() {
    (this.state.references && this.state.references.length > 0 && this.state.numberOfBadDois === 0) ? this.enableButton() : this.disableButton();
  },

  enableButton: function() {
    $(this.props.button).attr("disabled", false);
  },

  disableButton: function() {
    $(this.props.button).attr("disabled", true);

  },

  renderMaxReferencesMessage: function() {
    if(this.props.dontShowMaxReferences) {
      return;
    }
    if (this.state.references.length <= this.props.maxReferences) {
      return
    } else {
      return (
        <p style={{color: '#CA3145'}}>
          <strong>You have exceeded the reference limit for free articles. This article will not be eligible for free publication if submitted with the current number of references.</strong>
        </p>
      )
    }
  },

  renderDoiMessage: function() {
    if (this.state.numberOfBadDois) {
      return <span className='pull-right overall-message missing-doi'>Please enter a DOI or URL for each reference.</span>
    }
  },

  renderEmptyList: function() {
    if (this.state.references.length === 0 && this.state.editingList) {
      return (
        <div className='article-content-references-table-wrap'>
          <div className='spinner-container'>
            <img src='https://public.cureus.com/ajax-loader.gif'/>
          </div>
          <a className='remove-selected-references'>
            Remove Selected
          </a>
          <table id='article-content-references-table' style={{width: "100%"}}>
            <thead>
              <tr>
                <th className='table-draggable'></th>
                <th className='table-header-select-all' style={{width: "40px"}}>
                  <input ref='selectAll' type='checkbox'/>
                </th>
                <th className='table-header-number' style={{width: "50px"}}>#</th>
                <th className='table-header-details'>Reference details</th>
                <th className='table-header-actions' style={{width: "88px"}}></th>
              </tr>
            </thead>
          </table>
        </div>
      )
    }
  },

  render: function() {
    var self = this;
    if(self.state.references) {
      if(self.state.references.length > 0){
        var referenceList =  <ReferencesList
                                references={ self.state.references }
                                onDelete={ self.updateState }
                                onUpdate={ self.updateState }
                                fieldData={ self.props.fieldData }
                                optionsForReferenceSelect={ self.props.optionsForReferenceSelect }
                                editingList={self.state.editingList}
                                disableEditing={self.disableEditing}
                                bulkDestroyPath={self.props.bulkDestroyPath}
                                articleId={self.props.articleId}
                                isEditor={self.props.isEditor}
                                fetchParsedReferencePath={self.props.fetchParsedReferencePath}
                                revertReferencePath={self.props.revertReferencePath}
                                parsedReferences={self.props.parsedReferences}
                                />
      }
      return (
        <div>
          {self.renderMaxReferencesMessage()}
          <div className="row reference-panel-buttons">
            <div className="large-6 columns">
              <div className="centered references-convert-panel">
                <a href="#" onClick={ self.showConvertPanel } className="button large primary">Launch reference converter</a>
              </div>
            </div>
            <div className="large-6 columns">
              <div className="centered references-add-panel">
                <Select ref='select' name='reference[type]' value={ self.state.selected } options={ self.props.optionsForReferenceSelect } onChange={ self.handleChange } placeholder='Select reference type to add' clearable={ false } searchable={ false } className="select-reference button secondary large"/>
              </div>
            </div>
          </div>
          <Importer
            importReferencesPath={ self.props.importReferencesPath }
            handleClose={ self.hidePanels }
            onDelete={ self.updateState }
            disableEditing={self.disableEditing}
            />
          <NewReference
            referenceFields={ self.state.referenceFields }
            createReferencePath={ self.props.createReferencePath }
            onReferenceAdd={ self.updateState }
            selected={ self.state.selected }
            handleClose={ self.hidePanels }/>
          { self.renderEmptyList() }
          { referenceList }
          {this.renderDoiMessage()}
        </div>
      )
    } else {
      return (
        <h4> Loading Reference Tool ... </h4>
      )
    }
  }
});

export default Main;