import React, { useState } from 'react';
import _debounce from 'lodash/debounce';

const InstitutionInput = ({setInstitution, institution}) => {

  const [predictiveSearchResults, setPredictiveSearchResults] = useState([])
  const [showPredictiveSearchDropDown, setShowPredictiveSearchDropDown] = useState(false)

  const [debounce] = useState((term) => _debounce((term) => { getPredictiveSearchResults(term)}, 500));

  const handleChange = (e) => {
    let inst = e.target.value
    setInstitution(inst)
    debounce(inst)
  }

  const getPredictiveSearchResults = (inst) => {
    $.ajax({
      method: 'GET',
      url: "/institutions/autocomplete_suggestions",
      data: {term: inst},
      dataType: 'json',
      success: function(res) {
        setPredictiveSearchResults(res)
      },
      error: function(res) {
      }
    }, 'json');
  }

  const renderPredictiveSearchResults = () => {
    if (showPredictiveSearchDropDown && predictiveSearchResults.length > 0 && institution != "") {
      return (
        <div className="institution-search-results">
          {predictiveResults()}
        </div>
      )
    }
  }

  const predictiveResults = () => {
    return predictiveSearchResults.map((result) => {
      return (
        <p key={result.name} >
          <a onClick={() =>{updateInstitutionInput(result.name)}}>{result.name}</a>
        </p>
      )
    })
  }

  const closeDropdown = () => {
    setTimeout(() => {setShowPredictiveSearchDropDown(false)}, 100)
  }

  const updateInstitutionInput = (inst) => {
    setInstitution(inst)
    setShowPredictiveSearchDropDown(false)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      closeDropdown();
    }
  };
  return (
    <div>
      <input
        onKeyDown={handleKeyDown}
        onBlur={closeDropdown}
        onFocus={() => {setShowPredictiveSearchDropDown(true)}}
        className="institution-input"
        type="text"
        value={institution}
        onChange={(e) => {handleChange(e)}}
        placeholder="Affiliation"/>
      {renderPredictiveSearchResults()}
    </div>
  );
};

export default InstitutionInput;