import React, { useState, useEffect } from 'react';
import { Cureus } from '../../../Cureus';

const AboutContactModal = ({handleAddWidget, widgetAboutSidebarId, widget={}, handleUpdateWidget}) => {
  const [blurb, setBlurb] = useState(widget.blurb || '')

  useEffect(() => {
    new Cureus.CKEditor('channel-about-contact-blurb', {
      handle_change: updateBlurb, 
      editor_type: 'static_text_with_color', 
      ckeditor: {height: 250}, 
      placeholder: "Example: <br/> Channel Administrator <br/> Frank White <br/> frank.white@university.edu",
      initialValue: blurb
    })
  }, [])

  const updateBlurb = () => {
    setBlurb(CKEDITOR.instances["channel-about-contact-blurb"].getData())
  }

  const shouldDisable = () => {
    return blurb.length > 0 ? '' : 'disabled';
  }

  const handleAddWidgetWrapper = () => {
    let params;
    params = {
      widget: {
        type: "Widget::AboutContact",
        parent_id: widgetAboutSidebarId,
        blurb: blurb,
      }
    }
    if(handleAddWidget) {
      handleAddWidget(params, false)
    } else {
      handleUpdateWidget(widget.id, params, false)
    }
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Contact Info</h5>
            <hr/>
          </div>
          <p>Add contact information for your channel admins and editors here so that authors and reviewers can call or email you with questions or concerns.</p>
          <div className="row">
            <div className="small-12 columns">
              <textarea id="channel-about-contact-blurb" name="channel-about-contact-blurb" style={{height: '250px'}} />
            </div>
          </div>
          <div className='modal-footer text-center'>
            <a className={"button primary " + shouldDisable()} onClick={handleAddWidgetWrapper}>Add</a>
          </div>
        </div>
      </div>
    </div>
  )
}


export default AboutContactModal;