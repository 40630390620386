import React from "react";

const DropDown = ({list, updateList, dropdownRef, title}) => {
  const handleChange = (e) => {
    let val = e.target.value
    let updatedlist = [...list]
    let listObject = getUpdatedListObject(updatedlist, val)
    listObject.checked = !listObject.checked
    updateList(updatedlist)
  }

  const handleAllButtonChange = (e) => {
    if (e.target.checked) {
      if (hasUncheckedBoxes()) {
        selectAllCheckbox()
      }
    } else {
      if (!hasUncheckedBoxes()) {
        unselectAllCheckbox()
      }
    }
  }

  const endClass = (i) => {
    return i == list.length + 1 ? 'end' : ''
  }

  const hasUncheckedBoxes = () => {
    return list.some((el) => { return !el.checked })
  }

  const selectAllCheckbox = (e) => {
    document.querySelectorAll(`.${title}-checkbox`).forEach( (el) => {
      if (!el.checked) { el.click() }
    })
  }

  const unselectAllCheckbox = (e) => {
    document.querySelectorAll(`.${title}-checkbox`).forEach( (el) => {
      if (el.checked) { el.click() }
    })
  }

  const allSelected = () => {
    return list.every((el) => { return el.checked })
  }

  const renderAllButton = () => {
    return (
      <div className={`columns small-12`}>
        <input onChange={handleAllButtonChange} value='' checked={allSelected()} id='all' type="checkbox" className="css-checkbox"/>
          <label htmlFor='all'><span>All</span></label>
      </div>
    )
  }

  const valueName = (listObject) => {
    return (title == "Country" ? listObject.alpha3 : listObject.name)
  }

  const getUpdatedListObject = (updatedlist, val) => {
    return updatedlist.find((el) => { return valueName(el) == val })
  }

  const renderCheckboxes = () => {
    // Split the list array into two parts
    let middleIndex = Math.ceil(list.length / 2);
    const firstColumnItems = list.slice(0, middleIndex);
    const secondColumnItems = list.slice(middleIndex);

    return (
      <>
        <div className="column small-12 medium-6">
          {renderAllButton()}
          {firstColumnItems.map((listObject, i) => {
            return renderCheckBox(listObject, i)
          })}
        </div>
        <div className="column small-12 medium-6">
          {secondColumnItems.map((listObject, i) => {
            return renderCheckBox(listObject, i)
          })}
        </div>
      </>
    )
  }

  const renderCheckBox = (listObject, i) => {
    return (
      <div key={listObject.name} className={`columns small-12 ${endClass(i)}`}>
        <input onChange={handleChange} value={valueName(listObject)} id={listObject.name} checked={listObject.checked} type="checkbox" className={`${title}-checkbox css-checkbox`} />
        <label htmlFor={listObject.name}><span>{listObject.name}</span></label>
      </div>
    )
  }

  return (
    <div ref={dropdownRef} className="row specialty-dropdown checkbox-container">
      <h4>{title}</h4>
      {renderCheckboxes()}
    </div>
  )
}

export default DropDown