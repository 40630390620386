var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const KeywordAutoSuggester = createReactClass({
  componentDidMount: function (){
    var $input;
    $input = $(this.refs.autosuggest).find("input");
    Cureus.Utils.KeywordAutocomplete.setup($input, {
      autocompleteUrl: "/keywords/autocomplete_suggestions"
    });
  },

  render: function (){
    var self, children_with_props;

    self = this;
    return (
      <div ref="autosuggest">
        { this.props.children }
      </div>
    )
  }
});

export default KeywordAutoSuggester;
