import React, {useState, useEffect} from 'react';
import Modal from '../Modal';
import CitationModal from './Modal/CitationModal';
import LikeButton from '../Shared/LikeButton';

const ShareBar = ({article, preview, fireAmplitude, addPdfModalListener, page, currentUserId, initialLikeId, initialLikeCount, setArticleLikeId, setArticleLikeCount, showLikeOnShareBar, showShareBarFixed}) => {
  const target = preview ? '_self' : '_blank'

  useEffect(() => {
    addPdfModalListener("pdf-share-bar")
  }, []);

  const openCitationModal = () => {
    fireAmplitude('citationModalClicked', {article_id: article.id})
    Modal.open({
      children: <CitationModal
                  article={article}
                  fireAmplitude={fireAmplitude}
                />
    });
  }

  const isPdfDownloadDisabled = () => {
    if (preview) {
      return !article.title || !article.abstract || !article.categories.length || !article.keywords.length;
    }
    return false;
  }

  const renderPdfIcon = () => {
    const disabled = isPdfDownloadDisabled();
    const iconSrc = disabled ? "https://public.cureus.com/article-show-redesign/pdf-icon-disabled.png" : "https://public.cureus.com/article-show-redesign/pdf-icon-2.png";
    const iconTitle = disabled ? "PDF Download Disabled" : "Download PDF"

    return (
      <img
        className="st-custom-button icon pdf has-tip tip-top"
        data-tooltip=""
        title={iconTitle}
        src={iconSrc}
      />
    )
  }

  return (
    <div className={"share-bar " + (showShareBarFixed ? 'fixed-bar ' : '') + (preview ? 'preview-mode' : '')}>
      {!preview && page == 'article' && showLikeOnShareBar && <LikeButton key={"topSection" + initialLikeId}
        currentUserId={currentUserId}
        likableId={article.id}
        initialLikeId={initialLikeId}
        initialLikeCount={initialLikeCount}
        setArticleLikeCount={setArticleLikeCount}
        setArticleLikeId={setArticleLikeId}
        containerType="shareBar"
        likableType={"Article"}
      />}
      <div className="icon-background">
        <a href={isPdfDownloadDisabled() ? "#" : article.pdf_link} target={target} id="pdf-share-bar">
          {renderPdfIcon()}
        </a>
      </div>
      {!preview && <>
        <div className="icon-background">
          <img className="st-custom-button icon has-tip tip-top" data-tooltip="" title="Download citation" src="https://public.cureus.com/article-show-redesign/citation-sharebar-icon.png" onClick={openCitationModal}/>
        </div>
        <div className="icon-background">
          <img className="st-custom-button icon has-tip tip-top" onClick={() => fireAmplitude('articleShared', {article_id: article.id, medium: 'email'})} data-tooltip="" title="Share via email" src="https://public.cureus.com/article-show-redesign/mail-sharebar-icon.png" data-network="email" data-email-subject={`${article.title}`} data-url={article.path}/>
        </div>
        <div className="icon-background">
          <img className="st-custom-button icon has-tip tip-top" onClick={() => fireAmplitude('articleShared', {article_id: article.id, medium: 'sharethis'})} data-tooltip="" title="Select sharing option" src="https://public.cureus.com/article-show-redesign/share-sharebar-icon.png" data-network="sharethis" data-url={article.path}/>
        </div>
        <div className="icon-background">
          <img className="st-custom-button icon has-tip tip-top" onClick={() => fireAmplitude('articleShared', {article_id: article.id, medium: 'reddit'})} data-tooltip="" title="Share on Reddit" src="https://public.cureus.com/article-show-redesign/reddit-icon.png" data-network="reddit" data-url={article.path} />
        </div>
        <div className="icon-background">
          <img className="st-custom-button icon facebook-sharebar has-tip tip-top" onClick={() => fireAmplitude('articleShared', {article_id: article.id, medium: 'facebook'})} data-tooltip="" title="Share on Facebook" src="https://public.cureus.com/article-show-redesign/facebook-sharebar-icon.png" data-network="facebook" data-url={article.path} />
        </div>
        <div className="icon-background">
          <img className="st-custom-button icon twitter has-tip tip-top" onClick={() => fireAmplitude('articleShared', {article_id: article.id, medium: 'twitter'})} data-tooltip="" title="Share on Twitter" src="https://public.cureus.com/article-show-redesign/x-twitter-sharebar-icon.png" data-network="twitter" data-url={article.path} />
        </div>
      </>}
    </div>
  )
}

export default ShareBar;