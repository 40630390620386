var createReactClass = require('create-react-class');
import React from 'react';

const OwnershipRating = createReactClass({
  modalCopy: function(content_type) {
    return "How would you rate the quality of copy editing (and if relevant, communication skills) from the editor assigned to your paper after peer review? (Please do not consider speed in your rating.)"
  },
  
  renderOwnerships: function() {
    var self = this;
    return self.props.ownerships.map(function(rev) {
      return (
        <React.Fragment>
          <div className="small-12 columns">
            <h4>Please rate your copy editor</h4>
              <p>
                {self.modalCopy(rev.content_type)}
              </p>
          </div>
            <li>
            <div className="reviewer-rating">
              <input type="hidden" name={`ownership_ratings[${rev.id}]`} id={`ratings_${rev.id}`} class="rating-field"/>
              <div className="small-3 columns greek-container">
                <h5>Copy Editor</h5>
              </div>
                <div className="small-9 columns">
                  <i className="far fa-star"onClick={() => self.props.handleRating(rev.id, 1)}/>
                  <i className="far fa-star" onClick={() => self.props.handleRating(rev.id, 2)}/>
                  <i className="far fa-star" onClick={() => self.props.handleRating(rev.id, 3)}/>
                  <i className="far fa-star" onClick={() => self.props.handleRating(rev.id, 4)}/>
                  <i className="far fa-star" onClick={() => self.props.handleRating(rev.id, 5)}/>
                </div>
              </div>
            </li>
            <div className="small-12 columns">
                <textarea name={rev.id} onChange={(e) => self.props.handleCommentChange(e)} placeholder="Comments: (optional)"/>
            </div>  
        </React.Fragment>
      )
    })
  },

  render: function() {
    return(
      <div className="ownership-rating-container">
        <div className="rate-reviewers-wrap">
          <ul>
            {this.renderOwnerships()}
          </ul>
        </div>
      </div>
    )
  }
})

export default OwnershipRating;