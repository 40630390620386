var createReactClass = require('create-react-class');
import React from 'react';
import NewAdvancedSearch from './NewAdvancedSearch';
import _ from "lodash";

const NewSearch = createReactClass({
  getInitialState: function() {
    var self = this;
    return({
      advanced: this.props.advanced,
      query: this.props.search,
      filters: this.props.filters || [{boolean: '', field: 'all', query: ''}],
      debounceGetPredictiveSearchResults: _.debounce(function() { self.getPredictiveSearchResults() }, 500),
      predictiveSearchResults: [],
      showPredictiveSearchDropDown: false
    })
  },

  componentDidUpdate: function(prevProps) {
    if (this.props.search !== prevProps.search) {
      this.setState({ query: this.props.search });
    }
  },

  toggleAdvanced: function() {
    this.setState({filters: [{boolean: '', field: 'all', query: ''}], advanced: !this.state.advanced, query: ''}, this.props.clearAdvancedSearch)
  },

  renderAdvancedSearch: function() {
    return (
      <NewAdvancedSearch
        advanced={this.state.advanced}
        filters={this.state.filters}
        toggleAdvanced={this.toggleAdvanced}
        handleAdvancedSearchEnter={this.props.handleAdvancedSearchEnter}
        handleAdvancedSearchClick={this.props.handleAdvancedSearchClick}
        updateSearchBar={this.updateSearchBar}
      />
    )
  },

  updateSearchBar: function(e, text) {
    if (!this.state.advanced) {
      this.setState({query: e.target.value}, this.callbackAfterUpdateSearchBar(e))
    }
    else {
      this.setState({query: text, showPredictiveSearchDropDown: false})
    }
  },

  callbackAfterUpdateSearchBar: function(e) {
    this.state.debounceGetPredictiveSearchResults()
    this.props.updateSearch(e)
  },

  getPredictiveSearchResults: function() {
    var self = this;
    self.handleHidingPredictiveSearchDropDown()

    if (self.state.query.length > 0) {
        $.ajax({
          method: 'GET',
          url: "/articles/search_suggestions",
          data: {query: self.state.query, category_ids: self.props.selectedCategoryIds()},
          dataType: 'json',
          success: function(res) {
            self.setState({predictiveSearchResults: res, showPredictiveSearchDropDown: true})
          },
          error: function(res) {
          }
        }, 'json');
      }
    },

  closePredictiveSearchDropDown: function() {
    setTimeout(() => {this.setState({showPredictiveSearchDropDown: false})}, 100)
  },

  openPredictiveSearchDropDown: function() {
    this.setState({showPredictiveSearchDropDown: true})
  },

  handleHidingPredictiveSearchDropDown: function() {
    if (this.state.query.length == 0) { this.setState({showPredictiveSearchDropDown: false})}
  },

  renderPredictiveSearchResults: function() {
    if (this.state.showPredictiveSearchDropDown && this.state.predictiveSearchResults.length > 0 && this.state.query != "") {
      return (
          <div className="predictive-search-results">
            {this.predictiveResults()}
          </div>
        )
      }
    },

  predictiveResults: function() {
    var self = this;
    return self.state.predictiveSearchResults.map(function(result) {
      var formatedString = self.boldSearchTermInResult(result.title)
      return <a key={result.id} href={`/articles/${result.id}`} target="_blank" dangerouslySetInnerHTML={{__html: formatedString}}/>
    })
  },

  boldSearchTermInResult: function(title) {
    // Looks at the resulting article title, checks if the search term is in the title, and if so, bolds it
    var chars = title.split("")
    var indexOfSearchTermInTitle = title.toLowerCase().indexOf(this.state.query.toLowerCase())
    if (indexOfSearchTermInTitle == -1) {
      return title
    } else {
      chars.splice(indexOfSearchTermInTitle, 0, "<strong>")
      chars.splice(indexOfSearchTermInTitle + this.state.query.length + 1, 0, "</strong>")
      return chars.join("")
    }
  },

  renderSearchButton: function() {
    if (!this.props.compOrChannel) {
      return (
        <div className="show-for-medium-up search-button-container">
          <button className="button primary search-button box-shadow" style={{ borderRadius: "8px" }} onClick={this.handleSearchOrAdvancedSearchClick} type="submit">Search</button>
        </div>
      )
    }
  },

  handleSearchOrAdvancedSearchClick: function() {
    if (this.state.advanced) {
      this.props.handleAdvancedSearchClick(this.state.filters)
    } else {
      this.props.handleSearchClick()
    }
  },

  handleCompChannelStyle: function() {
    if (this.props.compOrChannel) {
      return {height: 0}
    } else {
      return {}
    }
  },

  renderAdvancedSearchText: function() {
    if (this.state.advanced) {
      return(
        <div className="show-for-medium-up advanced-search-text advanced-button enabled box-shadow tip-top" data-tooltip="" title="Advanced search">
          <img className='advanced-icon' src={"https://public.cureus.com/browse-article/advanced-search-enabled.png"} onClick={this.toggleAdvanced} />
        </div>
      )
    } else {
      return (
        <div className="show-for-medium-up advanced-search-text advanced-button box-shadow tip-top" data-tooltip="" title="Advanced search">
          <img className='advanced-icon' src={"https://public.cureus.com/browse-article/advanced-search-disabled-3.png"} onClick={this.toggleAdvanced} />
        </div>
      )
    }
  },

  addAdvancedSearchRow: function() {
    var filters = this.state.filters;
    let boolean = filters.length == 0 ? '' : 'and'
    filters.push({boolean: 'and', field: 'all', query: ''})
    this.setState({filters: filters});
  },

  renderAddButton: function() {
    var self = this;
    if(self.state.advanced) {
      return(
        <div className="add-advanced-icon-container">
          <a href="javascript:void(0)" data-remove-multi-add="true" onClick={self.addAdvancedSearchRow}>
            <img src="https://public.cureus.com/browse-article/add_option_row.png" />
          </a>
        </div>
      )
    }
  },

  handleAdvancedOrSearchEnter: function(e) {
    e.preventDefault();
    if (this.state.advanced) {
      this.props.handleAdvancedSearchEnter(e, this.state.filters);
    } else {
      this.props.handleSearchEnter(e);
    }
  },

  render: function() {
    return(
      <div className="large-11 medium-12 columns small-centered search-container browse-article-header-container" style={this.handleCompChannelStyle()}>
        <div className="row">
          <div>
            {this.renderAdvancedSearch()}
            <div className='small-12 columns search-box new-search'>
              <form className="small-8 medium-10 columns article-search-bar" onSubmit={this.handleAdvancedOrSearchEnter} >
                <div className="search-input-container" style={{padding: "0px", width: "100%"}}>
                  <input className="search-input" enterKeyHint="go" disabled={this.state.advanced} autoComplete='off' id='q' name='q' type='text' placeholder="Search results are ordered by relevance." onFocus={this.openPredictiveSearchDropDown} onBlur={this.closePredictiveSearchDropDown} onChange={(e) => this.updateSearchBar(e, null)} value={this.state.query}/>
                  {this.renderPredictiveSearchResults()}
                  <i className='fa fa-search tip-top'/>
                </div>
                {this.renderSearchButton()}
              </form>
              {this.renderAdvancedSearchText()}
              {this.renderAddButton()}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default NewSearch;