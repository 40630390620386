import React, { useState, useEffect } from 'react';
import Table from "./Table"
import Modal from '../Modal';
import NewTermModal from "./NewTermModals";
import UploadTermsModal from "./UploadTermsModal";
import { Cureus } from '../../Cureus';

const Main = () => {
  const [selectedBoxes, setSelectedBoxes] = useState([])
  const [blackListTerms, setBlackListTerms] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect( () => {
    fetchBlackListTerms()
  }, [])

  const fetchBlackListTerms = () => {
    $.ajax({
      type: 'GET',
      url: "/admin/black_list_terms",
      success: function(data) {
        setBlackListTerms(data)
      }
    })
  }

  const handleCreate = (term) => {
    $.ajax({
      type: 'POST',
      url: "/admin/black_list_terms",
      data: {term: term},
      success: () => {
        fetchBlackListTerms()
        Modal.close()
      },
      error: (data) => {
        setErrorMessage(data.responseJSON.message)
      }
    });
  }

  const setErrorMessage = (message) => {
    document.querySelector(".id-error-message").innerHTML = message
  }

  const openAddTermModal = (e) => {
    e.preventDefault()
    Modal.open({
      children: <NewTermModal handleCreate={handleCreate}/>
    });
  }

  const handleUploadCSV = (fileData) => {
    let formData = new FormData()
    formData.append("file", fileData)

    setIsLoading(true)

    fetch('/admin/black_list_terms/upload_terms', {
      method: 'POST',
      body: formData,
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
      },
    })
    .then((res) => {
      setIsLoading(false)
      if (res.ok) {
        return res.json()
      } else {
        return Promise.reject(res.json())
      }
    })
    .then((json) => {
      if (json.duplicateCount > 0) {
        Cureus.Flash.warning(`Duplicate terms detected: ${json.termsCount - json.duplicateCount} out of ${json.termsCount} added successfully`)
      }
      fetchBlackListTerms()
      Modal.close()
    })
    .catch((failedPromise) => {
      failedPromise.then((json) => {
        Cureus.Flash.warning(json.message)
      })
    })
  }

  const openUploadTermsModal = (e) => {
    e.preventDefault()
    Modal.open({
      children: <UploadTermsModal handleUploadCSV={handleUploadCSV}/>
    });
  }

  const unCheckAllBoxesAndClearState = () => {
    allCheckBoxes().forEach( (box) => { box.checked = false })
    selectAllCheckboxElement().checked = false
    setSelectedBoxes([])
  }

  const allCheckBoxes = () => {
    return document.querySelectorAll(".checkbox-term")
  }

  const selectAllCheckboxElement = () => {
    return document.querySelector("#select-all")
  }


  const handleDelete = (e) => {
    if(window.confirm('Are you sure to delete this record?')){
      $.ajax({
        type: 'DELETE',
        url: "/admin/black_list_terms/destroy_all",
        data: {ids: selectedBoxes},
        success: () => {
          fetchBlackListTerms()
          setSelectedBoxes([])
        }
      });
    };
  }

  const handleCancel = () => {
    setSelectedBoxes([])
    unCheckAllBoxesAndClearState()
  }

  const actionButtons = () => {
    return (
      <>
        <a className="button alert" onClick={handleDelete} >Delete</a>
        <a className="button secondary" onClick={handleCancel}>Cancel</a>
      </>
    )
  }

  return (
    <>
      <h2>Blacklisted</h2>
      <div className="row">
        <div className="small-6 columns">
          <a className="button" onClick={openAddTermModal}>
            <i className="fas fa-plus fa-sm"/>&nbsp;&nbsp;Add Term
          </a>
          <a className="button small stencil-blue" onClick={openUploadTermsModal}>
            <img src="https://public.cureus.com/upload-3x.png"/>
            &nbsp;&nbsp;
            Upload List
          </a>
          {selectedBoxes.length > 0 && actionButtons()}
        </div>
      </div>
      {isLoading &&
        <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>}

      <Table
        blackListTerms={blackListTerms}
        selectedBoxes={selectedBoxes}
        setSelectedBoxes={setSelectedBoxes}
        allCheckBoxes={allCheckBoxes}
        unCheckAllBoxesAndClearState={unCheckAllBoxesAndClearState}
        selectAllCheckboxElement={selectAllCheckboxElement}
      />
    </>
  )
}

export default Main;