import React, { useState, useRef, useEffect } from 'react';
import HistoryModal from '../../../../History/HistoryModal';
import Modal from '../../../../Modal';
import moment from 'moment';
import {Cureus} from '../../../../../Cureus';

const BaseTable = ({tableHeaders, state, rowNames, historyElementColumnIndex, removeOrderingColumn, columnAmountAndWidths, enableEmailReminder, emailTemplateName, canSelectOnlyOneCheckbox, dateOrderColumn=1, searchableColumnIndex=2}) => {

  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [state])

  useEffect(() => {
   if (tableData) {
    populateTable()
   }
  }, [tableData])

  const fetchData = () => {
    $.ajax({
      type: 'GET',
      url: '/admin/channels/articles_table_data',
      data: { dataType: 'articles', state: state},
      success: function(data) {
        setTableData(data)
      }
    });
  }

  const populateTable = () => {
    // ./ChannelAdminEditors.jsx#91 - removes this global datatable search extension filter from ChannelAdminEditors, needed otherwise this table renders empty
    $.fn.dataTable.ext.search.pop()
    var dt = $(`#${state}-table`).DataTable({
      language: {
        search: "Search by channel name:"
      },
        columns: columnAmountAndWidths,
      columnDefs: [
        {orderable: false, targets: removeOrderingColumn},
        // putting searchable true option first will the targed column searchable
        {searchable: true, targets: [searchableColumnIndex]},
        {searchable: false, targets: [0,1,2,3,4]},
        {
          // when displaying, show date in MM-DD-YYYY format, when sorting it will sort by ISO date
          targets: [dateOrderColumn],
          render: (date, type, row) => {
            return (type === 'display') ? date : moment(date, "MM-DD-YYYY").toISOString();
          }
        }
      ],
      processing: true,
      dom: '<"row"<"large-8 columns"f><"large-2 columns time-range"><"large-2 columns end show-amount"l>><"table"t>r<"row bottom"<"large-5 columns"p>>',
      "bAutoWidth": false,
      "rowCallback": (row) => {
        let historyElement = row.children[historyElementColumnIndex].children[0]
        historyElement.addEventListener('click',function() {
          openHistoryModal(historyElement.dataset.articleId, historyElement.dataset.authorshipId)
        })
      },
      "drawCallback": () => {
        removeClickHandlerFromEmailButton()
        initEmailReminderFeature()
      }
    })

    dt.api().clear();
    addDataToTable(dt)

    dt.fnSort([[dateOrderColumn, 'desc']])
    dt.api().draw()
  }

  const initEmailReminderFeature = () => {
    if (enableEmailReminder) {
      Cureus.Admin.MultipleReminders.init({
        btnSelector: `.${state}.bulk-email-button`,
        noNavSelector: true,
        tabPaneSelector: `#articles-datatable-container-${state}`,
        templateName: emailTemplateName,
        templatePath: "/admin/reminders/template?channels=true",
        createPath: "/admin/reminders",
        async: true,
        canSelectOnlyOneCheckbox: canSelectOnlyOneCheckbox
      });
    }
  }

  const removeClickHandlerFromEmailButton = () => {
    // Need this ownerwise Cureus.Admin.MultipleReminders will cause duplicate modals to open at the same time.
    $(`.${state}.bulk-email-button`).off('click')
  }

  const addDataToTable = (dt) => {
    for (let i = 0; i < tableData.length; i++) {
      let row = tableData[i];
      dt.fnAddData(
        rowData(row)
      )
    }
  }

  const rowData = (row) => {
    return rowNames.map((rowName) => {
      return row[rowName] || ''
    })
  }

  const openHistoryModal = (articleId, authorshipId) => {
    Modal.open({
      children: <HistoryModal
          articleId={articleId}
          authorshipReviewershipId={authorshipId}
          isAuthorship={true}
        />
    });
  }

  const renderTableHeaders = () => {
    return tableHeaders.map((header, index) => {
      return <th key={index}>{header}</th>
    })
  }

  return (
    <div className="channel-articles-datatable-container" id={`articles-datatable-container-${state}`}>
      <table id={`${state}-table`}>
        <thead>
          <tr>
          {renderTableHeaders()}
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  )
}

export default BaseTable;