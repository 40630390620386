import React, { useState, useEffect } from 'react';
import SocialModalInput from './SocialModalInput';

const SocialAccountsModal = ({handleAddWidget, widgetAboutSidebarId, widget={}, handleUpdateWidget}) => {
  const [email, setEmail] = useState(widget.email || '');
  const [facebook, setFacebook] = useState(widget.facebook || '');
  const [twitter, setTwitter] = useState(widget.twitter || '');
  const [youtube, setYoutube] = useState(widget.youtube || '');
  const [linkedin, setLinkedin] = useState(widget.linkedin || '');
  const [instagram, setInstagram] = useState(widget.instagram || '');

  const shouldDisable = () => {
    return (!email && !facebook && !twitter && !youtube && !linkedin && !instagram) ? 'disabled' : null;
  }

  const handleAddWidgetWrapper = () => {
    let params;
    params = {
      widget: {
        type: "Widget::SocialAccounts",
        parent_id: widgetAboutSidebarId,
        configuration: { email, facebook, twitter, youtube, linkedin, instagram },
      }
    };
    if(handleAddWidget) {
      handleAddWidget(params, false)
    } else {
      handleUpdateWidget(widget.id, params, false)
    }
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Social Media Accounts</h5>
            <hr/>
          </div>
          <p>Add your organization’s social media accounts to provide quick and easy access for readers!</p>

          <SocialModalInput icon={'fas fa-envelope'} placeholder='Cureus@cureus.com' value={email} handleChange={(e) => setEmail(e.target.value)} />

          <SocialModalInput icon={'fab fa-facebook-square'} placeholder='https://www.facebook.com/Cureus' value={facebook} handleChange={(e) => setFacebook(e.target.value)} />

          <SocialModalInput icon={'fab fa-x-twitter'} placeholder='https://www.twitter.com/CureusInc' value={twitter} handleChange={(e) => setTwitter(e.target.value)} />

          <SocialModalInput icon={'fab fa-youtube'} placeholder='https://www.youtube.com/Cureus' value={youtube} handleChange={(e) => setYoutube(e.target.value)} />

          <SocialModalInput icon={'fab fa-linkedin-in'} placeholder='https://www.linkedin.com/in/Cureus' value={linkedin} handleChange={(e) => setLinkedin(e.target.value)} />

          <SocialModalInput icon={'fab fa-instagram'} placeholder='https://www.instagram.com/Cureus' value={instagram} handleChange={(e) => setInstagram(e.target.value)} />

        </div>

        <div className='modal-footer text-center'>
            <a className={"button primary " + shouldDisable()} onClick={handleAddWidgetWrapper}>Add</a>
        </div>

      </div>
    </div>
  )
}


export default SocialAccountsModal;