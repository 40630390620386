import React from 'react';
import DateSelector from '../StatsWidget/DateSelector';

const Summary = ( {summary, deltas, compareStartDate, compareEndDate, setCompareStartDate, setCompareEndDate } ) => {

  function handleDateRangeSelect(fromDate, toDate, selectedRange) {
    setCompareStartDate(fromDate);
    setCompareEndDate(toDate);
  }

  function renderDelta(key) {
    if (deltas[key]) {
      return (<span className={"delta " + deltas[key].sign}>{deltas[key].value}</span>)
    }
  }

  return (
    <div className='section'>
      <div className='row'>
        <h6>Summary</h6>
        <div className='date-selector-wrap'>
          <DateSelector
            from={compareStartDate}
            to={compareEndDate}
            dateRanges={[]}
            getStats={handleDateRangeSelect}
            noFloat={true}
            page={'email'}
          />
        </div>
      </div>
      <table className='summary'>
        <tbody>
          <tr>
            <td>
              <span>Total clicks</span>
              <br/>
              <span className='stat'>{summary.totalClicks}</span>
              {renderDelta("totalClicks")}
            </td>
            <td>
              <span>Unique clicks</span>
              <br/>
              <span className='stat'>{summary.uniqueClicks}</span>
              {renderDelta("uniqueClicks")}
            </td>
            <td>
              <span>Click rate</span>
              <br/>
              <span className='stat'>{summary.clickRate}</span>
              {renderDelta("clickRate")}%
            </td>
            <td>
              <span>CTOR</span>
              <br/>
              <span className='stat'>{summary.ctor}%</span>
              {renderDelta("ctor")}
            </td>
            <td>
              <span>Best performing day</span>
              <br/>
              <span className='stat'>{summary.bestPerformingDay}</span>
              <br/>
              <span>{summary.bestClickRate}% click rate</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Preferences clicks</span>
              <br/>
              <span className='stat'>{summary.preferenceClicks}</span>
              {renderDelta("preferenceClicks")}
              <br/>
              <span>Totals</span>
            </td>
            <td>
              <span>Other clicks</span>
              <br/>
              <span className='stat'>{summary.otherClicks}</span>
              {renderDelta("otherClicks")}
              <br/>
              <span>Totals</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Summary;