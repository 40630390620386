import React, { useState, useRef, useEffect } from 'react';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const ChannelCampaignModal = ({campaignId, channelId, fetchData, listId, allInvitees}) => {

  const [title, setTitle] = useState(null);
  const [list, setList] = useState(listId);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [lists, setLists] = useState([]);
  const [allListsSelected, setAllListsSelected] = useState(allInvitees);
  const [campId, setcampId] = useState(campaignId)

  useEffect(() => {
    fetchCampaign()
    bindChangeListeners()
  }, [])
  
  const fetchCampaign = () => {
    let path = campId ? `/admin/channels/${channelId}/campaigns/${campId}` : `/admin/channels/${channelId}/campaigns/new`
    $.ajax({
      type: 'GET',
      dataType: "JSON",
      url: path,
      success: function(data) {
        new Cureus.CKEditor('message', {editor_type: 'static_text', ckeditor: {height: 200}})
        setLists(data.lists)
        if(campId) {
          setTitle(data.campaign.title)
          setSubject(data.campaign.subject)
          setMessage(data.campaign.message)
          setList(data.list)
        }
      }
    });
  }

  const setListSelection = (val) => {
    if (val == 'all-lists') {
      setAllListsSelected(true)
    } else {
      setList(val)
    }
  }

  const updateOrCreateCampaign = () => {
    let type = campId ? 'PUT' : 'POST'
    let path = campId ? `/admin/channels/${channelId}/campaigns/${campId}` : `/admin/channels/${channelId}/campaigns`
    $.ajax({
      type: type,
      dataType: "JSON",
      url: path,
      data: {
              campaign: {title: title, subject: subject, message: message, template_name: 'channel', channel_id: channelId},
              email_list_id: list,
              all_lists: allListsSelected
            },
      success: function(data) {
        setcampId(data.campaignId)
        if (fetchData) {
          fetchData()
        }
        window.open(`/admin/channels/${data.channelId}/campaigns/${data.campaignId}/preview`, '_blank').focus()
        Cureus.Flash.success('The campaign has been saved.')
      }
    });
  }

  const queueCampaign = () => {
    $.ajax({
      type: 'POST',
      dataType: "JSON",
      url: `/admin/channels/${channelId}/campaigns/${campId}/queue_for_send`,
      data: {
        campaign: {title: title, subject: subject, message: message, template_name: 'channel', channel_id: channelId},
        email_list_id: list
      },
      success: function() {
        if (fetchData) {
          fetchData()
        }
        Modal.close();
        Cureus.Flash.success('The campaign has been queued to send.')
      }
    });
  }

  const renderLists = () => {
    return (
      <div className="large-12 columns">
        <div className="input-container">
          <label>Recipients</label>
          <select onChange={(e) => setListSelection(e.target.value)}>
            {!list && !allInvitees && <option value="" disabled selected>Please, choose a list of email recipients.</option>}
            <option value="all-lists" selected={allInvitees ? true : false}>All lists</option>
            {lists.map(function(listItem) {
              return <option key={listItem.id} value={listItem.id} selected={list == listItem.id ? true : false}>{listItem.title}</option>
            })}
          </select>
        </div>
      </div>
    )
  }

  const checkDisable = () => {
    return (!title || !message || !subject || (!list && !allListsSelected) )? 'disabled' : ''
  }

  const bindChangeListeners = () => {
    setTimeout(function() {
      CKEDITOR.instances['message'].on("change", function(){
        setMessage(CKEDITOR.instances['message'].getData())
      })
    }, 1000)
  }

  return (
    <div className="reveal-modal medium" data-reveal id="channels-campaign-modal">
    <div className="modal-body">
      <div className='modal-close'>
        <a href='#' className='cancel-modal'>
          <img src="https://public.cureus.com/close_x.png"/>
        </a>
      </div>

      <div className="modal-content">
        <div className="header-container"> 
          <h4>Email Campaign Settings</h4>
        </div>
        <div className="channel-campaign-modal-form">
          <div className="large-12 columns">
            <div className="input-container">
              <label htmlFor="channel_title">Title</label>
              <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} id="channel_title"/>
            </div>
          </div>
          {renderLists()}
          <div className="large-12 columns">
            <div className="input-container">
              <label htmlFor="from">From</label>
              <input type="text" value="No-reply@cureus.com" id="from" disabled/>
            </div>
          </div>
          <div className="large-12 columns">
            <div className="input-container">
              <label htmlFor="subject">Subject</label>
              <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} id="subject" />
            </div>
          </div>
          <div className="large-12 columns">
            <div className="input-container">
              <label htmlFor="message" className="ck-editor">Body</label>
              <textarea id='message' value={message}/>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <a href="#" className="button secondary cancel-modal left"> Cancel </a>
        <a className={"button special " + checkDisable()} onClick={updateOrCreateCampaign}>Preview & Save</a>
        <a className={"button primary " + checkDisable()} onClick={queueCampaign}>Send</a>
      </div>
    </div>
  </div>
  )
}

export default ChannelCampaignModal;