import {Cureus} from '../Cureus';

Cureus.PublishedScoringMailer = function(currentUser, articleId) {
  this.currentUser = currentUser;
  this.articleId = articleId;
  this.timeAtLoad = new Date();
  this.initListeners();
}

Cureus.PublishedScoringMailer.prototype.submitMailerRequest = function() {
  var self = this;
  $.ajax({
    method: 'get',
    url: '/articles/' + self.articleId + '/request_scoring',
    data: {userId: self.currentUser, articleId: self.articleId},
    success: function(e) { },
    error: function(e) { }
  });
}

Cureus.PublishedScoringMailer.prototype.initListeners = function() {
  var self = this;
  window.addEventListener("beforeunload", function(e) {
    var timeElapsed = new Date() - self.timeAtLoad;
    if (timeElapsed > 12000) {
      self.submitMailerRequest();
    }
  });
}