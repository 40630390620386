import React, { useEffect, useState } from 'react';

const StatsTable = ({id, title, fullReportHref}) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    $.ajax({
      type: "GET",
      dataType: "json",
      url: fullReportHref,
      success: function(res) {
        setData(res);
      }
    })
  }, [])

  function renderData() {
    if (!data) { return }
    if (id == 'most-popular-links') {
      return data.map(function(row, idx) {
        return (
          <tr key={id + "-" + idx}>
            <td>
              <div><a href={row.link} target="_blank" rel="noreferrer">{row.link}</a></div>
            </td>
            <td>
              {row.count}
            </td>
          </tr>
        )
      })
    } else {
      return data.map(function(row, idx) {
        return (
          <tr key={id + "-" + idx}>
            <td>
              {row.country}
            </td>
            <td>
              {row.count}
              <br/>
              <span className='small-grey'>{row.pct}%</span>
            </td>
          </tr>
        )
      })
    }
  }

  return (
    <table id={id} className='stats-table'>
      <thead>
        <tr>
          <th>{title} (<a href={fullReportHref} target="_blank" rel="noreferrer">full report</a>)</th>
          <th>Clicks</th>
        </tr>
      </thead>
      <tbody>
        {renderData()}
      </tbody>
    </table>
  )

}

export default StatsTable;