var createReactClass = require('create-react-class');
import React from 'react';

const EditProfile = createReactClass({
  getInitialState: function() {
    var userProfile = this.props.userProfile
    return {
      first_name: userProfile.first_name,
      middle_initial: userProfile.middle_initial,
      last_name: userProfile.last_name,
      specialty_id: userProfile.specialty_id,
      sub_specialty_name: userProfile.sub_specialty_name,
      suffix: userProfile.suffix,
      user_qualifications: userProfile.user_qualifications || []
    }
  },

  renderQualifications: function() {
    var self = this;
    let qualifications = self.props.qualifications;

    qualifications = qualifications.map((qualArray) => {
      return qualArray.map((qual) => {
        var defaultChecked = self.state.user_qualifications.includes(qual.id);
        return (
          <div key={qual.id} className={`new-qualification large-4 columns left checks-column ${self.state.user_qualifications.includes(parseInt(qual.id)) ? "checked" : ""}`}>
            <input value={qual.id} defaultChecked={defaultChecked} onClick={(e) => self.handleCheck(e)}
            className="checkbox-new"
            type="checkbox" id={qual.id}/>
            <label htmlFor={qual.id}>{qual.title}</label>
          </div>
        )
      })
    })

    qualifications.push([this.renderOther()])
    return qualifications.map(function (group, idx) {
      return (
        <div className="row" key={idx}>
        {group}
        </div>
      )
    })
  },

  renderOther: function() {
    var self = this;
    var qual = self.props.otherQualification;
    var defaultChecked = self.state.user_qualifications.includes(qual.id);
    return (
      <div key={qual.id} className={`new-qualification large-4 columns left checks-column ${self.state.user_qualifications.includes(parseInt(qual.id)) ? "checked" : ""}`}>
        <input value={qual.id} defaultChecked={defaultChecked} onClick={(e) => self.handleCheck(e)}
        className="checkbox-new"
        type="checkbox" id={qual.id}/>
        <label htmlFor={qual.id}>{qual.title}</label>
      </div>
    )
  },

  renderSpecialtyOptions: function() {
    var self = this;
    return self.props.specialties.map(function(spec) {
      return <option key={spec[1]} value={spec[1]}>{spec[0]}</option>
    })
  },

  handleChange: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  handleCheck: function(e) {
    var newChecked = this.state.user_qualifications
    if (e.target.checked) {
      newChecked.push(parseInt(e.target.value))
      this.setState({user_qualifications: newChecked})
    } else {
      var idx = newChecked.indexOf(parseInt(e.target.value))
      newChecked.splice(idx, 1)
      this.setState({user_qualifications: newChecked})
    }
  },

  allowSubmit: function() {
    return this.hasValidSpecialty() && this.hasOneQualification()
  },

  hasValidSpecialty: function() {
    return this.state.specialty_id != 'Select Specialty'
  },

  hasOneQualification: function() {
    return this.state.user_qualifications.length > 0
  },

  renderSuffixes: function() {
    return(
      <select name="suffix" onChange={(e) => this.handleChange(e)} defaultValue={this.state.suffix || ""}>
        <option value=""></option>
        <option value="Jr.">Jr.</option>
        <option value="Sr.">Sr.</option>
        <option value="II">II</option>
        <option value="III">III</option>
        <option value="IV">IV</option>
      </select>
    )
  },

  render: function(){
    return (
      <div className="reveal-modal medium" data-reveal id="edit-profile-modal" data-options={this.props.setUncloseable()}>
      <div className="modal-body">
        {this.props.renderFlash()}
        {this.props.renderModalCloseX()}
        <div className="modal-content">
          <h4>{this.props.confirmation ? "Welcome to Cureus! Please review your profile for accuracy." : "Edit My Profile"}</h4>
          <br/>
          <div className="row">
              <div className="large-4 medium-4 columns">
                <label>First Name*</label>
                  <input type="text" name="first_name" value={this.state.first_name} onChange={(e) => this.handleChange(e)}></input>
              </div>
              <div className="large-1 medium-1 columns">
                <label>M.I.</label>
                <input size="1" maxLength="1" name="middle_initial" type="text" value={this.state.middle_initial} onChange={(e) => this.handleChange(e)}></input>
              </div>
              <div className="large-5 medium-5 columns">
                <label>Last Name*</label>
                <input type="text" onChange={(e) => this.handleChange(e)} name="last_name" value={this.state.last_name}></input>
              </div>
              <div className="large-2 medium-2 columns">
                <label>Suffix</label>
                {this.renderSuffixes()}
              </div>
          </div>
          <div className="qualifications new-checkbox-container">
            <div className='heading'>
              <h6 className="reg">Qualifications (Check all that apply)*</h6>
            </div>
            <div className='checkbox-container'>
              {this.renderQualifications()}
            </div>
          </div>
          <div className="row">
            <div className="large-6 medium-6 columns">
              <label>Primary Specialty*</label>
              <select defaultValue={this.state.specialty_id} name="specialty_id" onChange={(e) => this.handleChange(e)}>
                <option>Select Specialty</option>
                {this.renderSpecialtyOptions()}
              </select>
            </div>
            <div className="large-6 medium-6 columns">
              <label>Subspecialty</label>
              <input type="text" value={this.state.sub_specialty_name || ""} name="sub_specialty_name" onChange={(e) => this.handleChange(e)}></input>
            </div>
          </div>
          {this.props.renderErrors()}
          <div className='modal-footer'>
              {this.props.renderButton({user: this.state, section: 'EditProfileForm'}, this.props.updateProfilePath, () => this.forceUpdate(), this.allowSubmit())}
          </div>
        </div>
      </div>
    </div>
    )
  }
})

export default EditProfile;