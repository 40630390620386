import React, { useState, useEffect } from 'react';
import DateSelector from '../../../StatsWidget/DateSelector';

const MainMetrics = ({dateRanges}) => {

  const [tableData, setTableData] = useState([]);
  const [dateFrom, setDateFrom] = useState(dateRanges[5].from)
  const [dateTo, setDateTo] = useState(dateRanges[5].to)
  const [selectedRange, setSelectedRange] = useState(dateRanges[5].label);

  useEffect(() => {
    fetchChannelsData();
  }, [dateFrom, dateTo, selectedRange])

  useEffect(() => {
    populateTable(tableData);
  }, [tableData])

  const fetchChannelsData = () => {
    $.ajax({
      type: 'GET',
      url: '/admin/channels/articles_table_data',
      data: {dataType: "main_metrics", date_from: dateFrom, date_to: dateTo},
      success: function(data) {
        setTableData(data)
      }
    });
  }

  const setColumns = () => {
    return [
      {class: 'centered', width: '25%'},
      {class: 'centered', width: '17.5%'},
      {class: 'centered', width: '17.5%'},
    ]
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData([
        `<a target="_blank" href="/admin/channels/${row.id}">${row.title}</a>`,
        row.queued_for_editor_check,
        row.published
      ], false)
    )
  }

  const populateTable = (channelsData) => {
    var dt = $("#main-metrics-table").DataTable({
      destroy: true,
      paging: false,
      columns: setColumns(),
      searching: false,
      order: [[0, 'asc']],
      dom: '<"row"<"large-10 columns search-filters"f>>',
    });
    dt.fnClearTable();
    for (var i = 0; i < channelsData.length; i++) {
      let row = channelsData[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[0, 'asc']])
    dt.fnDraw()
  }

  const filterData = (from, to, rangeName) => {
    setDateFrom(from)
    setDateTo(to)
    setSelectedRange(rangeName)
  }

  const renderDateSelector = () => {
    return <DateSelector
      from={dateRanges[3].from}
      to={dateRanges[3].to}
      getStats={filterData}
      selectedRange={"All time"}
      noFloat={true}
      dateRanges={dateRanges}
    />
  }

  return (
    <div className="row">
      <div className="large-12 columns main-metrics">
        <div className='header-container'>
          <h4>Main metrics</h4>
          <div className="large-2 large-offset-10 columns date-picker-container">
              {renderDateSelector()}
          </div>
        </div>
        <table className="table-wrapper" id="main-metrics-table">
          <thead>
            <tr>
              <th>Channel Name</th>
              <th>Approved for Editor Check</th>
              <th>Published</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  )
}

export default MainMetrics;