import React from 'react';

const ChannelEditorialRoles = () => {
  return (
    <React.Fragment>
      <h1>Channel Editorial Roles</h1>

      <p>The journal editorial staff conducts and manages editorial and peer review independently of the channel. In addition, all article submissions to academic channels are reviewed by the channel’s selected editorial staff, each of whom are vetted and approved by the journal. There are two channel roles available: channel administrators, known as “Channel Admins” and channel editors, known as “Deputy Editors”.</p>

      <p><strong>Channel Admins</strong> are responsible for managing the channel. This includes curating content on the channel homepage via widgets (found on the Design page) and adding/managing Deputy Editors. Channel Admins may also review articles during the initial and final channel editorial review steps detailed below. Channel Admins work closely with Cureus Admins to keep their channel running efficiently.</p>

      <p><strong>Deputy Editors</strong> are responsible for the editorial oversight of all channel content from submission to publication, as well as performing any other editorial tasks that arise. All peer-reviewed content published in Cureus must receive final approval from the Cureus associate editor team.</p>
    
    </React.Fragment>
  )

}

export default ChannelEditorialRoles;