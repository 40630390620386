import React from 'react';

const ApprovalRating = ({data, editorView}) => {
  return (
    <div className="row-container">
      <div className="row">
        <div className="small-12 columns">
          <div className="small-1 columns icon-container">
            <span>
              <i className="fas fa-tachometer-alt background"/>
            </span>
          </div>
          <div className="small-11 columns header">
            <h5>Article ratings</h5>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns">
          <div className="small-2 small-offset-1 columns">
            <div className={"ap-rating-container " + (editorView ? 'editor-view' : '')}>
              <div className={"circle green " + (editorView ? 'editor-view' : '')}/>
              <h6 className="reg"><span className="percent-label">Top 20%</span> {editorView ? <br/> : ''} {data[1] || "0%"}</h6>
            </div>
          </div>
          <div className="small-2 columns">
            <div className={"ap-rating-container " + (editorView ? 'editor-view' : '')}>
              <div className={"circle yellow " + (editorView ? 'editor-view' : '')}/>
              <h6 className="reg"><span className="percent-label">Next 20%</span> {data[2] || "0%"}</h6>
            </div>
          </div>
          <div className="small-2 columns">
            <div className={"ap-rating-container " + (editorView ? 'editor-view' : '')}>
              <div className={"circle blue " + (editorView ? 'editor-view' : '')}/>
              <h6 className="reg"><span className="percent-label">Middle 20%</span> {data[3] || "0%"}</h6>
            </div>
          </div>
          <div className="small-2 columns">
            <div className={"ap-rating-container " + (editorView ? 'editor-view' : '')}>
              <div className={"circle purple " + (editorView ? 'editor-view' : '')}/>
              <h6 className="reg"><span className="percent-label">Lower 20%</span> {data[4] || "0%"}</h6>
            </div>
          </div>
          <div className="small-2 columns end">
            <div className={"ap-rating-container " + (editorView ? 'editor-view' : '')}>
              <div className={"circle red " + (editorView ? 'editor-view' : '')}/>
              <h6 className="reg"><span className="percent-label">Bottom 20%</span> {data[5] || "0%"}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApprovalRating;