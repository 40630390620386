
var createReactClass = require('create-react-class');
import React from 'react';
import ChannelFilter from './ChannelFilter';
import ChannelTile from './ChannelTile';
import SubChannelModal from './SubChannelModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const ChannelSelector = createReactClass({
  getInitialState: function() {
    return {
      selectedChannel: {},
      showChannels: false,
      filter: "Channel::AcademicChannel",
      submitDisabled: 'disabled',
      radioButtonFalseState: null,
      declaredFieldValue: null
    }
  },

  componentWillMount: function() {
    if(this.props.channelDeclared != null){
      this.setState({
        radioButtonFalseState: !this.props.channelDeclared,
        declaredFieldValue: this.props.channelDeclared
      });
      if(this.props.channelDeclared == false){
        this.setState({
          submitDisabled: '',
          selectedChannel: false
        });
      }
    }

    if(this.props.selectedChannel ) {
      this.setState({
        selectedChannel: this.props.selectedChannel,
        showChannels: true,
        submitDisabled: this.props.selectedChannel.parent ? 'disabled' : '',
        filter: this.props.selectedChannel.type
      });
    }
  },

  componentDidMount: function(){
    Cureus.ChangeDetectorAlert.init("form");
    new Cureus.InstaSaver("#save-now");
    if (this.props.selectedChannel && this.props.selectedChannel.parent) {
      this.openSubchannelModal(this.props.selectedChannel)
    }
  },

  openSubchannelModal: function(channel) {
    Modal.open({
      children: <SubChannelModal 
        handleChooseChannel={this.handleChooseChannel}
        channel={channel}
        subchannels={this.props.subchannels}
        handleCancel={this.handleCancel}
        selectedChannel={this.state.selectedChannel}
      />
    });
  },

  handleRadioChange: function(e) {
    if(e.target.value == "false") {
      this.setState({
        selectedChannel: {id: ""},
        showChannels: false,
        submitDisabled: '',
        radioButtonFalseState: true,
        declaredFieldValue: false
      });
    } else {
      this.setState({
        showChannels: true,
        submitDisabled: 'disabled',
        radioButtonFalseState: false,
        declaredFieldValue: null
      });
    }
  },

  handleChooseChannel: function(channel) {
    if(channel.parent) {
      this.openSubchannelModal(channel)
    } else { 
      this.setState({
        selectedChannel: channel,
        submitDisabled: '',
        declaredFieldValue: true
      });
    }
  },

  isActive: function(channel) {
    return (this.state.selectedChannel.id == channel.id || this.state.selectedChannel.parent_id == channel.id)
  },

  handleFilter: function(filter) {
    this.setState({filter: filter});
  },

  filteredChannels: function() {
    var self = this;

    if(self.state.filter) {
      return self.props.channels.filter(function(channel) {
        return channel.type == self.state.filter;
      });
    } else {
      return self.props.channels;
    }
  },

  handleCancel: function() {
    this.setState({selectedChannel: {}, submitDisabled: 'disabled', declaredFieldValue: null, filter: "Channel::AcademicChannel"}, function() {
      Modal.close()
    })
  },

  render: function() {
    var self = this;
    return (
      <div>
        <input type="hidden" value={ this.state.selectedChannel.id } name={ this.props.fieldName }/>
        <input type='hidden' value={ this.state.declaredFieldValue } name={ this.props.declaredFieldName }/>

        <div className="row">
          <div className="large-12 columns">
            <input id="channel_entry_false" type="radio" value="false" checked={ this.state.radioButtonFalseState } onChange={ this.handleRadioChange }/>
            <label htmlFor="channel_entry_false">No, this is not a channel article.</label>
          </div>
        </div>
        <div className="row">
          <div className="large-12 columns">
            <input id="channel_entry_true" type="radio" value="true" checked={ this.state.showChannels } onChange={ this.handleRadioChange }/>
            <label htmlFor="channel_entry_true">Yes, this is a channel article.</label>
          </div>
        </div>



        { this.state.showChannels ?
          <div>
            <p>
              Select a channel below. You must be a member of a department or society in order to contribute to its channel (with the exception of FLAGSHIP).
            </p>

            <ChannelFilter
              onFilter={ this.handleFilter }
              selectedChannel={ this.state.selectedChannel }
            />

            <div className='row card-tiles-wrap'>
              {
                self.filteredChannels().map(function(channel) {
                  return (
                    <div className='large-3 small-6 columns'>
                      <ChannelTile
                        active={ self.isActive(channel) }
                        channel={ channel }
                        onClick={ self.handleChooseChannel.bind(self, channel) }
                        selectedChannel={self.state.selectedChannel}
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
          : null
        }
        <div className="step-actions clearfix">
          <div className="row">
            <div className="pull-right">
              <div className="save-success-message" id="save-success-message">
                You have successfully saved your progress.
              </div>
            </div>
          </div>
          <div className="pull-left">
            <a href={ this.props.backLink } className="button secondary">Back</a>
          </div>
          <div className="pull-right">
            <a id="save-now" className="button special" data-disable-with="Save" disabled={ this.state.submitDisabled } data-alert-exception>Save</a>
            &nbsp;
            <input className="button primary" data-disable-with="Save and continue" disabled={ this.state.submitDisabled } name="commit" type="submit" value="Save and continue" data-alert-exception/>
          </div>
        </div>
      </div>
    )
  }
});

export default ChannelSelector;