import React, { useState, useEffect } from 'react';
import ChannelTeamMember from './ChannelTeamMember';

const ChannelTeam = ({handleAddWidget, widgetMainRiverId, mainContentWidgets, widget, handleUpdateWidget, slug}) => {
  const [channelMemberEmails, setChannelMemberEmails] = useState([])
  const [members, setMembers] = useState({0: {email: '', title: ''}, 1: {email: '', title: ''}})
  const [type, setType] = useState(widget ? widget[0].widgetType.split('::').pop(): "FiftyPercent")
  const [widgetId, setWidgetId] = useState(widget ? widget[0].widgetId : null)
  const [HasAllValidEmails, setHasAllValidEmails] = useState(false)

  useEffect(() => {
    fetchEmails()
    if(widget) {
      formatMembersForUpdate()
    }
    setHasAllValidEmails(hasEmpties())
  }, [])

  const formatMembersForUpdate = () => {
    let newMembers = {}
    widget.forEach((obj, idx) => {
      newMembers[idx] = {email: obj.email, title: obj.title}
    })
    setMembers(newMembers)
  }

  const removeMember = (idx) => {
    let newMembers = {...members}
    delete newMembers[idx]
    // need to set a new type and members object with correct indices
    let maxRow = Object.keys(newMembers).length
    let newType;
    if (maxRow === 1) {
      newType = "HundredPercent"
    }
    if (maxRow === 2) {
      newType = "FiftyPercent"
    }
    if (maxRow === 3) {
      newType = "ThirtyPercent"
    }
    if (maxRow === 4) {
      newType = "TwentyFivePercent"
    }
    setType(newType)

    let arr = Object.entries(newMembers);
    let newObj = {};
    arr.forEach(([key, value], idx) => {
        newObj[idx] = value; // Shift indices down
    });
    setMembers(newObj)
  }

  const fetchEmails = () => {
    $.ajax({
      type: 'GET',
      url: `/admin/channels/${slug}/fetch_emails`,
      dataType: "JSON",
      success: function(res) {
        setChannelMemberEmails(res.emails)
      }
    })
  }

  const validMemberLength = () => {
    return Object.keys(members).length !== 0;
  }

  const shouldDisable = () => {
   return hasEmpties() || !HasAllValidEmails || !validMemberLength() ? 'disabled' : ''
  }

  const hasEmpties = () => {
    return Object.keys(members).some((memberKey) => {
      return (members[memberKey].email.length === 0) || (members[memberKey].title.length === 0)
    })
  }

  const handleUpdate = (e, idx) => {
    let newMembers = {...members}
    newMembers[idx][e.target.name] = e.target.value
    setMembers(newMembers)
  }

  const handleAddWidgetWrapper = () => {
    let params;
    if (mainContentWidgets["Widget::ChannelTeam"]) {
      params = {
        type: `Widget::TeamMember::${type}`,
        parent_id: mainContentWidgets["Widget::ChannelTeam"],
        configuration: setConfiguration()
      }
    } else {
      params = {
        type: "Widget::ChannelTeam",
        parent_id: widgetMainRiverId,
        widgets_attributes: [{
          type: `Widget::TeamMember::${type}`,
          configuration: setConfiguration()
        }]
      }
    }
    if(handleAddWidget) {
      handleAddWidget(params)
    } else {
      handleUpdateWidget(widgetId, params)
    }
  }

  const setConfiguration = () => {
    let configObj = {}
    Object.values(members).forEach((memberObj) => {
      return configObj[memberObj.email] = memberObj.title
    })
    return configObj
  }

  const setTypeWrapper = (e) => {
    setType(e.target.value)
    let maxRow = parseInt(e.target.dataset.max)
    let newMembers = {}
    for (let index = 0; index < maxRow; index++) {
      if (members[index] === undefined) {
        newMembers[index] = {email: '', title: ''}
      } else {
        newMembers[index] = members[index]
      }
    }
    setMembers(newMembers)
  }

  const renderMembers = () => {
    return Object.values(members).map((member, idx) => {
      return (
        <ChannelTeamMember
          setMembers={setMembers}
          idx={idx}
          key={idx}
          email={member.email}
          title={member.title}
          channelMemberEmails={channelMemberEmails}
          handleUpdate={handleUpdate}
          setHasAllValidEmails={setHasAllValidEmails}
          slug={slug}
          removeMember={removeMember}
        />
      )
    })
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Channel Team</h5>
            <hr/>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <p>Showcase your channel admins and editors. Each row must be added separately. Adding an admin or editor here does not grant them admin or editor access; they must be added on the Settings page in order to access the channel dashboard, etc.</p>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <div className="radios-container">
                <label htmlFor={"radio-1"}>Row of one</label>
                <input type="radio" id="radio-1" value="HundredPercent" onChange={(e) => setTypeWrapper(e)} data-max="1" checked={type == 'HundredPercent'} />
                <label htmlFor={"radio-2"}>Row of two</label>
                <input type="radio" id="radio-2" value="FiftyPercent" onChange={(e) => setTypeWrapper(e)} data-max="2" checked={type == 'FiftyPercent'} />
                <label htmlFor={"radio-3"}>Row of three</label>
                <input type="radio" id="radio-3" value="ThirtyPercent" onChange={(e) => setTypeWrapper(e)} data-max="3" checked={type == 'ThirtyPercent'} />
                <label htmlFor={"radio-4"}>Row of four</label>
                <input type="radio" id="radio-4" value="TwentyFivePercent" onChange={(e) => setTypeWrapper(e)} data-max="4" checked={type == 'TwentyFivePercent'} />
              </div>
            </div>
          </div>
          {renderMembers()}
          <div className='modal-footer text-center'>
            <a className={"button primary " + shouldDisable()} onClick={handleAddWidgetWrapper}>Add</a>
          </div>
        </div>
      </div>
    </div>
  )
}


export default ChannelTeam;