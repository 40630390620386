import React from 'react';

const AddingInteractiveModels = () => {

  return (
    <React.Fragment>
      <h1>Adding Interactive Models</h1>
      <p>All 3D models must be accompanied by an identical video version hosted on YouTube or Vimeo. Follow these steps to insert an embedded 3D model directly into your article:</p>
      <ol className='decimals indented-for-medium-up'>
        <li>Upload your 3D model to <a href='http://www.sketchfab.com/' target='_blank' rel='noreferrer'>Sketchfab</a>.</li>
        <li>Place your cursor where you wish to insert the 3D model and click the Insert 3D Model button in the toolbar.</li>
        <li>Paste the 3D model URL into the Sketchfab URL field and click the Search button. Click the 3D model to make sure it will play correctly.</li>
        <li>Paste the matching video URL (hosted on Youtube or Vimeo) into the Video URL field and click the Search button. Click the video to make sure it will play correctly.</li>
        <li>Enter a title in the title field.</li>
        <li>If desired, enter a legend in the legend field.</li>
        <li>Click the Insert button to insert the 3D model into your article.</li>
      </ol>
    </React.Fragment>
  )

}

export default AddingInteractiveModels;