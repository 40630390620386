import React, { useState, useEffect } from 'react';

const Tab = ({type, src, title, activeTab, setType, srcActive}) => {
  const [hovering, setHovering] = useState(false)
  
  return (
    <div className={"tabs " + (activeTab === type ? 'active' : '')} onClick={() => {setType(type)}} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <img src={(hovering || activeTab == type) ? srcActive :  src} />
      <span>{title}</span>
    </div>
  )
}

export default Tab;