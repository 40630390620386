import {Cureus} from '../../Cureus';

Cureus.Utils.submitOnce = function (selector, validatorFn) {
  var $submit = $(selector);

  var toggleDisabled = function(submitElm) {
    if(submitElm.attr('submit_once')){
      submitElm.attr('disabled', 'disabled');
      return false;
    }else{
      submitElm.attr('submit_once', true);
    }
  }

  $submit.click(function() {
    if(typeof(validatorFn) == 'function' && validatorFn()) {
      return toggleDisabled($submit);
    } else {
      return toggleDisabled($submit);
    }
  });
}
