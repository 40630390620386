import React, {useState, useEffect} from 'react';
import CollectionArticleCard from './CollectionArticleCard';

const KeywordSearchedArticles = ({keywordId, keywordBubble, keywordArticles, basePath, removeKeywordAndArticles, setTotalArticlesCount}) => {

  const [keywordSearchedArticles, setKeywordSearchedArticles] = useState(keywordArticles || []);

  const handleRemoveArticleFromKeyword = (id) => {
    $.ajax({
      type: 'DELETE',
      url: `${basePath}`,
      dataType: 'json',
      data: { article_id: id },
      success: function(res){
        const removedArticleId = res.removed_article.article_id;
        handleRemovedArticleFromKeywordSearch(removedArticleId);
        setTotalArticlesCount(res.articlesCount)
      }
    })
  }

  const handleRemovedArticleFromKeywordSearch = (articleId) => {
    const newArticleList = keywordSearchedArticles.filter((article) => article.id !== articleId);
    setKeywordSearchedArticles(newArticleList);
  }

  const renderKeywordSearchedArticles = () => {
    return (
      <div className="articles-container">
        {keywordSearchedArticles.map((article) => {
          const { id, published_at, type, title, authors } = article;
          return (
            <CollectionArticleCard
              key={id}
              id={id}
              published_at={published_at}
              type={type}
              title={title}
              authors={authors}
              basePath={basePath}
              handleRemoveArticle={handleRemoveArticleFromKeyword}
            />
          )
        })}
      </div>
    )
  }

  return (
    <div className='keyword-searched'>
      <div className="keyword-bubbles-section">
        <div className="row">
          {keywordBubble && <div className="large-1 columns bubble">{keywordBubble}</div>}
          <img className="close-button" src={"https://public.cureus.com/homepage_new/delete-x.png"} onClick={() => removeKeywordAndArticles(keywordId)} />
        </div>
      </div>

      <div className="article-list-section">
        <div className="row">
          {renderKeywordSearchedArticles()}
        </div>
      </div>
    </div>
  )
}

export default KeywordSearchedArticles;