function sticky(id, height, opts){
  var container = document.getElementById(id);
  if(opts != undefined){
    var elem = document.querySelector(opts['selector']);
  }
  if(window.scrollY > height){
    container.addClass('sticky');
  }

  var toggled;
  window.addEventListener('scroll', function(){
    var container = document.getElementById(id);
    if (toggled) {
      // when we add the sticky class, it can potentially change window.scrollY thus causing a subsequent
      // scroll event to fire without the user having actually scrolled. set toggle to true when adding the
      // class and unset it here without doing anything else to prevent rubberbanding.
      toggled = false;
      return;
    }
    if(window.scrollY > height && !toggled){
      container.addClass('sticky');
      if (elem !== undefined) {
        elem.addClass('sticky-enabled');
      }
      toggled = true;
    }else{
      container.removeClass('sticky');
      if(elem !== undefined){
        elem.removeClass('sticky-enabled');
      }
    }
  }, false);
}

export { sticky }