var createReactClass = require('create-react-class');
import React from 'react';
import ContactCoAuthors from '../ContactCoAuthors';

const Validator = createReactClass({
  getInitialState: function() {
    return {
      disclosure: this.props.disclosure,
      disclosureFields: this.props.disclosure_fields,
      optionalIrbName: this.props.disclosure_fields.HumanSubjectsOptionalIrbName.text_data || '',
      optionalStatement: this.props.disclosure_fields.HumanSubjectsOptionalStatement.text_data || '',
      approvalStatement: this.props.disclosure_fields.HumanSubjectsApprovalStatement.text_data || '',
      approvalStatementDisabled: "disabled"
    }
  },


  componentDidMount: function() {
    if(this.props.isCaseReport && this.props.disclosure.declared === null) {
      $("[data-radio-true]").click()
    }
    this.checkInitialToggle();
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");
    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  checkInitialToggle: function() {
    this.checkValid();
  },

  checkValid: function() {
    if ($("[data-radio-true]").is(":checked")) {
      $(this.props.toggleableElem).show();
      this.handleButtonDisable();
    } else if ($("[data-radio-false]").is(":checked") ) {
      $(this.props.toggleableElem).hide();
      this.enableNext();
    }
  },

  handleButtonDisable: function() {
    if ( this.handleTrueHumanSubjectsSelection()) {
      this.handleHumanSubjectsStatements()
    }
    else {
      this.disableNext()
    }
  },

  handleTrueHumanSubjectsSelection: function() {
    return ( $("[data-human-subjects-confirm-informed-consent]").is(":checked") && $("[data-human-subject-confirm-identification]").is(":checked") )
  },

  handleHumanSubjectsStatements: function() {
    if ( this.state.optionalIrbName || this.state.optionalStatement ) {
      this.setState({approvalStatementDisabled: ''})
      this.checkApprovalStatement()
    }
    else {
      this.setState({approvalStatementDisabled: "disabled"})
      this.enableNext()
    }
  },

  checkApprovalStatement: function() {
    this.state.approvalStatement ? this.enableNext() : this.disableNext()
  },

  optionalStatementChange: function(event) {
    this.setState({optionalStatement: event.target.value}, this.checkValid )
  },

  optionalIrbNameChange: function(event) {
    this.setState({optionalIrbName: event.target.value}, this.checkValid )
  },

  approvalStatementChange: function(event) {
    this.setState({approvalStatement: event.target.value}, this.checkValid )
  },

  enableNext: function() {
    $(this.props.nextButton).attr("disabled", false);
  },

  disableNext: function() {
    $(this.props.nextButton).attr("disabled", true);
  },

  checkConfirmInformedConsent: function() {
    return this.state.disclosureFields.HumanSubjectsConfirmInformedConsent.boolean_data ? "checked" : ""
  },

  checkconfirmIdentification: function() {
    return this.state.disclosureFields.HumanSubjectsConfirmIdentification.boolean_data ? "checked" : ""
  },

  checkDisclosureDeclaredFalse: function() {
    return this.state.disclosure.declared == false ? "true" : ""
  },

  checkDisclosureDeclaredTrue: function() {
    return this.state.disclosure.declared == true ? "true" : ""
  },

  render: function() {
    return (
      <div className = "human_subjects_form" ref="active">
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
        <div className="row">
          <div className="small-12 columns">
            <div className="stacked-radio-inputs">
            <input id={ this.props.article_type + "_disclosures_attributes_1_id"} name={ this.props.article_type + "[disclosures_attributes][1][id]"} type="hidden" value={ this.state.disclosure.id } />

              <h4>Human Subjects</h4>
              <p>Did you study human participants or live human tissue? <strong>Important:</strong>  You must select 'Yes' for all patient-related submissions, including retrospective studies, case reports and studies where IRB approval was waived.</p>
              <input data-radio-false id={ this.props.article_type + "_disclosures_attributes_1_declared_false"} name={ this.props.article_type + "[disclosures_attributes][1][declared]"} type="radio" defaultValue="false" defaultChecked={this.checkDisclosureDeclaredFalse()} onChange={ this.checkValid }/>
              <label htmlFor={ this.props.article_type + "_disclosures_attributes_1_declared_false"}>
                No, this study does not involve human participants or live human tissue.
              </label>
              <br/>
              <input data-radio-true id={ this.props.article_type + "_disclosures_attributes_1_declared_true"} name={ this.props.article_type + "[disclosures_attributes][1][declared]"} type="radio" defaultValue="true" defaultChecked={ this.checkDisclosureDeclaredTrue() } onChange={ this.checkValid }/>
              <label htmlFor={ this.props.article_type + "_disclosures_attributes_1_declared_true"}>
                Yes, this study involves human participants or live human tissue.
              </label>

              <ContactCoAuthors
                author_emails={ this.props.author_emails }
                submitter_email={ this.props.submitter_email}
                event_klass={ this.props.event_klass }
                shareEmailUrl={ this.props.shareEmailUrl}/>
            </div>
          </div>
        </div>

        <div id="human_subjects">
          <div className="stacked-radio-inputs">
            <div className="row">
              <div className="small-12 columns">
                <h6>Required: </h6>

                <input name={this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][3][data]"} type="hidden" defaultValue="0"/>
                <input id={this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_3_id"} name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][3][id]"} type="hidden" defaultValue={ this.state.disclosureFields.HumanSubjectsConfirmInformedConsent.id }/>
                <input id={this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_3_data" } data-human-subjects-confirm-informed-consent name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][3][data]"} type="checkbox" defaultChecked={ this.checkConfirmInformedConsent() } onChange={ this.checkValid }/>
                <label htmlFor={this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_3_data" } className="human-subjects-confirm-informed-consent-label">
                  Please confirm that informed consent (verbal/written) has been obtained or waived, <strong> particularly with retrospective case reports and small case series. </strong>
                </label>
                <br/>
                <input name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][4][data]" } type="hidden" defaultValue="0"/>
                <input id= { this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_4_id" } name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][4][id]"} type="hidden" defaultValue={ this.state.disclosureFields.HumanSubjectsConfirmIdentification.id }/>
                <input id={ this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_4_data" } data-human-subject-confirm-identification name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][4][data]"} type="checkbox" defaultChecked={ this.checkconfirmIdentification() } onChange={ this.checkValid }/>
                <label htmlFor={ this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_4_data"} className="human-subject-confirm-identification-label">
                  Please confirm that no identifying information (in the text or image) appears in your article, or if so, confirm that patient consent was obtained prior to submitting it for publication.
                </label>
              </div>
            </div>
          </div>
          <h6>Attention: IRB and Ethics Committee Information </h6>
          <p>
            All prospective clinical studies and research involving human subjects are required to provide IRB/ethics committee approval details.
            <i> When the institution that sponsors a retrospective clinical study mandates IRB review, an IRB approval number <u>must</u> be provided.</i>
          </p>
          <input id={ this.props.article_type + "_disclosures_attributes_0_id"} name={ this.props.article_type + "[disclosures_attributes][2][id]"} type="hidden" defaultValue={ this.state.disclosure.id }/>
          <div className= "row">
            <div className= "small-6 columns">
              <label htmlFor="irb-name-field">
                 Institutional Review Board (IRB) or ethics committee (provide full name)
              </label>
            </div>
            <div className= "small-6 columns">
              <label htmlFor="approval-number-field">
                 Approval number (if applicable)
              </label>
            </div>
          </div>

          <div className= "row">
            <div className= "small-6 columns">
              <input id="irb-name-field" name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][0][data]"} type="text" value={this.state.optionalIrbName} onChange={this.optionalIrbNameChange}/>
              <input id={ this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_0_id"} name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][0][id]"}  type="hidden" defaultValue={ this.state.disclosureFields.HumanSubjectsOptionalIrbName.id }/>
            </div>
            <div className= "small-6 columns">
              <input id="approval-number-field" disabled={this.state.approvalStatementDisabled} type="text" name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][1][data]"} onChange={this.approvalStatementChange} value={this.state.approvalStatement}/>
              <input id={this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_1_id"} name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][1][id]"} type="hidden" defaultValue={ this.state.disclosureFields.HumanSubjectsApprovalStatement.id }/>
            </div>
          </div>

          <div className="row">
            <div className="small-12 columns">
              <label htmlFor={this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_2_data"}>
                Statement indicating approval of research
              </label>
              <textarea className="larger-text-pizzas" id="human-subjects-text-area" name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][2][data]"} onChange={this.optionalStatementChange } defaultValue={ this.state.disclosureFields.HumanSubjectsOptionalStatement.text_data }></textarea>
              <input id= {this.props.article_type + "_disclosures_attributes_2_disclosure_fields_attributes_2_id"} name={ this.props.article_type + "[disclosures_attributes][2][disclosure_fields_attributes][2][id]"} type="hidden" defaultValue={ this.state.disclosureFields.HumanSubjectsOptionalStatement.id }/>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default Validator;