
import React, { useEffect, useState } from 'react'
import {Cureus} from '../../../Cureus';

const EditorMessagesTable = ({indexPath, updateTablePath}) => {

  const [messagesToRemove, setMessagesToRemove] = useState([]);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: indexPath,
      success: function(data) {
        populateTable(data.data);
      }
    });
  }

  const updateMessageTable = (messagesToRemove) => {
    $.ajax({
      type: 'PUT',
      dataType: 'json',
      url: updateTablePath,
      data: {message_ids: messagesToRemove},
      success: function(data) {
        populateTable(data.data);
        Cureus.Flash.success("Messages successfully updated");
        setShowButton(false);
      }
    });
  }

  const setColumns = () => {
    return [
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '160px', class: 'centered'},
      {width: '320px'},
      {width: '320px'}
    ]
  }

  const parseUser = (userFullName, userId) => {
    return `<a target='_blank' href='/admin/users/${userId}'>${userFullName}</a>`
  }

  const parseResponsesNeeded = (responseList) => {
    let ListOfResponses = responseList.map((response) => {
      return `<li>${response}</li>`
    })
    return `<ul>${ListOfResponses.join('')}</ul>`
  }

  const parseArticleLink = (article_id, article_title, showTitle=false) => {
    return `<a target='_blank' href='/admin/articles/${article_id}'>${showTitle ? article_title : article_id}</a>`;
  }

  const renderCheckbox = (message_id) => {
    return `<input id="${message_id}_id" className="checkbox" type="checkbox" name="checkbox"/>`;
  }

  const handleDelete = () => {
    let messagesToRemove = [];
    window.document.querySelectorAll('input[type="checkbox"][name=checkbox]:checked').forEach((checkbox) => {
      messagesToRemove.push(checkbox.getAttribute("id").split("_")[0])
    })
    setMessagesToRemove(messagesToRemove);
    updateMessageTable(messagesToRemove);
  }

  const populateTable = (data) => {
    let dt = $('#editor-messages-table').DataTable({
        dom: '<"row"<"large-8 columns"f>><"table"t>r<"row bottom"<"large-4 columns page-nums"p><"large-3 columns end show-amount"l>>',
        destroy: true,
        columns: setColumns(),
        searching: true,
        pageLength: 20, 
        rowCallback: function(row) {
          row.addEventListener('click', function() {
            if (document.querySelectorAll('input[type="checkbox"][name=checkbox]:checked').length == 0) {
              setShowButton(false);
            } else {
              setShowButton(true);
            }
          });
        }
    });

    dt.api().clear();
    for (var i = 0; i < data.length; i++) {
      let row = data[i];
      dt.fnAddData( [
        renderCheckbox(row.message_id),
        row.created_at,
        parseUser(row.author_name, row.author_id),
        parseArticleLink(row.article_id, row.title),
        row.type,
        row.tier_badge,
        parseArticleLink(row.article_id, row.title, true),
        parseResponsesNeeded(row.response_array)
      ], false)
    }
    dt.fnDraw();
  }

  const renderDataTable = () => {
    return (
      <table id="editor-messages-table" className='table-wrapper data-table'>
        <thead>
          <tr>
            <th className="single-action-icon"><i className="far fa-trash-alt"/></th>
            <th className='centered'>Date</th>
            <th className='centered'>Posted By</th>
            <th className='centered'>Article ID</th>
            <th className='centered'>Type</th>
            <th className='centered'>Tier</th>
            <th className='centered'>Title</th>
            <th className='centered'>Tagged</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    )
  }

  return (
    <div className='main'>
      <a style={{display: showButton ? 'inline-block' : 'none'}} className="button alert" onClick={() => handleDelete()}>Delete</a>
      {renderDataTable()}
    </div>
  )
}

export default EditorMessagesTable