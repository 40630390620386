var createReactClass = require('create-react-class');
import React from 'react';
import Info from './Info';
import Selection from './Selection';
import Main from '../StripeV2/Main';

const BoostModal = createReactClass({
  getInitialState: function() {
    return {
      currPage: 1,
      selected: null
    }
  },

  componentDidMount: function() {
    this.fireAmplitude()
  },

  fireAmplitude: function() {
    var self = this;
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { article_id: self.props.articleId,
              event_type: 'socialBoostViewed',
              user_id: self.props.currentUserId,
              source: self.props.ampSource
            }
    })
  },

  handleNext: function() {
    var nextPage = this.state.currPage + 1
    this.setState({currPage: nextPage})
  },

  handleBack: function() {
    var prevPage = this.state.currPage - 1
    this.setState({currPage: prevPage})
  },

  handleSelection: function(e) {
    var selection = e.target.getAttribute('name')
    this.setState({selected: selection, currPage: 2})
  },

  setPrice: function() {
    switch (this.state.selected) {
      case 'premium': return 9900;
      case 'standard': return 6000;
      case 'premium-plus': return 19900;
      case 'maximum': return 49900;
    }
  },

  renderBody: function() {
    if(this.state.currPage === 1) {
      return (
        <Selection
          handleBack={this.handleBack}
          handleNext={this.handleNext}
          handleSelection={this.handleSelection}
          selected={this.state.selected}
          title={this.props.title}
          paymentsPage={this.props.paymentsPage}
          copyText={this.props.copyText}
          subsequentBoost={this.props.subsequentBoost}
        />
      )
    } else if(this.state.currPage === 2) {
      return (
        <Main
          product={'social_boost'}
          handleBack={this.handleBack}
          selected={this.state.selected}
          articleId={this.props.articleId}
          optional={false}
          source="dashboard"
          ampSource={this.props.ampSource}
          price={this.setPrice()}
          copyText={this.props.copyText}
          paymentsPage={this.props.paymentsPage}
          subsequentBoost={this.props.subsequentBoost}
        />
      )
    }
  },

  renderContents: function() {
    if(this.props.paymentsPage) {
      return this.renderBody()
    } else {
      return (
        <div className="reveal-modal large boost-modal" data-reveal>
          {this.renderBody()}
        </div>
      )
    }
  },

   render: function() {
    return this.renderContents()
  }
})

export default BoostModal;