var createReactClass = require('create-react-class');
import React from 'react';
import ThankYouModal from './ThankYouModal';
import Modal from '../Modal';
import Slider from './Slider';
import { Cureus } from '../../Cureus';
import _ from "lodash";
import SiqBadgeImage from "../Shared/SiqBadgeImage"

const ScoringModal = createReactClass({
  getInitialState: function(){
    return {
      score: 1.0,
      criteria: this.props.siq_widget.criteria,
      hasScore: false,
      disabled: '',
      class: ''
    }
  },

  handleCancel: function(){
    Modal.close();
  },

  componentDidMount: function(){
    Cureus.Slider.init();
  },

  renderScoringExplanation: function() {
    if (!this.state.hasScore) {
      return null
    }
    if (this.state.score <= 2 || this.state.score >= 9) {
      var text = this.state.score <= 2 ? "Please justify your low rating with a brief explanation." : "Please justify your high rating with a brief explanation."
      return(
        <div className="scoring-explanation">
          <input id="scoring_explanation_present" name="scoring[explanation_present]" defaultValue="true" type="hidden" />
          <label htmlFor="scoring_explanation">{text}</label>
          <textarea cols="40" id="scoring_explanation" name="scoring[explanation]" rows="8" required="required"/>
          <div id="scoring_explanation_error_message" className="error"/>
        </div>
      )
    } else {
      return(
        <input id="scoring_explanation_present" name="scoring[explanation_present]" defaultValue="false" type="hidden" />
      )
    }
  },

  handleNext: function() {
    var self = this;
    self.setState({disabled: 'disabled'})
    var $form = $(self.refs.form);
    var formData = $form.serialize()
    $.ajax({
      type: "GET",
      url: self.props.siq_widget.query_score_url,
      data: $form.serialize(),
      success: function(data) {
        self.setState({score: data.score, criteria: data.criteria, hasScore: true, disabled: '', serializedScore: formData, class: "next"})
      },
      error: function() {
        self.setState({hasScore: false, disabled: ''})
        alert("Oops, your SIQ rating could not be saved. Please refresh the page and try again.");
      }
    });
  },

  fireAmplitude: function(eventName) {
    var self = this;
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: {
        articleId: self.props.siq_widget.articleId,
        event_type: eventName,
        user_id: self.props.siq_widget.currentUserId
      }
    })
  },

  handleSubmit: function(e){
    var self = this;

    self.clearErrors();
    var $form = $(self.refs.form);
    e.preventDefault();
    $.ajax({
      type: "POST",
      url: self.props.siq_widget.score_url,
      data: ($form.serialize() + '&' + self.state.serializedScore),
      success: function(data) {
        if (data.spammer) {
          window.location.reload()
        } else if(data.success){
          var $triggers = $(".siq-widget-trigger");
          $triggers.addClass("disabled");
          $triggers.html("already rated");
          $triggers.attr("disabled", "disabled");
          if (document.getElementById('score_article').classList.contains('pdf')) {
            self.fireAmplitude("postPdfSiqSubmitted")
          }
          Modal.close();

          self.updateValuesForSiqHoverComponent(data)
          self.handleThankYou(data.score, data.categoryValues, data.totalScores, data.categoryProgress);
        }else{ // comes back as data.error
          Object.keys(data.error).forEach(function(k){
            $('#scoring_' + k).addClass('error')
            $('#scoring_' + k + "_error_message").html(data.error[k].join(', '))
          })
        }

      },
      error: function() {
        alert("Oops, your SIQ rating could not be saved. Please refresh the page and try again.");
        Modal.close();
      }
    });
  },

  updateValuesForSiqHoverComponent: function(data) {
    this.props.setJustScored(true)
    if (data.score) {
      var newObject = _.cloneDeep(this.props.articleRatingsAndSiq)
      newObject.siq_categories = data.siq_categories
      newObject.siq_breakdown = data.siq_breakdown
      newObject.scorings_count = data.totalScores + 1
      this.props.setArticleRatingsAndSiq(newObject)
    }
  },

  clearErrors: function(){
    ["disclosure_declared", "disclosure", "explanation"].forEach(function(el){
      $("#scoring_" + el).removeClass('error');
      $("#scoring_" + el + "_error_message").html('');
    });
  },

  handleThankYou: function(score, categoryValues, totalScores, categoryProgress){
    var self = this;
    Modal.open({
      children:
        <ThankYouModal
          totalScores={totalScores}
          categoryValues={categoryValues}
          score={score}
          article_base_class={ self.props.siq_widget.article_base_class }
          categoryProgress={categoryProgress}
        />
    });
  },

  showCOI: function(){
    this.clearErrors();
    $("#scoring_disclosure").attr('disabled', false);
    $("#scoring_disclosure").show();
  },

  hideCOI: function(){
    $("#scoring_disclosure").val('');
    $("#scoring_disclosure").attr('disabled', true);
    $("#scoring_disclosure").hide();
  },

  handlePrev: function(){
    var self = this;
    self.setState({hasScore: false, class: ""}, function() {
      Cureus.Slider.init();
      self.restoreSliderValues();
    })
  },

  restoreSliderValues: function(){
    $('.hidden-scoring-value').each(function(_,elem){
      $($(elem).parent().find(".ui-slider-label-ticks")[parseInt(elem.value)-1]).find("span").click()
    })
  },

  renderModalBody: function () {
    var self = this;
    if (!this.state.hasScore) {
      return (
        <form ref='form' id='scoring-form'>
          <div className="continue-scoring">
            <div className="row">
              <div className="small-12 columns how-rate-header">
                <h4>How do you rate this article?</h4>
              </div>
            </div>
            <div className="siq-slider-wrap">
              <p id="pdf-source"></p>
            </div>
            <div className='siq-slider-wrap'>
              {
                Object.values(self.state.criteria).map(function(criterium, index){
                  return (
                    <Slider
                      key={ index }
                      criterium={ criterium }
                    />
                  )
                })
              }
            </div>
            <div className='modal-footer hide-for-small'>
              <a onClick={ this.handleCancel } className="button secondary cancel-modal">Cancel</a>
              <a className={"button primary continue-scoring-btn " + self.state.disabled} onClick={ this.handleNext }>Next</a>
            </div>
            <div className='modal-footer show-for-small'>
              <div className="row">
                <div className="small-6 columns">
                  <a onClick={ this.handleCancel } className="button secondary cancel-modal">Cancel</a>
                </div>
                <div className="small-6 columns">
                  <a className={"button primary continue-scoring-btn " + self.state.disabled} onClick={ this.handleNext }>Next</a>
                </div>
              </div>
            </div>
          </div>
        </form>
      )
    } else {
      return (
        <form ref='form' id='scoring-form'>
          <div className="row collapse">
            <div className="large-8 columns large-text-left small-text-center">
              <h4>Conflicts of Interest</h4>
            </div>
            <div className="large-4 columns large-text-right small-text-center">
              <h4>SIQ:&nbsp;<span className="siq-score">{ this.state.score.toPrecision(2) }</span></h4>
            </div>
          </div>
          <p>
            Please disclose any and all potential conflicts of interest below. Please note that while declaring a conflict of interest will not prevent you from rating this article, it will affect the weight assigned to your SIQ rating. Cureus adheres to <a href="http://www.google.com/url?q=http%3A%2F%2Fwww.icmje.org%2Frecommendations%2Fbrowse%2Froles-and-responsibilities%2Fauthor-responsibilities--conflicts-of-interest.html&amp;sa=D&amp;sntz=1&amp;usg=AFQjCNFeoRXuClocJQNlRN6MRyV66pNWCA"  target="_blank">ICMJE guidelines</a> concerning conflicts of interest. To learn more about what constitutes a conflict of interest, please refer to the <a href="/author_guide"  target="_blank">Conflicts of Interest</a> section of the Cureus Author Guide.
          </p>
          <span>Based on the above guidelines, do you have any conflicts of interest to disclose?</span>

          <div id="scoring_disclosure_declared">
            <input onClick={ this.hideCOI } className="dd-radio-false" id="scoring_disclosure_declared_false" name="scoring[disclosure_declared]" required="required" required type="radio" value="false"/>
            <label className="inline text-left" htmlFor="scoring_disclosure_declared_false">No</label>
            &nbsp;&nbsp;
            <input onClick={ this.showCOI } className="dd-radio-true" id="scoring_disclosure_declared_true" name="scoring[disclosure_declared]" required="required" required type="radio" value="true" />
            <label className="inline text-left" htmlFor="scoring_disclosure_declared_true">Yes</label>
          </div>
          <div id="scoring_disclosure_declared_error_message" className="error"/>

          <textarea cols="40" id="scoring_disclosure" name="scoring[disclosure]" rows="8" />
          <div id="scoring_disclosure_error_message" className="error"/>

          { this.renderScoringExplanation() }

          <div className='modal-footer hide-for-small'>
            <a onClick={ this.handlePrev } className="button secondary left">Prev</a>
            <a onClick={ this.handleCancel } className="button secondary cancel-modal">Cancel</a>
            <a className="button primary submit-siq-score" onClick={ this.handleSubmit}>Submit</a>
          </div>
          <div className='modal-footer show-for-small'>
            <div className="row">
              <div className="small-4 columns">
                <a onClick={ this.handlePrev } className="button secondary left">Prev</a>
              </div>
              <div className="small-4 columns">
                <a onClick={ this.handleCancel } className="button secondary cancel-modal">Cancel</a>
              </div>
              <div className="small-4 columns">
                <a className="button primary submit-siq-score" onClick={ this.handleSubmit}>Submit</a>
              </div>
            </div>
          </div>
        </form>
      )
    }
  },

  renderHeader: function() {
    if(this.state.class === 'next') {
      return (
        <img src={ this.props.siq_widget.icmje_img_url }/>
      )
    } else {
      return (
        <>
          <div class="siq-image">
            <SiqBadgeImage
              siq={this.props.siq_widget.curr_score}
              width={"100px"}
            />
          </div>
          <div class="text-center siq-badge-score">
            {this.props.siq_widget.curr_score}
          </div>
        </>
      )
    }
  },


  render: function(){
    var self = this;

    return (
      <div ref='modal' data-reveal className='reveal-modal mobile-middling' id='score_article'>
        <div className="modal-content">
          <div className='modal-body'>
          <div className={"modal-header " + this.state.class}>
            {this.renderHeader()}
          </div>
          {this.renderModalBody()}
          </div>
        </div>
      </div>
    );
  }
});

export default ScoringModal;