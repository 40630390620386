var createReactClass = require('create-react-class');
import React from 'react';
import Form from '../../UserPicker/Form';
import * as UserToolUtils from '../../Publishing/UserTool/ApiClient';
import Modal from '../../Modal';

const NewModal = createReactClass({
  getInitialState: function() {
    return({
      showRequirementMessage: this.props.showRequirementMessage,
      editing: null,
      searchEmail: '',
      isAuthorships: this.props.isAuthorships,
      loading: false,
      usership: {user: null},
      showSpinner: false,
      showCheckingForUser: false,
      didSearch: false,
      addReviewer: false
    })
  },

  componentDidMount: function() {
    var self = this;
    if (this.state.addReviewer) {
      $(this.refs.container).on("opened", function (evt){
        self.setState({focusOn: "email"});
        document.querySelector('.reveal-modal-bg').style.display = "block";
      });
    } else {
      $(this.refs.container).on("opened", function (evt){
        document.getElementById('email-input').focus()
      });
    }
  },

  handleSearch: function(email) {
    var self = this;
    email = self.state.searchEmail
    self.setState({addReviewer: true})

    if(email.length == 0) { return; }

    self.setState({showSpinner: true, showCheckingForUser: true, didSearch: false});
    UserToolUtils.getSearch({
      email: email,
      endpointUrl: self.props.endpointUrl,
      params: self.props.defaultParams,
      success: function(data) {
        self.setState({
          usership: {editable: data.editable, user: data},
          showSpinner: false,
          showCheckingForUser: false,
          errors: null,
          focusOn: (data.id ? "submit" : "firstName"),
          didSearch: true
        });
      },
      error: function(jqXHR) {
        self.setState({
          showSpinner: false,
          showCheckingForUser: false,
          errors: JSON.parse(jqXHR.responseText),
          didSearch: true
        });
      }
    });
  },

  handleSubmit: function(params) {
    var self = this;

    UserToolUtils.postCreate({
      params: Object.assign(params, self.props.defaultParams),
      endpointUrl: self.props.endpointUrl,
      success: function(data) {
        self.setState({
          user: null,
          errors: null,
          showSpinner: false,
          didSearch: false
        });
        if (self.props.inReview) {
          self.props.handleReviewerAdd()
        }
        self.clearEmailField();
        UserToolUtils.getIndex({
          endpointUrl: self.props.endpointUrl,
          params: self.props.defaultParams,
          success: self.props.updateUserships
        })
        $(self.refs.container).foundation("reveal", "close");
      },
      error: function(jqXHR) {
        self.setState({
          errors: JSON.parse(jqXHR.responseText),
          showSpinner: false,
          didSearch: false
        });
      }
    });
  },

  submitText: function() {
    if (this.props.defaultParams.channelAdmins) { return "Add admin"}
    if (this.props.defaultParams.channelEditors) { return "Add editor"}
    return this.props.isAuthorships ? 'Add author' : 'Invite reviewer';
  },

  title: function() {
    if (this.props.defaultParams.channelAdmins) { return "Add an Admin"}
    if (this.props.defaultParams.channelEditors) { return "Add an Editor"}
    return this.props.isAuthorships ? 'Add an Author' : 'Invite a Reviewer';
  },

  closeModal: function() {
    Modal.close()
  },

  updateSearchEmail: function(e) {
    this.setState({searchEmail: e.target.value.trim()});
  },

  handleKeyPress: function(e) {
    if(e.key === 'Enter') {
      e.preventDefault();
      this.handleSearch();
    }
  },

  clearEmailField: function() {
    this.setState({searchEmail: ''});
  },

  renderModalContent: function() {
    if (this.state.addReviewer) {
      return (
        <div className="modal-content">
          <Form
            key={"new_author_form"}
            resource={this.state.usership}
            subject="Author"
            title={this.title()}
            submitText={this.submitText()}
            enableSearch={true}
            specialtyOptions={this.props.specialtyOptions}
            countryOptions={this.props.countryOptions}
            affiliationNameQueryUrl={this.props.affiliationNameQueryUrl}
            onSearch={this.handleSearch}
            onSubmit={this.handleSubmit}
            showSpinner={this.state.showSpinner}
            showCheckingForUser={this.state.showCheckingForUser}
            errors={this.state.errors}
            focusOn={this.state.focusOn}
            hideInstitutionFields={false}
            didSearch={this.state.didSearch}
            email={this.state.searchEmail}
            />
        </div>
      )
    } else {
      return (
        <div className="modal-content">
          <h4>Invite Reviewers</h4>
          <p>Improve your chances of efficient, timely review by inviting more reviewers.</p>
          <p>Search for an email address to see if your reviewer already has a Cureus account. If no results come up, please add them to Cureus. Don’t worry - we’ll send the reviewers an email with detailed peer review instructions.</p>

          <span>Reviewer email address:</span>
            <div className='row'>
            <input id="email-input" style={{width: '73%', display: "inline-block", marginRight: 8, marginTop: 8}} type='text' placeholder='example@email.com' onChange={ this.updateSearchEmail } onKeyPress={ this.handleKeyPress } value={this.state.searchEmail}/>
            <a className='button' style={{width: '25%'}} onClick={ this.handleSearch }>SEARCH</a>
          </div>
          <div className='row centered' style={{marginTop: 20}}>
            <a className='button secondary' onClick={ this.closeModal }>CANCEL</a>
          </div>
        </div>
      )
    }
  },

  render: function() {
    return (
      <div className="reveal-modal middling user-picker-modal" data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          {this.renderModalContent()}
        </div>
      </div>

    )
  }
})

export default NewModal;