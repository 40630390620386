var createReactClass = require('create-react-class');
import React from 'react';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const AddToList = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("FilterTool")
  ],

  getStateFromFlux: function (){
    var flux = this.getFlux();
    return {
      activeFilters: flux.store("FilterTool").getState().filters
    }
  },

  handleAddToList: function (evt){
    var self;
    
    self = this;
    evt.preventDefault();

    $.ajax({
      type: "PUT",
      dataType: "json",
      url: this.props.updateUrl,
      data: this.prepareData()
    })
      .done(function (data, textStatus, jqXHR){
        window.location.replace(self.props.redirectUrl);
      })
      .error(function (jqXHR, textStatus, errorThrown){
        // TODO: Handle error case
      });
  },

  prepareData: function (){
    var output;

    output = {
      staged: this.props.staged,
      selectAll: this.props.selectAll,
      search: {value: this.props.query},
      subscribed: true // Cannot click this button when unsubscribed selected
    };

    if(this.state.activeFilters.length > 0){
      output["filters"] = this.state.activeFilters;
    }

    return output;
  },
 
  render: function (){
    if(this.props.disabled) {
      return (
        <a className="button primary disabled">
          Add to List
        </a>
      )
    } else {
      return (
        <a className="button primary" onClick={this.handleAddToList}>
          Add to List
        </a>
      )
    }
  }
});

export default AddToList;