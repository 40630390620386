import React, {useState, useEffect} from 'react';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import Sidebar from './Sidebar';

const Main = ({categoriesList}) => {

  const [activeTab, setActiveTab] = useState('sidebar');

  return (
    <div className='small-12 columns'>
      <Router hashType={"hashbang"}>
        <div className="row">
          <div className="large-12 columns">
            <div className="cureus-tabs secondary-tabs dashboard homepage">
              <ul className="tabs">
                <Link to="/sidebar">
                  <li className={"tab-title " + (activeTab === 'sidebar' ? 'active' : '')} onClick={() => setActiveTab('sidebar')}>
                  <a className="dashboard">SIDEBAR</a>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
            <Switch>
              <Route path='/sidebar'>
                <Sidebar categoriesList={categoriesList} />
              </Route>
            </Switch>
        </div>
      </Router>
    </div>
  )
}

export default Main;