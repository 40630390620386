import React, { useState, useEffect } from 'react';
import LineChart from './LineChart';
import BarChart from './BarChart';
import IntervalButton from './IntervalButton'

const ReadsChart = ({metricsPaths, publishedAt, firstMonth}) => {
  const [lineData, setLineData] = useState({})
  const [barData, setBarData] = useState({})
  const [buttonInterval, setButtonInterval] = useState(firstMonth ? "first_30" : "all")

  useEffect(() => {
    fetchData('bar')
  }, [])

  useEffect(() => {
    fetchData(buttonInterval)
  }, [buttonInterval])

  const fetchData = (interval) => {
    $.ajax({
      type: "GET",
      url: metricsPaths.chart_data,
      data: {published_at: publishedAt, interval: interval},
      dataType: "JSON",
      success: function(res) {
        if(interval === 'bar') {
          setBarData(res)
        } else {
          setLineData(res)
        }
      }
    })
  }

  const numberWithCommas = (x) => {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
  }    

  return (
    <div className="row small-collapse">
      <div className="large-12 columns">
        <div className="metrics-section">
          <h3 className="reg">Article Reads by Date</h3>
          <p>
            Hover your cursor over a specific day or month to see the corresponding online article views and PDF downloads. Cureus data is delayed by 24 hours and PMC data is delayed by approximately one month
          </p>
          <div className="interval-buttons-container">
            <IntervalButton
              title="All Time"
              buttonInterval={buttonInterval}
              setButtonInterval={setButtonInterval}
              intervalName={"all"}
            />
            <IntervalButton
              title="First 30 days"
              buttonInterval={buttonInterval}
              setButtonInterval={setButtonInterval}
              intervalName={"first_30"}
            />
            <IntervalButton
              title="Last 30 days"
              buttonInterval={buttonInterval}
              setButtonInterval={setButtonInterval}
              intervalName={"last_30"}
            />
            <IntervalButton
              title="Last 12 months"
              buttonInterval={buttonInterval}
              setButtonInterval={setButtonInterval}
              intervalName={"last_year"}
            />
          </div>
          <LineChart 
            ctx={'line-chart'}
            datasets={lineData.events}
            backgroundColor="white"
            numberWithCommas={numberWithCommas}
          />
          <BarChart 
            ctx={'stacked-bar-chart'}
            datasets={barData.bars}
            backgroundColor="white"
            numberWithCommas={numberWithCommas}
          />
        </div>
      </div>
    </div>
  )
}

export default ReadsChart;