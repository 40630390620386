var createReactClass = require('create-react-class');
import React from 'react';
import FlagRow from './FlagRow';

const Row = createReactClass({
  getInitialState: function() {
    return({
      editMode: false,
      comment: this.props.comment,
      commentBody: this.props.comment.body,
      openFlag: null,
      canManage: this.props.canManage
    })
  },

  renderBody: function() {
    if (this.state.editMode) {
      return(
        <td>
          <textarea  value={this.state.commentBody} onChange={this.handleTextChange}/>
          <div className='pull-right'>
            <a style={{marginRight: '10px'}} className='button tiny success' onClick={this.handleUpdate}>Update</a>
            <a className='button tiny secondary'onClick={this.handleCancel}>Cancel</a>
          </div>
        </td>
      )
    } else {
      return(
        <td>{ this.state.comment.body }</td>
      )
    }
  },

  handleTextChange: function(e) {
    this.setState({commentBody: e.target.value})
  },

  handleUpdate: function() {
    var self = this;
    var data = {id: self.state.comment.id, comment: {body: self.state.commentBody}};
    $.ajax({
      type: 'put',
      url: self.props.comment.commentPath,
      data: data,
      success: function(res) {
        var newComment = self.state.comment;
        newComment.body = self.state.commentBody;
        self.setState({comment: newComment}, self.setState({editMode: false}))
      },
      error: function(res) {
      }
    });
  },

  updateFlaggings: function() {
    var newComment = this.state.comment;
    newComment.flaggings.forEach(function(flag) {
      flag.state = 'rejected'
    });
    this.setState({comment: newComment, openFlag: null}, Cureus.Flash.success('Flag denied - the comment will remain visible.'));
  },

  handleDelete: function() {
    if (this.state.canManage) {
      this.props.handleDelete(this.state.comment.commentPath, this.state.comment.id)
    }
  },

  toggleEdit: function() {
    if(this.state.canManage) {
      this.setState({editMode: !this.state.editMode});
    }
  },

  handleCancel: function() {
    this.setState({commentBody: this.state.comment.body});
  },

  closeFlag: function() {
    this.setState({openFlag: null})
  },

  flagOpen: function(flag) {
    var self = this;
    return function() {
      self.setState({openFlag: flag});
    }
  },

  renderFlaggings: function() {
    var self = this;
      var flaggings = self.props.comment.flaggings.map(function(flag) {
        return(
          <a href='#' onClick={self.flagOpen(flag)} key={flag.id} >
            <i className={'fa fa-flag ' + flag.state} />
          </a>
        )
      });
      return(
        <td className='centered'>
          {flaggings}
        </td>
      )
  },



  render: function() {
    var self = this;
    if (self.state.openFlag) {
      return(
        <FlagRow flag={self.state.openFlag} closeFlag={self.closeFlag} handleDelete={self.handleDelete} updateFlaggings={self.updateFlaggings}/>
      )
    } else {
      return(
        <tr>
          <td className='nowrap'><a href={ this.props.comment.commentatorUrl }>{ self.props.comment.commentatorName }</a></td>
          <td className='nowrap'>{self.props.comment.created}</td>
          { self.renderBody() }
          { self.renderFlaggings() }
          <td className='single-action-icon'>
            <a onClick={self.toggleEdit } className={self.state.canManage ? '' : 'disabled'}>
              <i className='fa fa-pencil-alt' />
            </a>
          </td>
          <td className='single-action-icon'>
            <a onClick={self.handleDelete} className={self.state.canManage ? '' : 'disabled'}>
              <i className='far fa-trash-alt' />
            </a>
          </td>
        </tr>
      )
    }
  }
});

export default Row;