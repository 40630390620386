import React, { useState } from 'react';

const SocialModalInput = ({handleChange, icon, name, value, placeholder, type}) => {

  return (
    <div className="row admin-channel-design">
      <div className="small-12 columns">
        <div className='social-input-container'>
          <input type='text' placeholder={placeholder} name={name} value={value} onChange={handleChange}/>
          <i className={`${icon} icons`} />
        </div>
      </div>
    </div>
  )
}

export default SocialModalInput;