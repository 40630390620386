import React from 'react';

const PrivacyAndPermissionIssues = () => {
  return (
    <React.Fragment>
      <h1>Privacy and Permission Issues</h1>
      <p>Patient privacy and anonymity must be protected. Exceptions may be made only if the individuals in question have provided explicit approval. For more information about patient privacy, anonymity and informed consent, please refer to the <a href='http://www.icmje.org/recommendations' target='_blank' rel='noreferrer'>ICMJE Privacy and Confidentiality guidelines</a>.</p>
      <p>It is the author’s responsibility to obtain the appropriate permissions from the original publisher to republish any previously published text, figures, tables, supplementary information, etc., in an open access journal under a CC-BY license.</p>
    </React.Fragment>
  )
}

export default PrivacyAndPermissionIssues;