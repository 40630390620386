import React, {useState, useEffect} from 'react';
import { Cureus } from "../../Cureus";

const EditorAnnouncement = ({}) => {

  const [announcement, setAnnouncement] = useState('');
  const [loadedCKEditor, setLoadedCKEditor] = useState(false);

  useEffect(() => {
    if (!loadedCKEditor) {
      new Cureus.CKEditor("notes-to-editors", {
        handle_change: updateAnnouncement,
        editor_type: "static_text_with_color",
        ckeditor: { height: 200 },
        placeholder: "Provide announcements, updates, and reminders to the editors."
      });
      setLoadedCKEditor(true);
    }
    const timeout = setTimeout(retrieveLastAnnouncement, 500);
    return () => clearTimeout(timeout);
  }, [])

  const retrieveLastAnnouncement = () => {
    $.ajax({
      url: "/admin/editor_announcements/last_announcement",
      type: 'GET',
      success: function(response) {
        setAnnouncement(response.announcement);
        CKEDITOR.instances["notes-to-editors"].setData(response.announcement);
      }
    });
  }

  const updateAnnouncement = () => {
    const newAnnouncement = CKEDITOR.instances["notes-to-editors"].getData();
    setAnnouncement(newAnnouncement);
  }

  const createAnnouncement = () => {
    $.ajax({
      url: "/admin/editor_announcements",
      type: 'POST',
      data: { announcement: announcement },
      success: function(response) {
        Cureus.Flash.success('Updated successfully!')
      },
      error: function(error) {
        Cureus.Flash.alert('There was an issue updating the message.')
      }
    });
  }

  return (
    <div>
      <h3 className='reg'>What's New for Editors</h3>
      <p>Add and edit the announcements, updates, and reminders displayed on all Cureus editor dashboards.</p>
      <textarea
        className="columns small-12"
        id="notes-to-editors"
        name="notes-to-editors"
        style={{ height: "300px" }}
      />
      <button className='button small primary' style={{ marginTop: "20px", marginBottom: "30px", width: "100px" }} onClick={createAnnouncement}>
        Save
      </button>
    </div>
  )
}

export default EditorAnnouncement;