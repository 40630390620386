var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const DeferralModal = createReactClass({
  getInitialState: function() {
    return({
      reasons: this.props.reasons,
      select_editing: {optional: true,
                      required: false,
                      scientific: false},
      disabled: false,
      comments: null,
      rejectedAffiliations: false
    })
  },

  componentDidMount: function() {
    var self = this;
    if(self.props.articleState === 'editor_approval' && self.props.userRole != 'ApprovalEditor') {
      $.ajax({
        type: 'GET',
        url: self.props.affiliationsPath,
        success: function(data) {
          if(data.rejected) {
            self.setState({rejectedAffiliations: true})
          }
        }
      });
    }
    var editorText = ''
    if (typeof CKEDITOR === 'object') {
      editorText = CKEDITOR.instances['reject-request-textarea'].getData();
    }
    this.initializeCKEditor(editorText);
  },

  initializeCKEditor: function(editorText) {
    var ckeditor = new Cureus.CKEditor('reject-request-textarea',  {  editor_type: 'deferral',
                                                      ckeditor: {height: 300},
                                                      placeholderText: editorText,
                                                      change_listener: "#reject-request-textarea",
                                                      validate_button: "[data-button-type=submit]"
                                                    })

  },

  getAuthenticityToken: function (){
    return $('meta[name="csrf-token"]').attr("content");
  },


  renderCheckboxes: function() {
    if (this.props.tier !== 'preferred' && this.props.userRole !== 'ApprovalEditor') {
      var checkboxes = [];
      var self = this;
      for (var reasonId in self.state.reasons) {
        var currentReason = self.state.reasons[reasonId]
        checkboxes.push(
          <div className='columns large-4 end'>
            <div className='checkbox-container'>
              <label htmlFor={'reason_ids[' + reasonId + ']'}>
                <input name={'reason_ids[' + reasonId + ']'} id={'reason_ids[' + reasonId + ']'} type='checkbox' value={currentReason.checked} onChange={self.handleCheckboxToggle} checked={currentReason.checked} disabled={self.state.disabled} />
                {currentReason.label}
              </label>
            </div>
          </div>
        )
      }
      return (
        <div className='row'>
          {checkboxes}
        </div>
      )
    }
  },

  handleSelectEditingCheck: function(e) {
    var self = this;
    var currentComments = self.state.comments;
    if (!self.state.select_editing["required"]) {
      // we are not on the required option, save our comments
      var ckeditor = CKEDITOR.instances['reject-request-textarea']
      currentComments = ckeditor.getData();
    }
    var select_editing = {...self.state.select_editing};
    var checked = false
    Object.keys(select_editing).forEach(function(k) {
      if (k == e.target.id) {
        select_editing[k] = !select_editing[e.target.id];
      } else {
        select_editing[k] = false
      }
    })
    var all_false = true;
    Object.keys(select_editing).forEach(function(k) { 
      if (select_editing[k] == true) {
        all_false = false;
      }
    });
    if (all_false) {
      return
    }
    self.setState({select_editing, comments: currentComments}, function() {
      self.clearCKEditor(this.toggleCheckboxes(select_editing["required"]), checked)
      self.renderDeferralReasonText(false);
    })
  },
  
  toggleCheckboxes: function(select_editing, checked) {
    if (select_editing) {
      checked = false
      this.setState({disabled: true})
    } else {
      checked = true
      this.setState({disabled: false})
    }
    return checked
  },

  clearCKEditor: function(checked) {
    var ckeditor = CKEDITOR.instances['reject-request-textarea']
    if (!checked) {
      ckeditor.setData("", function() {
        document.getElementById('submit-def-reason-button').disabled = false
      })
      ckeditor.setReadOnly(true)
    } else {
      ckeditor.setReadOnly(false)
    }
  },

  handleCheckboxToggle: function(e) {
    var self = this;
    self.handleReasonRemoval();
    var oldState = self.state.reasons;
    var reasonId = e.target.name.slice(11).slice(0, -1); // parse id from 'reason_ids[###]'
    oldState[reasonId].checked = !oldState[reasonId].checked // toggle the checked value
    self.setState({reasons: oldState}, function() {
      self.renderDeferralReasonText(true); // render deferrals again which takes into account which is checked
    });
  },

  handleReasonRemoval: function() {
    // remove old reasons from ckeditor
    var ck = CKEDITOR.instances["reject-request-textarea"]
    var deferralReasonsContent = ck.document.getById("deferral-reasons-list")
    if (deferralReasonsContent) {
      deferralReasonsContent.remove()
    }
  },

  renderDeferralReasonText: function(checked) {
    if ( this.checkForNoDeferralReason() || this.state.select_editing["required"]) { return }
    var ckeditor = CKEDITOR.instances['reject-request-textarea'];

    var reasonDescriptions = ['<div id="deferral-reasons-list">'];
    var completeText = this.state.comments;
    if (checked)
    {
      for (var reasonId in this.state.reasons) {
        var currentReason = this.state.reasons[reasonId];
        if (currentReason.checked) {
          reasonDescriptions.push(
            "<p>" + currentReason.description + "</p>"
          )
        }
      }
      reasonDescriptions.push('</ol>')
      var oldText = ckeditor.getData(); // includes everything, including user input    
      var completeText = reasonDescriptions.join(" ") + oldText
    }
    if (ckeditor.getData() == '') {
      ckeditor.setData(completeText + "<p></p>")
    }
    else {
      ckeditor.setData(completeText);
    }
  },

  checkForNoDeferralReason: function() {
    var checked = 0
    for (var reasonId in this.state.reasons) {
      var currentReason = this.state.reasons[reasonId];
      if (currentReason.checked) {
        checked +=1
      }
    }
    if (checked == 0) {return true}
  },

  renderHeader: function() {
    var title;
    var description;
    if (this.props.tier === 'preferred') {
      if (this.props.view === 'science' || this.props.seniorCopyOwnership) {
        var action;
        if (this.props.articleState == 'editor_approval') {
          title = "Defer Preferred Editing article publication"
          action = "Defer Publication"
        } else {
          title = "Defer Preferred Editing article peer review"
          action = "Defer Peer Review"
        }
        description = "Enter a detailed description of any scientific content issues that the author must address and then " + action + " to send the article back to the author."
      }
      else {
        if (this.props.articleState == 'editor_approval') {
          title = "Defer article publication"
          description = "Please enter your comments and required revisions for the author."
        }
        else {
          title = "Send to Preferred Editor queue for copy editing"
          description = "Enter any formatting or language issues that must be addressed by the copy editor in order for peer review to begin. (Remember, most issues can wait until after peer review.)"
        }
      }
    } else {
      title = 'Defer for revisions'
      if (this.props.userRole == 'ApprovalEditor') {
        description = 'Please enter your comments and required revisions for the author.'
      }
      else {
        description = 'Select the issues to include in the deferral email. Please include detailed notes for the author in the below text field.'
      }
    }
    return (
      <div>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    )
  },

  renderAffiliationsMessage: function() {
    if(this.state.rejectedAffiliations) {
      return <p className="rejected-affs">You've rejected the author affiliations for this article. Please instruct the author how to revise the affiliations prior to resubmission.</p>
    }
  },

  renderSelectEditingCheckBox: function() {
    if (this.showSelectEditingCheckbox()) {
      return (
        <div className='row'>
          <hr className='small-margin'/>
          Preferred Editing Options
          <br></br>
          <br></br>
          <div className='columns large-4'>
            <div className='checkbox-container text-left' style={{marginRight: '16px'}}>
              <label htmlFor='optional'>
                <input name="select_editing[optional]" id='optional' type='radio' value={this.state.select_editing.optional} onChange={(e) => this.handleSelectEditingCheck(e)} checked={this.state.select_editing.optional}/>
                Preferred Editing Service optional
              </label>
            </div>
          </div>
          <div className='columns large-4'>
            <div className='checkbox-container text-centered' style={{marginRight: '16px'}}>
              <label htmlFor='required'>
                <input name="select_editing[required]" id='required' type='radio' value={this.state.select_editing.required} onChange={(e) => this.handleSelectEditingCheck(e)} checked={this.state.select_editing.required}/>
                Preferred Editing Service required
              </label>
            </div>
          </div>
          <div className='columns large-4'>
            <div className='checkbox-container' style={{marginRight: '16px'}}>
              <label htmlFor='scientific'>
                <input name="select_editing[scientific]" id='scientific' type='radio' value={this.state.select_editing.scientific} onChange={(e) => this.handleSelectEditingCheck(e)} checked={this.state.select_editing.scientific}/>
                Scientific Issues Only
              </label>
            </div>
          </div>
        </div>
      )
    }
  },


  showSelectEditingCheckbox: function() {
    var correctState = this.props.inEditorPreview
    var freeTier = (this.props.tier === 'free' || this.props.tier === 'professional')
    var preferredCheck = this.props.preferredCheck

    return (correctState && freeTier && preferredCheck)
  },

  setPath: function() {
    if (this.props.view == 'seniorCopy') {
      return this.props.sendToSeniorCopyPath
    } else {
      return this.props.path
    }
  },


  render: function() {
    return (
        <div className="modal-content">
          <div className="modal-header">
            {this.renderHeader()}
            {this.renderAffiliationsMessage()}
            <div className='modal-close'>
              <a href='#' className='cancel-modal'>
                <img src="https://public.cureus.com/close_x.png"/>
              </a>
            </div>

          <div className="modal-body">
            <form acceptCharset="UTF-8" action={this.setPath()}method="post">
              <input name="utf8" type="hidden" value="✓"/>
              <input name="_method" type="hidden" value={this.props.actionMethod}/>
              <input name="authenticity_token" type="hidden" value={this.getAuthenticityToken()} />
              <div className="checkboxes">
                { this.renderCheckboxes() }
              </div>
              {this.renderSelectEditingCheckBox()}
              <br/>
              <div className='deferral-ckeditor-wrapper'>
                <textarea name='comments' id='reject-request-textarea' ></textarea>
              </div>
              <div className='modal-footer'>
                <input id="submit-def-reason-button" className="button" data-button-type="submit" data-disable-with="Submitting..." name="commit" type="submit" value={'Defer For Revisions'}/>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
})

export default DeferralModal;