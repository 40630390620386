var createReactClass = require('create-react-class');
import React from 'react';

const EmailForm = createReactClass({
  getInitialState: function() {
    return {
      email: null,
      disabled: ""
    }
  },

  handleEmailInput: function(e) {
    this.setState({email: e.target.value})
  },

  handleSend: function() {
    var self = this;
    self.setState({disabled: "disabled"})
    $.ajax(self.props.emailPath, {
      dataType: "json",
      type: "POST",
      data: {email: self.state.email, face: self.props.face, articleId: self.props.articleId},
      success: function(data) {
        if(self.props.face !== null) {
          self.props.fireAmplitude("readerRatingEmail", {face: self.props.face, email: self.state.email})
        } else {
          setCookie("newsletterSignup", true)
        }
        self.props.handleClose()
      }
    })
  },

  sendOnReturn: function(e) {
    if (e.key === 'Enter') {
      this.handleSend()
    }
  },

  renderMessage: function() {
    var message;
    if (this.props.face) {
      return (
        <div>
          <h4 className="reg">Make your vote count!</h4>
          <hr/>
          <p>Please enter your email address to submit your vote and subscribe to our monthly newsletter.</p>
        </div>
      )
    } else if (this.props.abTest) {
      return (
        <p>Get the latest news from Cureus sent straight to your inbox.</p>
      )
    } else {
      return (
        <p>Sign up for our newsletter to keep up with the latest from Cureus.</p>
      )
    }
  },

  render: function (){
    return (
      <div className="email-form-container">
        {this.renderMessage()}
        <div className="row"> 
          <div className="large-7 large-offset-1 columns">
            <input type="text" onKeyDown={(e) => this.sendOnReturn(e)} onChange={(e) => this.handleEmailInput(e)}></input>
          </div>
          <div className="large-2 columns end">
            <a className={"button special " + this.state.disabled} onClick={this.handleSend}>Send</a>
          </div>
        </div>
      </div>
    )
  }
});

export default EmailForm;
