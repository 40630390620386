var createReactClass = require('create-react-class');
import React from 'react';

const ReviewerRating = createReactClass({
  renderReviewerships: function() {
    var self = this;
    return self.props.reviewerships.map(function(rev) {
      return (
        <li>
          <div className="reviewer-rating">
            <input type="hidden" name={`reviewership_ratings[${rev.id}]`} id={`ratings_${rev.id}`} class="rating-field"/>
            <div className="small-3 columns greek-container">
              <h5>{rev.attributes.greek_symbol}</h5>
            </div>
            <div className="small-9 columns">
              <i className="far fa-star"onClick={() => self.props.handleRating(rev.id, 1)}/>
              <i className="far fa-star" onClick={() => self.props.handleRating(rev.id, 2)}/>
              <i className="far fa-star" onClick={() => self.props.handleRating(rev.id, 3)}/>
              <i className="far fa-star" onClick={() => self.props.handleRating(rev.id, 4)}/>
              <i className="far fa-star" onClick={() => self.props.handleRating(rev.id, 5)}/>
            </div>
          </div>
        </li>
      )
    })
  },

  render: function() {
    return(
      <div className="reviewer-rating-container">
        <h4>Please rate your reviewers</h4>
        <p>
          Congratulations and thanks for publishing with Cureus! Reviewers play a key role in the Cureus community and we are grateful for their participation. We ask that you please take a moment to rate your reviewers before publishing.
        </p>
        <div className="rate-reviewers-wrap" style={{minHeight: '142px'}}>
          <ul>
            {this.renderReviewerships()}
          </ul>
        </div>
      </div>
    )
  }
})

export default ReviewerRating;