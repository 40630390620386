var createReactClass = require('create-react-class');
import React from 'react';
import DeleteModal from './DeleteModal';
import Modal from '../Modal';

const DeleteModalTrigger = createReactClass({
  componentDidMount: function() {
    var self = this;
    $('#delete-modal-trigger').on('openModal', function(e, attachmentId, attachmentType){
      self.setState({
        attachmentId: attachmentId,
        attachmentType: attachmentType
      })
      self.openModal();
    })
  },

  openModal: function(){
    Modal.open({
      children:
        <DeleteModal
         attachmentId={this.state.attachmentId}
         attachmentType={this.state.attachmentType}
         ckEditorId={this.props.ckEditorId}
         articleId={this.props.articleId}
         articleContentType={this.props.articleContentType}
        />
    })
  },

  render: function(){
    return (
      <div><a onClick={ this.openModal } id="delete-modal-trigger"/></div>
    );
  }
})

export default DeleteModalTrigger;