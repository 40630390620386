var createReactClass = require('create-react-class');
import React from 'react';

const Channel = createReactClass({
  render: function(){
    return (
      <div className="small-4 columns">
        <div className="channel-content-card">
          <a href={this.props.link} target="_blank"><img src={this.props.image}/></a>
        </div>
      </div>
    );
  }
})

export default Channel;