import React, { memo } from 'react'

const SearchBar = ({ onSubmit, updateSearch, searchTerm }) => {
  const searchIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="11" cy="11" r="6" stroke="#ABB5BE" strokeWidth="2"/>
      <path d="M20 20L17 17" stroke="#ABB5BE" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit(event);
  }

  return (
    <form className="search-bar-container" onSubmit={handleSubmit}>
      <div className="search-bar-input-container">
        {searchIcon}
        <input type="text" className="search-bar-input" name="searchTerm" id="searchTerm" onChange={updateSearch} value={searchTerm} />
      </div>
      <button className="search-button" type="submit">Search</button>
    </form>
  );
}

export default memo(SearchBar);