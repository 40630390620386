import {Cureus} from '../Cureus';

Cureus.ChecklistToggler = function(options) {
  this.options = options;
  this.$container = $(options.container);
  this.$toggler = $(options.toggler);
  this.$targets = $(options.disableEnableTarget);

  this.checked_limit = options.limit || undefined;
  this._bindEvents();
  this.initialize();
};

_.extend(Cureus.ChecklistToggler.prototype, {
  _bindEvents: function() {
    var self = this;

    if(this.checkboxCounter >= this.checked_limit){
      this.disableAllUncheckedCheckboxes();
    };

    this.highlightCheckboxes(this.findAllCheckedCheckboxes());

    this.$container.on("change", "input[type='checkbox']", function(e) {
      var $checkbox = $(e.currentTarget);

      self.countCheckedCheckboxes();
      if ($checkbox.is(":checked")) {
        self.highlightCheckboxes($checkbox);
        self.handleTogglerClicked($checkbox);
      }
      else { // checkbox unchecked
        self.unHighlightCheckbox($checkbox);
        self.ensureSomethingChecked();
      };

      self.checkForCategoryPresence();
    });
  },

  initialize: function() {
    this.countCheckedCheckboxes()
    this.checkForCategoryPresence();
  },

  checkCheckbox: function (checkboxElms){
    checkboxElms.prop('checked', true);
  },

  checkToggler: function (){
    this.checkCheckbox(this.$toggler);
  },

  countCheckedCheckboxes: function (){
    this.checkboxCounter = this.findAllCheckedCheckboxes().length;

    if(!this.checked_limit) {return};

    if(this.checkboxCounter === this.checked_limit) {
      this.disableAllUncheckedCheckboxes();
    }else{
      this.enableAllUncheckedCheckboxes();
    }
  },

  disableAllUncheckedCheckboxes: function (){
    this.findAllUncheckedCheckboxes().attr('disabled', 'disabled');
    this.findAllUncheckedCheckboxes().siblings("span").addClass('disabled');
  },

  enableAllUncheckedCheckboxes: function (){
    this.findAllUncheckedCheckboxes().removeAttr('disabled');
    this.findAllUncheckedCheckboxes().siblings("span").removeClass('disabled');
  },

  ensureSomethingChecked: function (){
    if(this.$toggler && this.checkboxCounter <= 0){
      this.checkToggler();
    }
  },

  findAllCheckedCheckboxes: function() {
    return this.$container.find("input[type='checkbox']:checked");
  },

  findAllUncheckedCheckboxes: function (){
    return this.$container.find("input[type='checkbox']:not(:checked)");
  },

  handleTogglerClicked: function ($checkbox){
    var to_uncheck;

    if($checkbox.is(this.$toggler)) { // Toggler checkbox clicked
      to_uncheck = this.$container.find("input[type='checkbox']:not(" + this.options.toggler + "):checked");
    } else {
      to_uncheck = this.$toggler;
    }

    this.uncheckCheckbox(to_uncheck);
    this.countCheckedCheckboxes();
  },

  highlightCheckboxes: function ($checkbox){
    var checkbox_container;

    checkbox_container = $checkbox.parents(".checkbox-container");
    checkbox_container.addClass("selected");
  },

  uncheckFoundationCheckbox: function (checkboxElms){
    //Hack to get foundation checkboxes to uncheck. Not very good :(
    checkboxElms.siblings("span.custom.checkbox").removeClass("checked");
  },

  uncheckCheckbox: function (checkboxElms){
    checkboxElms.prop('checked', false);
    this.uncheckFoundationCheckbox(checkboxElms);
  },

  unHighlightCheckbox: function ($checkbox){
    var checkbox_container;
    checkbox_container = $checkbox.parents(".checkbox-container");

    checkbox_container.removeClass("selected");
  },

  checkForCategoryPresence: function() {
    (this.findAllCheckedCheckboxes().length > 0) ? this.enableTarget() : this.disableTarget()
  },

  disableTarget: function() {
    [].forEach.call(this.$targets, function(el) {
      el.setAttribute("disabled", true)
    });
  },

  enableTarget: function() {
    [].forEach.call(this.$targets, function(el) {
      el.removeAttribute("disabled")
    });
  },


});
