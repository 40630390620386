import React from 'react';
import SiqBadgeImage from '../SiqBadgeImage'

const NewArticleCard = ({resource, page, compOrChannel}) => {
  const renderSiq = () => {
    if (resource.retraction_present || ['Poster', 'Abstract'].includes(resource.humanized_type)) {
      return
    }
     return(
      <a target="_blank" href={`${resource.path}?score_article=true`}>
        <div className={"siq-badge siq-badge-wrap-medium " + `${renderWidthClass()}`}>
          <div className={renderBadgeClass()}>
            <SiqBadgeImage
              siq={resource.siq}
            />
              <div className="siq-badge-score react-content-card">
                {resource.siq}
              </div>
          </div>
          {renderWinner()}
        </div>
      </a>
     )
  }

  const renderWidthClass = () => {
    if (resource.siq <= 5 || resource.siq == "-" || resource.siq == null) {
      return 'badge';
    } else {
      return 'ribbon-badge';
    }
  }

  const renderBadgeClass = () => {
    if (resource.siq <= 5 || resource.siq == "-" || resource.siq == null) {
      return 'browse-articles-badge ';
    }
  }

  const renderWinner = () => {
    if (resource.competition_winner) {
      return(
        <div className='content-card-trophy'>
          <div dangerouslySetInnerHTML={ {__html: resource.trophy_image} } key={ resource.id + "winner"} />
        </div>
      )
    }
  }

  const renderSiqHeader = () => {
    return (
      <div className="header-siq">
        <span className="date">
          {resource.published_date}
        </span>
        <br/>
        <span className="type">
          {resource.humanized_type}
        </span>
        {renderWinnerDetails()}
      </div>
    )
  }

  const renderWinnerDetails = () => {
    if (resource.competition_winner) {
      return(
        <div className='content-card-trophy-details'>
          <div dangerouslySetInnerHTML={ {__html: resource.trophy_image} } key={ resource.id + "winner"} />
        </div>
      )
    }
  }

  const renderCategories = () => {
    if (!resource.categories) {
      return
    }
    return resource.categories.map(function(category) {
      return <a key={category.id + '_' + resource.id} href={`/${category.specialty_url}`} className="tag" target="_blank">{category.name}</a>
    })
  }

  const handleRatingForMobile = () => {
    let articleScore = resource.article_ratings_and_siq.scorings_count;
    let ap_rating = resource.approval_rating
    if(window.innerWidth <= 640 && resource.has_thumbnail && (articleScore !== 1 || ap_rating > 1)) {
      return "lower-rating-card";
    } else {
      return "";
    }
  }

  const renderTitle = () => {
    if(page == "UserProfile" && resource.retraction_present) {
      return (
        <div className="row title">
          <a href={resource.path} target="_blank">
            <h4>
              <span style={{color: '#CA3145', marginRight: '7px'}}>RETRACTED:</span>{resource.title}
            </h4>
          </a>
        </div>
      )
    } else {
      return (
        <div className={"row title " + handleRatingForMobile()}>
          <a href={resource.path} target="_blank">
            <h4>
              {resource.title}
            </h4>
          </a>
        </div>
      )
    }
  }

  const renderDetails = () => {
    return(
      <div className="details">
        <div className="row header">
          <div>
            <div>
              <span className="date">
                {resource.published_date}
              </span>
            </div>
            <div>
              <span className="type">
                {resource.humanized_type}
              </span>
            </div>
          </div>
        </div>
        {renderTitle()}
        <div className="row authors">
          {renderAuthors()}
        </div>
        <div className="row categories">
          {renderCategories()}
        </div>
      </div>
    )
  }

  const renderAuthors = () => {
    if(window.innerWidth <= 640) {
      return truncateNames(resource.author_list);
    } else {
      return resource.author_list;
    }
  }

  const truncateNames = (names) => {
    const namesArray = names.split(', ');
    let truncatedNames = '';
    let count = 0;

    for (let i = 0; i < namesArray.length; i++) {
      if ((truncatedNames.length + namesArray[i].length + 2) <= 40) {
        if (i === namesArray.length - 1) {
          truncatedNames += namesArray[i];
        } else {
          truncatedNames += namesArray[i] + ', ';
          count++;
        }
      } else {
        const remainingCount = namesArray.length - count;
        truncatedNames += `+${remainingCount} more`;
        break;
      }
    }

    return truncatedNames;
  }

  const renderChannelLogo = () => {
    if(compOrChannel && resource.channel_logo) {
      return (
        <div className="channel-logo-container">
          <img src={resource.channel_logo}/>
        </div>
      )
    }
  }

  const renderThumbnail = () => {
    if (resource.has_thumbnail) {
      return (
        <div className={"medium-3 medium-push-8 columns thumbnail-wrap " + handleRatingForMobile()}>
          <a href={resource.path} target="_blank">
            <div className="thumbnail-mask"/>
            <div className="thumbnail-container" key={resource.id + "thumb"} dangerouslySetInnerHTML={{__html: resource.thumbnail}}/>
          </a>
        </div>
      )
    }
  }

  const getDetailsClass = () => {
    if (resource.has_thumbnail) {
      return "medium-8 medium-pull-3 columns"
    }
    return "medium-11 columns"
  }

  /*Save these 2 function for V2 of browse articles when we have bookmark ability
  // const bookMarkArticle = () => {
  //   if (bookMarked) {
  //     // ajax call to created
  //     setBookMarked(!bookMarked);
  //   } else {
  //     // ajax call to destroy
  //     setBookMarked(!bookMarked);
  //   }
  // }
  // const renderBookMark = () => {
  //   return (
  //     <div className='bookmark-container'>
  //       {bookMarked ? (
  //         <img onClick={bookMarkArticle} className='bookmark-icon' src="https://public.cureus.com/browse-article/bookmark_checked.png"/>
  //         ) : (
  //         <img onClick={bookMarkArticle} className='bookmark-icon' src="https://public.cureus.com/browse-article/bookmark_unchecked.png" />
  //       )}
  //     </div>
  //   )
  // };
  */

  return (
    <div className="row">
      <div className="large-11 medium-12 columns small-centered browse-article-card-container">
        <div className="medium-10 columns small-centered new-article-content-card">
          <div className="medium-1 flex columns badge-container">
            {renderSiq()}
            {renderSiqHeader()}
            {renderChannelLogo()}
          </div>
          {/* {renderBookMark()} */}
          {renderThumbnail()}
          <div className={getDetailsClass()}>
            {renderDetails()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewArticleCard;