import React from 'react';

const CollectionRejectedModal = ({rejectedData}) => {

  return (
    <div id={"collection-reject-reveal-modal"} className="reveal-modal new-modal collection-reject-modal middling large-12 columns" data-reveal>
      <div className="modal-body collection-reject-section">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="small-12 columns reject-img-container">
              <img className='reject-img text-center' src="https://public.cureus.com/my_dashboard/my_collections/rejected-modal-img.png" />
            </div>
            <div className="small-12 columns text-center rejection-text">
              <h3 className='rejection-header'>Message from Editor</h3>
              <h5 className='rejection-date'>Rejected on {rejectedData.date}</h5>
            </div>
            <div className='small-12 columns rejection-reasons-container'>
              <img className='rejection-icon' src="https://public.cureus.com/my_dashboard/my_collections/rejected-modal-icon.png" />
              <p dangerouslySetInnerHTML={{__html: rejectedData.reason}}></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionRejectedModal;