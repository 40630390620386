import React from 'react';

const PeerReviewQuestion = ({response, disclosure=false}) => {

  const parseUser = (userFullName, userId) => {
    return (
     <a target='_blank' href={`/admin/users/${userId}`}>{userFullName}</a>)
  }

  const handleStrikeThrough = (state) => {
    if(state === 'rejected') {
      return {textDecoration: 'line-through'}
    }
  }

  const renderAuthorResponse = (author_response) => {
    if(author_response) {
      return (
        <p><strong>Author Response:</strong> {author_response}</p>
      )
    }
  }

  return (
    <div className="answer-container row">
      <div className='large-2 columns'>
        <p>{parseUser(response['full_name'], response['reviewer_id'])}</p>
      </div>
      <div className='large-2 columns'>
        <p>{response['cureus_invited'] ? 'Cureus invited' : 'Author invited'}</p>
      </div>
      <div className='large-1 greek columns'>
        <span className={`annotator-hl ${response['greek_color']}`}>{response['greek_symbol']}</span>
      </div>
      <div className='large-7 columns'>
      {disclosure ?
        <p style={handleStrikeThrough(response['state'])}><strong>Disclosure:</strong> {response['disclosure']}</p>
      :
        <div>
          <p style={handleStrikeThrough(response['state'])}><strong>Reviewer Comment:</strong> {response['reviewer_response']}</p>
          {renderAuthorResponse(response['author_response'])}
        </div>
      } 
      </div>
    </div>
  )
}

export default PeerReviewQuestion;