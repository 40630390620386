var createReactClass = require('create-react-class');
import React from 'react';

const NewSidebarWidget = createReactClass({
  handleCreateNewWidget: function(e) {
    e.preventDefault()
    this.createWidget(e)
  },

  createWidget: function(e) {
    var self = this;
    let form = e.target
    $.ajax(self.props.createWidgetPath, {
      dataType: "json",
      type: "POST",
      data: $(form).serialize(),
      success: function(data) {
        self.props.updateAllWidgets(data)
      }
    })
  },

  renderOptions: function() {
    return this.props.selectOptions.map(function(el, i) {
      return (
        <option key={i} value={el.value}>{el.option}</option>
      )
    })
  },

  render: function() {
    if (this.props.canManage) {
      return (
        <form onSubmit={this.handleCreateNewWidget} id="new-widget">
          <div className="new-widget-controls-wrap">
            <input  name="widget[parent_id]" type="hidden" value={this.props.parentId} />
            <select name="widget[type]" id="widget_type">
                <option  value="">Select widget</option>
                {this.renderOptions()}
            </select>
            <input type="submit" value="Add" className="button primary tiny"/>
          </div>
        </form>
      )
    } else {
      return <div/>
    }
  }
})

export default NewSidebarWidget;






