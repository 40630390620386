import React, { useState, useEffect, useCallback } from 'react';
import ChannelCard from './ChannelCard';
import Pages from '../../../Shared/Pagination/Pages';
import SortButton from './SortButton';
import _debounce from 'lodash/debounce';

const ChannelsList = () => {
  const [channels, setChannels] = useState([])
  const [channelAdminEditors, setChannelAdminEditors] = useState([])
  const [logoBaseUrl, setLogoBaseUrl] = useState('')
  const [paginationData, setPaginationData] = useState({})
  const [channelsPerPage, setChannelsPerPage] = useState('10')
  const [pageNumber, setPageNumber] = useState({page: 1})
  const [sortBy, setSortBy] = useState('title')
  const [searchTerm, setSearchTerm] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [sortPublish, setSortPublish] = useState(true)

  const [debounce] = useState((term) => _debounce((term) => { fetchChannels(term)}, 500));

  useEffect(() => {
    $(document).foundation("reflow")
  }, [channels])

  useEffect(() => {
    fetchChannels()
  }, [channelsPerPage, pageNumber, sortBy])

  useEffect(() => {
    if (!firstLoad) {
      setPageNumber({page: 1})
    }
  }, [sortPublish])

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
    } else {
      debounce(searchTerm)
    }
  }, [searchTerm])

  const fetchChannels = (term) => {
    $.ajax({
      type: 'GET',
      url: '/admin/channels',
      dataType: 'json',
      data: params(term),
      success: function(json) {
        setChannels(json.channels)
        setLogoBaseUrl(json.logoBaseUrl)
        setChannelAdminEditors(json.channelAdminEditors)
        setPaginationData(json.paginationData)
      }
    });
  }

  const params = (term) => {
    return {
      limit: channelsPerPage,
      current_page: pageNumber.page,
      search_term: (term || searchTerm),
      sort_by: sortBy,
      published: sortPublish
    }
  }

  const renderCards = () => {
    return channels.map( (channel) => {
      return <ChannelCard channel={channel} channelAdminEditors={channelAdminEditors[channel.id]}logoBaseUrl={logoBaseUrl} key={channel.id}/>
    })
  }

  const handleChannelsPerPageChange = (e) => {
    setChannelsPerPage(e.target.value)
  }

  const handleSearchTermUpdate = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleSetPageNumber = (page) => {
    setPageNumber({page: page})
  }

  const renderOnlyPublishedButton = () => {
    return (
      <a data-tooltip="2" title="published/unpublished channels" className="filter has-tip" onClick={() => { setSortPublish(!sortPublish) }} style={{background: sortButtonColor()}}><i class="fa fa-filter"/></a>
    )
  }

  const sortButtonColor = () => {
    return ( sortPublish == true ? "#f7f7f7" : "white"   )
  }

  return (
    <div className="channels-list">
      <div className="row header">
        <div className="columns search small-8">
          <input onChange={handleSearchTermUpdate} type="text" placeholder="Search..."/>
          <SortButton sortBy={sortBy} setSortBy={setSortBy}/>
          {renderOnlyPublishedButton()}
        </div>
        <div className="columns offset-2 small-2">
          <div className="per-page">
            <span>Show</span>
            <select value={channelsPerPage} onChange={handleChannelsPerPageChange}>
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
            </select>
            <span>Entries</span>
          </div>
        </div>
      </div>

      {renderCards()}

      {Object.keys(paginationData).length > 0 && <Pages
        paginationData={paginationData}
        handlePageSelect={handleSetPageNumber}
      />}

      <span className="page-details">Showing {paginationData.showingFrom} to {paginationData.showingTo} of {paginationData.totalItems} entries</span>
    </div>
  )
}

export default ChannelsList;