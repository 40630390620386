import React from 'react';
var createReactClass = require('create-react-class');

const InvoicesWidget = createReactClass({
  renderStats: function() {
    var self = this
    var stats =  self.props.stats.map(function(e) {
      if (e.title) {
        return (
          <tr>
            <td colSpan='3'><strong>{e.title}</strong></td>
          </tr>
        )
      } else if(e.divider) {
        return (
          <tr>
            <td colSpan='6'><hr/></td>
          </tr>
        )
      } else {
        return (
          <tr>
            <td><h5 className="reg">{e.label}</h5></td>
            <td ><strong>{e.purchases}</strong></td>
            <td><strong>{e.amount}</strong></td>
            <td><strong>{e.averagePrice}</strong></td>
            <td><strong>{e.discount != "$0" && e.discount}</strong></td>
            <td><strong>{e.averageReferences}</strong></td>
            <td><strong>{e.averagePoints}</strong></td>
          </tr>
        )
      }
    })

    return (
      <tbody>
        {stats}
      </tbody>
    )
  },

  renderTableHeaders: function() {
      return (
        <tr>
          <th style={{width: '500px'}}>
            <h5>Tier</h5>
          </th>
          <th style={{width: '260px'}}><h5>Purchases</h5></th>
          <th><h5>Amount Paid</h5></th>
          <th><h5>Average Price</h5></th>
          <th><h5>Discounts</h5></th>
          <th><h5>Average # References</h5></th>
          <th><h5>Average # Ed. Check Points</h5></th>
        </tr>
      )
  },

  render: function() {
    return (
      <table className="invoices-widget">
        <thead>
          {this.renderTableHeaders()}
        </thead>
          {this.renderStats()}
      </table>
    )
  }
})

export default InvoicesWidget;