import React, { useEffect } from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-labels';

const StackedBarChart = ( { ctx, datasets, title, labels, spinning, yLabel, xLabel} ) => {

  useEffect(() => {
    $(`#${ctx}`).remove();
    $(`#${ctx}-container`).append(`<canvas id='${ctx}'></canvas>`);
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        plugins: {
          legend: {
            display: true
          },
          title: {
            display: false,
            text: title
          },
          labels: {
            render: function(args) {
              if (args.value != 0) {
                return args.value
              }
            }
          }
        },
        scales: {
          x: [{
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }],
          y: {
            stacked: true,
            title: {
              display: true,
              text: yLabel
            }
          }
        }
      }
    })
  })

  function renderSpinner() {
    if(spinning) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  }

  return (
    <div id={`${ctx}-container`}>
      <div>
        {renderSpinner()}  
      </div>
      <canvas id={ctx}></canvas>
    </div>
  )

}

export default StackedBarChart;