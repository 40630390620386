import React, {useState} from 'react';

const CollectionArticleCard = ({id, published_at, type, title, authors, handleRemoveArticle}) => {

  return (
    <div key={id} className="collection-article-card">
      <div className="black-dot" />
      <div className="card-header">
        <p>{published_at}</p>
        <p><b>{type}</b></p>
      </div>
      <h6 className="article-card-title">{title}</h6>
      <p className="authors">{authors}</p>
      <a className="button small secondary remove-button" onClick={() => handleRemoveArticle(id)}>Remove</a>
    </div>
  )
}

export default CollectionArticleCard;