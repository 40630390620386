var MetricsApi = {

  makeRequest: function(metricsApi, method, params, onSuccess=null, onError=null, onComplete=null) {
    $.ajax({
      type: 'POST',
      url: '/admin/get_token',
      success: function(res) {
        params.userToken = res.token;
        $.ajax({
          type: method,
          url: metricsApi,
          data: params,
          success: function(res) {
            if (onSuccess) { onSuccess(res); }
          },
          error: function(e) {
            if (onError) { onError(res); }
          },
          complete: function() {
            if (onComplete) { onComplete(res); }
          }
        })
      }
    })
  }
};

export default MetricsApi;