import React, { useEffect } from 'react';
import CollectionCard from './CollectionCard';
import Pages from '../../Shared/Pagination/Pages';

const UnpublishedTab = ({publishNewCollectionPath, collections, fetchCollections, paginationData, handlePageSelect, pagesType}) => {

  return (
    <div className='collection-container'>
      <div className='collection-card-container'>
        <div className='large-3 columns create-new-collection-card'>
          <a className='add-button' href={publishNewCollectionPath}>
            <img 
              className='add-button-plus' 
              src="https://public.cureus.com/my_dashboard/my_collections/add-button-plus.png"
            />
          </a>
          <h5>New Collection</h5>
          <p>Create and submit a new collection for editorial approval.</p>
        </div>
        {collections.map((collection, index) => {
          return (
            <CollectionCard 
              key={collection.id} 
              collection={collection} 
              index={index}
              fetchCollections={fetchCollections}
            />
          )
        })}
      </div>
      {collections.length > 0 &&
        <div className='pagination-container'>
          <Pages
            paginationData={paginationData}
            handlePageSelect={handlePageSelect}
            pagesType={pagesType}
          />
        </div>
      }
    </div>
  )
}

export default UnpublishedTab;