var createReactClass = require('create-react-class');
import React from 'react';

const Prev = createReactClass({
  render: function(){
    return (
      <a disabled={ this.props.disabled } className={ this.props.disabled } onClick={ this.props.handlePrev }><i className="fa fa-caret-left"></i></a>
    );
  }
})

export default Prev