import React, { forwardRef, memo } from 'react';
import ArticleDateSelector from '../../NewArticleIndex/ArticleDateSelector';
import Dropdown from '../../Shared/Dropdown';

const Filters = ({
  dateData,
  pageCountOverview,
  selectedSort,
  updateSortDirection,
  setPerPage,
  perPage,
  handleDateRangeSubmit,
}) => {
  const { dateRanges, toDate, fromDate, isActive } = dateData
  const filterArrowIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M7.41289 11.2341L11.0199 14.6179C11.563 15.1274 12.4404 15.1274 12.9835 14.6179L16.5905 11.2341C17.4679 10.411 16.8412 9 15.6017 9L8.38776 9C7.14829 9 6.53552 10.411 7.41289 11.2341Z"
        fill="#ABB5BE"
      />
    </svg>
  );

  const handlePerPageChange = (value) => {
    setPerPage(value);
  };

  const RawButton = memo(({ forwardedRef, isLoading, disabled, ...props }) => {
    return (
      <button {...props} ref={forwardedRef}>
        {props.children}
      </button>
    );
  });

  const Button = forwardRef((props, ref) => (
    <RawButton {...props} forwardedRef={ref} />
  ));

  const dropDownItems = [
    { content: 20, onItemClick: () => handlePerPageChange(20) },
    { content: 50, onItemClick: () => handlePerPageChange(50) },
    { content: 100, onItemClick: () => handlePerPageChange(100) },
  ];

  const { dir } = selectedSort;

  return (
    <div className="filters-container">
      <div className="date-filters-container">
        <button
          className={`filter-date-order ${dir === "desc" ? "desc" : "asc"}`}
          onClick={() => updateSortDirection(dir)}
        >
          Newest{filterArrowIcon}
        </button>
        <ArticleDateSelector
          fromDate={fromDate}
          toDate={toDate}
          handleDateRangeSubmit={handleDateRangeSubmit}
          selectedRange={dateRanges}
          datePickerActive={isActive}
        />
      </div>

      <div className="pagination-filters-container">
        <div className="pagination-results-count">{pageCountOverview}</div>
        <div className="per-page-filter-container">
          <Dropdown
            toggle={
              <Button>
                Show {perPage} {filterArrowIcon}
              </Button>
            }
            items={dropDownItems}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Filters);