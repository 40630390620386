var createReactClass = require('create-react-class');
import React from 'react';
import Thanks from './Thanks';
import EmailForm from './EmailForm';

const Main = createReactClass({
  getInitialState: function() {
    return {
      minimized: false,
      view: "rating",
      face: null
    }
  },

  componentDidMount: function() {
    if (this.props.publishedArticle === true) {
      this.setState({view: 'email'})
    }
  },

  renderContent: function() {
    if (!this.state.minimized) {
      if (this.state.view === "rating") {
        return (
          <div className="contents">
            <h4 className="reg">How likely are you to recommend this article to a peer?</h4>
            <hr/>
            <div className="box-container">
              <img className="box red" src="https://public.cureus.com/no.png" onClick= {(e) => this.handleRating(e)} name="no"/>
              <img className="box yellow" src="https://public.cureus.com/maybe.png" onClick= {(e) => this.handleRating(e)} name="maybe"/>
              <img  className="box green" src="https://public.cureus.com/yess.png" onClick= {(e) => this.handleRating(e)} name="yes"/>
            </div>
          </div>
        )
      } else if (this.state.view === "thanks") {
        return <Thanks 
                  handleClose={this.handleClose}
                  reviewer={this.props.reviewer}
                />
      } else {
        return <EmailForm 
                  emailPath={this.props.emailPath}
                  handleClose={this.handleClose}
                  fireAmplitude={this.fireAmplitude}
                  face={this.state.face}
                  articleId={this.props.articleId}
                  abTest={this.props.abTest}
                />
      }
    }
  },

  handleRating: function(e) {
    if (this.props.currentUser) {
      this.setState({view: "thanks"})
    } else {
      this.setState({view: "email"})
    }
    var currCookie = getCookie("articleRating")
    var newCookie = currCookie + this.props.articleId + '|'
    setCookie("articleRating", newCookie)

    var eventName = this.props.reviewer === "" ? "readerRatingClicked" : "readerRatingReviewerClicked"

    this.setState({face: e.target.name}, this.recordRating(eventName, {face: e.target.name}))
  },

  fireAmplitude: function(eventName, eventParams) {
    var self = this;
    eventParams['event_type'] = eventName
    eventParams['user_id'] = self.props.currentUser ? self.props.currentUser.id : null
    eventParams['articleId'] = self.props.articleId
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: eventParams
    })
  },

  recordRating: function(eventName, eventParams) {
    var self = this;
    let endpoint = `/articles/${self.props.articleId}/record_reader_rating`
    $.ajax(endpoint, {
      dataType: "json",
      type: "POST",
      data: {
        eventName: eventName,
        eventParams: eventParams,
        actor: self.props.currentUser ? self.props.currentUser.id : null
      }
    })
  },

  renderMinimizeButton: function() {
    if (this.props.reviewer === "") {
      if (this.state.minimized) {
        return (
          <div className="minimize-button minimized" onClick={this.handleMinimize}><span>^</span></div>
        )
      } else {
        return (
          <div className="minimize-button" onClick={this.handleMinimize}>-</div>
        )
      }
    }
  },

  handleBorder: function() {
    if(this.state.minimized) {
      return {}
    } else {
      return {border: "1px solid #0096B7"}
    }
  },

  handleMinimize: function() {
    var min = !this.state.minimized
    this.setState({minimized: min})
  },

  handleClose: function() {
    setTimeout(function() {
      document.querySelector(".article-rating-modal-container").style.display = 'none'
    }, 1000)
  },

  render: function (){
    return (
      <div className={"article-rating-modal " + this.props.reviewer} style={this.handleBorder()}>
        <div className="blue-bar">
          {this.renderMinimizeButton()}
        </div>
        {this.renderContent()}
      </div>
    )
  }
});

export default Main;