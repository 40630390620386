var createReactClass = require('create-react-class');
import React from 'react';

const EditorialActivity = createReactClass({
  componentDidMount: function() {
    this.props.populateTable()
  },

  renderDatatable: function() {
    return (
        <table className="table-wrapper data-table" id="editor-activity-table">
          <thead>
            <tr>
              <th>Date Integer For Sorting</th>
              <th>Completed Date</th>
              <th>ID</th>
              <th>Tier</th>
              <th>Type</th>
              <th>Task</th>
              <th>Location</th>
              <th>Article Price</th>
              <th>Word Count</th>
              <th>Title</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
    )
  },

  render: function() {
    return (
      <div className="dataTables_wrapper no-footer">
        {this.renderDatatable()}
      </div>
    )
  }
})

export default EditorialActivity;