import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Link, useLocation, useHistory } from "react-router-dom";
import ArticleTab from './ArticleTab';
import AuthorsTab from './AuthorsTab';
import MetricsTab from './Metrics/MetricsTab';
import AttachmentsTab from './AttachmentsTab';
import NavBarItem from './NavBarItem';

const NavItems = ({hasAttachments, articleHtml, metricsPaths, article, cssClass="", activeTab, setActiveTab, preview, page, fireAmplitude, currentUserId, scrollComments}) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setActiveTab(location.pathname)
    window.addEventListener('resize', () => handleAnimation());
    if(scrollComments) {
      scrollToComments()
    }
  }, [])

  useEffect(() => {
    setActiveTab(location.pathname)
    handleAnimation()
    MathJax.Hub.Queue(["Typeset",MathJax.Hub]);
  }, [location.pathname])

  const handleAnimation = () => {
    if(cssClass !== "top-nav-links") {
      let slideBubble = document.querySelector(".bubble");
      let navBar = document.querySelector('.main-nav-selector');
      let active = navBar.querySelector('.active') || navBar.querySelector(`a[href='#!${location.pathname}']`)
      let adjustment = 0;
      if(navBar.offsetWidth/navBar.children.length < 140) {
        adjustment = 10
        slideBubble.style.width = navBar.offsetWidth/navBar.children.length + 'px'
      } else {
        slideBubble.style.width = '140px'
      }
      let translate = active.getBoundingClientRect().x - navBar.getBoundingClientRect().x - 10 + adjustment
      if(translate < 10) {
        translate = 0
      }
      slideBubble.style.transform = `translateX(${translate}px)`;
    }
  }

  const scrollToComments = () => {
    fireAmplitude("articleTabClicked", {article_id: article.id, source: 'Comments'})
    fireAmplitude("scrolledToComments", {article_id: article.id, source: 'tab'})
    history.push('/')
    setActiveTab('/')
    setTimeout(function() {
      const element = document.getElementById("article-comments-section");
      const y = element.getBoundingClientRect().top + window.pageYOffset - 70;
      window.scrollTo({top: y, left: 0, behavior: 'smooth'});
    }, 500)
  }

  const renderCss = () => {
    let navBarClass = "large-10 large-offset-1 medium-8 medium-offset-2 small-12 columns "
    if (cssClass === "top-nav-links") {
      return navBarClass + cssClass;
    } else {
      if(preview || page != 'article') {
        navBarClass = ((hasAttachments && page === 'article') ? "large-4 large-offset-4 medium-8 medium-offset-2 small-12 " : "large-2 large-offset-5 medium-4 medium-offset-4 small-12 ")
      }
      return navBarClass + "article-show-nav";
    }
  }

  return (
    <div className="row small-collapse">
      <div className="nav-container">
        <div className={renderCss()}>
          <ul className={"article-main-nav-links " + (cssClass === 'top-nav-links' ? '' : 'main-nav-selector')}>
            <NavBarItem
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              name={"Article"}
              path={"/"}
              fireAmplitude={fireAmplitude}
              articleId={article.id}
              cssClass={cssClass}
            />
            <NavBarItem
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              name={"Authors etc."}
              path={"/authors"}
              fireAmplitude={fireAmplitude}
              articleId={article.id}
              cssClass={cssClass}
            />
            {!preview && page == "article" && <NavBarItem
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              name={"Metrics"}
              path={"/metrics"}
              fireAmplitude={fireAmplitude}
              articleId={article.id}
              cssClass={cssClass}
            />}
          {hasAttachments && page == "article" &&
              <NavBarItem
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                name={"Media"}
                path={"/media"}
                fireAmplitude={fireAmplitude}
                articleId={article.id}
                cssClass={cssClass}
              />
          }
          {!preview && page == "article" &&
            <a onClick={scrollToComments}>
              <li>
                Comments
              </li>
            </a>
          }
          </ul>
          {cssClass !== "top-nav-links" && <div className="slider">
            <div className="bubble"></div>
          </div>}
        </div>
      </div>
      {!cssClass &&
        <Switch>
          <Route exact path='/'>
            <ArticleTab
              preview={preview}
              html={articleHtml["article"]["html"]}
              page={page}
              articleId={article.id}
              fireAmplitude={fireAmplitude}
              currentUserId={currentUserId}
            />
          </Route>
          <Route path='/authors'>
            <AuthorsTab html={articleHtml["authors"]["html"]}/>
          </Route>
          <Route path='/metrics'>
            <MetricsTab
              metricsPaths={metricsPaths}
              article={article}
              fireAmplitude={fireAmplitude}
            />
          </Route>
          <Route path='/media'>
            <AttachmentsTab html={articleHtml["figures"] ? articleHtml["figures"]["html"] : {}}/>
          </Route>
        </Switch>
      }
    </div>
  )

}

export default NavItems;