import {Cureus} from '../../Cureus';

Cureus.Utils.Colors = (function (){
  var rgbToHex, getContrast50,
    componentToHex;

  componentToHex = function (c) {
   var hex = c.toString(16);
   return hex.length == 1 ? "0" + hex : hex;
  }

  getContrast50 = function (hexcolor){
    return (parseInt(hexcolor, 16) > 0xffffff/2) ? '#000':'#fff';
  }

  rgbToHex = function (rgb_string){
    var output, rgb_vals, itor;
    rgb_vals= rgb_string.match(/\d+/g);
    output = [];
    
    for(var itor=0; itor < rgb_vals.length; itor++){
      output.push(parseInt(rgb_vals[itor]));
    }

    return componentToHex(output[0]) + 
      componentToHex(output[1]) + 
      componentToHex(output[2]);
  }

  return {
    getContrast50: getContrast50,
    rgbToHex: rgbToHex
  }
})();
