var createReactClass = require('create-react-class');
import React from 'react'

const TooManyAttachments = createReactClass({
    render: function(){
    return (
      <div className="reveal-modal tiny" data-reveal>
        <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <h4>Maximum Media Inserted</h4>
          <p>You have inserted 25 media items and cannot add any more.</p>
          <div className='modal-footer'>
            <a className='cancel-modal button primary'>Done</a>
          </div>
        </div>
      </div>
    </div>
    );
  }
})

export default TooManyAttachments;