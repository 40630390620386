import React from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';

const columnsConfig = [
  {
    title: 'User',
    data: 'full_name',
    render: (row) => {
      return `<a class='email' target='_blank' href='/admin/users/${row.user_id}'>${row.full_name}</a>`;
    }
  },
  { title: 'Flagged By', data: 'flagger' },
  { title: 'Flag Date', data: 'flag_date' },
  { title: 'Ban Date', data: 'ban_date', tooltip: 'Users are banned at 12AM PST' },
  { title: 'Reason', data: 'reason' }
];

const FlaggedUsers = () => {

  const rowRenderCallback = (row, data) => {
    const threeDays = 259200000
    let banDate = new Date(data[3])
    let currDate = new Date()
     if((banDate.getTime() - currDate.getTime()) <= threeDays) {
      row.classList.add('highlight')
     }
  }

  return (
    <DynamicJqueryTable
      columnsConfig={columnsConfig}
      path="/admin/users/flagged_index"
      tableId="flagged-users-table"
      tableTitle="Flagged Accounts"
      rowRenderCallback={rowRenderCallback}
      noDatepicker={true}
      sortProps={[3, 'asc']}
    />
  )
};

export default FlaggedUsers;