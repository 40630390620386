import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import SmallArticleCard from '../../Shared/ContentCards/SmallArticleCard';
import DeleteButton from '../DeleteButton';


const FeaturedMultimediaWidget = ({widgetId, slug, handleDeleteWidget}) => {

  const [medias, setMedias] = useState([]);
  const [offset, setOffset] = useState(0);
  const [offsetCount, setOffsetCount] = useState(4);

  useEffect(() => {
    if(widgetId) {
      let params = { offset }
      let onSuccess = (res) => {
        setMedias(res.contents)
      };
      ChannelApi.fetchWidget({widgetId, slug, params, onSuccess})
    }
  }, [widgetId, offset])

  const renderUrl = (media) => {
    return (media.video_url ? media.video_url : media.figure_url);
  }

  const renderMedias = () => {
    return medias.map((media) => {
      const { title, type, image } = media;
        return (
          <div className="small-6 medium-3 large-3 columns attachment-container">
            <div className='image-container'>
              <a href={renderUrl(media)} target="_blank">
                {media.video_url && <i class="fas fa-play-circle play-button"></i>}
                <img src={image}/>
              </a>
            </div>
            <div className='row figure-row'>
              <p className='figure-type'>{type}</p>
            </div>
            <h6>{title}</h6>
          </div>
        )
      })
  }

  const offSetRight = () => {
    setOffset(offset + offsetCount);
  }

  const offSetLeft = () => {
    if(offset === 0) {
      return
    }
    setOffset(offset - offsetCount);
  }

  const disableLeftArrow = () => {
    if(offset === 0) {
      return 'disable-arrow';
    }
  }

  const disableRightArrow = () => {
    if (medias.length < 4) {
      return 'disable-arrow';
    }
  }

  const renderButtons = () => {
    return (
      <>
        <i class={"far fa-arrow-alt-circle-left arrows " + disableLeftArrow()} onClick={offSetLeft}></i>
        <i class={"far fa-arrow-alt-circle-right arrows " + disableRightArrow()} onClick={offSetRight}></i>
      </>
    )
  }

  return (
    <div className="row small-collapse">
      <div className="small-12 columns">
        <div className="widget featured-multimedia-section">
          {handleDeleteWidget && <DeleteButton
            handleDeleteWidget={handleDeleteWidget}
            widgetId={widgetId}
            className="parent-widget"
          />}
          <div className='row'>
            <h2>Featured Multimedia</h2>
          </div>
          <div className={`row media-container ${medias.length < 4 ? 'flex' : 'block'}`}>
            {renderMedias()}
          </div>
          <div className='row text-center'>
            {renderButtons()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedMultimediaWidget;