import React, { useState, useEffect } from 'react';
import { Cureus } from '../../../Cureus';
import ChannelApi from '../../../Cureus/ChannelApi';

const AboutModal = ({handleAddWidget, widget={}, handleUpdateWidget, widgetAboutMainId, slug}) => {
  const [blurb, setBlurb] = useState(widget.blurb)
  const [image, setImage] = useState(null)
  const [imageFilename, setImageFilename] = useState(widget.imageFilename)
  const [disabled, setDisabled] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [widgetId, setWidgetId] = useState(widget.id)

  useEffect(() => {
    setDisabled(blurb ? '' : 'disabled')
  }, [blurb])

  useEffect(() => {
    if(widgetAboutMainId) {
      let onSuccess = (res) => {
        let fetchedBlurb = res.content.blurb
        setBlurb(fetchedBlurb)
        setImageFilename(res.content.imageFilename)
        initCkEditor(fetchedBlurb)
        setWidgetId(res.content.id)
      }
      let widgetId = widgetAboutMainId
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    } else {
      initCkEditor(blurb)
    }
  }, [])

  const initCkEditor = (blurb) => {
    new Cureus.CKEditor('channel-about-blurb', {
      handle_change: updateBlurb,
      editor_type: 'static_text_with_color',
      ckeditor: {height: 250},
      initialValue: blurb
    })
  }

  const updateBlurb = () => {
    setBlurb(CKEDITOR.instances["channel-about-blurb"].getData())
  }

  const handleAddWidgetWrapper = () => {
    setDisabled('disabled')
    setErrorMessage('Upload in progress!')
    let params = createFormData()
    if(widgetId) {
      handleUpdateWidget(widgetId, params, true)
    } else {
      handleAddWidget(params, true)
    }
  }

  const createFormData = () => {
    let formData = new FormData();
    if(widgetAboutMainId) {
      formData.append("widget[parent_id]", widgetAboutMainId);
    }
    formData.append("widget[type]", `Widget::AboutSection`);
    formData.append("widget[blurb]", blurb);
    if(image) {
      formData.append("widget[image]", image);
    }

    return formData;
  }

  const renderImage = () => {
    if(imageFilename) {
      return <span>Uploaded: {imageFilename}</span>
    }
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>About Page</h5>
            <hr/>
          </div>
          <p>Your About page should be more than a corporate resume. It should describe who you are. Write about your values, principles, and the mission that drives your organization. We recommend including an image of your team or organization.</p>
          <div className="row">
            <div className="small-12 columns">
              <textarea id="channel-about-blurb" name="channel-about-blurb" style={{height: '250px'}} />
            </div>
          </div>
          <br/>
          <div className="row">
            <div className='small-12 columns'>
            <div className="row file-container flex">
              <div className="small-8 columns file-uploads">
                <label htmlFor="image">Image (recommended)</label>
                <input
                  id="image"
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
              {renderImage()}
              </div>
            </div>
          </div>
          <div className='modal-footer text-center'>
            <a className={"button primary " + disabled} onClick={handleAddWidgetWrapper}>Add</a>
            {disabled && <p className="error-message text-left">{errorMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}


export default AboutModal;