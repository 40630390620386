var createReactClass = require('create-react-class');
import React from 'react';

const Page = createReactClass({

	handlePageSelect: function(e) {
    e.preventDefault();
    if(this.props.pageNumber == 0 || this.props.pageNumber > this.props.totalPages){
      return;
    }
		this.props.handlePageSelect(this.props.pageNumber);
	},

	handleCustomCharacterDisplay: function() {
		return this.props.character ? this.props.character : this.props.pageNumber
	},

	checkCurrentPage: function(){
		return this.props.pageNumber == this.props.currentPage ? "current" : "";
	},

	render: function() {

		return (
			<li className= {this.checkCurrentPage()} >
				<a onClick= {this.handlePageSelect}> {this.handleCustomCharacterDisplay()} </a>
			</li>
		)
	}
})

export default Page;