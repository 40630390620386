import React from 'react';

const UnacceptedArticleTypes = () => {
  return (
    <React.Fragment>
      <h1>Not Currently Accepted</h1>
      <p>Cureus <strong>does not</strong> accept the following submissions:</p>
      <ul>
        <li className='warning'>
          <strong>Articles on medical student, resident or physician burnout</strong>
          <p>All articles related to stress, anxiety or burnout of medical students or professionals will be rejected.</p>
        </li>
        <li className='warning'>
          <strong>Mini-reviews</strong>
          <p>All review article submissions should be comprehensive and include all relevant literature.</p>
        </li>
        <li className='warning'>
          <strong>Review protocols</strong>
          <p>All review protocols, including systematic review protocols, will be rejected.</p>
        </li>
        <li className='warning'>
          <strong>Letters to the editor</strong>
          <p>Cureus does not accept or publish letters to the editor.</p>
        </li>
      </ul>
    </React.Fragment>
  )
}

export default UnacceptedArticleTypes;