import React from 'react';

const AddingTables = () => {

  return (
    <React.Fragment>
      <h1>Adding Tables</h1>
      <p>Follow these steps to paste and insert a table directly into your article:</p>
      <ol className='decimals indented-for-medium-up'>
        <li>Place your cursor where you wish to insert the table and click the Insert Table button in the toolbar.</li>
        <li>Paste your previously created table from Microsoft Word, Excel, Google Docs or Google Sheets into the text field by selecting Paste from the Edit menu in your browser toolbar OR using the following key commands: 'Ctrl+V' on PC or 'Cmd+V' on Mac.</li>
        <li>Paste your previously created table from Microsoft Word, Excel, Google Docs or Google Sheets into the text field and edit as needed.</li>
        <li>Enter a title in the title field.</li>
        <li>If desired, enter a legend in the legend field.</li>
        <li>Click the Insert button to insert the table into your article.</li>
        <li>Double-check the inserted table to make sure it is legible and formatted correctly.</li>
      </ol>
    </React.Fragment>
  )

}

export default AddingTables;