import React, { useEffect } from 'react';
import { Cureus } from '../../Cureus';

const Ad = ({ props, size }) => {

  useEffect(() => {
    new Cureus.AdsNew(props);
  }, [])

  return (
    <div className={ `cureus-ad ${ size }`}>
      ADVERTISEMENT
      <div id={ `edh-${props.size}-${props.divNum}` }/>
    </div>
  )
}

export default Ad;