import React, {useState, useRef} from 'react';
import useClickOutside from '../useClickOutside';
import {Cureus} from '../../Cureus';

const CopyPeRatio = ({currentRatio, updatePath, type, header, ratioLabel, updateMessage}) => {
  const [editing, setEditing] = useState(false)
  const [ratio, setRatio] = useState(currentRatio)

  const RatioInput = useRef("menu");

  useClickOutside(RatioInput, () => {
    setEditing(false);
  });

  const handleSave = () => {
    const editorial_settings ={ [type]: ratio };
    $.ajax({
      type: "PUT",
      url: updatePath,
      data: {editorial_settings: editorial_settings},
      success: function(res) {
        Cureus.Flash.success(`${updateMessage} updated successfully.`)
        $("html, body").animate({scrollTop: 0}, 1000);
      },
      error: function() {
        Cureus.Flash.alert(`${updateMessage} could not be updated.`)
        $("html, body").animate({scrollTop: 0}, 1000);
      }
    })
  }

  const renderInput = () => {
    if (editing) {
      return <input
        type="text"
        ref={RatioInput}
        value={ratio}
        onChange={(e) => setRatio(e.target.value)}/>
    } else {
      return <div
        className="editing-false"
        onClick={() => setEditing(true)}>{ratio}</div>
    }
  }

  return (
    <div className="copy-pe-ratio-container">
      <span>{header}</span>
      <div className="grey-box">
        {renderInput()}
        <span>{ratioLabel}</span>
      </div>
      <a className="button small" onClick={handleSave}>Save</a>
    </div>
  )
}

export default CopyPeRatio;