var createReactClass = require('create-react-class');
import React from 'react';
import HistoryModal from './HistoryModal';
import Modal from '../Modal';

const Main = createReactClass({
  componentDidMount: function() {
    var historyIcons = document.querySelectorAll('[data-authorship-reviewership-reveal-id]')
    var self = this;
    historyIcons.forEach(function(icon) {
      icon.addEventListener('click', function() {
        var authorshipReviewershipId = icon.dataset.authorshipReviewershipRevealId;
        var isAuthorship = (icon.attributes.reviewership === undefined)
        Modal.open({
          children: <HistoryModal isAuthorship={isAuthorship} authorshipReviewershipId={authorshipReviewershipId} articleId={self.props.articleId} />
        });
      });
    });
  },

  render: function() {
    return(
      <div></div>
    );
  }
});

export default Main;

