// modified code from https://github.com/codeshifu/react-highlight-pop
import React, { Component, createRef } from 'react';

class HighlightPop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
      x: 0,
      y: 0,
      selectedText: ''
    };

    this.highlight = createRef();
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.onMouseUp);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  hidePopover = () => {
    this.setState({ showPopover: false });
  };

  onMouseUp = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if (!selectedText) {
      this.hidePopover();
      return;
    }

		const selectionRange = selection.getRangeAt(0);

    const startNode = selectionRange.startContainer.parentNode;
    const endNode = selectionRange.endContainer.parentNode;

    const highlightable = this.highlight.current;
    const highlightableRegion = highlightable.querySelector('.h-popable');
    
    if (highlightableRegion) {
      if (
        !highlightableRegion.contains(startNode) ||
        !highlightableRegion.contains(endNode)
      ) {
        this.hidePopover();
        return;
      }
    } else if (
      !highlightable.contains(startNode) ||
      !highlightable.contains(endNode)
    ) {
      this.hidePopover();
      return;
    }

    const { x, y, width } = selectionRange.getBoundingClientRect();
    if (!width) {
      this.hidePopover();
      return;
    }
    let finalX = x
    let leftSidebar = document.querySelector('.left-sidebar')
    if(getComputedStyle(leftSidebar).display !== 'none') {
      finalX = x - leftSidebar.offsetWidth
    }
    this.setState({
      x: finalX + width / 2,
      y: y + window.scrollY - 70,
      selectedText,
      showPopover: true
    });

		const { onHighlightPop = () => {} } = this.props;
		onHighlightPop(selectedText);
	};

  render() {
    const { showPopover, x, y, selectedText } = this.state;
    const { children, popoverItems } = this.props;
    const itemClass = 'h-popover-item';
    return (
      <div ref={this.highlight}>
        {showPopover && (
          <div
            className="h-popover"
            style={{ left: `${x}px`, top: `${y}px` }}
            role="presentation"
            onMouseDown={e => e.preventDefault()}
          >
            {popoverItems ? (
              popoverItems(itemClass, selectedText)
            ) : (
              <span role="button" className={itemClass}>
                Add yours
              </span>
            )}
          </div>
        )}
        {children}
      </div>
    );
  }
}

HighlightPop.defaultProps = {
  onHighlightComment: null,
  onExitHighlight: null,
	popoverItems: null,
	children: null
};

export default HighlightPop;