import React, { useEffect } from 'react';

const MatchedArticles = ( { datasets } ) => {

  useEffect(() => {
    $("#matched-articles-table").DataTable({
      dom: '<"row"<"large-4 columns"l><"large-6 columns"f>>t<"row"<"large-8 columns"i><"pull-right"p>>',
    });
  }, [])

  useEffect(() => {
    populateTable()
  })
  
  function populateTable() {
    var dt = $("#matched-articles-table").DataTable();
    dt.api().clear();
    for (var i = 0; i < datasets.length; i++) {
      var editor = datasets[i];
      dt.fnAddData( [
          `<a href='/admin/users/${editor.id}' target='_blank'>${editor.full_name}</a>`,
          editor.matches,
          editor.claimed_matches,
          editor.in_queue
      ], false)
    }
    dt.fnDraw()
  }

  return (
    <table className="table-wrapper data-table" id="matched-articles-table">
      <thead>
        <tr>
          <th>Editor</th>
          <th>Lifetime Matches</th>
          <th>Lifetime Matches Claimed</th>
          <th>Matches Currently Pending Assignment</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  )
}

export default MatchedArticles;