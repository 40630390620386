
var createReactClass = require('create-react-class');
import React from 'react';

const ChannelTile = createReactClass({
  handleClick: function(e) {
    this.props.onClick();
    e.preventDefault();
  },

  renderSubChannelLogo: function() {
    if(!this.props.selectedChannel) {
      return;
    }
    if (this.props.selectedChannel.parent_id === this.props.channel.id) {
      return (
        <div className="subchannel-container">
          <img src={this.props.selectedChannel.logo_url} />
          <a href="#">Edit</a>
        </div>
      )
    }
  },

  render: function() {
    if(this.props.active) {
      var cx = 'card-tile active';
    } else {
      var cx = 'card-tile';
    }

    if(this.props.channel.thumbnail_url){
      var thumbnail = <img src={ this.props.channel.thumbnail_url }/>
    }else{
      var thumbnail = <div className="thumbnail-placeholder"/>
    }

    return (
      <div className={ cx } onClick={ this.handleClick }>
        <div className='thumbnail'>
          <div className="card-tile-thumbnail-mask"/>
          { thumbnail }
          <div className='logo'>
            <img src={ this.props.channel.logo_url }/>
          </div>
        </div>

        <div className='details'>
          <div className='title'>
            { this.props.channel.title }
          </div>
          {this.renderSubChannelLogo()}
        </div>
      </div>
    )
  }
});

export default ChannelTile;
