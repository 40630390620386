import React, { useState, useEffect } from 'react';

const HeroTest = ({}) => {
  useEffect(() => {
    
  }, [])

  return (
    <div>HeroTest</div>
  )

}

export default HeroTest;