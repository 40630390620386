import React, {useState, useEffect} from 'react';

const MultimediaCard = ({multimedia}) => {
  return (
    <div className="small-12 medium-3 large-3 attachment-container">
      <div className='new-badge'>New</div>
      <a href={multimedia.attachment_location} target="_blank">
        <div className='image-container'>
        {multimedia.video_url && <i class="fas fa-play-circle play-button"></i>}
          <img 
            src={multimedia.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src="https://public.cureus.com/new_default_article_image.png"
            }}
          />
        </div>
        <div className='figure-row'>
          <p className='figure-type'>{multimedia.type}</p>
        </div>
        <h6>{multimedia.title}</h6>
      </a>
    </div>
  )
}

export default MultimediaCard;