
var createReactClass = require('create-react-class');
import React from 'react';
import AuthorDetailsModal from './AuthorDetailsModal';
import * as UserToolUtils from '../../Publishing/UserTool/ApiClient';
import Modal from '../../Modal';

const ChangeEmailModal = createReactClass({
  getInitialState: function() {
    return({
      searchEmail: this.props.authorship.user.email,
      errors: false,
      spinner: false
    })
  },

  updateSearchEmail: function(e) {
    var email = e.target.value.trim()
    this.setState({searchEmail: email});
  },

  handleKeyPress: function(e) {
    if(e.key === 'Enter') {
      this.handleSearch();
      e.preventDefault();
    }
  },
  
  handleSearch: function() {
    var self = this;
    self.setState({spinner: true})
    UserToolUtils.getSearch({
      email: self.state.searchEmail,
      endpointUrl: self.props.endpointUrl,
      params: self.props.defaultParams,
      success: function(user) {
        self.setState({spinner: false}, function() {
          Modal.open({
            children: <AuthorDetailsModal
            idx={this.props.idx}
            specialtyOptions={this.props.specialtyOptions}
            countryOptions={this.props.countryOptions}
            affiliationNameQueryUrl={this.props.affiliationNameQueryUrl}
            deptAutocompletePath={ this.props.deptAutocompletePath }
            updateUsership={this.props.updateUsership}
            endpointUrl= { this.props.endpointUrl }
            resource={ this.formatResource(user) }
            defaultParams= { this.props.defaultParams }
            />
          })
        })
      },
      error: function(jqXHR) {
        self.setState({
          spinner: false,
          errors: JSON.parse(jqXHR.responseText)
        })
      }
    })
  },

  formatResource: function(user) {
    let authorship = this.props.authorship;
    let resource = { authorship: authorship }
    if (!user.is_confirmed) {
      // use the authorship affiliation data
      resource.editable = true;
      user.registered = user.first_name !== null
      let affiliation = authorship.article_affiliations[0]
      let authorship_user = authorship.user
      user.first_name = authorship_user.first_name
      user.middle_initial = authorship_user.middle_initial
      user.last_name = authorship_user.last_name
      user.department_name = affiliation.departmentName;
      user.institution_name = affiliation.institutionName;
      user.city = affiliation.city;
      user.country = affiliation.country;
      user.affiliation_country = affiliation.country;
      user.affiliation_city = affiliation.city;
      user.specialty_name = authorship_user.specialty_name;
      user.email = this.state.searchEmail;
      user.change_email = true;
    }
    resource.user = user;
    return resource;
  },

  render: function() {
    return (
      <div className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Change Co-author Email Address</h4>
            <p>Did you enter the incorrect email address? Does your co-author already have a Cureus account? If so, please provide the correct email address here.</p>
            <div>
              <label htmlFor='email-search'>Email Address:</label>
            </div>
            <div className="row">
             <input type='text' autoFocus onChange={ this.updateSearchEmail } value={this.state.searchEmail} style={{width: '70%'}} onKeyPress={ this.handleKeyPress } />
            </div>
            <div className='modal-footer'>
                <a className='cancel-modal button secondary'>Cancel</a>
                <a className='button' onClick={ this.handleSearch }>Search</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default ChangeEmailModal;