import React, {useState} from 'react';

const Pill = ({pill, removePill}) => {

  const [pillHovered, setPillHovered] = useState(false);
  
  const handleMouseOver = () => {
    setPillHovered(true);
  };

  const handleMouseOut = () => {
    setPillHovered(false);
  };

  return (
          <div className="pill" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <span>{pill.name}</span>
            <img onClick={() => removePill(pill)} src={ pillHovered ? "https://public.cureus.com/collections/close-hover.png" : "https://public.cureus.com/collections/close-nohover.png"} />
          </div>
  )
}

export default Pill;