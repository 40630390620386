import React, { useState, useEffect, useRef } from 'react';
import useClickOutside from '../useClickOutside';

const Selector = ({initialContributions, id}) => {
  const [contributions, setContributions] = useState(initialContributions)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])

  const contributionPopout = useRef("menu");
  useClickOutside(contributionPopout, () => {
    setOpen(false);
  });
  
  useEffect(() => {
    setSelected(initialContributions.filter((contribution) => {
        return contribution.declared
    }).map((contribution) => {
      return contribution.id
    }))
  }, []);

  const handleCheckboxChange = (e, contribution) => {
    const { checked } = e.target;
    if (checked) {
      setSelected((prevSelected) => [...prevSelected, contribution.id]);
    } else {
      setSelected((prevSelected) =>
        prevSelected.filter((id) => id !== contribution.id)
      );
    }
  };

  const handleApply = () => {
    $.ajax({
      type: 'PUT',
      url: '/publish/authorships/update_contributions',
      data: {contribution_ids: selected, id: id},
      dataType: 'json',
      success: function(res){
        setOpen(!open)
        setContributions(res.contributions)
      },
      error: function() {
        Cureus.Flash.alert('There was an issue, please try later.')
      }
    })
  }

  const renderMessage = () => {
    const declared = contributions.filter((contribution) => {
      return contribution.declared
    })
    if (declared.length === 0) {
      return <span>Contributions (please check all that apply)</span>
    } else {
      const overflow = declared.length > 1 ? `(+${declared.length - 1})` : ""
      return (
        <span>{declared[0].label} {overflow}</span>
      )
    }
  }
  
  return (
    <div ref={contributionPopout}>
      <div className="contribution-selector" onClick={() => setOpen(!open)}>
        {renderMessage()}
        <i class="fas fa-sort-down"></i>
      </div>
      {open && 
        <div className="contribution-popout">
          {contributions.map((contribution) => {
            return (
              <>
                {contribution.subheader && 
                  <div className="sub-header">
                    {contribution.subheader}
                  </div>
                }
                <div className="line-item">
                  <input
                    id={contribution.id}
                    className="checkbox"
                    type="checkbox"
                    checked={selected.includes(contribution.id)}
                    onChange={(e) => handleCheckboxChange(e, contribution)}
                  />
                  <label htmlFor={contribution.id}>{contribution.label}</label>
                </div>
              </>
            )
          })}
          <a className="button" onClick={handleApply}>Apply</a>
        </div>
      }
    </div>
  )
}

export default Selector;