import React, {useState, useEffect, useRef} from "react";
import SiqHoverWrapper from '../../Shared/SiqHoverWrapper';
import ScoringModal from '../../../components/Scoring/ScoringModal';
import Modal from '../../Modal';
import SiqSignInModal from './Modals/SiqSignInModal';
import { Cureus } from '../../../Cureus';

const Siq = ({article, size, preview, articleRatingsAndSiq, setArticleRatingsAndSiq, siqWidget, isUserSignedIn, fireAmplitude}) => {
  const [justScored, setJustScored] = useState(false)
  const didMount = useRef(false);

  useEffect( () => {
    if ( !didMount.current ) {
      return didMount.current = true;
    }
    openModalIfParams()
  }, [isUserSignedIn])

  const openModalIfParams = () => {
    let urlSearchParams = new URLSearchParams(window.location.search);
    let bool = Boolean(urlSearchParams.get("score_article"))
    if (bool) { openModal() }
  }

  const handleClicked = (buttonType="Rate Article") => {
    fireAmplitude('articleSIQClicked', {source: "article page", target_type: "Article", target_id: article.id, button: buttonType})
    $(window).scrollTop(0);
    openModal()
  }

  const openModal = () => {
    if (!isUserSignedIn) {
      Modal.open({
        children: <SiqSignInModal/>})
    } else if (siqWidget.daily_limit) {
      window.scrollTo(0, 0);
      Cureus.Flash.warning("You've read and rated 20 articles today - this is our daily limit.")
    } else if (siqWidget.rate_limit) {
      window.scrollTo(0, 0);
      Cureus.Flash.warning("There is a five-minute cooldown period before you can rate your next article. Have you read the entire article?")
    } else {
      Modal.open({
        children: <ScoringModal
          siq_widget={siqWidget}
          articleRatingsAndSiq={articleRatingsAndSiq}
          setArticleRatingsAndSiq={setArticleRatingsAndSiq}
          setJustScored={setJustScored}
      />})
    }
  }

  const hasScored = () => {
    return (siqWidget.user_has_scored || justScored)
  }

  const renderSiqHover = () => {
    return (
      articleRatingsAndSiq &&
        <SiqHoverWrapper
          article={article}
          articleRatingsAndSiq={articleRatingsAndSiq}
          handleOpenModal={handleClicked}
          hasScored={hasScored()}
          size={size}
          preview={preview}
        />
    )
  }

  const renderButtonOrCopy = () => {
    if (preview) {
      return renderCopy()
    } else {
      return renderButton()
    }
  }

  const renderCopy = () => {
    return (
      <div className="preview-copy">
        <p>Scholarly Index Quotient&trade; enables readers to rate your published article.</p>
        <a target="_blank" href="/siq">Learn More<i className="fa fa-angle-double-right"/></a>
      </div>
    )
  }

  const renderButton = () => {
    if (size == "medium") {
      return medButton()
    } else {
      return smallButton()
    }
  }

  const smallButton = () => {
    let disabledClass = (handleSmallButtonRender()) ? "" : "disabled"
    let text =  hasScored() ? "Already Rated" : "Rate Article"

    return (
      <div className={`button special small ${disabledClass}`} onClick={() => {handleClicked()}}>
        {text}
      </div>
    )
  }

  const medButton = () => {
    let disabledClass = (siqWidget.user_can_score && !preview) ? "" : "disabled"
    let text =  hasScored() ? "Already Rated" : "Contribute Article"

    return (
      <>
        <h2>Rated by 2 Readers</h2>
        <p>Scholary Impact Quotient is our unique post-publication peer review rating process</p>
        <strong><a className="more" href="/siq" target="_blank">Read More</a></strong>
        <div className="button-section">
          <div className={`button special small ${disabledClass}`} onClick={() => {handleClicked()}}>
            {text}
          </div>
          {renderWhatsThis()}
        </div>
      </>
    )
  }

  const handleSmallButtonRender = () => {
    if (!isUserSignedIn && !preview) {
      return true;
    } else if (siqWidget.user_can_score && !preview) {
      return true;
    } else {
      return false;
    }
  }

  const renderWhatsThis = () => {
    return <div className="siq-bottom">
      <a href="/siq" target="_blank">What's This?</a>
    </div>
  }

  return (
    <div className={`siq ${size}`}>
      <div className="siq-main">
        <div className="siq-icon">
          {renderSiqHover()}
        </div>
        <div className="siq-button">
          {renderButtonOrCopy()}
        </div>
      </div>
      {size != "medium" && !preview && renderWhatsThis()}
    </div>
  )
}

export default Siq

