var createReactClass = require('create-react-class');
import React from 'react';

const Authors = createReactClass({
  render: function(){
    return (
      <div>
        <h4 className="reg">Author affiliations</h4>
        <p>
          Author affiliations (department, institution, location) will be listed in PubMed Central and cannot be altered after the article has been indexed in PMC. In cases where two or more authors hail from the same department or institution, affiliations should be entered consistently for all relevant authors.
        </p>
        <img src="https://public.cureus.com/author-affiliations.png"/>
      </div>
    );
  }
});

export default Authors;