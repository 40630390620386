var createReactClass = require('create-react-class');
import React from 'react';

const SocialBoost = createReactClass({
  render: function() {
   return (
    <div className="modal-content middling">
      <h4>Enter relevant social handles (optional)</h4>
      <p>Please provide the Twitter and Instagram handles that you would like tagged in our posts. Multiple handles should be separated by commas.</p>
      <label>
        Your and/or your co-authors’ Twitter(s)
        <input type="text" name="twitter" onChange={this.props.handleChange}/>
      </label>
      <label>
        Your and/or your co-authors’ Instagram(s)
        <input type="text" name="instagram" onChange={this.props.handleChange}/>
      </label>
      <label>
        Academic Institution/ Hospital Twitter
        <input type="text" name="aff_twitter" onChange={this.props.handleChange}/>
      </label>
      <label>
        Academic Institution/ Hospital Instagram
        <input type="text" name="aff_instagram" onChange={this.props.handleChange}/>
      </label>
    </div>
   )
 }
})

export default SocialBoost;