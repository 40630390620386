import React, { useState, useEffect } from 'react';
import ArticleCard from './ArticleCard';
import EllipsePages from '../../Shared/Pagination/EllipsePages';

const RecentArticles = ({recentArticles}) => {

  const [articles, setArticles] = useState(recentArticles.articles);
  const [pagination, setPagination] = useState(recentArticles.paginationData);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    updatePage(1);
  }, []);

  const miniKaminari = (array, currentPage, perPage) => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    
    
    const totalPages = Math.ceil(array.length / perPage);
    const totalItems = array.length;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;
    const paginatedItems = array.slice(startIndex, endIndex);
    
    const newPaginationData = {
      totalPages: totalPages,
      currentPage: currentPage,
      totalItems: totalItems,
      firstPage: isFirstPage,
      lastPage: isLastPage
    };
    setArticles(paginatedItems);
    setPagination(newPaginationData);
  }  

  const updatePage = (page) => {
    miniKaminari(recentArticles.articles, page, 2);
  }

  const renderCards = () => {
    return (
    <div className='card-holder row'>
      {articles.map((article) => {
        return <ArticleCard article={article} />
      })}
    </div>
  )}
 
  return (
    <div className='most-recent-container'>
      <div className='header'>
        <h2>Most Recent Articles</h2>
        <div onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}} className="view-more">
          <a href="#!/articles">
            <img src={hovered ? "https://public.cureus.com/collections/elements-blue.png" : "https://public.cureus.com/collections/elements.png"}/>
            <span>View More</span>
          </a>
        </div>
      </div>
      {renderCards()}
      <div className='pagination-holder'>
        <EllipsePages paginationData={pagination} handlePageSelect={updatePage} paginationType={"ellipses"} />
      </div>
    </div>
  )
}

export default RecentArticles;