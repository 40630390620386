import React, { useState, useEffect } from 'react';

const FeaturedMultimediaModal = ({handleAddWidget, hasLessThan4Multimedia, widgetMainRiverId}) => {
  const shouldDisable = () => {
    return hasLessThan4Multimedia ? "disabled" : "";
  }

  const handleAddWidgetWrapper = () => {
    let params = {
      widget: {
        type: "Widget::FeaturedMultimedia",
        parent_id: widgetMainRiverId,
      }
    }
    handleAddWidget(params)
  }

  const renderMessage = () => {
    if (hasLessThan4Multimedia) {
      return <p style={{color: "#CA3145"}}>More published articles featuring figures and videos are needed before you can add this section!</p>
    } else {
      return <p>Display all figures and videos from all published channel articles. Figures and videos are ordered from newest to oldest and at least four must be present in order to add this section to your channel homepage.</p>
    }
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Article Media</h5>
          </div>
          <hr/>
          {renderMessage()}
          <div className='modal-footer text-center'>
            <a className={"button primary " + shouldDisable()} onClick={handleAddWidgetWrapper}>Add</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedMultimediaModal;