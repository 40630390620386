import React from 'react';

const ChannelEditorialResponsibilities = () => {
  return (
    <React.Fragment>
      <h1>Channel Editorial Responsibilities</h1>

      <p>Channel admins/editors review all article submissions during two distinct steps in the article publication process. All articles must be reviewed within seven days of submission. While channel review is important, the journal editors make the final decision to publish or reject each article.</p>

      <p><strong>Initial Channel Editorial Review:</strong> These manuscripts have just been submitted, and the channel is responsible for reviewing each submission to ensure the following:</p>

      <ul className='disc indented-for-medium-up'>
        <li>The article is submitted by a channel member or otherwise approved author and the topic and scope of the article fits within the channel.</li>
        <li>The article contains credible science with all necessary ethical and registration procedures followed.</li>
        <li>The article is well-written and does not require extensive language editing.</li>
        <li>The article is formatted according to the Cureus Author Guide.</li>
      </ul>

      <p>Articles in need of revision must be deferred back to the authors with a complete list of required changes.</p>

      <p><strong>Final Channel Editorial Review:</strong> These manuscripts have already undergone initial review by the channel and journal and have also completed peer review. The authors have revised the manuscript based on the peer reviewer feedback and submitted the article for final approval. The channel is responsible for reviewing each submission to ensure the following:</p>

      <ul className='disc indented-for-medium-up'>
        <li>The authors have adequately addressed the peer review comments, making revisions where needed.</li>
        <li>The article is ready for publication from a scientific perspective. In other words, no additional revisions besides copy editing are needed prior to publication.</li>
      </ul>

      <p>Articles in need of revision must be deferred back to the authors with a complete list of required changes. Articles that are successfully pre-approved are passed to Cureus editors for final consideration and approval or rejection. The decision to approve or reject is made independently of the earlier assessment performed by the channel editorial team.</p>

    </React.Fragment>

  )

}

export default ChannelEditorialResponsibilities;