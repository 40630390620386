var createReactClass = require('create-react-class');
import React from 'react';

const Sort = createReactClass({
  selectLabel: function() {
    return(
      <div className={this.props.typesRequired ? 'large-3 large-offset-0 medium-4 small-6 columns' : 'large-4 large-offset-0 medium-4 medium-offset-2 small-6 columns'}>
        <div id='sort-filter-component'>
          {this.selectOptions()}
        </div>
      </div>
    )
  },

  valueRenderer: function(val) {
    return (
      <div>
        <i className='fa fa-sort'></i>
        <span>{ val.label }</span>
      </div>
    )
  },

  handleChange: function(val) {
    this.props.updateSort(val)
  },

  selectOptions: function() {
    var selectOptions = this.props.sorts.selectOptions
    if (!this.props.searched) {
      selectOptions = selectOptions.slice(1)
    }
    return(
      <Select
        name={ "order" }
        options={ selectOptions }
        searchable={ false }
        value={ this.props.selectedSort }
        clearable={ false }
        onChange={ this.handleChange}
        valueRenderer={ this.valueRenderer }
        className={ "plain-select" }
      />
    )
  },

  classType: function() {
    if (this.props.hasSubchannels) {
      return 'large-3 large-offset-0 medium-4 medium-offset-0 small-12 columns end'
    }
    if(this.props.compOrChannel) {
      return this.props.typesRequired ? 'large-3 large-offset-0 medium-4 medium-offset-0 small-6 columns end' : 'large-4 large-offset-0 medium-8 medium-offset-2 small-12 columns end'
    }
    if (this.props.posters) {
      return "large-4 medium-6 small-6 columns end"
    }
    if (this.props.searchPage) {
      return 'large-4 medium-6 small-6 columns'
    } else {
      return 'large-4 large-offset-0 medium-4 medium-offset-0 small-6 columns'
    }
  },

  render: function() {
    return(
      <div className={this.classType()}>
        <div id='sort-filter-component'>
          {this.selectOptions()}
        </div>
      </div>
    )
  }
})

export default Sort;