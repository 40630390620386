import React, { useState, useEffect } from 'react';
import Hero from './Hero';
import Tab from './Tab';
import Overview from './Overview/Overview';
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Authors from './Authors/Authors';
import Articles from './Articles/Articles';

const Index = ({collection, collectionPath, articlesPath, authorsPath, topics, keywords, questions, recentArticles, comments, isSubmissionOpen, isCureusAdminCreated, collectionUrl, collectionType, sidebarAd, subscribePath, currentUser, emailListId, searchArticlesPath, featuredMultimediaPath, searchAuthorsPath, subscribed, preview, featuredPeople, startDate, endDate, collectionDisplayName}) => {

  const [activeTab, setActiveTab] = useState(null);
  const tabs = ["Overview", "Articles", "Authors"];
  const paths = {
    "Overview" : '#!/',
    "Articles" : '#!/articles',
    "Authors" : '#!/authors'
  }

  return (
    <div className='overview-container large-12 medium-12 small-12'>
      <Hero collection={collection} isSubmissionOpen={isSubmissionOpen} isCureusAdminCreated={isCureusAdminCreated} collectionUrl={collectionUrl} collectionType={collectionType} subscribePath={subscribePath} currentUser={currentUser} emailListId={emailListId} subscribed={subscribed} collectionPath={collectionPath} startDate={startDate} endDate={endDate} collectionDisplayName={collectionDisplayName}/>
      <div className='tabs-holder'>
        {tabs.map((tab) => {
            return <Tab title={tab} page={tab} setTab={setActiveTab} activeTab={activeTab} path={paths[tab]}/>
          })}
      </div>
      <Router hashType={"hashbang"}>
        <Switch>
          <Route path='/' exact>
            <Overview collection={collection} topics={topics} keywords={keywords} setActiveTab={setActiveTab} questions={questions} recentArticles={recentArticles} comments={comments} sidebarAd={sidebarAd} preview={preview} collectionType={collectionType} featuredPeople={featuredPeople}/>
          </Route>
          <Route path='/articles'>
            <Articles articlesPath={articlesPath} setActiveTab={setActiveTab} searchArticlesPath={searchArticlesPath} featuredMultimediaPath={featuredMultimediaPath} />
          </Route>
          <Route path='/authors'>
            <Authors authorsPath={authorsPath} setActiveTab={setActiveTab} searchAuthorsPath={searchAuthorsPath}/>
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default Index;