import React from 'react';

const AuthorCard = ({ author }) => {
  const notMedicalProfessional = author.specialty === "I'm not a medical professional.";

  const renderName = () => {
    if(author.qualifications){
      return `${author.full_name}, ${author.qualifications}`
    }
    return author.full_name
  }

  return (
    <div className="small-12 medium-6 large-4 author-card">
      <div className="circle-image">
        <div>
          <img
            className="avatar-image"
            src={author.avatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = 'https://public.cureus.com/new-default-user-image.png'
            }}/>
        </div>
      </div>
      <div className="title">
        <span>{renderName()}</span>
        {!notMedicalProfessional &&
          <p className="specialty">{author.specialty}</p>
        }
      </div>
      <img
        className="icon"
        src="https://public.cureus.com/collections/show/building.png"/>
      <span className="affiliation">{author.affiliation_names}</span>
      <div className="hovered-content">
        <a
          className="button large learn-more-button"
          href={author.user_path}
          target="_blank">VIEW PROFILE</a>
      </div>
    </div>
  )
}

export default AuthorCard;