import React, {useState, useEffect} from 'react';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const GoogleCalendarEventAddModal = ({createEventPath, calendarId, isValidCalendarToken, googleButton}) => {

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [summary, setSummary] = useState("");
  const [error, setError] = useState(false);

  const updateFrom = (e) => {
    setFrom(e.target.value);
  }

  const updateTo = (e) => {
    setTo(e.target.value);
  }

  const handleEventSubmit = () => {
    if (from.length === 0 || to.length === 0 || summary.length === 0) {
      setError(true);
    } else {
      $.ajax({
        type: "POST",
        url: createEventPath,
        dataType: 'json',
        data: { to_date: to, from_date: from, summary: summary, calendar_id: calendarId},
        success: function() {
          Cureus.Flash.success("You have successfully created the event(s) on the calendar.")
          Modal.close();
        }
      })
    }
  }

  const renderModalContent = () => {
    if (isValidCalendarToken) {
      return renderEventForm();
    } else {
      return renderSignInButton();
    }
  }

  const renderSignInButton = () => {
    return (
      <div className='text-center'>
        <h6>Sign in with google to create an event</h6>
        <div dangerouslySetInnerHTML={{ __html: googleButton }} target="_blank" />
      </div>
    )
  }

  const renderErrorMessage = () => {
    return <small className="error-alert">Please fill out the form completely.</small>
  }

  const renderEventForm = () => {
    return (
      <>
        <div className="modal-header text-center">
        <h5>Availability</h5>
        <hr/>
        </div>
        <div className="google-date-selector-section" aria-hidden="false">
          <div className='event-description-container'>
            <label htmlFor="event-desc">Event Summary</label>
            <input id="event-desc" className='event-input' type="text" placeholder="Submit your event with full name e.g. John Smith - Vacation" value={summary} onChange={(e) => setSummary(e.target.value)} />
          </div>
          <div className="date-inputs-container">
            <div className="date-input-from" style={{width: '165px'}}>
              <label htmlFor="from">From</label>
              <input id="from" name="from" type="date" value={from} className="hasDatepicker" min="2023-01-01" max={"toDate"} onChange={updateFrom} onClick={(e) => e.stopPropagation()} />
            </div>
            <div className="date-input-to" style={{width: '165px'}}>
              <label htmlFor="to">To</label>
              <input id="to" name="to" type="date" value={to} className="hasDatepicker" min="2023-01-01" max={"toDate"} onChange={updateTo} onClick={(e) => e.stopPropagation()} />
            </div>
          </div>
          {error && renderErrorMessage()}
          <div className="date-buttons-container">
            <input className="button secondary apply-btn"  type="button" value="Apply" onClick={handleEventSubmit} />
            <a className="cancel-modal">CANCEL</a>
          </div>
        </div>
      </>
    )
  }

  return (
    <div id="add-date-reveal-modal" className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          {renderModalContent()}
        </div>
      </div>
    </div>
  )
}

export default GoogleCalendarEventAddModal;