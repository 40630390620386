import React, { useEffect, useState } from 'react';
import Modal from '../Modal';

const ChannelAdminReviewArticlesModal = ({notifications}) => {

  const [channels, setChannels] = useState(notifications);

  const closeModal = () => {
    Modal.close();
  }


  const renderPluralization = () => {
    if (notifications.length === 1) {
      return `${notifications.length} article ready for review.`;
    } else {
      return `${notifications.length} articles ready for review.`;
    }
  }

  return (
    <div data-reveal className='reveal-modal' id='channel-admin-review-alert-modal'>
      <div className='modal-body'>
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
      </div>
      <div className='modal-content'>
        <div className='image'>
          <img src="https://public.cureus.com/bell-alerts/guest-editor-alert-modal.png" />
        </div>
        <h4 className='title'>{notifications[0].title}</h4>
        <div className='message'>
          <p>{renderPluralization()}</p>
        </div>
        <a href={`/admin/channels/${notifications[0].channel_id}#!/articles`} className='button primary' onClick={closeModal}>View Dashboard</a>
      </div>
    </div>
  )
}

export default ChannelAdminReviewArticlesModal;