import React, { useState, useEffect } from 'react';
import Main from '../OnDemand/Main'
import DashboardKeywordTool from './DashboardKeywordTool';

const InfoWidget = ({infoWidget, keywordProps, view}) => {
  const renderOnDemand = () => {
    if(infoWidget.canOnDemand) {
      return (
        <div className="large-12 medium-6 medium-push-6 small-12 columns peer-review-button-container large-reset-order">
          <Main 
            articlesPath={infoWidget.articlesPath}
            acceptancePath={infoWidget.acceptancePath}
            cooldown={infoWidget.cooldown}
            currentUserId={infoWidget.currentUserId}
          />
        </div>
      )
    } else if (infoWidget.noRevPool) {
      return (
        <div className="large-12 medium-6 medium-push-6 small-12 columns peer-review-button-container large-reset-order">
          <a className="button special reviewer-pool" data-reveal-id={'reviewer-pool-modal'}><i className="fas fa-user-md"/>Join our Peer Review Panel</a>
        </div>
      )
    }
  }
  
  const renderReviewerKeywordsForm = () => {
    if(!infoWidget.noRevPool || infoWidget.canOnDemand) {
      return (
        <DashboardKeywordTool 
          basePath={keywordProps.basePath}
          keywords={keywordProps.keywords}
          params={keywordProps.params}
          keywordLimit={keywordProps.keywordLimit}
          reviewerGuidePath={keywordProps.reviewerGuidePath}
          autoCompletePath={keywordProps.autoCompletePath}
        />
      )
    }
  }

  const renderViews = () => {
    if (view === 'desktop') {
      return 'show-for-large-up';
    } else {
      return 'show-for-small-only';
    }
  }
  
  const renderReviewerRating = () => {
    return (
      <div className={"small-10 small-offset-1 columns end large-12 large-offset-0 text-left"}>
        <h6>Reviewer Rating</h6>
        <div className="small-8 columns no-padding">
          <div className="stat-container">
            <div className="stat">
            <div className="reviewer-rating" dangerouslySetInnerHTML={{__html: infoWidget.ratingStars}}/>
            </div>

          </div>
        </div>
        <div className="small-4 columns learn-more-container">
          <a href={infoWidget.learnMoreReviewersPath} target="_blank">Learn more</a>
        </div>
      </div>
    )
  }

  const renderCertificate = () => {
    if(infoWidget.certificatePath) {
      return (
        <div className="small-10 small-offset-1 columns end large-12 large-offset-0 text-left">
          <div className="cert-container">
            <div className="download-container round-icon info-widget-download">
              <i class="fas fa-download"/>
            </div>
            <a href={infoWidget.certificatePath} target="_blank">Download Peer Review Certificate</a>
          </div>
        </div>
      )
    }
  }

  return (
    <div className={"large-3 small-12 medium-12 columns bordered info-widget-container " + renderViews()}>
        {renderOnDemand()}
        {renderReviewerKeywordsForm()}
        {renderReviewerRating()}
        {renderCertificate()}
    </div>
  )
}

export default InfoWidget;