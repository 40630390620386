import React from 'react';

const AddingVideos = () => {

  return (
    <React.Fragment>
      <h1>Adding Videos</h1>
      <p>Follow these steps to insert an embedded video directly into your article:</p>
      <ol className='decimals indented-for-medium-up'>
        <li>Upload your video to YouTube or Vimeo.</li>
        <li>Place your cursor where you wish to insert the video and click the Insert Video button in the toolbar.</li>
        <li>Paste the video URL into the URL field and click the Search button. Click the video to make sure it will play correctly.</li>
        <li>Enter a title in the title field.</li>
        <li>If desired, enter a legend in the legend field.</li>
        <li>Click the Insert button to insert the video into your article.</li>
      </ol>
    </React.Fragment>
  )

}

export default AddingVideos;