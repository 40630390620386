var createReactClass = require('create-react-class');
import React from 'react';
import KeywordTool from '../KeywordTool';
import DashboardKeywordTool from '../NewReviewsDashboard/DashboardKeywordTool';

const EditAboutMe = createReactClass({
  getInitialState: function() {
    return {
      bio: this.props.userProfile.bio,
      keywords: this.props.keywords
    }
  },

  componentDidMount: function() {
    var self = this;
    if (self.props.currentStep === 5) {
      $.ajax({
        url: self.props.getComponentDataPath,
        type: "GET",
        data: {class: 'EditAboutMe', user_id: self.props.user_id},
        dataType: "json",
        success: function(data) {
          self.setState({
            basePath: data.basePath,
            keywords: data.keywords,
            params: data.params,
            keywordLimit: data.keywordLimit,
            bio: data.userProfile.bio,
            reviewerGuidePath: data.reviewerGuidePath,
            autoCompletePath: data.autoCompletePath
          })
        }
      })
    } else {
      self.setState({
        basePath: this.props.basePath,
        keywords: this.props.keywords,
        params: this.props.params,
        keywordLimit: this.props.keywordLimit,
        bio: this.props.userProfile.bio,
        reviewerGuidePath: this.props.reviewerGuidePath,
        autoCompletePath: this.props.autoCompletePath
      })
    }
  },
  
  handleChange: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  renderKeywordTool: function() {
    if(this.state.keywords) {
      return (
        <DashboardKeywordTool 
          basePath={this.state.basePath}
          keywords={this.state.keywords}
          params={this.state.params}
          keywordLimit={this.state.keywordLimit}
          reviewerGuidePath={this.state.reviewerGuidePath}
          autoCompletePath={this.state.autoCompletePath}
          reviewsPage={true}
          editProfilePage={true}
        />
      )
    }
  },

  render: function(){
    return (
      <div className="reveal-modal medium" data-reveal data-options={this.props.setUncloseable()}>
      <div className="modal-body">
        {this.props.renderModalCloseX()}
        <div className="modal-content">
          <div className="small-12 columns">
            <h4 style={{marginBottom: '15px'}}>{this.props.confirmation ? 'Create My Intro' : 'Edit My Intro'}</h4>
            <h6>About</h6>
            <p>Share a brief summary of your work, research and career.</p>
            <textarea rows="200" cols="30" name="bio" id="user_bio" value={this.state.bio || ""} onChange={(e) => this.handleChange(e)}></textarea>
            <br/>
            <h6>Reviewer Keywords</h6>
            <span>You can add up to {this.state.keywordLimit} keywords to ensure you are invited to peer review relevant articles. Please select from the available options after you start typing. New keywords cannot be added.</span>
          </div>
          {this.renderKeywordTool()}
        </div>
        {this.props.renderErrors()}
        <div className='modal-footer'>
          {this.props.renderSkipButton()}
          {this.props.renderButton({user: this.state, section: "EditAboutMeForm"}, this.props.updateProfilePath, () => this.forceUpdate())}
        </div>
      </div>
    </div>
    )
  }
})

export default EditAboutMe;