import React from 'react';
import Modal from '../../Modal';
import { Cureus } from '../../../Cureus';

const CollectionDeleteModal = ({collection, fetchCollections}) => {

  const deleteCollection = () => {
    $.ajax({
      type: 'put',
      url: `/publish/collections/${collection.id}/abandon`,
      success: function(res) {
          fetchCollections("unpublished");
          Modal.close();
          Cureus.Flash.success("You have successfully deleted the collection.");
      },
      error: function(res) {
        Cureus.Flash.alert("Something went wrong. Please try again.");
      }
    })
  }

  return (
    <div id={"delete-collection-reveal-modal"} className="reveal-modal new-modal delete-collection-modal middling large-12 columns" data-reveal>
      <div className="modal-body delete-section">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="large-12 columns">
              <h6 className='text-center'>Do you really want to delete this collection?</h6>
            </div>
          </div>
          <div className="modal-footer">
            <a href="#" className="button cancel-modal">Cancel</a>
            <button className="button alert" onClick={deleteCollection}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionDeleteModal;