var createReactClass = require('create-react-class');
import React from 'react';
import Widget from './Widget';
import NewSidebarWidget from './NewSidebarWidget';
import sortable from 'jquery'

const Main = createReactClass({
  componentWillMount: function() {
    this.setState({widgets: this.props.widgets})
  },

  componentDidMount: function() {
    if (this.props.canManage) {
      var self = this;
      $("[data-sorting]").sortable({
        axis: "y",
        helper: "clone",
        update: function(e, ui) {
          var currentWidgetId = parseInt(ui.item[0].id)
          var widgetIdList = $(this).sortable("toArray").map(function(e) {return parseInt(e)})
          var position = widgetIdList.indexOf(currentWidgetId) + 1

          var widgetUpdatePath = self.props.widgets.filter(function(el) {return el.id === currentWidgetId})[0].updateWidgetPath
          $.ajax(widgetUpdatePath, {
            dataType: "json",
            type: "PUT",
            data: {widget: {position: position}}
          })
        }
      })
    }
  },

  updateAllWidgets: function(widgets) {
    this.setState({widgets: widgets})
  },

  renderWidgets: function() {
    var self = this;
    return self.state.widgets.map(function(widget, i) {
      return (
        <Widget
          key={widget.id}
          canManage={self.props.canManage}
          widget={widget}
          updateAllWidgets={self.updateAllWidgets}
          position={i + 1}
        />
      )
    })
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <div className="large-12 columns">
            <h3 className="reg"> Sidebar </h3>
            <div className="edit-page-widget-wrap" data-sorting="true">
              {this.renderWidgets()}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="large-12 columns">
            <div className="new-widget-form">
              <NewSidebarWidget
                parentId={this.props.parentId}
                canManage={this.props.canManage}
                selectOptions={this.props.sidebarWidgetOptions}
                createWidgetPath={this.props.createWidgetPath}
                updateAllWidgets={this.updateAllWidgets}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Main;