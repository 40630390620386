import {Cureus} from '../Cureus';

Cureus.Expander = {
  init: function(){
    Cureus.Expander.doExpander();
  },

  doExpander: function(){
    var self = this;
    $('.expandable').each(function (curr_index, curr_expandable){
      var $curr_expandable, more_link_label, less_link_label, cTimer, slice, expand_prefix;

      $curr_expandable      = $(curr_expandable);
      more_link_label       = $curr_expandable.data('more-label') || "...more";
      less_link_label       = $curr_expandable.data('less-label') || "...less";
      slice                 = $curr_expandable.data('slice-size');
      expand_prefix         = $curr_expandable.data('expand-prefix') || ' ';
      before_expand         = (self[$curr_expandable.data('before-expand')]) ? (self[$curr_expandable.data('before-expand')]) : null;
      on_collapse           = (self[$curr_expandable.data('on-collapse')]) ? (self[$curr_expandable.data('on-collapse')]) : null;


      if(slice){
      }else if ( $curr_expandable.hasClass('bio') == true ){
        slice = 300;
      }else if ( $curr_expandable.hasClass('abstract_text') == true) {
        slice = 300;
      }else {
        slice = 120;
      }
      cTimer = $curr_expandable.hasClass('bio') ? 20000 : 5000 ;
      $curr_expandable.expander({
        slicePoint:         slice,              // default is 100
        expandPrefix:       expand_prefix,      // default is '... '
        expandText:         more_link_label,    // default is 'read more'
        userCollapseText:   less_link_label,    // default is 'read less'
        expandEffect:       "slideDown",
        collapseEffect:     "slideUp",
        beforeExpand:       before_expand,
        onCollapse:         on_collapse
      });
    });
  },

  acadCouncilExpand: function (){
    $(this).closest("section.academic-council-member").addClass("shadow");
  },

  acadCouncilCollapse: function (){
    $(this).closest("section.academic-council-member").removeClass("shadow");
  }
};
