import React, { useEffect, useRef, useState } from 'react';
import LeftSidebar from './LeftSidebar/Sidebar';
import RightSidebar from './RightSidebar';
import Pages from '../Shared/Pagination/Pages';
import Filters from './LeftSidebar/Filters';
import FiltersModal from './FiltersModal';
import Modal from '../Modal';
import SearchFields from './SearchFields';
import SearchResults from './SearchResults';
import SortButtons from './SortButtons';
import ResultsHeader from './ResultsHeader';
import Header from '../NewArticleIndex/Header';
import Ad from '../Shared/Ad';

const Main = ({roles, honorStatuses, allCountries, allSpecialties, bannerAdTop}) => {
  const [statuses, setStatuses] = useState(honorStatuses)
  const [userRoles, setUserRoles] = useState(roles)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [paginationData, setPaginationData] = useState({})
  const [pageCountText, setPageCountText] = useState('')
  const [name, setName] = useState('')
  const [institution, setInstitution] = useState('')
  const [countries, setCountries] = useState(allCountries)
  const [people, setPeople] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [specialties, setSpecialties] = useState(allSpecialties)
  const [showSpecialtyDropDown, setShowSpecialtyDropDown] = useState(false)
  const [showCountriesDropDown, setShowCountriesDropDown] = useState(false)
  const [loading, setLoading] = useState(true)
  const [sortBy, setSortBy] = useState("most_recent_contribution")
  const [sortDirection, setSortDirection] = useState("desc")
  const hasPageRendered = useRef(false)

  useEffect( () => {
    if (hasPageRendered.current) {
      submitSearch()
    }
  }, [perPage, sortBy, sortDirection, page])

  useEffect( () => {
    submitSearch()
    hasPageRendered.current = true
  }, [])

  useEffect( () => {
    if(typeof(bannerAdTop) !== 'object') {
      $('#banner-ad-1').append(bannerAdTop)
    }
  }, [])

  const renderFiltersModal = () => {
    let modal =
      <FiltersModal
        filterComponent={
        <Filters
          setStatuses={setStatuses}
          setUserRoles={setUserRoles}
          statuses={statuses}
          userRoles={userRoles}
          submitSearch={submitSearch}
          modal={true}
          id={"modal-view"}
        />}
      />

    Modal.open({
      children: modal
    });
  }

  const renderFilterAndSearch = () => {
    return (
      <div className="show-for-medium-down">
        <div className="row">
          <div className="columns medium-12 small-12">
            <div className="button white-bg mobile-filter-button" onClick={renderFiltersModal}>
              <img src="https://public.cureus.com/browse-article/filter-icon.png" />
              <span>Filter & Search</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const submitSearch = (e) => {
    if (e) { e.preventDefault() }

    setLoading(true)

    $.ajax("/people", {
      method: 'GET',
      data: params(),
      dataType: 'json',
      contentType: 'application/json',
      success: function(data) {
        setPeople(data.people)
        setTotalCount(data.totalCount)
        setPaginationData(data.paginationData)
        setPageCountText(data.pageCountOverview)
      },
      error: function(data) {
        console.log(data)
      },
      complete: function() {
        setLoading(false)
      }
    })
  }

  const params = () => {
    return {
      statuses: getName(statuses),
      userRoles: getName(userRoles),
      name: name,
      institution: institution,
      specialties: getName(specialties),
      countries: getName(countries, "alpha3"),
      page: page,
      perPage: perPage,
      sortBy: sortBy,
      sortDirection: sortDirection
    }
  }

  const getName = (array, key="name") => {
    let names =  array.map((obj) => { if(obj.checked) { return obj[key] }})
    return names.filter((el) => { return el != null})
  }

  const renderBannerAd = (props, id, classname) => {
    if(typeof(props) === 'object') {
      return (
        <div id={id}>
          <div className={`cureus-ad-wrapper page-banner-ad ${classname}`} >
            <Ad
              props={ props }
              size={ "banner" }
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className={classname} id={id}/>
      )
    }
  }


  return (
    <div className="new-person-index">
      <div className='row small-collapse'>
        <LeftSidebar
          setStatuses={setStatuses}
          setUserRoles={setUserRoles}
          statuses={statuses}
          userRoles={userRoles}
          submitSearch={submitSearch}
        />
        <div className='row'>
          <div className='large-8 medium-12 small-12 columns end'>
            {renderBannerAd(bannerAdTop, 'banner-ad-1')}

            <Header
              pageTitle={"Browse Contributors"}
              categoryIdPreSelected={null}
              cureusUEmailListIds={[1]}
              isSignedIn={true}
            />
            {renderFilterAndSearch()}

            <SearchFields
              name={name}
              institution={institution}
              setName={setName}
              setInstitution={setInstitution}
              setSpecialties={setSpecialties}
              specialties={specialties}
              showSpecialtyDropDown={showSpecialtyDropDown}
              setShowSpecialtyDropDown={setShowSpecialtyDropDown}
              showCountriesDropDown={showCountriesDropDown}
              setShowCountriesDropDown={setShowCountriesDropDown}
              countries={countries}
              setCountries={setCountries}
              submitSearch={submitSearch}

            />
            <SortButtons
              totalCount={totalCount}
              page={page}
              pageCountText={pageCountText}
              perPage={perPage}
              setPerPage={setPerPage}
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
            />
            <ResultsHeader/>

            <SearchResults
              people={people}
              loading={loading}
            />

            <div className='content-index-pagination'>
              <Pages
                paginationData={paginationData}
                handlePageSelect={setPage}
                pagesType={"article"}
              />
            </div>
          </div>
          <RightSidebar/>
        </div>
      </div>
    </div>
  )
}

export default Main;