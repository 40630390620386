import {Cureus} from '../Cureus';

Cureus.ContentToggler = (function($, _){
  var init, ContentToggler;

  init = function (options){
    new ContentToggler(options);
  };

  ContentToggler = function(options) {
    if ( options != undefined) {
      this.eventId = options.eventId;
    };
    this.initialize();   
  };

    _.extend(ContentToggler.prototype, {
      initialize: function() {
        this.showEvent()        
        this.handleSectionToggle();
      },

      handleSectionToggle: function() {
        $("a[data-toggle]").on('click', function(e){
          var toggleId = $(e.currentTarget).data('toggle');
          $(toggleId + " i[data-toggle-content]").toggle();
          $(toggleId + " section").toggle();
          e.preventDefault();
        });
      },

      showEvent: function() {
        if ( this.eventId ) {
          $("#event-" + this.eventId + " section" ).show();
        }
      }
    })
  return {init: init};
})(jQuery, _);
