import React, {useState, useEffect} from 'react';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const ApproveModal = ({applicationId, setApplicationState=null, pendingProposals=null}) => {

  useEffect(() => {
    new Cureus.CKEditor('approve-request-textarea',  { 
      editor_type: 'deferral',
      ckeditor: {height: 300},
      change_listener: "#approve-request-textarea"
    })
  }, [])

  const renderData = () => {
    const message = CKEDITOR.instances["approve-request-textarea"].getData();
    if (message) {
      return { message: message };
    } else {
      return null;
    }
  }

  const handleApproveArticle = () => {
    $.ajax({
      type: 'PUT',
      url: `/admin/collection_applications/${applicationId}/approve`,
      data: renderData(),
      dataType: 'json',
      success: function(res) {
        Modal.close();
        if (pendingProposals) {
          var table = $('#collections-table').DataTable();
          let row = $(`#row-${applicationId}`);
          table.fnDeleteRow(row[0]);
        }
        if (setApplicationState) {
          setApplicationState(res.state);
        }
        Cureus.Flash.success("Collection inquiry has been approved.");
      },
      error: function() {
        Modal.close();
        Cureus.Flash.alert("Collection inquiry could not be approved.");
      }
    });
  }

  return (
    <div id={"approve-collection-modal"} className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="large-12 columns">
              <p>Are you sure you want to approve this collection? Please add any comments for the guest editor below (optional).</p>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <textarea id='approve-request-textarea'></textarea>
            </div>
            <div className="large-12 columns buttons-container">
              <a className="button small success" onClick={handleApproveArticle}>Approve</a>
              <a href="#" className="button small alert cancel-modal">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApproveModal;