import React from 'react';
import { useState } from 'react';

const ConclusionCard = ({ id, title, subtitle, handleQuestionChange, src, name, value, setCredibleConclusion, selectedValue, setDisableSaveButton}) => {

  const [selected, setSelected] = useState("");

  const handleCardClick = (name, value) => {
    handleQuestionChange(id, value)
    setSelected(value);
    setCredibleConclusion(value)
    if(value) {
      setDisableSaveButton(false)
    }
  }

  return (
    <div className={`card-container large-4 columns ${selected == selectedValue ? 'selected' : ''}`} onClick={()=> handleCardClick(name, value)}>
      <div className='card-img-container'>
        <img src={src} />
      </div>
      <div className='card-content'>
        <p>{title}</p>
        <p>{subtitle}</p>
      </div>
    </div>
  )
}

export default ConclusionCard;