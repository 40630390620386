import React, { useState, useEffect } from 'react';
import MeetingCard from '../Shared/ContentCards/MeetingCard';

const Meetings = ({ slug }) => {

  const [meetings, setMeetings] = useState([]);
  const [noMeetings, setNoMeetings] = useState(false);

  useEffect(() => {
    $.ajax({
      type: 'GET',
      url: `/admin/channels/${slug}/meetings`,
      dataType: 'json',
      success: function(data) {
        setMeetings(data.meetings);
        if(data.meetings.length === 0) {
          setNoMeetings(true);
        }
      }
    })
  }, [])

  const renderMeetings = () => {
    return meetings.map((meeting, idx) => {
      return  <MeetingCard key={idx} meeting={meeting} />
    })
  }

  return (
    <div className="channel-main-content-container">
      <div className='row'>
        <div className='small-12 columns widget meeting-content meeting-content-tab'>
          {noMeetings && 
            <div className='settings-box'>
              <h4 className='text-center'>Please select Meetings from the channel menu on the left to add and manage meetings.</h4>
            </div>
          }
          {renderMeetings()}
        </div>
      </div>
    </div>
  )
}

export default Meetings;