import React, { useState, useEffect } from 'react';
import { decodeHtml } from '../../Cureus';

const DiscussionCard = ({discussion, selected, setSelected}) => {
  const [authors, setAuthors] = useState('')
  const [authorsOverflow, setauthorsOverflow] = useState(null)

  useEffect(() => {
    let authorList = discussion.author_list.split(',')
    setAuthors(authorList.slice(0, 2).join(', '))
    let overflow = authorList.slice(2).length
    if(overflow > 1) {
      setauthorsOverflow(overflow)
    }
  }, [])

  const renderAuthorList = () => {
    if(authorsOverflow) {
      return (
        <div className="authors">
          {authors}
          <a href={discussion.resourcePath + '?authors-tab=true'}><span>+{authorsOverflow} more</span></a>
        </div>    
      )
    } else {
      return (
        <div className="authors">
          {authors}
        </div>    
      )
    }
  }

  const renderName = () => {
    if(discussion.commentatorQualis) {
      return (
        <React.Fragment>
          <span className="name">{discussion.commentatorName}, &nbsp;</span>
          <span className="qualis">{discussion.commentatorQualis}</span>
        </React.Fragment>
      )
    } else {
      return (
        <span className="name">{discussion.commentatorName}</span>
      )
    }
  }

  const handleCheck = (e) => {
    let newSelected = [...selected]
    if(e.target.checked){
      newSelected.push(e.target.name)
    } else {
      const index = newSelected.indexOf(e.target.name);
      newSelected.splice(index, 1)
    }
    setSelected(newSelected)
  }

  
  return (
    <React.Fragment>
      <div className="large-6 columns drag-handle">
        <div className="large-1 columns">
          <div className="discussion-checkbox-container">
            <input defaultChecked={discussion.selected} type="checkbox" name={discussion.id} onClick={(e) => handleCheck(e)}/>
          </div>
        </div>
        <div className="discussion-card large-11 columns">
        <a href={discussion.resourcePath}>
          <div className="row">
            <div className="small-9 columns title">
                <a href={discussion.resourcePath}>{discussion.articleTitle}</a>  
              </div>
            <div className="small-3 columns thumbnail">
              <div key={discussion.id + "thumb"} dangerouslySetInnerHTML={{__html: discussion.thumbnail}}/>
            </div>
          </div>
          <div className="row flex-container marg">
            <div className="small-9 columns">
              {renderAuthorList()}  
            </div>
            <div className="small-3 columns">
              <div className="views">
                <i class="fas fa-eye"/> {discussion.viewsCount}
              </div>
            </div>
          </div>
          <div className="discussion-comment-container">
            <div className="row">
                <div className="large-12 columns">
                  <div className="name-container">
                    <img src="https://public.cureus.com/homepage_new/discussion-comment-bub.png"/>
                    {renderName()}
                  </div>
                </div>
            </div>
            <div className="row">
              <div className="large-12 columns">
                <div className="comment-body" dangerouslySetInnerHTML={{__html: decodeHtml(discussion.body)}}/>
              </div>
            </div>
            <div className="row">
              <div className="large-12 columns join-discussion-link">
                <a href={discussion.commentAnchorPath}>Join Discussion <i class="fas fa-arrow-right"/></a>
              </div>
            </div>
          </div>
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DiscussionCard;