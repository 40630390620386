var createReactClass = require('create-react-class');
import React from 'react';
import SiqBadgeImage from '../Shared/SiqBadgeImage'

// This component is getting deprecated. It's missing a bunch of divs to make this visually complete
// use SiqHoverWrapper, do not use SigHover, its missing a bunch of html elements
const SiqHover = createReactClass({
  categoryScore: function(category) {
    return this.props.article.siq_breakdown[category].score.toFixed(1)
  },

  categoryProgress: function(category) {
    return this.props.article.siq_breakdown[category].progress + "%"
  },

  handleClick: function() {
    (this.props.handleOpenModal && this.props.handleOpenModal("Contribute Rating"))
  },

  hasScored: function() {
    return (this.props.hasScored || this.props.article.user_has_scored || this.props.article.user_article)
  },

  renderButton: function() {
    var disabled = this.hasScored() || this.props.preview
    var buttonText = this.hasScored() ? "ALREADY RATED" : "CONTRIBUTE RATING"
    var buttonUrl = this.props.handleOpenModal ? "javascript:void(0)" : this.props.article.rating_link
    var target = this.props.handleOpenModal ? "" : "_blank"

    return (
      <a href={buttonUrl} disabled={disabled} target={target} className="button small special contribute" id={`contribute-button-${this.props.articleId}`} onClick={this.handleClick}>
        {buttonText}
      </a>
    )
  },

  renderCategoryContent: function() {
    var self = this;
    var enoughScores = this.props.article.scorings_count > 1
    return this.props.article.siq_categories.map(function(category, idx) {
      return (
        <div className='row' key={`siq-${idx}`}>
          <div className="small-1 columns react-dash">
            <h4 className="fixed">
              {enoughScores ? self.categoryScore(category) : '-'}
            </h4>
          </div>
          <div className={`small-9 columns ${enoughScores ? 'solid-bars' : 'striped-bars'}`}>
            <h6 className="category">
              {category}
            </h6>
            <div className={`${enoughScores ? 'cureus-progress active' : 'progress-striped'}`}>
              <div className="bar" style={{width: `${enoughScores ? self.categoryProgress(category) : '100%'}`}}>
              </div>
            </div>
          </div>
        </div>
      )
    })
  },

  render: function(){
    var tradeSym = String.fromCharCode(8482)
    return (
      <div className="siq-hover-view">
        <div className="row">
          <div className ={`small-12 columns ${this.props.page}`}>
            <div className={`siq-badge-score react-score ${this.props.size} ${this.props.page} margin`}>
              {this.props.siq}
            </div>
            <div className="siq-image">
              <SiqBadgeImage
                siq={this.props.siq}
                width="98px"
              />
            </div>
          </div>
          <div className={`small-12 columns ${this.props.page}`}>
            <h4 className="reg rated-number">
              {`Rated by ${this.props.article.scorings_count} readers`}
            </h4>
            {this.props.article.scorings_count < 2 &&
            (<p className="siq-p">
              The SIQ for this article will be revealed<br/>once 2 ratings are submitted.
            </p>)}
            {this.renderCategoryContent()}
          </div>
          <div className="small-12 columns contribute-button-column">
            {this.renderButton()}
          </div>
          <div className={`small-12 columns learn-more-column ${this.props.page}`}>
            <a className="learn more"href={this.props.article.learn_more_link} target="_blank">
              Learn more about <strong>SIQ</strong>{`${tradeSym}`}
            </a>
          </div>
        </div>
      </div>
    )
  }
})

export default SiqHover;