import React, {useState, useEffect} from 'react';

const ChannelBadge = ({article}) => {
  const [channelBadgeImage, setChannelBadgeImage] = useState("");
  const [channelTitle, setChannelTitle] = useState("");
  const [channelUrl, setChannelUrl] = useState("");

  useEffect(() => {
    fetchChannelBadge();
  },[])

  const fetchChannelBadge = () => {
    $.ajax({
      type: 'GET',
      url: `/articles/${article.id}/fetch_channel_badge_info`,
      success: function(res) {
        setChannelBadgeImage(res.channelLogo);
        setChannelTitle(res.channelTitle);
        setChannelUrl(res.channelPath);
      }
    });
  }

  return (
    <>
    {channelTitle &&
      <a href={channelUrl} target="_blank">
        <div className='channel-badge-container'>
          <img src={channelBadgeImage} alt="logo" />
          <p>Published via {channelTitle}</p>
        </div>
      </a>
    }
    </>
  )
}

export default ChannelBadge;