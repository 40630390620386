var createReactClass = require('create-react-class');
import React from 'react';

const TotalEarningsTable = createReactClass({
  renderDynamicRows: function() {
    var self = this;
    return Object.keys(self.props.earningsHash).map(function(label) {
      return (
        <tr key={label}>
          <td><strong>{label}</strong></td>
          <td>{self.props.earningsHash[label].count}</td>
          <td>{self.props.earningsHash[label].total}</td>
        </tr>
      )
    })
  },

  render: function() {
    return(
      <div>
        <h4>Totals</h4>
        <table className="table-wrapper" style={{tableLayout: "fixed"}}>
          <tbody>
            {this.renderDynamicRows()}
          </tbody>
        </table>
      </div>
    )
  }
})

export default TotalEarningsTable;