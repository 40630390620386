var createReactClass = require('create-react-class');
import React from 'react';
import Sortable from '../Sortable';
import Row from './Row';

const Table = createReactClass({
  handleReorder: function (e, sortable, movedComponent){
    var self, newPosition, oldPosition;

    self = this;
    newPosition = e.newIndex+1;
    oldPosition = movedComponent.props.emailList.position+1;
    $.ajax({
      type: "PUT",
      dataType: "json",
      url: movedComponent.props.emailList.updateUrl,
      data: { position: e.newIndex }
    })
      .done(function (data, textStatus, jqXHR){
        Cureus.Flash.success( "You have successfully moved line " + oldPosition + " to line " + newPosition + ".");
        self.props.afterReorder();
      })
      .fail(function (jqXHR, textStatus, errorThrown){
        // TODO: Handle error case here
      });

  },
  isChecked: function(emailList) {
    return _.contains(this.props.checked, emailList.id);
  },

  handleCheck: function(emailListId, isChecked) {
    if(isChecked) {
      var checked = this.props.checked;
      checked.push(emailListId);
    } else {
      var checked = _.without(this.props.checked, emailListId);
    }
    this.props.onCheck(checked);
  },

  renderRow: function() {
    var self = this;
    return this.props.emailLists.map( function(emailList) {
      return <Row 
              key={"email-list-row-"+emailList.id}
              onCheck={self.handleCheck}
              isChecked={self.isChecked(emailList)}
              emailList={emailList} />
    })
  },

  renderOrderable: function (){
    return (
      <Sortable tagName='tbody' className="email-list-tool-orderable" handleSort={ this.handleReorder }>
        { 
          this.renderRow()
        }
      </Sortable>
    )
  },

  renderUnorderable: function (){
    return (
        <tbody>
        {
         this.renderRow()
        }
       </tbody>
    )
  },

  render: function (){
    return (
      <table className="full-width table-wrapper">
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Subscribers</th>
            <th>Actions</th>
          </tr>
        </thead>
        { this.props.isOrderable ? this.renderOrderable() : this.renderUnorderable() }
      </table>
    );
  }
});

export default Table;