import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';

const Table = ({renderChevronContainer, path, state, label}) => {

  const [expanded, setExpanded] = useState(false);
  const [tableData, setTableData] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    fetchData();
  }, [expanded])

  const setColumns = () => {
    return [
      {width: '160px'},
      {width: '160px'},
      {width: '160px'},
      {width: '160px'}
    ]
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.approved_at,
        row.accept_reject_at,
        `<a class='email' target='_blank' href='/admin/users/${row.submitter_id}'>${row.submitter}</a>`,
        `<a target="_blank" href="/admin/articles/${row.article_id}">${row.title}</a>`
    ], false)
    )
  }

  const populateTable = (fetchedTableData) => {
    var dt = $(`#${state}-table`).DataTable({
      columns: setColumns(),
      columnDefs: [
        {
          // when displaying, show date in MM-DD-YYYY format, when sorting it will sort by ISO date
          targets: [0, 1],
          render: (date, type, row) => {
            return (type === 'display') ? date : moment(date, "MM-DD-YYYY").toISOString();
          }
        }
      ],
      searching: false,
      dom: '<"row">tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "bAutoWidth": false,
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
    })
    
    dt.api().clear();
    for (var i = 0; i < fetchedTableData.length; i++) {
      let row = fetchedTableData[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
  }

  const fetchData = () => {
    if(tableData) {
      populateTable(tableData)
      return;
    }

    $.ajax({
      type: 'GET',
      url: path,
      data: {state: state},
      success: function(data) {
        setTableData(data.articles)
        populateTable(data.articles)
      }
    });
  }

  const renderHeaderRow = () => {
    return (
      <div className="row article-state-row">
        <div className="small-9 columns article-state-label">
          <h4>{label}</h4>  
        </div>
        <div className="small-1 columns chevron-container">
          {renderChevronContainer(expanded, setExpanded)}
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="article-state-row-container">
        {renderHeaderRow()}
        {expanded && 
          <div className="channel-articles-datatable-container">
            <table id={`${state}-table`}>
              <thead>
                <tr>
                  <th>Approved for Publication</th>
                  <th>{state === 'approved' ? 'Date Included' : 'Date Excluded'}</th>
                  <th>Submitting Author</th>
                  <th>Title</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        }        
      </div>

    </React.Fragment>
  )
}

export default Table;