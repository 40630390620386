import React, { useState, useEffect } from 'react';
import TeamMemberSidebar from './SidebarWidgets/TeamMemberSidebar'
import Sortable from '../../components/Sortable';
import PromotionWidget from './SidebarWidgets/PromotionWidget';
import CureusPromotion from './SidebarWidgets/CureusPromotion';
import OverviewWidget from './SidebarWidgets/OverviewWidget';
import TwitterWidget from './SidebarWidgets/TwitterWidget';
import PollWidget from './SidebarWidgets/Poll/PollWidget';
import MostPopularContent from './SidebarWidgets/MostPopularContent';

const SidebarContent = ({sidebarWidgets, slug, handleDeleteWidget, handleUpdateWidget}) => {
  const sharedProps = {sidebarWidgets, slug, handleDeleteWidget, handleUpdateWidget}
  const componentMapping = (widget) => {
    return {
      'Widget::OverviewSidebar': <OverviewWidget
                                    {...sharedProps}
                                    widgetId={widget.id}
                                  />,
      'Widget::ChannelTeamSidebar': <TeamMemberSidebar
                                      {...sharedProps}
                                      widgetId={widget.id}
                                    />,
      'Widget::ChannelPromotion': <PromotionWidget
                                    {...sharedProps}
                                    widgetId={widget.id}
                                  />,
      "Widget::CureusPromotionSidebar": <CureusPromotion
                                          {...sharedProps}
                                          widgetId={widget.id}
                                        />,
      "Widget::Poll": <PollWidget
                        {...sharedProps}
                        widgetId={widget.id}
                      />,
      "Widget::MostPopularContent": <MostPopularContent
                                      {...sharedProps}
                                      widgetId={widget.id}
                                    />
    }
  }

  const renderOrderedContents = () => {
    return sidebarWidgets.map((widget, idx) => {
      let type = widget.type
      return (
          <div className="card drag-handle" key={idx}>
            {componentMapping(widget)[type]}
          </div>
        )
    })
  }

  const handleSort = (e, sortable, movedComponent) => {
    let widgetId = movedComponent.props.children.props.widgetId
    let params = {widget: {position: e.newIndex + 1}}
    handleUpdateWidget(widgetId, params, false, true)
  }

  return (
    <div className="channel-main-content-container sidebar-content">
      <Sortable tagName='div' handleSort={ handleSort }>
        {renderOrderedContents()}
      </Sortable>
    </div>
  )
}

export default SidebarContent;