import {Cureus} from '../Cureus';

Cureus.PreviewLink = function(selector) {
  this.link = document.getElementById(selector);
  this.form = Cureus.closestHTMLElement(this.link, 'form');
  this.previewUrl = this.link.dataset.previewUrl;
  this.bindEvents();
}

Cureus.PreviewLink.prototype.bindEvents = function() {
  var self = this;

  self.link.addEventListener('click', function(e) {
    self.saveCKEditors();
    $.ajax({
      type: "PUT",
      dataType: "json",
      url: self.form.action,
      data: self.form.serialize()
    }).done(function(data, textStatus, jqXHR) {
      window.open(self.previewUrl, "_blank");
    }).error(function(jqXHR, textStatus, errorThrown) {
      var response = JSON.parse(jqXHR.responseText);
      Cureus.Flash.alert(response.errors);
    });
    e.preventDefault();
  });
}

Cureus.PreviewLink.prototype.saveCKEditors = function() {
  for(var instance in CKEDITOR.instances) {
    CKEDITOR.instances[instance].updateElement();
  }
}