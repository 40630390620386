var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal';
import StateUpdateModal from './StateUpdateModal';
import AffiliationsModal from './AffiliationsModal';


const Table = createReactClass({
  getInitialState: function() {
    return({
      rating: null
    })
  },

  mapRows: function() {
    var self = this;
    return this.props.editors.map(function(editor) {
      return(
        <tr key={editor.user_id + '_table'}>
          <td>
            <a href={editor.user_path} target='_blank'>{editor.full_name}</a>
          </td>
          <td>{editor.email}</td>
          <td className='centered' dangerouslySetInnerHTML={{__html: editor.since}}></td>
          <td className='centered'>{editor.time_to_completion}</td>
          {self.renderArticleRatingTd(editor)}
          {self.renderAffiliationsTd(editor)}
          <td className='centered'>{self.actionButton(editor)}</td>
          <td>
            <a>
              <i className={self.canOpenModal(editor.ownership_id, editor.state) ? 'fa fa-edit' : 'fa fa-edit disabled'} onClick={self.canOpenModal(editor.ownership_id, editor.state) ? self.openModal(editor) : null}/>
            </a>
          </td>
        </tr>
      )
    });
  },

  renderAffiliationsTd: function(editor) {
    if(this.props.submissions) {
      return;
    }
    if(this.props.copy) {
      if(editor.verified) {
        return <td className="centered">Verified</td>
      } else {
        return <td className="centered" style={{color: '#3088C6', cursor: 'pointer'}} onClick={() => this.openAffiliationsModal(editor, 'started')}>Not Verified</td>
      }
    }
  },

  renderArticleRatingTd: function(editor) {
    if (editor.user_type === "ApprovalEditor") {
      if (this.props.userInfo.ownershipIds.includes(editor.ownership_id) && editor.state === "started" && editor.user_id === this.props.userInfo.id || (editor.state === "started" && this.props.userInfo.userType === 'Admin')) {
        return <td className="centered">
                <select onChange={(e) => this.handleRatingSelect(e)} defaultValue="">
                  <option value="" disabled hidden>Select</option>
                  <option value="1">Top 20%</option>
                  <option value='2'>Next 20%</option>
                  <option value="3">Middle 20%</option>
                  <option value="4">Lower 20%</option>
                  <option value="5">Bottom 20%</option>
                </select>
              </td>
      }  else if (editor.article_rating) {
        return <td>{editor.article_rating}</td>
      }
       else {
         return <td>N/A</td>
       }
    }
  },

  handleRatingSelect: function(e) {
    this.setState({rating: e.target.value})
  },
  
  actionButton: function(editor) {
    var disabledClass = this.userCanTakeAction(editor.ownership_id) ? '': ' disabled'
    switch(editor.state){
      case 'completed':
        return <span className='completed-label'>COMPLETED</span>;
      case 'unstarted':
        let unStartedData = {ownershipId: editor.ownership_id, newState: "started"};

        return this.actionButtonHTMLRender({
          editor: editor,
          buttonClass: "secondary tiny",
          buttonText: "Start",
          disabledClass: disabledClass,
          copyEditorClickFunction: () => {this.openAffiliationsModal(editor, 'started')},
          nonCopyEditorClickFunction: () => {this.updateState(unStartedData)}
        })
      case 'started':
        let startedStateData = {ownershipId: editor.ownership_id, newState: "completed", rating: this.state.rating, editorType: editor.user_type}
        
        if (editor.content_type === "channel_preview" || editor.content_type === "channel_approval") {
          this.enableApproveAndDeferButton();
          return <span className='in-progress-label'>IN PROGRESS</span>;
        } else {
          return this.actionButtonHTMLRender({
            editor: editor,
            buttonClass: "tiny alternate",
            buttonText: "Finish",
            disabledClass: disabledClass,
            copyEditorClickFunction: () => {this.checkAffiliations(editor)},
            nonCopyEditorClickFunction: () => {this.updateState(startedStateData)}
          })
        }
    }
  },

  actionButtonHTMLRender: function({editor, buttonClass, buttonText, disabledClass, copyEditorClickFunction, nonCopyEditorClickFunction}) {
    let clickFunction;
  
    if ( editor.content_type === 'copy' ) {
      clickFunction = copyEditorClickFunction
    } else {
      clickFunction = nonCopyEditorClickFunction
    }
    return <input type='button' className={'button ' + buttonClass + disabledClass} value={buttonText} style={{marginBottom: '0px'}} onClick={clickFunction} /> 
  },

  checkAffiliations: function(editor) {
    var self = this;
    $.ajax({
      type: 'GET',
      url: self.props.affiliationsPath,
      success: function(data) {
        if(data.rejected) {
          self.openAffiliationsModal(editor, 'completed')
        } else {
          self.updateState({ownershipId: editor.ownership_id, newState: "completed", rating: self.state.rating, editorType: editor.user_type, verify: true})
        }
      }
    });
  },

  openAffiliationsModal: function(editor, newState) {
    var self = this;
    Modal.open({
      children: <AffiliationsModal 
        affiliations={self.props.affiliations}
        updateState={self.updateState}
        editor={editor}
        newState={newState}
      />
    });
  },

  openModal: function(editor) {
    var self = this;
    return function() {
      Modal.open({
        children: <StateUpdateModal
                    isJunior={self.props.userInfo.isJunior}
                    editor={editor}
                    updateState={self.props.updateState}
                    destroy={self.props.destroy}
                    isAdmin={self.props.isAdmin()}
                    isApprovalEditor={self.props.userInfo.userType === 'ApprovalEditor'}
                  />
      })
    }
  },

  userCanTakeAction: function(ownershipId) {
    if(this.props.isAdmin()) {
      return true;
    } else if(this.props.userInfo.ownershipIds.includes(ownershipId)) {
      if(this.props.userInfo.userType === "ApprovalEditor" && this.props.articleState != 'editor_approval') {
        return false
      } else {
        return true
      }
    }
  },

  canOpenModal: function(ownershipId, ownershipState) {
    if(this.props.userInfo.userType === "ApprovalEditor" && ownershipState === 'completed') {
      return false;
    }
    return this.props.isAdmin() || (this.props.userInfo.ownershipIds.includes(ownershipId) && !this.props.submissions)
  },

  updateState: function(ownershipObject) {
    Modal.close()
    if (!this.userCanTakeAction(ownershipObject.ownershipId)) { return; }
    this.props.updateState(ownershipObject)
  },

  renderArticleRating: function() {
    if(this.props.submissions) { return; }
    if (this.props.approval) {
      return <th className='centered' style={{width: '130px'}}>Rating</th>
    } else if(this.props.copy) {
      return <th className='centered'>Affiliations</th>
    }
  },

  enableApproveAndDeferButton: function() {
    let approveButton = document.querySelector('.approve-for-review') || document.querySelector('.approve-for-publication-channel');
    let deferButton = document.querySelector('.deferral-modal-button');
    if(approveButton && deferButton) {
      approveButton.classList.remove('disabled');
      approveButton.removeAttribute('disabled');
      deferButton.classList.remove('disabled');
      deferButton.removeAttribute('disabled');
    }
  },

  render: function() {
    return(
      <table className='table-wrapper'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th className='centered' style={{width: '110px'}}>Last Update</th>
            <th className='centered' style={{width: '110px'}}>Edit Time</th>
            {this.renderArticleRating()}
            <th style={{width: '100px'}}>Action</th>
            <th className='single-action-icon'><i className='fa fa-edit'/></th>
          </tr>
        </thead>
        <tbody>
          {this.mapRows()}
        </tbody>
      </table>
    )
  }
})

export default Table;