var createReactClass = require('create-react-class');
import React from 'react'
import TooManyAttachments from './TooManyAttachments';
import Modal from '../Modal';
import Figure from './Figure';
import Table from './Table';
import Video from './Video';
import InteractiveModel from './InteractiveModel';

const EditorModalTrigger = createReactClass({
    getInitialState: function(){
      return {
        attachmentsCount: null,
        number: 0
      }
    },

    componentDidMount: function() {
      var self = this;
      $('#editor-modal-trigger').off('openModal')
      // Empty Attachment object created on 'insert modal click'
      // Transloadit image save and dif image version creation requires attachment object to exist
      self.initializeModalTrigger()
    },

    initializeModalTrigger: function()  {
      var self = this;
      $('#editor-modal-trigger').one('openModal', function(e, options){
        self.updateAttachmentCount(function(){
          if (options.id) {
            self.fetchAttachment(options.id);
          } else if (self.state.attachmentsCount >= 25) {
            self.initializeModalTrigger();
            Modal.open({
              children: <TooManyAttachments/>
            }, self.enableEditorButtons());
          } else {
            self.createAttachment(options.type);
          }
        });
      })
    },

    updateAttachmentCount: function(callback){
      this.setState({attachmentsCount: this.props.attachmentsCount + this.ckeditor().document.find(".attachment-wrapper").count()}, callback);
    },

    fetchAttachment: function(id, callback){
      var self = this;
      self.disableEditorButtons()
      var path = "/publish/articles/" + self.props.articleId +"/attachments/" + id
      $.get(path, function(attachment) {
        self.setState({
          attachment: attachment
        }, function(){self.openModal()})
      })
    },

    createAttachment: function(type){
      var self = this;
      self.disableEditorButtons();
      self.setAttachmentNumber(type, function(){
        $.post(self.props.indexAttachmentsPath, {
          type: type,
          number: self.state.number
        }, function(attachment) {
          self.setState({
            attachment: attachment,
            attachmentsCount: self.state.attachmentsCount += 1
          }, function(){
            self.openModal();
          })
        });
      });
    },

    disableEditorButtons: function() {
      var editor = CKEDITOR.instances[this.props.ckEditorId]
      editor.commands['OpenFigure'].disable()
      editor.commands['OpenTable'].disable()
      editor.commands['OpenVideo'].disable()
      editor.commands['OpenInteractiveModel'].disable()
    },

    enableEditorButtons: function() {
      var editor = CKEDITOR.instances[this.props.ckEditorId]
      editor.commands['OpenFigure'].enable()
      editor.commands['OpenTable'].enable()
      editor.commands['OpenVideo'].enable()
      editor.commands['OpenInteractiveModel'].enable()
    },

    syncAttachmentNumbers: function(){
      var self = this;
      var html = $.parseHTML(this.ckeditor().getData())

      // append to a temp element in order to traverse the dom tree
      var tempDom = $('<div></div>').append(html)

      // store arrays of the attachment markup with wrapper
      var attachments = {
        figure: [],
        table: [],
        video: [],
        interactive_model: []
      }

      // store the min range of each attachment type
      var minNumbers = {
        figure: null,
        table: null,
        video: null,
        interactive_model: null
      };

      // push attachment into appropriate array
      $('.attachment-wrapper', tempDom).each(function(index, elem){
        // Expected anchorId: figure-anchor-1234
        var anchorId = elem.dataset.anchorId.split('-');
        attachments[anchorId[0]].push(elem)
      })

      // set the min range for each attachment type
      for(var attachmentType in attachments){
        var minNumber = self.findMinNumber(attachments[attachmentType])
        if(minNumber){
          minNumbers[attachmentType] = minNumber;
        }
      }

      // sets the number for all the attachments in respect to the min value of each range
      for(var attachmentType in attachments){
        for(var i=0; i < attachments[attachmentType].length; i++){
          self.syncNumber(attachments[attachmentType][i], i + minNumbers[attachmentType])
        }
      }
    },

    syncNumber: function(elem, number){
      elem.dataset.number = number; // replace number in wrapper or add if doesn't exist
      $(elem).find('.article-attachment-wrap').data({number: number}); // replace number in main wrap or add if doesn't exist
      var title = $(elem).find('.article-attachment-wrap .attachment-title').html().match(/^(.*?):(.*)/);
      $(elem).find('.article-attachment-wrap .attachment-title').html([title[1].replace(/(\d+)(?!.*\d)/, number), title[2]].join(':'));
      this.ckeditor().document.findOne('[data-anchor-id="' + elem.dataset.anchorId + '"]').$.replaceWith(elem)
    },

    findMinNumber: function(attachments){
      var numbers = attachments.map(function(a){
        return parseInt($(a).find('.article-attachment-wrap .attachment-title').text().split(':')[0].split(' ').pop())
      })
      return((numbers.length > 0) ? Math.min.apply(null, numbers) : null)
    },

    setAttachmentNumber: function(type, callback){
      var self = this;
      var number = this.getPreviousAttachmentNode(type)
      if(number){ // check current document for previous elements
        this.setState({number: number}, callback)
      }else{
        // check previous article content sections for presence of attachment type
        var path = "/publish/articles/" + self.props.articleId +"/attachments/previous"
        $.get(path,
          {
            attachment_type: type,
            article_content_type: self.props.articleContentType
          },
          function(data) {
            self.setState({number: data.number + 1}, callback)
          }
        )
      }
    },

    getPreviousAttachmentNode: function(type) {
      var range =  this.ckeditor().getSelection().getRanges()[ 0 ] ? this.ckeditor().getSelection().getRanges()[ 0 ] : this.state.range;
      if (!range) {
        range = this.ckeditor().createRange()
        range.moveToElementEditablePosition( this.ckeditor().editable(), true );
        this.ckeditor().getSelection().selectRanges( [ range ] );
      };
      // Expand the range to the beginning of editable.
      range.collapse( true );
      range.setStartAt( this.ckeditor().editable(), CKEDITOR.POSITION_AFTER_START );

      this.setState({range: range})

      // Let's use the walker to find the closes (previous) attachment wrapper node.
      var walker = new CKEDITOR.dom.walker( range ),
          node, attachmentElem, nodeType;
      while ( ( node = walker.previous() ) ) {
        // If found, check the attributes to determine if the correct attachment type
        if ( node.type == CKEDITOR.NODE_ELEMENT ) { // found a div element
          if((attachmentElem = node.find('.article-attachment-wrap').$[0]) &&
             (nodeType = attachmentElem.id.split('-')[0])){
            if(nodeType == type.toLowerCase() || nodeType == "interactive_model"){
              if(attachmentElem.dataset.number){ // check if numher is defined in tag (will be present going forward)
                return parseInt(attachmentElem.dataset.number) + 1
              }else{ // fetch number from the database
                var path = "/publish/articles/" + self.props.articleId +"/attachments/" + node.$.dataset.attachmentId
                $.get(path, function(attachment) {
                  return parseInt(attachment.number) + 1
                })
              }
            }
          }
        }
      }
      return null;
    },

    ckeditor: function(){
      return CKEDITOR.instances[this.props.ckEditorId]
    },

    attachmentComponents: function() {
      return {
        "Figure": Figure,
        "Table": Table,
        "Video": Video,
        "InteractiveModel": InteractiveModel
      }
    },

    handleUpdate: function(e, data, callback) {
      e.preventDefault()
      var self = this;

      // Updates ckeditor text field to same content as in underlying html element text field.
      CKEDITOR.instances["edit-legend-" + self.state.attachment.id].updateElement();

      $.ajax({
        type: "PUT",
        url: self.state.attachment.update_url,
        data: data,
        success: function(serializedAttachmentData) {
          // We need a callback to update the state of the child components, because updating the state in this file will not re-render the child components due to line 55. For some reason, the openModal prevents state change in this file from re-rendering the child components affected by state change.
          if (callback) {callback(serializedAttachmentData)}
          self.syncAttachmentNumbers();
        },
        error: function() {
          alert("Oops, the changes could not be saved. Please refresh the page and try again.");
        }
      });
    },

    openModal: function(){
      this.initializeModalTrigger()
      var AttachmentComponent = this.attachmentComponents()[this.state.attachment.type];
      Modal.open({
        children:
          <AttachmentComponent
            attachment={this.state.attachment}
            handleUpdate={this.handleUpdate}
            mediaGuidePath={this.props.mediaGuidePath}
            syncAttachmentNumbers={this.syncAttachmentNumbers}
            checkAllCheckboxes={this.props.checkAllCheckboxes}
          />
      }, this.enableEditorButtons())
    },

    render: function(){
      return (
        <div><a id="editor-modal-trigger"/></div>
      );
    }
  })

  export default EditorModalTrigger;