import React from 'react';

const CompletedAnswer = ({correct, text, percent}) => {
  return (
    <div className='percentage-bar-container'>
      {correct ? <i className='fas fa-check-circle' /> : <div className='circle' />}
      <div className="answer-row-wrap">
        <p className='text-in-answer-bar'>{text}</p>
        <div className="answer" style={{width: percent}}></div>
        <p className='percent-in-answer-bar'>{percent}</p>
      </div>
    </div>
  )
}

export default CompletedAnswer;