import React from 'react';
var createReactClass = require('create-react-class');

const ArticlesModal = createReactClass({
  getInitialState: function() {
    return ({
      articles: this.props.editorArticles,
      sort: ''
    })
  },

  componentDidMount: function() {
    this.articleSort('since')
    this.setState({articles: this.state.articles.reverse()})
  },

  handleSort: function(col) {
    return function() {
      if (col === this.state.sort) {
        this.setState({articles: this.state.articles.reverse()})
      } else {
        this.articleSort(col)
      }
    }.bind(this)
  },

  articleSort: function(col) {
    var sortedArticles = this.state.articles.sort(function(a, b) {
      var aVal = a[col];
      var bVal = b[col];
      if (col === 'select') {
        aVal = a["tier"] === 'select' ? 0 : 1
        bVal = b["tier"] === 'select' ? 0 : 1
      }
      if (col === 'prof') {
        aVal = a["tier"] === 'professional' ? 0 : 1
        bVal = b["tier"] === 'professional' ? 0 : 1
      }
      if (aVal < bVal) { return -1; }
      if (aVal > bVal) { return 1; }
      return 0;
    });
    this.setState({articles: sortedArticles, sort: col})
  },

  mapRows: function() {
    return this.props.editorArticles.map(function(art) {
      return (
        <tr key={art.article_id}>
          <td>{art.since}</td>
          <td><a target="_blank" href={"/admin/articles/" +art.article_id }>{art.article_id}</a></td>
          <td className="centered" style={{width: '120px'}}>
            <div dangerouslySetInnerHTML={{__html: art.tier}}/>
          </td>
          <td><a target="_blank" href={"/admin/articles/" +art.article_id }>{art.title}</a></td>
          <td>{art.type}</td>
        </tr>
      )
    });
  },

  render: function() {
    return (
      <div className="reveal-modal" data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <div className='row'>
              <div className='large-12 columns'>
                <table className='table-wrapper dataTable' id="editor-articles-modal">
                  <tr>
                    <th onClick={this.handleSort('since')} >Most Recent Activity</th>
                    <th onClick={this.handleSort('article_id')}>ID</th>
                    <th onClick={this.handleSort('tier')}>Tier</th>
                    <th onClick={this.handleSort('title')}>Title</th>
                    <th onClick={this.handleSort('type')}>Type</th>
                  </tr>
                  <tbody>
                    {this.mapRows()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  },
})

export default ArticlesModal;