var createReactClass = require('create-react-class');
import React from 'react';
import NpiEdit from './NpiEdit';
import NpiRegistered from './NpiRegistered';

const Npi = createReactClass({
  getInitialState: function() {
    return {
      country: null,
      idNone: false,
      view: null
    }
  },

  componentDidMount: function() {
    var self = this;
    if (self.props.confirmation) {
      $.ajax({
        url: self.props.getComponentDataPath,
        type: "GET",
        data: {class: 'Npi', user_id: self.props.user_id},
        dataType: "json",
        success: function(data) {
          self.setState({
            country: null,
            idNone: false,
            npi: data.userProfile.npi,
            details: data.userProfile.details,
            view: data.userProfile.npi === null ? 'edit' : 'registered',
            npiPath: data.npiPath
          })
        }
      })
    } else {
      self.setState({            
        country: null,
        idNone: this.props.userProfile.npi === 'none',
        npi: this.props.userProfile.npi,
        details: this.props.userProfile.details,
        view: this.props.userProfile.npi === null ? 'edit' : 'registered',
        npiPath: this.props.npiPath
      })
    }
  },
  
  handleChange: function(e) {
    this.setState({[e.target.name]: e.target.value}, function() {
      $('input:checkbox').removeAttr('checked');
    })
  },

  setNone: function(e) {
    if (e.target.checked) {
      this.setState({npi: 'none', details: null, country: null, idNone: true}, function() { 
        document.getElementById('select-country').selectedIndex =0
      });
    } else {
      this.setState({npi: null, idNone: false});
    }
  },

  handleEdit: function() {
    this.setState({view: 'edit'})
  },

  validateNpi: function() {
    return this.state.idNone || (this.state.npi && !(this.state.country == 'Other' && this.state.details != null && this.state.details.length == 0))
  },

  renderContents: function() {
    if (!this.state.view) {return;}
    if (this.state.view === 'edit') {
      return <NpiEdit 
                country={this.state.country}
                handleChange={this.handleChange}
                setNone={this.setNone}
                npi={this.state.npi}
                details={this.state.details}
                npiPath={this.state.npiPath}
              />
    } else {
      return <NpiRegistered 
                npi={this.state.npi}
                details={this.state.details}
                handleEdit={this.handleEdit}
              />
    }
  },

  render: function(){
    return (
      <div className="reveal-modal medium" data-reveal data-options={this.props.setUncloseable()}>
      <div className="modal-body">
        {this.props.renderFlash()}
        {this.props.renderModalCloseX()}
        {this.renderContents()}
        {this.props.renderErrors()}
        <div className='modal-footer'>
          {this.props.renderButton({user: {country: this.state.country, details: this.state.details, npi: this.state.npi}, section: 'NpiForm'}, this.props.updateProfilePath, () => this.forceUpdate(), this.validateNpi())}
        </div>
      </div>
    </div>
    )
  }
})

export default Npi;