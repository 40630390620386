var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';

const FlagModal = createReactClass({
  getInitialState: function() {
    return({
      comments: this.props.comments,
      canManage: this.props.canManage
    });
  },

  renderRows: function() {
    var self = this;
    return Object.values(this.state.comments).map(function(comment) {
      return(
        <Row comment={comment} canManage={self.state.canManage} key={comment.id} handleDelete={self.handleDelete} />
      )
    });
  },

  handleDelete: function(commentPath, commentId) {
    var self = this;
    if(confirm("Are you sure? This action is irreversible")){
      $.ajax({
        type: 'delete',
        url: commentPath,
        dataType: 'json',
        data: {id: commentId},
        success: function(res) {
          var newComments = self.state.comments;
          delete newComments[commentId]
          self.setState({comments: newComments}, Cureus.Flash.success('The comment has been deleted.'))
        },
        error: function(res) {
          Cureus.Flash.notice('There was an error, please try later.')
        }
      });
    }
  },

  render: function() {
    return(
      <div  className='reveal-modal' data-reveal>
        <div className='modal-body'>
          <div className='modal-close'>
            <a className="cancel-modal">
             <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className='modal-content'>
            <table id='#article-comments' className='full-width comment-management table-wrapper'>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Created at</th>
                  <th>Body</th>
                  <th>Flagged?</th>
                  <th className='single-action-icon'><i className='fa fa-pencil-alt'/></th>
                  <th className='single-action-icon'><i className='far fa-trash-alt'/></th>
                </tr>
              </thead>
              <tbody>
                { this.renderRows() }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
});

export default FlagModal;