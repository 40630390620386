var createReactClass = require('create-react-class');
import React from 'react';

const Deferred = createReactClass({
  renderPreview: function() {
    if(this.props.article.title) {
      return (
        <li>
          <a target="_blank" href={this.props.article.previewPath}>Preview</a>
        </li>
      )
    }
  },

  renderViewDeferralNotes: function() {
    if(this.props.article.articleTier !== 'select') {
      return (
        <li>
          <a data-reveal-id={'article_' + this.props.article.id  + '_defer_history_modal'}>View deferral notes</a>
        </li>
      )
    }
  },

  render: function() {
    return(
      <React.Fragment>
        <li>
          <a target="_blank" href={this.props.article.previewPath}>Preview</a>
        </li>
        {this.renderViewDeferralNotes()}
        {this.props.renderEmailEditingReceipt()}
        <li>
          <a className="dropdown-link" onClick={this.props.handleDelete}>Delete</a>
        </li>
      </React.Fragment>
    )
  }
})

export default Deferred;