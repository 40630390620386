var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const Email = createReactClass({
  getInitialState: function() {
    return ({
      message: "Thank you for your interest in purchasing our Preferred Editing service. The preliminary review of your article has concluded and we are pleased to inform you that you qualify for this service. Pricing is as follows:" + '\n'+ 
      "" + 
      '\n' +
      this.props.articlePrice +
      '\n' +
      "" + 
      '\n' +
      "Please reply to this email and let us know if you'd like to purchase Preferred Editing for a completely hassle-free publishing experience or simply continue with free publication."
      ,
      recipient: this.props.recipientEmail,
      }
    )
  },

  updateMessage: function(event) {
    this.setState({message: event.target.value})
  },

  handleSendEmail: function() {
    var self = this;
    $.ajax({
      type: 'POST',
      url: self.props.sendQuoteEmailPath,
      dataType: 'json',
      data: {message: self.state.message},
      success: function(res) {
        Cureus.Flash.success("Success! This email has been sent.");
      },
      error: function(res) {
        Cureus.Flash.alert('There was an issue, please try later.')
      }
    }).done(function() {
      window.scrollTo(0, 0)
      Modal.close()
    })
  },

  render: function(){
    return(
      <div>
        <div className='modal-content'>
          <h4>
            Send upfront Preferred Editing instructions to submitting author.
          </h4>
          <br/>
          <br/>
          <label>Recipient </label>
          <input name='recipient' type='text' value={this.props.recipientEmail} disabled="disabled"/>

          <label>From </label>
          <input name='from' type='text' disabled = "disabled" value="support@cureus.com"/>

          <label>Subject </label>
          <input name='subject' type='text' value="Details about preferred editing service..." disabled="disabled"/>

          <label>Message </label>
          <textarea value={this.state.message} onChange={this.updateMessage} style={{height: '250px'}}/>
        </div>
        <div className="modal-footer">
          <div>
            <a className="button primary centered" onClick={this.handleSendEmail}>Send</a>
          </div>
        </div>
      </div>
    )
  }
})

export default Email;