var createReactClass = require('create-react-class');
import React from 'react';
import SortableMixin from '../../../../vendor/assets/javascripts/react-sortable-mixin';

const Sortable = createReactClass({
  mixins: [SortableMixin],

  getInitialState: function() {
    return {
      items: []
    }
  },

  handleUpdate: function(e, sortable, movedComponent) {
    this.props.handleSort(e, sortable, movedComponent);
  },

  componentDidMount: function() {
    this.setState({
      items: this.props.children
    });
  },

  sortableOptions: {
    handle: ".drag-handle"
  },

  componentWillReceiveProps: function(nextProps) {
    this.setState({
      items: nextProps.children
    });
  },

  render: function() {
    return (
      < this.props.tagName className={ this.props.className } ref="list" >
        { this.state.items }
      </ this.props.tagName >
    )
  }
});

export default Sortable;
