import {Cureus} from '../Cureus';

Cureus.ShowMore = function(classNameSelector, displayHeight) {
  this.triggerContainer = document.querySelector("#expand-trigger-wrap");
  if (!this.triggerContainer) {
    return;
  }
  this.content = document.querySelector("[data-show-more-content]")
  this.parent = document.querySelector(classNameSelector);

  this._initiallySetContentHeight(displayHeight)
  this._createAndAppendFaderElement();
  this._toggleContent();
  this._checkIfFadeNeeded(this.parent);
}

// initialization functions
Cureus.ShowMore.prototype._initiallySetContentHeight = function(displayHeight) {
  if (!this.parent) {return;}
  this.contentHeight = displayHeight || 110;
  this.parent.style.maxHeight = this.contentHeight + "px"
  this.parent.style.overflow = "hidden"
}

Cureus.ShowMore.prototype._createAndAppendFaderElement = function() {
  if (!this.content) {return;}
  this.fader = document.createElement("div")
  this.fader.addClass("fader")
  this.content.parentElement.append(this.fader)
}

Cureus.ShowMore.prototype._toggleContent = function() {
  var self = this;
  self.triggerContainer.addEventListener("click", function() {
    self.parent.toggleClass("fadeable");
    self.toggleExpandText();
    self.openOrCloseContent();
  })
}

Cureus.ShowMore.prototype._checkIfFadeNeeded = function() {
  if (!this.content) {return;}
  if (this.content.offsetHeight > this.parent.offsetHeight) {
    this.parent.addClass("fadeable")
    this.triggerContainer.style.display = ""
  }
  else {
    this.parent.removeClass("fadeable")
    this.triggerContainer.style.display = "show"
  }
}

// method calls
Cureus.ShowMore.prototype.toggleExpandText = function() {
  var seeMoreLessText = this.triggerContainer.children
  for (var i=0; i< seeMoreLessText.length; i++){
    seeMoreLessText[i].toggle()
  }
}

Cureus.ShowMore.prototype.openOrCloseContent = function() {
  if (this.content.offsetHeight > this.parent.offsetHeight) {
    this.parent.style.maxHeight = ""
  }
  else {
    this.parent.style.maxHeight = this.contentHeight + "px"
  }
}
