import {Cureus} from '../Cureus';

Cureus.SimpleRadioToggler = function(existed, isFinancialRelationship){
  this.nextButton = document.querySelectorAll('[data-button-type=next]');
  this.noCheck = document.querySelector('[data-button-type=hide]');
  this.yesCheck = document.querySelector('[data-button-type=show]');
  this.textArea = document.querySelector('[data-text-field=true]');
  this.toggleField = document.querySelector('[data-toggle-field=true]')
  this.isFinancialRelationship = isFinancialRelationship || false;
  this.changeListener = document.querySelector('[data-change-listener]');
  this.initialState(existed);
  this.bindListeners();
}

Cureus.SimpleRadioToggler.prototype.initialState = function(existed) {
  if (existed) {
    this.yesCheck.checked = true;
    this.toggleField.style.display = 'block';
    this.enableNext();
  } else if (existed === false) {
    this.enableNext();
    this.noCheck.checked = true;
    this.toggleField.style.display = 'none';
  } else {
    this.toggleField.style.display = 'none';
    this.disableNext();
  }
}

Cureus.SimpleRadioToggler.prototype.financialRelationshipCheck= function() {
  if (document.querySelectorAll('.added_financial_relationship_row').length > 0) {
    this.enableNext();
  } else {
    this.disableNext();
  }
}

Cureus.SimpleRadioToggler.prototype.bindListeners = function() {
  var self = this;
  this.yesCheck.addEventListener('click', function(e) {
    self.toggleField.style.display = 'block';
    if (self.textArea && !self.textArea.value.length) {
      self.disableNext();
    }
    if(self.isFinancialRelationship) {
      self.financialRelationshipCheck();
    }
  });

  this.noCheck.addEventListener('click', function(e) {
    self.toggleField.style.display = 'none';
    self.enableNext();
  });

  [].forEach.call(this.nextButton, function(el) {
    el.addEventListener('click', function(e) {
      if (self.noCheck.checked && self.textArea) { self.textArea.value = '' };
    });
  })

  if (this.textArea) {
    this.textArea.addEventListener('keyup', function() {
      self.textArea.value.length > 0 ? self.enableNext() : self.disableNext();
    })
  }

  if (this.changeListener) {
    this.changeListener.addEventListener('change', function() {
      self.changeListener.value > 0 ? self.enableNext() : self.disableNext();
    })
  }
}

Cureus.SimpleRadioToggler.prototype.disableNext = function() {
  [].forEach.call(this.nextButton, function(el) {
    el.setAttribute("disabled", true)
  });
}

Cureus.SimpleRadioToggler.prototype.enableNext = function() {
  [].forEach.call(this.nextButton, function(el) {
    el.removeAttribute("disabled")
  });
}
