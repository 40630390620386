var createReactClass = require('create-react-class');
import React from 'react';

const LockedListSelectedModal = createReactClass({
  render: function() {
    return (
      <div className="cureus-custom-multi-select-modal reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <div className='modal-form'>
              <h5>
                Unable to delete email list(s)
              </h5>

              <p>
                You have chosen one or more locked lists, which cannot be deleted. Please deselect all locked lists and try again.
              </p>

              <div className='modal-footer'>
                <a className='cancel-modal button secondary'>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default LockedListSelectedModal;