import React, { useEffect } from 'react';
import Modal from '../Modal';

const EditorMessageModal = ({editorMessages, title, id, fetchArticles, editorMessagePath, currentUserId}) => {
  useEffect(() => {
    $.ajax({
      type: 'PUT',
      url: editorMessagePath,
      data: {article_id: id},
      success: function() {
        fetchArticles(true);
      }
    });
  }, [])

  const renderMessages = () => {
    let length = editorMessages.length
    return editorMessages.map(function(obj, idx) {
        return (
          <React.Fragment>
            <div><i>{obj.date}</i></div>
            <div><i>{obj.time}</i></div>
            <br/>
            <div dangerouslySetInnerHTML={{__html: obj.message}}/>
            <p className="note">NOTE: This message was also sent via email to {obj.authorEmails}.</p>
            {length == idx + 1 ? null : <hr/>}
          </React.Fragment>
        )
      }
    )
}

const fireAmplitude = () => {
  $.ajax("/amplitudes", {
    dataType: "json",
    type: "POST",
    data: { 
            event_type: 'editorMessageModalReplyClicked', 
            user_id: currentUserId
          }
  })
}

  const closeModal = () => {
    fireAmplitude()
    setTimeout(function() {
      Modal.close()
    }, 500)
  }

  return (
    <div className="reveal-modal middling editor-messages" data-reveal id={"editor-messages-" + id}>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        
        <div className="modal-content">
          <h4 className='reg'> Re: “{title}”</h4>
          {renderMessages()}
        </div>

        <div className="modal-footer text-center">
          <a href={("mailto:" + editorMessages[editorMessages.length - 1].email + "?" + "subject=" + "Replying%20to%20your%20email%20about%20my%20Cureus%20article%20" + `${(id)}`)} className="button special" onClick={closeModal} id="reply-button">Reply</a>
        </div>
      </div>
    </div>
  )
}

export default EditorMessageModal;