import React from 'react';
import Filters from './Filters';

const Sidebar = ({submitSearch, id, userRoles, statuses, setUserRoles, setStatuses}) => {

  return (
    <div className="small-2 columns show-for-large-up">
      <Filters
        setStatuses={setStatuses}
        setUserRoles={setUserRoles}
        statuses={statuses}
        userRoles={userRoles}
        submitSearch={submitSearch}
        id={"large-view"}
      />
    </div>
  )
}

export default Sidebar