import React from 'react';
import SearchBar from '../../Shared/Utility/SearchBar';
import Pages from '../../Shared/Pagination/Pages';
import Filters from './Filters';
import Content from './Content';

const BrowseContents = ({
  bannerAdTopProps,
  contents,
  contentType,
  dateData,
  handleDateRangeSubmit,
  loading,
  onSubmit,
  pageCountOverview,
  paginationData,
  perPage,
  renderBannerAd,
  searchTerm,
  selectCategoryAndSubmit,
  selectedSort,
  setPerPage,
  title,
  updatePage,
  updateSearch,
  updateSortDirection,
}) => {
  return (
    <div className="large-8 medium-12 small-12 columns end">
      {renderBannerAd(bannerAdTopProps, "banner-ad-1")}
      <div className="browse-content-container">
        <h1 className="browse-contents-header">{title}</h1>
        <SearchBar
          onSubmit={onSubmit}
          updateSearch={updateSearch}
          searchTerm={searchTerm}
        />
        <Filters
          dateData={dateData}
          pageCountOverview={pageCountOverview}
          selectedSort={selectedSort}
          updateSortDirection={updateSortDirection}
          perPage={perPage}
          setPerPage={setPerPage}
          handleDateRangeSubmit={handleDateRangeSubmit}
        />
        <Content
          contents={contents}
          loading={loading}
          handleSubmit={selectCategoryAndSubmit}
        />
        <div className="pages-container">
          <Pages
            paginationData={paginationData}
            handlePageSelect={updatePage}
            pagesType={contentType}
          />
        </div>
      </div>
    </div>
  );
};

export default BrowseContents;
