var createReactClass = require('create-react-class');
import React from 'react';

const V2 = createReactClass({
  getInitialState: function() {
    return {
      uploading: false
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  handleUpload: function() {
    var $file = $(this.refs.file);
    $file.click();
  },

  handleFileChange: function() {
    var $form = $(this.refs.form);
    $form.submit();
    this.setState({uploading: true});
  },

  render: function() {
    if(this.props.imagePresent) {
      var image = <img src={ this.props.imageUrl }/>
      var deleteButton = (
        <a className='button alert small' href={ this.props.removePath } data-method='delete' data-confirm='Are you sure?'>
          <i className='far fa-trash-alt'></i>
        </a>
      )
    } else {
      var image = (
        <div className='placeholder-wrap' style={{height: this.props.placeholderHeight}}>
          <div className='placeholder-inner'>
            <div className='positioning'>
              <i className='far fa-picture'></i>
            </div>
          </div>
        </div>
      )
    }

    if(this.state.uploading) {
      var uploading = (
        <div className='uploading'>
          <span>Uploading...</span>
        </div>
      )
    }

    return (
      <div className='image-uploader v2'>
        <form ref='form' acceptCharset='UTF-8' method='post' action={ this.props.updatePath } encType="multipart/form-data">
          <input name="utf8" type="hidden" value="✓" />
          <input name="_method" type="hidden" value="put" />
          <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
          <input ref='file' type='file' name={ this.props.name } onChange={ this.handleFileChange }/>
        </form>

        <div className='row'>
          <div className='large-5 columns'>
            <div className='image-wrap'>
              { image }
              { uploading }
            </div>
          </div>

          <div className='large-7 columns'>
            <h4 className='reg'>Logo</h4>
            <p>We recommend a minimum size of 400x400 pixels to ensure clarity on HD/Retina devices. Symbols or icons with minimal text are also recommended.</p>
            <p>TIP: Consider leaving some white space around your logo. You may already have a suitable image on your Twitter or Facebook page.</p>

            <a className='button primary small' onClick={ this.handleUpload }>
              Upload logo
            </a>
            &nbsp;
            { deleteButton }
          </div>
        </div>
      </div>
    )
  }
});

export default V2;
