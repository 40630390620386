import React from 'react';
import Modal from '../Modal';
import ReferenceField from './ReferenceField';

var createReactClass = require('create-react-class');

const ReferenceMetadataModal = createReactClass({
  getInitialState: function() {
    return {
      disabled: false,
      doiMetadata: this.props.doiMetadata,
      pubMedId: null,
      disableSearch: false,
      searched: false
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  handleChange: function(e) {
    this.setState({pubMedId: e.target.value})
  },

  searchText: function() {
    return this.state.disableSearch ? "Searching" : "Search ID"
  },

  searchDisabled: function() {
    return this.state.disableSearch || this.state.pubMedId === null || this.state.pubMedId.length === 0
  },

  fetchPubmedMetadata: function() {
    var self = this;
    $.post(this.props.fetchParsedReferencePath, { reference_id: this.props.referenceId, pubmed_id: this.state.pubMedId, reference_type: this.props.type }, function(data) {
      self.setState({disableSearch: false, doiMetadata: data, searched: true})
    })
  },

  renderErrorText: function() {
    if (this.state.searched) {
      return (
        <p>Please verify the PubMed or PMC ID and try again.</p>
      )
    } else {
      return (
        <p>PMC's DOI converter service failed to find a match for this DOI. If you believe this to be incorrect, please input either the PubMed or PMC ID below and search again.</p>
      )
    }
  },

  renderErrorOrForm: function() {
    var self = this;
    if (this.state.doiMetadata.invald_parsed_reference) {
      return (
        <div className='row columns'>
          <p style={{color: 'red'}}>Unable to determine PubMed or PMC ID from DOI.
          </p>
          { this.renderErrorText() }
          <input className='large-10 columns' name="pubmed_id" onChange={ this.handleChange } value={ this.state.pubMedId}></input>
          <a className='small button success' disabled={ this.searchDisabled() } onClick={ this.fetchPubmedMetadata }>{ this.searchText() }</a>
        </div>
        
      )
    } else if (this.state.doiMetadata.request_failed) {
        <p className='columns' style={{color: 'red'}}>DOI search failed. Please check your DOI and try again.</p>
    } else {
      return (
        <div className='row'>
          <form ref='doiModalForm' id="doi-modal-form" acceptCharset='UTF-8' className='doiModalForm' method='post'>
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
            <input name="_method" type="hidden" value="put" />
            <input name="reference[type]" value={ this.props.type } type="hidden"/>
            <input name="reference[omit_doi]" value={ false } type="hidden"/>
          {
            this.state.doiMetadata.fields.map(function(field, idx) {
              if (field.name !== 'url') {
                return (
                  <ReferenceField field={ field } key={ idx } isModal={ true } originalField={ self.props.originalReferenceFields[idx] } />
                )
              }
            })
          }
          </form>
        </div>
      )
    }
  },

  handleUpdate: function(e,) {
    var self = this;
    var $form = $(self.refs.doiModalForm);

    $.post(self.props.updatePath, $form.serialize(), function(data) {
      self.props.handleUpdate();
      Modal.close();
      self.props.scrollOnFinishEdit()
    });

    e.preventDefault();
  },

  render: function() {
    return (
    <div className="reveal-modal middling" data-reveal id="doi-metadata">
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        
        <div className="modal-content">
          <h4 className='reg columns'>Confirm Reference: <a target="_blank" href={this.state.doiMetadata.url} style={{color: '#0096B7'}}>{this.state.doiMetadata.doi}</a></h4>
          {this.renderErrorOrForm()}
        </div>

        <div className="modal-footer">
          <a href="#" className="button secondary cancel-modal">Cancel</a>
          { !this.state.doiMetadata.invald_parsed_reference && <a href="#" className='button' disabled={this.state.disabled} onClick={this.handleUpdate}>Update Reference</a> }
        </div>
      </div>
    </div>
    )
  }
})

export default ReferenceMetadataModal;