var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';
import Abstract from './Abstract';
import ArticleCitations from './ArticleCitations';
import ArticleMedia from './ArticleMedia';
import ArticleSubheaders from './ArticleSubheaders';
import Authors from './Authors';
import Content from './Content';
import Keywords from './Keywords';
import MediaMentions from './MediaMentions';
import Nav from './Nav';
import Title from './Title';
import TopBar from './TopBar';

const Main = createReactClass({
  getInitialState: function() {
    return {
      xCoord: 0,
      yCoord: 0,
      pages: this.orderedPagesByArticleType()[this.props.article_type],
      currentPageName: this.validStep(),
      currentPage: this.pageMapping()[this.validStep()] || this.pageMapping()["Title"],
      currentPageNum: this.findCurrentPageNum(this.validStep()),
      prevState: '',
      nextState: '',
      tooltip: ''
    }
  },

  componentDidMount: function() {
    this.setState({
      tooltip: $("#" + this.props.dom_id)
    })


    this.setPage();         // choose page to show
    this.bindOpenTrigger(); // reveal howto modeless
    new Cureus.Drags(document.getElementById(this.props.dom_id), document.getElementById(this.props.topbar_id))
  },

  // NAVIGATION
  pageMapping: function(){
    return {
      "Title": <Title/>,
      "Abstract": <Abstract/>,
      "Keywords": <Keywords/>,
      "Authors": <Authors/>,
      "Article Citations": <ArticleCitations/>,
      "Article Subheaders": <ArticleSubheaders/>,
      "Article Media": <ArticleMedia mediaGuidePath={this.props.media_guide_path}/>,
      "Media Mentions": <MediaMentions/>,
      "default": <Title/>
    }
  },

  findCurrentPageNum: function(step){
    var index = this.orderedPagesByArticleType()[this.props.article_type].indexOf(step);
    return ((index < 0) ? 1 : (index + 1));
  },

  validStep: function(){
    var contentSteps = [
      "Appendices",
      "Case Presentation",
      "Conclusions",
      "Discussion",
      "Editorial",
      "Introduction",
      "Introduction and Background",
      "Materials and Methods",
      "Results",
      "Review",
      "Technical Report"
    ]
    return contentSteps.indexOf(this.props.current_step) < 0 ? this.props.current_step : "Article Citations";
  },

  setPage: function(pageNum){
    var pageNum = pageNum || this.state.currentPageNum;
    var pageName = this.state.pages[pageNum-1];

    // set new page number
    this.setState({currentPageNum: pageNum});

    // set button states
    if(pageNum == 1){
      this.setState({prevState: 'disabled'})
    }else{
      this.setState({prevState: ''})
    };
    if(pageNum == this.state.pages.length){
      this.setState({nextState: 'disabled'});
    }else{
      this.setState({nextState: ''});
    }
    // load current page
    this.setState({currentPage: this.pageMapping()[pageName]});
  },


  pageIndex: function(){
    return (this.state.pages.indexOf(this.state.currentPageName) + 1)
  },

  handlePrev: function(){
    var pageNum = (this.state.currentPageNum == 1) ? this.state.currentPageNum : (this.state.currentPageNum - 1)
    this.setPage(pageNum);
  },

  handleNext: function(){
    var pageNum = (this.state.currentPageNum == this.state.pages.length) ? this.state.currentPageNum : (this.state.currentPageNum + 1)
    this.setPage(pageNum);
  },

  // CLOSE/MIN/MAX
  handleClose: function(){
    $("#" + this.props.dom_id).hide();
  },

  bindOpenTrigger: function(){
    var self = this;

    $(self.props.trigger_selector).on('click', function(event){
      var customTrigger = $(event.target).data('howto')
      if(customTrigger != undefined){
        self.setState({
          currentPageName: customTrigger,
          currentPage: self.pageMapping()[customTrigger],
          currentPageNum: self.findCurrentPageNum(customTrigger)
        })
      }
     self.show();
      // javascript to set absolute positioning of tooltip to be relative to window
      event.preventDefault();
    });
  },

  show: function() {
    this.state.tooltip.css("top", $(document).scrollTop() + 55);
    this.state.tooltip.css("left", $(document).scrollLeft() + 15);
    $("#" + this.props.dom_id).show();
  },

  orderedPagesByArticleType: function(){
  return {
    "PeerReviewed": [
      "Title",
      "Abstract",
      "Keywords",
      "Authors",
      "Article Citations",
      "Article Subheaders",
      "Article Media",
      "Media Mentions"
    ],
    "OriginalArticle": [
      "Title",
      "Abstract",
      "Keywords",
      "Authors",
      "Article Citations",
      "Article Subheaders",
      "Article Media",
      "Media Mentions"
    ],
    "TechnicalReport": [
      "Title",
      "Abstract",
      "Keywords",
      "Authors",
      "Article Citations",
      "Article Subheaders",
      "Article Media",
      "Media Mentions"
    ],
    "ReviewArticle": [
      "Title",
      "Abstract",
      "Keywords",
      "Authors",
      "Article Citations",
      "Article Subheaders",
      "Article Media",
      "Media Mentions"
    ],
    "CaseReport": [
      "Title",
      "Abstract",
      "Keywords",
      "Authors",
      "Article Citations",
      "Article Subheaders",
      "Article Media",
      "Media Mentions"
    ],
    "Editorial": [
      "Title",
      "Abstract",
      "Keywords",
      "Authors",
      "Article Citations",
      "Article Subheaders",
      "Article Media",
      "Media Mentions"
    ]
  }
},

// RENDER
  render: function() {
    return (
      <div id={this.props.dom_id}>
        <TopBar handleClose={ this.handleClose } dom_id={ this.props.dom_id } content_id={ this.props.content_id } nav_id={ this.props.nav_id } />
        <Content currentPage={ this.state.currentPage } currentPageNum={ this.state.currentPageNum }/>
        <Nav
          currentPageNum={ this.state.currentPageNum }
          pagesTotal={ this.state.pages.length }
          prevState={ this.state.prevState }
          nextState={ this.state.nextState }
          handlePrev={ this.handlePrev }
          handleNext={ this.handleNext }
        />
      </div>
    );
  }
});

export default Main;