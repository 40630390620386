import React from 'react';

const ConflictsOfInterest = () => {
  return (
    <React.Fragment>
      <h1>Conflicts of Interest</h1>
      <p>
        Cureus requires all parties involved in the publication process (all authors, reviewers, academic editors and post-publication SIQ scorers) to transparently and fully declare any potential conflicts of interest (COI).
      </p>
      <p>
        Authors are required to declare all competing interests during the submission process. Reviewers are expected to consider any potential conflicts of interest before agreeing to review, and to declare, in detail, any relevant conflicts of interest before submitting their review. Editors are expected to recuse themselves from the publication process if they feel they have a conflict of interest in regards to the article.
      </p>
      <p>
        Cureus Editorial Board members and associate editors are welcome to submit articles to Cureus, however, they may have absolutely no part in the handling of their manuscript at any stage of the publication process. While we recognize that eliminating all bias is impossible, we ensure every effort is made to minimize any bias during the evaluation and processing of these submissions.
      </p>
      <p>
        All potential conflicts of interest should be declared. Please disclose a relationship and/or conflict of interest if faced with even the slightest amount of uncertainty as to its potential relevance. Examples of conflicts of interest include, but are not limited to: possible financial benefits if the article is published; prior working, or personal, relationships with any of the authors; patent or grant activity that could be considered broadly relevant to the work; personal material or financial gain (such as free travel, gifts, etc.) relating to the work; personal convictions (religious, political, etc.) which may have a bearing on the work, etc.
      </p>
      <p>
        A complete list of funding sources (institutional, corporate, grants, etc.) and relevant relationships and financial activities must be detailed in the Ethics Statements and Conflict of Interest Disclosures section of the article submission process.
      </p>
      <p>
        The following COI sections contain questions posed to the authors during the submission process. All COI sections must be addressed in order to satisfy Cureus COI requirements and are reprinted below as they appear in the article submission process. The language contained in the four sections below is based upon the <a href='http://www.icmje.org/conflicts-of-interest' target='_blank' rel='noreferrer'>ICMJE Form for Disclosure of Potential Conflicts of Interest</a>. (Click each header to view the section as it appears in the submission process.)
      </p>

      <h2>The Work Under Consideration for Publication</h2>
      <p>
        Did you or your institution at any time receive payment or services from a third party (government, commercial, private foundation, etc.) for any aspect of the submitted work (such as grants, data monitoring board, study design, manuscript preparation, statistical analysis, etc.)?
      </p>
      
      <h2>Relevant Financial Activities Outside the Submitted Work</h2>
      <p>
        Please indicate all financial relationships (regardless of compensation amount) associated with the submitting author and all co-authors. You should disclose interactions with any entity that could be considered broadly relevant to your work. Report all relationships that were present during the 36 months prior to publication. Remember – if there is any question, it is better to disclose a relationship than not to do so.
      </p>
      <p>
        For grants you have received for work outside the submitted work, only disclose support from entities that could be perceived to be affected financially by the published work, such as drug companies, or foundations supported by entities that could be perceived to have a financial stake in the outcome.
      </p>
      
      <h2>Intellectual Property: Patents &amp; Copyrights</h2>
      <p>
        Do you have any patents, whether planned, pending or issued, that could be considered broadly relevant to this work?
      </p>
      
      <h2>Relationships Not Covered Previously</h2>
      <p>
        Are there other relationships or activities that readers could perceive to have influenced, or that give the appearance of potentially influencing, what you wrote in the submitted work?
      </p>
      
      <h2>Disclosure of 3rd Party Funding</h2>
      <p>
        Separately from declaring any conflicts of interest, Cureus also requires that authors disclose any payments or services received from a 3rd party (government, commercial, private foundation, etc.) for any aspect of the submitted work (such as grants, data monitoring board, study design, article preparation, statistical analysis, etc.).
      </p>
      <p>
        This information is included in a disclosure statement attached to the final published article.
      </p>
    </React.Fragment>
  )
}

export default ConflictsOfInterest;