var createReactClass = require('create-react-class');
import React from 'react';

const SiqHover = createReactClass({
  scoringCount: function() {
    return this.props.article.scorings_count
  },

  categoryScore: function(category) {
    return (this.props.article.siq_breakdown[category].score).toFixed(1)
  },

  categoryProgress: function(category) {
    return this.props.article.siq_breakdown[category].progress + "%"
  },

  renderButton: function() {
    var disabled = this.props.article.user_has_scored || this.props.article.user_article
    var buttonText = this.props.article.user_has_scored ? "ALREADY RATED" : "CONTRIBUTE RATING"
    return (
      <a href={this.props.article.rating_link} disabled={disabled} target="_blank" className="button small special contribute" id={`contribute-button-${this.props.articleId}`}>
        {buttonText}
      </a>
    )
  },

  renderBody: function () {
    var self = this;
    var tradeSym = String.fromCharCode(8482)
    if (self.scoringCount() < 2) {
      return (
        <div className="row">
          <div className={`large-12 medium-12 small-12 columns ${this.props.page}`}>
            <div className={`siq-badge-score react-score ${this.props.page}`}>
              {self.props.siq}
            </div>
            <div className="siq-image">
              <img src="https://public.cureus.com/siq_badge_ribbon.svg"/>
            </div>
          </div>
          <div className={`large-12 medium-12 small-12 columns ${this.props.page}`}>
            <h4 className="reg rated-number">
              {`Rated by ${self.scoringCount()} readers`}
            </h4>
            <p className="siq-p">
              The SIQ for this article will be revealed
            </p>
            <p className="siq-p">
              once 2 ratings are submitted.
            </p>
            
          {this.renderCategoryContent()}
          </div>
          <div className="large-12 medium-12 small-12 columns contribute-button-column">
            {this.renderButton()}
          </div>
          <div className={`large-12 medium-12 small-12 columns learn-more-column ${this.props.page}`}>
            <a href={this.props.article.learn_more_link} target="_blank" className="learn more">
              Learn more about <strong>SIQ</strong>{`${tradeSym}`}
            </a>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="row">
          <div className={`large-12 medium-12 small-12 columns ${this.props.page}`}>
            <div className={`siq-badge-score react-score ${this.props.page}`}>
              {self.props.siq}
            </div>
            <div className="siq-image">
              <img src="https://public.cureus.com/siq_badge_ribbon.svg"/>
            </div>
          </div>
          <div className={`large-12 medium-12 small-12 columns ${this.props.page}`}>
            <h4 className="reg rated-number">
              {`Rated by ${self.scoringCount()} readers`}
            </h4>
            {this.renderCategoryContent()}
          </div>
          <div className="large-12 medium-12 small-12 columns contribute-button-column">
            {this.renderButton()}
          </div>
          <div className={`large-12 medium-12 small-12 columns learn-more-column ${this.props.page}`}>
            <a href={this.props.article.learn_more_link} target="_blank" className="learn more">
              Learn more about <strong>SIQ</strong>{`${tradeSym}`}
            </a>
          </div>
        </div>
      )
    }
  },

  renderCategoryContent: function() {
    var self = this;
    if (self.scoringCount() < 2){
      return self.props.article.siq_categories.map (function(category, idx) {
        return (
          <div className='row' key={"siq-" + idx}>
            <div className=" large-1 medium-1 small-1 columns react-dash">
              <h4 className="fixed">
                - 
              </h4>
            </div>
            <div className=" large-9 medium-9 small-9 columns striped-bars">
              <h6 className="category">
                {category}
              </h6>
              <div className="progress-striped">
                <div className="bar" style={{width: "100%"}}>
                </div>
              </div>
            </div>
          </div>
        )
      });
    }
    else {
      return self.props.article.siq_categories.map (function(category, idx) {
        return (
          <div className='row' key={"siq-" + idx}>
            <div className=" large-1 medium-1 small-1 columns react-dash">
              <h4 className="fixed">
                {self.categoryScore(category)}
              </h4>
            </div>
            <div className=" large-9 medium-9 small-9 columns solid-bars">
              <h6 className="category">
                {category}
              </h6>
              <div className="cureus-progress active">
                <div className="bar" style={{width: self.categoryProgress(category)}}>
                </div>
              </div>
            </div>
          </div>
        )
      });
    }
  },

  render: function(){
    return(
      <div className="siq-hover-view">
        {this.renderBody()}
      </div>
    )
  }
})

export default SiqHover;
