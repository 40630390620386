var createReactClass = require('create-react-class');
import React from 'react';

const ExportCsvModal = createReactClass({
  render: function (){
    return (

      <div className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <h3>Email coming!</h3>

          <div className="modal-content">
            <div className='modal-footer'>
              <a className='cancel-modal button secondary'>Ok</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default ExportCsvModal;