import React from 'react';

// Only use this button component for simple buttons like href or onClick without dropdowns
const CollectionButton = ({classProp, chevron=false, text, preview=false, hrefLink}) => {

  const renderChevron = () => {
    <img src="https://public.cureus.com/my_dashboard/my_collections/chevron-down.png" />
  }

  return (
    <a className={'collection-button ' + classProp} href={hrefLink}>
      {text}
      {chevron && renderChevron()}
    </a>
  )
}

export default CollectionButton;