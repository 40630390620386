import React, { useEffect } from 'react';

const ActivityHistory = ( { datasets, setEditorType } ) => {
  useEffect(() => {
    populateTable()
  })
  
  function populateTable() {
    var dt = $("#activity-history-table").DataTable();
    dt.api().clear();
    for (var i = 0; i < datasets.length; i++) {
      var editor = datasets[i];
      dt.fnAddData( [
          `<a href='users/${editor.id}' target='_blank'>${editor.full_name}</a>`,
          editor.ownerships_completed,
          editor.deferred,
          (editor.approved - editor.blocked - editor.desk) >= 0 ? (editor.approved - editor.blocked - editor.desk) : 0 ,
          editor.desk,
          editor.blocked,
          editor.messages,
          editor.emails,
          editor.expirations
      ], false)
    }
    dt.fnDraw()
  }

  function renderSelector() {
    return (
      <select onChange={e => setEditorType(e.target.value)}>
        <option value="">Editors</option>
        <option value="Submissions">Submissions</option>
        <option value="Approval">Approval</option>
        <option value="Copy">Copy</option>
        <option value="Dep. Admin">Dep. Admin</option>
      </select>
    )
  }

  return (
    <table className="table-wrapper data-table" id="activity-history-table">
      <thead>
        <tr>
          <th className="centered">{renderSelector()}</th>
          <th className="centered">Completed</th>
          <th className="centered"><i className="fas fa-exchange-alt"/>Deferred</th>
          <th className="centered"><i className="fa fa-check-circle" style={{color: 'green'}}/>Approved/Assigned EC Points</th>
          <th className="centered"><i className="fas fa-reply" style={{color: 'orange'}}/> Desk Rejected</th>
          <th className="centered"><i className="fas fa-minus-circle" style={{color: 'red'}}/> Blocked</th>
          <th className="centered"><i className="far fa-comment" style={{color: '#FFEF02'}}/> Msg Board Comments</th>
          <th className="centered"> <i className="far fa-envelope" style={{color: 'blue'}}/> Emails to Author</th>
          <th className="centered"> <i className="fas fa-stopwatch"/> Expirations</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  )
}

export default ActivityHistory;