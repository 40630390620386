import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Cureus } from '../../Cureus'
import { fetchTableData } from './Utility/TableDataFetcher';
import DateSelector, {
  currentWeek,
  currentMonth,
  lastMonth,
  lastThirtyDays,
  lastSixMonths,
  lastSevenDays,
} from '../StatsWidget/DateSelector';

const initialDateRanges = [lastSevenDays(), currentWeek(), currentMonth(), lastMonth(), lastThirtyDays(), lastSixMonths()];

// pass column data as a prop, array of objects
// const columnsConfig = [
//   { title: 'Date Created / Updated', data: 'created_and_updated', width: '160px' },
//   { title: 'ID', data: 'id', class: 'centered' },
//   { title: 'Created by', data: 'created_by', class: 'centered' },
//   { title: 'Collection Title', data: 'title', class: 'centered' },
//   { title: 'Category', data: 'category', width: '140px', class: 'centered' },
//   { title: 'Type', data: 'type', class: 'centered' },
//   { title: 'Articles', data: 'articles_count', class: 'centered' },
//   { title: 'Views', data: 'views', class: 'centered' },
// ];

const DynamicJqueryTable = ({
  columnsConfig,
  columnDef={},
  path,
  params={},
  tableId = "dynamic-table",
  tableTitle,
  tableWrapperHideProp=null,
  tableWrapperShowProp=null,
  noDatepicker=false,
  rowRenderCallback,
  showCheckBoxes=false,
  userEmail,
  showCsv,
  initialDateIndex = 0,
  sortProps,
  tableCss="",
  showCss="",
  searchLabel,
  createRowProp,
  renderDomProp=false
}) => {
  const [dateRanges, setDateRanges] = useState(initialDateRanges);
  const [selectedRange, setSelectedRange] = useState(initialDateRanges[initialDateIndex].label);
  const [from, setFrom] = useState(initialDateRanges[initialDateIndex].from);
  const [to, setTo] = useState(initialDateRanges[initialDateIndex].to);
  const [csvContent, setCsvContent] = useState([]);
  const [pageLength, setPageLength] = useState(20);

  useEffect(() => {
    fetchData(from, to, selectedRange);
  }, []);

  const fetchData = async (start, finish, label) => {
    try {
      let paramsWithDate = {
        ...params,
        start_date: start,
        end_date: finish
      };
      const response = await fetchTableData(path, paramsWithDate);

      setSelectedRange(label);
      setTo(start);
      setFrom(finish);
      populateTable(response.data);

      if(response.data.length != 0) {
        generateCSVContent(response.data);
      }
    } catch (error) {
      Cureus.Flash.alert("Something went wrong, please try again");
    }
  };

  const populateTable = (data) => {
    const dt = $(`#${tableId}`).DataTable({
      destroy: true,
      pageLength: pageLength,
      lengthMenu: [20, 50, 100],
      language: {
        searchPlaceholder: "Search...",
        search: searchLabel
      },
      columns: columnsConfig.map(col => ({
        width: col.width || null,
        className: col.class || '',
      })),
      createdRow(row, data, dataIndex) {
        if (createRowProp) {
          createRowProp(row);
        }
      },
      searching: true,
      rowCallback( row, data, displayNum, displayIndex, dataIndex ) {
        if(rowRenderCallback) {
          rowRenderCallback(row, data)
        }
      },
      columnDefs: [
        // can pass sorting data here if needed
        // {targets: [0], orderData: [1]}
      ],
      dom: renderDOM(),
      fnDrawCallback: function(oSettings) {
        const tableWrapper = $(oSettings.nTableWrapper);
        if (oSettings.fnRecordsDisplay() === 0) {
          tableWrapper.find('.dataTables_paginate, .dataTables_length, .dataTables_info').hide();
          tableWrapperHideProp;
        } else {
          tableWrapper.find('.dataTables_paginate, .dataTables_length, .dataTables_info').show();
          tableWrapperShowProp;
        }

        if (showCheckBoxes) {
          removeClickHandlerFromEmailButton();
          initEmailReminderFeature();
        }
      }
    });

    $(`#${tableId}`).on('length.dt', function(e, settings, len) {
      setPageLength(len); // Update React state when page length is changed
    });

    dt.api().clear();
    data.forEach(row => {
      dt.fnAddData(columnsConfig.map(col =>
        col.render ? col.render(row) : row[col.data]
      ), false);
    });
    let columnSort;
    if(sortProps) {
      columnSort = sortProps
    }
    else {
      columnSort = showCheckBoxes ? [1, 'asc'] : [0, 'asc'];
    }
    dt.fnSort([columnSort]);
    dt.fnDraw();
    $(document).foundation('tooltip', 'reflow');
  };

  const renderDateSelector = () => {
    if(noDatepicker) {
      return;
    }
    return (
      <div className={'small-2 columns date-selector ' + shiftDateSelector()}>
        <DateSelector
          from={from}
          to={to}
          getStats={fetchData}
          selectedRange={selectedRange}
          dateRanges={dateRanges}
        />
      </div>
    )
  }

  const shiftDateSelector = () => {
    const defaultRanges = ['Current week', 'Current month', 'Last month', 'Last 30 days', 'Last 6 months'];
    return !defaultRanges.includes(selectedRange) ? 'shift-selector' : ''
  }

  const renderDOM = () => {
    return renderDomProp ? renderDomProp() : `<"row"<"large-5 columns"f>><"table"t>r<"row bottom"<"large-4 columns page-nums ${tableCss}"p><"large-3 columns end show-amount"l>>`
  }

  const removeClickHandlerFromEmailButton = () => {
    // Need this ownerwise Cureus.Admin.MultipleReminders will cause duplicate modals to open at the same time.
    $(`#${tableId}-row .bulk-email-button`).off('click')
  }

  const initEmailReminderFeature = () => {
    Cureus.Admin.MultipleReminders.init({
      btnSelector: `#${tableId}-row .bulk-email-button`,
      noNavSelector: true,
      tabPaneSelector: `#${tableId}-datatable-container`,
      templateName: "ApprovalEditorSuite",
      templatePath: "/admin/reminders/template",
      createPath: "/admin/reminders",
      aeLeaderBoard: true,
      async: true
    });
  };

  const renderMailButton = () => {
    return (
      <div className={`button primary small bulk-email-button disabled`}  >
        <i className="fa fa-envelope"></i>
      </div>
    );
  }

  const generateCSVContent = (data) => {
    const header = [...Object.keys(data[0])];
    const rows = data.map((editor) => {
      const editorInfo = [...Object.values(editor)];
      return editorInfo;
    })
    setCsvContent([header, ...rows]);
  }

  const renderCSVButton = () => {
    const disabled = csvContent.length === 0;

    const handleClick = (e) => {
      if (disabled) return;
      $.ajax({
        type: 'POST',
        dataType: 'json',
        data: {contents: csvContent, userEmail: userEmail, tableTitle: `${tableTitle}`},
        url: '/admin/ae_dashboards/export_csv',
        success: function(data) {
          Cureus.Flash.success("CSV file has been emailed to you!")
        }
      });
    }

    return (
      <a
        className={`csv-button-container ${disabled ? 'disabled' : ''}`}
        onClick={handleClick}>
        <span>CSV</span>
        <img src="https://public.cureus.com/csv-icon.png" alt="" />
      </a>
    )
  }

  return (
    <div className='list-tab' id={`${tableId}-row`}>
      <div className="campaigns-index" id={`${tableId}-datatable-container`}>
        <h4>{tableTitle}</h4>
        {renderDateSelector()}
        {showCheckBoxes && renderMailButton()}
        {showCsv && renderCSVButton()}
          <table id={tableId}>
            <thead>
              <tr>
                {columnsConfig.map((col, idx) => {
                  if (showCheckBoxes && idx == 0) {
                    return <th><input type="checkbox" id="header-checkbox"/></th>
                  } else {
                    return (
                      <th key={idx}>
                        {col.title}
                        {col.tooltip &&
                          <img
                            className='column-title-tooltip tip-top'
                            src='https://public.cureus.com/homepage_new/question_mark.png'
                            data-tooltip=''
                            title={col.tooltip}/>
                        }
                      </th>
                    )
                  }
                })}
              </tr>
            </thead>
            <tbody></tbody>
          </table>
      </div>
    </div>
  );
};

DynamicJqueryTable.propTypes = {
  columnsConfig: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    class: PropTypes.string,
    width: PropTypes.string
  })),
  path: PropTypes.string,
  params: PropTypes.object,
  tableId: PropTypes.string,
  tableTitle: PropTypes.string,
  showCheckBoxes: PropTypes.bool,
  userEmail: PropTypes.string,
  showCsv: PropTypes.bool,
  searchLabel: PropTypes.string,
  tableWrapperHideProp: PropTypes.func,
  tableWrapperShowProp: PropTypes.func,
  createRowProp: PropTypes.func
}

export default DynamicJqueryTable;