var createReactClass = require('create-react-class');
import React from 'react';

const UserDetails = createReactClass({
  render: function (){
    var selected_user = this.props.user;
    if(selected_user){
      return (
        <div className='mergetool-user-details'>
          <h4 className='reg'>User details:</h4>
          <dl>
            <dt>Name: </dt>
            <dd>
              <a href={selected_user.details.adminShowUrl} target="_blank">
                {selected_user.details.name}
              </a>
            </dd>
            <br />
            <dt>Email: </dt>
            <dd>{selected_user.details.email}</dd>
            <br />
            <dt>User id: </dt>
            <dd>{selected_user.details.id}</dd>
          </dl>

          <h4 className='reg'>To be merged:</h4>
          <dl>
            <dt>Authorships: </dt>
            <dd>{selected_user.toBeMerged.authorships}</dd>
            <br />
            <dt>Reviewerships: </dt>
            <dd>{selected_user.toBeMerged.reviewerships}</dd>
            <br />
            <dt>Comments: </dt>
            <dd>{selected_user.toBeMerged.comments}</dd>
            <br />
            <dt>Scorings: </dt>
            <dd>{selected_user.toBeMerged.scorings}</dd>
            <br />
            <dt>Flaggings: </dt>
            <dd>{selected_user.toBeMerged.flaggings}</dd>
            <br />
            <dt>Invoices: </dt>
            <dd>{selected_user.toBeMerged.invoices}</dd>
            <br />
          </dl>
        </div>
      )
    }else{
      return (
        <p>Search for a user using the input field above to see more details!</p>
      )
    }
  }
});

export default UserDetails;
