import React from 'react'

const DeferralModal = ({deferralReasons}) => {

  return (
    <div id={"delete-collection-reveal-modal"} className="reveal-modal new-modal delete-collection-modal middling large-12 columns" data-reveal>
    <div className="modal-body delete-section">
      <div className="modal-close">
        <a href='#' className='cancel-modal'>
          <img src="https://public.cureus.com/close_x.png"/>
        </a>
      </div>
      <div className="modal-content">
        <div className="row">
          <div className="small-12 columns">
            <h4 className='text-left'>Collection Deferral Notes</h4>
            <p>Please make all requested revisions prior to resubmitting.</p>
          </div>
          <div className='defer-reson' dangerouslySetInnerHTML={{__html: deferralReasons}}>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default DeferralModal;