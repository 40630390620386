import React, { memo } from 'react';
import ArticleContentCard from '../../Shared/ContentCards/ArticleContentCard';

const ArticleContent = ({ contents, loading, handleSubmit }) => {
  const renderSpinner = () => {
    return (
      <div className="spinner-container">
        <img
          src="https://public.cureus.com/ajax-loader.gif"
          alt="loading spinner"
        />
      </div>
    );
  };

  const renderArticleCards = () => {
    return contents.map((content, index) => {
      return (
        <ArticleContentCard content={content} handleSubmit={handleSubmit} key={`article-content-${index}`} />
      );
    });
  };


  return (
    <div className="articles-container">
      {loading ? renderSpinner() : renderArticleCards()}
    </div>
  );
};

export default memo(ArticleContent);