import { Cureus } from '../../Cureus';
import Handlebars from 'handlebars';

Cureus.Admin.DepartmentMerger = (function($, _) {
  var init, DepartmentMerger;

  init = function(options) {
    new DepartmentMerger(options);
  };

  DepartmentMerger = function(options) {
    this.$table = $(options.tableSelector);
    this.$modal = $(options.modalSelector);
    this.$formWrapper = $(options.formWrapperSelector);
    this.openModalSelector = options.openModalSelector;

    this.selectedCheckboxes = [];

    this._bindEvents();
  };

  _.extend(DepartmentMerger.prototype, {
    _bindEvents: function() {
      var self = this;

      self.$table.on("click", this.openModalSelector, function() {
        var $openTrigger = $(this);

        var url = $openTrigger.data("url");
        var departments = $openTrigger.data("departments");

        var data = { departments: departments };

        self.$formWrapper.closest("form").attr("action", url);
        var template = Handlebars.compile("<div class='row'><div class='large-2 columns'><h6>Merge into<h6></div><div class='large-10 columns'><h6>To be merged</h6></div></div>{{#departments}}<div class='row'><div class='large-2 columns'><input type='radio' name='merge_department' value='{{id}}' required></div><div class='large-10 columns'><label for='merge_department_{{id}}'><input type='checkbox' name='merge_ids[]' value='{{id}}' id='merge_department_{{id}}'>{{name}}</label></div></div>{{/departments}}<input type='submit' class='button' value='Merge'>")
        var formHtml = template(data);
        self.$formWrapper.html(formHtml);

        self.$modal.foundation('reveal', 'open');
      });
    },
  });

  return { init: init };
})(jQuery, _);
