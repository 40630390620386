import React, { useState, useEffect } from 'react';
import BigArticleCard from '../../Shared/ContentCards/BigArticleCard';

const PosterCard = ({resource, isLast=false}) => {

  const endClass = () => {
    return isLast ? "end" : ""
  }

  return (
    <div className={`large-4 columns poster-card ${endClass()}`}>
      <BigArticleCard
        resource={resource}
        showCategories={true}
        showThumbnail={true}
        customStyleClass={true}
      />
    </div>
  )
}

export default PosterCard;