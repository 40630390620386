import React from 'react';

const ReplicationStudies = () => {
  return (
    <React.Fragment>
      <h1>Replication Studies</h1>

      <p>
        Cureus encourages submission of replication studies, particularly of research published in this journal.
      </p>
    </React.Fragment>
  )
}

export default ReplicationStudies;