var createReactClass = require('create-react-class');
import React from 'react';
import Post from './Post';

const Main = createReactClass({
  getInitialState: function() {
    return {
      posts: ['https://public.cureus.com/Stanford.png', 'https://public.cureus.com/Harvard.png', 'https://public.cureus.com/Yale.png', 'https://public.cureus.com/JohnsHopkins.png', 'https://public.cureus.com/Northwestern.png', 'https://public.cureus.com/Cleveland.png', 'https://public.cureus.com/UPMC.png', 'https://public.cureus.com/UCSF.png', 'https://public.cureus.com/NYU.png', 'https://public.cureus.com/Michigan.png', 'https://public.cureus.com/MountSinai.png', 'https://public.cureus.com/Mass.png', 'https://public.cureus.com/NewYorkPresbyterian.png', 'https://public.cureus.com/Houston.png', 'https://public.cureus.com/Colorado.png', 'https://public.cureus.com/duke.png', 'https://public.cureus.com/MayoClinic.png', 'https://public.cureus.com/UofTexas.png', 'https://public.cureus.com/barnes.png', 'https://public.cureus.com/bringham.png', 'https://public.cureus.com/cedars.png', 'https://public.cureus.com/dana.png', 'https://public.cureus.com/HospitalforSpecial.png', 'https://public.cureus.com/Jefferson.png', 'https://public.cureus.com/Penn.png', 'https://public.cureus.com/rush.png', 'https://public.cureus.com/scripps.png', 'https://public.cureus.com/Sloan.png', 'https://public.cureus.com/UCLA.png', 'https://public.cureus.com/UW.png'],
      start: 0,
      max: 6
    }
  },

  componentDidMount: function() {
    this.triggerFoundation()
  },

  triggerFoundation: function() {
    $(document).foundation({
      equalizer : {
        // Specify if Equalizer should make elements equal height once they become stacked.
        equalize_on_stack: true,
        // Allow equalizer to resize hidden elements
        act_on_hidden_el: false,
      }
    });
  },

  renderPosts: function() {
    var self = this;
    var index = 0
    var end = self.state.max + self.state.start
    var length = self.state.posts.length
    var over;
    if(end > length) {
      over = end - length
      end = length
    }
    var posts = self.state.posts.slice(self.state.start, end)
    if(over) {
      posts = posts.concat(self.state.posts.slice(0, over))
    }
    return posts.map(function(image) {
      index = index + 1
      return <Post image={image} key={image} index={"number-" + index}/>
    })
  },

  handleNext: function(dir) {
    var start = this.state.start
    var length = this.state.posts.length
    if (dir === 'left') {
      start = start - 6
      if(start < 0) {
        start = length + start
      }
    } else if (dir === 'right'){
      start = start + 6
      if(start > length) {
        start = start - length
      }
    }
    this.setState({start: start}, function() {this.triggerFoundation()})
  },

  render: function(){
    return (
      <React.Fragment>
      <div className="row">
        <div className="large-8 large-offset-2 medium-12 small-12 columns">
            <div className="channel-carousel-container">
              <h2>Reach High-Value Physicians From Leading Institutions</h2>
            </div>
        </div>
      </div>
      <div className="row">
        <div className="large-10 large-offset-1 medium-12 small-12 columns flex-container">
          <div className="large-1 medium-1 columns show-for-medium-up arrow-flex">
            <div className="channel-carousel-arrow-container">
              <i className="far fa-arrow-alt-circle-left"  onClick={() => this.handleNext('left')} style={{marginBottom: '32px'}}></i>
            </div>
          </div>
          <div className="medium-10 large-10 small-12 columns">
            <div className="channel-carousel-container">
              {this.renderPosts()}
            </div>
          </div>
          <div className="large-1 medium-1 columns show-for-medium-up arrow-flex">
            <div className="channel-carousel-arrow-container">
              <i className="far fa-arrow-alt-circle-right" onClick={() => this.handleNext('right')} style={{marginBottom: '32px'}}></i>
            </div>
          </div>
        </div>
      </div>
      <div className="row show-for-small-only">
      <div className="small-12 columns">
        <div className="channel-carousel-arrow-container" style={{marginTop: '0px'}}>
          <i className="far fa-arrow-alt-circle-left"  onClick={() => this.handleNext('left')}></i>
          <i className="far fa-arrow-alt-circle-right" onClick={() => this.handleNext('right')}></i>
        </div>
      </div>
    </div>
  </React.Fragment>
    )
  }
})

export default Main;