var createReactClass = require('create-react-class');
import React from 'react';
import SiqBadgeImage from '../SiqBadgeImage';

const PmcCard = createReactClass({
  getInitialState: function() {
    return({
      resource: this.props.resource.struct.table
    })
  },

  authorList: function() {
    var list = this.state.resource.authors.map(function(el) {
      return el
    }).join(', ')
    var truncated = list.substring(0, 200);
    if (list.length > truncated.length) {
      return truncated + '...';
    }
    return truncated;
  },
  render: function() {
    return(
      <div className="content-card">
        <div className="content-card-header">
          <div className="siq-badge-wrap-medium">
            <div style={{width: "40px", position: "relative"}}>
              <SiqBadgeImage
                width="55px"
              />
              <div className="siq-badge-score">
              -
              </div>
            </div>
          </div>
          <div className="content-card-header-info">
            <span>{this.state.resource.published_at}</span>
            <br/>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="content-card-details">
        <div className="content-card-divider"></div>
        <a href={"/pmc_articles/" + this.state.resource.dbid} target="_blank"><h5>{this.state.resource.title}</h5>
        </a>
        <div className="content-card-authors">
          {this.authorList()}
          <br/>
          {this.state.resource.journal_title}
        </div>
        <div className="content-card-logo">
          <img alt="Pmc logo grey" src="https://public.cureus.com/pmc-logo-grey.png"/>
        </div>
        </div>
      </div>
    )
  }
})

export default PmcCard;