var createReactClass = require('create-react-class');
import React from 'react';
import ConfirmationModal from '../ConfirmationModal';
import Modal from '../Modal';

const CureusUArticleListItem = createReactClass({
  getInitialState: function() {
    return ({
      summary: this.props.article.summary
    })
  },

  removeArticle: function(articleId) {
    var self = this;
    return function() {
      self.props.removeArticle(articleId);
    }
  },

   removeArticleConfirmationModal: function() {
    Modal.open({
      children: <ConfirmationModal
                  handleSubmit= {this.removeArticle(this.props.article.id)} />
    })
  },

  onChange: function(e) {
    this.setState({summary: e.target.value})
  },

  renderRemove: function() {
    if (this.props.removeArticle) {
      return (
        <div className='small-3 columns end remove'>
          <input type='button' onClick={this.removeArticleConfirmationModal} className="button small secondary pull-right" value='remove'/> 
        </div>
      )
    }
  },

  renderAdd: function() {
    if (this.props.addArticle && this.props.canAdd) {
      return (
        <i className='add-article right fas fa-plus-circle fa-lg' onClick={() => { this.props.addArticle(this.props.article.id) }}></i>
      )
    }
  },

  renderAlreadySent: function() {
    if (this.props.article.alreadySent) {
      let className = this.props.addArticle && this.props.canAdd ? 'can-add' : ''
      return (
        <img className={"already-sent-badge " + className} src="https://public.cureus.com/cureus_u_sent.png"/>
      )
    }
  },

  render: function() {
    return (
      <div className='cureus-u-article-list-item row drag-handle'>
        <div className='row title'>
          <a href={this.props.article.articlePath} target="_blank" rel="noreferrer">{this.props.article.title}</a>
          { this.renderAlreadySent() }
          { this.renderAdd() }
        </div>
        <div className='row'>
          <div className='thumbnail' dangerouslySetInnerHTML={{__html: this.props.article.thumbnail}}/>
          <div className='article-details'>
            <table>
              <tbody>
                <tr>
                  <td>Article ID: <a href={this.props.article.adminArticlePath} target="_blank" rel="noreferrer">{this.props.article.id}</a></td>
                  <td>Approval Editor Rating: {this.props.article.rating}</td>
                  <td>Views: {this.props.article.views}</td>
                </tr>
                <tr>
                  <td>Published: {this.props.article.publishedAt}</td>
                  <td>SIQ: {this.props.article.siq}</td>
                  <td>Downloads: {this.props.article.downloads}</td>
                </tr>
                <tr>
                  <td>Type: {this.props.article.type}</td>
                  <td># SIQs: {this.props.article.siqCount}</td>
                  <td>Country: {this.props.article.country}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='row'>
          <div className='small-12 columns'>
            Authors:&nbsp;
            <span dangerouslySetInnerHTML={{__html: this.props.article.authors}}></span>
          </div>
        </div>
        <div className='row'>
          <div className='small-8 columns input'>
            <input id="campaign_campaign_articles_attributes_summary" name="campaign[campaign_articles_attributes][][summary]" value={this.state.summary} placeholder="Optional Summary" type="text" onChange={(e) => this.onChange(e)}/>
          </div>
          { this.renderRemove() }
        </div>
      </div>
    )
  }
});

export default CureusUArticleListItem;