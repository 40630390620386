import React, { useState, useEffect } from "react";
import ArticlesTableSection from "./ArticlesTableSection"

const Articles = () => {
  const [channelTableData, setChannelTableData] = useState([])

  useEffect(() => {
    fetchChannelsTableInfo()
  }, [])

  const fetchChannelsTableInfo = () => {
    $.ajax({
      url: '/admin/channels/articles_table_data',
      data: { dataType: 'articles_table_data' },
      success: function(data) {
        setChannelTableData(data)
      }
    });
  }

  return (
    <>
      {
        channelTableData.length > 0 &&
        <>
          <ArticlesTableSection
            key={1}
            channelTableData={channelTableData}
            headerText="Channel & Peer Review"
            states={["editor_preview", "in_review", "editor_approval"]}
            defaultState={"editor_preview"}/>
          <ArticlesTableSection
            key={2}
            channelTableData={channelTableData}
            showEmailButtton={false}
            headerText="Removals & Expirations"
            states={["channel_removed", "claim_expired"]}
            defaultState={"channel_removed"}/>
        </>
      }
    </>
  )
}

export default Articles
