import { Cureus } from '../../Cureus';
import Handlebars from 'handlebars'

Cureus.MultiAdd = (function ($, _){
  var init, MultiAdd;

  init = function(options) {
    new MultiAdd(options);
  }

  MultiAdd = function(options) {
    this.rowTemplatePath = options.rowTemplate;
    this.$container = $(options.container || ".multi-add-wrap");
    this.fieldCounter = this.numberOfRows();
    this.afterDelete = options.afterDelete || function() {};
    this.afterAdd = options.afterAdd || function() {};
    this.presenterOptions = options.presenterOptions || {};
    this.allowFirstRowRemove = options.allowFirstRowRemove || {};

    this.bindAddButtons();
    this.bindRemoveButtons();
    this.toggleActionButtons();

    (options.afterInitialize || function() {})();
  }

  _.extend(MultiAdd.prototype, {
    add: function (e){
      this.createNewField();
      this.afterAdd();
      e.preventDefault();
    },

    createNewField: function (){
      // The only place we use this is for professional societies
      var template = Handlebars.compile("<div class='control-group prof-soc-section'><div class='row'><div class='small-6 columns'><label for='user_professional_society_user_profile_{{fieldCounter}}_name'>Name of Professional Society</label><input id='user_professional_society_user_profile_{{fieldCounter}}_name' name='user[professional_society_user_profile][][name]' data-professional-society-autocomplete type='text'><label for='user_professional_society_user_profile_{{fieldCounter}}_url'>Website/URL</label><input id='user_professional_society_user_profile_{{fieldCounter}}_url' name='user[professional_society_user_profile][][url]' type='text'></div><div class='small-6 columns centered'><div class='uploadable-image' id='logo-uploader-wrap'><div class='fancy-circle-image smaller-circle prof-society-avatar'><img /></div><input class='button tiny secondary prof-file' id='logo' name='user[professional_society_user_profile][][logo]' id=user_professional_society_user_profile_{{fieldCounter}}_logo' type='file'></div></div></div><div class='row'><div class='large-6 columns'><a href='#' class='button tiny secondary' data-remove-prof-society data-remove-multi-add='true'> Remove </a><a href='#' class='button tiny secondary' data-add-fields='true'> Add another </a></div></div><hr></div>");
      var $row = $(template(_.extend({
        fieldCounter: this.fieldCounter
      }, this.presenterOptions)));

      this.$container.find(".multi-add-wrap").append($row);
      this.refreshFoundation();
      this.incrementFieldCount();
      this.toggleActionButtons();
    },

    incrementFieldCount: function (){
      this.fieldCounter++;
    },

    remove: function (e){
      if ( this.allowFirstRowRemove && this.numberOfRows() == 1 ) {
        $("[data-remove-prof-society]").remove()
        $(".prof-soc-section .row").first().remove()
        $(".prof-soc-section hr").remove()
      }
      else {
        $(e.currentTarget).closest(".control-group").remove();
      }
      this.toggleActionButtons();
      this.afterDelete();
      e.preventDefault();
    },

    bindAddButtons: function (){
      this.$container.on('click', '[data-add-fields]', _.bind(this.add, this));
    },

    bindRemoveButtons: function (){
      this.$container.on("click", "[data-remove-multi-add]", _.bind(this.remove, this));
    },

    refreshFoundation: function() {
      $(document).foundation();
    },

    numberOfRows: function() {
      return this.$container.find('.control-group').length
    },

    toggleActionButtons: function() {
      if(this.numberOfRows() == 1 && !this.allowFirstRowRemove ) {
        this.$container.find('[data-add-fields]').show();
        this.$container.find('[data-remove-multi-add]').hide();
      } else {
        this.$container.find('[data-add-fields]:not(:last)').hide();
        this.$container.find('[data-add-fields]:last').show();
        this.$container.find('[data-remove-multi-add]').show();
      }
    }
  });

  return {
    init: init
  }
})(jQuery, _);
