import React from 'react';

const AddingMedia = () => {

  return (
    <React.Fragment>
      <h1>Adding Media to Your Article</h1>

      <h2>What counts as media?</h2>
      <p>
        We consider all tables, figures and video to be media. Whenever you see media referred to in our instructions, you can safely assume that we mean tables, figures and video unless otherwise specified.
      </p>

      <h2>Where can I add media?</h2>
      <p>
        Media is not permitted in the abstract, introduction or conclusions sections of any article type. The only exception is the introduction section of a review article. Each article is permitted up to 25 media items.
      </p>

      <h2>How do I add, move or remove media?</h2>
      <p>
        To add media to your article first place your cursor in the desired location in the article section text field and click one of the Insert Figure, Insert Table or Insert Video buttons on the article section toolbar. You will then be prompted to upload the figure or paste the table or video URL and add a title and optional legend. For detailed instructions on adding each media type, please see below.
      </p>
      <p>
        You can move videos, tables and figures up or down in the text by clicking the arrow buttons embedded in each media item.
      </p>
      <h2>Questionnaires as media?</h2>
      <p>
        All questionnaires that have been previously published must be properly cited in the article (mandatory). If the questionnaire is not Open Access, permission for its use must also be obtained. For questionnaires developed by the authors, these must be included within the article itself.
      </p>
    </React.Fragment>
  )
}

export default AddingMedia;