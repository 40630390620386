
var createReactClass = require('create-react-class');
import React from 'react';

const Search = createReactClass({
  updateSearchTerm: function(e) {
    e.preventDefault();
    this.props.updateSearchTermState(e.target.value)
  },

  componentWillMount: function() {
    this.updateSearchTerm = _.debounce(this.updateSearchTerm, 500);
  },

  handleChange: function(e) {
    e.persist()
    this.updateSearchTerm(e)
  },

  render: function() {
    return(
      <div className='email-list-tool-search'>
        <input type='text' placeholder='Search Reviewers by Email, Name or Specialty' onChange={(e) => this.handleChange(e)} />
      </div>
    )
  }
}) 

export default Search;