var createReactClass = require('create-react-class');
import React from 'react';
import ArticleTypeModal from './ArticleTypeModal';
import Modal from '../Modal';

const Types = createReactClass({
  getInitialState: function() {
    return({
      types: this.props.types
    })
  },

 openTypeModal: function() {
    Modal.open({
      children: <ArticleTypeModal types={this.state.types} handleChange={this.props.handleChange}  submitUpdate={this.props.submitUpdate} resetPage={this.props.resetPage}/>
    })
  },

  innerLabel: function() {
    var checked = [];
    Object.values(this.state.types).forEach(function(type) {
      if (type.checked){ checked.push(type.name) }
    });
    if (checked.length) {
      return (
        <div>
          <div className='cureus-multi-select-placeholder'>
            {checked.join(', ')}
          </div>
          <div className='cureus-multi-select-count'>{checked.length}</div>
        </div>
      )
    } else {
      return (
        <div className='cureus-multi-select-placeholder'>
          <i className='far fa-copy'/>
          <span>ALL TYPES</span>
        </div>
      )
    }
  },

  classType: function() {
    if (this.props.hasSubchannels) {
      return 'large-3 large-offset-0 medium-4 medium-offset-2 small-12 columns'
    }
    if(this.props.compOrChannel) {
      return 'large-3 large-offset-0 medium-4 medium-offset-2 small-6 columns'
    }
    return this.props.searchPage ? 'large-4 large-offset-2 medium-6 small-6 columns' : 'large-4 large-offset-0 medium-4 small-6 columns'
  },

  render: function() {
    return(
      <div className={this.classType()}>
        <div id='types-multiselect-wrap'>
          <div className='cureus-custom-multi-select-wrap'>
            <div className='cureus-multi-select' onClick={this.openTypeModal}>
              {this.innerLabel()}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Types;