var createReactClass = require('create-react-class');
import React from 'react';

const AddDisclosureModal = createReactClass({
  getInitialState: function() {
    return {
      CHECKBOX_DATA:  [
        ['Grant', 'grant'],
        ['Employment', 'employment'],
        ['Stock/Stock Options', 'stock'],
        ['Personal Fees (consulting, lectures, manuscript preparations, etc.)', 'personal_fees'],
        ['Patent', 'patent'],
        ['Royalties', 'royalties'],
        ['Non-financial (e.g. leadership or fiduciary role in other board, society, committee or advocacy group)', 'non_financial_support'],
        ['Other (e.g. payment for expert testimony, support for attending meetings/travel, receipt of equipment/materials/drugs/medical writing/gifts/other services)', 'other']
      ],
      formData: {
        article_id: this.props.articleId,
        disclosure_id: this.props.disclosureId,
        financial_relationship: {
          grant: false,
          employment: false,
          stock: false,
          personal_fees: false,
          patent: false,
          royalties: false,
          non_financial_support: false,
          other: false,
          name: '',
          authors: '',
          comments: '',
          custom_coi_type: ''
        },
        action:  this.props.action
      },
      modalErrors: [],
      errorFields: []
    }
  },

  componentDidMount: function() {
    if (this.props.relationInfo) {
      this.updateDefaultState();
    }
  },

  updateDefaultState: function() {
    var self = this;
    var newFormData = self.state.formData;

    Object.keys(newFormData.financial_relationship).forEach(function(key) {
      newFormData.financial_relationship[key] = self.props.relationInfo[key];
    });

    this.setState({
      formData: newFormData
    })
  },

  newDisclosure: function(formData) {
    var self = this;
    $.ajax({
      type: 'post',
      url: '/publish/articles/' + formData.article_id + '/financial_relationships',
      data: formData,
      success: function(){
        self.props.getData()
        $('#new_financial_relationship').foundation('reveal', 'close');
      },
      error: function(data) { self.handleSubmitError(data) }
    })
  },

  updateDisclosure: function(formData) {
    var self = this;
    $.ajax({
      type: 'put',
      url: self.props.relationInfo.update_path,
      data: formData,
      success: function() {
        self.props.getData();
        $('#new_financial_relationship').foundation('reveal', 'close');
      },
      error: function(data) { self.handleSubmitError(data) }
    })
  },

  clearErrors: function() {
    this.setState({
      modalErrors: [],
      errorFields: []
    })
  },

  submitForm: function(e) {
    e.preventDefault();
    var self = this;
    self.clearErrors();
    var formData = self.state.formData;
    if (self.state.formData.action === 'update') {
      self.updateDisclosure(formData);
    } else {
      self.newDisclosure(formData);
    }
  },

  handleSubmitError: function(data) {
    var parsed = JSON.parse(data.responseText);
    var displayError = Object.values(parsed).map(function(el) { return el.join(' ') }).join(' ');
    var keys = Object.keys(parsed);
    this.setState({
      modalErrors: [displayError],
      errorFields: keys
    })
  },

  updateCheckbox: function(box) {
    var self = this;
    return function() {
      var newValue = !self.state.formData.financial_relationship[box];
      var newState = self.state;
      newState.formData.financial_relationship[box] = newValue;
      self.setState(newState);
    }
  },

  updateTextField: function(field) {
    var self = this;
    return function(e) {
      var newState = self.state;
      newState.formData.financial_relationship[field] = e.target.value;
      self.setState(newState)
    }
  },


  mapCheckboxColumn: function(data) {
    var self = this;
    var checkBoxes = data.map(function(box) {
      return (
        <div className='row' key={box[1]}>
          <div className='small-12 columns'>
            <label>
              <input type='checkbox' onChange={self.updateCheckbox(box[1])} defaultValue={box[1]} id={box[1]}
              checked={self.state.formData.financial_relationship[box[1]]} />
               &nbsp;{ box[0] }
            </label>
          </div>
        </div>
      )
    });
    return (
      <div className='small-6 columns'>
        {checkBoxes}
      </div>
    )
  },



  mapCheckboxes: function() {
    return (
      <div className='row financial-relationship-types'>
        {this.mapCheckboxColumn(this.state.CHECKBOX_DATA.slice(0, 6))}
        {this.mapCheckboxColumn(this.state.CHECKBOX_DATA.slice(6))}
      </div>
    )
  },

  renderErrors: function() {
    if (this.state.modalErrors.length) {
      return(
        <div className='create-errors errors'>{this.state.modalErrors.join(' ')}</div>
      )
    }
  },

  displayOtherCoi: function() {
    return this.state.formData.financial_relationship.other ? 'block' : 'none';
  },

  render: function() {
    return(
      <div className="reveal-modal medium" data-reveal id="new_financial_relationship">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal' onClick={this.clearErrors}>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className="modal-content">
            <h4>Financial Relationships</h4>
            { this.renderErrors() }
            <p>
            Check the appropriate boxes below to indicate where you (or your co-authors) have financial relationships (regardless of compensation amount) and add details in the relevant fields. You may check multiple boxes per relationship. You should disclose interactions with any entity that could be considered broadly relevant to your work. Report all relationships that were present during the 36 months prior to publication.
            </p>
            { this.mapCheckboxes() }
            <div className='financial-relationship-text-fields'>
              <div className='row'>
                <div className='small-12 columns'>
                  <label className={this.state.errorFields.includes('name') ? 'errors' : ''}>
                  Name of Institution/Company
                  <input type='text' label='name' value={this.state.formData.financial_relationship.name} onChange={this.updateTextField('name')} />
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='small-12 columns'>
                  <label className={this.state.errorFields.includes('authors') ? 'errors' : ''}>
                  Author(s)
                  <textarea value={this.state.formData.financial_relationship.authors}
                    onChange={this.updateTextField('authors')} label='authors' />
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='small-12 columns'>
                  <label>
                  Comments
                  <textarea value={this.state.formData.financial_relationship.comments}
                    onChange={this.updateTextField('comments')} label='comments' />
                  </label>
                </div>
              </div>
              <div className='row' style={{display: this.displayOtherCoi() }}>
                <div className='small-12 columns'>
                  <label className={this.state.errorFields.includes('custom_coi_type') ? 'errors' : ''}>
                  Other COI type
                  <input type='text' label='custom_coi_type' value={this.state.formData.financial_relationship.custom_coi_type} onChange={this.updateTextField('custom_coi_type')} />
                  </label>
                </div>
              </div>
            </div>
          </div>
            <div className='row'>
              <div className='small-12 columns'>
                <div className="pull-right modal-footer">
                  <a href="#" className="button secondary cancel-modal" onClick={this.clearErrors}> Cancel </a>
                  <input type='submit' className="button primary"  defaultValue={this.state.formData.action === 'create' ? 'Submit' : 'Update'} onClick={ this.submitForm } />
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default AddDisclosureModal;