import React, { useEffect } from 'react';

const DeskSubmissions = ( { datasets } ) => {

  useEffect(() => {
    $("#desk-submissions-table").DataTable({
      dom: '<"row"<"large-4 columns"l><"large-6 columns"f>>t<"row"<"large-8 columns"i><"pull-right"p>>',
    });
  }, [])

  useEffect(() => {
    populateTable()
  })
  
  function populateTable() {
    var dt = $("#desk-submissions-table").DataTable();
    dt.api().clear();
    for (var i = 0; i < datasets.length; i++) {
      var editor = datasets[i];
      dt.fnAddData( [
          `<a href='/admin/users/${editor.id}' target='_blank'>${editor.full_name}</a>`,
          editor.approved,
          editor.desk,
          editor.blocked,
          (((editor.desk + editor.blocked) / editor.approved) * 100).toFixed(0) + '%'
      ], false)
    }
    dt.fnDraw()
  }

  return (
    <table className="table-wrapper data-table" id="desk-submissions-table">
      <thead>
        <tr>
          <th>Editor</th>
          <th>Approved</th>
          <th>Desk Rejected</th>
          <th>Blocked</th>
          <th>Percentage</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  )
}

export default DeskSubmissions;