import React from 'react';

const Faq = () => {
  return (
    <React.Fragment>
      <h1>Frequently Asked Questions</h1>

      <h2>Are channel articles subject to the same editorial policies and procedures as non-channel articles?</h2>
      <p><strong>Yes</strong>. All channel article submissions will undergo the same review by Cureus editor and peer reviewers prior to approval for publication. Channel articles that do not meet the standards of the journal will be rejected.</p>

      <h2>Are academic channels the same as special issues in other journals?</h2>
      <p><strong>No</strong>. Academic channels function much differently than traditional special issues as Cureus editors retain final control over article acceptance and publication. Cureus Collections are closer to the general approach of journal special issues, but still different. Here Cureus guest editors are able to determine whether articles already approved by Cureus journal editors are a good fit for inclusion in their collection.</p>

      <h2>Can a channel admin or editor review their own article?</h2>
      <p><strong>No</strong>. Channel admins or editors who are listed as an author on a channel article are unable to access the article from the channel editorial platform. This ensures that no channel articles are considered, managed or reviewed by authors as this is a clear conflict of interest. If all channel admins and editors are listed as authors on a single article, this article will bypass channel review and proceed directly to journal editorial review which does not involve the channel editorial team.</p>

      <h2>Do peer reviewers invited by a channel admin or editor count towards the required number of completed peer reviews?</h2>
      <p><strong>No</strong>. As with all articles published in Cureus, every channel article must receive at least two completed peer reviews from reviewers invited by Cureus journal editors. Peer reviewers suggested by the authors or invited by channel admins or editors will assist in strengthening the article by providing valuable additional feedback, but these reviews do not count towards the minimum requirement to satisfy peer review.</p>

      <h2>Does Cureus hire associate editors from channel organizations?</h2>
      <p><strong>Yes</strong>, this can happen, although associate editors are restricted from providing editorial review of their hospital, university or medical society’s channel articles to avoid the risk of conflict of interest.</p>

      <h2>Are channel articles fast-tracked for publication?</h2>
      <p><strong>No</strong>. Channel articles are processed in one article queue along with all other article submissions to the journal. This includes articles undergoing Preferred Editing, articles that qualified for free publication and articles that underwent third-party language editing.</p>
      
    </React.Fragment>
  )
}

export default Faq;