var createReactClass = require('create-react-class');
import React from 'react';

const Reviewer = createReactClass({
  render: function(){
    return (
      <div className="columns small-6 featured-user" >
        <div className="small-2 columns featured-user-checkbox-container" >
          <input type="checkbox" name={this.props.reviewer.id} onClick={this.props.handleSelect}/>
        </div>
        <div className="small-10 columns user-info"  >
          <div className="row">
            <div className="medium-2 columns avatar-container">
              <div className="avatar">
                <img src={this.props.reviewer.avatar_profile_url} />
              </div>
            </div>
            <div className="medium-10 columns user-info-container">
              <div className="title">
                <strong className="full-name">
                  {this.props.reviewer.full_name}  
                </strong>
                {this.props.reviewer.qualification_titles}
              </div>

              <div className="specialty">
                {this.props.reviewer.specialty}
              </div>

              <div className="row affiliation">
                <div className="columns icon-container small-1">
                  <i className="far fa-building"></i>
                </div>
                <div className="columns affiliation-name small-11">
                  <span>{this.props.reviewer.affiliation_name_full}</span>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }
})

export default Reviewer;