var createReactClass = require('create-react-class');
import React from 'react';

const PMCPagination = createReactClass({
  getDefaultProps: function() {
    return {
      options: [
        {
          label: "Show 20 results",
          value: 20
        },
        {
          label: "Show 50 results",
          value: 50
        },
        {
          label: "Show 100 results",
          value: 100
        }
      ],
      value: "Show 20 results"
    }
  },

  handleChange: function(val) {
    this.props.onChange(val);
  },

  valueRenderer: function(val) {
    return (
      <div>
        { val.label }
        &nbsp;
        <i className='fa fa-sort-down'></i>
      </div>
    )
  },

  render: function() {
    return (
      <Select
        options={ this.props.options }
        searchable={ false }
        value={ this.props.value }
        clearable={ false }
        onChange={ this.handleChange }
        valueRenderer={ this.valueRenderer }
        className="plain-select"
      />
    )
  }
});

export default PMCPagination;
