import React, { useState, useEffect } from 'react';
import DiscussionCard from '../Shared/ContentCards/DiscussionCard';

const HomepageContainer = ({currentUserId}) => {
  const [discussions, setDiscussions] = useState([])
  const [selected, setSelected] = useState([])
  const [limit, setLimit] = useState(4)

  useEffect(() => {
    fetchDiscussions()
  }, [])

  const fetchDiscussions = () => {
    $.ajax({
      type: 'GET',
      data: {limit: limit},
      url: "/comments/fetch_discussions",
      success: function(data) {
        setDiscussions(data.discussions)
        setSelected(data.selected)
        setLimit(data.limit)
      }
    });
  }

  const renderDiscussions = () => {
    return discussions.map(function(discussion, idx) {
      return <DiscussionCard 
        discussion={discussion}
        selected={selected}
        setSelected={setSelected}
        position={idx + 1}
        currentUserId={currentUserId}
      />
    })
  }
  
  return (
    <div className="large-12 columns discussions-container">
      <h3>Discussions</h3>
      {renderDiscussions()}
    </div>
  )

}

export default HomepageContainer;