import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";
import CureusUpdates from '../Home/CureusUpdates';
import NewPicturePrognosis from '../PicturePrognosis/New';

const Sidebar = ({cureusUpdatePath, lastCureusUpdate, setActiveTab}) => {
  const location = useLocation();
  const [activeSubTab, setActiveSubTab] = useState(location.pathname)
  useEffect(() => {
    setActiveTab('sidebar')
  }, [])

  return (
    <React.Fragment>
      <div className="small-12 columns nav-container bordered">
        <div className="row">
          <div className="large-12 columns">
            <div className="cureus-tabs secondary-tabs">
              <div className="tabs-container dashboard">
                <div className="tab-items">
                  <ul>
                  <Link to="/sidebar/picture-prognosis">
                    <li className={"tab-title inline " + (activeSubTab === '/sidebar/picture-prognosis' ? 'active' : '')} onClick={() => setActiveSubTab('/sidebar/picture-prognosis')}>
                      <a className="dashboard">PICTURE PROGNOSIS SETTINGS</a>
                    </li>
                  </Link>
                  <Link to="/sidebar/cureus-updates">
                      <li className={"tab-title inline " + (activeSubTab === '/sidebar/cureus-updates' ? 'active' : '')} onClick={() => setActiveSubTab('/sidebar/cureus-updates')}>
                        <a className="dashboard">CUREUS UPDATES</a>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch>
        <Route path='/sidebar/cureus-updates'>
          <CureusUpdates lastCureusUpdate={lastCureusUpdate} cureusUpdatePath={cureusUpdatePath} />
        </Route>
        <Route path='/sidebar/picture-prognosis'>
          <NewPicturePrognosis />
        </Route>
      </Switch>
    </React.Fragment>
  )

}

export default Sidebar;