var createReactClass = require('create-react-class');
import React from 'react';
import Main from '../../StripeV2/Main';

const PostDeferralOptionsModal = createReactClass({
  getInitialState: function() {
    return {
      selection: true
    }
  },

  handlePurchase: function() {
    this.setState({selection: false})
  },

  renderModalContent: function () {
    if (this.state.selection) {
      return (
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className="modal-content">
            <h4>Make the revisions yourself or purchase Preferred Editing? It's up to you.</h4>
            <br/>
            <p> 
              Your article has been deferred by a Cureus editor. You may revise and respond yourself to the required edits in the deferral message or purchase our Preferred Editing (PE) service where we make the revisions for you.
            </p>
            <p> 
              If you'd rather do the work yourself, just click Edit Article to get started. To learn more about our Preferred Editing service, click Purchase Preferred - you can always go back and edit yourself if you decide not to purchase PE.
            </p>
            <div className="modal-footer">
              <a className="button secondary" href={"/publish/articles/" + this.props.article.id + "/edit"} >Edit Article</a>
              <a className="button primary" onClick={this.handlePurchase}>Purchase Preferred</a>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <Main
          product="preferred_editing"
          articleId={this.props.article.id}
          source={this.props.source}
          optional={true}
          />
      )
    }
  },
  
  render: function () {
    return (
      <div className="reveal-modal middling" data-reveal>
        {this.renderModalContent()}
      </div>
    )
  }
})

export default PostDeferralOptionsModal;