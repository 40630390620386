import React from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';
import { addColor } from './UtilityFunctions';

const Concern = ({ userEmail, checkboxObj }) => {
  const columnsConfig = [
    checkboxObj,
    {
      title: 'Editor',
      data: 'full_name',
      width: '140px',
      orderable: true,
      render: (row) => {
        return `<a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>`
      }
    },
    { title: '# Articles finished', data: 'finished', class: 'centered' },
    { title: '# Articles claimed', data: 'claimed', class: 'centered' },
    {
      title: 'Rejection Rate',
      data: 'rejected_rate',
      class: 'centered',
      tooltip: 'Rejected / Total completed, Target: 2-3% Close enough: 1%, 4-5% Problematic: 0%, 6%+'
    },
    {
      title: 'Deferrals Rate',
      data: 'deferred_rate',
      class: 'centered',
      tooltip: 'Deferred / Total claimed, Target: 15-20&#013; Close enough: 11-14%, 21-25%&#013; Problematic: 0-10%, 26%+&#013;'
    },
    { title: 'Expirations', data: 'expired', class: 'centered', tooltip: `Total expirations` },
    { title: 'Expirations Rate', data: 'expired_rate', class: 'centered', tooltip: `Total expirations / Total claimed, Problematic: 20%+` },
  ];

  const peerReviewArticleConfig = [
    {
      title: 'Editor',
      data: 'full_name',
      width: '140px',
      orderable: true,
      render: (row) => {
        return `<a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>`
      }
    },
    { title: 'Date', data: 'initially_completed_at', width: '140px', class: 'centered' },
    {
      title: 'Article ID',
      data: 'article_id',
      class: 'centered',
      render: (row) => {
        return `<a class='column-hoverable' id=${row.id} target='_blank' href='/admin/articles/${row.article_id}'>${row.article_id}</a>`
      }
    },
    { title: 'Reviewer Stats (Yes)', data: 'reviewer_response_yes', class: 'centered' },
    { title: 'Reviewer Stats (No)', data: 'reviewer_response_no', class: 'centered' },
    { title: 'Reviewer Stats (Pending)', data: 'reviewer_response_pending', class: 'centered' },
    { title: 'Action Taken',
      data: 'action_taken',
      class: 'centered',
      render: () => {
        return `<span class='checkmark'><i class="fa-solid fa-circle-check fa-xl" style="color: #6ac646;"></i></span>`
      }
    }
  ]

  const peerReviewEditorConfig = [
    {
      title: 'Editor',
      data: 'full_name',
      width: '140px',
      orderable: true,
      render: (row) => {
        return `<a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>`
      }
    },
    { title: 'Not Aligned', data: 'not_aligned', class: 'centered' },
    { title: 'Total Articles', data: 'total', class: 'centered' },
    { title: 'Not Aligned %', data: 'not_aligned_rate', class: 'centered' },
  ]

  const rowRenderCallback = (row, data) => {
    colorizeRejected(data, row);
    colorizeDeferred(data, row);
    colorizeExpired(data, row);
  }

  const colorizeRejected = (data, row) => {
    let cssClass;
    let count = parseInt(data[4]);
    if (count === 0 || count >= 6) {
      cssClass = "circle red";
    } else if (count === 1 || count === 4 || count === 5 ) {
      cssClass = "circle orange";
    }

    if (cssClass) {
      addColor(data[4], row, cssClass, 4);
    }
  }

  const colorizeDeferred = (data, row) => {
    let cssClass;
    let count = parseInt(data[5]);
    if (( count >= 0 && count <= 10) || count >= 26) {
      cssClass = "circle red";
    } else if ((count > 10 && count <= 14) || count > 20 && count <= 25 ) {
      cssClass = "circle orange";
    }

    if (cssClass) {
      addColor(data[5], row, cssClass, 5);
    }
  }

  const colorizeExpired = (data, row) => {
    let cssClass;
    let count = parseInt(data[7]);
    if (count > 20) {
      cssClass = "circle red";
    }

    if (cssClass) {
      addColor(data[6], row, cssClass, 7);
    }
  }

  return (
    <>
      <DynamicJqueryTable
        columnsConfig={columnsConfig}
        path="/admin/ae_dashboards/concern"
        tableId="concern"
        tableTitle={"Points of Concern"}
        rowRenderCallback={rowRenderCallback}
        userEmail={userEmail}
        initialDateIndex={4}
        showCheckBoxes
        showCsv
      />
      <DynamicJqueryTable
        columnsConfig={peerReviewArticleConfig}
        path="/admin/ae_dashboards/peer_review_article"
        tableId="peer-review-article"
        tableTitle={"Not Aligned with Cureus Peer Reviewers (by article)"}
        userEmail={userEmail}
        initialDateIndex={0}
      />
      <DynamicJqueryTable
        columnsConfig={peerReviewEditorConfig}
        path="/admin/ae_dashboards/peer_review_editor"
        tableId="peer-review-editor"
        tableTitle={"Not Aligned with Cureus Peer Reviewers (by editor)"}
        userEmail={userEmail}
        initialDateIndex={4}
      />
    </>
  )
};

export default Concern;