import React, {useState} from 'react';
import Modal from '../Modal';

const ArticleModal = ({collectionId}) => {

  const [articleId, setArticleId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleAddArticle = () => {
    $.ajax({
      type: 'POST',
      url: `/admin/collections/${collectionId}/add_article_to_collection/${articleId}`,
      dataType: 'json',
      success: function(res) {
        Modal.close();
      },
      error: function(res) {
        setErrorMessage(res.responseJSON.message)
      }
    });
  }

  return (
    <div id={"add-article-reveal-modal"} className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="large-12 columns">
              <h5 className="text-center">Enter Article ID:</h5>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns input-container">
              <input 
                className="article-modal-input"
                placeholder="ID:"
                value={articleId}
                onChange={(e) => setArticleId(e.target.value)}
              />
            </div>
            <div className="small-12 columns message-container">
              {errorMessage && <small>{errorMessage}</small>}
            </div>
            <div className="large-12 columns buttons-container">
              <a className="button small success" onClick={handleAddArticle}>Add</a>
              <a href="#" className="button small alert cancel-modal">Cancel</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleModal;