import { Cureus } from '../../Cureus';
import Handlebars from 'handlebars';

Cureus.Admin.InstitutionMerger = (function($, _) {
  var init, InstitutionMerger;

  init = function(options) {
    new InstitutionMerger(options);
  };

  InstitutionMerger = function(options) {
    this.$trigger = $(options.openModalSelector);
    this.$table = $(options.tableSelector);
    this.$modal = $(options.modalSelector);
    this.$formWrapper = $(options.formWrapperSelector);

    this.selectedCheckboxes = [];

    this._bindEvents();
  };

  _.extend(InstitutionMerger.prototype, {
    _bindEvents: function() {
      var self = this;

      self.bindOpenTrigger();

      self.$table.on("change", "input[type='checkbox']", function() {
        var $checkbox = $(this);
        if($checkbox.is(":checked")) {
          self.addCheckbox($checkbox);
        } else {
          self.removeCheckbox($checkbox);
        }
        self.toggleOpenTrigger();
      });

      $(document).on("open.fndtn.reveal", "#merge-institutions", function() {
        var data = { 
          institutions: self.presentedInstitutions(),
          merge_ids: self.institutionIds()
        };
        var template = Handlebars.template("{{#institutions}}<label for='merge_institution_{{id}}'><input type='radio' name='merge_institution' value='{{id}}' id='merge_institution_{{id}}' required>{{name}}</label>{{/institutions}}{{#merge_ids}}<input type='hidden' name='merge_ids[]' value='{{this}}'>{{/merge_ids}}<input type='submit' class='button' value='Merge'>")
        var formHtml = template(data);
        self.$formWrapper.html(formHtml);
      });
    },

    toggleOpenTrigger: function(){
      if(this.selectedCheckboxes.length > 1){
        this.$trigger.removeClass("disabled");
      } else {
        this.$trigger.addClass("disabled");
      }
    },

    bindOpenTrigger: function(){
      var self = this;

      this.$trigger.on("click", function(e){
        e.preventDefault();
        if(!$(this).hasClass("disabled")) {
          self.$modal.foundation("reveal", "open");
        }
      });
    },

    addCheckbox: function($checkbox) {
      var selectedIds = _.map(this.selectedCheckboxes, function($obj) {
        return $obj.val();
      });

      if(!_.contains(selectedIds, $checkbox.val())) {
        this.selectedCheckboxes.push($checkbox);
      }
    },

    removeCheckbox: function($checkbox) {
      this.selectedCheckboxes = _.reject(this.selectedCheckboxes, function($obj) {
        return $obj.val() == $checkbox.val();
      });
    },

    presentedInstitutions: function() {
      return _.map(this.selectedCheckboxes, function($checkbox) {
        return {
          id: $checkbox.val(),
          name: $checkbox.data("name")
        }
      });
    },

    institutionIds: function() {
      return _.map(this.selectedCheckboxes, function($checkbox) {
        return $checkbox.val();
      })
    }
  });

  return { init: init };
})(jQuery, _);
