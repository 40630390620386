import {Cureus} from '../Cureus';

Cureus.Autosaver = function() {
  this.saveInterval = null;
};


Cureus.Autosaver.prototype.submitFormData = function(successCallback, sidebarClick) {
  var self = this;
  var forms = document.querySelectorAll('form');

  if (self.syncCKEditor() || !forms[0]) {
    if (sidebarClick) { successCallback(); }
    return;
  }

  if (location.href.indexOf('references/edit') !== -1) {
    var form = forms[1]
  } else {
    var form = forms[0]
  }

  $.ajax({
    type: 'post',
    url: form.action,
    data: form.serialize(),
    success: successCallback
  });
}


Cureus.Autosaver.prototype.syncCKEditor = function() {
  var emptyData = false;
  if (typeof CKEDITOR !== 'undefined') {
    for (instance in CKEDITOR.instances) {
      CKEDITOR.instances[instance].updateElement();
      if (CKEDITOR.instances[instance].getData() === '') { emptyData = true; }
    }
  }
  return emptyData;
}

// Used for autosaving article contents  in 1 minute intervals
Cureus.Autosaver.prototype.startAutoSave = function() {
  var self = this;
  var hidden, visibilityState, visibilityChange;
  if (typeof document.hidden !== "undefined") {
    hidden = "hidden", visibilityChange = "visibilitychange", visibilityState = "visibilityState";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden", visibilityChange = "msvisibilitychange", visibilityState = "msVisibilityState";
  }

  self.startInterval();
  self.addFlash();
  document.addEventListener(visibilityChange, function() {
    switch(document[visibilityState]) {
      case 'visible':
        self.startInterval()
        break;
      case 'hidden':
        self.stopInterval();
        break;
    }
  })
}

Cureus.Autosaver.prototype.stopInterval= function() {
  window.clearInterval(this.saveInterval)
  this.submitFormData(null, false);
}

Cureus.Autosaver.prototype.startInterval = function() {
  var self = this;
  self.saveInterval = setInterval(function() {
    self.submitFormData(self.toggleAlert, false);
  }, 60000);
}

Cureus.Autosaver.prototype.addFlash = function() {
  var savedFlash = document.createElement('div');
  savedFlash.innerHTML = "<div data-alert class='alert-box centered auto-saved-alert'>Draft auto-saved</div>";
  document.getElementById('flash_container').appendChild(savedFlash.firstChild);
}

Cureus.Autosaver.prototype.toggleAlert = function() {
  document.querySelector('.auto-saved-alert').toggleClass('active-alert');
  setTimeout(function() {
    document.querySelector('.auto-saved-alert').toggleClass('active-alert');
  }, 3000);
}