export const addColor = (count, row, cssClass, idx) => {
  const circle = document.createElement('span');
  const td = row.children[idx];
  circle.classList.add(...cssClass.split(' '));
  circle.textContent = count
  td.textContent = '';
  td.classList.add('circle-container')
  td.appendChild(circle);
};

export const addTextColor = (row, cssClass, idx) => {
  const td = row.children[idx];
  td.classList.add(cssClass)
}