var createReactClass = require('create-react-class');
import React from 'react';
import NewReferenceFields from './NewReferenceFields';

const NewReference = createReactClass({
  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  onSubmit: function(e) {
    var self = this;

    var $form = $(self.refs.form);
    var data = $form.serialize();

    $.post(self.props.createReferencePath, data, function(data) {
      self.props.onReferenceAdd();
      self.props.handleClose();
    }).fail (function(errors) {
        alert(JSON.parse(errors.responseText).errorMessage)
      })
    e.preventDefault();
  },

  render: function() {
    if(this.props.referenceFields.length > 0) {
      var AddReferenceWrapper =
        <div className='add-reference-wrap'>
          <form ref='form' acceptCharset='UTF-8' onSubmit={ this.onSubmit } method='post' className='custom new-reference'>
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
            <input type="hidden" name="type" value={ this.props.selected } />
            <NewReferenceFields referenceFields={ this.props.referenceFields } handleClose={ this.props.handleClose }/>
          </form>
        </div>
    };

    return (
      <div>
        { AddReferenceWrapper }
      </div>
    );
  }
});

export default NewReference;