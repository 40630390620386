import React, { useState, useEffect } from 'react';


const Clarification = ({clarification, articleId}) => {

  return (
    <div className="row">
      <div className='large-12 medium-12 small-12 columns clarification-container'>
        <div className='background'>
          <h2>Clarification</h2>
          <p className='clarification-text' dangerouslySetInnerHTML={{__html: clarification}}/>
          <div className='link-container'>
            <a className="button special" href={`/articles/${articleId}`}>View case presentation</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clarification;