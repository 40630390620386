import React, { useState, useEffect } from 'react';
import { HashRouter as Router } from "react-router-dom";
import NavItems from './NavItems';
import TopSection from './TopSection'
import LeftSidebar from './LeftSidebar/Sidebar';
import RightSidebar from './RightSidebar';
import Comments from '../CommentForm/Main';
import MoreAbout from './MoreAbout/MoreAbout';
import FurtherReading from './FurtherReading';
import ShareBar from './ShareBar';
import FeatureBar from './FeatureBar';
import { Cureus } from '../../Cureus';
import Siq from './LeftSidebar/Siq';
import Ad from '../Shared/Ad';

const Main = ({hasAttachments, article, articleHtml, tableOfContents, comments, currentUserId, metricsPaths, preview, retracted, heroImage, page, sidebarAd, bannerAdBottom, bannerAdTop, scrollComments, channelLogo, channelTitle, channelPath}) => {

  const [articleLikeCount, setArticleLikeCount] = useState(0);
  const [articleLikeId, setArticleLikeId] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [dataFetched, setDataFetched] = useState(false);
  const [showScrollButtonAndNav, setShowScrollButtonAndNav] = useState(false);
  const [showTopNavMobile, setShowTopNavMobile] = useState(false);
  const [showShareBarFixed, setShowShareBarFixed] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [articleRatingsAndSiq, setArticleRatingsAndSiq] = useState(null)
  const [siqWidget, setSiqWidget] = useState({})
  const [isUserSignedIn, setisUserSignedIn] = useState(null)
  const [showFeatureBar, setShowFeatureBar] = useState(false);
  const [showLikeOnShareBar, setShowLikeOnShareBar] = useState(false);
  const siqProps = {article, articleRatingsAndSiq, setArticleRatingsAndSiq, siqWidget, isUserSignedIn}


  useEffect(() => {
    Cureus.ListSlider.init();
    fetchArticleLikeCommentCount();
    fetchSIQData()
    window.addEventListener('scroll', toggleScrollButton);
    window.addEventListener('resize', () => handleResize(70));
    let loadAdjustment = preview || page !== 'article' ? 7 : 0
    setTimeout(function() {
      handleResize(70 + loadAdjustment)
    }, 500)
    if(typeof(bannerAdBottom) !== 'object') {
      $('#banner-ad-2').append(bannerAdBottom)
    }
    setTimeout(function() {
      addScriptTag();
    }, 1000)
  }, []);

  const addScriptTag = () => {
    const url = 'https://platform-api.sharethis.com/js/sharethis.js#property=5d51e46c4cd0540012f1ffd9&product=custom-share-buttons';
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", url);
    head.appendChild(script);
    return () => {
      document.body.removeChild(script)
    }
  }

  const handleResize = (offset) => {
    handleShowFeatureBar()
    const windowWidth = window.innerWidth;
    if (windowWidth < 640) {
      setShowTopNavMobile(true);
    } else {
      setShowTopNavMobile(false);
    }
    document.querySelector('.top-section').style.height = document.querySelector('.article-header-container').offsetHeight + offset + 'px';
  }

  const toggleScrollButton = () => {
    handleShowFeatureBar();
    handleFixedShareBar();
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 720) {
      setShowLikeOnShareBar(true);
      setShowScrollButtonAndNav(true);
    } else {
      setShowLikeOnShareBar(false);
      setShowScrollButtonAndNav(false);
    }
  }

  const handleFixedShareBar = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 250){
      setShowShareBarFixed(true);
    } else {
      setShowShareBarFixed(false);
    }
  }

  const handleShowFeatureBar = () => {
    if(document.documentElement.scrollTop > 720 && window.innerWidth < 640) {
      setShowFeatureBar(true)
    } else {
      setShowFeatureBar(false)
    }
  }

  const renderTopArticleNav = () => {
    if (showTopNavMobile || showScrollButtonAndNav) {
      return (
        <div className='top-article-nav'>
          <a href="/">
            <img className='blue-logo' src="https://public.cureus.com/cureus-nav-logo-actual.png" />
          </a>
          <div className="nav-links">
            <NavItems
              hasAttachments={hasAttachments}
              articleHtml={articleHtml}
              metricsPaths={metricsPaths}
              article={article}
              cssClass={"top-nav-links"}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              preview={preview}
              page={page}
              fireAmplitude={fireAmplitude}
              currentUserId={currentUserId}
              scrollComments={scrollComments}
            />
          </div>
          <div className='nav-buttons'>
            <div className='submit-container'>
              <a className={currentUserId ? 'round button no-mini submit-btn' : 'submit-link color-black'} href={'/publish/articles/new'}>
                <span>SUBMIT RESEARCH</span>
                {currentUserId && (
                  <img src="https://public.cureus.com/new-nav-bar/checkbox.png" alt="arrow right"/>
                )}
              </a>
            </div>
            {!currentUserId &&
              <>
                <div className='sign-in-container'>
                  <a className='sign-in-btn inter-font color-black' href="/users/sign_in">
                    SIGN IN
                  </a>
                </div>
                <div className='join-btn-container'>
                  <a className="button primary inter-font join-btn" href={'/registrations/sign_up'}>
                    JOIN NOW
                  </a>
                </div>
              </>
            }
          </div>
        </div>
      )
    }
  }

  const addPdfModalListener = (id) => {
    if(!currentUserId) {
      let pdfButton = document.getElementById(id)
      pdfButton.removeAttribute('target')
      pdfButton.href = '#'
      pdfButton.setAttribute('data-reveal-id', 'pdf-download-modal')
    }
  }

  const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  const fetchSIQData = () => {
    if (retracted) {return }
    fetch(`/articles/${article.id}/score/fetch_props`, {})
      .then(function(response) {
        return response.json()
      })
      .then(function(json) {
        setSiqWidget(json.siqWidget)
        setArticleRatingsAndSiq(json.articleRatingsAndSiq)
        setisUserSignedIn(json.isUserSignedIn)
      })
  }

  const fetchArticleLikeCommentCount = () => {
    $.ajax({
      type: 'GET',
      url: `/articles/${article.id}/fetch_likes_and_comments`,
      data: {user_id: currentUserId, article_id: article.id},
      success: function(res) {
        setArticleLikeCount(res.likeCount);
        setArticleLikeId(res.likeId);
        setCommentCount(res.commentCount);
        setDataFetched(true);
      }
    });
  }

  const fireAmplitude = (eventType, data) => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: {
              event_type: eventType,
              user_id: currentUserId,
              ...data
            }
    })
  }

  const renderBannerAd = (props, id) => {
    if(typeof(props) === 'object') {
      return (
        <div id={id}>
          <div className='cureus-ad-wrapper page-banner-ad' >
            <Ad
              props={ props }
              size={ "banner" }
            />
          </div>
        </div>
      )
    } else {
      return (
        <div id={id}/>
      )
    }
  }

  return (
    <div className="new-article-show">
      <div className="row small-collapse">
      <FeatureBar
        article={article}
        currentUserId={currentUserId}
        initialLikeId={articleLikeId}
        initialLikeCount={articleLikeCount}
        setArticleLikeId={setArticleLikeId}
        setArticleLikeCount={setArticleLikeCount}
        cssClass={showFeatureBar ? "show" : "hidden"}
        preview={preview}
        page={page}
        fireAmplitude={fireAmplitude}
        addPdfModalListener={addPdfModalListener}
        containerType={"featureBar"}
      />
      {showScrollButtonAndNav &&
        <div className={preview ? 'scroll-arrow-container preview' : 'scroll-arrow-container'} onClick={scrollToTop}>
            <img className="scroll-arrow" src="https://public.cureus.com/article-show-redesign/up-arrow-white.png" alt="up arrow"/>
        </div>
      }
      <Router hashType={"hashbang"}>
        <LeftSidebar
          page={page}
          tableOfContents={tableOfContents}
          article={article}
          preview={preview}
          retracted={retracted}
          fireAmplitude={fireAmplitude}
          sidebarAd={sidebarAd}
          {...siqProps}
        />
        <div className={"medium-12 small-12 columns end " +  (preview ? "large-10 large-offset-2" : "large-8")}>
          {renderTopArticleNav()}
          <TopSection
              html={articleHtml["hero"]["html"]}
              heroImage={heroImage}
              articleId={article.id}
              currentUserId={currentUserId}
              commentCount={commentCount}
              dataFetched={dataFetched}
              initialLikeId={articleLikeId}
              initialLikeCount={articleLikeCount}
              setArticleLikeId={setArticleLikeId}
              setArticleLikeCount={setArticleLikeCount}
              preview={preview}
              page={page}
              fireAmplitude={fireAmplitude}
              bannerAdTop={bannerAdTop}
              setActiveTab={setActiveTab}
              channelLogo={channelLogo}
              channelTitle={channelTitle}
              channelPath={channelPath}
            />
          <ShareBar
            article={article}
            preview={preview}
            fireAmplitude={fireAmplitude}
            addPdfModalListener={addPdfModalListener}
            currentUserId={currentUserId}
            initialLikeId={articleLikeId}
            initialLikeCount={articleLikeCount}
            setArticleLikeId={setArticleLikeId}
            setArticleLikeCount={setArticleLikeCount}
            page={page}
            showLikeOnShareBar={showLikeOnShareBar}
            showShareBarFixed={showShareBarFixed}
          />
          {renderBannerAd(bannerAdTop, 'banner-ad-1')}
          <NavItems
            hasAttachments={hasAttachments}
            articleHtml={articleHtml}
            metricsPaths={metricsPaths}
            article={article}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            preview={preview}
            page={page}
            fireAmplitude={fireAmplitude}
            currentUserId={currentUserId}
            scrollComments={scrollComments}
          />
          {page === "article" && !preview && activeTab === '/' && !retracted && <Siq size="medium" {...siqProps} fireAmplitude={fireAmplitude}/>

}
          {!preview && activeTab === '/' && page === 'article' && <div class="article-discussion-section tab-content-container" id="article-comments-section">
            <Comments
              {...comments}
            />
          </div>}
          {!preview && <MoreAbout
            keywords={article.keywords}
            fireAmplitude={fireAmplitude}
          />}
          {!preview && renderBannerAd(bannerAdBottom, 'banner-ad-2')}
          {!preview && <FurtherReading
            articleId={article.id}
            fireAmplitude={fireAmplitude}
          />}
        </div>
        {!preview && <RightSidebar article={article} fireAmplitude={fireAmplitude}/>}
      </Router>
      </div>
    </div>
  )
}

export default Main;