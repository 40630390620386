var createReactClass = require('create-react-class');
import React from 'react';

const FilterControl = createReactClass({
  handleClick: function(e) {
    this.props.onClick();
    e.preventDefault();
  },

  render: function (){
    return (
      <div className="email-list-filter-controls">
        <a className="button success" onClick={this.handleClick}>
          Filters
        </a>
      </div>
    )
  }
});

export default FilterControl;