import React from "react";

const SiqBadgeImage = ({siq, width="70px"}) => {
  const badgeWidth = () => {
    if (siq <= 5 || siq == "-" || siq == null) {
      return noRibbonBadgeWidth()
    } else {
      return width
    }
  }

  const noRibbonBadgeWidth = () => {
    if (width) {
      let num = width.split("px")[0]
      num = parseInt(num) - 15
      return `${num.toString()}px`
    }
  }

  const badgeUrl = () => {
    if (siq <= 5 || siq == "-" || siq == null) {
      return "https://public.cureus.com/article-show-redesign/siq-badge-hover.png"
    } else if (siq > 5 && siq <= 8) {
      return "https://public.cureus.com/article-show-redesign/siq-badge-hover-silver.png"
    } else if (siq > 8) {
      return "https://public.cureus.com/article-show-redesign/siq-badge-hover-gold.png"
    }
  }

  return (
    <img alt="SIQ" style={{width: badgeWidth()}} data-svg-fallback="https://public.cureus.com/siq_shield.png" src={badgeUrl()}/>
  )
}

export default SiqBadgeImage