jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "cureus_date-asc": function ( a, b ) {
        var x, y;
        if (jQuery.trim(a) !== '') {
            var deDatea = jQuery.trim(a).split('.');
            x = (deDatea[2] + deDatea[0] + deDatea[1]) * 1;
        } else {
            x = Infinity;
        }
 
        if (jQuery.trim(b) !== '') {
            var deDateb = jQuery.trim(b).split('.');
            y = (deDateb[2] + deDateb[0] + deDateb[1]) * 1;
        } else {
            y = Infinity;
        }
        var z = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        return z;
    },
 
    "cureus_date-desc": function ( a, b ) {
        var x, y;
        if (jQuery.trim(a) !== '') {
            var deDatea = jQuery.trim(a).split('.');
            x = (deDatea[2] + deDatea[0] + deDatea[1]) * 1;
        } else {
            x = Infinity;
        }
 
        if (jQuery.trim(b) !== '') {
            var deDateb = jQuery.trim(b).split('.');
            y = (deDateb[2] + deDateb[0] + deDateb[1]) * 1;
        } else {
            y = Infinity;
        }
        var z = ((x < y) ? 1 : ((x > y) ? -1 : 0));
        return z;
    }
} );
