import {Cureus} from '../Cureus';

Cureus.ModalCKEditor = (function($, _) {
  var init;

  init = function(options) {
    var $modal = $("#" + options.modalId);
    var $input = $("#" + options.inputId);

    $modal.on("open.fndtn.reveal", function(e) {
      //Stupid foundation - https://github.com/zurb/foundation/issues/5482
      if(e.namespace !== 'fndtn.reveal') { return; }
      new Cureus.CKEditor(options.inputId, options.ckeditor_options);
    });

    $modal.on("close.fndtn.reveal", function(e) {
      //Stupid foundation - https://github.com/zurb/foundation/issues/5482
      if(e.namespace !== 'fndtn.reveal') { return; }
      CKEDITOR.instances[options.inputId].destroy();
    });
  };

  return { init: init };
})(jQuery, _);
