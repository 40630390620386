var createReactClass = require('create-react-class');
import React from 'react';

const ConfirmationModal = createReactClass({
  confirm: function() {
    this.props.handleSubmit(this.props.message);
    $('#confirmation-modal').foundation('reveal', 'close');
  },

  render: function() {
    return(
      <div className="reveal-modal medium" data-reveal id="confirmation-modal">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Are you sure you wish to proceed?</h4>
            <p>
             This action cannot be undone.
            </p>
          </div>

          <div className="modal-footer">
            <a href="#" className="button secondary cancel-modal"> Cancel </a>
            <input type="button" className="button primary" name="commit" value="Proceed" onClick={ this.confirm } />
          </div>
        </div>
      </div>
    )
  }
})

export default ConfirmationModal;