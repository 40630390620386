import React, { useState, useEffect } from 'react';
import Modal from '../Modal';

const AddModal = ({topics, setTopics}) => {
  const [searchField, setSearchField] = useState("")
  
  const handleAdd = () => {
    let newTopics = [...topics, searchField]
    setTopics(newTopics)
    Modal.close();
  }
  
  const renderMessage = () => {
    if(searchField.length === 0) {
      return (
        <div className="row">
          <div className="small-12 columns">
            <span className="red-message">
              The Keyword field cannot be blank.
            </span>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="reveal-modal tiny" data-reveal id="trending-topics-add-modal">
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <div className="row">
            <div className="small-12 columns">
              <h4 className="text-center">Add Trending Topic</h4>
              <hr/>
              <p>
                Enter a new trending topic here. Click ‘View Articles’ to check for related articles before clicking the ‘Add’ button.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <div className="input-container">
                  <label>Keyword</label>
                  <input type="text" placeholder="Enter trending topic" onChange={(e) => setSearchField(e.target.value)}/>
              </div>
              <a href={`/articles?page=1&q=&order=%7B%22attr%22%3A%22_score%22%2C%22dir%22%3A%22desc%22%2C%22text%22%3A%22Relevance%22%7D&advanced=true&filters%5B0%5D%5Bboolean%5D=&filters%5B0%5D%5Bfield%5D=keywords&filters%5B0%5D%5Bquery%5D=${searchField}&obeySort=false`} target="_blank">View Articles <i class="fas fa-external-link-alt"/></a>
            </div>
          </div>
        </div>

        <div className="modal-footer text-center">
          <a className={"button " + (searchField.length === 0 ? 'disabled' : '')} onClick={handleAdd}>Add</a>
        </div>
        {renderMessage()}
      </div>
    </div>
  )
}

export default AddModal;