var createReactClass = require('create-react-class');
import React from 'react';
import Content from './Content';
import Select from 'react-select'

const Main = createReactClass({
  getInitialState: function() {
    return ({
      selectedId: this.props.categoryIds[0],
      resources: []
    })
  },

  componentDidMount: function() {
    this.fetchArticles();
  },

  changeSelectedId: function(e) {
    this.setState({selectedId: e.value}, function() {
      this.fetchArticles()
    })
  },

  fireAmplitude: function() {
    let articleIds = this.state.resources.map(function(res) {return res.id})
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: 'topArticlesDropdownClicked', 
              user_id: this.props.currentUserId,
              specialty: this.props.categoryMap[this.state.selectedId],
              articleIds: articleIds.toString()
            }
    })
  },

  fetchArticles: function() {
    var self = this
    $.ajax({
      type: 'get',
      url: '/featured_articles',
      dataType: 'JSON',
      data: {
        categoryId: this.state.selectedId
      },
      success: function(res) {
        self.setState({resources: res.data}, function() {
          if(this.state.selectedId != 0) {
            self.fireAmplitude()
          }
        })
      }
    })
  },

  renderCategorySelector: function() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#1395B7' : 'black',
        fontSize: '14px',
        fontWeight: 'bold',
        background: state.isSelected ? '#E3ECF2'  : 'white',
        cursor: 'pointer',
        "&:hover": {
          background: "#E3ECF2"
        }
      }),
      container: (provided) => ({
        ...provided,
        display: 'inline-block',
        position: 'relative',
        bottom: '7px'
      }),
      control: (provided, state) => ({
        ...provided,
        color: '#1395B7',
        fontSize: '14px',
        fontWeight: 'bold',
        width: '200px',
        background: 'white',
        height: '32px',
        border: state.isFocused ? "1px solid #1395B7" : "1px solid #cccccc",
        boxShadow: 'none',
        "&:hover": {
          border: "1px solid #1395B7",
          boxShadow: "none"
        }
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: '32px',
        padding: '0 6px'
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: "#1395B7",
        fontSize: '14px',
        "&:hover": {
          color: "#1395B7"
        }
      }),
      input: (provided, state) => ({
        ...provided,
        margin: '0px',
      }),
      indicatorSeparator: state => ({
        display: 'none',
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '32px',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#1395B7',
        paddingBottom: '18px',
        paddingLeft: '5px'
      }),
      menuList: (provided) => ({
        ...provided,
    
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px"
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1395B71f1"
        },
        "::-webkit-scrollbar-thumb": {
          background: "#1395B7"
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#1395B7"
        }
      })
    }
    return <Select 
              options={this.props.selectOptions} 
              onChange={this.changeSelectedId}
              defaultValue={{ label: "All Specialties", value: 0 }}
              styles={customStyles}
              isSearchable={false}
            />
  },

  render: function() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="small-12 columns">
            <div className="featured-header-container">
              <h3>Top Articles <img className="question-mark-icon tip-top" src="https://public.cureus.com/homepage_new/question_mark.png" data-tooltip="" title="Eligibility is determined by Cureus editors, updated constantly." /></h3>
              {this.renderCategorySelector()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns sidebar-padding">
          <Content
              currentUserId={this.props.currentUserId}
              selected={this.props.categoryMap[this.state.selectedId]}
              selectedId={this.state.selectedId}
              resources={this.state.resources}
              categoryUrl={this.props.categoryUrls[this.state.selectedId]}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
})

export default Main;