var createReactClass = require('create-react-class');
import React from 'react';
import ReactDOM from 'react-dom';
// import $ from 'jquery';

const Modal = createReactClass({
  statics: {
    open: function(props) {
      var $anchor = $('#react-modal');

      if (!$anchor.length) {
        $anchor = $('<div></div>')
          .prop('id', 'react-modal')
          .appendTo('body');
      }
      ReactDOM.render(
        React.createElement(Modal, props),
        $anchor.get(0),
        function(){
          $anchor.find('.reveal-modal').foundation('reveal', 'open', {root_element: '#react-modal'})
          $anchor.on('close.fndtn.reveal', '.reveal-modal', function() {
            ReactDOM.unmountComponentAtNode($anchor.get(0));
          });
        }
      );
    },

    close: function(props) {
      if(props !== undefined){
        if (props.onClose !== undefined){
          props.onClose().done(
            function(){ $("#react-modal .reveal-modal").foundation("reveal", "close"); }
          );
        } else if(props.onCloseInstant !== undefined) {
          props.onCloseInstant();
          $("#react-modal .reveal-modal").foundation("reveal", "close");
        }
      }else{
        $("#react-modal .reveal-modal").foundation("reveal", "close");
      }
    }
  },

  render: function() {
    return this.props.children;
  }
});

export default Modal;
