import React, { useState, useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import LikeButton from '../Shared/LikeButton';
import { useHistory } from "react-router-dom";

const TopSection = ({html, heroImage, articleId, currentUserId, dataFetched, commentCount, initialLikeId, initialLikeCount, setArticleLikeCount, setArticleLikeId, preview, page, fireAmplitude, bannerAdTop, setActiveTab, channelLogo, channelTitle, channelPath}) => {
  const history = useHistory();

  useEffect(() => {
    if(!preview && page === 'article') {
      if(dataFetched) {
        ReactDOM.render(renderLikeAndCommentSection(), document.getElementById('like-button-container'));
      }
    } else {
      let ele = document.getElementById('like-button-container')
      if(ele) {
        ele.remove()
      }
    }
  }, [dataFetched, initialLikeId]);

  useEffect(() => {
    if(dataFetched) {
      if(channelTitle) { 
        let selector = document.querySelector('.channels-container')
        if (selector) {
          ReactDOM.render(renderChannelBadge(), selector);
        }
      }
    }
  }, [dataFetched]);

  useEffect(() => {
    ReactDOM.render(renderHeroImage(), document.getElementById('hero-background-image-container'));
    addPublishedDateHandler()
    addDoiCopy();
    if(typeof(bannerAdTop) !== 'object') {
      $('#banner-ad-1').append(bannerAdTop)
    }
  }, []);

  const addPublishedDateHandler = () => {
    let element = document.getElementById('published-date')
    if(element) {
      element.addEventListener('click', function() {
        scrollToArticleInfo()
      })
    }
  }

  const scrollToArticleInfo = () => {
    setActiveTab('/authors')
    history.push("/authors")
    setTimeout(function() {
      const element = document.getElementById("article-info-section");
      const y = element.getBoundingClientRect().top + window.pageYOffset - 70;
      window.scrollTo({top: y, left: 0, behavior: 'smooth'});
    }, 500)
  }


  const addDoiCopy = () => {
    document.getElementById('doi-copy').addEventListener('click', function(e) {
      navigator.clipboard.writeText(e.target.dataset.doi)
    })
  }

  const renderHeroImage = () => {
    return (
      <img 
        className='hero-image' 
        src={heroImage}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src="https://public.cureus.com/article-show-default-pic/1-default-image.jpg"
        }}
      />
    )
  }

  const renderLikeAndCommentSection = () => {
    return (
      <div className="row reaction-section reaction-position" id="top-section-like">
        <LikeButton key={"topSection" + initialLikeId}
          currentUserId={currentUserId}
          likableId={articleId}
          initialLikeId={initialLikeId}
          initialLikeCount={initialLikeCount}
          setArticleLikeCount={setArticleLikeCount}
          setArticleLikeId={setArticleLikeId}
          containerType="topSection"
          likableType={"Article"}
        />
        <div className={"like-icon-container top-section-container likes " + (commentCount === 0 ? 'like-center' : '')} onClick={scrollToCommentSection}>
          <img
            className='has-tip tip-top icon-size'
            src="https://public.cureus.com/article-show-redesign/chat-icon-4x.png"
            data-tooltip=""
            title="Comment"
          />
          <p onClick={scrollToCommentSection} className='has-tip tip-top' data-tooltip="" title="View comments">{commentCount > 0 && commentCount}</p>
        </div>
      </div>
    )
  }

  const renderChannelBadge = () => {
    if(channelTitle) {
      return (
        <div className="channel-info">
          <a href={channelPath}>
            <img src={channelLogo} className="channel-logo"/>
          </a>
          <div className="channel-name-container">
            <a href={channelPath}>
              <span className="channel-name">
                Published via {channelTitle}  
              </span>
            </a>
          </div>
        </div>
      )
    }
  }

  const scrollToCommentSection = () => {
    fireAmplitude("scrolledToComments", {article_id: articleId, source: 'icon'})
    setActiveTab('/')
    history.push("/")
    setTimeout(function() {
      document.getElementById('comments_list').scrollIntoView()
    }, 500)
  }

  return (
    <div className="row small-collapse top-section">
      <div className="small-12 columns">
        <div dangerouslySetInnerHTML={{__html: html}}/>
      </div>
    </div>
  )
}

export default TopSection;