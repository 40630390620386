var createReactClass = require('create-react-class');
import React from 'react';

const Header = createReactClass({
  getInitialState: function() {
    return {
      people: this.props.people ? "selected" : "selection",
      posters: this.props.posters ? "selected" : "selection",
      articles: this.props.articlesPage ? "selected" : "selection"
    }
  },

  render: function() {
    return(
      <div className="small-12 columns centered">
        <div className="small-12 medium-10 medium-offset-1 small-offset-0 large-6 large-offset-3 columns">
          <div className="row">
            <div className="header-container">
            <a href={this.props.articlesPath}>
                <div className="small-4 columns">
                  <div className={this.state.articles}>
                    <i className="far fa-file-alt" aria-hidden="true"></i>
                    <h3 className="reg">ARTICLES</h3>
                  </div> 
                </div>
              </a>
              <a href={this.props.peoplePath}>
                <div className="small-4 columns">
                  <div className={this.state.people}>
                    <i className="fa fa-users" aria-hidden="true"></i>
                    <h3 className="reg">PEOPLE</h3>
                  </div>
                </div>
              </a>
              <a href={this.props.postersPath}>
                <div className="small-4 columns">
                <div className={this.state.posters}>
                    <i className="far fa-file-image" aria-hidden="true"></i>
                    <h3 className="reg">POSTERS</h3>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <hr/>
      </div>
    )
  }
})

export default Header;