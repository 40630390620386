var createReactClass = require('create-react-class');
import React from 'react';

const InReview = createReactClass({
  renderAddReviewers: function() {
    if (!this.props.article.competition_id && !this.props.article.authorReviewEnded) {
      return (
        <li><a onClick={this.props.handleInvite}>Invite reviewers</a></li>
      )
    }
  },

  renderButton: function() {
    if(this.props.article.actionButtonText === "Preview"){
      return(
        <div className="cureus-split-dropdown-wrap" >
          <a href={this.props.article.previewPath} className="button secondary split has-tip tip-top view-comments" data-cureus-split-dropdown={"action-buttons-" + this.props.article.id} target="_blank" id={"action-dropdown-view-tooltip-" + this.props.article.id}>
            {this.props.article.actionButtonText}
            <span/>
          </a>
          <ul className="cureus-split-dropdown" id={"action-buttons-" + this.props.article.id}>
            {this.renderEndAuthorReviews()}
            {this.renderAddReviewers()}
            <li>
              <span className='has-tip tip-top disabled' data-tooltip="" title="You cannot edit your article until all required reviews are complete." id={"action-dropdown-edit-tooltip-" + this.props.article.id}>
                <i className="fa fa-lock"></i> Edit Article
              </span>
            </li>
            {this.props.renderEmailEditingReceipt()}
          </ul>
        </div>
      )
    } else {
      return (
        <div className="cureus-split-dropdown-wrap" >
          <a href={this.props.article.articlePath} className="button secondary split has-tip tip-top view-comments" data-cureus-split-dropdown={"action-buttons-" + this.props.article.id} data-tooltip="" title="View the article in our reviewing tool. Opens in a new tab." target="_blank" id={"action-dropdown-view-tooltip-" + this.props.article.id}>
            {this.props.article.actionButtonText}
            <span/>
          </a>
          <ul className="cureus-split-dropdown" id={"action-buttons-" + this.props.article.id}>
            {this.renderEndAuthorReviews()}
            <li>
              <a target="_blank" href={this.props.article.previewPath}>Preview</a>
            </li>
            {this.renderAddReviewers()}
            <li>
              <span className='has-tip tip-top disabled' data-tooltip="" title="You cannot edit your article until all required reviews are complete." id={"action-dropdown-edit-tooltip-" + this.props.article.id}>
                <i className="fa fa-lock"></i> Edit Article
              </span>
            </li>
            {this.props.renderEmailEditingReceipt()}
          </ul>
        </div>
      )
    }
  },

  renderEndAuthorReviews: function() {
    if(this.props.article.canEndAuthorReviews) {
      return (
        <li>
          <a href='#' id={"action-dropdown-end-review-tooltip-" + this.props.article.id}>
            End author-invited review
          </a>
        </li>
      )
    }
  },
  
  render: function() {
    return(
      <div>
        {this.renderButton()}
      </div>
    )
  }
})

export default InReview;