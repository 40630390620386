var createReactClass = require('create-react-class');
import React from 'react';

const SubscribedFilter = createReactClass({
  getDefaultProps: function() {
    return {
      subscribed: true,
      options: [
        {
          label: "Subscribed",
          value: true
        },
        {
          label: "Unsubscribed",
          value: false
        }
      ]
    }
  },

  getValue: function() {
    if(this.props.subscribed == true) {
      return "Subscribed";
    } else {
      return "Unsubscribed";
    }
  },

  handleChange: function(value) {
    this.props.onChange(value);
  },

  render: function() {
    return (
      <Select
        value={this.getValue()}
        options={this.props.options}
        onChange={this.handleChange}
        clearable={false}
        searchable={false}
        />
    )
  }
});

export default SubscribedFilter;