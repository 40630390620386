var createReactClass = require('create-react-class');
import React from 'react';
import FieldSet from './FieldSet';
import Modal from '../Modal';
import ReferenceMetadataModal from './ReferenceMetadataModal';

const EditReference = createReactClass({
  getInitialState: function() {
    return {
      referenceFields: [],
      oldFieldData: "",
      selected: '',
      revertDisabled: false
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  handleUpdate: function(e) {
    var self = this;
    var $form = $(self.refs.form);

    $.post(self.props.reference.update_path, $form.serialize(), function(data) {
      self.props.handleUpdate();
    });

    e.preventDefault();
  },

  handleCancel: function(e) {
    this.props.handleCancel();
    e.preventDefault();
  },

  handleCheck: function() {
    this.props.handleCheck();
  },

  handleDelete: function(e) {
    this.props.handleDelete();
    e.preventDefault();
  },

  handleChangeType: function(val) {
    var self = this;
    var newFieldData = _.map(this.props.fieldData[val], function(field) {
      var existingField = self.props.reference.fields.find(ele => ele.name == field.name)
      if (existingField) {
        field.value = existingField.value
      }        
      return field
    })
    this.setState({
      referenceFields: newFieldData,
      selected: val
    });
  },

  handlePositionChange: function (e){
    var newPosition = $(this.refs.position).val();
    if(newPosition != this.props.reference.position) {
      this.props.handleUpdatePosition(this, newPosition);
    }
  },

  componentDidMount: function() {
    var oldFieldData = _.map(this.props.reference.fields, function(field) {
      return field.value;
    }).join(" ");
    var newState = {
      referenceFields: this.props.reference.fields,
      selected: this.props.reference.type,
      oldFieldData: oldFieldData
    }
    if (this.props.reference.omit_doi !== undefined) {
      newState.omitDoi = this.props.reference.omit_doi;
    }

    this.setState(newState);
    $(this.refs.position).numeric({
      decimal: false
    });
  },

  referenceIncludesDoi: function() {
    return this.state.referenceFields.some(function(field){
      return field.name === 'doi';
    });
  },

  renderDoiCheckbox: function() {
    if (this.referenceIncludesDoi()) {
      return(
        <div className='row'>
          <label htmlFor='omit_doi_box' style={{paddingLeft: '8px'}}>
            <input className='doi-checkbox' style={{marginRight: '6px'}} onChange={this.toggleCheckboxValue} type='checkbox' id='omit_doi_box' value={this.state.omitDoi} checked={this.state.omitDoi ? 'checked' : ''} />
            <input type='hidden' id='omit_doi' name='reference[omit_doi]' value={this.state.omitDoi} />
            I confirm that neither a DOI nor URL is available for this reference, and that it is my responsibility to provide the appropriate citation credit.
          </label>
         </div>
       )
    }
  },

  toggleCheckboxValue: function(e) {
    this.setState({omitDoi: (e.target.checked ? true : false)});
  },

  fetchDoiMetadata: function(doi, callback) {
    var self = this;
    $.post(this.props.fetchParsedReferencePath, { reference_id: this.props.reference.id, doi: doi, reference_type: this.state.selected }, function(data) {
      callback();
      Modal.open({
        children: <ReferenceMetadataModal 
          doi={ doi }
          originalReferenceFields={ self.state.referenceFields }
          referenceId={ self.props.reference.id }
          doiMetadata={ data }
          updatePath={ self.props.reference.update_path }
          handleUpdate={ self.props.handleUpdate }
          fetchParsedReferencePath={ self.props.fetchParsedReferencePath }
          type={ self.state.selected }
          scrollOnFinishEdit={self.props.scrollOnFinishEdit}
        />
      })
    })
    .fail(function() {
      callback();
      Modal.open({
        children: <ReferenceMetadataModal 
          doi={ doi }
          doiMetadata={ { request_failed: true } }
          />
      })
    })
  },

  handleRevert: function() {
    var self = this;
    if (window.confirm("Restore original reference? The values will be restored to what they were at the end of approval editing.")) {
      this.setState({revertDisabled: true})
      $.post(this.props.revertReferencePath, { reference_id: this.props.reference.id }, function(data) {
        this.setState({revertDisabled: false}, self.props.handleUpdate());
      })
    }
  },

  renderRevertButton: function() {
    if (this.props.isEditor && this.props.parsedReferences && this.props.reference.fields_changed && this.props.reference.fields_changed.num_changed > 0) {
      return <a className='button small alert pull-left' onClick={ this.handleRevert } disabled={ this.state.revertDisabled }>Revert To Original</a>
    }
  },

  render: function() {
    var oldFieldData =
      <div className='row'>
        <div className='large-12 columns'>
          <hr/>
          <label>Original data</label>
          <textarea value={ this.props.reference.original } type='text' className="reference-original-data"/>
        </div>
      </div>

    return (
      <tr id={"reference-row-" + this.props.reference.id}>
        <td/>
        <td className='table-checkbox'>
          <input type='checkbox' checked={ this.props.checked } onChange={ this.handleCheck }/>
        </td>

        <td style={{textAlign: "center"}}>
          <input type='number'
            defaultValue={this.props.reference.position}
            style={{width: "60px"}}
            ref='position'
            onBlur={ this.handlePositionChange }/>
        </td>

        <td colSpan="2">
          <form ref='form' acceptCharset='UTF-8' className='custom' method='post'>
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
            <input name="_method" type="hidden" value="put" />

            <div className='row'>
              <div className='large-12 columns'>
                <Select ref='select' name='reference[type]' value={ this.state.selected } options={ this.props.optionsForReferenceSelect } onChange={ this.handleChangeType } placeholder='Select reference type' clearable={ false } searchable={ false }/>
              </div>
            </div>

            <FieldSet referenceFields={ this.state.referenceFields } isEditor={ this.props.isEditor } fetchDoiMetadata={ this.fetchDoiMetadata } invalidParsedReference={ this.props.reference.invald_parsed_reference } parsedReferences={ this.props.parsedReferences }/>
            {this.renderDoiCheckbox()}
            { oldFieldData }
          </form>
          <br/>
          { this.renderRevertButton() }
          <a className='button small success pull-right' onClick={ this.handleUpdate }>
            Update
          </a>
          <a className='button small secondary pull-right cancel' onClick={ this.handleCancel }>
            Cancel
          </a>
        </td>
      </tr>
    )
  }
});

export default EditReference;