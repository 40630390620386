import React from 'react';

const PlagiarismMisconductAndEthics = () => {
  return (
    <React.Fragment>
      <h1>Plagiarism, Scientific Misconduct and Publication Ethics</h1>

      <h2>Plagiarism and Duplicate Publication</h2>
      <p>Plagiarism is defined by <a href='https://publicationethics.org/category/keywords/plagiarism' target='_blank'>The Committee on Publication Ethics (COPE)</a> as “When somebody presents the work of others (data, words or theories) as if they were his/her own and without proper acknowledgment.” All article submissions are automatically analyzed for plagiarism and text duplication by <a href='https://www.ithenticate.com/' target='_blank'>Crossref Similarity Check Powered by iThenticate</a>. Cureus editorial staff closely reviews the results to determine if plagiarism or duplicate publication is suspected.</p>
      <p>Duplicate publication involves substantial overlap with an already published article (including the author's own work), without clear, visible reference to the previous publication. Authors who attempt duplicate publication without including the proper reference should expect prompt article rejection.</p>

      <h2>Addressing Suspected Scientific Misconduct</h2>
      <p>Allegations of scientific misconduct, whether brought forth during editorial review, peer review or after publication, will be thoroughly investigated by the Cureus editorial office. This includes contacting the authors for their response to these allegations and, in cases of plagiarism, unethical research practices, and data or figure manipulation/falsification/fabrication, notifying the authors' institutions to request an independent investigation. It is the responsibility of the authors' institution to lead such an investigation as they are best positioned to uncover all related facts and details. Cureus will always provide as much assistance as possible in said investigations. Upon the investigating institution's confirmation of misconduct (or the journal's confirmation if the institution will not investigate), the relevant articles will be retracted (or blocked if not yet published) and offending authors permanently suspended from Cureus.</p>
      <p>In addition to the misconduct mentioned above, Cureus does not tolerate copyright infringement, authorship disputes or inaccurate attributions, attempts to inappropriately manipulate the peer review process, failures to declare conflicts of interest, knowingly providing incorrect information, fraud or libel. These may lead to further investigation, article retraction or correction, and permanent account suspension as detailed in the prior paragraph.</p>
      
      <p>The forms of misconduct listed in this section should not be considered exhaustive – those with additional questions should investigate additional resources found at the <a href='https://www.wame.org/' target='_blank'>World Association of Medical Editors (WAME)</a>, the <a href='https://publicationethics.org/' target='_blank'>Committee on Publication Ethics (COPE)</a> or the <a href='https://www.icmje.org/' target='_blank'>International Committee of Medical Journal Editors (ICMJE)</a>.</p>
      <p>Any complaints or tips regarding the above should be directed to <a href='mailto:info@cureus.com'>info@cureus.com</a>.</p>

      <h2>Publishing Sanctions</h2>
      <p>Cureus adheres to EU, UK, US and other laws and regulations by prohibiting transactions (including free publication) with any people or entities located in the following countries under geographic sanctions: North Korea, Crimea, Luhansk, Donetsk.</p>
      <p>Also prohibited are transactions with specific people or entities listed on the EU Consolidated List, the UK Consolidated List, and US Specially Designated Nationals or Blocked Persons list.</p>

      <h2>Complaints Against the Journal</h2>
      <p>Cureus is committed to providing fair and equitable service and opportunities to all readers, authors, reviewers and users. If, for any reason, you are dissatisfied with the performance or actions of the journal or its representatives, please contact us at <a href='mailto:support@cureus.com'>support@cureus.com</a>. We take all complaints seriously and strive to ensure that all complaints are resolved satisfactorily.</p>

    </React.Fragment>
  )
}

export default PlagiarismMisconductAndEthics