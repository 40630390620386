import React, {useEffect} from 'react';
import Channel100Card from './Channel100Card';

const Article100Card = ({content, handleDeleteWidget, handleUpdateWidget, mainContentWidgets, slug}) => {
  return (
  <div className="row hundred-percent-section">
    <div className="large-12 columns hundred-percent-container">
      <Channel100Card
        setVisibleCardType={()=>{}}
        content={content}
        handleDeleteWidget={handleDeleteWidget}
        handleUpdateWidget={handleUpdateWidget}
        mainContentWidgets={mainContentWidgets}
        slug={slug}
      />
    </div>
  </div>
  )
}

export default Article100Card;