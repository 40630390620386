import React, {useState, useEffect} from 'react';
import Table from './Table';

const PendingArticleRow = ({collection}) => {

  const [pendingArticlesData, setPendingArticlesData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    $.ajax({
      type: 'GET',
      url: `/collections/${collection.id}/cureus_collection_articles`,
      success: function(data) {
        setPendingArticlesData(data.articles);
      }
    });
  }

  return (
    <React.Fragment>
      <div className="articles-container">
        <Table 
          path={`/collections/${collection.id}/cureus_collection_articles`} 
          label={collection.title}
          card_url={collection.card_url}
          collectionId={collection.id}
          pendingArticlesData={pendingArticlesData}
          fetchData={fetchData}
        />
      </div>
    </React.Fragment>
  )
}

export default PendingArticleRow;