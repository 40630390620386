import React, {useState} from 'react';

const Tab = ({type, src, srcActive, title, activeTab, setCategory}) => {

  const [hovered, setHovered] = useState(false);

  return (
    <li onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}} className={"tab " + (activeTab == type ? 'active' : '')} onClick={() => {setCategory([type])}}>
      <img src={hovered || activeTab === type ? srcActive : src} />
      <span>{title}</span>
    </li>
  )
}

export default Tab;