var createReactClass = require('create-react-class');
import React from 'react';
import Next from './Next';
import Prev from './Prev';
import Range from './Range';

const Nav = createReactClass({
  render: function(){
    return (
      <div id="howto-nav">
        <Prev disabled={ this.props.prevState } handlePrev={ this.props.handlePrev }/>
        <Range pageIndex={ this.props.currentPageNum} totalPages={ this.props.pagesTotal } />
        <Next disabled={ this.props.nextState } handleNext={ this.props.handleNext }/>
      </div>
    );
  }
});

export default Nav;