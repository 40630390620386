import React, { useState, useEffect } from 'react';
import RadioInput from './RadioInput';
import TextField from './TextField';
import SizeInput from './SizeInput';

const CustomContentModalInputButtons = ({setContentType, contentType, setContentTypeId, contentTypeIdFound, setContentTypeSize, contentTypeSize, textFieldValue, handleTextFieldChange, uniqueName}) => {
  return (
    <>
      <RadioInput
        contentType={contentType}
        setContentType={setContentType}
        uniqueName={uniqueName}
      />
      {contentType === uniqueName &&
        <>
          <TextField
            textFieldValue={textFieldValue}
            handleTextFieldChange={handleTextFieldChange}
            uniqueName={uniqueName}
            setContentTypeId={setContentTypeId}
            contentTypeIdFound={contentTypeIdFound}
          />
          <SizeInput
            contentTypeSize={contentTypeSize}
            setContentTypeSize={setContentTypeSize}
            uniqueName="50%"
            buttonValue="FiftyPercent"
          />
          <SizeInput
            contentTypeSize={contentTypeSize}
            setContentTypeSize={setContentTypeSize}
            uniqueName="100%"
            buttonValue="OneHundredPercent"
          />
        </>
      }
    </>
  )
}

export default CustomContentModalInputButtons;