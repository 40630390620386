import React, { useState, useEffect } from 'react';
import WallOfShameRow from './WallOfShameRow';

const List = ({wallOfShamePath}) => {
  const [wallOfShameEntries, setWallOfShameEntries] = useState([])
  const [sortUp, setSortUp] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const sort = (e) => {
    setSortUp(!sortUp)
  }

  const fetchData = () => {
    $.ajax({
      type: 'GET',
      url: wallOfShamePath,
      success: function(data) {
        setWallOfShameEntries(data)
      }
    });
  }

  const renderWallOfShameList = () => {
    return wallOfShameEntries.sort((a,b) => {
      if(a.date_added_sort < b.date_added_sort) return (sortUp ? -1 : 1);
      if(a.date_added_sort > b.date_added_sort) return (sortUp ? 1 : -1);
      return 0
    })
    .map(function(wallOfShameEntry) {
      return <WallOfShameRow data={wallOfShameEntry} key={wallOfShameEntry.id} />
    })
  }
  
  return (
    <div className="large-12 columns">
      <div className="row info-row">
        
        <div className="small-6 columns ordering-container" onClick={(e) => sort(e)}>
          <span className="arrows-container">
            <img className={`${sortUp ? 'remove' : ''}`} src="https://public.cureus.com/wall_of_shame/white-arrow.png" alt="" />
            <img className={`rotate ${sortUp ? '' : 'remove'}`} src="https://public.cureus.com/wall_of_shame/red-arrow.png" alt="" />
            <img className={`rotate ${sortUp ? '' : 'remove'}`} src="https://public.cureus.com/wall_of_shame/white-arrow.png" alt="" />
            <img className={`${sortUp ? 'remove' : ''}`} src="https://public.cureus.com/wall_of_shame/red-arrow.png" alt="" />
          </span>
          <span>Sort by date added</span>
        </div>
        
        <div className="small-6 columns num-users">
          <span>{wallOfShameEntries.length} <span className="hide-for-medium-up">results</span><span className="hide-for-small">Users and Organizations</span></span>
        </div>

      </div>

      {renderWallOfShameList()}
    </div>
  )

}

export default List;