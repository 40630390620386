import React, { useState, useEffect } from 'react';

const TwitterSidebarModal = ({handleAddWidget, widgetSidebarId, widget={}, handleUpdateWidget, slug}) => {

  const [twitterProfileName, setTwitterProfileName] = useState(widget.blurb || '');

  const shouldDisable = () => {
    return !twitterProfileName.length ? 'disabled' : '';
  }

  const handleAddWidgetWrapper = () => {
    let params;
    params = {
    widget: {
        type: "Widget::TwitterSidebar",
        parent_id: widgetSidebarId,
        blurb: twitterProfileName,
      }
    }
    if(handleAddWidget) {
      handleAddWidget(params, false)
    } else {
      handleUpdateWidget(widget.id, params, false)
    }
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Twitter feed</h5>
            <hr/>
          </div>

          <div className="row">
            <div className="small-12 columns">
              <p>Embed your organization’s Twitter feed directly onto your channel homepage. Enter the desired Twitter profile name without the @ symbol.</p>
            </div>
          </div>

          <div className="row">
            <div className="small-12 columns">
              <div className="input-container">
                <label>Twitter profile name</label>
                <input type="text" value={twitterProfileName} onChange={(e) => setTwitterProfileName(e.target.value)}/>
              </div>
            </div>
          </div>

        </div>
          <div className='modal-footer text-center'>
            <a className={"button primary " + shouldDisable()} onClick={handleAddWidgetWrapper}>Add</a>
          </div>
      </div>
    </div>
  )
}


export default TwitterSidebarModal;