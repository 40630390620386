import React, {useState, useEffect} from 'react';

const ArticleCard = ({article}) => {

  const [authors, setAuthors] = useState('')
  const [authorsOverflow, setauthorsOverflow] = useState(null)

  useEffect(() => {
    let authorList = article.authors.split(',')
    setAuthors(authorList.slice(0, 2).join(', '))
    let overflow = authorList.slice(2).length
    if(overflow > 1) {
      setauthorsOverflow(overflow)
    }
  }, [])

  const renderAuthorList = () => {

    let moreAuthorsLink = ""
    
    if(authorsOverflow) {
      moreAuthorsLink = <a href={article.path + '#!/authors'} target="_blank"><span>+{authorsOverflow} more</span></a>
    }

    return (
      <div className='card-authors-container'>
        {authors}
        {moreAuthorsLink}
      </div>    
    )
  }

  return (
    
      <div className="collection-article-card large-6 medium-6 small-12">
        <div className='new-badge'>New</div>
        <a className="link-collection-article" href={article.path} target='_blank'>
          <div className="black-dot" dangerouslySetInnerHTML={{__html: article.top_card_image}}/>
          <div className='card-info'>
            <div className="card-header">
              <p><b>{article.type}</b></p>
            </div>
            <h6 className="article-card-title">{article.title}</h6>
            {renderAuthorList()}
          </div>
        </a>
      </div>
  )
}

export default ArticleCard;