import React from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';
import { addTextColor } from './UtilityFunctions';


const ActivityLog = ({userEmail, checkboxObj}) => {

  const generalActivityColumns = [
    checkboxObj,
    { 
      title: 'Editor',
      data: 'full_name',
      width: '140px',
      orderable: true,
      render: (row) => {
        return `<a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>`
      }
    },
    { title: 'Contribution volume', data: 'contribution_volume', class: 'centered', tooltip: 'Approved + Rejected' },
    { title: 'Turnaround time (days)', data: 'turnaround_time', class: 'centered', tooltip: 'Avg elapsed time from claim to completion Target: 0-24 hrs Close enough: 25-72 hrs Problematic: >72 hrs' },
    { title: 'Accepted', data: 'accepted', class: 'centered' },
    { title: 'Rejected', data: 'rejected', class: 'centered' },
    { title: 'Revisions required', data: 'deferred', class: 'centered' },
    { title: 'Requested Re-review', data: 're_review', class: 'centered' },
  ];

  const otherActivityColumns = [
    { 
      title: 'Editor',
      data: 'full_name',
      width: '140px',
      orderable: true,
      render: (row) => {
        return `<a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>` 
      }
    },
    { title: 'Peer Review Rejected', data: 'peer_review_rejected', class: 'centered' },
    { title: '# Msg Board Comments', data: 'msg_board_comments', class: 'centered' },
    { title: 'Email to Author', data: 'email_to_author', class: 'centered' },
    { title: 'Total view versions clicked', data: 'total_view_versions_clicked', class: 'centered' },
    { title: '% Articles previewed', data: 'articles_viewed_percent', class: 'centered', tooltip: "% of articles previewed out of total article claims" }
  ];

  const matchedUnmatchedColumns = [
    { 
      title: 'Editor',
      data: 'full_name',
      width: '140px',
      orderable: true,
      render: (row) => {
        return `<a class='column-hoverable' id=${row.id} target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>` 
      }
    },
    { title: 'Completed matches', data: 'completed_matches', class: 'centered' },
    { title: 'Completed unmatches', data: 'completed_unmatches', class: 'centered' },
    { title: 'Completed zero matches', data: 'completed_zero_matches', class: 'centered', tooltip: "Article did not match with any AE" },
    { title: '% Matched completions', data: 'match_completion_ratio', class: 'centered', tooltip: "% of matched articles out of total article completions" },
    { title: '% Unmatched completions', data: 'unmatched_completion_ratio', class: 'centered', tooltip: "% of unmatched articles out of total article completions" }
  ];

  const rowRenderCallback = (row, data) => {
    colorizeTurnAroundTime(data, row);
  }

  const colorizeTurnAroundTime = (data, row) => {
    let cssClass;
    let hours = parseInt(data[3].split(' ')[0]);
    if (hours >= 24 && hours <= 72) {
      cssClass = "orange-text";
    } else if (hours > 72 ) {
      cssClass = "red-text";
    }

    if (cssClass) {
      addTextColor(row, cssClass, 3);
    }
  }

  return (
    <>
      <DynamicJqueryTable
        columnsConfig={generalActivityColumns}
        path="/admin/ae_dashboards/general_activity"
        tableId="general-activity"
        tableTitle={"General Activity"}
        showCheckBoxes={true}
        rowRenderCallback={rowRenderCallback}
        userEmail={userEmail}
        showCsv={true}
        initialDateIndex={1}
      />
      <DynamicJqueryTable
        columnsConfig={matchedUnmatchedColumns}
        path='/admin/ae_dashboards/matched_unmatched'
        tableId="matched-unmatched"
        tableTitle={"Percentage of matched vs unmatched articles"}
        userEmail={userEmail}
        showCsv={true}
        initialDateIndex={4}
      />
      <DynamicJqueryTable
        columnsConfig={otherActivityColumns}
        path='/admin/ae_dashboards/other_activity'
        tableId="other-activity"
        tableTitle={"Other Activity"}
        userEmail={userEmail}
        showCsv={true}
        initialDateIndex={4}
      />
    </>
  )
};

export default ActivityLog;