import {Cureus} from '../Cureus';

Cureus.ModalInputFocus = function(opts) {
  this.modalSelector = opts.modalSelector
  this.bindEvents()
}

Cureus.ModalInputFocus.prototype.bindEvents = function() {
  var self = this;
  $(document).on("opened.fndtn.reveal", function() {
    self.focusOnFirstField();
  });
}

Cureus.ModalInputFocus.prototype.focusOnFirstField = function() {
  var field = document.querySelectorAll("#" + this.modalSelector + " textarea," + "#"+ this.modalSelector + " input[type='text']")[0]
  if(field) {
    field.focus();
  }
}