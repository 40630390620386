import React from 'react';

const UnacceptableTableExamples = () => {
  return (
    <React.Fragment>
      <h1>Bad Table Examples</h1>
      <p>Nested tables or tables with line returns within a cell will appear to display correctly in the Insert Table field, but when inserted into the article, the formatting will be removed and the table will illegible. For this reason, nested tables and line returns within a cell are not permitted.</p>
      <div className='small-font space-top-m'>
        <i className='fas fa-exclamation-triangle red space-right-xs'></i>
        <strong>Line returns in table cell</strong>
        <div className='space-top-s'>Insert Table Field</div>
        <img className='space-top-xxs' src='https://public.cureus.com/line_return_table.png'/>
        <div className='space-top-m'>Inserted table (formatting stripped)
        </div>
        <img className='space-top-xxs' src='https://public.cureus.com/line_return_inserted_table.png'/>
      </div>
      <div className='small-font space-top-m'>
      <i className='fas fa-exclamation-triangle red space-right-xs'></i>
        <strong>Nested table</strong>
        <div className='space-top-s'>Insert Table Field</div>
        <img className='space-top-xxs' src='https://public.cureus.com/nested_table.png'/>
        <div className='space-top-m'>Inserted table (formatting stripped)
        </div>
        <img className='space-top-xxs' src='https://public.cureus.com/nested_table_inserted.png'/>
      </div>
    </React.Fragment>
  )
}

export default UnacceptableTableExamples;