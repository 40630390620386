var createReactClass = require('create-react-class');
import React from 'react';
import SubscribedFilter from './SubscribedFilter';
import Search from './Search';
import Table from './Table';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';
import EmailTool from '../EmailTool';
import { Cureus } from '../../Cureus';

const AddFromExisting = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("Table")
  ],

  getInitialState: function() {
    return {
      subscribed: true,
      disabled: false
    }
  },

  getStateFromFlux: function() {
    return this.getFlux().store("Table").getState();
  },

  handleAddListEmails: function(e) {
    var self = this;
    this.setState({ disabled: true })
    $.post(self.props.addPath, {
      staged: self.state.staged,
      selectAll: self.state.selectAll,
      search: {value: self.state.query},
      fromEmailListId: self.props.fromEmailListId,
      subscribed: self.state.subscribed
    }, function() {
      window.location = self.props.showPath;
    });

    e.preventDefault();
  },

  handleAddListToRefresh: function(e) {
    var self = this;
    this.setState({ disabled: true })
    $.post(self.props.addPath, {
      fromEmailListId: self.props.fromEmailListId,
      refresh: true
    }, function() {
      window.scrollTo({top: 0});
      Cureus.Flash.success("Successfully added email list for auto-refreshing.");
      window.location = self.props.showPath;
    })

    e.preventDefault();

  },

  handleSubscribeFilterChange: function (subscribed){
    this.setState({subscribed: subscribed});
    this.getFlux().actions.deselectAll();
  },

  render: function() {
    var disabledAdd = this.state.disabled || this.state.selectedCount == 0 ? "disabled" : ""
    var addButton = <a className={`button primary ${disabledAdd}`} onClick={this.handleAddListEmails}>Add to list</a>
    var disabledReferesh = this.state.disabled ? "disabled" : ""
    var refreshButton = <a className={`button primary ${disabledReferesh}`} onClick={this.handleAddListToRefresh}>Set Refresh</a>

    return (
      <div className="email-list-show">
        <h1>Add {this.state.selectedCount} subscribers to:</h1>
        <h2>{this.props.toEmailListTitle} ({this.props.toListEmailsCount})</h2>
        <hr/>
        <h3>Viewing {this.props.fromEmailListTitle} ({this.props.fromListEmailsCount})</h3>

        <div className='email-list-datatable-header'>
          <div className="row">
            <div className="columns large-2">
              <SubscribedFilter onChange={this.handleSubscribeFilterChange} subscribed={this.state.subscribed} />
            </div>

            <div className="columns large-5 large-offset-5">
              <Search />
            </div>
          </div>
        </div>

        <Table dataSource={this.props.dataSource} subscribed={this.state.subscribed} columns={this.props.columns}/>

        <div className='email-list-datatable-footer'>
          <a href={this.props.showPath} className='button secondary'>Cancel</a>
          &nbsp;
          {addButton}
          &nbsp;
          {refreshButton}
        </div>
      </div>
    )
  }
});

AddFromExisting.defaultProps = {
  flux: new Fluxxor.Flux({
    Table: new EmailTool.fluxxor.stores.Table()
  }, EmailTool.fluxxor.actions)
}

export default AddFromExisting;