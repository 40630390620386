import React, { useEffect, useState } from 'react';

const SortButton = ({sortObj, updateSort, selectedSortState, orderDirection, handleSetState}) => {

  const isCurrentlySelected = () => {
    return selectedSortState === sortObj.label
  }

  const getArticles = (direction) => {
    let newVal = JSON.parse(sortObj.value)
    newVal.dir = direction
    updateSort(newVal)
  }

  const handleClick = () => {
    let newState = {sortLabel: sortObj.label, orderDirection: orderDirection}
    let direction = getDirection()

    newState.orderDirection = direction
    handleSetState(newState);
    getArticles(direction)
  }

  const getDirection = () => {
    if (isCurrentlySelected()) {
      return (orderDirection === 'desc' ? 'asc' : 'desc')
    } else {
      return "desc"
    }
  }

  const caratDirectionClass = () => {
    if (selectedSortState !== sortObj.label) {
      return "fas fa-sort-down"
    } else {
      let dirIcon = (orderDirection === 'desc' ? 'fa-sort-down' : 'fa-sort-up')
      return `fas ${dirIcon} ${activeClass()}`
    }

  }
  const activeClass = () => {
    return isCurrentlySelected() ? "active" : "inactive"
  }

  const renderTooltipForTopSort = () => {
    if (sortObj.label === "Top") {
      return <img className='top-sort-tooltip-icon tip-top' src="https://public.cureus.com/homepage_new/question_mark.png" data-tooltip="" title="Eligibility is determined by Cureus editors, updated constantly" />
    }
  }

  const cssForTopSort = () => {
    return sortObj.label === "Top" ? "top-sort-container" : "";
  }

  return (
    <div className={"article-sort-button " + cssForTopSort()} onClick={handleClick}>
      <div className={activeClass()}>{sortObj.label}</div>
      <i className={caratDirectionClass()}></i>
      {renderTooltipForTopSort()}
    </div>
  )
}

export default SortButton;

