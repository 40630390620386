import React, { useState, useEffect } from 'react';
import SettingsButton from './SettingsButton';

const SidebarSettings = ({handleAddWidget, widgetSidebarId, sidebarWidgets, slug, hasArticles}) => {
  const sharedProps = {handleAddWidget, widgetSidebarId, sidebarWidgets, slug, hasArticles}
  return (
    <div className="sidebar-settings-container">
      <div className="settings-box">
        <h3>Sidebar Settings</h3>
        <p>Click below to add a widget to the sidebar on your channel homepage. Click and drag to reorder widgets.</p>
        <hr/>
        <div className="sidebar-button-container">
          <SettingsButton
            {...sharedProps}
            img={"https://public.cureus.com/channel_design/overview_4x.png"}
            title={"Overview"}
            modalName={'OverviewSidebarModal'}
            imgClass={"overview"}
          />
        </div>
        <div className="sidebar-button-container">
          <SettingsButton
          {...sharedProps}
            img={"https://public.cureus.com/channel_design/channel_team_member.png"}
            title={"Channel Member Showcase"}
            modalName={'ChannelTeamSidebar'}
            imgClass={"team-member"}
          />
        </div>
        <div className="sidebar-button-container">
          <SettingsButton
            {...sharedProps}
            img={"https://public.cureus.com/channel_design/channel_promotion_new.png"}
            title={"Channel Promotion"}
            modalName={'Promotion'}
            imgClass={"promotion"}
          />
        </div>
        <div className="sidebar-button-container">
          <SettingsButton
            {...sharedProps}
            img={"https://public.cureus.com/channel_design/most-popular-content.png"}
            title={"Most Popular Articles"}
            modalName={'MostPopularContentModal'}
            imgClass={"most-popular-content"}
          />
          </div>

        <div className="sidebar-button-container">
          <SettingsButton
            {...sharedProps}
            img={"https://public.cureus.com/channel_design/channel_cureus_promotion.png"}
            title={"SIQ Promotion"}
            modalName={'CureusPromotionModal'}
            imgClass={"cureus-promo"}
          />
        </div>
        <div className="sidebar-button-container">
          <SettingsButton
            {...sharedProps}
            img={"https://public.cureus.com/channel_design/poll.png"}
            title={"Quiz"}
            modalName={'Poll'}
            imgClass={"poll"}
          />
        </div>
      </div>
    </div>
  )
}

export default SidebarSettings;