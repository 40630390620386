import Fluxxor from '../../../../../../../vendor/assets/javascripts/fluxxor';
import constants from '../constants';

const Table = Fluxxor.createStore({
  initialize: function() {
    this.staged = {};
    this.selectAll = false;
    this.selectedCount = 0;
    this.totalRecordCount = 0;
    this.query = "";
    this.fetchingData = false;

    this.bindActions(
      constants.BEGIN_AJAX_FETCH, this.onBeginAjaxFetch,
      constants.END_AJAX_FETCH, this.onEndAjaxFetch,
      constants.SEARCH, this.onSearch,
      constants.SELECT_ALL, this.onSelectAll,
      constants.SELECT_VISIBLE, this.onSelectVisible,
      constants.DESELECT_ALL, this.onDeselectAll,
      constants.DESELECT_VISIBLE, this.onDeselectVisible,
      constants.CHECK, this.onCheck,
      constants.UNCHECK, this.onUncheck
    );
  },

  getState: function() {
    return {
      staged: this.staged,
      selectedCount: this.selectedCount,
      selectAll: this.selectAll,
      totalRecordCount: this.totalRecordCount,
      query: this.query,
      fetchingData: this.fetchingData
    }
  },

  onBeginAjaxFetch: function() {
    this.fetchingData = true;
    this.emit("change");
  },

  onEndAjaxFetch: function(totalRecordCount) {
    this.totalRecordCount = totalRecordCount;
    this.fetchingData = false;
    this.emit("change");
  },

  onSearch: function() {
  // need this function so fluxxor doesn't break,
  // but we don't need to use this anymore. Eventually we'll remove fluxxor.
  },

  onSelectAll: function() {
    this.staged = {};
    this.selectAll = true;
    this.selectedCount = this.totalRecordCount;
    this.emit("change");
  },

  onSelectVisible: function(ids) {
    var self = this;
    if(self.selectAll) {
      _.each(ids, function(id) {
        self.unstage(id);
      });
    } else {
      _.each(ids, function(id) {
        self.stage(id);
      });
    }
  },

  onDeselectAll: function() {
    this.staged = {};
    this.selectAll = false;
    this.selectedCount = 0;
    this.emit("change");
  },

  onDeselectVisible: function(ids) {
    var self = this;
    if(self.selectAll) {
      _.each(ids, function(id) {
        self.stage(id);
      });
    } else {
      _.each(ids, function(id) {
        self.unstage(id);
      });
    }
  },

  onCheck: function(id) {
    this.selectAll ? this.unstage(id) : this.stage(id);
  },

  onUncheck: function(id) {
    this.selectAll ? this.stage(id) : this.unstage(id);
  },

  //private

  stage: function(id) {
    this.staged[id] = true;
    this.updateSelectedCount();
    this.emit("change");
  },

  unstage: function(id) {
    delete this.staged[id];
    this.updateSelectedCount();
    this.emit("change");
  },

  updateSelectedCount: function() {
    var stagedCount = Object.keys(this.staged).length;

    if(this.selectAll) {
      this.selectedCount = this.totalRecordCount - stagedCount;
    } else {
      this.selectedCount = stagedCount;
    }
  }
});

export default Table;
