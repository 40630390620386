import {Cureus} from '../Cureus';

Cureus.RevealablePassword = function( fieldSelector, checkboxSelector) {
  var self = this;
  self.field = document.querySelector(fieldSelector);
  self.checkbox = document.querySelector(checkboxSelector);

  self.checkbox.addEventListener("change", function() {
    if (self.checkbox.checked) {
      self.field.setAttribute("type", "text")
    } else {
      self.field.setAttribute("type", "password")
    }
  })
}