import React, { useEffect } from 'react';

const ClaimedArticles = ( { datasets, contentType } ) => {

  useEffect(() => {
    $("#claimed-articles-table").DataTable({
      dom: '<"row"<"large-4 columns"l><"large-6 columns"f>>t<"row"<"large-8 columns"i><"pull-right"p>>',
    });
  }, [])

  useEffect(() => {
    populateTable()
  })

  function populateTable() {
    var dt = $("#claimed-articles-table").DataTable();
    dt.api().clear();
    for (var i = 0; i < datasets.length; i++) {
      var editor = datasets[i];
      var data = [
        `<a href='/admin/users/${editor.user_id}' target='_blank'>${editor.full_name}</a>`,
        editor.claimed || 0,
        editor.started || 0,
      ];
      if (contentType == 'submissions') {
        data.push(editor.professional || 0)
        data.push(editor.content || 0)
      } else {
        data.push(editor.deferred || 0)
        data.push(articleURLs(editor))
      }


      dt.fnAddData(data, false)
    }
    dt.fnDraw()
  }

  const articleURLs = (editor) => {
    if (editor.article_ids == null) { return "" }
    let ids = editor.article_ids.split(",")
    return ids.map(id => {
      return `<a href='/admin/articles/${id}' target='_blank'>${id}</a>`
    }).join(", ")
  }

  function headers() {
    if (contentType == 'submissions') {
      return (
        <tr>
          <th>Editor</th>
          <th>Claimed</th>
          <th>Started</th>
          <th>Deferred for 3rd Party</th>
          <th>Deferred for Content</th>
        </tr>
      )
    } else {
      return (
        <tr>
          <th>Editor</th>
          <th>Claimed</th>
          <th>Started</th>
          <th>Deferred</th>
          <th>Claimed Articles</th>
        </tr>
      )
    }
  }

  return (
    <table className="table-wrapper data-table" id="claimed-articles-table">
      <thead>
        {headers()}
      </thead>
      <tbody></tbody>
    </table>
  )
}

export default ClaimedArticles;