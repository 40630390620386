import constants from './constants';

const actions = {
  beginAjaxFetch: function() {
    this.dispatch(constants.BEGIN_AJAX_FETCH);
  },

  endAjaxFetch: function(totalRecordCount) {
    this.dispatch(constants.END_AJAX_FETCH, totalRecordCount);
  },

  search: function(query) {
    this.dispatch(constants.SEARCH, query);
  },

  selectAll: function() {
    this.dispatch(constants.SELECT_ALL);
  },

  selectVisible: function(ids) {
    this.dispatch(constants.SELECT_VISIBLE, ids);
  },

  deselectAll: function() {
    this.dispatch(constants.DESELECT_ALL);
  },

  deselectVisible: function(ids) {
    this.dispatch(constants.DESELECT_VISIBLE, ids);
  },

  check: function(id) {
    this.dispatch(constants.CHECK, id);
  },

  uncheck: function(id) {
    this.dispatch(constants.UNCHECK, id);
  },

  addFilters: function(filters) {
    this.dispatch(constants.ADD_FILTERS, filters);
  },

  clearFilters: function() {
    this.dispatch(constants.CLEAR_FILTERS);
  }
}

export default actions;