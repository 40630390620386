var createReactClass = require('create-react-class');
import React from 'react';

const ArticleStatsRow = createReactClass({
  renderBadge: function() {
    if (this.props.article.badge) {
      return (
        <td className="no-border" dangerouslySetInnerHTML={{__html: this.props.article.badge}}/>
      )
    } else {
      return (
        <td className="no-border">
          <div className="article-tier-badge free">
            <div className="free left-nubbins nubbins">
              Free Article
            </div>
            <div className="free nubbins right-nubbins"></div>
          </div>
        </td>
      )
    }
  },

  render: function (){
    var article = this.props.article
    return (
      <tr style={{background: '#F8F8F8'}}>
        <td className="no-border" dangerouslySetInnerHTML={{__html: article.articleStatus}}/>
        {this.renderBadge()}
        <td className="no-border">
          <div className="nowrap">{ article.submitted_at || 'N/A' }</div>
        </td>
        <td className="no-border">
          {article.role}
        </td>
        <td className="no-border">
          {article.type}
        </td>
        <td className="no-border">
          <a class={this.errorClass()}href={article.adminArticlePath} target="_blank">{ article.title }</a>
        </td>
      </tr>
    )
  },

  errorClass: function() {
    if (this.props.article.isTitleTooSimilarToCurrentArticle) { return "error-message" }
  },
});

export default ArticleStatsRow;
