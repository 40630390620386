import {Cureus} from '../Cureus';

Cureus.CollapsibleList = (function($, _) {
  var init, CollapsibleList;

  init = function (options){
    new CollapsibleList(options);
  };

  CollapsibleList = function (options){
    this.container_selector = options.container;
    this.$container = $(this.container_selector);

    this.setupCollapsibleLists();
  };

  _.extend(CollapsibleList.prototype, {
    setupCollapsibleLists: function (){
      this.setInitialState();
      this.bindCollapsibleListEvents();
    },

    bindCollapsibleListEvents: function() {
      this.$container.each( function (index){
        var $curr_container = $(this)
        $curr_container.on("click", "[data-toggle-collapse]", function (e){
          var $listWrap = $(this).closest(".collapsible-list");
          $listWrap.toggleClass("closed");
          e.preventDefault();
        });
      });
    },

    setInitialState: function (){
      if($(window).width() <= 768) {
        this.$container.each( function (index){
          var $curr_container = $(this);
          $curr_container.find("[data-toggle-collapse]").each(function (index){
            var $listWrap = $(this).closest(".collapsible-list");
            $listWrap.addClass("closed");
          });
        });
      }
    }
  });

  return { init: init };
})(jQuery, _);
