import React, { useEffect } from 'react';
import Modal from '../Modal';
import AddQualificationModal from './AddQualificationModal';

const Main = ({ user_id, qualifications, updateProfilePath, userProfile, otherQualification }) => {

  useEffect(() => { 
    Modal.open({
      children: <AddQualificationModal user_id={user_id} qualifications={qualifications} userProfile={userProfile} updateProfilePath={updateProfilePath} otherQualification={otherQualification}/>
    })
  }, [])

  return (
    <React.Fragment/>
  )

}

export default Main;