var createReactClass = require('create-react-class');
import React from 'react';
import autosize from 'autosize';

const ReferenceField = createReactClass({
  getInitialState: function (){
    return {
      fieldValue: this.props.field.value,
      disabledDoiSearch: false
    };
  },

  getDefaultProps: function() {
    return {
      blockFields: [
        "authors", "title", "source_name", "doi", "url"
      ],
      textareaFields: [
        "authors", "title", "source_name"
      ]
    }
  },

  componentDidMount: function (){
    if (this.props.isModal) {
      autosize($(this.refs.modalWrap).find("textarea"));

    }
    else {
      autosize($(this.refs.wrap).find("textarea"));
    }
  },

  updateFieldValue: function (e){
    this.setState({fieldValue: e.target.value});
  },

  fetchDoiMetadata: function(e) {
    this.setState({disabledDoiSearch: true});
    this.props.fetchDoiMetadata(this.state.fieldValue, () => { this.setState({disabledDoiSearch: false}) });
  },

  searchText: function() {
    return this.state.disabledDoiSearch ? "Searching" : "Search DOI"
  },

  searchDisabled: function() {
    return this.state.disabledDoiSearch || this.state.fieldValue === null || this.state.fieldValue === undefined || this.state.fieldValue.length === 0
  },

  render: function (){
    if(this.props.field.tooltip) {
      var label = <label id={ this.props.field.name } htmlFor={ this.props.field.name } title={ this.props.field.tooltip } className="tip-top">{ this.props.field.label }</label>;
    } else {
      var label = <label htmlFor={ this.props.field.name }>{ this.props.field.label }</label>;
    }

    var label2;
    var button;
    if (this.props.isEditor && this.props.parsedReferences) {
      if (!this.props.invalidParsedReference) {
        var color = this.props.field.original_value == this.props.field.value ? "green" : "red"
        label2 = <label style={{color: color, cursor: 'default'}}>Original Value: {this.props.field.original_value}</label>
      }
      
      if (this.props.field.name === 'doi') {
        button = <a disabled={ this.searchDisabled() } class='small button success' onClick={ this.fetchDoiMetadata }>{ this.searchText() }</a>
      }
    }

    if (this.props.isModal) {
      var color = this.props.originalField.value == this.props.field.value ? "green" : "red"
      label2 = <label style={{color: color, cursor: 'default'}}>Current Value: {this.props.originalField.value}</label>
    }

    var block = _.include(this.props.blockFields, this.props.field.name);

    var classes = 'columns' + (block ? ' large-12': ' large-4');
    if(_.include(this.props.textareaFields, this.props.field.name) && !this.props.isModal) {
      var input = <textarea id={ this.props.field.name } name={'reference[' + this.props.field.name + ']'}  value={ this.state.fieldValue || ''} onChange={this.updateFieldValue}></textarea>
    } else {
      var input = <input id={this.props.field.name} type='text' name={'reference[' + this.props.field.name + ']'}  value={ this.state.fieldValue || ''} onChange={this.updateFieldValue} />
    }

    return (
      <div className={ classes } ref={this.props.isModal ? 'modalWrap' : 'wrap'}>
        {label}
        {label2}
        {input}
        {button}
      </div>
    )
  }
});

export default ReferenceField;