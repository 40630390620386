import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import DateSelector from '../../../StatsWidget/DateSelector'

const ArticleOverviewTable = ({dateRanges}) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [tableData, setTableData] = useState(null);
  const [dateFrom, setDateFrom] = useState(dateRanges[0].from)
  const [dateTo, setDateTo] = useState(dateRanges[0].to)
  const [selectedRangeName, setSelectedRangeName] = useState("Current week")


  useEffect(() => {
   if (tableData) {
    initializeTable()
   }
  }, [tableData])

  useEffect(() => {
    fetchData()
  }, [dateFrom, dateTo, selectedRangeName])

  const fetchData = () => {
    $.ajax({
      type: 'GET',
      url: '/admin/channels/articles_table_data',
      data: {
        dataType: "performance_articles_overview",
        date_from: dateFrom,
        date_to: dateTo
      },
      success: function(data) {
        setTableData(data)
      }
    });
  }

  const initializeTable = () => {
    let dt;
    if (isTableInitialized()) {
      dt = $("#performance-article-overview").DataTable()
    } else {
      dt = $("#performance-article-overview").DataTable({
        destroy: true,
        columns: setColumns(),
        searching: false,
        dom: '<"row header-bar"<"large-3 columns title1"><"large-2 columns time-range1">><"table"t>r<"row bottom"<"large-4 columns"p>>'
      });
    }

    dt.api().clear()
    mapColumns(dt)
    dt.api().draw()
    renderTitleAndDateSelector()
  }

  const isTableInitialized = () => {
    return $.fn.dataTable.isDataTable($("#performance-article-overview"))
  }
  const renderTitleAndDateSelector = () => {
    if (firstLoad) {
      document.querySelector(".title1").innerHTML = "<h5>Channel Editor Preview Activity</h5>"
      ReactDom.render(dateSelector(), document.querySelector(".time-range1"))
      setFirstLoad(false)
    }
  }

  const setColumns = () => {
    return [
      {width: '100px'},
      {width: '100px'},
      {width: '50px'},
      {width: '50px'},
      {width: '100px'},
      {width: '100px'},
      {width: '100px'}
    ]
  }

  const mapColumns = (dt) => {
    tableData.forEach((data) => {
      dt.fnAddData([
        `<a target="_blank" href="/admin/channels/${data.id}">${data.title}</a>`,
        data.submitted_editor_check,
        data.rejected,
        data.blocked,
        data.content_deferred,
        data.ithenticate,
        data.third_party_deferral
      ])
    })
  }

  const dateSelector = () => {
    return <DateSelector
      from={dateRanges[0].from}
      to={dateRanges[0].to}
      getStats={filterData}
      selectedRange={"Current week"}
      noFloat={true}
      dateRanges={dateRanges}
    />
  }

  const filterData = (from, to, rangeName) => {
    setDateFrom(from)
    setDateTo(to)
    setSelectedRangeName(rangeName)
  }

  return (
    <div className="performance">
      <table className="table-wrapper" id="performance-article-overview">
        <thead>
          <tr>
            <th>Channel</th>
            <th>Submissions to Ed Check</th>
            <th>Rejected</th>
            <th>Blocked</th>
            <th>Content Deferral</th>
            <th>Ithenticate</th>
            <th>3rd Party Deferral</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  )
}
export default ArticleOverviewTable;

