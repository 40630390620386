var createReactClass = require('create-react-class');
import React from 'react';

const ChannelTeamMember = createReactClass({
  renderWidgetContent: function() {
    if (this.props.open) {
      return(
        <div>
          <label>
            ID of channel team member user:
            <input type="text" name="widget[user_id]" id="user_id" defaultValue={this.props.widget.user_id}/>
          </label>
          <label>
            Title
            <input type="text" name="widget[title]" id="title" defaultValue={this.props.widget.title}/>
          </label>
        </div>
      )
    }
  },

  render: function() {
    return (
      <div>
        { this.renderWidgetContent() }
      </div>
    )
  }
})

export default ChannelTeamMember;