import React, {useState, useEffect, useRef} from 'react';

const Label = ({label}) => {
  return (
    <div className="row">
      <div className="small-12 columns">
        <div className="label-container">
          <img src="https://public.cureus.com/collections/application-form/form-label-1.png"/>
          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="2" viewBox="0 0 2 2" fill="none">
            <circle cx="1" cy="1" r="1" fill="#73797E"/>
          </svg>
          <h4>{label}</h4>
        </div>
      </div>
    </div>
  )
}

export default Label;