var createReactClass = require('create-react-class');
import React from 'react';
import CleanAffiliations from './CleanAffiliations';

const Main = createReactClass({
  getInitialState: function() {
    return({})
  },

  componentDidMount: function() {
  },

  render: function() {
    var self = this;
    return(
      <div>
        <CleanAffiliations
          tableDataSource={self.props.tableDataSource}
        />
      </div>
    )
  }
})

export default Main;