import React from "react"
import Checkbox from './Checkbox';

const AdminEditorCheckboxes = ({handleSelectAdmin, admins, recipients}) => {
  const assignedEditor = () => {
    return admins.find( (admin) => { return admin.isAssignedToArticle })
  }

  const unAssignedEditors = () => {
    return admins.filter( (admin) => { return !admin.isAssignedToArticle })
  }

  const renderAssignedEditorCheckbox = () => {
    let checkbox;
    if (assignedEditor()) {
      checkbox = <Checkbox
                      admin={assignedEditor()}
                      handleSelectAdmin={handleSelectAdmin}
                      recipients={recipients}
                    />
    } else {
      checkbox = <p>None</p>
    }
    return (
      <div className="checkboxes">
        <strong>Assigned Admin/Editor</strong>
        {checkbox}
      </div>
    )
  }

  const renderOtherEditorCheckboxes = () => {
    let checkboxes = unAssignedEditors().map( (admin) => {
      return (
        <Checkbox
          key={admin.id}
          admin={admin}
          handleSelectAdmin={handleSelectAdmin}
          recipients={recipients}
        />
      );
    });

    return (
      <div className="checkboxes">
        <strong>Channel Admins & Editors</strong>
        {checkboxes}
      </div>
    )
  }

  return (
    <>
      {renderAssignedEditorCheckbox()}
      {renderOtherEditorCheckboxes()}
    </>
  )
}

export default AdminEditorCheckboxes