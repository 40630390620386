var createReactClass = require('create-react-class');
import React from 'react';

const Name = createReactClass({
  renderFirstNameField: function() { 
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.first_name){
      class_name = "error"
      error_field = ( <div key={this.props.id + "_first_name_errors"} className="errors"> {this.props.errors.first_name} </div> )
    }
    if(this.props.canAdmin || this.props.editable || !this.props.id){
      return ( [<input autoComplete='new-password' key={this.props.id + "_first_name_field"} type="text" name="first_name" id={this.props.id + "_first_name"} ref="firstName" data-error_key="first_name" className={class_name} onChange={this.props.handleChange} value={this.props.first_name}  />, error_field] )
    }
    return ( <input autoComplete='new-password' key={this.props.id + "_first_name_field"} type="text" name="first_name" id={this.props.id + "_first_name"} value={this.props.first_name} disabled="disabled" /> )
  },

  renderMiddleInitialField: function (){
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.middle_initial){
      class_name = "error";
      error_field = ( <div key={this.props.id + "_middle_initial_errors"} className="errors"> {this.props.errors.middle_initial } </div> )
    }

    if(this.props.canAdmin || this.props.editable || !this.props.id) {
      return ( [<input autoComplete='new-password' key={this.props.id + "_middle_initial_field"} type="text" name="middle_initial" id={this.props.id + "_mi"} ref="middleInitial" data-error_key="middle_initial" className={class_name} onChange={this.props.handleChange} value={this.props.middle_initial} maxLength="1" />, error_field] )
    }
    return ( <input autoComplete='new-password' key={this.props.id + "middle_initial_field"} type="text" name="middle_initial" id={this.props.id + "_mi"} value={this.props.middle_initial} disabled="disabled" maxLength="1" /> )
  },

  renderLastNameField: function (){
    var class_name, error_field;

    class_name = "";
    error_field = null;
    if(this.props.errors && this.props.errors.last_name){
      class_name = "error";
      error_field = ( <div key={this.props.id + "_last_name_errors"} className="errors"> {this.props.errors.last_name } </div> )
    }

    if(this.props.canAdmin || this.props.editable || !this.props.id) {
      return ( [<input autoComplete='new-password' key={this.props.id + "_last_name_field"} type="text" name="last_name" id={this.props.id + "_last_name"} ref="lastName" data-error_key="last_name" className={class_name} onChange={this.props.handleChange} value={this.props.last_name} />, error_field] )
    }
    return ( <input autoComplete='new-password' key={this.props.id + "_last_name_field"} type="text" name="last_name" id={this.props.id + "_last_name"} value={this.props.last_name} disabled="disabled" /> )
  },

  renderSuffixField: function() {
    return(
      <select name="suffix" onChange={(e) => this.props.handleChange(e)} defaultValue={this.props.suffix || ""}>
        <option value=""></option>
        <option value="Jr.">Jr.</option>
        <option value="Sr.">Sr.</option>
        <option value="II">II</option>
        <option value="III">III</option>
        <option value="IV">IV</option>
      </select>
    )
  },

  render: function() {
    return (
      <div className="row">
        <div className="small-4 columns">
          <label htmlFor={this.props.id + "first_name"}>First name</label>
          { this.renderFirstNameField() }
        </div>
        <div className="small-2 columns">
          <label htmlFor={this.props.id + "_mi"}>MI</label>
          { this.renderMiddleInitialField() }
        </div>
        <div className="small-4 columns">
          <label htmlFor={ this.props.id + "_last_name" }>Last name</label>
          { this.renderLastNameField() }
        </div>
        <div className="small-2 columns">
          <label htmlFor={ this.props.id + "_suffix" }>Suffix</label>
          { this.renderSuffixField() }
        </div>
      </div>
    )
  }
})

export default Name;