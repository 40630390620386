import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const WhyReviewWithCureus = ({ setFooterText }) => {

  useEffect(() => {
    setFooterText('Sign up for Cureus today and join our peer review panel to provide valuable feedback to medical research authors across the globe.');
   }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>Peer Review Medical Articles | Review Research | Cureus</title>
        <meta name="description" content="Peer review medical articles with Cureus, where the peer review process takes weeks, not months, empowering the scientific community with reliable knowledge." />
      </Helmet>
      <h1>Why Review With Cureus?</h1>
      <p>Peer review is the foundation on which published medical literature stands. While peer review is largely altruistic, there are many benefits to serving as a medical science peer reviewer. </p>
      <p>Lend a hand to your fellow Cureus authors and
      <a href='/registrations/sign_up'> apply to join the Cureus peer review panel</a> today!</p>

      <h2>Review Cureus articles online with no hassle!</h2>
      <p>Cureus provides a hassle-free 
        <a href='/reviewer_guide#!/how-it-works/step-by-step-overview'> peer review process</a> empowering you to review the article and submit your comments online, often in just a few hours, and without downloading and printing PDFs to make comments in the margins. You can provide peer review of medical articles anywhere and at any time. Just add your comments, complete a short questionnaire and submit your review.</p>

      <h2>Stay abreast of the latest developments in your field by getting a first look at research from your fellow physicians.</h2>
      <p>Review invitations unlock exclusive first-look access to the latest work from physicians around the world. Your contributions to medical peer review in Cureus could mean you’re the first to learn about a rare new case or a years-in-the-making <a href='/newsroom'> newsworthy</a> study while also making a measurable impact on the authors’ work itself.</p>
      <p>When you peer review medical articles from researchers around the globe, you help authors strengthen their work while improving your own writing skills!</p>

      <h2>Gain progress towards our Cureus Honors program to earn rewards!</h2>
      <p>Participating in the peer review of medical articles will help you progress towards our honors tiers where you’ll be rewarded with perks like discounts on our <a href={'/cureus_editing_services'}>
        Preferred Editing</a> and Expedited PMC services.</p>
      <p>Additionally, Cureus-invited peer reviews are worth more than twice as much as author-invited reviews. Be sure to join our <a href='/people?preselected_roles%5B%5D=reviewer'> peer review panel</a>!
      </p>

      <h2>Provide a valuable scientific contribution to medical literature available in PubMed Central.</h2>
      <p>Cureus peer review assists the author in creating the best possible scholarly medical article. Peer reviewers provide comprehensive criticism, helping to provide authors with an efficient and fruitful publishing process. All articles published in Cureus will be <a href='/partnerships'> indexed in PubMed Central</a> approximately six weeks after publication!</p>
    </React.Fragment>
  )
}

export default WhyReviewWithCureus;