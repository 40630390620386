var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal';

const ChannelModal = createReactClass({
  getInitialState: function() {
    return({
      subchannels: this.props.subchannels
    })
  },

  mapChannels: function() {
    var self = this;
    return Object.values(self.props.subchannels).map(function(el) {
      return (
        <label key={el.id}>
          <input type='checkbox' onChange={() => self.handleChange(el.id)} checked={el.checked}/>
          &nbsp;{el.title}
        </label>
      )
    })
  },

  handleChange: function(id) {
    var self = this;
    var updatedSubchannels= self.state.subchannels;
    updatedSubchannels[id].checked = !updatedSubchannels[id].checked
    self.setState({subchannels: updatedSubchannels}, function() {self.props.handleChange(updatedSubchannels)})
  },

  handleClose: function() {
    Modal.close({onCloseInstant: this.props.resetPage})
  },

  render: function() {
    return(
      <div data-reveal  className='cureus-custom-multi-select-modal reveal-modal'>
        <div className='modal-heading'>
          <div className='modal-title'>
            Choose Academic Institution
          </div>
          <div className='modal-close' >
            <a href='#' className='button tiny' onClick={this.handleClose} style={{height: 'auto', width: 'auto'}}>
              Filter
            </a>
          </div>
        </div>
        <div className='modal-body-wrap'>
          <div className='modal-body'>
            <div className='modal-content'>
              {this.mapChannels()}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default ChannelModal;