import React, { useState, useEffect } from 'react';
import Card from "./Card"

const Articles = ({article, fireAmplitude}) => {
  const [articles, setArticles] = useState([]);
  const [articlesCount, setArticlesCount] = useState(0);

  useEffect(() => {
    fetchArticles();
  }, [])

  const renderArticles = () => {
    return articles.map(function(article, idx) {
      return <Card key={idx} article={article} fireAmplitude={fireAmplitude}/>
    })
  }

  const fetchArticles = () => {
    fetch(`/articles/${article.id}/most_popular_by_category`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
        'Accept': 'application/json',
      }
    })
    .then((res) => { return res.json()})
    .then((json) => {

      setArticles(json.articles)
      setArticlesCount(json.count)
    })
  }

  const popularArticlesURL = () => {
    const url = "/articles/most_popular"
    const encode = encodeURIComponent(categories().join("+"))
    return `${url}?categories=${encode}`
  }

  const categories = () => {
    return article.categories.map( (cat) => {
      return cat.name
    })
  }

  const renderViewMore = () => {
    if (articlesCount > 4) {
      return <a href={popularArticlesURL()} target="_blank" className="view-more">View More <i class="fa fa-arrow-right"></i></a>
    }
  }

  return (
    <>
      {articles.length !== 0 &&
        <div className="row small-collapse">
          <div className="small-12 columns sidebar most-popular">
            <h1>Popular Related Articles</h1>
            {renderArticles()}
            {renderViewMore()}
          </div>
        </div>
      }
    </>
  )
}

export default Articles;