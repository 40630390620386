import React, { useEffect, useState } from 'react';
import PeerReviewQuestion from './PeerReviewQuestion';

const Main = ({reportPath}) => {

  const [responses, setResponses] = useState([]);
  const [disclosures, setDisclosures] = useState([]);

  useEffect(() => {
    fetchReport();
  }, [])

  const fetchReport = () => {
    $.ajax({
      url: reportPath,
      type: 'GET',
      dataType: 'json',
      success: function(res) {
        setResponses(res.responses)
        setDisclosures(res.responses[0][1])
      }
    })
  }

  const renderDisclosure = () => {
    const questionIndex = responses.length + 1
    return disclosures.map((data) => {
      if(data.disclosure_declared) {
        return (
          <>
            <div className="response-header">
                <h5>{questionIndex}. Any disclosures?</h5>
            </div>
            <PeerReviewQuestion response={data} disclosure={true}/>
          </>
        )
      }
    })
  }

  const renderQuestions = () => {
    return (
      responses.map((response, index) => { 
        return (
          <div>
            <div className="response-header">
              <h5>{index + 1}. {response[0]}</h5>
            </div>
            {renderResponses(response[1])}
          </div>
        )})
    )
  }


  const renderResponses = (answers) => {
    return (
      <div className="response-body">
        {answers.map((answer) => {
          return (
            <PeerReviewQuestion response={answer}/>
          )
        })}
      </div>
    )
  }

  return (
    <div className="peer-review-report">
      <div className="row">
        <div className="small-12 columns">
          <div className="response-container">
          {responses && renderQuestions()}
          {disclosures.length > 0 && renderDisclosure()}
          </div>
        </div>
      </div>
    </div>
  )

}

export default Main;