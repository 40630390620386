import {Cureus} from '../../Cureus';

/* A utility to handle expanding and collpasing of content.
 * The markup consists of 2 parts:
 *   1. A div with class collapse-control and data-collapse='collapse_target'
 *   2. A div wrapping the content which will be expanded/collapsed
 *      with class collapsible and data-collapse-id='collapse_target'
 */
Cureus.CollapseManager = (function ($, _){
  var init;

  init = function (){
    $("[data-collapse]").on("click", handleCollapse);
  }

  function handleCollapse(e){
    var $this;

    $this = $(this);
    if($this.hasClass("closed")){
      expandGroup($this);
    }else{
      collapseGroup($this);
    }
  }

  function collapseGroup($control_element){
    var collapse_target;

    collapse_target = $control_element.data("collapse");
    $collapse_target = $("[data-collapse-id='"+collapse_target+"']");
    $control_element.addClass("closed");
    $collapse_target.addClass("collapsed");
  };

  function expandGroup($control_element){
    var collapse_target;

    collapse_target = $control_element.data("collapse");
    $collapse_target = $("[data-collapse-id='"+collapse_target+"']");
    $control_element.removeClass("closed");
    $collapse_target.removeClass("collapsed");
  };

  return {init: init}
})(jQuery, _);
