var createReactClass = require('create-react-class');
import React from 'react';

const CorrectionRetractionCard = createReactClass({
  renderCategories: function() {
    var self = this;
    if (!this.props.resource.categories) { return; }
    return this.props.resource.categories.map(function(category) {
      return <a key={ category.id + "_" + self.props.resource.id} href={category.path} className="tag">{ category.name }</a>
    });
  },

  render: function() {
    return(
      <div className="content-card">
        <div className="content-card-header">
          <div className="content-card-header-info">
            <span>
              {this.props.resource.published_at}
            </span>
            <br/>
            <span className="content-card-type">
              {this.props.resource.humanized_type}
            </span>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="content-card-details">
          <a href={this.props.resource.resource_path}>
            <h4>
              <strong>{this.props.resource.type}: </strong>
              {this.props.resource.title}
            </h4>
          </a>
          <div className="content-card-authors">
            {this.props.resource.author_list}
          </div>
          <div className="content-card-categories">
            {this.renderCategories()}
          </div>
        </div>
      </div>
    )
  }
})

export default CorrectionRetractionCard;