import React, {useState} from 'react';
import Modal from '../../Modal';
import CollectionRejectedModal from './CollectionRejectedModal';

const RejectedCollectionCard = ({cardImgUrl, label, previewUrl, rejectedData}) => {
  
  const openRejectModal = () => {
    Modal.open({
      children:
        <CollectionRejectedModal rejectedData={rejectedData} />
    })
  }

  return (
    <div className='rejected-card-container'>
      <div className='header-container'>
        <img className='thumbnail' src={cardImgUrl} />
        <h4>{label}</h4>
        <a className='button alternate text-center preview-btn' href={previewUrl} target="_blank">Preview</a>
      </div>
      <div className='footer-container'>
        <img src="https://public.cureus.com/my_dashboard/my_collections/rejected-icon.png" alt="" />
        <p>Your collection has been declined after careful consideration by our editorial staff. <a className='orange-link' onClick={openRejectModal}>Click here to view the rejection message.</a></p>
      </div>
    </div>
  )
};

export default RejectedCollectionCard;