import React, { useState, useEffect } from 'react';
import { Link, Switch, Route, useLocation, Redirect } from 'react-router-dom';


// take takes in array of tabs
// const tabs = [
//   { path: '/activity_log', label: 'ACTIVITY LOG', component: <ActivityLog /> },
//   { path: '/snapshot', label: 'CURRENT SNAPSHOT', component: <Snapshot /> },
//   { path: '/concern', label: 'POINTS OF CONCERN', component: <Concern /> },
//   { path: '/other', label: 'OTHER STATS', component: <Other /> },
// ];
const Tabs = ({ tabs }) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  return (
    <div className="row">
      <div className="large-12 columns">
        <div className="cureus-tabs secondary-tabs dashboard homepage">
          <ul className="tabs">
            {tabs.map((tab) => (
              <Link to={tab.path} key={tab.path}>
                <li
                  className={"tab-title " + (activeTab === tab.path ? 'active' : '')}
                  onClick={() => setActiveTab(tab.path)}
                >
                  <a className="dashboard">{tab.label}</a>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
      <Switch>
        <Route exact path="/">
          <Redirect to={tabs[0].path} />
        </Route>
        {tabs.map((tab) => (
          <Route key={tab.path} path={tab.path} exact>
            {tab.component}
          </Route>
        ))}
      </Switch>
    </div>
  );
};

export default Tabs;