import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import DeleteButton from '../DeleteButton';

const CureusPromotion = ({slug, handleDeleteWidget, sidebarWidgets, widgetId}) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    if(widgetId) {
      let onSuccess = (res) => {
        setContent(res)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [sidebarWidgets])

  const renderActionButtons = () => {
    return (
    <>
      <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png" style={{right: '30px'}}/>
      <DeleteButton
        handleDeleteWidget={handleDeleteWidget}
        widgetId={widgetId}
      />
    </>
    )
  }

  return (
    <div className="row sidebar-row">
      {handleDeleteWidget && renderActionButtons()}
      <div className="small-12 columns widget cureus-promotion">
        <div className="img-container">
          <a href="/siq">
            <img src={content.url}/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default CureusPromotion;