var createReactClass = require('create-react-class');
import React from 'react';
import QuickviewModal from './QuickviewModal';
import Modal from '../../../Modal';

const Row = createReactClass({
  getInitialState: function() {
    return ({
      focused: false
    })
  },

  openQuickviewModal: function() {
    Modal.open({
      children: <QuickviewModal
        reviewership={this.props.reviewership}
        articlePath={this.props.articlePath}
        renderCircle={this.renderCircle}
        handleStrikethrough={this.handleStrikethrough}
        reviewerTimeLeft={this.props.reviewerTimeLeft}
      />
    })
  },

  renderQuickviewButton: function() {
    if (this.props.reviewership.reviewHistory.count === 0) {
      return <a className="button secondary tiny disabled"><i className="far fa-eye"/>Quick View</a>
    }
    return <a className="button secondary tiny" onClick={this.openQuickviewModal}><i className="far fa-eye"/>Quick View</a>
  },

  renderToolTipText: function() {
    var text;
    if(this.props.reviewership.showTimer && this.props.reviewerTimeLeft) {
      var hours = this.props.reviewerTimeLeft === 1 ? 'hour' : 'hours'
      text = " has up to " + this.props.reviewerTimeLeft + " more " + hours +" to complete the review. Click to view their activity."
    } else if(this.props.reviewership.status === "reviewing") {
      text = " has accepted the invitation. Click to view their activity."
    } else if(this.props.reviewership.status === 'rejected') {
      return "This review was rejected. Click to learn more."
    } else if (this.props.reviewership.status === 'expired') {
      text = " did not submit their review in time. Click to learn more."
    } else if (this.props.reviewership.status ==='completed' && this.props.reviewership.reviewHistory.count === 0) {
      text = " did not leave any inline comments. Please refer to the questionnaire section for their feedback."
    } else {
      text = " has completed the review. Click to view their comments."
    }
    return this.props.reviewership.greek_symbol + text;
  },

  renderCircle: function(classname) {
    if(this.props.reviewership.status === 'rejected') {
      return <td className={'rev-status ' + classname + " " + this.props.reviewership.status}> <i className={"fas fa-times-circle fa-lg"}></i> Rejected</td>
    } else if(this.props.reviewership.showTimer && this.props.reviewerTimeLeft) {
      return <td className={'rev-status ' + classname}> <i className={"far fa-clock fa-lg timer"}></i>  Pending</td>
    } else if (this.props.reviewership.status === 'expired') {
      return <td className={'rev-status ' + classname + " " + this.props.reviewership.status}>Expired</td>
    } else if(this.props.reviewership.status === 'reviewing') {
      return <td className={'rev-status ' + classname + " " + this.props.reviewership.status}> <i class="fas fa-sync"/>  Pending</td>
    } else {
      return <td className={'rev-status ' + classname + " " + this.props.reviewership.status}> <i className={"fas fa-check-circle fa-lg"}></i>  Completed</td>
    }
  },

  handleStrikethrough: function() {
    if(this.props.reviewership.status === 'rejected') {
      return {textDecoration: 'line-through', fontSize: '16px', fontWeight: 600}
    } else {
      return  {fontSize: '16px', fontWeight: 600}
    }
  },

  render: function() {
    return(
      <tr id={this.props.reviewership.greek_symbol + "-" + this.props.reviewership.id} title={this.renderToolTipText()} className="tip-left" data-tooltip="">
        {this.renderCircle("large-2 small-1 medium-1")}
        <td className="large-3 small-4 medium-4 text-left rev-name" style={this.handleStrikethrough()}> {this.props.reviewership.greek_symbol} </td>
        <td className="large-2 small-2 medium-2">
           <div className={"colored-box " + this.props.reviewership.greek_symbol.toLowerCase() }/>
        </td>
        <td className="large-3 small-3 medium-3" style={this.handleStrikethrough()}> {this.props.reviewership.reviewHistory.count} </td>
        <td className="large-2 small-2 medium-2 text-left">
          {this.renderQuickviewButton()}
        </td>
      </tr>
    )
  }
})

export default Row;