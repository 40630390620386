import React, { useEffect } from 'react';
import Filters from './Filters';
import SpecialtyPromotion from './SpecialtyPromotion';

const Sidebar = ({categories, contentTypes, updateTypes, updateCategories, submitUpdate, categoryIds, id, categoryIdPreSelected}) => {

const renderPublisherHealthTag = () => {
  if (categoryIdPreSelected) {
    const script = document.createElement("script");
    script.setAttribute("src", "https://phx.publisherhealth.com/assets/widget.js?account_id=cureus-widget");
    let ele = document.querySelector(".publisher-health-ad")
    ele.appendChild(script);
  }
}

  useEffect(() => {
    renderPublisherHealthTag()
  }, []);

  const publisherHealthAd = () => {
    if (categoryIdPreSelected) {
      return (
        <div className="row">
          <div className=" small-12 columns">
            <div className='cureus-ad-wrapper'>
              <div className='cureus-ad publisher-health-ad square'>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="small-2 columns show-for-large-up">
      <Filters
        categories={categories}
        categoryIds={categoryIds}
        contentTypes={contentTypes}
        updateTypes={updateTypes}
        updateCategories={updateCategories}
        submitUpdate={submitUpdate}
        id={id}
        categoryIdPreSelected={categoryIdPreSelected}
      />
      {categoryIdPreSelected &&
        <SpecialtyPromotion categoryIdPreSelected={categoryIdPreSelected} />
      }
      {publisherHealthAd()}
    </div>
  )
}

export default Sidebar