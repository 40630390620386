var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal';

const ArticleTypeModal= createReactClass({

  getInitialState: function() {
    return({
      types: this.props.types
    })
  },

  mapTypes: function() {
    var self = this;
    return Object.values(self.state.types).map(function(el) {
      return(
          <label key={el.underscore}>
            <input type='checkbox' onChange={function(){ self.handleChange(el.classType)}} checked={el.checked}/>
            &nbsp;{el.name}
          </label>
      )
    })
  },

  handleChange: function(classType) {
    var self = this;
    var updatedTypes= self.state.types;
    updatedTypes[classType].checked = !updatedTypes[classType].checked
    self.setState({types: updatedTypes}, function(){self.props.handleChange(updatedTypes)});
  },

  handleClose: function() {
    Modal.close({onCloseInstant: this.props.resetPage});
  },

  render: function(){
    return(
      <div data-reveal  className='cureus-custom-multi-select-modal reveal-modal'>
        <div className='modal-heading'>
          <div className='modal-title'>
            Choose Types
          </div>
          <div className='modal-close' >
            <a href='#' className='button tiny' onClick={this.handleClose} style={{height: 'auto', width: 'auto'}}>
              Filter
            </a>
          </div>
        </div>
        <div className='modal-body-wrap'>
          <div className='modal-body'>
            <div className='modal-content'>
              {this.mapTypes()}
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default ArticleTypeModal;
