var createReactClass = require('create-react-class');
import React from 'react';

const ThoughtsModal = createReactClass({
  revQuestionDetails: function(event) {
    return (
      <td>
        <div>
          <strong>Brief Summary:</strong> {event.summary}
        </div>
        <div>
          <strong>Brief Assessment:</strong> {event.assessment}
        </div>
        <div>
          <strong>Quality:</strong> {event.quality}
        </div>
        <div>
          <strong>Credibility/Value:</strong> {event.credibility}
        </div>
        <div>
          <strong>Optional Comments:</strong> {event.comment}
        </div>
      </td>
    )
  },

  renderTableRows: function() {
    var self = this;
    return self.props.events.map(function(event) {
      if(event.reviewership_question) {
        return(
          <tr key={event.id}>
            {self.revQuestionDetails(event.reviewership_question)}
            <td>{event.created_at}</td>
          </tr>
        )
      }
      if (event.message === null) {
        return (
          <tr key={event.id}>
            <td>{"No message..."}</td>
            <td>{event.created_at}</td>
          </tr>
        )
      } else {
        return (
          <tr key={event.id}>
            <td>{event.message}</td>
            <td>{event.created_at}</td>
          </tr>
        )
      }
    })
},

  render: function() {
    return (
      <div className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>{this.props.reviewerName} Comments</h4>
            <table className="full-width table-wrapper">
                <tbody>
                 {this.renderTableRows()}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    )
  }
})

export default ThoughtsModal;