
var createReactClass = require('create-react-class');
import React from 'react';

const CompetitionTile = createReactClass({
  handleClick: function(e) {
    this.props.onClick();
    e.preventDefault();
  },

  render: function() {
    if(this.props.active) {
      var cx = 'card-tile active';
    } else {
      var cx = 'card-tile';
    }
    return (
      <div className={ cx } onClick={ this.handleClick }>
        <div className="thumbnail">
          <div className="card-tile-thumbnail-mask"/>
          <img src={ this.props.competition.thumbnail_url }/>
        </div>

        <div className='details'>
          <div className='title'>
            { this.props.competition.title }
          </div>

          <div className='deadline'>
            Submission deadline: { this.props.competition.end_date }
          </div>
        </div>
      </div>
    )
  }
});

export default CompetitionTile;
