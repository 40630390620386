var createReactClass = require('create-react-class');
import React from 'react';

const Keywords = createReactClass({
  render: function(){
    return (
      <div>
        <h4 className="reg">How should I format my keywords?</h4>
        <h5>
          <i className="far fa-check-circle good"/>
          &nbsp;Correct
        </h5>
        <p>meningioma, HBV, degenerative lumbar disorders</p>
        <h5>
          <i className="fa fa-times-circle bad"/>
          &nbsp;Incorrect
        </h5>
        <p>Meningioma, H.B.V., DEGENERATIVE LUMBAR DISORDERS</p>
      </div>
    );
  }
});

export default Keywords;