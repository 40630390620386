import React from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';
import { Cureus } from '../../Cureus';
import Modal from '../Modal';
import ChannelOfficialRejectionModal from './ChannelOfficialRejectionModal';

const MemberRequestsMain = ({fetchMemberRequestsPath}) => {

  const memberRequestsColumns = [
    { title: "Date of request", data: 'date_requested' },
    { 
      title: "Channel",
      data: 'channel_name',
      render: (row) => {
        return `<a class='column-hoverable' target='_blank' href='/admin/channels/${row.channel_id}'>${row.channel_name}</a>`
      }
    },
    { 
      title: "Name", 
      data: 'full_name', 
      width: '150px',
      render: (row) => {
        return `<a class='column-hoverable' target='_blank' href='/admin/users/${row.user_id}'>${row.full_name}</a>`
      }
    },
    { title: "Specialty", data: 'specialty' },
    { title: "Hospital/Institution", data: 'institution' },
    { title:  "Department/Specialization", data: 'department'},
    { title: "City/Country", data: 'residence' },
    { title: "Type" , data: 'member_type' },
    {
      title: "Action",
      class: "action-buttons-container",
      render: (row) => {
        return `
          <a class="text-center button success add" id=${row.channel_management_id}><i class="fas fa-check"></i></a>
          <a class="text-center button edit" id=${row.channel_management_id}>Reject</a>
        `
      }
    }
  ];

  const tableWrapperHideProp = () => {
    $(oSettings.nTableWrapper).find('.dataTables_info').hide();
  };

  const tableWrapperShowProp = () => {
    $(oSettings.nTableWrapper).find('.dataTables_info').show();
  };

  const createRowProp = (row) => {
    $(row).attr('id', 'row-' + row.children[8].children[0].id);
  }

  const rowRenderCallback = (row) => {
    let approveBtn = row.children[8].children[0];
    let rejectBtn = row.children[8].children[1];
    let channelManagementId = row.children[8].children[0].getAttribute("id"); 
    
    // Need .off().on() to not send double requests to API due to table rendering issue
    $(approveBtn).off('click').on('click', () => {
      approveOrRejectNominee(channelManagementId, "approve");
    });
  
    $(rejectBtn).off('click').on('click', () => {
      Modal.open({
        children: 
        <ChannelOfficialRejectionModal
          approveOrRejectNominee={approveOrRejectNominee}
          channelManagementId={channelManagementId}
          action="reject"
        />
      })
    });
  };

  const renderData = (action, message) => {
    return action === "reject" ? { message } : {};
  }

  const approveOrRejectNominee = (channelManagementId, action, message) => {
    $.ajax({
      type: 'PUT',
      url: `/admin/channel_managements/${channelManagementId}/${action}`,
      data: renderData(action, message),
      dataType: 'json',
      success: function(res) {
        var table = $('#members-requests').DataTable();
        let row = $(`#row-${channelManagementId}`);
        table.fnDeleteRow(row[0]);
        if (action === 'approve') {
          Cureus.Flash.success("Member has been approved.");
        } else {
          Cureus.Flash.success("Member has been rejected.");
        }
      },
    });
  }

  const renderDomProp = () => {
    return `<"row"<"large-5 columns"f>><"table"t>r<"row bottom"<"large-4 columns page-nums" <"pull-left"p><"pull-left showing-info"i>><"large-3 columns end show-amount"<"text-right"l>>>`;
  }

  return(
    <>
      <DynamicJqueryTable 
        columnsConfig={memberRequestsColumns}
        path={fetchMemberRequestsPath}
        tableId="members-requests"
        tableTitle={""}
        noDatepicker={true}
        searchLabel={""}
        tableWrapperHideProp={tableWrapperHideProp}
        tableWrapperShowProp={tableWrapperShowProp}
        rowRenderCallback={rowRenderCallback}
        createRowProp={createRowProp}
        renderDomProp={renderDomProp}
      />
    </>
  )
}

export default MemberRequestsMain;