import React from 'react';
import Card from '../Card';

const PromotingYourResearch = () => {

  var card1 = {
    type: 'paragraph',
    title: {
      text: 'Article Sharing Guide',
      href: '/promotion_guide'
    },
    body: {
      text: 'Help your article reach its full potential with these simple strategies to increase your audience and citations.'
    }
  };

  var card2 = {
    type: 'paragraph',
    title: {
      text: 'Article Promotion',
      href: '/article_promotion'
    },
    body: {
      text: 'Double your audience with our paid social media promotion (for eligible articles only).',
      textTwo: '*Please note: articles containing images of genitalia are not eligible for Article Promotion due to social media image policies.'
    }
  };

  return (
        <React.Fragment>
      <h1>Promoting Your Research</h1>
      <p>You've worked hard to conduct your research and publish your results. Now you're ready to go the extra mile and promote your research to medical professionals all over the world. Make sure to read our Article Sharing Guide to learn how to maximize your audience. And did you know that Cureus offers paid social media promotion for eligible published articles?</p>
      <br/>
      <div className='row'>
        <div className='medium-5 columns'>
          <Card
            type={ card1.type }
            title={ card1.title }
            body={ card1.body }/>
        </div>
        <div className='medium-5 medium-offset-1 columns'>
        <Card
            type={ card2.type }
            title={ card2.title }
            body={ card2.body }/>
        </div>
      </div>

      <div>
        <h3>Cureus Video Abstracts: A Complete Guide</h3>
        <p>
        As technology becomes more integrated into our lives, our attention spans have shortened, and we've grown accustomed to receiving information in condensed formats. This shift in information consumption extends to scientific research, necessitating a change in how content is presented. That’s why Cureus provides authors with the opportunity to create and submit a video abstract to be posted on the Cureus YouTube channel. We’ll share your video with over 60,000 people across our Facebook, YouTube, Twitter, LinkedIn, and Instagram accounts. <a href='https://www.youtube.com/playlist?list=PLzbC4LSc0iMO68Bo5u7kkazFdaLQix1Oc'>Click here to view some examples!</a>
        </p>
        <h2>What is a Video Abstract</h2>
        <p>A video abstract is a supplementary feature for peer-reviewed articles designed to capture viewers' interest. The purpose of a video abstract is to showcase the key findings of your research or case report. A video abstract should present the main results and conclusions while also offering something unique compared to the written article. A successful video abstract engages viewers by narrating a story, often starting with a specific discovery, question, or theme around which the story develops.</p>
        <p>Creating a compelling video about a serious subject doesn't require a large budget or cutting-edge technology. Modern technology allows for the production of high-quality videos at a relatively low cost, as detailed below. The focus should be on capturing your audience's attention and inspiring them to read your article. If you would rather not appear in the video, you can easily record a voiceover using video footage from stock content websites.</p>

        <h2>How to Create an Effective Video Abstract</h2>
        <p>You will be in charge of the project from start to finish. Be aware that our team will evaluate all video abstracts for appropriateness, and posting is not guaranteed. In some instances, you may be asked to make minor adjustments to your video. Video abstract submissions will only be accepted after your paper has been published. Submissions prior to article publication will not be considered.</p>
        <p>Below you’ll find our step-by-step instructions for creating your video abstract. If you have any questions during the process, please reach out to <a href='mailto:social@cureus.com'>social@cureus.com</a>.</p>
        <p><strong>Preparing to Record</strong></p>
        <ol className='decimals'>
          <li>Write a script using conversational language as if explaining your research to a non-expert. Video abstracts target a scientific audience but should be accessible to a broader audience, including journalists and students.</li>
          <li>While writing your paper, consider potential footage and other media for your video abstract. You can often find relevant stock videos on websites like Adobe that can be used to help make your video more engaging.</li>
          <li>To ensure your message is effectively communicated and keeps the viewer's attention, aim for a concise video, no longer than 4 minutes. The full research details can be found in the written article.</li>
        </ol>
        <p><strong>The Recording Process</strong></p>
        <ol className='decimals'>
          <li>Find a quiet place to film with ample lighting.</li>
          <li>Maintain a relaxed and friendly tone in your video.</li>
          <li>Introduce yourself (your name and institution) and if you’re a medical student please also state the name of the senior author. (Multiple authors per video are welcome.)</li>
          <li>Please stay on topic and stick to the subject matter of the article.
            <ol className='alpha'>
              <br/>
              <li>Why is this topic of interest to the general public or the medical community?</li>
              <li>What was the conclusion of your research?</li>
              <li>What will you do next? Will you expand on this research? Are you starting new research?</li>
            </ol>
          </li>
        </ol>
        <p><strong>Submitting Your Video</strong></p>
        <p>You can use <a href="https://www.dropbox.com/" target="_blank">Dropbox</a>, <a href="https://www.google.com/drive/" target="_blank">Google Drive</a>, or <a href="https://wetransfer.com/" target="_blank">WeTransfer</a> to send your video and (if applicable) separate audio files) to <a href='mailto:social@cureus.com'>social@cureus.com</a>. Please include the title of and link to your article and any social media handles for you, your co-authors, and your institution so we can tag everyone properly.</p>
        <h2>Budget Solutions for Video Abstracts</h2>
        <p><strong>Creating a comprehensive story with top-quality elements (high-end budget)</strong></p>
        <p>If you have access to advanced equipment, whether personally, through your university's media department, or by outsourcing production, you can consider incorporating some or all of the following features:</p>
        <ul className='disc'>
          <li>Author(s) appearing on camera</li>
          <li>Engaging footage and/or graphics</li>
          <li>3D animations</li>
          <li>Re-enactments to demonstrate medical procedures or consultations</li>
          <li>Contextual footage (laboratories, key aspects of the study)</li>
          <li>General footage relevant to the medical or scientific context</li>
        </ul>
        <p><strong>Innovative storytelling with limited video elements (medium budget)</strong></p>
        <p>A video abstract in this budget range may feature the author(s) providing a voiceover alongside professional animations. (Avoid using PowerPoint presentations or low-quality images/graphics.) Potential components include:</p>
        <ul className='disc'>
          <li>Author(s) appearing on camera</li>
          <li>Engaging footage</li>
          <li>Graphics (with simple animations rather than static images)</li>
          <li>Contextual footage (laboratories, key aspects of the study)</li>
          <li>General footage relevant to the medical or scientific context</li>
        </ul>
        <p><strong>Utilizing readily available technology for concise storytelling (low budget)</strong></p>
        <p>Creating a high-quality video doesn't require expensive equipment. For those with limited budget or time, we welcome videos recorded using:</p>
        <ul className='disc'>
          <li>Smartphones/tablets (see tips for shooting with a smartphone or tablet)</li>
          <li>Webcam or video calls on platforms like Skype or Google Hangout</li>
        </ul>
        <p>
          These videos should be shorter, as capturing and maintaining viewer attention is more challenging with amateur footage. Consider presenting your paper's highlights in a brief and engaging manner. The absence of advanced video tools makes focusing on storytelling even more crucial for holding your audience's attention.
        </p>
        <p>
          This approach is best suited for videos featuring only the author speaking to the camera. You can record a quick video with your phone using the below suggestions. Whenever possible, consider incorporating additional elements into your video, such as images or video clips.
        </p>
        <p><strong>Tips for Shooting with a Smartphone or Tablet</strong></p>
        <ol className='decimals'>
          <li>Position the smartphone horizontally to record in landscape mode (widescreen).</li>
          <li>Ensure the camera is set to Video Mode.</li>
          <li>Use a hand-grip or tripod mount to keep the image steady and position the phone at eye level.</li>
          <li>To lock exposure and focus, tap on the screen to select the desired focus point, then tap and hold until you see 'AF.../Lock,' indicating your settings are saved. To create some depth of field, sit farther from your background and focus on yourself or the top of your chair if you're recording alone.</li>
          <li>Enable Airplane mode during recording to avoid interruptions.</li>
          <li>If using a microphone isn't possible, record your audio on a separate device positioned closer to you (but out of the frame) and later synchronize it with the video or send us the audio file separately.</li>
          <li>Consider using popular apps like:
            <ol className='alpha'>
              <br/>
              <li>iPhone: <a href="https://apps.apple.com/us/app/easy-audio-recorder-lite/id845313878?mt=12" target="_blank">Easy Audio Recorder Lite</a></li>
              <li>Android phones: <a href="https://play.google.com/store/apps/details?id=com.media.bestrecorder.audiorecorder&hl=en_US&gl=US&pli=1" target="_blank">Voice Record</a></li>
            </ol>
          </li>
        </ol>
        <p><strong>Sound quality is just as important as image quality</strong></p>
        <p>Having top-notch sound quality is essential because a significant portion of video content today is consumed using headphones. Poor sound quality can be particularly irritating when directly transmitted to your ears. If the audio is subpar, the overall video quality is negatively impacted, which might lead viewers to stop watching.</p>
        <p><strong>Tips for optimal audio quality</strong></p>
        <ul className='disc'>
          <li>Steer clear of noisy clothing and refrain from touching the microphone while recording, as it can pick up the sound.</li>
          <li>Avoid striking the table where the microphone is placed, as the noise can be captured by your camera or microphone.</li>
        </ul>
      </div>


    </React.Fragment>
  )
}

export default PromotingYourResearch