
var createReactClass = require('create-react-class');
import React from 'react';

const Row = createReactClass({
  getInitialState: function() {
    return({
      hasNoAffiliation: this.props.hasNoAffiliation,
      hasNoCity: null,
      hasNoCountry: null
    })
  },

  componentDidMount: function() {
    if (this.state.hasNoAffiliation) {
      this.setState({
        hasNoCity: true,
        hasNoCountry: true
      })
    }
  },

  renderSelectTag: function() {
    return (
      <select style={this.applyRedborder('hasNoCountry')} name="user[affiliations][][country]" id="user_affiliations_0_country_name" defaultValue={this.props.affiliationObject.affiliation.country} onChange={this.removeBorderCountry}>
        <option>Select Country</option>
        { this.renderSelectTagOptions() }
      </select>
    )
  },

  renderSelectTagOptions: function() {
    return this.props.countryOptions.map( function(e) {
            return <option key={e.value} value={e.value}>{e.label}</option>
           })
  },

  isPrimaryAffiliation: function() {
    return this.props.affiliationObject.affiliation.isPrimary ? "checked" : false
  },

  removeAffiliationRow: function() {
    this.props.removeAffiliation(this.props.affiliationObject.affiliation.id)
  },

  applyRedborder: function(inputField) {
    if (this.state[inputField]) {
      return {border: '2px solid #CA3145'}
    }
  },

  applyAsterisk: function(inputField) {
    if (this.state[inputField]) {
      return <span className="red-asterisk">   *</span>
    }
  },

  removeBorderCity: function() {
    let city = document.getElementById("user_affiliations_" + this.props.index + "_city")
    if (city.value.length !== 0) {
      this.setState({hasNoCity: false})
    } else {
      this.setState({hasNoCity: true})
    }
  },

  removeBorderCountry: function() {
    let country = document.getElementById("user_affiliations_0_country_name" )
    if (country.value === 'Select Country') {
      this.setState({hasNoCountry: true})
    } else {
      this.setState({hasNoCountry: false})
    }
  },

  render: function() {
    return (
        <div>
          <div className="row">
            <div className="large-6 medium-6 columns">
              <label htmlFor={"user_affiliations_" + this.props.index + "_institution_name"}><br/>Academic institution/Hospital*</label>
              <input type="text" name="user[affiliations][][institution_name]" id={"user_affiliations_" + this.props.index + "_institution_name"} defaultValue={this.props.affiliationObject.affiliation.institutionName} data-autocomplete-url={this.props.autocompleteUrl} className="ui-autocomplete-input" autoComplete="off"/>
            </div>
            <div className="large-6 medium-6 columns">
              <label htmlFor={"user_affiliations_" + this.props.index + "_department_name"}><br/>Department/Specialization*</label>
              <input type="text" name="user[affiliations][][department_name]" id={"user_affiliations_" + this.props.index + "_department_name"} defaultValue={this.props.affiliationObject.affiliation.departmentName} />
            </div>
          </div>
          <div className="row">
            <div className="large-6 medium-6 columns">
              <label className="inline-label" htmlFor={"user_affiliations_" + this.props.index + "_country"}>Country</label> {this.applyAsterisk('hasNoCountry')}
              { this.renderSelectTag(this.props.affiliationObject) }
            </div>
            <div className="large-6 medium-6 columns">
              <label className="inline-label" htmlFor={"user_affiliations_" + this.props.index + "_city"}>City</label> {this.applyAsterisk('hasNoCity')}
              <input style={this.applyRedborder('hasNoCity')} type="text" name="user[affiliations][][city]" id={"user_affiliations_" + this.props.index + "_city"} defaultValue={this.props.affiliationObject.affiliation.city} onChange={this.removeBorderCity}/>
            </div>
          </div>
          <div className="row affiliation-actions">
            <div className="large-6 medium-6 small-6 columns">
              <input type="radio" name="user[affiliations][][is_primary]" id={"user_affiliations__is_primary_" + this.props.index} defaultChecked={this.isPrimaryAffiliation(this.props.index)}/>
              <label className="inline" htmlFor={"user_affiliations__is_primary_" + this.props.index}>Make this my primary affiliation</label>
            </div>
            <div className="large-6 medium-6 small-6 columns">
              <div className="right">
                <a className="button tiny secondary" data-remove-multi-add="true" href="javascript:void(0)" onClick={this.removeAffiliationRow}><i className="far fa-trash-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
    )
  }
})

export default Row;