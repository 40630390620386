import React, { Component } from 'react';
import Modal from '../Modal';
import OnDemandModal from './OnDemandModal'
class Main extends Component {
  openModal = () => {
    Modal.open({
      children: <OnDemandModal 
                  articlesPath={this.props.articlesPath}
                  acceptancePath={this.props.acceptancePath}
                  cooldown={this.props.cooldown}
                  currentUserId={this.props.currentUserId}
                />
		});
  }

  render() {
    return (
      <a className="button alternate review-now" onClick={this.openModal}><i class="far fa-file-alt"></i> Review Now</a>
    )
  }
}

export default Main;