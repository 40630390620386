var createReactClass = require('create-react-class');
import React from 'react';

const Search = createReactClass({
  updateSearchTerm: function(e) {
    e.preventDefault();
    this.props.updateSearchTermState(e.target.value);
  },

  render: function() {
    return(
      <div className='email-list-tool-search'>
        <input type='text' placeholder='Search Email Lists by Title' onChange={this.updateSearchTerm} />
      </div>
    )
  }
})

export default Search;