var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';

const VersionsTable = createReactClass({
  renderVersions: function() {
    var self = this;
    return self.props.optionsForVersionSelect.map(function(version, idx) {
      return (
        <Row 
          snapshotId={self.props.snapshotId}
          version={version}
          handleClick={self.handleClick}
          key={idx}
          index={idx}
          fetchSnapshot={self.props.fetchSnapshot}
        />
      )
    })
  },

  render: function() {
    return(
      <div className="row">
        <div className="small-12 columns">
          <div className="annotations-wrapper">
            <div className="annotations-overlay">
              <div id="spinner" />
            </div>
            <table id="versions-table">
              <thead>
                <tr>
                  <th>Selected</th>
                  <th>Version</th>
                  <th>Date</th>
                  <th>Editorial State</th>
                  <th>Reviewer Comments</th>
                </tr>
              </thead>
              <tbody>
                {this.renderVersions()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
})

export default VersionsTable;