var createReactClass = require('create-react-class');
import React from 'react';
import Hero from './Hero';
import { Cureus } from '../../Cureus';

const Main = createReactClass({
  getInitialState: function() {
    return {
      heros: this.props.heros || []
    }
  },

  componentDidMount: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      url: self.props.heroPath,
      dataType: 'json',
      success: function(res) {
        self.setState({
          heros: res.heros,
        }, function() {
          Cureus.SplitButton.init()
        })
      }
    });
  },

  componentDidUpdate: function() {
    Cureus.SplitButton.init()
  },

  renderHeros: function(){
    var self = this;
    return self.state.heros.map(function(hero) {
      return <Hero key={hero.id} hero={hero} onDelete={self.onDelete}/>
    })
  },

  handleAdd: function() {
    var heros = this.state.heros
    heros.push({id: null, heroImage: null, fontHex: null, active: false})
    this.setState({heros: heros})
  },

  onDelete: function(heros) {
    this.setState({heros: heros}, function() {
      Cureus.Flash.success('Hero Deleted')
    })
  },

  render: function(){
    return (
      <div className="small-12 columns">
        <h3>Homepage Hero Dashboard</h3>
        {this.renderHeros()}
        <div className="row">
          <div className="pull-left">
            <a className="button" onClick={this.handleAdd}>Add Hero</a>
          </div>
        </div>
      </div>
    );
  }
})

export default Main;