import React, { useState, useEffect } from 'react';
import PerformanceTable from './PerformanceTable';
import DateSelector, { currentWeek, currentMonth, lastMonth, lastThirtyDays, lastSixMonths } from '../../StatsWidget/DateSelector';
import Modal from '../../Modal';
import EmailReminderModal from '../../../components/AdminEmailReminder/EmailReminderModal';

const PerformanceSection = () => {
  const initialDateRanges = [currentWeek(), currentMonth(), lastMonth(), lastThirtyDays(), lastSixMonths()];
  const [selectedRange, setSelectedRange] = useState(lastThirtyDays().label);
  const [from, setFrom] = useState(lastThirtyDays().from);
  const [to, setTo] = useState(lastThirtyDays().to);
  const [data, setData] = useState([]);
  const [fetchCount, setFetchCount] = useState(0);
  const [searchDataType, setSearchDataType] = useState('articles_completed');

  useEffect(() => {
    fetchData();
  }, [fetchCount, searchDataType]);

  useEffect(() => {
    enableDisableMailButton();
  }, [data]);

  const enableDisableMailButton = () => {
    const flattenedData = data.flat();
    const selectedIds = flattenedData.filter( (el) => el.checked === true)
    if (selectedIds.length > 0) {
      $("#performance-section .bulk-email-button").removeClass('disabled');
    } else {
      $("#performance-section .bulk-email-button").addClass('disabled');
    }
  }

  const url = () => {
    let link = `/admin/ae_dashboards/performance?search_data_type=${searchDataType}`
    if (from === undefined || to === undefined) {
      return link
    } else {
      return link + `&start_date=${from}&end_date=${to}`
    }
  }

  const fetchData = () => {
    fetch(url())
      .then(response => response.json())
      .then(data => {setData(data)})
  }

  const renderTables = () => {
    return data.map( (t, index) => {
      return (
        <div className="small-3 columns" key={index}>
          <PerformanceTable setData={setData} data={data} tableNumber={index} />
        </div>
      )
    })
  }

  const renderDateSelector = () => {
    return (
      <div className='small-2 columns date-selector'>
        <DateSelector
          from={from}
          to={to}
          noFloat={true}
          getStats={handleUpdatingDate}
          selectedRange={selectedRange}
          dateRanges={initialDateRanges}
        />
      </div>
    )
  }

  const handleUpdatingDate = (from, to, label) => {
    setFrom(from);
    setTo(to);
    setSelectedRange(label);
    // triggers a re-fetch of the data
    setFetchCount(fetchCount + 1);
  }

  const renderMailButton = () => {
    return (
      <div onClick={handleOpenModal} className={`button primary small bulk-email-button disabled`}  >
        <i className="fa fa-envelope"></i>
      </div>
    );
  }

  const handleOpenModal = () => {
    $.ajax({
      type: 'GET',
      url: "/admin/reminders/template",
      data: {
        templateName: "ApprovalEditorSuite",
        templateData: {resource_ids: selectedEditorIdsJoined()},
        aeLeaderBoard: true,
      },
      success: function(data) {
        let props = {
          templateName: "ApprovalEditorSuite",
          templateData: {resource_ids: selectedEditorIdsJoined()},
          createPath: "/admin/reminders",
          data: data,
          async: true
        }
        Modal.open({
          children: <EmailReminderModal {...props} />
        });
      }
    });
  }

  const selectedEditorIdsJoined = () => {
    const flattenedData = data.flat();
    const selectedIds = []
    flattenedData.forEach( (el) => {
      if (el.checked) {
        selectedIds.push(el.id)
      }
    })
    return selectedIds.join(',')
  }

  return (
    <div id="performance-section">
      <div className="row">
        <div className="small-12 columns header">
          <h3>Performance Brackets</h3>
          <select onChange={(e) => setSearchDataType(e.target.value)} className="contribution-type">
            <option value="articles_completed">Contribution Volume</option>
            <option value="articles_assigned_expired">Expirations</option>
            <option value="days_active">Days active</option>
          </select>
          {renderDateSelector()}
          {renderMailButton()}
        </div>

      </div>
      <div className="row">
        {renderTables()}
      </div>
    </div>
  );
};

export default PerformanceSection;