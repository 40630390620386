import React, { useEffect } from 'react';
import Chart from 'chart.js-v3/auto';

const LineChart = ( { ctx, datasets, title, yLabel, xLabel} ) => {

  useEffect(() => {
    $(`#${ctx}`).remove();
    $(`#${ctx}-container`).append(`<canvas id='${ctx}'></canvas>`);
    new Chart(ctx, {
      type: 'line',
      data: {
        datasets: datasets
      },
      options: {
        elements: {
          point:{
              radius: 3
          }
        },
        plugins: {
          title: {
            display: false,
            text: title
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: yLabel
            }
          },
          x: {
            title: {
              display: true,
              text: xLabel
            }
          }
        }
      }
    })
  })


  return (
    <div id={`${ctx}-container`}><canvas id={ctx}></canvas></div>
  )

}

export default LineChart;