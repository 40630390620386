
var createReactClass = require('create-react-class');
import React from 'react';

const Main = createReactClass({
  getInitialState: function() {
    return {
      loading: true
    }
  },

  componentDidMount: function() {
    var self = this
    $.ajax({
      type: "GET",
      dataType: "json",
      url: '/dashboard/metrics',
      data: {overall: true},
      success: function(data) {
        self.setState({stats: data.stats, loading: false})
      }
    })
  },

  renderStats: function(number, rank, title, cssClass = "") {
    if (this.state.loading) {
      return;
    } else {
      return (
        <div className={`large-3 columns ${cssClass} metrics-box`}>
          <p className="header">{title}</p>
          {this.renderTotal(number)}
          {this.renderRank(rank)}
        </div>
      )
    }
  },

  renderTotal: function(stat) {
    return <h2>{this.state.stats[stat]}</h2>
  },

  renderRank: function(stat) {
    let stats = this.state.stats
    if (stats[stat]) {
      return (
        <h6 className="rank">
          {stats[stat]}
        </h6>
      ) 
    } else {
      return (
        <h6 className="rank">
          &#8211;
        </h6>
      )
    }
  },

  renderSeeModalBox: function() {
    if (!this.state.loading) {
      return (
        <div>
          <a href='#' data-reveal-id='see_modal'>
            <div className="large-3 large-offset-1 columns metrics-box see end">
              <h6 className="reg blue">What would you like to see here?</h6>
              <i className="fa fa-plus-circle fa-2x"></i>
            </div>
          </a>
        </div>
      )
    }
  },

  handleLoading: function() {
    if (this.state.loading) {
      return <p>&nbsp; Loading...</p>
    }
  },

  render: function() {
    return(
      <div>
        <div className="row">
          <p>
            See how you measure up against the rest of the Cureus community with your Cureus totals and individual article metrics along with corresponding Cureus user rank. Data may be delayed up to one hour.
          </p>
          <h4>Overall Metrics</h4>
          {this.handleLoading()}
          </div>
          <div className="row">
            {this.renderStats('total_articles', 'total_articles_rank', 'Total Published Articles')}
            {this.renderStats('total_articles_views', 'total_articles_views_rank', 'Total Article Views', 'large-offset-1 columns')}
            {this.renderStats('total_pdfs_downloads', 'total_pdfs_downloads_rank', 'Total PDF Downloads', 'large-offset-1 columns end')}
          </div>
          <div className="row">
            {this.renderStats('total_peer_reviews_contributed', 'total_peer_reviews_contributed_rank', 'Peer Review Contributions')}
            {this.renderStats('total_siqs_contributed', 'total_siqs_contributed_rank', 'SIQ Contributions', 'large-offset-1 columns')}
            {this.renderStats('total_citations', 'total_citations_rank', 'Total Citations', 'large-offset-1 columns end')}
          </div>
      </div>
    )
  }
})

export default Main;


