var createReactClass = require('create-react-class');
import React from 'react';

const EditModal = createReactClass({
  getInitialState: function() {
    var affiliation = this.props.affiliation
    return ({
      institution_name: affiliation[0],
      name: affiliation[1],
      affiliation_country: affiliation[2],
      country: affiliation[3],
      affiliation_city: affiliation[4],
      city: affiliation[5],
      partial_ratio: affiliation[6],
      token_set_ratio: affiliation[7],
      token_sort_ratio: affiliation[8],
      supported_type: affiliation[9],
      valid: affiliation[10],
      address: affiliation[11],
      state: affiliation[12],
      zip: affiliation[13],
      institution_id: affiliation[14]
    })
  },

  componentDidMount: function() {
  },

  handleChanges: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  updateData: function() {
    var self = this;
    var payload = {
      data: {
        name: self.state.name,
        country: self.state.country,
        city: self.state.city,
        zip: self.state.zip,
        state: self.state.state,
        valid: self.state.valid
      }
    }
    $.ajax({
      type: "PUT",
      url: "/admin/affiliations/" + self.state.institution_id,
      data: payload,
      success: function(_) {
        location.reload()
      }
    })
  },

  getSelectedValid: function(b) {
    if (self.state.valid == b) {
      return "selected"
    }
    return ""
  },

  render: function() {
    var self = this;
    return(
      <div className="reveal-modal middling" id="edit-affiliation-modal" data-reveal ref="container">
        <div className="modal-body">
          <div className="modal-close">
            <a href="#" className="cancel-modal">
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content middling">
            <h4>Edit Clean Affiliation Data</h4>
            <strong>User Inputted Data</strong>
            <br/>
            {self.state.institution_name}
            <br/>
            {self.state.affiliation_city}, {self.state.affiliation_country}
            <br/>
            <strong>Parsed Info</strong>
            <br/>
            Partial Ratio: {self.state.partial_ratio}
            &emsp;
            Token Set Ratio: {self.state.token_set_ratio}
            &emsp;
            Token Sort Ratio: {self.state.token_sort_ratio}
            <br/>
            Supported Type: {self.state.supported_type.toString()}
            <br/>
            <strong>Google Search Address</strong>
            <br/>
            {self.state.address}
            <label><strong>Name</strong>
              <input id="name" defaultValue={self.state.name} type="text" onChange={self.handleChanges} name="name">
              </input>
            </label>
            <label><strong>City</strong>
              <input id="city" defaultValue={self.state.city} type="text" onChange={self.handleChanges} name="city">
              </input>
            </label>
            <label><strong>Country</strong>
              <input id="country" defaultValue={self.state.country} type="text" onChange={self.handleChanges} name="country">
              </input>
            </label>
            <label><strong>State</strong>
              <input id="state" defaultValue={self.state.state} type="text" onChange={self.handleChanges} name="state">
              </input>
            </label>
            <label><strong>Zip</strong>
              <input id="zip" defaultValue={self.state.zip} type="text" onChange={self.handleChanges} name="zip">
              </input>
            </label>
            <label><strong>Valid?</strong>
            <select id="valid" name="valid" value={self.state.valid == null ? 'null' : self.state.valid} onChange={self.handleChanges}>
              <option value="true">True</option>
              <option value="false">False</option>
              <option value="null">Unknown</option>
            </select>
            </label>
          </div>

          <div className="modal-footer">
            <div className="row">
              <a className="button small cancel-modal">Cancel</a>
              <a className="button small" onClick={self.updateData}>Update</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default EditModal;