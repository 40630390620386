var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const PreferredEditingModal = createReactClass({
  renderCopy: function() {
    return this.props.optional ? 
    (
      <div>
        <p>Tired of the back and forth with our editors? Would you rather we do the work for you? While not required, our Preferred Editing service is available for purchase.</p>
        
        <p>All language and formatting errors will be fixed by our editorial team. (If our editors have any questions about the scientific content of your article, you may be asked to clarify or revise.) Only three actions required: submit article, revise article after peer review, hit the publish button.</p>
      </div>
    ) :
    (
      <div>
        <p>Our Preferred Editing service is required in order to proceed with your manuscript as detailed in your deferral email. Preferred Editing helps to defray the considerable expenses associated with editorial review before and after peer review and copy editing prior to publication. Learn more about our Preferred Editing service and the cost calculations <a href="/cureus_editing_services" target="_blank"><strong>here</strong></a>.</p>
        <p style={{'margin-bottom': '5px'}}>Language and formatting errors will be fixed by our editorial team with the following exceptions requiring your revision:</p>
        <ul style={{'padding-left': '10px'}}>
          <li>Scientific content issues that can only be addressed by the authors</li>
          <li>Blurry, stretched, or otherwise illegible figures</li>
          <li>Tables with multiple values in the same cell</li>
          <li>References that are missing required information</li>
        </ul>

        <p><strong>Do you need to customize your receipt? (optional)</strong></p>
        <p>Your emailed receipt will contain the following information: service purchased, article title, order number, order date, payment type, total paid. Please enter any additional information needed in order to receive reimbursement from your institution (if provided). <strong><u>This information will appear in your receipt email exactly as entered below.</u></strong></p>
        <textarea name="custom_receipt" onChange={this.props.handleChange}/>
      </div>
    )
  },
  
  render: function() {
    let optional = this.props.optional ? ' (optional)' : ''
    return (
      <div className='modal-content'>
        <h4 className='reg'>
          {"Purchase Preferred Editing Service" + optional}
        </h4>
        <h5 className="reg">“{this.props.title}”</h5>
        {this.props.renderPricingInfo()}
        {this.renderCopy()}
      </div>
    )
  }
})

export default PreferredEditingModal;