import React, { useEffect, useState } from 'react';
import Table from "./Table"

const Main = () => {
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect( () => {
    fetchPosts()
  }, [])

  const fetchPosts = () => {
    $.ajax({
      type: 'GET',
      dataType: "json",
      success: function(data) {
        setPosts(data.posts)
        setIsLoading(false)
      }
    })
  }

  return (
    <>
      <h2>Cureus Social Media Autoposts</h2>
      {isLoading &&
        <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>}

      <Table
        posts={posts}
      />
    </>
  )
}


export default Main;