import React, { useState } from 'react';

const OverviewInfoRow = ({label, imgUrl, details}) => {


  return (
    <>
      <div className='row overview-gray-text'>
        <img className="about-icon" src={imgUrl} />
        <p className='no-margin'>{label}</p>
      </div>
      <h6>{details}</h6>
    </>
  )
}

export default OverviewInfoRow;