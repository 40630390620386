const AttachmentMixin = {
  getInitialState: function() {
    return {
      errorMessage: "",
      isUploading: false,
      isAnimating: false
    }
  },

  handleFileSelect: function() {
    this.setState({isUploading: true, finishedUploading: false});
  },

  handleProgress: function(bytesReceived, bytesExpected) {
    var self = this;
    var $progressBar = $(self.refs.progressBar);
    var percentComplete = (bytesReceived * 100) / bytesExpected;

    self.setState({isAnimating: true});
    $progressBar.animate({width: percentComplete + "%"}, 3000, function() {
      self.setState({isAnimating: false, finishedUploading: true});
    });
  },

  handleSuccess: function(assembly) {
    var self = this;
    setTimeout(function() {
      if(!self.state.isAnimating) {
        self.updateResource(assembly);
      } else {
        self.handleSuccess(assembly);
      }
    }, 500);
  },

  handleError: function(error) {
    var self = this;

    if(error.error == "AUTH_EXPIRED") {
      self.setState({errorMessage: "Please refresh the page, and try uploading again."});
    } else {
      self.setState({errorMessage: error.message});
    }
    self.setState({isUploading: false});
  },

  updateResource: function(assembly) {
    var self = this;
    var fileName = assembly.assembly_id + "-" + assembly.uploads[0].name;
    $.ajax({
      type: "PUT",
      url: self.props.updateObjectFilePath,
      data: { object_file: fileName },
      success: function(object) {
        self.setState({errorMessage: null, isUploading: false});
        self.props.onUpdate(object, false);

      },
      error: function(stuff) {
        self.setState({errorMessage: "Oops! The upload has failed, please try again.", isUploading: false});
      }
    });
  }
}

export default AttachmentMixin;