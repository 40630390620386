import React from 'react';
import Modal from '../../Modal';
import List from './List';

const Filters = ({submitSearch, modal, id, userRoles, setUserRoles, setStatuses, statuses}) => {
  const checkedLength = (elements) => {
    return Object.values(elements).filter((obj) => { return obj.checked && obj.id != 0}).length
  }

  const submitUpdateWrapper = (e) => {
    if (modal) {
      Modal.close()
    }
    submitSearch(e)
  }

  return (
    <div id={id} className={(modal ? "new-articles-index" : "small-12 columns show-for-large-up") + " left-sidebar"}>
      <List
        updateList={setUserRoles}
        list={userRoles}
        checkedLength={checkedLength}
        id={id}
        text={"Role"}
      />
      {<List
        updateList={setStatuses}
        list={statuses}
        checkedLength={checkedLength}
        id={id}
        text={"Honors Tier"}
      />}
      <a className="button small apply" onClick={submitUpdateWrapper}>Apply</a>
    </div>
  )
}

export default Filters