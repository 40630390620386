import React, { useEffect } from 'react';
import { Cureus } from '../../../../Cureus';

const SortButton = ({sortBy, setSortBy}) => {
  useEffect(() => {
    Cureus.SplitButton.init();
  }, [])

  const renderSortCheck = (option) => {
    if(sortBy == option) {
      return <i className="fas fa-check"/>
    }
  }

  const renderSortButtons = () => {
    return (
      <>
        <li onClick={() => setSortBy('title')}>
          {renderSortCheck('title')}
          <a>Alphabetical (Default)</a>
        </li>
          <li onClick={() => setSortBy('published_at')}>
          {renderSortCheck('published_at')}
          <a>Launched Date</a>
        </li>
          <li onClick={() => setSortBy('free_articles')}>
          {renderSortCheck('free_articles')}
          <a># of Free Articles</a>
        </li>
          <li onClick={() => setSortBy('published_count')}>
          {renderSortCheck('published_count')}
          <a># of Published Articles</a>
        </li>
      </>
    )
  }

  return (
    <div className="cureus-split-dropdown-wrap sort-button">
      <a className="button secondary" data-cureus-split-dropdown={"sort-button-" + 1}>
        <span><i className="fas fa-sort-amount-down-alt"/></span>
      </a>
      <ul id={"sort-button-" + 1} className="cureus-split-dropdown new-dash">
        <li className="header">
          Sort By
        </li>
        {renderSortButtons()}
      </ul>
    </div>
  )
}

export default SortButton;