var createReactClass = require('create-react-class');
import React from 'react';

const HistoryModal = createReactClass({
  getInitialState: function() {
    return({
      events: []
    })
  },

  componentDidMount: function() {
    var self = this;
    if (self.props.isAuthorship) {
      var path = '/admin/articles/' + this.props.articleId + '/authorships/' + this.props.authorshipReviewershipId + '/get_events'
    } else {
      var path = '/admin/articles/' + this.props.articleId + '/reviewerships/' + this.props.authorshipReviewershipId + '/get_events'
    }

    $.ajax({
      type: 'get',
      url: path,
      success: function(res) {
        self.setState({events: res.events})
      },
      error: function(res) {
      }
    });
  },

  renderBody: function() {
    var self = this;
    if (this.state.events.length) {
      return this.state.events.map(function(event, idx) {
        return(
          <div key={idx + '_event_reviewership_'+ self.props.authorshipReviewershipId}>
            <span className={'label ' +  event.historyClass}>
              {event.historyName}
            </span>
            &nbsp;&mdash;&nbsp;
            {event.createdAt}
          </div>
        )
      });
    } else {
      if (self.props.isAuthorship){
        return <p>There is no record of any reminders sent to this author.</p>
      } else {
        return <p>There is no record of any reminders sent to this reviewer.</p>
      }
    }
  },

  render: function() {
    return(
      <div className='event-history-modal reveal-modal' data-reveal>
        <div className='modal-body'>
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
             <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className='modal-content'>
            {this.renderBody()}
          </div>
        </div>
      </div>
    )
  }
});

export default HistoryModal;