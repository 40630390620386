var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const EditMessageModal = createReactClass({
  getInitialState: function() {
    return ({
      message: this.props.message
    });
  },

  initializeCKEditor: function() {
    new Cureus.CKEditor('edit-message-text', {editor_type: 'static_text', ckeditor: {height: 200}})
  },

  componentDidMount: function() {
    this.initializeCKEditor();
  },

  updateMessage: function(e) {
    var newMessage = CKEDITOR.instances["edit-message-text"].getData();
    this.props.handleUpdateMessage(newMessage);
  },

  render: function() {
    return(
      <div className='reveal-modal middling' data-reveal id='edit-message-modal'>
        <div className='modal-body'>

          <div className='modal-close'>
            <a href='#' className='cancel-modal' >
              <img src="https://public.cureus.com/close_x.png" />
            </a>
          </div>

          <div className='modal-content'>
            <label htmlFor='edit-message-text'>Edit Message</label>
            <textarea id='edit-message-text' value={this.props.message} onChange={this.updateState}/>
            <div className='modal-footer'>
              <a href='#' className='button secondary cancel-modal tiny' >Cancel</a>
              <input type='button' value='Update' className='button primary tiny'  onClick={this.updateMessage}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default EditMessageModal;