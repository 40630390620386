import React, {useState} from 'react';

const AuthorsAndReviewersBanModal = ({banHandler}) => {

  const [banReason, setBanReason] = useState("");
  const [isError, setIsError] = useState(false);

  const handleSubmit = () => {
    if (banReason.trim() === "") {
      setIsError(true);
    } else {
      banHandler(banReason);
      setIsError(false);
    }
  }

  return (
    <div id={"ban-user-reveal-modal"} className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="large-12 columns">
              <h4>Suspend user account?</h4>
              <p><strong>This message will be included in an email sent to the user.</strong></p>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <textarea 
                name="reason" 
                placeholder="Please provide a brief explanation for the ban."
                required={true}
                value={banReason}
                onChange={(e) => setBanReason(e.target.value)}
              />
              {isError && <small>Please fill out this field.</small>}
            </div>
          </div>
          <div className="modal-footer">
            <a href="#" className="button cancel-modal">Cancel</a>
            <button className="button alert" onClick={handleSubmit}>Suspend</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthorsAndReviewersBanModal;