var createReactClass = require('create-react-class');
import React from 'react';

const BulkExportModal = createReactClass({
  getInitialState: function() {
    return {
      email: ""
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  handleChange: function(e) {
    this.setState({email: e.target.value});
  },

  render: function() {
    var submitButton = <input type='submit' value='Send' className='button primary'/>

    return (
      <div className="cureus-custom-multi-select-modal reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <div className='modal-form'>
              <h5>
                Export {this.props.emailListIds.length} email lists as CSV.
              </h5>

              <p>
                Click send to receive these email lists in your email.
              </p>

              <form ref='form' acceptCharset='UTF-8' className='custom' method='post' action={this.props.bulkExportPath}>
                <input name="utf8" type="hidden" value="✓" />
                <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
                <input type='hidden' name='_method' value='post' />

                <input type='hidden' name='email_list_ids' value={this.props.emailListIds} />

                <div className='modal-footer'>
                  <a className='cancel-modal button secondary'>Cancel</a>
                  {submitButton}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default BulkExportModal;