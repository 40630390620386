Cureus.SearchTool.fluxxor.stores.Search = Fluxxor.createStore({
  initialize: function(query) {
    if(typeof(query) === "string") {
      this.query = query;
    } else {
      this.query = "";
    }

    var constants = Cureus.SearchTool.fluxxor.constants;
    
    this.bindActions(
      constants.SET_QUERY, this.onSetQuery
    )
  },

  onSetQuery: function(query) {
    this.query = query;
    this.emit("change");
  },

  getQuery: function() {
    return this.query;
  }
});
