import React, { useState, useEffect } from 'react';
import Modal from '../Modal';

const AddModal = ({selected, setSelected, discussions, setDiscussions, handleRechecks}) => {
  const [searchField, setSearchField] = useState("")
  const [comment, setComment] = useState(null)
  const [article, setArticle] = useState(null)
  const [searched, setSearched] = useState(false)
  
  const handleAdd = () => {
    // let newSelected = [...selected, comment.id]
    // setSelected(newSelected)
    let newDiscussions = [comment, ...discussions]
    setDiscussions(newDiscussions)
    handleRechecks()
    Modal.close();
  }
  
  const renderMessage = () => {
    let message;
    if(!searched || searchField.length === 0) {
      message = "The Article ID field cannot be blank."
    } else if (article && !comment) {
      message = "No comments associated with this article."
    } else if (!article) {
      message = "Article ID is not found." 
    }
    return (
      <div className="row">
        <div className="small-12 columns">
          <span className="red-message">
            {message}
          </span>
        </div>
      </div>
    )
  }

  const handleSearch = () => {
    setSearched(true)
    $.ajax({
      type: 'GET',
      data: {article_id: searchField},
      url: "/admin/comments/fetch_first_comment",
      success: function(data) {
        setArticle(data.article)
        setComment(data.comment ? data.comment[0] : null)
      }
    });
  }

  const renderComment = () => {
    if(comment) {
      return (
        <div className="row">
          <div className="small-12 columns">
            <div className="comment-body" dangerouslySetInnerHTML={{__html: comment.body}}/>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="reveal-modal tiny" data-reveal id="trending-topics-add-modal">
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <div className="row">
            <div className="small-12 columns">
              <h4 className="text-center">Add Article Discussion</h4>
              <hr/>
              <p>
                Enter the article ID and click the ‘Add’ button to display the article and most recent comment.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <div className="input-container">
                  <label>Article ID</label>
                  <input type="text" placeholder="Enter article ID" onChange={(e) => setSearchField(e.target.value)}/>
              </div>
            </div>
          </div>
          {renderMessage()}
          {renderComment()}
        </div>
        <div className="modal-footer">
          <a className={"left button secondary " + (searchField.length === 0 ? 'disabled' : '')} onClick={() => handleSearch()}>Search</a>
          <a className={"button " + (searchField.length > 0 && comment ? '' : 'disabled')} onClick={handleAdd}>Add</a>
        </div>
      </div>
    </div>
  )
}

export default AddModal;