import React, { useState, useEffect } from 'react';

const Promotion = ({fireAmplitude}) => {
  const [wgt, setWgt] = useState({})

  useEffect( () => {
    fetchPromotionWidget()
  }, [])

  const fetchPromotionWidget = () => {
    fetch('/get_promotion', {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
        'Accept': 'application/json',
      }
    })
    .then((res) => { return res.json()})
    .then((json) => {
      setWgt(json.sidebarWidgets.promotion)
    })
  }

  return (
    <div className="row small-collapse promotion" onClick={() => fireAmplitude('articleRightPromotionClicked', {page: wgt.url})}>
      <div className="small-12 columns">
        <a href={wgt.url} target="_blank">
          <img src={wgt.imageUrl}/>
        </a>
      </div>
    </div>
  )
}

export default Promotion;