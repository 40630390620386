import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import Ad from '../Shared/Ad';
import SideNavMenu from './SideNavMenu';
import TopNavMenu from './TopNavMenu';

const Page = ({ url, title, bannerAd, squareAd, pageId, sectionId, sections, contents, nextPageData }) => {

  const [pageContents, setPageContents] = useState(contents);
  const [nextPage, setNextPage] = useState(nextPageData);
  const [activePageId, setActivePageId] = useState(pageId);
  const [activeSectionId, setActiveSectionId] = useState(sectionId);

  useEffect(() => {
    $(document).foundation({
      accordion: {
        // specify the class used for accordion panels
        content_class: 'content',
        // specify the class used for active (or open) accordion panels
        active_class: 'active',
        // allow multiple accordion panels to be active at the same time
        multi_expand: true,
        // allow accordion panels to be closed by clicking on their headers
        // setting to false only closes accordion panels when another is opened
        toggleable: true
      }
    });
  });

  useEffect(() => {
    loadPageContents();
  }, [activePageId])

  function loadPageContents() {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: url,
      data: getParams(),
      success: function(data) {
        setActiveSectionId(data.sectionId);
        setActivePageId(data.pageId);
        setPageContents(data.contents);
        setNextPage(data.nextPageData);
      }
    })
  }

  function getParams() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");
    return {
      csrfParam: param,
      csrfToken: token,
      pageId: activePageId
    }
  }

  function renderNextPage() {
    if(nextPage) {
      return (
        <div className='page-next'>
          <a onClick={ () => setActivePageId(nextPage.pageId) }>
            { nextPage.title }
            <i className="fas fa-chevron-right"/>
          </a>
      </div>
      ) 
    }
  }

  return (
    <React.Fragment>
      <div className='cureus-ad-wrapper page-banner-ad show-for-large-up' >
        <Ad
          props={ bannerAd }
          size={ "banner" }
        />
      </div>
      <div className='row page-top-nav hide-for-large-up'>
        <TopNavMenu sections={ sections } activePageId={ activePageId } setActivePageId={ setActivePageId } activeSectionId={ activeSectionId } setActiveSectionId={ setActiveSectionId }/>
      </div>
      <div className='content-container page'>
        <div className='row nav-header'>
          <div className='page-nav-label large-4 columns'>
            <span>The Cureus Guides</span>
            <i className="fas fa-chevron-right sm"/>
            <span className='blue'>{ title }</span>
          </div>
          <div className='large-8 columns'>
            <SearchBar classes={ "page" }/>
          </div>
        </div>
        <div className='row page-contents'>
          <div className='page-side-nav large-4 columns show-for-large-up'>
            <SideNavMenu title={ title } sections={ sections } activePageId={ activePageId } setActivePageId={ setActivePageId } activeSectionId={ activeSectionId } setActiveSectionId={ setActiveSectionId }/>
            <div className='cureus-ad-wrapper page-square-ad show-for-large-up' >
              <Ad
                props={ squareAd }
                size={ "square" }
              />
            </div>
          </div>
          <div className='page-body large-8 columns' dangerouslySetInnerHTML={{__html: pageContents }}>
          </div>
          { renderNextPage() }
        </div>
      </div>
    </React.Fragment>
  )

}

export default Page;