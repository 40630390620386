var createReactClass = require('create-react-class');
import React from 'react';

const Slider = createReactClass({
  renderTitle: function() {
    if(this.props.link) {
      return <a href={this.props.link} target="_blank"><h6 className="reg">{this.props.title}</h6></a>
    } else {
      return <h6 className="reg">{this.props.title}</h6>
    }
  },

  render: function(){
    return (
      <div>
        {this.renderTitle()}
        <input id={this.props.criterion} type="hidden" />
        <div className={"cuslider"} data-initial-value={this.props.currentValue} data-input_id={this.props.criterion} data-min="0" data-max={this.props.max} ></div>
      </div>
    );
  }
});

export default Slider;