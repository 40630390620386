import React, { useState, useEffect } from 'react';

const ReadsCard = ({value, label, source, cssClass}) => {

  return (
    <div className="small-6 columns no-pad">
      <div className={`reads-card small ${cssClass}`}>
        <h4>{value}</h4>
        <span className="source">{source}</span>
        <h6>{label}</h6>
      </div>
    </div>
  )
}

export default ReadsCard;