var createReactClass = require('create-react-class');
import React from 'react';
import PMCPagination from './PMCPagination';
import PMCOrder from './PMCOrder';

const PMCFilters = createReactClass({
    getInitialState: function() {
    return {
      perPage: 20,
      order: "published_at_desc"
    }
  },


  handlePerPageChange: function(val) {
    var self = this;
    self.setState({perPage: val}, function(){ self.props.handlePerPageChange(val)});
  },

  handleOrderChange: function(val) {
    var self = this;
    this.setState({order: val}, function(){ self.props.handleOrderChange(val)});
  },

  render: function() {
    return (
      <div className='row'>
        <div className='large-12 columns'>
          <div className='row'>
            <div className='large-3 large-offset-3 medium-6 medium-offset-0 columns'>
              <PMCPagination onChange={ this.handlePerPageChange } value={ this.state.perPage }/>
            </div>
            <div className='large-3 medium-6 columns'>
              <PMCOrder onChange={ this.handleOrderChange } value={ this.state.order }/>
            </div>
            <div className='large-3 columns'></div>
          </div>
        </div>
      </div>
    )
  }
});

export default PMCFilters;
