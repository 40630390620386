import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import SmallArticleCard from '../../Shared/ContentCards/SmallArticleCard';
import DeleteButton from '../DeleteButton';

const RecentlyPublishedWidget = ({widgetId, slug, handleDeleteWidget, mainContentWidgets}) => {

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    if(widgetId) {
      let onSuccess = (res) => {
        setArticles(res.articles)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [mainContentWidgets])

  const renderLeft = () => {
    return articles.slice(0, 2).map(function(article, idx) {
      return (
        <div className="row small-collapse">
          <div className="small-12 columns">
            <SmallArticleCard key={article.id} resource={article} showCategories={true}/>
          </div>
        </div>
      )
    })
  }

  const renderRight = () => {
    return articles.slice(2, 4).map(function(article) {
      return (
        <div className="row small-collapse">
          <div className="small-12 columns">
            <SmallArticleCard key={article.id} resource={article} showCategories={true}/>
          </div>
        </div>
      )
    })
  }

  return (
    <div className="row small-collapse">
      <div className="small-12 columns">
        <div className="widget recently-published">
          {handleDeleteWidget && <DeleteButton
            handleDeleteWidget={handleDeleteWidget}
            widgetId={widgetId}
            className="parent-widget"
          />}
          <div className="small-12 columns">
            <h2>Recently published</h2>            
          </div>
          <div className="row small-collapse">
            <div className="large-12 columns featured-articles">
              <div className="small-12 medium-12 large-6 columns left-container">
                {renderLeft()}
              </div>
              <div className="small-12 medium-12 large-6 columns right-container">
                {renderRight()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecentlyPublishedWidget;