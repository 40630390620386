var createReactClass = require('create-react-class');
import React from 'react';
import NpiOther from './NpiOther';
import NpiUs from './NpiUs';

const NpiEdit = createReactClass({
  renderContents: function() {
    if(this.props.country === "Other") {
      return <NpiOther
                handleChange={this.props.handleChange}
                npi={this.props.npi}
                details={this.props.details}
              />
    } else if (this.props.country === "US") {
      return <NpiUs 
                npiPath={this.props.npiPath}
                handleChange={this.props.handleChange}
              />
    } else {
      return;
    }
  },

  noNpiDeclared: function() {
    if (this.props.npi) {
      return this.props.npi == 'none'
    }
    return false;
  },

  render: function(){
    return (
      <div className="modal-content">
          <h4>Identification Number</h4>
          <p>This helps us verify your identity and maintain publication and peer review quality. Do you have a unique health care provider identification number? US-based users can search and connect to their NPI number. Don’t have an NPI number? Select Other from the Identification type dropdown menu and enter your identification number manually.</p>
          <div className="row">
            <div className="large-3 columns">
              <label>Identification type</label>
              <select id="select-country" name="country" onChange={(e) => this.props.handleChange(e)}>
                <option>Please Select</option>
                <option value="US">NPI</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          {this.renderContents()}
        <div className="row" style={{marginTop: '25px'}}>
          <input type="checkbox" name="npi" id="no_npi" checked={this.noNpiDeclared()} onChange={(e) => this.props.setNone(e)}/>
          <label htmlFor="no_npi">I don’t have an identification number.</label>
        </div>
      </div>
    )
  }
})

export default NpiEdit;