var createReactClass = require('create-react-class');
import React from 'react';

const Promotion = createReactClass({
  renderWidgetContent: function() {
    if (this.props.open) {
      return (
        <div className="row">
          <div className="small-12 columns">
            <div className="promotion-widget-wrap">
              <div className="page-widget-wrap">
                <div className="widget-title-wrap">
                  <a href={this.props.widget.url} target="_blank">
                    <img src={this.props.widget.promoURL}/>
                  </a>
                </div>
                {/* Form fields. Form in parent component */}
                <label>
                  Title
                  <input type="text" name="widget[title]" id="title" defaultValue={this.props.widget.title}/>
                </label>
                <label>
                  Image
                  <input type="file" name="widget[image]" id="image"/>
                </label>
                <label>
                  Url
                  <input type="text" name="widget[url]" id="url" defaultValue={this.props.widget.url}/>
                </label>
              </div>
            </div>
          </div>
        </div>
      )
    }
  },

  render: function() {
    return (
      <div>
        { this.renderWidgetContent() }
      </div>
    )
  }
})

export default Promotion;

