var createReactClass = require('create-react-class');
import React from 'react';
import AddDisclosureModal from './AddDisclosureModal'
import Modal from '../Modal';

const RelationshipRow = createReactClass({
  editDisclosure: function(e) {
    e.preventDefault();
    var self = this;
    self.props.clearErrors();
    Modal.open({
      children: <AddDisclosureModal
                    articleId={self.props.articleId}
                    disclosureId={self.props.disclosureId}
                    action='update'
                    getData={self.props.getData}
                    relationInfo={self.props.relationInfo}
                  />
    });
  },

  deleteDisclosure: function(e) {
    e.preventDefault();
    var self = this;
    self.props.clearErrors();
    if (confirm("Are you sure you want to delete this? This action is irreversible")) {
      $.ajax({
        type: 'delete',
        url: self.props.relationInfo.destroy_path,
        success: self.props.getData,
        error: self.props.addTableErrors
      });
    }
  },

  render: function() {
    return (
      <tr className={'financial_relationship_table_row_' + this.props.id + ' added_financial_relationship_row'}>
        <td>{this.props.relationInfo.name}</td>
        <td>{this.props.relationInfo.authors}</td>
        <td>{this.props.coiTypes}</td>
        <td>{this.props.relationInfo.comments}</td>
        <td className='nowrap'>
          <a href='#' className='button tiny secondary edit-button' onClick={this.editDisclosure} data-id={this.props.id}><i className='fa fa-pencil-alt'/></a>
          <a href='#' className='button tiny secondary delete-button' onClick={this.deleteDisclosure} data-id={this.props.id}><i className='far fa-trash-alt'/></a>
        </td>
      </tr>
    )
  }
})

export default RelationshipRow;