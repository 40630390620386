import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import RecentlyPublished from './Modals/RecentlyPublished';
import CustomContentModal from './Modals/CustomContentModal';
import InternalContent from './Modals/InternalContent';
import FeaturedMultimediaModal from './Modals/FeaturedMultimediaModal';
import ChannelTeam from './Modals/ChannelTeam';
import ChannelTeamSidebar from './Modals/ChannelTeamSidebar'
import Promotion from './Modals/Promotion';
import CureusPromotionModal from './Modals/CureusPromotionModal';
import OverviewSidebarModal from './Modals/OverviewSidebarModal';
import TwitterSidebarModal from './Modals/TwitterSidebarModal';
import Poll from './Modals/Poll';
import MostPopularContentModal from './Modals/MostPopularContentModal';
import AboutModal from './About/AboutModal';
import AboutContactModal from './About/AboutContactModal';
import SocialAccountsModal from './About/SocialAccountsModal';

const SettingsButton = ({img, title, modalName, handleAddWidget, hasArticles, hasLessThan4Multimedia, widgetMainRiverId, imgClass, mainContentWidgets, nextRowClass='', slug, widgetSidebarId, sidebarWidgets, widgetAboutMainId, widgetAboutSidebarId, handleUpdateWidget}) => {
  const props = ({ img, title, modalName, handleAddWidget, hasArticles, hasLessThan4Multimedia, widgetMainRiverId, imgClass, mainContentWidgets, slug, widgetSidebarId, sidebarWidgets, widgetAboutMainId, widgetAboutSidebarId, handleUpdateWidget})

  useEffect(() => {
  }, [])

  const modalHash = (name) => {
    const modalObj = {
      'RecentlyPublished': <RecentlyPublished {...props}/>,
      'CustomContentModal': <CustomContentModal {...props}/>,
      'InternalContent': <InternalContent {...props}/>,
      'ChannelTeam': <ChannelTeam {...props}/>,
      'FeaturedMultimediaModal': <FeaturedMultimediaModal {...props}/>,
      'ChannelTeamSidebar': <ChannelTeamSidebar {...props}/>,
      'Promotion': <Promotion {...props}/>,
      'CureusPromotionModal': <CureusPromotionModal {...props}/>,
      'OverviewSidebarModal': <OverviewSidebarModal {...props}/>,
      'TwitterSidebarModal': <TwitterSidebarModal {...props}/>,
      'Poll': <Poll {...props}/>,
      'MostPopularContentModal': <MostPopularContentModal {...props}/>,
      'AboutModal': <AboutModal {...props}/>,
      'AboutContactModal': <AboutContactModal {...props}/>,
      'SocialAccountsModal': <SocialAccountsModal {...props}/>,
    }

    return modalObj[name]
  }

  const openModal = () => {
    Modal.open({
      children: modalHash(modalName)
    });
  }

  const renderIcon = () => {
    return <img className={imgClass} src={img}/>
  }

  return (
    <a className={"settings-button-container " + nextRowClass} onClick={openModal}>
      {renderIcon()}
      <span>{title}</span>
    </a>
  )
}

export default SettingsButton;