import React, { useState, useEffect } from 'react';

const AuthorsTab = ({html}) => {

  return (
    <div className="row">
      <div className="small-12 columns">
        <div className="tab-content-container authors-tab">
          <div dangerouslySetInnerHTML={{__html: html}}/>
        </div>
      </div>
    </div>
  )
}

export default AuthorsTab;