import React, {useEffect} from 'react';

const MetricsCard = ({cardDetails, index, visibleIndex, setVisibleCardType}) => {

  useEffect(() => {
    if (index == visibleIndex) {
      setVisibleCardType("metrics")
    }
  })
  
  return (
    <div className={`featured-content-card metrics-card ${index == visibleIndex ? '' : 'hide-card'}`}>
      <div className="metrics-image-large hide-for-medium-down">
        <img className="hide-for-medium-down metrics-image" src={cardDetails.image.url ? cardDetails.image.url : cardDetails.preview_image} />
      </div>
      <img className="hide-for-large-up metrics-image metrics-image-small" src={cardDetails.mobile_image.url ? cardDetails.mobile_image.url : cardDetails.preview_mobile_image} />
    </div>
  )
}

export default MetricsCard;