import React from 'react';
const TableSelector = ( { options, defaultValue, setTableParams, tableParams, editors, paramKey } ) => {
  return(
    <select onChange={e => setTableParams( tableParams => ({
      ...tableParams,
      [paramKey]: e.target.value
    }))}>
      <option value="">{defaultValue}</option>
      { 
          options.map((option) => {
          return <option value={editors ? option.id : option}>{editors ? option.name : option}</option>
        })
      }
    </select>
  )
}

export default TableSelector;