import React, {useState} from 'react';
import ChecklistItem from './ChecklistItem';


const CheckListDesk = ({buttonDisable, handleCheck, blockOrDeskItems, handleSelectAll}) => {

  const renderSelectAll = (deferType) => {
    return(
      <div className="checklist-item row">
        <div className="large-3 large-offset-8 columns end">
          <div className="select-all">
            <a className="button tiny success" onClick={() => handleSelectAll(deferType)}>Select All</a> 
          </div>
        </div>
      </div>
    )  
  }

  return (
    <div className="checklist">
      {renderSelectAll('desk')}
      <ChecklistItem 
        title={"Issues with study relevance, design, results, etc*"}
        buttonDisable={buttonDisable}
        type={'desk'}
        handleCheck={handleCheck}
        index={0}
        blockOrDeskItems={blockOrDeskItems}
      />
      <ChecklistItem 
        title={"Topic not acceptable for Cureus* (permanent)"}
        buttonDisable={buttonDisable}
        type={'desk'}
        handleCheck={handleCheck}
        index={1}
        blockOrDeskItems={blockOrDeskItems}
      />
      <ChecklistItem 
        title={"Submitted as incorrect article type (new submission is okay)"}
        buttonDisable={buttonDisable}
        type={'desk'}
        handleCheck={handleCheck}
        index={2}
        blockOrDeskItems={blockOrDeskItems}
      />
      <ChecklistItem 
        title={"Other issues"}
        buttonDisable={buttonDisable}
        type={'desk'}
        handleCheck={handleCheck}
        index={3}
        blockOrDeskItems={blockOrDeskItems}
      />
    </div>
  )
}

export default CheckListDesk;