var createReactClass = require('create-react-class');
import React from 'react';
import Invite from './Invite';
import LearnMoreModal from './ReviewerActivity/LearnMoreModal.jsx';
import Modal from '../../Modal';

const PeerReviewProgress = createReactClass({
  renderProgressToolTipText: function() {
    var text = ( this.props.article.reviews.completed === 0 ? "None" : "One")
    return text + " of the required minimum number (2) of reviews have been completed so far."
  },

  renderInReviewRemindButton: function() {
    if (this.props.article.competition_id === null) {
      return (
        <a id={this.props.article.rereviews ? "rereviewing" : "reviewing"} className={(this.props.article.reviews.reviewing === 0 ? "disabled no-margin" : "no-margin")} onClick={this.props.openRemindModal}><i className='far fa-envelope'/>REMIND</a>
      )
    } else {
      return (
        null
      )
    }
  },

  renderNoResponseRemindButton: function() {
    if (this.props.article.competition_id === null) {
      return (
        <a id={this.props.article.rereviews ? "reinvited" : "invited"} className={(this.props.article.reviews.invited + this.props.article.reviews.expired) === 0 ? "disabled no-margin" : "no-margin"} onClick={this.props.openRemindModal}><i className='far fa-envelope'/>REMIND</a>
      )
    } else {
      return (
        null
      )
    }
  },

  handleBarStyle: function() {
    if(this.props.article.articleState === 'reviews_complete' || this.props.reviews.completed >= 2) {
      return {width: '100%'}
    } else {
      return {width: this.props.reviews.completed === 0 ? '15%' : '60%'}
    }
  },

  copyHash: function() {
    if(this.props.article.rereviews) {
      return {
        invited: "Total Re-Invited",
        reviewing: "In Re-Review",
        title: "RE-REVIEW PROGRESS"
      }
    } else {
      return {
        invited: "Total Invited",
        reviewing: "In Review",
        title: "PEER REVIEW PROGRESS"
      }
    }
  },

  renderCompleted: function () {
    if(this.props.article.rereviews) {
      return (
        <td>
          <div className="stat-box">
            <h3> {this.props.reviews.completed}</h3>
          </div>
        </td>
      )
    } else {
      return (
        <td>
          <div className="stat-box">
            <h3>{this.props.reviews.completed}</h3>
          </div>
        </td>
      )
    }
  },

  renderCheck: function() {
    if(this.props.article.articleState !== 'in_review' && this.props.article.articleState !== 'reviews_complete' || this.props.article.competition_id) {
      return;
    }
    if(this.props.article.needsCureusReviewer) {
      return (
        <div className="row">
          <div className="small-1 small-offset-1 columns requirement-box">
            <i className="far fa-check-circle fa-2x"/> 
          </div>
          <div className="small-10 columns requirement-box learn-more">
            <span>A minimum of 2 Cureus-invited reviews is required.</span>            
          </div>
        </div>
      )
    } else {
      return (
        <div className="row">
          <div className="small-12 columns requirement-box learn-more completed">
            <i className="far fa-check-circle fa-2x completed"/> 
            <span>You've received the required amount (2) of Cureus-invited reviews.</span>            
          </div>
        </div>
      )
    }
  },

  renderReviewInvite: function() {
    if(this.props.article.articleState === 'in_review') {
      return (
        <div className="small-12 medium-4 large-4 columns">
          <table className="dashboard-table coauthors peer-review-progress" style={{marginBottom: '0px'}}>
            <thead>
              <tr style={{height: '35px'}}>
                <th/>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="dashboard-reviewers-invite-container">
                  <Invite
                    article={this.props.article}
                    dashboard={true}
                  />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    } else if(this.props.article.articleState !== 'reviews_complete') {
      return (
        <div className="small-12 medium-4 large-4 columns">
          <table className="dashboard-table coauthors peer-review-progress" style={{marginBottom: '0px'}}>
            <thead>
              <tr style={{height: '35px'}}>
                <th/>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="dashboard-reviewers-emphasis-container small-12 columns">
                    <div className="small-1 columns">
                      <i className='fas fa-exclamation-triangle'></i>
                    </div>
                    <div className="small-11 columns">
                      <div dangerouslySetInnerHTML={{__html: this.props.article.reviewerStatusEmphasisBox}}/>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  },

  openLearnMoreModal: function() {
    Modal.open({
      children: <LearnMoreModal/>
    })
  },

  renderReviewProgressBox: function() {
    if (this.props.article.reviewsState && !this.props.article.wasDeferred && !this.props.article.endedPeerReview) {
      return (
        <div className="dashboard-panel-box peer-review-progress-box">
          <h6>{this.copyHash()["title"]}</h6>
          <div className="row centered collapse">
            <div className={(this.props.article.articleState === "reviews_complete" ?  "small-12 columns" : "small-12 medium-8 large-8 columns")}>
              <table className="dashboard-table coauthors peer-review-progress">
                <thead>
                  <tr>
                    <th>
                      <span className="tip-top tool-container" data-tooltip="" id={"progress-" + this.props.article.id } title={this.renderProgressToolTipText()}>Completed</span>
                    </th>
                    <th className="small-3">
                      <span title="This also includes reviewers invited by Cureus Editors." id="total-invited" data-tooltip=""  className="tip-top tool-container">{this.copyHash()['invited']}</span>
                    </th>
                    <th className="small-3">
                      <span title="Number of reviewers who have accepted but not yet completed their review." id="in-review" data-tooltip="" className="tip-top tool-container">{this.copyHash()['reviewing']}</span>
                    </th>
                    <th className="small-3">
                      <span title="Number of reviewers who have not responded to the invitation." id="no-response" data-tooltip="" className="tip-top tool-container">No Response</span>
                    </th>
                    <th className="small-3">
                      <span title="Number of reviewers who have declined the invitation." id="declined" data-tooltip="" className="tip-top tool-container">Declined</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  {this.renderCompleted()}
                  <td>
                      <div className="stat-box">
                        <h3> {this.props.reviews.total} </h3>
                      </div>
                    </td>
                    <td>
                      <div className="stat-box">
                        <h3> {this.props.reviews.reviewing} </h3>
                        {this.renderInReviewRemindButton()}
                      </div>
                    </td>
                    <td>
                      <div className="stat-box">
                        <h3> {this.props.reviews.invited + this.props.reviews.expired} </h3>
                        {this.renderNoResponseRemindButton()}
                      </div>
                    </td>
                    <td className="small-3">
                      <div className="stat-box">
                        <h3> {this.props.reviews.declined} </h3>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {this.renderCheck()}
              <div className="row">
                <div className={(this.props.article.articleState === "reviews_complete" ?  "small-12 columns" : "small-11 small-offset-1 columns")}>
                  <p className="centered learn-more" onClick={this.openLearnMoreModal} style={this.props.article.articleState === 'reviews_complete' ? {textAlign: 'center'} : {}}>
                    <i> Cureus articles are peer reviewed, not peer rejected. </i><a href="javascript:void(0)"><strong>Learn more here.</strong></a>
                  </p> 
                </div>
              </div>
            </div>
            {this.renderReviewInvite()}
          </div>
        </div>
      )
    } else {
      return null
    }
  },

  render: function() {
    return (
      <div>
        {this.renderReviewProgressBox()}
      </div>
    )
  }
})

export default PeerReviewProgress;
