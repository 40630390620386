var createReactClass = require('create-react-class');
import React from 'react';
import Transloadit from '../Transloadit';
import AttachmentMixin from '../AttachmentMixin';

const EditFile = createReactClass({
  mixins: [AttachmentMixin],

  handleDelete: function() {
    var self = this;

    if(window.confirm("Are you sure you want to delete this file?")) {
      $(self.refs.spin).spin();
      $.ajax({
        type: "DELETE",
        url: self.props.poster.poster_file_path,
        success: function(poster) {
          self.props.onUpdate(poster, true);

        }
      })
    }
  },

  renderProgressBarText: function() {
    if (this.state.finishedUploading) {
      return <p>Please wait, we are processing the figure...</p>
    }
  },

  render: function() {
    var preview_url = this.props.poster.preview_url || this.props.poster.file.file.preview.url;
    if(this.state.errorMessage) {
      var errorMessage =
        <div className='alert-box alert'>
          { this.state.errorMessage }
        </div>
    }

    if(this.state.isUploading) {
      return (
        <div key='progressbar' className='cureus-progress progress-striped active poster-progress'>
          {this.renderProgressBarText()}
        <div ref='progressBar' className='bar'></div>
        </div>
      )
    } else {
      return (
        <div>
          { errorMessage }
          <div className='poster-upload-widget'>
            <div className='poster-upload-has-file'>
              <div ref='spin' className='poster-upload-preview-container'>
                <div className='row'>
                  <div className='large-3 columns'>
                    <div className='poster-upload-preview-window'>
                      <img src={ preview_url } />
                    </div>
                  </div>
                  <div className='large-9 columns'>
                    <div className='poster-upload-preview-stats'>
                      <h5 className='poster-upload-file-name'>
                        { this.props.poster.filename }
                      </h5>
                      <div className='poster-upload-file-size'>
                        { this.props.poster.filesize }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <Transloadit
                transloaditTemplate={ this.props.poster.transloadit_template }
                handleFileSelect={ this.handleFileSelect }
                handleProgress={ this.handleProgress }
                handleSuccess={ this.handleSuccess }
                handleError={ this.handleError }
                fileName={ 'poster_file' }>
              </Transloadit>
              &nbsp;
              <a onClick={ this.handleDelete } className='button small'>
                Replace
              </a>
            </div>
          </div>
        </div>
      )
    }
  }
});

export default EditFile;