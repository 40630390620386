import React, {useEffect} from 'react';

const CureusUCard = ({cardDetails, index, visibleIndex, fireAmplitude, setVisibleCardType}) => {

  useEffect(() => {
    if (index == visibleIndex) {
      setVisibleCardType("cureus-u")
    }
  })

  return (
    <div className={`row featured-content-card ${index == visibleIndex || (!index && !visibleIndex) ? '' : 'hide-card'}`}>
      <div className="columns hide-for-small-only medium-6 col-6-image">
        <img src={cardDetails.newsroom_post_thumbnail} />
      </div>
      <div className="hide-for-medium-up">
        <img className="small-screen-image" src={cardDetails.newsroom_post_thumbnail} />
      </div>
      <div className="columns small-12 medium-6 card-info">
        <p className="ck-editor-content" dangerouslySetInnerHTML={{__html: cardDetails.description}}></p>
        {cardDetails.cta_type == 'button' && <button>{cardDetails.cta_text}</button>}
        {cardDetails.cta_type == 'arrow' && <span>{cardDetails.cta_text}</span>}
        <a className="read-more" href={cardDetails.newsroom_post_path} onClick={() => fireAmplitude('cureus-u', cardDetails.newsroom_post_path)}>
          Read More
          <i className="fas fa-arrow-right"></i>
        </a>
      </div>
    </div>
  )
}

export default CureusUCard;