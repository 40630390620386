import React, { useState, useEffect } from 'react';
import _ from 'lodash';

const Search = ({ searchPath, mostSearchedAll, mostSearchedDisplay, currentUserId, color}) => {
  const [showMostSearchDropdown, setShowMostSearchDropdown] = useState(false);
  const [showPredictiveSearch, setShowPredictiveSearch] = useState(false);
  const [predictiveSearchResults, setPredictiveSearchResults] = useState([]);
  const [firstLoad, setfirstLoad] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [debounce] = useState((term) => _.debounce((term) => {getPredictiveSearchResults(term)}, 500));

  useEffect(() => {
    if (firstLoad) {
      setfirstLoad(false)
      return
    }

    if (searchTerm.length > 0) {
      debounce(searchTerm);
      setShowMostSearchDropdown(false)
    } else {
      setShowPredictiveSearch(false)
      setShowMostSearchDropdown(true)
    }
  }, [searchTerm])

  const renderMostSearched = (terms) => {
    return terms.map((term) => {
      return <a  key={term} target="_blank" href={`/articles?page=1&q=${term}&order=%7B%22attr%22:%22_score%22,%22dir%22:%22desc%22,%22text%22:%22Relevance%22%7D&advanced=false&filters=&obeySort=false&searched=true`} onClick={() => fireAmplitude(term)}>{term}</a>
    })
  }

  const renderPredictiveSearchResults = () => {
    return predictiveSearchResults.map(function(result) {
      var formatedString = boldSearchTermInResult(result.title)
      return <a key={result.id} href={`/articles/${result.id}`} target="_blank" dangerouslySetInnerHTML={{__html: formatedString}}/>
    })
  }

  const boldSearchTermInResult =  (title) => {
    // Looks at the resulting article title, checks if the search term is in the title, and if so, bolds it
    var chars = title.split("")
    var indexOfSearchTermInTitle = title.toLowerCase().indexOf(searchTerm.toLowerCase())
    if (indexOfSearchTermInTitle == -1) {
      return title
    } else {
      chars.splice(indexOfSearchTermInTitle, 0, "<strong>")
      chars.splice(indexOfSearchTermInTitle + searchTerm.length + 1, 0, "</strong>")
      return chars.join("")
    }
  }

  const renderResults = () => {
    if (searchTerm.length > 0) {
      return renderPredictiveSearchResults()
    } else {
      return renderMostSearched(mostSearchedAll)
    }
  }

  const renderDropdown = () => {
    if(showMostSearchDropdown || showPredictiveSearch) {
      return (
        <div className="predictive-search-results">
          {showMostSearchDropdown && dropDownHeader()}
          {renderResults()}
        </div>
      )
    }
  }

  const dropDownHeader = () => {
    return (
      <div className="header">
        <span>Most searched:</span>
      </div>
    )
  }

  const getPredictiveSearchResults = (term) => {
    $.ajax({
      method: 'GET',
      url: "/articles/search_suggestions",
      data: {query: term},
      dataType: 'json',
      success: function(res) {
        setPredictiveSearchResults(res)
        setShowPredictiveSearch(true)
      },
      error: function(res) {
      }
    }, 'json');

  }

  const fireAmplitude = (term) => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: {
              event_type: 'mostSearchedTermClicked',
              user_id: currentUserId,
              term: term
            }
    })
  }

  const handleChange = (e) => {
    const term = e.target.value
    setSearchTerm(term)
  }

  const handleFocus = (e) => {
    const term = e.target.value
    if (term.length == 0) {
      setShowMostSearchDropdown(true)
    } else {
      setShowPredictiveSearch(true)
    }
  }

  const handleOnBlur = (e) => {
    const term = e.target.value
      // without setTimeout the onblur event closed the dropdown before the a tag href is called
    setTimeout(() => {
      setShowPredictiveSearch(false)
      setShowMostSearchDropdown(false)
    }, 100)
  }

  return (
    <React.Fragment>
      <div className='row'>
        <div className="large-8 large-offset-2 small-10 small-offset-1 columns container">
          <form method="get" action={searchPath} onChange={handleChange} className="search-container" autoComplete="off" onFocus={handleFocus} onBlur={handleOnBlur} >
            <i className="fa fa-search"/>
            <input className="search-field" type="text" name="q" placeholder="Search articles"/>
            <input type="hidden" name={"source"} value={"homeSearch"} />
            <input type="hidden" name={"searched"} value={"true"} />
            <input type="submit" value="Search" className="button primary hide-for-small search-button"/>
            <input type="submit" value="Search" className="button primary tiny show-for-small-only search-button"/>
          </form>
          {renderDropdown()}
        </div>
      </div>
      <div className="row">
        <div className="large-8 large-offset-2 small-10 small-offset-1 columns">
            <div className="most-searched-container">
              <span style={{color: color}}>Most searched:</span>{renderMostSearched(mostSearchedDisplay)}
            </div>
          </div>
      </div>
    </React.Fragment>
  )
}

export default Search;