import React, {useState} from 'react';
import Modal from '../Modal';

const ChannelOfficialRejectionModal = ({approveOrRejectNominee, channelManagementId, action}) => {
  
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    approveOrRejectNominee(channelManagementId, action, message);
    Modal.close();
  }

  return (
    <div id={"reject-user-reveal-modal"} className="reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="large-12 columns">
              <h4>Deny request to add Channel Admin or Deputy Editor?</h4>
              <p>Please explain why the proposed Channel Admin or Deputy Editor does not meet the eligibility requirements. 
                This message will be included in an email sent to all current Channel Admins.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="large-12 columns">
              <textarea 
                name="message" 
                required={true}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ height: '150px' }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <a href="#" className="button cancel-modal">Cancel</a>
            <button className="button alert" onClick={handleSubmit}>Deny Request</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelOfficialRejectionModal;