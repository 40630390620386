var createReactClass = require('create-react-class');
import React from 'react';

const FlagModal = createReactClass({
  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  getInitialState: function(){
    return({
    })
  },

  renderModal: function() {
    return (
      <div className="reveal-modal middling" data-reveal id={"report_comment_" + this.props.comment.id} >
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Report Comment</h4>
            <p>
             Please select a reason for flagging this comment.
            </p>
          </div>

          <form acceptCharset="UTF-8" action={this.props.comment.flagPath} method="post"  id={ "submit_edit_reviewership_" + this.props.comment.id }>
            <div>
              <input name="utf8" type="hidden" value="✓"/>
              <input name="_method" type="hidden" value="put"/>
              <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />

            </div>
            <select id="flaggings_reason" name="flaggings[reason]">
              <option value="spam">Spam</option>
              <option value="offensive">Offensive</option>
              <option value="potentially libelous">Potentially libelous</option>
            </select>

            <div className='modal-footer'>
              <a href="#" className="button secondary cancel-modal"> Cancel </a>
              <input type='submit' value='Submit' name="commit" className='button primary' />
            </div>

          </form>

        </div>
      </div>
    )
  },

  render: function() {
    return (
      <div>
        {this.renderModal()}
      </div>
    )
  }
})

export default FlagModal;