var createReactClass = require('create-react-class');
import React from 'react';
import TotalEarningsTable from './TotalEarningsTable';

const InvoiceTool = createReactClass({
  definedRanges: function() {
    var self = this;
    return this.props.dateRanges.map(function(range, idx) {
      return(
        <a key={range.label + idx} onClick={self.props.updateRange(range.from, range.to, range.label)} href="javascript:void(0)">{range.label}</a>
      )
    });
  },

  rangeSelector: function() {
    if (this.props.open) {
      return(
        <div className="f-dropdown fancy-date-selector-content open"style={{position: "absolute", left: "-366px !important", width: '500px', maxWidth:'500px', top: "32px", marginLeft: '-150px'}} aria-hidden="false">
          <div className="date-inputs-wrap">
            <div className="date-input"style={{width: '165px'}}>
              <label htmlFor="from-3">From</label>
              <input onChange={this.props.updateFrom} id="from-3" name="from" type="date" value={this.props.from} className="hasDatepicker"/>
            </div>
            <div className="date-input" style={{width: '165px'}}>
              <label htmlFor="to-3">To</label>
              <input onChange={this.props.updateTo} id="to-3" name="to" type="date" value={this.props.to} className="hasDatepicker"/>
            </div>
          </div>
          <div className="date-filters-wrap">
            <div className="date-filters">
              {this.definedRanges()}
            </div>
            <input className="button secondary tiny"  type="button" value="Apply" onClick={this.props.applyButton}/>
            <a onClick={this.props.closeDatePicker}>Cancel</a>
          </div>
        </div>
      )
    }
  },

  description: function() {
    if (this.props.isCurrentUser) {
      return (
        <ul>
          <li>Click on the desired month in the Past Invoices table to view the corresponding invoice.</li>
          <li>Invoices are generated on the first of the month for each prior month.</li>
          <li>Miscellaneous items include all non-article editing tasks (e.g. training, query management, queue management, etc.)</li>
        </ul>
      )
    } else {
      return (
        <ul>
          <li>Click on the desired month in the Past Invoices table to view the corresponding invoice.</li>
          <li>Invoices are generated on the first of the month for each prior month.</li>
          <li>To update editor type/rate, click the desired link in the Editor Configuration table above.</li>
          <li>Miscellaneous items include all non-article editing tasks (e.g. training, query management, queue management, etc.)</li>
          <li>In the event of an overpayment, a negative (debit) miscellaneous item may be entered.</li>
          <li>Location and Wordcount are recorded when the editing service was purchased. Any changes after submission do not affect payment amount and are not reflected on the invoice.</li>
        </ul>
      )
    }
  },  

  render: function() {
    return(
      <div className='row'>
        <div className="large-6 columns">
          <h4>Invoice Notes</h4>
          {this.description()}
        </div>
        <div className="large-6 columns">
          <div className='right'>
            <div className='fancy-date-selector'>
              <div className='fake-dropdown' >
                <a role='button' onClick={this.props.toggleOpen} className='current'>{this.props.selectedRange}</a>
                <a role='button'className='selector' onClick={this.props.toggleOpen}></a>
                {this.rangeSelector()}
              </div>
            </div>
          </div>
          <TotalEarningsTable 
            articlesCompleted={this.props.articlesCompleted}
            miscItemCount={this.props.miscItemCount}
            earningsHash={this.props.earningsHash}
          />

        </div>
      </div>
    )
  }
})

export default InvoiceTool;