import React from 'react';
import { useState } from 'react';
import Label from './Label'
import RadioContainer from '../Shared/RadioContainer'

const Coi = ({handleQuestionChange, setDisclosure, setDisclosureDeclared}) => {

  const [hasCoi, setHasCoi] = useState(null);
  const [isEmptyDisclosure, setIsEmptyDisclosure] = useState(true);

  const handleDisclosureDeclarationSelection = (e) => {
    const {value} = e.target;
    setHasCoi(value);
    handleQuestionChange('disclosure_declared', value);
    setDisclosureDeclared(value);
    if(!value) {
      setIsEmptyDisclosure(true);
      setDisclosure(null);
    } 
  }

  const handleAnswerChange = (e) => {
    const value = e.target.value;
    handleQuestionChange('disclosure', value);
    if(value.trim() != "") {
      setIsEmptyDisclosure(false);
      setDisclosure(value.trim());
    } else {
      setIsEmptyDisclosure(true);
      setDisclosure(null);
    }
  };

  const hasCoiFragment = () => {
    return (
      <div>
        <textarea className='coi-description' onChange={handleAnswerChange}></textarea>
        {isEmptyDisclosure && (<p className='empty-response'>This field is required.</p>)}
      </div>
    )
  }

  return (
    <div className='coi-container'>
      <h3>Conflicts of Interest</h3>
      <div class='coi-instructions'>
        <p>
          Cureus adheres to
          <a href="http://www.icmje.org/recommendations/browse/roles-and-responsibilities/author-responsibilities--conflicts-of-interest.html"  rel="nofollow" target="_blank"> ICMJE guidelines </a>
          concerning conflicts of interest. Conflicts of interest may involve 3rd party payment or service for any aspect of the submitted work, relevant financial activities outside the submitted work, patents that could be considered broadly relevant to the work and any relationships not covered previously.
        </p>
        <p>
          Reviewers must disclose to editors any conflicts of interest that could bias their opinions of the manuscripts, and must not use knowledge of the reviewed work to further their own interests before publication. In extreme cases, reviewers may wish to contact the
          <a href="mailto:alexander.muacevic@cureus.com" > Cureus Editor-in-Chief </a>
          in order to provide a more detailed explanation of the potential conflict and determine the best course of action.
        </p>
        <p>Cureus Team</p>
      </div>
      <Label label={'Based on the above guidelines, do you have any conflicts of interest to disclose?'}/>
      <RadioContainer label={"Yes"} value={true} selectedValue={hasCoi} handleChange={handleDisclosureDeclarationSelection} name={"coi"}/>
      <RadioContainer label={"No"} value={false} selectedValue={hasCoi} handleChange={handleDisclosureDeclarationSelection} name={"coi"}/>
      {hasCoi ? hasCoiFragment() : null}
    </div>
  )
}

export default Coi;