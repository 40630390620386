import Table from '../components/EmailTool/fluxxor/stores/Table'
import FilterTool from '../components/EmailTool/fluxxor/stores/FilterTool';
import actions from '../components/EmailTool/fluxxor/actions';
import constants from '../components/EmailTool/fluxxor/constants';

const EmailTool = {
  fluxxor: {
    actions: actions,
    constants: constants,
    stores: { Table, FilterTool }
  }
};

export default EmailTool;
//= require_tree ./EmailTool
