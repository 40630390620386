var createReactClass = require('create-react-class');
import React from 'react';
import ReferenceField from './ReferenceField';

const FieldSet = createReactClass({
  render: function() {
    var self = this;
    return (
      <div className='row'>
        {
          this.props.referenceFields.map(function(field, idx) {
            return (
              <ReferenceField field={ field } key={ idx } isEditor={ self.props.isEditor } fetchDoiMetadata={ self.props.fetchDoiMetadata} invalidParsedReference={ self.props.invalidParsedReference } parsedReferences={ self.props.parsedReferences }/>
            )
          })
        }
      </div>
    )
  }
});

export default FieldSet;