import React from 'react';
var createReactClass = require('create-react-class');
import Modal from '../Modal';
import ArticlesModal from './ArticlesModal';
import DateSelector from './DateSelector';
import EditorTotals from './EditorTotals';

const Table = createReactClass({

  getInitialState: function() {
    return({
      dateRanges: this.props.dateRanges,
      selectedRange: this.props.dateRanges[2].label,
      from: this.props.dateRanges[2].from,
      to: this.props.dateRanges[2].to,
      editors: [],
      loading: true
    })
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  componentDidMount: function() {
    var self = this;
    self.getStats(self.state.from, self.state.to, self.state.selectedRange)
  },

  copyOrContentEditors: function(editors) {
    var array = editors ? editors : this.state.editors
    return array.filter( function(el) {
      return ["ContentEditor", "CopyEditor", "SubmissionsCopyEditor", 'JuniorCopyEditor', 'JuniorContentEditor', 'SeniorCopyEditor', 'SeniorSubmissionsCopyEditor', 'SubmissionsEditor', 'DeputyAdmin'].includes(el.type)
    })
  },

  editorIds: function(editors) {
    var array = editors ? this.copyOrContentEditors(editors) : this.copyOrContentEditors()
    return array.map(function(ed){return ed.editorId })
  },

  getStats: function(fromDate, toDate, selectedRange) {
    var self = this;
    self.setState({
      from: fromDate,
      to: toDate,
      selectedRange: selectedRange,
      loading: true
    }, function() {
      $.ajax({
        type: 'get',
        url: self.props.path,
        data: {from: self.state.from, to: self.state.to},
        success: function(res) {
          var editors = res.editors.data.map(function(ed){return ed.attributes})
          self.setState({editors: editors, editorArticles: res.editorArticles, checked: self.editorIds(editors), loading: false}, function(){ self.editorSort(self.state.sort) } )
          $("[type=checkbox]").prop('checked', true);
        },
        error: function(res) {
        }
      })
    })
  },

  handleSort: function(col) {
    return function() {
      if (col === this.state.sort) {
        this.setState({editors: this.state.editors.reverse()})
      } else {
        this.editorSort(col)
      }
    }.bind(this)
  },

  editorSort: function(col) {
    var self = this;
    var sortedEditors = self.state.editors.sort(function(a, b) {
      var aVal = a[col];
      var bVal = b[col];
      if (col === 'time') {
        aVal = parseFloat(aVal.num);
        bVal = parseFloat(bVal.num);
      }

      aVal = self.parseCurrency(aVal);
      bVal = self.parseCurrency(bVal);
      aVal = self.assignVal(aVal);
      bVal = self.assignVal(bVal)

      if (aVal < bVal) { return -1; }
      if (aVal > bVal) { return 1; }
      return 0;
    });
    self.setState({editors:sortedEditors, sort: col})
  },

  parseCurrency: function(curr) {
    if (curr !== undefined && curr !== null) {
      if (curr[0] === '$' || curr[curr.length - 1] === "%") {
        curr = parseInt(curr.replace(/\$|\.|\%|\,/g,''))
      }
    }
    return curr;
  },

  assignVal: function(curr) {
    if(curr === undefined || curr === null || curr === '-') {
      curr = Number.MIN_SAFE_INTEGER
    }
    return curr;
  },

  openModal: function(editorId) {
    var self = this;
    Modal.open({
      children: <ArticlesModal
                  editorArticles={self.state.editorArticles[editorId]}
                />
    });
  },

  mapRows: function() {
    var self = this;
    if (self.state.editors.length) {
      return self.state.editors.map(function(ed) {
        return(
          <tr key={ed.editorId}>
            <td>{self.renderCheckBox(ed)}</td>
            <td><a href={ed.adminUserPath}>{ed.email}</a></td>
            <td>{ed.firstName}</td>
            <td>{ed.lastName}</td>
            <td>{ed.title}</td>
            <td>{ed.time.words}</td>
            <td style={{cursor: 'pointer'}} onClick={function() { self.openModal(ed.editorId) }}><span className='span-link'>{ed.count}</span></td>
            <td>{ed.rate || "-"}</td>
            <td>{ed.preferredPercentage || '-'}</td>
            <td>{ed.miscTotal || '-'}</td>
            <td><a href={ed.adminUserInvoicePath} target="_blank">{ed.total || '-'}</a></td>
          </tr>
        )
      });
    } else {
      return( <tr><td className='centered' colSpan='10'>Nothing in this date range</td></tr>)
    }
  },

  renderCheckBox: function(ed) {
    var self = this;
    if (["ContentEditor", "CopyEditor", "SubmissionsCopyEditor", 'JuniorCopyEditor', 'JuniorContentEditor', 'SeniorCopyEditor', 'SeniorSubmissionsCopyEditor', 'SubmissionsEditor', 'DeputyAdmin'].includes(ed.type)) {
      return <input onChange={function() { self.handleCheck(ed.editorId) }} type="checkbox"/>
    } else {
      return "-"
    }
  },

  handleCheck: function(editorId) {
    var updatedChecked = this.state.checked
    var index = updatedChecked.indexOf(editorId)
    if (index !== -1) {
      updatedChecked.splice(index, 1)
    } else {
      updatedChecked.push(editorId)
    }
    this.setState({checked: updatedChecked})
  },

  handleCheckAll: function(){
    var self = this;
    if(self.state.checked.length === self.copyOrContentEditors().length){
      self.setState({checked: []}, function(){
        $("[type=checkbox]").prop('checked', false);
      })
    }else{
      self.setState({checked: self.editorIds()}, function(){
        $("[type=checkbox]").prop('checked', true);
      })
    }
  },

  handleDownload: function() {
    $('#editor-download-form').submit();
  },

  renderTable: function() {
    if (this.state.loading) {
      return <center><img src="https://public.cureus.com/pac-loader.gif"/></center>
    } else {
      return (
          <table className='table-wrapper dataTable'>
            <tr>
              <th><input onChange={this.handleCheckAll} type="checkbox"/></th>
              <th onClick={this.handleSort('email')}>Email</th>
              <th onClick={this.handleSort('firstName')}>First name</th>
              <th onClick={this.handleSort('lastName')}>Last name</th>
              <th onClick={this.handleSort('type')}>Type</th>
              <th onClick={this.handleSort('time')}><i className='far fa-clock'/></th>
              <th onClick={this.handleSort('count')}><i className='far fa-file'/></th>
              <th onClick={this.handleSort('rate')}>Rate</th>
              <th onClick={this.handleSort('preferredPercentage')}>%</th>
              <th onClick={this.handleSort('miscTotal')}>Misc</th>
              <th onClick={this.handleSort('total')}>Total</th>
            </tr>
            <tbody>
              {this.mapRows()}
            </tbody>
        </table>
      )
    }
  },

  render: function() {
    return(
      <div>
        <div className='row'>
          <h3 className='reg'>Editor Rates and Invoices</h3>
          <div className='large-7 columns'>
            <p>This view shows all editorial activity in the selected date range. To view a detailed breakdown of editor activity and add miscellaneous items, click the linked amount in the Total column.
              To bulk download invoices, adjust the date range to the desired pay period and check the desired editor's boxes. Then click the button to have the files sent to your email.</p>
            <a className="button" onClick={this.handleDownload}>Email Invoices</a>
            <form id='editor-download-form' method="post" action={this.props.editorDownloadPath}>
              <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
              <input type='hidden' value={this.state.checked} name="download[editor_ids]" />
              <input type='hidden' value={this.state.from} name="download[start_time]" />
              <input type='hidden' value={this.state.to} name="download[end_time]" />
              <input type='hidden' value={this.props.currentUserId} name="download[current_user_id]" />
            </form>
          </div>
          <div className='large-4 columns large-offset-1'>
            <DateSelector
                from={this.state.from}
                to={this.state.to}
                getStats={this.getStats}
                selectedRange={this.state.selectedRange}
                dateRanges={this.state.dateRanges}
              />
              <br></br>
              <EditorTotals
                editors={this.copyOrContentEditors()}
              />
          </div>
        </div>
        <div className='row'>
          <div className='large-12 columns'>
            {this.renderTable()}
          </div>
        </div>
      </div>
    )
  }
})

export default Table;