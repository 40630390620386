var createReactClass = require('create-react-class');
import React from 'react';
import NpiRows from './NpiRows';

const NpiUs = createReactClass({
  getInitialState: function() {
    return {
      firstName: "",
      lastName: "",
      id: "",
      state: "",
      results: null,
      disabled: '',
      errorMessage: null,
      searching: false
    }
  },

  renderTable: function() {
    if (this.state.results){
      if(this.state.results.length > 0) {
        return(
          <div className="npi-results-container">
            <h6>Select your ID from the list.</h6>
            <div className="npi-table-container">
            <table className="npi-table">
              <tbody>
                {this.renderNpiRows()}
              </tbody>
              </table>
            </div>
            <a style={{marginTop: "15px"}} className='button right secondary' onClick={() => this.setState({searching: false, results: null})}>Search Again</a>
          </div>
        )
      } else {
        return (
          <div className="npi-results-container">
            <h6>Select your ID from the list.</h6>
            <div className="npi-table-container no-results">
              <div className="no-match centered">We did not find a matching profile.</div>
            </div>
            <a style={{marginTop: "15px"}} className='button right secondary' onClick={() => this.setState({searching: false, results: null})}>Search Again</a>
          </div>
        )
      }
    }
  },

  renderNpiRows: function() {
    var self = this;
    return self.state.results.map(function(person) {
      return (
              <NpiRows
                key={person.number}
                id={person.number}
                name={person.basic.name}
                spec={person.taxonomies[0].desc}
                address={self.formatAddress(person)}
                phoneNumber={person.addresses[0].telephone_number}
                handleChange={self.props.handleChange}
              />
      )
    })
  },

  formatAddress: function(person) {
    var info = person.addresses[1]
    return `${info.address_1}, ${info.address_2} ${info.city}, ${info.state}`
  },

  fetchNpiResults: function() {
    var self = this;
    self.setState({disabled: 'disabled', searching: true})
    $.ajax({
      type: 'GET',
      url: self.props.npiPath,
      data: {first_name: self.state.firstName, last_name: self.state.lastName, id: self.state.id, state: self.state.state},
      success: function(res) {
        self.setState({results: res.results, disabled: '', errorMessage: ''})
      },
      error: function() {
        self.setState({errorMessage: "Something went wrong, please try again.", disabled: ''})
      }
    });
  },

  handleQueryChange: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  renderStateOptions: function() {
    return (
      <select onChange={(e) => this.handleQueryChange(e)} name="state" value={this.state.state}>
        <option value="">Please Select</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District Of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </select>
    )
  },


  renderSearchFields: function() {
    if (!this.state.searching) {
      return (
        <div>
          <div className="row">
          <div className="large-4 columns"><h6 className="reg">Enter your NPI Number:</h6></div>
          </div>
          <div className="row">
            <div className="large-4 columns">
                <input name="id" onChange={(e) => this.handleQueryChange(e)} type="text" placeholder="Must be exactly 10 digits" value={this.state.id}></input>
            </div>
            <div className="large-1 columns end">
            <a className={'button ' + (this.state.id.length === 10 ? '' : ' disabled')} onClick={this.fetchNpiResults}>Verify</a>
            </div>
          </div>
          <div className="row centered" style={{margin: '15px 0 15px 0'}}>- or -</div>
          <div className="row">
          <div className="large-4 columns"><h6 className="reg">Search for your NPI Number:</h6></div>
          </div>
          <div className="row">
            <div className="large-4 columns">
              <label>First Name:</label>
              <input name="firstName" onChange={(e) => this.handleQueryChange(e)} type="text" value={this.state.firstName}></input>
            </div>
            <div className="large-4 columns end">
              <label>Last Name:</label>
              <input name="lastName" onChange={(e) => this.handleQueryChange(e)} type="text" value={this.state.lastName}></input>
            </div>
          </div>
          <div className="row">
            <div className="large-4 columns">
              <label>State:</label>
            </div>
          </div>
          <div className="row">
            <div className="large-4 columns">
              {this.renderStateOptions()}
            </div>
            <div className="large-1 columns end">
            <a className={'button'} onClick={this.fetchNpiResults}>Search</a>
            </div>
          </div>
        </div>
      )
    }
  },

  render: function(){
    return (
      <div>
        {this.renderSearchFields()}
        {this.renderTable()}
      </div>
    )
  }
})

export default NpiUs;