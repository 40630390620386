var createReactClass = require('create-react-class');
import React from 'react';
import Close from './Close';
import MinMax from './MinMax';

const TopBar = createReactClass({
  render: function(){
    return (
      <div id="howto-topbar" className="clearfix">
        <h5 className="pull-left">
          <i className="far fa-life-ring"/>
          &nbsp;How-to &amp; Info
        </h5>
        <Close handleClose={ this.props.handleClose } />
        <MinMax dom_id={ this.props.dom_id } content_id={ this.props.content_id } nav_id={ this.props.nav_id } />
      </div>
    );
  }
});

export default TopBar;