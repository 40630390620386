var createReactClass = require('create-react-class');
import React from 'react';

const TopicTool = createReactClass({
  getInitialState: function() {
    return {
      topic: "",
      topics: []
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  topicsRemaining: function() {
    return this.props.topicLimit - this.state.topics.length;
  },

  componentWillMount: function() {
    this.setState({
      topics: this.props.topics
    });
  },

  componentDidMount: function() {
    var self = this;
    var $input = $(self.refs.input);
    
    $input.autocomplete({
      source: self.topicSuggestions,
      select: function(e, ui) {
        self.setState({
          topic: ui.item.name
        });
        self.handleAdd();
      }
    });

    this.checkForTopicPresence();
  },

  topicSuggestions: function(request, response) {
    var term = request.term;
    var searchUrl = this.props.basePath + "/search";

    $.ajax({
      type: 'GET',
      url: searchUrl,
      data: _.extend(this.props.params, {topic: term}),
      success: function(data) {
        var topicSuggestions = [];
        _.each(data, function(el) {
          topicSuggestions.push({
            label: el.name,
            value: el.name,
            name: el.name,
            id: el.id
          });
        });
        response(topicSuggestions);
      }
    });
  },

  handleChange: function(e) {
    this.setState({
      topic: e.target.value
    });
  },

  handleKeyPress: function(e) {
    if(e.key === 'Enter') {
      this.handleAdd();
      e.preventDefault();
    }
  },

  handleAdd: function() {
    var self = this;

    if(self.state.topics.length < self.props.topicLimit) {
      $.ajax({
        type: 'POST',
        url: self.props.basePath,
        data: _.extend(self.props.params, {
          topic: self.state.topic
        }),
        success: function(data) {
          self.setState({
            topics: self.state.topics.concat(data),
            topic: ""
          });
          self.checkForTopicPresence();
        }
      });
    };
  },

  handleRemove: function(topic) {
    var self = this;

    $.ajax({
      type: 'DELETE',
      url: self.props.basePath + "/" + topic.id,
      data: self.props.params,
      success: function() {
        self.removeTopic(topic);
        self.checkForTopicPresence();
      }
    });
  },

  removeTopic: function(topic) {
    var topics = _.reject(this.state.topics, function(kw) {
      return topic.id == kw.id;
    });

    this.setState({
      topics: topics
    });
  },

  checkForTopicPresence: function() {
    this.state.topics.length < this.props.topicssRequired ? this.disableButton() : this.enableButton()
  },

  enableButton: function() {
    [].forEach.call(this.$buttons(), function(el) {
      el.removeAttribute("disabled")
    })
  },

  disableButton: function() {
    [].forEach.call(this.$buttons(), function(el) {
      el.setAttribute("disabled", true)
    })
  },

  $buttons: function() {
    return $(this.props.enableDisableTarget)
  },

  renderRemaining: function() {
    if(this.state.topics.length < this.props.topicssRequired) {
      var remaining = this.props.topicssRequired - this.state.topics.length;
      return (
        <p className="beta-keyword-info small-description">
          Please add {remaining} more {remaining === 1 ? "keyword" : "keywords"} in order to proceed.
        </p>
      )
    } else if(this.topicsRemaining() <= this.props.topicLimit) {
      return (
        <p className="beta-keyword-info small-description">
          You can add { this.topicsRemaining() } more topics.
        </p>
      )
    }
  },

  render: function() {
    var self = this;

    if(self.topicsRemaining() < self.props.topicLimit){
      var actionBox =
          <div>
            <div className='beta-keyword-list clearfix'>
              <ul id="beta-keywords">
                {
                  self.state.topics.map(function(topic) {
                    return (
                      <li key={ topic.name }>
                        <div className='keyword_left_triangle'/>
                        <div className='dot-wrap'/>
                        <div className='dot'/>
                        { topic.name }
                        <a onClick={ self.handleRemove.bind(self, topic) }>
                          <i className='fa fa-times'>
                          </i>
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div>

            </div>
          </div>

    } else { var actionBox = <div></div> }


    return (
      <div className='beta-keyword-panel'>
        <div className='keyword-input-wrap'>
          <div className='row'>
            <div className='large-12 columns'>
              <small>Enter topics separated by a comma.</small>
            </div>
          </div>

          <div className='row'>
            <div className='large-6 medium-6 small-9 columns'>
              <input type='text'  autoComplete="off" id="ui-autocomplete-input" ref='input' disabled={self.topicsRemaining() === 0} onChange={ self.handleChange } onKeyPress={ self.handleKeyPress } value={ self.state.topic }/>
            </div>
            <div className='large-1 medium-1 small-1 columns'>
              <a className='button' disabled={self.topicsRemaining() === 0} onClick={ self.handleAdd }>Add</a>
            </div>
            <div className='large-5 columns'></div>
          </div>
        </div>
        <div>
            { actionBox }
        </div>
        {this.renderRemaining()}
      </div>
    )
  }
});

export default TopicTool;
