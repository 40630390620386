import React, { useState, useEffect } from 'react';

const SplitDropDownSort = ({ handleSort, sortBy }) => {

  const renderSortCheck = (sortSelected) => {
    if (sortSelected === sortBy) {
      return <i className="fas fa-check"/>
    }
  }

  return (
    <div className="cureus-split-dropdown-wrap sort-button comment">
      <a className="button secondary" style={{marginBottom: "0px"}} data-cureus-split-dropdown={"sort-button-" + 1}>
        <span><i className="fas fa-sort-amount-down-alt"/></span>
      </a>
      <ul id={"sort-button-" + 1} className="cureus-split-dropdown new-dash">
        <li className="header">
          Sort By
        </li>
        <li onClick={() => handleSort('asc')}>
            {renderSortCheck("asc")}
          <a>Oldest to Newest</a>
        </li>
        <li onClick={() => handleSort('desc')}>
          {renderSortCheck("desc")}
          <a>Newest to Oldest</a>
        </li>
      </ul>
    </div>
  )
}

export default SplitDropDownSort;