import React from 'react';

const MobilePersonRowCard = ({ person }) => {

  const specialtyName = () => {
    let name = person.specialty.toLowerCase()
    if (!person.qualifications.toLowerCase().includes(name) ) {
      return <p><a className={classForSpecialty()} target="_blank" href={person.category_url}>{person.specialty}</a></p>
    }
  }

  const classForSpecialty = () => {
    if (!person.category_url) {
      return "no-link"
    }
  }

  return (
    <div className="row">
      <div className="mobile-card">
        <div className="card-body">
          <div className="icons">
            <img className="avatar" src={person.avatar_image} alt="person icon"/>
            {person.honors_badge && <img className="badge" src={person.honors_badge} alt="badge icon"/>}
          </div>

          <div className="details">
            <p className='name'><a target="_blank" href={`/users/${person.id}`}><strong>{person.first_name} {person.last_name}</strong></a></p>
            <p className='qualifications'>{person.qualifications}</p>
            {specialtyName()}
            <p className='affiliations'>{person.affiliation_name}</p>
          </div>

          <div className="stats row text-center">
            <div className="columns small-4 publications">
              <p>Publications</p>
              <p><strong>{person.publication_count}</strong></p>
            </div>
            <div className="columns small-4 reviews">
              <p>Reviews</p>
              <p><strong>{person.reviews_count}</strong></p>
            </div>
            <div className="columns small-4 scorings">
              <p>SIQ Scores</p>
              <p><strong>{person.siq_rated_count}</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePersonRowCard;