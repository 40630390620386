import React, { useState, useRef, useEffect } from 'react';
import Modal from '../Modal';
import NewUserModal from './NewUserModal';

const Main = ({fetchUsersPath, checkUserExistsPath, newUserPath, removeEntriesPath}) => {

  const [spinning, setSpinning] = useState(true);
  const [usersSelected, setUsersSelected] = useState(false);

  useEffect(() => {
    populateTable();
    fetchUsers();
  }, [])

  const setColumns = () => {
    return [
      {width: '160px'},
      {width: '110px'},
      {width: '110px'},
      {width: '200px'},
      {width: '110px'},
      {width: '200px'},
      {width: '30px'},
    ]
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.email,
        row.first_name,
        row.last_name,
        row.affiliation,
        row.date_added,
        row.reason,
        row.checkbox
      ], false)
    )
  }

  const drawData = (userData) => {
    var dt = $("#users-table").DataTable();
    dt.api().clear();
    for (var i = 0; i < userData.length; i++) {
      let row = userData[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
  }

  const populateTable = (userData) => {
    var dt = $("#users-table").DataTable({
      destroy: true,
      columns: setColumns(),
      searching: false,
      dom: '<"row">tr<"pagination-container"<"large-8 columns pagination-details" <"pull-left"p>>>',
      "bAutoWidth": false,
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
      "rowCallback": function(row) {
        row.children[6].children[0].addEventListener('click',function(e) {
          checkboxClicked(e)
        })
      }
    })
  }

  const deleteSelected = (e) => {
    setSpinning(true)
    var wallOfShameIds = []
    $("input:checked").each(function() {
      wallOfShameIds.push($(this).data('resource-id'))
    })
    $.ajax({
      type: 'Delete',
      url: removeEntriesPath,
      data: {ids: wallOfShameIds},
      success: function() {
        setSpinning(false)
        fetchUsers()
        setChecked(false)
      }
    });
  }

  const checkboxClicked = () => {
    const checkedInputs = $("input:checked");
    setUsersSelected(checkedInputs.length > 0)
  }

  const fetchUsers = () => {
    setSpinning(true)
    $.ajax({
      type: 'GET',
      url: fetchUsersPath,
      success: function(data) {
        setSpinning(false)
        drawData(data)
      }
    });
  }

  const addNewUser = (e) => {
    e.preventDefault()
    Modal.open({
      children: <NewUserModal fetchUsers={fetchUsers} checkUserExistsPath={checkUserExistsPath} newUserPath={newUserPath} />
    });
  }

  return (
    <React.Fragment>
      <div className="wall-of-shame-container">
        <h3>Users</h3>
        <table id="users-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Affiliation</th>
              <th>Date added</th>
              <th>Reason</th>
              <th><i className="fas fa-pencil-alt"></i></th>
            </tr>
          </thead>
          { spinning &&
              <div className='spinner-container'>
                <img src='https://public.cureus.com/ajax-loader.gif'/>
              </div>
          }
          { !spinning &&
            <tbody></tbody>
          }
        </table>

        <a className="button special add-new-button" onClick={(e) => addNewUser(e)}>
          <i className="fa fa-plus-circle"></i>ADD NEW
        </a>

        <a className={`button delete-button ${usersSelected ? '' : 'hidden'}`} onClick={(e) => deleteSelected(e)}>
          DELETE
        </a>

      </div>
    </React.Fragment>
  )

}

export default Main;
