import React from 'react';

const RetractionPolicy = () => {
  return (
    <React.Fragment>
      <h1>Retraction & Correction Policies</h1>
      <h2>Retractions</h2>
      <p>
        Cureus reserves the right to retract articles that are found to be plagiarized or fraudulent (e.g. containing data manipulation or misrepresentation) or in serious breach of one of our policies. For more information on our process for dealing with scientific misconduct, <a href="/author_guide#!/policies-and-procedures/plagiarism-misconduct-and-ethics" target='_blank'>please refer to the Publication Ethics and Scientific Misconduct section</a>.
      </p>
      <h2>Corrections</h2>
      <p>
        Cureus publishes corrections to address errors of scientific fact in published articles. Errors that invalidate the work (typically by affecting the results and conclusions) are not eligible for correction and will instead result in article retraction. <strong>Cureus does not publish corrections of minor issues that do not substantively impact the article’s scientific integrity or understanding.</strong>
      </p>
      <p>
        <strong>Corrections related to author institutional affiliation errors will be considered at the discretion of the editorial staff.</strong> If permitted, these corrections require a $300 fee to offset the considerable time and resources expended by the journal in evaluating and replying to these requests. Authors are provided with multiple opportunities to ensure that their institutional affiliations are correct prior to publication.
      </p>
      <p>
        Formal correction notices are published and linked to the corrected article. If the corrected article is already indexed in PubMed Central, it will be submitted to ensure the corrected version and correction notice are indexed.
      </p>
    </React.Fragment>
  )
}

export default RetractionPolicy;