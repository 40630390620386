var createReactClass = require('create-react-class');
import { Cureus } from '../../Cureus';
import React from 'react';
import ReactDOM from 'react-dom';
import VersionsTable from './VersionsTable';
import { Annotator } from '../../../../../vendor/assets/javascripts/annotator-full';
import '../../../../../vendor/assets/javascripts/annotator.touch';
import PointToPointModal from './PointToPointModal';
import Modal from '../Modal';
import AnnotationComment from './AnnotationComment';
import { getCookie } from '../../Cureus/Cookies';
import QuestionnaireQuestionComment from './QuestionnaireQuestionComment';

const Main = createReactClass({
  getInitialState: function(){
    return {
      snapshot: {},
      readOnly: false,
      orderedArray: [],
      annotating: true,
      selectedComment: null
    }
  },

  componentDidMount: function(){
    this.initializeSpinner();
    this.fetchSnapshot(this.determineSnapshot());
    var self = this;
    window.onload = function() { self.setState({annotating: false}, function() {
      $(".annotations-overlay").fadeOut(1700);
    })}
    self.enablePublishButton(self.props.hasAllResponses)
    if(!getCookie('point-to-point-modal') && self.props.submitter && !self.props.printMode) {
      Modal.open({
        children: <PointToPointModal />
      });
    }
  },

  componentDidUpdate: function() {
    MathJax.Hub.Queue(["Typeset",MathJax.Hub]);
    if($("[data-annotation-id]").length >= this.state.snapshot.comment_count && this.state.annotating) {
      this.setState({annotating: false}, function() {
        $(".annotations-overlay").fadeOut(1700);
      })
    }
  },

  enablePublishButton: function(bool) {
    if(bool) {
      let button = document.getElementById("next-modal-button")
      if(button) {
        button.classList.remove("disabled")
      }
    }
  },

  determineSnapshot: function() {
    if(window.location.href.split('snapshot_id=')[1]) {
      return "/publish/snapshots/" + window.location.href.split('snapshot_id=')[1]
    } else {
      return this.props.snapshotUrl
    }
  },

  setSelectedComment: function(annotationId) {
    this.setState({selectedComment: annotationId})
  },

  appendBackToCommentButton: function() {
    if(this.state.selectedComment) {
      this.removeAppendDiv()
      $(`[data-annotation-id='${this.state.selectedComment}']`).before("<div id='comment-back-button-append'></div>")
      ReactDOM.render(<a className='button primary annotation-back-button small' onClick={this.backToComment}><i class="fas fa-arrow-circle-down"/>Back To Comment</a>, document.getElementById('comment-back-button-append'))
    }
  },

  backToComment: function() {
    this.removeAppendDiv()
    let ele = document.getElementById(`annotation-comment-${this.state.selectedComment}`)
    ele.scrollIntoView({behavior: 'smooth'})
    this.setState({selectedComment: null})
  },

  removeAppendDiv: function() {
    let ele = document.getElementById('comment-back-button-append');
    if(ele) {
      ele.remove()
    }
  },

  initializeSpinner: function(){
    $("#spinner").spin();
  },

  initializeAnnotator: function(){
    var annotator = $('.annotations').annotator({
      readOnly: this.props.printMode ? true : this.state.snapshot.read_only,
      adminEditorOrSubmitter: this.props.submitter || this.props.adminOrEditor
    })
    annotator.annotator('addPlugin', 'Touch', {
      useHighlighter: false
    })
    annotator.annotator('addPlugin', 'Store', {
      prefix: this.state.snapshot.annotator_prefix_url
    });

    var permissions = {
        "read": [],
        "update": [],
        "delete": [],
        "admin": ["no_one"] }

    if(!this.state.snapshot.readOnly){
      permissions["update"] = [this.props.userId];
      permissions["delete"] = [this.props.userId];
    }

    annotator.annotator('addPlugin', 'Permissions', {
      user: this.props.userId,
      highlightColor: this.props.highlightColor,
      permissions: permissions,
      rejectedRevs: this.props.rejectedRevs
    });
    if (this.props.submitter) {
      $('.annotator-adder').remove()
    }

    var self = this;
    window.setTimeout(function(){self.getOrderedAnnotationIds()}, 1500);
  },

  reloadAnnotations: function(){
    $.each(Annotator._instances.slice(0), function(){
      this.destroy();
    })
    this.initializeAnnotator();
  },

  fetchSnapshot: function(url){
    var self = this;
    var $overlay = $(".annotations-overlay");
    $("sup").slice(1).remove()
    $overlay.css('display', 'block')
    self.setState({annotating: true})
    $.get(url, function(data){
      self.setState({snapshot: data, orderedArray: []}, function(){
        MathJax.Hub.Queue(function() {
          ["Typeset",MathJax.Hub], [self.reloadAnnotations()]
        });
        self.initializeLightBox();
        $(document).foundation();
        self.reflowTabs()
        self.appendPrintButton();
        self.setReferenceAnchors()
      })
    }, "json")
  },

  setReferenceAnchors: function() {
    let refs = document.getElementById('references')
    if(refs) {
      document.querySelectorAll('.refs-anchor').forEach(function(element) {
        element.addEventListener('click', function() {
          const y = refs.getBoundingClientRect().top + window.pageYOffset - 70;
          window.scrollTo({top: y, left: 0, behavior: 'smooth'});
        })
      })
    }
  },

  appendPrintButton: function() {
    if(!this.props.printMode) {
      $('.tabs, .inline').last().after("<ul class='inline' id='append-print-button'></ul>")
      ReactDOM.render(<><a class='button tiny dropdown left' id='print-button' data-dropdown='print-options' aria-controls='print-options'>Print Options</a> <ul id='print-options' class='f-dropdown' data-dropdown-content aria-hidden='true'> <li key='1'> <a class='' target='_blank' id='print-options-button'  href={this.props.printPdf}><i class='fa fa-download' aria-hidden='true'></i>Print PDF - No Comments</a> </li> <li key='2'> <a class='' target='_blank' id='print-options-button' href={this.props.printWithAnnotations + "?snapshot_id=" + this.state.snapshot.id}><i class='fa fa-print' aria-hidden='true'></i>Print With Comments</a> </li> </ul></>, document.getElementById('append-print-button'), function() {
        $(document).foundation('reflow');
      })
    }
  },

  reflowTabs: function() {
    var tabs = document.querySelectorAll('.can-active');
    tabs.forEach(function(tab) {
      tab.addEventListener('click', function() {
        tabs.forEach(function(tab) {
          tab.classList.remove('active')
        })
      })
    })
  },

  initializeLightBox: function() {
    Cureus.MediaLightbox.init({
      items: $(".gallery-item")
    });
  },

  versionsBar: function() {
    if (!this.props.printMode) {
      return (
        <VersionsTable
          optionsForVersionSelect={this.props.optionsForVersionSelect}
          snapshot={this.state.snapshot.humanized_version}
          snapshotId={this.state.snapshot.id}
          printWithAnnotations={this.props.printWithAnnotations + "?snapshot_id=" + this.state.snapshot.id}
          printPdf={this.props.printPdf}
          viewVersions={this.props.viewVersions}
          fetchSnapshot={this.fetchSnapshot}
        />
      )
    }
  },

  handleStrikeThrough: function(state) {
    if(state === 'rejected') {
      return {textDecoration: 'line-through'}
    }
  },

  renderGeneralComments: function() {
    var self = this;
    let generalComments = self.state.snapshot.general_comments;
    let thoughts = []
    let assessments = []
    let summaries = []
    Object.keys(generalComments).forEach(function(greekSymbol) {
      if (generalComments[greekSymbol]['thoughts']) {
        thoughts.push((<p key={greekSymbol}><span style={{'fontWeight': 'bold'}}>Reviewer {greekSymbol}: </span><span style={self.handleStrikeThrough(generalComments[greekSymbol]['state'])}>{generalComments[greekSymbol]['thoughts']}</span></p>))
      } else {
        if(generalComments[greekSymbol]['assessment'] && generalComments[greekSymbol]['summary']) {
          assessments.push((<p key={greekSymbol}><span style={{'fontWeight': 'bold'}}>Reviewer {greekSymbol}: </span><span style={self.handleStrikeThrough(generalComments[greekSymbol]['state'])}>{generalComments[greekSymbol]['assessment']}</span></p>))
          summaries.push((<p key={greekSymbol}><span style={{'fontWeight': 'bold'}}>Reviewer {greekSymbol}: </span><span style={self.handleStrikeThrough(generalComments[greekSymbol]['state'])}>{generalComments[greekSymbol]['summary']}</span></p>))
        }
      }
    })
    return (
     <React.Fragment>
       {this.renderThoughts(thoughts)}
       {this.renderQuestions(summaries, assessments)}
       {this.state.snapshot.has_completed_questionnaire && (
          this.questionnaireResponses()
        )}
      </React.Fragment>
    )
  },

  questionnaireResponses: function() {
    if(!this.props.submitter && !this.props.adminOrEditor) {
      return;
    }
    let self = this;
    let questionnaireResponses = this.state.snapshot.questionnaire_responses;
    let responses = {};
    let questions = {};
    const questionnaireQuestions = Object.keys(questionnaireResponses)

    questionnaireQuestions.forEach(function(greekSymbol) {
      questionnaireResponses[greekSymbol].questions.forEach(function(question) {
        const questionType = question.type
        if(!responses[questionType]) {
          responses[questionType] = []
          questions[questionType] = []
        }
        if(questions[questionType].length == 0) {
          questions[questionType].push({question: question.question, questionType: question.type, questionId: question.id})
        }
  
        responses[questionType].push({reviewer: greekSymbol, response: question.reviewer_response, author_response: question.author_response, questionId: question.id, state: questionnaireResponses[greekSymbol]['state']})
      })
      
    })

    let questionIndex = 0;
    return (
      <React.Fragment>
        <div>
        <h3 className='reg'> Reviewer Questionnaire Comments</h3>
          {this.props.submitter && (this.props.articleState == 'reviews_complete' ||  this.props.articleState == 'rereviews_complete') && (
            <div>
              <p>Please provide our editors with your response to all reviewer questionnaire comments listed below. (Your responses are not shared with the peer reviewers.) If two or more reviewers have provided similar comments, please copy and paste your response. There is no minimum required response length, but our editors will be looking for thoughtful responses showing that you carefully considered all reviewer comments. While you are not required to make all revisions suggested by the reviewers, inadequate responses will require clarification and delay the publication of your article.</p>
              <br/>
            </div>
          )}
          {Object.keys(questions).map(function(question) {
            return questions[question].map(function(q) {
              questionIndex += 1;
              return self.renderQuestionnaireQuestion(self, q, responses, questionIndex)
            })
          })}
        </div>
      </React.Fragment>
    )
  },

  formatTextForHtml: function(text) {
    if (text.includes('\n\n')) {
      const paragraphs = text.split(/\n\n+/);
      return paragraphs.map(paragraph =>
        `<p>${paragraph.replace(/\n/g, '<br/>')}</p>`).join('');
    } else {
      return `<span>${text.replace(/\n/g, '<br/>')}</span>`;
    }
  },

  renderQuestionnaireQuestion: function(self, question, responses, questionIndex) {
    return (
      <div>
        <h6>{questionIndex}. {question.question}</h6>
        <br/>
        {responses[question.questionType].map(function(reviewer_response) {
          return (
            <QuestionnaireQuestionComment
            reviewerResponse={reviewer_response}
            submitter={self.props.submitter}
            articleId={self.state.snapshot.article_id}
            adminOrEditor={self.props.adminOrEditor}
            enablePublishButton={self.enablePublishButton}
            printMode={self.props.printMode}
            formatTextForHtml={self.formatTextForHtml}
            />
          )
        })}
        <br/>
      </div>
    )
  },

  renderThoughts: function(thoughts) {
    if (thoughts.length > 0) {
      return (
        <React.Fragment>
          <h6>General Comments:</h6>
          {thoughts.map(function(x) { return x })}
        </React.Fragment>
      )
    }
  },

  renderQuestions: function(summaries, assessments) {
    if(!this.props.submitter && !this.props.adminOrEditor) {
      return;
    }
    if (summaries.length > 0 || assessments.length > 0) {
      return (
        <React.Fragment>
          <h5>Reviewer Questionnaire Comments (Old Version):</h5>
          <p>Reviewers who started their review prior to the release of new, expanded reviewer questionnaire were prompted to reply to the following two questions only. Please revise accordingly, but you do not need to respond directly to these comments. The new, expanded reviewer questionnaire replies are in the next section and will require your response for each comment.</p>
          <br/>
          <p className="reviewer-question">Provide a brief summary of the main research question, claims, and conclusions of the study. How does this research fit within the existing literature?</p>
          {summaries.map(function(x) { return x })}
          <br/>
          <p className='reviewer-question'>Provide a brief assessment of the research process and presentation while noting any obvious flaws or areas of weakness. Are the conclusions consistent with the evidence presented?</p>
          {assessments.map(function(x) { return x })}
          <br/>
        </React.Fragment>
      )
    }
  },

  renderAnnotations: function() {
    var self = this;
    let found = []
    var annotationsList = self.state.orderedArray.map(function(id) {
      var foundAnnotation =  self.state.snapshot.snapshotAnnotations.filter(function(annotation){
        return annotation.id === id
      });
      found.push(...foundAnnotation)
      return (
        <AnnotationComment
          key={id}
          rejectedStyle={self.props.rejectedRevs.includes(foundAnnotation[0].highlight) ? self.handleStrikeThrough('rejected') : {}}
          foundAnnotation={foundAnnotation[0]}
          annotationId={id}
          submitterResponse={foundAnnotation[0].submitter_response}
          submitter={self.props.submitter}
          adminOrEditor={self.props.adminOrEditor}
          enablePublishButton={self.enablePublishButton}
          setSelectedComment={self.setSelectedComment}
          printMode={self.props.printMode}
          formatTextForHtml={self.formatTextForHtml}
        />
      )
    })
    if(annotationsList.length > 0 && found.length !== self.state.snapshot.snapshotAnnotations.length) {
      let missing = self.state.snapshot.snapshotAnnotations.filter(x => !found.includes(x));
      var missingAnnotations = missing.map(function(missingAnnotation) {
        return (
          <AnnotationComment
            key={missingAnnotation.id}
            rejectedStyle={self.props.rejectedRevs.includes(missingAnnotation.highlight) ? self.handleStrikeThrough('rejected') : {}}
            foundAnnotation={missingAnnotation}
            annotationId={missingAnnotation.id}
            submitterResponse={missingAnnotation.submitter_response}
            submitter={self.props.submitter}
            adminOrEditor={self.props.adminOrEditor}
            enablePublishButton={self.enablePublishButton}
            setSelectedComment={self.setSelectedComment}
            printMode={self.props.printMode}
            formatTextForHtml={self.formatTextForHtml}
        />
        )
      })
    }
    if(missingAnnotations) {
      annotationsList.push(...missingAnnotations)
    }
    return <ol>{annotationsList}</ol>
  },

  renderAnnotationsAtBottom: function() {
    if ((this.state.orderedArray.length === 0 && (!this.state.snapshot.general_comments || Object.keys(this.state.snapshot.general_comments).length === 0))|| this.state.annotating) {
      return;
    }
    return (
      <div className='bottom-annotations'>
        <div className='article-content-wrap'>
          <div className='article-content-section'>
            <h3 className='reg'> Reviewer Article Comments</h3>
            {this.renderRejectedText()}
            <h5>Article:</h5>
            {this.renderAnnotations()}
            {this.renderGeneralComments()}
          </div>
        </div>
      </div>
    )
  },

  renderRejectedText: function() {
    var self = this;
    if(self.props.rejectedRevs.length > 0) {
      return self.props.rejectedRevs.map(function(name) {
        return <p className="rejected-text">* Reviewer {name}'s review was rejected by the Cureus editorial team. Please refer to our <a target="_blank" href="/author_guide">Author Guide</a> for more information on this policy.</p>
      })
    }
  },

  getOrderedAnnotationIds:function() {
    var self = this;
    var filtered = [];
    let annotationsCount = $("[data-annotation-id]").length
    if(annotationsCount === 0 && !this.props.hasNoAnnotations) {
      if(self.state.snapshot.comment_count != 0) {
        setTimeout(function() {
          self.getOrderedAnnotationIds()
        }, 1000)
        return;
      } else {
        self.setState({orderedArray: []})
      }
    }

    let annotationIds = []

    $("[data-annotation-id]").each(function(index, el) {
      if (!self.duplicateAnnotation(filtered, el)) {
        filtered.push(el);

        var annotationId = $(el).data('annotationId')
        annotationIds.push(annotationId)
      }
    })

    self.setState({orderedArray: annotationIds});

    if(!self.props.isReviewer) {
      filtered.forEach(function(el, index) {
        if (self.needsComma(el, filtered[index + 1])) {
          $(el).prepend($("<sup class='unhighlightable'>" + (index + 1) + ", </sup>"));
        } else {
          $(el).prepend($("<sup class='unhighlightable'>" + (index + 1) + "</sup>"));
        }
      })
    }
  },

  needsComma: function(currElement, nextElement) {
    if(!nextElement) {return false;}
    if(this.getFirstTextNode(currElement) === this.getFirstTextNode(nextElement)) {
      return true
    }
    return false
  },


  getFirstTextNode: function(el) {
    for (let node of el.childNodes) {
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue.trim() !== "") {
        return node.nodeValue;
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        let result = this.getFirstTextNode(node);
        if (result) {
          return result;
        }
      }
    }
    return null;
  },
  

  duplicateAnnotation: function(filteredArr, el){
    return filteredArr.some(function(filt) {
      return $(filt).data('annotationId') === $(el).data('annotationId')
    });
  },

  printButton: function() {
    if (this.props.printMode) {
      return (
        <a className='button tiny secondary pull-right' onClick={function(){window.print()}}>Print</a>
      )
    }
  },

  floatNoneForPrintMode: function() {
    if (this.props.printMode) {
      return (
        <style>
          {"div{float: none !important"}
        </style>
      )
    }
  },

  showReviewingText: function() {
    if(this.props.viewVersions || this.state.snapshot.read_only && !this.props.printMode) {
      return <strong>You are viewing version:</strong>
    } else if (!this.props.viewVersions && !this.props.printMode) {
      return <strong> You are reviewing version: </strong>
    }
  },

  render: function(){
    return (
      <div className="row">
        {this.appendBackToCommentButton()}
        {this.floatNoneForPrintMode()}
        <div className="small-12 large-8 large-offset-2 columns">
          <div className="reviewer-tool">
            <div className="reviewer-tool-heading">
              <div className="row">
                <div className="small-12 columns">
                  {this.showReviewingText()}
                  {this.printButton()}
                </div>
              </div>
             { this.versionsBar() }
            </div>
            <hr/>
            <div className='annotations'>
              <div dangerouslySetInnerHTML={{__html: this.state.snapshot.html }}/>
            </div>
          </div>
            {this.renderAnnotationsAtBottom()}
        </div>
      </div>
    )
  }
})

export default Main;