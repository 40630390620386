var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';

const Main = createReactClass({
  componentDidMount: function (){
    if(this.props.spinnerSelector) {
      $(this.props.spinnerSelector).remove();
    }
  },

  componentWillMount: function (){
    this.setState({messages: this.props.messages});
  },

  handleDeleteMessage: function (message){
    var self, messages;
    self = this;
    messages = this.state.messages
    $.ajax({
      type: "delete",
      dataType: "json",
      url: message.deletePath
    })
      .done(function (data, textStatus, jqXHR){
        self.setState({
          messages: _.without(messages, message)
        });
      });
  },

  renderMessages: function() {
    var self = this;
    if (self.state.messages.length){
      return self.state.messages.map(function(message) {
        return <Row
                  message={message}
                  key={"adminMessageRow"+message.id}
                  onDelete={self.handleDeleteMessage} />
      });
    } else {
      return (
        <tr>
          <td colSpan={100} className='centered empty-row'>No messages yet</td>
        </tr>
      )
    }
  },

  render: function (){
    return (
      <div className="table-wrapper">
        <table className="message-board">
          <thead>
            <tr>
              <th className="date"> Date </th>
              <th className="admin"> Editor </th>
              <th> Message </th>
              <th className="single-action-icon"><i className="fa fa-edit"/></th>
              <th className="single-action-icon"><i className="far fa-trash-alt"/></th>
            </tr>
          </thead>
          <tbody>
            { this.renderMessages() }
          </tbody>
        </table>
      </div>
    )
  }
});

export default Main;