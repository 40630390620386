import React, { useState, useEffect } from 'react';
import CategorySidebar from './Sidebars/CategorySidebar';
import { useFirstMountState } from '../../hooks/useFirstMountState';
import Ad from '../Shared/Ad';
import BrowseArticles from './BrowseArticles/BrowseArticles';
import TrendingTopics from '../NewArticleIndex/RightSidebar/TrendingTopics';
import TopAuthors from '../Shared/TopAuthors';

const MostPopular = ({ categories, bannerAdTop, title, path }) => {
  const isFirstMount = useFirstMountState();
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState([]);
  const [submitCallback, setSubmitCallback] = useState(false);
  const [categorySelection, setCategorySelection] = useState({ allCategories: true });
  const [keywords, setKeywords] = useState([]);
  const [authors, setAuthors] = useState([]);

  useEffect(function onInitialLoad() {
    if (isFirstMount) {
      handleSubmit();
      fetchKeyWords();
      getTopAuthorsByViews();
      if (typeof bannerAdTop !== "object") {
        $("#banner-ad-1").append(bannerAdTop);
      }
    }
  }, []);

  // used to fetchData only after state has finished setting
  // (i.e. clicking abstract-category should setCategoryState and handleSubmit)
  useEffect(function fetchDataCallback() {
    if (!isFirstMount && submitCallback) {
      handleSubmit();
    }
  }, [submitCallback]);

  const getTopAuthorsByViews = () => {
    fetch(`/articles/top_authors_by_view`)
    .then((res) => res.json())
    .then((json) => {
      setAuthors(json.authors);
    });
  };

  const fetchKeyWords = () => {
    $.ajax(
      {
        method: "GET",
        url: '/articles/most_popular_keywords',
        dataType: "json",
        data: buildParams(),
        success: function (res) {
          setKeywords(res.keyWords || []);
        },
        error: function (res) {},
      }
    );
  };

  const selectCategory = (event, name, id = null) => {
    event.preventDefault();
    if (name === "allCategories") {
      return setCategorySelection({ allCategories: true });
    }

    const newCategorySelection = { ...categorySelection };
    if (!Object.keys(categorySelection).includes(`${id}`)) {
      delete newCategorySelection.allCategories;
      setCategorySelection({ ...newCategorySelection, [id]: name });
    } else {
      delete newCategorySelection[id];
      setCategorySelection(newCategorySelection);
    }
  };

  const buildParams = () => {
    if (!categorySelection["allCategories"]) {
      return {
        categories: Object.values(categorySelection),
        category_ids: Object.keys(categorySelection)
      }
    }

    return {
      categories: {},
    };
  };

  const handleSubmit = () => {
    setLoading(true);
    $.ajax({
      method: 'GET',
      url: path,
      data: buildParams(),
      dataType: 'json',
      success: function(res) {
        setContents(res.contents);
        setLoading(false);
        setSubmitCallback(false);
      },
      error: function(res) {
        console.log("error: ", res);
      }
    });
  };

  const renderBannerAd = (props, id) => {
    if (typeof props === "object") {
      return (
        <div id={id}>
          <div className="cureus-ad-wrapper page-banner-ad">
            <Ad props={props} size="banner" />
          </div>
        </div>
      );
    } else {
      return <div id={id} />;
    }
  };

  const selectCategoryAndSubmit = (name, id) => {
    setCategorySelection({ [id]: name });
    setSubmitCallback(true);
  };

  return (
    <div className="browse-index">
      <div className="row small-collapse">
        <CategorySidebar
          categories={categories}
          categorySelection={categorySelection}
          handleSubmit={handleSubmit}
          updateCategory={selectCategory}
        />
        <BrowseArticles
          bannerAdTopProps={bannerAdTop}
          contents={contents}
          loading={loading}
          renderBannerAd={renderBannerAd}
          handleSubmit={selectCategoryAndSubmit}
          title={title}
        />

        <div className="small-2 columns show-for-large-up">
          <div className="right-sidebar">
            <TrendingTopics
              keywords={keywords}
              path="/articles"
              headerEnabled={false}
            />
            <TopAuthors
              authors={authors}
              viewMoreButton={false}
              seeMoreUsersPath="/people"
              description="Their articles were most viewed yesterday"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MostPopular;