var ChannelApi = {

  fetchWidget: function({widgetId, slug, onSuccess=null, params}) {
    $.ajax({
      type: 'GET',
      url: `/admin/channels/${slug}/design/widgets/${widgetId}`,
      data: params,
      dataType: 'json',
      success: function(res) {
        if (onSuccess) { onSuccess(res); }
      }
    })
  },

  createWidget: function(params, slug, onSuccess=null, hasImage=false) {
    let headers = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
      'Accept': 'application/json'
    }
    if (!hasImage) {
      headers['Content-Type'] = 'application/json'
      params = JSON.stringify(params)
    }
    fetch(`/admin/channels/${slug}/design/widgets`, {
      method: 'POST',
      body: params,
      credentials: "same-origin",
      headers: headers,
    }).then(function(response) {
      return response.json()
    })
    .then(function(res) {
      if (onSuccess) { onSuccess(res); }
    })
  },

  updateWidget: function(widgetId, params, slug, onSuccess=null, hasImage=false) {
    let headers = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
      'Accept': 'application/json'
    }
    if (!hasImage) {
      headers['Content-Type'] = 'application/json'
      params = JSON.stringify(params)
    }
    fetch(`/admin/channels/${slug}/design/widgets/${widgetId}`, {
      method: 'PUT',
      body: params,
      credentials: "same-origin",
      headers: headers,
    }).then(function(response) {
      return response.json()
    })
    .then(function(res) {
      if (onSuccess) { onSuccess(res); }
    })
  },

  destroyWidget: function(widgetId, slug, onSuccess=null) {
    $.ajax({
      type: 'DELETE',
      url: `/admin/channels/${slug}/design/widgets/${widgetId}`,
      dataType: 'json',
      success: function(res) {
        if (onSuccess) { onSuccess(res); }
      }
    })
  }
};

export default ChannelApi;