import React, {useEffect} from 'react';

const Categories = ({categories, resourceId, showCategories=true}) => {
  const renderCategories = () => {
    if (!categories || showCategories == false) { return; }
    return categories.map(function(category) {
      return <a target="_blank" key={ category.id + "_" + resourceId} href={category.path} className="tag">{ category.name }</a>
    });
  }

  return (
    <div className="categories">
      {renderCategories() }
    </div>
  )
}

export default Categories;