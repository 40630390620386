import React from 'react';
import RejectedCollectionCard from './RejectedCollectionCard';
import Pages from '../../Shared/Pagination/Pages';

const RejectedTab = ({collections, paginationData, handlePageSelect, pagesType}) => {

  return (
    <div className='rejected-section'>
      {collections.map((collection) => {
        return (
          <RejectedCollectionCard
            key={collection.id}
            cardImgUrl={collection.card_url}
            label={collection.title}
            previewUrl={collection.preview_url}
            rejectedData={collection.rejected}
          />
        )
      })}
      <div className='pagination-container'>
        <Pages 
          paginationData={paginationData} 
          handlePageSelect={handlePageSelect} 
          pagesType={pagesType}
        />
      </div>
    </div>
  )
}

export default RejectedTab;