var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../Modal';

const CategoriesModal = createReactClass({
  getInitialState: function() {
    return({
      categories: this.props.categories
    })
  },

  mapCategories: function() {
    var self = this;
    // return Object.values(self.state.categories).map(function(el) {
    return self.props.categoryIds.map(function(id) {
      var el = self.state.categories[id]
      return(
          <label key={el.id}>
            <input type='checkbox' onChange={function(){ self.handleChange(el.id)}} checked={el.checked}/>
            &nbsp;{el.name}
          </label>
      )
    })
  },

  handleChange: function(catId) {
    var self = this;
    var updatedCategories = self.state.categories;
    updatedCategories[catId].checked = !updatedCategories[catId].checked
    self.setState({categories: updatedCategories}, function(){self.props.handleChange(updatedCategories)});
  },

  handleClose: function() {
      Modal.close({onCloseInstant: this.props.resetPage});
  },

  render: function(){
    return(
      <div data-reveal  className='cureus-custom-multi-select-modal reveal-modal'>
        <div className='modal-heading'>
          <div className='modal-title'>
            Select one or more categories.
          </div>
          <div className='modal-close'>
            <a href='#' className='tiny button' onClick={this.handleClose} style={{height: 'auto', width: 'auto'}}>
              Filter
            </a>
          </div>
        </div>
        <div className='modal-body-wrap'>
          <div className='modal-body'>
            <div className='modal-content'>
              {this.mapCategories()}
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default CategoriesModal;
