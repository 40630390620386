var createReactClass = require('create-react-class');
import React from 'react';
import EditFile from './EditFile';
import NewFile from './NewFile';

const Main = createReactClass({
  getInitialState: function() {
    return {
      poster: this.props.poster,
    }
  },

  setPoster: function(poster, disabledState) {
    this.setState({poster: poster})
    $("[data-button-type='next']").attr("disabled", disabledState)
  },

  render: function() {
    if ( this.state.poster && (this.state.poster.preview_url || this.state.poster.file) ) {
      return (
        <div>
          <EditFile
            poster={ this.state.poster }
            onUpdate={ this.setPoster }
            updateObjectFilePath={ this.props.update_poster_file_path }
          />
        </div>
      )
    } else {
      return (
        <div>
          <NewFile
            poster={ this.state.poster }
            onUpdate={ this.setPoster }
            updateObjectFilePath={ this.props.update_poster_file_path }
          />
        </div>
      )
    }
  }
});

export default Main;