import { post } from 'jquery';
import React, { useEffect } from 'react';

const Table = ({posts}) => {

  useEffect(() => {
    if (posts.length > 0) {
      initializeTable(posts);
    }
  }, [posts])

  const setColumns = () => {
    return [
      {width: '10%'},
      {width: '10%'},
      {width: '10%'},
      {width: '35%'},
      {width: '10%'},
      {width: '12.5%'},
      {width: '12.5%'},
    ]
  }

  const mapColumns = (dt) => {
    posts.forEach( (post) => {
      dt.fnAddData([
        post.platform,
        post.content_type,
        post.created_at,
        post.content_title,
        contentURLs(post),
        contentLocations(post),
        post.views,
      ])
    })
  }

  const contentURLs = (post) => {
    return post.content_information.map( (info) => {
      return `<a href="${info.content_url}" target="_blank">${info.content_location}</a>`
    }).join(", ")
  }

  const contentLocations = (post) => {
    return post.content_information.map( (info) => {
      return info.content_location
    }).join(", ")
  }

  const initializeTable = (userData) => {
    var dt = $("#social-media-posts-table").DataTable({
      destroy: true,
      columns: setColumns(),
      searching: true,
      order: [[2, "desc"]],
      dom: '<"row"<"large-5 columns"f>><"table"t>r<"row bottom"<"large-3 columns"p><"large-3 columns end show-amount"l>>'
    });
    dt.api().clear();
    mapColumns(dt)
    dt.fnDraw()
  }

  return (
    <div className="campaigns-index">
      <table id="social-media-posts-table">
        <thead>
          <tr>
            <th>Platform</th>
            <th>Content Type</th>
            <th>Dated Posted</th>
            <th>Content Title</th>
            <th>Content URL</th>
            <th>Content Posted Where</th>
            <th>Content Views</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  )
}

export default Table;




