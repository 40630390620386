import React, { useState, useEffect } from 'react';

const Draft = ({}) => {

  useEffect(() => {
    fetchData();
  }, [])

  const populateTable = (fetchedTableData) => {
    var dt = $("#keywords-table").DataTable({
      columns: [
        {width: '80%'},
        null
      ],
      searching: false,
      dom: '<"row">tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "bAutoWidth": false,
    })
    
    dt.api().clear();
    for (var i = 0; i < fetchedTableData.keywords.length; i++) {
      let row = fetchedTableData.keywords[i];
      dt.fnAddData( [
        row[0],
        row[1]
      ], false)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
  }

  const fetchData = () => {
    $.ajax({
      type: 'GET',
      url: "/admin/keywords/most_used_keywords",
      success: function(data) {
        populateTable(data)
      }
    });
  }


  return (
    <div className="small-12 columns">
      <div className="channel-articles-datatable-container">
        <h4>Most popular over last 30 days</h4>
        <br/>
        <table id="keywords-table">
          <thead>
            <tr>
              <th>Keyword</th>
              <th>Frequency</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>  
    </div>
  )
}

export default Draft;