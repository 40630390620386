var createReactClass = require('create-react-class');
import React from 'react';
import Main from '../../StripeV2/Main';
import PostDeferralOptionsModal from './PostDeferralOptionsModal';
import { Cureus } from '../../../Cureus';

const PaidServiceModal = createReactClass({
  componentDidMount: function() {
    new Cureus.Amplitude({
      target_id: this.props.article.id,
      event_type: "EditingServicePaymentModal",
      source: "dashboard",
      target_type: "Article"
    })
  },

  modalSelection: function () {
    return (
      <Main
        product="preferred_editing"
        articleId={this.props.article.id}
        optional={false}
        source="dashboard"
        copyText={this.props.article.copyText}
        title={this.props.article.title}
        />
    )
  },

  render: function() {
    return (
      <div className="reveal-modal middling" data-reveal id="payment-modal">
        {this.modalSelection()}
      </div>
    )
  }
})

export default PaidServiceModal;