var createReactClass = require('create-react-class');
import React from 'react';

const DropdownNav = createReactClass({
  getDefaultProps: function() {
    return {
      selectOptions: [
        {
          label: "Placeholder label",
          value: "Placeholder value"
        }
      ],
      value: "Placeholder label"
    }
  },

  handleChange: function(val) {
    window.location = val;
  },

  render: function() {
    return (
      <div>
        <Select
          options={ this.props.selectOptions }
          searchable={ false }
          value={ this.props.value }
          clearable={ false }
          onChange={ this.handleChange }
          className="plain-select with-arrow"
        />
      </div>
    )
  }
});

export default DropdownNav;