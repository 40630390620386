var createReactClass = require('create-react-class');
import React from 'react';
import DateSelector from '../StatsWidget/DateSelector';

const Main = createReactClass({
  getInitialState: function() {
    return {
      categoryId: null,
      approvalEditorRating: null,
      dateRanges: this.props.dateRanges,
      selectedRange: this.props.dateRanges[3].label,
      from: this.props.dateRanges[3].from,
      to: this.props.dateRanges[3].to,
    }
  },

  componentDidMount: function() {
    this.initializeTable();
  },

  getCategoryOptions: function() {
    return this.props.categories.map(function(cat) {
      return {
        label: cat.name,
        value: cat.id
      }
    })
  },

  handleCategorySelect: function(val) {
    this.setState({categoryId: val})
  },

  renderCategoryDropdown: function() {
    return (
      <Select
        placeholder="Select a Category"
        value={this.state.categoryId}
        options={this.getCategoryOptions()}
        searchable={false}
        className='plain-select'
        onChange={this.handleCategorySelect}
      />
    )
  },

  handleApprovalEditorRatingSelect: function(val) {
    this.setState({approvalEditorRating: val})
  },

  renderApprovalEditorRatingDropdown: function() {
    var options = [
      {label: '1', value: 1},
      {label: '2', value: 2},
      {label: '3', value: 3},
      {label: '4', value: 4},
      {label: '5', value: 5}
    ]
    return (
      <Select
        placeholder="Editor Rating Cutoff"
        value={this.state.approvalEditorRating}
        options={options}
        searchable={false}
        className='plain-select'
        onChange={this.handleApprovalEditorRatingSelect}
        defaultValue={2}
      />
    )
  },

  handleDateRangeSelect: function(fromDate, toDate, selectedRange) {
    this.setState({
      from: fromDate,
      to: toDate,
      selectedRange: selectedRange
    })
  },

  renderDatePicker: function() {
    return (
      <DateSelector
        from={this.state.from}
        to={this.state.to}
        getStats={this.handleDateRangeSelect}
        selectedRange={this.state.selectedRange}
        dateRanges={this.state.dateRanges}
      />
    )
  },

  renderTable: function() {
    return (
      <table className="table-wrapper" id="cureus-u-datatable">
        <thead>
          <tr>
            <th className="centered">Title</th>
            <th>Published Date</th>
            <th></th>
            <th>Approval Editor Rating</th>
            <th>By</th>
            <th>Type</th>
            <th>Country</th>
            <th>SIQ</th>
            <th>Reader Rating No's</th>
            <th>Views</th>
            <th>Sends</th>
            <th>Most Recent Send</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    )
  },

  initializeTable: function() {
    $(function() {
      var dt = $("#cureus-u-datatable").DataTable({
        dom: '<"row"<"large-2 columns"l><"large-5 columns"f>>t<"row"<"large-6 columns"i><"pull-right"p>>',
        pagingType: 'full_numbers',
        columns: [
          {width: '225px'}, // Title
          {class: "centered"}, // Published Date
          null,                // Sort
          {class: "centered"}, // Approval Editor Rating
          {class: "centered"}, // Approval Editor Initials
          {class: "centered"}, // Article Type
          {class: "centered"}, // Country
          {class: "centered"}, // Siq
          {class: "centered"}, // Reader Rating No's
          {class: "centered"}, // Views
          {class: "centered"}, // Sends
          {class: "centered"}, // Most Recent Send
          null,                // Sort
          null,                // ID
          null,                // Title
          null,                // Reader rating breakdown
          null                 // Num sent for category
        ],
        rowCallback: function(row, data) {
          var sendState = data[16]
          var color = 'white'
          if (sendState == 2) {
            color = 'lightpink'
          }
          if (sendState == 1){
            color = 'yellow'
          }
          $(row).find('td:eq(9)').css('background-color', color)
        },
        columnDefs: [
          {targets: [0], data: null, render: function(data, type, row) {
            return `<span class="has-tip" id=${data[13]} data-tooltip="" title="${data[14]}">${data[0]}</span>`
          }},
          {targets: [1], orderData: [2]},
          {targets: [8], data: null, render: function(data, type, row) {
            return `<span class="has-tip" id=${data[13]} data-tooltip="" title="${data[15]}">${data[8]}</span>`
          }},
          {targets: [11], orderData: [12]},
          {targets: [2,12,13,14,15,16], visible: false}
        ]
      });
      dt.fnSort([[2, 'asc']])
    })
  },

  payload: function() {
    return {
      categoryId: this.state.categoryId,
      approvalEditorRating: this.state.approvalEditorRating,
      from: this.state.from,
      to: this.state.to
    }
  },

  handleSearch: function() {
    var self = this;
    $.ajax({
      type: "get",
      url: "/admin/inside_cureus/",
      dataType: "json",
      data: this.payload(),
      success: function(data) {
        self.populateData(data['data'])
      }
    })
  },

  populateData: function(data) {
    var dt = $("#cureus-u-datatable").DataTable()
    dt.fnClearTable();
    for (var i = 0; i < data.length; i++){
      let object = data[i]['attributes']
      dt.fnAddData( [
        object.title_link,
        object.published_date,
        object.published_date_sortable,
        object.approval_editor_rating,
        object.approval_editor_initials,
        object.type,
        object.country,
        object.siq,
        object.no_ratings,
        object.views_count,
        object.sent_count,
        object.most_recent_sent_at,
        object.most_recent_sent_at_sortable,
        object.id,
        object.title,
        object.reader_ratings,
        object.send_state
      ], false)
    }
    dt.fnDraw()
  },

  render: function() {
    return (
      <div className="inside-cureus-wrapper">
        <div className="row">
          <h2 className="centered">
            Cureus U Article Selection Tool
          </h2>
        </div>
        <div className="small-12 columns">
          <div className="row">
            <div className="small-4 columns">
              {this.renderCategoryDropdown()}
            </div>
            <div className="small-3 columns">
              {this.renderApprovalEditorRatingDropdown()}
            </div>
            <div className="small-4 columns">
              <div className="small-6 columns" style={{paddingTop: '10px'}}>
                Published Date
              </div>
              <div className="small-6 columns" style={{paddingTop: '4px'}}>
                {this.renderDatePicker()}
              </div>
            </div>
            <div className="small-1 columns" style={{marginTop: '4px'}}>
              <a className="button small primary" onClick={this.handleSearch}>Search</a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            {this.renderTable()}
          </div>
        </div>
      </div>
    )
  }
})

export default Main;