// utils.js
// NEED TO UPDATE TO HANDLE DATE ARGS
export const fetchTableData = async (url, params = {}) => {
  try {
    const queryParams = new URLSearchParams(params).toString();
    const fullUrl = `${url}?${queryParams}`;
    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": $("[name='csrf-token']").attr("content"),
        'Accept': 'application/json',
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Fetching data failed: ' + error.message);
  }
};
