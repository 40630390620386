import React, { useState, useEffect } from 'react';
import NewArticleCard from '../../Shared/ContentCards/NewArticleCard';
import Pages from '../../Shared/Pagination/Pages';
import SearchBar from '../../Shared/SearchBar/SearchBar';
import MultimediaCard from './MultimediaCard';
import EllipsePages from '../../Shared/Pagination/EllipsePages';


const Articles = ({articlesPath, setActiveTab, searchArticlesPath, featuredMultimediaPath}) => {
  const [spinning, setSpinning] = useState(true);
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState([]);
  const [articleCount, setArticleCount] = useState(0);
  const [paginationData, setPaginationData] = useState({currentPage: 1, firstPage: true});
  const [multimedia, setMultimedia] = useState([]);
  const [multimediaPage, setMultimediaPage] = useState(1);
  const [multimediaPaginationData, setMultimediaPaginationData] = useState({currentPage: 1, firstPage: true});
  const [isSearchResults, setIsSearchResults] = useState(false);

  useEffect(() => {
    fetchCollection();
    setActiveTab("Articles")
  }, []);

  useEffect(() => {
    if (isSearchResults) {
      setArticleCount(articles.length);
    } else {
      setArticleCount(paginationData.totalItems);
    }
  }, [articles]);

  useEffect(() => {
    fetchCollection();
    scrollToTop();
  }, [page]);

  useEffect(()=>{
    fetchMultimedia();
  }, [multimediaPage]);

  const scrollToTop = () => {
    setTimeout(function() {
      window.scrollTo({top: 700, left: 0, behavior: 'smooth'});
    }, 1000);
  }

  const fetchCollection = () => {
    $.ajax({
      url: articlesPath,
      type: 'GET',
      dataType: 'json',
      data: {page: page},
      success: function(res) {
        setArticles(res.data);
        setPaginationData(res.paginationData);
        setArticleCount(res.paginationData.totalItems);
        setSpinning(false);
      }
    })
  }

  const fetchMultimedia = () => {
    $.ajax({
      url: featuredMultimediaPath,
      type: 'GET',
      dataType: 'json',
      data: {page: multimediaPage},
      success: function(res) {
        setMultimedia(res.data);
        setMultimediaPaginationData(res.paginationData);
      }
    })
  }

  const updatePage = (page) => {
    setPage(page);
  }

  const updateMultimediaPage = (page) => {
    setMultimediaPage(page);
  }

  const renderSpinner = () => {
    return (
      <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>
    )
  }

  const renderArticles = () => {
    return (
      articles.map((article) => {
        return <NewArticleCard resource={article}/>
      })
    )
  }

  const renderMultiMedia = () => {
    return (
      multimedia.map((multimedia) => {
        return <MultimediaCard multimedia={multimedia}/>
      })
    )
  }

  const renderArticleContainer = () => {
    if (articleCount=== 0 && !isSearchResults) {
      return (
        <div className='no-articles-container'>
          <i className="fa fa-exclamation-circle icon"></i><span>This collection has been launched recently. Articles will appear here as soon as they are published.</span>
        </div>
      )
    } else {
      return (
        <>
          <div className='article-search-bar'>
            <SearchBar url={searchArticlesPath} placeHolder={"Search for a specific article here."} setCards={setArticles} isFilterMenuOpen={false} setIsFilterMenuOpen={false} page={"collection"} isFilterRequired={false} setIsSearchResults={setIsSearchResults} isPaginationUsed={true} isSpinnerUsed={true} setSpinning={setSpinning}/>
          </div>
          <div className='search-results-wrap'>
            <div className='row collapse'>
              <div className='new-content-index-wrap' id="filter-results">
                {spinning && (
                  renderSpinner()
                )}
                {!spinning && (
                  <div className='row'>
                  <div className='large-12 small-12 columns'>
                    <div className='content-index-results-columns' id='content-results-wrap'>
                      <div>
                        {renderArticles()}
                      </div>
                    </div>
                  </div>
                  <div className='pagination-container'>
                    {!isSearchResults && paginationData && (
                      <Pages
                      paginationData={paginationData}
                      handlePageSelect={updatePage}
                      pagesType={"article"}
                    />
                    )}
                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
          {!spinning && multimedia.length > 0 && (
            <div>
              <div className='multimedia-container'>
                <div className='header'>
                  <h2>Most Recent Multimedia</h2>
                </div>
                <div className='cards-container'>
                  {renderMultiMedia()}
                </div>
              </div>
              <div className='multimedia-pagination-holder'>
                <EllipsePages paginationData={multimediaPaginationData} handlePageSelect={updateMultimediaPage} paginationType={"ellipses"} />
              </div>
            </div>
          )}
        </>
      )
    }
  } 
  
  return (
    <div className='articles-page-container large-12 medium-12'>
      <div className='heading'>
        <h2 className='title'>Collection Articles</h2>
        {!spinning && (
          <h2 className='article-count'>({articleCount})</h2>
        )}
      </div>
      {renderArticleContainer()}
    </div>
  )
}


export default Articles;