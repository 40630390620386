import React, {useEffect, useState} from 'react';

const Tab = ({page, title, activeTab, setTab, path}) => {

  return (
    <a className={"tab " + (activeTab == page ? 'active' : '')} onClick={() => {setTab(page)}} href={path}>
      <span>{title}</span>
    </a>
  )
}

export default Tab;