import React, { useState, useRef, useEffect } from 'react';
import Modal from '../Modal';
import './buttons.js'
import ChannelCampaignModal from '../ChannelCampaigns/ChannelCampaignModal';

const AllInviteesTable = ({channelId, createEmailButtonTitle, fetchAllInviteesPath, setListEmailsCount, removeListEmailsPath}) => {

  const [listEmails, setListEmails] = useState(null);
  const [spinning, setSpinning] = useState(true);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setSpinning(true)
    $.ajax({
      type: 'GET',
      url: fetchAllInviteesPath,
      success: function(data) {
        setSpinning(false)
        if (setListEmailsCount) {
          setListEmailsCount(data.listEmails.length)
        }
        setListEmails(data.listEmails)
        populateTable(data.listEmails)
      }
    });
  }

  const removeListEmails = () => {
    if (confirm("Are you sure you want to delete the selected emails?")) {
      setSpinning(true)
      var channelReviewerIds = []
      $("input:checked").each(function() {
        channelReviewerIds.push($(this).data('resource-id'))
      })
      $.ajax({
        type: 'Delete',
        url: removeListEmailsPath,
        data: {list_email_ids: channelReviewerIds},
        success: function(data) {
          fetchData()
          const checkedInputs = $("input:checked");
          setChecked(checkedInputs.length > 0)
        }
      });
    }
  }

  const setColumns = () => {
    return [
      {width: '35px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      {width: '70px'},
      null,
      {width: '70px'},
    ]
  }
  
  const checkboxClicked = (event) => {
    const checkedInputs = $("input:checked");
    setChecked(checkedInputs.length > 0)
  }

  const selectAllCheckboxes = (event) => {
    $('input:checkbox').prop('checked', event.target.checked);
    setChecked(event.target.checked)
  }

  const createInviteEmail = () => {
    Modal.open({
      children: <ChannelCampaignModal allInvitees={true} channelId={channelId} />
    });
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.checkbox,
        row.first_name,
        row.last_name,
        row.email,
        row.specialty_name,
        row.date_added,
        row.sort_column,
        row.cureus_member ? 'Yes <i class="fa fa-check"></i>' : 'No',
      ], false)
    )
  }

  const populateTable = (listEmails) => {
    var dt = $("#list-emails-table").DataTable({
      columns: setColumns(),
      language: {
        "searchPlaceholder": "Search...",
        "search": ""
      },
      columnDefs: [
        {targets: [5], orderData: [6]},
        {targets: [6], visible: false},
        {orderable: false, targets: [0]}
      ],
      "bAutoWidth": false,
      dom: '<"row email-list-search-row"<f>>tr<"row pagination-row"<"small-8 columns pagination-details" <"pull-left"p><"pull-left"l>><"pull-right create-email-button-container"B>>',
      buttons: [
        {
          text: '<i class="fas fa-pencil-alt"></i> ' + createEmailButtonTitle,
          action: function ( e, dt, node, config ) {
            createInviteEmail()
          }
        }
      ],
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
      "rowCallback": function(row) {
        row.children[0].children[0].addEventListener('click',function(e) {
          checkboxClicked(e)
        })
      }
    })
    
    if (!listEmails) {
      listEmails = []
    }
    dt.api().clear();
    for (var i = 0; i < listEmails.length; i++) {
      let row = listEmails[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
  }

  if (spinning) {
    return (
      <React.Fragment>
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      </React.Fragment>
    )
  } else if (listEmails && listEmails.length > 0) {
    return (
      <React.Fragment>
        <div>
          <a className={`button secondary ${checked ? '' : 'hidden'}`} onClick={(e) => removeListEmails(e)}><i className='fa fa-trash-alt'></i> Delete</a>
        </div>
        <div className="list-emails-datatable-container">
          <table id="list-emails-table">
            <thead>
              <tr>
                <th><input onClick={(e) => selectAllCheckboxes(e)} type="checkbox" /></th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email Address</th>
                <th>Specialty</th>
                <th>Added</th>
                <th></th>
                <th>Confirmed Account</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <div className="empty-container text-center">
          <div>
            <i className='fa fa-user-friends'></i>
            <h3>No recipients listed</h3>
          </div>
        </div>        
      </React.Fragment>
    )
  }

}

export default AllInviteesTable;