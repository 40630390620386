var createReactClass = require('create-react-class');
import React from 'react';
import Content from './Content';
import CategorySelector from './CategorySelector';

const Main = createReactClass({
  getInitialState: function() {
    return ({
      selectedId: this.props.landing ? this.props.categoryIds[0] : 0,
      resources: []
    })
  },

  componentDidMount: function() {
    this.fetchArticles();
  },

  changeSelectedId: function(e) {
    this.setState({selectedId: e.target.id}, () => this.fetchArticles())
  },

  fetchArticles: function() {
    var self = this
    $.ajax({
      type: 'get',
      url: '/featured_articles',
      dataType: 'JSON',
      data: {
        categoryId: this.state.selectedId
      },
      success: function(res) {
        self.setState({resources: res.data})
      }
    })
  },

  renderCategorySelector: function() {
    if (!this.props.landing) {
      return (
        <CategorySelector
        categoryMap={this.props.categoryMap}
        categoryIds={this.props.categoryIds}
        selectedId={this.state.selectedId}
        changeSelectedId={this.changeSelectedId}
      />
      )
    }
  },

  renderHeader: function() {
    var className= ""
    var style = {}
    var landing = ""
    var noMargin = {}
    if (this.props.landing) {
      className = "small-12 medium-10 medium-offset-1 columns"
      style = {padding: 0}
      noMargin = {marginBottom: 0}
    }
    return (
      <div className="row">
        <div className={className} style={style}>
          <div className="header-container" style={noMargin}>
            <h6>
              <strong>FEATURED ARTICLES</strong>
            </h6>
          </div>
        </div>
      </div>
    )
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <div className="small-12 medium-10 small-centered columns">
            {this.renderHeader()}
            <div className="row">
              {this.renderCategorySelector()}
              <Content
                selected={this.props.categoryMap[this.state.selectedId]}
                selectedId={this.state.selectedId}
                resources={this.state.resources}
                landing={this.props.landing}
                categoryUrl={this.props.categoryUrls[this.state.selectedId]}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Main;