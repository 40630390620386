var createReactClass = require('create-react-class');
import React from 'react';

const ActionSplitButtons = createReactClass({
  render: function() {
    if(this.props.sent) {
      return (
        <div className="cureus-split-dropdown-wrap">
          <a href={ this.props.showPath } className="button secondary tiny split" data-cureus-split-dropdown={"campaign-actions" + this.props.id} >
            <div>View report</div>
            <span />
          </a>

          <ul id={ "campaign-actions" + this.props.id} className="cureus-split-dropdown">
            <li>
              <a href={ this.props.copyPath } data-method="post">Replicate</a>
            </li>

            <li>
              <a href={ this.props.emailPath } target="_blank">View email</a>
            </li>

            <li>
              <a onClick={this.props.onRenameClick}> Rename </a>
            </li>
          </ul>
        </div>
      )
    } else {
      return (
        <div className="cureus-split-dropdown-wrap">
          <a href={ this.props.editPath } className="button secondary tiny split" data-cureus-split-dropdown={"campaign-actions" + this.props.id}>
            <div>Edit</div>
            <span />
          </a>

          <ul id={ "campaign-actions" + this.props.id} className="cureus-split-dropdown">

            <li>
              <a href={ this.props.copyPath } data-method="post">Replicate</a>
            </li>

            <li>
              <a href={ this.props.emailPath } target="_blank">View email</a>
            </li>
          </ul>
        </div>
      )
    }
  }
});

export default ActionSplitButtons;
