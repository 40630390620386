import React from 'react';
import { useState } from 'react';
import ConclusionCard from './ConclusionCard';
import Label from './Label';

const CredibleConclusions = ({question, handleQuestionChange, setDisableSaveButton}) => {

  const [credibleConclusions, setCredibleConclusions] = useState("none");

  return (
    <div className='credible-conclusions-container'>
      <Label label={question.question} sublabel={question.question_subtitle}  />
      <div className='cards-container'>
        <ConclusionCard id={question.id} title={"Yes"} subtitle={"(Accept after revisions)"} handleQuestionChange={handleQuestionChange} src={"https://public.cureus.com/peer_questionnaire/yes.png"} name={"credible_conclusions"} value={'yes'} selectedValue={credibleConclusions} setCredibleConclusion={setCredibleConclusions} setDisableSaveButton={setDisableSaveButton}/>
        <ConclusionCard id={question.id} title={"Decision pending"} subtitle={"(Please send for re-review after revisions)"} handleQuestionChange={handleQuestionChange} src={"https://public.cureus.com/peer_questionnaire/pending.png"} name={"credible_conclusions"} value={'pending'} selectedValue={credibleConclusions}setCredibleConclusion={setCredibleConclusions} setDisableSaveButton={setDisableSaveButton}/>
        <ConclusionCard id={question.id} title={"No"} subtitle={"(Do not publish)"} handleQuestionChange={handleQuestionChange} src={"https://public.cureus.com/peer_questionnaire/no.png"} name={"credible_conclusions"} value={'no'} selectedValue={credibleConclusions}setCredibleConclusion={setCredibleConclusions} setDisableSaveButton={setDisableSaveButton}/>
      </div>
    </div>
  )
}

export default CredibleConclusions;