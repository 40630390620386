var createReactClass = require('create-react-class');
import React from 'react';

const LearnMoreModal = createReactClass({
  render: function() {
    return (
      <div className="reveal-modal middling" data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Peer Reviewed, not Peer Rejected</h4>
            <p>
             Congratulations, your article has been approved for peer review! Invitations have been emailed to your preselected peer reviewers including the reviewers invited by Cureus editors. Your article will be unlocked for editing when the required minimum number (2) of anonymous reviews has been submitted.
           </p>

            <p>
              Cureus takes the pain out of peer review. Your work is no longer at the mercy of an opaque and mysterious panel. Efficient pre-publication peer review and community-based, post-publication examination and discussion ensure that your article receives a helpful and informative review. Reviewers can suggest colleagues to review. Cureus automatically sends reminder emails, however, you can also send reminders to invited and in-progress reviewers every 48 hours via your dashboard. Improve your chances of efficient, timely review by inviting more reviewers.
            </p>

            <p>
              See more in our <a target="_blank" href="/author_guide">Author Guide</a>.
            </p>
          </div>

          <div className="modal-footer">
          <div className="text-center">
            <a className="button secondary cancel-modal"> Close </a>
          </div>
          </div>
        </div>
      </div>
    )
  }
})

export default LearnMoreModal;