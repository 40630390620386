import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";

const TOCLink = ({title, activeSection, setActiveSection}) => {
  const location = useLocation();

  useEffect( () => {
    if(location.pathname === '/') {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      }
    }
  }, [])

  const handleScroll = event => {
    let windowYAmountPositionScrolledDown = document.documentElement.scrollTop || document.body.scrollTop
    let articleSectionElement = document.querySelector(`#${title}`)
    let YPositionOfElement = window.pageYOffset + articleSectionElement.getBoundingClientRect().top
    let topPadding = 90

    if ( windowYAmountPositionScrolledDown > (YPositionOfElement - topPadding) ) {
      setActiveSection(title)
    }
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  const formatTitle = () => {
    let titleized = title.replace("and", "&").split("_")

    return titleized.map( (word) => {
      return toTitleCase(word)
    }).join(" ")
  }

  const toTitleCase =(str) => {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const displayDash = () => {
    if (activeSection == title) { return <div className={`dash ${title}`}/> }
  }

  const color = () => {
    if (activeSection == title) { return "black" }
  }

  return (
    <li>
      {displayDash()}
      <HashLink
        to={`#${title}`}
        scroll={scrollWithOffset}
        replace
        style={{color: color()}}
      >
        {formatTitle()}
      </HashLink>
    </li>
  )
}
export default TOCLink