import React, { memo } from 'react';

const Checkbox = ({ checked, id, label, onChange }) => {
  const checkedIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" stroke="#1395B7"/>
      <path d="M9 12.5L11.7009 15L15.875 10" stroke="#1395B7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )

  const uncheckedIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g opacity="0.5">
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" stroke="#CCCCCC"/>
      </g>
    </svg>
  )

  const formattedLabel = label.replaceAll('/', ' / ');
  const checkBoxId = id ? `checkbox-${id}` : 'checkbox';

  return (
    <div className={`checkbox-container ${checked ? 'checked' : ''}`} onClick={onChange} id={checkBoxId}>
      <input id={checkBoxId} type="checkbox"/>
      <label className="checkbox-icon" htmlFor={checkBoxId}>
        {checked ? checkedIcon : uncheckedIcon}
      </label>
      <label htmlFor={checkBoxId}>{formattedLabel}</label>
    </div>
  )
}

export default memo(Checkbox)