var createReactClass = require('create-react-class');
import React from 'react';

const MediaMentions = createReactClass({
  render: function(){
    return (
      <div>
        <h4 className="reg">How do I correctly mention figures, tables, videos and interactive models in the text?</h4>
        <p>
          Each media item must be mentioned in the paragraph immediately preceding it. For more detailed instructions, see our <a href="https://www.cureus.com/media_guide" target="_blank">Media Instructions</a> page.
        </p>
      </div>
    );
  }
});

export default MediaMentions;