import React, { useState, useRef, useEffect } from 'react';
import LikeUser from './LikeUser'

const Modal = ({likableId, likableType}) => {

  const [users, setUsers] = useState([]);
  const [likesCount, setLikesCount] = useState(0);

  useEffect(() => {
    $.ajax({
      type: 'GET',
      url: `/likes`,
      data: {likable_type: likableType, likable_id: likableId},
      dataType: 'json',
      success: function(res) {
        setUsers(res.users);
        setLikesCount(res.users.length);
      }
    });
  }, [])

  const renderUsers = () => {
    return users.map((user, idx) => {
      return (
        <LikeUser key={idx} user={user}/>
      )
    })
  }

  return (
    <div id="comment-likes-modal" className="reveal-modal middling row" data-reveal style={{ minHeight: 'auto' }}>
      <div className="modal-body small-10 large-10">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content small-12 large-12">
          <div className='heading-container'>
            <h4 style={{marginRight: '11px'}}>Who liked it?</h4>
            <h4>({likesCount})</h4>
          </div>
          {renderUsers()}
        </div>
        <div className="modal-footer text-center">
          <a href="#" className="button secondary cancel-modal"> Close </a>
        </div>
      </div>
    </div>
  )
}

export default Modal;