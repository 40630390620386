import React, { useEffect } from 'react';
import Modal from '../Modal';
import LatexInfoModal from './LatexInfoModal';

const ModalTrigger = ({ type }) => {

  useEffect(() => {
    $('#latex-modal-trigger').off('openModal');
    initializeModalTrigger();
  }, []);

  const initializeModalTrigger = () => {
    $('#latex-modal-trigger').one('openModal', () => {
      openModal();
    });
  };

  const openModal = () => {
    initializeModalTrigger();
    Modal.open({
      children: <LatexInfoModal type={type} />
    });
  };

  return (
    <div>
      <a id="latex-modal-trigger" />
    </div>
  );
};

export default ModalTrigger;
