var createReactClass = require('create-react-class');
import React from 'react';
import FlagModal from './FlagModal';
import Modal from '../Modal';


const Main = createReactClass({

  handleClick: function() {
    var self = this;
    Modal.open({
      children: <FlagModal comments={self.props.comments} canManage={self.props.canManage}/>
    });
  },

  render: function() {
    return(
      <a onClick={this.handleClick}>(View)</a>
    )
  }
})

export default Main;
