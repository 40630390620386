import React, { useState, useEffect } from 'react';
import AuthorCard from './AuthorCard';
import SearchBar from '../../Shared/SearchBar/SearchBar';
import Pages from '../../Shared/Pagination/Pages';

const Authors = ({authorsPath, setActiveTab, searchAuthorsPath}) => {

  const [spinning, setSpinning] = useState(true);
  const [authors, setAuthors] = useState([]);
  const [page, setPage] = useState([]);
  const [paginationData, setPaginationData] = useState({currentPage: 1, firstPage: true});
  const [isSearchResults, setIsSearchResults] = useState(false);

  useEffect(() => {
    setActiveTab("Authors")
    fetchAuthors();
  }, [])

  useEffect(() => {
    fetchAuthors();
    scrollToTop();
  }, [page]);

  const fetchAuthors = () => {
    $.ajax({
      url: authorsPath,
      type: 'GET',
      dataType: 'json',
      data: {page: page},
      success: function(res) {
        setAuthors(res.data);
        setPaginationData(res.paginationData);
        setSpinning(false);
      }
    })
  }

  const updatePage = (page) => {
    setPage(page);
  }

  const renderSpinner = () => {
    return (
      <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>
    )
  }

  const scrollToTop = () => {
    setTimeout(function() {
      window.scrollTo({top: 700, left: 0, behavior: 'smooth'});
    }, 1000);
  }

  const renderAuthors = () => {
    return authors.map((author) => {
      return <AuthorCard author={author} />
    })
  }
  
  return (
    <div className='authors-container'>
      <div className='header'>
        <h2>Collection Authors</h2>
      </div>
      <div className='search-container'>
        <SearchBar url={searchAuthorsPath} placeHolder={"Search for a specific author here."} setCards={setAuthors} isFilterMenuOpen={false} setIsFilterMenuOpen={false} page={"collection"} isFilterRequired={false} setIsSearchResults={setIsSearchResults} isPaginationUsed={true}
        isSpinnerUsed={true}
        setSpinning={setSpinning}/>
      </div>
      <div className='search-results-grid'>
        {spinning && (
          renderSpinner()
        )}
        {renderAuthors()}
      </div>
      <div className='pagination-container'>
        {!spinning && !isSearchResults && paginationData && (
          <Pages
            paginationData={paginationData}
            handlePageSelect={updatePage}
            pagesType={"article"}
          />
        )}
      </div>
    </div>
  )
}

export default Authors;