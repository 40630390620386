
var createReactClass = require('create-react-class');
import React from 'react';
import CompetitionTile from './CompetitionTile';
import { Cureus } from '../../Cureus';

const CompetitionSelector = createReactClass({
  getInitialState: function() {
    return {
      selectedCompetition: {},
      showCompetitions: false,
      submitDisabled: 'disabled',
      radioButtonFalseState: null,
      declaredFieldValue: null
    }
  },

  componentWillMount: function() {
    if(this.props.competitionDeclared != null){
      this.setState({
        radioButtonFalseState: !this.props.competitionDeclared,
        declaredFieldValue: this.props.competitionDeclared
      });
      if(this.props.competitionDeclared == false){
        this.setState({
          submitDisabled: ''
        });
      }
    }

    if(this.props.selectedCompetition) {
      this.setState({
        selectedCompetition: this.props.selectedCompetition,
        showCompetitions: true,
        submitDisabled: '',
        radioButtonFalseState: null,
      });
    }
  },

  componentDidMount: function(){
    Cureus.ChangeDetectorAlert.init("form");
    new Cureus.InstaSaver("#save-now");
  },

  handleRadioChange: function(e) {
    if(e.target.value == "false") {
      this.setState({
        selectedCompetition: {},
        showCompetitions: false,
        submitDisabled: '',
        radioButtonFalseState: true,
        declaredFieldValue: false,
      });
    } else {
      this.setState({
        showCompetitions: true,
        submitDisabled: 'disabled',
        radioButtonFalseState: false,
        declaredFieldValue: null
      });
    }
  },

  handleChooseCompetition: function(competition) {
    this.setState({
      selectedCompetition: competition,
      submitDisabled: '',
      declaredFieldValue: true
    });
  },

  isActive: function(competition) {
    return this.state.selectedCompetition.id == competition.id;
  },

  renderRadioButtons: function() {
    return(
      <div>
        <div className="row">
          <div className="large-12 columns">
            <input id="competition_entry_false" type="radio" value="false" checked={ this.state.radioButtonFalseState } onChange={ this.handleRadioChange }/>
            <label htmlFor="competition_entry_false">No, this is not a competition entry.</label>
          </div>
        </div>
        <div className="row">
          <div className="large-12 columns">
            <input id="competition_entry_true" type="radio" value="true" checked={ this.state.showCompetitions } onChange={ this.handleRadioChange }
            disabled={this.props.numberOfRunningCompetitions > 0 ? '' : 'disabled'}/>
            <label htmlFor="competition_entry_true">Yes, this is a competition entry.</label>
          </div>
        </div>
      </div>
    )
  },

  render: function() {
    var self = this;
    
    return (
      <div>
        <input  type="hidden" value={ this.state.selectedCompetition.id || "" } name={ this.props.fieldName }/>
        <input  type="hidden" value={ this.state.declaredFieldValue } name={ this.props.declaredFieldName }/>
        { this.renderRadioButtons() }

        { this.state.showCompetitions ?
          <div>
            <p>
              Select a competition below. Some competitions may require a specific article type. If so, this article type will be preselected for you.
            </p>

            <div className='row card-tiles-wrap'>
              {
                self.props.competitions.map(function(competition) {
                  return (
                    <div className='large-3 small-6 columns'>
                      <CompetitionTile
                        active={ self.isActive(competition) }
                        competition={ competition }
                        onClick={ self.handleChooseCompetition.bind(self, competition) }
                      />
                    </div>
                  )
                })
              }
            </div>
          </div>
          : null
        }
        <div className="step-actions clearfix">
          <div className="row">
            <div className="pull-right">
              <div className="save-success-message" id="save-success-message">
                You have successfully saved your progress.
              </div>
            </div>
          </div>
          <div className="pull-right">
            <a id="save-now" className="button special" data-disable-with="Save" disabled={ this.state.submitDisabled } data-alert-exception>Save</a>
            &nbsp;
            <input className="button primary" data-disable-with="Save and continue" disabled={ this.state.submitDisabled } name="commit" type="submit" value="Save and continue" data-alert-exception/>
          </div>
        </div>
      </div>
    )
  }
});

export default CompetitionSelector;
