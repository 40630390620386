import React, { useEffect, useState } from 'react';
import Carat from './Carat';

const Types = ({contentTypes, updateTypes, checkedLength, id}) => {
  const [collapse, setCollapse] = useState(true);
  const [total, setTotal] = useState(checkedLength(Object.values(contentTypes)))

  const handleChange = (articleType) => {
    var updatedTypes= contentTypes;
    updatedTypes[articleType].checked = !updatedTypes[articleType].checked
    updateTypes(updatedTypes)
    setTotal(updatedTypes[articleType].checked ? total + 1 : total - 1)
  }

  const renderTypes = () => {
    return Object.values(contentTypes).map((el, idx) => {
      return(
          <div className={`checkbox-row ${(el.checked ? 'checked' : '')}`} key={el.classType + id}>
            <input id={el.classType + id} type="checkbox" className="css-checkbox" checked={el.checked} onChange={() => handleChange(el.classType)} />
            <label for={el.classType + id}>{el.name}</label>
          </div>
      )
    })
  }

  const collapsedClass = () => {
    return collapse ? 'collapsed' : ''
  }

  return (
    <div className="row small-collapse">
      <div className="small-12 columns">
        <div className={`filter-header ${collapsedClass()}`} onClick={() => setCollapse(!collapse)}>
            <span >Article Type {total > 0 && <span>({total})</span>}</span>
            {<Carat collapse={collapse} setCollapse={setCollapse}/>}
        </div>
        {!collapse && <div className="checkbox-container">
          {renderTypes()}
        </div>}
      </div>
    </div>
  )
}

export default Types