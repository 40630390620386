import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import DeleteButton from '../DeleteButton';
import UpdateButton from '../UpdateButton';

const AboutWidget = ({slug, handleDeleteWidget, handleUpdateWidget, mainContentWidgets}) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    let widgetId = mainContentWidgets["Widget::AboutSection"]
    if(widgetId) {
      let onSuccess = (res) => {
        setContent(res.content)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [mainContentWidgets])

  if(!content) {return <></>}

  return (
    <div className="row">
      <div className="small-12 columns widget about-section">
      <h2>About</h2>
      <UpdateButton
        handleUpdateWidget={handleUpdateWidget}
        widget={content}
        modalName={"AboutModal"}
        mainContentWidgets={mainContentWidgets}
        slug={slug}
      />
      <DeleteButton
        handleDeleteWidget={handleDeleteWidget}
        widgetId={mainContentWidgets["Widget::AboutSection"]}
      />
      {content.image && <div className="row small-collapse">
        <div className="small-12 columns">
          <div className="img-container">
            <img src={content.image}/>
          </div>
        </div>
      </div>}
      <div className="row small-collapse">
        <div className="small-12 columns">
          <div dangerouslySetInnerHTML={{__html: content.blurb}}/>
        </div>
      </div>
      </div>
    </div>
  )
}

export default AboutWidget;