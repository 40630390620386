import {Cureus} from '../Cureus';

Cureus.CKEditor = function(dom_id, options) {
  var self = this;

  Cureus.CKEditorLoader.load(function() {
    var ckeditorArgumentOptions, editorType, allowedElements, editorTypeOptions;

    CKEDITOR.config.disableNativeSpellChecker = false;

    self.options = options || {};
    self.dom_id = dom_id;
    self.cke_dom_id = 'cke_' + dom_id;
    editorType = self.options["editor_type"] || 'default';
    self.$hiddenTextArea = $("#" + self.dom_id);
    allowedElements = self.getAllowableElements(editorType);
    editorTypeOptions = self.getEditorTypeOptions(editorType);
    let placeholder = self.options["placeholder"] || ""

    self.defaultCkOptions = {
      resize_dir: 'both',
      resize_maxWidth: 1100,
      resize_maxHeight: 900,
      allowedContent: allowedElements,
      contentsCss: '/ckeditor/contents.css',
      placeholder: placeholder,
      customConfig: '/ckeditor/config.js'
    };

    ckeditorArgumentOptions = self.options["ckeditor"] || {};
    self.ckOptions = _.extend(self.defaultCkOptions,
      editorTypeOptions,
      ckeditorArgumentOptions);

    CKEDITOR.plugins.addExternal('blockimagepaste', '/ckeditor/plugins/blockimagepaste/plugin.js', '')
    self.ckeditor = CKEDITOR.replace(self.dom_id, self.ckOptions);

    //Split self out
    self.placeholderText = self.options['placeholderText']
    self.maximum_length = self.options["char_count"];
    self.responsive_container = self.options["responsive_container"];
    self.change_listener = self.options["change_listener"];
    self.handle_change = self.options["handle_change"];
    self.ignore_initial_change_event = self.options["ignore_initial_change_event"] || false;
    self.$buttonToValidate = $(self.options["validate_button"]) || null;
    self.$stopValidationElement = $(self.options["stopValidationElement"]);
    self.onInstanceReady(editorType);
    if(!self.options['no_insta_save']) {
      new Cureus.InstaSaver("#save-now", self.ckeditor);
    }
  });
};

_.extend(Cureus.CKEditor.prototype, {
  onInstanceReady: function(editorType){
    var self = this;
    self.ckeditor.on('instanceReady', function() {
      self.setCharCount();
      self.initializeMediaLibrary(editorType);
      self.removeLoadingText();
      self.addTipText();
      self.setFullHeight();
      self.bindChangeListener();
      self.bindButtonValidator();
      self.bindAttachmentRemoval();
      self.moveAttachmentUp();
      self.moveAttachmentDown();
      self.setPlaceholderText();
      self.setInitialValue();
      self.disableUpDown();
      self.removeDisable();
      self.expandFigure();
      self.editFigure();
      self.openLatexModal()
      self.disableContextMenu();
      self.addIdToToolBarButtons();
      self.foundationJoyRideStart();
      self.setFocus();
    });
  },

  setFocus: function(){
    var focusManager = new CKEDITOR.focusManager( this.ckeditor );
    focusManager.focus();
    focusManager.blur();
  },

  disableContextMenu: function() {
    let nodeList = this.ckeditor.document.find('.attachment-wrapper').$
    for(var i=0; i < nodeList.length; i++) {
      nodeList[i].setAttribute('oncontextmenu', 'return false')
    }
  },

  bindChangeListener: function(){
    var self = this;
    if(self.change_listener){

      var evt = document.createEvent('HTMLEvents');
      evt.initEvent('change', true, true);
      self.ckeditor.on('change', function(){
        $(self.change_listener).val(self.getCurrentLength());
        document.querySelector(self.change_listener).dispatchEvent(evt);
      });
      if(!self.ignore_initial_change_event){
        $(self.change_listener).val(self.getCurrentLength()); // fire off once to populate values
        document.querySelector(self.change_listener).dispatchEvent(evt);
      }
    }
    self.ckeditor.on('change', function(){
      self.updateHiddenTextarea();
      self.removeDisable()
      if (self.handle_change) {
        self.handle_change();
      }
    });
  },

  bindButtonValidator: function() {
    if ( (this.$stopValidationElement) && (this.$stopValidationElement.is(":checked")) ) {
    // do nothing
    }
    else {
      var self = this;
      if (self.$buttonToValidate){
        self.ckeditor.on('change', function() {
          self.$buttonToValidate.trigger('change');
          self.nextButtonValidator();
        });

        if (!self.ignore_initial_change_event) {
          self.$buttonToValidate.trigger('change'); // fire off once to populate values
        }

        self.initialNextButtonValidator();
      }
    }
  },

  addIdToToolBarButtons: function() {
    var subheaderDropDown = $(".cke_toolbar").closest("span")[0]
    var insertFigureButton = $(".cke_toolbar").closest("span")[6]

    // Set foundation joyride data attribute to the Id of the ckeditor toolbar button

    var joyRideFigure = document.querySelector("[data-id='add-figure']")
    if (joyRideFigure) {
      insertFigureButton.id = insertFigureButton.id + " add-figure"
      joyRideFigure.dataset.id = insertFigureButton.id
    }

    var joyRideSubHeader = document.querySelector("[data-id='subheaders']")
    if (joyRideSubHeader) {
      subheaderDropDown.id = subheaderDropDown.id + " subheaders"
      joyRideSubHeader.dataset.id = subheaderDropDown.id
    }
  },

  foundationJoyRideStart: function () {
    if (this.options.joyRide) {
      Cureus.ArticleTourJoyride.init({
        articleId: this.options.joyRide.articleId,
        cookieName: this.options.joyRide.cookieName
      });
    }
  },

  openLatexModal: function() {
    $('.cke_reset').contents().click(function(e) {
      if($(e.target).attr('class') === "cke_button_icon cke_button__insertlatex_icon") {
        $('#latex-modal-trigger').trigger('openModal')
      }
    })
  },

  expandFigure: function() {
    $('.cke_reset').contents().click(function(e) {
      if($(e.target).attr('class') === "button secondary expand_button" || $(e.target).attr('class') === 'fa fa-arrows-alt') {
        window.open($(e.target).data("url"))
      }
    })
  },

  editFigure: function() {
    $('.cke_reset').contents().click(function(e) {
      if($(e.target).attr('class') === "button edit_button") {
        var attachmentId = $(e.target).data("attachment-id");
        var attachmentType = $(e.target).data("type");
        $('#editor-modal-trigger').trigger('openModal', {type: attachmentType, id: attachmentId})
      }
    })
  },

  bindAttachmentRemoval: function() {
    $('.cke_reset').contents().click(function(e) {
      if($(e.target).attr('class') === "button secondary remove_button" || $(e.target).attr('class') === 'trash') {
        var attachmentId = $(e.target).data("attachment-id");
        var attachmentType = $(e.target).data("type");
        $('#delete-modal-trigger').trigger('openModal', [attachmentId, attachmentType])
      }
    })
  },

  moveAttachmentUp: function() {
    var self = this;
    $('.cke_reset').contents().click(function(e) {
      if($(e.target).attr('class') === "button secondary up_button" || $(e.target).attr('class') === 'fa fa-angle-double-up') {
        var attachmentId = $(e.target).data("attachment-id");
        var nodeList = self.parsedNodeList()
        for(var i=0; i < nodeList.length; i++) {
          if (!nodeList[i].dataset.attachmentId) {continue;}
          if (nodeList[i].dataset.attachmentId === attachmentId.toString() && i !== 0) {
            let currFigure = nodeList[i].outerHTML
            let prev = nodeList[i-1].outerHTML
            nodeList[i].outerHTML = prev
            nodeList[i-1].outerHTML = currFigure
            self.disableUpDown();
            self.removeDisable();
            self.syncAttachmentNumbers();
            self.updateHiddenTextarea();
            break;
          }
        }
      }
    })
  },

  moveAttachmentDown: function() {
    var self = this;
    $('.cke_reset').contents().click(function(e) {
      if($(e.target).attr('class') === "button secondary down_button" || $(e.target).attr('class') === 'fa fa-angle-double-down') {
        var attachmentId = $(e.target).data("attachment-id");
        var nodeList = self.parsedNodeList()
        for(var i=0; i < nodeList.length; i++) {
          if (!nodeList[i].dataset.attachmentId) {continue;}
          if (nodeList[i].dataset.attachmentId === attachmentId.toString() && i !== nodeList.length - 1) {
            let currFigure = nodeList[i].outerHTML
            let next = nodeList[i+1].outerHTML
            nodeList[i].outerHTML = next
            nodeList[i+1].outerHTML = currFigure
            self.disableUpDown();
            self.removeDisable();
            self.syncAttachmentNumbers();
            self.updateHiddenTextarea();
            break;
          }
        }
      }
    })
  },

  disableUpDown: function() {
    var self = this;
    var nodeList = self.parsedNodeList()
    if (nodeList.length === 0) {return;}
    let firstEle = nodeList[0]
    let lastEle = nodeList[nodeList.length - 1]
    if(firstEle.className === 'attachment-wrapper' && lastEle.className === 'attachment-wrapper') {
      $(firstEle.children[3]).addClass('disabled')
      $(lastEle.children[4]).addClass('disabled')
    } else if (firstEle.className === 'attachment-wrapper') {
      $(firstEle.children[3]).addClass('disabled')
    } else if (lastEle.className === 'attachment-wrapper') {
      $(lastEle.children[4]).addClass('disabled')
    }
  },

  removeDisable: function() {
    var self = this;
    var nodeList = self.parsedNodeList()
    for (var i=1; i < nodeList.length - 1; i++) {
      if (nodeList[i].className === 'attachment-wrapper') {
        $(nodeList[i].children[3]).removeClass('disabled')
        $(nodeList[i].children[4]).removeClass('disabled')
      }
    }
  },

  parsedNodeList: function() {
    var self = this;
    let nodeList = self.ckeditor.document.getBody().getChildren().$
    for(var i=0; i < nodeList.length; i++) {
      if (nodeList[i].dataset === undefined) {
        nodeList[i].remove()
      }
    }
    return nodeList
   },

  syncAttachmentNumbers: function(){
    var self = this;
    var html = $.parseHTML(this.ckeditor.getData())

    // append to a temp element in order to traverse the dom tree
    var tempDom = $('<div></div>').append(html)

    // store arrays of the attachment markup with wrapper
    var attachments = {
      figure: [],
      table: [],
      video: [],
      interactive_model: []
    }

    // store the min range of each attachment type
    var minNumbers = {
      figure: null,
      table: null,
      video: null,
      interactive_model: null
    };

    // push attachment into appropriate array
    $('.attachment-wrapper', tempDom).each(function(index, elem){
      // Expected anchorId: figure-anchor-1234
      var anchorId = elem.dataset.anchorId.split('-');
      attachments[anchorId[0]].push(elem)
    })

    // set the min range for each attachment type
    for(var attachmentType in attachments){
      var minNumber = self.findMinNumber(attachments[attachmentType])
      if(minNumber){
        minNumbers[attachmentType] = minNumber;
      }
    }

    // sets the number for all the attachments in respect to the min value of each range
    for(var attachmentType in attachments){
      for(var i=0; i < attachments[attachmentType].length; i++){
        self.syncNumber(attachments[attachmentType][i], i + minNumbers[attachmentType])
      }
    }
  },

  syncNumber: function(elem, number){
    elem.dataset.number = number; // replace number in wrapper or add if doesn't exist
    $(elem).find('.article-attachment-wrap').data({number: number}); // replace number in main wrap or add if doesn't exist
    var title = $(elem).find('.article-attachment-wrap .attachment-title').html().match(/^(.*?):(.*)/);
    $(elem).find('.article-attachment-wrap .attachment-title').html([title[1].replace(/(\d+)(?!.*\d)/, number), title[2]].join(':'));
    this.ckeditor.document.findOne('[data-anchor-id="' + elem.dataset.anchorId + '"]').$.replaceWith(elem)
  },

  findMinNumber: function(attachments){
    var numbers = attachments.map(function(a){
      return parseInt($(a).find('.article-attachment-wrap .attachment-title').text().split(':')[0].split(' ').pop())
    })
    return((numbers.length > 0) ? Math.min.apply(null, numbers) : null)
  },

  updateHiddenTextarea: function(){
    this.$hiddenTextArea.html(this.ckeditor.getData());
    this.$hiddenTextArea.val(this.ckeditor.getData());
  },

  setCharCount: function(){
    this.enableButtonArea();
    if(this.maximum_length){ this.initializeCharacterCounter(); }
  },

  setFullHeight: function(){
    var self = this;
    if(self.responsive_container){
      window.setTimeout ( function() {
        var containerHeight = $(self.responsive_container).height() - 30;
        self.ckeditor.resize(self.ckeditor.config.width, containerHeight);
      }, 100 )
    }
  },

  initializeMediaLibrary: function(editorType){
    if(editorType == 'media_library') {
      this.disableAttachments();
    }
  },

  setPlaceholderText: function() {
    if (this.placeholderText) {
      this.ckeditor.setData(this.placeholderText);
    }
  },

  setInitialValue: function() {
    if (this.options.initialValue) {
      this.ckeditor.setData(this.options.initialValue)
    }
  },

  removeLoadingText: function(){
    $('.ck-wrapper h3').hide();
  },

  addTipText: function() {
    $('.ck-wrapper .tip').show();
  },

  disableAttachments: function(){
    var nodeList = this.ckeditor.document.find('.article-attachment-wrap');
    for(var i=0; i < nodeList.count(); i++){
      nodeList.getItem(i).setAttribute('contentEditable', false);
    }
  },

  initializeCharacterCounter: function(){
    var self;
    self = this;

    self.insertCharacterCounter();

    self.ckeditor.on('paste', function (evt){
      window.setTimeout(function (){
        self.updateCharacterCount();
      }, 130);
    });

    self.ckeditor.document.on('keyup', function(evt) {
      self.updateCharacterCount();
    });
  },

  getCurrentLength: function(){
    var text;

    text = $(this.ckeditor.getData()).text();
    text = text.replace(/\s?\n\s?/gm, "0"); // newlines automatically get spaces added alongside for some reason so squash them together when counting.

    return text.length;
  },

  insertCharacterCounter: function(){
    var counter_id, $ckeditor, self;
    self = this;

    counter_id = self.cke_dom_id + "_counter";
    $ckeditor = $("#" + self.cke_dom_id);
    self.counter_elem = $("<div class='originalDisplayInfo' id ='" + counter_id + "'></div>");
    $ckeditor.after(self.counter_elem);

    self.updateCharacterCount();
  },

  getAllowableElements: function(editorType){
    var ALLOWED_ELEMENTS = {
      references: 'p em h4 sup sub blockquote ol li',
      expanded_abstract: 'p em h4 sup sub blockquote ol ul li',
      appendices: 'p strong em h4 sup sub blockquote ol ul li',
      table: true,
      media_library: true,
      deferral: true,
      "submit-modal": true,
      "default" : 'p em h4 sup sub blockquote'
    }

    return ALLOWED_ELEMENTS[editorType] || ALLOWED_ELEMENTS['default'];
  },

  getEditorTypeOptions: function (editorType){
    var EDITOR_TYPES = {
      references: {
        stylesSet: 'cureus-subheaders',
        height: 600,
        toolbar: [
          {name: 'styles',      items: ['Styles'] },
          {name: 'paragraph',   items: ['NumberedList'] },
          {name: 'superscript', items: ['Superscript']},
          {name: 'subscript',   items: ['Subscript']}
        ],
        removePlugins: 'elementspath'
      },
      abstract: {
        stylesSet: 'cureus-no-subheaders',
        height: 600,
        toolbar: [
          {name: 'styles',      items: ['Styles']},
          {name: 'basicstyles', items: ['Italic', 'Superscript', 'Subscript'] },
          {name: 'insertLatex',      items: ['insertLatex']},
          {name: 'paragraph',   items: ['Blockquote']}
        ],
        extraPlugins: 'insertLatex,blockquote',
        removePlugins: 'elementspath'
      },
      appendices: {
        toolbar: [
          {name: 'styles',      items: ['Styles']},
          {name: 'basicstyles', items: ['Bold']},
          {name: 'insertLatex',      items: ['insertLatex']},
          {name: 'superscript', items: ['Superscript']},
          {name: 'subscript',   items: ['Subscript']},
          {name: 'paragraph',   items: ['Blockquote','NumberedList','BulletedList']}
        ],
        stylesSet: 'cureus-subheaders',
        extraPlugins: 'insertLatex,blockquote',
        removePlugins: 'elementspath'
      },
      expanded_abstract: {
        toolbar: [
          {name: 'styles',      items: ['Styles']},
          {name: 'insertLatex',      items: ['insertLatex']},
          {name: 'superscript', items: ['Superscript']},
          {name: 'subscript',   items: ['Subscript']},
          {name: 'paragraph',   items: ['Blockquote','NumberedList','BulletedList']}
        ],
        extraPlugins: 'insertLatex,blockquote',
        removePlugins: 'elementspath'
      },
      table: {
        stylesSet: 'cureus-no-subheaders',
        height: 400,
        toolbar: [
          { name: 'basicstyles', items : [ 'Bold'] },
          { name: 'basicstyles', items : ['Italic'] },
          {name: 'superscript', items: ['Superscript']},
          {name: 'subscript',   items: ['Subscript']},
        ],
        extraPlugins: 'confighelper,blockimagepaste',
        removePlugins: 'elementspath'
      },
      marketing_mailer: {
        stylesSet: 'cureus-subheaders',
        height: 600,
        allowedContent: '',
        extraPlugins: 'colorbutton,font',
      },
      static_text: {
        stylesSet: 'cureus-subheaders',
        height: 600,
        allowedContent: '',
        toolbar: [
          { name: 'basicstyles', items : [ 'Bold','Italic','Underline','RemoveFormat' ] },
          { name: 'paragraph', items : [ 'NumberedList','BulletedList'] },
          { name: 'links', items : [ 'Link','Unlink'] }
        ]
      },
      static_text_with_color: {
        stylesSet: 'cureus-subheaders',
        height: 600,
        extraPlugins: 'colorbutton,panel,panelbutton,floatpanel,button,font,confighelper',
        allowedContent: '',
        toolbar: [
          { name: 'basicstyles', items : [ 'Bold','Italic','Underline','RemoveFormat' ] },
          { name: 'paragraph', items : [ 'NumberedList','BulletedList'] },
          { name: 'links', items : [ 'Link','Unlink'] },
          { name: 'colors', items : [ 'TextColor'] },
          { name: 'font', items : [ 'FontSize']}
        ]
      },
      competition: {
        stylesSet: 'cureus-subheaders',
        height: 600,
        allowedContent: true,
        forcePasteAsPlainText: true,
        toolbar: [
          { name: 'basicstyles', items : [ 'Bold','Italic','Underline','RemoveFormat' ] },
          { name: 'paragraph', items : [ 'NumberedList','BulletedList' ] },
          { name: 'links', items : [ 'Link','Unlink' ] },
          { name: 'insert', items : [ 'html5video' ] },
          { name: 'source', items : [ 'Source' ] }
        ],
        extraPlugins: 'sourcedialog,html5video,widget,widgetselection,clipboard,lineutils'
      },
      basic: {
        stylesSet: 'cureus-subheaders',
        height: 150,
        allowedContent: '',
        toolbar: [
          { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline'] },
          { name: 'paragraph', items : [ 'NumberedList','BulletedList'] },
          { name: 'links', items: ['Link', 'Unlink'] }
        ]
      },
      attachment_legend: {
        forcePasteAsPlainText: true,
        toolbar: [
          {name: 'basicstyles', items : [ 'Italic' ] },
          {name: 'superscript', items: ['Superscript']},
          {name: 'subscript',   items: ['Subscript']}
        ],
        extraPlugins: 'confighelper',
        removePlugins: 'elementspath'
      },
      blank: {
        toolbar: [],
        removePlugins: 'elementspath'
      },
      media_library : {
        extraPlugins: 'insertLatex,blockquote,insertFigure,insertTable,insertVideo,insertInteractiveModel',
        removePlugins: 'elementspath,pastefromword,liststyle',
        height: 600,
        stylesSet: 'cureus-subheaders',
        forcePasteAsPlainText: true,
        toolbar: [
          {name: 'styles',      items: ['Styles']},
          {name: 'basicstyles', items: ['Italic', 'Superscript', 'Subscript'] },
          {name: 'insertLatex',      items: ['insertLatex']},
          {name: 'paragraph',   items: ['Blockquote']},
          {name: 'insertFigure',items: ['insertFigure']},
          {name: 'insertTable', items: ['insertTable']},
          {name: 'insertVideo', items: ['insertVideo']},
          {name: 'insertInteractiveModel',items: ['insertInteractiveModel']}
        ]
      },
      deferral: {
        extraPlugins: 'blockquote',
        removePlugins: 'elementspath',
        height: 600,
        stylesSet: 'cureus-subheaders',
        forcePasteAsPlainText: true,
        toolbar: [
          {name: 'styles',      items: ['Styles']},
          {name: 'basicstyles', items: ['Bold']},
          {name: 'basicstyles', items: ['Italic' ] },
          {name: 'paragraph',   items: ['NumberedList','BulletedList', 'Blockquote' ]},
          {name: 'superscript', items: ['Superscript']},
          {name: 'subscript',   items: ['Subscript']},
          { name: 'links', items: ['Link', 'Unlink'] }
        ]
      },
      'submit-modal' : {
        stylesSet: 'cureus-subheaders',
        extraPlugins: 'blockquote',
        removePlugins: 'elementspath',
        height: 600,
        forcePasteAsPlainText: true,
        toolbar: [
          {name: 'basicstyles', items: ['Bold','Italic','Underline','Strike']},
          {name: 'superscript', items: ['Superscript']},
          {name: 'subscript',   items: ['Subscript']},
          {name: 'paragraph',   items: ['Blockquote','NumberedList','BulletedList']},
        ]
      },
      'conclusions' : {
        extraPlugins: 'insertLatex,blockquote',
        removePlugins: 'elementspath',
        height: 600,
        stylesSet: 'cureus-subheaders',
        forcePasteAsPlainText: true,
        toolbar: [
          {name: 'basicstyles', items: ['Italic','Superscript', 'Subscript' ] },
          {name: 'insertLatex',      items: ['insertLatex']},
          {name: 'paragraph',   items: ['Blockquote']}
        ]
      },
      'introduction' : {
        extraPlugins: 'insertLatex,blockquote',
        removePlugins: 'elementspath',
        height: 600,
        stylesSet: 'cureus-subheaders',
        forcePasteAsPlainText: true,
        toolbar: [
          {name: 'styles',      items: ['Styles']},
          {name: 'basicstyles', items: ['Italic','Superscript', 'Subscript' ] },
          {name: 'insertLatex',      items: ['insertLatex']},
          {name: 'paragraph',   items: ['Blockquote']}
        ]
      },
      'default' : {
        extraPlugins: 'blockquote',
        removePlugins: 'elementspath',
        height: 600,
        stylesSet: 'cureus-subheaders',
        forcePasteAsPlainText: true,
        toolbar: [
          {name: 'styles',      items: ['Styles']},
          {name: 'basicstyles', items: ['Italic','Superscript', 'Subscript' ] },
          {name: 'paragraph',   items: ['Blockquote']}
        ]
      }
    }
    return EDITOR_TYPES[editorType] || EDITOR_TYPES['default'];
  },

  updateCharacterCount: function(){
    var currentLength = this.getCurrentLength();
    var remaining_characters = this.maximum_length - currentLength;
    if(remaining_characters < 21){
      this.counter_elem.addClass("negative");
    } else {
      this.counter_elem.removeClass("negative");
    }
    this.counter_elem.html(currentLength + "/" + this.maximum_length + " characters");
  },

  enableButtonArea: function(){
    var $btn_area = $(".btn-area");
    if(($btn_area).css('display') == 'none'){
      $(".btn-area").fadeIn();
    }
  },


  initialNextButtonValidator: function() {
      this.nextButtonValidator()
    },

  nextButtonValidator: function() {
    if (this.maximum_length === false) { return; }
    var limit = this.maximum_length ? this.maximum_length : Infinity
    if ( (this.getCurrentLength() > 0) && (this.getCurrentLength() <= limit) ) {
      this.enableButton();
    }
    else {
      this.disableButton();
    }
  },

  enableButton: function() {
    this.$buttonToValidate.attr("disabled", false);
  },

  disableButton: function() {
    this.$buttonToValidate.attr("disabled", true);
  }
});