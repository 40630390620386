import React from 'react';
import DropDown from './DropDown';
import Button from './Button'

var createReactClass = require('create-react-class');
const Calculator = createReactClass({
  getInitialState: function (){
    return {
      country: this.props.userCountry || 'United States',
      type: 'Original Article',
      word_count: 0,
      references: '1',
      errors: 'Level 1',
      price: 'Free',
      authorships: 1
    }
  },

  componentDidMount: function() {
    document.getElementById('countries-drop').value = this.state.country
  },

  getPrice: function(key, value) {
    var self = this;
    self.setState({price: null})
    if(key === 'type') {
      self.setState({[key]: value})
    } else {
      self.setState({[key]: value})
    }
  },

  sendRequest: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      url: '/calculate',
      dataType: 'json',
      data: self.state,
      success: function(res){
        self.setState({price: res.price})
      }
    })
  },

  handleButtonBorder: function(label) {
     if(this.state.errors === label) {
       return ({border: '2px solid #0096B7', fontWeight: 'bold'})
     }
  },

  renderPrice: function() {
    var padding = '0px';
    var className = 'button success right small'
    if (this.props.home){
      padding = '20px';
      className += ' home'
    }
    if(this.state.price !== 'Free' && this.state.price) {
      var additional = this.state.errors === "Level 5" ? 50 : 70
      var displayPrice = this.state.type === 'Review Article' && this.state.errors === "Level 1" ? `$${this.state.price}` : `$${this.state.price} - $${this.state.price + additional}`
      return (<h4 style={{paddingRight: padding}} id="price">{displayPrice}</h4>)
    } else if (this.state.price === 'Free') {
      return <h4 style={{paddingRight: padding}} id="price">{this.state.price}</h4>
    }
    else {
      return <a className={className} onClick={this.sendRequest}>Calculate</a>
    }
  },

  renderTitle: function() {
    var text = "Preferred Editing Service Price Calculator"
    if (this.props.home){
      text = "How much does it cost to publish?"
    }
    return (
      <h4>{text}</h4>
    )
  },

  renderPriceTitle: function() {
    var padding = '0px';
    if (this.props.home){
      padding = '20px';
    }
    return (
      <h6 style={{textAlign: 'right', paddingRight: padding}}>Estimated Price:</h6>
    )
  },

  renderMessage: function() {
    if (this.props.home) {
      return (
        <p style={{fontStyle: "italic", textAlign: "center", marginTop: '20px', marginBottom: '0px'}}>
          Learn more about editing services <a href="cureus_editing_services" target="_blank">here</a>
        </p>
      )
    }
  },

  render: function() {
    return(
      <div className='calculator'>
      {this.renderTitle()}
        <div className="row">
          <div className="small-12 columns">
            <DropDown 
            options={this.props.countries}
            getPrice={this.getPrice}
            stateKey='country'
            id="countries-drop"
            label="Primary affiliation country"
            />
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <DropDown 
              handleButtonBorder={this.handleButtonBorder}
              options={this.props.articleTypes}
              getPrice={this.getPrice}
              stateKey='type'
              label="Type of article"
            />
          </div>
        </div>
        <div className="row">
        <div className="small-6 columns">
          <DropDown 
              handleButtonBorder={this.handleButtonBorder}
              options={this.props.references}
              getPrice={this.getPrice}
              stateKey='references'
              label="Reference count"
            />
          </div>
          <div className="small-6 columns">
            <DropDown
              range={true} 
              handleButtonBorder={this.handleButtonBorder}
              options={this.props.wordRange}
              getPrice={this.getPrice}
              stateKey='word_count'
              label="Word count"
              />
          </div>
        </div>
        <div className="row">
        <div className="small-6 columns">
        <DropDown
            handleButtonBorder={this.handleButtonBorder}
            options={this.props.authorships}
            getPrice={this.getPrice}
            stateKey='authorships'
            label="Author count"
            />
          </div>
          <div className="small-6 columns">
          <DropDown
            handleButtonBorder={this.handleButtonBorder}
            options={this.props.errorLevels}
            getPrice={this.getPrice}
            stateKey='errors'
            label="Article errors"
            />
          </div>
        </div>
        <div className="row">
          <div className="price-container">
            <h6 className="reg">Estimated Price:</h6>
            {this.renderPrice()}
          </div>
        </div>
        <div className="large-12">
          {this.renderMessage()}
        </div>
      </div>
    )
  }
})

export default Calculator;