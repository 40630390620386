import React, { useState } from 'react';
import Modal from '../Modal';
import {Cureus} from '../../Cureus';

export default function EmailEditingServiceReceiptModal(props) {

  const [email, setEmail] = useState(props.email);
  const [disabled, setDisabled] = useState(false);

  function handleSend(e) {
    e.preventDefault();
    setDisabled(true);
    $.ajax({
      type: "POST",
      url: props.emailReceiptPath,
      data: getSendParams(),
      success: function() {
        Cureus.Flash.success('Your Preferred Editing purchase receipt has been resent to ' + email + ".");
      },
      error: function() {
        Cureus.Flash.alert('Failed to resend your Preferred Editing purchase receipt to ' + email + ".");
      },
      complete: function() {
        Modal.close();
        setDisabled(false);
        window.scrollTo(0, 0);
      }
    })
  }

  function getSendParams() {
    return {
      email: email,
      type: 'EditingServiceInvoice',
      id: props.editingServiceInvoiceId
    }
  }

  return (
    <div className="reveal-modal middling" data-reveal id={"email-editing-service-receipt-modal-" + props.editingServiceInvoiceId}>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        
        <div className="modal-content">
          <h4 className='reg'>Resend PE Receipt</h4>
          <p>Please enter the email address where you wish to receive the Preferred Editing receipt for this article.</p>
            <label>Email: </label>
            <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
        </div>

        <div className="modal-footer">
          <a href="#" className="button secondary cancel-modal">Cancel</a>
          <a href="#" className='button' onClick={handleSend} disabled={disabled}>Send</a>
        </div>
      </div>
    </div>
  )
}