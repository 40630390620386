import React, {useState, useEffect} from 'react';
import Modal from '../Modal';
import GoogleCalendarEventAddModal from './GoogleCalendarEventAddModal';

const Availability = ({checkTokenPath, createEventPath, calendarId, googleButton, editorType}) => {

  const [isValidCalendarToken, setIsValidCalendarToken] = useState(false);

  useEffect(() => {
    checkIfUserHasProperToken();
  }, [])

  const checkIfUserHasProperToken = () => {
    $.ajax({
      type: "GET",
      url: checkTokenPath,
      dataType: 'json',
      success: function(res) {
        setIsValidCalendarToken(res.valid_token)
      }
    })
  }

  const handleGoogleCalendarEventAdd = () => {
    Modal.open({
      children:
        <GoogleCalendarEventAddModal
          createEventPath={createEventPath}
          calendarId={calendarId}
          isValidCalendarToken={isValidCalendarToken}
          googleButton={googleButton}
        />
    })
  }

  const renderAvailability = () => {
    if (editorType !== 'ApprovalEditor') {
      return (
        <div className='availability-section'>
          <div className='availability-links-container'>
            <img className='availability-icon' src="https://public.cureus.com/admin/editors-availability/editors-availability-icon.png" />
            <h4 className='availability-text'>My Availability</h4>
            <a className='add-range-button' onClick={handleGoogleCalendarEventAdd}>Add an event</a>
            <a className='view-calendar-button' href={`https://calendar.google.com/calendar/u/0/r?cid=${calendarId}`} target="_blank">View Team Calendar</a>
          </div>
          <div className='availbility-description-container'>
            <p>Are you going to be unavailable for more than a day or two? Please click the orange button above to add the time to our team calendar. (You'll first be prompted to sign in to your Cureus Google account.)
            </p>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  return renderAvailability()
}

export default Availability;