var createReactClass = require('create-react-class');
import React from 'react';
import SectionListItem from './SectionListItem';

const Main = createReactClass({
  getInitialState: function() {
    return ({
      sections: this.props.sections,
      campaignId: this.props.campaignId,
      channelDigestId: this.props.channelDigestId,
    })
  },

  mapItems: function() {
    var self = this;
    return self.state.sections.map(function(section, idx) {
      idx = idx + 1
      return (
        <SectionListItem
          key={section.id}
          number={idx}
          id={section.id}
          imagePosition={section.imagePosition}
          ctaButtonText={section.ctaButtonText}
          ctaButtonUrl={section.ctaButtonUrl}
          message={section.message}
          image={section.image}
          updateSection={self.updateSection}
          removeSection={self.removeSection}
          newsroom={self.props.newsroom}
          channelDigestId={self.props.channelDigestId}
        />
      )
    })
  },

  addSection: function() {
    var self = this;
    $.ajax({
      type: "POST",
      url: self.props.addSectionPath,
      success: function(res) {
        var newSections = self.state.sections
        newSections.push(res.section)
        self.setState({sections: newSections})
      }
    })
  },

  updateSection: function(params) {
    var self = this;
    var path;
    var model;
    if(!self.props.channelDigestId) {
      model = self.props.newsroom === true ? "newsroom_posts/" : "campaigns/"
      path = '/admin/' + model  +  self.state.campaignId  + '/sections/' + params.id
    } else {
      path = `/admin/channels/${self.props.channelId}/digests/${self.props.channelDigestId}/sections/${params.id}`;
    }
    var formFile = self.createFormData(params.imageFile)
    delete params.imageFile
    delete params.disabled
    formFile.append("campaign_section", JSON.stringify(params))

    fetch(path, {
      method: "PUT",
      body: formFile,
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': $("[name='csrf-token']").attr("content")
      }
    }).then(function(response) {return response.json()})
    .then(function(res) {
      var newSections = self.state.sections.filter(function(sec) {
        return sec.id !== res.section.id
      })
      newSections.push(res.section)
      newSections.sort(function(a, b) {
        if (a.id > b.id) return 1;
  		  if (b.id > a.id) return -1;
      })
      self.setState({sections: newSections})
    })
  },

  createFormData: function(file) {
    var formFile = new FormData()
    if (!file) {return formFile}
    formFile.append("image", file)
    return formFile
  },

  removeSection: function(sectionId) {
    var self = this;
    var path;
    var model;
    if(!self.props.channelDigestId) {
      model = self.props.newsroom === true ? "newsroom_posts/" : "campaigns/"
      path = '/admin/' + model  +  self.state.campaignId  + '/sections/' + sectionId
    } else {
      path = `/admin/channels/${self.props.channelId}/digests/${self.props.channelDigestId}/sections/${sectionId}`;
    }
    $.ajax({
      url: path,
      type: "DELETE",
      success: function() {
        var newSections = self.state.sections.filter(function(sec) {
          return sec.id !== sectionId
        })
        self.setState({sections: newSections})
      }
    })
  },

  render: function() {
    return (
      <div className="campaign-sections">
        <ul style={{listStyle:"none"}}>
          {this.mapItems()}
        </ul> 
        <div className="row">
          <a className="button primary right" onClick={this.addSection}>Add Section</a>
        </div>
      </div>
    )
  }
})

export default Main;