import {Cureus} from '../Cureus';

Cureus.DismissableModal = (function($, _) {
    var init, DismissableModal;
    var dismissableModalSelector = ".dismissable-modal";

    init = function(selector, options) {
      var options = options || {};
      _.each($(selector || dismissableModalSelector), function(el) {
        new DismissableModal($(el), options);
      });
    }

    DismissableModal = function($modalElm, options) {
      this.$modalElm = $modalElm;
      this.modalElmId = $modalElm.attr('id');
      var defaultCloseSelector = "#" + this.modalElmId + "-submit";
      var modalCloseSelector = options.closeSelector || defaultCloseSelector;
      this.$modalSubmitElm = $(modalCloseSelector);
      this.$modalCheckbox = this.$modalElm.find("[data-dismiss-checkbox]");
      this.expireTime = options.expireTime || 365;
      if(!this.isCookieSet()) {
        this.$modalElm.foundation('reveal', 'open');
      } else {
        let ele = $('#keywords-add-modal')
        if(ele.length > 0) {
          ele.foundation('reveal','open')
        }
      }

      this.bindSubmitEvent();
    }

    _.extend(DismissableModal.prototype, {
      isCookieSet: function() {
        return $.cookie(this.cookieName()) != undefined;
      },

      bindSubmitEvent: function() {
        var self = this;

        if(self.$modalCheckbox.length == 0) {
          self.$modalElm.on("closed.fndtn.reveal", function() {
            self.setCookie();
          });
        } else {
          self.$modalSubmitElm.on("click", function(e){
            if(self.$modalCheckbox.is(':checked')) {
              self.setCookie();
            }
            self.$modalElm.foundation('reveal', 'close')
            e.preventDefault();
            e.stopPropagation();
          });
        }
      },

      setCookie: function() {
        if(this.expireTime != -1) {
          $.cookie(this.cookieName(), "dismissed", { path: "/", expires: this.expireTime});
        } else {
          $.cookie(this.cookieName(), "dismissed", { path: "/" });
        }
      },

      cookieName: function() {
        return this.modalElmId;
      }
    });

    return {init: init};
  })(jQuery, _);

