import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import UpdateButton from '../UpdateButton';
import DeleteButton from '../DeleteButton';
import Card from '../../MostPopularArticles/Card'

const MostPopularContent = ({slug, handleDeleteWidget, handleUpdateWidget, sidebarWidgets, widgetId}) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    if(widgetId) {
      let onSuccess = (res) => {
        setContent(res)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [sidebarWidgets])

  const renderActionButtons = () => {
    return (
    <>
      <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
      <UpdateButton
        handleUpdateWidget={handleUpdateWidget}
        widget={content}
        modalName={"MostPopularContentModal"}
        sidebarWidgets={sidebarWidgets}
        slug={slug}
        hasArticles={content ? content.hasArticles : null}
      />
      <DeleteButton
        handleDeleteWidget={handleDeleteWidget}
        widgetId={widgetId}
      />
    </>
    )
  }

  const renderContents = () => {
    if (!content) {return}
    return content.contentDetails.map( (details, idx) => {
      return (
        <Card key={idx} article={details}/>
      )
    })
  }

  return (
    <div className="row sidebar-row">
      {handleDeleteWidget && renderActionButtons()}
      <div className="small-12 columns widget most-popular-container">
        <h3>Most Popular</h3>
        {renderContents()}
      </div>
    </div>
  )
}

export default MostPopularContent;