import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";
import UnpublishedTab from './UnpublishedTab/UnpublishedTab';
import PublishedTab from './PublishedTab/PublishedTab';
import PendingArticlesTab from './PendingArticles/PendingArticlesTab';
import RejectedTab from './RejectedTab/RejectedTab';

const MyCollections = ({myCollectionsPath, publishNewCollectionPath, checkPendingStatusPath, publishedCollectionCount}) => {

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname === '/' ? 'unpublished' : location.pathname.split('/')[1]);
  const [unpublishedCollections, setUnpublishedCollections] = useState([]);
  const [publishedCollections, setPublishedCollections] = useState([]);
  const [pendingCollections, setPendingCollections] = useState([]);
  const [rejectedCollections, setRejectedCollections] = useState([]);
  const [pendingArticlesCount, setPendingArticlesCount] = useState(0);
  const [rejectedCollectionsCount, setRejectedCollectionsCount] = useState(0);
  const [page, setPage] = useState([]);
  const [paginationData, setPaginationData] = useState({currentPage: 1, firstPage: true});

  useEffect(() => {
    checkAndShowPendingTab();
    checkAndShowRejectedTab();
  }, [])

  useEffect(() => {
    fetchCollections(activeTab, page);
  },  [page])

  useEffect(() => {
    fetchCollections(activeTab, 1);
  }, [activeTab])
  
  useEffect(() => {
    const path = location.pathname.replace('/', '');
    setActiveTab(path || 'unpublished');
  }, [location.pathname])

  const updatePage = (page) => {
    setPage(page);
  }

  const fetchCollections = (state, page_number=page) => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      data: {state: state, page: page_number},
      url: myCollectionsPath,
      success: function(res) {
        if (state === 'unpublished') {
          setUnpublishedCollections(res.collections);
        } else if (state === 'published') {
          setPublishedCollections(res.collections);
        } else if (state === 'pending_articles') {
          setPendingCollections(res.collections);
        } else if (state === 'rejected') {
          setRejectedCollections(res.collections);
        }
        setPaginationData(res.paginationData)
      }
    })
  }

  const checkAndShowPendingTab = () => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: checkPendingStatusPath,
      success: function(res) {
        setPendingArticlesCount(res.pendingArticlesCount);
      }
    })
  }

  const checkAndShowRejectedTab = () => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: '/dashboard/check_rejected_collections_status',
      success: function(res) {
        setRejectedCollectionsCount(res.rejectedCollectionCount);
      }
    })
  }

  return (
    <div className="large-9 medium-12 small-12 columns new-dashboard-section">
      <div className="small-12 columns nav-container">
        <div className="row">
          <div className="tab-items">
            <ul className='tab-title-list'>
              <Link to='/'>
                <li className={"tab-title " + (activeTab === 'unpublished' ? 'active' : '')} onClick={() => setActiveTab('unpublished')}>
                  <a className="tab">UNPUBLISHED {`(${unpublishedCollections.length})`}</a>
                </li>
              </Link>
              { publishedCollectionCount > 0 && (
                <Link to='/published'>
                  <li className={"tab-title " + (activeTab === 'published' ? 'active' : '')} onClick={() => setActiveTab('published')}>
                    <a className="tab">PUBLISHED ({publishedCollectionCount})</a>
                  </li>
                </Link>
              )}
              {pendingArticlesCount > 0 &&
                <Link to='/pending_articles'>
                  <li className={"tab-title " + (activeTab === 'pending_articles' ? 'active' : '')} onClick={() => setActiveTab('pending_articles')}>
                    <a id='pending-tab-count' className="tab">PENDING ARTICLES {`(${pendingArticlesCount})`}</a>
                    <span><img className='pending-dot' src="https://public.cureus.com/my_dashboard/my_collections/pending-dot.png" /></span>
                  </li>
                </Link>
              }
              {rejectedCollectionsCount > 0 &&
                <Link to='/rejected'>
                  <li className={"tab-title " + (activeTab === 'rejected' ? 'active' : '')} onClick={() => setActiveTab('rejected')}>
                    <a className="tab">REJECTED {`(${rejectedCollectionsCount})`}</a>
                  </li>
                </Link>
              }
            </ul>
          </div>
          <Switch>
            <Route exact path="/">
              <UnpublishedTab 
                publishNewCollectionPath={publishNewCollectionPath}
                collections={unpublishedCollections} 
                fetchCollections={fetchCollections}
                paginationData={paginationData}
                handlePageSelect={updatePage}
                pagesType={"article"}
              />
            </Route>
            {publishedCollectionCount > 0 && (
              <Route path="/published">
                <PublishedTab
                  collections={publishedCollections} 
                  paginationData={paginationData} 
                  handlePageSelect={updatePage} 
                  pagesType={"article"}
                  fetchCollections={fetchCollections}
                />
              </Route>
            )}
            <Route path="/pending_articles">
              <PendingArticlesTab 
                collections={pendingCollections}
                paginationData={paginationData}
                handlePageSelect={updatePage}
                pagesType={"article"}
              />
            </Route>
            <Route path="/rejected">
              <RejectedTab 
                collections={rejectedCollections}
                paginationData={paginationData}
                handlePageSelect={updatePage}
                pagesType={"article"}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )

}

export default MyCollections;