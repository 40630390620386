import React, { memo, useEffect, useState } from 'react';
import TopAuthors from '../../Shared/TopAuthors';
import TopContent from '../../Shared/TopContent';
import TrendingTopics from '../../NewArticleIndex/RightSidebar/TrendingTopics';

const RightSideBar = ({
  refreshKeyWords,
  handleKeyWordClick,
  categorySelection,
  contentTypeTitle,
  contentType,
}) => {
  const [authors, setAuthors] = useState([]);
  const [contents, setContents] = useState([]);
  const [keywords, setKeywords] = useState([]);
  useEffect(() => {
    getTopAuthorsByViews();
    getTopAbstractsByViews();
  }, []);

  useEffect(() => {
    fetchKeyWords();
  }, [refreshKeyWords]);

  const getTopAuthorsByViews = () => {
    fetch(`/${contentType}/top_authors_by_view`)
      .then((res) => res.json())
      .then((json) => {
        setAuthors(json.authors);
      });
  };

  const getTopAbstractsByViews = () => {
    fetch(`/${contentType}/top_${contentType}_by_view`)
      .then((res) => res.json())
      .then((json) => {
        setContents(json.contents);
      });
  };

  const fetchKeyWords = () => {
    $.ajax(
      {
        method: "GET",
        url: `/${contentType}/most_popular_keywords`,
        dataType: "json",
        data: { category_ids: categoryIds() },
        success: function (res) {
          setKeywords(res.keyWords || []);
        },
        error: function (res) {},
      },
      "json",
    );
  };

  const categoryIds = () => {
    if (categorySelection.allCategories) {
      return "";
    } else {
      return Object.keys(categorySelection).join(",");
    }
  };

  return (
    <div className="small-2 columns show-for-large-up">
      <div className="right-sidebar">
        <TopAuthors
          authors={authors}
          viewMoreButton={false}
          seeMoreUsersPath="/people"
        />
        <TrendingTopics
          keywords={keywords}
          updateSearch={handleKeyWordClick}
          headerEnabled={false}
        />
        <TopContent
          contents={contents}
          viewMoreButton={false}
          viewMoreArrow={false}
          contentTypeTitle={contentTypeTitle}
          contentType={contentType}
        />
      </div>
    </div>
  );
};

export default memo(RightSideBar);