var createReactClass = require('create-react-class');
import React from 'react';

const PeerReviewPanel = createReactClass({
  getInitialState: function() {
    return {
      reviewer_pool: null
    }
  },

  componentDidMount: function() {
    var self = this;
    if (self.props.confirmation) {
      $.ajax({
        url: self.props.getComponentDataPath,
        type: "GET",
        data: {class: 'PeerReviewPanel', user_id: self.props.user_id},
        dataType: "json",
        success: function(data) {
          self.setState({
            reviewer_pool: data.userProfile.reviewer_pool
          })
        }
      })
    } else {
      self.setState({            
        reviewer_pool: this.props.userProfile.reviewer_pool
      })
    }
  },
  
  handleChange: function(e) {
    if (e.target.checked) {
      this.setState({[e.target.name]: 1})
    } else {
      this.setState({[e.target.name]: 0})
    }
  },

  render: function(){
    return (
      <div className="reveal-modal medium" data-reveal data-options={this.props.setUncloseable()}>
      <div className="modal-body">
        {this.props.renderFlash()}
        {this.props.renderModalCloseX()}

        <div className="modal-content">
          <h4>Apply to Join Our Peer Review Panel</h4>
          <p>Lend a hand to your fellow Cureus authors and volunteer for our peer review panel. We'll review your qualifications and experience and contact you with any questions or concerns. <a target="_blank" href="/reviewer_guide#!/overview/why-review-with-cureus">Reviewing with Cureus</a> is easy, fast and hassle-free!</p>
          <input type="checkbox" name="reviewer_pool" onChange={(e) => this.handleChange(e)} checked={this.state.reviewer_pool === 1} id="reviewer_label_id"/>
          <label htmlFor="reviewer_label_id">I would like to join the Peer Review Panel.</label>
        </div>
        {this.props.renderErrors()}
        <div className='modal-footer'>
              {this.props.renderButton({user: this.state, section: 'PeerReviewPanelForm'}, this.props.updateProfilePath, () => this.forceUpdate())}
        </div>
      </div>
    </div>
    )
  }
})

export default PeerReviewPanel;