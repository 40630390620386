var createReactClass = require('create-react-class');
import React from 'react';
import Button from './Button';

const Main = createReactClass({
  getInitialState: function() {
    return({
      channels: this.props.social_channels,
      update_profile_path: this.props.update_profile_path,
      user_data: this.props.user_data,
      section: this.props.section
    })
  },

  renderButtons: function() {
    var url = this.state.update_profile_path
    var user_data = this.state.user_data
    var section = this.state.section
    return this.state.channels.map(function(channel) {
      return(
        <Button
          actionLink={channel.actionLink}
          addLink={channel.addLink}
          removeLink={channel.removeLink}
          name={channel.name}
          icon={channel.icon}
          key={channel.name}
          added={channel.added}
          update_profile_path={url}
          user_data={user_data}
          section={section}
        />
      );
    });
  },

  render: function() {
    return(
    <div className='row'>
      <div className='large-12 columns'>
        {this.renderButtons()}
      </div>
    </div>
    )
  }
})

export default Main;