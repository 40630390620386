import React, {useState, useEffect} from 'react';
import CollectionArticleCard from './CollectionArticleCard';
import KeywordSearchedArticles from './KeywordSearchedArticles';

const Main = ({articlesAdded, basePath, collectionId, collectionType, articlesCount}) => {

  const [selectedArticles, setSelectedArticles] = useState(articlesAdded || []);
  const [keywordSearchedSections, setKeywordSearchedSections] = useState([]);
  const [articleId, setArticleId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [spinning, setSpinning] = useState(false);
  const [keywordInputError, setKeywordInputError] = useState(false);
  const [totalArticlesCount, setTotalArticlesCount] = useState(articlesCount || 0);
  const [errorMessage, setErrorMessage] = useState(null);
 
  const getSearchedKeyWordArticles = () => {
    if (keyword.trim().length === 0) {
      setKeywordInputError(true);
      setKeyword("");
    } else {
      setSpinning(true);
      $.ajax({
        type: 'GET',
        url: `${basePath}/keyword_search`,
        dataType: 'json',
        data: { keyword: keyword },
        success: function(res){
          setKeywordSearchedSections([...keywordSearchedSections, {keyword: res.keyword, articles: res.keywordRelatedArticles}]);
          setKeyword("");
          setSpinning(false);
          setTotalArticlesCount(res.articlesCount);
          setKeywordInputError(false);
        }
      })
    }
  }

  const handleRemoveArticleFromSelected = (id) => {
    $.ajax({
      type: 'DELETE',
      url: `${basePath}`,
      dataType: 'json',
      data: { article_id: id },
      success: function(res){
        const removedArticleId = res.removed_article.article_id;
        removeArticleFromSuggested(removedArticleId);
        setTotalArticlesCount(res.articlesCount);
      }
    })
  }

  const removeKeywordAndArticles = (keywordId) => {
    $.ajax({
      type: 'DELETE',
      url: `${basePath}/remove_keyword_and_searched_articles`,
      dataType: 'json',
      data: { keyword_id: keywordId },
      success: function(res){
        const keywordId = res.keyword_id;
        const filteredKeywordSections = keywordSearchedSections.filter((section) => section.keyword.id !== keywordId);
        setKeywordSearchedSections(filteredKeywordSections);
        setTotalArticlesCount(res.articlesCount);
      }
    })
  }

  const handleAddArticleById = () => {
    setErrorMessage(null)
    setSpinning(true);
    $.ajax({
      type: 'POST',
      url: `${basePath}/`,
      dataType: 'json',
      data: {article_id: articleId, collection_id: collectionId, state: 'approved'},
      success: function(res){
        setSelectedArticles(res.collectionArticles);
        setArticleId("");
        setSpinning(false);
        setTotalArticlesCount(res.articlesCount);
      },
      error: function(res) {
        setArticleId("");
        setSpinning(false);
        setErrorMessage(res.responseJSON.errorMessage)
      }
    })
  }

  const removeArticleFromSuggested = (articleId) => {
    const newArticleList = selectedArticles.filter((article) => article.id !== articleId);
    setSelectedArticles(newArticleList);
  }

  const renderArticlesAddedToCollection = () => {
    return (
      <div className="articles-container">
        {selectedArticles.map((article) => {
          const { id, published_at, type, title, authors } = article;
          return (
            <CollectionArticleCard
              key={id}
              id={id}
              published_at={published_at}
              type={type}
              title={title}
              authors={authors}
              handleRemoveArticle={handleRemoveArticleFromSelected}
              basePath={basePath}
            />
          )
        })}
      </div>
    )
  }

  const renderSpinner = () => {
    if (spinning) {
      return <img className="spinner" src='https://public.cureus.com/ajax-loader.gif'/>
    }
  }

  return (
    <div className="row">
      <div className="large-12 columns">
          <div className="collections-article">

          <div className="article-search-section">
            <div className="row">
              <div className="large-12 columns">
                <small>Enter article ID number here.</small>
              </div>
            </div>
            <div className="row">
              <div className="large-3 medium-6 small-9 columns">
                <input className="article-input" type="text" onChange={(e) => setArticleId(e.target.value)} value={articleId} />
              </div>
              <div>
                <a className="button medium" onClick={handleAddArticleById}>ADD</a>
              </div>
            </div>
          </div>

          <br/>
          {errorMessage && 
          <>
            <div className="error-message-container">
              <span>
                {errorMessage}
              </span>
              
            </div>
            <br/>
          </>
          }

          <div className="article-list-section">
            <div className="row">
              {collectionType !== 'GuestEditorCollection' && <p className="list-header">Suggested articles based on collection keywords <b>({totalArticlesCount})</b>:</p>}
              {renderSpinner()}
              {renderArticlesAddedToCollection()}
            </div>
          </div>

          {collectionType !== "UserCollection" &&
            <div>
              {keywordSearchedSections.map((section, idx) => {
                return (
                  <KeywordSearchedArticles
                    key={idx}
                    keywordId={section.keyword.id}
                    keywordBubble={section.keyword.name}
                    keywordArticles={section.articles}
                    basePath={basePath}
                    removeKeywordAndArticles={removeKeywordAndArticles}
                    setTotalArticlesCount={setTotalArticlesCount}
                  />
                )
              })}
            </div>
          }

          {collectionType !== "UserCollection" && collectionType !== 'GuestEditorCollection' &&
            <div className="keyword-search-section">
              <p>Search for more articles based on keywords:</p>
              <div className="row">
                <div className="large-3 medium-6 small-9 columns">
                  <input className="article-input" type="text" placeholder="Enter keyword" onChange={(e) => setKeyword(e.target.value)} value={keyword} />
                  {keywordInputError && <small className="input-error">Please type a keyword.</small>}
                </div>
                <div>
                  <a className="button medium" onClick={getSearchedKeyWordArticles}>Search</a>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </div>
  )
}

export default Main;