import React from 'react';

const TitleLegendsNumbering = () => {

  return (
    <React.Fragment>
      <h1>Titles, Legends and Numbering</h1>

      <ul className='disc'>
        <li>All media items are automatically numbered. Do not include the number in the title.</li>
        <li>Figures or tables containing acronyms, initialisms or labels must include a full list of definitions in the legend field.</li>
        <li>Reference citations are not permitted in figure, video, or table titles. They must be placed in the legend field or directly within the figure or table.</li>
        <li>Capitalization and punctuation for media titles and legends must be consistent throughout the article.</li>
        <li>Media legends may contain a maximum of 2,000 characters (including spaces).</li>
      </ul>
    </React.Fragment>
  )

}

export default TitleLegendsNumbering;