import React from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';
import QueueInfo from './QueueInfo'
import { addColor } from './UtilityFunctions';
import PerformanceSection from './Performance/PerformanceSection';

const Snapshot = ({userEmail, checkboxObj}) => {
  const currentSnapshotTableColumns = [
    checkboxObj,
    {
      title: 'Editor',
      data: 'full_name',
      render: (row) => {
        return `<a class='email' target='_blank' href='/admin/users/${row.editor_id}'>${row.full_name}</a>`;
      }
    },
    { title: 'Specialty', data: 'specialty_name' },
    { title: 'Awaiting decision', data: 'awaiting', width: '200px' },
    { title: 'Contributions this month', data: 'completed' }
  ];

  const specialtyTableColumns = [
    checkboxObj,
    { title: 'Specialty', data: 'specialty_name' },
    { title: '# Unclaimed Articles', data: 'unclaimed', tooltip: 'Total unique unclaimed articles matched with specialty' },
    { title: '# Unclaimed Articles > 48h', data: 'unclaimed_over_48_hours' },
    { title: '# Editors matched With > 48h', data: 'matches_for_unclaimed_over_48_hours' }
  ];

  const rowRenderCallback = (row, data) => {
    colorizeAwaiting(data, row)
    colorizeContributions(data, row)
  }

  const colorizeAwaiting = (data, row) => {
    let cssClass;
    let count = data[3]
    if(count >= 6) {
      cssClass = 'circle red'
    } else if (count >= 3 && count <= 5) {
      cssClass = 'circle orange'
    }
    if (cssClass) {
      addColor(count, row, cssClass, 3)
    }
  }

  const colorizeContributions = (data, row) => {
    const currentDate = new Date();
    const dayOfMonth = currentDate.getDate();

    if(dayOfMonth < 15) {
      return;
    }

    let cssClass;
    let count = data[4]
    if(count >= 0 && count <= 3) {
      cssClass = 'circle red'
    } else if (count >= 4 && count <= 7) {
      cssClass = 'circle orange'
    }
    if (cssClass) {
      addColor(count, row, cssClass, 4)
    }
  }

  const disableNoMatchesCheckbox = (data, row) => {
    if(row[4] === 0) {
      data.children[0].children[0].setAttribute('disabled', true)
    }
  }

  return (
    <>
      <QueueInfo/>
      <DynamicJqueryTable
        columnsConfig={currentSnapshotTableColumns}
        path="/admin/ae_dashboards/current_snapshot"
        tableId="current-snapshot-table"
        tableTitle="Current Snapshot"
        noDatepicker={true}
        rowRenderCallback={rowRenderCallback}
        userEmail={userEmail}
        showCsv={true}
        showCheckBoxes={true}
      />
      <DynamicJqueryTable
        columnsConfig={specialtyTableColumns}
        path="/admin/ae_dashboards/unclaimed_specialty"
        tableId="unclaimed-specialty-table"
        tableTitle="Unclaimed article broken down by specialty"
        noDatepicker={true}
        userEmail={userEmail}
        showCheckBoxes={true}
        rowRenderCallback={disableNoMatchesCheckbox}
      />
      <PerformanceSection/>
    </>
  )
};

export default Snapshot;