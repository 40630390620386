var createReactClass = require('create-react-class');
import React from 'react';
import Slider from './Slider';
import Email from './Email';
import OriginalArticleStep from './OriginalArticleStep';
import BlockOrDesk from './BlockOrDesk';
import Deferral from './Deferral';
import { Cureus } from '../../Cureus';

const Modal = createReactClass({
  getInitialState: function() {
    return ({
      criteria: {
        references: 0,
        media_formatting: 0,
        spelling_grammar: 0,
        author_affiliations: 0,
        missing_acronyms: 0,
        text_formatting: 0,
        misc: 0
      },
      language_quality: null,
      requestType: this.props.rating ? "put" : "post",
      tier: '',
      total_rating: 0,
      price: null,
      sendEmail: false,
      deferralMessage: null,
      oa_score: 0,
      isOriginalArticle: this.props.isOriginalArticle,
      oaStats: {},
      reasons: this.props.reasons,
      blockReasons: this.props.blockReasons,
      block: !this.props.isOriginalArticle,
      deskReject: !this.props.isOriginalArticle,
      blockOrDeskItems: {},
      deferralItems: {},
      drawerReasons: [],
      prev: ''
    })
  },

  componentDidMount: function() {
    this.handleInitialState()
  },

  fetchOaStats: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      url: this.props.oaPath,
      success: function(data) {
        // need to set the initial score m8
        self.setState({oaStats: data.stats, oa_score: data.stats.initialScore})
      }
    });
  },

  handleInitialState: function() {
    Cureus.Slider.init(this.saveValue)
  },

  initializeCKEditor: function(defaultValue) {
    new Cureus.CKEditor('reject-request-textarea',  {  editor_type: 'deferral',
                                                      ckeditor: {height: 300},
                                                      change_listener: "#reject-request-textarea",
                                                      validate_button: "[data-button-type=submit]",
                                                      placeholderText: defaultValue
                                                    })

  },


  renderBadgeText: function() {
    let flag = this.props.flag
    if (flag === "Cureus") {
      return "Cureus Editing Suggested"
    } else {
      return "3rd Party Suggested"
    }
  },

  handleNext: function(e) {
    var tier = e.target.name
    if(tier === 'next') {
      if(this.state.isOriginalArticle) {
        this.setState({isOriginalArticle: false, block: true, deskReject: false, prev: this.setPrev()})
      } else if(this.state.block) {
        this.setState({isOriginalArticle: false, block: false, deskReject: true, prev: this.setPrev()})
      } else if(this.state.deskReject) {
        this.setState({isOriginalArticle: false, block: false, deskReject: false, deferralView: true, prev: this.setPrev()})
      } else {
        this.setState({block: false, deskReject: false, deferralView: true, prev: this.setPrev()})
      }
    } else if (tier === 'back') {
      if(this.state.deferralView) {
        this.setState({deferralView: false, deskReject: true, block: false, isOriginalArticle: false, prev: "deskReject"})
      } else if(this.state.deskReject) {
        this.setState({deferralView: false, block: true, deskReject: false, isOriginalArticle: false, prev: "block"})
      } else if(this.state.block) {
        this.setState({block: false, deskReject: false, isOriginalArticle: true, prev: "isOriginalArticle"})
      } else {
        this.setState({deferralView: this.state.prev === 'deferralView', block: this.state.prev === 'block', deskReject: this.state.prev === 'deskReject', isOriginalArticle: this.state.prev === "isOriginalArticle", language_quality: false})
      }
    } else {
      let reasons = ""
      let drawerReasons = []
      document.querySelectorAll('.fail').forEach(function(node) {
        let reason = node.dataset.reason
        if(reason) {
          reasons = reasons + `<p>${reason}</p>`
        }
        let drawerReason = node.dataset.drawerreason
        if(drawerReason) {
          drawerReasons.push(drawerReason)
        }
      })
      this.setState({tier: tier, isOriginalArticle: false, block: false, deskReject: false, deferralView: false, drawerReasons: drawerReasons, prev: this.setPrev()}, function() {
        if(tier === 'free' || tier === 'select') {
          this.updateNonCriteria('language_quality', true)
        } else {
          this.initializeCKEditor(reasons)
        }
      })
    }
  },

  setPrev: function() {
    if(this.state.isOriginalArticle) {
      return "isOriginalArticle"
    } else if(this.state.block) {
      return "block"
    } else if(this.state.deskReject) {
      return "deskReject"
    } else if (this.state.deferralView) {
      return "deferralView"
    }
  },

  blockOrDeskItemsAdd: function(index, status) {
    var newObj = this.state.blockOrDeskItems
    newObj[index] = status
    this.setState({blockOrDeskItems: newObj})
  },

  deferralItemsAdd: function(index, status) {
    var newObj = this.state.deferralItems
    newObj[index] = status
    this.setState({deferralItems: newObj})
  },

  handleCheck: function(score) {
    var oa_score = this.state.oa_score + parseInt(score)
    if (oa_score < 0) {
      oa_score = 0
    }
    this.setState({oa_score: oa_score})
  },

  renderBadge: function() {
    var flag = this.props.flag
    if (flag === "Cureus" || flag === "3rd Party") {
      return (
        <div className="article-tier-wrap">
          <div className="article-tier-badge professional-required">
            <div className="left-nubbins nubbins professional-required">
              {this.renderBadgeText()}
            </div>
            <div className="nubbins professional-required right-nubbins"></div>
          </div>
        </div>
      )
    }
  },

  saveValue: function(e) {
    var newCriteria = Object.assign(this.state.criteria, {});
    newCriteria[e.target.name] = e.target.value;
    this.setState({ criteria: newCriteria }, function() {this.updateTotal()})
  },

  handleChange: function(e) {
    this.setState({deferralMessage: e.target.value})
  },

  updateNonCriteria: function(e, noElm) {
    var self = this;
    var name;
    if(noElm) {
      name = e;
    } else {
      name = e.target.name
    }
    name === 'language_quality' ? self.setState({language_quality: true}, function() {Cureus.Slider.init(self.saveValue)}) : self.setState({tier: name, language_quality: false, total_rating: 0, criteria: {
      references: 0,
      media_formatting: 0,
      spelling_grammar: 0,
      author_affiliations: 0,
      missing_acronyms: 0,
      text_formatting: 0,
      misc: 0
    }}, function() {
      if (typeof CKEDITOR === 'object' && CKEDITOR.instances["reject-request-textarea"]) {
        CKEDITOR.instances["reject-request-textarea"].destroy()
      }
      if(name === 'block' || name === 'desk') {
        self.initializeCKEditor();
      }
    })
  },

  updateSuggestion: function() {
    if (this.state.total_rating >= 5) {
      this.setState({tier: 'select'})
    }  else if (this.props.upfrontPricing && this.state.total_rating < 5) {
      this.setState({tier: 'upfront'})
    } else {
      this.setState({tier: 'free'})
    }
  },

  updateTotal: function() {
    var newTotal = Object.values(this.state.criteria).reduce(function(accum, val) {
      return parseInt(accum) + parseInt(val);
    });
    this.setState({total_rating: newTotal, price: null}, function() {
      this.updateSuggestion()
    });
  },

  handleSubmit: function(e) {
    e.target.className += " disabled"
    var deferralMessage;
    if (typeof CKEDITOR === 'object' && CKEDITOR.instances["reject-request-textarea"]) {
      deferralMessage = CKEDITOR.instances['reject-request-textarea'].getData();
    }
    this.setState({deferralMessage: deferralMessage}, function() {
      if (this.state.tier === 'block' || this.state.tier === 'desk') {
        this.createOrUpdate('PUT')
      } else {
        this.createOrUpdate('POST')
      }
    })
  },

  renderProfessionalSelector: function() {
    if(!this.props.compOrChannel) {
      return (
        <div style={{display: 'inline'}}>
          <input id='professional' name='professional' type='radio' onChange={this.updateNonCriteria}  checked={this.state.tier === 'professional'}/>
          <label htmlFor='professional' style={{display: 'inline-block'}}>Defer for 3rd Party</label>
        </div>
      )
    }
  },

  renderSelections: function() {
    if(this.state.tier === 'professional') {
      return (
        <div className="selectors">
          {this.renderProfessionalSelector()}
        </div>
      )
    } else if (this.state.tier === 'content') {
      return (
        <div className="selectors">
          <div style={{display: 'inline'}}>
            <input id='content-def' name='content' type='radio' onChange={this.updateNonCriteria}  checked={this.state.tier === 'content'}/>
            <label htmlFor='content-def' style={{display: 'inline-block'}}>Defer for Content</label>
          </div>
        </div>
      )
    } else if(this.state.tier === 'free' || this.state.tier === 'select') {
      return (
        <div className="selectors">
          <input id='language_yes' name='language_quality' type='radio' onChange={this.updateNonCriteria} checked={this.state.language_quality === true}/>
          <label htmlFor='language_yes' style={{display: 'inline-block'}}> Acceptable </label>
        </div>
      )
    } else if(this.state.tier === 'block'){
      return (
        <div className="selectors">
          <input id='block' name='block' type='radio' onChange={this.updateNonCriteria}  checked={this.state.tier === 'block'}/>
          <label htmlFor='block' style={{display: 'inline-block'}}>Block Article</label>
        </div>
      )
    } else {
      return (
        <div className="selectors">
          <input id='desk' name='desk' type='radio' onChange={this.updateNonCriteria}  checked={this.state.tier === 'desk'}/>
          <label htmlFor='desk' style={{display: 'inline-block'}}>Desk Reject</label>
        </div>
      )
    }
  },

  createOrUpdate: function(requestType) {
    var self = this;
    $.ajax({
      type: requestType,
      url: self.setPath(),
      data: self.formatParams(),
      dataType: "json",
      success: function(res) {
        window.location.reload();
      },
      error: function(res) {
        Cureus.Flash.alert("Something went wrong")
      }
    });
  },

  setPath: function() {
    if(this.state.tier === 'block' || this.state.tier === 'desk') {
      return this.props.blockArticlePath
    } else {
      return "/admin/articles/" +  this.props.articleId + "/editor_article_ratings"
    }
  },

  formatParams: function() {
    if(this.state.tier === 'block') {
      return {comments: this.state.deferralMessage, block_type: 'Blocked', ban_reason: this.setBannableOffsense(), oa_score: this.state.oa_score}
    } else if (this.state.tier === 'desk') {
      return {comments: this.state.deferralMessage, block_type: 'DeskRejected', oa_score: this.state.oa_score}
    } else {
      var tier = this.state.tier === "upfront" ? "free" : this.state.tier
      return {editor_article_rating: Object.assign(this.state.criteria, {tier: tier, total_rating: this.state.total_rating, language_quality: this.state.language_quality}), adjustment: this.props.editorCheckAdjustment.type, deferralMessage: this.state.deferralMessage, oa_score: this.state.oa_score, drawerReasons: JSON.stringify(this.state.drawerReasons)}
    }
  },

  setBannableOffsense: function() {
    let bannableOffense;
    let self = this;
    // assumes that only one bannable offense reason will be selected, if two
    // is selected then the higher id reason will be the bannable offense
    for(let reason in this.state.reasons) {
      let reasonObj = this.state.reasons[reason]
      if (self.bannableOffenseText()[reasonObj.id] && reasonObj.checked) {
        bannableOffense = self.bannableOffenseText()[reasonObj.id]
      }
    }

    return bannableOffense;
  },

  bannableOffenseText: function() {
    // staging reason ids    - 60: "plagiarism", 61: "author listed as reviewer", 67: "new account created after suspension"
    // production reason ids - 65: "plagiarism", 66: "author listed as reviewer", 72: "new account created after suspension"
    return {
      65: "plagiarism",
      66: "author listed as reviewer",
      72: "new account created after suspension"
    }
  },

  fetchPrice: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      url: self.props.getPricePath,
      dataType: 'json',
      data: {total_rating: self.state.total_rating},
      success: function(res) {
        self.setState({
          price: res.price
        })
      }
    });
  },

  renderSliders: function() {
    if(this.state.language_quality === true && !this.props.compOrChannel) {
      return(
        <div>
          <br/>
          <div className='siq-slider-wrap editor-article-rating'>
            <Slider title={'Author affiliations and names complete and formatted correctly?'} criterion={'author_affiliations'} currentValue={this.state.criteria.author_affiliations}  max={"2"}/>
            <Slider title={'Acronyms/initialisms spelled out prior to first use?'} criterion={'missing_acronyms'} currentValue={this.state.criteria.missing_acronyms} max={"2"}/>
            <Slider title={'Spelling, grammar, syntax and punctuation errors?'} criterion={'spelling_grammar'} currentValue={this.state.criteria.spelling_grammar} max={"4"}/>
            <Slider title={'Text formatted correctly? (subheadings, spaces, etc.)'} criterion={'text_formatting'} currentValue={this.state.criteria.text_formatting} max={"2"}/>
            <Slider title={'Media quality, formatting, labels and placement'} criterion={'media_formatting'} currentValue={this.state.criteria.media_formatting} max={"5"}/>
            <Slider title={'Reference formatting and accuracy'} criterion={'references'} currentValue={this.state.criteria.references} max={"7"}/>
            <Slider title={'Miscellaneous'} criterion={'misc'} currentValue={this.state.criteria.misc} max={"12"}/>
          </div>
        </div>
      )
    }
  },

  renderEmailModalContent: function() {
    return (
      <div>
        <Email
        recipientEmail={this.props.articleSubmitter}
        sendQuoteEmailPath={this.props.sendQuoteEmailPath}
        articlePrice={this.props.articlePrice}
        />
      </div>
    )
  },

  handleEmailClick: function() {
    this.setState({sendEmail: true})
  },

  submitButton: function() {
    var disabled = ''
    if(!this.state.tier) {
      disabled = 'disabled'
    }
    return <a className={"button primary " +  disabled} onClick={this.handleSubmit}>Submit</a>
  },

  displayTier: function() {
    if (!this.state.tier) {return;}
    switch (this.state.tier) {
      case "select":
        return "Requires Paid Services"
      case "professional":
        return "Requires 3rd Party Editing"
      case "upfront":
        return "Qualified for Upfront Editing"
      case "content":
        return "Defer for Content"
      case "desk":
        return "Desk Reject"
      case "block":
        return "Block"
      default:
        return "Free"
    }
  },

  displayPrice: function() {
    if(this.state.tier === 'select' && this.state.price) {
      return (
        <div>
          <h4 style={{textAlign: 'right'}}>Estimate: ${this.state.price}</h4>
        </div>
      )
    } else if (this.state.total_rating > 4 && this.state.language_quality === true) {
      return (
        <div className="calculate-price right">
          <a className="button mini" onClick={this.fetchPrice}>Calculate Price</a>
        </div>
      )
    } else {
      return <br/>
    }
  },

  renderCalculatePrice: function() {
    if (this.state.total_rating > 4 && this.state.language_quality === true) {
      return <a className="button mini" onClick={this.fetchPrice}>Calculate Price</a>
    }
  },

  displayEmailQuoteButton: function() {
    if (this.state.tier === 'upfront') {
      return (
        <a className="button tiny special right" onClick={this.handleEmailClick}>Email Quote</a>
      )
    }
  },

  renderWarning: function() {
    if (this.props.editorCheckAdjustment.warning) {
      var score = "1-4"
      if (this.props.editorCheckAdjustment.type === 'ithenticate/media' && this.props.editorCheckAdjustment.deferralCount === 1) {
        score = "4"
      } else if (this.props.editorCheckAdjustment.type === 'references' || this.props.editorCheckAdjustment.type === 'authors') {
        score = "0-4"
      }
      return (
        <div className="editor-check-warning-wrap">
          <span className="warning">{this.props.editorCheckAdjustment.warning}</span>
          <span className="info">{"(a score of " + score + " will be deferred for payment)"}</span>
        </div>
      )
    }
  },

  renderPoints: function() {
    var points;
    var addPoints;
    var cutOff = this.props.editorCheckAdjustment.deferralCount === 1 ? 3 : 0
    if (this.state.language_quality === true && !this.props.compOrChannel) {
      points = 'Points: ' + this.state.total_rating
      var diff = this.state.total_rating > cutOff ? 5 - this.state.total_rating : 0;
      if (this.props.editorCheckAdjustment.warning && diff > 0 && diff < 5) {
        addPoints = (
        <span className="editor-check-add-points">&nbsp;(+{diff})</span>
        )
      }
    }
    if (points) {
      return  (
        <h4 className='reg pull-right'>
          {points}
          {addPoints}
        </h4>
      )
    }
  },

  renderProfessionalPaid: function() {
    if(this.props.badge) {
      return <div className="paid-badge" dangerouslySetInnerHTML={{__html: this.props.badge}}/>
    }
  },

  renderChannelBadge: function() {
    if(this.props.channelBadge) {
    return (
        <div className="channel-badge-container">
          <div className="channel-badge" dangerouslySetInnerHTML={{__html: this.props.channelBadge}}/>
          <h6 className="channel-title reg">{this.props.title}</h6>
        </div>
      )
    }
  },

  renderIthenticates: function() {
    var self = this;
    return self.props.ithenticates.map(function(obj, i) {
      return <h6 key={i} className='reg' dangerouslySetInnerHTML={{__html: obj}}></h6>
    })
  },

  renderDeferralReasonText: function(checked) {
    if ( this.checkForNoDeferralReason()) { return }
    var ckeditor = CKEDITOR.instances['reject-request-textarea'];

    var reasonDescriptions = ['<div id="deferral-reasons-list">'];
    var completeText = this.state.deferralMessage;
    if (checked)
    {
      for (var reasonId in this.state.reasons) {
        var currentReason = this.state.reasons[reasonId];
        if (currentReason.checked) {
          reasonDescriptions.push(
            "<p>" + currentReason.description.replace("{similarity_report_url}", this.props.ithenticateUrl) + "</p>"
          )
        }
      }
      reasonDescriptions.push('</ol>')
      var oldText = ckeditor.getData(); // includes everything, including user input
      var completeText = reasonDescriptions.join(" ") + oldText
    }
    if (ckeditor.getData() == '') {
      ckeditor.setData(completeText + "<p></p>")
    }
    else {
      ckeditor.setData(completeText);
    }
  },

  handleCheckboxToggle: function(e, state) {
    var self = this;
    self.handleReasonRemoval();
    let reasons = state === 'block' ? self.state.blockReasons : self.state.reasons;
    var oldState = reasons;
    var reasonId = e.target.name.slice(11).slice(0, -1); // parse id from 'reason_ids[###]'
    oldState[reasonId].checked = !oldState[reasonId].checked // toggle the checked value
    self.setState({reasons: oldState}, function() {
      self.renderDeferralReasonText(true); // render deferrals again which takes into account which is checked
    });
  },

  handleReasonRemoval: function() {
    // remove old reasons from ckeditor
    var ck = CKEDITOR.instances["reject-request-textarea"]
    var deferralReasonsContent = ck.document.getById("deferral-reasons-list")
    if (deferralReasonsContent) {
      deferralReasonsContent.remove()
    }
  },

  checkForNoDeferralReason: function() {
    var checked = 0
    for (var reasonId in this.state.reasons) {
      var currentReason = this.state.reasons[reasonId];
      if (currentReason.checked) {
        checked +=1
      }
    }
    if (checked == 0) {return true}
  },

  renderCheckboxes: function(state) {
    var checkboxes = [];
    var self = this;
    let reasons = state === 'block' ? self.state.blockReasons : self.state.reasons;
    for (var reasonId in reasons) {
      var currentReason = reasons[reasonId]
      checkboxes.push(
        <div className='columns large-4 end'>
          <div className='checkbox-container'>
            <label htmlFor={'reason_ids[' + reasonId + ']'}>
              <input name={'reason_ids[' + reasonId + ']'} id={'reason_ids[' + reasonId + ']'} type='checkbox' value={currentReason.checked} onChange={(event) => self.handleCheckboxToggle(event, state)} checked={currentReason.checked} disabled={self.state.disabled} />
              {currentReason.label}
            </label>
          </div>
        </div>
      )
    }
    return checkboxes.chunk(3).map(function(group) {
      return (
        <div className='row'>
          {group}
        </div>
      )
    })
  },


  deferralMessageTextarea: function() {
    if(this.state.tier === 'content') {
      return (
        <div>
          <textarea id='reject-request-textarea' />
          <br/>
        </div>
      )
    } else if(this.state.tier === 'block') {
      return (
        <div>
          <p>Please add comments indicating why this article has been blocked. These comments will be sent to the submitting author.</p>
          {this.renderCheckboxes('block')}
          <textarea id='reject-request-textarea'/>
          <br/>
        </div>
      )
    } else if(this.state.tier === 'desk') {
      return (
        <div>
          <p>Please add comments indicating why this article has been desk rejected. These comments will be sent to the submitting author.</p>
          {this.renderCheckboxes('desk')}
          <textarea id='reject-request-textarea'/>
          <br/>
        </div>
      )
    } else if(this.state.tier == 'professional') {
      return (
        <div>
          <p>Please indicate any additional revisions needed prior to resubmission that may not be corrected by third party professional editing. (Optional)</p>
          <textarea id='reject-request-textarea'/>
          <br/>
        </div>
      )
    }
  },

  // need to incorporate all the new piss here
  renderPreviousDeferralReason: function() {
    var message;
    if(this.props.previousDeferralReason === 'both') {
      message = "media and text duplication)"
    } else if(this.props.previousDeferralReason === 'media') {
      message = "media)"
    } else {
      message = "text duplication)"
    }
    return (
    <h3 className='reg no-margin'>{"Editor Check (Previously deferred due to " + message}</h3>
    )
  },

  renderTitle: function() {
    if(this.props.rating) {
      if(this.props.rating.tier === 'ithenticate') {
        return this.renderPreviousDeferralReason()
      } else if (this.props.rating.tier === 'content') {
        return <h3 className='reg no-margin'>Editor Check (Previously deferred for content)</h3>
      } else if(this.props.rating.tier === 'professional') {
        return (
          <h3 className='reg no-margin'>Editor Check (Previously deferred for 3rd party editing)</h3>
        )
      }
    }
    return (
      <h3 className='reg no-margin'>Editor Check</h3>
    )
  },

  renderExpired: function() {
    if(this.props.expired) {
      return (
        <div className='columns large-6 medium-6'>
          <h6 className='expired-warning'>This article was previously submitted deferred and expired. If the ithenticate results are acceptable, please defer with a score of 5 misc points (Preferred Editing required).</h6>
        </div>
      )
    }
  },

  updateDisabled: function(disabled) {
    this.setState({isDisabled: disabled})
  },

  renderModalContents: function() {
    if (this.state.isOriginalArticle){
      return (
        <OriginalArticleStep
          fetchOaStats={this.fetchOaStats}
          handleNext={this.handleNext}
          oa_score={this.state.oa_score}
          handleCheck={this.handleCheck}
          oaStats={this.state.oaStats}
        />
      )
    } else if(this.state.block) {
      return (
        <BlockOrDesk
          handleNext={this.handleNext}
          renderWarning={this.renderWarning}
          blockOrDeskItems={this.state.blockOrDeskItems}
          blockOrDeskItemsAdd={this.blockOrDeskItemsAdd}
          isOriginalArticle={this.props.isOriginalArticle}
          renderIthenticates={this.renderIthenticates}
          currStep={"block"}
        />
      )
    } else if (this.state.deskReject) {
      return (
        <BlockOrDesk
          handleNext={this.handleNext}
          renderWarning={this.renderWarning}
          blockOrDeskItems={this.state.blockOrDeskItems}
          blockOrDeskItemsAdd={this.blockOrDeskItemsAdd}
          isOriginalArticle={this.props.isOriginalArticle}
          renderIthenticates={this.renderIthenticates}
          deskItems={this.state.deskItems}
          currStep={"desk"}
        />
      )
    } else if(this.state.deferralView) {
      return (
        <Deferral
          isOriginalArticle={this.props.isOriginalArticle}
          handleNext={this.handleNext}
          renderIthenticates={this.renderIthenticates}
          renderWarning={this.renderWarning}
          deferralItems={this.state.deferralItems}
          deferralItemsAdd={this.deferralItemsAdd}
          ithenticatePercent={this.props.ithenticatePercent}
          ithenticateUrl={this.props.ithenticateUrl}
          compOrChannel={this.props.compOrChannel}
          tier={this.state.tier}
        />
      )
    } else {
      return (
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className="modal-content">
            <div className='row collapse'>
              <div className='columns large-12 medium-12'>
                { this.renderTitle() }
                { this.renderChannelBadge() }
                { this.renderBadge() }
                { this.renderWarning() }
              </div>
              {this.renderExpired()}
              <div className='columns large-6 medium-6'>
                { this.renderPoints() }
              </div>
            </div>
            <br/>
            {this.renderIthenticates()}
            {this.renderProfessionalPaid()}
            {this.renderSelections()}
            {this.renderSliders()}
            {this.deferralMessageTextarea()}
            <div className="tier-display">
              <h4>{this.displayTier()}</h4>
            </div>
            {this.displayEmailQuoteButton()}
            {this.displayPrice()}
          </div>
          <div className="modal-footer">
            <div>
              <a className={"button secondary"} name="back" onClick={this.handleNext}>Go back</a>
              <a href="#" className="button secondary cancel-modal"> Cancel </a>
              {this.submitButton()}
            </div>
          </div>
        </div>
      )
    }
  },

  render: function() {
    if (this.state.sendEmail) {
      return (
        <div className="reveal-modal medium" id="editor-article-rating-modal" data-reveal >
          <div className="modal-body">

            <div className='modal-close'>
              <a href='#' className='cancel-modal'>
                <img src="https://public.cureus.com/close_x.png"/>
              </a>
            </div>

            <div className="modal-content">
              <div className="email-modal-content">
                {this.renderEmailModalContent()}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="reveal-modal medium" id="editor-article-rating-modal" data-reveal >
          {this.renderModalContents()}
        </div>
      )
    }
  }
})

export default Modal;