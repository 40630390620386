import React, { useState, useRef, useEffect } from 'react';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';
import ChannelCampaignModal from './ChannelCampaignModal';

const Index = ({fetchCampaignsPath, channelId}) => {
  const [spinning, setSpinning] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsCount, setCampaignsCount] = useState(null);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    $('#channel-campaigns-table').DataTable().fnDestroy();
    setSpinning(true)
    $.ajax({
      type: 'GET',
      dataType: "JSON",
      url: fetchCampaignsPath,
      success: function(data) {
        setSpinning(false)
        setCampaigns(data.campaigns)
        setCampaignsCount(data.campaigns.length)
        populateTable(data.campaigns)
      }
    });
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.sent ? '<span><i class="fa fa-rocket"></i></span>' : '<span><i class="fa fa-pencil-alt"></i></span>',
        `<a href=${row.previewPath} target='_blank'>${row.title}</a>`,
        row.created_at,
        row.sent_at,
        row.recipients_count,
        renderButton(row.sent, row.campaign_id, row.previewPath),
        row.destroyPath,
        row.sent,
        row.created_at_sort,
        row.sent_at_sort,
        row.campaign_id
      ], false)
    )
  }
  
  const renderButton = (sent, id, previewPath) => {
    if(sent) {
      return (
        `<a class="button secondary tiny" href=${previewPath} target='_blank'>View email</a>`
      )
    } else {
      return (
        `<div class='cureus-split-dropdown-wrap'>
          <a href='#' class='button secondary tiny split' data-cureus-split-dropdown=campaign-actions-${id} >
            <div>Edit</div>
            <span />
          </a>

          <ul id=campaign-actions-${id} class='cureus-split-dropdown'>
            <li>
              <a>Delete</a>
            </li>
          </ul>
        </div>`
      )
    }
  }

  const populateTable = (campaigns) => {
    var dt = $("#channel-campaigns-table").DataTable({
      columns: [
        {class: 'status-cell'},
        {width: '475px'},
        {width: '180px'},
        {width: '180px'},
        null,
        {class: 'button-cell'},
        null,
        null,
        null,
        null,
        null
      ],
      language: {
        "searchPlaceholder": "Search...",
        "search": ""
      },
      columnDefs: [
        {orderable: false, targets: [0, 5]},
        {targets: [6, 7, 8, 9, 10], visible: false},
        {targets: [2], orderData: [8]},
        {targets: [3], orderData: [9]},
      ],
      dom: '<"row"<"small-4 small-offset-8 columns"f>>tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
      "rowCallback": function(row, data) {
        if(!data[7]) {
          let button = row.children[5].children[0].children[1].children[0].children[0];
          $(button).off().on('click', function() {
            handleDelete(data[6])
          })
          $(row.children[5].children[0].children[0]).off().on('click', function() {
            openCampaignModal(data[10])
          })
        }
      }
    })
    if (!campaigns) {
      campaigns = []
    }
    dt.api().clear();
    for (var i = 0; i < campaigns.length; i++) {
      let row = campaigns[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[2, 'desc']])
    dt.fnDraw()
    Cureus.SplitButton.init();
  }

  const handleDelete = (path) => {
    $.ajax({
      type: 'DELETE',
      dataType: "JSON",
      url: path,
      success: function() {
        fetchData()
        Cureus.Flash.success('The campaign has been deleted.')
      }
    });
  }

  const openCampaignModal = (campaignId) => {
    Modal.open({
      children: <ChannelCampaignModal 
        campaignId={campaignId}
        channelId={channelId}
        fetchData={fetchData}
      />
    });
  }

  if (spinning) {
    return (
      <React.Fragment>
      <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <div className='campaigns-index channel-campaigns'>
          <div className="row channels-index-header">
            <h1>Promotion Email Campaigns
              <span className="channels-h1-blurb reviewers-count">{campaignsCount}</span>
            </h1>
            <p className="campaign-description">Use the promotion tool to generate awareness of your Cureus academic channel within your organization. Create recipient lists and send campaigns featuring calls for articles and invitations to join your internal reviewer list. Recipients can be added individually or via bulk CSV upload. All added recipients are private and will not be shared with Cureus nor receive any emails from Cureus outside of those you send via the promotion tool.</p>
          </div>
            <a className="button primary create-channel-campaign-button" onClick={() => openCampaignModal(null)}><i className='fa fa-pencil-alt' /> Create Campaign</a>
            <table id="channel-campaigns-table" className='table-wrapper'>
            
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Title</th>
                  <th>Created</th>
                  <th>Edited/Sent</th>
                  <th>Recipients</th>
                  <th>Actions</th>
                  <th/>
                  <th/>
                  <th/>
                  <th/>
                  <th/>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </React.Fragment>
    )
  }
}

export default Index;