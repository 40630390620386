var createReactClass = require('create-react-class');
import React from 'react';
import ChannelModal from './ChannelModal'
import Modal from '../Modal';

const Channels = createReactClass({
  getInitialState: function() {
    return({
      subchannels: this.props.subchannels
    })
  },

  openChannelModal: function() {
    Modal.open({
      children: <ChannelModal subchannels={this.props.subchannels} handleChange={this.props.handleChange} submitUpdate={this.props.submitUpdate} resetPage={this.props.resetPage}/>
    })
  },

  innerLabel: function() {
    var checked = [];
    Object.values(this.state.subchannels).forEach(function(ch) {
      if (ch.checked){ checked.push(ch.title) }
    });
    if (checked.length) {
      return (
        <div>
          <div className='cureus-multi-select-placeholder'>
            {checked.join(', ')}
          </div>
          <div className='cureus-multi-select-count'>{checked.length}</div>
        </div>
      )
    } else {
      return (
        <div className='cureus-multi-select-placeholder'>
          <i className='fa fa-filter'/>
          <span>ALL INSTITUTIONS</span>
        </div>
      )
    }
  },
  render: function() {
    return (
      <div className='large-3 large-offset-0 medium-4 medium-offset-2 small-12 columns'>
        <div id='types-multiselect-wrap'>
          <div className='cureus-custom-multi-select-wrap'>
            <div className='cureus-multi-select' onClick={this.openChannelModal}>
              {this.innerLabel()}
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Channels;