var createReactClass = require('create-react-class');
import React from 'react';

const StateHistoryRow = createReactClass({
  getInitialState: function (){
    return {
      open: false
    }
  },

  toggleDrawer: function() {
    this.setState({open: !this.state.open})
  },

  renderCarat: function() {
    if(this.props.message){
      if(this.state.open) {
        return <i className="fa fa-angle-up carat" onClick={this.toggleDrawer}/>
      } else {
        return <i className="fa fa-angle-down carat" onClick={this.toggleDrawer}/>
      }
    }
  },

  drawerColor: function() {
    if(this.props.even) {
      return {background: "#FFFFFF"}
    } else {
      return {background: "#F9F9F9"}
    }
  },

  renderDrawer: function() {
    if(this.state.open) {
      return (
        <tr style={this.drawerColor()}>
          <td className="drawer" colSpan={3} dangerouslySetInnerHTML={{__html: this.props.message}}></td>
        </tr>
      )
    }
  },

  render: function() {
    return(
      <React.Fragment>
        <tr style={this.drawerColor()}>
          <td>{this.renderCarat()}</td>
          <td>{this.props.date}</td>
          <td>{this.props.state}</td>
        </tr>
        {this.renderDrawer()}
      </React.Fragment>
    )
  }
})

export default StateHistoryRow;