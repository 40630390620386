var createReactClass = require('create-react-class');
import React from 'react';

const AdvancedSearchRow = createReactClass({

  getOffset: function() {
    if (this.props.id == 0) {
      return "small-offset-4 medium-offset-2"
    }
  },
  
  renderBooleanOperators: function() {
    if (this.props.id > 0) {
      return(
        <select name="boolean" data-index={this.props.id} onKeyPress={this.props.handleAdvancedSearchEnter} onChange={(e) => this.props.handleChange(this.props.id, e)} value={this.props.filter.boolean}>
          <option value="and">AND</option>
          <option value="or">OR</option>
          <option value="not">NOT</option>
        </select>
      )
    }
  },

  renderSearchableFields: function() {
    return(
      <select name="field" data-index={this.props.id} onKeyPress={this.props.handleAdvancedSearchEnter} onChange={(e) => this.props.handleChange(this.props.id, e)} value={this.props.filter.field}>
        <option value="all">All Fields</option>
        <option value="affiliations">Affiliation</option>
        <option value="authors">Author</option>
        <option value="title">Title</option>
        <option value="body">Body</option>
        <option value="abstract">Abstract</option>
        <option value="keywords">Keywords</option>
      </select>
    )
  },

  renderInputBox: function() {
    return(
      <input name="query" data-index={this.props.id} onKeyPress={this.props.handleAdvancedSearchEnter} onChange={(e) => this.props.handleChange(this.props.id, e)} value={this.props.filter.query} type="text"/>
    )
  },

  renderAddRemoveButtons: function() {
    var self = this;
    return(
      <div className="buttons">
        {self.props.numFilters > 1 && 
          <a href="javascript:void(0)" data-add-fields="true" onClick={self.removeFilter}>
            <i className="fa fa-minus-circle" aria-hidden="true"></i>
          </a>
        }
        {self.props.numFilters - 1 == self.props.id && 
          <a href="javascript:void(0)" data-remove-multi-add="true" onClick={self.props.addRow}>
            <i style={{padding: '1px 0 0 5px'}} className="fa fa-plus-circle" aria-hidden="true"></i>
          </a>
        }
      </div>
    )
  },

  removeFilter: function() {
    this.props.removeRow(this.props.id)
  },
  
  render: function() {
    var self = this
    return(
      <div className="advanced-search-row">
        <div className="small-4 medium-2 large-1 large-offset-1 columns boolean-options">
          {this.renderBooleanOperators()}
        </div>
        <div className={`medium-2 small-8 ${self.getOffset()} columns search-options`}>
          {this.renderSearchableFields()}
        </div>
        <div className="small-10 medium-5 columns search-text">
          {this.renderInputBox()}
        </div>
        <div className="small-2 large-1 columns end buttons-container">
          <div className="medium-7 columns end buttons-container">
            {this.renderAddRemoveButtons()}
          </div>
        </div>
      </div>
    )
  }
})

export default AdvancedSearchRow;