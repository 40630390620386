import React, {useEffect} from 'react';
import VideoModal from './VideoModal';
import Modal from '../Modal';

const VideoImage = ({imgUrl, videoUrl}) => {
  const openModal = () => {
    Modal.open({
      children: <VideoModal
        videoUrl={videoUrl}
      />
    });
  }
  return (
    <img class="video-image" src={imgUrl} onClick={openModal}/>
  )
}

export default VideoImage;