import React, {useEffect} from 'react';

const NewsCard = ({cardDetails, index, visibleIndex, fireAmplitude, setVisibleCardType}) => {

  const relocate = () => {
    fireAmplitude('news', cardDetails.cta_url)
    window.open(cardDetails.cta_url)
  }

  useEffect(() => {
    if (index == visibleIndex) {
      setVisibleCardType("news")
    }
  })

  return (
    <div className={`row featured-content-card ${index == visibleIndex ? '' : 'hide-card'}`}>
      <div className="columns hide-for-small-only medium-6 col-6-image">
        <img src={!cardDetails.preview_image ? cardDetails.large_card_image : cardDetails.preview_image} />
      </div>
      <div className="hide-for-medium-up">
        <img className="small-screen-image" src={cardDetails.content_card_image ? cardDetails.content_card_image : cardDetails.preview_image} />
      </div>
      <div className="columns small-12 medium-6 card-info">
        <p className="ck-editor-content" dangerouslySetInnerHTML={{__html: cardDetails.description}}></p>
        {cardDetails.cta_type == 'button' && <button onClick={() => relocate()}>{cardDetails.cta_text}</button>}
        {cardDetails.cta_type == 'arrow' && 
          <a className="read-more" target="_blank" href={cardDetails.cta_url} onClick={() => fireAmplitude('news', cardDetails.cta_url)}>
            {cardDetails.cta_text}
            <i className="fas fa-arrow-right"></i>
          </a>
        }
      </div>
    </div>
  )
}

export default NewsCard;