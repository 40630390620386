var createReactClass = require('create-react-class');
import React from 'react';

const Content = createReactClass({
  render: function(){
    return (
      <div id="howto-content" className={ "howto-content-" + this.props.currentPageNum }>
        { this.props.currentPage }
      </div>
    );
  }
});

export default Content;