import React, {useState, useEffect} from 'react';
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
import NewTable from './NewTable';
import RejectedApprovedTable from './RejectedApprovedTable';
import DateSelector from '../StatsWidget/DateSelector'

const Main = ({dateRanges, reasons}) => {

  const [activeTab, setActiveTab] = useState(window.location.hash.replace(/#!\//, ''))
  const [applications, setApplications] = useState([]);
  const [dateFrom, setDateFrom] = useState(dateRanges[3].from)
  const [dateTo, setDateTo] = useState(dateRanges[3].to)
  const [selectedRange, setSelectedRange] = useState(dateRanges[3].label);

  useEffect(() => {
    fetchApplications();
  }, [activeTab, dateFrom, dateTo, selectedRange])

  const fetchApplications = () => {
    $.ajax({
      type: "GET",
      url: "/admin/collection_applications",
      dataType: "json",
      data: {state: activeTab, to: dateTo, from: dateFrom},
      success: function(res) {
        setApplications(res.applications);
      }
    })
  }

  const filterData = (from, to, rangeName) => {
    setDateFrom(from)
    setDateTo(to)
    setSelectedRange(rangeName)
  }

  const renderDateSelector = () => {
    return <DateSelector
      from={dateRanges[3].from}
      to={dateRanges[3].to}
      getStats={filterData}
      selectedRange={"Last 6 months"}
      noFloat={true}
      dateRanges={dateRanges}
    />
  }

  const renderNewTable = () => {
    return (
      <NewTable 
        applications={applications}
        fetchApplications={fetchApplications}
        reasons={reasons}
      />
    )
  }

  const renderRejectedApprovedTable = () => {
    return (
      <RejectedApprovedTable 
        applications={applications}
        activeTab={activeTab}
      />
    )
  }

  return (
    <Router hashType={"hashbang"}>
      <div className="row">
        <div className="large-12 columns">
          <div className="cureus-tabs secondary-tabs dashboard homepage">
            <ul className="tabs">
            <Link to="/new">
                <li className={"tab-title " + (activeTab === 'new' ? 'active' : '')} onClick={() => setActiveTab('new')}>
                  <a className="dashboard">PENDING</a>
                </li>
              </Link>
              <Link to="/rejected">
                <li className={"tab-title " + (activeTab === 'rejected' ? 'active' : '')} onClick={() => setActiveTab('rejected')}>
                  <a className="dashboard">REJECTED</a>
                </li>
              </Link>
              <Link to="/approved">
                <li className={"tab-title " + (activeTab === 'approved' ? 'active' : '')} onClick={() => setActiveTab('approved')}>
                  <a className="dashboard">APPROVED</a>
                </li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="large-2 large-offset-10 columns date-picker-container">
          {renderDateSelector()}
        </div>
          <Switch>
            <Route path={['/new']} render={renderNewTable} />
            <Route path={['/approved', '/rejected']} render={renderRejectedApprovedTable} />  
          </Switch>
      </div>
    </Router>
  )
};

export default Main;