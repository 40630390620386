import React, { useEffect } from 'react';


const CureusUpdatesHistoryTable = ({cureusUpdates}) => {
  
  useEffect(() => {
    populateTable()
  }, [])

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.created_at,
      ], false)
    )
  }

  const setColumns = () => {
    return [
      {width: '135px'},
    ]
  }

  const populateTable = () => {
    var dt = $("#cureus-updates-table").DataTable({
      columns: setColumns(),
      "bAutoWidth": false,
      searching: false,
      dom: '<"row "<f>>tr<"row pagination-row"<"small-12 columns pagination-details" <"pull-left page-nums"p><"pull-left page-dropdown"l>>>',
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      }
    })
    if (!cureusUpdates) {
      cureusUpdates = []
    }
    dt.api().clear();
    for (var i = 0; i < cureusUpdates.length; i++) {
      let row = cureusUpdates[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[0, 'desc']])
    dt.fnDraw()
  }

  if (cureusUpdates && cureusUpdates.length > 0) {
    return (
      <React.Fragment>
        <div className="">
          <table id="cureus-updates-table">
            <thead>
              <tr>
                <th>Date posted</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <div className="empty-container text-center">
          <div>
            <i className='fa fa-user-friends'></i>
            <h3>No recipients listed</h3>
          </div>
        </div>        
      </React.Fragment>
    )
  }

}

export default CureusUpdatesHistoryTable;