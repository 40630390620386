var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';
import Sortable from '../../Sortable';
import { Cureus } from '../../../Cureus';
import * as UserToolUtils from '../../Publishing/UserTool/ApiClient';

const CoauthorMain = createReactClass({
  getInitialState: function() {
    return({
      userships: this.props.authorships,
      loading: false,
      showAll: false
    })
  },

  componentDidMount: function() {
    $(document).foundation('tooltip', 'reflow');
  },

  updateAuthorships: function(authorships) {
    this.setState({
      userships: authorships,
      loading: false
    })
  },

  handleLoading: function() {
    this.setState({
      loading: true
    })
  },

  handleSort: function(e, sortable, component) {
    var self = this;
    var position = e.newIndex + 1;
    self.setState({loading: true},
      UserToolUtils.putUpdatePosition({
        usership: component.props.authorship,
        position: position,
        defaultParams: {authorable_type: 'Article', authorable_id: component.props.articleId},
        endpointUrl: self.props.authorshipEndpoint,
        success: self.updateAuthorships
      })
    )
  },

  renderSpinner: function() {
    if (this.state.loading) {
      return (
        <div className="spinner-container">
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  },

  renderAuthors: function() {
    var self = this;
    var authorships = this.state.showAll ? this.state.userships : this.state.userships.slice(0, 3)
    var rows = authorships.map(function(authorship) {
      return <Row
        key={authorship.id}
        id={authorship.id}
        articleId={self.props.articleId}
        authorship={authorship}
        updateAuthorships={self.updateAuthorships}
        handleLoading={self.handleLoading}
        authorshipEndpoint={self.props.authorshipEndpoint}
        specialtyOptions={self.props.specialtyOptions}
        countryOptions={self.props.countryOptions}
        affiliationNameQueryUrl={self.props.affiliationNameQueryUrl}
        ogEmail={authorship.user.email}
        editAuthorsPath={self.props.editAuthorsPath}
        pastEditorApproval={self.props.pastEditorApproval}
        deferredForPreferred={self.props.deferredForPreferred}
        articleState={self.props.articleState}
        />
    });
    return (
      <Sortable tagName="tbody" handleSort={self.handleSort}>
        {rows}
      </Sortable>
    )
  },

  renderShowAll: function() {
    if (this.state.userships.length > 3) {
      var text = this.state.showAll ? 'Show less ' : 'Show all'
      var seeChevron = this.state.showAll ? <i className="fa fa-angle-up"/> : <i className="fa fa-angle-down"/>
      return (
        <a onClick={ this.toggleShowAll } className="content-toggler">{ text } {seeChevron}</a>
      )
    }
  },

  toggleShowAll: function() {
    this.setState({showAll: !this.state.showAll})
  },

  renderQuestion: function() {
    if(this.props.articleState !== 'blocked') {
      return (
        <i className="fas fa-question-circle has-tip tip-right" data-tooltip="" id={"authors- " + this.props.articleId} title={this.props.coauthorConfirmedEmphasisBox}/>
      )
    }
  },

  render: function() {
    var self = this;
    return (
      <div className="dashboard-panel-box coauthors">
        <h6>AUTHORS &nbsp; {self.renderQuestion()}</h6>
        {this.renderSpinner()}
        <table className="dashboard-table coauthors">
          <thead>
            <tr>
              <th className="table-draggable hide-for-small"></th>
              <th className="hide-small">Position</th>
              <th className="centered"><i className="far fa-envelope"/></th>
              <th className="text-left">Author Details</th>
              <th className="text-left">Status</th>
              <th className="centered"></th>
            </tr>
          </thead>
          {self.renderAuthors()}
        </table>
        <div className="centered">
          {self.renderShowAll()}
        </div>
      </div>
    )
  }
}) 

export default CoauthorMain;