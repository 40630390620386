import React, { Component } from 'react';

class SocialBoostQuote extends Component {
  renderQuote() {
    return {
      1: "For physicians and researchers who are too busy or unfamiliar with social media, Cureus can help promote articles with their Article Promotion option. I have promoted several of my articles with the Article Promotion option, which has increased the visibility and readership of my articles by an average of 914 reads. This helps spread important medical knowledge to more of my fellow researchers and practicing colleagues.",
      2: "As a new author, I am working on growing my audience. I received fantastic results after using the Cureus Article Promotion feature to promote my latest case report. My article's audience grew by 1160 reads! I enjoy looking at the metrics page to see how the readership of my article has grown and even where people are reading my research throughout the world.",
      3: "While I am comfortable using social media and understand its value in promoting research, it is not my particular area of expertise. As a physician and researcher, I don’t have the time to post my articles to social media forums or groups. I trust Cureus to create targeted social media ad campaigns for my research to reach the broader medical community."
    }
  }

  renderName = () => {
    let user = this.props.user
    if(user.id === 1) {
      return (
        <React.Fragment>
          <h4>Dr. Hassan Kesserwani</h4>
          <h6>Neurology, Flowers Medical Group, Dothan, AL</h6>
        </React.Fragment>
      )
    } else if(user.id === 2) {
      return (
        <React.Fragment>
          <h4>Dr. Tobechukwu Clouden</h4>
          <h6>Psychiatry, Riverview Medical Center, Red Bank, NJ</h6>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <h4>Dr. Latha Ganti</h4>
          <h6>Emergency Medicine, University of Central Florida College of Medicine, Orlando, FL</h6>
        </React.Fragment>
      )
    }
  }

  renderAvatar = () => {
    return {
      1: 'https://public.cureus.com/social_boost/carousel/avatar-2.png',
      2: 'https://public.cureus.com/social_boost/carousel/clouden-avatar-1.png',
      3: 'https://public.cureus.com/social_boost/carousel/avatar-3.jpg'
    }
  }

  renderHandle = () => {
    if(this.props.user.id === 3) {
      return (
        <p className="handle cureus-blue"><a className="cureus-blue" href="https://twitter.com/LathaGanti" target="_blank">@LathaGanti</a></p>
      )
    }
  }

  render() {
    let user = this.props.user
    return (
      <div className="row flex-container">
        <img className="carousel-icon top-right" src='https://public.cureus.com/social_boost/carousel/thumbup_blue.png'/>
        <img className="carousel-icon middle-right" src='https://public.cureus.com/social_boost/carousel/thumbup_light_blue@3x.png'/>
        <img className="carousel-icon bottom-right" src='https://public.cureus.com/social_boost/carousel/blue@3x.png'/>
        <img className="carousel-icon bottom-middle" src='https://public.cureus.com/social_boost/carousel/heart_pink@3x.png'/>
        <img className="carousel-icon bottom-left" src='https://public.cureus.com/social_boost/carousel/heart_light_pink@3x.png'/>
        <div className="large-1 columns show-for-large-up arrow-flex">
          <div className="channel-carousel-arrow-container">
            <i className="far fa-arrow-alt-circle-left"  onClick={() => this.props.handleNext('left')}></i>
          </div>
        </div>
        <div className="large-10 medium-12 small-12 columns">
          <div className="user-testimonial-container">
            <div className="large-4 small-12 medium-12 columns">
                <div className="avatar">
                  <img src={this.renderAvatar()[user.id]}/>
                </div>
                {this.renderHandle()}
            </div>
            <div className="large-8 small-12 medium-12 columns">
              <div className="quote-container">
                <i class="fas fa-quote-left"/>
                <p>{this.renderQuote()[user.id]}</p>
                <i class="fas fa-quote-right"/>
                <div className="author-details">
                  {this.renderName()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="large-1 columns show-for-large-up arrow-flex">
          <div className="channel-carousel-arrow-container">
            <i className="far fa-arrow-alt-circle-right" onClick={() => this.props.handleNext('right')}></i>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialBoostQuote;