import React from 'react';

const TrendingTopics = ({updateSearch, keywords, headerEnabled= true, path}) => {

  const renderSearch = (keyword) => {
    updateSearch(null, keyword);
  }

  // use updateSearch or path depending on if we want to have an onClick like a button
  // or a link to the given path + search term
  return (
    <div className="trending-topics-section">
      {headerEnabled && <h2>Relevant Keywords</h2>}
      <div className="trending-topics-container">
        {keywords.map((keyword, idx) => {
          return (
            <a
              key={idx}
              className="pill-container"
              {...(updateSearch && {onClick: () => renderSearch(keyword)})}
              {...(path && {href: `${path}?q=${keyword}`, target: '_blank'})}
            >
              {keyword}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default TrendingTopics;