import React, {useState, useEffect} from 'react';
import Modal from '../Modal';
import moment from 'moment';
import RejectModal from './RejectModal';
import ApproveModal from './ApproveModal';

const NewTable = ({applications, fetchApplications, reasons}) => {

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    populateTable(applications);
  }, [applications])

  const deleteCollectionIds = () => {
    $.ajax({
      type: "put",
      url: "/admin/collection_applications/bulk_reject",
      dataType: "json",
      data: {selected_ids: selectedIds, message: "mass-delete"},
      success: function(res) {
        var table = $('#collections-table').DataTable();
        selectedIds.forEach(id => {
          let row = $(`#row-${id}`);
          table.fnDeleteRow(row[0]);
        })
        setSelectedIds([]);
      }
    })
  }

  const mapColumns = (dt, row) => {
    const editButtons = '<a class="text-center button add"><i class="fas fa-check"></i></a><a class="text-center button edit alert">Reject</a>';
    return (
      dt.fnAddData([
        '',
        row.created_at,
        `<a class='column-hoverable' id=${row.id} href='/admin/users/${row.user_id}'>${row.submitter}</a>`,
        row.position,
        row.specialty,
        `<a class='column-hoverable' id=${row.id} href='/admin/collection_applications/${row.id}' target=_blank>${row.title}</a>`,
        editButtons
      ], false)
    )
  }

  const setColumns = () => {
    return [
      {class: 'centered', orderable: false, data: null, defaultContent: '', render: () => '<input type="checkbox" class="row-checkbox" />'},
      {width: 'centered'},
      {class: 'centered'},
      {class: 'centered'},
      {class: 'centered'},
      {class: 'centered', width: '300px'},
      {class: 'centered', width: '200px'}
    ]
  }

  const openRejectModal = (applicationId) => {
    Modal.open({
      children: 
        <RejectModal
          applicationId={applicationId}
          pendingProposals={true}
          reasonsData={reasons}
        />
    })
  }

  const openApproveModal = (applicationId) => {
    Modal.open({
      children: 
        <ApproveModal
          applicationId={applicationId}
          pendingProposals={true}
        />
    })
  }

  const toggleRowSelection = (checkBox, applicationId) => {
    setSelectedIds(prevIds => {
      if (checkBox.checked) {
        return prevIds.includes(applicationId) ? prevIds : [...prevIds, applicationId];
      } else {
        return prevIds.filter(id => id !== applicationId);
      }
    });
  }

  const populateTable = (collectionsData) => {
    var dt = $("#collections-table").DataTable({
      destroy: true,
      pageLength: 20,
      lengthMenu: [20, 50, 100],
      columns: setColumns(),
      columnDefs: [
        {
          // when displaying, show date in MM-DD-YYYY format, when sorting it will sort by ISO date
          targets: [1],
          render: (date, type, row) => {
            return (type === 'display') ? date : moment(date, "MM-DD-YYYY").toISOString();
          }
        },
      ],
      createdRow: function(row, data, dataIndex) {
        $(row).attr('id', 'row-' + row.children[2].children[0].id);
      },
      "rowCallback": function(row, data) {
        let approveBtn = row.children[6].children[0];
        let rejectBtn = row.children[6].children[1];
        let applicationId = row.children[2].children[0].id;
        let checkBox = row.children[0].children[0];
        approveBtn.addEventListener('click', function() {
          openApproveModal(applicationId)
        });
        rejectBtn.addEventListener('click', function() {
          openRejectModal(applicationId)
        });
        checkBox.addEventListener('click', function() {
          toggleRowSelection(checkBox, applicationId)
        });
      },
      dom: '<"row"<"large-12 columns search-filters"f>>tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
    })
    
    dt.fnClearTable();
    for (var i = 0; i < collectionsData.length; i++) {
      let row = collectionsData[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
  }

  const renderDeleteButton = () => {
    if (selectedIds.length > 0) {
      return <a className='text-center button edit alert delete-btn' onClick={deleteCollectionIds}>DELETE</a>
    } else {
      return <></>
    }
  }

  return (
    <div id='pending-table' className='row'>
      <div id='delete-btn-container'>
        {renderDeleteButton()}
      </div>
      <div className='small-12 columns collection-table-section'>
        <table id="collections-table">
          <thead>
            <tr>
              <th></th>
              <th>Submission Date</th>
              <th>Submitter</th>
              <th>Qualifications</th>
              <th>Specialty</th>
              <th>Title</th>
              <th>Approve / Reject</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  )
}

export default NewTable;