import React from 'react';

const ManagingPeerReview = () => {
  return (
    <React.Fragment>
      <h1>Managing the Peer Review Process</h1>
      <p>You can oversee your article’s peer review from the My Documents tab of your dashboard - monitor peer review progress with up-to-the-minute statistics, remind invited reviewers or invite new reviewers, and view peer review comments as they are submitted.</p>
      
      <h2>Ending the Peer Review Period</h2>
      <p>You can end the peer review period for all reviewers once your article has satisfied our peer review requirements. If chosen, this option will expire all outstanding and in-progress reviews, with the exception of any reviews that were started within the prior 48 hours. These reviewers will be notified that they have up to 24 hours to submit their review, however, the review period may end sooner if you revise and submit for publication approval.</p>
      <p>If you are waiting on a Cureus-invited review (and have received at least one review from author-invited reviewers), you can also choose to end the review period for the remaining author-invited reviewers. If any of these reviewers started their review in the prior 48 hours, they will be notified that they have up to 24 hours to submit their review.</p>

      <h2>Re-review</h2>
      <p>You have the option to request an additional round of reviewer from one or more reviewers who completed the initial peer review. In some cases, Cureus editors will also invite reviewers to review the article again. The re-review process works a bit differently depending on whether the author or an editor initiated the re-review.</p>
      <p><strong>Author-initiated re-review: </strong>You may edit your article at any time and submit for publication approval. You don’t have to wait for re-reviews to be submitted if you decide that you’d rather move forward. Any edits you make during re-review will not be visible to the reviewers.</p>
      <p><strong>Editor-initiated re-review: </strong>Your article will be locked until one re-review has been completed or 10 days have elapsed, whichever comes first. At that point, you will be able to revise and submit for publication approval.</p>
      <div>
        <img src='https://public.cureus.com/in_review_dashboard3.png'/>
      </div>
    </React.Fragment>
  )
}

export default ManagingPeerReview