
var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';

const Main = createReactClass({
  getInitialState: function() {
    return({
      hasNoAffiliation: this.props.hasNoAffiliation
    })
  },

  componentWillMount: function() {
    this.setState({
      affiliations: this.props.affiliations,
    })
  },

  componentDidMount: function() {
    $('[data-autocomplete-url]').associationAutocomplete();
  },

  addAffiliationRow: function() {
    var newAffiliationsArray = this.state.affiliations
    // add random unique number for key
    var newAffiliation = { affiliation: { id: new Date().getTime()} }

    newAffiliationsArray.push(newAffiliation)
    this.setState({affiliations: newAffiliationsArray})
  },

  removeAffiliationRow: function(uniqueId) {
    if (this.state.affiliations.length > 1) {
      var newAffiliationsArray = this.state.affiliations
      newAffiliationsArray = newAffiliationsArray.filter(function(e) {
        return e.affiliation.id != uniqueId
      })
    this.setState({affiliations: newAffiliationsArray})
    }
  },

  renderAffiliationsForm: function() {
    var self = this;
     return self.state.affiliations.map(function(affiliationObject, index) {
      return (
        <Row
          affiliationObject={affiliationObject}
          countryOptions={self.props.countryOptions}
          index={index}
          key={affiliationObject.affiliation.id}
          removeAffiliation={self.removeAffiliationRow}
          autocompleteUrl={self.props.autocompleteUrl}
          hasNoAffiliation={self.state.hasNoAffiliation}
        />
      )
    })
  },

  render: function() {
    return (
      <div>
        { this.renderAffiliationsForm() }
        <div className="row">
          <div className="large-12 medium-12 columns">
            <a className="button tiny secondary" data-add-fields="true" href="javascript:void(0)" onClick={this.addAffiliationRow}>
            <i className="fa fa-plus-circle"></i>
              &nbsp;Add another
            </a>
          </div>
        </div>
      </div>
    )
  }
})

export default Main;