var createReactClass = require('create-react-class');
import React from 'react';
import OAChecklistItem from './OAChecklistItem';


const OriginalArticleStep = createReactClass({
  getInitialState: function (){
    return {
      disabled: ' disabled',
    }
  },

  componentDidMount: function() {
    this.props.fetchOaStats()
  },

  buttonDisable: function() {
    var disabled = (document.querySelectorAll('.fail').length + document.querySelectorAll('.pass').length === 9) ? '' : ' disabled'
    this.setState({disabled: disabled});
  },

  renderRecommendedAction: function() {
    if(this.props.oa_score >= 6) {
      return (
        <h4 className="recommend">Recommended action: Desk reject or block</h4>
      )
    } else {
      return (
        <h4 className="recommend">Recommended action: Proceed</h4>
      )
    }
  },

  renderTertiaryFlag: function() {
    if (this.props.oaStats.tertiary) {
      return (
        <div>
          <span className="flag-text">This article contains “{this.props.oaStats.tertiary}” in the title. Please take a closer look at the article to determine if it is a quality submission.</span>
        </div>
      )
    }
  },

  renderChecklist: function() {
    return (
      <div className="checklist">
        <OAChecklistItem 
          title={"Is the article title free of grammar, spelling, punctuation mistakes?"}
          score={2}
          handleCheck={this.props.handleCheck}
          buttonDisable={this.buttonDisable}
          statFail={'none'}
        />
        <OAChecklistItem 
          title={"Is the materials and methods section at least 2,500 characters?"}
          score={2}
          handleCheck={this.props.handleCheck}
          buttonDisable={this.buttonDisable}
          statFail={_.isEmpty(this.props.oaStats) ? null : this.props.oaStats.materialsCount.split(',').join('') <= 2500}
          count={this.props.oaStats.materialsCount}
        />
        <OAChecklistItem 
          title={"Is the discussion section at least 2,500 characters?"}
          score={2}
          handleCheck={this.props.handleCheck}
          buttonDisable={this.buttonDisable}
          statFail={_.isEmpty(this.props.oaStats) ? null : this.props.oaStats.discussionCount.split(',').join('') <= 2500}
          count={this.props.oaStats.discussionCount}
        />
        <OAChecklistItem 
          title={"Is the results section at least 1,250 characters?"}
          score={2}
          handleCheck={this.props.handleCheck}
          buttonDisable={this.buttonDisable}
          statFail={_.isEmpty(this.props.oaStats) ? null : this.props.oaStats.resultsCount.split(',').join('') <= 1250}
          count={this.props.oaStats.resultsCount}
        />
        <OAChecklistItem 
          title={"Are figures clear, legible and displaying correctly?"}
          score={2}
          handleCheck={this.props.handleCheck}
          buttonDisable={this.buttonDisable}
          statFail={'none'}
        />
        <OAChecklistItem 
          title={"Does the article contain at least 15 references?"}
          score={1}
          handleCheck={this.props.handleCheck}
          buttonDisable={this.buttonDisable}
          statFail={_.isEmpty(this.props.oaStats) ? null : this.props.oaStats.referenceCount < 15}
          count={this.props.oaStats.referenceCount}
        />
        <OAChecklistItem 
          title={"Are one or more of the words “limitation”, “limited”, or “limit” included?"}
          score={1}
          handleCheck={this.props.handleCheck}
          buttonDisable={this.buttonDisable}
          statFail={this.props.oaStats.limitFail}
        />
        <OAChecklistItem 
          title={'Are one or more of the words "ethics", "review", or "IRB" included?'}
          score={1}
          handleCheck={this.props.handleCheck}
          buttonDisable={this.buttonDisable}
          statFail={this.props.oaStats.ethicsReviewIrbFail}
        />
        <OAChecklistItem 
          title={"Is the abstract free of multiple grammar, spelling, punctuation mistakes?"}
          score={1}
          handleCheck={this.props.handleCheck}
          buttonDisable={this.buttonDisable}
          statFail={'none'}
        />
      </div>
    )
  },

  render: function() {
    return (
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className="modal-content">
            <h3 className="reg">Original Article Preliminary Check</h3>
            <p>This process is intended to identify and reject poorly done original research. Please assign a pass or fail grade for each of the below items. All 8 items must be assessed and a recommended action will be displayed as a result. You may override the recommended action if you deem it necessary.</p>
            {this.renderChecklist()}
          </div>
          {this.renderTertiaryFlag()}
          {this.renderRecommendedAction()}
          <div className="modal-footer">
            <a className={"button special" + this.state.disabled} name="desk" onClick={this.props.handleNext}>Desk Reject</a>
            <a className={"button alert" + this.state.disabled} name="block" onClick={this.props.handleNext}>Block</a>
            <a className={"button primary" + this.state.disabled} name="next" onClick={this.props.handleNext}>Next</a>
          </div>
        </div>
    )
  }
})

export default OriginalArticleStep;