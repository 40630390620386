var createReactClass = require('create-react-class');
import React from 'react';
import Link from './Link';

export const Main = createReactClass({
  renderPoppers: function() {
    var self = this;
    var lastIdx = self.props.authors.length - 1
    return self.props.authors.map(function(author, idx) {
      var isLast = idx === lastIdx
      return <Link idx={idx} isLast={isLast} key={idx} author={author} correspondentId={self.props.correspondentId} plain={self.props.plain}/>
    })
  },

  render: function() {
    return(
        <section>
          {this.renderPoppers() }
        </section>
    )
  }
});

export default Main;