import React, { useState, useEffect } from 'react';

const Promotion = ({handleAddWidget, widgetSidebarId, widget, handleUpdateWidget, slug}) => {
  const [url, setUrl] = useState(widget ? widget.url : "")
  const [title, setTitle] = useState(widget ? widget.title : "")
  const [image, setImage] = useState(null)
  const [imageFilename, setImageFilename] = useState(widget ? widget.imageFilename : '')
  const [disabled, setDisabled] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    handleSettingErrorMessage()
  }, [url, title, image])

  const handleSettingErrorMessage = () => {
    let validURL;
    if (/https?:\/\//.test(url)) {
      validURL = false
      setErrorMessage('Please do not include "https://" or "http://"')
    } else {
      validURL = true
      setErrorMessage("")
    }
    setDisabled(validURL && title && (image || imageFilename) ? '' : 'disabled')
  }

  const handleAddWidgetWrapper = () => {
    setDisabled('disabled')
    setErrorMessage('Please wait while we upload your image.')
    let params = createFormData()
    if(handleAddWidget) {
      handleAddWidget(params, true)
    } else {
      handleUpdateWidget(widget.id, params, true)
    }
  }

  const createFormData = () => {
    let formData = new FormData();
    if(widgetSidebarId) {
      formData.append("widget[parent_id]", widgetSidebarId)
    }
    formData.append("widget[type]", "Widget::ChannelPromotion");
    formData.append("widget[url]", `http://${url}`);
    formData.append("widget[title]", title);
    if(image) {
      formData.append("widget[image]", image);
    }
    return formData;
  }

  const renderFilename = () => {
    if(imageFilename) {
      return <span>Uploaded: {imageFilename}</span>
    }
  }

  return (
    <div className="reveal-modal small channel-design-modals" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Channel Promotion</h5>
            <hr/>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <p>Add an image that links to an external webpage.</p>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <div className="input-container">
                <label>Title</label>
                <input type="text" name="title" value={title} placeholder="Enter the title to be displayed above the image." onChange={(e) => setTitle(e.target.value)}/>
                </div>
              </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <div className="input-container">
                <label>URL</label>
                <input type="text" name="url" value={url} placeholder="Enter the URL of the destination webpage." onChange={(e) => setUrl(e.target.value)}/>
              </div>
            </div>
          </div>
          <div className="row file-container flex">
            <div className="small-8 columns file-uploads">
              <label htmlFor="image">Image (PNG, JPEG, TIFF files accepted)</label>
              <input
                id="image"
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            {renderFilename()}
          </div>
          <div className='modal-footer text-center'>
            <a className={"button primary " + disabled} onClick={handleAddWidgetWrapper}>Add</a>
            {disabled && <p className="error-message text-left">{errorMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}


export default Promotion;