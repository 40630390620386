import React, { useState, useEffect } from 'react';
import HomepageSettings from './HomepageSettings';
import SidebarSettings from './SidebarSettings';
import ChannelApi from '../../Cureus/ChannelApi';
import Modal from '../Modal';
import MainContent from './MainContent';
import SidebarContent from './SidebarContent';

const Home = ({slug, hasArticles, hasLessThan4Multimedia, widgetMainRiverId, widgetSidebarId, initialMainContentWidgets, initialSidebarWidgets, isPublished, hasPosters, hasAbstracts}) => {

  const [mainContentWidgets, setMainContentWidgets] = useState({});
  const [sidebarWidgets, setSidebarWidgets] = useState([]);

  useEffect(() => {
    setMainContentWidgets(initialMainContentWidgets)
    setSidebarWidgets(initialSidebarWidgets)
  }, [])


  const handleAddWidget = (params, hasImage=false) => {
    ChannelApi.createWidget(params, slug, function(res) {
      setWidgets(res)
    }, hasImage)
  }

  const handleDeleteWidget = (widgetId) => {
    ChannelApi.destroyWidget(widgetId, slug, function(res) {
      setWidgets(res)
    })
  }

  const handleUpdateWidget = (widgetId, params, hasImage=false) => {
    ChannelApi.updateWidget(widgetId, params, slug, function(res) {
      setWidgets(res)
    }, hasImage)
  }

  const setWidgets = (res) => {
    if(res.ordered_side_rail) {
      setSidebarWidgets(res.ordered_side_rail)
    } else {
      let mainContentWidgetsObj = {...mainContentWidgets}
      mainContentWidgetsObj[res.type] = res.id
      setMainContentWidgets(mainContentWidgetsObj)
    }
    Modal.close()
  }

  return (
    <div className="admin-channel-home-container">
      <div className="row small-collapse">
        <div className="small-8 columns">
          <MainContent
            mainContentWidgets={mainContentWidgets}
            slug={slug}
            handleDeleteWidget={handleDeleteWidget}
            handleUpdateWidget={handleUpdateWidget}
            showNoArticles={isPublished && !hasAbstracts && !hasArticles && !hasPosters}
          />
        </div>
        <div className="small-4 columns sidebar-content">
          <SidebarContent
            sidebarWidgets={sidebarWidgets}
            slug={slug}
            handleDeleteWidget={handleDeleteWidget}
            handleUpdateWidget={handleUpdateWidget}
          />
        </div>
      </div>
      <hr className="orange-bar"/>
      <div className="row small-collapse">
        <div className="small-8 columns">
          <HomepageSettings
            handleAddWidget={handleAddWidget}
            hasArticles={hasArticles}
            hasLessThan4Multimedia={hasLessThan4Multimedia}
            widgetMainRiverId={widgetMainRiverId}
            mainContentWidgets={mainContentWidgets}
            slug={slug}
          />
        </div>
        <div className="small-4 columns">
          <SidebarSettings
            handleAddWidget={handleAddWidget}
            hasArticles={hasArticles}
            widgetSidebarId={widgetSidebarId}
            sidebarWidgets={sidebarWidgets}
            slug={slug}
          />
        </div>
      </div>
    </div>
  )
}

export default Home;