import React, {useEffect, useState} from 'react';
import Chart from 'chart.js-v3/auto';

const OpenClickChart = ({ getStat }) => {

  const [timePart, setTimePart] = useState('hour');

  useEffect(() => {
    getStat('#open-click-container', {timePart: timePart, statType: "opens_and_clicks"}, handleSuccess)
  }, [timePart])

  function handleSuccess(data) {
    if ($("#open-click")) {
      $('#open-click').remove();
    }
    $('#open-click-container').append("<canvas id='open-click'></canvas>");
    new Chart('open-click', {
      type: 'line',
      data: data,
      options: {
        scales: {
          y: {
            beginsAtZero: true
          },
          x: {
            beginsAtZero: true
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    })
  }

  return (
    <div id="open-click-container" className='open-click-container'>
      <div className='legend'>
        <div className='left'>
          <select name="timePart" value={timePart} onChange={(e) => setTimePart(e.target.value)}>
            <option value="hour">Hour</option>
            <option value="day">Day</option>
          </select>
        </div>
        <div className='right'>
          <i className='dot opens'/>
          <span>Opens</span>
          <i className='dot clicks'/>
          <span>Clicks</span>
        </div>
      </div>
      <canvas id='open-click'></canvas>
    </div>
  )
}

export default OpenClickChart;