import React, { useEffect } from 'react';
import Chart from 'chart.js-v3/auto';

const ScatterChart = ( { ctx, datasets, title, yLabel, xLabel} ) => {

  useEffect(() => {
    $(`#${ctx}`).remove();
    $(`#${ctx}-container`).append(`<canvas id='${ctx}'></canvas>`);
    new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: datasets
      },
      options: {
        maintainAspectRatio: false,
        elements: {
          point:{
              radius: 12
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
                label: function(ctx) {
                  let label = ctx.dataset.label.split(',')[0];
                  label += " (" + ctx.parsed.x + ", " + ctx.parsed.y + ")";
                  return label;
                }
             }
          },
          title: {
            display: false,
            text: title
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: yLabel
            }
          },
          x: {
            title: {
              display: true,
              text: xLabel
            }
          }
        }
      }
    })
  })


  return (
    <div id={`${ctx}-container`} style={{height: '800px'}}><canvas id={ctx}></canvas></div>
  )

}

export default ScatterChart;