import React, { useState, useEffect } from 'react';

const PollOption = ({idx, optionId, answer, selected, setSelectedId}) => {

  const handleSelection = (e) => {
    e.preventDefault()
    setSelectedId(optionId)
  }

  return (
    <div className={selected ? 'answer-container selected' : 'answer-container'} onClick={(e) => handleSelection(e)}>
      <label htmlFor={`${idx}`} style={{ marginBottom: '0' }}>
        <p className='answer-text'>{answer}</p>
        {selected ? (
          <i className='fas fa-check-circle' />
        ) : (
          <input 
            id={`${idx}`} 
            type='radio' 
            className='answer-circle'
            onChange={(e) => handleSelection(e)} 
          />
        )} 
      </label>
    </div>
  )
};

export default PollOption;