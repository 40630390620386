import React, {useState, useEffect, useRef} from 'react';

const BigCard = ({collection, index, tooltip}) => {
  const [hiddenCategories, setHiddenCategories] = useState(0);
  const [like, setLike] = useState(false);
  const [hovering, setHovering] = useState(false);
  let categoryContainer = useRef();
  let categoryItems = useRef();
  const maxCategoriesToShow = 3;

  useEffect(() => {
    window.addEventListener('resize', adjustCategories);
    adjustCategories();
    return () => {
      window.removeEventListener('resize', adjustCategories);
    };
  },[])

  const adjustCategories = () => {
    categoryContainer = document.getElementById(`categoryContainer${index}`);
    categoryItems = categoryContainer.querySelectorAll('.category');
    let availableSpace = categoryContainer.offsetWidth - 48;
      let visibleCategories = 0;
      for (let i = 0; i < categoryItems.length; i++) {
        categoryItems[i].style.display = 'inline-block';
        const categorySpan = categoryItems[i].querySelector('span');
        categorySpan.style.display = 'inline';

        const categoryWidth = categoryItems[i].offsetWidth; 
        if (categoryWidth > availableSpace) {
          categorySpan.style.display = 'none';
          categoryItems[i].style.display = 'none';
        } else {
          availableSpace -= categoryWidth;
          visibleCategories++;
        }
        if (visibleCategories === maxCategoriesToShow) {
          break;
        }
      }

      const hiddenCats = categoryItems.length - visibleCategories;
      setHiddenCategories(hiddenCats);
    };

  const shortenText = (scope) => {
    if (scope === null) {
      return '';
    }

    const maxLength = 250;
    if (scope.length <= maxLength) {
      return scope;
    } else {
      const shortenedText = scope.slice(0, maxLength).trim();
      return shortenedText + '...';
    }
  }
  
  const renderSubmissionStatus = (open) => {
    if (open) {
      return (
        <div className='submission-window open'>
          Accepting Submissions
        </div>
      )
    } else {
      return (
        <div className='submission-window closed'>
          Closed
        </div>
      )
    }
  }

  return (
      <div className="small-12 medium-12 large-12 columns card big">
        {collection.type != 'CureusUCollection' && renderSubmissionStatus(collection.is_submission_open)}
        <div className='image-holder'>
          <img src={collection.big_card_url}/>
        </div>
        <div className='big-card-text'>
          <div className='left-side small-12 medium-6 large-6'>
            <div className='collection-type-holder'>
              <h5 className='collection-type'>{collection.pretty_type}</h5>
            </div>
            <h2 className='collection-title'>{collection.title}</h2>
          </div>
          <div className='right-side small-12 medium-6 large-6'>
            <div className='description-info-container'>
              <div className='category-container' id={`categoryContainer${index}`}>
                <div className='category-list'>
                  {collection.categories.map((category)=>{
                  return (
                    <div className='category' key={category.id}>
                      <span>{category.name}</span>
                    </div>
                  )
                  })}
                  {hiddenCategories > 0 && <div title={tooltip} data-tooltip="" className="category-count tip-top">+{hiddenCategories}</div>}
                </div>
              </div>
              <p className='scope-summary'>{shortenText(collection.summary)}</p>
            </div>
            <div className='views-info-container'>
              <a className='read-more-button' href={collection.collection_path} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
                <div className='read-more-text'>Read More</div>
                <img src={hovering ? "https://public.cureus.com/new-nav-bar/arrow-right-blue.png" :"https://public.cureus.com/collections/arrow-right-black.png"}></img>
              </a>
              <div className='views-container'>
                <div className='article-number tip-top' data-tooltip="" title={"Total number of published collection articles"}>
                    <img src="https://public.cureus.com/collections/articles.png"/>
                    <div>{collection.article_count}</div>
                </div>
                <div className='collection-views tip-top' data-tooltip="" title={"Total views of collection and articles"}>
                    <img src="https://public.cureus.com/collections/eye.png"/>
                    <div>{collection.pretty_article_views_count}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default BigCard;