import React, { useState, useEffect } from 'react';
import Card from './Card';

const Index = ({currentUserId}) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetchArticles()
  }, [])

  const fetchArticles = () => {
    $.ajax({
      type: 'GET',
      url: "/most_read_articles/fetch_most_popular",
      success: function(data) {
        setArticles(data.articles)
      }
    });
  }

  const fireAmplitude = () => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: {
              event_type: 'viewPopularArticlesClicked',
              user_id: currentUserId
            }
    })
  }

  const renderArticles = () => {
    return articles.map(function(article, idx) {
      return <Card
        article={article}
        key={idx}
        currentUserId={currentUserId}
        amplitudeEvent={"popularArticleClicked"}
      />
    })
  }

  return (
    <div className="most-popular-container featured-articles-container-home">
      <h3>Popular Articles <img className="question-mark-icon tip-top" src="https://public.cureus.com/homepage_new/question_mark.png" data-tooltip="" title="Yesterday's most-viewed articles, updated daily. View numbers are from yesterday only." /></h3>
      {renderArticles()}
      <div className="view-more-popular">
        <a href="/articles/most_popular" onClick={fireAmplitude}>View More <i class="fa fa-arrow-right"/></a>
      </div>
    </div>
  )

}

export default Index;