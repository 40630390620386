import React from 'react';
const Intro = props => {
  if(props.cooldown) {
    return (
      <div className="modal-content">
        <h4>New reviews will be available in {props.cooldown.timeleft}.</h4>
        <p>It’s been less than 3 days since you accepted an on-demand review. Please check back in {props.cooldown.timeleft} to see if any articles related to your specialty are available.</p>
        <div className="modal-footer">
          <a className='cancel-modal button secondary'>Done</a>
        </div>
      </div>
    )
  } else if(props.noArticles) {
    return (
      <div className="modal-content">
        <h4>There are no articles available to review right now</h4>
        <p>Please check back soon to see if any articles related to your specialty are available.</p>
        <div className="modal-footer">
          <a className='cancel-modal button secondary'>Done</a>
        </div>
      </div>
    )
  }
  return (
    <div className="modal-content">
      <h4>Would you like to peer review an article right now?</h4>
      <p>We’re offering a select group of Cureus reviewers the opportunity to peer review articles on demand without waiting for an invitation.</p>
      <p>You will be presented with up to three articles relevant to your specialty that are currently in review. After accepting an on-demand review, there will be a three-day waiting period before you can accept another.</p>
      <p>Click below to view the available articles.</p>
      <div className="modal-footer">
        <a className='cancel-modal button secondary'>Cancel</a>
        <a className="button primary" onClick={props.handleNext}>Next</a>
      </div>
    </div>
  )
}

export default Intro;