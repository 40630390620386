import {Cureus} from '../../Cureus';

Cureus.Utils.Placeholder = {
  /*
    Look through the document and on any inputs
    with placeholders, compare the field value
    against the placeholder.  If they match, clear
    the field
  */
  clearPlaceholders: function () {
    $('textarea').each( function () {
      Cureus.Utils.Placeholder.clearTextarea($(this));
    });
  },

  //private
  clearTextarea: function (elt) {
    if(elt.val() == elt.attr('placeholder')){
      elt.val('');
    }
  },
  clearInput: function (elt) {
    if(elt.value() == elt.attr('placeholder')){
      elt.val('');
    }
  }
}
