import React, { useState, useEffect } from 'react';
import Topic from './Topic';
import Sortable from '../../components/Sortable';
import AddModal from './AddModal';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const Selected = ({}) => {

  const [topics, setTopics] = useState([])

  useEffect(() => {
    fetchTopics()
  }, [])

  const fetchTopics = () => {
    $.ajax({
      type: 'GET',
      url: "/admin/trending_topics",
      success: function(data) {
        setTopics(data.topics)
      }
    });
  }

  const handleSave = () => {
    $.ajax({
      type: 'POST',
      data: {topics: topics},
      url: "/admin/trending_topics/save_all",
      success: function(data) {
        setTopics(data.topics)
        Cureus.Flash.success('Trending topics saved.')
      }
    });
  }

  const handleSort = (e, sortable, movedComponent) => {
    let newPosition = e.newIndex + 1;
    handleUpdatePosition(movedComponent, newPosition);
  }

  const handleUpdatePosition = (movedComponent, newPosition) => {
    let oldPositionIndex = movedComponent.props.position - 1;

    let movedTopic = topics[oldPositionIndex];
    let newTopicsState = topics.slice()
    newTopicsState.splice(oldPositionIndex, 1);
    newTopicsState.splice(newPosition - 1, 0, movedTopic);
    setTopics(newTopicsState)
  }


  const isValidPosition = (position) => {
    let min = 1;
    let max = this.state.reasons.length;
    return position >= min && position <= max;
  }

  const handleDelete = (term) => {
    let newTopicsState = topics.filter(topic => topic !== term)
    setTopics(newTopicsState)
  }

  const renderTopics = () => {
    return topics.map(function(topic, idx) {
      return (
          <Topic 
            handleDelete={handleDelete}
            topic={topic}
            position={idx + 1}
            key={topic}
          />
      )
    })
  }

  const openAddModal = () => {
    Modal.open({
			children: <AddModal 
                  topics={topics}
                  setTopics={setTopics}
                />
		});
  }

  return (
    <div className="row">
      <div className="small-12 columns">
        <div className="small-12 columns">
          <h3>Trending Topics Settings</h3>
          <br/>
          <div className="trending-topics-container"> 
            <Sortable tagName='span' handleSort={ handleSort }>
              {renderTopics()}
            </Sortable>
          </div>
          <a className={"button special " + (topics.length < 12 ? '' : 'disabled')} onClick={openAddModal}>Add New</a>
          <br/>
          <a className="button primary" onClick={handleSave}>Save</a>
        </div>
      </div>
    </div>
  )

}

export default Selected;