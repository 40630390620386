var createReactClass = require('create-react-class');
import React from 'react';
import EditProfile from './EditProfile';
import AffiliationsMain from './AffiliationsMain';
import Npi from './Npi';
import PeerReviewPanel from './PeerReviewPanel';
import EditAboutMe from './EditAboutMe';
import PublicProfile from './PublicProfile';
import EditInstitutionalEmail from './EditInstitutionalEmail';
import Modal from '../Modal';

const Main = createReactClass({
  getInitialState: function() {
    return {
      modalType: this.props.modalType,
      currentStep: this.props.currentStep,
      errors: null,
    }
  },

  componentDidMount: function() {
    if (this.props.confirmation && this.props.currentStep && this.props.currentStep <= 4) {
      this.openModal();
    }
  },

  openModal: function(){
    Modal.open({
      children: this.modalType()[this.state.modalType]
    });
  },

  renderFlash: function() {
    if(this.props.confirmation) {
      return (
        <div id="flash"><div class="alert-box warning" style={{backgroundColor: '#195292;', borderColor: "#195292;"}} title="Warning" data-alert=""> <div class="row"> <div class="large-12 columns"><a class="close" href="#"><div class="flash-close-btn"></div></a><span class="flash-content">You must complete your profile in order to proceed to The Cureus Journal of Medical Science.</span> </div> </div> </div></div>
      )
    }
  },

  modalType: function() {
    return {
      'profile': <EditProfile
        qualifications={this.props.qualifications}
        specialties={this.props.specialties}
        renderButton={this.renderButton}
        userProfile={this.props.userProfile}
        updateProfilePath={this.props.updateProfilePath}
        getComponentDataPath={this.props.getComponentDataPath}
        renderModalCloseX={this.renderModalCloseX}
        setUncloseable={this.setUncloseable}
        renderErrors={this.renderErrors}
        confirmation={this.props.confirmation}
        renderFlash={this.renderFlash}
        otherQualification={this.props.otherQualification}
        />,
      'affiliations': <AffiliationsMain
        countryOptions={this.props.countryOptions}
        affiliations={this.props.affiliations}
        autocompleteUrl={this.props.autocompleteUrl}
        deptAutocompletePath={this.props.deptAutocompletePath}
        hasNoAffiliation={this.props.hasNoAffiliation}
        updateProfilePath={this.props.updateProfilePath}
        renderButton={this.renderButton}
        getComponentDataPath={this.props.getComponentDataPath}
        user_id={this.props.user_id}
        confirmation={this.props.confirmation}
        renderModalCloseX={this.renderModalCloseX}
        setUncloseable={this.setUncloseable}
        renderErrors={this.renderErrors}
        renderFlash={this.renderFlash}
        />,
      'npi': <Npi
        userProfile={this.props.userProfile}
        updateProfilePath={this.props.updateProfilePath}
        renderButton={this.renderButton}
        npiPath={this.props.npiPath}
        getComponentDataPath={this.props.getComponentDataPath}
        user_id={this.props.user_id}
        confirmation={this.props.confirmation}
        renderModalCloseX={this.renderModalCloseX}
        setUncloseable={this.setUncloseable}
        renderErrors={this.renderErrors}
        renderFlash={this.renderFlash}
        />,
      'peerReviewPanel': <PeerReviewPanel
        userProfile={this.props.userProfile}
        updateProfilePath={this.props.updateProfilePath}
        renderButton={this.renderButton}
        getComponentDataPath={this.props.getComponentDataPath}
        user_id={this.props.user_id}
        confirmation={this.props.confirmation}
        renderModalCloseX={this.renderModalCloseX}
        setUncloseable={this.setUncloseable}
        renderErrors={this.renderErrors}
        renderFlash={this.renderFlash}
        />,
      'aboutMe': <EditAboutMe
        basePath={this.props.basePath}
        keywords={this.props.keywords}
        params={this.props.params}
        keywordLimit={this.props.keywordLimit}
        reviewerGuidePath={this.props.reviewerGuidePath}
        autoCompletePath={this.props.autoCompletePath}
        userProfile={this.props.userProfile}
        updateProfilePath={this.props.updateProfilePath}
        renderButton={this.renderButton}
        getComponentDataPath={this.props.getComponentDataPath}
        currentStep={this.state.currentStep}
        user_id={this.props.user_id}
        confirmation={this.props.confirmation}
        renderModalCloseX={this.renderModalCloseX}
        setUncloseable={this.setUncloseable}
        renderSkipButton={this.renderSkipButton}
        renderErrors={this.renderErrors}
        />,
      'publicProfile': <PublicProfile
        userProfile={this.props.userProfile}
        updateProfilePath={this.props.updateProfilePath}
        renderButton={this.renderButton}
        getComponentDataPath={this.props.getComponentDataPath}
        currentStep={this.state.currentStep}
        user_id={this.props.user_id}
        confirmation={this.props.confirmation}
        renderSkipButton={this.renderSkipButton}
        renderErrors={this.renderErrors}
        />,
      'editInstitutionalEmail': <EditInstitutionalEmail
        email={this.props.email}
        userId={this.props.user_id}
        renderButton={this.renderButton}
      />
    }
  },

  stepHash: function() {
    return {
      1: 'profile',
      2: 'affiliations',
      3: 'npi',
      4: 'peerReviewPanel',
      5: 'aboutMe',
      6: 'publicProfile'
    }
  },

  handleSubmit: function(payload, route, callback) {
    var self = this;
    var nextStep = self.stepHash()[self.state.currentStep + 1]
    self.setState({disabled: "disabled"})
    $.ajax({
      dataType: "JSON",
      url: route,
      data: self.formatPayloadParams(payload),
      type: "PUT"
    }).done(function(res) {
      if(self.props.confirmation) {
        self.setState({modalType: nextStep, currentStep: self.state.currentStep + 1, errors: null}, function() {
          Modal.close({onCloseInstant: function() {
            document.querySelector('.reveal-modal-bg').style.display = 'none'
          }});
          if (this.props.confirmation && self.state.currentStep && self.state.currentStep < 5) {
            self.openModal();
          }
          if(res.join_panel) {
            $('#keywords-add-modal').foundation('reveal', 'open');
            document.querySelector('.reveal-modal-bg').style.display = 'block'
          }
        })
      } else {
        if(res.banned) {
          window.location.href = res.redirect_path
        } else if(res.join_panel) {
          window.location.href += "?join_panel=true";
        } else {
          window.location.reload();
        }
      }
    }).error(function(res) {
      self.setState({errors: res.responseText}, function() {
        if (callback) {
          callback()
        }
      })
    })
  },

  renderSkipButton: function() {
    if (this.props.confirmation) {
      return (
        <a className="button secondary" onClick={this.handleSkip}>Skip</a>
      )
    }
  },

  handleSkip: function() {
    var newStep = this.state.currentStep + 1
    var nextStep = this.stepHash()[newStep]
    this.setState({modalType: nextStep, currentStep: newStep}, function() {
      Modal.close({onCloseInstant: function() {
        document.querySelector('.reveal-modal-bg').style.display = 'none'
      }});
      if(this.state.currentStep === 7) {
        $("#professional-societies-modal").foundation("reveal", "open");
      } else {
        this.openModal()
      }
    })
  },

  formatPayloadParams: function(payload) {
    payload["user"]["step"] = (this.state.currentStep + 1)
    payload["user_id"] = this.props.user_id
    return payload
  },

  handleButtonDisable: function(bool) {
    return bool ?  "" : " disabled"
  },


  renderButton: function(payload, route, callback, bool = true) {
    if(this.props.confirmation && this.state.currentStep < 4) {
      return <a onClick={() => this.handleSubmit(payload, route, callback)} className={"button" + this.handleButtonDisable(bool)}>Next</a>
    } else {
      return <a onClick={() => this.handleSubmit(payload, route, callback)} className={"button special" + this.handleButtonDisable(bool)}>Save</a>
    }
  },

  renderModalCloseX: function() {
    if (!this.props.confirmation) {
      return (
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
      )
    }
  },

  renderErrors: function() {
    if (this.state.errors) {
      var errors = this.state.errors.split('\\n').map(err => <li>{err}</li>)
      return (
        <div className="right" style={{color: 'red'}}>
          <ul>
            {errors}
          </ul>
        </div>
      )
    }
  },

  setUncloseable: function() {
    if(this.props.confirmation) {
      return "close_on_background_click:false;close_on_esc:false;"
    }
  },

  render: function(){
    return (
      !this.props.hideEdit && <i className='fa fa-pencil-alt new-pencil' onClick={this.openModal} data-step={this.props.stepNumber}/>
    )
  }
})

export default Main;