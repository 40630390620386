import React from 'react';

const DropDownInput = ({setShowFunction, show, list, text}) => {
  const printSelection = (selection) => {
    let names = selection.map((element, i) => {
      if (element.checked) {
        return element.name
      }
    })

    return names.filter(Boolean).join('/')
  }

  const hasSelection = () => {
    return selectedAnyInList() ? 'has-selection' : ''
  }

  const caratDirection = () => {
    return show ? 'fa-caret-up' : 'fa-caret-down'
  }

  const selectedItems = () => {
    return list.filter((element) => {
      return element.checked
    })
  }

  const selectedAnyInList = () => {
    return selectedItems().length > 0
  }

  const renderNumber = () => {
    if (selectedAnyInList()) {
      return selectedItems().length
    }
  }

  return (
    <div className='dropdown-field'>
      <input type="text" onClick={() => {setShowFunction(true)}} readOnly value={printSelection(list)} placeholder={text} />
      <div className="carat-number">
        <i className={`fa ${caratDirection()} ${hasSelection()} fa-lg carat`}/>
        <span className={`${hasSelection()}`}>{renderNumber()}</span>
      </div>
    </div>
  )
};

export default DropDownInput;
