var createReactClass = require('create-react-class');
import React from 'react';

const FileDrop = createReactClass({
  handleDroppedFile: function(file) {
    this.props.onDroppedFile(file);
  },
  handleDrop: function(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({dragHover: false});

    var files = e.dataTransfer.files;

    for(var x = 0; x < files.length; x++) {
      this.handleDroppedFile(files[x]);
    }
  },

  render: function() {
    var classes = 'file-drop-zone' + (this.props.dragHover ? ' file-drop-zone-active' : '') + (this.props.inline ? ' file-drop-inline' : '')
    if(this.props.plain) {
      return (
        <div className={ classes }
          onDragEnter={ this.props.handleDragEnter }
          onDragOver={ this.props.handleDragOver }
          onDragLeave={ this.props.handleDragLeave }
          onDrop={ this.handleDrop }
          >
          { this.props.children }
        </div>
      )
    } else {
      return (
        <div className={ classes }>
          { this.props.children }
          <div className='drop-layover'
            onDragEnter={ this.props.handleDragEnter }
            onDragOver={ this.props.handleDragOver }
            onDragLeave={ this.props.handleDragLeave }
            onDrop={ this.handleDrop }
            >
          </div>
        </div>
      )
    }
  }
});

export default FileDrop;
