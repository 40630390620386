import React, {useState, useEffect} from 'react';
import ArticleCard from '../Shared/ContentCards/ArticleCard'

const MostPopular = ({topArticles}) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetchPopularArticles();
  }, [])

  const fetchPopularArticles = () => {
    $.ajax({
      type: 'GET',
      url: window.location.href,
      dataType: 'json',
      success: function(res) {
        setArticles(res);
      }
    });
  }

  const renderArticles = () => {
    return (
      <div className='row'>
        <div className='large-12 small-12 columns'>
          <div className='content-index-results-count'>
            Showing 1-{articles.length}
          </div>
          <div className='content-index-results-columns'>
            {articles.map((article) => {
              return <ArticleCard key={article.id} resource={article} />
            })}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='search-results-wrap'>
      <div className='content-index-wrap' id='pmc-results'>
        {renderArticles()}
      </div>
    </div>
  )
}

export default MostPopular;