import {Cureus} from '../Cureus';

Cureus.WikiSidebar = function (options){
    this.sidebarWrap = document.querySelector(options.sidebarSelector)
    this.widthFromWrapper = document.querySelector(options.sidebarSelector) || true

    this.trackActive()
};

Cureus.WikiSidebar.prototype.trackActive = function() {
  var section = document.querySelectorAll("h3, h2");
  var sections = {};

  section.forEach( function(e) {
    if (e.id) { sections[e.id] = e.offsetTop }
  });

  window.onscroll = function() {
    var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

    for (id in sections) {
      if (sections[id] <= scrollPosition) {
        var activeLinks = document.querySelectorAll('.active')

        activeLinks.forEach(function(e) {
          e.setAttribute('class', '');
        })

        var wikiLink = document.querySelector('a[href*=' + id + ']').parentElement
        wikiLink.setAttribute('class', 'active');
        wikiLink.parentElement.querySelector("li").setAttribute('class', 'active');
      }
    }
  };
}