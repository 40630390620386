var createReactClass = require('create-react-class');
import React from 'react';

const Post = createReactClass({
  render: function(){
    return (
      <div className={"large-4 medium-6 small-12 columns centered end " + this.props.index}>
        <div className="channel-content-card" style={{marginBottom: '32px', height: 'inherit'}}>
          <img src={this.props.image}/>
        </div>
      </div>
    );
  }
})

export default Post;