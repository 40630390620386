
var createReactClass = require('create-react-class');
import React from 'react';

const CoiResponsibility = createReactClass({
  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");
    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  componentDidMount: function(){
    this.handleChange();
  },

  handleChange: function(){
    if($(this.refs.coi_responsibility).is(":checked") == false){
      $("[data-button-type='next']").attr('disabled',true)
    }else{
      $("[data-button-type='next']").attr('disabled',false)
    }
  },

  render: function(){
    return (
      <div>
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
        <input id="coi_responsibility" name="article[coi_responsibility]" type="checkbox" defaultValue= "true" defaultChecked={ this.props.coi_responsibility ? 'checked' : '' } onChange={ this.handleChange } ref="coi_responsibility"/>
        <label htmlFor="coi_responsibility">I understand that I alone am responsible for including all co-author COI and disclosure statements.</label>
      </div>
    );
  }
})

export default CoiResponsibility;