import React, {useState, memo} from 'react';

const Collapse = ({ title, children, isOpen, openCollapse }) => {
  const handleClick = () => {
    openCollapse();
  }

  const renderIcon = (isOpen) => {
    const iconState = isOpen ? 'open' : 'collapsed';
    return (
      <svg className={iconState} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M17.6569 16.2426L19.0711 14.8284L12.0001 7.75732L4.92896 14.8284L6.34317 16.2426L12.0001 10.5857L17.6569 16.2426Z"
          fill="#1395B7"/>
      </svg>
    )
  }

  return (
    <>
      <button className={`collapse ${isOpen ? 'open' : 'collapsed'}`} onClick={handleClick}>
        <h2>{title}</h2>
        {renderIcon(isOpen)}
      </button>
      <div style={{display: isOpen ? 'block' : 'none'}}>{children}</div>
    </>
  )
}

export default memo(Collapse)