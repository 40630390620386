import React, { useState, useEffect } from 'react';
import Comments from '../../CommentForm/Main.jsx'


const Questions = ({comments}) => {
  
  return (
    <div className='comments-container'>
      <div className='comment-header'>
      </div>
      <Comments {...comments} title={"Join the Discussion"}/>
    </div>
  )
}

export default Questions;