import {Cureus} from '../Cureus';

/*jslint continue:true*/
/**
 * Adapted from {@link http://www.bulgaria-web-developers.com/projects/javascript/serialize/}
 * Changes:
 *     Ensures proper URL encoding of name as well as value
 *     Preserves element order
 *     XHTML and JSLint-friendly
 *     Disallows disabled form elements and reset buttons as per HTML4 [successful controls]{@link http://www.w3.org/TR/html401/interact/forms.html#h-17.13.2}
 *         (as used in jQuery). Note: This does not serialize <object>
 *         elements (even those without a declare attribute) or
 *         <input type="file" />, as per jQuery, though it does serialize
 *         the <button>'s (which are potential HTML4 successful controls) unlike jQuery
 * @license MIT/GPL
*/
if (typeof Object.assign != 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) { // .length of function is 2
      'use strict';
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      var to = Object(target);

      for (var index = 1; index < arguments.length; index++) {
        var nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (var nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

if (!Object.values) {
  Object.values = function values(O) {
    return Object.keys(O).map(function (key) {
      return O[key]
    });
  };
}

String.prototype.titleize = function() {
  var words = this.split(" ")
  return words.map(function(word) {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }).join(" ")
}

HTMLElement.prototype.serialize = function() {
  'use strict';
  var form = this;
  var i, j, len, jLen, formElement, q = [];
  function urlencode (str) {
      // http://kevin.vanzonneveld.net
      // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
      // PHP behavior, you would need to add ".replace(/~/g, '%7E');" to the following.
      return encodeURIComponent(str).replace(/!/g, '%21').replace(/'/g, '%27').replace(/\(/g, '%28').
          replace(/\)/g, '%29').replace(/\*/g, '%2A').replace(/%20/g, '+');
  }
  function addNameValue(name, value) {
      q.push(urlencode(name) + '=' + urlencode(value));
  }
  if (!form || !form.nodeName || form.nodeName.toLowerCase() !== 'form') {
      throw 'You must supply a form element';
  }
  for (i = 0, len = form.elements.length; i < len; i++) {
      formElement = form.elements[i];
      if (formElement.name === '' || formElement.disabled) {
          continue;
      }
      switch (formElement.nodeName.toLowerCase()) {
      case 'input':
          switch (formElement.type) {
          case 'text':
          case 'hidden':
          case 'password':
          case 'button': // Not submitted when submitting form manually, though jQuery does serialize this and it can be an HTML4 successful control
          case 'submit':
              addNameValue(formElement.name, formElement.value);
              break;
          case 'checkbox':
          case 'radio':
              if (formElement.checked) {
                  addNameValue(formElement.name, formElement.value);
              }
              break;
          case 'file':
              // addNameValue(formElement.name, formElement.value); // Will work and part of HTML4 "successful controls", but not used in jQuery
              break;
          case 'reset':
              break;
          }
          break;
      case 'textarea':
          addNameValue(formElement.name, formElement.value);
          break;
      case 'select':
          switch (formElement.type) {
          case 'select-one':
              addNameValue(formElement.name, formElement.value);
              break;
          case 'select-multiple':
              for (j = 0, jLen = formElement.options.length; j < jLen; j++) {
                  if (formElement.options[j].selected) {
                      addNameValue(formElement.name, formElement.options[j].value);
                  }
              }
              break;
          }
          break;
      case 'button': // jQuery does not submit these, though it is an HTML4 successful control
          switch (formElement.type) {
          case 'reset':
          case 'submit':
          case 'button':
              addNameValue(formElement.name, formElement.value);
              break;
          }
          break;
      }
  }
  return q.join('&');
}

HTMLElement.prototype.toggleClass = function(classStr) {
    var classes = this.className.split(' ');
    if (classes.includes(classStr)) {
       classes = classes.filter(function(el) {
         return el !== classStr
       });
    }  else {
        classes.push(classStr);
    }
    this.className = classes.join(' ');
}

HTMLElement.prototype.toggle = function() {
  this.style.display == "" ? this.style.display = "none" : this.style.display = ''
}

HTMLElement.prototype.removeClass = function(classStr) {
    var classes = this.className.split(' ');
    classes = classes.filter(function(el) {
        return el !== classStr
    });
    this.className = classes.join(' ');
}

HTMLElement.prototype.addClass = function(classStr) {
    var classes = this.className.split(' ');
    if (!classes.includes(classStr)) {
        classes.push(classStr);
    }
    this.className = classes.join(' ');
}


Cureus.closestHTMLElement = function(el, selector) {
    var matchesFn;
    // find vendor prefix
    ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
        if (typeof document.body[fn] == 'function') {
            matchesFn = fn;
            return true;
        }
        return false;
    })
    var parent;
    // traverse parents
    while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
            return parent;
        }
        el = parent;
    }
    return null;
}

Cureus.getScript = function(source, callback) {
  var script = document.createElement('script');
  var prior = document.getElementsByTagName('script')[0];
  script.async = 1;

  script.onload = script.onreadystatechange = function( _, isAbort ) {
    if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState) ) {
      script.onload = script.onreadystatechange = null;
      script = undefined;

      if(!isAbort) { if(callback) callback(); }
    }
  };

  script.src = source;
  prior.parentNode.insertBefore(script, prior);
}

Cureus.debounce = function(callback, wait, immediate) {
  var timeout;
  return function() {
    var context = this;
    var args = arguments;

    var later = function() {
      timeout = null;
      if (!immediate) { callback.apply(context, args); }
    }
    var callNow = immediate && !timeout;
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
    if (callNow) { callback.apply(context, args) }
  }
}

Cureus.botCheck = function(){
    var botPattern = "bot|googlebot|crawler|spider|robot|crawling";
    var re = new RegExp(botPattern, 'i');
    var userAgent = navigator.userAgent;
    if (re.test(userAgent)) {
      return true;
    }else{
      return false;
    }
}

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function(searchElement, fromIndex) {

      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        // c. Increase k by 1.
        k++;
      }

      // 8. Return false
      return false;
    }
  });
}