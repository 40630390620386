import React, { useState, useEffect } from 'react';
import { decodeHtml } from '../../../Cureus';

const DiscussionCard = ({discussion, currentUserId}) => {
  const [authors, setAuthors] = useState('')
  const [authorsOverflow, setauthorsOverflow] = useState(null)

  useEffect(() => {
    let authorList = discussion.author_list.split(',')
    setAuthors(authorList.slice(0, 2).join(', '))
    let overflow = authorList.slice(2).length
    if(overflow > 1) {
      setauthorsOverflow(overflow)
    }
  }, [])

  const fireAmplitude = (eventType) => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: eventType, 
              user_id: currentUserId,
              article_id: discussion.article_id
            }
    })
  }

  const renderAuthorList = () => {
    if(authorsOverflow) {
      return (
        <div className="authors">
          {authors}
          <a href={discussion.resourcePath + '?authors-tab=true'}><span>+{authorsOverflow} more</span></a>
        </div>    
      )
    } else {
      return (
        <div className="authors">
          {authors}
        </div>    
      )
    }
  }

  const renderName = () => {
    if(discussion.commentatorQualis) {
      return (
        <React.Fragment>
          <span className="name">{discussion.commentatorName}, &nbsp;</span>
          <span className="qualis">{discussion.commentatorQualis}</span>
        </React.Fragment>
      )
    } else {
      return (
        <span className="name">{discussion.commentatorName}</span>
      )
    }
  }
  
  return (
    <React.Fragment>
      <div className="large-6 medium-12 small-12 columns">
        <div className="discussion-card">
          <a href={discussion.resourcePath} onClick={() => fireAmplitude('discussionCardClicked')}>
            <div className="row">
              <div className="small-9 columns title">
                  <a href={discussion.resourcePath} onClick={() => fireAmplitude('discussionCardTitleClicked')}>{discussion.articleTitle}</a>  
                </div>
              <div className="small-3 columns thumbnail">
                <div key={discussion.id + "thumb"} dangerouslySetInnerHTML={{__html: discussion.thumbnail}}/>
              </div>
            </div>
            <div className="row flex-container marg">
              <div className="small-9 columns">
                {renderAuthorList()}  
              </div>
              <div className="small-3 columns">
                <div className="views">
                  <i class="fas fa-eye"/> {discussion.viewsCount}
                </div>
              </div>
            </div>
            <a href={discussion.commentAnchorPath}>
              <div className="discussion-comment-container">
                <div className="row">
                    <div className="large-12 columns">
                      <div className="name-container">
                        <img src="https://public.cureus.com/homepage_new/discussion-comment-bub.png"/>
                        {renderName()}
                      </div>
                    </div>
                </div>
                <div className="row">
                  <div className="large-12 columns">
                      <div className="comment-body" dangerouslySetInnerHTML={{__html: decodeHtml(discussion.body)}}/>
                  </div>
                </div>
                <div className="row">
                  <div className="large-12 columns join-discussion-link">
                    <a href={discussion.commentAnchorPath} onClick={() => fireAmplitude('joinDiscussionClicked')}>Join Discussion <i class="fas fa-arrow-right"/></a>
                  </div>
                </div>
              </div>
            </a>
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DiscussionCard;