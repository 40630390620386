import React, { useState, useEffect } from 'react';
import DateSelector from '../StatsWidget/DateSelector';

const Index = ({initialDateRanges, isAdmin}) => {
  const [dateRanges, setDateRanges] = useState(initialDateRanges);
  const [selectedRange, setSelectedRange] = useState(initialDateRanges[0].label);
  const [from, setFrom] = useState(initialDateRanges[0].from)
  const [to, setTo] = useState(initialDateRanges[0].to)

  useEffect(() => {
    fetchData(from, to, selectedRange);
  }, [])

  const fetchData = (fromDate, toDate, label) => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      data: {start: fromDate, finish: toDate},
      url: '/admin/approval_editor_completions',
      success: function(data) {
        setSelectedRange(label)
        setTo(toDate)
        setFrom(fromDate)
        populateTable(data.data)
      }
    });
  }

  const setColumns = () => {
    if(isAdmin) {
      return [
        {width: '160px'},
        {width: '160px'},
        {width: '160px'},
        {width: '160px'},
        {width: '160px'},
        {width: '160px'}
      ]
    } else {
      return [
        {width: '160px'},
        {width: '160px'},
        {width: '160px'},
        {width: '160px'},
        {width: '160px'}
      ]
    }
  }


  const populateTable = (data) => {
    var dt = $("#ae-completions-table").DataTable({
      destroy: true,
      columns: setColumns(),
      searching: true,
      pageLength: 20,
      dom: '<"row"<"large-5 columns"f>><"table"t>r<"row bottom"<"large-4 columns page-nums leader-board"p><"large-3 columns end show-amount"l>>'
    });
    dt.api().clear();
    for (var i = 0; i < data.length; i++) {
      let row = data[i];
      if(isAdmin) {
        dt.fnAddData( [
          i + 1,
          `<a class='email' target='_blank' href='/admin/users/${row.id}'>${row.email}</a>`,
          row.first_name,
          row.last_name,
          row.name,
          row.completions
        ], false)
      } else {
        dt.fnAddData( [
          i + 1,
          row.first_name,
          row.last_name,
          row.name,
          row.completions
        ], false)
      }
    }
    dt.fnDraw()
  }

  const renderDateSelector = () => {
    return (
      <div className='small-2 columns date-selector ae-completions'>
        <DateSelector
          from={from}
          to={to}
          getStats={fetchData}
          selectedRange={selectedRange}
          dateRanges={dateRanges}
        />
      </div>
    )
  }

  return (
    <div className='list-tab'>
      <div className="campaigns-index">
        {renderDateSelector()}
        <table id="ae-completions-table">
          <thead>
            <tr>
              <th>#</th>
              { isAdmin && <th>Email Address</th>}
              <th>First Name</th>
              <th>Last Name</th>
              <th>Specialty</th>
              <th># Completions</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  )
}

export default Index;




