import React, { useState, useEffect } from 'react';
import ArticleReads from './ArticleReads';
import ReadsChart from './ReadsChart';
import WorldMap from './WorldMap';
import UsMap from './UsMap'
import Citations from './Citations';
import Plummary from './Plummary';

const MetricsTab = ({metricsPaths, article, fireAmplitude}) => {

  return (
    <div className="row">
      <div className="small-12 columns tab-content-container">
        <div className="metrics-container">
          <ArticleReads 
            metricsPaths={metricsPaths}
            boostablePath={article.boostable_path}
            fireAmplitude={fireAmplitude}
          />
          <ReadsChart
            metricsPaths={metricsPaths}
            publishedAt={article.published_at}
            firstMonth={article.first_month}
          />
          <WorldMap 
            metricsPaths={metricsPaths}
          />
          <UsMap 
            metricsPaths={metricsPaths}
          />
          <Citations 
            article={article}
          />
          <Plummary
            article={article}
          />
        </div>
      </div>
    </div>
  )
}

export default MetricsTab;