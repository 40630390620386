var createReactClass = require('create-react-class');
import React from 'react';
import Main from '../Publishing/UserTool/Main';

const AffiliationsModal = createReactClass({
  render: function() {
    return (
      <div className="reveal-modal medium" data-reveal id="verification-modal">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4 className='reg'>Verify Author Details</h4>
            <p>Please check that all author names and affiliations are accurate and uniform. The names and affiliations must be approved before you can finish your copy editing assignment.</p>
            <Main isVerification={true} {...this.props.affiliations}/>
          </div>

          <div className="modal-footer">
            <a className="button alert pull-left" onClick={() => this.props.updateState({ownershipId: this.props.editor.ownership_id, newState: 'started'})}>Reject</a>

            <a href="#" className="button secondary cancel-modal"> Cancel </a>
            <a className="button primary" onClick={() => this.props.updateState({ownershipId: this.props.editor.ownership_id, newState: this.props.newState, verify: true})}>Approve</a>
          </div>
        </div>
      </div>
    )
  }
})

export default AffiliationsModal;


