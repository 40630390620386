const constants = {
  BEGIN_AJAX_FETCH: "BEGIN_AJAX_FETCH",
  END_AJAX_FETCH: "END_AJAX_FETCH",
  SEARCH: "SEARCH",
  SELECT_ALL: "SELECT_ALL",
  SELECT_VISIBLE: "SELECT_VISIBLE",
  DESELECT_ALL: "DESELECT_ALL",
  DESELECT_VISIBLE: "DESELECT_VISIBLE",
  CHECK: "CHECK",
  UNCHECK: "UNCHECK",
  ADD_FILTERS: "ADD_FILTERS",
  CLEAR_FILTERS: "CLEAR_FILTERS"
}

export default constants;