import React, { useEffect, useState } from 'react';
import NpiRows from '../UserProfileModals/NpiRows';

const NpiModal = ({ npiData, user, npiPath, updateProfilePath }) => {

  useEffect(() => {
    fireAmplitude({event_type: 'npiModalAppeared', user_id: user.id, numMatches: npiData.length })
  }, [])

  const [results, setResults] = useState(npiData)
  const [selected, setSelected] = useState(null)
  const [firstName, setfirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [state, setState] = useState("")
  const [screen, setScreen] = useState(npiData.length > 0 ? "select" : "search")
  const [query, setQuery] = useState('')
  const [confirming, setConfirming] = useState(false)
  const [searching, setSearching] = useState(false)
  const [searched, setSearched] = useState(false)
  const [error, setError] = useState('')

  function handleChange(evt) {
    setSelected(evt.target.value);
  }

  function handleConfirm(evt) {
    fireAmplitude({ event_type: 'npiModalConfirmed', user_id: user.id, numMatches: npiData.length, screen: screen, selected: selected});
    if (screen == 'select' && selected == 'none') {
      setResults([]);
      setSelected(null);
      setScreen('search');
    } else {
      setConfirming(true)
      var payload = {
        section: "NpiForm",
        user: { country: "USA", npi: selected },
        ignoreStep: true
      }
      $.ajax({
        dataType: "JSON",
        url: updateProfilePath,
        data: payload,
        type: "PUT"
      }).done(function(res) {
        window.location.reload();
      })
    }
  }

  function renderNpiResults() {
    if (searching) {
      return (
        <div>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
    else if (results.length > 0) {
      return (
        <React.Fragment>
          <h6 style={{textAlign: 'left'}}>Select your ID from the list.</h6>
          <div className="npi-table-container">
            <table className="npi-table">
              <tbody>
                { renderNpiRows() }
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )
    } else if (searched) {
      return (
        <React.Fragment>
          <h6>No results found. Please try again.</h6>
        </React.Fragment>
      )
    }
  }

  function renderNpiRows() {
    return results.map(function(person) {
      return (
              <NpiRows
                key={person.number}
                id={person.number}
                name={person.basic.name}
                spec={person.taxonomies[0].desc}
                address={formatAddress(person)}
                phoneNumber={person.addresses[0].telephone_number}
                handleChange={handleChange}
              />
      )
    })
  }

  function renderResultsTable() {
    var noneText;
    var buttonText;
    var buttonClass;
    if (screen == 'select') {
      noneText = 'None of the above.'
      buttonText = 'Confirm'
      buttonClass = 'primary'
    } else {
      noneText = "I don't have an identification number."
      buttonText = 'Save'
      buttonClass = 'special'
    }
    return (
      <div className="npi-results-container">
        { renderNpiResults() }
        <div className="npi-modal-table-footer">
          <table className='no-npi'>
            <tr>
              <td><input id="noNpi" type="radio" name="npi" value="none" onChange={handleChange}/></td>
              <td><label htmlFor="noNpi">{noneText}</label></td>
            </tr>
          </table>
          <a className={`button right ${buttonClass}`} disabled={selected === null || confirming} onClick={ handleConfirm }>{buttonText}</a>
        </div>
      </div>
    )
  }

  function formatAddress(person) {
    var info = person.addresses[1]
    return `${info.address_1}, ${info.address_2} ${info.city}, ${info.state}`
  }

  function fetchNpiResults(action) {
    setSearching(true);
    $.ajax({
      type: 'GET',
      url: npiPath,
      data: {first_name: firstName, last_name: lastName, state: state, id: query},
      success: function(res) {
        fireAmplitude({ event_type: 'npiModalSearch', user_id: user.id, numMatches: npiData.length, numResults: res.results.length, action: action, firstName: firstName, lastName: lastName, state: state, npi: query})
        setSearching(false);
        setSearched(true);
        setError('')
        setResults(res.results);
      },
      error: function() {
        setSearching(false);
        setError("Something went wrong, please try again.")
      }
    })
  }

  function renderErrorMessage() {
    if (error) {
      return <h6 style={{color: 'red'}}>{error}</h6>
    }
  }

  function renderStateOptions() {
    return (
      <select onChange={(e) => setState(e.target.value)} name="state" value={state}>
        <option value="">Please Select</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District Of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </select>
    )
  }

  function renderSearchFields() {
    return (
      <div>
        <div className="row">
        <div className="large-4 columns"><h6 className="reg">Enter your NPI Number:</h6></div>
        </div>
        <div className="row">
          <div className="large-4 columns">
              <input name="id" onChange={(e) => setQuery(e.target.value)} type="text" placeholder="Must be exactly 10 digits" value={query}></input>
          </div>
          <div className="large-2 columns end">
          <a className={'button ' + (query.length === 10 || searching ? '' : ' disabled')} onClick={() => fetchNpiResults("lookup")}>Look up</a>
          </div>
        </div>
        <div className="row centered" style={{margin: '15px 0 15px 0'}}>- or -</div>
        <div className="row">
        <div className="large-4 columns"><h6 className="reg">Search for your NPI Number:</h6></div>
        </div>
        <div className="row">
          <div className="large-4 columns">
            <label>First Name:</label>
            <input name="firstName" onChange={(e) => setfirstName(e.target.value)} type="text" value={firstName}></input>
          </div>
          <div className="large-4 columns end">
            <label>Last Name:</label>
            <input name="lastName" onChange={(e) => setLastName(e.target.value)} type="text" value={lastName}></input>
          </div>
        </div>
        <div className="row">
          <div className="large-4 columns">
            <label>State:</label>
          </div>
        </div>
        <div className="row">
          <div className="large-4 columns">
            {renderStateOptions()}
          </div>
          <div className="large-2 columns end">
          </div>
          <a className={'button'} onClick={() => fetchNpiResults("search")} disabled={ searching || (firstName.length == 0 || lastName.length == 0) && state.length == 0 }>Search</a>
        </div>
      </div>
    )
  }

  function renderBody() {
    if (screen == 'select') {
      return renderResultsTable();
    } else {
      return (
        <React.Fragment>
          { renderErrorMessage() }
          { renderSearchFields() }
          { renderResultsTable() }
        </React.Fragment>
      )
    }
  }

  function fireAmplitude(eventParams) {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: eventParams
    })
  }

  return (
    <div  className='reveal-modal medium' data-reveal data-options='close_on_background_click:false;'>
      <div className='modal-body'>
        <div className='modal-close'>
          <a className="cancel-modal" onClick={() => fireAmplitude({event_type: 'npiModalClosed', user_id: user.id, numMatches: npiData.length, screen: screen})}>
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className='modal-content'>
          <div className='centered'>
            <img src="https://public.cureus.com/hospital-staff.jpg"/>
            <h4>Help us provide you with the best possible Cureus experience.</h4>
            <p>Verify your identity by entering your NPI number below. This allows us provide you with a custom experience while helping underwrite some of our editorial costs and continue offering free publication. (And don’t worry - we promise never to sell your information.)</p>
            <br/>
            { renderBody() }
          </div>
        </div>
      </div>
    </div>
  )

}

export default NpiModal;