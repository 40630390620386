import React, { useState, useEffect } from 'react';
import ReadsCard from './ReadsCard';

const ArticleReads = ({metricsPaths, boostablePath, fireAmplitude}) => {
  const [readData, setReadData] = useState({})

  useEffect(() => {
    fetchReads()
  }, [])

  const fetchReads = () => {
    $.ajax({
      type: "GET",
      url: metricsPaths.reads,
      data: {type: 'reads'},
      dataType: "JSON",
      success: function(res) {
        setReadData(res)
      }
    })
  }

  return (
    <div className="row small-collapse">
      <div className="large-12 columns">
        <div className="metrics-section">
          <h3 className="reg">Total Article Reads</h3>
          <p>
            Article reads consist of online article views and PDF downloads. Cureus data is delayed by 24 hours and PMC data is delayed by approximately one month.
          </p>
          <div className="row small-collapse">
            <div className="large-6 medium-6 columns hide-for-small">
              <div className="reads-card large">
                <h4>{readData.total}</h4>
                {boostablePath && 
                  <a className="social-boost" href={boostablePath} onClick={() => fireAmplitude('wantMoreReadsClicked', {source: 'articleMetrics'})}>Want more reads?</a>
                }
                <h6>Total Article Reads</h6>
              </div>
            </div>
            <div className="large-6 medium-6 small-12 columns">
              <ReadsCard 
                cssClass={'top-box'}
                label={"Article Views"}
                source={"Cureus"}
                value={readData.cureus_views}
              />
              <ReadsCard 
                cssClass={'top-box right-box'}
                label={"PDF Downloads"}
                source={"Cureus"}
                value={readData.cureus_downloads}
              />
              <ReadsCard 
                cssClass={''}
                label={"Article Views"}
                source={"PMC"}
                value={readData.pmc_views}
              />
              <ReadsCard 
                cssClass={'right-box'}
                label={"PDF Downloads"}
                source={"PMC"}
                value={readData.pmc_downloads}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleReads;