import React, { useState, useEffect } from 'react';

const Citations = ({article}) => {
  const [citationData, setCitationData] = useState(null)

  useEffect(() => {
    fetchCitations()
  }, [])

  const fetchCitations = () => {
    $.ajax({
      type: "GET",
      url: `/articles/${article.id}/fetch_citations`,
      data: {type: 'reads'},
      dataType: "JSON",
      success: function(res) {
        setCitationData(res.citations)
      }
    })
  }

  const renderCitations = () => {
    if(!citationData) {
      return <div/>
    }
    if(citationData.length !== 0) {
      return (
        <>
          <h5>Crossref Citations ({citationData.length})</h5>
          <div className="crossref-citations">
            <ol>
            {citationData.map(function(citation) {
              return <li>{citation.formatted} &nbsp; <a href={citation.link} target="_blank">Link</a></li>
              })
            }
            </ol>
          </div>
        </>
      )
    } else {
      return (
        <div className="text-center">
          <p className="no-margin-bottom">No Crossref citations have been found.</p>
        </div>
      )
    }
  }

  return (
    <div className="row small-collapse">
      <div className="large-12 columns">
        <div className="metrics-section citations">
          <h3 className="reg">Citations</h3>
          <p>
            Citation index reporting varies. Crossref citations are listed below and Google Scholar citations can be seen by clicking the corresponding button.
          </p>
          <a className="button g-scholar-button" href={`https://scholar.google.com/scholar?hl=en&lr=&q=${article.title}`} target="_blank">Google Scholar &nbsp; <i className="fas fa-arrow-right"/></a>
          <hr/>
          {renderCitations()}
        </div>
      </div>
    </div>
  )
}

export default Citations;