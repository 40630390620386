import React from 'react';
const Article = ({article}) => {
  return (
    <div className="article-box">
      <span className="type">{article.type}</span>
      <h4>{article.title}</h4>
      <div className="authors" dangerouslySetInnerHTML={{__html: article.author_links}}/>
      <div className="categories">
        Categories: {article.categories}
      </div>
      <div className="abstract">
        <h6>Abstract</h6>
        <p dangerouslySetInnerHTML={{__html: article.abstract}}/>
      </div>
    </div>
  )
}

export default Article;