import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';

const InProgress = ({channels}) => {
  const [tableData, setTableData] = useState([]);
  const [channelId, setChannelId] = useState(channels[0].id)

  useEffect(() => {
    initializeTable()
  }, [])

  useEffect(() => {
    fetchData();
  }, [channelId])

  useEffect(() => {
      drawTable()
  }, [tableData])

  const fetchData = () => {
    $.ajax({
      type: 'GET',
      url: '/admin/channels/articles_table_data',
      data: {channel_id: channelId, dataType: "in_progress"},
      success: function(data) {
        setTableData(data)
      }
    });
  }

  const initializeTable = () => {
    $("#in-progress").DataTable({
      destroy: true,
      columns: setColumns(),
      order: [],
      searching: false,
      dom: '<"row header-bar"<"large-6 columns"><"large-6 end columns select">><"table"t>r<"row bottom">',
      paging: false
    });

    renderSelect()
  }

  const drawTable = () => {
    let dt = $("#in-progress").DataTable()
    dt.api().clear()
    mapColumns(dt)
    dt.api().draw()
    $(document).foundation("reflow")
  }

  const renderSelect = () => {
    ReactDom.render(selectElement(), document.querySelector(".select"))
  }

  const selectElement = () => {
    let options = channels.map( (channel, i) => {
      return <option key={i} value={channel.id}>{channel.title}</option>
    })

    return (
      <select onChange={(e) => {setChannelId(e.target.value)}}>
        {options}
      </select>
    )
  }

  const setColumns = () => {
    return [
      {width: '100px'},
      {width: '100px'}
    ]
  }

  const mapColumns = (dt) => {
    tableData.forEach((data) => {
      let label;
      if(data.tooltip.length) {
        label = `<span data-tooltip title='${data.tooltip}'>${data.label}</span>`
      } else {
        label = `<span>${data.label}</span>`
      }
      dt.fnAddData([label, data.count])
    })
  }

  return (
    <div className="in-progress">
      <h5>In Progress Overview</h5>
      <table className="table-wrapper" id="in-progress">
        <thead>
          <tr>
            <th>Description</th>
            <th>Articles</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  )
}
export default InProgress;