var createReactClass = require('create-react-class');
import React from 'react';

const CheckboxToggler = createReactClass({
  handleChange: function() {
    var isChecked = $(this.refs.checkbox).is(":checked");

    if(isChecked) {
      this.props.onSelectAll();
    } else {
      this.props.onDeselectAll();
    }
  },

  render: function() {
    return (
      <div className='email-list-tool-checkbox-toggler'>
        <input ref='checkbox' type='checkbox' onChange={this.handleChange} checked={this.props.isChecked()} />
      </div>
    )
  }
});

export default CheckboxToggler;