import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Redirect, Switch, Route, Link } from "react-router-dom";
import ChannelsList from './ChannelsList';
import ChannelAdminEditors from './ChannelAdminEditors';
import Articles from './Articles';
import Performance from './Performance';
import InProgress from './InProgress';

const Main = ({canCreateChannel, dateRanges, channels, currentUserEmail}) => {
  const [activeTab, setActiveTab] = useState('channels_list')

useEffect(() => {
  setActiveTab(window.location.hash.replace(/^#!\/?|\/$/g, ''))
}, [])

  const link = (tab, headerName) => {
    return (
      <Link to={tab}>
        <li className={"tab-title " + (activeTab === tab ? 'active' : '')} onClick={() => setActiveTab(tab)}>
          <a className="dashboard">{headerName}</a>
        </li>
      </Link>
    )
  }

  return (
    <Router hashType={"hashbang"}>
      <div className="row">
        <div className="large-12 columns cureus-tabs-container">
          <div className="cureus-tabs secondary-tabs dashboard homepage">
            <ul className="tabs">
            {link('channels_list', 'OVERVIEW')}
            {link('channel_admins_editors', 'CHANNEL ADMINS & EDITORS')}
            {link('articles', 'ARTICLE UPDATES')}
            {link('performance', 'PERFORMANCE')}
            {link('in_progress', 'RECENT ACTIVITY')}

            {canCreateChannel && <button className="button primary small" data-reveal-id="new-channel-modal" href="/admin/channels/new">New Channel</button>}
            </ul>
          </div>
        </div>
          <Switch>
          <Redirect exact from="/" to="/channels_list" />
            <Route path='/channels_list'>
              <ChannelsList/>
            </Route>
            <Route path='/channel_admins_editors'>
              <ChannelAdminEditors dateRanges={dateRanges}/>
            </Route>
            <Route path='/articles'>
              <Articles/>
            </Route>
            <Route path='/performance'>
              <Performance dateRanges={dateRanges} currentUserEmail={currentUserEmail}/>
            </Route>
            <Route path='/in_progress'>
              <InProgress channels={channels} dateRanges={dateRanges}/>
            </Route>
          </Switch>
      </div>
    </Router>
  )
}

export default Main;