import React, { Component } from 'react';

class ProgressCard extends Component {
  renderPreviousInfo() {
    if(this.props.current - this.props.prev > 0) {
      return (
        <div className="prev-info up">
          <h1><i className="fas fa-caret-up"/> {this.props.current - this.props.prev}</h1>
        </div>
      )
    } else if(this.props.current - this.props.prev < 0) {
      return (
        <div className="prev-info down">
          <h1><i className="fas fa-caret-down"/> {this.props.current - this.props.prev}</h1>
        </div>
      )
    } else {
      return (
        <div className="prev-info">
          <h1>- 0</h1>
        </div>
      )
    }
  }
  
  render() {
    return (
      <div className="row progress-stats-container">
          <div className="large-6 columns">
            <h4>{this.props.current} out of {this.props.total}</h4>
          </div>
          <div className="large-6 columns">
            <h5 className="reg">{this.props.title}</h5>
            {this.renderPreviousInfo()}
          </div>
        </div>
    )
  }
}

export default ProgressCard;