import React, { useState, useEffect } from 'react';
import Question from './Question';

const Questions = ({questions}) => {
  
  return (
    <div className='faquestions-container'>
      <h2>Collection FAQ</h2>
      {questions.map((question) => {
        return <Question question={question}/>
      })}
    </div>
  )
}

export default Questions;