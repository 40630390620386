var createReactClass = require('create-react-class');
import React from 'react';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const FilterNotification = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("Table", "FilterTool")
  ],

  getStateFromFlux: function() {
    var filterCount = this.getFlux().store("FilterTool").getState().filters.length;
    var totalRecordCount = this.getFlux().store("Table").getState().totalRecordCount;
    var fetchingData = this.getFlux().store("Table").getState().fetchingData;
    var visible = this.getFlux().store("FilterTool").hasValidFilters();
    var fetching = false;

    if(!fetchingData) {
      fetching = true;
    }

    return {
      filterCount: filterCount,
      totalRecordCount: totalRecordCount,
      isFetching: fetching,
      isVisible: visible
    }
  },

  handleEdit: function(e) {
    this.props.onEdit();
    e.preventDefault();
  },

  handleClearFilters: function(e) {
    this.getFlux().actions.clearFilters();
    e.preventDefault();
  },

  render: function() {
    if(!this.state.isVisible){ return null; }

    if(this.state.filterCount > 0) {
      if(this.state.isFetching) {
        var notification = 
          <div>
            <i className='fa fa-times-circle' onClick={this.handleClearFilters}></i> There are <strong>{this.state.totalRecordCount} results</strong>. <a onClick={this.handleEdit}>Edit filters</a>
          </div>
      } else {
        var notification = <span>Fetching results..</span>
      }

      return (
        <div className='row'>
          <div className='large-12 columns'>
            <div className='filter-notification-box'>
              {notification}
            </div>
          </div>
        </div>
      )
    } else {
      return <div></div>;
    }
  }
});

export default FilterNotification;