import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import FeaturedContentModal from './FeaturedContentModal'
import MetricsCard from './MetricsCard'
import ArticleCard from './ArticleCard'
import NewsCard from './NewsCard'
import CureusUCard from './CureusUCard'


const FeaturedContentCard = ({ setSaveButtonEnabled, specialties, originalCard, cards, cardIndex, setCards, articleExistsPath, newsroomPostDetailsPath }) => {

  const [card, setCard] = useState({...originalCard});
  const [visibleCardType, setVisibleCardType] = useState("");

  const deleteCard = (e) => {
    if (window.confirm("Are you sure you want to delete this card?")) {
      let shallowCardsCopy = [...cards]
      shallowCardsCopy.splice(cardIndex, 1);
      setCards(shallowCardsCopy)
      setSaveButtonEnabled(true)
    }
  }

  const editCard = (e) => {
    e.preventDefault()
    Modal.open({
      children: <FeaturedContentModal newsroomPostDetailsPath={newsroomPostDetailsPath}
       setSaveButtonEnabled={setSaveButtonEnabled}
       articleExistsPath={articleExistsPath}
       setOriginalCard={setCard}
       cards={cards}
       cardIndex={cardIndex}
       setCards={setCards}
       givenCard={card}
       />
    });
  }

  return (
    <div className="row card admin-content-card drag-handle" >
      <button className="edit-button" onClick={(e) => editCard(e)}><i className="fa fa-pencil-alt"></i>EDIT</button>
      <button className="delete-button" onClick={(e) => deleteCard(e)}>DELETE</button>
      
      {card.content_type != '' && card.content_type &&
        <div className="featured-content-container">
  
          {card.content_type == 'metrics' &&
            <MetricsCard setVisibleCardType={setVisibleCardType} cardDetails={card} />
          }
          
          {card.content_type == 'news' &&
            <NewsCard setVisibleCardType={setVisibleCardType} cardDetails={card} />
          }
  
          {card.content_type == 'article' &&
            <ArticleCard setVisibleCardType={setVisibleCardType} cardDetails={card} />
          }
  
          {card.content_type == 'cureus-u' &&
            <CureusUCard setVisibleCardType={setVisibleCardType} cardDetails={card} />
          }
        </div>
      }

    </div>
  )
}

export default FeaturedContentCard;