import React, {useState, useEffect} from 'react';
import { HashRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

const GuestEditor = ({renderCollectionTable, fetchCollections, guestEditorPublishedCount, guestEditorApprovalCount, guestEditorDraftCount, guestEditorRejectedCount, renderGuestEditorTable, renderGuestEditorDraftTable}) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname)

  useEffect(() => {
    fetchCollections(location.pathname.split('/')[2], 'GuestEditorCollection');
  }, [activeTab])

  return (
    <div className='small-12 columns admin-collection'>
      <div className="row">
        <div className="large-12 columns">
          <div className="cureus-tabs secondary-tabs dashboard homepage">
            <ul className="tabs">
              <Link to="/guest_editor/draft">
                <li className={"tab-title " + (activeTab === '/guest_editor/draft' ? 'active' : '')} onClick={() => setActiveTab('/guest_editor/draft')}>
                  <a className="dashboard">DRAFT ({guestEditorDraftCount})</a>
                </li>
              </Link>
              <Link to="/guest_editor/editor_approval">
                <li className={"tab-title " + (activeTab === '/guest_editor/editor_approval' ? 'active' : '')} onClick={() => setActiveTab('/guest_editor/editor_approval')}>
                  <a className="dashboard">EDITOR APPROVAL ({guestEditorApprovalCount})</a>
                </li>
              </Link>
              <Link to="/guest_editor/published">
                <li className={"tab-title " + (activeTab === '/guest_editor/published' ? 'active' : '')} onClick={() => setActiveTab('/guest_editor/published')}>
                  <a className="dashboard">PUBLISHED ({guestEditorPublishedCount})</a>
                </li>
              </Link>
              <Link to="/guest_editor/rejected">
                <li className={"tab-title " + (activeTab === '/guest_editor/rejected' ? 'active' : '')} onClick={() => setActiveTab('/guest_editor/rejected')}>
                  <a className="dashboard">REJECTED ({guestEditorRejectedCount})</a>
                </li>
              </Link>
            </ul>
          </div>
        </div>
        <Switch>
          <Route path={['/guest_editor/published']} render={renderCollectionTable} />
          <Route path={['/guest_editor/draft']} render={renderGuestEditorDraftTable} />
          <Route path={['/guest_editor/draft', '/guest_editor/editor_approval', '/guest_editor/rejected']} render={renderGuestEditorTable} />
        </Switch>
      </div>
    </div>
  )
};

export default GuestEditor;