import {Cureus} from '../Cureus';

Cureus.Amplitude = function(options) {
  if (!Cureus.botCheck()) {
    this.requiredParams = {
      target_type: true,
      target_id: true,
      actor_id: true,
      event_type: true
    }

    this.options = options
    this.createEvent()
  }
}

Cureus.Amplitude.prototype.createEvent = function() {
  var self = this;
  $.ajax("/amplitudes", {
    dataType: "JSON",
    type: "POST",
    data: self.options
  })
}
