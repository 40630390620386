import React, { useState, useEffect } from 'react';
import { Cureus } from '../../Cureus';
import Reviewership from './Reviewership';
import InfoWidget from './InfoWidget';
import Pages from '../Shared/Pagination/Pages';

const Main = ({invited, reviewing, completed, expired, reviewershipsPath, infoWidget, keywordProps}) => {

  const [reviewershipStates, setReviewershipStates] = useState('invited');
  const [reviewerships, setReviewerships] = useState([]);
  const [spinning, setSpinning] = useState(true);
  const [paginationData, setPaginationData] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true)

  useEffect(() => {
    Cureus.ListSlider.init();
    Cureus.SplitButton.init();
    fetchReviewerships();
  }, [reviewershipStates, currPage])

  const fetchReviewerships = () => {
    setSpinning(true)
    setReviewerships([])
    if(!initialLoad) {
      let ele = document.getElementById('secondary-nav-tabs')
      if(ele) {
        ele.scrollIntoView({behavior: 'smooth'})
      }
    }
    $.ajax({
      type: 'GET',
      url: reviewershipsPath,
      data: {reviewer_states: reviewershipStates, currPage: currPage},
      success: function(data) {
        setInitialLoad(false)
        setPaginationData(data.paginationData)
        setSpinning(false)
        setReviewerships(data.reviewerships)
      }
    });
  }

  const handleStateChange = (newState) => {
    setCurrPage(1)
    setReviewershipStates(newState)
  }

  const renderNoReviewershipsMessage = () => {
    return (
      <div className="small-12 columns bordered gray-container text-center">
        <h3 className="subhead" style={{marginBottom: '0px', fontSize: '18px', fontWeight: 'bold'}}>You do not have any articles to review.</h3>
      </div>
    )
  }

  const renderReviewerships = () => {
    if(reviewerships.length === 0 && !spinning) {
      return renderNoReviewershipsMessage()
    }
    return reviewerships.map((reviewership) => {
        return (
          <Reviewership
            reviewership={reviewership}
            key={"rev_row_" + reviewership.id}
          />
        )
    })
  }

  const renderCompleted = () => {
    if(completed > 0) {
      return (
        <li className={"tab-title-new inline " + (reviewershipStates === 'completed' ? 'active' : '')} onClick={() => handleStateChange('completed')}>
          <a className="dashboard new-sub-tab">COMPLETED ({completed})</a>
        </li>
      )
    }
  }

  const renderReviewing = () => {
    if(reviewing > 0) {
      return (
        <li className={"tab-title-new inline " + (reviewershipStates === 'reviewing' ? 'active' : '')} onClick={() => handleStateChange('reviewing')}>
          <a className="dashboard new-sub-tab">REVIEWING ({reviewing})</a>
        </li>
      )
    }
  }

  const renderExpired = () => {
    if(expired > 0) {
      return (
        <li className={"tab-title-new inline " + (reviewershipStates === 'expired' ? 'active' : '')} onClick={() => handleStateChange('expired')}>
          <a className="dashboard new-sub-tab">EXPIRED ({expired})</a>
        </li>
      )
    }
  }

  const renderSpinner = () => {
    if(spinning) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  }

  const handlePageSelect = (page) => {
    setCurrPage(page)
  }

  const renderPagination = () => {
    if(reviewerships.length > 0 || reviewerships.length > 10) {
      return (
        <div className="content-index-pagination small-12 columns" style={{marginTop: '25px'}}>
          <Pages
            paginationData={paginationData}
            handlePageSelect={handlePageSelect} 
          />
        </div>
      )
    }
  }

  return (
    <React.Fragment>
      <InfoWidget 
        infoWidget={infoWidget}
        keywordProps={keywordProps}
        view={'desktop'}
      />
      <div className="large-9 medium-12 small-12 columns articles-container" id="secondary-nav-tabs">
        <div className="small-12 columns nav-container bordered">
          <div className="row">
            <div className="large-11 columns">
              <div className="cureus-tabs secondary-tabs">
                <div className="tabs-container dashboard">
                  <div className="left-arrow new-dashboard">
                  <div className="show-for-small">
                      <i className="fas fa-angle-left fa-lg" id="left-arrow"/>
                    </div>
                  </div>
                  <div className="tab-items">
                    <ul>
                      <li className={"tab-title-new inline " + (reviewershipStates === 'invited' ? 'active' : '')} onClick={() => handleStateChange('invited')}>
                        <a className="dashboard">INVITED ({invited})</a>
                      </li>
                      {renderReviewing()}
                      {renderCompleted()}
                      {renderExpired()}
                    </ul>
                  </div>
                  <div className="right-arrow new-dashboard">
                    <div className="show-for-small">
                      <i className="fas fa-angle-right fa-lg" id="right-arrow"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns cards-container">
          {renderSpinner()}
          {renderReviewerships()}
          {renderPagination()}
        </div>
      </div>
      <InfoWidget 
        infoWidget={infoWidget}
        keywordProps={keywordProps}
        view={'mobile'}
      />
    </React.Fragment>
  )

}

export default Main;