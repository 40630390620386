import React, { useEffect } from 'react';
import TableOfContents from "./TableOfContents";
import Siq from "./Siq";
import Categories from "./Categories";
import Keywords from "./Keywords";
import { useLocation } from "react-router-dom";
import Ad from '../../Shared/Ad';

const Sidebar = ({tableOfContents, article, articleRatingsAndSiq, setArticleRatingsAndSiq, siqWidget, isUserSignedIn, preview, retracted, page, fireAmplitude, sidebarAd}) => {
  const location = useLocation();

  useEffect(() => {
    if(typeof(sidebarAd) !== 'object') {
      $('#square-ad').append(sidebarAd)
    }
  }, []);

  const renderToc = () => {
    if(location.pathname === '/' && page === 'article')
    return (
      <>
        <hr/>
        <TableOfContents tableOfContents={tableOfContents}/>
      </>
    )
  }

  function renderAd() {
    if(typeof(sidebarAd) === 'object') {
      return (
        <div id="square-ad">
          <div className='cureus-ad-wrapper page-square-ad show-for-large-up' >
            <Ad
              props={ sidebarAd }
              size={ "square" }
            />
          </div>
        </div>
      )
    } else {
      return (
        <div id="square-ad"/>
      )
    }
  }

  return (
    <div className="small-2 columns show-for-large-up left-sidebar">
      <div className={"left-sidebar-content overflow " + (preview ? 'preview' : '')}>
        <div className="border-container">
          {!retracted && page === 'article' &&
            <Siq
              article={article}
              preview={preview}
              articleRatingsAndSiq={articleRatingsAndSiq}
              setArticleRatingsAndSiq={setArticleRatingsAndSiq}
              siqWidget={siqWidget}
              isUserSignedIn={isUserSignedIn}
              size="small"
              fireAmplitude={fireAmplitude}
            />
          }
          {renderToc()}
          <hr/>
          <Categories
            categories={article.categories}
            fireAmplitude={fireAmplitude}
          />
          <hr/>
          <Keywords
            keywords={article.keywords}
            fireAmplitude={fireAmplitude}
          />
        </div>
        {renderAd()}
      </div>
    </div>
  )
}

export default Sidebar