"use strict";
require('@rails/ujs').start();
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'foundation-sites'
import 'foundation-sites/js/foundation/foundation.accordion';
import 'foundation-sites/js/foundation/foundation.alert';
import 'foundation-sites/js/foundation/foundation.reveal';
// import 'foundation-sites/js/foundation/foundation.tooltip';
import '../../../vendor/assets/javascripts/foundation-tooltip';
import 'foundation-sites/js/foundation/foundation.equalizer';
import 'foundation-sites/js/foundation/foundation.dropdown';
import 'foundation-sites/js/foundation/foundation.joyride';
import 'foundation-sites/js/foundation/foundation.tab';
import '../stylesheets/application.scss';
import 'blueimp-file-upload/css/jquery.fileupload-ui.css';
import '../../../vendor/assets/javascripts/react-select';
import '../../../vendor/assets/javascripts/jquery.cookie';
import '../../../vendor/assets/javascripts/jquery.magnific-popup';
// jquery.numeric.min is only used in ReferenceTool/EditReference, is it better to import it there?
import '../../../vendor/assets/javascripts/jquery.numeric.min';
import '../../../vendor/assets/javascripts/scrollspy';

import 'jquery-ui'
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/slider';

import 'datatables.net'
import dt from 'datatables.net';
$.fn.DataTable = dt;

require.context('../src', true, /\.js/);
require.context("../images", true);
import { Cureus, showSaveNotification } from '../src/Cureus';
import { sticky } from '../src/Cureus/sticky';
import { getCookie, setCookie } from '../src/Cureus/Cookies';
import '../src/components/ChangeDetectorAlert';
import '../src/components/Popper';

function importAll (r) {
  r.keys().forEach(r);
}
importAll(require.context('../src/Cureus', true, /\.js/));

window.Cureus = Cureus;
window.sticky = sticky;
window.showSaveNotification = showSaveNotification;
window.getCookie = getCookie;
window.setCookie = setCookie;

// Support component names relative to this directory:
var componentRequireContext = require.context("src/components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

document.addEventListener('DOMContentLoaded', function(){
  $(document).foundation()
});