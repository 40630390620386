import React from 'react';

const SuspectPlagiarism = () => {
  return (
    <React.Fragment>
      <h1>What If I Suspect Plagiarism?</h1>
      <p>All articles submitted to Cureus are rigorously screened and filtered for evidence of plagiarism, but if you suspect academic fraud, you can flag the article for editorial review by selecting &quot;Flag this article&quot; from the dropdown menu in the My Reviews tab.</p>
      <p>Our goal is to make this process easy for author and reviewer alike; your feedback allows Cureus to continually evolve and improve. If you have specific questions during the review process or if you accidentally submit an incomplete review, please email <a href='mailto:support@cureus.com'>support@cureus.com</a>.</p>
    </React.Fragment>
  )
}

export default SuspectPlagiarism;