import React, { useEffect, useState } from 'react';
import { Cureus } from '../../Cureus';
import Modal from '../Modal';


const AddQualificationModal = ({ user_id, qualifications, updateProfilePath, userProfile, otherQualification }) => {

  const [userQualifications, setUserQualifications] = useState(userProfile.user_qualifications);

  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    allowSubmit()
  }, [buttonDisable])

  const renderQualifications = () => {
    
    let allQualifications = qualifications.map((qualArray) => {
      return qualArray.map((qual) => {
        let defaultChecked = userQualifications.includes(otherQualification.id);
        return (
          <div key={qual.id} className={`new-qualification large-4 columns left checks-column ${userQualifications.includes(parseInt(qual.id)) ? "checked" : ""}`}>
            <input value={qual.id} defaultChecked={defaultChecked} onClick={(e) => handleCheck(e)} 
            className="checkbox-new"
            type="checkbox" id={qual.id}/>
            <label htmlFor={qual.id}>{qual.title}</label>
          </div>
        )
      })
    })
    allQualifications.push([renderOther()])
    return allQualifications.map(function (group, idx) {
      return (
        <div className="row" key={idx}>
        {group}
        </div>
      )
    })
  }

  const renderOther = () => {
    let qual = otherQualification;
    let defaultChecked = userQualifications.includes(otherQualification.id);
    return (
      <div key={qual.id} className={`new-qualification large-4 columns left checks-column ${userQualifications.includes(parseInt(qual.id)) ? "checked" : ""}`}>
        <input value={qual.id} defaultChecked={defaultChecked} onClick={(e) => handleCheck(e)} 
        className="checkbox-new"
        type="checkbox" id={qual.id}/>
        <label htmlFor={qual.id}>{qual.title}</label>
      </div>
    )
  }

  const handleCheck = (e) => {
    let newChecked = userQualifications

    if (e.target.checked) {
      newChecked = [...userQualifications, parseInt(e.target.value)];
      setUserQualifications(newChecked);
    } else {
      let idx = newChecked.indexOf(parseInt(e.target.value));
      newChecked = newChecked.slice(0, idx).concat(newChecked.slice(idx + 1));
      setUserQualifications(newChecked);
    }
  }

  const allowSubmit = () => {
    if (userQualifications.length > 0) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }

  const renderButton = (payload, route, callback, bool = true) => {
    return <a onClick={() => handleSubmit(payload, route, callback)} className={"button special" + (buttonDisable ? 'disabled': '')}>Save</a>
  }

  const handleSubmit = (payload, route) => {
    $.ajax({
      dataType: "JSON",
      url: route,
      data: payload,
      type: "PUT"
    }).done(function(res) {
      Cureus.Flash.success("Qualifications successfully updated");
      Modal.close();
    })
  }

  return (
    <div className="reveal-modal medium" data-reveal data-options="close_on_background_click:false;close_on_esc:false;">
    <div className="modal-body">
      <div className="modal-content">
        <h4>Select your qualifications below</h4>
        <p>At least one qualification is required. Please select 'Other' if needed.</p>
        <div className="qualifications new-checkbox-container">
          <div className='heading'>
            <h6 className="reg">Qualifications (Check all that apply)*</h6>
          </div>
          <div className='checkbox-container'>
            {renderQualifications()}
          </div>
        </div>
        <div className='modal-footer'>
            {renderButton({user: {user_qualifications: userQualifications}, section: 'EditProfileForm', user_id: user_id}, updateProfilePath, () => forceUpdate(), !buttonDisable)}
        </div>
      </div>
    </div>
  </div>
  )

}

export default AddQualificationModal;
