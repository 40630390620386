var createReactClass = require('create-react-class');
import React from 'react';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const Search = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React)
  ],

  render: function (){
    return (
      <div className="email-list-search-control">
        <label>
          <input className="search" type="text" placeholder="Search" />
        </label>
      </div>
    )
  }
});

export default Search;