var createReactClass = require('create-react-class');
import React from 'react';

const BulkAddModal = createReactClass({
  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  render: function() {
    return (
      <div className="cureus-custom-multi-select-modal reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <div className='modal-form'>
              <h5>
                Create email list from selected lists
              </h5>

              <form ref='form' acceptCharset='UTF-8' className='custom' method='post' action={this.props.bulkAddPath}>
                <input name="utf8" type="hidden" value="✓" />
                <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
                <input type="hidden" name="refresh" value={this.props.refresh} />

                <label htmlFor='bulk-add-title'> Title </label>
                <input id='bulk-add-title' type='text' name='email_list[title]' required='required'/>

                <label htmlFor='bulk-add-description'> Description </label>
                <textarea name='email_list[description]' id='bulk-add-description'/>

                <input type='hidden' name='email_list_ids' value={this.props.emailListIds} />

                <div className='modal-footer'>
                  <a className='cancel-modal button secondary'>Cancel</a>
                  <input type='submit' value="Create list" className='button primary'/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default BulkAddModal;