import React, { useEffect } from 'react';
import Modal from '../Modal';
import BoostModal from "../Boost/BoostModal";
import { Cureus } from '../../Cureus';

const PublishedArticle = ({ article, renderStateSteps, renderAuthors, renderHamburgerMenu, isSubmitter, currentUserId,social_boost_a_id }) => {
  useEffect(() => {
    if(social_boost_a_id) {
      if(article.id === parseInt(social_boost_a_id)) {
        openBoostModal()
      }
    } else {
      let urlParams = new URLSearchParams(window.location.search)
      if(urlParams.has('boost')) {
        var button = document.querySelector(".promote")
        if(button) {
          button.click()
        }
      }
    }
  }, [])

  const checkForCampaign = () => {
    let urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('utm_campaign')
  }

  const fireAmplitude = (type) => {
    new Cureus.Amplitude({
      target_id: article.id,
      event_type: type,
      source: 'dashboard',
      target_type: "Article"
    })
  }

  const openBoostModal = () => {
    fireAmplitude("promoteButtonClicked")
    Modal.open({
      children: <BoostModal 
        articleId={article.id} 
        ampSource={checkForCampaign() || 'direct'}
        title={article.title}
        copyText={article.boostCopyText}
        subsequentBoost={article.subsequentBoost}
        currentUserId={currentUserId}
      />})
  }
  
  const PMCButton = () => {
    let style;
    if(article.canBoost) {
      if(article.subsequentBoost) {
        style = {width: '212px'}
      } else {
        style = {width: '179px'}
      }
    }
    if(article.canExpedited) {
      return (
          <a className="button success skip_embargo preview-button" style={style} data-reveal-id={'expedited-service-payment-modal-' + article.id} onClick={() => fireAmplitude("ExpeditedPMCPriceModal")}>Skip PMC Embargo</a> 
      )
    }
  }

  const boostButton = (secondRow) => {
    let style;
    if(secondRow) {
      style = {marginRight: '14px'}
    }
    if(article.canBoost) {
      if(article.subsequentBoost) {
        return (
          <a className="button preview-button green-patina" style={style} onClick={() => openBoostModal()}>Promote Again?</a>
        )
      } else {
        return (
          <a className="button preview-button promote success" style={style} onClick={() => openBoostModal()}><i className='fas fa-rocket'/>Promote</a>
        )
      }
    }
  }

  const firstButtonRow = () => {
    let button;
    if(article.canExpedited) {
      button = PMCButton()
    } else if(article.canBoost) {
      button = boostButton()
    } else {
      button = <a target="_blank" className="button preview-button" href={article.titlePath}><i className='fa fa-pencil-alt'/>View in Journal</a>
    }
    return (
      <div className="large-4 medium-4 small-12 columns published-buttons-container">
        {button}
      </div>
    )
  }

  const secondButtonRow = (small) => {
    let button;
    if(article.canExpedited && article.canBoost) {
      button = boostButton(true)
    }
    return (
      <div className="large-4 medium-4 small-12 columns published-buttons-container no-padding second">
        {button}
        {renderHamburgerMenu(false, small)}
      </div>
    )
  }

  const rendercoauthorIcon = () => {
    if(!isSubmitter) {
      return (
        <span className="has-tip tip-bottom" data-tooltip="" id={"coauthor-notif-" + article.id} title="Co-authored article">
          <i className="fas fa-user-friends"/>
        </span>
      )
    }
  }

  const renderTitle = () => {
    return (
      <div className="title">
        <h5 className="title reg">
          {rendercoauthorIcon()}
          <a href={article.titlePath} target="_blank">{article.title}</a>
        </h5>
      </div>
    )
  }

  const smallView = () => {
    return (
      <div className="small-12 columns bordered article-box show-for-small-only">
        <div className="row title-container">
          <div className="small-12 columns">
            {renderTitle()}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <div className="type-container">
              <span className="type">{article.articleType}</span>
            </div>
          </div>
        </div>
        <div className="small-view-container">
          {renderStateSteps()}
        </div>
        <div className="row" className="small-view-container">
          <div className="small-12 columns text-left">
            {firstButtonRow()}
            {secondButtonRow('small')}
          </div>
        </div>
        <div className="row pmc-box-container">
          <div className="small-12 columns">
          <div dangerouslySetInnerHTML={{__html: article.publishedPmcEmphasis}}/>
          </div>
        </div>
        {renderAuthors()}
      </div>
    )
  }

  return (
    <React.Fragment>
      {smallView()}
      <div className="small-12 columns bordered article-box hide-for-small">
        <div className="row title-container">
          <div className="large-8 medium-8 small-6 columns">
            {renderTitle()}
          </div>
          {firstButtonRow()}
        </div>
        <div className="row">
          <div className="large-8 medium-8 small-4 columns">
            <div className="type-container">
              <span className="type">{article.articleType}</span>
            </div>
          </div>
          {secondButtonRow()}
        </div>
        {renderStateSteps()}
        <div className="row pmc-box-container">
          <div className="large-9 medium-8 small-12 columns">
          <div dangerouslySetInnerHTML={{__html: article.publishedPmcEmphasis}}/>
          </div>
        </div>
        {renderAuthors()}
      </div>
    </React.Fragment>
  )
}

export default PublishedArticle;