var createReactClass = require('create-react-class');
import React from 'react';

const CategorySelector = createReactClass({
  getInitialState: function() {
    return {
      topIdx: 0,
    }
  },

  renderSelections: function() {
    var self = this;
    let topIdx = this.state.topIdx;
    return this.props.categoryIds.slice(topIdx, topIdx+10).map(function(id) {
      let item = self.props.categoryMap[id]
      item = item.split('/').join('/\n')
      let className = id == self.props.selectedId ? 'selected' : ''
      return (
      <tr key={id}>
        <td className={className}>
          <a id={id} onClick={self.props.changeSelectedId}>{item}</a>
        </td>
      </tr>
      )
    })
  },

  renderUpArrow: function() {
    let disabled = this.state.topIdx == 0 ? 'disabled ' : ''
    return (
      <div className={disabled + "arrow medium-12 columns centered"}>
        <i onClick={this.upArrowClicked} className="fas fa-arrow-alt-circle-up fa-lg"></i>
      </div>
    )
  },

  upArrowClicked: function() {
    if (this.state.topIdx == 0) {
      return;
    }
    var newTopIdx = Math.max(this.state.topIdx - 10, 0)
    this.setState({topIdx: newTopIdx})
  },

  renderDownArrow: function() {
    let numCategories = this.props.categoryIds.length
    let disabled = this.state.topIdx >= numCategories - 10 ? 'disabled ' : ''
    return (
      <div className={disabled + "arrow down medium-12 columns centered"}>
        <i onClick={this.downArrowClicked} className="fas fa-arrow-alt-circle-down fa-lg"></i>
      </div>
    )
  },

  downArrowClicked: function() {
    let numCategories = this.props.categoryIds.length
    if (this.state.topIdx > numCategories - 10) {
      return;
    }
    var newTopIdx = Math.min(this.state.topIdx + 10, numCategories-10)
    this.setState({topIdx: newTopIdx})
  },

  render: function() {
    return (
      <div className="small-12 medium-3 large-2 columns">
        <div className="category-selector">
          {this.renderUpArrow()}
          <table>
            <tbody>
              {this.renderSelections()}
            </tbody>
          </table>
          {this.renderDownArrow()}
        </div>
      </div>
    )
  }
})

export default CategorySelector;