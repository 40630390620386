var createReactClass = require('create-react-class');
import React from 'react';
import EarModal from './EarModal'
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const Main = createReactClass({
  getInitialState: function() {
    return {
      pending: false,
      ownershipUserId: this.props.ownershipUserId,
      ownershipState: this.props.ownershipState,
      ownershipUpdated: this.props.ownershipUpdated
    }
  },

  openModal: function() {
    var self = this;
    Modal.open({
      children: <EarModal
                  rating={self.props.rating}
                  submitterUrl={self.props.submitterUrl}
                  articleId={self.props.articleId}
                  comments={self.props.comments}
                  canManage={self.props.canManage}
                  flag={this.props.flag}
                  getPricePath={this.props.getPricePath}
                  sendQuoteEmailPath={this.props.sendQuoteEmailPath}
                  upfrontPricing={this.props.upfrontPricing}
                  articleSubmitter={this.props.articleSubmitter}
                  articlePrice={this.props.articlePrice}
                  editorCheckAdjustment={this.props.editorCheckAdjustment}
                  ithenticates={this.props.ithenticates}
                  badge={this.props.badge}
                  blockArticlePath={this.props.blockArticlePath}
                  compOrChannel={this.props.compOrChannel}
                  channelBadge={this.props.channelBadge}
                  title={this.props.title}
                  expired={this.props.expired}
                  previousDeferralReason={this.props.previousDeferralReason}
                  isOriginalArticle={this.props.isOriginalArticle}
                  oaPath={this.props.oaPath}
                  reasons={this.props.reasons}
                  blockReasons={this.props.blockReasons}
                  previewPath={this.props.previewPath}
                  ithenticatePercent={this.props.ithenticatePercent}
                  ithenticateUrl={this.props.ithenticateUrl}
                />
    });
  },

  claim: function() {
    var self = this;
    this.setState({pending: true}, () => {
      $.ajax({
        type: 'PUT',
        url: `/admin/articles/${this.props.articleId}/claim`,
        data: {content_type: 'submissions', take_over: 'true'},
        dataType: 'json',
        success: function(data) {
          if (data.success) {
            self.setState({ownershipUserId: data.ownershipUserId, pending: false})
          }
          else {
            self.setState({pending: false, ownershipUserId: data.ownershipUserId, ownershipState: data.ownershipState, ownershipUpdated: data.ownershipUpdated}, () => {
              Cureus.Flash.alert(data.message);
              $(window).scrollTop(0);
            })
          }
        }
      })
    })
  },

  canDoEditorCheck: function() {
    return this.props.currentUserId === this.state.ownershipUserId;
  },

  isUnclaimed: function() {
    return this.props.systemUserId === this.state.ownershipUserId;
  },

  className: function() {
    let classes = "button success tiny"
    if (this.state.pending) {
      return classes + " disabled"
    }
    return classes
  },

  renderClaimOverride: function() {
    if (this.props.isCureusAdmin) {
      let className = "button special tiny"
      if (this.state.pending) {
        className = className + " disabled"
      }
      return (
        <>
          <a className={this.className()} onClick={this.openModal}>EDITOR CHECK</a>
          <br/>
          <a className={className} onClick={this.claim}>CLAIM</a>
        </>
      )
    }
  },

  render: function() {
    if (this.canDoEditorCheck()) {
      return <a className={this.className()} onClick={this.openModal}>EDITOR CHECK</a>
    } else if (this.isUnclaimed()) {
      return <a className={this.className()} onClick={this.claim}>CLAIM EDITOR CHECK</a>
    } else {
      return <div>{this.renderClaimOverride()}</div>
    }
  }
})

export default Main;