import React from 'react';

const Checkbox = ({admin, handleSelectAdmin, recipients}) => {
  const isChecked = () => {
    return recipients.includes(admin.email)
  }

  return (
    <div className="checkbox">
      <label>
        <input type="checkbox" checked={isChecked()} onChange={handleSelectAdmin} value={admin.email} />
        &nbsp;{admin.email}
      </label>
    </div>
  )
}

export default Checkbox