import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import ConfirmationModal from '../ConfirmationModal'

const DeleteButton = ({widgetId, handleDeleteWidget, className=""}) => {

  const handleOpenModal = () => {
    Modal.open({
      children: <ConfirmationModal
                  handleSubmit={() => handleDeleteWidget(widgetId) }
                  modalSize="small channel-design-modals"
                />
    })
  }

  return (
    <img className={"action-buttons channels " + className}  src="https://public.cureus.com/channel_design/delete_widget.png" onClick={handleOpenModal}/>
  )
}

export default DeleteButton;