import React from 'react';

const WallOfShameRow = ({data}) => {

  const renderAffiliation = () => {
    return (
      <div>
        <strong>
          {data.affiliation_department}{(data.affiliation_city || data.affiliation_city || data.affiliation_institution) && <span>,&nbsp;</span>}
        </strong>
        {data.affiliation_institution}{(data.affiliation_city || data.affiliation_country) && <span>,&nbsp;</span>}
        {data.affiliation_city}{data.affiliation_country && <span>,&nbsp;</span>}
        {data.affiliation_country}
      </div>
    )
  }

  return (
    <div className="row shame-row">
      <div className="large-12 columns">
        <div className="small-12 large-6 columns">
          <div className="small-3 medium-2 hide-for-large-up columns avatar-container">
            {data.user_id && 
              <div className="avatar">
                <img src={`${data.avatar ? data.avatar : 'https://public.cureus.com/new-default-user-image.png'}`} />
              </div>
            }
            {!data.user_id && 
              <div className="organization-avatar">
                <i class="far fa-building"></i>
              </div>
            }
          </div>
          <div className="hide-for-small hide-for-medium avatar-container">
            {data.user_id && 
              <div className="avatar">
                <img src={`${data.avatar ? data.avatar : 'https://public.cureus.com/new-default-user-image.png'}`} />
              </div>
            }
            {!data.user_id && 
              <div className="organization-avatar">
                <i class="far fa-building"></i>
              </div>
            }
          </div>

          {!data.user_id && 
            <div className="small-9 large-10 columns organization-name-container">
              <div className="name-qualifications">
                <strong>{data.name}</strong>
              </div>
              <div className="specialty">
                {data.location}
              </div>
            </div>
          }

          {data.user_id && 
            <div className="small-9 large-10 columns">
              <div className="name-qualifications">
                <strong><a href={data.user_path} >{data.full_name}</a>{data.qualifications !== "" && <span>,&nbsp;</span>}</strong>
                {data.qualifications}
              </div>
              <div className="specialty">
                {data.specialty}
              </div>

              <div className="hide-for-large-up row small-affiliation-container" >
                <div className="small-1 columns">
                  <i class="far fa-building"></i>
                </div>
                <div className="small-11 columns small-affiliation">
                  <div className="affiliation-title">
                    {renderAffiliation()}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {data.user_id &&
          <div className="hide-for-small hide-for-medium large-4 offset-1 columns">
            <div className="large-12 columns">
              <div className="large-1 columns">
                <i class="far fa-building"></i>
              </div>
              <div className="large-11 columns">
                <div className="affiliation-title">
                  {renderAffiliation()}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="large-12 columns reason-container">
        {data.reason}
      </div>
    </div>
  )

}

export default WallOfShameRow;