var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';
import AttachmentInsertMixin from './AttachmentInsertMixin';
import Modal from '../Modal';
import Details from './Details';

const Table = createReactClass({
  mixins: [AttachmentInsertMixin],
  getInitialState: function(){
    return {
      attachment: this.props.attachment,
      disableButton: true,
    }
  },

  componentDidMount: function(){
    var self = this;
    $('#add-table').on("insertItem", ".primary", function(e){ 
      var elem = CKEDITOR.dom.element.createFromHtml(self.state.attachment.ck_editor_html + "\n")
      try{
        self.setCursorPosition();
        self.removeAttachmentForReinsertion(self.state.attachment.type);
        self.ckeditor().insertElement(elem);
      } catch(e) {
        self.moveCursorToStart();
        self.ckeditor().insertElement(elem);
        self.resetCursorPosition();
      }
      self.ckeditor().updateElement();
      self.disableAttachments();
      Modal.close();
    });
    new Cureus.CKEditor("edit-html-" + this.state.attachment.id, {
      ckeditor: {resize_enabled: false},
      editor_type: 'table',
      responsive_container: "#add-table .media-uploader",
      change_listener: "#edit-html-change",
      no_insta_save: true
    });
    self.bindFlexibleHeight();
    self.bindChangeListeners();
  },

  bindChangeListeners: function(){
    var self = this;
    $("#edit-html-change").on("change", function(){
      self.updateHtmlState()
      self.isValidNextButton();
    })
  },

  resizeCKEditor: function() {
    if(typeof CKEDITOR !== 'undefined'){
      if(this.htmlCkEditor() !== 'undefined'){
        var modalBodyHeight = $('#add-table .media-uploader').height();
        var width = $(".media-uploader").width()
        this.htmlCkEditor().resize(width, modalBodyHeight - 30);
      }
    }
  },

  bindFlexibleHeight: function(){
    window.addEventListener('resize', this.resizeCKEditor, true )
  },

  htmlCkEditor: function(){
    return CKEDITOR.instances["edit-html-" + this.state.attachment.id];
  },

  handleUpdate: function(e, data){
    var self = this;
    self.props.handleUpdate(e, data, function(serializedAttachmentData) {
      self.setState({
        disableButton: true,
        attachment: serializedAttachmentData
      }, function() {$("#add-table .primary").trigger("insertItem")})
    });
  },

  handleSubmit: function(e) {
    var self = this;
    self.setState({disableButton: true})
    var payload = {
      attachment: {
        title: self.state.attachment.title,
        legend: self.state.attachment.legend,
        html: self.state.attachment.html
      }
    }
    self.handleUpdate(e, payload)
  },

  updateHtmlState: function() {
    var newAttachment = Object.assign({}, this.state.attachment)
    newAttachment.html = this.htmlCkEditor().getData()
    this.setState({attachment: newAttachment})
  },

  isValidNextButton: function() {
    var validTitle = ($("#attachment_title").val() !== "") && ($("#attachment_title").val() !== undefined);
    var validFigure = this.state.attachment.html
    var validCharacterCount = this.state.characterCount <= 2000
    this.setValidState((validTitle && validFigure && validCharacterCount));
  },

  renderInsertButton: function() {
    var emptyTitle = ($("#attachment_title").val() === "") || ($("#attachment_title").val() === undefined)
    if (this.state.disableButton && emptyTitle) {
      return (
        <span className="tip-top" data-tooltip={this.state.disableButton} id="next-button" title="Please enter a title to proceed. (Scroll down).">
          <a className={'button primary ' + this.disableButtonClass()} data-tooltip={this.state.disableButton} onClick= {this.handleSubmit}>Insert</a>
        </span>
      )
    } else {
      return (
        <a className={'button primary ' + this.disableButtonClass()} onClick={this.handleSubmit}>Insert</a>
      )
    }
  },

  render: function() {
    return (
      <div className="reveal-modal custom-full attachment-modal" data-reveal>
        <div className='modal-content' id='add-table'>
          <div className='modal-header'>
            <h4 className="reg">Insert Table</h4>
            <div className='modal-close'>
              <a className="cancel-modal">
                <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
              </a>
            </div>
            <p className="title"> Please review our <a target="_blank" href={this.props.mediaGuidePath + '#table-req'}>Table Guidelines</a> for detailed instructions for inserting tables.</p>
          </div>
          <div className='modal-body table'>
            <input type='hidden' id="edit-html-change"/>
              <div className="media-uploader table">
                <textarea  name="attachment[html]"  id={ "edit-html-" + this.state.attachment.id } value={this.state.attachment.html} placeholder="Please create your table in Microsoft Word, Excel Google Docs or Google Sheets. When you are finished editing your table, please copy and paste it here by selecting Paste from the Edit menu in your browser toolbar OR using the following key commands: 'Ctrl+V' on PC or 'Cmd+V' on Mac."/>
              </div>
            <Details
              isValidNextButton={this.isValidNextButton}
              attachment={this.state.attachment}
              handleCharacterCount={this.handleCharacterCount}
              setValidState={this.setValidState}
              updateKey={this.updateKey}
            />
          </div>
          <div className='modal-footer'>
            <a className='cancel-modal button secondary'>Cancel</a>
            {this.renderInsertButton()}
          </div>
        </div>
      </div>
    ) }
  })

  export default Table;