import React, { useState, useEffect } from 'react';

const Question = ({question}) => {

  const [answer, setAnswer]= useState(false);

  const handleClick = () => {
    setAnswer(!answer)
  }
  return (
    <div className='question-container'>
      <div className={answer ? "active question" : "question"}>
        <div>{question.question}</div>
        <div className='dropdown-icon' onClick={handleClick}>
          <img src={answer ? "https://public.cureus.com/collections/close.png" : "https://public.cureus.com/collections/plus.png"}/>
        </div>
      </div>
      {answer && <div className='answer-container'>{question.answer}</div>}
    </div>
  )
}

export default Question;