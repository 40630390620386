import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import DateSelector from '../../../StatsWidget/DateSelector'
import { isNotAllZeroData } from './shared.js';
import ExportCSVButton, {normalizeArrayOfObjectsToAllArrays} from '../../../Shared/Utility/ExportCSVButton';

const EditorCheckActivity = ({dateRanges, currentUserEmail}) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [tableData, setTableData] = useState([]);
  const [dateFrom, setDateFrom] = useState(dateRanges[0].from)
  const [dateTo, setDateTo] = useState(dateRanges[0].to)
  const [selectedRangeName, setSelectedRangeName] = useState("Current week")
  const [loading, setLoading] = useState(true)


  useEffect(() => {
   if (tableData) {
    initializeTable()
   }
  }, [tableData])

  useEffect(() => {
    setLoading(true)
    fetchData()
  }, [dateFrom, dateTo, selectedRangeName])

  const fetchData = () => {
    $.ajax({
      type: 'GET',
      url: '/admin/channels/articles_table_data',
      data: {
        dataType: "performance_editor_check_activity",
        date_from: dateFrom,
        date_to: dateTo
      },
      success: function(data) {
        setTableData(data)
      }
    });
  }

  const initializeTable = () => {
    let dt;
    if (isTableInitialized()) {
      dt = $("#performance-editor-check-activity").DataTable()
    } else {
      dt = $("#performance-editor-check-activity").DataTable({
        language: {
          search: "Search by channel name:"
        },
        destroy: true,
        columns: setColumns(),
        dom: '<"row header-bar"<"large-3 columns ed-check-title"><"large-2 columns ed-check-date">><"large-5 columns"f><"table"t>r<"row bottom"<"large-2 columns"l><"large-4 columns pagination end"p>>',
        "drawCallback": () => { setLoading(false) }
      });
    }

    dt.api().clear()
    mapColumns(dt)
    dt.api().draw()
    renderTitleAndDateSelector()
  }

  const isTableInitialized = () => {
    return $.fn.dataTable.isDataTable($("#performance-editor-check-activity"))
  }
  const renderTitleAndDateSelector = () => {
    if (firstLoad) {
      document.querySelector(".ed-check-title").innerHTML = "<h5>Editor Check Activity</h5>"
      ReactDom.render(dateSelector(), document.querySelector(".ed-check-date"))

      setFirstLoad(false)
    }
  }

  const setColumns = () => {
    return [
      {width: '100px'},
      {width: '100px'},
      {width: '50px'},
      {width: '50px'},
      {width: '100px'},
      {width: '100px'},
      {width: '100px'},
      {width: '50px'},
      {width: '50px'},
      {width: '100px'}
    ]
  }

  const mapColumns = (dt) => {
    tableData.forEach((data) => {
      if (isNotAllZeroData(data)) {
        dt.fnAddData([
          `<a target="_blank" href="/admin/channels/${data.id}">${data.title}</a>`,
          data.initial_submit_editor_check,
          data.content_deferred_1x,
          data.content_deferred_2x,
          data.content_deferred_3x,
          data.ithenticate,
          data.third_party_deferral,
          data.rejected,
          data.blocked,
          data.approved_for_review
        ])
      }
    })
  }

  const dateSelector = () => {
    return <DateSelector
      from={dateRanges[0].from}
      to={dateRanges[0].to}
      getStats={filterData}
      selectedRange={selectedRangeName}
      noFloat={true}
      dateRanges={dateRanges}
    />
  }

  const filterData = (from, to, rangeName) => {
    setDateFrom(from)
    setDateTo(to)
    setSelectedRangeName(rangeName)
  }

  const renderSpinner = () => {
    if (loading) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  }

  const filterDataForCsvContent = () => {
    let filteredData = tableData.filter((data) => { return isNotAllZeroData(data) })
    return normalizeArrayOfObjectsToAllArrays(filteredData)
  }

  return (
    <div className='datatables'>
      {renderSpinner()}
      <div className="performance">
        <ExportCSVButton userEmail={currentUserEmail} csvContent={filterDataForCsvContent()} tableTitle={'Editor_Check_Activity'} top={-2} left={"44%"}/>
        <table className="table-wrapper" id="performance-editor-check-activity">
          <thead>
            <tr>
              <th>Channel</th>
              <th>Initial Submissions to Editor Check</th>
              <th>Deferred for Content 1x</th>
              <th>Deferred for Content 2x</th>
              <th>Deferred for Content 3x or More</th>
              <th>Deferred for Ithenticate</th>
              <th>Deferred for 3rd Party Editing</th>
              <th>Rejected</th>
              <th>Blocked</th>
              <th>Approved for Peer Review</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  )
}
export default EditorCheckActivity;

