
var createReactClass = require('create-react-class');
import React from 'react';

const References = createReactClass({
  render: function() {
    return (
      <div className='large-12 small-12 columns'>
        <h4>
          Consistent affiliations are required for publication approval.
        </h4>

        <p>
          Your article will not be approved if your references are incomplete or incorrectly formatted. Please read the <a href='/author_guide#references-section'  target='_blank'>References section of the Author Guide</a> before submitting your draft to ensure that your references are correct.
        </p>

        <p>
          Remember that each article type has a specific reference limit. If you have exceeded this limit, please remove references before submitting your article. Articles with too many references will be deferred.
        </p>
        <label>
          <input ref='checkbox' type='checkbox' onChange={ this.props.handleChecked }/>
          &nbsp;
          My references are correct.
        </label>
      </div>     
    )
  }
});

export default References;
