var createReactClass = require('create-react-class');
import React from 'react';

const RejectReviewModal = createReactClass({  
  renderModalText: function() {
    if(this.props.failsPeerReview) {
      let reinvite = this.props.articleState == 'in_review' ? null : (<p>As a result, this article no longer satisfies our peer review requirements and will be returned to review with all expired reviewers re-invited. You may add new reviewers if you wish.</p>)
      return (
        <div className="row">
          <p>
            Please explain why you are rejecting this review. Your message will be sent to the reviewer and the submitting author. Rejected reviews do not count towards the required number of reviews.
          </p>
          {reinvite}
        </div>
      )
    } else {
      return (
        <div className="row">
          <p>
            Please explain why you are rejecting this review. Your message will be sent to the reviewer and the submitting author. Rejected reviews do not count towards the required number of reviews.
          </p>
          <p>This article still satisfies our peer review requirements so it will not be returned to review.</p>
        </div>
      )
    }
  },

  render: function() {
    return (
      <div id="raw-history-log-modal" className="reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <h4>Reject review?</h4>
            {this.renderModalText()}
            <textarea onChange={this.props.handleChange}/>
          </div>
          <div className="modal-footer">
            <a href="#" className="button secondary cancel-modal"> Cancel </a>
            <a className="button alert" onClick={this.props.rejectReview}>Reject</a>
          </div>
        </div>
      </div>
    )
  }
})

export default RejectReviewModal;