var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const StepTwo = createReactClass({
  componentDidMount: function() {
    var self = this;
    Cureus.Slider.init(self.props.handleChange)
  },

  handleDisable: function() {
    return this.props.credibility ? '' : 'disabled'
  },

  renderSlider: function() {
    return (
      <div className="siq-slider-wrap "> 
        <div>
          <input id="quality" type="hidden" name="quality"/>
          <div className="cuslider" data-initial-value={this.props.quality - 1} data-input_id="quality" data-min="1" data-max="5" name="quality" value={this.props.quality} />
        </div>
      </div>
    )
  },

  renderRadios: function() {
    return (
      <div className="small-12 columns small-offset-1">
        <label className="position-radio">
          <input type="radio" name="credibility" value="1" checked={this.props.credibility === "1"} onChange={(e) => this.props.handleChange(e)}/>
          Yes (accept after revisions)
        </label>
        <label className="position-radio">
          <input type="radio" name="credibility" value="2" checked={this.props.credibility === "2"} onChange={(e) => this.props.handleChange(e)}/>
          Decision pending (please send for re-review after revisions)
        </label>
        <label className="position-radio">
          <input type="radio" name="credibility" value="3" checked={this.props.credibility === "3"} onChange={(e) => this.props.handleChange(e)}/>
          No (do not publish)
        </label>
      </div>
    )
  },

  render: function() {
    return (
      <div className='modal-content'>
        <p><strong>Step 2: The following responses are private to the editors.</strong></p>
        <p style={{marginBottom: 0}}>Please provide your quality assessment of this article.</p>
        <p><strong>(1=Lowest, 2=Low, 3=Average, 4=High, 5=Highest)</strong></p>
        {this.renderSlider()}
        <p>Is the medical science and any associated conclusions contained within this article credible and potentially valuable?</p>
        {this.renderRadios()}
        <p>Add confidential comments for our editors, including any potential ethical concerns, as well as whether you are willing to review a revised version if needed (optional).</p>
        <textarea name="comment" onChange={this.props.handleChange} value={this.props.comment}/>
        <div className='modal-footer'>
          <a href='#' className='button secondary left' onClick={this.props.handleBack}>Back</a>
          <a href='#' className='button secondary cancel-modal'>Cancel</a>
          <a href='#' className={'button primary ' + this.handleDisable()} onClick={this.props.handleNext}>Next</a>
        </div>
      </div>
    )
  }
})

export default StepTwo;