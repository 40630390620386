import React from 'react';

const CommentingPolicies = () => {
  return (
    <React.Fragment>
      <h1>Commenting Policies</h1>
      <p>
        Cureus requires that all public comments follow the normal standards of professional discourse. Users must sign in to their Cureus profile in order to comment on an article or poster. Under no circumstances does Cureus allow anonymous or pseudonymous commenting or user profiles. Additionally, Cureus maintains a zero tolerance policy for any language deemed insulting, inflammatory, obscene or libelous.
      </p>
      <p>
        All comments which do not meet these standards will be removed, while repeat offenders will have their commenting rights revoked.
      </p>
    </React.Fragment>
  )
}

export default CommentingPolicies;