import React, { useState, useEffect } from 'react';
import { Switch, Route, Link } from "react-router-dom";
import Promotion from "./Promotion"

const Sidebar = () => {
  const [activeSubTab, setActiveSubTab] = useState(location.pathname)
  useEffect(() => {
    setActiveSubTab('/sidebar/promotion')
  }, [])

  return (
    <>
      <div className="small-12 columns nav-container bordered">
        <div className="row">
          <div className="large-12 columns">
            <div className="cureus-tabs secondary-tabs">
              <div className="tabs-container dashboard">
                <div className="tab-items">
                  <ul>
                  <Link to="/sidebar/promotion">
                    <li className={"tab-title inline " + (activeSubTab === '/sidebar/promotion' ? 'active' : '')} onClick={() => setActiveSubTab('/sidebar/promotion')}>
                      <a className="dashboard">PROMOTION</a>
                    </li>
                  </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch>
        <Route path='/sidebar/promotion'>
          <Promotion />
        </Route>
      </Switch>
    </>
  )

}

export default Sidebar;