import React, { useEffect, useState } from 'react';

const Coauthors = ({coauthorsPath, currentUserId, userId}) => {

  const [coauthors, setCoauthors] = useState([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    fetchCoauthors()
  }, [])

  const fetchCoauthors = () => {
    $.ajax({
      type: 'GET',
      url: coauthorsPath,
      dataType: 'JSON',
      success: function(res) {
        setCoauthors(res.coauthors)
      }
    })
  }

  const renderTogglerMessage = () => {
    const groupedCoauthors = groupCoauthors(coauthors);
    if(groupedCoauthors[0].length < 4) { 
      return
    }

    var caratClass = expanded ? "fa fa-angle-up" : "fa fa-angle-down"
    var message = expanded ? "See less" : "See all"

    return (
      <div className="content-toggler" id="expand-trigger-wrap">
        <a onClick={ () => setExpanded(!expanded) }>
          {message} <i className={ caratClass }></i>
        </a>
      </div>
    )
  }

  const renderCoauthors = () => {
    const groupedCoauthors = groupCoauthors(coauthors);

    if(groupedCoauthors.length == 0) {
      return (
        <div class="large-6 medium-6 small-6 columns"></div>
      )
    } else if (groupedCoauthors.length == 1) {
      return (
        <div class="large-6 medium-6 small-6 columns">
          <div class="profile-data">
            <div>
              {groupedCoauthors.flat().map((coauthor, index) => (
                <div class="row coauthor-profile">
                  <div class="small-2 columns">
                    <div class="profile-list-item">
                      <div class="fancy-circle-image-new profile-show">
                        <a href={coauthor.author_url}>
                          <div dangerouslySetInnerHTML={{__html: coauthor.avatar}}></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="small-10 columns info">
                    <div class="profile-list-item">
                      <div class="details">
                        <div class="coauthors">
                          <a href={coauthor.author_url}>
                            <strong dangerouslySetInnerHTML={{__html: coauthor.full_name}}></strong>
                          </a>
                          {coauthor.qualifications}
                          <br/>
                          {coauthor.specialty}
                          <br/>
                          <strong>{coauthor.size}</strong>
                          <br/>
                          <br/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        )} else {
          return (
            <div className="row">
              <div className={`profile-data + ${expanded ? 'expand' : 'collapse'}`} >
                <div className="large-6 medium-6 small-6 columns">
                  <div>
                    {groupedCoauthors[0].map((coauthor, index) => (
                      <div className="row coauthor-profile" key={index}>
                        <div className="small-2 columns">
                          <div className="profile-list-item">
                            <div className="fancy-circle-image-new profile-show">
                            <a href={coauthor.author_url}>
                                <div dangerouslySetInnerHTML={{ __html: coauthor.avatar }} />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="small-10 columns info">
                          <div className="profile-list-item">
                            <div className="details">
                              <div className="coauthors">
                              <a href={coauthor.author_url}>
                                  <strong dangerouslySetInnerHTML={{ __html: coauthor.full_name }} />
                                </a>
                                 &nbsp;{coauthor.qualifications}
                                <br />
                                {coauthor.specialty}
                                <br />
                                <strong>{coauthor.size}</strong>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="large-6 medium-6 small-6 columns">
                  <div>
                    {groupedCoauthors[1].map((coauthor, index) => (
                      <div className="row coauthor-profile" key={index}>
                        <div className="small-2 columns">
                          <div className="profile-list-item">
                            <div className="fancy-circle-image-new profile-show">
                            <a href={coauthor.author_url}>
                                <div dangerouslySetInnerHTML={{ __html: coauthor.avatar }} />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="small-10 columns info">
                          <div className="profile-list-item">
                            <div className="details">
                              <div className="coauthors">
                                <a href={coauthor.author_url}>
                                  <strong dangerouslySetInnerHTML={{ __html: coauthor.full_name }} />
                                </a>
                                 &nbsp;{coauthor.qualifications}
                                <br />
                                {coauthor.specialty}
                                <br />
                                <strong>{coauthor.size}</strong>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )
        }
  }

  const groupCoauthors = (coauthors) => {
    if (coauthors.length > 1) {
      const groupSize = Math.ceil(coauthors.length / 2);
      const grouped = [];
      for (let i = 0; i < coauthors.length; i += groupSize) {
        grouped.push(coauthors.slice(i, i + groupSize));
      }
      return grouped.map(group => group.filter(coauthor => coauthor !== null));
    } else {
      return [coauthors.filter(coauthor => coauthor !== null)];
    }
  };

  const renderContainer = () => {
    if(coauthors.length > 0 || currentUserId == userId) {
      return (
        <div class="profile-section left-container-wrapper">
          <div class="left-container">
            <div class="row">
              <div class="large-12 medium-12 columns">
                <div class="content-card">
                  <div class="coauthors">
                    <div class="row">
                      <div class="large-12 medium-12 small-12 columns">
                        <h6 class="title">Co-authors ({coauthors.length})</h6>
                        {renderCoauthors()}
                        <div class="row">
                          <div class="large-12 medium-12 small-12 columns centered">
                            {renderTogglerMessage()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      {renderContainer()}
    </div>
  );
}

export default Coauthors;