import React, {useState, useEffect} from 'react';

const QueueInfo = () => {
  const [claimed, setClaimed] = useState('x')
  const [unClaimed, setUnclaimed] = useState('x')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    $.ajax({
      url: '/admin/ae_dashboards/queue_info',
      dataType: "json",
      success: function(data) {
        setClaimed(data.claimed)
        setUnclaimed(data.unclaimed)
      }
    });
  }

  return (
    <div className="row">
      <div className="large-12 columns">
        <div className="queue-info-box">
          <h4>Editor Approval Queue:</h4>
          <h4>{claimed} claimed / {unClaimed} unclaimed</h4>
        </div>
        </div>
    </div>
  )
};

export default QueueInfo;