import React, {useState} from 'react';
import { HashRouter as Router, useLocation } from "react-router-dom";
import InfoWidget from './InfoWidget';
import MyCollections from './MyCollections';

const Main = ({collectionsInfoWidgetPath, publishNewCollectionPath, myCollectionsPath, checkPendingStatusPath, publishedCollectionCount}) => {

  return (
    <React.Fragment>
      <Router hashType={'hashbang'}>
        <InfoWidget 
          collectionsInfoWidgetPath={collectionsInfoWidgetPath}
        />
        <MyCollections 
          publishNewCollectionPath={publishNewCollectionPath}
          myCollectionsPath={myCollectionsPath}
          checkPendingStatusPath={checkPendingStatusPath}
          publishedCollectionCount={publishedCollectionCount}
        />
      </Router>
    </React.Fragment>
  )
}

export default Main;