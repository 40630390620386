import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import InternalContent from './Modals/InternalContent';
import CustomContentModal from './Modals/CustomContentModal';
import ChannelTeam from './Modals/ChannelTeam';
import ChannelTeamSidebar from './Modals/ChannelTeamSidebar';
import Promotion from './Modals/Promotion';
import CureusPromotionModal from './Modals/CureusPromotionModal';
import OverviewSidebarModal from './Modals/OverviewSidebarModal';
import TwitterSidebarModal from './Modals/TwitterSidebarModal';
import Poll from './Modals/Poll';
import MostPopularContentModal from './Modals/MostPopularContentModal';
import AboutModal from './About/AboutModal';
import AboutContactModal from './About/AboutContactModal';
import SocialAccountsModal from './About/SocialAccountsModal';

const UpdateButton = ({widget, handleUpdateWidget, modalName, mainContentWidgets, sidebarWidgets, slug, hasArticles, widgetInternalContentTabId, internalTab }) => {
  const props = ({ widget, handleUpdateWidget, mainContentWidgets, sidebarWidgets, slug, hasArticles, widgetInternalContentTabId, internalTab })
  const modalHash = (name) => {
    const modalObj = {
      'CustomContentModal': <CustomContentModal {...props}/>,
      'InternalContent': <InternalContent {...props}/>,
      'ChannelTeam': <ChannelTeam {...props}/>,
      'ChannelTeamSidebar': <ChannelTeamSidebar {...props}/>,
      'Promotion': <Promotion {...props}/>,
      'CureusPromotionModal': <CureusPromotionModal {...props}/>,
      'OverviewSidebarModal': <OverviewSidebarModal {...props}/>,
      'TwitterSidebarModal': <TwitterSidebarModal {...props}/>,
      'Poll': <Poll {...props}/>,
      'MostPopularContentModal': <MostPopularContentModal {...props}/>,
      'AboutModal' : <AboutModal {...props}/>,
      'AboutContactModal' : <AboutContactModal {...props}/>,
      'SocialAccountsModal' : <SocialAccountsModal {...props} />,
    }

    return modalObj[name]
  }

  const openModal = () => {
    Modal.open({
      children: modalHash(modalName)
    });
  }

  return (
    <img className={"action-buttons channels update"}  src="https://public.cureus.com/channel_design/edit_widget.png" onClick={openModal}/>
  )
}

export default UpdateButton;