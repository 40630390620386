import React, { useEffect } from 'react';

const CoauthoredArticle = ({ article, renderStateSteps, renderAuthors, renderReviewers, renderType, renderEditorMessageIcon }) => {

  const renderTitle = () => {
    return (
      <div className="title">
      <h5 className="title reg">
        <span className="has-tip tip-bottom" data-tooltip="" id={"coauthor-notif-" + article.id} title="Co-authored article: The submitting author is responsible for submitting and revising this article.">
          <i className="fas fa-user-friends"/>
        </span>
        <a href={article.previewPath} target="_blank">{article.title || "Untitled Article"}</a>
      </h5>
    </div>
    )
  }

  const smallView = () => {
    return (
      <div className="small-12 columns bordered article-box show-for-small-only">
        {renderEditorMessageIcon()}
        <div className="row title-container">
          <div className="small-12 columns">
            {renderTitle()}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            {renderType()}
          </div>
        </div>
        <div style={{marginTop: '18px'}}>
          {renderStateSteps()}
        </div>
        <div style={{marginTop: '18px'}}>
          <div className="small-12 columns text-left">
            <div className="small-12 columns no-padding">
              <a className="button preview-button" href={article.previewPath} target="_blank">Preview</a>
            </div>
          </div>
        </div>
        {renderAuthors()}
        {renderReviewers()}
      </div>
    )
  }

  return (
    <React.Fragment>
      {smallView()}
      <div className="small-12 columns article-box bordered hide-for-small">
      {renderEditorMessageIcon()}
        <div className="row title-container">
          <div className={article.articleState === 'blocked' ? "small-10 columns" : "large-8 medium-8 small-8 columns"}>
              {renderTitle()}
            </div>
            <div className={article.articleState === 'blocked'? "small-2 columns text-right" : "large-4 medium-4 small-4 columns text-right"}>
            <a className="button preview-button" href={article.previewPath} target="_blank">Preview</a>
          </div>
        </div>
        <div className="row">
          <div className="large-9 medium-8 small-6 columns">
            {renderType()}
          </div>
        </div>
        <div style={{marginTop: '18px'}}>
          {renderStateSteps()}
        </div>
        {renderAuthors()}
        {renderReviewers()}
      </div>
    </React.Fragment>
  )
}

export default CoauthoredArticle;