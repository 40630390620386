var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const InstitutionAutoSuggester = createReactClass({
  componentDidMount: function (){
    var $input;
    $input = $(this.refs.autosuggest).find("input");

    Cureus.Utils.AssociationAutocomplete.setup($input, {
      autocompleteUrl: this.props.autosuggestUrl
    });
  },

  render: function (){
    var self, children_with_props;

    self = this;

    return (
      <div ref="autosuggest">
        { this.props.children }
      </div>
    )
  }
});

export default InstitutionAutoSuggester;
