var createReactClass = require('create-react-class');
import React from 'react';
import AddToList from './AddToList';
import AddToRefresh from './AddToRefresh';
import Search from './Search';
import SubscribedFilter from './SubscribedFilter';
import FilterTool from './FilterTool';
import FilterNotification from './FilterNotification';
import FilterableTable from './FilterableTable';
import FilterControl from './FilterControl';
import Fluxxor from '../../../../../vendor/assets/javascripts/fluxxor';

const AddFromCureus = createReactClass({
  mixins: [
    Fluxxor.FluxMixin(React),
    Fluxxor.StoreWatchMixin("Table")
  ],

  getInitialState: function() {
    return {
      subscribed: true,
      filtering: false
    }
  },

  getStateFromFlux: function() {
    var flux = this.getFlux();
    return {
      selectedSubscribersCount: flux.store("Table").getState().selectedCount,
      staged: flux.store("Table").getState().staged,
      selectAll: flux.store("Table").getState().selectAll,
      query: flux.store("Table").getState().query
    }
  },

  handleSubscribeFilterChange: function (subscribed){
    this.setState({subscribed: subscribed});
    this.getFlux().actions.deselectAll();
  },

  handleFilter: function() {
    this.setState({filtering: true});
    this.getFlux().actions.deselectAll();
  },

  handleSaveFilter: function() {
    this.setState({filtering: false});
  },

  handleCancelFilter: function() {
    this.setState({filtering: false});
  },

  render: function() {
    var disableAddToList;

    disableAddToList = (this.state.selectedSubscribersCount < 1) || (this.state.subscribed == false)
    if(this.state.filtering) {
      var body = <FilterTool onCancel={this.handleCancelFilter} onSave={this.handleSaveFilter} />
    } else {
      var body = 
        <div>
          <FilterNotification onEdit={this.handleFilter} />

          <div className='email-list-datatable-header'>
            <div className="row">
              <div className="columns large-1">
                <FilterControl onClick={this.handleFilter} />
              </div>
              <div className="columns large-2 large-offset-1">
                <SubscribedFilter onChange={this.handleSubscribeFilterChange} subscribed={this.state.subscribed} />
              </div>
              <div className="columns large-5">
                <Search />
              </div>
            </div>
          </div>

          <FilterableTable dataSource={this.props.dataSource} subscribed={this.state.subscribed} />

          <div className='email-list-datatable-footer'>
            <a href={this.props.emailListPath} className='button secondary'>Cancel</a>
            &nbsp;
            <AddToList 
              disabled={disableAddToList} 
              staged={this.state.staged} 
              selectAll={this.state.selectAll} 
              query={this.state.query}
              redirectUrl={this.props.addFromCureusPath}
              updateUrl={this.props.updateUrl} />
            <AddToRefresh
              updateUrl={this.props.updateUrl}
              filters={this.props.flux.stores.FilterTool.filters} />
          </div>
        </div>
    }

    return (
      <div className="email-list-show">
        <h1>Add {this.state.selectedSubscribersCount} subscribers to:</h1>
        <h2>{this.props.emailListTitle} ({this.props.listEmailsCount})</h2>
        <hr/>
        <h3>Viewing All Cureus Users ({this.props.totalUserCount})</h3>

        {body}
      </div>
    )
  }
});

export default AddFromCureus;