import {Cureus} from '../Cureus';

Cureus.CKEditorLoader = (function($, _) {
  var load, setup;
  var loading = false;
  var loaded = false;
  var CDN_URL = "https://cdnjs.cloudflare.com/ajax/libs/ckeditor/4.14.1/ckeditor.js"
  // var CDN_URL = "https://cdn.ckeditor.com/4.14.1/standard/ckeditor.js"

  load = function(callback) {
    if(loaded) {
      callback();
    } else {
      if(loading) {
        setTimeout(function() {
          load(callback);
        }, 100);
      } else {
        loading = true;
        $.ajax({
          url : CDN_URL,
          type : 'GET', 
          tryCount : 0,
          retryLimit : 3,
          dataType: "script",
          success : function() {
            loaded = true
            setup();
            callback();
          },
          error : function() {
              this.tryCount++;
              if (this.tryCount <= this.retryLimit) {
                  //try again
                  $.ajax(this);
                  return;
              }
            }
        });
      }
    }
  }

  setup = function() {
    CKEDITOR.plugins.addExternal( 'font', '/ckeditor/plugins/font/');
    CKEDITOR.plugins.addExternal( 'colorbutton', '/ckeditor/plugins/colorbutton/');
    CKEDITOR.plugins.addExternal( 'panelbutton', '/ckeditor/plugins/panelbutton/');
    CKEDITOR.plugins.addExternal( 'blockquote', '/ckeditor/plugins/blockquote/');
    CKEDITOR.plugins.addExternal( 'sourcedialog', '/ckeditor/plugins/sourcedialog/');
    CKEDITOR.plugins.addExternal( 'insertFigure', '/ckeditor/plugins/insertFigure/');
    CKEDITOR.plugins.addExternal( 'insertTable', '/ckeditor/plugins/insertTable/');
    CKEDITOR.plugins.addExternal( 'insertInteractiveModel', '/ckeditor/plugins/insertInteractiveModel/');
    CKEDITOR.plugins.addExternal( 'insertLatex', '/ckeditor/plugins/insertLatex/');
    CKEDITOR.plugins.addExternal( 'insertVideo', '/ckeditor/plugins/insertVideo/');
    CKEDITOR.plugins.addExternal( 'confighelper', '/ckeditor/plugins/confighelper/');
    CKEDITOR.plugins.addExternal( 'widget', '/ckeditor/plugins/widget/');
    CKEDITOR.plugins.addExternal( 'html5video', '/ckeditor/plugins/html5video/');
    CKEDITOR.plugins.addExternal( 'clipboard', '/ckeditor/plugins/clipboard/');
    CKEDITOR.plugins.addExternal( 'widgetselection', '/ckeditor/plugins/widgetselection/');
    CKEDITOR.plugins.addExternal( 'lineutils', '/ckeditor/plugins/lineutils/');

    CKEDITOR.stylesSet.add( 'cureus-subheaders', [
      { name: 'Normal', element: 'p'},
      { name: 'Subheading', element: 'h4' }
    ]);

    CKEDITOR.stylesSet.add( 'cureus-no-subheaders', [
      { name: 'Normal', element: 'p'}
    ]);

    CKEDITOR.on('instanceReady', function (ck) {
      ck.editor.on("change", function (e) {
        var sel = ck.editor.getSelection();
        if (sel) {
          var selected = sel.getStartElement();
          if (selected && selected.$)
            sel.getStartElement().$.normalize();
        }
      });
    });
  }

  return { load: load };
})(jQuery, _);
