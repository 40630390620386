var createReactClass = require('create-react-class');
import React from 'react';

const JudgeInputField = createReactClass({
	handleSubmit: function() {
		$("[data-checkbox]").last().prop({checked: true})
  },

  onSubmit: function(element) {
    var self = this;
  	var judgeId = $("[data-judge-field]").val();

    $.post(this.props.createJudgePath, { judge_id: judgeId, competition_id: this.props.competitionId }, function() {
    	self.props.getJudgeships( function() {
    		self.handleSubmit();
    		self.props.handleCheckboxSelect()
    		self.props.handleEmptyJudgeships()
    	});
    });
  },


	render: function() {
		if ( this.props.judgeView == "new") {
			var judgeInputField = 	<div className="row">
																<div className="small-6 columns">
																	<input data-judge-field type="text" id="" name="" />
																</div>
																<div className="small-6 columns">
																	<a onClick={ this.onSubmit } className="button secondary">Add </a>
																</div>
															</div>
		}

		return (
			<div className = "judge-add">
				{ judgeInputField }
			</div>
		)
	}
})

export default JudgeInputField;

