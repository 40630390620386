import { useState } from 'react';

export function useCategoryParams(categories) {
  const [categorySelection, setCategorySelection] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('category')) {
      const category = searchParams.get('category');
      let newCategoryObj = {}
      const categoryObj = categories.find((cat) => cat.name === category);
      if (categoryObj) {
        newCategoryObj[categoryObj.id] = categoryObj.name;
      } else {
        newCategoryObj = { allCategories: true };
      }

      return newCategoryObj;
    }
    return { allCategories: true };
  });

  return [categorySelection, setCategorySelection];
}

export function useSearchParamsState() {
  const [searchTerm, setSearchTerm] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('keyword') || "";
  });

  return [searchTerm, setSearchTerm]
}