import React, { useState, useEffect } from 'react';

const Card = ({article, fireAmplitude, cardType}) => {
  const [authors, setAuthors] = useState('')
  const [authorsOverflow, setAuthorsOverflow] = useState(null)

  useEffect( () => {
    setAuthorList()
  }, [])

  const setAuthorList = () => {
    let authorList = article.author_list.split(',')
    setAuthors(authorList.slice(0, 2).join(', '))

    let overflow = authorList.slice(2).length
    if(overflow > 1) {
      setAuthorsOverflow(overflow)
    }
  }

  const renderClass = () => {
    if (cardType) {
      return 'most-popular-';
    } else {
      return "";
    }
  }

  const renderAuthorList = () => {
    return (
      <div className={`${renderClass()}` + "authors"}>
        {authors}&nbsp;&nbsp;{authorsOverflow && <a onClick={() => fireAmplitude("popularArticleClicked", {article_id: article.id, page: "articleShow"})} href={article.path + '?authors-tab=true'}> <span>+{authorsOverflow} more</span></a>}
      </div>
    )
  }

  return (
    <>
      <a onClick={() => fireAmplitude("popularArticleClicked", {article_id: article.id, page: "articleShow"})} href={article.path} target="_blank"> <p className="title">{article.title}</p></a>
      <p>{renderAuthorList()}</p>
    </>
  )
}

export default Card

