var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';
import Modal from '../Modal';
import ConfirmationModal from '../ConfirmationModal';

const SectionListItem = createReactClass({
  getInitialState: function() {
    return ({
      imagePosition: this.props.imagePosition || 'none',
      image: this.props.image,
      message: this.props.message,
      id: this.props.id,
      ctaButtonText: this.props.ctaButtonText,
      ctaButtonUrl: this.props.ctaButtonUrl,
      imageFile: null,
      disabled: ""
    })
  },

  componentDidMount: function() {
    new Cureus.CKEditor(`campaign_section_message_${this.state.id}`, {editor_type: 'marketing_mailer', ckeditor: {height: 200}});
  },

  removeSection: function(articleId) {
    var self = this;
    self.setState({disabled: 'disabled'})
    return function() {
      self.props.removeSection(articleId);
    }
  },

   removeSectionConfirmationModal: function() {
    Modal.open({
      children: <ConfirmationModal
                  handleSubmit= {this.removeSection(this.state.id)} />
    })
  },

  onChange: function(e) {
    this.setState({[e.target.dataset.name]: e.target.value})
  },

  handleFileUpload: function(e) {
    this.setState({imageFile: e.target.files[0]})
  },

  renderImage: function() {
    if(this.props.image) {
      return (
      <div className="row">
        <span>Current image:</span>
        <div dangerouslySetInnerHTML= {{ __html: this.props.image }} />
      </div>
      )
    }
  },

  setCkEditorData: function() {
    var ckeditor = CKEDITOR.instances[`campaign_section_message_${this.state.id}`]
    var data = ckeditor.getData()
    this.setState({message: data, disabled: 'disabled'}, function() {
      this.props.updateSection(this.state)
    })
  },

  renderRadioButton: function(pos) {
    return (
      <label className="position-radio">
        <input type="radio"
        onChange={(e) => this.onChange(e)}
        data-name="imagePosition"
        value={pos.toLowerCase()}
        checked={this.state.imagePosition === pos.toLowerCase()}
        name={`position_${this.state.id}`}
        />
        {pos}
      </label>
    )
  },

  render: function() {
    return (
      <li>
        <div className="row">
          <label className="section-number">Section {this.props.number}</label>
        </div>
        <div className="row">
          <label>
          Image
          <input type="file" onChange={(e) => this.handleFileUpload(e)}
          />
          </label>
        </div>
        {this.renderImage()}
        <div className="row">
          <label>Image Location</label>
          {this.renderRadioButton('None')}
          {this.renderRadioButton('Top')}
          {this.renderRadioButton('Left')}
          {this.renderRadioButton('Right')}
        </div>
        <div className="row">
          <label>Section Message</label>
          <textarea 
            id={`campaign_section_message_${this.state.id}`}
            value={this.state.message || ""}
            onChange={(e) => this.onChange(e)}
            data-name="message"
          />
        </div>
        {(!this.props.newsroom && !this.props.channelDigestId) && 
          <div className="row">
            <label style={{marginTop: "10px"}}>Call to Action Button Text</label>
            <input type="text" data-name="ctaButtonText" onChange={(e) => this.onChange(e)} value={this.state.ctaButtonText}/>
          </div>
        }
        {(!this.props.newsroom && !this.props.channelDigestId) &&
          <div className="row">
            <label>Call to Action Button URL</label>
            <input type="text" data-name="ctaButtonUrl" onChange={(e) => this.onChange(e)} value={this.state.ctaButtonUrl}/>
          </div>
        }
        <div className="row" style={(this.props.channelDigestId ? {marginTop: '25px'} : {})}>
          <a className={"button primary add left " + this.state.disabled} onClick={this.removeSectionConfirmationModal}>Delete</a>
          <a className={"button primary add right " + this.state.disabled} onClick={this.setCkEditorData}>Save</a>
        </div>
        <hr/>
      </li>
    )
  }
});

export default SectionListItem;