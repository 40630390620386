import React, { useState, useEffect } from 'react';

const SizeInput = ({contentTypeSize, setContentTypeSize, uniqueName, buttonValue}) => {

  const isSelected = () => {
    return (contentTypeSize == buttonValue)
  }

  return (
    <div style={{display: "inline-block"}}>
      <input type="radio" id={`${uniqueName}-radio`} onChange={() => setContentTypeSize(buttonValue)} checked={isSelected()} />
      <label htmlFor={`${uniqueName}-radio`}>{uniqueName}</label>
    </div>
  )
}

export default SizeInput;