var createReactClass = require('create-react-class');
import React from 'react';

const UpdateMiscItemModal = createReactClass({
  getInitialState: function() {
    return({
      miscEventData: this.props.miscEventData
    })
  },

  updateMiscItem: function() {
    var self = this;
    var payload = {miscData: {
      id: self.props.miscEventData.id,
      created_at: self.state.datetime,
      description: self.state.description,
      rate: self.state.rate
    }}
    $.ajax({
      type: "PUT",
      url: "/admin/misc_events/" + self.props.miscEventData.id,
      data: payload,
      success: function(data) {
        location.reload()
      }
    });
  },

  destroyEvent: function() {
    var self = this;
    $.ajax({
      type: "DELETE",
      url: "/admin/misc_events/" + self.props.miscEventData.id,
      success: function() {
        location.reload()
      }
    });
  },

  handleChanges: function(e) {
    this.setState({[e.target.name]: e.target.value})
  },

  render: function() {
    var miscEvent = this.state.miscEventData
    return(
      <div className="reveal-modal middling" id="add-misc-modal" data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content middling">
            <h4>Update Miscellaneous Item</h4>
            <label>Date
              <input id="event_created_at" defaultValue={this.props.miscEventData.created_at.slice(0, 10)} type="date" onChange={this.handleChanges} name="datetime"></input>
            </label>
            <label>Item Name
              <input type="text" id="description" defaultValue={miscEvent.description} onChange={this.handleChanges} name="description"></input>
            </label>
            <label>Rate
              <input id ="event_rate" type="text" defaultValue={miscEvent.rate} onChange={this.handleChanges} name="rate"></input>
            </label>
          </div>

          <div className="modal-footer">
            <div className="row">
              <a className="button small alert" style={{float: 'left'}} onClick={this.destroyEvent}>Delete</a>
              <a className="button small cancel-modal">Cancel</a>
              <a className="button small" onClick={this.updateMiscItem}>Update</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default UpdateMiscItemModal;
