import React from 'react';

const ResultCard = ({ title, header, content, href }) => {

  function renderTitle() {
    return (
      <h2 className='blue' dangerouslySetInnerHTML={{__html: title }}/>
    )
  }

  function renderHeader() {
    return (
      <h3 className='blue' dangerouslySetInnerHTML={{__html: header }}/>
    )
  }

  function renderContent() {
    return (
      <div dangerouslySetInnerHTML={{__html: content }}/>
    )
  }

  return (
    <div className='result-card'>
      { renderTitle() }
      { renderHeader() }
      { renderContent() }
      <div style={{textAlign: 'right'}}>
        <a href={ href }><i className="fas fa-arrow-right fa-lg blue"></i></a>
      </div>
    </div>
  )
}

export default ResultCard;