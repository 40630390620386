import React from 'react';
var createReactClass = require('create-react-class');

const EditorTotals = createReactClass({
  calculateTotal: function() {
    var self = this;
    return self.props.editors.map(function(ed) {
      return parseFloat(ed.total.replace(/\$|\,/g,''))
    }).reduce(function(a, b) { return a + b; }, 0);
  },

  render: function() {
    return(
      <div>
        <h4>Totals</h4>
        <table className="table-wrapper editors-table">
          <tbody>
            <tr>
              <td><strong>Editorial Costs:</strong></td>
              <td>{this.calculateTotal().toLocaleString("en-US", {style:"currency", currency:"USD"})}</td>
            </tr>
            <tr>
              <td><strong>Submissions/Content/Copy Editors:</strong></td>

              <td>{this.props.editors.length}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
})

export default EditorTotals;