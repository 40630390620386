var createReactClass = require('create-react-class');
import React from 'react';

const ArticleSubheaders = createReactClass({
  render: function(){
    return (
      <div>
        <h4 className="reg">How should I format my subheaders?</h4>
        <p>Cureus allows two levels of subheaders. The first (and highest) level should utilize the Subheading style available via the dropdown menu to the left of the italics button. These subheaders should be in sentence case and on a line separate from the rest of the text.</p>

        <div className='row'>
          <div className='columns large-12 centered'>
            <img src='https://public.cureus.com/subheader-format-one.png'/>
          </div>
        </div>
        <br/>
        <br/>
        <p>The second subheader level should be in title case, italicized and on a line separate from the rest of the text.</p>
        <div className='row'>
          <div className='columns large-12 centered'>
            <img src='https://public.cureus.com/subheader-format-two.png'/>
          </div>
        </div>
      </div>
    );
  }
});

export default ArticleSubheaders;