import React from 'react';
import DynamicJqueryTable from '../Shared/DynamicJqueryTable';

const Other = () => {
 
  const specialtiesColumns = [
    { title: 'Specialty', data: 'specialty', class: 'centered' },
    { title: 'Avg monthly articles (prior 3 months)', data: 'average_articles_per_month', class: 'centered' },
    { title: 'Editors in specialty', data: 'editors_count', class: 'centered' },
  ];

  return (
    <>
      <DynamicJqueryTable
        columnsConfig={specialtiesColumns}
        path="/admin/ae_dashboards/specialties"
        tableId="specialties"
        tableTitle={"#Editors vs #Articles per Specialty"}
        noDatepicker={true}
      />
    </>
  )
};

export default Other;