import {Cureus} from '../Cureus';

/* Cureus Flash
 * How to use:
 * Invoke Cureus.Flash.notice(...) to display a flash notice
 * Cureus.Flash.warning(...) to display a flash alert
 * arguments:
 *   <String> message : the message to display
 *   Optional <String> : a place to move the flash
 *                                           container, if you don't want it
 *                                           to appear in the usual palce
 */
Cureus.Flash = (function() {

    var HIDE_INTERVAL = 4000;
    var SLIDE_DURATION = 300;
    //DISMISS_CONTROL = '<a class="close" href="#"><i class="fa fa-remove"></i></a>';
    //DISMISS_CONTROL = '<a class="close" href="#">&times;</i></a>';
    var DISMISS_CONTROL = '<a class="close" href="#"><div class="flash-close-btn"></div></i></a>';
    var  flashContainer, flash, visible;

   function init() {
      flashContainer = document.getElementById('flash_container');
      if (!flashContainer) {
        throw new Error("Attempting to initialize Cureus.Flash with no flash container on the page.");
      }
      flash = document.createElement('div');
      flash.id = 'flash';
      visible = false;
    }

    function show() {
      if (visible) { return false; }
      document.getElementById('flash').style.display = 'block';
      Cureus.Flash.visible = true;
    }

    function hide () {
      document.getElementById('flash').style.display = 'none';
      Cureus.Flash.visible = false;
    }

    function info(message, placement) {
      generateFlash(message, placement, 'info');
    }

    function notice(message, placement) {
      generateFlash(message, placement, 'notice');
    }

    function success(message, placement) {
      generateFlash(message, placement, 'success');
    }

    function alert(message, placement) {
      generateFlash(message, placement, 'alert');
    }

    function warning(message, placement) {
      generateFlash(message, placement, 'warning');
    }

    function special(message, placement) {
      generateFlash(message, placement, 'special');
    }

    function generateFlash(message, placement, type) {

      flash.innerHTML = '<div class="alert-box ' + type + '" title="' + type.charAt(0).toUpperCase() + type.slice(1) + '" data-alert> <div class="row"> <div class="large-12 columns">' + DISMISS_CONTROL + '<span class="flash-content">' + message + '</span> </div> </div> </div>'
      $(flashContainer).append(flash);
      moveFlash(placement);
      refreshFoundation();
    }

    function refreshFoundation() {
      $(document).foundation();
    }

    function moveFlash(newPosition) {
      var where;
      if((typeof(newPosition === 'undefined')) || !newPosition) { return; }
      where = ((typeof(newPosition) === 'string') ? document.querySelector(newPosition) : (newPosition))
      if (!where) {
        throw new Error("Attempting to move the flash to an invalid position: ", new_position);
      }
      where.append(flashContainer);
    }
    return {
      init: init,
      show: show,
      hide: hide,
      info: info,
      special: special,
      notice: notice,
      success: success,
      alert: alert,
      warning: warning
    }
  })();
