var createReactClass = require('create-react-class');
import React from 'react';

const Thanks = createReactClass({
  componentDidMount: function() {
    var self = this;
    if(self.props.reviewer === "reviewer") {
      document.querySelector(".article-rating-modal-container").style.display = 'none'
    } else {
      setTimeout(function() {
        self.props.handleClose()
      }, 2000)
    } 
  },

  render: function (){
    return (
     <div className="thanks-container">
       <img className='siq-modal-thank-you-img' src='https://public.cureus.com/siq-modal-thank-you.png'></img>
     </div>
    )
  }
});

export default Thanks;