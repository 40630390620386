import React from 'react';

const RaceAndEthnicity = () => {
  return (
    <React.Fragment>
      <h1>Reporting on Race and Ethnicity</h1>
      <p>
        Reporting on the collection of demographic data that include race and ethnicity is permitted. Please follow this guidance, as applicable: 
      </p>
      <ul className='indented-for-medium-up'>
        <li className='check'>
          Disclose the reason(s) for collecting and assessing such data (e.g., funding source mandate).
        </li>
        <li className='check'>
          Provide an explanation of the parties responsible for identifying race and ethnicity (e.g., self-reported, electronic health record data).
        </li>
        <li className='check'>
          Specific races and ethnicities are preferred to collective terminology; when not possible, authors must define collective categories such as “other”; however, do not use the collective term “non-White,” and instead indicate the specific groups.
        </li>
        <li className='check'>
          Capitalize the names of all races, ethnicities, and tribes. When doing so, ensure that racial and ethnic terms are not used as nouns (e.g., Latinos), instead using their preferred adjectival forms (e.g., Latino participants).
        </li>
        <li className='check'>
          Do not presume that certain racial or ethnic terms are synonymous, as geographic origin and regionalization are both important (e.g., Black and African American may not be interchangeable).
        </li>
      </ul>
    </React.Fragment>
  )
}

export default RaceAndEthnicity;