import React, { useState, useEffect } from 'react';
import ReactDom from 'react-dom';
import DateSelector from '../../../StatsWidget/DateSelector'
import moment from 'moment'

const ChannelAdminEditors = ({dateRanges}) => {
  const [editors, setEditors] = useState([])
  const [dateFrom, setDateFrom] = useState(dateRanges[0].from)
  const [dateTo, setDateTo] = useState(dateRanges[0].to)
  const [selectedRangeName, setSelectedRangeName] = useState("Current week")

  useEffect( () => {
    fetchEditors()
  }, [])

  useEffect(() => {
    initializeTable();
  }, [editors])

  useEffect(() => {
    redrawTable()
  }, [dateFrom, dateTo, selectedRangeName])

  const fetchEditors = () => {
    $.ajax({
      type: 'GET',
      url: '/admin/channels/articles_table_data',
      data: { dataType: 'editors'},
      success: function(data) {
        setEditors(data)
      }
    });
  }

  const setColumns = () => {
    return [
      {width: '25px'},
      {width: '25px'},
      {width: '50px', class: "email", "render": (email, type, row, meta) => { return `<a target='_blank' href='/admin/users/${row[6]}'>${email}</a>` }},
      {width: '50px', class: "channel", "render": (title, type, row, meta) => { return `<a target='_blank' href='/admin/channels/${row[7]}'>${title}</a>` }},
      {width: '25px'},
      {width: '25px'}
    ]
  }

  const mapColumns = (dt) => {
    editors.forEach((data) => {
      dt.fnAddData([
        data.first_name,
        data.last_name,
        data.email,
        data.title,
        data.date_added,
        data.user_type,
        data.id,
        data.channel_id
      ])
    })
  }

  const initializeTable = () => {
    let dt = $("#channel-admins-editors-table").DataTable({
      destroy: true,
      columns: setColumns(),
      searching: true,
      'language':{
         "emptyTable": "Loading..."
      },
      processing: true,
      dom: '<"row"<"large-7 columns"f><"large-3 columns time-range"><"large-2 columns end show-amount"l>><"table"t>r<"row bottom"<"large-4 columns"p>>',
    });
    mapColumns(dt)
    dt.api().draw()
    ReactDom.render(dateSelector(), document.querySelector('div.time-range'))
  }

  const redrawTable = () => {
    // This extends the global Datatable search function (even though i only want to affect this specific table) and will affect any other tables on this web page
    $("#channel-admins-editors-table").dataTable.ext.search.pop()
    $("#channel-admins-editors-table").dataTable.ext.search.push (
      function( settings, data, dataIndex ) {
        if ( data[4] == '') { return false; }

        let date = data[4]

        if (moment(date, "MM-DD-YYYY").isBetween(dateFrom, dateTo, null, '[]')) {
          return true;
        } else {
          return false;
        }
      }
    );
    $("#channel-admins-editors-table").dataTable().api().search('').draw()
  }

  const dateSelector = () => {
    return <DateSelector
      from={dateRanges[0].from}
      to={dateRanges[0].to}
      getStats={filterData}
      selectedRange={"Current week"}
      noFloat={true}
      dateRanges={dateRanges}
    />
  }

  const filterData = (from, to, rangeName) => {
    setDateFrom(from)
    setDateTo(to)
    setSelectedRangeName(rangeName)
  }

  return (
    <div className="channel-admin-editors campaigns-index">
      <table id="channel-admins-editors-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Channel</th>
            <th>Date Added/Removed</th>
            <th>Position</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  )
}
export default ChannelAdminEditors;

