import {Cureus} from '../Cureus';

Cureus.MediaLightbox = (function($, _) {
  var init, MediaLightbox, FilmStrip;

  var FILMSTRIP_OFFSET = 300,
      FILMSTRIP_SIZE = 5;

  MediaLightbox = function(options) {
    var self, items, videos;

    self = this;

    self.galleryOptions = _.extend({
      enabled: true
    }, options.galleryOptions || {});

    self.filmStrip = new FilmStrip();

    items = options.items || $([]);
    items.magnificPopup({
      image: {
        cursor: null
      },

      iframe: {
        cursor: null,
        markup: '<div class="mfp-iframe-scaler">'+
                '<div class="mfp-close"></div>'+
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                '</div>',
        patterns: {
          youtube: {
            index: 'youtube.com/',
            id: 'v=',
            src: '//www.youtube.com/embed/%id%?autoplay=1'
          }
        }
      },

      gallery: self.galleryOptions,

      callbacks: {
        open: function() {
          self.filmStrip.rebuild();
          self.resizeTable(this.content);
          self.updateMetadata(this.content);
        },

        close: function() {
          self.filmStrip.cleanUp();
        },

        change: function() {
          self.filmStrip.rebuild();
          self.resizeTable(this.content);
          self.updateMetadata(this.content);
        },

        resize: function() {
          self.resizeTable(this.content);
        }
      }
    });

    if(options.triggerSelector) {
      $triggers = $(options.triggerSelector);
      $triggers.click(function(e) {
        var $original = $($(this).attr("href"));
        $original.click();
        e.preventDefault();
        e.stopPropagation();
      });
    }
  };

  _.extend(MediaLightbox.prototype, {
    resizeTable: function($content) {
      $content.find(".table-wrap").css('max-height', $(window).height() - FILMSTRIP_OFFSET);
    },

    updateMetadata: function($content) {
      var instance = $.magnificPopup.instance;
      var counterText = (instance.index + 1) + " of " + instance.items.length;
      var titleText = instance.currItem.el.data("title");

      $content.find(".mfp-counter").html(counterText);
      $content.find(".mfp-title").html(titleText);
      
      if($content.hasClass("mfp-figure")) {
        if($content.find(".mfp-download").length != 0) {
          var $download = $content.find(".mfp-download");
          var $url = $("<a class='button secondary' target='_blank'>Download</a>");
          //is a figure
          $url.attr("href", $content.find("img").attr("src"));
          $download.html($url);
        } else {
          $content.find(".mfp-bottom-bar").prepend($("<div class='mfp-download'></div>"));
        }
      }
    }
  });

  FilmStrip = function() {
    this.$filmStrip = $("<div class='film-strip'></div>");
    this.mfp = $.magnificPopup.instance;
  };

  _.extend(FilmStrip.prototype, {
    rebuild: function() {
      this.mfp.wrap.append(this._buildFilmStrip());
    },

    _buildFilmStrip: function() {
      this._cacheItems();
      this._buildStrip();
      this._buildNavigation();
      this._bindEvents();
      this._setActiveThumbnail();

      return this.$filmStrip;
    },

    _setActiveThumbnail: function() {
      var $imgs = this.$filmStrip.find("img");

      $imgs.removeClass("active");
      $($imgs[this.mfp.index % FILMSTRIP_SIZE]).addClass("active");
    },

    cleanUp: function() {
      this.$filmStrip.empty();
      this.$filmStrip.remove();
    },

    _cacheItems: function() {
      var self = this;

      self._cachedItems = [];

      _.each(self.mfp.items, function(item, index) {
        var $item = item.el ? $(item.el) : $(item);

        var src = $item.data('thumbnail');
        if(!src) {
          src = $item.attr("href");
        }
        var $tile = $("<img>");

        $tile.attr('src', src);
        $tile.data('index', index);

        self._cachedItems.push($tile);
      });
    },

    _buildStrip: function() {
      this.$filmStrip.empty();

      var startingIndex = this._pageStartIndex();

      for(var i = startingIndex; i < (startingIndex + FILMSTRIP_SIZE); i++) {
        var $item = this._cachedItems[i];
        if($item != undefined) {
          this.$filmStrip.append($item);
        }
      }
    },

    _buildNavigation: function() {
      var $leftNav = $("<div class='filmstrip-nav filmstrip-left'></div>");
      var $rightNav = $("<div class='filmstrip-nav filmstrip-right'></div>");
      var $placeholderNav = $("<div class='filmstrip-nav'></div>");

      if(this._canNavigateLeft()) {
        this.$filmStrip.prepend($leftNav)
      } else {
        this.$filmStrip.prepend($placeholderNav);
      }

      if(this._canNavigateRight()) {
        this.$filmStrip.append($rightNav)
      } else {
        this.$filmStrip.prepend($placeholderNav);
      }
    },

    _navigateRight: function() {
      if(!this._canNavigateRight()) { return };
      var index = this._pageStartIndex() + FILMSTRIP_SIZE;
      this.mfp.goTo(index);
      this.rebuild();
    },

    _navigateLeft: function() {
      if(!this._canNavigateLeft()) { return };
      var index = this._pageStartIndex() - 1;
      this.mfp.goTo(index);
      this.rebuild();
    },

    _canNavigateRight: function() {
      if(this._pageStartIndex() + FILMSTRIP_SIZE > this.mfp.items.length) {
        return false;
      } else {
        return true;
      }
    },

    _canNavigateLeft: function() {
      if(this._pageStartIndex() == 0) {
        return false;
      } else { 
        return true;
      }
    },

    _pageStartIndex: function() {
      return Math.floor(this.mfp.index / FILMSTRIP_SIZE) * FILMSTRIP_SIZE;
    },

    _bindEvents: function() {
      var self = this;

      self.$filmStrip.find("img").click(function() {
        self.mfp.goTo($(this).data('index'));
      });

      self.$filmStrip.click(function(e) {
        e.stopPropagation();
      });

      self.$filmStrip.find(".filmstrip-left").click(function() {
        self._navigateLeft();
      });

      self.$filmStrip.find(".filmstrip-right").click(function() {
        self._navigateRight();
      });
    }
  });

  init = function(options) {
    new MediaLightbox(options);
  };

  return { init: init };
})(jQuery, _);
