import React, { useEffect } from 'react';
import Chart from 'chart.js-v3/auto';

const BarChart = ( { ctx, datasets, backgroundColor, numberWithCommas} ) => {

  useEffect(() => {
    $(`#${ctx}`).remove();
    $(`#${ctx}-container`).append(`<canvas id='${ctx}'></canvas>`);
    const options = { 
      scales: {
        y: {
          stacked: true
        },
        x: {
          stacked: true
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          align: 'start',
          labels: {
            sort: function(a, b) {
              return a.text.localeCompare(b.text)
            }
          }
        },
        messages: {empty: "No data for this period"},
        elements: { 
          line: { tension: 0 }
        },
        title: {
          display: true,
          text: 'Cumulative Cureus and PMC Article Reads',
          align: 'start',
          color: 'black',
          font: {
            size: 22,
            weight: 'bold',
          },
          padding: {
            top: 20,
            bottom: 20
          }
        },
        tooltip: {
          backgroundColor: 'white',
          mode: 'index',
          xPadding: 12,
          yPadding: 12,
          intersect: false,
          borderColor: "#9B9B9B", 
          borderWidth: 1, 
          titleAlign: "center", 
          titleMarginBottom: 10, 
          caretSize: 0,
          caretPadding: 10,
          titleColor: 'black',
          itemSort: function(a, b) {
            return a.dataset.name.localeCompare(b.dataset.name)
          },
          callbacks: {
            afterBody: function(tooltipItem, data) {
              var sum = 0;
              tooltipItem.forEach(function(item) {
                  sum += item.dataset.data[item.label]
              })
              return "Total: " + numberWithCommas(sum)
            },
            labelTextColor: function() {
              return 'black'
            }
          }
        }
      }
    }
    new Chart(ctx, {
      type: 'bar',
      data: {
        datasets: datasets
      },
      options: options
    })
    if(backgroundColor) {
      $(`#${ctx}`).css('background-color', backgroundColor)
    }
  })

  return (
    <div className="metrics-charts" id={`${ctx}-container`}>
      <canvas id={ctx}></canvas>
    </div>
  )
}

export default BarChart;