import {Cureus} from '../../Cureus';

Cureus.JumpToTop = (function($, _) {
  var init, JumpToTop, setScrollThreshold,
    curr_scroll_threshold_pct,
    DEFAULT_SCROLL_THRESHOLD_PERCENT;

  DEFAULT_SCROLL_THRESHOLD_PERCENT = 30;
  curr_scroll_threshold_pct = DEFAULT_SCROLL_THRESHOLD_PERCENT;

  init = function() {
    new JumpToTop();
  };

  setScrollThreshold = function (new_threshold) {
    curr_scroll_threshold_pct = new_threshold;
  };

  JumpToTop = function(options) {
    this.$button = $('.back-to-top-button');

    $("body").append(this.$button);

    this._bindEvents();
  };

  _.extend(JumpToTop.prototype, {
    _bindEvents: function() {
      var self = this;
      var $document = $(document);

      $(window).on("scroll", function() {
        var scrollPercent = ($document.scrollTop() / $document.height()) * 100;

        if(scrollPercent > curr_scroll_threshold_pct) {
          self.$button.fadeIn(150);
        } else {
          self.$button.fadeOut(150);
        }
      });

      this.$button.on("click", function() {
        $('html,body').animate({ 
          scrollTop: 0 
        }, 600, 'easeInOutQuint');
      });
    }
  });

  return { 
    init: init,
    setScrollThreshold: setScrollThreshold
  };
})(jQuery, _);
