import React, { memo } from 'react';

const Buttons = ({ clearCategory, handleClearCategories, handleShowMore, handleSubmit, showMore }) => {
  const showMoreText = showMore ? 'Show less' : 'Show more';
  return (
    <div className="button-container">
      <button className="button stencil-blue small show-more" onClick={handleShowMore}>
        {showMoreText}
      </button>

      <button
        className="button home-orange small clear-categories"
        style={{display: clearCategory ? 'block' : 'none'}}
        onClick={(event) => handleClearCategories(event, 'allCategories')}
      >
        Clear Categories
      </button>

      <button className="button small apply" onClick={handleSubmit}>
        Apply
      </button>
    </div>
  )
}

export default memo(Buttons)