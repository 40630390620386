import React, {useState, useEffect} from 'react';
import Draft from '../../Shared/DropDownArticleStates/Draft';
import EditorPreview from '../../Shared/DropDownArticleStates/EditorPreview';
import EditorPreviewDeferred from '../../Shared/DropDownArticleStates/EditorPreviewDeferred';
import EditorCheck from '../../Shared/DropDownArticleStates/EditorCheck';
import EditorCheckDeferred from '../../Shared/DropDownArticleStates/EditorCheckDeferred';
import InReview from '../../Shared/DropDownArticleStates/InReview';
import ReviewsComplete from '../../Shared/DropDownArticleStates/ReviewsComplete';
import ChannelEditorApproval from '../../Shared/DropDownArticleStates/ChannelEditorApproval';
import ChannelEditorApprovalDeferred from '../../Shared/DropDownArticleStates/ChannelEditorApprovalDeferred';
import CureusEditorApproval from '../../Shared/DropDownArticleStates/CureusEditorApproval';
import CureusEditorApprovalDeferred from '../../Shared/DropDownArticleStates/CureusEditorApprovalDeferred';
import Approved from '../../Shared/DropDownArticleStates/Approved';
import Published from '../../Shared/DropDownArticleStates/Published';
import DeskRejected from '../../Shared/DropDownArticleStates/DeskRejected';
import Blocked from '../../Shared/DropDownArticleStates/Blocked';
import Abandoned from '../../Shared/DropDownArticleStates/Abandoned';
import All from '../../Shared/DropDownArticleStates/All';

const Main = ({collectionArticlesPath, articlesCount, createPath, templatePath}) => {

  const [data, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    setSpinning(true)
    $.ajax({
      type: 'GET',
      url: collectionArticlesPath,
      success: function(data) {
        setData(data)
        setSpinning(false)
        $(document).foundation('reflow');
      }
    });
  }

  const renderChevronContainer = (expanded, setExpanded, className='') => {
    return (
      <div className="chevron-arrow-container">
        <img 
          src="https://public.cureus.com/channel-icons/chevron-thin-down.png" 
          onClick={() => setExpanded(!expanded)} 
          onMouseOver={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-hovered-down.png")}
          onMouseOut={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-thin-down.png")}
          onMouseDown={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-clicked-down.png")}
          onMouseUp={e => (e.currentTarget.src = "https://public.cureus.com/channel-icons/chevron-hovered-down.png")}
          className={`chevron-toggle ${className} ${expanded ? "rotate" : ""}`}
        />
      </div>
    )
  }

  const renderMailIcon = (state) => {
    return (
      <div className="button primary small bulk-email-button disabled has-tip tip-left" data-tooltip="" title="Select one or more checkboxes and click this button to send email reminders." id={"mail-tooltip-" + state}>
        <i className="fa fa-envelope"></i>
      </div>
    )
  }

  if (!data || !data.initial_data) {
    return <div/>
  } else {
    return (
      <React.Fragment>
        <h5 className="articles-header">Articles Submitted to Collection <span>({articlesCount})</span></h5>
        <div className="articles-container">
          <Draft renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.draft} isParent={false} renderMailIcon={renderMailIcon} />
          <EditorPreview renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.editor_preview} isParent={false} renderMailIcon={renderMailIcon} />
          <EditorPreviewDeferred renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.editor_preview_deferred} isParent={false} renderMailIcon={renderMailIcon} />
          <EditorCheck renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.editor_check} isParent={false} renderMailIcon={renderMailIcon} />
          <EditorCheckDeferred renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.editor_check_deferred} isParent={false} renderMailIcon={renderMailIcon} />
          <InReview renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.in_review} isParent={false} renderMailIcon={renderMailIcon}/>
          <ReviewsComplete renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.reviews_complete} isParent={false} renderMailIcon={renderMailIcon}/>
          <ChannelEditorApproval renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.channel_editor_approval} isParent={false} renderMailIcon={renderMailIcon}/>
          <ChannelEditorApprovalDeferred renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.channel_editor_approval_deferred} isParent={false} renderMailIcon={renderMailIcon}/>
          <CureusEditorApproval renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.cureus_editor_approval} isParent={false} renderMailIcon={renderMailIcon}/>
          <CureusEditorApprovalDeferred renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.cureus_editor_approval_deferred} isParent={false} renderMailIcon={renderMailIcon}/>
          <Approved renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} createPath={createPath} templatePath={templatePath} initialData={data.initial_data.approved} isParent={false} renderMailIcon={renderMailIcon}/>
          <Published renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.published} isParent={false} renderMailIcon={renderMailIcon}/>
          <DeskRejected renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.desk_rejected} isParent={false} renderMailIcon={renderMailIcon}/>
          <Blocked renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.blocked} isParent={false} renderMailIcon={renderMailIcon}/>
          <Abandoned renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.abandoned} isParent={false} renderMailIcon={renderMailIcon}/>
          <All renderChevronContainer={renderChevronContainer} path={collectionArticlesPath} initialData={data.initial_data.all} isParent={false} renderMailIcon={renderMailIcon}/>
        </div>
      </React.Fragment>

    )
  }
}

export default Main;