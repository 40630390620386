var createReactClass = require('create-react-class');
import React from 'react';

const Post = createReactClass({
  render: function(){
    return (
      <a href={this.props.post.newsroomPostPath} target="_blank" className={this.props.index}>
        <div className="large-3 medium-5 small-8 columns content-card-container centered end">
          <div className="newsroom-content-card" data-equalizer-watch="newsroom">
              <div className="newsroom-content-card-thumbnail">
                <div className="newsroom-content-card-thumbnail-mask"/>
                <img src={this.props.post.thumbnailImagePath}/>
              </div>
            <div className="newsroom-content-card-details">
                <a href={this.props.post.newsroomPostPath} target="_blank">
                  <h4 className="reg">{this.props.post.title}</h4>
                </a>
                <br/>
                <p>{this.props.post.introMessage}</p>
                <p>{this.props.post.publishDate}</p>
            </div>
          </div>
        </div>
      </a>
    );
  }
})

export default Post;