import React from 'react';
import Label from './Label'
import { useState } from 'react';

const QuestionAnswerItem = ({id, question, answer, handleQuestionChange, subtitle, type}) => {

  const [isEmpty, setIsEmpty] = useState(true);
  const handleAnswerChange = (e) => {
    handleQuestionChange(id, e.target.value);
    setIsEmpty(e.target.value.trim() == "");
  };

  if(type != "CredibleConclusionsQuestion") {
    return (
      <div className='row'>
        <div className="small-12 columns question-container">
          <Label label={question} sublabel={subtitle} />
          <textarea className='questionnaire-answer' value={answer} onChange={handleAnswerChange}></textarea>
          {isEmpty && (
            <p className='empty-response'>This field is required.</p>
          )}
        </div>      
      </div>
    )
  } else {
    return null;
  }
}

export default QuestionAnswerItem;