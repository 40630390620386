
var createReactClass = require('create-react-class');
import React from 'react';
import Name from '../../UserPicker/Name';
import Affiliation from './Affiliation';

const AuthorForm = createReactClass({
  getAffiliationErrors: function(idx) {
    if(this.props.errors && this.props.errors.affiliations) {
      return this.props.errors.affiliations[0][idx]
    }
  },

  getNameErrors: function() {
    if(this.props.errors) {
      var errors = {}
      if (this.props.errors.first_name) {
        errors.first_name = this.props.errors.first_name[0];
      }
      if (this.props.errors.last_name) {
        errors.last_name = this.props.errors.last_name[0];
      }
      if (Object.keys(errors).length > 0) {
        return errors;
      }
    }
  },

  renderAffiliations: function() {
    var self = this;
    var affiliations = this.props.affiliations;
    let count = affiliations.length
    return affiliations.map(function(affiliation, idx) {
      return <Affiliation
        idx={ idx }
        count= { count }
        affiliation={ affiliation }
        affiliationNameQueryUrl={ self.props.affiliationNameQueryUrl }
        deptAutocompletePath={ self.props.deptAutocompletePath }
        countryOptions={ self.props.countryOptions }
        handleChange={ self.props.handleAffiliationChange }
        handleCountryChange={ self.props.handleCountryChange }
        handleAffiliationAdd={ self.props.handleAffiliationAdd }
        handleAffiliationRemove={ self.props.handleAffiliationRemove }
        errors={ self.getAffiliationErrors(idx) }
        // todo jb: update this later.
        editable={ true }
        toggleSpinner={ self.props.toggleSpinner }
        canAdmin={ self.props.canAdmin }/>
    })
  },

  renderSpinner: function() {
    if (this.props.spinner) {
      return (
        <img style={{position: 'absolute', left: '50%'}}src="https://public.cureus.com/ajax-loader.gif"/>
      )
    }
  },

  render: function() {
    var user = this.props.user;
    return (
      <div className="user-details-wrap">
        <div className="form-fields">
          <Name
          id={ this.props.id }
          first_name={ user.first_name }
          middle_initial={ user.middle_initial }
          last_name={ user.last_name }
          suffix={ user.suffix }
          editable={ true }
          handleChange={ this.props.handleNameChange }
          errors={ this.getNameErrors() }
          canAdmin={ this.props.canAdmin }
          />
          { this.renderSpinner() }
          { this.renderAffiliations() }
        </div>
      </div>
    )
  }
})

export default AuthorForm;