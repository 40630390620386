var createReactClass = require('create-react-class');
import React from 'react';

const Main = createReactClass({
  getInitialState: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  removeImage: function() {
    let self = this;
    $.ajax({
      type: "PUT",
      url: self.userUpdatePath,
      dataType: "json",
      data: {
        user: { avatar: "remove_avatar" },
        section: 'AvatarForm'
      },
      success: function () {
        let avatar = document.querySelector(".uploadable-image .avatar-image");
        avatar.src = "https://public.cureus.com/new-default-user-image.png"
      }
    });
  },

  renderUpload: function() {
    if (this.props.allowUpload){
      if (this.props.buttonText == "Change Image") {
        return (
          <>
            <a href="#" data-options="align:bottom" data-dropdown="drop" aria-expanded="false" className="button tiny special change-image">Change Image</a>
            <ul id="drop" className="tiny f-dropdown" data-dropdown-content>
              <li>
                <a href="#" id="avatar-uploader-button" data-select-file="#avatar-file">
                    ADD NEW
                </a>
              </li>
              <li><a href="#" onClick={this.removeImage}>REMOVE</a></li>
            </ul>
          </>
        )
      }
      else {
        return (
          <div className="button tiny special" id="avatar-uploader-button" data-select-file="#avatar-file">
            {this.props.buttonText}
          </div>
        )
      }
    }
  },

  render: function() {
    return (
      <div className="uploadable-image" id="avatar-uploader-wrap">
        <div className="fancy-circle-image small-circle">
          <div dangerouslySetInnerHTML= {{__html: this.props.avatarImageMarkup}} />
          <div className="fancy-image-overlay"/>
        </div>
        <div className="centered">
          {this.renderUpload()}
        <form className="edit_user" id="edit_user" encType="multipart/form-data" action={this.props.userUpdatePath} acceptCharset="UTF-8" method="post">
          <input name="utf8" type="hidden" value="✓"/>
          <input type="hidden" name="_method" value="patch"/>
          <input name="authenticity_token" type="hidden" name={this.state.csrfParam} value={this.state.csrfToken}/>
          <div dangerouslySetInnerHTML={{__html: this.props.transloaditTemplate}}/>
          <input id="avatar-file" type="file" name="user[avatar]"/>
        </form>
        </div>
      </div>
    )
  }
})

export default Main;