import React, { Component } from 'react';

class Card extends Component {
  componentDidMount() {
    setTimeout(function() {
      $(document).foundation({
        equalizer : {
          // Specify if Equalizer should make elements equal height once they become stacked.
          equalize_on_stack: true,
          // Allow equalizer to resize hidden elements
          act_on_hidden_el: false,
        }
      });
    }, 200)
  }

  render() {
    let props = this.props;
    let className = props.subsequentBoost ? "large-4 medium-12 small-12 columns" : "large-3 medium-12 small-12 columns"
    return (
      <div className={className}>
        <div className="tile" data-equalizer-watch="boost">
          {this.props.type == "premium-plus" && <div class="popular"><strong>Popular</strong></div>}
          <h3>{props.header}</h3>
          <h4><strong>${props.price}</strong></h4>
          <a className="button purchase" name={props.type} data-price={props.price} onClick={(e) => props.handleSelection(e)}>Purchase</a>
          <p data-equalizer-watch="blurb">{props.blurb}</p>
          <span>{props.footer}</span>
        </div>
      </div>
    )
  }
}

export default Card;