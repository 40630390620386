import React, { useState, useEffect } from 'react';
import Main from '../Publishing/UserTool/Main';

const AddPersonModal = ({ userToolProps, fetchChannelReviewerData }) => {

  return (
    <div className="email-list-tool-modal reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>


        <div className="modal-content">
          <h5>Add Individual Reviewer</h5>
          <p>Adding an individual reviewer? Let’s see if they already have a Cureus account. Please enter the reviewer’s email address to search the Cureus database.</p>
          <Main fetchChannelReviewerData={fetchChannelReviewerData} {...userToolProps} />
        </div>
      </div>
    </div>
  )
}

export default AddPersonModal;