import React, { useEffect } from 'react';
import Modal from '../Modal';
import NpiModal from './NpiModal';

const Main = ({ npiData, user, npiPath, updateProfilePath }) => {

  useEffect(() => { 
    Modal.open({
      children: <NpiModal npiData={npiData} user={user} npiPath={npiPath} updateProfilePath={updateProfilePath}/>
    })
  }, [])

  return (
    <React.Fragment/>
  )

}

export default Main;