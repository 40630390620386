import React, {useEffect} from 'react';
import BigArticleCard from '../../../Shared/ContentCards/BigArticleCard'

const AbstractCard50 = ({content, showActionButtons, handleDeleteWidget, handleUpdateWidget, mainContentWidgets, slug}) => {
  return (
    <div className="large-6 columns left-container">
      <BigArticleCard
        resource={content}
        handleDeleteWidget={handleDeleteWidget}
        handleUpdateWidget={handleUpdateWidget}
        showCategories={true}
        showActionButtons={showActionButtons}
        customStyleClass={true}
        mainContentWidgets={mainContentWidgets}
        slug={slug}
      />
    </div>
  )
}

export default AbstractCard50;