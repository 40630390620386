
var createReactClass = require('create-react-class');
import React from 'react';
import DepartmentAutoSuggester from '../../UserPicker/DepartmentAutoSuggester';

const Department = createReactClass({
  getInitialState: function() {
    return {
      errors: this.props.errors
    }
  },
  
  handleDisabled: function() {
    if (!this.props.institution_name){
      return true;
    }
    return false;
  },

  renderInvalid: function() {
    if (this.props.department_name && (this.props.invalidDepartmentName || (this.props.errors && this.props.errors.department_name))) {
      return (
        <div>
          <label className="left">
            <input type="checkbox" onChange={this.props.handleChange} key={ this.props.id + "_confirm_department" } name={ "confirmDepartment" } id={ this.props.id + "_confirm_department" } ref="confirmDepartment" data-idx={ this.props.idx }/>
            &nbsp; I confirm the department is correct.
          </label>
        </div>
      )
    }
  },

  renderDepartmentNameField: function (){
    var class_name, error_field, text;

    class_name = "";
    error_field = null;
    if (this.props.errors && this.props.errors.department_name && !this.props.confirmDepartment) {
      text = this.props.errors.department_name
      class_name = "error";
      error_field = ( <div key="user_picker_department_name_errors" className="errors"> { text } </div> )
    }

    if(this.props.editable) {
      return (
        [
          (<DepartmentAutoSuggester deptAutocompletePath={this.props.deptAutocompletePath} institutionName={this.props.institution_name}>
          <input key={this.props.id + "_department_name_field"} type="text" name="departmentName" id={this.props.id + "_department_name"} ref="departmentName" data-idx={this.props.idx} data-error_key="department_name"className={class_name} onChange={this.props.handleChange} value={this.props.department_name} disabled={this.handleDisabled()} autoComplete='new-password' />
          </DepartmentAutoSuggester>), 
          error_field
        ]
      )
    }
    return (<input type="text" name="department_name" key={this.props.id + "_department_name_field"} id={this.props.id + "_department_name"} value={this.props.department_name} disabled="disabled" autoComplete='new-password' />)
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <label>Department/Specialization (Med students should list relevant department.)</label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            { this.renderDepartmentNameField() }
            { this.renderInvalid() }
          </div>
        </div>
      </div>
    )
  }
})

export default Department;