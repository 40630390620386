import React from 'react';

const PictureBubbles = ({avatars, count}) => {

  const renderPictureBubbles = () => {
    return avatars.map( (avatar, idx) => {
      let left = -7 * idx
      if (idx == avatars.length - 1 && count) { return renderCount(left) }
      return <img key={avatar.id} style={{left: left}} src={avatar.avatar_profile_url} />
    })
  }

  const renderCount = (left) => {
    let ct = formatCount(count)
    return <div className="author-count text-center" style={{left: left}}>{ct}</div>
  }

  const formatCount = (count) => {
    if (count > 999) {
      return `${~~(count / 1000)}k+`
    } else if (count > 999999) {
      return `${(count / 1000000).toFixed(2)}m+`
    } else {
      return count
    }
  }

  return (
    <div className="picture-bubbles">
      {renderPictureBubbles()}
    </div>
  )
}

export default PictureBubbles