import React, { useState, useEffect } from 'react';
import { Cureus } from '../../Cureus';

const FeaturedContentModal = ({ setSaveButtonEnabled, newsroomPostDetailsPath, articleExistsPath, givenCard, setOriginalCard, cards, setCards, cardIndex}) => {

  const [card, setCard] = useState({...givenCard})
  const [addButtonEnabled, setAddButtonEnabled] = useState(true)
  const [loadedCKEditor, setLoadedCKEditor] = useState(false)
  const [description, setDescription] = useState(null)
  const [articleExists, setArticleExists] = useState(true)
  const [newsroomPostExists, setNewsroomPostExists] = useState(true)

  const defaultCardValues = {
    'metrics': {content_type: 'metrics', image: null, preview_image: null, new_image: false, mobile_image: null, preview_mobile_image: null, new_mobile_image: false},
    'article': {content_type: 'article', article_id: '', article_title: '', article_authors: [], published_at: '', image: null, preview_image: null},
    'news': {content_type: 'news', description: '', cta_type: 'button', image: null, preview_image: null, new_image: false, cta_text: '', cta_url: ''},
    'cureus-u': {content_type: 'cureus-u', newsroom_post_id: null, newsroom_post_thumbnail: '', newsroom_post_path: '', description: ''},
  }

  useEffect(() => {
    if (!loadedCKEditor){
      [['article-description', updateArticleDescription], ['cureus-u-description', updateCureusUDescription], ['news-description', updateNewsDescription]].forEach( (ele) => {
        new Cureus.CKEditor(ele[0], {placeholderText: card.description, handle_change: ele[1], editor_type: 'static_text_with_color', ckeditor: {height: 200}})
      })
      setLoadedCKEditor(true)
    }
  })

  useEffect(() => {
    if (card.content_type == 'metrics'){
      let enableAddButton = card.image !== null && card.mobile_image !== null
      setAddButtonEnabled(enableAddButton)
    }
    if (card.content_type == 'news'){
      let enableAddButton = card.image !== null && card.cta_text != '' && card.cta_url != '' && card.description != ''
      setAddButtonEnabled(enableAddButton)
    }
    if (card.content_type == 'cureus-u'){
      let enableAddButton = card.description !== '' && card.newsroom_post_id !== null && card.newsroom_post_id !== '' && newsroomPostExists
      setAddButtonEnabled(enableAddButton)
    }
    if (card.content_type == 'article'){
      let enableAddButton = card.article_id != null && articleExists
      setAddButtonEnabled(enableAddButton)
    }
  }, [card])

  useEffect(() => {
    if (['news', 'cureus-u', 'article'].includes(card.content_type)){
      card.description = description
      setCard({...card})
    }
  }, [description])

  const checkArticleExists = (e) => {
    const articleId = e.target.value
    if (articleId == '') {
      card.article_id = null
      setCard({...card})
      return
    }
    $('#article-id').prop('disabled', true)
    $.ajax({
      type: 'POST',
      url: articleExistsPath,
      data: {article_id: articleId},
      success: function(data) {
        card.article_id = articleId
        $('#article-id').prop('disabled', false)
        $('#article-id').focus()
        if (data.article_exists) {
          setArticleExists(true)
          card.article_title = data.title
          card.article_authors = data.authors
          card.published_at = data.published_at
          card.article_type = data.type
          card.article_image = data.article_image
          card.article_image_large = data.article_image_large
        } else {
          setArticleExists(false)
        }
        setCard({...card})
      }
    });
  }

  const addCard = () => {
    let shallowCardCopy = {...card}
    let shallowCardsCopy = [...cards]
    // update the cards array at appropriate index for given card
    shallowCardsCopy[cardIndex] = shallowCardCopy
    setOriginalCard(shallowCardCopy)
    setCards(shallowCardsCopy)
    setSaveButtonEnabled(true)
  }

  const addImage = (e) => {
    const file = e.target.files[0]
    const url = URL.createObjectURL(file);

    card.preview_image = url
    card.image = file
    card.new_image = true
    setCard({...card})
  }

  const addMobileImage = (e) => {
    const file = e.target.files[0]
    const url = URL.createObjectURL(file);

    card.preview_mobile_image = url
    card.mobile_image = file
    card.new_mobile_image = true
    setCard({...card})
  }

  const updateNewsDescription = () => {
    setDescription(CKEDITOR.instances["news-description"].getData())
  }

  const updateCureusUDescription = () => {
    setDescription(CKEDITOR.instances["cureus-u-description"].getData())
  }

  const updateArticleDescription = () => {
    setDescription(CKEDITOR.instances["article-description"].getData())
  }

  const updateName = (e) => {
    card.cta_text = e.target.value
    setCard({...card})
  }

  const updateUrl = (e) => {
    card.cta_url = e.target.value
    setCard({...card})
  }

  const updateCtaType = (e) => {
    card.cta_type = e.target.value
    setCard({...card})
  }

  const updateNewsroomPostId = (e) => {
    const newsroomPostId = e.target.value
    $('#newsroom-post-input').prop('disabled',true)
    $.ajax({
      type: 'POST',
      url: newsroomPostDetailsPath,
      data: {newsroom_post_id: newsroomPostId},
      success: function(data) {
        $('#newsroom-post-input').prop('disabled',false)
        $('#newsroom-post-input').focus()
        setNewsroomPostExists(true)
        card.newsroom_post_id = data.newsroom_post_id
        card.newsroom_post_thumbnail = data.newsroom_post_thumbnail
        card.newsroom_post_path = data.newsroom_post_path
        setCard({...card})
      },
      error: function(data) {
        $('#newsroom-post-input').prop('disabled',false)
        $('#newsroom-post-input').focus()
        setNewsroomPostExists(false)
        card.newsroom_post_id = newsroomPostId
        setCard({...card})
      }
    });
  }

  const radioClick = (content_type) => {
    if (content_type === card.content_type)
      return
    if( ! (content_type in defaultCardValues) )
      return
    let newCard = defaultCardValues[content_type]
    $('#article-id').val(null)
    $('input[type="file"]').val(null)
    CKEDITOR.instances["news-description"].setData("")
    CKEDITOR.instances["cureus-u-description"].setData("")
    CKEDITOR.instances["article-description"].setData("")
    setCard(newCard)
  }
  return (
    <div className="featured-content-modal reveal-modal middling" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-header">
          <h5>
            Featured Content
          </h5>
        </div>

        <div className="modal-content">
          <div className='modal-form'>


            <p>Featured content is displayed in a rotating carousel near the top of the homepage.</p>

            <p><b>Select content type:</b></p>

            <div>
              <input type="radio" id="metrics-radio" onChange={() => radioClick('metrics')} checked={card.content_type === 'metrics'} value="metrics" />
              <label for="metrics-radio">Metrics</label>
              <div className={card.content_type === 'metrics' ? '' : 'hide'}>
                <div className="row">
                  <div className="columns small-3"><span>Desktop Image</span></div>
                  <div className="columns small-9"><input type="file" name="file" onChange={(e) => addImage(e)} /></div>
                </div>
                <div className="row">
                  <div className="columns small-3"><span>Mobile Image</span></div>
                  <div className="columns small-9"><input type="file" name="file" onChange={(e) => addMobileImage(e)} /></div>
                </div>
                {card.image && !card.preview_image &&
                  <img className="image-preview" src={card.image.url} alt="metrics preview image" />
                }
                {card.preview_image &&
                  <img className="image-preview" src={card.preview_image} alt="metrics preview image" />
                }
              </div>
            </div>
            <div>
              <input type="radio" id="article-radio" onChange={() => radioClick('article')} checked={card.content_type === 'article'} value="article" />
              <label htmlFor="article-radio">Article</label>
              <div className={card.content_type === 'article' ? '' : 'hide'}>
                <div className="input-container">
                  <label>Article ID</label>
                  <input id="article-id" type="text" placeholder="Article ID is needed in order to add it to the homepage" onChange={(e) => checkArticleExists(e)} value={card.article_id} />
                  <span className={`article-error ${articleExists ? 'hide' : ''}`}>No article found</span>
                </div>
                <textarea id="article-description" name="article-description" style={{height: '300px'}} />
                <div className="row under-text-area">
                  <div className="columns small-2"><span>Image</span></div>
                  <div className="columns small-10"><input type="file" name="file" onChange={(e) => addImage(e)} /></div>
                  {card.image && card.image.url && !card.preview_image &&
                    <img className="image-preview" src={card.image.url} alt="article preview image" />
                  }
                  {card.preview_image &&
                    <img className="image-preview" src={card.preview_image} alt="article preview image" />
                  }
                </div>
              </div>
            </div>
            <div>
              <input type="radio" id="news-radio" onChange={() => radioClick('news')} name="news" checked={card.content_type === 'news'} value="news" />
              <label htmlFor="news-radio">News</label>
              <div className={card.content_type === 'news' ? '' : 'hide'}>
                <textarea id="news-description" name="news-description" style={{height: '300px'}} />

                <div className="row under-text-area">
                  <div className="columns small-2"><span>Image</span></div>
                  <div className="columns small-10"><input type="file" name="file" onChange={(e) => addImage(e)} /></div>
                  {card.image && !card.preview_image &&
                    <img className="image-preview" src={card.image.url} alt="news preview image" />
                  }
                  {card.preview_image &&
                    <img className="image-preview" src={card.preview_image} alt="news preview image" />
                  }
                </div>

                <div className="row">
                  <div className="columns small-2"><span>CTA Type</span></div>
                  <div className="columns small-10">
                    <select defaultValue={card.cta_type} onChange={(e) => {updateCtaType(e)}}>
                      <option value="button">Button</option>
                      <option value="arrow">Arrow</option>
                    </select>
                  </div>
                </div>

                <div className="input-container">
                  <label>Name</label>
                  <input type="text" placeholder="Please, provide a name for the button" onChange={(e) => updateName(e)} value={card.cta_text} />
                </div>

                <div className="input-container">
                  <label>URL</label>
                  <input type="text" placeholder="Enter the link." onChange={(e) => updateUrl(e)} value={card.cta_url} />
                </div>

              </div>
            </div>
            <div>
              <input type="radio" id="cureus-u-radio" onChange={() => radioClick('cureus-u')} checked={card.content_type === 'cureus-u'} value="cureus-u" />
              <label htmlFor="cureus-u-radio">Cureus U</label>
              <div className={card.content_type === 'cureus-u' ? '' : 'hide'}>
                <div className="input-container">
                  <label>Newsroom Post ID</label>
                  <input type="text" id="newsroom-post-input" placeholder="Enter campaign article ID." onChange={(e) => updateNewsroomPostId(e)} value={card.newsroom_post_id} />
                  <span className={`article-error ${newsroomPostExists ? 'hide' : ''}`}>No newsroom post found</span>
                </div>
                <textarea id="cureus-u-description" name="cureus-u-description" style={{height: '300px'}} />
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 text-center">
                <button className={`add-modal-button ${addButtonEnabled ? 'cancel-modal' : 'disabled'}`} onClick={() => addCard()}>Add</button>
              </div>
              { !addButtonEnabled &&
                <div className="columns small-12">
                  <span className="add-warning">You cannot add a widget without entering the details</span>
                </div>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}


export default FeaturedContentModal;