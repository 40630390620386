import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import TeamMemberRow from './ChannelTeam/TeamMemberRow';
import Sortable from '../../../components/Sortable';


const ChannelTeamWidget = ({slug, handleDeleteWidget, handleUpdateWidget, mainContentWidgets, widget_id, showActionButtons=true}) => {

  const [contents, setContents] = useState([]);

  useEffect(() => {
    let widgetId = widget_id || mainContentWidgets["Widget::ChannelTeam"]
    if(widgetId) {
      let onSuccess = (res) => {
        setContents(res.contents)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [mainContentWidgets])

  const handleSort = (e, sortable, movedComponent) => {
    let widgetId = movedComponent.props.children.props.content[0].widgetId
    let params = {widget: {position: e.newIndex + 1}}
    ChannelApi.updateWidget(widgetId, params, slug)
  }

  const renderContent = () => {
    return contents.map(function(content, idx) {
      if(widget_id) {
        return (
          <TeamMemberRow
            content={content}
            showActionButtons={showActionButtons}
            handleDeleteWidget={handleDeleteWidget}
            handleUpdateWidget={handleUpdateWidget}
            mainContentWidgets={mainContentWidgets}
            slug={slug}
          />
        )
      } else {
        return (
          <div className="card drag-handle" key={idx}>
            <TeamMemberRow
              content={content}
              showActionButtons={showActionButtons}
              handleDeleteWidget={handleDeleteWidget}
              handleUpdateWidget={handleUpdateWidget}
              mainContentWidgets={mainContentWidgets}
              slug={slug}
            />
          </div>
        )
      }
    })
  }

  return (
    <div className="row">
      <div className="small-12 columns widget channel-team">
      <h2>Channel Team</h2>
      <Sortable tagName='div' handleSort={ handleSort }>
        {renderContent()}        
      </Sortable>
      </div>
    </div>
  )
}

export default ChannelTeamWidget;