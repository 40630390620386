import React, {useState, useEffect} from 'react';

const SpecialtyPromotion = ({categoryIdPreSelected}) => {

  const [promotion, setPromotion] = useState({})

  useEffect(()=> {
    $.ajax({
      url: `/specialties/${categoryIdPreSelected}/get_specialty_promotion`,
      type: 'GET',
      dataType: 'json',
      success: function(res) {
        setPromotion(res.promotion);
      }
    });
  }, [])

  return (
    <>
      {promotion && <div className="small-12 columns show-for-large-up specialty-promotion-container">
        <a href={promotion.url} target="_blank">
          <img src={promotion.image} />
        </a>
      </div>}
    </>
  )
}

export default SpecialtyPromotion;