import React from 'react';

const FiltersModal = ({filterComponent}) => {
  return (
    <div className="reveal-modal large" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content">
          <h5>Filter Browse People</h5>
          {filterComponent}
        </div>
      </div>
    </div>
  )
}

export default FiltersModal