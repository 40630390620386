import React, { useState, useEffect } from 'react';
import { Cureus } from '../../Cureus';

const AttachmentsTab = ({html}) => {
  useEffect(() => {
    new Cureus.LazyLoader.init({selector:'img[data-lazy]'});
    Cureus.MediaLightbox.init({
      items: $(".gallery-wrap .gallery-item")
    });
  }, [])

  return (
    <div className="row">
      <div className="small-12 columns">
        <div className="tab-content-container">
          <div dangerouslySetInnerHTML={{__html: html}}/>
        </div>
      </div>
    </div>
  )
}

export default AttachmentsTab;