var createReactClass = require('create-react-class');
import React from 'react';
import AttachmentUploadMixin from './AttachmentUploadMixin';
import Errors from './Errors';
import Details from './Details';

const Edit = createReactClass({
  mixins: [AttachmentUploadMixin],
  disableButtonClass: function() {
    return this.props.disableButton ? "disabled" : ''
  },

  renderProgressBar: function() {
    return (
      <div className="media-uploader">
        <div className="media-preview">
          <div className='uploading'>
            <div className='progress-striped active'>
              {this.renderProgressBarText()}
              <div ref='progressBar' className='bar progressBar'></div>
            </div>
          </div>
        </div>
      </div>
    )
  },

  renderProgressBarText: function() {
    if (this.props.finishedUploading) {
      return <p>Please wait, we are processing the figure...</p>
    }
  },

  renderTransloadit: function() {
    return (
      <div className="media-uploader">
        {this.props.transloadit()}
      </div>
    )
  },

  renderFileDrop: function(){
    if (this.props.attachment){
      return this.props.isUploading ? this.renderProgressBar() : this.renderTransloadit()
    } else {
      return null
    }
  },

  renderErrors: function() {
    if (this.props.errorMessage) {
      return (
        <Errors message={this.props.errorMessage}/>
      )
    }
  },

  renderModalBody: function(){
    if (this.props.attachment && this.props.reupload === false && (this.props.hasUploaded || this.props.attachment.has_file)){
      return(
        <div className="media-uploader">
          <div className="media-preview">
            <img src={this.props.attachment.url || this.props.attachment.lightbox_url} className="preview-image"/>
            <a className={'button small alert replace-button'} onClick={this.props.handleReupload}>Replace</a>
          </div>
        </div>
      )
    } else {
      return( this.renderFileDrop() )
    }
  },

  renderBody: function() {
      return (
        <div className='modal-body figure'>
          { this.renderErrors() }
          { this.renderModalBody() }
          <Details
            isValidNextButton={this.props.isValidNextButton}
            attachment={this.props.attachment}
            handleCharacterCount={this.props.handleCharacterCount}
            setValidState={this.props.setValidState}
            updateKey={this.props.updateKey}
          />
        </div>
      )
  },

  renderNextButton: function() {
    var emptyTitle = ($("#attachment_title").val() === "") || ($("#attachment_title").val() === undefined)
    if (this.props.disableButton && emptyTitle) {
      return (
        <span className="tip-top" data-tooltip={this.props.disableButton} id="next-button" title="Please enter a title to proceed. (Scroll down).">
          <a className={'button primary ' + this.disableButtonClass()} data-tooltip={this.props.disableButton} onClick= {this.props.handleNext}>Next</a>
        </span>
      )
    } else {
      return (
        <a className={'button primary ' + this.disableButtonClass()} onClick={this.props.handleNext}>Next</a>
      )
    }
  },

  render: function(){
    return (
      <div className='modal-content' id='add-figure'>
        <div className='modal-header'>
          <h4 className="reg">Insert Figure</h4>
          <p className="title"> Please review our <a target="_blank" href={this.props.mediaGuidePath + '#figure-req'}>Figure Guidelines</a> for detailed instruction and examples.</p>
          <div className='modal-close'>
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
        </div>
          {this.renderBody()}
          <div className='modal-footer'>
            <a className='cancel-modal button secondary'>Cancel</a>
            {this.renderNextButton()}
        </div>
      </div>
    )
  }
})

export default Edit;