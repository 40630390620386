import React, { useState, useEffect } from 'react';

const Card = ({article, currentUserId, amplitudeEvent}) => {
  const [authors, setAuthors] = useState('')
  const [authorsOverflow, setauthorsOverflow] = useState(null)

  useEffect(() => {
    let authorList = article.author_list.split(',')
    setAuthors(authorList.slice(0, 1).join(', '))
    let overflow = authorList.slice(1).length
    if(overflow > 1) {
      setauthorsOverflow(overflow)
    }
  }, [])

  const fireAmplitude = () => {
    if (!amplitudeEvent) {return}
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: {
              event_type: amplitudeEvent,
              user_id: currentUserId,
              article_id: article.id
            }
    })
  }

  const renderAuthorList = () => {
    if(authorsOverflow) {
      return (
        <div className="authors">
          {authors}
          <a href={article.path + '?authors-tab=true'}><span>+{authorsOverflow} more</span></a>
        </div>
      )
    } else {
      return (
        <div className="authors">
          {authors}
        </div>
      )
    }
  }

  const renderViewCounts = () => {
    if (!article.views_count) {return}
    return (
      <div className="views">
        <i class="fas fa-eye"/> {article.views_count}
      </div>
    )
  }

  return (
    <div className="most-popular-card">
      <div className="row collapse">
        <div className="small-12 columns">
          <div className="header">
            <span className="left">{article.published_date}</span>
            <span className="right">{article.humanized_type}</span>
          </div>
        </div>
      </div>
      <div className="row collapse">
        <div className="small-12 columns">
          <div className="title">
            <h3><a href={article.path} onClick={fireAmplitude}>{article.title}</a></h3>
          </div>
        </div>
      </div>
      <div className="row collapse flex-container">
        <div className="small-9 columns">
          {renderAuthorList()}
        </div>
        <div className="small-3 columns">
        {renderViewCounts()}
        </div>
      </div>
    </div>
  )

}

export default Card;