import React, { useState } from 'react';
import { Cureus } from '../../Cureus';

const MessageBoard = ({initialText, updated, user_id}) => {
  const [text, setText] = useState(initialText)
  const [update, setUpdate] = useState(updated)

  const updateNote = () => {
    $.ajax({
      type: 'PUT',
      url: `/admin/users/${user_id}/update_note`,
      dataType: 'json',
      data: {text: text},
      success: function(res) {
        setUpdate(res.updated)
        Cureus.Flash.success('Message board updated.')
      },
      error: function(res) {
        Cureus.Flash.notice('Message board failed to update. Please try again.')
      },
      always: $(window).scrollTop(0)
    });
  }

  return (
    <div className="user-message-board">
      <h4>Notes</h4>
      <h6>Please add any relevant notes about this user here. Notes are not emailed to any Cureus admins or editors.</h6>
      <textarea placeholder="No notes added." value={text} onChange={((e) => setText(e.target.value))}/>
      <a className="button" onClick={updateNote}>Save</a>
      {update && <span className="update-date">Updated {update}</span>}
    </div>
  )
}

export default MessageBoard;