import {Cureus} from '../../Cureus';

Cureus.Utils.AssociationAutocomplete = {
  setup: function($fieldElem, options) {
    var lastXhr;
    var options = (options == undefined) ? {} : options;
    var autocompleteUrl = options["autocompleteUrl"] || $fieldElem.data('autocomplete-url');
    $fieldElem.autocomplete({
      source: function(request, response) {
        if (options["institution"]) {
          request.institution = options["institution"]
        }
        lastXhr = $.getJSON(autocompleteUrl, request, function(data, status, xhr) {
          if ( xhr === lastXhr ) {
            var affilationNameSuggestions = [];
            var suggestionTaken = false
            _.each(data, function(el) {
              affilationNameSuggestions.push({
                label: el.name,
                value: el.name
              });
              if ($fieldElem.val() == el.name) {
                suggestionTaken = true
              }
            });
            if (suggestionTaken) {
              affilationNameSuggestions = []
            }
            response(affilationNameSuggestions);
          }
        });
      },
      focus: function(event) {
        event.preventDefault();
      },
      select: function(event, ui) {
        var input = $fieldElem[0]
        var setValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
        setValue.call(input, ui.item.value);
        var e = document.createEvent('Event', {bubbles: true});
        e.initEvent('input', true, true);
        input.dispatchEvent(e);

        event.preventDefault();
      }
    });
  }
};

// Make it a jQuery plugin too
(function($) {
  $.fn.associationAutocomplete = function() {
    return this.each(function(i) {
      Cureus.Utils.AssociationAutocomplete.setup($(this));
    });
  };
})(jQuery);