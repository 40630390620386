var createReactClass = require('create-react-class');
import React from 'react';
import EditFile from './EditFile';
import NewFile from './NewFile';

const Main = createReactClass({
  getInitialState: function() {
    const {collection, update_banner_image_path} = this.props;

    return {
      collection: collection,
      update_banner_image_path: update_banner_image_path
    }
  },

  setCollection: function(collection, disabledState) {
    this.setState({collection: collection})
    $("[data-button-type='next']").attr("disabled", disabledState)
  },

  render: function() {
    if ( this.state.collection && (this.state.collection.preview_url) ) {
      return (
        <div>
          <EditFile
            collection={ this.state.collection }
            onUpdate={ this.setCollection }
            updateObjectFilePath={this.state.update_banner_image_path }
          />
        </div>
      )
    } else { 
      return (
        <div>
          <NewFile
            collection={ this.state.collection }
            onUpdate={ this.setCollection }
            updateObjectFilePath={ this.state.update_banner_image_path }
          />
        </div>
      )
    }
  }
});

export default Main;