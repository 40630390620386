var createReactClass = require('create-react-class');
import React from 'react';
import KeywordAutoSuggester from './KeywordAutoSuggester';

const DashboardKeywordTool = createReactClass({
  getInitialState: function() {
    return {
      keyword: "",
      keywords: [],
      expanded: this.props.reviewsPage ? true : false
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  keywordsRemaining: function() {
    return this.props.keywordLimit - this.state.keywords.length;
  },

  sortKeywords: function(kws) {
    return kws.sort((a, b) => a.name.localeCompare(b.name))
  },

  componentWillMount: function() {
    this.setState({
      keywords: this.sortKeywords(this.props.keywords)
    });
  },

  handleChange: function(e) {
    this.setState({
      keyword: e.target.value
    });
  },
  
  handleKeyPress: function(e) {
    document.querySelector('.keyword-add-button').setAttribute('disabled', true)
  },

  handleAdd: function() {
    var self = this;

    if(self.state.keywords.length < self.props.keywordLimit) {
      $.ajax({
        type: 'POST',
        url: self.props.basePath,
        data: _.extend(self.props.params, {
          keyword: self.state.keyword
        }),
        success: function(data) {
          document.querySelector('.keyword-add-button').setAttribute('disabled', true)
          if(self.props.reviewsPage && !self.props.editProfilePage) {
            document.getElementById('close-button').style.display = 'block'
          }
          self.setState({
            keywords: self.sortKeywords(self.state.keywords.concat(data)),
            keyword: "",
            expanded: true
          });
          self.checkForKeywordPresence();
        }
      });
    };
  },

  handleRemove: function(keyword) {
    var self = this;

    $.ajax({
      type: 'DELETE',
      url: self.props.basePath + "/" + keyword.id,
      data: self.props.params,
      success: function() {
        self.removeKeyword(keyword);
        self.checkForKeywordPresence();
      }
    });
  },

  removeKeyword: function(keyword) {
    var self = this;
    var keywords = _.reject(self.state.keywords, function(kw) {
      return keyword.id == kw.id;
    });

    self.setState({
      keywords: self.sortKeywords(keywords)
    });
  },

  checkForKeywordPresence: function() {
    this.state.keywords.length < this.props.keywordsRequired ? this.disableButton() : this.enableButton()
  },

  enableButton: function() {
    [].forEach.call(this.$buttons(), function(el) {
      el.removeAttribute("disabled")
    })
  },

  disableButton: function() {
    [].forEach.call(this.$buttons(), function(el) {
      el.setAttribute("disabled", true)
    })
  },

  $buttons: function() {
    return $(this.props.enableDisableTarget)
  },

  renderKeywords: function() {
    var self = this;
    if(self.state.keywords.length > 0) {
      return (
          <div className='reviews-keyword-list clearfix' style={this.props.reviewsPage ? {marginTop: '15px'} : {}}>
            <ul>
              {
                self.state.keywords.map(function(keyword) {
                  return (
                    <li key={ keyword.name }>
                      { keyword.name }
                      <a onClick={ self.handleRemove.bind(self, keyword) }>
                        <i className='fa fa-times'>
                        </i>
                      </a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
      )
    }
  },

  renderTogglerMessage: function() {
    if(this.props.reviewsPage) {
      return;
    }
    var text = this.state.expanded ? "Less" : "Add more"
    var caratClass = this.state.expanded ? "fa fa-angle-up" : "fa fa-angle-down"
    if(this.state.keywords.length > 0) {
      return (
        <div className="expander-toggler">
          <a onClick={ this.toggleTextExpand }> { text } <i className={ caratClass }></i>
          </a>
        </div>
      ) 
    }
  },

  toggleTextExpand: function() {
    this.setState({ expanded: !this.state.expanded })
  },

  renderKeywordForm: function() {
    if(this.state.expanded || this.state.keywords.length === 0)   {
      return (
        <div className='row' style={this.props.reviewsPage ? {marginTop: '15px'} : {}}>
          <div className='small-9 columns' style={this.props.editProfilePage ? {padding: '0px', marginBottom: '8px'} : {}}>
          <KeywordAutoSuggester autosuggestUrl={this.props.autoCompletePath}>
              <input type='text'  autoComplete="off" id="ui-autocomplete-input" ref='input' disabled={this.keywordsRemaining() === 0} onChange={ this.handleChange } value={ this.state.keyword } onKeyPress={this.handleKeyPress} placeholder={this.keywordsRemaining() === 0 ? "Keyword limit reached" : "Keyword"}/>
          </KeywordAutoSuggester>
          </div>
          <div className='small-3 columns'>
            <a className='button secondary keyword-add-button' disabled={this.keywordsRemaining() === 0} onClick={ this.handleAdd }>Add</a>
          </div>
        </div>
      )
    }
  },

  renderTitle: function() {
    if(!this.props.reviewsPage) {
      return (
        <h6>Keywords</h6>
      )
    }
  },

  renderBlurb: function() {
    if(!this.props.reviewsPage) {
      return (
        <div className="small-12 columns">
          <span className="keyword-desc">Add up to {this.props.keywordLimit} keywords to help us match you with interesting, relevant articles to review.</span>
        </div>
      )
    }
  },

  renderLearnMore: function() {
    if(this.props.reviewsPage) {
      return (
        <div className="small-12 columns learn-more-link-container">
          <a href={this.props.reviewerGuidePath} target="_blank">Learn more</a>
        </div>
      )
    } else {
      return (
        <div className="small-4 columns small-offset-7 medium-4 medium-offset-7 large-4 large-offset-8 learn-more-container reviews text-right">
          <a href={this.props.reviewerGuidePath} target="_blank">Learn more</a>
        </div>
      )
    }
  },

  render: function() {
    return (
      <React.Fragment>
        <div className='small-12 columns keyword-container' data-show-more-keywords>
          {this.renderTitle()}
          {this.renderKeywords()}
          {this.renderTogglerMessage()}
          {this.renderKeywordForm()}
        </div>
        {this.renderBlurb()}
        {this.renderLearnMore()}
      </React.Fragment>
    )
  }
});

export default DashboardKeywordTool;
