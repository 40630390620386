var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';
import LearnMoreModal from './LearnMoreModal';
import Modal from '../../../Modal';


const ReviewerActivityMain = createReactClass({
  renderNoActivityText: function() {
    if (this.props.article.competition_id === null && this.props.article.articleState != 'in_rereview') {
      return (
        <tr className='no-activity'>
          <td colSpan='5'>
            There is no activity to show.
            <br/>
            <a onClick={this.props.handleInvite}>Invite more reviewers</a>&nbsp;or&nbsp;<a id="invited" onClick={this.props.openRemindModal}>remind invited.</a>
          </td>
        </tr>
      )
    } else {
      return (
        <tr className='no-activity'>
          <td colSpan='5'>
            There is no activity to show.
          </td>
        </tr>
      )
    }
  },

  renderReviewerRows: function() {
    var self = this;
    return ( self.props.article.reviewerships.data.map( function(reviewership) {
      return (
        <Row
        key={"reviewerships-" + reviewership.id}
        reviewership={reviewership.attributes}
        articlePath={self.props.article.articlePath}
        reviewerships={self.props.article.reviewerships}
        reviewerTimeLeft={self.props.article.reviewerTimeLeft}
        />
      )
    }))
  },

  renderReviewerData: function() {
    if (this.props.article.reviewerships.data.length === 0) {
      return ( this.renderNoActivityText() )
    } else {
      return ( this.renderReviewerRows() )
    }
  },

  render: function() {
    if (this.props.article.reviewsState) {
      return (
        <div>
          <div className="dashboard-panel-box reviewer-activity">
            <h6>REVIEWS</h6>
            <table className="dashboard-table rev-activity">
              <thead>
                <tr>
                  <th className="large-2 small-1 medium-1 text-left">Status</th>
                  <th className="large-3 small-4 medium-4 text-left">Reviewer</th>
                  <th className="large-2 small-2 medium-2">Highlight</th>
                  <th className="large-3 small-3 medium-3">Comments</th>
                  <th className="large-2 small-2 medium-2"></th>
                </tr>
              </thead>
              <tbody>
                {this.renderReviewerData()}
              </tbody>
            </table>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
})

export default ReviewerActivityMain;