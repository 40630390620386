import React, { useState } from 'react';
import EditorColumn from './EditorColumn';
import CopyEmails from './CopyEmails';

const ChannelCard = ({logoBaseUrl, channel, channelAdminEditors}) => {
  const [showAllEditors, setShowAllEditors] = useState(false)

  const renderLiveIcon = () => {
    if (channel.state == "published") {
      return <img className="live-icon" src="https://public.cureus.com/admin/channels/live-icon.png"/>
    }
  }

  const renderDigestIcon = () => {
    if (channel.has_digest == true) {
      return <p className="digest-paragraph"><img src='https://public.cureus.com/admin/channels/digest.png'/>Email Digest</p>
    }
  }

  const renderExpandButton = () => {
    if (isMoreThan3Editors()) {
      return <div className='expand-button'><i className="fas fa-chevron-down" onClick={() => {setShowAllEditors(!showAllEditors)}}/></div>
    }
  }

  const isMoreThan3Editors = () => {
    if (!channelAdminEditors) {
      return false
    } else if (checkMoreThan3Editors()) {
      return true
    } else {
      return false
    }
  }

  const checkMoreThan3Editors = () => {
    return filterByEditorType("ChannelAdmin").length > 3 || filterByEditorType("DeputyEditor").length > 3
  }

  const filterByEditorType = (type) => {
    if (channelAdminEditors) {
      return channelAdminEditors.filter( (admin) => {
        return admin.user_type == type
      })
    } else {
      return []
    }
  }

  return (
    <div className="card row">
      <div className="details section columns small-3">
        {renderLiveIcon()}
        <div className="row">
          <div className="columns small-12">
            <div className="row top-row">
              <div className="columns small-9">
                <h1><a target="_blank" href={`/admin/channels/${channel.id}`}>{channel.title}</a></h1>
              </div>
              <div className="columns small-3">
                <span data-tooltip="1" class="has-tip" title="Channel launch date">{channel.published_at}</span>
              </div>
            </div>
            <div className="row">
              <div className="columns small-5">
                <img src={`${logoBaseUrl}/${channel.id}/admin_sidebar_${channel.logo}`}/>
              </div>
              <div className="columns small-7">
                {channel.contract_date && <p>Contract date: {channel.contract_date}</p>}
                {channel.yearly_contracted_articles && <p>Annual article limit: {channel.yearly_contracted_articles}</p>}
                <p>{channel.total_submitted_editor_check} approved for editor check</p>
                <p>{channel.published_count} published</p>
                {renderDigestIcon()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section columns small-3">
        <EditorColumn showAllEditors={showAllEditors} type="ChannelAdmin" channelAdminEditors={channelAdminEditors} />
        {renderExpandButton()}
      </div>

      <div className="section columns small-3">
        <EditorColumn showAllEditors={showAllEditors} type="DeputyEditor" channelAdminEditors={channelAdminEditors} />
      </div>

      <div className="section columns small-3">
        <CopyEmails channelAdminEditors={channelAdminEditors}/>
      </div>
    </div>
  )
}

export default ChannelCard;