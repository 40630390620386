import React from 'react';

const AverageScores = ({data, type, editorView}) => {
  return (
    <div className="row-container">
      <div className="row">
        <div className="small-12 columns">
          <div className="small-1 columns icon-container">
            <span>
              <i className="fas fa-calculator background"/>
            </span>
          </div>
          <div className="small-11 columns header">
            <h5>Average editor check scores</h5>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns">
          <div className={(editorView ? 'small-8' : 'small-6') + " small-offset-1 columns line-item"}>
            <h6 className="reg">Author affiliations and names complete and formatted correctly?</h6>
          </div>
          <div className={(editorView ? "small-2" : "small-4") + " columns end"}>
            <h6 className="reg">{data.affiliations}</h6>
          </div>
          <div className={(editorView ? 'small-8' : 'small-6') + " small-offset-1 columns line-item"}>
            <h6 className="reg">Acronyms/initialisms spelled out prior to first use?</h6>
          </div>
          <div className={(editorView ? "small-2" : "small-4") + " columns end"}>
            <h6 className="reg">{data.acronyms}</h6>
          </div>
          <div className={(editorView ? 'small-8' : 'small-6') + " small-offset-1 columns line-item"}>
            <h6 className="reg">Spelling, grammar, syntax and punctuation errors?</h6>
          </div>
          <div className={(editorView ? "small-2" : "small-4") + " columns end"}>
            <h6 className="reg">{data.spelling}</h6>
          </div>
          <div className={(editorView ? 'small-8' : 'small-6') + " small-offset-1 columns line-item"}>
            <h6 className="reg">Text formatted correctly? (subheadings, spaces, etc.)</h6>
          </div>
          <div className={(editorView ? "small-2" : "small-4") + " columns end"}>
            <h6 className="reg">{data.text}</h6>
          </div>
          <div className={(editorView ? 'small-8' : 'small-6') + " small-offset-1 columns line-item"}>
            <h6 className="reg">Media quality, formatting, labels and placement</h6>
          </div>
          <div className={(editorView ? "small-2" : "small-4") + " columns end"}>
            <h6 className="reg">{data.media}</h6>
          </div>
          <div className={(editorView ? 'small-8' : 'small-6') + " small-offset-1 columns line-item"}>
            <h6 className="reg">Reference formatting and accuracy</h6>
          </div>
          <div className={(editorView ? "small-2" : "small-4") + " columns end"}>
            <h6 className="reg">{data.references}</h6>
          </div>
          <div className={(editorView ? 'small-8' : 'small-6') + " small-offset-1 columns line-item"}>
            <h6 className="reg">Miscellaneous</h6>
          </div>
          <div className={(editorView ? "small-2" : "small-4") + " columns end"}>
            <h6 className="reg">{data.misc}</h6>
          </div>
          <div className={(editorView ? 'small-8' : 'small-6') + " small-offset-1 columns line-item"}>
            <h6 className="reg">Total</h6>
          </div>
          <div className={(editorView ? "small-2" : "small-4") + " columns end"}>
            <h6 className="reg">{data.total}</h6>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AverageScores;