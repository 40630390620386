import {Cureus} from '../Cureus';

function loadData(path, queue, tableName, domId, data={}) {
  data.queue = queue;
  $.ajax({
    type: 'GET',
    url: path,
    data: data,
    contentType: "text/plain",
    dataType: 'json',
    success: function (data) {
      var fn = "populate" + tableName
      window[fn](data.data)
      if(data.data.length === 0 && tableName !== 'SubmissionsClaim') {
        document.getElementById(domId).getElementsByTagName('td')[0].innerHTML = 'No data available in table'
      }
    },
  });
}

function claim(id, contentType, takeOver, callback) {
  $('.claim').each(function(_,a){a.addClass("disabled")})
  $.ajax({
    type: 'PUT',
    url: "/admin/articles/" + id + "/claim",
    data: {content_type: contentType, take_over: takeOver},
    dataType: 'json',
    success: function(data) {
      if (data.success) {
        if (callback) {
          callback()
        } 
        window.open(data.url, "_blank"); 
      }
      else {
        Cureus.Flash.alert(data.message)
        if (callback) {
          callback()
        }
        $(window).scrollTop(0);
      }
    },
  })
}

function unclaim(id, contentType, callback) {
  $('.claim').each(function(_,a){a.addClass("disabled")})
  $.ajax({
    type: 'PUT',
    url: '/admin/articles/' + id + '/unclaim',
    data: {content_type: contentType},
    dataType: 'json',
    success: function(data) {
      if (data.success) {
        if (callback) {
          callback()
        }
      }
      else {
        Cureus.Flash.alert(data.message)
        if (callback) {
          callback()
        }
        $(window).scrollTop(0);
      }
    },
  })
}

export { loadData, claim, unclaim }