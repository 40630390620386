window.ParsleyConfig = {
  errorClass: 'error',
  validators: {
    toggler: {
      fn: function(value, requirement) {
        var toggler = document.querySelector(requirement);
        if(toggler.checked) {
          return value.length !== 0;
        } else {
          return true;
        }
      },
      priority: 32
    }
  }
}
