var createReactClass = require('create-react-class');
import React from 'react';
import Modal from '../../Modal';
import RatingModal from '../ReviewsDashboard/RatingModal';

const Approved = createReactClass({
  getInitialState: function() {
    return {

    }
  },
  
  openModal: function() {
    Modal.open({
      children: <RatingModal
        rateables={ this.props.article.ratedReviewers ? this.props.article.ownerships : this.props.article.reviewerships.data }
        articleId={ this.props.article.id }
        ratingsPath={ this.props.article.ratingsPath }
        ratedReviewers= { this.props.article.ratedReviewers }
      />
    });
  },
  
  render: function() {
    return(
    <div class="cureus-split-dropdown-wrap">
      <a class="button success split" data-cureus-split-dropdown={"action-buttons-" + this.props.article.id} onClick={this.openModal}>Publish
      <span></span>
      </a>
      <ul class="cureus-split-dropdown" id={"action-buttons-" + this.props.article.id}>
        <li>
          <a target="_blank" href={this.props.article.previewPath}>Preview</a>
        </li>
        <li>
          <a target="_blank" data-tooltip="" title="View the article in our reviewing tool. Opens in a new tab." id={"action-dropdown-edit-tooltip-" + this.props.article.id} href={this.props.article.articlePath}>View comments</a>
        </li>
        {this.props.renderEmailEditingReceipt()}
      </ul>
    </div>
    )
  }
})

export default Approved;


  
