import React from 'react';
import DateSelector from '../StatsWidget/DateSelector';

const Summary = ( {summary, deltas, compareStartDate, compareEndDate, setCompareStartDate, setCompareEndDate } ) => {

  function handleDateRangeSelect(fromDate, toDate, selectedRange) {
    setCompareStartDate(fromDate);
    setCompareEndDate(toDate);
  }

  function renderDelta(key) {
    if (deltas[key]) {
      return (<span className={"delta " + deltas[key].sign}>{deltas[key].value}</span>)
    }
  }

  return (
    <div className='section'>
      <div className='row'>
        <h6>Summary</h6>
        <div className='date-selector-wrap'>
          <DateSelector
            from={compareStartDate}
            to={compareEndDate}
            dateRanges={[]}
            getStats={handleDateRangeSelect}
            noFloat={true}
            page={'email'}
          />
        </div>
      </div>
      <table className='summary'>
        <tbody>
        <tr>
            <td>
              <span>Campaigns sent</span>
              <br/>
              <span className='stat'>{summary.campaignsSent}</span>
            </td>
            <td>
              <span>Emails sent</span>
              <br/>
              <span className='stat'>{summary.emailsSent}</span>
              <br/>
              <span>{summary.deliveredRate}% delivered</span>
              {renderDelta("deliveredRate")}
            </td>
            <td>
              <span>Total subscribers processed</span>
              <br/>
              <span className='stat'>{summary.totalSubscribers}</span>
              {renderDelta("totalSubscribers")}
            </td>
            <td>
              <span>Best performing day</span>
              <br/>
              <span className='stat'>{summary.bestPerformingDay}</span>
              <br/>
              <span>{summary.bestOpenRate}% open rate</span>
            </td>
            <td>
              <span>Unsubscribe rate</span>
              <br/>
              <span className='stat'>{summary.unsubscribeRate}%</span>
              {renderDelta("unsubscribed")}
              <br/>
              <span>{summary.unsubscribed} unsubscribed</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Total open rate</span>
              <br/>
              <span className='stat'>{summary.totalOpenRate}%</span>
              {renderDelta("totalOpenRate")}
              <br/>
              <span>{summary.totalOpens} total</span>
            </td>
            <td>
              <span>Unique open rate</span>
              <br/>
              <span className='stat'>{summary.uniqueOpenRate}%</span>
              {renderDelta("uniqueOpenRate")}
              <br/>
              <span>{summary.uniqueOpens} opened</span>
            </td>
            <td>
              <span>Click rate</span>
              <br/>
              <span className='stat'>{summary.clickRate}%</span>
              {renderDelta("clickRate")}
              <br/>
              <span>{summary.clicks} clicked</span>
            </td>
            <td>
              <span>New drafts started</span>
              <br/>
              <span className='stat'>{summary.newDrafts}</span>
              {renderDelta("newDrafts")}
            </td>
            <td>
              <span>New sign ups</span>
              <br/>
              <span className='stat'>{summary.newUsers}</span>
              {renderDelta("newUsers")}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Summary;