import React from 'react';
import Pages from '../Shared/Pagination/Pages';

const DetailedPerformance = ({ data, paginationData, setPage, handleSort }) => {

  function renderRow(row) {
    var attributes = row.attributes;
    return (
      <tr>
        <td>
          <a href={attributes.href} target='_blank' rel='noreferrer'>{attributes.title}</a>
          <br/>
          <span>{attributes.sentAt}</span>
        </td>
        <td>
          {attributes.sentDelivered}
        </td>
        <td>
          {attributes.openRates}
          <br/>
          <span>{attributes.opens}</span>
        </td>
        <td>
          {attributes.clickRate}
          <br/>
          <span>{attributes.clicks}</span>
        </td>
        <td>
          {attributes.newDrafts}
        </td>
        <td>
          {attributes.newUsers}
        </td>
        <td>
          {attributes.unsubscribeRate}
          <br/>
          <span>{attributes.unsubscribes}</span>
        </td>
      </tr>
    )
  }

  return (
    <div className='section'>
      <div className='row'>
        <h6>Detailed Performance</h6>
      </div>
      <table className="detailed-performance" id="detailed-performance-table">
        <thead>
          <tr>
            <th onClick={() => handleSort("c.sent_at")}>
              Campaign
            </th>
            <th onClick={() => handleSort("cs.processed")}>
              Emails sent/delivered
            </th>
            <th onClick={() => handleSort("open_rate")}>
              Open rate
            </th>
            <th onClick={() => handleSort("click_rate")}>
              Click rate
            </th>
            <th onClick={() => handleSort("cs.new_drafts")}>
              New drafts started
            </th>
            <th onClick={() => handleSort("cs.new_users")}>
              New sign ups
            </th>
            <th onClick={() => handleSort("cs.cureus_unsubscribes")}>
              Unsubscribed
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map(function(row) {
            return renderRow(row)
          })}
        </tbody>
      </table>
      { paginationData.totalItems > 0 && <Pages
        paginationData={paginationData}
        handlePageSelect={setPage}
      /> }
    </div>
  )
}

export default DetailedPerformance;