var createReactClass = require('create-react-class');
import React from 'react';

const DisplayReference = createReactClass({
  handleCheck: function() {
    this.props.handleCheck();
  },

  handleEdit: function(e) {
    this.props.handleEdit();
    e.preventDefault();
  },

  handleDelete: function(e) {
    this.props.handleDelete();
    e.preventDefault();
  },

  render: function() {
    return (
      <tr>
        <td className='drag-handle'>
          <table className="dots">
            <tbody>
              <tr>
                <td className="dot"></td>
                <td></td>
                <td className="dot"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="dot"></td>
                <td></td>
                <td className="dot"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="dot"></td>
                <td></td>
                <td className="dot"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td className="dot"></td>
                <td></td>
                <td className="dot"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td className='table-checkbox'>
          <input type='checkbox' checked={ this.props.checked } onChange={ this.handleCheck }/>
        </td>

        <td style={{textAlign: "center"}}>
          { this.props.reference.position }
        </td>

        <td>
          <h6 className='friendly-name'>
            { this.props.reference.friendly_name }
            {this.props.doiStatus}
          </h6>
          <div className='formatted' dangerouslySetInnerHTML={{__html: this.props.reference.formatted}}>
          </div>
        </td>

        <td className="table-actions" id={'ref-' + this.props.reference.position} style={{textAlign: "right", padding: '9px'}}>
          <a className='button tiny secondary edit' href="#" onClick={ this.handleEdit }>
            <i className='fa fa-pencil-alt'></i>
          </a>
          <a className='button tiny secondary trash' href='#' onClick={ this.handleDelete }>
            <i className='far fa-trash-alt'></i>
          </a>
        </td>
      </tr>
    )
  }
});

export default DisplayReference;