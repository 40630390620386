import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import Article50Card from '../MainWidgets/Cards/Article50Card'
import Abstract50Card from './Cards/Abstract50Card.jsx'
import Poster50Card from '../MainWidgets/Cards/Poster50Card'
import Channel100Card from './Cards/Channel100Card';

import Sortable from '../../../components/Sortable';

const CustomContentWidget = ({slug, handleDeleteWidget, handleUpdateWidget, mainContentWidgets, widget_id, showActionButtons=true}) => {

  const [contents, setContents] = useState([]);

  useEffect(() => {
    let widgetId = widget_id || mainContentWidgets["Widget::CustomContent"]
    if(widgetId) {
      let onSuccess = (res) => {
        setContents(res.contents);
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [mainContentWidgets])

  const components = {
    Article50Card: Article50Card,
    Article100Card: Channel100Card,
    Abstract50Card: Abstract50Card,
    Abstract100Card: Channel100Card,
    Poster50Card: Poster50Card,
    Poster100Card: Channel100Card,
  };

  const renderContent = () => {
    return contents.map(function(content, idx) {
      const Card = components[content.react_component_name]
      if(showActionButtons) {
        return (
          <div className="card drag-handle" key={idx}>
            <Card
              content={content}
              showActionButtons={showActionButtons}
              handleDeleteWidget={handleDeleteWidget}
              handleUpdateWidget={handleUpdateWidget}
              mainContentWidgets={mainContentWidgets}
              slug={slug}
            />
          </div>
        )
      } else {
        return (
          <Card
            content={content}
            showActionButtons={showActionButtons}
            handleDeleteWidget={handleDeleteWidget}
            handleUpdateWidget={handleUpdateWidget}
            mainContentWidgets={mainContentWidgets}
          />
        )
      }
    })
  }

  const handleSort = (e, sortable, movedComponent) => {
    let widgetId = movedComponent.props.children.props.content.widgetId
    let params = {widget: {position: e.newIndex + 1}}
    ChannelApi.updateWidget(widgetId, params, slug)
  }

  return (
    <div className="row small-collapse">
      <div className="small-12 columns">
        <div className="widget custom-content">
          <Sortable tagName='div' handleSort={ handleSort }>
          {renderContent()}
        </Sortable>
        </div>
      </div>
    </div>
  )
}

export default CustomContentWidget;