import React from 'react';
import {Cureus} from '../Cureus';
import Modal from '../components/Modal';
import PublishingConfirmationModal from '../components/Misc/PublishingConfirmationModal';

Cureus.PublishingConfirmationModal = function(options) {
  this.modalTrigger = document.querySelectorAll(options.modalTriggerSelector);
  this.bindEvents(options.type);
}

Cureus.PublishingConfirmationModal.prototype.bindEvents = function(type) {
  var self = this;
  [].forEach.call(self.modalTrigger, function(el) {
    el.addEventListener('click', function(e) {
      if (!this.dataset.clickthroughAllowed) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.target.blur()
        Modal.open({
          children: React.createElement(PublishingConfirmationModal, {target: this, type: type})
        })
      }
    });
  })
}

