var createReactClass = require('create-react-class');
import React from 'react';
import HistoryModal from './HistoryModal';
import ThoughtsModal from './ThoughtsModal';
import RejectReviewModal from './RejectReviewModal';
import RejectedModal from './RejectedModal';
import Modal from '../Modal';

const Row = createReactClass({
  getInitialState: function() {
    return {
      reviewerships: null,
      currentUser: null,
      checked: [],
      disabled: 'disabled',
      rejectReason: ''
    }
  },

  rejectReview: function() {
    var self = this;
    $.ajax({
      type: "PUT",
      dataType: "JSON",
      url: self.props.reviewer.rejectReviewerPath,
      data: {article_id: self.props.articleId, rejectReason: self.state.rejectReason, articleState: self.props.articleState},
      success: function(data) {
        Modal.close();
        if(data.redirect) {
          window.location.reload()
        } else {
          self.props.fetchReviewerships()
        }
      }
    });
  },

  renderCheckbox: function() {
    if (this.props.checkedAll && this.props.status === 'Completed') {
      return <></>
    } else if (!this.props.reviewer.banned) {
      return <input className="td reviewer-checks" onClick={(e) => this.props.updateCheckedState(e, this.props.reviewer.id)} type="checkbox"/>
    }
  },

  renderHistoryModal: function() {
    Modal.open({
      children: <HistoryModal
          articleId={this.props.articleId}
          reviewershipId={this.props.reviewer.id}
        />
    });
  },

  renderThoughtsModal: function() {
    if (this.props.reviewer.completedReviewerEvents === null) {
      return;
    } else {
      Modal.open({
        children: <ThoughtsModal
          thoughts={this.props.reviewer.thoughts}
          reviewerName={this.props.reviewer.reviewerName}
          completedAt={this.props.reviewer.completedAt}
          events={this.props.reviewer.completedReviewerEvents}
          />
      });
    }
  },

  substringThoughts: function() {
    let len = this.props.reviewer.completedReviewerEvents.length - 1
    if (this.props.reviewer.completedReviewerEvents.length > 0 && this.props.reviewer.completedReviewerEvents[len].message !== null) {
      return this.props.reviewer.completedReviewerEvents[len].message.substring(0, 55) + '...'
    } else {
      ""
    }
  },

  handleChange: function(e) {
    this.setState({rejectReason: e.target.value})
  },

  openRejectModal: function() {
    Modal.open({
      children: <RejectReviewModal
          reviewerId={this.props.reviewer.id}
          rejectReviewerPath={this.props.reviewer.rejectReviewerPath}
          rejectReview={this.rejectReview}
          handleChange={this.handleChange}
          failsPeerReview={this.props.reviewer.failsPeerReview}
          articleState={this.props.articleState}
        />
    });
  },

  openRejectedModal: function() {
    Modal.open({
      children: <RejectedModal rejectedReviewEvent={this.props.reviewer.rejectedReviewEvent}/>
    });
  },

  displayRecommendationMessageWithRejectButton: function() {
    const recommendationMessage = this.props.completedReviewershipAttributes?.recommendationMessage || "";
    const textColor = this.props.completedReviewershipAttributes?.textColor || "";

    return (
      <td className="text-center">
        {recommendationMessage &&
          <p className="text-left" style={{ color: `${textColor}`}}>
            <strong>
              {recommendationMessage}
            </strong>
          </p>
        }
        <a className="button mini alert" onClick={this.openRejectModal}>Reject</a>
      </td>
    )
  },

  renderReject: function() {
    if(this.props.canReject) {
      if(this.props.reviewer.status === 'Completed') {
        return this.displayRecommendationMessageWithRejectButton();
      } else if(this.props.reviewer.status === 'Rejected') {
        return <td className="text-center"><a className="rejected" onClick={this.openRejectedModal}>Rejected</a></td>
      }
      else {
        return <td/>
      }
    }
  },

  renderMedStudent: function() {
    if(this.props.reviewer.medStudent) {
      return (
        <i class="fas fa-user-graduate"/>
      )
    }
  },

  renderApprovalEditor: function() {
    if(this.props.reviewer.isApprovalEditor) {
      return (
        <i class="fas fa-id-badge"/>
      )
    }
  },

  handleBackgroundColor: function() {
    if (this.props.completedReviewershipAttributes) {
      return this.props.completedReviewershipAttributes.backgroundColor;
    }
  },

  render: function () {
    if (!this.props.reviewer) {
      return null;
    }
    let reviewer = this.props.reviewer
    let status = reviewer.status
    if (status == 'Completed' && reviewer.completedReviewerEvents && reviewer.completedReviewerEvents.length > 1) {
      status = 'Rereview Completed'
    }
    return (
      <tbody className='reviewer-row'>
        <tr className={(reviewer.banned ? 'strikeout' : '')} style={{background: `${this.handleBackgroundColor()}`}}>
          <td className="text-center">{this.renderCheckbox()}</td>
          <td className={reviewer.similar ? "similar" : ''}><a href={reviewer.adminUserPath} target="_blank">{reviewer.name}{this.renderMedStudent()} {this.renderApprovalEditor()}</a></td>
          <td>{reviewer.email}</td>
          <td>{status}</td>
          <td className="text-center"><a onClick={this.renderHistoryModal}><i className="fa fa-history"></i></a></td>
          <td>{reviewer.inviter}</td>
          <td className="text-center" dangerouslySetInnerHTML={{__html: reviewer.greek}}></td>
          {this.props.hasOldStyleMessage && <td onClick={this.renderThoughtsModal}><span className="reviewer-thoughts">{this.substringThoughts()}</span></td>}
          {this.renderReject()}
        </tr>
    </tbody>
    )
  }
});

export default Row;