import {Cureus} from '../Cureus';

Cureus.PreviewSubmit = function(options) {
  this.triggerElem = document.querySelectorAll(options['triggerElem']);
  this.disabledElem = document.querySelectorAll(options['disabledElem']);
  this.cname = "previewArticle-" + options['articleId'];
  this.bindClickEvent();
  this.checkCookie();
}

Cureus.PreviewSubmit.prototype.bindClickEvent = function() {
  var self = this;
  [].forEach.call(this.triggerElem, function(el) {
    el.addEventListener('click', function() {
      self.setCookie(self.cname, true, 365);
      self.enableButton();
    });
  })
}


Cureus.PreviewSubmit.prototype.checkCookie = function() {
  if(this.getCookie(this.cname)){
    this.enableButton();
  }
}

Cureus.PreviewSubmit.prototype.enableButton = function() {
  [].forEach.call(this.disabledElem, function(el) {
    el.removeClass('disabled');
    el.removeAttribute('disabled');
  })
}

Cureus.PreviewSubmit.prototype.setCookie = function(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}

Cureus.PreviewSubmit.prototype.getCookie = function(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
        c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
