import React, { useEffect, useState } from 'react';
import Authors from '../NewArticleIndex/RightSidebar/Authors';
import Reviewers from '../NewArticleIndex/RightSidebar/Reviewers';

const RightSidebar = () => {
  const [authors, setAuthors] = useState([])
  const [reviewers, setReviewers] = useState([])

  useEffect( () => {
    getHighestSiqUsers();
  }, [])

  const getHighestSiqUsers = () => {
    fetch("/people/contributors_of_high_siq_articles")
    .then((res) => res.json())
    .then((json) => {
      setAuthors(json.authors);
      setReviewers(json.reviewers);
    })
  }

  return (
    <div className="small-2 columns show-for-large-up right-sidebar">
      <Authors
        authors={authors}
        viewMoreButton={false}
      />
      <Reviewers
        reviewers={reviewers}
        viewMoreButton={false}
      />
    </div>
  )
}

export default RightSidebar