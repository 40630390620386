export const isNotAllZeroData = (data) => {
  let keys = Object.keys(data).filter( (val) => {
    return (val !== "title" && val !== "id"
  )})

  let values = keys.map( (key) => { return data[key] })

  let isAllZeroValues = values.every((val) => {return val == 0});

  return !isAllZeroValues
}
