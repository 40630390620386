import React, { useEffect, useState, useRef } from 'react';
import useClickOutside from '../useClickOutside';
import { Cureus } from '../../Cureus';

const Main = ({articleId}) => {
  const [expandDropDown, setExpandDropDown] = useState(false);
  const [disabledHash, setDisabledHash] = useState({})
  const [selected, setSelected] = useState(null)
  const [offered, setOffered] = useState(null)

  const discountsDropdown = useRef("menu");

  useClickOutside(discountsDropdown, () => {
    setExpandDropDown(false);
  });

  useEffect(() => { 
    fetchDiscount()
  }, [])

  const fetchDiscount = () => {
    $.ajax({
      type: 'GET',
      url: `/admin/articles/${articleId}/discounts`,
      success: function(data) {
        setDisabledHash(data.disabled)
        setOffered(data.discount)
      }
    });
  }

  const handleApply = () => {
    setExpandDropDown(false)
    $.ajax({
      type: 'POST',
      data: {discount: selected, product: 'preferred'},
      url: `/admin/articles/${articleId}/discounts`,
      success: function(data) {
        Cureus.Flash.success("Discount successfully offered.");
        setOffered(data.discount)
      },
      error: function() {
        Cureus.Flash.success("Something went wrong, please try again.");
      }
    });
  }

  if(offered) {
    return (
      <div class="discounts-container">
        {offered}
      </div>
    )
  } else {
    return (
      <div className="discounts-container" ref={discountsDropdown}>
        <div onClick={() => setExpandDropDown(!expandDropDown)} >
          <div className='fake-dropdown'>
            <a role='button' className='current'>
              Select the discount
            </a>
            <a role='button' className='selector'>
            </a>
          </div>
        </div>
        {
          expandDropDown && (
            <div className='checkbox-section'>
              <div className='checkbox-content'>
                <label>
                  <input type="radio" onChange={() => setSelected('hardship')} checked={selected === 'hardship'} value="hardship" disabled={disabledHash["hardship"]}/>
                  Financial Hardship (40%)
                </label>
                <label>
                  <input type="radio" onChange={() => setSelected('economy')} checked={selected === 'economy'} value="economy" disabled={disabledHash["economy"]}/>
                  Economy Discount (40%)
                </label>
                <label>
                  <input type="radio" onChange={() => setSelected('approval')} checked={selected === 'approval'} value="approval" />
                  AE Credit (100%, $15 fee)
                </label>
                <label>
                  <input type="radio" onChange={() => setSelected('management')} checked={selected === 'management'} value="management" />
                  Mgmt editor (100%)
                </label>

                <a className={`button small ${selected ? '' : 'disabled'}`} onClick={handleApply}>Apply</a> 
              </div>
            </div>
          )
        }
      </div>
    ) 
  }
}

export default Main;