import React from 'react';

const SeoTips = () => {
  return (
    <React.Fragment>
      <h1>SEO Tips to Boost Article Visibility</h1>
      <p>It’s important to do everything you can to boost the search engine visibility of your articles. Here are two easy tips that will help drive your articles to the top of search engine results.</p>
      <h2>Optimizing Article Keywords</h2>
      <p>The majority of traffic to your published article will arrive directly from Google. Increase your article’s discoverability with these easy tips:</p>
      <ul className='indented-for-medium-up'>
        <li className='check'><strong>Create a search engine friendly title. (Include 1-2 keywords related to your topic.)</strong></li>
        <li className='check'><strong>Optimize your abstract. (Place keywords in the first two sentences and repeat several times throughout the remainder of your abstract.)</strong></li>
        <li className='check'><strong>Use keywords throughout your article. (Make sure the keywords are consistent with your topic and field of study.)</strong></li>
      </ul>
      <p>Keywords are terms relevant to your article that people can search for on a journal database. You’ll be required to add 5-10 keywords during the submission process, but you should also include them in your article as described above.</p>
      <h2>Backlinking to Cureus Articles</h2>
      <p>Do you have a profile or bio page on your hospital or university’s website? Adding hyperlinks on this page that link to your Cureus articles will provide a big boost to their “importance” in the eyes of Google and other search engines. The result? Higher ranked results when your article’s topic or keyword are searched.</p>
    </React.Fragment>
  )
}

export default SeoTips;