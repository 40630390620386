

var createReactClass = require('create-react-class');
import React from 'react';

const SubChannelModal = createReactClass({
  getInitialState: function() {
    return({
      parent: this.props.channel,
      subchannels: null,
      selectedChannel: this.props.selectedChannel
    })
  },

  componentDidMount: function() {
    var self = this;
    var subchannels =  self.props.subchannels.filter(function(subchannel) {
      return self.state.parent.id == subchannel.parent_id
    })
    subchannels = self.sortSubchannels(subchannels);
    self.setState({subchannels: subchannels});
  },

  sortSubchannels: function(subchannels) {
    return subchannels.sort(function(a, b) {
      var textA = a.title.toUpperCase();
      var textB = b.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  },

  handleChannelSelection: function(subchannel) {
    var self = this;
    self.setState({selectedChannel: subchannel}, function() {
      self.props.handleChooseChannel(subchannel)
    })
  },

  renderSubChannels: function() {
    if(!this.state.subchannels) {
      return;
    }
    var self = this;
    return self.state.subchannels.map(function(subchannel) {
      return (
        <label className="position-radio">
          <input type="radio" onClick={ () => self.handleChannelSelection(subchannel) } name="channel" defaultChecked={self.props.selectedChannel.id == subchannel.id}/>
          {subchannel.title}
        </label>
      )
    })
  },

  render: function() {
    return(
      <div className='reveal-modal small' data-reveal>
        <div className='modal-body'>
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
             <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          <div className='modal-content'>
          <h4>{this.state.parent.title}</h4>
            <p>
             Please select your academic institution:
            </p>
            {this.renderSubChannels()}
          </div>
          <div className='modal-footer'>
            <a className='button secondary' onClick={this.props.handleCancel}>Cancel</a>
            <a className={"button primary cancel-modal " + ((this.state.selectedChannel.parent || !this.state.selectedChannel.id) ? 'disabled' : '')}>Select</a>
          </div>
        </div>
      </div>
    )
  }
});

export default SubChannelModal;