import React, { useState, useRef, useEffect } from 'react';
import HistoryModal from '../../History/HistoryModal';
import Modal from '../../Modal';
import {Cureus} from '../../../Cureus';

const All = ({renderChevronContainer, articlesPath, initialData, isParent}) => {

  const [expanded, setExpanded] = useState(false);
  const [tableData, setTableData] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    fetchData();
  }, [expanded])

  const setColumns = () => {
    if(isParent) {
      return [
        {width: '160px'},
        null,
        {width: '160px'},
        {width: '160px'},
        null,
        {width: '160px', class: 'centered'}
      ]
    } else {
      return [
        {width: '160px'},
        null,
        {width: '160px'},
        null,
        {width: '160px', class: 'centered'}
      ]
    }
  }

  const mapColumns = (dt, row) => {
    if(isParent) {
      return (
        dt.fnAddData( [
          row.most_recent_article_history_event_date,
          row.sort_column,
          row.channel_name,
          row.submitting_author,
          row.title,
          row.reminder_history
      ], false)
      )
    } else {
      return (
        dt.fnAddData( [
          row.most_recent_article_history_event_date,
          row.sort_column,
          row.submitting_author,
          row.title,
          row.reminder_history
      ], false)
      )
    }
  }

  const setSorting = () => {
    if(isParent) {
      return {orderable: false, targets: [5]}
    } else {
      return {orderable: false, targets: [4]}
    }
  }

  const populateTable = (fetchedTableData) => {
    var dt = $("#all-table").DataTable({
      columns: setColumns(),
      columnDefs: [
        {targets: [0], orderData: [1]},
        {targets: [1], visible: false},
        setSorting()
      ],
      "rowCallback": function(row) {
        let element = row.children[3].children[0]
        element.addEventListener('click',function() {
          openHistoryModal(element.dataset.articleId, element.dataset.authorshipId)
        })
      },
      dom: '<"row"<"large-5 columns"f>>tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
    })
    
    dt.api().clear();
    for (var i = 0; i < fetchedTableData.articles.length; i++) {
      let row = fetchedTableData.articles[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
    $(document).foundation('reflow');
  }

  const fetchData = () => {
    if(tableData) {
      populateTable(tableData)
      return;
    }
    $.ajax({
      type: 'GET',
      url: articlesPath,
      data: {state: 'All'},
      success: function(data) {
        setTableData(data)
        populateTable(data)
      }
    });
  }

  const openHistoryModal = (articleId, authorshipReviewershipId) => {
    Modal.open({
      children: <HistoryModal
          isAuthorship={true}
          articleId={articleId}
          authorshipReviewershipId={authorshipReviewershipId}
        />
    });
  }

  const renderHeaderRow = () => {
    return (
      <div className="row article-state-row">
        <div className="small-1 columns bubble-container">
          <img src={`https://public.cureus.com/channel-icons/grey-bubble.png`} />
          <span className="bubble-text">{initialData.total}</span>
        </div>
        <div className="small-9 columns article-state-label">
          <h4>{initialData.label}</h4>  
          <span>{initialData.blurb}</span>
        </div>
        <div className="small-1 columns chevron-container">
          {renderChevronContainer(expanded, setExpanded)}
        </div>
      </div>
    )
  }

  const renderChannel = () => {
    if(isParent) {
      return <th>Channel</th>
    }
  }

  return (
    <React.Fragment>
      <div className="article-state-row-container">
        {renderHeaderRow()}
        {expanded && 
          <div className="channel-articles-datatable-container">
            <table id="all-table">
              <thead>
                <tr>
                  <th className="has-tip tip-top" data-tooltip="" title={"This includes any actions performed by the submitting author or a channel or Cureus editor/admin."} style={{fontWeight: 'bold'}}>Most recent activity</th>
                  <th></th>
                  {renderChannel()}
                  <th>Submitting author</th>
                  <th>Title</th>
                  <th>Reminder history</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        }        
      </div>

    </React.Fragment>
  )
}

export default All;