var createReactClass = require('create-react-class');
import React from 'react';

const TextAreaCounter = createReactClass({
  getInitialState: function() {
    return({
      charLimit: 0,
      currCharCount: 0,
      charRemaining: 0
    })
  },

  componentDidMount: function() {
    var textArea = document.getElementById(this.props.textareaId);
    var self = this;
    var currCount = textArea.innerHTML.length;
    this.setState({
      textArea: textArea,
      charLimit: this.props.charLimit,
      currCharCount: currCount,
      charRemaining: this.props.charLimit - currCount
    }, function() {
      self.handleNextButtonStatus();
      self.addEventListener();
    });

  },

  classList: function() {
    if (this.state.charRemaining < 0) {
      return 'originalDisplayInfo warningDisplayInfo';
    } else {
      return 'originalDisplayInfo';
    }
  },

  addEventListener: function() {
    var self = this;
    self.state.textArea.addEventListener('keyup', function(e) {
      var  currCharLength = e.target.value.length;
      self.setState({
        currCharCount: currCharLength,
        charRemaining: self.state.charLimit - currCharLength
      }, self.handleNextButtonStatus);
    });
  },

  handleNextButtonStatus: function() {
    var self = this;
    [].forEach.call(document.querySelectorAll('[data-button-type=next]'), function(el) {
      if (self.state.textArea.value.length > 0 && self.state.textArea.value.length <= self.state.charLimit) {
        el.removeAttribute("disabled")
      } else {
        el.setAttribute("disabled", true)
      }
    });
  },


  render: function() {
    return(
      <div className={this.classList()}>
        {this.state.charRemaining} characters
      </div>
    )
  }
});

export default TextAreaCounter;