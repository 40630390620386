var createReactClass = require('create-react-class');
import React from 'react';

const V1 = createReactClass({
  getInitialState: function() {
    return {
      uploading: false
    }
  },

  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  handleUpload: function() {
    var $file = $(this.refs.file);
    $file.click();
  },

  handleFileChange: function() {
    var $form = $(this.refs.form);
    $form.submit();
    this.setState({uploading: true});
  },

  render: function() {
    if(this.props.imagePresent) {
      var content = [
        <img src={ this.props.imageUrl }/>,
        <div className='buttons-wrap'>
          <a className='button primary small' onClick={ this.handleUpload }>
            Upload image
          </a>
          &nbsp;
          <a className='button alert small' href={ this.props.removePath } data-method='delete' data-confirm='Are you sure?'>
            <i className='far fa-trash-alt'></i>
          </a>
        </div>
      ]
    } else {
      var content = (
        <div className='placeholder-wrap' style={{height: this.props.placeholderHeight}}>
          <div className='placeholder-inner'>
            <div className='positioning'>
              <i className='far fa-picture'></i>
              <br/>
              <a className='button primary small' onClick={ this.handleUpload }>
                Upload image
              </a>
            </div>
          </div>
        </div>
      )
    }

    if(this.state.uploading) {
      var uploading = (
        <div className='uploading'>
          <span>Uploading...</span>
        </div>
      )
    }

    return (
      <div className='image-uploader v1'>
        <form ref='form' acceptCharset='UTF-8' method='post' action={ this.props.updatePath } encType="multipart/form-data">
          <input name="utf8" type="hidden" value="✓" />
          <input name="_method" type="hidden" value="put" />
          <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
          <input ref='file' type='file' name={ this.props.name } onChange={ this.handleFileChange }/>
        </form>

        <div className='image-wrap'>
          { content }
          { uploading }
        </div>
      </div>
    )
  }
});

export default V1;