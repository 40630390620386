import React from 'react';

const TableRequirements = () => {
  return (
    <React.Fragment>
      <h1>Table Requirements</h1>
      <ul className='disc'>
        <li>All tables must be cell-based (images will not be accepted).</li>
        <li>All table formatting (bullet points, bolded text, line returns within a cell, colors, etc.) will be removed after the table is inserted into the article in order to ensure that all tables are formatted in accordance with PMC standards.</li>
        <li>References listed in a table must be cited and listed in the references section of the article.</li>
        <li>Empty rows are not permitted.</li>
        <li>The top row is considered the header row and will be bolded.</li>
      </ul>
    </React.Fragment>
  )
}

export default TableRequirements;