import React from 'react';

const Reviewers = ({seeMoreUsersPath, reviewers, viewMoreButton=true}) => {
  const renderAllAuthorInfo = () => {
    let elements = reviewers.map( (reviewer, idx) => {
      return (
        <a key={idx} href={`/users/${reviewer.user_id}`} target="_blank">
          <div className="card">
            <div className="img-container">
              <img src={reviewer.avatar_profile_url}/>
            </div>
            {renderInfo(reviewer)}
          </div>
        </a>
      )
    })

    return (
      <div className="author-infos">
        {elements}
      </div>
    )
  }

  const renderInfo = (reviewer) => {
    let noBio = reviewer.bio ? "" : "no-bio"
    return (
      <div className={`info ${noBio}`}>
        <h2>{reviewer.first_name}&nbsp;{reviewer.last_name}</h2>
        {reviewer.bio && <p className="bio">{reviewer.bio}</p>}
      </div>
    )
  }

  const renderSuggestionButton = () => {
    return (
      viewMoreButton &&
      <a href={seeMoreUsersPath + "&reviewer_pool=true"} target="_blank">
        <div className="button stencil-blue small text-center see-more-suggestions">
          VIEW MORE
        </div>
      </a>
    )
  }

  return (
    <div className='authors reviewers'>
      <div className="header">
        <div className="text">
          <h2>Recent Peer Reviewers</h2>
          <p>Based on most recently submitted reviews</p>
        </div>
        {viewMoreButton && <a href={seeMoreUsersPath + "&reviewer_pool=true"} target="_blank">
          <i className="fas fa-arrow-right"/>
        </a>}
      </div>
      {renderAllAuthorInfo()}
      {renderSuggestionButton()}
    </div>
  )
}

export default Reviewers