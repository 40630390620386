const AttachmentInsertMixin = {
  removeAttachmentForReinsertion: function(attachmentType) {
    if (attachmentType === 'InteractiveModel') {
      attachmentType = this.checkForInteractiveModel(attachmentType)
    }
    if(attachmentType.length)
    var attachment = this.ckeditor().document.getById(attachmentType.toLowerCase() + '-anchor-' + this.state.attachment.id)
    if (attachment) {
      attachment.getParents()[2].remove()
    }
  },

  checkForInteractiveModel: function (attachmentType) {
    var parsedStr = attachmentType.slice(0, 11) + '_' + attachmentType.slice(11, 17)
    return parsedStr.toLowerCase()
  },

  ckeditor: function(){
    return CKEDITOR.instances[this.state.attachment.article_type + "_article_contents_attributes_0_html"]
  },

  disableAttachments: function(){
    var nodeList = this.ckeditor().document.find('.article-attachment-wrap');
    for(var i=0; i < nodeList.count(); i++){
      nodeList.getItem(i).setAttribute('contentEditable', false);
    }
  },

  handleCharacterCount: function(count, legend) {
    var newAttachment = Object.assign({}, this.state.attachment)
    newAttachment["legend"] = legend
    this.setState({characterCount: count, attachment: newAttachment})
    this.isValidNextButton()
  },

  setValidState: function(isValid){
    this.setState({disableButton: !isValid})
  },

  updateKey: function(e) {
    var newAttachment = Object.assign({}, this.state.attachment)
    newAttachment[e.target.dataset.title] = e.target.value
    this.setState({attachment: newAttachment})
  },

  disableButtonClass: function(props) {
    if (props) {
      return this.props.disableButton ? "disabled" : ''
    } else {
      return this.state.disableButton ? "disabled" : ''
    }
  },

  setCursorPosition: function() {
    var ckeditor = this.ckeditor();
    var id = this.props.attachment.id;
    var element =  ckeditor.document.getById( this.props.attachment.type.toLowerCase() + '-anchor-' + id )
    if (!element) {return}
    var range = ckeditor.createRange();
    range.collapse(true)
    range.setStart( this.ckeditor().editable(), (element.getAscendant('div').getIndex()));
    ckeditor.getSelection().selectRanges( [ range ] );
  },

  moveCursorToStart: function(){
    var ckeditor = this.ckeditor()
    var range = ckeditor.createRange();
    range.collapse(true)
    range.setStart( ckeditor.editable(), 0);
    ckeditor.getSelection().selectRanges( [ range ] );
  },

  resetCursorPosition: function() {
    var ckeditor = this.ckeditor()
    var range = ckeditor.createRange();
    range.setStart( ckeditor.editable(), -1);
    range.setEnd( ckeditor.editable(), -1);
    ckeditor.getSelection().selectRanges( [ range ] );
  }
}

export default AttachmentInsertMixin;
