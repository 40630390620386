import React from 'react';

const SubmittingAnArticle = () => {
  return (
    <React.Fragment>
      <h1>Submitting an Article</h1>
      <p>Cureus operates a merit-based publication system, in which we publish all articles that satisfy Cureus editorial requirements and contain no fraudulent or dangerous science. It is the submitting author’s responsibility to meet us halfway by submitting an article draft that meets all listed editorial requirements. Authors must complete the following steps in order to submit an article draft for peer review. Authors may save and exit the submission process at any time by clicking “Exit” in the top right corner of the page.</p>
      
      <div className='big-numbers'>
        <div className='step-1'>
          <h3>Getting started</h3>
          <p>Are you submitting on behalf of a channel? Are you entering a competition? Designate your article as a channel or competition article here, then choose the article type (original, review, case report, technical report, editorial). This information cannot be changed after moving on to the Title, etc. section.</p>
        </div>
        
        <div className='step-2'>
          <h3>Title, etc.</h3>
          
          <h4>Title</h4>
          <div className='submission-step-info stack-small'>
            <div className='left-side'>
              <div className='space-bottom-s'>
                <i className='fas fa-check blue'></i>
                <div className='icon-before blue small-font'>
                  <strong>APA style</strong>
                </div>
              </div>
              <div className='space-bottom-s'>
                <i className='fas fa-check blue'></i>
                <div className='icon-before blue small-font'>
                  <strong>Title case</strong>
                </div>
              </div>
              <div>
                <i className='fas fa-check blue' style={{position: 'relative', bottom: '15px'}}></i>
                <div className='icon-before blue small-font'>
                  <strong>Only one <br/> sentence</strong>
                </div>
              </div>
            </div>
            <div className='right-side'>
              <div className="space-bottom-s">
                <div className='icon-with-text'>
                  <div className='icon'>
                    <i className='fas fa-exclamation-triangle red'></i>
                  </div>
                  <div className='text small-font'>
                    <strong>Do not enter the title in all caps or include a period at the end.</strong>
                  </div>
                </div>
              </div>
              <div className="space-bottom-s">
                <div className='icon-with-text'>
                  <div className='icon'>
                    <i className='fas fa-exclamation-triangle red'></i>
                  </div>
                  <div className='text small-font'>
                    <strong>Acronyms, initialisms or abbreviations are not permitted in the article title. Exceptions include common abbreviations such as COVID-19, HIV, MRI, etc.</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p>Beginning with this step, you may preview your draft at any time to see how the published version will be displayed.</p>
          
          <h4>Abstract</h4>
          <div className='submission-step-info stack-small'>
            <div className='left-side'>
              <div className='inline-block-small medium-font blue space-bottom-xxs'>
                <strong>3500</strong>
              </div>
              <div className='inline-block-small small-font blue'>
                <strong>characters limit</strong>
              </div>
            </div>
            <div className='right-side'>
              <div className='icon-with-text'>
                <div className='icon'>
                  <i className='fas fa-exclamation-triangle red'></i>
                </div>
                <div className='text small-font'>
                  <strong>Abstract subheaders are permitted in original articles only.</strong>
                </div>
              </div>
            </div>
          </div>
          
          <h4>Categories</h4>
          <div className='submission-step-info stack-small'>
            <div className='left-side only'>
              <div className='inline-block-small medium-font blue space-bottom-xxs'>
                <strong>1-3</strong>
              </div>
              <div className='inline-block-small small-font blue'>
                <strong>article categories</strong>
              </div>
            </div>
            <div className='right-side'></div>
          </div>
          
          <h4>Keywords</h4>
          <div className='submission-step-info stack-small'>
            <div className='left-side'>
              <div className='inline-block-small medium-font blue space-bottom-xxs'>
                <strong>5-10</strong>
              </div>
              <div className='inline-block-small small-font blue'>
                <strong>keywords</strong>
              </div>
            </div>
            <div className='right-side'>
              <div className='icon-with-text'>
                <div className='icon'>
                <i className='fas fa-info-circle blue'></i>
                </div>
                <div className='text small-font'>
                  <strong>Please keep your keywords short (a maximum of 3-4 words each) and as specific as possible.</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='step-3'>
          <h3>Authors</h3>
          <h4>List</h4>
          <p>Add your co-authors. Authors cannot be added after submission so make sure to include all authors prior to submitting! The submitting author is listed as the first author by default; this can be changed by dragging and dropping the authors into the desired order.</p>

          <p><strong>You are responsible for editing all author names and affiliations.</strong> While your co-authors can edit their name and affiliation in their Cureus profiles, their changes will not be reflected in articles submitted to or published in Cureus. Author names and affiliations for all authors listed in an article must be edited by the submitting author. It is the submitting author’s responsibility to enter or revise all co-author name and affiliation information and ensure accuracy and consistent formatting.</p>

          <p><strong>Affiliations: </strong>All authors must have an accurate and consistently formatted affiliation name and location. Medical students should list ‘Medical School’ or the relevant article category as their department (e.g. neurosurgery, family medicine, etc.). Titles such as Professor, Assistant Professor, Medical Student, Resident, Department Chair, etc. are not permitted in the author affiliation and should not be included in the Department field. If two or more authors are from the same hospital or academic institution, the hospital or institution name entered in this field must be identical for those authors. If these authors are also from the same department, then the information in the department field must also be identical. This includes spelling, capitalization, spacing, etc.</p>

          <p>Articles submitted with inconsistent affiliation formatting will be required to purchase our Preferred Editing service in order to proceed so it is very important that you edit all author affiliations to be uniform!</p>

          <h4>No Suffixes for Seniority in Author List</h4>
          <p>Suffixes denoting author list seniority or professional standing (e.g., Senior, Junior, II, III) should not be included.</p>

          <h4>Contributions</h4>
          <p>All authors must meet <a href="#!/policies-and-procedures/icmje-requirements">ICMJE guidelines</a> for authorship in order to be listed as an author. Open the dropdown menu next to each author to select their contributions to the work. Authors who do not satisfy ICMJE authorship criteria should be removed from the author list and mentioned in the Acknowledgements section (consent needed).</p>

        </div>
        
        <div className='step-4'>
          <h3>Disclosures</h3>
          <p>Please provide all relevant information pertaining to any human or animal subjects and disclose any relevant conflicts of interest (COI). You may also add acknowledgements at this time.</p>
          <p>In the event that human or animal subjects are included in the article, please provide the relevant IRB names and approval numbers and confirm that informed consent was obtained or waived.</p>
          <p>The submitting author is responsible for including all co-author COI and disclosure information. Are you unsure about potential conflicts of interest among your co-authors? You can send an email request for this information directly to your co-authors from inside the submission process.</p>
          <p>Conflicts of interest may involve 3rd party payment or service for any aspect of the submitted work, relevant financial activities outside the submitted work, patents that could be considered broadly relevant to the work and any relationships not covered previously.</p>
          <p> Based on the provided information and disclosures, Cureus will automatically generate an <a href='https://public.cureus.com/author_guide/Ethics%20Statement%20and%20Conflict%20of%20Interest%20Disclosures.png'>Ethics Statement and Conflict of Interest Disclosures</a> to be included alongside the published article. Please see the <a href='#!/policies-and-procedures/cope-guidelines'>Publication Ethics</a> and <a href='#!/policies-and-procedures/conflicts-of-interest'>Conflicts of Interest</a> sections of the Author Guide to view a detailed breakdown of required statements and disclosures.</p>
        </div>
        
        <div className='step-5'>
          <h3>Article</h3>
          <p>Enter the main article content here. Article sections will vary depending on the article type selected earlier in the submission process. Do not compose your article directly in the text field. Instead, first write your article in the word processor of your choice and then copy and paste your article text into the appropriate section. Please do not include section names (e.g. Introduction, Materials &amp; Methods, Results, etc.) as these are added automatically.</p>
          
          <h4>Previously Presented as Abstract or Poster</h4>
          <p>If you previously presented your article as an abstract or poster, this must be mentioned in the introduction section. Please include the meeting or conference title and date. For example, “This article was previously presented as a meeting abstract at the 2020 AANS Annual Scientific Meeting on April 27, 2020.”</p>
          
          <h4>Previously Posted to Preprint Server</h4>
          <p>If you previously posted your article to a preprint server, this must be mentioned in the body of the article. For example, “This article was previously posted to the medRxiv preprint server on April 27, 2020.” Article submissions that are currently available on a preprint server will be accepted if they are not pending full publication elsewhere.</p>
          
          <h4>Text Formatting</h4>
          <p>All articles must adhere to <strong>Cureus formatting styles:</strong></p>
          <ul>
            <li className='check'><strong>No paragraph indentations or bulleted lists</strong></li>
            <li className='check'><strong>Only one line return after each paragraph</strong></li>
            <li className='check'><strong>Only one space between sentences</strong></li>
            <li className='warning'><strong>Please remove any text styling before copying and pasting your text into the appropriate field</strong></li>
          </ul>

          <h4>Scientific Nomenclature</h4>
          <p>Please adhere to <a href='https://wwwnc.cdc.gov/eid/page/scientific-nomenclature' target='_blank' rel='noreferrer'>CDC guidelines for writing scientific nomenclature such as bacteria and genes.</a> (Italics cannot be used in article titles.)</p>
          
          <h4>Conclusions</h4>
          <p>The conclusions should be concise and exclude any data or other information that would require citations.</p>
          <div className='submission-step-info stack-small'>
            <div className='left-side'>
              <div className='inline-block-small medium-font blue space-bottom-xxs'><strong>1-2</strong></div>
              <div className='inline-block-small small-font blue'><strong>paragraphs</strong></div>
            </div> 
            <div className='right-side'>
              <div className='icon-with-text'>
                <div className='icon'>
                  <i className='fas fa-exclamation-triangle red'></i>
                </div>
                <div className='text small-font'>
                  <strong>Articles with 1-2 sentence conclusions will not be accepted.</strong>
                </div>
              </div>
            </div>
          </div>
          
          <h4>Numbers</h4>
          <p>Numbers 1-9 should be spelled out except in cases of measurement. (e.g. 7 mm) Numbers higher than nine should be entered numerically. (10, 100, 463, 1,932, etc.)</p>
          
          <h4>Citations</h4>
          <p>References should be cited using square brackets. Square brackets should not be used anywhere in your article except for the in-text citations. Reference citations may be placed mid-sentence or at the end of a sentence (but before the period). If multiple references are cited in one place, please list them as follows: [1-4], [1,3], [5,9-12], etc. Footnotes are not permitted. Citations are not permitted in figure, table, video or 3D model titles. They should instead be included in the media legend or table cells.</p>
          
          <h4>Media</h4>
          <p>To add media to your article, first place your cursor in the desired location in the article section text field and click one of the Insert Figure, Table, Video or 3D model buttons on the article section toolbar. You will then be prompted to upload the figure, paste or create the table or paste the video or 3D model URL and add a title and optional legend.</p>
          <div className='submission-step-info stack-small'>
            <div className='left-side'>
              <div className='inline-block-small medium-font blue space-bottom-xxs'><strong>25</strong></div>
              <div className='inline-block-small small-font blue'><strong>media items per article limit</strong></div>
            </div> 
            <div className='right-side'>
              <div className='icon-with-text'>
                <div className='icon'>
                  <i className='fas fa-exclamation-triangle red'></i>
                </div>
                <div className='text small-font'>
                  <strong>Media is not permitted in the abstract, introduction or conclusions section. The only exception is the introduction section of a review article.</strong>
                </div>
              </div>
            </div>
          </div>
          <p>Please visit our <a href='/media_guide'>Media Guide</a> for detailed instructions on adding each media type as well as examples of unacceptable media.</p>
          
          <h4>Subheaders</h4>
          <p>Cureus allows for three levels of subheaders.</p>
          <p>Major subheaders should be formatted using the subheader style found in the “Styles” dropdown menu. Major subheaders should be in sentence case, i.e. the first word should be capitalized and all other words should be in lowercase, except proper nouns and acronyms/initialisms (such as GBM, SRS, etc.).</p>
          <p>Minor subheaders should be in title case and italicized.  Both major and minor subheaders should be followed by a line return before the next paragraph begins. If the article contains a third level of subheader, it should be in sentence case and lead off the paragraph, followed by a colon. E.g. Patient zero: This patient exhibited signs of…</p>
          
          <h4>Formulas</h4>
          <p>Cureus supports LaTex. Formulas may be built using LaTeX during your submission or you may copy/paste and convert your pre-prepared formulas. Please keep this in mind when preparing your manuscript.</p>
          
          <h4>Appendices (Optional)</h4>
          <p>Add supplemental information here.</p>

          <h4>Miscellaneous</h4>
          <p>Please <a href='https://www.ibm.com/support/pages/how-cite-ibm-spss-statistics-or-earlier-versions-spss' target='_blank' rel='noreferrer'>refer here for information on how to cite IBM SPSS statistics</a> or <a href='https://www.stata.com/support/faqs/resources/citing-software-documentation-faqs/' target='_blank' rel='noreferrer'>Stata software</a> within the article body. (These do not require a corresponding reference entry.)</p>
          <p>Any medical device or similar technology referenced in the text should, for the first instance in both the abstract and body, be immediately followed by the manufacturer name and location in parentheses.</p>
          <p>Brand name drugs should be treated as a proper noun, but generic brands should remain in lower case.</p>
          <p>All questionnaires that have been previously published must be properly cited in the article (mandatory). If the questionnaire is not Open Access, permission for its use must also be obtained. For questionnaires developed by the authors, these must be included within the article itself.</p>
        </div>
        
        <div className='step-6'>
          <h3>References</h3>
          <p>Creating references is simple with our automated converting tool. Please format all references in a text document according to the Cureus style before copying and pasting the list and selecting “Convert references.”</p>
          <p>Once all references have been converted, please preview (and edit as necessary) each reference to ensure that it has been converted correctly. We’ll do our best to detect each ereference type based on its formatting, <strong>but the conversion tool is not perfect and the results will require your review</strong>. When editing your converted references, please do not add punctuation at the end - punctuation will be added automatically. If you prefer, you can also enter your references one-by-one.</p>
          <p>Please view the <a href='#!/author-instructions/references'>References section</a> for more detailed instructions.</p>
        </div>
        
        <div className='step-7'>
          <h3>Reviewers</h3>
          
          <h4>Author-Invited Reviewers</h4>
          <p>The submitting author must suggest a minimum of five individuals with requisite domain knowledge to review the article. Please note that medical students may not be invited to peer review. Prior to the forwarding of a review invitation, each author-submitted reviewer is carefully vetted by Cureus editorial staff. Reviewers who are deemed unsuitable are replaced by Cureus editorial staff at this time. </p>
          
          <h4>Cureus-Invited Reviewers</h4>
          <p>In addition to these author-suggested reviewers, a minimum of six peer reviewers are automatically invited from the Cureus internal peer review panel, consisting of relevant specialists who have either volunteered for the panel or previously published as an author in Cureus. This ensures that a minimum of 11 peer reviewers are invited to review each article. Once the article has entered peer review, three additional reviewers are automatically invited by Cureus every two days to ensure that the article completes peer review in a timely fashion.</p>

          <h4>Satisfying Peer Review</h4>
          <p>Two completed reviews from Cureus-invited reviewers are required to satisfy peer review. Cureus editors will reject reviews that do not include constructive feedback.</p>
        </div>
        
        <div className='step-8'>
          <h3>Summary</h3>
          <p>The summary contains a section-by-section outline of your article. You can review each aspect of your article and jump directly to a specific section of the submission process if you’d like to make changes.</p>
          <p>If any issues with the reference citations are detected, you will receive a notification and list of the citation or reference-related issues (e.g. missing citations, missing references, incorrectly formatted citations). It is your responsibility to identify and address all listed in order to proceed to the next step and submit your article.</p>
        </div>
        
        <div className='step-9'>
          <h3>Submit</h3>
          <p> Please preview your article draft one last time and make sure that everything looks good. You cannot submit your draft without first previewing your work. When you’re satisfied, click the ‘Submit my article’ button. Peer review invitation emails will be sent out and the peer review process will begin when your article is approved during our preliminary editorial review. Please refer to The <a href='#'>Cureus Publishing Process</a> section for a detailed description of what happens next.</p>
        </div>
        
        <div className='step-10'>
          <h3>Purchasing Preferred Editing Service or Uploading Third Party Editing Service Receipt</h3>
          <p>If, after submission, you receive an email stating that our Preferred Editing service is required, you will be prompted to enter your payment information and resubmit the article. Your article cannot be edited while deferred for Preferred Editing.</p>
          <p>If, after submission, you receive an email stating that professional third-party language editing is required, you will be prompted to enter the name of the service and upload an invoice or receipt when resubmitting the article.</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubmittingAnArticle;