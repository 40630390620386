import React from 'react';

const LatexInfoModal = ({type}) => {

  return (
    <div className="reveal-modal small" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a className="cancel-modal">
            <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="modal-content">
          <h4>Inserting a formula in your {type}?</h4>
            <p>You can build your formula here: <a href="https://latexeditor.lagrida.com" target="_blank">https://latexeditor.lagrida.com</a></p>

            <p>Please paste your LaTeX formula into the text field. Insert "\(" before the formula and "\)" after the formula to ensure it is displayed correctly. (Note: do not include the quotes.)</p>

            <div className='modal-footer'>
              <a className='cancel-modal button secondary'>Close</a>
            </div>
        </div>
      </div>
    </div>
  );
};

export default LatexInfoModal;
