import React, { memo } from "react";

const AbstractPosterCard = ({
  authors,
  categories,
  path,
  publishedDate,
  type,
  title,
  handleSubmit,
  thumbnail_url,
}) => {
  const renderCategoryCards = () => {
    return categories.map((category) => {
      const { id, name } = category;
      return (
        <button
          className="abstract-category"
          key={`category-${id}`}
          onClick={() => handleSubmit(name, id)}
        >
          {name}
        </button>
      );
    });
  };

  return (
    <div className="abstract-card-container">
      <div className="abstract-card-header">
        <div className="date-published">{publishedDate}</div>
        <div className="card-type">{type}</div>
      </div>

      <a className="abstract-title" href={path} target="_blank">
        {title}

        {thumbnail_url && (
          <img
            className="card-thumbnail"
            src={thumbnail_url}
            alt={`"${title}" thumbnail`}
          />
        )}
      </a>

      <div className="abstract-authors">{authors}</div>

      <div className="abstract-categories-container">
        {renderCategoryCards()}
      </div>
    </div>
  );
};

export default memo(AbstractPosterCard);