import {Cureus} from '../Cureus';
import '../../../../vendor/assets/javascripts/spin.min.js';
import '../../../../vendor/assets/javascripts/spin-jquery';

Cureus.AvatarUploader = function(wrapSelector, timestamp) {
  var script = 'https://assets.transloadit.com/js/jquery.transloadit2-v2-latest.js';
  window.AvatarUploader = this;

  $.getScript(script, function() {

    window.AvatarUploader.wrap = document.querySelector(wrapSelector)
    window.AvatarUploader.form = window.AvatarUploader.wrap.querySelector("form")
    window.AvatarUploader.image = window.AvatarUploader.wrap.querySelector(".avatar-image");
    window.AvatarUploader.miniImage = document.querySelectorAll(".mini-avatar-image")
    this.$overlay = $(window.AvatarUploader.wrap.querySelector(".fancy-image-overlay"));

    window.AvatarUploader.timestamp = timestamp;

    var self = this;
    $(window.AvatarUploader.image).on("load", function() {
      self.$overlay.spin(false);
    });

    window.AvatarUploader.bindFileSelector();

    var options = {
      wait: true,
      modal: false,
      autoSubmit: false,
      triggerUploadOnFileSelection: false,
      onFileSelect: _.bind(window.AvatarUploader.onFileSelect, this),
      onSuccess: _.bind(window.AvatarUploader.onSuccess, this)
    }

    // must use jquery sdk
    $(window.AvatarUploader.form).transloadit(options);
  });
}

Cureus.AvatarUploader.prototype.bindFileSelector = function() {
  this.wrap.querySelector("[data-select-file]").onclick = function(e) {
    var fileSelector = this.dataset.selectFile
    document.querySelector(fileSelector).click();
  }
}

Cureus.AvatarUploader.prototype.onFileSelect = function(filename) {
  var split_filename;
  split_filename = filename.split('.');
  var extension = split_filename[split_filename.length-1].toLowerCase();
  if(extension.match(/((jpe?g)|(png)|(gif)|(bmp))/)){
    $(window.AvatarUploader.form).trigger('submit.transloadit');
    this.$overlay.spin({color: '#666', radius: 10, width: 2, length: 2});
  }else{
    window.AvatarUploader.displayError("The file type is not allowed, please upload a file of type jpg, png, gif, or bmp.");
  }
}

Cureus.AvatarUploader.prototype.convertFileNametoPNG = function(fileName, timestamp) {
  // converting all files to PNG because users would change extension names from like png to jpg and it would break
  // when rendering with some image tools because the mime-type didn't change.

  var name = fileName.split(".")
  name.splice(name.length - 1, 1, "png")
  return this.timestamp + "-" + name.join(".")
}

Cureus.AvatarUploader.prototype.onSuccess = function(assembly) {
  var self = window.AvatarUploader;
  var fileName = self.convertFileNametoPNG(assembly.uploads[0].name, self.timestamp)

  var avatarUpdateUrl = self.form.getAttribute("action");
  var newProfileAvatarUrl = assembly.results.profile[0].url;
  var newMiniProfileAvatarUrl = assembly.results.mini[0].url;
  $.ajax({
    type: "PUT",
    url: avatarUpdateUrl,
    dataType: "json",
    data: {
      user: { avatar: fileName },
      section: 'AvatarForm'
    },
    success: function(data) {
      self.swapImageWith(self.image, newProfileAvatarUrl);
      self.swapImageWith(self.miniImage, newMiniProfileAvatarUrl);
    },
    error: function() {
      //??
      Cureus.Flash.alert('Unfortunately an error has occured. Please try again later.')
    }
  });
},

Cureus.AvatarUploader.prototype.swapImageWith = function(imageElem, imagePath) {
  $(imageElem).attr("src", imagePath);
},

Cureus.AvatarUploader.prototype.displayError = function (msg){
  Cureus.Flash.alert(msg);
}