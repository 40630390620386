var createReactClass = require('create-react-class');
import React from 'react';
import DeleteCampaignsModal from './DeleteCampaignsModal';
import Modal from '../Modal';
import PropTypes from 'prop-types';

const DeleteButton = createReactClass({
  propTypes: {
    destroyPath: PropTypes.string.isRequired,
    ids: PropTypes.array.isRequired
  },

  handleDelete: function() {
    Modal.open({
      children: <DeleteCampaignsModal
        destroyPath={this.props.destroyPath}
        ids={this.props.ids}
        onDelete={this.props.onDelete} 
      />
    });
  },

  render: function() {
    if(this.props.ids.length > 0) {
      return (
        <a onClick={this.handleDelete} className='button secondary'>DELETE</a>
      )
    } else {
      return <div></div>;
    }
  }
});

export default DeleteButton;
