import React, { useState } from 'react';
import { Cureus } from '../../../../Cureus';

const EditorColumn = ({type, channelAdminEditors, showAllEditors}) => {
  const editorType = () => {
    switch(type) {
      case "ChannelAdmin":
        return "Channel Admins"
      case "DeputyEditor":
        return "Deputy Editors"
    }
  }

  const editors = () => {
    let editorsOfChannel = channelAdminEditors
    let editorsByEditorType = filterByEditorType(editorsOfChannel)

    if (showAllEditors) {
      return editorsByEditorType
    } else {
      return editorsByEditorType.splice(0,3)
    }
  }

  const filterByEditorType = (editors) => {
    if (editors) {
      return editors.filter( (admin) => {
        return admin.user_type == type
      })
    } else {
      return []
    }
  }

  const copyEmailToClipboard = (e, admin) => {
    e.preventDefault()
    navigator.clipboard.writeText(admin.email)
    Cureus.Flash.success(`${admin.email} copied to clipboard`)
  }

  const renderEditors = () => {
    return editors().map((admin) => {
      return (
        <div key={admin.id} className="row">
          <div className="channel-deputy-admin columns small-12">
            <p><a target="_blank" href={`/admin/users/${admin.id}`}>{admin.first_name} {admin.last_name}</a></p>
            <p className='email' ><a onClick={(e) => {copyEmailToClipboard(e, admin)}}>{admin.email}</a></p>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="row">
        <div className="columns small-12">
          <h2>{editorType()}</h2>
        </div>
      </div>
        {renderEditors()}
    </>
  )
}

export default EditorColumn
