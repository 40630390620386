var createReactClass = require('create-react-class');
import React from 'react';

const Errors = createReactClass({
    handleClose: function() {
      $('.errors').hide();
    },
  
    render: function() {
      return (
        <div className="errors">
          { this.props.message }
          <a className="close" onClick={ this.handleClose }>
            <img alt="Close_x" src="https://public.cureus.com/close_x_white.png"/>
          </a>
        </div>
      )
    }
  });
  
  export default Errors;