
var createReactClass = require('create-react-class');
import React from 'react';

const Payment = createReactClass({

  getInitialState: function(){
    return ({
      redirecting: false
    })
  },

  redirectToCheckout: function() {
    this.setState({redirecting: true}, this.props.redirectToCheckout())
  },

  render: function() {
    return (
      <div className='modal-content'>
        <div className='row'>
            <div className='large-8 columns large-centered'>
              <div className='large-12 columns centered'>
                <a className='button primary' disabled={this.props.loading || this.state.redirecting} onClick={this.redirectToCheckout}>Pay now</a>
              </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Payment;
