
var createReactClass = require('create-react-class');
import React from 'react';
import Pages from '../Shared/Pagination/Pages';
import Header from '../ArticleIndex/Header';
import Filters from './Filters';
import Search from './Search';
import PersonCard from '../Shared/ContentCards/PersonCard';

const Main = createReactClass({
  getInitialState: function() {
    return ({
      contents: this.props.contents.data,
      page: 1,
      pageCountOverview: this.props.pageCountOverview,
      paginationData: this.props.paginationData,
      search: this.props.q || '',
      specialties: this.props.specialties,
      selectedSort: this.props.sorts.selectOptions[0].value,
      spinning: false,
      reviewerPool: this.props.reviewerPool,
    })
  },

  componentDidMount: function() {
    this.submitUpdate()
  },

  handleSearchEnter: function(e) {
    if (e.key === 'Enter') {
      this.setState({page: 1}, this.submitUpdate)
    }
  },

  handleSearchClick: function() {
    this.setState({page: 1}, this.submitUpdate)
  },

  updateSearch: function(e) {
    this.setState({search: e.target.value})
  },

  resetPage: function() {
    this.setState({page: 1}, this.submitUpdate)
  },

  formatParams: function() {
    var params = {specialties: {}, page: this.state.page, q: this.state.search, order: this.state.selectedSort, fetch_people: true, reviewer_pool: this.state.reviewerPool}
    Object.values(this.state.specialties).forEach(function(sp) {
      if (sp.checked) params.specialties[sp.id] = sp.name
    })
    return params
  },

  submitUpdate: function() {
    $("html, body").animate({scrollTop: 150}, 0);

    var path = this.props.filterPath
    var params = this.formatParams()
    this.setState({spinning: true})
    var self = this
    $.ajax({
      method: 'get',
      url: path,
      data: params,
      dataType: 'json',
      success: function(res) {
        self.updateContents(res)
      },
      error: function(res) {
      }
    }, 'json');
  },

  updateContents: function(data) {
    var contents = data.contents.data
    this.setState({contents: contents, page: data.page, pageCountOverview: data.pageCountOverview, spinning: false, paginationData: data.paginationData
    })
  },

  updateSpecialties: function(newSpecialtyState) {
    this.setState({specialties: newSpecialtyState})
  },

  updateSort: function(sort) {
    this.setState({selectedSort: sort, page: 1}, this.submitUpdate)
  },

  updatePage: function(page) {
    this.setState({page: page}, this.submitUpdate)
  },

  renderSpinner: function() {
    return (
      <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>
    )
  },

  renderHeader: function() {
    return (
      <div className="row">
        <Header
          people={true}
          peoplePath={this.props.peoplePath}
          posters={false}
          postersPath={this.props.postersPath}
          articlesPage={false}
          articlesPath={this.props.articlesPath}/>
      </div>
    )
  },

  renderFilters: function() {
    return (
      <Filters
        specialtyIds={this.props.specialtyIds}
        specialties={this.props.specialties}
        submitUpdate={this.submitUpdate}
        resetPage={this.resetPage}
        updateSpecialties={this.updateSpecialties}
        sorts={this.props.sorts}
        selectedSort={this.state.selectedSort}
        updateSort={this.updateSort}
      />
    )
  },

  renderBody: function() {
    if (this.state.spinning) return this.renderSpinner();
    return (
      <div className="row">
        <div className="content-index-results-count">
          {this.state.pageCountOverview}
        </div>
        <div className="content-index-results-columns" id="content-results-wrap">
          <div>
            {this.state.contents.map(function(obj) {
              return <PersonCard key={obj['id'] + '_' + 'User'} resource={obj['attributes']}/>
              })}
          </div>
        </div>
      </div>
    )
  },

  render: function() {
    return (
      <div>
        {this.renderHeader()}
        <div className="row">
          <div className="small-12 columns">
            <Search
              typesRequired={this.typesRequired}
              searchPage={this.props.searchPage}
              updateSearch={this.updateSearch}
              handleSearchEnter={this.handleSearchEnter}
              people={this.props.people}
              handleSearchClick={this.handleSearchClick}
              posters={this.props.posters}
            />
            {this.renderFilters()}
          </div>
        </div>
        <div className="search-results-wrap">
          <div>
            <div className="row">

            </div>
            <div className="content-index-wrap" id="filter-results">
              {this.renderBody()}
              <div className="content-index-pagination">
                <Pages
                paginationData={this.state.paginationData}
                handlePageSelect={this.updatePage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default Main;