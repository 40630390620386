import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SearchResults from './SearchResults';

import Introduction from './AcademicChannelsPages/Introduction';
import Faq from './AcademicChannelsPages/Faq';
import ChannelEditorialRoles from './AcademicChannelsPages/ChannelEditorialRoles';
import ChannelEditorialResponsibilities from './AcademicChannelsPages/ChannelEditorialResponsibilities';

const AcademicChannelGuide = ({ query, urls }) => {

  return (
    <Switch>
      <Route path="/overview/introduction">
        <Introduction/>
      </Route>

      <Route path="/channel-editorial-roles-and-responsibilities/channel-editorial-roles">
        <ChannelEditorialRoles />
      </Route>

      <Route path="/channel-editorial-roles-and-responsibilities/channel-editorial-responsibilities">
        <ChannelEditorialResponsibilities />
      </Route>

      <Route path="/faqs/frequently-asked-questions">
        <Faq />
      </Route>

      <Route path="/search">
        <SearchResults query={ query } guide={ 'academicchannels_guide' }/>
      </Route>
      <Redirect to="/overview/introduction"/>
    </Switch>
  )
}

export default AcademicChannelGuide;