import {Cureus} from '../Cureus';

Cureus.ListSlider = (function($, _){
  var init, ListSlider;

  init = function (){
    new ListSlider();
  };

  ListSlider = function() {
    this.initialize();
  };

    _.extend(ListSlider.prototype, {
      initialize: function() {
        var tabsLeft = $("ul.tabs > li.tab-title").map(function(_, ele) {
          var rect = ele.getBoundingClientRect();
          return rect.left
        })
        var tabsLeftArray = Object.values(tabsLeft).splice(0, tabsLeft.length);
        var offset = tabsLeftArray[0]
        tabsLeftArray = tabsLeftArray.map(function(x) { return Math.round(x - offset) })
        var scrollTo;
        var tabItems = $(".tab-items")[0]
        $('.left-arrow').on('click', function() {
          var scrollLeft = tabItems.scrollLeft;
          if (scrollLeft == 0) {
            return;
          }
          for (let i = tabsLeftArray.length - 1; i >= 0; i--) {
            if (scrollLeft > tabsLeftArray[i]) {
              scrollTo = tabsLeftArray[i];
              break;
            }
          }
          tabItems.scrollTo({
            left: scrollTo,
            behavior: 'smooth'
          });
        })
    
        $('.right-arrow').on('click', function() {
          var scrollLeft = tabItems.scrollLeft;
          if (scrollLeft == tabsLeftArray[tabsLeftArray.length-1]) {
            return;
          }
          for (let i = 0; i < tabsLeftArray.length; i++) {
            if (scrollLeft < tabsLeftArray[i]) {
              scrollTo = tabsLeftArray[i];
              break;
            }
          }
          tabItems.scrollTo({
            left: scrollTo,
            behavior: 'smooth'
          });
        })
      }
    })
  return {init: init};
})(jQuery, _);
