
var createReactClass = require('create-react-class');
import React from 'react';

const Authorships = createReactClass({
  render: function() {
    return (
      <div className='large-12 small-12 columns'>
        <h4>
          Accurate and uniform author affiliations are required.
        </h4>

        <p>
          Do you have two or more authors from the same institution? If so, is that institution spelled exactly the same for all authors? What about the department?
        </p>

        <p>
          This is a PubMed Central requirement for indexing. As a result, articles submitted with inconsistent affiliation formatting will be required to purchase our Preferred Editing service in order to proceed. Have you edited all affiliations to ensure formatting consistency and accuracy?
        </p>
        <label>
          <input ref='checkbox' type='checkbox' onChange={ this.props.handleChecked }/>
          &nbsp;
          Yes, all author affiliations are accurate and formatted consistently.
        </label>
      </div>     
    )
  }
});

export default Authorships;