var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';
import Search from './Search';
import Pages from '../Shared/Pagination/Pages';

const Table = createReactClass({
  getInitialState: function() {
    return {
      reviewers: null,
      updateCheckedState: this.props.updateCheckedState,
      page: 1,
      spinning: true,
      paginationData: [],
      searchTerm: "",
      sort: null,
      sorting_dir: null,
      page_limit: 10
    }
  },

  componentDidMount: function() {
    this.fetchReviewers()
  },

  fetchReviewers: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      url: self.props.availableReviewersPath,
      dataType: 'json',
      data: {page: self.state.page, sort: self.state.sort, sorting_dir: self.state.sorting_dir, page_limit: self.state.page_limit},
      success: function(res) {
        self.setState({
          reviewers: res.reviewers,
          paginationData: res.pagination_data,    
          spinning: false
        })
      }
    });
  },

  renderReviewers: function() {
    var self = this;
    if (!self.state.reviewers) {
      return;
    }
    return self.state.reviewers.map(function(reviewer) {
      return <Row
        key={reviewer.id}
        reviewer={reviewer}
        updateCheckedState={self.state.updateCheckedState}
        isCompetition={self.props.isCompetition}
        articleState={self.props.articleState}
        articleStateHash={self.articleStateHash}
        />
    })
  },

  renderVolunteer: function() {
    if(!this.props.isCompetition) {
      return <th className = "has-tip tip-top" data-tooltip="" title="Volunteers should be prioritized over non-volunteers" onClick={() => this.handleSort('reviewer_pool')}>Volunteer</th>
    }
  },

  updateSearchTermState: function(term) {
    var self = this;
    self.setState({searchTerm: term, page: 1}, function() {
      if (self.state.searchTerm.length) {
        self.fetchResults();
      } else {
        self.handlePageFetch(1)
      }
    });
  },

  fetchResults: function() {
    var self = this;
    self.setState({spinning: true, reviewers: null})
    $.ajax({
      method: 'GET',
      url: self.props.searchPath,
      data: {search_term: self.state.searchTerm, page: self.state.page, sort: self.state.sort, sorting_dir: self.state.sorting_dir, page_limit: self.state.page_limit},
      success: self.searchSuccess,
      error: function() {alert('Error fetching search results')}
    })
  },

  searchSuccess: function(res) {
    if(this.state.searchTerm.length) {
      this.setState({
        reviewers: res.reviewers,
        paginationData: res.pagination_data,          
        spinning: false
      })
    }
  },

  handleSort: function(col) {
    var prev = this.state.sort ? this.state.sort : col
    var direction;
    if (prev === col) {
      direction = this.state.sorting_dir === 'asc' ? 'desc' : 'asc'
    } else {
      direction = 'asc'
    }
    this.setState({sort: col, sorting_dir: direction}, function() {
      this.handlePageFetch(1)
    })
  },

  handlePageFetch: function(page) {
    this.setState({page: page, spinning: true, reviewers: null}, function() {
      if (this.state.searchTerm.length !== 0) {
        this.fetchResults()
      } else {
        this.fetchReviewers()
      }
    })
  },

  renderPages: function() {
    if(this.state.paginationData.totalPages) {
      return (
        <div className="row">
          <span>Showing {this.state.paginationData.showingFrom} to {this.state.paginationData.showingTo} of {this.state.paginationData.totalCount} entries</span>
          <Pages
            classNameForReviewer="available-reviewers"
            paginationData={this.state.paginationData}
            handlePageSelect={this.handlePageFetch} 
            />
        </div>
      )
    }
  },

  renderSpinner: function() {
    if(this.state.spinning) {
      return (
        <img style={{position: 'absolute', left: '600px'}}src="https://public.cureus.com/pac-loader.gif"/>
      )
    }
  },

  articleStateHash: function() {
    return {
      'draft': true,
      'editor_preview': true,
      'in_review': true,
      'reviews_complete': true
    }
  },

  renderCheckbox: function() {
    if( this.articleStateHash()[this.props.articleState]) {
      return <th></th>
    }
  },

  handleSelect: function(e) {
    this.setState({page_limit: e.target.value}, function(){
      this.handlePageFetch(1)
    })
  },

  renderLimitSelector: function() {
    return (
      <select className="table-limit-dropdown" onChange={(e) => this.handleSelect(e)}>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    )
  },

  render: function() {
    return(
      <div>
        Show {this.renderLimitSelector()} entries&nbsp;&nbsp;
        <Search updateSearchTermState={this.updateSearchTermState} />
        <div className="table-wrapper">
        <table id="available-reviewers-table" style={{cellSpacing: 0}}>
          <thead>
            <tr>
              {this.renderCheckbox()}
              {this.renderVolunteer()}
              <th onClick={() => this.handleSort('email')}>Email</th>
              <th onClick={() => this.handleSort('first_name')}>First name</th>
              <th onClick={() => this.handleSort('last_name')}>Last name</th>
              <th onClick={() => this.handleSort('specialty_name')}>Specialty</th>
              <th className = "has-tip tip-top" data-tooltip="" title="% of review invitations accepted" onClick={() => this.handleSort('invitations_accepted')}>% Invitations Accepted</th>
              <th className = "has-tip tip-top" data-tooltip="" title="Total reviews completed" onClick={() => this.handleSort('reviewerships_completed')}>Reviews Completed</th>
              <th className = "has-tip tip-top" data-tooltip="" title="Total articles published as submitting author" onClick={() => this.handleSort('articles_published')}>Articles Published</th>
            </tr>
          </thead>
          {this.renderReviewers()}
        </table>
        {this.renderSpinner()}
      </div>
      {this.renderPages()}
    </div>
    )
  }
})

export default Table;