var createReactClass = require('create-react-class');
import React from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

const NewSubmitModal = createReactClass({
  getInitialState: function() {
    return {
      step: this.props.step,
      summary: this.props.summary,
      assessment: this.props.assessment,
      quality: this.props.quality,
      credibility: this.props.credibility,
      comment: this.props.comment,
      disclosure: this.props.disclosure,
      disclosure_declared: this.props.disclosure_declared,
      submitting: false,
      error: false
    }
  },

  handleNext: function() {
    var newStep = this.state.step + 1
    this.setState({step: newStep}, this.props.handleMainStateChange('step', newStep))
  },

  handleBack: function() {
    var newStep = this.state.step - 1
    this.setState({step: newStep}, this.props.handleMainStateChange('step', newStep))
  },

  handleChange: function(e) {
    this.setState({[e.target.name]: e.target.value}, this.props.handleMainStateChange(e.target.name, e.target.value))
  },

  handleSubmit: function() {
    var self = this;
    self.setState({submitting: true, error: null})
    $.ajax({
      type: "PUT",
      dataType: "json",
      url: self.props.completeReviewPath,
      data: this.state,
      success: function(data) {
        window.location.replace(data.path)
      },
      error: function() {
        self.setState({submitting: false, error: true})
      }
    });
  },

  renderContents: function() {
    if(this.state.step === 1) {
      return (
        <StepOne 
          handleChange={this.handleChange}
          handleNext={this.handleNext}
          summary={this.state.summary}
          assessment={this.state.assessment}
        />
      )
    } else if(this.state.step === 2) {
      return (
        <StepTwo
          handleChange={this.handleChange}
          handleNext={this.handleNext}
          handleBack={this.handleBack}
          quality={this.state.quality}
          credibility={this.state.credibility}
          comment={this.state.comment}
        />
      )
    } else {
      return (
        <StepThree 
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleBack={this.handleBack}
          disclosure={this.state.disclosure}
          disclosure_declared={this.state.disclosure_declared}
          submitting={this.state.submitting}
          error={this.state.error}
        />
      )
    }
  },

  render: function() {
    return (
      <div className="reveal-modal middling submit-review-modal" data-reveal>
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>
          {this.renderContents()}
        </div>
      </div>
    )
  }
})

export default NewSubmitModal;