var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const Importer = createReactClass({
  componentDidMount: function() {
    new Cureus.CKEditor("reference-import", {
      ckeditor: {height: 600},
      editor_type: 'references'
    });
  },

  splitReferences: function() {
    this.props.handleClose()
    this.props.disableEditing()
    var references = CKEDITOR.instances["reference-import"].getData().split('\n').map(function(ref) { return ref.replace(/<\/?[^>]+(>|$)/g, "")}).filter(function(str) {return str !== '' && str !== '&nbsp;' && !/^\s+$/.test(str)}).map(function(str) {return '<p>' + str + '</p>'})
    this.handleSubmit(references)
  },

  handleSubmit: function(references) {
    var self = this;
    if (references.length === 0) {
      CKEDITOR.instances["reference-import"].setData('');
      self.props.onDelete();
      return;
    }
    $.post(self.props.importReferencesPath, {text: references.splice(0, 5).join()}).done(function() {
      self.handleSubmit(references)
    })
  },

  render: function() {
    return (
      <div>
        <div className='reference-import-wrap' style={{display:'none'}}>
          <section id='import-references-form-wrap'>
            <h4 className="reg">Reference Converter</h4>
            <p className='import-instructions'>
              Copy and paste your reference list below. Try to remove any numbered list formatting that was applied in your original document before reapplying the numbered list formatting via the button below.
            </p>
            <div className='ck-wrapper'>
              <textarea name='text' id='reference-import'></textarea>
            </div>
            <div className='pull-right'>
              <a className="button small secondary cancel" onClick={ this.props.handleClose }>Cancel</a>
              <a className="button small primary" onClick={this.splitReferences}>Convert references</a>
            </div>
            <div className="clearfix"/>
          </section>
        </div>
      </div>
    )
  }
});

export default Importer;