import {Cureus} from '../../Cureus';

Cureus.Admin.SideNav = (function($, _) {
  var init;

  init = function() {
    new SideNav();
  };

  var SideNav = function() {
    this.$navTogglers = $('.channel-nav-toggle');
    this.$adminSubmenu = $('#admin-submenu-container');

    this._bindEvents();
  };

  _.extend(SideNav.prototype, {
    _bindEvents: function() {
      var self = this;
      this.$navTogglers.on('click', function(e){
        e.preventDefault();
        self.toggleNav();
      });
    },

    toggleNav: function() {
      this.$adminSubmenu.toggleClass("toggle");
    }
  });

  return { init: init };
})(jQuery, _);

