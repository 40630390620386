import React, { useState } from 'react';
import Carat from './Carat';

const List = ({list, updateList, checkedLength, id, text}) => {
  const [collapse, setCollapse] = useState(false);
  const [total, setTotal] = useState(checkedLength(list))

  const handleChange = (name) => {
    let updatedList = [...list]
    let listItem = updatedList.find((el) => { return el.name === name})

    listItem.checked = !listItem.checked

    setTotal(getTotal(updatedList))
    updateList(updatedList)
  }

  const getTotal = (list) => {
    return list.filter((obj) => { return obj.checked}).length
  }

  const renderlist = () => {
    return list.map((listItem) =>  {
      return (
        <div className={`checkbox-row ${(listItem.checked ? 'checked' : '')}`} key={listItem.name}>
          <input id={listItem.name + id} type="checkbox" className="css-checkbox" checked={listItem.checked} onChange={() => handleChange(listItem.name)} />
          <label htmlFor={listItem.name + id}><span>{listItem.name}</span></label>
        </div>
      )
    })
  }

  const collapsedClass = () => {
    return collapse ? 'collapsed' : ''
  }

  return (
    <div className="row small-collapse">
      <div className="small-12 columns">
        <div className={`filter-header ${collapsedClass()}`} onClick={() => setCollapse(!collapse)}>
            <span>{text} {total > 0 && <span>({total})</span>}</span>
            {<Carat collapse={collapse} setCollapse={setCollapse}/>}
        </div>
        {!collapse && <div className="checkbox-container">
          {renderlist()}
        </div>}
      </div>
    </div>
  )
}

export default List