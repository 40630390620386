var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../Cureus';

const Details = createReactClass({
  componentDidMount: function(){
    $(document).foundation('tooltip', 'reflow');
    this.initializeCKEditor();
    this.initializeCharacterCounterListener();
  },

  initializeCKEditor: function(){
    new Cureus.CKEditor("edit-legend-" + this.props.attachment.id, {
      ckeditor: {height: 100},
      editor_type: 'attachment_legend',
      char_count: 2000,
      change_listener: "#change_counter",
      no_insta_save: true
    });
  },

  initializeCharacterCounterListener: function(){
    var self = this;
    $("#change_counter").on('change', function(e){
      var editor = CKEDITOR.instances["edit-legend-" + self.props.attachment.id]
      var legend = editor.getData()
      self.props.handleCharacterCount(e.target.value, legend);
    })
  },

  titleChecker: function(e) {
    var self = this;
    self.props.updateKey(e)
    self.props.isValidNextButton()
  },

  renderAsterisk: function() {
    if (!this.props.attachment.title) {
      return <span className="red-asterisk">*</span>
    }
  },

  submitHandler: function(e) {
    e.preventDefault();
  },

  renderAttachmentType: function() {
    if (this.props.attachment.type === 'InteractiveModel') {
      return 'Interactive Model'
    } else {
      return this.props.attachment.type
    }
  },

  interactiveModelInputStyle: function () {
    if (this.props.attachment.type === 'InteractiveModel') {
      return {paddingLeft: '150px'}
    }
  },


  render: function(){
    return (
      <form ref='detailsForm' id="details-form" onSubmit={this.submitHandler}>
          <p className={"prepend-text " + this.props.attachment.type}>{this.renderAttachmentType() + " " +  this.props.attachment.number + ": "}</p>
          <label id="title-label" htmlFor="attachment_title" className="tip-top"  data-tooltip=""  title={this.props.attachment.type + 's' + " are automatically numbered according to their placement in the article."}>Title {this.renderAsterisk()}</label>
          <input style={this.interactiveModelInputStyle()} autoComplete="off" data-title="title" className="title" id="attachment_title" className="tip-top" name="attachment[title]" ref="attachmentTitle" size="30" type="text" defaultValue={ this.props.attachment.title } placeholder="Enter your descriptive title here (required). Do not include reference citations." data-tooltip="" title={this.props.attachment.type + 's' + " are automatically numbered according to their placement in the article."} onChange={ this.titleChecker } />
          <label htmlFor="attachment_legend">Legend</label>
          <textarea className="legend" name="attachment[legend]" ref="attachmentLegend" rows="4" defaultValue={ this.props.attachment.legend } id={ "edit-legend-" + this.props.attachment.id } placeholder="Enter your legend here (optional). Please include any abbreviation definitions and reference citations here." />
          <input type='hidden' id="change_counter"/>
      </form>
    );
  }
})

export default Details;