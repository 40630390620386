
var createReactClass = require('create-react-class');
import React from 'react';
import * as UserToolUtils from '../../Publishing/UserTool/ApiClient';
import Form from '../../UserPicker/Form';
import { Cureus } from '../../../Cureus';

const NewModal = createReactClass({
  getInitialState: function (){
    return {
      usership: {user: null},
      showSpinner: false,
      showCheckingForUser: false,
      didSearch: false,
      didSubmit: false,
      submitting: false
    }
  },

  componentWillMount: function() {
    this.handleSearch(this.props.searchEmail);
  },

  componentDidMount: function() {
    var self = this;

    $(this.refs.container).on("opened", function (evt){
      self.setState({focusOn: "email"});
      document.querySelector('.reveal-modal-bg').style.display = "block";
    });
  },

  handleSearch: function(email) {
    var self = this;

    if(email.length == 0) { return; }

    self.setState({showSpinner: true, showCheckingForUser: true, didSearch: false});
    UserToolUtils.getSearch({
      email: email,
      endpointUrl: self.props.endpointUrl,
      params: self.props.defaultParams,
      success: function(data) {
        if (data.first_name) {
          data.registered = true
        }
        self.setState({
          usership: {editable: data.editable, user: data},
          showSpinner: false,
          showCheckingForUser: false,
          errors: null,
          focusOn: (data.id ? "submit" : "firstName"),
          didSearch: true
        });
      },
      error: function(jqXHR) {
        if(jqXHR.status === 0) {
          self.setState({
            showSpinner: false,
            showCheckingForUser: false,
            errors: {email: "There was an error. Please try again."},
            didSearch: true
          });
        } else {
          self.setState({
            showSpinner: false,
            showCheckingForUser: false,
            errors: JSON.parse(jqXHR.responseText),
            didSearch: true
          });
        }
      }
    });
  },

  handleSubmit: function(params) {
    var self = this;
    self.setState({submitting: true, didSubmit: true})
    delete self.props.defaultParams.user
    UserToolUtils.postCreate({
      params: Object.assign(params, self.props.defaultParams),
      endpointUrl: self.props.endpointUrl,
      success: function(data) {
        self.setState({
          user: null,
          errors: null,
          showSpinner: false,
          didSearch: false,
          submitting: false
        });
        if (self.props.inReview && self.props.handleReviewerAdd) {
          self.props.handleReviewerAdd()
        }
        self.props.clearEmailField();
        UserToolUtils.getIndex({
          endpointUrl: self.props.endpointUrl,
          params: self.props.defaultParams,
          success: self.props.updateUserships
        })
        if (self.props.fetchChannelReviewerData) {
          self.props.fetchChannelReviewerData();
        }
        if (self.props.fetchListEmailData) {
          self.props.fetchListEmailData();
        }
        if (self.props.isChannel) {
          Cureus.Flash.success("This channel admin or editor is now queued for review to ensure they meet the journal's eligibility criteria.")
        }
        $(self.refs.container).foundation("reveal", "close");
      },
      error: function(jqXHR) {
        self.setState({
          errors: JSON.parse(jqXHR.responseText),
          showSpinner: false,
          didSearch: false,
          submitting: false
        });
      }
    });
  },

  submitText: function() {
    if (this.props.defaultParams.channelAdmins) { return "Add Admin"}
    if (this.props.defaultParams.channelEditors || this.props.isCollection) { return "Add Editor"}
    if (this.props.isChannel) { return "Add reviewer" }
    return this.props.isAuthorships ? 'Add author' : 'Invite reviewer';
  },

  title: function() {
    if (this.props.defaultParams.channelAdmins) { return "Add an Admin"}
    if (this.props.defaultParams.channelEditors || this.props.isCollection) { return "Add an Editor"}

    if (this.props.myReviewsPage) {
      return "Suggest a Reviewer"
    } else if (this.props.isAuthorships) {
      return 'Add an Author'
    } else {
      return 'Invite a Reviewer'
    }
  },

  render: function() {
    return (
      <div className="reveal-modal middling user-picker-modal" data-reveal ref="container">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <Form
              key={"new_author_form"}
              resource={this.state.usership}
              subject="Author"
              isChannel={this.props.isChannel}
              title={this.title()}
              submitText={this.submitText()}
              enableSearch={true}
              specialtyOptions={this.props.specialtyOptions}
              countryOptions={this.props.countryOptions}
              affiliationNameQueryUrl={this.props.affiliationNameQueryUrl}
              deptAutocompletePath={ this.props.deptAutocompletePath }
              onSearch={this.handleSearch}
              onSubmit={this.handleSubmit}
              showSpinner={this.state.showSpinner}
              showCheckingForUser={this.state.showCheckingForUser}
              errors={this.state.errors}
              focusOn={this.state.focusOn}
              hideInstitutionFields={false}
              didSearch={this.state.didSearch}
              didSubmit={this.state.didSubmit}
              email={this.props.searchEmail}
              submitting={this.state.submitting}
              isAuthorship={true}
              isCollection={this.props.isCollection}
              />
          </div>
        </div>
      </div>
    )
  }
});

export default NewModal;