var createReactClass = require('create-react-class');
import React from 'react';

const Editor = createReactClass({
  renderEditorChief: function() {
    if (this.props.editor.is_editor_in_chief) {
      return <span className="editor-in-chief">Editor-in-Chief</span>
    } else {
      return <span/>
    }
  },

  renderInitialEditorsBeforeLazyLoad: function() {
    var editor = this.props.editor;
    if (this.props.idx < 9) {
      return <img src={editor.avatar_profile_url} alt={editor.full_name} title={editor.full_name}/>
    } else {
      return <img data-lazy={editor.avatar_profile_url} src={this.props.spinnerURL} alt={editor.full_name} title={editor.full_name}/>
    }
  },

  setButtonStyleClass: function() {
    if(!this.props.filter && (this.props.idx === 2 || this.props.idx === 3)) {
      return "abs-button"
    }
  },

  render: function() {
    var editor = this.props.editor;
    return(
      <div className={"board-container columns small-12 medium-6 end " + (this.props.firstRowCol ? "large-6" : "large-3")}>
        <div className={"board-member " + (this.props.filter ? "" : "associate-editors") + (this.props.minHeight ? " first-row ": "") }>
          <div className="avatar">
            <a href={editor.path}>
              {this.renderInitialEditorsBeforeLazyLoad()}
            </a>
          </div>
          <h4>{editor.full_name}</h4>
          {this.renderEditorChief()}
          <div className="specialty truncate">
            {editor.specialty_name}
          </div>
          <div className="affiliation truncate">
            {editor.affiliation_name}
          </div>
          <a href={editor.path} className={"button view-profile " + this.setButtonStyleClass()}>View profile</a>
        </div>
      </div>
    )
  }
})

export default Editor;