var createReactClass = require('create-react-class');
import React from 'react';
import JudgeshipModal from './JudgeshipModal';
import Modal from '../Modal';

const JudgeRows = createReactClass({
	 handleSubmit: function(element) {
  	Modal.open({
  		children: <JudgeshipModal
  								judgeId={ $(element.target).data('judge-id') }
  								deleteJudgeship={ this.props.deleteJudgeship }/>

  	})
	},

	render: function() {
		var self = this;
		return (
			<div className="admin-competition-reviewers">
				{
					self.props.judgeships.map( function(judgeship, i) {
						return(

								<div className="row" key={ judgeship.judge.id }>
									<div className="small-10 columns">
										<input ref="checkbox" name={"judges[" + judgeship.judge.id  + "]"} id={ judgeship.judge.id } onClick={ self.props.handleCheckboxSelect } data-checkbox data-judge-id={ judgeship.judge.id } type="checkbox" />
										<label htmlFor={ judgeship.judge.id } >{ judgeship.judge.id } - { judgeship.judge_name }, { judgeship.judge.email } </label>
										<p className="email-sent-at"> { judgeship.orientation_email_sent_at ? "Invited " + judgeship.orientation_email_sent_at : '' }</p>
									</div>
									<div className="small-2 columns judgeship-delete">
										<a data-judge-id={ judgeship.judge.id } onClick={ self.handleSubmit } className="button small secondary"> <i data-judge-id={ judgeship.judge.id } className="far fa-trash-alt"></i></a>
									</div>
								</div>


						)
					})
				}
			</div>
		)
	}
})

export default JudgeRows;
