import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import Tabs from '../Shared/Tabs';
import EmailRequestsMain from './EmailRequestsMain';
import MemberRequestsMain from './MemberRequestsMain';

const RequestsIndex = ({fetchMemberRequestsPath, fetchEmailRequestsPath}) => {

  const tabs = [
    { path: '/members', label: 'MEMBERS', component: <MemberRequestsMain fetchMemberRequestsPath={fetchMemberRequestsPath} /> },
    { path: '/email_inquiries', label: 'EMAIL INQUIRIES', component: <EmailRequestsMain fetchEmailRequestsPath={fetchEmailRequestsPath} /> }
  ];

  return (
    <>
      <h3>Channel Requests</h3>
      <Router hashType="hashbang">
        <Tabs tabs={tabs} />
      </Router>
    </>
  )
}

export default RequestsIndex;