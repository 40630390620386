var createReactClass = require('create-react-class');
import React from 'react';
import NewModal from '../../Publishing/UserTool/NewModal';
import Modal from '../../Modal';

const Invite = createReactClass({
  getInitialState: function() {
    return({
      searchEmail: '',
      headerText: this.props.article.headerText || "INVITE REVIEWERS"
    })
  },

  handleSearch: function() {
    Modal.open({
      children: <NewModal
        specialtyOptions={ this.props.article.specialtyOptions }
        countryOptions={ this.props.article.countryOptions }
        affiliationNameQueryUrl={ this.props.article.affiliationNameQueryUrl }
        searchEmail={ this.state.searchEmail }
        endpointUrl={this.props.article.endpointUrl}
        isAuthorships={this.props.article.isAuthorships}
        defaultParams={{article_id: this.props.article.id}}
        clearEmailField={this.clearEmailField}
        handleReviewerAdd={this.props.handleReviewerAdd}
        inReview={this.props.article.articleState === "in_review"}
        myReviewsPage={this.props.myReviewsPage}
      />
    });
  },



  updateSearchEmail: function(e) {
    this.setState({searchEmail: e.target.value});
  },

  handleKeyPress: function(e) {
    if(e.key === 'Enter') {
      this.handleSearch();
      e.preventDefault();
    }
  },

  clearEmailField: function() {
    this.setState({searchEmail: ''});
  },

  renderHeaderText: function() {
    return <h6 className="tip-top" data-tooltip={true} id="invite-reviewers" title={this.renderTooltip()}>{this.state.headerText}</h6>
  },

  renderTooltip: function() {
    if (this.props.myReviewsPage) {
      return "Know a colleague who would provide a good review? Enter their email below!"
    } else {
      return "Improve your chances of efficient, timely review by inviting more reviewers."
    }
  },

  renderHr: function() {
    if(!this.props.dashboard) {
      return <hr/>
    }
  },

  handleMyReviewsPage: function() {
    if ( this.props.myReviewsPage && this.props.completedReviews > 1 ) {
      return null
    } else {
      return (
        <div className="dashboard-panel-box invite-reviewers-box">
          {this.renderHeaderText()}
          {this.renderHr()}
          <input type='text' placeholder='example@email.com' onChange={ this.updateSearchEmail } onKeyPress={ this.handleKeyPress } value={this.state.searchEmail}/>
          <div className="right-align"><a className='button tiny secondary no-margin auto' onClick={ this.handleSearch }><i className="fas fa-search"></i>SEARCH</a></div>
        </div>
      )
    }
  },

  renderInviteReviewersBox: function() {
    if ((this.props.article.articleState === "in_review" || this.props.article.articleState === 'reviews_complete') && this.props.article.competition_id === null && !this.props.article.authorReviewEnded) {
      return this.handleMyReviewsPage()
    } else {
      return ( null )
    }
  },

  render: function() {
    return (
      <div>
        {this.renderInviteReviewersBox()}
      </div>
    )
  }
})

export default Invite;