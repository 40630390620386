import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";
import Main from '../HomepageHeros/Main';
import TrendingTopics from '../TrendingTopics/Index';
import Discussions from '../Discussions/Index';
import FeaturedContent from '../Home/FeaturedContent';
import DiversityTool from '../DiversityTool/Main';
import FeaturedMultimedia from '../FeaturedMultimedia/Main';

const MainContent = ({featuredContent, featuredContentPath, featuredMultimedia, featuredMultimediaPath, heroPath, articleExistsPath, newsroomPostDetailsPath, specialties, usersPath, adminFeaturedUsersPath}) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname)

  return (
    <React.Fragment>
      <div className="small-12 columns nav-container bordered">
        <div className="row">
          <div className="large-12 columns">
            <div className="cureus-tabs secondary-tabs">
              <div className="tabs-container dashboard">
                <div className="tab-items">
                  <ul>
                  <Link to="/main_content/hero">
                      <li className={"tab-title inline " + (activeTab === '/main_content/hero' ? 'active' : '')} onClick={() => setActiveTab('/main_content/hero')}>
                        <a className="dashboard">HERO</a>
                      </li>
                    </Link>
                    <Link to="/main_content/featured-content">
                      <li className={"tab-title inline " + (activeTab === 'featured-content' ? 'active' : '')} onClick={() => setActiveTab('featured-content')}>
                      <a className="dashboard">FEATURED CONTENT</a>
                      </li>
                    </Link>
                    <Link to="/main_content/trending_topics">
                      <li className={"tab-title inline " + (activeTab === '/main_content/trending_topics' ? 'active' : '')} onClick={() => setActiveTab('/main_content/trending_topics')}>
                        <a className="dashboard">TRENDING TOPICS</a>
                      </li>
                    </Link>
                    <Link to="/main_content/discussions">
                      <li className={"tab-title inline " + (activeTab === '/main_content/discussions' ? 'active' : '')} onClick={() => setActiveTab('/main_content/discussions')}>
                        <a className="dashboard">DISCUSSIONS</a>
                      </li>
                    </Link>
                    <Link to="/main_content/featured_users">
                      <li className={"tab-title inline " + (activeTab === '/main_content/featured_users' ? 'active' : '')} onClick={() => setActiveTab('/main_content/featured_users')}>
                        <a className="dashboard">FEATURED AUTHORS AND REVIEWERS</a>
                      </li>
                    </Link>
                    <Link to="/main_content/featured_multimedia">
                      <li className={"tab-title inline " + (activeTab === '/main_content/featured_multimedia' ? 'active' : '')} onClick={() => setActiveTab('/main_content/featured_multimedia')}>
                        <a className="dashboard">FEATURED MULTIMEDIA</a>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Switch>
        <Route path='/main_content/hero'>
          <Main heroPath={heroPath}/>
        </Route>
        <Route path='/main_content/featured-content'>
          <FeaturedContent newsroomPostDetailsPath={newsroomPostDetailsPath}
           featuredContent={featuredContent}
           featuredContentPath={featuredContentPath}
           specialties={specialties}
           articleExistsPath={articleExistsPath}
          />
        </Route>
        <Route path='/main_content/trending_topics'>
          <TrendingTopics/>
        </Route>
        <Route path='/main_content/discussions'>
          <Discussions/>
        </Route>
        <Route path='/main_content/featured_users'>
          <DiversityTool 
            usersPath={usersPath} 
            savePath={adminFeaturedUsersPath}
          />
        </Route>
        <Route path='/main_content/featured_multimedia'>
          <FeaturedMultimedia 
            featuredMultimedia={featuredMultimedia}
            featuredMultimediaPath={featuredMultimediaPath}
          />
        </Route>
      </Switch>
    </React.Fragment>
  )

}

export default MainContent;