import React, { memo } from 'react';
import AbstractPosterCard from '../../Shared/ContentCards/AbstractPosterCard';

const Content = ({ contents, loading, handleSubmit }) => {
  const renderSpinner = () => {
    return (
      <div className="spinner-container">
        <img
          src="https://public.cureus.com/ajax-loader.gif"
          alt="loading spinner"
        />
      </div>
    );
  };

  const renderCards = () => {
    return loading ? renderSpinner() : renderContentCards();
  };

  const renderContentCards = () => {
    return contents.map((content) => {
      const {
        type,
        path,
        categories,
        title,
        id,
        author_list,
        published_date,
        thumbnail_url,
      } = content;
      return (
        <AbstractPosterCard
          authors={author_list}
          categories={categories}
          path={path}
          publishedDate={published_date}
          type={type}
          title={title}
          key={`abstract-card-${id}`}
          handleSubmit={handleSubmit}
          thumbnail_url={thumbnail_url}
        />
      );
    });
  };

  return <div className="abstracts-container">{renderCards()}</div>;
};

export default memo(Content);