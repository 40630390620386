var createReactClass = require('create-react-class');
import React from 'react';
import Modal from './Modal';

const ContactCoAuthorsModal = createReactClass({
  handleSubmit: function(){
    var self = this;
    self.disableEmailButton();
    var $form = $(self.refs.form);
    $.ajax({
      type: "POST",
      url: self.props.shareEmailUrl,
      data: $form.serialize(),
      success: function(data) {
        self.closeModal();
        alert("Your email has been sent. Keep up the good work!");
        $(".alert-box").removeClass("alert");
        $(".alert-box").addClass("success");

      },
      error: function() {
        alert("Oops, Mail no get sent.");
      }
    });
  },

  closeModal: function() {
    Modal.close();
  },

  disableEmailButton: function() {
    $("[data-button-type=send-email]").prop("disabled", true);
  },

  handleSendCopyTothis: function() {
    if ($(".copy_to_this").is(":checked")) {
      if ($(".modal_authors").val() == '') {
        $(".modal_authors").val(this.props.submitter_email);
      }
      else {
        $(".modal_authors").val(this.props.author_emails + ", " + this.props.submitter_email);
      }
    }
    else {
      $(".modal_authors").val(this.props.author_emails);
    }
  },


  render: function(){
    var defaultMessage = "Hello!\n\nI’m in the process of submitting our article to the Cureus Journal of Medical Science. As the submitting author, I’m responsible for declaring any potential conflicts of interest for myself and all co-authors. At your earliest convenience, please let me know if you have anything to disclose.\n\nThanks!";

    return(
      <div className="reveal-modal medium" data-reveal id="react-modal">
        <div className="modal-body">
          <div className='modal-close'>
            <a href='#' className='cancel-modal'>
              <img src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

           <div className="modal-content">
            <div className="form-fields modal">
              <h4>Contact your co-authors</h4>
              <p>
                We’ll send an email to your co-authors with the following message. Feel free to replace what you see below with your own personal message.
              </p>

                <form ref="form" acceptCharset="UTF-8" action="/share/emails" className="custom" id="edit_original_article_4947" method="post">
                  <div style={{margin:0, padding:0, display:"inline"}}>
                    <input name="utf8" type="hidden" value="✓"/>
                    <input type="hidden" name="share_email[event_klass]" defaultValue={ this.props.event_klass }/>
                  </div>
                  <label htmlFor= "authors">
                    Recipients
                    <textarea className="modal_authors" name="modal_authors_x" disabled={true} defaultValue={ this.props.author_emails }/>
                    <input type="hidden" className="modal_authors" name="share_email[recipients]" defaultValue={ this.props.author_emails }/>
                  </label>

                  <input id="check" className="copy_to_this" name="" type="checkbox"  height="100" onChange={ this.handleSendCopyTothis }/>
                  <label htmlFor="check">Send a copy to me</label>

                  <label htmlFor= "modal_subject">
                    Subject
                    <input type="text" className="modal_subject" cols="40" id="modal_subject" name='share_email[subject]' defaultValue={`Message from ${this.props.submitter_name}: Do you have any disclosures for our co-authored article?`}/>
                  </label>

                  <label htmlFor= "modal_message">
                    Message
                    <textarea className="medium-size-pizzas" cols="40" id="modal_message" name='share_email[message]' defaultValue={ defaultMessage } />
                  </label>


                  <div className="modal-footer">
                    <a href="#" className="button secondary cancel-modal"> Cancel </a>
                    <input className="button primary" type="submit" name="commit" value="Send Email" onClick={ this.handleSubmit } data-button-type="send-email"/>
                  </div>

                </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export default ContactCoAuthorsModal;
