import React, { useEffect } from 'react';
import CollectionRow from './CollectionRow';
import Pages from '../../Shared/Pagination/Pages'


const PublishedTab = ({collections, paginationData, handlePageSelect, pagesType, fetchCollections}) => {

  return (
    <div className='published-collection-container'>
      <div className='collection-holder'>
        {collections.map((collection) => {
          return (
            <CollectionRow
              collection={collection} 
              fetchCollections={fetchCollections}/>
          )
        })}
        {
          paginationData.totalPages > 1 && (
            <div className='pagination-container'>
              <Pages 
                paginationData={paginationData} 
                handlePageSelect={handlePageSelect} 
                pagesType={pagesType}/>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default PublishedTab;