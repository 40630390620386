import React, { useState, useEffect } from 'react';
import DateSelector from '../StatsWidget/DateSelector';
import SubmissionsCompleted from './SubmissionsCompleted';
import ApprovalCompleted from './ApprovalCompleted';
import CopyCompleted from './CopyCompleted';
import DualCompleted from './DualCompleted';
import MessagesCommentsToAuthor from './MessagesCommentsToAuthor';
import RejectedReviews from './RejectedReviews';
import AverageScores from './AverageScores'
import ApprovalRating from './ApprovalRating';
import ApprovalMatches from './ApprovalMatches';

const Main = ({ path, type, inProgress, dateRanges, editorView }) => {

  const [response, setResponse] = useState(null);
  const [startDate, setStartDate] = useState(dateRanges[0].from)
  const [endDate, setEndDate] = useState(dateRanges[0].to)
  const [selectedRange] = useState(dateRanges[0].label);

  useEffect(() => {
    getStats(startDate, endDate, selectedRange);
  }, [])

  const getStats = (fromDate, toDate, selectedRange) => {
    // if editorView set dates to 30 days passed in as props merged in from haml
    $.ajax({
      type: 'GET',
      url: path,
      data: {from: fromDate, to: toDate, type: type},
      success: function(res) {
        setStartDate(res.startDate)
        setEndDate(res.endDate);
        setResponse(res);
      }
    })
  }

  const renderDateSelector = () => {
      return (
        <div className='small-2 columns'>
          <DateSelector
            from={startDate}
            to={endDate}
            getStats={getStats}
            selectedRange={selectedRange}
            dateRanges={dateRanges}
          />
        </div>
      )
    }

    const renderInProgress = () => {
      if(type != 'submissions') {
        return (
            <div className="small-12 columns header">
              <div className="small-1 columns icon-container">
              <span className={"vertical-line-icon " + type}>
                <i className="fas fa-clipboard-list"/>
              </span>
              </div>
              <div className="small-11 columns">
                <h5>In Progress &nbsp; &nbsp; &nbsp;{inProgress}</h5>
              </div>
            </div>
        )
      }
    }

    const renderCompleted = () => {
      if(!response) {
        return;
      }
      if(type === 'submissions') {
        return <SubmissionsCompleted data={response.completedWork} editorView={editorView}/>
      } else if(type === 'approval') {
        return <ApprovalCompleted data={response.completedWork} editorView={editorView}/>
      } else if(type === 'copy') {
        return <CopyCompleted data={response.completedWork} editorView={editorView}/>
      } else if(type === 'dual' || type === 'deputyAdmin') {
        return <DualCompleted data={response.completedWork} type={type} editorView={editorView}/>
      }
    }

    const renderMessages = () => {
      if(!response) {
        return;
      } else {
        return <MessagesCommentsToAuthor 
                  data={response.messageData}
                  label={"In-app emails to author"}
                  iconClass={"far fa-envelope envelope"}
                  type={type}
                  editorView={editorView}
               />
      }
    }

    const renderComments = () => {
      if(!response) {
        return;
      } else {
        return <MessagesCommentsToAuthor 
                  data={response.commentData}
                  label={"Message board comments"}
                  iconClass={"far fa-comment-alt"}
                  type={type}
                  editorView={editorView}
               />
      }
    }

    const renderApprovalRating = () => {
      if(!response) {
        return;
      } else if(type === 'approval') {
        return <ApprovalRating 
                  data={response.ratingsData}
                  editorView={editorView}
               />
      }
    }

    const renderRejectedReviews = () => {
      if(!response) {
        return;
      } else if (type === 'approval'){
        return <RejectedReviews 
                  data={response.rejectedReviewsData}
                  type={type}
               />
      }
    }

    const renderApprovalMatches = () => {
      if(!response) {
        return;
      } else if (type === 'approval'){
        return (
          <div className="editor-activity" style={{marginTop: '40px'}}>
            <div className="row">
              <div className="small-3 columns">
                <h4>Matched Articles</h4>
              </div>
            </div>
            <ApprovalMatches 
              data={response.matchData}
              editorView={editorView}
            />
          </div>
        )
      }
    }

    const renderAverageScores = () => {
      if(!response) {
        return;
      } else if (type === 'dual' || type === 'submissions' || response.completedWork.showSubmissions) {
        return <AverageScores 
                  data={response.averageScoresData}
                  type={type}
                  editorView={editorView}
               />
      }
    }

    return (
      <React.Fragment>
        <div className="editor-activity">
          <div className="row">
            <div className="small-3 columns">
              <h4>Editorial Activity</h4>
            </div>
            { renderDateSelector() }  
          </div>
          <div className="row">
            {renderInProgress()}
          </div>
          {renderCompleted()}
          {renderMessages()}
          {renderComments()}
          {renderApprovalRating()}
          {renderAverageScores()}
          {renderRejectedReviews()}
        </div>
        {renderApprovalMatches()}
      </React.Fragment>
    )
  }


export default Main;