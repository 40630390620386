var createReactClass = require('create-react-class');
import React from 'react';
import AdvancedSearch from './AdvancedSearch';

const Search = createReactClass({
  getInitialState: function() {
    return({
      advanced: this.props.advanced,
      query: this.props.search
    })
  },

  classType: function() {
    if(this.props.hasSubchannels) {
      return 'medium-10 medium-centered small-12 columns'
    }
    if(this.props.compOrChannel) {
      return this.props.typesRequired ? 'large-3 large-offset-0 medium-8 medium-offset-2 small-12 columns' : 'large-4 large-offset-0 medium-8 medium-offset-2 small-12 columns'
    }
    if (this.props.searchPage) {
      return 'large-8 large-offset-2 medium-8 medium-offset-2 small-12 columns'
    } else {
      return 'large-8 large-offset-2 medium-8 medium-offset-2 small-12 columns'
    }
  },

  placeholderText: function() {
    if (this.state.advanced) {
      return ''
    }
    if(this.props.people) {
      return 'Search People'
    } else if(this.props.posters){
      return 'Search Posters'
    } else if (this.props.articlesPage) {
      return "Search Articles"
    } else {
      return 'Title, keyword, name'
    }
  },

  toggleAdvanced: function() {
    this.setState({advanced: !this.state.advanced, query: ''})
  },

  renderAdvancedSearch: function() {
    if (this.props.articlesPage) {
      return (
        <AdvancedSearch
        advanced={this.state.advanced}
        filters={this.props.filters}
        toggleAdvanced={this.toggleAdvanced}
        handleAdvancedSearchEnter={this.props.handleAdvancedSearchEnter}
        handleAdvancedSearchClick={this.props.handleAdvancedSearchClick}
        updateSearchBar={this.updateSearchBar}
        />
      )
    }
    return (
      <br></br>
    )
  },

  updateSearchBar: function(e, text) {
    if (!this.state.advanced) {
      this.setState({query: e.target.value}, this.props.updateSearch(e))
    }
    else {
      this.setState({query: text})
    }
  },

  renderSearchButton: function() {
    if (!this.state.advanced && !this.props.compOrChannel) {
      return (
        <div className="small-3 medium-2 columns end text-center">
          <a className="button primary" onClick={this.props.handleSearchClick}>Search</a>
        </div>
      )
    }
  },

  handleCompChannelStyle: function() {
    if (this.props.compOrChannel) {
      return {height: 0}
    } else {
      return {}
    }
  },

  getSearchClass: function() {
    if (this.state.advanced || (this.props.channelVersion == 2 && !this.props.hasSubchannels) || this.props.compOrChannel) {
      return "small-12 columns"
    }
    return "small-9 medium-10 columns end"
  },

  render: function() {
    return(
      <div className="search-container" style={this.handleCompChannelStyle()}>
        <div className="row">
          <div className={this.classType()}>
            <div className='search-box'>
              <div style={{padding: "0px"}}className={this.getSearchClass()}>
                <input disabled={this.state.advanced} autoComplete='off' id='q' name='q' placeholder={this.placeholderText()} type='text' onChange={(e) => this.updateSearchBar(e, null)} onKeyPress={this.props.handleSearchEnter} value={this.state.query}/>
                <i className='fa fa-search'/>
              </div>
              {this.renderSearchButton()}
            </div>
          </div>
        </div>
        {this.renderAdvancedSearch()}
      </div>
    )
  }
})

export default Search;