
var createReactClass = require('create-react-class');
import React from 'react';
import InstitutionAutoSuggester from '../../UserPicker/InstitutionAutoSuggester';

const Institution = createReactClass({
  getInitialState: function() {
    return {
      errors: this.props.errors
    }
  },

  componentDidUpdate: function(prevProps) {
    if(this.props.errors != prevProps.errors) {
      this.setState({errors: this.props.errors})
    }
  },


  handleCheck: function() {
    var self = this;
    self.props.toggleSpinner();
    $.ajax({
      method: 'GET',
      url: "/institutions/validate",
      data: {authorship_id: self.props.authorship_id, id: self.props.id, institution_name: self.props.institution_name},
      success: function(data) {
        if(!data.invalid) {
          self.setState({errors: null})
        } else {
          var errors = self.state.errors
          errors.invalid_institution = true
          errors.institution_name = 'The spelling or formatting of this institution is not accepted. Please try a different format or spelling.'
          self.setState({errors: errors})
        }
      }
    }).always(function() {
      self.props.toggleSpinner();
    })
  },

  renderInvalid: function() {
    if (this.state.errors && this.state.errors.invalid_institution) {
      return (
        <div>
          <label className="left">
            <input type="checkbox" onChange={this.props.handleChange} key={ this.props.id + "_confirm_institution" } name={ "confirmInstitution" } id={ this.props.id + "_confirm_institution" } ref="confirmInstitution" data-idx={ this.props.idx }/>
            &nbsp; I confirm the institution is correct.
          </label>
          {!this.props.confirmInstitution && <a className="button secondary right" onClick={this.handleCheck}>Recheck</a>}
        </div>
      )
    }
  },

  renderInstitutionNameField: function() {
    var class_name, error_field, text;

    class_name = "";
    error_field = null;
    if(this.state.errors && this.state.errors.institution_name && !this.props.confirmInstitution){
      text = this.state.errors.institution_name
      class_name = "error";
      error_field = ( <div key={this.props.id + "_institution_name_errors"} className="errors"> { text } </div> )
    }

    if(this.props.editable){
      return (
        [
          (<InstitutionAutoSuggester autosuggestUrl={this.props.affiliationNameQueryUrl} >
            <input type="text" key={this.props.id + "_institution_name_field" } name={"institutionName"} id={this.props.id + "_institution_name"} ref="institutionName" data-idx={this.props.idx} data-error_key="institution_name" className={class_name} onChange={this.props.handleChange} value={this.props.institution_name} autoComplete='new-password' />
          </InstitutionAutoSuggester>),
        error_field ]
      )
    }
    return (<input type="text" key={this.props.id + "_institution_name_field"} name="institution_name" id={this.props.id + "_institution_name"} value={this.props.institution_name} disabled="disabled" autoComplete='new-password' />)
  },

  render: function() {
    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <label>Hospital/Academic Institution</label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            { this.renderInstitutionNameField() }
            { this.renderInvalid() }
          </div>
        </div>
      </div>
    )
  }
})

export default Institution;