var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';
import NewModal from './NewModal';
import Sortable from '../Sortable';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const Main = createReactClass({
  getInitialState: function() {
    return {
      reasons: this.props.reasons
    }
  },

  renderRows: function() {
    var self = this;
    return self.state.reasons.map(function(reason) {
      return <Row key={reason.id} reason={reason} type={self.props.type}/>
    })
  },

  openNewModal: function() {
    Modal.open({
      children: <NewModal
        type={this.props.type}
        newPath={"/admin/deferral_reasons"}
        buttonText={this.props.buttonText}
      />
    });
  },

  handleSort: function(e, sortable, movedComponent) {
    var newPosition = e.newIndex + 1;
    this.handleUpdatePosition(movedComponent, newPosition);
  },

  handleUpdatePosition: function(movedComponent, newPosition){
    var self = this;
    var updateNumberUrl = '/admin/deferral_reasons/' + movedComponent.props.reason.id + "/update_number"
    var oldPosition = movedComponent.props.reason.number;
    var $flash = $('.wizard-nav-wrap').parent();
    if($flash.length == 0) { $flash = undefined } //Hacky but necessary for now
    if(this.isValidPosition(newPosition)) {
      $.ajax({
        type: "PUT",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').last().attr('content'))},
        url: updateNumberUrl,
        data: { number: newPosition, type: self.props.type },
        success: function(res) {
          Cureus.Flash.success("You have successfully moved reason " + oldPosition + " to reason " + newPosition + ".", $flash);
          self.setState({reasons: res.reasons})
        }
      });
    } else {
      Cureus.Flash.alert("That is not a valid Deferral Reason number.", $flash);
    }
  },

  isValidPosition: function(position) {
    var min = 1;
    var max = this.state.reasons.length;
    return position >= min && position <= max;
  },

  buttonText: function() {
    return "New " + this.props.buttonText;
  },

  render: function() {
    let editorApprovalTable = this.props.type === "EditorApprovalRejectionReason"
    return (
      <div className="article-content-references-table-wrap">
        <h2 className="reg">{this.props.titleText}</h2>
        <table className="table-wrapper" id="deferral-reasons-table">
          <thead>
            <tr>
              <th className='table-draggable'></th>
              <th>#</th>
              <th>Label</th>
              {editorApprovalTable && <th>Tooltip</th>}
              <th>Description</th>
              <th><i className="fa fa-edit"></i></th>
              <th><i className="far fa-trash-alt"></i></th>
            </tr>
          </thead>
          <Sortable tagName='tbody' handleSort={ this.handleSort }>
            {this.renderRows()}
          </Sortable>
        </table>
        <a className="button success" onClick={this.openNewModal}>{this.buttonText()}</a>
    </div>
    )
  }
});

export default Main;
