var createReactClass = require('create-react-class');
import React from 'react';

const NewAdvancedSearchRow = createReactClass({

  getOffset: function() {
    if (this.props.id == 0) {
      return "small-offset-4 medium-offset-2"
    }
  },
  
  renderBooleanOperators: function() {
    if (this.props.id > 0) {
      return(
        <select className="boolean-dropdown box-shadow" name="boolean" data-index={this.props.id} onKeyPress={this.props.handleAdvancedSearchEnter} onChange={(e) => this.props.handleChange(this.props.id, e)} value={this.props.filter.boolean}>
          <option value="and">AND</option>
          <option value="or">OR</option>
          <option value="not">NOT</option>
        </select>
      )
    }
  },

  renderSearchableFields: function() {
    return(
      <select className="search-field-dropdown box-shadow" name="field" data-index={this.props.id} onKeyPress={this.props.handleAdvancedSearchEnter} onChange={(e) => this.props.handleChange(this.props.id, e)} value={this.props.filter.field}>
        <option value="all">All Fields</option>
        <option value="affiliations">Affiliation</option>
        <option value="authors">Author</option>
        <option value="title">Title</option>
        <option value="body">Body</option>
        <option value="abstract">Abstract</option>
        <option value="keywords">Keywords</option>
      </select>
    )
  },

  renderInputBox: function() {
    return(
      <input className="input-styles box-shadow" name="query" data-index={this.props.id} onKeyPress={this.props.handleAdvancedSearchEnter} onChange={(e) => this.props.handleChange(this.props.id, e)} value={this.props.filter.query} type="text"/>
    )
  },

  renderRemoveButtons: function() {
    var self = this;
    return(
      <div className="buttons">
        {self.props.numFilters > 1 && this.props.idx > 0 &&
          <a className="remove-advanced-icon-container box-shadow" href="javascript:void(0)" data-add-fields="true" onClick={self.removeFilter}>
            <img src="https://public.cureus.com/browse-article/remove-option-row.png" />
          </a>
        }
      </div>
    )
  },

  removeFilter: function() {
    this.props.removeRow(this.props.id)
  },
  
  render: function() {
    var self = this
    return(
      <div className="small-12 columns advanced-search-row new-search-row">
        <div className="boolean-container">
          {this.renderBooleanOperators()}
        </div>
        <div className="searchable-container">
          {this.renderSearchableFields()}
        </div>
        <div className="small-12 columns input-container">
          {this.renderInputBox()}
        </div>
        <div className="remove-btn-container">
          {this.renderRemoveButtons()}
        </div>
      </div>
    )
  }
})

export default NewAdvancedSearchRow;