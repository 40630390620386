var createReactClass = require('create-react-class');
import React from 'react';

const NpiRows = createReactClass({
  render: function (){
    return (
        <tr>
          <td>
            <input type="radio" name="npi" value={this.props.id} onChange={(e) => this.props.handleChange(e)}></input>
          </td>
          <td>
            <a href={`https://npiregistry.cms.hhs.gov/registry/provider-view/${this.props.id}`} target="_blank">{this.props.id}</a>
          </td>
          <td>
          {this.props.name}
          </td>
          <td>
          {this.props.spec}
          </td>
          <td>
          {this.props.address}
          </td>
          <td>
          {this.props.phoneNumber}
          </td>
        </tr>
    )
  }
})

export default NpiRows;
