import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// Search
import SearchResults from './SearchResults';
// Guidelines
import AddingMedia from './MediaPages/AddingMedia';
import TitleLegendsNumbering from './MediaPages/TitleLegendsNumbering';
import MentioningMedia from './MediaPages/MentioningMedia';
// Figures
import FigureRequriements from './MediaPages/FigureRequirements';
import AddingFigures from './MediaPages/AddingFigures';
import UnacceptableFigureExamples from './MediaPages/UnacceptableFigureExamples';
import GenerativeAiImages from './MediaPages/GenerativeAiImages';
// Tables
import TableRequirements from './MediaPages/TableRequirements';
import AddingTables from './MediaPages/AddingTables';
import UnacceptableTableExamples from './MediaPages/UnacceptableTableExamples';
// Videos
import AddingVideos from './MediaPages/AddingVideos';
// Interactive Models
import AddingInteractiveModels from './MediaPages/AddingInteractiveModels';
// Privacy and Permissions
import PrivacyAndPermissionIssues from './MediaPages/PrivacyAndPermissionIssues';

const MediaGuide = ( { query, urls }) => {
  return (
    <Switch>
      <Route path="/overview/adding-media">
        <AddingMedia/>
      </Route>
      <Route path="/overview/title-legends-numbering">
        <TitleLegendsNumbering/>
      </Route>
      <Route path="/overview/mentioning-media">
        <MentioningMedia/>
      </Route>
      <Route path="/overview">
        <Redirect to="/overview/adding-media"/>
      </Route>

      <Route path="/figures/figure-requirements">
        <FigureRequriements/>
      </Route>
      <Route path="/figures/adding-figures">
        <AddingFigures/>
      </Route>
      <Route path="/figures/unacceptable-figure-examples">
        <UnacceptableFigureExamples/>
      </Route>
      <Route path="/figures/generative-ai-images">
        <GenerativeAiImages/>
      </Route>
      <Route path="/figures">
        <Redirect to="/figures/figure-requirements"/>
      </Route>
      
      <Route path="/tables/table-requirements">
        <TableRequirements/>
      </Route>
      <Route path="/tables/adding-tables">
        <AddingTables/>
      </Route>
      <Route path="/tables/unacceptable-table-examples">
        <UnacceptableTableExamples/>
      </Route>
      <Route path="/tables">
        <Redirect to="/tables/table-requirements"/>
      </Route>

      <Route path="/videos/adding-videos">
        <AddingVideos/>
      </Route>
      <Route path="/videos">
        <Redirect to="/videos/adding-videos"/>
      </Route>


      <Route path="/interactive-models/adding-interactive-models">
        <AddingInteractiveModels/>
      </Route>
      <Route path="/interactive-models">
        <Redirect to="/interactive-models/adding-interactive-models"/>
      </Route>

      <Route path="/privacy-and-permissions/privacy-and-permission-issues">
        <PrivacyAndPermissionIssues/>
      </Route>
      <Route path="/privacy-and-permissions">
        <Redirect to="/privacy-and-permissions/privacy-and-permission-issues"/>
      </Route>

      <Route path="/search">
        <SearchResults query={ query } guide={ 'media_guide' }/>
      </Route>
      
      <Redirect to="/overview/adding-media"/>
    </Switch>
  )
}

export default MediaGuide;