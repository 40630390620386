import React, { useState, useEffect } from 'react';
import Card from "../../ArticleShow/Sidebar/MostPopularArticles/Card";

const PopularArticles = ({contents, selectedCategoryIds}) => {

  const [articles, setArticles] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    $(document).foundation('tooltip', 'reflow')
  }, [articles]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false)
    } else {
      fetchArticles();
    }
  }, [contents])

  const renderArticles = () => {
    return articles.map(function(article, idx) {
      return (
        <Card
          key={idx}
          article={article}
          cardType={selectedCategoryIds ? "most-popular" : ""}
        />
      )
    })
  }

  const fetchArticles = () => {
    $.ajax({
      type: 'GET',
      url: '/articles/most_popular',
      dataType: 'json',
      data: formatData(),
      success: function(res) {
        setArticles(res.contents);
      }
    });
  }

  const formatData = () => {
    let params = { category_ids: [], limit: 4, serializer: "BrowseArticles::RightSidebar::MostPopularArticlesSerializer"};
    if (isAllCategoriesChecked()) {
      delete params.category_ids;
    } else {
      params['category_ids'] = selectedCategoryIds;
    }
    return params;
  }

  const isAllCategoriesChecked = () => {
    return selectedCategoryIds.length == 1 && selectedCategoryIds[0] == 0
  }

  const renderViewMore = () => {
    return <a href={"/articles/most_popular"} target="_blank" className="view-more">See More</a>
  }

  return (
    <>
      {articles.length !== 0 &&
        <div className="row small-collapse">
          <div className="small-12 columns sidebar most-popular">
            <h2 className="popular-text-container">Popular Articles
              <img className="question-mark-icon tip-top" src="https://public.cureus.com/homepage_new/question_mark.png" data-tooltip="" title="Yesterday's most-viewed articles, updated daily." />
            </h2>
            {renderArticles()}
            {renderViewMore()}
          </div>
        </div>
      }
    </>
  )
}

export default PopularArticles;