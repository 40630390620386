import React, { useState, useEffect } from 'react';
import InfoWidgetInviteAndMetrics from './InfoWidgetViews/InfoWidgetInviteAndMetrics';
import InfoWidgetStart from './InfoWidgetViews/InfoWidgetStart';

const InfoWidget = ({collectionsInfoWidgetPath}) => {

  const [publishedCount, setPublishedCount] = useState(0);
  const [infoWidget, setInfoWidget] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchInfoWidget();
  }, [])

  const fetchInfoWidget = () => {
    setLoading(true)
    $.ajax({
      type: 'GET',
      url: collectionsInfoWidgetPath,
      success: function(res) {
        setPublishedCount(res.publishedCollectionsCount)
        setInfoWidget(res);
        setLoading(false);
      }
    })
  }
  
  const renderInfoWidget = () => {
    if (!loading) {
      if (publishedCount === 0) {
        return <InfoWidgetStart />
      } else {
        return <InfoWidgetInviteAndMetrics infoWidget={infoWidget} />
      }
    }
  }
  
  return (
    <div className="large-3 small-12 medium-12 columns new-info-widget-section">
      <div className="small-12 columns no-padding">
        {renderInfoWidget()}
      </div>
    </div>
  )
}

export default InfoWidget;