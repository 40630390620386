import React, { useState, useEffect } from 'react';
import { Cureus } from '../../Cureus';
import Article from './Article';
import Poster from './Poster';
import Abstract from './Abstract';
import Pages from '../Shared/Pagination/Pages';
import InfoWidget from './InfoWidget';
import Ad from '../Shared/Ad';

const Main = ({activeCount, publishedCount, rejectedCount, blockedCount, showBell, articlesPath, currentUserId, newArticlePath, infoWidget, initialState, editorMessagePath, social_boost_a_id, bannerAdBottom}) => {

  const [articleStates, setArticleStates] = useState(initialState || 'active');
  const [articles, setArticles] = useState([]);
  const [authorshipEndpoint, setAuthorshipEndpoint] = useState(null);
  const [spinning, setSpinning] = useState(true);
  const [emailReceiptEndPoint, setEmailReceiptEndPoint] = useState(null);
  const [email, setEmail] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [sortData, setsortData] = useState({col: 'state_changed_at', dir: 'desc'})
  const [initialLoad, setInitialLoad] = useState(true)
  const [newEditorMessages, setNewEditorMessages] = useState(false)
 
  useEffect(() => {
    Cureus.ListSlider.init();
    Cureus.SplitButton.init();
    fetchArticles();
    if(typeof(bannerAdBottom) !== 'object') {
      $('#banner-ad-1').append(bannerAdBottom)
    }
  }, [articleStates, currPage, sortData])

  const fetchArticles = (messageRefetch) => {
    setSpinning(true)
    setArticles([])
    if(!initialLoad && !messageRefetch) {
      let ele = document.getElementById('secondary-nav-tabs')
      if(ele) {
        ele.scrollIntoView({behavior: 'smooth'})
      }
    }
    $.ajax({
      type: 'GET',
      url: articlesPath,
      data: {article_states: articleStates, currPage: currPage, sort: sortData},
      success: function(data) {
        setInitialLoad(false)
        setPaginationData(data.paginationData)
        setSpinning(false)
        setArticles(data.articles)
        setAuthorshipEndpoint(data.authorshipEndpoint)
        setEmailReceiptEndPoint(data.emailReceiptEndPoint)
        setEmail(data.email)
        setNewEditorMessages(data.hasNewMessages)
      }
    });
  }

  const handlePageSelect = (page) => {
    setCurrPage(page)
  }

  const handleStateChange = (newState) => {
    setCurrPage(1)
    setArticleStates(newState)
  }

  const renderNoArticlesMessage = () => {
    let word;
    if(articleStates === 'active') {
      word = "in progress"
    } else {
      word = "published"
    }
    return (
      <div className="small-12 columns bordered my-doc-gray text-center">
        <h3 className="subhead" style={{marginBottom: '0px', fontSize: '18px', fontWeight: 'bold'}}>{"You do not have any articles " + word + ". "} <a style={{color: '#0096B7'}} href={newArticlePath}>Would you like to start a new one?</a></h3>
      </div>
    )
  }

  const renderArticles = () => {
    if(articles.length === 0 && !spinning) {
      return renderNoArticlesMessage()
    }
    return articles.map((article) => {
      if(article.articleData.data.attributes.isPoster) {
        return (
          <Poster
            poster={article.articleData.data.attributes}
            key={"article_row_" + article.articleData.data.id}
          />
        )
      } else if(article.articleData.data.attributes.isAbstract) {
        return (
          <Abstract
            abstract={article.articleData.data.attributes}
            key={"article_row_" + article.articleData.data.id}
          />
        )
      } else {
        return (
          <Article
            article={article.articleData.data.attributes}
            authorships={article.authorships}
            key={"article_row_" + article.articleData.data.id}
            articleStates={articleStates}
            authorshipEndpoint={authorshipEndpoint}
            currentUserId={currentUserId}
            email={email}
            emailReceiptEndPoint={emailReceiptEndPoint}
            fetchArticles={fetchArticles}
            editorMessagePath={editorMessagePath}
            social_boost_a_id={social_boost_a_id}
          />
        )
      }
    })
  }

  const renderBlocked = () => {
    if(blockedCount > 0) {
      return (
        <li className={"tab-title-new inline " + (articleStates === 'blocked' ? 'active' : '')} onClick={() => handleStateChange('blocked')}>
          <a className="dashboard new-sub-tab">BLOCKED ({blockedCount})</a>
        </li>
      )
    }
  }

  const renderDesk = () => {
    if(rejectedCount > 0) {
      return (
        <li className={"tab-title-new inline " + (articleStates === 'rejected' ? 'active' : '')} onClick={() => handleStateChange('rejected')}>
          <a className="dashboard new-sub-tab">REJECTED ({rejectedCount})</a>
        </li>
      )
    }
  }

  const renderSpinner = () => {
    if(spinning) {
      return (
        <div className='spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  }

  const renderPagination = () => {
    if(articles.length > 0 || articles.length > 5) {
      return (
        <div className="content-index-pagination small-12 columns" style={{marginTop: '25px'}}>
          <Pages
            paginationData={paginationData}
            handlePageSelect={handlePageSelect} 
          />
        </div>
      )
    }
  }

  const renderSortCheck = (column) => {
    if(column === sortData.col) {
      return <i class="fas fa-check"/>
    }
  }

  const renderSortButton = (num) => {
    return (
      <div className="cureus-split-dropdown-wrap sort-button">
      <a className="button secondary" data-cureus-split-dropdown={"sort-button-" + num}>
        <span><i class="fas fa-sort-amount-down-alt"/></span>
      </a>
      <ul id={"sort-button-" + num} className="cureus-split-dropdown new-dash">
        <li className="header">
          Sort By
        </li>
        <li onClick={() => handleSort('state_changed_at')}>
          {renderSortCheck('state_changed_at')}
          <a>Most Recent (Default)</a>
        </li>
        <li onClick={() => handleSort('state')}>
        {renderSortCheck('state')}
          <a>Article status</a>
        </li>
        <li onClick={() => handleSort('submitter')}>
        {renderSortCheck('submitter')}
          <a>Author role</a>
        </li>
        <li onClick={() => handleSort('created_at')}>
        {renderSortCheck('created_at')}
        <a>Submission date</a>
        </li>
        <li onClick={() => handleSort('title')}>
        {renderSortCheck('title')}
        <a>Alphabetical order</a>
        </li>
      </ul>
    </div>
    )
  }

  const handleSort = (col) => {
    var dir;
    if(col === sortData.col && col !== 'boost' && col !== 'expedited') {
      dir = sortData.dir === 'desc' ? 'asc' : 'desc'
    } else {
      if(col === 'state_changed_at' || col === 'created_at') {
        dir = 'desc'
      } else {
        dir = 'asc'
      }
    }
    setsortData({col: col, dir: dir})
    setCurrPage(1)
  }

  const renderInfoWidget = (view) => {
    return (
      <InfoWidget 
        infoWidget={infoWidget} 
        setArticleStates={setArticleStates}
        handleSort={handleSort}
        newEditorMessages={newEditorMessages}
        fetchArticles={fetchArticles}
        articles={articles}
        editorMessagePath={editorMessagePath}
        view={view}
      />
    )
  }

  const renderBannerAd = (props, id, classname) => {
    if(typeof(props) === 'object') {
      return (
        <div id={id}>
          <div className={`cureus-ad-wrapper page-banner-ad ${classname}`} >
            <Ad
              props={ props }
              size={ "banner" }
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className={classname} id={id}/>
      )
    }
  }

  return (
    <React.Fragment>
      {renderInfoWidget('desktop')}
      <div className="large-9 medium-12 small-12 columns articles-container" id="secondary-nav-tabs">
        <div className="small-12 columns nav-container bordered">
          <div className="row hide-for-large-up">
            <div className="medium-1 large-1 medium-offset-11 large-offset-11 small-2 small-offset-10 columns">
            {renderSortButton(1)}
            </div>
          </div>
          <div className="row" style={{ display: 'flex', alignItems: 'center'}}>
            <div className="large-11 columns">
              <div className="cureus-tabs secondary-tabs">
                <div className="tabs-container dashboard">
                  <div className="left-arrow new-dashboard">
                  <div className="show-for-small">
                      <i className="fas fa-angle-left fa-lg" id="left-arrow"/>
                    </div>
                  </div>
                  <div className="tab-items">
                    <ul className='tab-list'>
                      <li className={"tab-title-new inline " + (articleStates === 'active' ? 'active' : '')} onClick={() => handleStateChange('active')}>
                        <a className="dashboard">ACTIVE ({activeCount})</a>
                      </li>
                      <li className={"tab-title-new inline " + (articleStates === 'published' ? 'active' : '')} onClick={() => handleStateChange('published')}>
                        <a className="dashboard new-sub-tab">{showBell ? <div className="round-icon green tiny article-pub"><i className="far fa-bell"/></div> : ''}PUBLISHED ({publishedCount})</a>
                      </li>
                      {renderDesk()}
                      {renderBlocked()}
                    </ul>
                  </div>
                  <div className="right-arrow new-dashboard">
                    <div className="show-for-small">
                      <i className="fas fa-angle-right fa-lg" id="right-arrow"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="large-1 columns show-for-large-up sort-button-large">
              {renderSortButton(2)}
            </div>
          </div>
        </div>
        <div className="small-12 columns cards-container">
          {renderSpinner()}
          {renderArticles()}
          {renderPagination()}
        </div>
      </div>
      {renderInfoWidget('mobile')}
      <div className="small-12 columns">
        {renderBannerAd(bannerAdBottom, 'banner-ad-1')}
      </div>
    </React.Fragment>
  )

}

export default Main;