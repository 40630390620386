import React, { useState } from 'react';

const EditInstitutionalEmail = ({userId, email, renderButton}) => {
  const [instEmail, setInstEmail] = useState(email)
  const handleUnsuccessfulSubmission = () => {
    $('#institutional-email').foundation('reveal', 'close');
    window.Cureus.Flash.alert("Institutional email must be a valid email address.");
  }

  return (
    <div className="reveal-modal medium" id="institutional-email" data-reveal>
      <div className="modal-body">
        <div className="modal-close">
          <a href="#" className="cancel-modal">
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>

        <div className="row">
          <div className="large-12 columns">
            <h4>Institutional Email Address</h4>
            <h6 className="reg">Please enter and save your institutional email address in order to submit an article to Cureus.</h6>
          </div>
        </div>
        <div className="row">
          <div className="large-6 columns">
          <input name="details" type="text" value={instEmail} onChange={ (e) => { setInstEmail(e.target.value) }} />
          </div>
        </div>

        <div className="modal-footer">
          {renderButton({user: { institutional_email: instEmail }, ignoreStep: true, section: "InstitutionalEmailForm"}, `/users/${userId}`, handleUnsuccessfulSubmission )}
        </div>
      </div>
    </div>
  );
};

export default EditInstitutionalEmail;