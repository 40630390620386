import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import { Cureus } from '../../Cureus';
import ConfirmationModal from './ConfirmationModal';
import ChannelCampaignModal from './ChannelCampaignModal';
import { data } from 'jquery';

const EmailRequestsMain = ({fetchEmailRequestsPath}) => {
  const [spinning, setSpinning] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    $('#channel-campaigns-table').DataTable().fnDestroy();
    setSpinning(true)
    $.ajax({
      type: 'GET', 
      dataType: "JSON",
      url: fetchEmailRequestsPath,
      success: function(data) {
        checkRedDot(data.campaigns)
        setSpinning(false)
        setCampaigns(data.campaigns)
        populateTable(data.campaigns)
      }
    });
  }

  const checkRedDot = (campaigns) => {
    let check = []
    for (let i = 0; i < campaigns.length; i++) {
      let campaign = campaigns[i]
      if(!campaign.sent_at) {
        check.push(campaign)
        break
      }
    }
    if(check.length === 0) {
      let element = document.querySelector('.channels-red-dot')
      if(element) {
        element.remove()
      }
    }
  }

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData( [
        row.date_of_request,
        row.channel_name,
        `<a>${row.campaign_title}</a>`,
        row.recipients,
        row.sent_at ? `Sent ${row.sent_at}` : `<a class="button special tiny">Send</a>`,
        row.campaign_id,
        row.channel_id
      ], false)
    )
  }

  const populateTable = (campaigns) => {
    var dt = $("#channel-campaigns-table").DataTable({
      columns: [
        null,
        {width: '200px'},
        {width: '475px'},
        null,
        {class: 'button-cell', width: '150px'},
        null,
        null,
      ],
      language: {
        "searchPlaceholder": "Search...",
        "search": ""
      },
      columnDefs: [
        {targets: [5, 6], visible: false},
      ],
      dom: '<"row"<"large-5 columns"f>>tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
      "rowCallback": function(row, data) {
        $(row.children[4].children[0]).off().on('click', function() {
          openConfirmationModal(data[3], data[1], data[5])
        })
        $(row.children[2].children[0]).off().on('click', function() {
          openCampaignModal(data[5], data[6])
        })
      }
    })
    if (!campaigns) {
      campaigns = []
    }
    dt.api().clear();
    for (var i = 0; i < campaigns.length; i++) {
      let row = campaigns[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[4, 'asc']])
    dt.fnDraw()
    Cureus.SplitButton.init();
  }

  const openCampaignModal = (campaignId, channelId) => {
    Modal.open({
      children: <ChannelCampaignModal 
        campaignId={campaignId}
        channelId={channelId}
        fetchData={fetchData}
      />
    });
  }

  const openConfirmationModal = (recipients, channelName, campaignId) => {
    Modal.open({
			children: <ConfirmationModal
									recipients={recipients}
									channelName={channelName}
                  fetchData={fetchData}
                  campaignId={campaignId} />
		});
  }

  if (spinning) {
    return (
      <React.Fragment>
      <div className='spinner-container'>
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <div className='campaigns-index email-page'>
          <table id="channel-campaigns-table" className='table-wrapper'>
            <thead>
              <tr>
                <th>Date of request</th>
                <th>Channel</th>
                <th>Campaign</th>
                <th># Recipients</th>
                <th>Action</th>
                <th/>
                <th/>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          </div>
        </React.Fragment>
    )
  }
}

export default EmailRequestsMain;