import {Cureus} from '../Cureus';

Cureus.DisableWithATag = {
  enable: function() {
    var tags = document.querySelectorAll('a[data-disable-with]')
    for (var i = 0; i < tags.length; i++){
      tags[i].addEventListener('click', function() {
        this.className += ' disabled';
      });
    }
  }
}