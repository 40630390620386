import React, { useState, useEffect } from 'react';
import ChannelApi from '../../../Cureus/ChannelApi';
import TeamMemberCard from '../MainWidgets/ChannelTeam/TeamMemberCard';
import UpdateButton from '../UpdateButton';
import DeleteButton from '../DeleteButton';


const TeamMemberSidebar = ({slug, handleDeleteWidget, handleUpdateWidget, sidebarWidgets, widgetId}) => {
  const [member, setMember] = useState(null);

  useEffect(() => {
    if(widgetId) {
      let onSuccess = (res) => {
        setMember(res)
      };
      ChannelApi.fetchWidget({widgetId, slug, onSuccess})
    }
  }, [sidebarWidgets])

  const renderActionButtons = () => {
    return (
    <>
      <img className={"action-buttons drag channels"} src="https://public.cureus.com/channel_design/rearrange_widget.png"/>
      <UpdateButton
        handleUpdateWidget={handleUpdateWidget}
        widget={member}
        modalName={"ChannelTeamSidebar"}
        sidebarWidgets={sidebarWidgets}
        slug={slug}
      />
      <DeleteButton
        handleDeleteWidget={handleDeleteWidget}
        widgetId={member.widgetId}
      />
    </>
    )
  }

  if(!member) {
    return <></>
  }

  return (
    <div className="row sidebar-row">
      {handleDeleteWidget && renderActionButtons()}
      <div className="small-12 columns widget channel-team team-member-sidebar">
          <TeamMemberCard member={member} />
      </div>
    </div>
  )
}

export default TeamMemberSidebar;