import React, { useState, useEffect } from 'react';
import {
  HashRouter,
  Switch,
  Route
} from 'react-router-dom';
import Card from './Card';
import SearchBar from './SearchBar';
import Ad from '../Shared/Ad';
import SearchResults from './SearchResults';

const Root = ({ sections, featured, bannerAd, squareAd }) => {

  const [query, setQuery] = useState('');

  useEffect(() => {
    if(typeof(bannerAd) !== 'object') {
      $('#banner-ad').append(bannerAd)
      $('#square-ad').append(squareAd)
    }
  }, []);

  function renderSections(split=null, type) {
    return sections.map(function(card, idx) {
      if(split == null || idx % 2 == split) {
        return <Card
                  key={ `card-${card.type}-${idx}-${type}` }
                  type={ card.type }
                  title={ card.title }
                  body={ card.body }
              />
      }
    })
  }

  function renderSectionsForLarge() {
    return (
      <div className='card-container large-4 large-offset-1 columns show-for-large-up'>
        { renderSections(null, 'large') }
      </div>
    )
  }

  function renderSectionsForSmallMedium() {
    return (
      <div className='card-container hide-for-large-up'>
        <div className='card-column-left'>
          { renderSections(0, 'left') }
        </div>
        <div className='card-column-right'>
          { renderSections(1, 'right') }
          <div className='featured-card-container bottom'>
            <Card
              key={ "featured-card-bottom" }
              type={ featured.type }
              label={ featured.label }
              title={ featured.title }
              body={ featured.body }
            />
          </div>
        </div>
      </div>
    )
  }

  function renderSearchResult() {
    return (
      <div className='cureus-page'>
        <div className='cureus-ad-wrapper page-banner-ad show-for-large-up' >
          {renderBannerAd()}
        </div>
        
        <div className='content-container page'>
          <div className='row nav-header'>
            <div className='page-nav-label large-4 columns'>
              <a href='#!'>The Cureus Guides</a>
              <i className="fas fa-chevron-right sm"/>
              <span className='blue'>Search results</span>
            </div>
            <div className='large-8 columns' style={{padding: 0}}>
              <SearchBar classes={ "page" } doSearch={ doSearch }/>
            </div>
          </div>

          <div className='row page-contents'>
            <div className='cureus-ad-wrapper page-square-ad show-for-large-up' >
              <Ad
                props={ squareAd }
                size={ "square" }
              />
            </div>

            <div className='page-body large-8 columns'>
              <SearchResults query={ query }/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function renderBannerAd() {
    if(typeof(bannerAd) === 'object') {
      return (
        <div className='cureus-ad-wrapper page-banner-ad show-for-large-up' >
          <Ad
            props={ bannerAd }
            size={ "banner" }
          />
        </div>
      )
    } else {
      return (
        <div id="banner-ad"/>
      )
    }
  }

  function renderSquareAd() {
    if(typeof(bannerAd) === 'object') {
      return (
        <div className='cureus-ad-wrapper page-square-ad show-for-large-up' >
          <Ad
            props={ squareAd }
            size={ "square" }
          />
        </div>
      )
    } else {
      return (
        <div id="square-ad"/>
      )
    }
  }

  function doSearch(evt) {
    if (evt.key == 'Enter' || evt.type == 'click') {
      var value = $("#search-input")[0].value;
      window.location.hash = '#!/search';
      setQuery(value);
    }
  }

  function renderRoot() {
    return (
      <div className='cureus-page'>
        <div className='hero-container'>
          <div className='hero'/>
          <div className='hero-content'>
            <div className='large-7 columns'>
              <div className='hero-header'>What do you want to know?</div>
              <SearchBar classes={ "root" } doSearch={ doSearch }/>
            </div>
            <div className='large-4 large-offset-1 columns show-for-large-up'>
              <div className='featured-card-container top'>
                <Card
                  key={ "featured-card-top" }
                  type={ featured.type }
                  label={ featured.label }
                  title={ featured.title }
                  body={ featured.body }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='content-container'>
          <div className='root-contents large-7 columns'>
            <h1>Cureus Guides</h1>
            <p>Whether you’re submitting an article, accepting a peer review invitation or looking to learn more about channels and collections, we’ve got you covered.</p>
            <p><strong>Author Guide:</strong> Learn how our submission and publication process works and familiarize  yourself with our rules and requirements.</p>
            <p><strong>Media Guide:</strong> Here you’ll find detailed instructions for adding figures, tables, videos and interactive models to your article.</p>
            <p><strong>Reviewer Guide:</strong> First time peer reviewing with Cureus? Find out what to expect!</p>
            <p><strong>Academic Channels Guide:</strong> Read a detailed overview of how channels are organized and operated.</p>
            <p><strong>Collection Guest Editor Guide:</strong> Learn the ins and outs of guest editing a Cureus Collection, including how to submit your proposal.</p>
            <div className='cureus-ad-wrapper root-ad show-for-large-up'>
              {renderSquareAd()}
            </div> 
          </div>
          { renderSectionsForLarge() }
          { renderSectionsForSmallMedium() }
        </div>
      </div>
    )
  }

  return (
    <HashRouter hashType={ "hashbang" }>
      <Switch>
        <Route path="/search">
          { renderSearchResult() }
        </Route>
        <Route path="/">
          { renderRoot() }
        </Route>
      </Switch>
    </HashRouter>
  )

}

export default Root;