var createReactClass = require('create-react-class');
import React from 'react';
import EllipsePage from './EllipsePage';

const EllipsePages = createReactClass({
	// Props need to have Current Page, Total Page, and ajax get index method that is bound to pagination page number on click.
	// Index controller action should return kaminari page data.
	// See Cureus.EmailCampaignIndex.Main for great example.
	generatePagination: function () {
		var pages = [];
    for(var i=1; i <= this.props.paginationData.totalPages; i++) {
      pages.push(<EllipsePage
                  pageNumber={i}
                  currentPage={this.props.paginationData.currentPage}
                  handlePageSelect={this.props.handlePageSelect}
                  key={i} 
                  type={"ellipses"}/>
                  )
    }
    if(pages.length == 1) {
      return
    }
    
		return pages;
	},

  pagesLeftOfCurrentPage: function (pages) {
		var currentPageIndex = this.props.paginationData.currentPage - 1;

		if (this.props.paginationData.currentPage - 1 > 5) {
			var leftOfCurrentPage = pages.slice(currentPageIndex - 3, currentPageIndex);
			var firstPages = pages.slice(0, 2);

			firstPages.push(<li className= "unavailable" ><a>...</a></li>);
			firstPages.push(leftOfCurrentPage);
			return firstPages
		}
		else {
			return pages.slice(0, currentPageIndex)
		}
	},

	guillemetComponent: function(pageNumber, character) {
		return <EllipsePage
            pageNumber={pageNumber}
            character={character}
            currentPage={this.props.paginationData.currentPage}
            handlePageSelect={this.props.handlePageSelect}
            key={pageNumber}
            totalPages={this.props.paginationData.totalPages}
            />
	},

  renderNavArrows: function() {
    let leftArrow, rightArrow;
    const currentPage = this.props.paginationData.currentPage;
    const totalPages = this.props.paginationData.totalPages;
    const isLastPage = this.props.paginationData.lastPage;
    const isFirstPage = this.props.paginationData.firstPage;

    // first page
    if(currentPage == 1 && !isLastPage) {
      leftArrow = this.guillemetComponent(this.props.paginationData.currentPage-1, <div className="pagination-arrows disabled"><img className="arrow-image disabled" src="https://public.cureus.com/collections/arrow-left-white-recent.png"/></div>)
      rightArrow = this.guillemetComponent(this.props.paginationData.currentPage+1,<div className="pagination-arrows"><img className="arrow-image" src="https://public.cureus.com/collections/arrow-right-black-recent.png"/></div>)
    }
    //only page
    else if(isFirstPage === isLastPage && isLastPage) {
      leftArrow = this.guillemetComponent(this.props.paginationData.currentPage-1, <div className="pagination-arrows disabled"><img className="arrow-image disabled" src="https://public.cureus.com/collections/arrow-left-white-recent.png"/></div>)
      rightArrow = this.guillemetComponent(this.props.paginationData.currentPage+1,<div className="pagination-arrows disabled"><img className="arrow-image disabled" src="https://public.cureus.com/collections/arrow-right-white-recent.png"/></div>)
    }
    //last page
    else if(currentPage == totalPages) {
      leftArrow = this.guillemetComponent(this.props.paginationData.currentPage-1, <div className="pagination-arrows"><img className="arrow-image" src="https://public.cureus.com/collections/arrow-left-black-recent.png"/></div>)
      rightArrow = this.guillemetComponent(this.props.paginationData.currentPage+1,<div className="pagination-arrows"><img className="arrow-image" src="https://public.cureus.com/collections/arrow-right-white-recent.png"/></div>)
    }
    else {
      leftArrow = this.guillemetComponent(this.props.paginationData.currentPage-1, <div className="pagination-arrows"><img className="arrow-image" src="https://public.cureus.com/collections/arrow-left-black-recent.png"/></div>)
      rightArrow = this.guillemetComponent(this.props.paginationData.currentPage+1,<div className="pagination-arrows disabled"><img className="arrow-image disabled" src="https://public.cureus.com/collections/arrow-right-black-recent.png"/></div>)
    }
    var arrows = [];
    arrows.push(leftArrow, rightArrow)
    return arrows;
  },

	render: function() {

    return (
      <ul className="pagination-ellipses">
        <div className='ellipses-holder'>
          {this.generatePagination()}
        </div>
        <div className='arrow-holder'>
          {this.renderNavArrows()}
        </div>
      </ul>
    )
	}
})

export default EllipsePages;