import React, { Component } from 'react';
import Article from './Article';
import ArticleTabs from './ArticleTabs';
class Selection extends Component {
  state = {
    currArticle: 1
  }

  reviewArticle = () => {
    let article_id =  this.props.articles[this.state.currArticle - 1].id;
    let type = this.props.articles[this.state.currArticle - 1].type;
    $.ajax({
      type: 'POST',
      url: this.props.acceptancePath,
      data: {article_id: article_id},
      success: (data) => {
        this.props.fireAmplitude('onDemandAccept', {articleId: article_id, type: type})
        window.location.replace(data.path)
      },
      error: function() {
        window.location.reload()
      }
    });
  }

  handleSelection = (e) => {
    this.setState({currArticle: e.target.name})
  }

  renderArticles = () => {
    if(this.props.articles.length === 1) {
      return <Article article={this.props.articles[0]}/>
    } else {
      return <ArticleTabs 
                articles={this.props.articles}
                handleSelection={this.handleSelection}
              />  
    }
  }

  render() {
    return (
      <div className="modal-content">
        <h4>Please select an article to review.</h4>
        <br/>
        {this.renderArticles()}
        <div className="modal-footer">
          <a className='button secondary left' onClick={this.props.handleBack}>Back</a>
          <a className="button primary success" onClick={this.reviewArticle}>Review Article {(this.props.articles.length === 1) ? '' : this.state.currArticle}</a>
        </div>
      </div>
    )
  }
}

export default Selection;