var createReactClass = require('create-react-class');
import React from 'react';

const DeleteHeroModal = createReactClass({
  getInitialState: function() {
    return {
      inputText: ""
    }
  },

  handleChange: function(e) {
    this.setState({inputText: e.target.value});
  },

  handleDelete: function() {
    var self = this;
    $.ajax(self.props.destroyPath, {
      type: "DELETE",
      success: function(res) {
        self.close();
        self.props.onDelete(res.heros);
      }
    });
  },

  close: function() {
    $(this.refs.modal).foundation("reveal", "close");
  },

  enterPressed: function(event) {
    var code = event.keyCode || event.which;
    if (code === 13 && this.state.inputText == "DELETE") {
      this.handleDelete()
    }
  },

  render: function() {
    if(this.state.inputText == "DELETE") {
      var submitButton = <a className='button primary' onClick={this.handleDelete}>Delete</a>
    } else {
      var submitButton = <a className='button primary disabled'>Delete</a>
    }
    return (
      <div ref='modal' className="cureus-custom-multi-select-modal reveal-modal middling" data-reveal>
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <div className='modal-form'>
              <h5>
                Delete Hero
              </h5>

              <p>
                This is an irreversible action! Are you sure you want to do this? Please type 'DELETE' below to continue.
              </p>

              <form>
                <input type='text' onChange={this.handleChange} onKeyPress={this.enterPressed}/>
              </form>

              <div className='modal-footer'>
                <a className='cancel-modal button secondary'>Cancel</a>
                {submitButton}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default DeleteHeroModal;
