import { useEffect, useRef } from 'react';

export function useFirstMountState() {
  const isFirstMount = useRef(true);

  useEffect(function firstMount() {
    isFirstMount.current = false;
  }, []);

  return isFirstMount.current;
}
