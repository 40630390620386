import React, {useState} from 'react';

const SpecialtyCard = ({specialty}) => {
  return (
    <div className='large-4 medium-6 small-12 columns specialty-card'>
      <img src={`https://public.cureus.com/new-nav-bar/specialties-tab/${specialty.url}.png`}/>
      <p>{specialty.name}</p>
      <div className="hovered-content">
        <a className='button large browse-button' href={`/${specialty.url}`} target="_blank">Browse</a>
      </div>
    </div>
  )
}

export default SpecialtyCard;