import React from 'react';
import CompletedRow from './CompletedRow';
const ApprovalMatches = ({data, editorView}) => {
  return (
    <div className="row-container">
      <div className="completed-container">
        <div className="row">
          <div className="small-12 columns">
            <div className="small-1 columns icon-container">
              <span>
                <i class="fas fa-clipboard-check background"/>
              </span>
            </div>
          </div>
        </div>
        <CompletedRow 
          label="Lifetime Matches"
          value={data.total}
          editorView={editorView}
          progress={false}
        />
        <CompletedRow 
          label="Lifetime Matches Claimed"
          value={data.claimed}
          progress={(data.claimed/data.total) * 100}
          editorView={editorView}
        />
        <CompletedRow 
          label="Matches Currently Pending Assignment"
          value={data.currQueue}
          editorView={editorView}
          progress={false}
        />
      </div>
    </div>
  )
}

export default ApprovalMatches;