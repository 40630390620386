var createReactClass = require('create-react-class');
import React from 'react';
import { Cureus } from '../../../Cureus';

const Row = createReactClass({
  componentDidMount: function (){
    Cureus.SplitButton.init();
    $(document).foundation('tooltip', 'reflow');
  },

  dragHandle: function() {
    return(
      <td className='drag-handle hide-for-small'>
        <table className="dots coauthors">
          <tbody>
            <tr>
              <td className="dot coauthors"></td>
              <td></td>
              <td className="dot coauthors"></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="dot coauthors"></td>
              <td></td>
              <td className="dot coauthors"></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="dot coauthors"></td>
              <td></td>
              <td className="dot coauthors"></td>
            </tr>
          </tbody>
        </table>
      </td>
    )
  },

  integerToOrdinal: function(i) {
    var ones_digit = i % 10
    var tens_ones_digits = i % 100
    if (ones_digit == 1 && tens_ones_digits != 11) {
      return i + 'st'
    }
    if (ones_digit == 2 && tens_ones_digits != 12) {
      return i + 'nd'
    }
    if (ones_digit == 3 && tens_ones_digits != 13) {
      return i + 'rd'
    }
    return i + 'th'
  },

  positionColumn: function() {
    return (
      <td className="hide-small" style={{fontWeight: 'bold'}}> {this.integerToOrdinal(this.props.authorship.position)} </td>
    )
  },

  nameColumn: function() {
    return (
      <td className="text-left truncate">{this.props.authorship.user.full_name}</td> 
    )
  },

  correspondingColumn: function() {
    let isChecked = this.props.authorship.correspondent
    return (
      <td className="centered">
        <input className="table-correspondent" type='radio' value='' checked={isChecked} onClick={this.handleCorrespondent}/>
      </td>
    )
  },

  handleCorrespondent: function() {
    var self = this;
    self.props.handleLoading()
    $.ajax({
      type: 'put',
      url: self.props.authorship.corresponding_author_path,
      data: {authorable_type: 'Article', authorable_id: self.props.articleId},
      dataType: 'json',
      success: function(res){
        self.props.updateAuthorships(res.userships)
      },
      error: function(res) {
        self.props.updateAuthorships(self.props.userships)
        Cureus.Flash.alert('There was an issue, please try again.')
      }
    })
  },

  authorDetails: function() {
    return (
      <td className="author-details">
        <span className="bolded">{this.props.authorship.user.full_name}</span>
        &nbsp;
        &nbsp;
        {this.renderCorrespondent()}
        <div className="email">
          <a href={ "mailto:" + this.props.authorship.user.email }>{this.props.authorship.user.email}</a>
        </div>
        <span>{this.props.authorship.specialty}</span>
        {this.renderAffiliations()}
    </td>
    )
  },

  renderAffiliations: function() {
    var self = this;
    return self.props.authorship.article_affiliations.map(function(aff) {
      return (
        <div className="affiliations">
          <span>{aff.name.length > 70 ? aff.name.substring(0, 70) + '...' : aff.name}</span>
        </div>
      )
    })
  },

  renderCorrespondent: function() {
    if(this.props.authorship.correspondent) {
      return (
        <span><i className='far fa-envelope'/>&nbsp;<a className="corresponding" href={this.props.authorship.user.path} target="_blank">&nbsp; &nbsp;Corresponding Author</a> </span>
      )
    }
  },

  confirmedColumn: function() {
    if (this.props.articleState !== 'approved' && this.props.articleState !== 'published' && this.props.articleState !== 'editor_approval' && !this.props.deferredForPreferred) {
      return (
        <td className="centered confirmed-column">
          <a className="button secondary tiny no-margin" href={this.props.editAuthorsPath}><i className='fa fa-pencil-alt hide-for-small'/> &nbsp;Edit</a>
        </td>
      )
    }
  },

  sendReminder: function() {
    var self = this;
    $.ajax({
      type: 'put',
      url: self.props.authorship.remind_author_path,
      dataType: 'json',
      success: function(res){
        $(window).scrollTop(0);
        Cureus.Flash.success('We sent an email to your co-author at ' + self.props.authorship.user.email);
      }
    });
  },

  statusColumn: function() {
    if(this.props.authorship.user.is_confirmed) {
      return (
        <td className="text-left">
          <div>
            <span><i class="far fa-check-circle hide-for-small"/> &nbsp;Confirmed</span>
          </div>
        </td>
      )
    } else {
      return (
        <td className="text-left">
          <div>
            <span><i class="fas fa-sync hide-for-small"/> &nbsp;Unconfirmed</span>
          </div>
          <div className="remind-container">
            <a href="#" onClick={this.sendReminder}><i className='far fa-envelope'/> &nbsp; Remind</a>
          </div>
        </td>
      )
    }
  },

  render: function() {
    var self = this
    let opts = { 
      id: "authorship-" + this.props.authorship.id, 
      className: "no-hover-row" 
    }
    if (!this.props.authorship.user.is_confirmed) { 
      opts.className += " has-tip tip-left"
      opts['data-tooltip'] = ""
      opts.title = this.props.authorship.user.full_name + " has not confirmed their account. Click Remind and we'll send a reminder email on your behalf."
    }
    return (
      <tr id={'authorship-' + self.props.authorship.id} {...opts}>
        {this.dragHandle()}
        {this.positionColumn()}
        {this.correspondingColumn()}
        {this.authorDetails()}
        {this.statusColumn()}
        {this.confirmedColumn()}
      </tr>
    )
  }
}) 

export default Row;