import React, { useState, useEffect } from 'react';
import { Cureus } from '../../../Cureus';
import { HashLink } from 'react-router-hash-link';

const MapTable = ({selector, data, cssClass}) => {

  useEffect(() => {
    populateTable()
  }, [])

  const populateTable = () => {
    let table = document.querySelector(selector);
    if (data.length === 0) {
      let row = table.insertRow();
      var cell = row.insertCell()
      cell.colSpan = 4;
      cell.style = "color: #777777; font-size: 0.875rem;"
      cell.appendChild(document.createTextNode('No data yet.'))
    }
    else {
      data.forEach((record, idx) => {
        let row = table.insertRow();
        row.insertCell().appendChild(document.createTextNode(record.rank))
        row.insertCell().appendChild(document.createTextNode(record.code || record.state))
        row.insertCell().appendChild(document.createTextNode(parseInt(record.views).toLocaleString('en-US')))
        row.insertCell().appendChild(document.createTextNode(record.pct))
      })
    }
    new Cureus.ShowMoreTable(`#${cssClass}`, 3)
  }

  return (
    <div className="row">
      <div className="small-12 small-centered columns">
        <table className={cssClass} id={cssClass}>
          <thead>
            <tr>
              <td>Rank</td>
              <td>Country</td>
              <td>Views</td>
              <td>Percentage</td>
            </tr>
          </thead>
          <tbody/>
          <caption>
            <div id="expand-trigger-wrap" className="content-toggler">
              <HashLink
                  to={(cssClass === "us-article-map-table" ? "#states" : "#countries")}
                  replace
                >
                Show all <i className="fa fa-angle-down"/>
              </HashLink>
              <HashLink
                  to={(cssClass === "us-article-map-table" ? "#states" : "#countries")}
                  replace
                  style={{display: "none"}}
                >
                Show less <i className="fa fa-angle-up"/>
              </HashLink>
            </div>
          </caption>
        </table>
      </div>
    </div>
  )
}

export default MapTable;