var createReactClass = require('create-react-class');
import React from 'react';
import InstitutionAutoSuggester from '../UserPicker/InstitutionAutoSuggester';
import DepartmentAutoSuggester from '../UserPicker/DepartmentAutoSuggester';
import { Cureus } from '../../Cureus';

const AffiliationsRow = createReactClass({
  getInitialState: function() {
    return({
      hasNoAffiliation: this.props.hasNoAffiliation,
      hasNoCity: null,
      hasNoCountry: null,
    })
  },

  componentDidMount: function() {
    if (this.state.hasNoAffiliation) {
      this.setState({
        hasNoCity: true,
        hasNoCountry: true
      })
    }
  },

  renderSelectTag: function() {
    return (
      <select name="country" data-index={this.props.index}  onChange={(e) => this.props.handleChange(e)} defaultValue={this.props.affiliationObject.country}>
        <option>Select Country</option>
        { this.renderSelectTagOptions() }
      </select>
    )
  },

  renderSelectTagOptions: function() {
    var self = this;
    return self.props.countryOptions.map( function(e) {
      return <option key={e.value} value={e.value}>{e.label}</option>
    })
  },

  renderRemoveAffiliationIcon: function() {
    if (this.props.totalAffiliations > 1){
      return (
        <div className="large-6 medium-6 small-6 columns">
          <div className="right">
            <a className="button tiny secondary" data-remove-multi-add="true" href="javascript:void(0)" onClick={this.removeAffiliationRow}><i className="far fa-trash-alt"></i>
            </a>
          </div>
        </div>
      )
    }
  },

  isPrimaryAffiliation: function() {
    return (this.props.affiliationObject.isPrimary || this.props.totalAffiliations == 1) ? "checked" : false
  },

  removeAffiliationRow: function() {
    this.props.removeAffiliation(this.props.affiliationObject)
  },

  institutionName: function() {
    let name = this.props.affiliationObject.institutionName;
    if (name === "undefined") {
      return ''
    } else {
      return name === 'Not Selected' ? '' : name;
    }
  },

  departmentName: function() {
    let name = this.props.affiliationObject.departmentName;
    if (name === "undefined") {
      return ''
    } else {
      return name === 'Not Selected' ? '' : name;
    }
  },

  handleDisable: function() {
    if(!this.props.affiliationObject.institutionName) {
      return true
    } else {
      return false
    }
  },

  renderRecommendedInstitution: function() {
    let recommendedInstitutionName = this.props.affiliationObject.recommendedInstitutionName;
    if (recommendedInstitutionName && recommendedInstitutionName !== this.institutionName()) {
      return (
        <div className="recommended-inst">
          <strong>Did you mean?</strong> <a onClick={() => this.props.acceptRecommendedInstitution(this.props.index)}>{recommendedInstitutionName}</a>
        </div>
      )
    }
  },

  renderDepartmentPadding: function() {
    let recommendedInstitutionName = this.props.affiliationObject.recommendedInstitutionName;
    if (recommendedInstitutionName && recommendedInstitutionName !== this.institutionName()) {
      return (
        <div className="recommended-inst">
          &nbsp;
        </div>
      )
    }
  },

  render: function() {
    return (
        <div>
          <div className="row">
            <div className="large-6 medium-6 columns">
              <label htmlFor={"user_affiliations_" + this.props.index + "_institution_name"}><br/>Academic institution/Hospital*</label>
              <InstitutionAutoSuggester autosuggestUrl={this.props.autocompleteUrl} >
                {this.renderRecommendedInstitution()}
                <input onChange={(e) => this.props.handleChange(e)} type="text" data-index={this.props.index} name="institutionName" value={this.institutionName()} data-autocomplete-url={this.props.autocompleteUrl} className="ui-autocomplete-input"/>
              </InstitutionAutoSuggester>
            </div>
            <DepartmentAutoSuggester deptAutocompletePath={this.props.deptAutocompletePath} institutionName={this.props.affiliationObject.institutionName}>
              <div className="large-6 medium-6 columns">
                <label><br/>Department/Specialization*</label>
                {this.renderDepartmentPadding()}
                <input onChange={(e) => this.props.handleChange(e)} type="text" name="departmentName" data-index={this.props.index} value={this.departmentName()} style={{zIndex: 9999999}} disabled={this.handleDisable()} />
              </div>
            </DepartmentAutoSuggester>
          </div>
          <div className="row">
            <div className="large-6 medium-6 columns">
              <label className="inline-label">Country*</label>
              { this.renderSelectTag(this.props.affiliationObject) }
            </div>
            <div className="large-6 medium-6 columns">
              <label className="inline-label">City*</label>
              <input onChange={(e) => this.props.handleChange(e)} type="text" name="city" data-index={this.props.index} defaultValue={this.props.affiliationObject.city} onChange={(e) => this.props.handleChange(e)}/>
            </div>
          </div>
          <div className="row affiliation-actions">
            <div className="large-6 medium-6 small-6 columns">
            <input id={this.props.index} type="radio" checked={this.isPrimaryAffiliation()} onChange={this.props.handleOptionChange}/>
              <label className="inline">Make this my primary affiliation</label>
            </div>
            {this.renderRemoveAffiliationIcon()}
          </div>
        </div>
    )
  }
})

export default AffiliationsRow;