import {Cureus, showSaveNotification, saveFailedBanner} from '../Cureus';

Cureus.InstaSaver = function(selector) {
  var $saved = $(selector);
  $saved.on('click', function(e){
    if($('#flash')){$('#flash').remove()}
    e.target.addClass("disabled")
    var blockingSave = $('#blocking-saver')
    blockingSave.addClass("disabled")
    e.preventDefault();
    var form = e.target.closest("form");
    $.ajax({
      dataType: "json",
      type: 'put',
      processData: false,
      contentType: false,
      url: form.action,
      data: new FormData(form),
      success: function(res) {
        if(res.redirect) {
          window.location.reload()
        } else {
          showSaveNotification(e.target);
          window.setTimeout(function(){
            blockingSave.removeClass("disabled")
          }, 900)
        }
      },
      error: function() {
        saveFailedBanner()
      }
    });
  })
}
