var createReactClass = require('create-react-class');
import React from 'react';
import Author from './Author';
import Reviewer from './Reviewer';

const Main = createReactClass({
  getInitialState: function() {
    return {
      authors: [],
      reviewers: [],
      selected: [],
      revPage: 0,
      authorPage: 0
    }
  },
  componentDidMount: function() {
    this.fetchUsers()
  },

  fetchUsers: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      data: {authorPage: this.state.authorPage, revPage: this.state.revPage},
      url: this.props.usersPath,
      success: function(data) {
        self.setState({authors: data.authors, reviewers: data.reviewers}, function() {
          self.handleCheckDisable();
        })
      }
    });
  },

  loadMore: function(e) {
    var newPage = this.state[e.target.name] + 1
    this.setState({[e.target.name]: newPage}, function() {
      this.fetchUsers()
    })
  },

  handleCheckDisable: function() {
    if(this.state.selected.length === 8) {
      $("input:checkbox:not(:checked)").attr('disabled', true)
    } else {
      $("input:checkbox").attr('disabled', false)
    }
  },

  handleSelect: function(e) {
    var id = e.target.name
    var selected = this.state.selected
    if(e.target.checked) {
      var toAdd = this.state.authors.concat(this.state.reviewers).filter(function(author) {
        return author.id === parseInt(id)
      })
      selected.push(toAdd[0])
    } else {
      selected = selected.filter(function(author) {
        return author.id !== parseInt(id)
      })
    }
    this.setState({selected: selected}, function() {
      this.handleCheckDisable()
    })
  },

  handleSave: function(){
    var self = this;
    $.ajax({
      type: "POST",
      url: self.props.savePath,
      data: {featured_users: JSON.stringify(this.state.selected)},
      success: function(data) {
        window.location.reload()
      },
      error: function() {
        alert("Oops, Mail no get sent.");
      }
    });
  },

  renderAuthors: function() {
    var self = this;
    let map = []
    for (let i = 0; i < self.state.authors.length; i+=2) {
      map.push(
        <div className="row featured-user-row" data-equalizer-mq="small-up" data-equalizer="featured-user-row">
          <Author 
            key={self.state.authors[i].id}
            author={self.state.authors[i]}
            handleSelect={self.handleSelect}
          />
          <Author 
            key={self.state.authors[i+1].id}
            author={self.state.authors[i+1]}
            handleSelect={self.handleSelect}
          />
        </div>
      )
    }
    return map
  },

  renderReviewers: function() {
    var self = this;
    let map = []
    for (let i = 0; i < self.state.reviewers.length; i+=2) {
      map.push(
        <div className="row featured-user-row" data-equalizer-mq="small-up" data-equalizer="featured-user-row">
          <Reviewer 
            key={self.state.reviewers[i].id}
            reviewer={self.state.reviewers[i]}
            handleSelect={self.handleSelect}
          />
          {self.state.reviewers.length != i+1 &&
            <Reviewer 
              key={self.state.reviewers[i+1].id}
              reviewer={self.state.reviewers[i+1]}
              handleSelect={self.handleSelect}
            />          
          }
        </div>
      )
    }
    return map
  },

  render: function(){
    return (
      <div className="diversity-container small-12 columns">
        <div>
          <h3>
            Featured Authors &#38; Reviewers
          </h3>
          <p>
            Select eight diverse authors and reviewers to feature on the homepage. (The ideal ratio is 6 authors and 2 reviewers.)
          </p>
        </div>
        <div className="row authors">
          <div className="small-12 columns">
            <h6>Authors:</h6>
            {this.renderAuthors()}
          </div>
          <div className="row">
            <div className="small-12 columns">
              <a className="button primary load" name="authorPage" onClick={this.loadMore}>LOAD MORE</a>
            </div>
          </div>
        </div>
        <div className="row reviewers">
          <h6>Reviewers:</h6>
          {this.renderReviewers()}
          <div className="row">
            <div className="small-12 columns">
              <a className="button primary load" name="revPage" onClick={this.loadMore}>LOAD MORE</a>
            </div>
          </div>
        </div>
        <div className="row">
          <a className={"button primary save-updates " + (this.state.selected.length === 8 ? '' : 'disabled')} onClick={this.handleSave}>Save Updates</a>
        </div>
      </div>
    )
  }
})

export default Main;