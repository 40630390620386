var createReactClass = require('create-react-class');
import React from 'react';
import EditMessageModal from './EditMessageModal';
import ConfirmationModal from './ConfirmationModal';
import Modal from '../Modal';

const Row = createReactClass({
  componentWillMount: function (){
    this.setState({ message: this.props.message })
  },

  handleEditClick: function (evt){

    Modal.open({
      children: <EditMessageModal
                  message={this.state.message.text}
                  handleUpdateMessage={this.handleUpdateMessage} />
    });
  },

  handleUpdateMessage: function (newMessage){
    var self = this;
    $.ajax({
      type: "put",
      dataType: "json",
      url: this.props.message.updatePath,
      data: {
        message: {
          text: newMessage
        }
      }
    })
      .done(function (data, textStatus, jqXHR){ // handle success
        var message = self.state.message;
        message.text = newMessage;
        self.setState({isEditing: false}, function(){ Modal.close() });
      });
  },

  handleClickDelete: function (evt){
    Modal.open({
      children: <ConfirmationModal
                  handleSubmit={ this.props.onDelete }
                  message={ this.props.message } />
    })
  },

  formattedText: function() {
    if(this.state.message.names) {
      return this.state.message.text + this.state.message.names
    } else {
      return this.state.message.text
    }
  },

  renderTextCell: function (){
    return (
      <td dangerouslySetInnerHTML={{__html: this.formattedText()}}/>
    )
  },

  renderActionsButton: function (){
    return (
        <a onClick={this.handleEditClick}>
          <i className="fa fa-edit"/>
        </a>
    )
  },

  render: function (){
    return (
      <tr>
        <td>
          <div className="nowrap">{ this.state.message.date }</div>
          <div className="nowrap">{ this.state.message.time }</div>
        </td>
        <td>
          <span id={ "message-initials-" + this.state.message.id} className="message-initials has-tip tip-top" data-tooltip={ "message-initials-" + this.state.message.id} title={this.state.message.adminName}>{ this.state.message.adminInitials }</span>
        </td>
        { this.renderTextCell() }
        <td>{ this.renderActionsButton() }</td>
        <td><a onClick={this.handleClickDelete}><i className="far fa-trash-alt"/></a></td>
      </tr>
    )
  }
})

export default Row;
