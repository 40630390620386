import React from 'react';

const BusinessModel = () => {
  return (
    <React.Fragment>
      <h1>Business Model</h1>

      <p>Cureus’ business model is unique among academic journals. As an Open Access publisher Cureus makes all content freely available to all readers worldwide immediately upon publication, ensuring maximum dissemination of articles, posters and abstracts. Readers can view all online content without registering for an account.</p>

      <h2>Author Fees</h2>
      <p>Notably, there are NO article processing charges (APCs) required to publish in Cureus. However authors must adhere to our rigorous formatting and language requirements; approximately one-third (31%) of all authors comply with this standard and qualify for completely free publication. Review articles and other articles that exceed our author or reference limits or contain too many formatting and language errors are required to purchase Cureus’ Preferred Editing service before entering peer review. Pricing is based on the following criteria:</p>
      <ul className='indented-for-medium-up'>
        <li className='check'>
          Formatting and language errors
        </li>
        <li className='check'>
          Article length
        </li>
        <li className='check'>
          Author count
        </li>
        <li className='check'>
          Reference count
        </li>
      </ul>

      <p>The greater the amount of editing work required, the higher the price of Preferred Editing. Author fees do not influence editorial decision making. For more information on our Preferred Editing service, including a price estimation calculator, please refer to our <a href='/cureus_editing_services' target='_blank'>Preferred Editing page.</a> Also available to authors are optional “a la carte” services, <a href='/article_promotion' target='_blank'>Article Promotion</a> and Expedited PMC Indexing, which allows authors to bypass the traditional one-month embargo period prior to article indexing in PubMed Central.</p>

      <h2>Academic Channel Subscriptions</h2>
      <p>Cureus offers an annual subscription-based publishing service to universities and medical societies/groups. Bypassing the need for our Preferred Editing service, Cureus academic channels serve as a branded showcase for organizations, their members and their peer-reviewed articles. <a href='/channels' target='_blank'>Click here to learn more about academic channels.</a></p>

      <h2>Publishing Competition Sponsorships</h2>
      <p>Organizations such as medical nonprofits or medical device or pharmaceutical companies can sponsor a publishing competition on a related topic to generate broad awareness and/or deliver relevant, peer-reviewed clinical experiences directly to potential customers. Article selection, editorial review, peer review and publication are strictly independent of the sponsoring company. <a href='/competitions' target='_blank'>Click here to learn more about publishing competitions.</a></p>

      <h2>Advertising</h2>
      <p>Cureus has partnered with several ad serving partners to serve targeted ads from healthcare companies, institutions and other related companies in order to continue providing an Open Access reading experience for all readers. For more information please go to our <a href='/advertising' target='_blank'>Advertising Policy page.</a></p>
  </React.Fragment>
  )
}

export default BusinessModel;