import React, {useState, useEffect} from 'react';
import moment from 'moment';

const RejectedApprovedTable = ({applications, activeTab}) => {

  useEffect(() => {
    populateTable(applications);
  }, [applications])
  

  const mapColumns = (dt, row) => {
    return (
      dt.fnAddData([
        row.created_at,
        row.updated_at,
        `<a class='column-hoverable' href='/admin/users/${row.user_id}'>${row.submitter}</a>`,
        row.position,
        row.specialty,
        `<a class='column-hoverable' id=${row.id} href='/admin/collection_applications/${row.id}'>${row.title}</a>`
      ], false)
    )
  }

  const setColumns = () => {
    return [
      {width: 'centered'},
      {class: 'centered'},
      {class: 'centered'},
      {class: 'centered'},
      {class: 'centered'},
      {class: 'centered'}
    ]
  }

  const populateTable = (applicationsData) => {
    var dt = $("#collections-table").DataTable({
      destroy: true,
      pageLength: 20,
      lengthMenu: [20, 50, 100],
      columns: setColumns(),
      columnDefs: [
        {
          // when displaying, show date in MM-DD-YYYY format, when sorting it will sort by ISO date
          targets: [0, 1],
          render: (date, type, row) => {
            return (type === 'display') ? date : moment(date, "MM-DD-YYYY").toISOString();
          }
        }
      ],
      dom: '<"row"<"large-12 columns search-filters"f>>tr<"row"<"large-8 columns pagination-details" <"pull-left"p><"pull-left showing-info"i>><"large-4 columns table-length-container"<"text-right"l>>>',
      "fnDrawCallback": function(oSettings) {
        if (oSettings.fnRecordsDisplay() == 0) {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
          $(oSettings.nTableWrapper).find('.dataTables_length').hide();
          $(oSettings.nTableWrapper).find('.dataTables_info').hide();
        } else if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        } else {
          $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
          $(oSettings.nTableWrapper).find('.dataTables_length').show();
          $(oSettings.nTableWrapper).find('.dataTables_info').show();
        }
      },
    })
    
    dt.api().clear();
    for (var i = 0; i < applicationsData.length; i++) {
      let row = applicationsData[i];
      mapColumns(dt, row)
    }
    dt.fnSort([[1, 'desc']])
    dt.fnDraw()
  }

  return (
    <div className='row'>
      <div className='small-12 columns collection-table-section'>
        <table id="collections-table">
          <thead>
            <tr>
              <th>Submission Date</th>
              <th>{activeTab === 'rejected' ? "Rejection Date" : "Approval Date"}</th>
              <th>Submitter</th>
              <th>Qualifications</th>
              <th>Specialty</th>
              <th>Title</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>
  )
}

export default RejectedApprovedTable;