import React from 'react';

const Card = ({channel}) => {
  return (
      <div className="small-12 medium-6 large-4 channel-card">
        <img src={channel.logo_url}/>
        <p>{channel.title}</p>
        <div className='hovered-content'>
          <a class="button large learn-more-button" href={channel.channel_path}>LEARN MORE</a>
        </div>
      </div>
  )
}

export default Card;