Cureus.MediaLibrary.AttachmentUploaderMixin = {
  getInitialState: function() {
    return {
      isUploading: false,
      isAnimating: false,
      errorMessage: null
    }
  },

  handleFileSelect: function() {
    this.setState({errorMessage: null});
    this.setState({isUploading: true});
  },

  handleProgress: function(bytesReceived, bytesExpected) {
    var self = this;
    var $progressBar = $(".progressBar");
    var percentComplete = (bytesReceived * 100) / bytesExpected;

    self.setState({isAnimating: true});
    $progressBar.animate({width: percentComplete + "%"}, 3000, function() {
      self.setState({isAnimating: false});
    });
  },

  handleSuccess: function(assembly) {
    var self = this;

    setTimeout(function() {
      if(!self.state.isAnimating) {
        self.updateAttachment(assembly);
      } else {
        self.handleSuccess(assembly);
      }
    }, 500);
  },

  handleError: function(error) {
    if(error.error == "AUTH_EXPIRED") {
      this.setErrorMessage("Please refresh the page, and try uploading again.");
    } else {
      this.setErrorMessage(error.message);
    }
    this.setState({isUploading: false});
  },

  setErrorMessage: function(message) {
    this.setState({errorMessage: message});
  },

  handleCloseErrorBox: function() {
    this.setState({errorMessage: null});
  },

  updateAttachment: function(assembly) {
    var self = this;

    var uploadedFile = assembly.uploads[0];
    var fileName = assembly.assembly_id + "-" + uploadedFile.name;
    var width = uploadedFile.meta.width;
    var height = uploadedFile.meta.height;
    $.ajax({
      type: "PUT",
      url: self.props.attachment.update_url,
      data: {
        attachment: {
          file: fileName,
          type: "Figure",
          height: height,
          width: width
        }
      },
      success: function(data) {
        self.handleUploadComplete(data);
        self.setState({isUploading: false});
      },
      error: function() {
        self.setState({errorMessage: "Error uploading file."});
      }
    });
  }
}
