export var getIndex = function(options) {
  $.ajax({
    type: 'get',
    url: options.endpointUrl,
    data: options.params,
    success: options.success,
    error: function(res) { console.log(res) }
  }).always(function() {
    if (options.always) {
      options.always()
    }
  })
}

export var putUpdate = function(options) {
  $.ajax({
    type: "PUT",
    dataType: "json",
    url: options.endpointUrl + "/" + options.id,
    data:  options.params,
    success: options.success,
    error: options.error
  }).always(function() {
    if (options.always) {
      options.always()
    }
  });
}

export var getSearch = function(options) {
  $.ajax({
    type: "GET",
    dataType: "json",
    url: options.endpointUrl + '/' + encodeURIComponent(options.email),
    data: options.params,
    success: options.success,
    error: options.error
  }).always(function() {
    if (options.always) {
      options.always()
    }
  });
}

export var postCreate = function(options) {
  $.ajax({
    type: "POST",
    dataType: "json",
    url: options.endpointUrl,
    data:  options.params,
    success: options.success,
    error: options.error
  }).always(function() {
    if (options.always) {
      options.always();
    }
  });
}

export var deleteDestroy = function(options) {
  $.ajax({
    type: "DELETE",
    dataType: "json",
    url: options.endpointUrl + '/' + options.id,
    data: options.params,
    success: options.success
  }).always(function() {
    if (options.always) {
      options.always()
    }
  });
}

export var putUpdatePosition = function(options) {
  //Shitty but needed until we can refactor
  var params = options.usership;
  params.authorship = {
    id: options.usership.id,
    position: options.position
  }
  params.sort_order = true;

  $.ajax({
    type: "PUT",
    dataType: "json",
    url: options.endpointUrl + '/' + options.usership.id,
    data: Object.assign(params, options.defaultParams),
    success: options.success
  }).always(function() {
    if (options.always) {
      options.always()
    }
  });
}

