var createReactClass = require('create-react-class');
import React from 'react';
import Row from './Row';
import { Cureus } from '../../Cureus';
import Modal from '../Modal';
import AuthorsAndReviewersBanModal from '../Shared/AuthorsAndReviewersBanModal';

const Main = createReactClass({
  getInitialState: function() {
    return {
      reviewerships: null,
      currentUser: null,
      checked: [],
      disabled: 'disabled',
      columnWidth: '',
      checkedAll: false,
      hasOldStyleMessage: false
    }
  },

  componentDidMount: function (){
    if(this.props.canReject) {
      this.setState({columnWidth: '9%'})
    } else {
      this.setState({columnWidth: '11%'})
    }
    this.fetchReviewerships()
  },

  componentDidUpdate: function() {
    $('.question-mark-icon').foundation('reflow')
  },

  renderReviewers: function() {
    var self = this;
    return self.state.reviewerships.map(function(reviewer) {
      return <Row
        key={reviewer.id}
        reviewer={reviewer}
        articleState={self.props.articleState}
        updateCheckedState={self.updateCheckedState}
        canReject={self.props.canReject}
        articleId={self.props.articleId}
        fetchReviewerships={self.fetchReviewerships}
        completedReviewershipAttributes={reviewer.completedReviewershipAttributes}
        checkedAll={self.state.checkedAll}
        status={reviewer.status}
        hasOldStyleMessage={self.state.hasOldStyleMessage}
        />
    })
  },

  fetchReviewerships: function() {
    var self = this;
    $.ajax({
      type: 'GET',
      url: self.props.reviewershipsPath,
      dataType: 'json',
      success: function(res) {
        self.setState({
          reviewerships: res.reviewerships_list,
          hasOldStyleMessage: self.hasOldStyleMessage(res.reviewerships_list)
        })
      }
    });
  },

  hasOldStyleMessage: function(reviewerships) {
    return reviewerships.some(reviewership => 
      reviewership.completedReviewerEvents.some(event => event.message)
    );
  },

  banReviewers: function(banReason) {
    var self = this;
    $.ajax({
      type: 'PUT',
      url: self.props.reviewershipsBanPath,
      dataType: 'json',
      data: {reviewership_ids: this.state.checked, message: banReason},
      success: function(res) {
        self.setState({
          reviewerships: res.reviewerships_list,
          checked: [],
        });
        Modal.close();
      }
    });
  },

  openReviewerBanModal: function() {
    Modal.open({
      children: 
        <AuthorsAndReviewersBanModal 
          banHandler={this.banReviewers}
        />
    });
  },

  sendReminder: function() {
    var self = this;
    self.setState({disabled: "disabled"})
    $.ajax({
      dataType: "JSON",
      url: self.props.remindReviewersPath,
      data: {reviewershipIds: self.state.checked},
      type: "PUT"
    }).done(function(res) {
      self.setState({disabled: "disabled", checked: []})
      Cureus.Flash.success('You have resent review invitations to: ' + res.names)
      self.fetchReviewerships()
    }).always(function() {
      $(".reviewer-checks").prop({checked:false})
      $("html, body").animate({scrollTop: 0}, 1000);
    })
  },

  updateCheckedState: function(e, reviwershipId) {
    var newChecked = this.state.checked
    if (e.target.checked) {
      newChecked.push(reviwershipId)
      this.setState({checked: newChecked, disabled: ""})
    } else {
      var idx = newChecked.indexOf(reviwershipId)
      newChecked.splice(idx, 1)
      this.setState({checked: newChecked})
      if (newChecked.length === 0) {
        $(".reviewer-checks").prop({checked:false})
        this.setState({disabled: "disabled"})
      }
    }
  },

  allEligibleReviewers: function() {
    return this.state.reviewerships.filter(function(reviewership) {
      return (["Reinvited", "Invited", "Reviewing"].includes(reviewership.status) && !reviewership.banned)
    }).map(function(reviewership) {
      return reviewership.id
    })
  },

  checkAll: function(e) {
    if (e.target.checked) {
      $(".reviewer-checks").prop({checked: true})
      this.setState({disabled: '', checked: this.allEligibleReviewers(), checkedAll: true})
    } else {
      $(".reviewer-checks").prop({checked: false})
      this.setState({disabled: 'disabled', checked: [], checkedAll: false})
    }
  },

  renderResendButton: function() {
    let checkSelectedReviewersInCorrectStates = this.compareReviewersSelected();
    if (this.props.canEdit && checkSelectedReviewersInCorrectStates && this.state.checked.length > 0) {
      return (
        <a onClick={this.sendReminder} className={"button primary tiny " + this.state.disabled} >Email selected reviewers</a>
      )
    } else {
      return (
        <a onClick={this.sendReminder} className={"button primary tiny disabled"} >Email selected reviewers</a>
      )
    }
  },

  compareReviewersSelected: function() {
    const checkedIds = this.state.checked;
    const reviewerships = this.state.reviewerships;
    for (const id of checkedIds) {
      const hasMatchingReviewer = reviewerships.some(reviewer => reviewer.id === id && ['Invited', 'Reinvited', 'Reviewing'].includes(reviewer.status));
      if (!hasMatchingReviewer) {
        return false;
      }
    }
    return true;
  },

  renderPeerReviewButton: function() {
    const { snapshotId, articleId, hasMoreThanOneReviewRound } = this.props;
  
    if (snapshotId) {
      const buttonText = hasMoreThanOneReviewRound ? "View latest Peer Review Report" : "View Peer Review Report";
  
      return (
        <div className="report-button-container">
          <a href={`/admin/articles/${articleId}/snapshots/${snapshotId}/peer_review_report`} className="button primary tiny report-button" target="_blank">
            {buttonText}
          </a>
          {hasMoreThanOneReviewRound && (
            <img className="question-mark-icon tip-top" src="https://public.cureus.com/homepage_new/question_mark.png" data-tooltip="" title="To view the report from earlier rounds of review, expand the Peer Review Satisfied article history event and click the link." />
          )}
        </div>
      );
    }
    return null;
  },

  renderManageButton: function() {
    if (this.props.canEdit) {
      return (
        <a href={this.props.editPublishArticle} className="button primary tiny " target="_blank" >Manage reviewers</a>
      )
    } else {
      return (
        <a className={"button primary tiny disabled"} value="This action is currently not available">Manage reviewers</a>
      )
    }
  },

  renderBanReviewersButton: function() {
    if (this.props.canBan && !this.state.checkedAll && this.state.checked.length > 0) {
      return <a onClick={this.openReviewerBanModal} className={"button alert tiny ban-button"}>BAN SELECTED REVIEWERS</a>
    }
  },

  renderReject: function() {
    if(this.props.canReject) {
      return <th className="text-center" width="9%">Recommendation</th>
    }
  },

  renderLegend: function() {
    return (
      <div className="small-4 columns table-legend-container">
        <div className="table-legend">
          <h5>LEGEND:</h5>
          <div className="row legend-item">
            <div className="small-1 columns">
              <i className="fas fa-id-badge"/>
            </div>
            <div className="small-11 columns">
              approval editor (provide multiple chances to revise)
            </div>
          </div>
          <div className="row legend-item">
            <div className="small-1 columns">
              <i className="fas fa-user-graduate"/>
            </div>
            <div className="small-11 columns">
              med student (not allowed as reviewers or sole authors)
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              Authors and reviewers who share the same name will be displayed in <strong>bold text</strong> for closer inspection.
            </div>
          </div>
        </div>
      </div>
    )
  },

  render: function () {
    if (!this.state.reviewerships) {
      return (
        <img src='https://public.cureus.com/ajax-loader.gif'/>
      )
    }
    return (
      <div>
        <div className="right-button-container">
          <h5>Reviewers</h5>
          {this.renderPeerReviewButton()}
          {this.renderBanReviewersButton()}
          {this.renderResendButton()}
          &nbsp;
          &nbsp;
          {this.renderManageButton()}
        </div>
        <div className="table-wrapper">
          <table className="reviewer-status" style={{cellSpacing: 0}}>
            <thead>
              <tr>
                <th className="text-center" width="5%"><input onClick={this.checkAll} className="th reviewer-checks" type="checkbox" /></th>
                <th width="20%">Name</th>
                <th width="20%">Email</th>
                <th width={this.state.columnWidth}>Status</th>
                <th className="text-center" width={this.state.columnWidth}><i className="fa fa-history"></i></th>
                <th width={this.state.columnWidth}>Inviter</th>
                <th className="text-center" width={this.state.columnWidth}>ID</th>
                {this.state.hasOldStyleMessage && <th width={this.state.columnWidth}>Message <i className="far fa-envelope"/></th>}
                {this.renderReject()}
              </tr>
            </thead>
            {this.renderReviewers()}
          </table>
        </div>
        {this.renderLegend()}
      </div>
    )
  }
});

export default Main;
