var createReactClass = require('create-react-class');
import React from 'react';
import BoostModal from './BoostModal';
import Modal from '../Modal';

const Main = createReactClass({
  componentDidMount: function(){
    var self = this;
    $("#boost-trigger-" + this.props.articleId).on('open', function(){
        self.openBoostModal();
        self.fireAmplitude();
    });
    if (this.props.socialBoostArticleId == this.props.articleId) {
      $("#boost-trigger-" + this.props.articleId).trigger("open");
    }
  },

  openBoostModal: function(){
      Modal.open({
        children: <BoostModal 
          articleId={this.props.articleId} 
          ampSource={"direct"}
          title={this.props.title}
          copyText={this.props.copyText}
          subsequentBoost={this.props.subsequentBoost}
          />})
  },

  fireAmplitude: function() {
    var self = this;
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { article_id: self.props.articleId,
              event_type: 'socialBoostViewed', 
              user_id: self.props.currentUser,
              source: self.props.utmCampaign || "direct"
            }
    })
  },

  render: function(){
    return (
      <div id={"boost-trigger-" + this.props.articleId}/>
    )
  }
})

export default Main;