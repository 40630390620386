import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
const Main = ({notificationPath, currentUserId}) => {

  const [notificationComponent, setNotificationComponent] = useState(null);

  useEffect(() =>  {
    fetchNotificationType();
  }, []);

  const fetchNotificationType = () => {
    $.ajax({
      url: notificationPath,
      type: 'GET',
      success: (response) => {
        if(response.notification != false) {
          const notificationData = response.notification;
          // imports notification component dynamically i.e. GuestEditorModal
          import(`./${notificationData[0].modal}`).then((module) => {
            const ModalComponent = module.default;
            const componentInstance = <ModalComponent notifications={notificationData} currentUserId={currentUserId}/>;
            setNotificationComponent(componentInstance);
          });
        }
      }
    })
  }

  const openModal = () => {
    Modal.open({
      children: notificationComponent
    })
  }

  return (
    <React.Fragment>
      {notificationComponent && (
        <a onClick={openModal}  className='alert-bell'>
          <img src="https://public.cureus.com/bell-alerts/alert-bell.png" />
        </a>
      )}
    </React.Fragment>
  )
}

export default Main;