import React, { useState, useEffect } from "react";
import Modal from '../Modal';
import { Cureus } from '../../Cureus';

const QueueModal = ({publishDate, setPublishDate, pictureQuizId}) => {
  const [modalPublishDate, setModalPublishDate] = useState(publishDate);

  const updatePublishDate = () => {
    if(pictureQuizId) {
      $.ajax({
        type: 'PUT',
        data: {queue_date: modalPublishDate},
        url: `/admin/picture_quizzes/${pictureQuizId}/queue`,
        success: function(data) {
          Cureus.Flash.success(`You have successfully scheduled this for ${data.queue_date} and it has been added to the queue.`)
        }
      });
    }
    setPublishDate(modalPublishDate)
    Modal.close();
  }

  return (
    <div className="reveal-modal tiny prognosis-queue-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <p className="text-center">Choose a date and click the Add button to add this to the picture prognosis queue. Please note that anything already scheduled for that date will be overwritten.</p>
        <input type="date" defaultValue={publishDate} onChange={(e) => setModalPublishDate(e.target.value)} min={new Date().toISOString().split('T')[0]}/>
        <div className='modal-footer text-center'>
          <a className="button success" onClick={updatePublishDate}>Add</a>
          <a className='cancel-modal button alert'>Cancel</a>
        </div>
      </div>
    </div>
  )
}

export default QueueModal;