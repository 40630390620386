import React from 'react';
import PictureBubbles from './PictureBubbles';

const Contributors = ({authorsCount, reviewersCount, avatars, articleCount}) => {
  const renderArticleCount = (string, count) => {
    return (
      <div className="metrics-info">
        <h1>{renderCount(count)}</h1>
        {renderLink(string)}
      </div>
    )
  }

  const renderLink = (string) => {
    if (string == "Articles") {
      return <p>{string}</p>
    } else {
      let word = string.slice(0, -1).toLowerCase()
      return <p><a href={`/people?preselected_roles%5B%5D=${word}`}>{string}</a></p>
    }
  }

  const renderCount = (count) => {
    if (count > 999) {
      return `${~~(count / 1000)}k+`
    } else if (count > 999999) {
      return `${(count / 1000000).toFixed(2)}m+`
    } else {
      return count
    }
  }

  return (
    <div className="contributors">
      <h2>By the Numbers</h2>
      <p>Totals based on article type and category selections</p>
      <div className="metrics">
      {renderArticleCount("Articles", articleCount)}
      {renderArticleCount("Authors", authorsCount)}
      {renderArticleCount("Reviewers", reviewersCount)}
      </div>

      <PictureBubbles avatars={avatars} count={authorsCount} />
    </div>
  )
}

export default Contributors