import React, { useState, useEffect } from 'react';

const MostPopularContentModal = ({handleAddWidget, widgetSidebarId, widget, handleUpdateWidget, hasArticles}) => {
  const [contentAmount, setContentAmount] = useState(widget ? widget.contentAmount : 3)
  const [disabled, setDisabled] = useState(true)

  useEffect( () => {
    setDisabled(checkDisabled())
  }, [contentAmount])

  const handleAddWidgetWrapper = () => {
    if(handleAddWidget) {
      handleAddWidget(params())
    } else {
      handleUpdateWidget(widget.id, params())
    }
  }

  const params = () => {
    return {
      widget: {
        type: "Widget::MostPopularContent",
        parent_id: widgetSidebarId,
        configuration: {
          amount: contentAmount
        }
      }
    }
  }

  const checkDisabled = ( () => {
    return hasArticles ? false : true
  })

  const disabledClass = () => {
    return disabled ? "disabled" : ""
  }

  const renderText = () => {
    if (hasArticles) {
      return<p>Highlight the most-viewed channel articles from the last 30 days.</p>
    } else {
      return <p style={{color: "#CA3145"}}>You cannot add this widget unless you have published articles.</p>
    }
  }
  const renderNumberInput = () => {
    return (
      <div className="input-container">
        <label>Number of displayed articles</label>
        <input type="number" name="content-amount" value={contentAmount || 3} onChange={(e) => {setContentAmount(parseInt(e.target.value))}}/>
      </div>
    )
  }

  return (
    <div className="reveal-modal small channel-design-modals most-popular-content-modal" data-reveal>
      <div className="modal-body">
        <div className='modal-close'>
          <a href='#' className='cancel-modal'>
            <img src="https://public.cureus.com/close_x.png"/>
          </a>
        </div>
        <div className="modal-content middling">
          <div className="modal-header text-center">
            <h5>Most Popular Articles</h5>
            <hr/>
          </div>
          <div className="row">
            <div className="small-12 columns">
              {renderText()}
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              {hasArticles && renderNumberInput()}
            </div>
          </div>
          <div className='modal-footer text-center'>
            <a className={"button primary " + disabledClass()} onClick={handleAddWidgetWrapper}>Add</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MostPopularContentModal;