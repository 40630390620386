import React from 'react';

const SearchBar = ({ classes, doSearch }) => {
  
  return (
    <div className={`search-bar-container ${ classes }`}>
      <i className="fas fa-search fa-lg" onClick={ doSearch }></i>
      <input id="search-input" onKeyDown={ doSearch } ></input>
    </div>
  )
}

export default SearchBar;