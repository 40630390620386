import React from 'react'
import {Cureus} from '../../Cureus';
var createReactClass = require('create-react-class');

const EmailReminderModal = createReactClass({
  getDefaultProps: function() {
    var param = $("[name='csrf-param']").attr("content");
    var token = $("[name='csrf-token']").attr("content");

    return {
      csrfParam: param,
      csrfToken: token
    };
  },

  handleSubmit: function(){
    var self = this;
    var $form = $("#reminder-form")
    $("#form-submit-button").addClass('disabled')
    $.ajax({
      type: "POST",
      url: self.props.createPath,
      data: $form.serialize(),
      success: function() {
        $('#email-reminder-modal').foundation('reveal', 'close');
        Cureus.Flash.success("Success! You've sent a reminder to the user.")
        if (self.props.unCheckSingles) self.props.unCheckSingles();
        if (self.props.unCheckAll) self.props.unCheckAll();
      },
      error: function() {
        $('#email-reminder-modal').foundation('reveal', 'close');
        Cureus.Flash.alert("Oops! There was an error with your request.")
      }
    });
  },

  renderSubmitButton: function() {
    if(this.props.async) {
      return (
        <a className='button primary' id="form-submit-button" onClick={this.handleSubmit}>Send</a>
      )
    } else {
      return (
        <input type='submit' value='Send' className='button primary'/>
      )
    }
  },

  render: function() {
    return (
      <div className="reveal-modal small" data-reveal id="email-reminder-modal">
        <div className="modal-body">
          <div className="modal-close">
            <a className="cancel-modal">
              <img alt="Close_x" src="https://public.cureus.com/close_x.png"/>
            </a>
          </div>

          <div className="modal-content">
            <form acceptCharset="UTF-8" method="post" action={this.props.createPath} id="reminder-form">
              <input name="utf8" type="hidden" value="✓" />
              <input type="hidden" name={ this.props.csrfParam } value={ this.props.csrfToken } />
              <input type='hidden' name='templateName' value={ this.props.templateName }/>
              <input type='hidden' name='templateData[resource_ids]' value={ this.props.templateData.resource_ids }/>

              <label>Recipients</label>
              <input type='text' defaultValue={ this.props.data.recipient_emails } readOnly/>

              <label>From</label>
              <input type='text' value={ this.props.data.from } readOnly/>

              <label>Subject</label>
              <input type='text' defaultValue={ this.props.data.subject } name='templateData[subject]'/>

              <label>Message</label>
              <textarea defaultValue={ this.props.data.message } rows='8' className='large-text-pizzas' name='templateData[message]'/>

              <div className='modal-footer'>
                <a className='cancel-modal button secondary'>Cancel</a>
                {this.renderSubmitButton()}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
});

export default EmailReminderModal;