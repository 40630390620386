var createReactClass = require('create-react-class');
import React from 'react';

const Sorter = createReactClass({
  renderSortDropdown: function (){
    return (
      <select id="email-list-tool-sort-type-selector" className="email-list-tool-sort-type-selector" onChange={this.props.onSortTypeChange} defaultValue={this.props.sortType}>
        <option value="title">   Name </option>
        <option value="created_at">   Date </option>
        <option value="size">   Size </option>
        <option value="type">   Type </option>
        <option value="position"> Custom </option>
      </select>
    )
  },

  renderSortDirection: function (){
    if (this.props.sortType == "position") {
      return
    }
    else {
      var direction_icon;
      if(this.props.sortDir == "normal") {
        direction_icon = ( <i className="fa fa-arrow-up" />)
      }else{
        direction_icon = ( <i className="fa fa-arrow-down" />)
      }

      return (
        <a className= "button small secondary email-list-tool-sort-dir" onClick={this.props.onSortDirChange}>
          { direction_icon }
        </a>
      )
    }
  },

  render: function (){
    return (
      <div className="email-list-tool-sorter">
        <label htmlFor="email-list-tool-sort-type-selector">Sort By:</label>
        { this.renderSortDropdown() }
        { this.renderSortDirection() }
      </div>
    );
  }
});

export default Sorter;