import React, { Component } from 'react';
import Row from './Row';
import DualRow from './DualRow';

class Table extends Component {
  state = {
    editors: [],
    sortCol: null,
    edited: {}
  }

  componentDidMount() {
    this.fetchEditors()
  }

  fetchEditors() {
    $.ajax({
      type: 'GET',
      url: this.props.path,
      data: {type: this.props.type},
      success: (res) => {
        this.setState({editors: res.editors})
      }
    })
  }

  handleSort = (col) => {
    var sortedEditors;
    if (col === this.state.sortCol) {
      sortedEditors = this.state.editors.reverse()
    } else {
      sortedEditors = this.state.editors.sort(function(a, b) {
        var aVal;
        var bVal;
        if(['submissions', 'copy', 'approval'].includes(col)) {
          aVal = a["goals"][col];
          bVal = b["goals"][col];
        } else {
          aVal = a[col];
          bVal = b[col];
        }
        if (aVal < bVal) { return -1; }
        if (aVal > bVal) { return 1; }
        return 0;
      });
    }
    this.setState({editors: sortedEditors, sortCol: col})
  }

  handleEdit = (e, id) => { 
    let edited = this.state.edited
    let type = e.target.name
    if(edited[id] && edited[id][type]) {
      edited[id][type] = {user_id: id, amount: e.target.value, goal_type: type}
    } else {
      edited[id] = {...edited[id], ...{[type]: {user_id: id, amount: e.target.value, goal_type: type}}}
    }
    this.setState({edited: edited})
  }

  handleSave = () => {
    $.ajax({
      type: 'PUT',
      url: this.props.updatePath,
      data: {editors: this.state.edited},
      success: (res) => {
        this.setState({edited: {}}, function() {
          this.fetchEditors()
        })
      }
    })
  }

  renderHead() {
    if(this.props.type === 'dual' || this.props.type === 'deputy') {
      return (
        <thead>
          <tr>
            <th onClick={() => this.handleSort('email')} width="20%">Email</th>
            <th onClick={() => this.handleSort('first_name')} width="20%">First name</th>
            <th onClick={() => this.handleSort('last_name')} width="20%">Last name</th>
            <th onClick={() => this.handleSort('title')} width="20%">Title</th>
            <th onClick={() => this.handleSort('submissions')} width="10%">SE Goal</th>
            <th onClick={() => this.handleSort('copy')} width="10%">CE Goal</th>
          </tr>
        </thead>
      )
    } else {
      return (
        <thead>
          <tr>
            <th onClick={() => this.handleSort('email')} width="20%">Email</th>
            <th onClick={() => this.handleSort('first_name')} width="20%">First name</th>
            <th onClick={() => this.handleSort('last_name')} width="20%">Last name</th>
            <th onClick={() => this.handleSort('title')} width="20%">Title</th>
            <th onClick={() => this.handleSort(this.props.type)} width="20%">Goal</th>
          </tr>
        </thead>
      )
    }
  }

  renderEditors = () => {
    return this.state.editors.map((editor) => {
      if(this.props.type === 'dual' || this.props.type === 'deputy') {
        return <DualRow 
                  key={editor.id} 
                  editor={editor} 
                  handleEdit={this.handleEdit}
                  edited={this.state.edited}
               />
      } else {
        return <Row 
                  key={editor.id} 
                  editor={editor} 
                  handleEdit={this.handleEdit}
                  type={this.props.type}
                  edited={this.state.edited}
              />
      }
    })
  }

  render() {
    return (
      <div className="editor-goals-table-container">
        <div className="table-wrapper">
          <table>
            {this.renderHead()}
            {this.renderEditors()}
          </table>
        </div>
        <div className="right">
          <a className="button special small" onClick={this.handleSave}>Save</a>
        </div>
      </div>
    )
  }
}

export default Table;