import React, { useState, useEffect } from 'react';

const Topic = ({topic, handleDelete}) => {
  const renderDeleteX = () => {
    return (
      <img className="action-buttons" src="https://public.cureus.com/homepage_new/delete-x.png" onClick={() => handleDelete(topic)}/>
    )
  }
  return (
  <span>
    <a className="button pill drag-handle" href={`/articles?page=1&q=&order=%7B%22attr%22%3A%22_score%22%2C%22dir%22%3A%22desc%22%2C%22text%22%3A%22Relevance%22%7D&advanced=true&filters%5B0%5D%5Bboolean%5D=&filters%5B0%5D%5Bfield%5D=keywords&filters%5B0%5D%5Bquery%5D=${topic}&obeySort=false`} target="_blank">{topic}</a>
    {renderDeleteX()}
  </span>
  )
}

export default Topic;