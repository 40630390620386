import React from 'react';

const DisciplineSpecificStandards = () => {
  return (
    <React.Fragment>
      <h1>Discipline Specific Standards</h1>
      <p>
        We strongly recommend all authors adhere to the reporting standards adopted by their field or study design. The following is a list of standards that should be followed depending on the study type:
      </p>
      <ul className='indented-for-medium-up'>
        <li className='check'>
          Articles reporting on <strong>protein structures</strong> should follow the standards within the <a href='http://www.wwpdb.org/' target='_blank' rel='noreferrer'>Worldwide Protein Data Bank</a> (a general resource listing reporting standards and worldwide deposition databases for protein information).
        </li>
        <li className='check'>
          Articles reporting on <strong>diagnostic accuracy studies</strong> (studies evaluating the ability of a test to differentiate between patients who have the target condition and those who do not have the target condition) should follow the <a href='https://www.equator-network.org/reporting-guidelines/stard/' target='_blank' rel='noreferrer'>STARD requirements</a>.
        </li>
        <li className='check'>
          Articles reporting on <strong>clinical trials</strong> must follow the <a href='http://www.who.int/ictrp/en/' target='_blank' rel='noreferrer'>WHO definition of a clinical trial</a>. In accordance with the ICMJE guidelines, all trials initiated from July 1st, 2005 must be registered in an approved registry. Unregistered trials will not be considered.
        </li>
        <li className='check'>
          Reports of <strong>systematic reviews and meta-analyses </strong>must use the PRISMA statement for clinical articles, which may be used for all non-clinical articles. Authors should include a completed PRISMA checklist and flow diagram to accompany their text and should state (within their Materials &amp; Methods section) whether a protocol exists for their systematic review (if so, a copy of the protocol must be supplied as supplemental information). Authors may register their systematic review (e.g. in a registry such as PROSPERO) and should provide the registry number in their article. Systematic reviews or meta-analyses should include those terms in the title, abstract and/or full article.
        </li>
        <li className='check'>
          Reports utilizing <strong>quantitative real time PCR </strong>should follow the MIQE guidelines and checklist.
        </li>
        <li className='check'>
          Articles reporting on <strong>microarray experiments </strong>should conform to the standards published by the Functional Genomics Data Society, the most well known being the MIAME guidelines. The MIAME checklist must be provided, and all data from the experiments must be deposited in a publicly accessible database (ArrayExpress or GEO) with the accession numbers provided in the article.
        </li>
        <li className='check'>
          Articles which report on medical <strong>observational/epidemiological studies </strong>(specifically cohort, case-control and cross-sectional studies) must adhere to the STROBE initiative.
        </li>
      </ul>
      <p>
        In addition, Cureus suggests that authors consult the EQUATOR Network (a general resource listing various reporting standards for health research studies); the MIBBI Portal (a listing of minimum information guidelines from diverse bioscience communities); or the BioSharing site for additional reporting guidelines or checklists which might apply to their work.
      </p>
      <p>
        Generally speaking, data should be referred to by the most specific identifier available for the database archive to which it is submitted.
      </p>
    </React.Fragment>
  )
}

export default DisciplineSpecificStandards;